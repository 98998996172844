// the order of this enum dynamically determines the stack context
enum zMap {
  '_UNUSED',
  'TABLE_HEADERS',
  'CARD_TITLE',
  // mapbox pins are set to zIndex:3, anything that should appear above them should be after this entry
  'MAP_PIN',
  'AUTOCOMPLETE',
  'APP_HEADER',
  'MODAL_BACKGROUND',
  'REACH_MENU',
  'TOOLTIP',
}

export const {
  APP_HEADER,
  AUTOCOMPLETE,
  CARD_TITLE,
  MODAL_BACKGROUND,
  REACH_MENU,
  TABLE_HEADERS,
  TOOLTIP,
} = zMap;
