import * as TP from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CarrierItemV2Fragment = (
  { readonly __typename?: 'CarrierV2' }
  & Pick<TP.CarrierV2, 'id' | 'name' | 'code' | 'scacNumber' | 'currencyId'>
  & { readonly mainAddress?: TP.Maybe<(
    { readonly __typename?: 'CarrierAddress' }
    & Pick<TP.CarrierAddress, 'city' | 'state'>
  )> }
);

export type CarriersForCarrierPickerV2QueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.CarriersFilterV2>;
  first?: TP.InputMaybe<TP.Scalars['Int']>;
  last?: TP.InputMaybe<TP.Scalars['Int']>;
  after?: TP.InputMaybe<TP.Scalars['String']>;
  before?: TP.InputMaybe<TP.Scalars['String']>;
}>;


export type CarriersForCarrierPickerV2Query = (
  { readonly __typename?: 'Query' }
  & { readonly carriersNullableV2?: TP.Maybe<(
    { readonly __typename?: 'CarrierConnectionV2' }
    & { readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & Pick<TP.PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), readonly edges: ReadonlyArray<(
      { readonly __typename?: 'CarrierEdgeV2' }
      & Pick<TP.CarrierEdgeV2, 'cursor'>
      & { readonly node: (
        { readonly __typename?: 'CarrierV2' }
        & Pick<TP.CarrierV2, 'id' | 'name' | 'code' | 'scacNumber' | 'currencyId'>
        & { readonly mainAddress?: TP.Maybe<(
          { readonly __typename?: 'CarrierAddress' }
          & Pick<TP.CarrierAddress, 'city' | 'state'>
        )> }
      ) }
    )> }
  )> }
);

export const CarrierItemV2FragmentDoc = gql`
    fragment CarrierItemV2 on CarrierV2 {
  id
  name
  code
  mainAddress {
    city
    state
  }
  scacNumber
  currencyId
}
    `;
export const CarriersForCarrierPickerV2Document = gql`
    query carriersForCarrierPickerV2($filter: CarriersFilterV2, $first: Int, $last: Int, $after: String, $before: String) {
  carriersNullableV2(
    filter: $filter
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...CarrierItemV2
      }
    }
  }
}
    ${CarrierItemV2FragmentDoc}`;

/**
 * __useCarriersForCarrierPickerV2Query__
 *
 * To run a query within a React component, call `useCarriersForCarrierPickerV2Query` and pass it any options that fit your needs.
 * When your component renders, `useCarriersForCarrierPickerV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarriersForCarrierPickerV2Query({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useCarriersForCarrierPickerV2Query(baseOptions?: Apollo.QueryHookOptions<CarriersForCarrierPickerV2Query, CarriersForCarrierPickerV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CarriersForCarrierPickerV2Query, CarriersForCarrierPickerV2QueryVariables>(CarriersForCarrierPickerV2Document, options);
      }
export function useCarriersForCarrierPickerV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CarriersForCarrierPickerV2Query, CarriersForCarrierPickerV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CarriersForCarrierPickerV2Query, CarriersForCarrierPickerV2QueryVariables>(CarriersForCarrierPickerV2Document, options);
        }
export type CarriersForCarrierPickerV2QueryHookResult = ReturnType<typeof useCarriersForCarrierPickerV2Query>;
export type CarriersForCarrierPickerV2LazyQueryHookResult = ReturnType<typeof useCarriersForCarrierPickerV2LazyQuery>;
export type CarriersForCarrierPickerV2QueryResult = Apollo.QueryResult<CarriersForCarrierPickerV2Query, CarriersForCarrierPickerV2QueryVariables>;