import { ApolloProvider } from '@apollo/client';
import { useMasteryApolloClient } from '@app/client';
import { AuthStatus, useKeycloakAuthContext } from '@components/AuthContext';
import { Loading } from '@components/Loading';
import { useKeycloak } from '@react-keycloak/web';
import { IS_LOCAL_DEV, SKIP_AUTH } from '@utils/constants';
import { win } from '@utils/win';
import { FC } from 'react';
import { AppRoutes, routeCanBypassAuth } from '../AppRoutes';

const addToQueryParams = (key: string, value: string): string => {
  let res = win.location.href;
  if (IS_LOCAL_DEV) {
    return res;
  }
  try {
    const u = new URL(res);
    if (!u.searchParams.get(key)) {
      u.searchParams.append(key, value);
    }
    res = u.href;
  } catch {
    // noop
  }
  return res;
};

export const LOGIN_INIT_KEY = 'login-init';

const KeycloakWrapper: FC = ({ children }) => {
  const client = useMasteryApolloClient();
  const { status } = useKeycloakAuthContext();
  const { keycloak, initialized } = useKeycloak();
  let content = null;

  if (routeCanBypassAuth() && keycloak.authenticated !== true) {
    content = <AppRoutes />;
  } else if (keycloak.authenticated === true) {
    content = <ApolloProvider client={client}>{children}</ApolloProvider>;
  } else if (initialized && keycloak.authenticated === false) {
    keycloak.login({
      redirectUri: IS_LOCAL_DEV
        ? win.location.href
        : addToQueryParams(LOGIN_INIT_KEY, 'true'),
    });
  } else if (SKIP_AUTH && status === AuthStatus.authenticated) {
    content = <ApolloProvider client={client}>{children}</ApolloProvider>;
  } else {
    // We are waiting for keycloak.authenticated here
    // At time of writing, keycloak checks every login, there is no immediate boot
    // Hopefully once we transition to k8s deployment, we can have fast boot from server instead of round trip back to keycloak server
    content = <Loading delay={2500} css={{ margin: 100 }} />;
  }
  return content;
};

export const AuthWrapper: FC = ({ children }) => (
  <KeycloakWrapper>{children}</KeycloakWrapper>
);
