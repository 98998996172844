import { useHasPermissionFromContext } from '@components/PermissionsContext';
import { getInnerText } from '@components/Table/util/getInnerText';
import { INPUT_HEIGHT } from '@components/Theme';
import { CSSObject } from '@emotion/styled';
import { useDebouncedFn } from '@hooks/useDebouncedFn';
import { useTheme } from '@hooks/useTheme';
import { win } from '@utils/win';
import { AUTOCOMPLETE } from '@utils/zIndex';
import Downshift, {
  ControllerStateAndHelpers,
  DownshiftState,
  GetItemPropsOptions,
  StateChangeOptions,
} from 'downshift';
import { isFunction, isString, noop, omit, toLower } from 'lodash-es';
import {
  forwardRef,
  HTMLProps,
  KeyboardEvent,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { ReadOnlyField } from '../Field/ReadOnlyField';
import { Label } from '../FieldLabel';
import { Icon } from '../Icon';
import { AUTOCOMPLETE_OFF_VALUE, Input } from '../Input';
import { useMaxItemContainerWidth } from './useMaxItemContainerWidth';

export interface Shell<ItemType> {
  label: string | ((item?: Maybe<fixMe>) => ReactNode);
  value: ItemType | undefined;
  /**  used for React key attr, falls back to label if no id provided */
  id?: string | number;
  hidden?: boolean;
  /** Render the item, but disallow selection */
  disabled?: boolean;
}

type ItemToString<ItemType> = (item: Shell<ItemType> | null) => string;

export type RenderItemHelpers<ItemType> = ControllerStateAndHelpers<
  Shell<ItemType>
> & {
  defaultItemStyles: CSSObject;
  key: string;
  item: Shell<ItemType>;
  isHighlighted: boolean;
  index: number;
  itemProps: Record<string, unknown>;
  getItemProps: (options: GetItemPropsOptions<Shell<ItemType>>) => unknown;
  disabled?: boolean;
};

export interface Props<ItemType> {
  name?: string;
  disabled?: boolean;
  id?: string;
  initialSelectedItem?: Shell<ItemType>;
  onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
  inputProps?: Omit<
    HTMLProps<HTMLInputElement> & { 'data-testid'?: string },
    'disabled'
  >;
  items: ReadonlyArray<Shell<ItemType>>;
  itemToString?: ItemToString<ItemType>;
  label?: ReactNode;
  loading?: boolean | string;
  /** Defaults to true. Typically this is what you want as you will not have any results yet. */
  showLoadingMessageOnFirstLoading?: boolean;
  /** This is the event you want to tap into to do asynchronous work. You must provide your own debounced function if you want to do that. Use the useDebouncedFn hook. */
  onInputValueChange?: (
    inputValue: string,
    stateAndHelpers: ControllerStateAndHelpers<Shell<ItemType>>
  ) => void;
  onChange: (
    item: Shell<ItemType> | null,
    helpers?: ControllerStateAndHelpers<Shell<ItemType>>
  ) => void;
  openMenuOnFocus?: boolean;
  renderItem?: (helpers: RenderItemHelpers<ItemType>) => ReactNode;
  /** Defaults to false */
  showSearchIcon?: boolean;
  selectedItem?: Maybe<Shell<ItemType>>;
  filterOnLabel?: boolean;
  disableSelectOnTab?: boolean;
  initialIsOpen?: boolean;
  isOpen?: boolean;
  downshiftStateReducer?: (
    state: DownshiftState<Shell<ItemType>>,
    changes: StateChangeOptions<Shell<ItemType>>
  ) => Partial<StateChangeOptions<Shell<ItemType>>>;
  /** Restricts item container to width of parent input, incurring further possibility of word-break */
  restrictItemContainerWidth?: boolean;
  renderDropdownInPopper?: boolean;
  /** The item selection area will appear above the button */
  dropup?: boolean;
  popperZIndex?: number;
  tabIndex?: number;
  disableClickBubble?: boolean;
  triggerInputChangeOnEmpty?: boolean;
  ref?: MutableRefObject<HTMLButtonElement> | null;
}

export const getStringFromLabelFunc = (item: Maybe<Shell<unknown>>): string => {
  if (isFunction(item?.label)) {
    let candidate: ReactNode = '';
    if (item && item.label) {
      candidate = item.label(item.value);
    }
    if (isString(candidate)) {
      return candidate;
    } else {
      return getInnerText((<>{candidate}</>) as fixMe);
    }
  }
  return item?.label || '';
};

export function getStringFromItem<ItemType>(
  item: Maybe<Shell<ItemType>>,
  itemToString?: ItemToString<ItemType>
): string {
  if (item) {
    if (itemToString) {
      return itemToString(item);
    } else if (isString(item.label)) {
      return item.label;
    } else if (isFunction(item.label)) {
      const label = item.label();
      return isString(label) ? label : '';
    } else {
      return '';
    }
  }
  return '';
}

/** Characters to ignore when matching the input value to the list of results */
const charsToIgnoreRegex = /(,|\.|-|'|")/gi;

export function checkFilterMatch<ItemType>(
  item: Shell<ItemType>,
  inputValue: string,
  itemToString?: ItemToString<ItemType>
): boolean {
  const coercedLabel = toLower(getStringFromItem(item, itemToString))
    .trim()
    .replace(charsToIgnoreRegex, '');
  const coercedInput = toLower(inputValue)
    .trim()
    .replace(charsToIgnoreRegex, '');
  return coercedLabel.includes(coercedInput);
}

// ts-unused-exports:disable-next-line
export const itemStyles: CSSObject = {
  margin: 0,
  padding: '8px 8px',
};

const ITEM_CONTAINER_MAX_HEIGHT = 250;
export const ITEM_CONTAINER_VERT_MARGIN = 2;

interface ItemContainerProps {
  maxWidth?: number;
  minWidth?: number | string;
  disableClickBubble?: boolean;
}

export const ItemContainer = forwardRef<HTMLUListElement, ItemContainerProps>(
  ({ children, maxWidth, minWidth, disableClickBubble, ...rest }, ref) => {
    const { card, gray, contextMenu } = useTheme();
    return (
      <ul
        css={{
          background: contextMenu.background,
          border: `1px solid ${gray[100]}`,
          borderRadius: 2,
          boxShadow: card.boxShadow,
          listStyleType: 'none',
          margin: 0,
          marginTop: ITEM_CONTAINER_VERT_MARGIN,
          maxHeight: ITEM_CONTAINER_MAX_HEIGHT,
          maxWidth: maxWidth || '100%',
          minWidth: minWidth || '100%',
          overflow: 'auto',
          padding: 0,
          position: 'absolute',
          top: '100%',
          width: 'max-content',
          zIndex: AUTOCOMPLETE,
        }}
        onClick={
          disableClickBubble ? (e): void => e.stopPropagation() : undefined
        }
        {...rest}
        ref={ref}
      >
        {children}
      </ul>
    );
  }
);

export const Item = forwardRef<
  anyOk,
  {
    isHighlighted: boolean;
    children: ReactNode;
    disabled?: boolean;
    onClick?: () => void;
  }
>(({ children, isHighlighted, disabled, ...rest }, ref) => {
  const {
    colors: { primary, text, disabledText },
  } = useTheme();
  return (
    <li
      data-disabled={disabled}
      css={{
        ...itemStyles,
        cursor: disabled ? 'not-allowed' : 'pointer',
        userSelect: 'none',
        backgroundColor: isHighlighted ? primary : 'inherit',
        color: isHighlighted ? 'white' : disabled ? disabledText : text,
      }}
      {...rest}
      onClick={
        disabled
          ? (e): void => {
              if (disabled) {
                e.preventDefault();
              }
            }
          : rest.onClick
      }
      ref={ref}
    >
      {children}
    </li>
  );
});

// this is an example of a TS generic react component
export const AutoComplete = <ItemType extends unknown>(
  props: Props<ItemType>
): ReactElement => {
  const {
    disableSelectOnTab,
    downshiftStateReducer,
    filterOnLabel = false,
    initialIsOpen,
    initialSelectedItem,
    inputProps,
    isOpen: isOpenProp,
    items,
    itemToString = getStringFromLabelFunc,
    label,
    loading,
    showLoadingMessageOnFirstLoading = true,
    name,
    onChange,
    onInputValueChange = noop,
    openMenuOnFocus = false,
    renderItem,
    restrictItemContainerWidth,
    selectedItem,
    showSearchIcon = false,
    renderDropdownInPopper,
    dropup,
    popperZIndex,
    triggerInputChangeOnEmpty = false,
    ...restProps
  } = props;

  const { gray } = useTheme();

  const [isLoading, setLoading] = useState(false);
  const [loadingTimer, setLoadingTimer] = useState<number | null>(null);
  const [isTyping, setIsTyping] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const itemContainerRef = useRef<HTMLDivElement>(null);

  const parentContainerRef = useRef();
  const maxItemContainerWidth = useMaxItemContainerWidth(
    parentContainerRef,
    restrictItemContainerWidth
  );

  const {
    styles: popperStyles,
    attributes: popperAttributes,
    state: popperState,
    update: popperUpdate,
  } = usePopper(inputRef.current, itemContainerRef.current, {
    placement: 'bottom-start',
    strategy: 'fixed',
  });

  const [userCanEdit, permissionScope] = useHasPermissionFromContext();

  // It is useful to only show the loading message that the consumer provides after N milliseconds
  // Otherwise you get unhelpful loading jank, especially if the requests run quick (< 500ms)
  useEffect(() => {
    clearTimeout(loadingTimer || 0);
    // if it is the first time that we see a loading prop, set immediately, as we probably don't have any results yet.
    if (loading && loadingTimer === null && showLoadingMessageOnFirstLoading) {
      setLoading(true);
    }
    // if the loadingTimer is null, that means we haven't seen a loading=true prop yet. So the component may have just mounted with no user input.
    else if (loadingTimer === null) {
      return;
    }

    if (loading) {
      const timer = win.setTimeout(() => setLoading(true), 1500);
      setLoadingTimer(timer);
    } else {
      setLoading(false);
      setLoadingTimer(0);
    }
    return (): void => clearTimeout(loadingTimer || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const debouncedSetIsTyping = useDebouncedFn(setIsTyping, 100, []);
  if (!userCanEdit) {
    return (
      <>
        {label && <Label>{label}</Label>}
        <ReadOnlyField data-scope={permissionScope} name={name}>
          {selectedItem?.label ||
            initialSelectedItem?.label ||
            inputProps?.value}
        </ReadOnlyField>
      </>
    );
  }

  return (
    <Downshift
      onChange={onChange}
      itemToString={itemToString}
      defaultHighlightedIndex={0}
      initialSelectedItem={initialSelectedItem}
      initialIsOpen={initialIsOpen}
      isOpen={isOpenProp}
      selectedItemChanged={(prev, next): boolean => {
        if (prev?.id) {
          return prev.id !== next?.id;
        } else if (prev?.label) {
          return getStringFromLabelFunc(prev) !== getStringFromLabelFunc(next);
        }
        // this is the default per Downshift, but we might want to change this to _.isEqual ?
        return prev !== next;
      }}
      onInputValueChange={(value, helpers): void => {
        if (!value && helpers.selectedItem?.label) {
          helpers.clearSelection();
        }
        if (
          (helpers.selectedItem?.label || '') !== value ||
          (triggerInputChangeOnEmpty && value === '')
        ) {
          onInputValueChange(value, helpers);
        }
        setIsTyping(true);
        debouncedSetIsTyping(false);
      }}
      selectedItem={selectedItem}
      stateReducer={(
        state,
        rawChanges
      ): Partial<StateChangeOptions<Shell<ItemType>>> => {
        let changes = rawChanges;
        if (
          changes.type ===
          Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem
        ) {
          changes = {
            ...changes,
            inputValue: itemToString(selectedItem || null),
          };
        }
        if (downshiftStateReducer) {
          return downshiftStateReducer(state, changes);
        }
        return changes;
      }}
    >
      {(helpers): ReactNode => {
        const {
          clearSelection,
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          getRootProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectHighlightedItem,
          openMenu,
        } = helpers;
        const allItemsToRender = filterOnLabel
          ? items.filter((item) =>
              checkFilterMatch(item, inputValue || '', itemToString)
            )
          : items;
        const itemsToRender = allItemsToRender.filter((obj) => !obj.disabled);

        const itemContainer = (
          <ItemContainer
            {...getMenuProps({}, { suppressRefError: true })}
            css={{
              bottom: dropup ? `${INPUT_HEIGHT}px` : undefined,
              top: dropup ? 'unset' : undefined,
            }}
            maxWidth={maxItemContainerWidth}
            minWidth={
              (renderDropdownInPopper &&
                popperState?.rects?.reference?.width) ||
              '100%'
            }
            disableClickBubble
          >
            {isLoading && (
              <li css={itemStyles} data-testid="autocomplete-loading-msg">
                {isString(loading) ? loading : 'Loading...'}
              </li>
            )}
            {allItemsToRender.map((item, fallbackIdx) => {
              let index = fallbackIdx;
              if (item.id) {
                const foundIdx = itemsToRender.findIndex(
                  (obj) => obj.id === item.id
                );
                if (foundIdx > -1) {
                  index = foundIdx;
                }
              }
              const isHighlighted =
                !item.disabled && highlightedIndex === index;
              const key = String(item.id || getStringFromLabelFunc(item));
              if (renderItem) {
                return renderItem({
                  ...helpers,
                  itemProps: {
                    ...getItemProps({ item: omit(item, ['disabled']) }),
                    key,
                  },
                  defaultItemStyles: itemStyles,
                  key,
                  item,
                  getItemProps,
                  index,
                  isHighlighted,
                  disabled: item.disabled,
                });
              }
              return (
                <Item
                  key={item.id || getStringFromLabelFunc(item)}
                  isHighlighted={isHighlighted}
                  disabled={item.disabled}
                  {...getItemProps({
                    key,
                    index,
                    item: omit(item, ['disabled']),
                  })}
                >
                  {isFunction(item.label)
                    ? item.label(item.value)
                    : item.label === ''
                    ? '---'
                    : item.label}
                </Item>
              );
            })}
          </ItemContainer>
        );

        return (
          <div
            css={{ position: 'relative' }}
            data-scope={permissionScope}
            // This attr is really just for Cypress tests.
            // It helps us know that the dropdown items probably won't change (re-render)
            data-has-settled={!isTyping && !isLoading && !loadingTimer}
            ref={parentContainerRef as fixMe}
            data-fieldname={name || inputProps?.name}
            {...restProps}
          >
            {label && <Label {...getLabelProps()}>{label}</Label>}
            <div
              css={{ position: 'relative', height: '100%' }}
              {...getRootProps({ refKey: 'ref' }, { suppressRefError: true })}
            >
              {showSearchIcon && (
                <Icon
                  i="search"
                  color={gray[400]}
                  css={{
                    position: 'absolute',
                    top: 1,
                    left: 9,
                    height: 'calc(100% - 2px)',
                    pointerEvents: 'none',
                  }}
                />
              )}
              <Input
                {...getInputProps({
                  onKeyDown: (e: KeyboardEvent): void => {
                    if (e.key === 'Tab') {
                      if (!disableSelectOnTab && isOpen) {
                        selectHighlightedItem();
                      } else if (!inputValue) {
                        // workaround for an upstream downshift issue (technically not a bug)
                        // https://github.com/downshift-js/downshift/issues/714
                        // switching to useCombobox should solve this when its released
                        clearSelection();
                      }
                    }
                  },
                })}
                ref={inputRef as fixMe}
                autoComplete={AUTOCOMPLETE_OFF_VALUE}
                disabled={restProps.disabled}
                css={
                  {
                    background: inputProps?.readOnly ? gray[100] : '',
                    paddingRight: 8,
                    ...(showSearchIcon && { paddingLeft: 23 }),
                    ...(inputProps?.css as CSSObject),
                  } as CSSObject
                }
                {...omit(inputProps, ['css'])}
                name={inputProps?.name || name}
                onFocus={async (e): Promise<void> => {
                  if (renderDropdownInPopper) {
                    await popperUpdate?.();
                  }
                  inputProps?.onFocus?.(e);
                  if (openMenuOnFocus) {
                    openMenu();
                  }
                }}
              />
            </div>
            {renderDropdownInPopper ? (
              <>
                {createPortal(
                  <div
                    ref={itemContainerRef}
                    style={{
                      ...popperStyles.popper,
                      zIndex: popperZIndex ?? AUTOCOMPLETE,
                    }}
                    {...popperAttributes.popper}
                  >
                    {isOpen && itemContainer}
                  </div>,
                  document.body
                )}
              </>
            ) : (
              isOpen && itemContainer
            )}
          </div>
        );
      }}
    </Downshift>
  );
};
