import { pickBy } from 'lodash-es';
import { FC, useMemo, useRef } from 'react';
import TextAreaAutoSizer from 'react-textarea-autosize';
import {
  SupportedElementWithDebounce,
  useDebouncedOnChange,
} from '../../hooks/useDebouncedOnChange';

interface RowProps {
  minRows?: number;
  maxRows?: number;
  rows?: number;
  resize?: boolean;
}

export type Props = RowProps &
  SupportedElementWithDebounce<HTMLTextAreaElement>;

function getMinMaxRows({ minRows, maxRows, rows }: RowProps): {
  minRows?: number;
  maxRows?: number;
  rows?: number;
} {
  const shouldGrow = Boolean(minRows || maxRows);
  if (!shouldGrow) {
    return pickBy({
      rows,
    });
  }
  return {
    minRows: Math.min(rows || Infinity, minRows || Infinity) || maxRows || 1,
    maxRows,
  };
}

export const TextArea: FC<Props> = (props) => {
  const ref = useRef(null);
  const { val, onChange } = useDebouncedOnChange<HTMLTextAreaElement>({
    ...props,
    ref,
  });
  const { minRows, maxRows, rows, resize = true, ...rest } = props;
  let Element: anyOk = 'textarea';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sizeObj = useMemo(() => getMinMaxRows({ minRows, maxRows, rows }), []);
  if (sizeObj.minRows || sizeObj.maxRows) {
    Element = TextAreaAutoSizer;
  }
  return (
    <Element
      css={{
        height: 'auto',
        width: '100%',
        minHeight: 34,
        '&:disabled': {
          cursor: 'not-allowed',
          opacity: 0.6,
        },
        resize: resize ? 'initial' : 'none',
      }}
      {...(rest as anyOk)}
      {...sizeObj}
      onChange={onChange}
      value={val as string}
    />
  );
};
