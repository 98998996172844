import * as TP from '../types';

import { gql } from '@apollo/client';
export type EmployeeDetailsV2Fragment = (
  { readonly __typename?: 'EmployeeV2' }
  & Pick<TP.EmployeeV2, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
  & { readonly messagingUserIdentities?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'MessagingV2' }
    & Pick<TP.MessagingV2, 'employeeId' | 'serverId' | 'type' | 'userId'>
  )>>, readonly employeeRole?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name'>
  )>, readonly employeeGroup?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name'>
  )>, readonly employeeOffice?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name'>
  )>, readonly division?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name'>
  )>, readonly manager?: TP.Maybe<(
    { readonly __typename?: 'EmployeeV2' }
    & Pick<TP.EmployeeV2, 'id' | 'fullName'>
  )> }
);

export type EmployeeDetailsEmployeeTreeV2Fragment = (
  { readonly __typename?: 'EmployeeV2' }
  & Pick<TP.EmployeeV2, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
  & { readonly children?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'EmployeeV2' }
    & Pick<TP.EmployeeV2, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
    & { readonly children?: TP.Maybe<ReadonlyArray<(
      { readonly __typename?: 'EmployeeV2' }
      & Pick<TP.EmployeeV2, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
      & { readonly children?: TP.Maybe<ReadonlyArray<(
        { readonly __typename?: 'EmployeeV2' }
        & Pick<TP.EmployeeV2, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
        & { readonly messagingUserIdentities?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'MessagingV2' }
          & Pick<TP.MessagingV2, 'employeeId' | 'serverId' | 'type' | 'userId'>
        )>>, readonly employeeRole?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly employeeGroup?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly employeeOffice?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly division?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly manager?: TP.Maybe<(
          { readonly __typename?: 'EmployeeV2' }
          & Pick<TP.EmployeeV2, 'id' | 'fullName'>
        )> }
      )>>, readonly messagingUserIdentities?: TP.Maybe<ReadonlyArray<(
        { readonly __typename?: 'MessagingV2' }
        & Pick<TP.MessagingV2, 'employeeId' | 'serverId' | 'type' | 'userId'>
      )>>, readonly employeeRole?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly employeeGroup?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly employeeOffice?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly division?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly manager?: TP.Maybe<(
        { readonly __typename?: 'EmployeeV2' }
        & Pick<TP.EmployeeV2, 'id' | 'fullName'>
      )> }
    )>>, readonly messagingUserIdentities?: TP.Maybe<ReadonlyArray<(
      { readonly __typename?: 'MessagingV2' }
      & Pick<TP.MessagingV2, 'employeeId' | 'serverId' | 'type' | 'userId'>
    )>>, readonly employeeRole?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly employeeGroup?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly employeeOffice?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly division?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly manager?: TP.Maybe<(
      { readonly __typename?: 'EmployeeV2' }
      & Pick<TP.EmployeeV2, 'id' | 'fullName'>
    )> }
  )>>, readonly messagingUserIdentities?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'MessagingV2' }
    & Pick<TP.MessagingV2, 'employeeId' | 'serverId' | 'type' | 'userId'>
  )>>, readonly employeeRole?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name'>
  )>, readonly employeeGroup?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name'>
  )>, readonly employeeOffice?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name'>
  )>, readonly division?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name'>
  )>, readonly manager?: TP.Maybe<(
    { readonly __typename?: 'EmployeeV2' }
    & Pick<TP.EmployeeV2, 'id' | 'fullName'>
  )> }
);

export const EmployeeDetailsV2FragmentDoc = gql`
    fragment EmployeeDetailsV2 on EmployeeV2 {
  id
  fullName
  title
  email
  userId
  phoneNumber
  employeeDepartmentId
  messagingUserIdentities @include(if: $useViewSlackEmployeeTreeFlag) {
    employeeId
    serverId
    type
    userId
  }
  employeeRole {
    id
    name
  }
  employeeGroup {
    id
    name
  }
  employeeOffice {
    id
    name
  }
  division {
    id
    name
  }
  manager {
    id
    fullName
  }
}
    `;
export const EmployeeDetailsEmployeeTreeV2FragmentDoc = gql`
    fragment EmployeeDetailsEmployeeTreeV2 on EmployeeV2 {
  ...EmployeeDetailsV2
  children {
    ...EmployeeDetailsV2
    children {
      ...EmployeeDetailsV2
      children {
        ...EmployeeDetailsV2
      }
    }
  }
}
    ${EmployeeDetailsV2FragmentDoc}`;