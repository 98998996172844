import * as TP from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchCustomersBasicQueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.CustomersFilter>;
  first?: TP.InputMaybe<TP.Scalars['Int']>;
  last?: TP.InputMaybe<TP.Scalars['Int']>;
  before?: TP.InputMaybe<TP.Scalars['String']>;
  after?: TP.InputMaybe<TP.Scalars['String']>;
}>;


export type SearchCustomersBasicQuery = (
  { readonly __typename?: 'Query' }
  & { readonly allCustomers: (
    { readonly __typename?: 'CustomerConnection' }
    & { readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & Pick<TP.PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), readonly edges: ReadonlyArray<(
      { readonly __typename?: 'CustomerEdge' }
      & Pick<TP.CustomerEdge, 'cursor'>
      & { readonly node: (
        { readonly __typename?: 'Customer' }
        & Pick<TP.Customer, 'id' | 'name' | 'code'>
      ) }
    )> }
  ) }
);


export const SearchCustomersBasicDocument = gql`
    query searchCustomersBasic($filter: CustomersFilter, $first: Int, $last: Int, $before: String, $after: String) {
  allCustomers(
    filter: $filter
    first: $first
    last: $last
    before: $before
    after: $after
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        name
        code
      }
    }
  }
}
    `;

/**
 * __useSearchCustomersBasicQuery__
 *
 * To run a query within a React component, call `useSearchCustomersBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCustomersBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCustomersBasicQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSearchCustomersBasicQuery(baseOptions?: Apollo.QueryHookOptions<SearchCustomersBasicQuery, SearchCustomersBasicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCustomersBasicQuery, SearchCustomersBasicQueryVariables>(SearchCustomersBasicDocument, options);
      }
export function useSearchCustomersBasicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCustomersBasicQuery, SearchCustomersBasicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCustomersBasicQuery, SearchCustomersBasicQueryVariables>(SearchCustomersBasicDocument, options);
        }
export type SearchCustomersBasicQueryHookResult = ReturnType<typeof useSearchCustomersBasicQuery>;
export type SearchCustomersBasicLazyQueryHookResult = ReturnType<typeof useSearchCustomersBasicLazyQuery>;
export type SearchCustomersBasicQueryResult = Apollo.QueryResult<SearchCustomersBasicQuery, SearchCustomersBasicQueryVariables>;