import { AutoComplete } from '@components/AutoComplete';
import { useSearchCustomersBasicLazyQuery } from '@generated/queries/searchCustomersBasic';
import { useSearchCustomersBasicV2LazyQuery } from '@generated/queries/searchCustomersBasicV2';
import { Customer, CustomerV2 } from '@generated/types';
import { useCustomerV2Flag } from '@hooks/useCustomerV2Flag';
import { useDebouncedFn } from '@hooks/useDebouncedFn';
import { searchPhoneNumberMatch } from '@utils/searchPhoneNumberMatch';
import { Component, FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SIDEBAR_SEARCH_STYLE } from '../style';

type CustomerSearchResult = Pick<Customer | CustomerV2, 'id' | 'code' | 'name'>;

const CustomerSearchBase: FC<RouteComponentProps> = ({ history: { push } }) => {
  const useCustomerV2 = useCustomerV2Flag();

  const [searchCustomersV1, { loading: loadingV1, data: dataV1 }] =
    useSearchCustomersBasicLazyQuery();
  const [searchCustomersV2, { loading: loadingV2, data: dataV2 }] =
    useSearchCustomersBasicV2LazyQuery();

  const debouncedSearchCustomers = useDebouncedFn(
    useCustomerV2 ? searchCustomersV2 : searchCustomersV1,
    500,
    []
  );

  const customers =
    (useCustomerV2
      ? dataV2?.allCustomersV2?.edges
      : dataV1?.allCustomers?.edges) ?? [];
  const items = customers.map(({ node }) => ({
    value: node,
    label: node.name,
    id: node.id,
  }));

  return (
    <AutoComplete<CustomerSearchResult>
      onInputValueChange={(inputValue): void => {
        debouncedSearchCustomers({
          variables: {
            filter: {
              text: searchPhoneNumberMatch(inputValue),
            },
            first: 15,
          },
        });
      }}
      onChange={(item, helpers): void => {
        if (item) {
          helpers?.clearSelection();
          helpers?.closeMenu();
          push(`/customers/${item.id}`);
        }
      }}
      items={items}
      loading={useCustomerV2 ? loadingV2 : loadingV1}
      renderDropdownInPopper
      css={{ height: '100%' }}
      showSearchIcon
      inputProps={{
        placeholder: 'Search',
        'data-testid': 'customer-search-input',
        css: SIDEBAR_SEARCH_STYLE,
      }}
    />
  );
};

export const CustomerSearch: typeof Component = withRouter(CustomerSearchBase);
