import { AuthStatus, useKeycloakAuthContext } from '@components/AuthContext';
import { useFlag } from '@components/Flag';
import { ToggleMenu } from '@components/ToggleMenu';
import { useEmployeeV2Flag } from '@hooks/useEmployeeV2Flag';
import { MenuItem } from '@reach/menu-button';
import { useKeycloak } from '@react-keycloak/web';
import { IS_NOT_PREVIEW_OR_PROD, SKIP_AUTH } from '@utils/constants';
import { assertNever } from '@utils/exhaustivenessCheck';
import { history } from '@utils/history';
import { win } from '@utils/win';
import { whoAmIDocument, whoAmIV2Document } from '@views/Common/Permissions';
import { compact } from 'lodash-es';
import { FC } from 'react';
import { authLogoutRedirectUri } from '../../config';

export const UserNav: FC = () => {
  const { status, user } = useKeycloakAuthContext();
  const { keycloak } = useKeycloak();
  const debugEnabled = useFlag('app-allow-debug');

  let userEl: string, authTxt: string;

  switch (status) {
    case AuthStatus.unitialized:
    case AuthStatus.loading:
      userEl = 'Loading...';
      break;
    case AuthStatus.authenticated:
      authTxt = '';
      if (SKIP_AUTH) {
        authTxt = 'Skip Auth';
      }
      userEl = compact([user?.firstName, user?.lastName]).join(' ') || '??';
      if (IS_NOT_PREVIEW_OR_PROD && authTxt) {
        userEl += ` (${authTxt})`;
      }
      break;
    case AuthStatus.errored:
      userEl = '';
      break;
    default:
      userEl = assertNever(status);
  }
  const useEmployeeV2 = useEmployeeV2Flag();
  const WhoAmIDoc = useEmployeeV2 ? whoAmIV2Document : whoAmIDocument;
  const onSelectFunction = (): void => {
    if (!SKIP_AUTH) {
      win.localStorage.removeItem(`swr-${WhoAmIDoc}`);
      win.localStorage.removeItem(`swr-err@${WhoAmIDoc}`);
      keycloak?.logout({
        redirectUri: authLogoutRedirectUri,
      }) ?? ((): void => alert('There was an error'));
    } else {
      win.location.href = authLogoutRedirectUri;
    }
  };

  return (
    <ToggleMenu text={userEl}>
      {debugEnabled && (
        <MenuItem onSelect={(): void => history.push('/__debug')}>
          Debug
        </MenuItem>
      )}
      {/*
        // TODO: we may want to re-enable this if the window manager is important to us
        {debugEnabled && (
          <MenuItem onSelect={(): void => history.push('/windows')}>
            Windows
          </MenuItem>
        )} */}
      <MenuItem onSelect={onSelectFunction}>Log Out</MenuItem>
    </ToggleMenu>
  );
};
