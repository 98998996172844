import * as TP from '../types';

import { gql } from '@apollo/client';
import { KeyValueInfoFragmentDoc } from '../fragments/keyValueInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FacilityMfItemAddressFragment = (
  { readonly __typename?: 'Address' }
  & Pick<TP.Address, 'id' | 'main' | 'city' | 'country' | 'postalCode' | 'state' | 'street1' | 'street2'>
  & { readonly geopoint: (
    { readonly __typename?: 'Geopoint' }
    & Pick<TP.Geopoint, 'lat' | 'lon'>
  ) }
);

export type FacilityMfItemFragment = (
  { readonly __typename?: 'Facility' }
  & Pick<TP.Facility, 'id' | 'name' | 'code' | 'phoneNumber'>
  & { readonly mainAddress?: TP.Maybe<(
    { readonly __typename?: 'Address' }
    & Pick<TP.Address, 'id' | 'main' | 'city' | 'country' | 'postalCode' | 'state' | 'street1' | 'street2'>
    & { readonly geopoint: (
      { readonly __typename?: 'Geopoint' }
      & Pick<TP.Geopoint, 'lat' | 'lon'>
    ) }
  )>, readonly facilityType?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )> }
);

export type FacilitiesForMasterfindQueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.FacilitiesFilter>;
  first?: TP.InputMaybe<TP.Scalars['Int']>;
  last?: TP.InputMaybe<TP.Scalars['Int']>;
  before?: TP.InputMaybe<TP.Scalars['String']>;
  after?: TP.InputMaybe<TP.Scalars['String']>;
}>;


export type FacilitiesForMasterfindQuery = (
  { readonly __typename?: 'Query' }
  & { readonly allFacilities: (
    { readonly __typename?: 'FacilityConnection' }
    & { readonly edges: ReadonlyArray<(
      { readonly __typename?: 'FacilityEdge' }
      & { readonly node: (
        { readonly __typename?: 'Facility' }
        & Pick<TP.Facility, 'id' | 'name' | 'code' | 'phoneNumber'>
        & { readonly mainAddress?: TP.Maybe<(
          { readonly __typename?: 'Address' }
          & Pick<TP.Address, 'id' | 'main' | 'city' | 'country' | 'postalCode' | 'state' | 'street1' | 'street2'>
          & { readonly geopoint: (
            { readonly __typename?: 'Geopoint' }
            & Pick<TP.Geopoint, 'lat' | 'lon'>
          ) }
        )>, readonly facilityType?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )> }
      ) }
    )> }
  ) }
);

export const FacilityMfItemAddressFragmentDoc = gql`
    fragment FacilityMFItemAddress on Address {
  id
  main
  city
  country
  postalCode
  state
  street1
  street2
  geopoint {
    lat
    lon
  }
}
    `;
export const FacilityMfItemFragmentDoc = gql`
    fragment FacilityMFItem on Facility {
  id
  name
  code
  phoneNumber
  mainAddress {
    ...FacilityMFItemAddress
  }
  facilityType {
    ...KeyValueInfo
  }
}
    ${FacilityMfItemAddressFragmentDoc}
${KeyValueInfoFragmentDoc}`;
export const FacilitiesForMasterfindDocument = gql`
    query facilitiesForMasterfind($filter: FacilitiesFilter, $first: Int, $last: Int, $before: String, $after: String) {
  allFacilities(
    filter: $filter
    first: $first
    last: $last
    before: $before
    after: $after
  ) {
    edges {
      node {
        ...FacilityMFItem
      }
    }
  }
}
    ${FacilityMfItemFragmentDoc}`;

/**
 * __useFacilitiesForMasterfindQuery__
 *
 * To run a query within a React component, call `useFacilitiesForMasterfindQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilitiesForMasterfindQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilitiesForMasterfindQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFacilitiesForMasterfindQuery(baseOptions?: Apollo.QueryHookOptions<FacilitiesForMasterfindQuery, FacilitiesForMasterfindQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FacilitiesForMasterfindQuery, FacilitiesForMasterfindQueryVariables>(FacilitiesForMasterfindDocument, options);
      }
export function useFacilitiesForMasterfindLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FacilitiesForMasterfindQuery, FacilitiesForMasterfindQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FacilitiesForMasterfindQuery, FacilitiesForMasterfindQueryVariables>(FacilitiesForMasterfindDocument, options);
        }
export type FacilitiesForMasterfindQueryHookResult = ReturnType<typeof useFacilitiesForMasterfindQuery>;
export type FacilitiesForMasterfindLazyQueryHookResult = ReturnType<typeof useFacilitiesForMasterfindLazyQuery>;
export type FacilitiesForMasterfindQueryResult = Apollo.QueryResult<FacilitiesForMasterfindQuery, FacilitiesForMasterfindQueryVariables>;