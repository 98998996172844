import { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { App } from './app';
import { EmailTenderingApp } from './app/EmailTendering';
import { initLD } from './app/initLaunchDarkly';
import { SpaceSelection } from './app/SpaceSelection';
import { CHOSEN_ELECTRON_SPACE, IS_ELECTRON } from './utils/constants';
import { mark } from './utils/perf';
import { init as initSentry } from './utils/sentry';
import { win } from './utils/win';

mark('src/index/init');

// This is to make fragment rendering work...jsxFactory issues with tsconfig
win.React = {
  Fragment,
};

initSentry();

const rootEl = document.getElementById('root');

const isEmailTendering = win.location.pathname === '/email-tender';

if (IS_ELECTRON && !CHOSEN_ELECTRON_SPACE) {
  /* istanbul ignore next */
  ReactDOM.render(<SpaceSelection />, rootEl);
} else if (isEmailTendering) {
  ReactDOM.render(<EmailTenderingApp />, rootEl);
} else {
  (async (): Promise<void> => {
    const LDProvider = await initLD();
    mark('src/index/render');
    ReactDOM.render(
      <LDProvider>
        <App />
      </LDProvider>,
      rootEl
    );
  })();
}
