import * as TP from '../types';

import { gql } from '@apollo/client';
import { CarrierInfoSidebarFragmentDoc } from '../fragments/carrierInfoSidebar';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchCarriersCarrierInfoSidebarQueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.CarriersFilter>;
  first?: TP.InputMaybe<TP.Scalars['Int']>;
  last?: TP.InputMaybe<TP.Scalars['Int']>;
  after?: TP.InputMaybe<TP.Scalars['String']>;
  before?: TP.InputMaybe<TP.Scalars['String']>;
}>;


export type SearchCarriersCarrierInfoSidebarQuery = (
  { readonly __typename?: 'Query' }
  & { readonly carriersNullable?: TP.Maybe<(
    { readonly __typename?: 'CarrierConnection' }
    & { readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & Pick<TP.PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), readonly edges: ReadonlyArray<(
      { readonly __typename?: 'CarrierEdge' }
      & Pick<TP.CarrierEdge, 'cursor'>
      & { readonly node: (
        { readonly __typename?: 'Carrier' }
        & Pick<TP.Carrier, 'id' | 'name' | 'code'>
        & { readonly mainAddress?: TP.Maybe<(
          { readonly __typename?: 'Address' }
          & Pick<TP.Address, 'id' | 'city' | 'state'>
        )> }
      ) }
    )> }
  )> }
);


export const SearchCarriersCarrierInfoSidebarDocument = gql`
    query searchCarriersCarrierInfoSidebar($filter: CarriersFilter, $first: Int, $last: Int, $after: String, $before: String) {
  carriersNullable(
    filter: $filter
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...CarrierInfoSidebar
      }
    }
  }
}
    ${CarrierInfoSidebarFragmentDoc}`;

/**
 * __useSearchCarriersCarrierInfoSidebarQuery__
 *
 * To run a query within a React component, call `useSearchCarriersCarrierInfoSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCarriersCarrierInfoSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCarriersCarrierInfoSidebarQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useSearchCarriersCarrierInfoSidebarQuery(baseOptions?: Apollo.QueryHookOptions<SearchCarriersCarrierInfoSidebarQuery, SearchCarriersCarrierInfoSidebarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCarriersCarrierInfoSidebarQuery, SearchCarriersCarrierInfoSidebarQueryVariables>(SearchCarriersCarrierInfoSidebarDocument, options);
      }
export function useSearchCarriersCarrierInfoSidebarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCarriersCarrierInfoSidebarQuery, SearchCarriersCarrierInfoSidebarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCarriersCarrierInfoSidebarQuery, SearchCarriersCarrierInfoSidebarQueryVariables>(SearchCarriersCarrierInfoSidebarDocument, options);
        }
export type SearchCarriersCarrierInfoSidebarQueryHookResult = ReturnType<typeof useSearchCarriersCarrierInfoSidebarQuery>;
export type SearchCarriersCarrierInfoSidebarLazyQueryHookResult = ReturnType<typeof useSearchCarriersCarrierInfoSidebarLazyQuery>;
export type SearchCarriersCarrierInfoSidebarQueryResult = Apollo.QueryResult<SearchCarriersCarrierInfoSidebarQuery, SearchCarriersCarrierInfoSidebarQueryVariables>;