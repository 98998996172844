/* eslint-disable no-restricted-globals */
import { ENV_VARS, MODE } from '@env';
import { Exact } from '@generated/types';
import { isString, mapValues } from 'lodash-es';
import {
  BUILD_VERSION,
  environment,
  IS_ELECTRON,
  IS_LOCAL_CYPRESS,
  IS_LOCAL_DEV,
  IS_LOCAL_DEV_NO_CY_NO_EMULATE,
  KnownDomains,
  KnownTenants,
  tenantDomainArr,
} from '../utils/constants';
import { sendSync } from '../utils/electron';
import { win } from '../utils/win';

interface Config {
  alchemyDataEndpoint: string;
  apiEndpoint: string;
  dataDictionaryEndpoint: string;
  uploadsApiEndpoint: string;
  launchDarklyKey: string;
  keycloakUri: string;
  keycloakRealm: string;
  keycloakClientId: string;
  restApiEndpoint: string;
  subscriptionApiEndpoint: string;
  emailTenderingEndpoint: string;
  caenLockingApiEndpoint: string;
  regionsDataEndpoint: string;
  ltlPricingMatrix: string;
  flatFileLicenseKey: string;
  flatFileApiEndPoint: string;
}

const {
  // this is only used for the validation script, yarn config:check
  ORIGIN: originFromEnv,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} = ENV_VARS;

let origin = originFromEnv || '';
if (typeof window !== 'undefined') {
  origin = window.location.origin;
}

export const authLogoutRedirectUri = origin;

const {
  VITE_GRAPHQL_URL: apiEndpoint = '',
  VITE_DATA_DICTIONARY_URL:
    dataDictionaryEndpoint = 'http://localhost:5000/minion',
  VITE_UPLOADS_URL: uploadsApiEndpoint = '',
  VITE_AUTH_KEYCLOAK_REALM: keycloakRealm = 'dev.mm100.mastermindtms.com',
  VITE_AUTH_KEYCLOAK_SERVER:
    keycloakUri = 'https://id.mm100nonprod.mastermindtms.com/auth',
  VITE_AUTH_KEYCLOAK_CLIENTID:
    keycloakClientId = 'https://dev.mm100.mastermindtms.com/',
  VITE_REST_URL: restApiEndpoint = '',
  VITE_SUBSCRIPTION_ENDPOINT:
    subscriptionApiEndpoint = 'ws://localhost:5000/graphql',
  VITE_EMAIL_TENDERING_ENDPOINT:
    emailTenderingEndpoint = 'https://tendering-email-api.dev.mm100.mastermindtms.com',
  VITE_AlCHEMY_DATA_ENDPOINT:
    alchemyDataEndpoint = 'https://alchemy-data-api.dev.mm100.mastermindtms.com',
  VITE_CAEN_LOCKING_ENDPOINT:
    caenLockingApiEndpoint = 'https://caenlocking-api.dev.mm100.mastermindtms.com',
  VITE_REGIONS_DATA_ENDPOINT:
    regionsDataEndpoint = `https://regions-v2-api.dev.mm100.mastermindtms.com`,
  VITE_FLAT_FILE_LICENSE_KEY: flatFileLicenseKey = '',
  VITE_FLAT_FILE_API_END_POINT:
    flatFileApiEndPoint = `https://flatfile.dev.mm100.mastermindtms.com`,
  VITE_LTL_PRICING_MATRIX_ENDPOINT:
    ltlPricingMatrix = `https://ltl-pricing-matrix.dev.mm100.mastermindtms.com`,
} = ENV_VARS;

export const sentryDsn =
  'https://5a8f866f49f74656a8e011942fc72ab7@o291138.ingest.sentry.io/1723665';
export const mapboxBaseStyle =
  'mapbox://styles/mastery-logistics/ck5o7qc0w01uz1ijy5qwsjgku';

export const launchDarklyKeyMap = {
  production: '5dcc30f129064b08eed09415',
  development: '5dcc30f129064b08eed09414',
  staging: '5dcc3f77af9e34086c559510',
  preview: '5dcd725170202d0901737f9d',
};

let localConfig: Config = {
  apiEndpoint,
  dataDictionaryEndpoint,
  keycloakClientId,
  keycloakRealm,
  keycloakUri,
  launchDarklyKey: launchDarklyKeyMap.development,
  restApiEndpoint,
  subscriptionApiEndpoint,
  alchemyDataEndpoint,
  uploadsApiEndpoint,
  emailTenderingEndpoint,
  caenLockingApiEndpoint,
  regionsDataEndpoint,
  ltlPricingMatrix,
  flatFileLicenseKey,
  flatFileApiEndPoint,
};

// Always use local Node API when running tests in cypress
// There is no need to use internal dev or the value of VITE_EMULATE_DOMAIN for cy.
if (IS_LOCAL_CYPRESS) {
  localConfig = mapValues(localConfig, (val) => {
    if (isString(val) && val.match('//')) {
      try {
        const uu = new URL(val);
        uu.host = 'localhost:5000';
        if (uu.protocol === 'https:') {
          uu.protocol = 'http:';
        }
        let str = uu.href;
        if (!val.match(/\/$/) && uu.pathname === '/') {
          str = str.replace(/\/$/, '');
        }
        return str;
      } catch {
        //
      }
    }
    return val;
  });
}

export const configAliases: Partial<Record<KnownDomains, KnownDomains[]>> = {
  'ml100.mastermindtms.com': ['molo.mastermindtms.com', 'mm.shipmolo.com'],
  'test.ml100.mastermindtms.com': [
    'sandbox.shipmolo.com',
    'upsidedown.shipmolo.com',
  ],
  'dev.mm100.mastermindtms.com': [
    'mastery-frontend.dev.mm100.mastermindtms.com',
  ],
};

const useV2Paradigms: KnownDomains[] = [
  'test.loadsmith.mastermindtms.com',
  'test.averitt.mastermindtms.com',
];

export const getConfigFromUrl = (hostname: string): Config | undefined => {
  let base = typeof hostname === 'string' ? hostname : '';
  const foundFromResolutions = Object.entries(configAliases).find(
    ([, testArr]) => testArr.includes(base as KnownDomains)
  )?.[0];
  if (foundFromResolutions) {
    base = foundFromResolutions;
  }
  let useV2 = false;
  if (useV2Paradigms.includes(hostname as KnownDomains)) {
    base = hostname;
    useV2 = true;
  }
  if (base === 'localhost') {
    // If we know we are in local, on a regular browser (not cy), AND that the contributor does not want to emulate an env, we use internal dev config.
    if (IS_LOCAL_DEV_NO_CY_NO_EMULATE) {
      return getConfigFromUrl('dev.mm100.mastermindtms.com');
    }
    return localConfig;
  } else if (base.match('mastery-frontend.pages.dev')) {
    return getConfigFromUrl('dev.mm100.mastermindtms.com');
  } else if (!base.match(/mastermindtms\.com/)) {
    return undefined;
  }
  let tenantId = base
    .replace(/dev\./, '')
    .replace(/test\./, '')
    .replace(/\.mastermindtms\.com/, '');
  if (useV2) {
    tenantId = hostname;
  }
  const isDev = Boolean(base.match(/^dev\./));
  const isTest = Boolean(base.match(/^test\./));
  const isPreview = isDev || isTest;
  let launchDarklyKey = launchDarklyKeyMap.production;
  if (isDev && tenantId === 'mm100') {
    launchDarklyKey = launchDarklyKeyMap.staging;
  } else if (isPreview) {
    launchDarklyKey = launchDarklyKeyMap.preview;
  }

  const flatFileLicenseKey =
    FlatFileLicenseKeys[tenantId as KnownTenants] || '';

  let keycloakUri = `https://id.${tenantId}${
    isPreview ? 'non' : ''
  }prod.mastermindtms.com/auth`;

  if (useV2) {
    const kcBase = base
      .replace(/dev\./, '')
      .replace(/test\./, '')
      .replace(/\.mastermindtms\.com/, '');
    keycloakUri = `https://id.${kcBase}-${
      isPreview ? 'non' : ''
    }prod.mastermindtms.com/auth`;
  }

  const obj = {
    launchDarklyKey,
    apiEndpoint: `https://graphql.${base}/`,
    dataDictionaryEndpoint: `https://minion-api.${base}/graphql`,
    subscriptionApiEndpoint: `wss://subscriptions.${base}/graphql`,
    uploadsApiEndpoint: `https://uploads.${base}`,
    restApiEndpoint: `https://rest.${base}`,
    keycloakUri,
    keycloakClientId: `https://${base}/`,
    keycloakRealm: base,
    caenLockingApiEndpoint: `https://caenlocking-api.${base}`,
    emailTenderingEndpoint: `https://tendering-email-api.${base}`,
    alchemyDataEndpoint: `https://alchemy-data-api.${base}`,
    regionsDataEndpoint: `https://regions-v2-api.${base}`,
    flatFileLicenseKey,
    flatFileApiEndPoint: `https://flatfile.${base}`,
    ltlPricingMatrix: `https://ltl-pricing-matrix.${base}`,
  };
  if (tenantId === 'sn100' && isPreview) {
    obj.keycloakUri = 'https://id.clientnonprod.mastermindtms.com/auth';
  }
  return obj;
};

// TODO: FlatFile license keys for all tenants
const FlatFileLicenseKeys: Exact<{ [key in KnownTenants]: string }> = {
  mm100: '70cbe3fa-b6ba-4943-be91-c2b93060c496',
  sn100: '90c6e4fe-8f73-4957-b5cf-9e4a86a232f3',
  ml100: '14499380-d8b8-43b8-9108-df4623a59cde',
  tb100: 'afcdf284-6790-4bb2-9ff5-6ad71e99b8de',
  we100: '9a3b33e2-6d7b-4c6c-82cc-79fe7af5cad8',
  ru100: '2e6c4dcc-429a-4413-ba15-578b2fcd562c',
  td100: '70cbe3fa-b6ba-4943-be91-c2b93060c496',
  tm100: 'e030017c-593f-49ae-8500-900f29d38255',
  pr100: '21ee849d-6b8d-48d0-b036-f89eb7b60799',
  ls8fm: '9275091f-b12c-42ad-abda-87a519c228ca',
  ave4u: '1c8e377d-83c8-41ad-9663-567ce868e9e8',
};

/* istanbul ignore next */
export const getKnownDomainFromInput = (
  rawStr: string | undefined
): string | undefined => {
  if (!rawStr) {
    return;
  }
  let str = rawStr;
  try {
    str = new URL(str).hostname;
  } catch {
    // noop
  }
  if (IS_LOCAL_DEV && str === 'localhost') {
    return str;
  }
  return tenantDomainArr.find((d) => d === str) ?? str;
};

const { hostname } = win.location;

let ELECTRON_DOMAIN: undefined | string = sendSync('store.get', 'space');
if ((ELECTRON_DOMAIN || '').match('localhost')) {
  ELECTRON_DOMAIN = undefined;
}
// This only happens when there is no space selected, and the user is presented with selection screen. We are only satisfying the rawConfig requirement, so it does not throw error.
if (IS_ELECTRON && !ELECTRON_DOMAIN && hostname === '-') {
  ELECTRON_DOMAIN = 'dev.mm100.mastermindtms.com';
}

let RESOLVED_TENANT_HOSTNAME_VAL = ELECTRON_DOMAIN || hostname || '';
if (environment === 'development' && ENV_VARS.VITE_EMULATE_DOMAIN) {
  RESOLVED_TENANT_HOSTNAME_VAL = ENV_VARS.VITE_EMULATE_DOMAIN;
}

if (
  MODE === 'test' ||
  (ENV_VARS as fixMe).START_DEV ||
  typeof window === 'undefined'
) {
  RESOLVED_TENANT_HOSTNAME_VAL = 'localhost';
}
const IS_MERCATOR = MODE === 'development' && ENV_VARS.BASE_URL === '/lib/';

if (IS_MERCATOR) {
  RESOLVED_TENANT_HOSTNAME_VAL = 'localhost';
}
export const RESOLVED_TENANT_HOSTNAME = RESOLVED_TENANT_HOSTNAME_VAL;

const rawConfig: Config | undefined = getConfigFromUrl(
  RESOLVED_TENANT_HOSTNAME
);

export const BASE_TENANT_HOSTNAME = rawConfig?.keycloakRealm;

if (!rawConfig) {
  throw new Error(
    `No config found for ${environment} with domain ${RESOLVED_TENANT_HOSTNAME}`
  );
}

export const config: Config = rawConfig;

try {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ((window || {}) as any).masteryConfig = { ...config, BUILD_VERSION };
} catch {
  // noop
}

if (IS_ELECTRON && !config && sendSync('store.get', 'space')) {
  sendSync('store.delete', 'space');
  win.location.reload();
}
