import { FetchPolicy } from '@apollo/client';
import { LinkRoutePath, MasterfindRoute } from '@app/routes';
import { getAddressDisplayString } from '@components/AddressDisplay';
import { AutoComplete, Shell } from '@components/AutoComplete';
import { getDatetimeValue } from '@components/DatetimeValue';
import { useFlag } from '@components/Flag';
import { Grid } from '@components/Grid';
import { Icon, IconProp } from '@components/Icon';
import { DEFAULT_LOADING_BAR_HEIGHT, LoadingBar } from '@components/LoadingBar';
import { Logo } from '@components/Logo';
import { NewWindowLinkProps, openNewWindow } from '@components/NewWindowLink';
import { Padding } from '@components/Padding';
import { usePermissionsContext } from '@components/PermissionsContext';
import { useShortcut } from '@components/Shortcut';
import { Theme } from '@emotion/react';
import { SeerLoadMasterfindFragment } from '@generated/fragments/seerLoadMasterfind';
import {
  CarriersForMasterfindDocument,
  CarriersForMasterfindQuery,
  CarriersForMasterfindQueryVariables,
} from '@generated/queries/carriersForMasterfind';
import {
  CarriersForMasterfindV2Document,
  CarriersForMasterfindV2Query,
  CarriersForMasterfindV2QueryVariables,
} from '@generated/queries/carriersForMasterfindV2';
import {
  LoadsForMasterfindV2Document,
  LoadsForMasterfindV2Query,
  LoadsForMasterfindV2QueryVariables,
} from '@generated/queries/loadsForMasterfindV2';
import {
  LoadsForMasterfindV3Document,
  LoadsForMasterfindV3Query,
  LoadsForMasterfindV3QueryVariables,
  MasterfindLoadV3Fragment,
} from '@generated/queries/loadsForMasterfindV3';
import { SeerGenericOrder, SeerGenericStringOperator } from '@generated/types';
import { useCarrierV2Flag } from '@hooks/useCarrierV2Flag';
import { useCustomerV2Flag } from '@hooks/useCustomerV2Flag';
import { useDebouncedFn } from '@hooks/useDebouncedFn';
import { useLazyQueryWithDataPromise } from '@hooks/useLazyQueryWithDataPromise';
import { useTheme } from '@hooks/useTheme';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { FS_UNMASK } from '@utils/fullstory';
import { history } from '@utils/history';
import { pipeSeparator } from '@utils/htmlEntities';
import { filterByPromiseFulfilled } from '@utils/promise';
import { win } from '@utils/win';
import { MODAL_BACKGROUND } from '@utils/zIndex';
import { CarrierPickerItemDisplay } from '@views/Common/CarrierPicker';
import {
  CustomerPickerItemDisplay,
  useCustomerSearch,
} from '@views/Common/CustomerPicker';
import {
  FacilityPickerItemDisplay,
  useFacilityCombinedSearch,
} from '@views/Common/FacilityPicker';
import {
  getResolvedPermissionValues,
  useHasPermission,
} from '@views/Common/Permissions';
import { PermissionsScopeNames } from '@views/Common/Permissions/constants';
import { useMachine } from '@xstate/react';
import Downshift from 'downshift';
import fuzzy from 'fuzzy';
import {
  compact,
  flattenDeep,
  noop,
  reject,
  sortBy,
  toPairs,
  uniqBy,
  uniqueId,
} from 'lodash-es';
import Cancelable from 'p-cancelable';
import {
  createContext,
  FC,
  Fragment,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { usePrevious } from 'react-use';
import { SetOptional } from 'type-fest';
import { Machine } from 'xstate';
import { WALKME_TOGGLE_BUTTON_ID } from '../layout/Header';

const stateTypes = Downshift.stateChangeTypes;

interface MasterfindContextType {
  actions: ActionDef[];
  addAction: (action: ActionDef) => void;
  removeAction: (action: Partial<ActionDef>) => void;
}

const defaultCtx: MasterfindContextType = {
  actions: [],
  addAction: noop,
  removeAction: noop,
};

const MasterfindContext = createContext<MasterfindContextType>(defaultCtx);

export const MasterfindProvider: FC = ({ children }) => {
  const [actions, setActions] = useState<ActionDef[]>(appWideActions);

  const addAction = useCallback((newAction: ActionDef): void => {
    setActions((prevActions): ActionDef[] => {
      return uniqBy([newAction].concat(prevActions), (obj) => obj.id);
    });
  }, []);

  const removeAction = useCallback(
    (actionToRemove: Partial<ActionDef>): void => {
      setActions((prevActions): ActionDef[] => {
        return reject(prevActions, (obj) => obj.id === actionToRemove.id);
      });
    },
    []
  );

  const value = useMemo(
    () => ({ actions, addAction, removeAction }),
    [actions, addAction, removeAction]
  );

  return (
    <MasterfindContext.Provider value={value}>
      {children}
    </MasterfindContext.Provider>
  );
};

/** Add an action to the masterfind actions list. The action is added on host component mount, and automatically removed on host component unmount. You can conditionally enable the action by using the hidden or visible option and passing any boolean. You can call this hook multiple times to add multiple actions. The actions is prepended to the list. So hook call #2 will be first in the list, #1 will be second. */
export const useMasterfindAction = (
  generateActionCallback: () => ActionDef,
  deps: anyOk[]
): void => {
  const { addAction, removeAction } = useContext(MasterfindContext);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const action = useMemo(() => generateActionCallback(), deps);
  useEffect(() => {
    addAction(action);
    return (): void => {
      removeAction(action);
    };
  }, [action, addAction, removeAction]);
};

interface MFStateSchema {
  states: {
    inactive: Record<string, unknown>;
    active: Record<string, unknown>;
    loading: Record<string, unknown>;
  };
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type MFEvent =
  | { type: 'TOGGLE' }
  | { type: 'CLOSE' }
  | { type: 'INPUT_CHANGE' }
  | { type: 'LOAD_COMPLETE' }
  | { type: 'LOAD_START' }
  | { type: 'RESET' };

interface MFContext {
  elapsed: number;
}

const mfMachine = Machine<MFContext, MFStateSchema, MFEvent>({
  id: 'masterfind',
  initial: win.location.search.includes('masterfind=true')
    ? 'active'
    : 'inactive',
  states: {
    inactive: {
      on: { TOGGLE: 'active.default' },
    },
    active: {
      initial: 'default' as fixMe,
      on: {
        TOGGLE: 'inactive',
        CLOSE: 'inactive',
        RESET: 'active.default',
        INPUT_CHANGE: 'active.hasInput',
      },
      states: {
        default: {},
        hasInput: {
          on: {
            LOAD_START: '#loading.stage0',
          },
        },
        loaded: {},
        error: {},
      },
    },
    loading: {
      id: 'loading',
      states: {
        stage0: {
          after: {
            500: 'stage1',
          },
        },
        stage1: {
          after: {
            5000: 'stage2',
          },
        },
        stage2: {
          after: {
            10000: 'error',
          },
        },
        error: {},
      },
      on: {
        INPUT_CHANGE: 'active.hasInput',
        LOAD_COMPLETE: 'active.loaded',
        CLOSE: 'inactive',
        RESET: 'active.default',
      },
    },
  },
});

const FONT_SIZE = 20;

interface ActionDef {
  name?: string;
  linkProps?: NewWindowLinkProps;
  action?: () => void;
  icon?: IconProp;
  id: string;
  hidden?: boolean;
  /** The string that Masterfind will use fuzzy searching on. Typically the same as the name/label but can be different */
  searchVector: string;
  visible?: boolean;
  disabled?: Shell<unknown>['disabled'];
  label?: (kwargs: { item: ActionDef; theme: Theme }) => ReactNode;
  /** Check keycloak permissions to show the action, the user must have all permissions in the array. */
  permissions?: PermissionsScopeNames[];
}

export const getActionId = (id: string): string => id;

const defaultActionsTitle: ActionDef = {
  name: 'Actions',
  disabled: true,
  searchVector: 'actions',
  id: getActionId('app-actions-title'),
};

const getLinkProps = (url: LinkRoutePath): NewWindowLinkProps => {
  return {
    to: url,
    header: true,
    sidebar: true,
  };
};

const routeActionMap: Record<
  MasterfindRoute,
  Omit<SetOptional<ActionDef, 'id'>, 'disabled'>
> = {
  '/': {
    name: 'Home',
    linkProps: getLinkProps('/'),
    icon: 'arrowRightSolid',
    searchVector: 'Home',
  },
  '/loads/create': {
    name: 'Loads - Add New',
    action: (): void => {
      openNewWindow({
        to: `/loads/create?row=0&section=order`,
      });
    },
    icon: 'plus',
    searchVector: 'Add New - Loads - Add New',
    permissions: ['load:create'],
  },
  '/loads': {
    name: 'Loads - Search',
    linkProps: getLinkProps('/loads'),
    icon: 'search',
    searchVector: 'Search - Loads - Search',
    permissions: ['load:view'],
  },
  '/load-repeat': {
    name: 'Loads - Repeat',
    linkProps: getLinkProps('/load-repeat'),
    icon: 'arrowRightSolid',
    searchVector: 'Loads - Repeat - Loads',
    permissions: ['load:repeat'],
  },
  '/track': {
    name: 'Loads - Track',
    linkProps: getLinkProps('/track'),
    icon: 'arrowRightSolid',
    searchVector: 'Loads - Track - Loads',
    permissions: ['load:track:view'],
  },
  '/map': {
    name: 'Book - State Map',
    linkProps: getLinkProps('/map'),
    icon: 'arrowRightSolid',
    searchVector: 'Book - State Map - Book',
    permissions: ['book:stateMap:view'],
  },
  '/available-routes': {
    name: 'Book - Available Routes',
    linkProps: getLinkProps('/available-routes'),
    icon: 'arrowRightSolid',
    searchVector: 'Book - Available Routes - Book',
    permissions: ['book:availableLoads:view'],
  },
  '/matches': {
    name: 'Book - Matches',
    linkProps: getLinkProps('/matches'),
    icon: 'arrowRightSolid',
    searchVector: 'Book - Matches - Book',
    permissions: ['book:myMatches:view'],
  },
  '/offers': {
    name: 'Book - Offers',
    linkProps: getLinkProps('/offers'),
    icon: 'arrowRightSolid',
    searchVector: 'Book - Offers - Book',
    permissions: ['offer:view'],
  },
  '/capacity/search': {
    name: 'Book - Capacity Search',
    linkProps: getLinkProps('/capacity/search'),
    icon: 'arrowRightSolid',
    searchVector:
      'Book - Capacity Search - Book - Capacity - Truck - Gate Reservation - Container',
  },
  '/carriers/search': {
    name: 'Carrier - Search',
    linkProps: getLinkProps('/carriers/search'),
    icon: 'search',
    searchVector: 'Search - Carrier - Search',
    permissions: ['carrier:view'],
  },
  '/carriers/add': {
    name: 'Carrier - Add New',
    linkProps: getLinkProps('/carriers/add'),
    icon: 'plus',
    searchVector: 'Add New - Carrier - Add New',
    permissions: ['carrier:create'],
  },
  '/facilities/add': {
    name: 'Facility - Add New',
    linkProps: getLinkProps('/facilities/add'),
    icon: 'plus',
    searchVector: 'Add New - Facility - Add New',
    permissions: ['facility:create'],
  },
  '/facilities/search': {
    name: 'Facility - Search',
    linkProps: getLinkProps('/facilities/search'),
    icon: 'search',
    searchVector: 'Search - Facility - Search',
    permissions: ['facility:view'],
  },
  '/customers/add': {
    name: 'Customer - Add New',
    linkProps: getLinkProps('/customers/add'),
    icon: 'plus',
    searchVector: 'Add New - Customer - Add New',
    permissions: ['customer:create'],
  },
  '/customers/search': {
    name: 'Customer - Search',
    linkProps: getLinkProps('/customers/search'),
    icon: 'search',
    searchVector: 'Search - Customer - Search',
    permissions: ['customer:view'],
  },
  '/employees/add': {
    name: 'Employee - Add New',
    linkProps: getLinkProps('/employees/add'),
    icon: 'plus',
    searchVector: 'Add New - Employee - Add New',
    permissions: ['employee:create'],
  },
  '/employees/search': {
    name: 'Employee - Search',
    linkProps: getLinkProps('/employees/search'),
    icon: 'search',
    searchVector: 'Search - Employee - Search',
    permissions: ['employee:view'],
  },
  '/accounting/customer-settlement': {
    name: 'Accounting - Customer Settlement',
    linkProps: getLinkProps('/accounting/customer-settlement'),
    icon: 'arrowRightSolid',
    searchVector: 'Accounting Customer Settlement',
    permissions: ['accounting:customerSettlement:view'],
  },
  '/accounting/invoices': {
    name: 'Accounting - Invoices',
    linkProps: getLinkProps('/accounting/invoices'),
    icon: 'arrowRightSolid',
    searchVector: 'Accounting Invoices',
    permissions: ['accounting:invoices:view'],
  },
  '/accounting/vendor-settlement': {
    name: 'Accounting - Vendor Settlement',
    linkProps: getLinkProps('/accounting/vendor-settlement'),
    icon: 'arrowRightSolid',
    searchVector: 'Accounting Vendor Settlement',
    permissions: ['accounting:vendorSettlement:view'],
  },
  '/accounting/vouchers': {
    name: 'Accounting - Vouchers',
    linkProps: getLinkProps('/accounting/vouchers'),
    icon: 'arrowRightSolid',
    searchVector: 'Accounting Vouchers',
    permissions: ['accounting:vouchers:view'],
  },
  '/accounting/vendor-invoice': {
    name: 'Accounting - Vendor Invoice',
    linkProps: getLinkProps('/accounting/vendor-invoice'),
    icon: 'arrowRightSolid',
    searchVector: 'Accounting Vendor Invoice',
    permissions: ['accounting:vendorInvoice:view'],
  },
  '/trailers/add': {
    name: 'Trailer - Add New',
    linkProps: getLinkProps('/trailers/add'),
    icon: 'arrowRightSolid',
    searchVector: 'Add New - Trailer - Add New',
    permissions: ['trailer:create'],
  },
  '/trailers/search': {
    name: 'Trailer - Search Trailer',
    linkProps: getLinkProps('/trailers/search'),
    icon: 'arrowRightSolid',
    searchVector: 'Search Trailer- Trailer - Search Trailer',
    permissions: ['trailer:create'],
  },
  '/lanes': {
    name: 'Lane',
    linkProps: getLinkProps('/lanes'),
    icon: 'arrowRightSolid',
    searchVector: 'Lane',
    permissions: ['customer:view'],
    hidden: true,
  },
  '/projects': {
    name: 'Project',
    linkProps: getLinkProps('/projects'),
    icon: 'arrowRightSolid',
    searchVector: 'Project',
    hidden: true,
  },
  '/patterns': {
    name: 'Pattern',
    linkProps: getLinkProps('/patterns'),
    icon: 'arrowRightSolid',
    searchVector: 'Pattern',
    permissions: ['customer:view'],
    hidden: true,
  },
  '/patterns/add': {
    name: 'Pattern - Add New',
    linkProps: getLinkProps('/patterns/add'),
    icon: 'arrowRightSolid',
    searchVector: 'Pattern Add New',
    permissions: ['customer:view'],
    hidden: true,
  },
  '/powers/add': {
    name: 'Power - Add New',
    linkProps: getLinkProps('/powers/add'),
    icon: 'arrowRightSolid',
    searchVector: 'Power Add New',
    permissions: ['power:view'],
  },
  '/powers/search': {
    name: 'Power - Search',
    linkProps: getLinkProps('/powers/search'),
    icon: 'search',
    searchVector: 'Search - Power - Search',
    permissions: ['power:view'],
  },
  '/drivers/search': {
    name: 'Driver - Search',
    linkProps: getLinkProps('/drivers/search'),
    icon: 'search',
    searchVector: 'Search - Driver - Search',
    permissions: ['driver:view'],
  },
  '/drivers/add': {
    name: 'Driver - Add New',
    linkProps: getLinkProps('/drivers/add'),
    icon: 'plus',
    searchVector: 'Add New - Driver - Add New',
    permissions: ['driver:create'],
  },
};

const routeActions: ActionDef[] = toPairs(routeActionMap).map(([key, val]) => ({
  ...val,
  id: val.id || key,
  searchVector: val.searchVector || val.name || val.id || '',
}));

const extraActions: ActionDef[] = [
  {
    name: 'Help',
    icon: 'question',
    id: getActionId('app-help'),
    action: (): void => {
      const btn = document.querySelector(`#${WALKME_TOGGLE_BUTTON_ID}`);
      if (btn instanceof HTMLElement) {
        btn.click();
      }
    },
    searchVector: 'Help',
  },
];

const combinedActions = routeActions.concat(extraActions);

const appWideActions: ActionDef[] = sortBy(combinedActions, (obj) =>
  obj.name === 'Help' ? 'zzzz' : obj.name
);

const FETCH_POLICY: FetchPolicy = 'network-only';

const useCarriersSearch = (): ((text: string) => Promise<ActionDef[]>) => {
  const canSearch = useHasPermission('carrier:view');
  const callQuery = useLazyQueryWithDataPromise<
    CarriersForMasterfindQuery,
    CarriersForMasterfindQueryVariables
  >(CarriersForMasterfindDocument);
  const runSearch = async (text: string): Promise<ActionDef[]> => {
    if (!canSearch) {
      return [];
    }
    const { data } = await callQuery({
      variables: { filter: { text }, first: 20 },
      fetchPolicy: FETCH_POLICY,
    });
    const rawRes =
      data.carriersNullable?.edges.map((edge): ActionDef => {
        const label = edge.node.name;
        return {
          name: label,
          label: (): ReactNode => {
            return <CarrierPickerItemDisplay carrier={edge.node} />;
          },
          searchVector: label,
          icon: 'truckMoving',
          linkProps: getLinkProps(`/carriers/${edge.node.id}/capacity-manager`),
          id: edge.node.id,
        };
      }) || [];
    const res = uniqBy(rawRes, (obj) => obj.id);
    return addSectionTitleToResults(res, {
      id: 'app-carriers-title',
      name: 'Carriers',
      disabled: true,
      searchVector: 'Carriers',
    });
  };
  return useCallback(runSearch, [callQuery, canSearch]);
};

const useCarriersSearchV2 = (): ((text: string) => Promise<ActionDef[]>) => {
  const canSearch = useHasPermission('carrier:view');
  const callQuery = useLazyQueryWithDataPromise<
    CarriersForMasterfindV2Query,
    CarriersForMasterfindV2QueryVariables
  >(CarriersForMasterfindV2Document);
  const runSearch = async (text: string): Promise<ActionDef[]> => {
    if (!canSearch) {
      return [];
    }
    const { data } = await callQuery({
      variables: { filter: { text }, first: 20 },
      fetchPolicy: FETCH_POLICY,
    });
    const rawRes =
      data.carriersNullableV2?.edges.map((edge): ActionDef => {
        const label = edge.node.name;
        return {
          name: label,
          label: (): ReactNode => {
            return <CarrierPickerItemDisplay carrier={edge.node} />;
          },
          searchVector: label,
          icon: 'truckMoving',
          linkProps: getLinkProps(`/carriers/${edge.node.id}/capacity-manager`),
          id: edge.node.id,
        };
      }) || [];
    const res = uniqBy(rawRes, (obj) => obj.id);
    return addSectionTitleToResults(res, {
      id: 'app-carriers-title',
      name: 'Carriers',
      disabled: true,
      searchVector: 'Carriers',
    });
  };
  return useCallback(runSearch, [callQuery, canSearch]);
};

const LoadV2LabelDisplay: FC<{
  node: SeerLoadMasterfindFragment | MasterfindLoadV3Fragment;
  filterVal: string;
}> = ({ node, filterVal }) => {
  let prefix = `Order #${node.orderNumber}`;
  const foundLoadCode = (node.loadCode || '').toString().includes(filterVal);
  if (foundLoadCode) {
    prefix = `Load #${node.loadCode}`;
  }
  const foundRouteCode = (node.routeCode || '').includes(filterVal);
  if (foundRouteCode) {
    prefix = `Route #${node.routeCode}`;
  }
  const foundRef = (node?.refNumber || '').includes(filterVal);
  if (foundRef) {
    const foundRefSingle = node.refNumber
      ?.split(',')
      .find((ref) => ref.includes(filterVal));
    prefix = `Ref #${foundRefSingle ?? node.refNumber}`;
  }
  const originAddress = getAddressDisplayString({
    value: { city: node.originCity || '', state: node.originState || '' },
    city: true,
    state: true,
  });
  const destinationAddress = getAddressDisplayString({
    value: {
      city: node.destinationCity || '',
      state: node.destinationState || '',
    },
    city: true,
    state: true,
  });
  let pickupDate = '';
  if (node.puStartDate) {
    pickupDate = getDatetimeValue({
      value: node.puStartDate,
      timezone: 'UTC',
      dateOnly: true,
    });
  }
  const addressDisplay = compact([originAddress, destinationAddress]).join(
    ' → '
  );
  const restLabel = compact([node.customer, pickupDate, addressDisplay]).join(
    ' | '
  );
  return (
    <div>
      <strong>{prefix}</strong>
      {pipeSeparator}
      {restLabel}
    </div>
  );
};

const isV3Query = (
  data: LoadsForMasterfindV3Query | LoadsForMasterfindV2Query
): data is LoadsForMasterfindV3Query => {
  return Boolean((data as anyOk)?.seerSearchMasterFindLoadSearch);
};

const useLoadSearchV2 = (): ((filter: string) => Promise<ActionDef[]>) => {
  const callQueryOld = useLazyQueryWithDataPromise<
    LoadsForMasterfindV2Query,
    LoadsForMasterfindV2QueryVariables
  >(LoadsForMasterfindV2Document);

  const callQueryNew = useLazyQueryWithDataPromise<
    LoadsForMasterfindV3Query,
    LoadsForMasterfindV3QueryVariables
  >(LoadsForMasterfindV3Document);

  const callQuery = useFlag('ME-43070-fix-masterfind-load-query')
    ? callQueryNew
    : callQueryOld;

  const runSearch = async (filterVal: string): Promise<ActionDef[]> => {
    if (!filterVal?.length) {
      return [];
    }
    const filter = {
      value: `%${filterVal}%`,
      operator: SeerGenericStringOperator.Like,
      orGroup: '1',
    };
    const { data } = await callQuery({
      variables: {
        filter: {
          refNumber: [filter],
          orderNumber: [filter],
          loadCode: [filter],
          routeCode: [filter],
        },
        pagination: { first: 20 },
        orderBy: {
          loadCode: { order: SeerGenericOrder.Desc, position: 0 },
          orderNumber: { order: SeerGenericOrder.Desc, position: 1 },
          routeCode: { order: SeerGenericOrder.Desc, position: 2 },
          refNumber: { order: SeerGenericOrder.Desc, position: 3 },
        },
      },
      fetchPolicy: FETCH_POLICY,
    });

    const edges = isV3Query(data)
      ? data.seerSearchMasterFindLoadSearch?.edges
      : data.seerSearchLoadSearchV2?.edges;

    const res =
      edges?.map((edge): ActionDef => {
        return {
          label: (): ReactElement => (
            <LoadV2LabelDisplay node={edge.node} filterVal={filterVal} />
          ),
          icon: 'boxOpen',
          action: (): void => {
            openNewWindow({
              to: `/loads/${edge.node.id}/?row=0&section=order`,
            });
          },
          searchVector: filterVal,
          id: edge.node.id || '',
        };
      }) || [];
    return uniqBy(res, (obj) => obj.id);
  };
  return useCallback(runSearch, [callQuery]);
};

const ITEM_PADDING = 10;

const SectionHeader: FC = ({ children }) => {
  const theme = useTheme();
  return (
    <div
      css={{
        color: theme.gray[500],
        textTransform: 'uppercase',
        fontSize: '.8em',
        paddingLeft: ITEM_PADDING + 2,
      }}
    >
      {children}
    </div>
  );
};

const RenderItemLabel: FC<ActionDef> = (item: ActionDef) => {
  const theme = useTheme();
  if (item.disabled) {
    return <SectionHeader>{item.name}</SectionHeader>;
  } else if (item.icon) {
    return (
      <Grid xs="20px 1fr" gap={0.2} css={{ paddingLeft: ITEM_PADDING }}>
        <div>
          <Icon i={item.icon} size={FONT_SIZE * 0.7} />
        </div>
        {item.label ? item.label({ item, theme }) : item.name}
      </Grid>
    );
  }
  return <Fragment>{item.name}</Fragment>;
};

const fuzzyItems = (val: string, items: ActionDef[]): ActionDef[] => {
  return fuzzy
    .filter(val, items, { extract: (obj) => obj.searchVector })
    .map((result) => result.original);
};

const addSectionTitleToResults = (
  arr: ActionDef[],
  title: ActionDef
): ActionDef[] => {
  if (!arr.length) {
    return [];
  }
  return [title].concat(arr);
};

const useGetCombinedLoadSearchV2 = (): ((
  val: string
) => Promise<ActionDef[]>) => {
  const canSearch = useHasPermission('load:view');
  const searchLoads = useLoadSearchV2();
  return useCallback(
    async (rawVal: string): Promise<ActionDef[]> => {
      if (!canSearch) {
        return [];
      }
      const loadResults = await searchLoads(rawVal);
      return addSectionTitleToResults(loadResults, {
        id: 'app-loads-title',
        name: 'Loads',
        disabled: true,
        searchVector: 'Loads',
      });
    },
    [canSearch, searchLoads]
  );
};

const useFacilitiesSearch = (): ((val: string) => Promise<ActionDef[]>) => {
  const canSearch = useHasPermission('facility:view');
  const searchFacilities = useFacilityCombinedSearch({
    filterInactive: false,
  });
  return useCallback(
    async (val: string): Promise<ActionDef[]> => {
      if (!canSearch) {
        return [];
      }
      const res = await searchFacilities(val);
      const arr = res.map(
        (obj): ActionDef => ({
          name: obj.name,
          id: obj.id,
          searchVector: obj.name,
          icon: 'industry',
          linkProps: getLinkProps(`/facilities/${obj.id}/`),
          label: (): ReactNode => <FacilityPickerItemDisplay facility={obj} />,
        })
      );
      return addSectionTitleToResults(arr, {
        id: getActionId('app-facilities-title'),
        name: 'Facilities',
        disabled: true,
        searchVector: 'Facilities',
      });
    },
    [canSearch, searchFacilities]
  );
};

const useCustomersSearch = (): ((val: string) => Promise<ActionDef[]>) => {
  const useCustomerV2 = useCustomerV2Flag();
  const canSearch = useHasPermission('customer:view');
  const searchCustomers = useCustomerSearch();
  return useCallback(
    async (val: string): Promise<ActionDef[]> => {
      if (!canSearch) {
        return [];
      }
      const res = await searchCustomers(val, useCustomerV2);
      const arr = res.map(
        (obj): ActionDef => ({
          name: obj.name,
          id: obj.id,
          searchVector: obj.name,
          icon: 'building',
          linkProps: getLinkProps(`/customers/${obj.id}/`),
          label: (): ReactNode => <CustomerPickerItemDisplay customer={obj} />,
        })
      );
      return addSectionTitleToResults(arr, {
        id: getActionId('app-customers-title'),
        name: 'Customers',
        disabled: true,
        searchVector: 'Customers',
      });
    },
    [searchCustomers, canSearch, useCustomerV2]
  );
};

/** @deprecated This version  transforms "Al's Toy Barn" to "Al" which is incorrect */
const oldRemoveNonAlphaNumericKeepSpacesRegex = /(?!(\w|\s)).*/gi;

const useGetAllData = (): ((val: string) => Promise<ActionDef[]>) => {
  const useCarrierV2 = useCarrierV2Flag();
  const searchCarriers = useCarriersSearch();
  const searchCarriersV2 = useCarriersSearchV2();
  const searchLoadsV2 = useGetCombinedLoadSearchV2();
  const searchFacilities = useFacilitiesSearch();
  const searchCustomers = useCustomersSearch();

  const flagRegex = useFlag('ME-32857-app-masterfind-regex');

  const regexToUse = useMemo(() => {
    return (
      (flagRegex ? new RegExp(flagRegex, 'gi') : '') ||
      oldRemoveNonAlphaNumericKeepSpacesRegex
    );
  }, [flagRegex]);

  const useSearch = async (rawVal: string): Promise<ActionDef[]> => {
    if (rawVal.length < 3) {
      return [];
    }
    const val = (rawVal || '').replaceAll(regexToUse, '');
    const results = await Promise.allSettled([
      useCarrierV2 ? searchCarriersV2(val) : searchCarriers(val),
      searchCustomers(val),
      searchFacilities(val),
      searchLoadsV2(val),
    ]);
    return uniqBy(
      flattenDeep(filterByPromiseFulfilled(results).map((res) => res.value)),
      (obj) => obj.id
    );
  };
  return useCallback(useSearch, [
    regexToUse,
    useCarrierV2,
    searchCarriersV2,
    searchCarriers,
    searchFacilities,
    searchCustomers,
    searchLoadsV2,
  ]);
};

const TITLE_HEIGHT = 36;
const TOTAL_HEIGHT = 350;
const SCROLLER_HEIGHT = TOTAL_HEIGHT - 48 - TITLE_HEIGHT;
const MASTERFIND_ELEMENT_ID = 'masterfind';

export const Masterfind: FC = () => {
  const [current, send] = useMachine(mfMachine);
  useShortcut({
    keys: 'ctrl+p, cmd+p, ctrl+shift+p, cmd+shift+p',
    debounceMillis: 20,
    onEvent: () => {
      send('TOGGLE');
    },
  });
  const { card, dialog, gray, hr, colors } = useTheme();

  const { actions: defaultActions } = useContext(MasterfindContext);

  const getData = useGetAllData();

  const [stateItems, setItems] = useState<ActionDef[]>([]);
  const [currentInputValue, setCurrentInputValue] = useState('');

  const waitTime = useFlag('ME-35600-feat-masterfind-debounce-ms');

  const filteredDefaultActions = currentInputValue
    ? fuzzyItems(currentInputValue, defaultActions)
    : defaultActions;

  let defaultActionsToRender: ActionDef[] = [];

  if (filteredDefaultActions.length) {
    defaultActionsToRender = [defaultActionsTitle].concat(
      filteredDefaultActions
    );
  }

  const computedItems = currentInputValue
    ? defaultActionsToRender.concat(stateItems)
    : defaultActionsToRender;

  const items = (
    current.matches('active.default') ? defaultActionsToRender : computedItems
  ).filter((obj) => !obj.hidden && obj.visible !== false);

  const noItems = items.length === 0;

  const titleColor = gray[900];

  const [currentlyRunningDataFetch, setCurrentlyRunningDataFetch] = useState<{
    id: string;
    prom: Cancelable<ActionDef[]> | null;
  } | null>(null);

  const onInputChange = useDebouncedFn(
    async (val: string) => {
      const id = uniqueId('mf-data');
      if (val.length < 3) {
        return;
      }
      send('LOAD_START');
      try {
        setCurrentlyRunningDataFetch((state) => {
          state?.prom?.cancel();
          return null;
        });
        const cProm = new Cancelable<ActionDef[]>(async (resolve, reject) => {
          try {
            const res = await getData(val);
            resolve(res);
          } catch (err: anyOk) {
            reject(err);
          }
        });
        setCurrentlyRunningDataFetch(() => ({ id, prom: cProm }));
        const data = await cProm;
        setItems(data);
        setCurrentlyRunningDataFetch(() => ({ id, prom: null }));
      } catch {
        // noop
      }
    },
    waitTime,
    [setCurrentlyRunningDataFetch]
  );

  const prev = usePrevious(currentInputValue);

  useEffect(() => {
    if (prev !== currentInputValue && currentInputValue) {
      onInputChange(currentInputValue);
    }
  }, [currentInputValue, onInputChange, prev]);

  useEffect(() => {
    if (currentlyRunningDataFetch?.prom === null) {
      send('LOAD_COMPLETE');
    }
  }, [currentlyRunningDataFetch, send]);

  const permissionsContext = usePermissionsContext();

  const filteredItemsViaPerms = items.filter((obj) => {
    if (!obj.permissions) {
      return obj;
    }
    return getResolvedPermissionValues({
      permissionsContext,
      scopes: obj.permissions,
    }).every(Boolean);
  });

  const itemsForAutocomplete = filteredItemsViaPerms.map((obj) => ({
    label: (): ReactNode => <RenderItemLabel {...obj} />,
    value: obj,
    id: obj.id || obj.name,
    disabled: obj.disabled,
  }));

  const close = (): void => {
    currentlyRunningDataFetch?.prom?.cancel();
    send('CLOSE');
    setCurrentInputValue('');
  };

  const isLoading =
    current.matches('loading.stage0') ||
    current.matches('loading.stage1') ||
    current.matches('loading.stage2');

  const loadingMessage = current.matches('loading.error')
    ? 'Could not load search results. Try again later.'
    : '';

  return (
    <DialogOverlay
      css={[
        dialog.overlay,
        {
          display: 'grid',
          placeItems: 'center',
          zIndex: MODAL_BACKGROUND,
          background: 'none',
        },
      ]}
      onDismiss={(): void => {
        close();
      }}
      isOpen={current.value !== 'inactive'}
    >
      <DialogContent
        css={{
          padding: 0,
          margin: '-20vh 0 0 0',
          position: 'relative',
          overflow: 'visible',
          fontSize: FONT_SIZE,
          width: '50vw',
          minWidth: 600,
          maxWidth: '90vw',
        }}
      >
        <form
          id={MASTERFIND_ELEMENT_ID}
          onSubmit={(e): void => e.preventDefault()}
          spellCheck="false"
          data-loading={isLoading.toString()}
          className={FS_UNMASK}
          css={{
            border: `3px solid ${titleColor}`,
            borderRadius: 3,
            background: titleColor,
            height: TOTAL_HEIGHT,
            boxShadow: '0 2px 10px rgba(0,0,0,0.15)',
            '[role="listbox"]': {
              margin: 0,
              maxHeight: 'unset',
              height: SCROLLER_HEIGHT,
              boxShadow: 'none !important',
              border: 0,
              borderRadius: 3,
              fontSize: 14,
            },
            input: {
              border: 0,
              borderBottom: `2px solid ${hr.background}`,
              borderRadius: '3px 3px 0 0',
              '&:focus': {
                outline: '0 !important',
              },
            },
          }}
        >
          <div
            css={{
              borderRadius: 3,
              background: card.background,
              overflow: 'hidden',
              height: '100%',
              borderTop: `6px solid ${titleColor}`,
            }}
          >
            <Grid
              css={{
                color: 'white',
                textTransform: 'uppercase',
                letterSpacing: '.3em',
                alignItems: 'center',
                fontSize: 12,
                fontWeight: 700,
                height: TITLE_HEIGHT,
                background: titleColor,
                marginTop: -6,
              }}
              gap={0.2}
              xs="min-content 1fr"
            >
              <Logo css={{ width: TITLE_HEIGHT, height: TITLE_HEIGHT }} />
              Masterfind
            </Grid>
            <AutoComplete<ActionDef>
              loading={loadingMessage}
              disableSelectOnTab
              showSearchIcon
              isOpen
              inputProps={{
                autoFocus: true,
                placeholder: 'Enter a command or search',
              }}
              css={{
                fontSize: FONT_SIZE,
                input: { fontSize: FONT_SIZE, paddingLeft: FONT_SIZE + 16 },
                '[role="combobox"] > [data-icon="search"]': {
                  fontSize: Math.round(FONT_SIZE * 0.9),
                  color: colors.text,
                },
                'li[data-disabled="true"]': {
                  cursor: 'default',
                },
              }}
              downshiftStateReducer={(state, changes: fixMe): fixMe => {
                if (
                  changes.highlightedIndex !== state.highlightedIndex &&
                  changes.highlightedIndex === 0
                ) {
                  // Without this, the first SectionHeader will get lost if the user arrives at the top item somehow (like arrowing from the last item in the list, down, back to the first)
                  setTimeout(() => {
                    document
                      .querySelector(
                        `#${MASTERFIND_ELEMENT_ID} [role="listbox"]`
                      )
                      ?.scrollTo(0, 0);
                  }, 0);
                }
                switch (changes.type) {
                  case stateTypes.changeInput:
                  case stateTypes.keyDownEscape:
                    setCurrentInputValue(changes.inputValue);
                    break;
                  default:
                    changes.inputValue = state.inputValue;
                }
                switch (changes.type) {
                  case stateTypes.keyDownEscape:
                    if (!state.inputValue) {
                      close();
                    } else {
                      send('RESET');
                    }
                    break;
                  case stateTypes.changeInput:
                    if (!changes.inputValue) {
                      send('RESET');
                    } else {
                      send('INPUT_CHANGE');
                    }
                    break;
                }
                return changes;
              }}
              items={itemsForAutocomplete}
              onChange={(item): void => {
                if (item && item.value) {
                  const { action } = item.value;
                  if (action) {
                    action();
                  } else if (item.value.linkProps) {
                    if (win.location.search.match('header=0')) {
                      // TODO: may want to re-enable this if we want to use the concept of a single 'main' window
                      // if (IS_ELECTRON) {
                      //   return (history as fixMe).pushMain(
                      //     item.value.linkProps.to
                      //   );
                      // }
                      openNewWindow(item.value.linkProps);
                    } else {
                      history.push(item.value.linkProps.to);
                    }
                  }
                  close();
                }
              }}
            />
            <LoadingBar
              loading={isLoading}
              targetMillis={3000}
              css={{
                zIndex: MODAL_BACKGROUND + 1,
                marginTop: (DEFAULT_LOADING_BAR_HEIGHT / 2) * -1,
                position: 'relative',
              }}
            />
            {noItems && current.matches('active.loaded') && (
              <Padding a={2} css={{ color: gray[300] }}>
                No Results
              </Padding>
            )}
          </div>
        </form>
      </DialogContent>
    </DialogOverlay>
  );
};
