import { LinkRoutePath } from '@app/routes';
import { Link, LinkProps, LinkTo } from '@components/Link';
import {
  IS_CYPRESS,
  IS_PRODUCTION_BUILD,
  NEW_WINDOW_TARGET,
} from '@utils/constants';
import { win } from '@utils/win';
import { compact, isBoolean, uniqueId } from 'lodash-es';
import { parse, stringify } from 'query-string';
import { FC, memo } from 'react';

const isInPWADesktopMode =
  IS_PRODUCTION_BUILD &&
  Boolean(win.matchMedia('(display-mode: standalone)').matches);

export interface NewWindowLinkProps extends LinkProps {
  width?: number;
  height?: number;
  onOpen?: (event: fixMe) => void;
  /** Show the header, defaults to true */
  header?: boolean;
  /** Show the sidebar, defaults to true */
  sidebar?: boolean;
  /** Return the window object to the consumer. Good if you need a custom beforeunload event handler */
  returnWindow?: true;
}

const boolAsParamString = (b: boolean | string | number): string => {
  if (isBoolean(b)) {
    return (b ? 1 : 0).toString();
  }
  return b.toString();
};

const urlHref = (
  kwargs: NewWindowLinkProps
): { href: LinkRoutePath; to: LinkTo } => {
  const { to, header, sidebar } = kwargs;

  let params: fixMe = {};
  let href: string = to;
  let path = '';
  try {
    const urlStr = href.match(/^\//) ? win.location.origin + href : href;
    const url = new URL(urlStr);
    params = parse(url.search);
    params = {
      ...params,
      header: boolAsParamString(header ?? params.header),
      sidebar: boolAsParamString(sidebar ?? params.sidebar),
    };
    path = url.pathname + '?' + stringify(params);
    href = url.origin + path;
  } catch {
    // noop
  }
  return { href: href as LinkRoutePath, to: path as LinkTo };
};

const BOOT_PREFIX = 'new-win-' + Date.now();

export const openNewWindow = (kwargs: NewWindowLinkProps): Window | null => {
  const {
    width = 1800,
    height = 1080,
    target = uniqueId(BOOT_PREFIX),
    returnWindow,
  } = kwargs;

  const href = urlHref(kwargs).href;

  const shouldUseNoopener = !returnWindow;

  const featureString = compact([
    'resizable',
    shouldUseNoopener && 'noopener',
    // As of writing, you can't use the combination of noopener + dimensions. Chrome doesn't respect the dimensions.
    // https://stackoverflow.com/questions/58495758/window-open-ignoring-height-and-width-when-noopener-is-included-in-the-option
    // https://bugs.chromium.org/p/chromium/issues/detail?id=1011688
    // If you supply nothing, the window will open the same size as the parent, which is probably close enough.
    !shouldUseNoopener && `height=${height}`,
    !shouldUseNoopener && `width=${width}`,
    // In PWA mode we can specify height + width appropriately - different constraints than web.
    isInPWADesktopMode && `height=${height}`,
    isInPWADesktopMode && `width=${width}`,
    'toolbar=0',
    'menubar=0',
    'location=0',
  ]).join(',');

  return win.open(href, target, featureString);
};

const NewWindowLinkComp: FC<NewWindowLinkProps> = ({ onOpen, ...props }) => {
  const to = urlHref(props).to || props.to;

  return (
    <Link
      target={NEW_WINDOW_TARGET}
      rel="noopener noreferrer"
      {...props}
      onClick={(event: fixMe): void => {
        if (!IS_CYPRESS) {
          event.preventDefault();
          openNewWindow(props);
        }
        onOpen?.(event);
      }}
      to={to}
    />
  );
};

export const NewWindowLink = memo(NewWindowLinkComp);
