import * as TP from '../types';

import { gql } from '@apollo/client';
import { EmployeeDetailsEmployeeTreeV2FragmentDoc } from '../fragments/employeeDetailsEmployeeTreeV2';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEmployeeDetailsV2QueryVariables = TP.Exact<{
  id: TP.Scalars['ID'];
  useViewSlackEmployeeTreeFlag?: TP.Scalars['Boolean'];
}>;


export type GetEmployeeDetailsV2Query = (
  { readonly __typename?: 'Query' }
  & { readonly employeeV2?: TP.Maybe<(
    { readonly __typename?: 'EmployeeV2' }
    & Pick<TP.EmployeeV2, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
    & { readonly parent?: TP.Maybe<(
      { readonly __typename?: 'EmployeeV2' }
      & Pick<TP.EmployeeV2, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
      & { readonly children?: TP.Maybe<ReadonlyArray<(
        { readonly __typename?: 'EmployeeV2' }
        & Pick<TP.EmployeeV2, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
        & { readonly children?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'EmployeeV2' }
          & Pick<TP.EmployeeV2, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
          & { readonly children?: TP.Maybe<ReadonlyArray<(
            { readonly __typename?: 'EmployeeV2' }
            & Pick<TP.EmployeeV2, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
            & { readonly messagingUserIdentities?: TP.Maybe<ReadonlyArray<(
              { readonly __typename?: 'MessagingV2' }
              & Pick<TP.MessagingV2, 'employeeId' | 'serverId' | 'type' | 'userId'>
            )>>, readonly employeeRole?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'id' | 'name'>
            )>, readonly employeeGroup?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'id' | 'name'>
            )>, readonly employeeOffice?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'id' | 'name'>
            )>, readonly division?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'id' | 'name'>
            )>, readonly manager?: TP.Maybe<(
              { readonly __typename?: 'EmployeeV2' }
              & Pick<TP.EmployeeV2, 'id' | 'fullName'>
            )> }
          )>>, readonly messagingUserIdentities?: TP.Maybe<ReadonlyArray<(
            { readonly __typename?: 'MessagingV2' }
            & Pick<TP.MessagingV2, 'employeeId' | 'serverId' | 'type' | 'userId'>
          )>>, readonly employeeRole?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly employeeGroup?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly employeeOffice?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly division?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly manager?: TP.Maybe<(
            { readonly __typename?: 'EmployeeV2' }
            & Pick<TP.EmployeeV2, 'id' | 'fullName'>
          )> }
        )>>, readonly messagingUserIdentities?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'MessagingV2' }
          & Pick<TP.MessagingV2, 'employeeId' | 'serverId' | 'type' | 'userId'>
        )>>, readonly employeeRole?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly employeeGroup?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly employeeOffice?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly division?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly manager?: TP.Maybe<(
          { readonly __typename?: 'EmployeeV2' }
          & Pick<TP.EmployeeV2, 'id' | 'fullName'>
        )> }
      )>>, readonly messagingUserIdentities?: TP.Maybe<ReadonlyArray<(
        { readonly __typename?: 'MessagingV2' }
        & Pick<TP.MessagingV2, 'employeeId' | 'serverId' | 'type' | 'userId'>
      )>>, readonly employeeRole?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly employeeGroup?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly employeeOffice?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly division?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly manager?: TP.Maybe<(
        { readonly __typename?: 'EmployeeV2' }
        & Pick<TP.EmployeeV2, 'id' | 'fullName'>
      )> }
    )>, readonly children?: TP.Maybe<ReadonlyArray<(
      { readonly __typename?: 'EmployeeV2' }
      & Pick<TP.EmployeeV2, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
      & { readonly children?: TP.Maybe<ReadonlyArray<(
        { readonly __typename?: 'EmployeeV2' }
        & Pick<TP.EmployeeV2, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
        & { readonly children?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'EmployeeV2' }
          & Pick<TP.EmployeeV2, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
          & { readonly messagingUserIdentities?: TP.Maybe<ReadonlyArray<(
            { readonly __typename?: 'MessagingV2' }
            & Pick<TP.MessagingV2, 'employeeId' | 'serverId' | 'type' | 'userId'>
          )>>, readonly employeeRole?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly employeeGroup?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly employeeOffice?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly division?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly manager?: TP.Maybe<(
            { readonly __typename?: 'EmployeeV2' }
            & Pick<TP.EmployeeV2, 'id' | 'fullName'>
          )> }
        )>>, readonly messagingUserIdentities?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'MessagingV2' }
          & Pick<TP.MessagingV2, 'employeeId' | 'serverId' | 'type' | 'userId'>
        )>>, readonly employeeRole?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly employeeGroup?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly employeeOffice?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly division?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly manager?: TP.Maybe<(
          { readonly __typename?: 'EmployeeV2' }
          & Pick<TP.EmployeeV2, 'id' | 'fullName'>
        )> }
      )>>, readonly messagingUserIdentities?: TP.Maybe<ReadonlyArray<(
        { readonly __typename?: 'MessagingV2' }
        & Pick<TP.MessagingV2, 'employeeId' | 'serverId' | 'type' | 'userId'>
      )>>, readonly employeeRole?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly employeeGroup?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly employeeOffice?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly division?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly manager?: TP.Maybe<(
        { readonly __typename?: 'EmployeeV2' }
        & Pick<TP.EmployeeV2, 'id' | 'fullName'>
      )> }
    )>>, readonly messagingUserIdentities?: TP.Maybe<ReadonlyArray<(
      { readonly __typename?: 'MessagingV2' }
      & Pick<TP.MessagingV2, 'employeeId' | 'serverId' | 'type' | 'userId'>
    )>>, readonly employeeRole?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly employeeGroup?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly employeeOffice?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly division?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly manager?: TP.Maybe<(
      { readonly __typename?: 'EmployeeV2' }
      & Pick<TP.EmployeeV2, 'id' | 'fullName'>
    )> }
  )> }
);


export const GetEmployeeDetailsV2Document = gql`
    query getEmployeeDetailsV2($id: ID!, $useViewSlackEmployeeTreeFlag: Boolean! = false) {
  employeeV2(id: $id) {
    ...EmployeeDetailsEmployeeTreeV2
    parent {
      ...EmployeeDetailsEmployeeTreeV2
    }
  }
}
    ${EmployeeDetailsEmployeeTreeV2FragmentDoc}`;

/**
 * __useGetEmployeeDetailsV2Query__
 *
 * To run a query within a React component, call `useGetEmployeeDetailsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeDetailsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeDetailsV2Query({
 *   variables: {
 *      id: // value for 'id'
 *      useViewSlackEmployeeTreeFlag: // value for 'useViewSlackEmployeeTreeFlag'
 *   },
 * });
 */
export function useGetEmployeeDetailsV2Query(baseOptions: Apollo.QueryHookOptions<GetEmployeeDetailsV2Query, GetEmployeeDetailsV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeDetailsV2Query, GetEmployeeDetailsV2QueryVariables>(GetEmployeeDetailsV2Document, options);
      }
export function useGetEmployeeDetailsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeDetailsV2Query, GetEmployeeDetailsV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeDetailsV2Query, GetEmployeeDetailsV2QueryVariables>(GetEmployeeDetailsV2Document, options);
        }
export type GetEmployeeDetailsV2QueryHookResult = ReturnType<typeof useGetEmployeeDetailsV2Query>;
export type GetEmployeeDetailsV2LazyQueryHookResult = ReturnType<typeof useGetEmployeeDetailsV2LazyQuery>;
export type GetEmployeeDetailsV2QueryResult = Apollo.QueryResult<GetEmployeeDetailsV2Query, GetEmployeeDetailsV2QueryVariables>;