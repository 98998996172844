import { reportError } from '@utils/sentry';
import {
  currentTZ,
  DEPRECATEDutcDatetimeToLocalDate,
  getDatetimeShort,
  utcDatetimeToLocalTime,
} from '@utils/time';
import { isEqual, isFinite, isNil } from 'lodash-es';
import { FC, HTMLAttributes, memo } from 'react';
import { MergeExclusive } from 'type-fest';

interface BaseProps {
  /** Value is always a UTC time */
  value: Maybe<string | Date>;
  /** Example: "America/Chicago" */
  timezone?: Maybe<string>;
  /** Display tz like "CST" at end of string */
  showTimezone?: boolean;
  /** Fallback when there is no data present */
  noData?: string;
  /** Use a different html element instead of default */
  as?: string;
  className?: string;
  style?: HTMLAttributes<unknown>['style'];
}

interface TimeOnly {
  timeOnly?: boolean;
}

interface DateOnly {
  dateOnly?: boolean;
}

interface OmitIfTimeOnly extends DateOnly {
  showYear?: boolean;
}

interface OmitTime {
  omitTime: true;
}

type TimeOnlyConsideration = MergeExclusive<OmitIfTimeOnly, TimeOnly>;
type OmitTimeConsideration = MergeExclusive<TimeOnly, OmitTime>;

interface OmitIfDateOnly extends TimeOnly, Pick<BaseProps, 'showTimezone'> {}

type DateOnlyConsideration = MergeExclusive<OmitIfDateOnly, DateOnly>;

export type Props = BaseProps &
  TimeOnlyConsideration &
  DateOnlyConsideration &
  OmitTimeConsideration;

const isValidDate = (s: anyOk): boolean => {
  return s && s.valueOf && isFinite(s.valueOf());
};

/** Default format is MM/YY HH:MM for US Locale */
export const getDatetimeValue = (props: Maybe<Props>): string => {
  if (!props) {
    return '';
  }
  const { value, timezone, noData, showTimezone } = props;
  const fallback = noData || '';
  try {
    if (isNil(value)) {
      return fallback;
    }
    let coercedDate: Date | undefined = undefined;
    coercedDate = new Date(value);
    if (!isValidDate(coercedDate)) {
      coercedDate = undefined;
    }
    if (!coercedDate) {
      return fallback;
    }
    if (props.timeOnly) {
      // TODO: it would be nice to emit a time with a timezone here instead
      if (!timezone) {
        return fallback;
      }
      return utcDatetimeToLocalTime(coercedDate, timezone) || fallback;
    } else if (props.dateOnly) {
      const res =
        DEPRECATEDutcDatetimeToLocalDate(
          coercedDate,
          timezone ? timezone : currentTZ,
          undefined,
          props.showYear,
          !timezone
        ) ?? fallback;
      return props.showYear ? res.replace(/(.*)\/\d\d(\d\d)/, '$1/$2') : res;
    }
    return getDatetimeShort(coercedDate, timezone ?? currentTZ, {
      showYear: props.showYear || false,
      showShortTZ: timezone ? showTimezone : true,
    });
  } catch (err: anyOk) {
    reportError(err);
    return fallback;
  }
};

const DatetimeValueRaw: FC<Props> = (props) => {
  const El = (props.as || 'span') as anyOk;
  return (
    <El className={props.className} style={props.style}>
      {getDatetimeValue(props)}
    </El>
  );
};

export const DatetimeValue = memo(DatetimeValueRaw, (prev, next) =>
  // we use isEqual here in case consumers pass in a Date for the value prop
  // Otherwise it will always bust the memo
  isEqual(prev, next)
) as typeof DatetimeValueRaw;
