import { useHasPermissionFromContext } from '@components/PermissionsContext';
import { MODE } from '@env';
import {
  currentTZ,
  getTimeFromDatetimeString,
  localDateToUTC,
  TimeStringToMsOffsetUTC,
  utcDatetimeToLocalTime,
  utcDateToLocal,
} from '@utils/time';
import { isNumber } from 'lodash-es';
import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import MaskedInput from 'react-text-mask';
import { usePrevious } from 'react-use';
import { ReadOnlyField } from '../Field/ReadOnlyField';
import { timeMask } from '../FieldMasks';

export interface Props {
  disabled?: boolean;
  id?: string;
  name?: string;
  onChange: (adjustedTime: string | number | null | undefined) => void;
  required?: boolean;
  timezone?: string;
  value: string | null;
  allowEmpty?: boolean;
  /** Instead of a date, output a number of ms that equals the time */
  outputMs?: boolean;
  readOnly?: boolean;
  onBlur?: (
    event: ChangeEvent<HTMLInputElement>,
    value: string | number | null | undefined
  ) => void;
  placeholder?: string;
}

/** Array of string times in 15 minute intervals, like ['00:00', '00:15', '00:30', ...] */
const getTimeList = (): Array<string> => {
  const list = [];

  for (let i = 0; i < 24; i++) {
    const hour = i.toString().length === 1 ? `0${i}` : i;
    for (let a = 0; a < 4; a++) {
      const minute = a === 0 ? `${a}0` : a * 15;
      list.push(`${hour}:${minute}`);
    }
  }
  return list;
};

const timeList = getTimeList() as [string];

const UP_ARROW_KEYCODE = 38;
const DOWN_ARROW_KEYCODE = 40;
const N_KEYCODE = 78;

const pickTime = (value: string, keyCode: number): string => {
  const results = timeList.filter((interval: string): anyOk => {
    const parsedTime = parseInt(interval.replace(':', ''));
    const parsedValue = parseInt(value.replace(':', ''));

    if (keyCode === UP_ARROW_KEYCODE) {
      return parsedTime > parsedValue;
    }
    if (keyCode === DOWN_ARROW_KEYCODE) {
      return parsedTime < parsedValue;
    }
    return;
  });

  if (keyCode === UP_ARROW_KEYCODE) {
    return results.length === 0 ? timeList[0] : results[0] || timeList[0];
  }
  if (keyCode === DOWN_ARROW_KEYCODE) {
    return results.length === 0
      ? timeList[timeList.length - 1] || timeList[0]
      : results[results.length - 1] || timeList[0];
  }
  return '';
};

export const Time: FC<Props> = ({
  disabled,
  id,
  name,
  onChange,
  required,
  timezone = currentTZ,
  value,
  allowEmpty,
  outputMs,
  onBlur,
  placeholder,
  ...rest
}): ReactElement => {
  const [userHasPermision, permissionScope] = useHasPermissionFromContext();
  const readOnly = !userHasPermision || rest.readOnly;

  const valueAsTime: string | undefined =
    value || !allowEmpty
      ? utcDatetimeToLocalTime(value || '', isNumber(value) ? 'UTC' : timezone)
      : '';

  const [time, setTime] = useState<string | undefined>(valueAsTime);
  const [propValue, setPropValue] = useState<Maybe<string | number>>(value);

  const changeValue = (newValue: Maybe<string | number>): void => {
    setPropValue(newValue);
    onChange(newValue);
  };

  if (MODE !== 'production') {
    /* eslint-disable no-console */
    if (!value && !allowEmpty) {
      console.warn(
        'Time input did not receive a value. Use allowEmpty property if you wish to pass a falsey value.'
      );
    }
    /* eslint-enable no-console */
  }

  const updateTime = (time: string | undefined): Maybe<string | number> => {
    let newValue: Maybe<string | number> = undefined;
    setTime(time);
    if (!time && allowEmpty) {
      newValue = null;
    } else if (outputMs) {
      newValue = TimeStringToMsOffsetUTC(time) ?? 0;
    } else {
      const localDate = utcDateToLocal(
        value || new Date(),
        timezone
      ).toDateString();
      const localDateTime = new Date(`${localDate} ${time}`);
      const dateTime = localDateToUTC(localDateTime, timezone);
      if (dateTime && !isNaN(dateTime.getTime())) {
        newValue = dateTime.toISOString();
      }
    }
    changeValue(newValue);
    return newValue;
  };

  const handleKeyUp = (keyPress: KeyboardEvent<HTMLInputElement>): void => {
    const { keyCode } = keyPress;
    if (keyCode === N_KEYCODE) {
      updateTime(getTimeFromDatetimeString(new Date().toTimeString()));
    }
  };

  const handleKeyDown = (keyPress: KeyboardEvent<HTMLInputElement>): void => {
    const { value } = keyPress.currentTarget;
    const { keyCode } = keyPress;

    if (keyCode === UP_ARROW_KEYCODE || keyCode === DOWN_ARROW_KEYCODE) {
      updateTime(pickTime(value, keyCode));
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;
    if (value === '' && !allowEmpty) {
      updateTime('');
    } else if ((value !== '' || allowEmpty) && value.indexOf('_') === -1) {
      updateTime(value);
    }
  };

  const handleBlur = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value: eventValue } = event.currentTarget;
    const adjustedTime = eventValue.replace(/_/g, '0');
    const parsedHour = parseInt(eventValue);
    let newValue: Maybe<string | number> = value;
    if (eventValue.indexOf('_') > -1) {
      newValue =
        parsedHour < 3
          ? updateTime(`0${parsedHour}:00`)
          : updateTime(adjustedTime);
    } else if (!eventValue && !allowEmpty) {
      newValue = updateTime('00:00');
    }
    if (outputMs) {
      newValue = TimeStringToMsOffsetUTC(adjustedTime as fixMe);
    }
    onBlur?.(event, newValue);
  };

  const prev = usePrevious(timezone);

  useEffect(() => {
    // we only want to run this update after the initial render
    // otherwise onChange is called on render, which may interfere with ideas of "touched" fields
    if (prev) {
      updateTime(time);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone]);

  useEffect(() => {
    if (value !== propValue) {
      setTime(valueAsTime);
      setPropValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  if (readOnly) {
    return <ReadOnlyField data-scope={permissionScope}>{time}</ReadOnlyField>;
  }

  return (
    <MaskedInput
      {...rest}
      autoComplete="off"
      disabled={disabled}
      id={id}
      mask={timeMask}
      name={name}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder}
      required={required}
      type="text"
      value={time}
      data-scope={permissionScope}
      defaultValue=""
      className="timeInput"
    />
  );
};
