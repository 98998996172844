import { useWhoAmI } from '@app/WhoAmI';
import { EmployeeInfoFragment } from '@generated/fragments/employeeInfo';
import { EmployeeInfoV2Fragment } from '@generated/fragments/employeeInfoV2';
import { useGetEmployeeDetailsLazyQuery } from '@generated/queries/getEmployeeDetails';
import { useGetEmployeeDetailsV2LazyQuery } from '@generated/queries/getEmployeeDetailsV2';
import { useGetEmployeeParentDetailsLazyQuery } from '@generated/queries/getEmployeeParentDetails';
import { useGetEmployeeParentDetailsV2LazyQuery } from '@generated/queries/getEmployeeParentDetailsV2';
import { useEmployeeV2Flag } from '@hooks/useEmployeeV2Flag';
import { useTheme } from '@hooks/useTheme';
import { useViewSlackEmployeeTreeFlag } from '@hooks/useViewSlackEmployeeTreeView';
import { isNil } from 'lodash-es';
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Loading } from '../../../components/Loading';
import { TreeNode } from '../../../components/TreePicker';
import { EmployeeTreeNodeValue, SidebarContext } from '../util';
import { EmployeeTreePicker } from './employeeTreePicker';
import { getEmployeeTreeNodes, prioritizeEmployeeTreeNodes } from './util';

interface Props {
  updateEmployeeTreeNodeValue: (emp: EmployeeTreeNodeValue) => void;
  employee: EmployeeInfoFragment | EmployeeInfoV2Fragment;
}

export const EmployeeTree = ({
  updateEmployeeTreeNodeValue,
  employee,
}: Props): ReactElement => {
  const { employee: currentEmployee } = useWhoAmI();
  const { employeeTreeNodeValue } = useContext(SidebarContext);
  const [employeeTreeNodes, setEmployeeTreeNodes] = useState<
    TreeNode<EmployeeTreeNodeValue>[]
  >([]);

  const [loading, setLoading] = useState(false);

  const useEmployeeV2 = useEmployeeV2Flag();
  const useViewSlackEmployeeTreeView = useViewSlackEmployeeTreeFlag();
  const { gray } = useTheme();
  const grayColor = gray[800];

  const [fireEmployeeDetailsQuery, { error: errorEmployeeDetailsQuery }] =
    useGetEmployeeDetailsLazyQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      onError: () => {
        setLoading(false);
      },
      onCompleted: ({ employee }) => {
        setEmployeeTreeNodes(
          prioritizeEmployeeTreeNodes(
            getEmployeeTreeNodes(
              employee?.parent
                ? [employee.parent]
                : employee
                ? [employee]
                : undefined,
              useViewSlackEmployeeTreeView,
              grayColor
            ),
            employee?.id,
            employee?.manager?.id
          )
        );
        setLoading(false);
        updateEmployeeTreeNodeValue({
          id: employee?.id || '',
          fullName: employee?.fullName || '',
          employeeRoleId: employee?.employeeRole?.id,
        });
      },
    });

  const [fireEmployeeDetailsQueryV2, { error: errorEmployeeDetailsQueryV2 }] =
    useGetEmployeeDetailsV2LazyQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      onError: () => {
        setLoading(false);
      },
      onCompleted: ({ employeeV2 }) => {
        setEmployeeTreeNodes(
          prioritizeEmployeeTreeNodes(
            getEmployeeTreeNodes(
              employeeV2?.parent
                ? [employeeV2.parent]
                : employeeV2
                ? [employeeV2]
                : undefined,
              useViewSlackEmployeeTreeView,
              grayColor
            ),
            employeeV2?.id,
            employeeV2?.manager?.id
          )
        );
        setLoading(false);
        updateEmployeeTreeNodeValue({
          id: employeeV2?.id || '',
          fullName: employeeV2?.fullName || '',
          employeeRoleId: employeeV2?.employeeRole?.id,
        });
      },
    });

  const [
    fireEmployeeParentDetailsQuery,
    { error: errorEmployeeParentDetailsQuery },
  ] = useGetEmployeeParentDetailsLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: () => {
      setLoading(false);
    },
    onCompleted: ({ employee }) => {
      setEmployeeTreeNodes(
        prioritizeEmployeeTreeNodes(
          getEmployeeTreeNodes(
            employee?.parent?.siblings,
            useViewSlackEmployeeTreeView,
            grayColor
          ),
          employee?.id,
          employee?.manager?.id
        )
      );
      setLoading(false);
      updateEmployeeTreeNodeValue({
        id: employee?.id || '',
        fullName: employee?.fullName || '',
        employeeRoleId: employee?.employeeRole?.id,
      });
    },
  });

  const [
    fireEmployeeParentDetailsQueryV2,
    { error: errorEmployeeParentDetailsQueryV2 },
  ] = useGetEmployeeParentDetailsV2LazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: () => {
      setLoading(false);
    },
    onCompleted: ({ employeeV2 }) => {
      setEmployeeTreeNodes(
        prioritizeEmployeeTreeNodes(
          getEmployeeTreeNodes(
            employeeV2?.parent?.siblings,
            useViewSlackEmployeeTreeView,
            grayColor
          ),
          employeeV2?.id,
          employeeV2?.manager?.id
        )
      );
      setLoading(false);
      updateEmployeeTreeNodeValue({
        id: employeeV2?.id || '',
        fullName: employeeV2?.fullName || '',
        employeeRoleId: employeeV2?.employeeRole?.id,
      });
    },
  });

  const fireQuery = useCallback(
    (employee) => {
      if (employee?.manager?.managerId) {
        useEmployeeV2
          ? fireEmployeeParentDetailsQueryV2({
              variables: {
                id: employee?.id || '',
                useViewSlackEmployeeTreeFlag: useViewSlackEmployeeTreeView,
              },
            })
          : fireEmployeeParentDetailsQuery({
              variables: {
                id: employee?.id || '',
              },
            });
      } else {
        useEmployeeV2
          ? fireEmployeeDetailsQueryV2({
              variables: {
                id: employee?.id || '',
                useViewSlackEmployeeTreeFlag: useViewSlackEmployeeTreeView,
              },
            })
          : fireEmployeeDetailsQuery({
              variables: {
                id: employee?.id || '',
              },
            });
      }
    },
    [employee]
  );

  useEffect(() => {
    if (employee) {
      setLoading(true);
      fireQuery(employee);
    }
  }, [employee]);

  useEffect(() => {
    if (currentEmployee && isNil(employeeTreeNodeValue)) {
      updateEmployeeTreeNodeValue({
        id: currentEmployee?.id || '',
        fullName: currentEmployee?.fullName || '',
        employeeRoleId: currentEmployee?.employeeRoleId,
      });
    }
  }, [currentEmployee]);

  return (
    <div style={{ width: '98%' }}>
      {errorEmployeeDetailsQuery ||
      errorEmployeeParentDetailsQuery ||
      errorEmployeeParentDetailsQueryV2 ||
      errorEmployeeDetailsQueryV2 ? (
        <div>Error...</div>
      ) : loading ? (
        <Loading delay={2000} />
      ) : (
        <EmployeeTreePicker
          updateEmployeeTreeNodeValue={updateEmployeeTreeNodeValue}
          employeeTreeNodeValue={employeeTreeNodeValue}
          employee={employee}
          currentEmployee={currentEmployee}
          employeeTreeNodes={employeeTreeNodes}
        />
      )}
    </div>
  );
};
