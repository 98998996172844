import * as TP from '../types';

import { gql } from '@apollo/client';
export type CarrierInfoSearchPageFragment = (
  { readonly __typename?: 'Carrier' }
  & Pick<TP.Carrier, 'id' | 'name' | 'code' | 'scacNumber' | 'mcNumber' | 'dotNumber' | 'levelId' | 'status'>
  & { readonly mainAddress?: TP.Maybe<(
    { readonly __typename?: 'Address' }
    & Pick<TP.Address, 'id' | 'city' | 'state'>
  )>, readonly reps?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'Rep' }
    & Pick<TP.Rep, 'id' | 'main'>
    & { readonly employee: (
      { readonly __typename?: 'Employee' }
      & Pick<TP.Employee, 'id' | 'firstName' | 'lastName'>
    ) }
  )>>, readonly opportunity?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name'>
  )>, readonly carrierServices?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'CarrierService' }
    & Pick<TP.CarrierService, 'id'>
    & { readonly carrierServiceType: (
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    ) }
  )>>, readonly certifications?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'CarrierCertification' }
    & Pick<TP.CarrierCertification, 'id'>
    & { readonly carrierCertificationType: (
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    ) }
  )>> }
);

export const CarrierInfoSearchPageFragmentDoc = gql`
    fragment CarrierInfoSearchPage on Carrier {
  id
  name
  code
  mainAddress {
    id
    city
    state
  }
  reps {
    id
    main
    employee {
      id
      firstName
      lastName
    }
  }
  scacNumber
  mcNumber
  dotNumber
  levelId
  status
  opportunity {
    id
    name
  }
  carrierServices {
    id
    carrierServiceType {
      id
      name
    }
  }
  certifications {
    id
    carrierCertificationType {
      id
      name
    }
  }
}
    `;