import * as TP from '../types';

import { gql } from '@apollo/client';
import { KeyValueInfoFragmentDoc } from './keyValueInfo';
export type FacilityAddressBriefFragment = (
  { readonly __typename?: 'FacilityAddress' }
  & Pick<TP.FacilityAddress, 'id' | 'addressTypeId' | 'city' | 'country' | 'main' | 'postalCode' | 'state' | 'street1' | 'street2' | 'riskInfoSource' | 'thirdPartyId' | 'isVerified'>
  & { readonly addressType?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly addressTypes?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id'>
  )>>, readonly geopoint: (
    { readonly __typename?: 'Geopoint' }
    & Pick<TP.Geopoint, 'lon' | 'lat'>
  ) }
);

export const FacilityAddressBriefFragmentDoc = gql`
    fragment FacilityAddressBrief on FacilityAddress {
  id
  addressType {
    ...KeyValueInfo
  }
  addressTypes {
    id
  }
  addressTypeId
  city
  country
  main
  postalCode
  state
  street1
  street2
  geopoint {
    lon
    lat
  }
  riskInfoSource
  thirdPartyId
  isVerified
}
    ${KeyValueInfoFragmentDoc}`;