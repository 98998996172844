import { BASE_HEADER_HEIGHT, IS_HEADER_ALLOWED } from '@app/Sidebar/constants';
import { ENV_VARS, MODE } from '@env';
import { sendSync } from './electron';
import { jsonParse } from './json';
import { win } from './win';

export const IS_CI = Boolean(ENV_VARS.VITE_BYPASS_AUTH);

export const devDomains = [
  'dev.mm100.mastermindtms.com',
  'mastery-frontend.dev.mm100.mastermindtms.com',
] as const;
type KnownDevDomains = typeof devDomains[number];

const pullDomains = ['mastery-frontend.pages.dev'] as const;
type KnownPullDomains = typeof pullDomains[number];

const previewDomains = [
  'test.mm100.mastermindtms.com',
  'test.td100.mastermindtms.com',
  'test.ml100.mastermindtms.com',
  'sandbox.shipmolo.com',
  'dev.sn100.mastermindtms.com',
  'test.sn100.mastermindtms.com',
  'upsidedown.shipmolo.com',
  'dev.we100.mastermindtms.com',
  'test.we100.mastermindtms.com',
  'dev.tb100.mastermindtms.com',
  'test.ru100.mastermindtms.com',
  'test.tm100.mastermindtms.com',
  'test.pr100.mastermindtms.com',
  'test.ls8fm.mastermindtms.com',
  'test.loadsmith.mastermindtms.com',
  'test.averitt.mastermindtms.com',
] as const;
type KnownPreviewDomains = typeof previewDomains[number];

const prodDomains = [
  /** "ml100.mastermindtms.com" below not actually deployed,
   * but we use it here for eventual config resolution */
  'ml100.mastermindtms.com',
  'mm.shipmolo.com',
  'mm100.mastermindtms.com',
  'molo.mastermindtms.com',
  'ru100.mastermindtms.com',
  'sn100.mastermindtms.com',
  'tb100.mastermindtms.com',
  'we100.mastermindtms.com',
] as const;
type KnownProdDomains = typeof prodDomains[number];

export type KnownDomains =
  | KnownDevDomains
  | KnownPullDomains
  | KnownPreviewDomains
  | KnownProdDomains;

export const tenantDomainArr: KnownDomains[] = [
  ...devDomains,
  ...pullDomains,
  ...previewDomains,
  ...prodDomains,
];

export enum KnownTenants {
  mm100 = 'mm100',
  sn100 = 'sn100',
  ml100 = 'ml100',
  tb100 = 'tb100',
  we100 = 'we100',
  ru100 = 'ru100',
  td100 = 'td100',
  tm100 = 'tm100',
  pr100 = 'pr100',
  // Loadsmith
  ls8fm = 'ls8fm',
  // Averitt
  ave4u = 'ave4u',
}

let hostname: fixMe = '';
try {
  hostname = win.location.hostname || '';
} catch {
  // noop
}

export enum EnvType {
  development = 'development',
  preview = 'preview',
  production = 'production',
  staging = 'staging',
  pull = 'pull',
}

let envStr: EnvType = EnvType.production;

if (MODE !== 'production') {
  envStr = EnvType.development;
}

export const IS_PR_PREVIEW = hostname.includes('mastery-frontend.pages.dev');

if (devDomains.includes(hostname)) {
  envStr = EnvType.staging;
} else if (previewDomains.includes(hostname)) {
  envStr = EnvType.preview;
} else if (IS_PR_PREVIEW) {
  envStr = EnvType.pull;
}

if (IS_CI) {
  envStr = EnvType.development;
}

export const IS_UNIT_TEST = MODE === 'test';
// ts-unused-exports:disable-next-line
export const IS_CYPRESS = Boolean((win as anyOk).Cypress);
export const IS_CY_COMPONENT_TEST =
  (win as anyOk).Cypress && (win as anyOk).Cypress.testingType === 'component';
/** If MODE === 'development' */
export const IS_LOCAL_DEV = MODE === 'development';
export const IS_LOCAL_CYPRESS = IS_CYPRESS && IS_LOCAL_DEV;
export const IS_LOCAL_DEV_NO_CY_NO_EMULATE =
  !IS_CYPRESS &&
  !ENV_VARS.VITE_EMULATE_DOMAIN &&
  (ENV_VARS.VITE_GRAPHQL_URL ?? '').match('localhost');
/** If MODE === 'production' */
export const IS_PRODUCTION_BUILD = MODE === 'production';

export const environment = envStr;

export const IS_NOT_PREVIEW_OR_PROD =
  environment !== 'preview' && environment !== 'production';

export const IS_NOT_PROD_ENV = environment !== 'production';

export const CHOSEN_ELECTRON_SPACE: undefined | string = sendSync(
  'store.get',
  'space'
);

export const USE_WALKME = Boolean(
  ENV_VARS.VITE_DISABLE_WALKME === 'false' ||
    ENV_VARS.NODE_ENV !== 'development'
);

/** Cypress can't open child windows, so we want to visit in same page. */
export const NEW_WINDOW_TARGET = IS_CYPRESS ? undefined : '_blank';

// ts-unused-exports:disable-next-line
export const IS_NODE = Boolean(
  typeof global !== 'undefined' && global.performance
);

export const IS_ELECTRON = Boolean(win.electron);

let SKIP_AUTH_VAL = false;
try {
  SKIP_AUTH_VAL = jsonParse(ENV_VARS.VITE_BYPASS_AUTH || 'false') || false;
} catch (err: anyOk) {
  // noop
}
if (IS_LOCAL_CYPRESS) {
  SKIP_AUTH_VAL = true;
}
if (IS_LOCAL_DEV_NO_CY_NO_EMULATE) {
  SKIP_AUTH_VAL = false;
}

let USE_SENTRY_VAL = environment !== 'development';
if (environment === 'development') {
  try {
    USE_SENTRY_VAL = jsonParse(ENV_VARS.VITE_USE_SENTRY || 'false') || false;
  } catch (err: anyOk) {
    // noop
  }
}

if (CHOSEN_ELECTRON_SPACE && !CHOSEN_ELECTRON_SPACE.match('localhost')) {
  SKIP_AUTH_VAL = false;
}

/** Use the real LaunchDarkly API instead of mocks */
let USE_LD_VAL = Boolean(
  environment !== 'development' || ENV_VARS.VITE_USE_LD === 'true'
);

if (environment === 'development' && ENV_VARS.VITE_EMULATE_DOMAIN) {
  SKIP_AUTH_VAL = false;
  USE_LD_VAL = true;
}

export const USE_LD = USE_LD_VAL;

export const USE_SENTRY = USE_SENTRY_VAL;
export const SKIP_AUTH = SKIP_AUTH_VAL;

export const MOCK_GEONAMES = ENV_VARS.VITE_MOCK_GEONAMES === 'true';
export const GEONAMES_TOKEN = '4e9c750f-c8ff-4c82-bb16-2cf7a363e997';
export const GEONAMES_ENDPOINT = 'https://ba-secure.geonames.net';

export const GEOGRAPHY_SOURCE = 'geonames';

export const SIDEBAR_SEARCH_HEIGHT = 59.6;

let IS_WINDOWS_OS_VAL = false;
try {
  if ((navigator as fixMe).userAgentData) {
    IS_WINDOWS_OS_VAL =
      (navigator as fixMe).userAgentData.platform === 'Windows';
  } else {
    IS_WINDOWS_OS_VAL = Boolean(navigator.appVersion.includes('Windows'));
  }
} catch {
  //
}
export const IS_WINDOWS_OS = IS_WINDOWS_OS_VAL;

export const HEADER_HEIGHT = IS_HEADER_ALLOWED ? BASE_HEADER_HEIGHT : 0;
export const BUILD_VERSION = ENV_VARS.VITE_BUILD_VERSION;
export const ALLOWED_DOC_TYPE_LIST = [
  '.doc',
  '.docx',
  '.pdf',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.txt',
  '.odt',
  '.ods',
];

export const EMPTY_ACCT_UUID = '00000000-0000-0000-0000-000000000000'; //empty UUID string sent by accounting service

export class KnownChargeTypes {
  public static FuelSurcharge = 'e11874f3-11d1-4170-aee1-95a457dcaded';
  public static BreakThroughFuelSurcharge =
    '86802a56-e022-49d3-bc4f-bfc3fede2f59';
  public static EstimatedFuelSurcharge = 'adb380b9-427d-4989-8436-f3941a0b57ef';
}
