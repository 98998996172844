import * as TP from '../types';

import { gql } from '@apollo/client';
import { CarrierItemV2FragmentDoc } from './carriersForCarrierPickerV2';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CarriersForMasterfindV2QueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.CarriersFilterV2>;
  first?: TP.InputMaybe<TP.Scalars['Int']>;
  last?: TP.InputMaybe<TP.Scalars['Int']>;
  after?: TP.InputMaybe<TP.Scalars['String']>;
  before?: TP.InputMaybe<TP.Scalars['String']>;
}>;


export type CarriersForMasterfindV2Query = (
  { readonly __typename?: 'Query' }
  & { readonly carriersNullableV2?: TP.Maybe<(
    { readonly __typename?: 'CarrierConnectionV2' }
    & { readonly edges: ReadonlyArray<(
      { readonly __typename?: 'CarrierEdgeV2' }
      & { readonly node: (
        { readonly __typename?: 'CarrierV2' }
        & Pick<TP.CarrierV2, 'id' | 'name' | 'code' | 'scacNumber' | 'currencyId'>
        & { readonly mainAddress?: TP.Maybe<(
          { readonly __typename?: 'CarrierAddress' }
          & Pick<TP.CarrierAddress, 'city' | 'state'>
        )> }
      ) }
    )> }
  )> }
);


export const CarriersForMasterfindV2Document = gql`
    query carriersForMasterfindV2($filter: CarriersFilterV2, $first: Int, $last: Int, $after: String, $before: String) {
  carriersNullableV2(
    filter: $filter
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    edges {
      node {
        ...CarrierItemV2
      }
    }
  }
}
    ${CarrierItemV2FragmentDoc}`;

/**
 * __useCarriersForMasterfindV2Query__
 *
 * To run a query within a React component, call `useCarriersForMasterfindV2Query` and pass it any options that fit your needs.
 * When your component renders, `useCarriersForMasterfindV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarriersForMasterfindV2Query({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useCarriersForMasterfindV2Query(baseOptions?: Apollo.QueryHookOptions<CarriersForMasterfindV2Query, CarriersForMasterfindV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CarriersForMasterfindV2Query, CarriersForMasterfindV2QueryVariables>(CarriersForMasterfindV2Document, options);
      }
export function useCarriersForMasterfindV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CarriersForMasterfindV2Query, CarriersForMasterfindV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CarriersForMasterfindV2Query, CarriersForMasterfindV2QueryVariables>(CarriersForMasterfindV2Document, options);
        }
export type CarriersForMasterfindV2QueryHookResult = ReturnType<typeof useCarriersForMasterfindV2Query>;
export type CarriersForMasterfindV2LazyQueryHookResult = ReturnType<typeof useCarriersForMasterfindV2LazyQuery>;
export type CarriersForMasterfindV2QueryResult = Apollo.QueryResult<CarriersForMasterfindV2Query, CarriersForMasterfindV2QueryVariables>;