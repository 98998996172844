import * as TP from '../types';

import { gql } from '@apollo/client';
import { CustomerPickerItemFragmentDoc } from '../fragments/customerPickerItem';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllCustomersForCustomerPickerQueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.CustomersFilter>;
  first?: TP.InputMaybe<TP.Scalars['Int']>;
}>;


export type AllCustomersForCustomerPickerQuery = (
  { readonly __typename?: 'Query' }
  & { readonly allCustomersNullable?: TP.Maybe<(
    { readonly __typename?: 'CustomerConnection' }
    & { readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & Pick<TP.PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), readonly edges: ReadonlyArray<(
      { readonly __typename?: 'CustomerEdge' }
      & Pick<TP.CustomerEdge, 'cursor'>
      & { readonly node: (
        { readonly __typename?: 'Customer' }
        & Pick<TP.Customer, 'code' | 'id' | 'name' | 'currencyId'>
        & { readonly mainAddress?: TP.Maybe<(
          { readonly __typename?: 'Address' }
          & Pick<TP.Address, 'id' | 'city' | 'state'>
        )>, readonly customerStatusType?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )> }
      ) }
    )> }
  )> }
);


export const AllCustomersForCustomerPickerDocument = gql`
    query allCustomersForCustomerPicker($filter: CustomersFilter, $first: Int) {
  allCustomersNullable(filter: $filter, first: $first) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...CustomerPickerItem
      }
    }
  }
}
    ${CustomerPickerItemFragmentDoc}`;

/**
 * __useAllCustomersForCustomerPickerQuery__
 *
 * To run a query within a React component, call `useAllCustomersForCustomerPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCustomersForCustomerPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCustomersForCustomerPickerQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAllCustomersForCustomerPickerQuery(baseOptions?: Apollo.QueryHookOptions<AllCustomersForCustomerPickerQuery, AllCustomersForCustomerPickerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCustomersForCustomerPickerQuery, AllCustomersForCustomerPickerQueryVariables>(AllCustomersForCustomerPickerDocument, options);
      }
export function useAllCustomersForCustomerPickerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCustomersForCustomerPickerQuery, AllCustomersForCustomerPickerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCustomersForCustomerPickerQuery, AllCustomersForCustomerPickerQueryVariables>(AllCustomersForCustomerPickerDocument, options);
        }
export type AllCustomersForCustomerPickerQueryHookResult = ReturnType<typeof useAllCustomersForCustomerPickerQuery>;
export type AllCustomersForCustomerPickerLazyQueryHookResult = ReturnType<typeof useAllCustomersForCustomerPickerLazyQuery>;
export type AllCustomersForCustomerPickerQueryResult = Apollo.QueryResult<AllCustomersForCustomerPickerQuery, AllCustomersForCustomerPickerQueryVariables>;