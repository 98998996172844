import { useFlag } from '@components/Flag';
import { FC } from 'react';
import {
  APP_FIX_COMBINE_DATE_TIME,
  APP_KEYCLOAK_MIN_VALIDITY_SECONDS_SYMBOL,
  APP_KEYCLOAK_TRIGGER_REFRESH_SECONDS_SYMBOL,
  APP_KEYCLOAK_USE_KEYCLOAK_USER_ID,
  APP_QUIET_NETWORK_ERRORS,
  APP_RELOAD_ON_AUTH_TIMEOUT_SYMBOL,
  APP_VERBOSE_ERROR_DISPLAY_SYMBOL,
} from './constants';
import { setGlobalVariable } from './util';

export const GlobalVariables: FC = () => {
  setGlobalVariable(
    APP_VERBOSE_ERROR_DISPLAY_SYMBOL,
    Boolean(useFlag('app-verbose-error-display'))
  );
  setGlobalVariable(
    APP_RELOAD_ON_AUTH_TIMEOUT_SYMBOL,
    Boolean(useFlag('app-reload-on-auth-timeout'))
  );
  setGlobalVariable(
    APP_KEYCLOAK_TRIGGER_REFRESH_SECONDS_SYMBOL,
    useFlag('app-keycloak-trigger-refresh-seconds')
  );
  setGlobalVariable(
    APP_KEYCLOAK_MIN_VALIDITY_SECONDS_SYMBOL,
    useFlag('app-keycloak-min-validity-seconds')
  );
  setGlobalVariable(
    APP_KEYCLOAK_USE_KEYCLOAK_USER_ID,
    useFlag('ME-17006-feat-keycloak-use-keycloak-user-id')
  );
  setGlobalVariable(
    APP_QUIET_NETWORK_ERRORS,
    Boolean(useFlag('ME-34235-app-quiet-network-errors'))
  );
  setGlobalVariable(
    APP_FIX_COMBINE_DATE_TIME,
    Boolean(useFlag('ME-34100-fix-combine-date-time'))
  );
  return null;
};
