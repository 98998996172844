import * as TP from '../types';

import { gql } from '@apollo/client';
import { KeyValueInfoFragmentDoc } from './keyValueInfo';
import { EmployeeSimpleFragmentDoc } from './employeeSimple';
export type EmployeeInfoFragment = (
  { readonly __typename?: 'Employee' }
  & Pick<TP.Employee, 'companyEmail' | 'divisionId' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeCompanyLevelId' | 'employeeDepartmentId' | 'employeeDepartmentLevelId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeRoleId' | 'employeeShirtSizeId' | 'employeeSuffixId' | 'employeeTimeId' | 'employeeTypeId' | 'ext' | 'firstName' | 'firstNameDotLastName' | 'fullName' | 'hireDate' | 'id' | 'lastName' | 'managerId' | 'middleName' | 'mobilePhone' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'relationship' | 'slackName' | 'subRegion' | 'termDate' | 'title' | 'userId'>
  & { readonly division?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly employeeGroup?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly employeeOffice?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly employeeStatus: (
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'active'>
  ), readonly manager?: TP.Maybe<(
    { readonly __typename?: 'Employee' }
    & Pick<TP.Employee, 'companyEmail' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeDepartmentId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeSuffixId' | 'ext' | 'firstName' | 'fullName' | 'id' | 'lastName' | 'middleName' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'relationship' | 'title' | 'userId' | 'managerId'>
  )> }
);

export const EmployeeInfoFragmentDoc = gql`
    fragment EmployeeInfo on Employee {
  companyEmail
  division {
    ...KeyValueInfo
  }
  divisionId
  email
  emergencyContact
  emergencyPhone
  employeeCompanyLevelId
  employeeDepartmentId
  employeeDepartmentLevelId
  employeeDisplayName
  employeeGenderId
  employeeGroupId
  employeeGroup {
    ...KeyValueInfo
  }
  employeeId
  employeeOfficeId
  employeeOffice {
    ...KeyValueInfo
  }
  employeeRoleId
  employeeShirtSizeId
  employeeStatus {
    id
    active
  }
  employeeSuffixId
  employeeTimeId
  employeeTypeId
  ext
  firstName
  firstNameDotLastName
  fullName
  hireDate
  id
  lastName
  managerId
  manager {
    ...EmployeeSimple
  }
  middleName
  mobilePhone
  nickname
  personalEmail
  phoneNumber
  relationship
  slackName
  subRegion
  termDate
  title
  userId
}
    ${KeyValueInfoFragmentDoc}
${EmployeeSimpleFragmentDoc}`;