import { WhoAmIDataFragment } from '@generated/queries/whoAmI';
import { WhoAmIDataV2Fragment } from '@generated/queries/whoAmIV2';
import { createContext, useContext } from 'react';

export type WhoAmIContextType = Omit<
  WhoAmIDataFragment | WhoAmIDataV2Fragment,
  'permissions'
>;

const defaultContext: WhoAmIContextType = {};

export const WhoAmIContext = createContext<WhoAmIContextType>(defaultContext);

export const useWhoAmI = (): WhoAmIContextType => useContext(WhoAmIContext);
