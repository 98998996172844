import * as TP from '../types';

import { gql } from '@apollo/client';
import { EmployeeDetailsFragmentDoc, EmployeeDetailsEmployeeTreeFragmentDoc } from '../fragments/EmployeeDetailsEmployeeTree';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEmployeeParentDetailsQueryVariables = TP.Exact<{
  id: TP.Scalars['ID'];
}>;


export type GetEmployeeParentDetailsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly employee?: TP.Maybe<(
    { readonly __typename?: 'Employee' }
    & Pick<TP.Employee, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
    & { readonly parent?: TP.Maybe<(
      { readonly __typename?: 'Employee' }
      & Pick<TP.Employee, 'id'>
      & { readonly siblings?: TP.Maybe<ReadonlyArray<(
        { readonly __typename?: 'Employee' }
        & Pick<TP.Employee, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
        & { readonly children?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'Employee' }
          & Pick<TP.Employee, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
          & { readonly children?: TP.Maybe<ReadonlyArray<(
            { readonly __typename?: 'Employee' }
            & Pick<TP.Employee, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
            & { readonly children?: TP.Maybe<ReadonlyArray<(
              { readonly __typename?: 'Employee' }
              & Pick<TP.Employee, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
              & { readonly employeeRole?: TP.Maybe<(
                { readonly __typename?: 'KeyValue' }
                & Pick<TP.KeyValue, 'id' | 'name'>
              )>, readonly employeeGroup?: TP.Maybe<(
                { readonly __typename?: 'KeyValue' }
                & Pick<TP.KeyValue, 'id' | 'name'>
              )>, readonly employeeOffice?: TP.Maybe<(
                { readonly __typename?: 'KeyValue' }
                & Pick<TP.KeyValue, 'id' | 'name'>
              )>, readonly division?: TP.Maybe<(
                { readonly __typename?: 'KeyValue' }
                & Pick<TP.KeyValue, 'id' | 'name'>
              )>, readonly manager?: TP.Maybe<(
                { readonly __typename?: 'Employee' }
                & Pick<TP.Employee, 'id' | 'fullName'>
              )> }
            )>>, readonly employeeRole?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'id' | 'name'>
            )>, readonly employeeGroup?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'id' | 'name'>
            )>, readonly employeeOffice?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'id' | 'name'>
            )>, readonly division?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'id' | 'name'>
            )>, readonly manager?: TP.Maybe<(
              { readonly __typename?: 'Employee' }
              & Pick<TP.Employee, 'id' | 'fullName'>
            )> }
          )>>, readonly employeeRole?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly employeeGroup?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly employeeOffice?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly division?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly manager?: TP.Maybe<(
            { readonly __typename?: 'Employee' }
            & Pick<TP.Employee, 'id' | 'fullName'>
          )> }
        )>>, readonly employeeRole?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly employeeGroup?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly employeeOffice?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly division?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly manager?: TP.Maybe<(
          { readonly __typename?: 'Employee' }
          & Pick<TP.Employee, 'id' | 'fullName'>
        )> }
      )>> }
    )>, readonly employeeRole?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly employeeGroup?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly employeeOffice?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly division?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly manager?: TP.Maybe<(
      { readonly __typename?: 'Employee' }
      & Pick<TP.Employee, 'id' | 'fullName'>
    )> }
  )> }
);


export const GetEmployeeParentDetailsDocument = gql`
    query getEmployeeParentDetails($id: ID!) {
  employee(id: $id) {
    ...EmployeeDetails
    parent {
      id
      siblings {
        ...EmployeeDetailsEmployeeTree
      }
    }
  }
}
    ${EmployeeDetailsFragmentDoc}
${EmployeeDetailsEmployeeTreeFragmentDoc}`;

/**
 * __useGetEmployeeParentDetailsQuery__
 *
 * To run a query within a React component, call `useGetEmployeeParentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeParentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeParentDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployeeParentDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeParentDetailsQuery, GetEmployeeParentDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeParentDetailsQuery, GetEmployeeParentDetailsQueryVariables>(GetEmployeeParentDetailsDocument, options);
      }
export function useGetEmployeeParentDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeParentDetailsQuery, GetEmployeeParentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeParentDetailsQuery, GetEmployeeParentDetailsQueryVariables>(GetEmployeeParentDetailsDocument, options);
        }
export type GetEmployeeParentDetailsQueryHookResult = ReturnType<typeof useGetEmployeeParentDetailsQuery>;
export type GetEmployeeParentDetailsLazyQueryHookResult = ReturnType<typeof useGetEmployeeParentDetailsLazyQuery>;
export type GetEmployeeParentDetailsQueryResult = Apollo.QueryResult<GetEmployeeParentDetailsQuery, GetEmployeeParentDetailsQueryVariables>;