import { compact } from 'lodash-es';
import { ReactElement } from 'react';
import {
  TreeNode,
  TreePicker,
  useTreePicker,
} from '../../../components/TreePicker';
import { EmployeeTreeNodeValue } from '../util';
import { EmployeeTreeInfoType } from './util';

interface Props {
  updateEmployeeTreeNodeValue: (emp: EmployeeTreeNodeValue) => void;
  employeeTreeNodeValue: Maybe<EmployeeTreeNodeValue>;
  employee: EmployeeTreeInfoType;
  currentEmployee: EmployeeTreeInfoType;
  employeeTreeNodes: TreeNode<EmployeeTreeNodeValue>[];
}

export const EmployeeTreePicker = ({
  updateEmployeeTreeNodeValue,
  employeeTreeNodeValue,
  currentEmployee,
  employee,
  employeeTreeNodes,
}: Props): ReactElement => {
  const props = useTreePicker({
    onChange: ({ checked }): void => {
      if (checked.length > 0) {
        const emp = checked[0]?.value;
        if (emp && emp?.id !== employeeTreeNodeValue?.id) {
          updateEmployeeTreeNodeValue(emp);
        }
      } else {
        updateEmployeeTreeNodeValue({
          id: currentEmployee?.id || '',
          fullName: currentEmployee?.fullName || '',
          employeeRoleId: currentEmployee?.employeeRoleId,
        });
      }
    },
    nodes: employeeTreeNodes,
    initialExpanded:
      employee?.manager?.id || employee?.id
        ? compact([employee?.manager?.id, employee.id])
        : undefined,
    initialChecked: employee?.id ? [employee.id] : undefined,
  });
  return <TreePicker {...props} />;
};
