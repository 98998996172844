import {
  CSSObject,
  Theme as ThemeInterface,
  ThemeProvider,
} from '@emotion/react';
import { useTheme } from '@hooks/useTheme';
import { IS_WINDOWS_OS } from '@utils/constants';
import { FC } from 'react';
import { colors, gray } from './colors';

export const INPUT_HEIGHT = 32;
export const BODY_FONT_SIZE = 12;

const tableCellBackground = 'white';
const tableCellAltBackground = gray[75];
const tableCellBorderColor = gray[300];

export const SCROLLBAR_WIDTH = IS_WINDOWS_OS ? 14 : 10;

const box = {
  borderColor: gray[300],
};

export const themeObj: ThemeInterface = {
  colors,
  gray,
  button: {
    background: 'white',
  },
  box,
  card: {
    background: 'white',
    boxShadow: '0 0 5px 3px rgba(0, 0, 0, 0.03)',
    borderRadius: 0,
  },
  contextMenu: {
    background: 'white',
  },
  fonts: {
    main: 'Roboto, sans-serif',
    code: 'source-code-pro, monospace',
  },
  formElement: {
    background: 'white',
    borderColor: box.borderColor,
    focused: {
      border: `1px solid ${colors.primary}`,
    },
    hasError: {
      border: `1px solid ${colors.error}`,
    },
    hasWarning: {
      border: `1px solid ${colors.warningDark}`,
    },
    readOnly: {
      background: gray[100],
    },
  },
  hr: {
    background: gray[200],
  },
  dividerStyle: {
    borderRight: `2px solid ${gray[200]}`,
    boxShadow: 'none',
  },
  dialog: {
    overlay: {
      background: 'rgba(0,0,0,.75)',
    },
    content: {
      background: 'white',
    },
  },
  shell: {
    content: {
      background: 'var(--body-color)',
    },
  },
  table: {
    tbody: {
      backgroundImage: `linear-gradient(0deg, ${tableCellAltBackground} 24.24%, ${tableCellBorderColor} 24.24%, ${tableCellBorderColor} 25%,  ${tableCellBackground} 25%, ${tableCellBackground} 49.24%, ${tableCellBorderColor} 49.24%, ${tableCellBorderColor} 50%, ${tableCellAltBackground} 50%, ${tableCellAltBackground} 74.24%, ${tableCellBorderColor} 74.24%, ${tableCellBorderColor} 75%, ${tableCellBackground} 75%, ${tableCellAltBackground} 99.24%, ${tableCellBorderColor} 99.24%, ${tableCellBorderColor} 100%)`,
      backgroundSize: '132px 128px',
      backgroundAttachment: 'local',
    },
    title: {
      background: 'white',
    },
    header: {
      background: gray[250],
      color: '#17191d',
    },
    row: {
      selectedRowBackground: colors.primaryLight,
    },
    cell: {
      background: tableCellBackground,
      borderTop: `1px solid ${tableCellBorderColor}`,
      alternateBackground: tableCellAltBackground,
      hoverBackground: gray[100],
    },
  },
  tooltip: {
    background: gray[75],
    color: '#17191d',
    border: `1px solid ${gray[300]}`,
  },
  sidebar: {
    background: 'white',
    link: {
      marginLeft: '-.5rem',
      fontSize: 14,
      padding: '.5rem',
      borderRadius: '3px',
      display: 'block',
      color: gray[900],
      textDecoration: 'none',
    },
    active: {
      background: '#e9f2fc',
      color: colors.ceruleanBlue,
      fontWeight: 'bold',
    },
  },
};

export const Theme: FC = ({ children }) => (
  <ThemeProvider theme={themeObj}>{children}</ThemeProvider>
);

export const useInputStyle = (): CSSObject => {
  const { colors, gray, fonts, formElement } = useTheme();
  return {
    border: `1px solid ${colors.text}`,
    borderRadius: '3px',
    color: 'inherit',
    fontSize: BODY_FONT_SIZE,
    fontFamily: fonts.main,
    padding: 8,
    width: '100%',
    display: 'block',
    lineHeight: 'normal',
    borderColor: formElement.borderColor,
    background: formElement.background,
    height: INPUT_HEIGHT,
    '&:focus': formElement.focused,
    '&.validation-error:focus': formElement.hasError,
    '&.validation-error': formElement.hasError,
    '&::placeholder': {
      color: gray['400'],
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
  };
};

export const useCardStyle = (): {
  whiteCard: CSSObject;
  grayCard: CSSObject;
} => {
  const { gray } = useTheme();

  const whiteCard: CSSObject = {
    boxShadow: 'none',
    paddingBottom: 8,
  };

  const grayCard: CSSObject = {
    boxShadow: 'none',
    backgroundColor: gray[100],
    paddingBottom: 8,
  };

  return { whiteCard, grayCard };
};
