import { auth0DataFromStorage } from '@components/AuthContext';
import { USE_LD } from '@utils/constants';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import { config } from '../../config';

const FakeLDProvider: FC = ({ children }) => <>{children}</>;

export const initLD = (): Promise<FC> => {
  /* istanbul ignore next: we do not use real LD in tests */
  if (USE_LD) {
    return asyncWithLDProvider({
      clientSideID: config.launchDarklyKey,
      user: auth0DataFromStorage || {
        anonymous: true,
        key: 'anonymous',
      },
      options: {
        bootstrap: 'localStorage',
      },
      reactOptions: {
        // https://docs.launchdarkly.com/docs/react-sdk-reference#section-flag-keys
        useCamelCaseFlagKeys: false,
      },
    });
  }
  return Promise.resolve(FakeLDProvider);
};
