import { APP_FIX_COMBINE_DATE_TIME } from '@app/GlobalVariables/constants';
import { getGlobalVariable } from '@app/GlobalVariables/util';
import { MODE } from '@env';
import { parseAbsolute, TimeFields } from '@internationalized/date';
import { IS_CY_COMPONENT_TEST } from '@utils/constants';
import {
  addDays,
  differenceInCalendarDays,
  differenceInMilliseconds,
  endOfDay,
  // eslint-disable-next-line no-restricted-imports
  format,
  isValid,
  parse,
  startOfDay,
} from 'date-fns';
import {
  compact,
  debounce,
  isNil,
  isNumber,
  isString,
  isUndefined,
  last,
  memoize,
  range,
  throttle,
  uniq,
} from 'lodash-es';
import timezones from 'timezones.json';
import { MergeExclusive } from 'type-fest';
import { CURRENT_LOCALE } from './util';

/** @deprecated Do not use! Use getDatetimeValue instead, which supports internationalization. */
export const dateFnsFormat = format;

export const MaxDate = new Date(8640000000000000);

export const millisToIntlSetObj = (ms: number): TimeFields => {
  const secs = ms / 1000;
  const hour = Math.floor(secs / 3600);
  const minute = Math.floor((secs % 3600) / 60);
  const second = Math.floor(secs % 60);
  const millisecond = Math.floor(ms % 1000);
  return {
    hour,
    minute,
    second,
    millisecond,
  };
};

interface DatetimeWithTimezoneInput {
  readonly timezone: string;
  readonly value: string;
}

export type IANATimezones =
  | 'America/Anchorage'
  | 'America/Los_Angeles'
  | 'America/Phoenix'
  | 'America/Denver'
  | 'America/Chicago'
  | 'America/New_York';

export const currentTZ: IANATimezones =
  (Intl.DateTimeFormat().resolvedOptions().timeZone as IANATimezones) ||
  'America/Chicago';

export const getTimezone = memoize((str: string): string => {
  let tz = str;
  if (tz === 'UTC') {
    return 'Etc/UTC';
  }
  // The following case is for legacy timezones like UTC or CDT. We want values like America/Chicago instead
  if (tz.length < 5) {
    tz = timezones.find((obj) => obj.abbr === str)?.utc[0] || '';
  }
  return tz;
});

export const getDateFromDatetime = (
  str: string,
  /** Use leading zeros for month and day, like 02/03. Defaults to true */
  rawLeadingZero?: boolean,
  withYear?: boolean
): string => {
  const useLeadingZero = rawLeadingZero === undefined ? true : rawLeadingZero;
  let result = str;
  if (str.match(/^\d?\d\/\d?\d\/\d\d\d\d/)) {
    result = withYear
      ? str.replace(/^(\d?\d\/\d?\d\/\d\d\d\d).*/, '$1')
      : str.replace(/^(\d?\d\/\d?\d).*/, '$1');
  } else if (str.match(/^\d?\d\/\d?\d\/\d\d/)) {
    result = withYear
      ? str.replace(/^(\d?\d\/\d?\d\/\d\d)/, '$1')
      : str.replace(/(.*\d?\d\/\d?\d)\/\d\d/, '$1');
  }
  if (useLeadingZero) {
    result = result
      .split('/')
      .map((n) => n.padStart(2, '0'))
      .join('/');
  }
  return result;
};

export const getLocaleDate = (
  dateObj: Date,
  tz?: string,
  locale?: undefined | 'en-US'
): string => {
  return dateObj.toLocaleString(locale ?? CURRENT_LOCALE, {
    timeZone: tz ? getTimezone(tz) : undefined,
    hour12: false,
    dateStyle: 'short',
  } as fixMe);
};

export const getDateFromLocaleDatetime = (
  dateObj: Date,
  tz?: string,
  withYear?: boolean,
  locale?: undefined | 'en-US'
): string => {
  return getDateFromDatetime(
    getLocaleDate(dateObj, tz, locale),
    undefined,
    withYear
  );
};

/** NOT FOR DISPLAYING TO USERS. Create a date string from a date object in MM/DD/YYYY format that is timezone aware. ie takes in UTC date and turns it into a localized date string based on tz. */
export const serverUtilityUTCDateToLocal = (
  str: Maybe<string | Date>,
  tz: Maybe<string>
): string | undefined => {
  if (!str || !tz) {
    return undefined;
  }
  const dateObj = new Date(str);
  if (isValid(dateObj)) {
    const tt = parseAbsolute(dateObj.toISOString(), tz ?? currentTZ);

    return [tt.month, tt.day, tt.year]
      .map((str) => str.toString().padStart(2, '0'))
      .join('/');
  }
  return undefined;
};

/** @deprecated Use `getDatetimeValue` for rendering to user. Use `serverUtilityUTCDateToLocal` to create a date string that is for the server in that is always in `MM/DD/YYYY` format. */
export const DEPRECATEDutcDatetimeToLocalDate = (
  /** The utcDatetime string like 2019-12-18T21:43:10.587Z */
  str: Maybe<string | Date>,
  /** The timezone like America/Chicago */
  tz: Maybe<string>,
  rawLeadingZero?: boolean,
  withYear?: boolean,
  showShortTZ?: boolean
): string | undefined => {
  if (!str) {
    return undefined;
  }
  try {
    const dateObj = new Date(str);
    if (isValid(dateObj)) {
      const rawStr = dateObj.toLocaleString(CURRENT_LOCALE, {
        timeZone: tz,
        hour12: false,
        ...(showShortTZ && {
          timeZoneName: 'short',
        }),
      } as fixMe);
      const dateStr = getDateFromDatetime(rawStr, rawLeadingZero, withYear);
      if (!showShortTZ) {
        return dateStr;
      }
      const timezoneStr = last((rawStr || '').split(' '));
      return compact([dateStr, timezoneStr]).join(' ');
    }
  } catch {
    // err
  }
  return undefined;
};

// eslint-disable-next-line no-console
const dateSatisfyReportThrottled = throttle((err) => console.error(err), 5000);

export const getTimeFromDatetimeString = (str: string): string => {
  if (!str) {
    return '';
  }
  const matches = str.match(/(\d\d:\d\d)/) || [];
  if (matches[1]) {
    const a = matches[1];
    return a === '24:00' ? '00:00' : a;
  } else {
    dateSatisfyReportThrottled(`${str} does not satisfy a valid Date`);
  }
  return str;
};

/** Convert a utc datetime string to a LOCAL time like 8:00:00 PM CST */
export const utcDatetimeToLocalTime = (
  /** The utcDatetime string like 2019-12-18T21:43:10.587Z */
  str: string | Date,
  /** The timezone like America/Chicago */
  tz: string
): string | undefined => {
  try {
    const dateObj = new Date(str);
    if (isValid(dateObj)) {
      const rawStr = dateObj
        .toLocaleString(CURRENT_LOCALE, {
          timeZone: getTimezone(tz),
          hour12: false,
          timeStyle: 'long',
        } as fixMe)
        // As of writing, chrome will take this date:
        // "1970-01-01T00:02:00.000Z"
        // And produce this result: 24:02:00
        // So we string replace here.
        .replace(/24(:\d\d:\d\d.*)/, '00$1');
      return getTimeFromDatetimeString(rawStr);
    }
  } catch (error) {
    if (MODE === 'development') {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }
  return undefined;
};

export const changeDateTimezoneToUtc = (
  str: string | null | undefined
): Date | null => {
  if (!str) {
    return null;
  }
  const localDate = new Date(str);
  return new Date(
    Date.UTC(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      localDate.getHours(),
      localDate.getMinutes(),
      localDate.getSeconds(),
      localDate.getMilliseconds()
    )
  );
};

export interface GetDatetimeShortOptions {
  showYear?: boolean;
  showShortTZ?: boolean;
}
/** Get a string like "12/19/19 11:25" */
export const getDatetimeShort = (
  rawVal: Maybe<string | Date>,
  tz?: string,
  options: GetDatetimeShortOptions = {
    showYear: true,
    showShortTZ: false,
  }
): string => {
  let d = rawVal;
  if (!d) {
    return '';
  }
  if (typeof d === 'string') {
    d = new Date(d);
  }
  const { showYear = true, showShortTZ = false } = options;
  return (
    d
      .toLocaleString('en-US', {
        hour12: false,
        ...(showYear && {
          year: '2-digit',
        }),
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: tz ? getTimezone(tz) : currentTZ,
        ...(showShortTZ && {
          timeZoneName: 'short',
        }),
      } as anyOk)
      .replace(/,/g, '')
      // chrome comes up with a time of 24:00 as of this comment writing, when the value should actually be 00:00
      // to try it:
      // new Date('2020-02-14T00:00:00').toLocaleString('en-US', {hour12: false})
      // firefox doesn't do this
      .replace(/\s24:(\d\d)/, ' 00:$1')
      .trim()
  );
};

export const getLocalDatetimeShort = (
  d: Date,
  options: { showYear: boolean } = { showYear: true }
): string => {
  return getDatetimeShort(d, undefined, { showYear: options.showYear });
};

/** Take a date and convert it to local string, given a timezone */
export const toBaseLocaleString = (d: Date, tz: string): string => {
  return d.toLocaleString('en-US', {
    hour12: true,
    timeZone: getTimezone(tz),
  });
};

/** Take date and convert it to a local Date object, given a timezone */
export const dateToTimezoneDate = (str: string | Date, tz: string): Date => {
  const ogDate = new Date(str);
  return parse(toBaseLocaleString(ogDate, tz), 'P, pp', ogDate);
};

/** Get the difference in milliseconds between a utc date object, and a given IANA timezone
 *  This is analagous to getting the "offset" of a timezone, but should be more bulletproof as it will respect DST more accurately.
 */
export const getTimezoneDiff = (utcDate: Date, tz: string): number =>
  differenceInMilliseconds(utcDate, dateToTimezoneDate(utcDate, tz));

/** Mutates to true UTC time from local time, taking tz into account */
export const localDateToUTC = (str: string | Date, tz: string): Date => {
  if (MODE !== 'production' && typeof str === 'string' && !str.endsWith('Z')) {
    throw new Error(
      'The ISO Date string passed to this function does not include the Z string at the end. This produces unexpected cross-browser results.'
    );
  }
  const ogDate = new Date(str);
  const localTime = dateToTimezoneDate(str, tz);
  const diffInMillis = ogDate.valueOf() - localTime.valueOf();
  return new Date(ogDate.valueOf() + diffInMillis);
};

/** Mutates to local time from UTC time, taking tz into account */
export const utcDateToLocal = (str: string | Date, tz: string): Date => {
  return dateToTimezoneDate(str, tz);
};

export const getTimeFromLocaleDatetime = (
  dateObj: Date,
  tz: string
): string => {
  return getTimeFromDatetimeString(getDatetimeShort(dateObj, tz));
};

interface FormatRangeArgs {
  start: Maybe<string | Date>;
  end: Maybe<string | Date>;
  tz: string;
  showShortTZ?: boolean;
}

export const formatDateRange = (
  { start, end, tz }: FormatRangeArgs,
  withYear?: boolean | undefined
): string => {
  let startStr = '';
  if (start) {
    startStr = getDateFromLocaleDatetime(new Date(start), tz, withYear);
  }
  let endStr = startStr;
  if (end) {
    endStr = getDateFromLocaleDatetime(new Date(end), tz, withYear);
  }
  return compact(uniq([startStr, endStr])).join(' - ');
};

const isoDateRegex = /\d\d\d\d-\d\d-\d\dT\d\d:\d\d/;

// eslint-disable-next-line no-console
const consoleErrorDebounced = debounce(console.error, 3000, { leading: true });

export const checkForValidCoercion = (dateStr: string | Date): Date => {
  const startDate = new Date(dateStr);
  if (MODE !== 'production') {
    if (!isValid || (isString(dateStr) && !isoDateRegex.test(dateStr))) {
      consoleErrorDebounced(
        new Error(
          `Value passed to formatTimeRange start of ${dateStr} cannot be coerced to a valid Date object.`
        )
      );
    }
  }
  return startDate;
};

export const formatTimeRange = ({
  start,
  end,
  tz,
  showShortTZ,
}: FormatRangeArgs): string => {
  let startStr = '',
    timezoneStr = '';
  if (start) {
    const startDate = checkForValidCoercion(start);
    startStr = getTimeFromLocaleDatetime(startDate, tz);
  }
  let endStr = startStr;
  if (end) {
    const endDate = checkForValidCoercion(end);
    endStr = getTimeFromLocaleDatetime(endDate, tz);
  }
  if (showShortTZ) {
    const dateTime = getDatetimeShort(start, tz, { showShortTZ: true }).split(
      ' '
    );
    if (dateTime.length > 1) {
      timezoneStr = ' ' + dateTime[2];
    }
  }
  return `${compact(uniq([startStr, endStr])).join(' - ')}${timezoneStr}`;
};

export const TimeStringToMsOffsetUTC = (
  timeString?: string
): number | undefined => {
  if (!timeString) {
    return undefined;
  }
  // Converts string in HH:mm or h:mm 24-hour format to number of Milliseconds offset from UNIX epoch.
  // Returns undefined for empty or invalid timeString.
  if (!timeString.length || !timeString.match(/[0-2]?\d:\d\d/)) {
    return undefined;
  }
  const theDate = new Date(0);
  timeString = timeString.padStart(5, '0'); //Pad leading 0 if it's missing.
  theDate.setUTCHours(parseInt(timeString.substring(0, 2)));
  theDate.setUTCMinutes(parseInt(timeString.substring(3)));
  return theDate.getTime();
};

export const MsOffsetUTCToTimeString = (
  timeOffsetMs: number | string
): string => {
  // Takes a number of Milliseconds offset from UNIX epoch and converts to 24-hour time.
  // Can be used with Formik which passes value in as string or with functions passing value in as a number.
  let timeOffsetAsNumber;
  if (typeof timeOffsetMs === 'number') {
    timeOffsetAsNumber = timeOffsetMs;
  } else if (typeof timeOffsetMs === 'string' && timeOffsetMs.length) {
    timeOffsetAsNumber = parseInt(timeOffsetMs);
  }
  if (!isUndefined(timeOffsetAsNumber)) {
    const theDate = new Date();
    theDate.setTime(timeOffsetAsNumber);
    return getTimeFromDatetimeString(theDate.toUTCString());
  } else {
    return '';
  }
};

/** Takes ms and provides a Date object back (1970 epoch) */
export const MsOffsetToDate = (
  timeOffsetMs: number | string
): Date | undefined => {
  let timeOffsetAsNumber;
  if (typeof timeOffsetMs === 'number') {
    timeOffsetAsNumber = timeOffsetMs;
  } else if (typeof timeOffsetMs === 'string' && timeOffsetMs.length) {
    timeOffsetAsNumber = parseInt(timeOffsetMs);
  }
  if (!isUndefined(timeOffsetAsNumber)) {
    const theDate = new Date();
    theDate.setTime(timeOffsetAsNumber);
    return theDate;
  }
  return undefined;
};

export const getDateMillis = (d: Date): number => {
  const hours = d.getHours();
  const minutes = d.getMinutes();
  const seconds = d.getSeconds();
  return hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
};

export const dateToMsOffset = (
  originalDate?: Date | string | null,
  tz = 'Etc/UTC'
): number | undefined => {
  if (originalDate === undefined || originalDate === null) {
    return undefined;
  }
  const d = shiftDateByTimezone(new Date(originalDate), tz, currentTZ);
  return getDateMillis(d);
};

export const format24HrTimeOffsetRange = (
  startMSOffset: number | null,
  endMSOfset: number | null
): string => {
  const vals = [startMSOffset, endMSOfset].filter(isNumber);
  return uniq(vals.map(MsOffsetUTCToTimeString)).join(' - ');
};

export const getTimezoneAbbreviation = memoize((str: string): string => {
  return timezones.find((obj) => obj.utc.find((tz) => tz == str))?.abbr || '';
});

const formatsWithYear: Array<[string, RegExp]> = [
  ['MM/dd/yyyy', /^\d\d\/\d\d\/\d\d\d\d$/],
  ['yyyy-MM-dd', /^\d\d\d\d-\d\d-\d\d$/],
  ['MM/dd/yy', /^\d\d\/\d\d\/\d\d$/],
  ['MM/dd/yy', /^\d\/\d\d\/\d\d$/],
  ['MM/dd/yy', /^\d\d\/\d\/\d\d$/],
  ['MM/dd/yy', /^\d\/\d\/\d\d$/],
  ['MM/dd/yy', /^\d\d\d\d\d\d\d\d$/],
  ['MM/dd/yyyy', /^\d\/\d\d\/\d\d\d\d$/],
  ['MM/dd/yyyy', /^\d\/\d\/\d\d\d\d$/],
  ['MM/d/yyyy', /^\d\d\/\d\/\d\d\d\d$/],
];

export const strHasYear = (str: string): boolean => {
  for (const [, matcher] of formatsWithYear) {
    if (str.match(matcher)) {
      return true;
    }
  }
  return false;
};

const supportedFormats: Array<[string, RegExp]> = formatsWithYear.concat([
  ['MM/dd', /^\d\d\/\d\d$/],
  ['MM/d', /^\d\d\/\d$/],
  ['M/dd', /^\d\/\d\d$/],
  ['M/d', /^\d\/\d$/],
  ['MMdd', /^\d\d\d\d$/],
  ['MM/dd', /^\d\d-\d\d$/],
  ['MM/d', /^\d\d-\d$/],
  ['MMd', /^\d\d\d$/],
  ['MM', /^\d\d$/],
]);

export const parseStringDate = (rawStr: string): Date | undefined => {
  try {
    const str = (rawStr || '').trim();
    for (const [template, matcher] of supportedFormats) {
      if (str.match(matcher)) {
        return parse(str, template, new Date());
      }
    }
  } catch {
    // noop
  }
  return undefined;
};

export const createDateWithTimezone = (
  str: string,
  tz: string
): Date | undefined => {
  const parsed = parseStringDate(str);
  if (!parsed) {
    return undefined;
  }
  const diff = getTimezoneDiff(parsed, tz);
  return new Date(parsed.valueOf() + diff);
};

/** Mutates the date by shifting it N number of hours away from the user's timezone. Helpful for things like the Datepicker which always assumes local time. */
export const shiftDateByTimezone = (
  d: Date,
  tzFrom: string,
  tzTo: string
): Date => {
  const userTZ = tzFrom || currentTZ;
  const fromDate = dateToTimezoneDate(d, userTZ);
  const toDate = dateToTimezoneDate(d, tzTo);
  const diff = (fromDate?.valueOf() || 0) - (toDate?.valueOf() || 0);
  return new Date(d.valueOf() + diff);
};

// ts-unused-exports:disable-next-line
export enum DayOfWeek {
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
}

export const getDayOfWeek = (
  rawDate: Maybe<Date | string>,
  tz: string
): DayOfWeek | undefined => {
  if (!rawDate) {
    return undefined;
  }
  const d = new Date(rawDate);
  const shifted = shiftDateByTimezone(d, tz, 'Etc/UTC');
  return DayOfWeek[shifted.getUTCDay()] as unknown as DayOfWeek;
};

export const getStartOfDay = (
  rawDate: Maybe<Date | string>,
  tz: string
): Date | undefined => {
  if (!rawDate) {
    return undefined;
  }
  const shiftedDate = shiftDateByTimezone(new Date(rawDate), tz, currentTZ);
  return shiftDateByTimezone(startOfDay(shiftedDate), currentTZ, tz);
};

/** Get a ISO-compliant date like 2022-04-12 that takes TZ into account. As of writing, Datepicker component defaults to user's current TZ. To get a date part for use in some APIs, we shift the date by UTC offset and use ISOString. This util is good for when locale is NOT involved. */
export const getISODatePart = (
  rawDate: Maybe<Date | string>,
  /** Defaults to currentTZ, like a date generated by Datepicker */
  tz?: string
): string | undefined => {
  if (!rawDate) {
    return undefined;
  }
  return shiftDateByTimezone(new Date(rawDate), tz ?? currentTZ, 'Etc/UTC')
    .toISOString()
    .slice(0, 10);
};

export const getEndOfDay = (
  rawDate: Maybe<Date | string>,
  tz: string
): Date | undefined => {
  if (!rawDate) {
    return undefined;
  }
  const shiftedDate = shiftDateByTimezone(new Date(rawDate), tz, currentTZ);
  return shiftDateByTimezone(endOfDay(shiftedDate), currentTZ, tz);
};

const DEPRECATEDcombineDateAndTime = (
  rawDate: Date | string,
  rawTime: Date | number | string,
  sourceTz: string = currentTZ
): Date => {
  const date = shiftDateByTimezone(new Date(rawDate), sourceTz, currentTZ);
  const time = shiftDateByTimezone(new Date(rawTime), sourceTz, currentTZ);
  const timeOffset = time.getTime() - startOfDay(time).getTime();
  const dateTime = new Date(startOfDay(date).getTime() + timeOffset);
  return shiftDateByTimezone(dateTime, currentTZ, sourceTz);
};

const DEPRECATEDcombineDateAndTimeOffset = (
  date: Date | string,
  timeOffsetMs: number
): Date => {
  const dateMs = new Date(date).getTime();
  return new Date(dateMs + timeOffsetMs);
};

interface BaseProps {
  date: Date | string;
  timezone?: string;
}

interface WithTime {
  time: Date | number | string;
}

interface WithTimeOffset {
  timeOffsetMs: number;
}

type CombineDateAndTimeArgs = BaseProps &
  MergeExclusive<WithTime, WithTimeOffset>;

/** Combine two dates, one that signifies the day to be taken, the other the time to be taken. Used in some of our forms where we have two independent inputs. The function assumes both values originate from the same timezone. */
export const combineDateAndTime = ({
  date,
  time,
  timeOffsetMs,
  timezone = currentTZ,
}: CombineDateAndTimeArgs): Date => {
  const useNewBehavior =
    getGlobalVariable(APP_FIX_COMBINE_DATE_TIME) ?? IS_CY_COMPONENT_TEST;
  if (!useNewBehavior) {
    if (time != null) {
      return DEPRECATEDcombineDateAndTime(date, time, timezone);
    } else if (timeOffsetMs != null) {
      return DEPRECATEDcombineDateAndTimeOffset(date, timeOffsetMs);
    } else {
      return DEPRECATEDcombineDateAndTimeOffset(date, 0);
    }
  }

  const parsedTime = parseAbsolute(
    time ? new Date(time).toISOString() : new Date().toISOString(),
    timezone
  );
  const parsedDate = parseAbsolute(
    date ? new Date(date).toISOString() : new Date().toISOString(),
    timezone
  );
  if (time) {
    return parsedDate
      .set({
        hour: parsedTime.hour,
        minute: parsedTime.minute,
        second: parsedTime.second,
        millisecond: parsedTime.millisecond,
      })
      .toDate();
  } else if (timeOffsetMs) {
    return parsedDate.set(millisToIntlSetObj(timeOffsetMs)).toDate();
  }
  return parsedDate.toDate();
};

export const getTodayWithTimezone = (): Date | undefined => {
  return createDateWithTimezone(format(new Date(), 'yyyy-MM-dd'), currentTZ);
};

export const sortByDate = (a: string, b: string): number => {
  const a1 = new Date(a).valueOf();
  const b1 = new Date(b).valueOf();
  if (a1 < b1) {
    return 1;
  } else if (a1 > b1) {
    return -1;
  } else {
    return 0;
  }
};

/** Takes a zone like America/Chicago and returns a string like CST or CDT */
export const getTimezoneAbbr = (
  ianaTimezoneString: Maybe<string>,
  date?: Maybe<Date>
): string | undefined => {
  if (!ianaTimezoneString) {
    return undefined;
  }
  const d = createDateWithTimezone(
    format(date || new Date(), 'yyyy-MM-dd'),
    ianaTimezoneString
  );
  if (d) {
    const parsed = getDatetimeShort(d, ianaTimezoneString, {
      showYear: false,
      showShortTZ: true,
    });
    const found = last(parsed.split(' '));
    // IANA timezone abbr list seems to require at least two characters
    // https://en.wikipedia.org/wiki/List_of_time_zone_abbreviations
    if (found?.match(/\w\w/)) {
      return found;
    }
  }
  return undefined;
};

export const getFormattedStartEndAvailable = (
  stop: Maybe<{
    availableStart?: Maybe<DatetimeWithTimezoneInput>;
    availableEnd?: Maybe<DatetimeWithTimezoneInput>;
  }>,
  tz: Maybe<string>
): string => {
  if (!stop) {
    return '';
  }
  return formatDateRange({
    start: stop.availableStart?.value,
    end: stop.availableEnd?.value,
    tz: stop.availableStart?.timezone || tz || currentTZ,
  });
};

interface BetweenDaysKwargs {
  start: Maybe<Date | string>;
  startTimezone: Maybe<string>;
  end: Maybe<Date | string>;
  endTimezone: Maybe<string>;
  /** Include start and end in array */
  inclusive?: boolean;
}

export const getDatesWithinRange = (
  kwargs: BetweenDaysKwargs
): Array<Date> | undefined => {
  const { start, startTimezone, end, endTimezone, inclusive } = kwargs;
  if (!start || !startTimezone || !end || !endTimezone) {
    return undefined;
  }
  const diff = differenceInCalendarDays(new Date(end), new Date(start));
  const middle = range(diff).map((i) => {
    return addDays(new Date(start), i + 1);
  });
  return compact([inclusive && new Date(start), ...middle]);
};

const arrIsFull = <T extends unknown>(arr: Maybe<T>[]): arr is T[] => {
  return !arr.find(isNil);
};

export const getNamedDaysBetweenDates = (
  kwargs: BetweenDaysKwargs
): undefined | DayOfWeek[] => {
  const { start, startTimezone, end, endTimezone } = kwargs;
  if (!start || !startTimezone || !end || !endTimezone) {
    return undefined;
  }
  const dates = getDatesWithinRange(kwargs);
  const arr = dates?.map((d) => {
    if (!kwargs.startTimezone) {
      return undefined;
    }
    return getDayOfWeek(d, kwargs.startTimezone);
  });
  if (!arr || !arrIsFull(arr)) {
    return undefined;
  }
  return arr;
};

/* example: 1442 minutes => 1 day, 0 hour, 2 minutes */
export const minutesToTimespan = (minutes: Maybe<number>): string => {
  if (!minutes || minutes < 0) {
    return '';
  }

  let hours = Math.floor(minutes / 60);
  minutes = Math.floor(minutes - hours * 60);
  const days = Math.floor(hours / 24);
  hours = Math.floor(hours - days * 24);

  let dShow;
  if (days == 0) {
    dShow = '';
  } else if (days == 1) {
    dShow = `${days} day`;
  } else {
    dShow = `${days} days`;
  }

  const dSeparator = days == 0 || (hours == 0 && minutes == 0) ? '' : ', ';

  let hShow;
  if (
    (days == 0 && hours == 0) ||
    (hours == 0 && minutes == 0) ||
    (days != 0 && hours == 0 && minutes != 0)
  ) {
    hShow = '';
  } else if (hours == 1) {
    hShow = `${hours} hour`;
  } else {
    hShow = `${hours} hours`;
  }

  const hSeparator =
    (days == 0 && hours == 0) ||
    (hours == 0 && minutes == 0) ||
    (days != 0 && hours == 0 && minutes != 0) ||
    minutes == 0
      ? ''
      : ', ';

  let mShow;
  if (minutes == 0) {
    mShow = '';
  } else if (minutes == 1) {
    mShow = `${minutes} minute`;
  } else {
    mShow = `${minutes} minutes`;
  }

  return `${dShow}${dSeparator}${hShow}${hSeparator}${mShow}`;
};

/* Returns string as YYYY-MM-DD HH:mm:SS.sss */
export const getUTCDateTime = (date: Date): string => {
  const padTo2Digits = (num: number): string => {
    return num.toString().padStart(2, '0');
  };
  return (
    [
      date.getUTCFullYear(),
      padTo2Digits(date.getUTCMonth() + 1),
      padTo2Digits(date.getUTCDate()),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getUTCHours()),
      padTo2Digits(date.getUTCMinutes()),
      padTo2Digits(date.getUTCSeconds()),
    ].join(':') +
    '.' +
    date.getUTCMilliseconds().toString().padStart(3, '0')
  );
};

export const getTimezoneDiffHours = (date: Date): string => {
  return date.toString().split(' ').splice(5, 1).join().slice(3);
};
