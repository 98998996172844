/* eslint-disable no-restricted-imports */
import { LinkTo } from '@components/Link';
import { ReactElement } from 'react';
import {
  NavLink as RawNavLink,
  NavLinkProps as RawNavLinkProps,
} from 'react-router-dom';

interface NavLinkProps extends Omit<RawNavLinkProps, 'to'> {
  to: LinkTo;
}

export const NavLink = (props: NavLinkProps): ReactElement => {
  return <RawNavLink {...props} />;
};
