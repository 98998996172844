import { AddressDisplay } from '@components/AddressDisplay';
import { useViewOnly } from '@components/ViewOnly';
import {
  CarrierItemFragment,
  CarriersForCarrierPickerQuery,
  CarriersForCarrierPickerQueryVariables,
  useCarriersForCarrierPickerLazyQuery,
} from '@generated/queries/carriersForCarrierPicker';
import {
  CarrierItemV2Fragment,
  CarriersForCarrierPickerV2Query,
  CarriersForCarrierPickerV2QueryVariables,
  useCarriersForCarrierPickerV2LazyQuery,
} from '@generated/queries/carriersForCarrierPickerV2';
import { CarriersFilter } from '@generated/types';
import { useCarrierV2Flag } from '@hooks/useCarrierV2Flag';
import { useDebouncedFn } from '@hooks/useDebouncedFn';
import { pipeSeparator } from '@utils/htmlEntities';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  AutoComplete,
  Props as AutoCompleteProps,
} from '../../../components/AutoComplete';

export const CarrierPickerItemDisplay: FC<{
  carrier: Maybe<CarrierItemFragment | CarrierItemV2Fragment>;
  compact?: boolean;
}> = ({ carrier, compact }) => (
  <div>
    <strong>{carrier?.name}</strong>
    {compact ? <br /> : ' | '}
    <span>
      {carrier?.code}
      {carrier?.mainAddress && pipeSeparator}
      <AddressDisplay value={carrier?.mainAddress} city state />
    </span>
  </div>
);

export const renderCarrierResult: AutoCompleteProps<
  CarrierItemFragment | CarrierItemV2Fragment
>['renderItem'] = ({
  item,
  key,
  defaultItemStyles,
  getItemProps,
  isHighlighted,
  index,
}) => {
  const { value: carrier } = item;
  return (
    <li
      key={key}
      css={{
        ...defaultItemStyles,
        ...{
          display: 'block',
          width: '100%',
          padding: '0.5rem',
          textAlign: 'left',
        },
      }}
      {...getItemProps({
        item,
        index,
        key,
        style: {
          backgroundColor: isHighlighted ? '#0f70e7' : 'white',
          color: isHighlighted ? 'white' : 'inherit',
        },
      })}
      data-testid={carrier?.id ?? ''}
    >
      <CarrierPickerItemDisplay carrier={carrier} />
    </li>
  );
};

interface Props
  extends Omit<
    AutoCompleteProps<CarrierItemFragment | CarrierItemV2Fragment>,
    'items'
  > {
  inputProps?: AutoCompleteProps<unknown>['inputProps'];
  carrierFilters?: Partial<CarriersFilter>;
}

const useSearchCarriers = (): [
  (filter: CarriersForCarrierPickerQueryVariables['filter']) => void,
  { loading: boolean; data: CarrierItemFragment[] }
] => {
  const [prevData, setPrevData] =
    useState<CarriersForCarrierPickerQuery | null>(null);
  const [rawGet, { loading, data }] = useCarriersForCarrierPickerLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const get = useCallback(
    (filter: CarriersForCarrierPickerQueryVariables['filter']) => {
      if (!filter?.text) {
        return;
      }
      return rawGet({ variables: { first: 15, filter } });
    },
    [rawGet]
  );
  useEffect(() => {
    if (data) {
      setPrevData(data);
    }
  }, [data]);
  return [
    get,
    {
      loading,
      // We use the previous data response while waiting for the new one to avoid a flash of no items in the dropdown, which is bad UX
      data: loading
        ? prevData?.carriersNullable?.edges.map((e) => e.node) || []
        : data?.carriersNullable?.edges.map((e) => e.node) || [],
    },
  ];
};

const useSearchCarriersV2 = (): [
  (filter: CarriersForCarrierPickerV2QueryVariables['filter']) => void,
  { loading: boolean; data: CarrierItemV2Fragment[] }
] => {
  const [prevData, setPrevData] =
    useState<CarriersForCarrierPickerV2Query | null>(null);
  const [rawGet, { loading, data }] = useCarriersForCarrierPickerV2LazyQuery({
    fetchPolicy: 'no-cache',
  });
  const get = useCallback(
    (filter: CarriersForCarrierPickerV2QueryVariables['filter']) => {
      if (!filter?.text) {
        return;
      }
      return rawGet({ variables: { first: 15, filter } });
    },
    [rawGet]
  );
  useEffect(() => {
    if (data) {
      setPrevData(data);
    }
  }, [data]);
  return [
    get,
    {
      loading,
      // We use the previous data response while waiting for the new one to avoid a flash of no items in the dropdown, which is bad UX
      data: loading
        ? prevData?.carriersNullableV2?.edges.map((e) => e.node) || []
        : data?.carriersNullableV2?.edges.map((e) => e.node) || [],
    },
  ];
};

export const CarrierPicker: FC<Props> = ({
  inputProps,
  carrierFilters,
  ...rest
}) => {
  const useCarrierV2 = useCarrierV2Flag();
  const [searchCarriers, { data: carriersV1, loading: loadingV1 }] =
    useSearchCarriers();
  const [searchCarriersV2, { data: carriersV2, loading: loadingV2 }] =
    useSearchCarriersV2();

  const carriers = useCarrierV2 ? carriersV2 : carriersV1;
  const loading = useCarrierV2 ? loadingV2 : loadingV1;
  const debouncedSearchCarriers = useDebouncedFn(
    useCarrierV2 ? searchCarriersV2 : searchCarriers,
    500,
    []
  );

  const { isViewOnly } = useViewOnly();

  const items = carriers.map((carrier) => ({
    value: carrier,
    label: carrier.name,
    id: carrier.id,
  }));

  return isViewOnly ? (
    <div data-testid={rest.name || 'carrier-search'}>
      {rest.selectedItem?.label}
    </div>
  ) : (
    <AutoComplete
      onInputValueChange={(inputValue): void => {
        debouncedSearchCarriers({
          text: inputValue,
          ...carrierFilters,
        });
      }}
      data-testid={rest.name || 'carrier-search'}
      name={rest.name || 'carrier-search'}
      loading={loading}
      items={items}
      renderItem={renderCarrierResult}
      inputProps={{
        placeholder: 'Search',
        'data-testid': 'carrier-search-input',
        name: 'carrier-search-input',
        css: {
          height: '100%',
          position: 'relative',
        },
        ...inputProps,
      }}
      {...rest}
    />
  );
};
