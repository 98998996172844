/* eslint-disable no-restricted-globals */
export function jsonParse<T>(
  str: string | boolean,
  fallback?: T,
  shouldLog?: false
): T | undefined {
  let ret = fallback || undefined;
  try {
    ret = JSON.parse(str as anyOk);
  } catch (err: anyOk) {
    if (shouldLog !== false) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
  return ret;
}

/** https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#Syntax */
export const jsonStringify = (
  ...params: Parameters<typeof JSON['stringify']>
): string => JSON.stringify(...params);
