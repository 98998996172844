import * as TP from '../types';

import { gql } from '@apollo/client';
import { EmployeeDetailsV2FragmentDoc, EmployeeDetailsEmployeeTreeV2FragmentDoc } from '../fragments/employeeDetailsEmployeeTreeV2';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEmployeeParentDetailsV2QueryVariables = TP.Exact<{
  id: TP.Scalars['ID'];
  useViewSlackEmployeeTreeFlag?: TP.Scalars['Boolean'];
}>;


export type GetEmployeeParentDetailsV2Query = (
  { readonly __typename?: 'Query' }
  & { readonly employeeV2?: TP.Maybe<(
    { readonly __typename?: 'EmployeeV2' }
    & Pick<TP.EmployeeV2, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
    & { readonly parent?: TP.Maybe<(
      { readonly __typename?: 'EmployeeV2' }
      & Pick<TP.EmployeeV2, 'id'>
      & { readonly siblings?: TP.Maybe<ReadonlyArray<(
        { readonly __typename?: 'EmployeeV2' }
        & Pick<TP.EmployeeV2, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
        & { readonly children?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'EmployeeV2' }
          & Pick<TP.EmployeeV2, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
          & { readonly children?: TP.Maybe<ReadonlyArray<(
            { readonly __typename?: 'EmployeeV2' }
            & Pick<TP.EmployeeV2, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
            & { readonly children?: TP.Maybe<ReadonlyArray<(
              { readonly __typename?: 'EmployeeV2' }
              & Pick<TP.EmployeeV2, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
              & { readonly messagingUserIdentities?: TP.Maybe<ReadonlyArray<(
                { readonly __typename?: 'MessagingV2' }
                & Pick<TP.MessagingV2, 'employeeId' | 'serverId' | 'type' | 'userId'>
              )>>, readonly employeeRole?: TP.Maybe<(
                { readonly __typename?: 'KeyValue' }
                & Pick<TP.KeyValue, 'id' | 'name'>
              )>, readonly employeeGroup?: TP.Maybe<(
                { readonly __typename?: 'KeyValue' }
                & Pick<TP.KeyValue, 'id' | 'name'>
              )>, readonly employeeOffice?: TP.Maybe<(
                { readonly __typename?: 'KeyValue' }
                & Pick<TP.KeyValue, 'id' | 'name'>
              )>, readonly division?: TP.Maybe<(
                { readonly __typename?: 'KeyValue' }
                & Pick<TP.KeyValue, 'id' | 'name'>
              )>, readonly manager?: TP.Maybe<(
                { readonly __typename?: 'EmployeeV2' }
                & Pick<TP.EmployeeV2, 'id' | 'fullName'>
              )> }
            )>>, readonly messagingUserIdentities?: TP.Maybe<ReadonlyArray<(
              { readonly __typename?: 'MessagingV2' }
              & Pick<TP.MessagingV2, 'employeeId' | 'serverId' | 'type' | 'userId'>
            )>>, readonly employeeRole?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'id' | 'name'>
            )>, readonly employeeGroup?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'id' | 'name'>
            )>, readonly employeeOffice?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'id' | 'name'>
            )>, readonly division?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'id' | 'name'>
            )>, readonly manager?: TP.Maybe<(
              { readonly __typename?: 'EmployeeV2' }
              & Pick<TP.EmployeeV2, 'id' | 'fullName'>
            )> }
          )>>, readonly messagingUserIdentities?: TP.Maybe<ReadonlyArray<(
            { readonly __typename?: 'MessagingV2' }
            & Pick<TP.MessagingV2, 'employeeId' | 'serverId' | 'type' | 'userId'>
          )>>, readonly employeeRole?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly employeeGroup?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly employeeOffice?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly division?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly manager?: TP.Maybe<(
            { readonly __typename?: 'EmployeeV2' }
            & Pick<TP.EmployeeV2, 'id' | 'fullName'>
          )> }
        )>>, readonly messagingUserIdentities?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'MessagingV2' }
          & Pick<TP.MessagingV2, 'employeeId' | 'serverId' | 'type' | 'userId'>
        )>>, readonly employeeRole?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly employeeGroup?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly employeeOffice?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly division?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly manager?: TP.Maybe<(
          { readonly __typename?: 'EmployeeV2' }
          & Pick<TP.EmployeeV2, 'id' | 'fullName'>
        )> }
      )>> }
    )>, readonly messagingUserIdentities?: TP.Maybe<ReadonlyArray<(
      { readonly __typename?: 'MessagingV2' }
      & Pick<TP.MessagingV2, 'employeeId' | 'serverId' | 'type' | 'userId'>
    )>>, readonly employeeRole?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly employeeGroup?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly employeeOffice?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly division?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly manager?: TP.Maybe<(
      { readonly __typename?: 'EmployeeV2' }
      & Pick<TP.EmployeeV2, 'id' | 'fullName'>
    )> }
  )> }
);


export const GetEmployeeParentDetailsV2Document = gql`
    query getEmployeeParentDetailsV2($id: ID!, $useViewSlackEmployeeTreeFlag: Boolean! = false) {
  employeeV2(id: $id) {
    ...EmployeeDetailsV2
    parent {
      id
      siblings {
        ...EmployeeDetailsEmployeeTreeV2
      }
    }
  }
}
    ${EmployeeDetailsV2FragmentDoc}
${EmployeeDetailsEmployeeTreeV2FragmentDoc}`;

/**
 * __useGetEmployeeParentDetailsV2Query__
 *
 * To run a query within a React component, call `useGetEmployeeParentDetailsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeParentDetailsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeParentDetailsV2Query({
 *   variables: {
 *      id: // value for 'id'
 *      useViewSlackEmployeeTreeFlag: // value for 'useViewSlackEmployeeTreeFlag'
 *   },
 * });
 */
export function useGetEmployeeParentDetailsV2Query(baseOptions: Apollo.QueryHookOptions<GetEmployeeParentDetailsV2Query, GetEmployeeParentDetailsV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeParentDetailsV2Query, GetEmployeeParentDetailsV2QueryVariables>(GetEmployeeParentDetailsV2Document, options);
      }
export function useGetEmployeeParentDetailsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeParentDetailsV2Query, GetEmployeeParentDetailsV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeParentDetailsV2Query, GetEmployeeParentDetailsV2QueryVariables>(GetEmployeeParentDetailsV2Document, options);
        }
export type GetEmployeeParentDetailsV2QueryHookResult = ReturnType<typeof useGetEmployeeParentDetailsV2Query>;
export type GetEmployeeParentDetailsV2LazyQueryHookResult = ReturnType<typeof useGetEmployeeParentDetailsV2LazyQuery>;
export type GetEmployeeParentDetailsV2QueryResult = Apollo.QueryResult<GetEmployeeParentDetailsV2Query, GetEmployeeParentDetailsV2QueryVariables>;