import {
  SidebarContext,
  SIDEBAR_BORDER_WIDTH,
  SIDEBAR_OPEN_WIDTH,
} from '@app/Sidebar/util';
import { Button } from '@components/Button';
import { Flag, useFlagBasedOnJSONDomain } from '@components/Flag';
import { Grid } from '@components/Grid';
import { Link } from '@components/Link';
import { Logo } from '@components/Logo';
import { NavLink } from '@components/NavLink';
import { openNewWindow } from '@components/NewWindowLink';
import { ToggleMenu } from '@components/ToggleMenu';
import { useDebouncedFn } from '@hooks/useDebouncedFn';
import { HEADER_HEIGHT, NEW_WINDOW_TARGET, USE_WALKME } from '@utils/constants';
import { FS_UNMASK } from '@utils/fullstory';
import { win } from '@utils/win';
import { APP_HEADER } from '@utils/zIndex';
import { HasPermission } from '@views/Common/Permissions';
import { FC, useContext, useState } from 'react';
import { useMount } from 'react-use';
import { config } from '../../../config';
import { UserNav } from '../UserNav';
import { MenuLink } from './MenuLink';

export const WALKME_TOGGLE_BUTTON_ID = 'walkme-toggle';

const backgroundCSSVar = `var(--header-stripe-color)`;

const BaseHeader: FC = ({ children }) => {
  return (
    <nav
      css={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        background: backgroundCSSVar,
        height: HEADER_HEIGHT,
        padding: '0 1rem',
        overflow: 'hidden',
        '-webkit-app-region': 'drag',
        userSelect: 'none',
      }}
      className={FS_UNMASK}
      data-testid="base-nav"
    >
      {children}
    </nav>
  );
};

const HeaderLogo: FC = () => {
  return (
    <Link
      to="/"
      data-testid="logo-link"
      aria-label="Return to home"
      css={{
        display: 'grid',
        placeItems: 'center',
        height: '100%',
        width: SIDEBAR_OPEN_WIDTH + SIDEBAR_BORDER_WIDTH,
        overflow: 'hidden',
      }}
    >
      <Logo width="48px" css={{ top: -1, left: -1, position: 'relative' }} />
    </Link>
  );
};

export const Header: FC = () => {
  const [menuKey, setMenuKeyRaw] = useState(Date.now());
  // we change the key on the menu to close the dropdown
  const closeAllMenus = useDebouncedFn(
    () => {
      setMenuKeyRaw(Date.now());
    },
    200,
    [],
    { leading: true }
  );
  const showFuelOption = useFlagBasedOnJSONDomain(
    'ME-24258-feat-mastermind-admin-fuel-services'
  );

  const { isHeaderAllowed } = useContext(SidebarContext);

  useMount(() => {
    if (isHeaderAllowed) {
      win.document.addEventListener('scroll', () => {
        closeAllMenus();
      });
    }
    document.querySelector('#root')?.classList.remove('loading');
  });

  if (!isHeaderAllowed) {
    return null;
  }

  return (
    <Grid
      xs="min-content 1fr"
      gap={0}
      css={{
        '-webkit-app-region': 'drag',
        userSelect: 'none',
        gridTemplateRows: HEADER_HEIGHT,
        background: backgroundCSSVar,
        position: 'sticky',
        top: 0,
        zIndex: APP_HEADER,
        ['[data-reach-menu]']: {
          zIndex: APP_HEADER + 1,
        },
      }}
    >
      <HeaderLogo />
      <BaseHeader key={menuKey}>
        <HasPermission scope="load:view">
          <ToggleMenu text="Loads">
            <HasPermission scope="load:view">
              <MenuLink
                to="/loads"
                as={NavLink as fixMe}
                data-testid="nav-link-load-search"
              >
                Search
              </MenuLink>
            </HasPermission>
            <HasPermission scope="load:create">
              <MenuLink
                to="/loads/create?bottom=0&row=0&section=order"
                as={NavLink as fixMe}
                target={NEW_WINDOW_TARGET}
                rel="noopener noreferrer"
                data-testid="nav-link-load-create"
                onClick={(event: fixMe): void => {
                  event.preventDefault();
                  openNewWindow({
                    to: `/loads/create?bottom=0&row=0&section=order`,
                  });
                  closeAllMenus();
                }}
              >
                Add New
              </MenuLink>
            </HasPermission>
            <HasPermission scope="load:repeat">
              <MenuLink
                to="/load-repeat"
                as={NavLink as fixMe}
                rel="noopener noreferrer"
                data-testid="nav-link-load-repeat"
              >
                Repeat
              </MenuLink>
            </HasPermission>
            <MenuLink
              to="/track"
              as={NavLink as fixMe}
              target={
                win.location.pathname === '/' ? NEW_WINDOW_TARGET : undefined
              }
              rel="noopener noreferrer"
              data-testid="nav-link-track"
              data-walkme="track"
            >
              Track
            </MenuLink>
          </ToggleMenu>
        </HasPermission>

        <HasPermission scope="book:view">
          <ToggleMenu text="Book">
            <HasPermission scope="book:stateMap:view">
              <MenuLink
                to="/map"
                as={NavLink as fixMe}
                rel="noopener noreferrer"
                data-testid="nav-link-map"
              >
                State Map
              </MenuLink>
            </HasPermission>
            <HasPermission scope="book:availableLoads:view">
              <MenuLink
                to="/available-routes"
                as={NavLink as fixMe}
                rel="noopener noreferrer"
                data-testid="nav-link-available-routes"
              >
                Available Routes
              </MenuLink>
            </HasPermission>
            <HasPermission scope="book:myMatches:view">
              <MenuLink
                to="/matches"
                as={NavLink as fixMe}
                rel="noopener noreferrer"
                data-testid="nav-link-my-matches"
              >
                My Matches
              </MenuLink>
            </HasPermission>
            <HasPermission scope="offer:view">
              <MenuLink
                to="/offers"
                as={NavLink as fixMe}
                rel="noopener noreferrer"
              >
                Offers
              </MenuLink>
            </HasPermission>
            <MenuLink
              to="/capacity/search"
              as={NavLink as fixMe}
              rel="noopener noreferrer"
            >
              Capacity Search
            </MenuLink>
          </ToggleMenu>
        </HasPermission>
        <HasPermission scope="carrier:view">
          <ToggleMenu text="Carrier">
            <MenuLink
              to="/carriers/search"
              as={NavLink as fixMe}
              rel="noopener noreferrer"
              data-testid="nav-link-search-carrier"
            >
              Search
            </MenuLink>

            <HasPermission scope="carrier:create">
              <MenuLink
                to="/carriers/add"
                as={NavLink as fixMe}
                rel="noopener noreferrer"
                data-testid="nav-link-add-carrier"
              >
                Add New
              </MenuLink>
            </HasPermission>
          </ToggleMenu>
        </HasPermission>
        <HasPermission scope="customer:view">
          <ToggleMenu text="Customer">
            <MenuLink
              to="/customers/search"
              as={NavLink as fixMe}
              rel="noopener noreferrer"
              data-testid="nav-link-search-customer"
            >
              Search
            </MenuLink>
            <HasPermission scope="customer:create">
              <MenuLink
                to="/customers/add"
                as={NavLink as fixMe}
                rel="noopener noreferrer"
                data-testid="nav-link-add-customer"
              >
                Add New
              </MenuLink>
            </HasPermission>
          </ToggleMenu>
        </HasPermission>
        <HasPermission scope="facility:view">
          <ToggleMenu text="Facility">
            <MenuLink
              as={NavLink as fixMe}
              to="/facilities/search"
              rel="noopener noreferrer"
              data-testid="nav-link-search-facility"
            >
              Search
            </MenuLink>
            <HasPermission scope="facility:create">
              <MenuLink
                to="/facilities/add"
                as={NavLink as fixMe}
                rel="noopener noreferrer"
                data-testid="nav-link-add-facility"
              >
                Add New
              </MenuLink>
            </HasPermission>
          </ToggleMenu>
        </HasPermission>
        <Flag name="ME-23189-view-planning-nav-item">
          <ToggleMenu text="Planning">
            <HasPermission scope="lane:view">
              <MenuLink
                as={NavLink as fixMe}
                to="/lanes"
                rel="noopener noreferrer"
                data-testid="nav-link-lane"
              >
                Lanes
              </MenuLink>
            </HasPermission>
            <Flag name="ME-65797-view-project-page">
              <MenuLink
                as={NavLink as fixMe}
                to="/projects"
                rel="noopener noreferrer"
                data-testid="nav-link-project"
              >
                Projects
              </MenuLink>
            </Flag>
          </ToggleMenu>
        </Flag>
        <Flag name="nav-employee-menu">
          <HasPermission scope="employee:view">
            <ToggleMenu text="Employee">
              <MenuLink
                as={NavLink as fixMe}
                to="/employees/search"
                rel="noopener noreferrer"
                data-testid="nav-link-search-employee"
              >
                Search
              </MenuLink>
              <HasPermission scope="employee:create">
                <MenuLink
                  as={NavLink as fixMe}
                  to="/employees/add"
                  rel="noopener noreferer"
                  data-testid="nav-link-add-employee"
                >
                  Add New
                </MenuLink>
              </HasPermission>
            </ToggleMenu>
          </HasPermission>
        </Flag>
        <Flag name="nav-admin">
          <HasPermission scope="admin:view">
            <ToggleMenu text="Admin">
              <Flag name="route-upload-tenant">
                <HasPermission scope="admin:regionUpload:view">
                  <MenuLink
                    as={NavLink as fixMe}
                    to="/admin/upload-regions"
                    rel="noopener noreferrer"
                    data-testid="nav-link-upload-regions-admin"
                  >
                    Upload Regions Base
                  </MenuLink>
                </HasPermission>
              </Flag>
              <Flag name="admin-release-lock">
                <HasPermission scope="admin:releaseLock:view">
                  <MenuLink
                    as={NavLink as fixMe}
                    to="/admin/release-lock"
                    rel="noopener noreferrer"
                    data-testid="nav-link-admin-release-lock"
                  >
                    Release Locks
                  </MenuLink>
                </HasPermission>
              </Flag>

              <HasPermission scope="admin:accounting:view">
                <MenuLink
                  as={NavLink as fixMe}
                  to="/admin/accounting/configuration"
                  rel="noopener noreferrer"
                  data-testid="nav-link-admin-accounting-default-voucher-date"
                >
                  Accounting
                </MenuLink>
              </HasPermission>

              {showFuelOption && (
                <HasPermission scope="admin:accounting:view">
                  <MenuLink
                    as={NavLink as fixMe}
                    to="/admin/fuel-services/fuel-indices"
                    rel="noopener noreferrer"
                    data-testid="nav-link-admin-fuel-services"
                  >
                    Fuel Services
                  </MenuLink>
                </HasPermission>
              )}

              <Flag name="nav-user-access">
                <HasPermission scope="admin:userAccess:view">
                  <MenuLink
                    href={`${config.keycloakUri}/admin/${config.keycloakRealm}/console`}
                    target={NEW_WINDOW_TARGET}
                    data-testid="nav-link-keycloak"
                  >
                    User Access
                  </MenuLink>
                </HasPermission>
              </Flag>
            </ToggleMenu>
          </HasPermission>
        </Flag>
        <HasPermission scope="accounting:view">
          <ToggleMenu text="Accounting">
            <HasPermission scope="accounting:customerSettlement:view">
              <MenuLink
                as={NavLink as fixMe}
                to="/accounting/customer-settlement"
                rel="noopener noreferrer"
                data-testid="nav-link-accounting-customer-settlement"
              >
                Customer Settlement
              </MenuLink>
            </HasPermission>
            <HasPermission scope="accounting:vendorSettlement:view">
              <MenuLink
                as={NavLink as fixMe}
                to="/accounting/vendor-settlement"
                rel="noopener noreferrer"
                data-testid="nav-link-accounting-vendor-settlement"
              >
                Vendor Settlement
              </MenuLink>
            </HasPermission>
            <HasPermission scope="accounting:invoices:view">
              <MenuLink
                as={NavLink as fixMe}
                to="/accounting/invoices"
                rel="noopener noreferrer"
                data-testid="nav-link-accounting-invoices"
              >
                Invoices
              </MenuLink>
            </HasPermission>
            <HasPermission scope="accounting:vouchers:view">
              <MenuLink
                as={NavLink as fixMe}
                to="/accounting/vouchers"
                rel="noopener noreferrer"
                data-testid="nav-link-accounting-vouchers"
              >
                Vouchers
              </MenuLink>
            </HasPermission>
            <Flag name="ME-30154-view-vendor-invoice-tab">
              <HasPermission scope="accounting:vendorInvoice:view">
                <MenuLink
                  as={NavLink as fixMe}
                  to="/accounting/vendor-invoice"
                  rel="noopener noreferrer"
                  data-testid="nav-link-accounting-vendor-invoice"
                >
                  Vendor Invoice
                </MenuLink>
              </HasPermission>
            </Flag>
          </ToggleMenu>
        </HasPermission>
        <Flag name="ME-32174-feat-assets">
          <HasPermission scope="driver:view">
            <ToggleMenu text="Resources">
              <MenuLink
                as={NavLink as fixMe}
                to="/drivers/search"
                rel="noopener noreferrer"
                data-testid="nav-link-search-driver"
              >
                Driver - Search
              </MenuLink>
              <HasPermission scope="driver:create">
                <MenuLink
                  as={NavLink as fixMe}
                  to="/drivers/add"
                  rel="noopener noreferer"
                  data-testid="nav-link-add-driver"
                >
                  Driver - Add New
                </MenuLink>
              </HasPermission>
              <HasPermission scope="trailer:create">
                <MenuLink
                  as={NavLink as fixMe}
                  to="/trailers/search"
                  rel="noopener noreferrer"
                  data-testid="nav-link-asset-trailer-search"
                >
                  Trailer - Search
                </MenuLink>
              </HasPermission>
              <MenuLink
                as={NavLink as fixMe}
                to="/trailers/add"
                rel="noopener noreferrer"
                data-testid="nav-link-asset-trailer-add"
              >
                Trailer - Add New
              </MenuLink>
              <HasPermission scope="power:view">
                <MenuLink
                  as={NavLink as fixMe}
                  to="/powers/search"
                  rel="noopener noreferrer"
                  data-testid="nav-link-aseet-power-view"
                >
                  Power - Search
                </MenuLink>
              </HasPermission>
              <MenuLink
                as={NavLink as fixMe}
                to="/powers/add"
                rel="noopener noreferrer"
                data-testid="nav-link-admin-release-lock"
              >
                Power - Add New
              </MenuLink>
            </ToggleMenu>
          </HasPermission>
        </Flag>
        <Grid
          css={{ textAlign: 'right', width: 'auto', marginLeft: 'auto' }}
          xs="1fr 30px"
          gap={0.5}
        >
          <UserNav />
          <div css={{ display: 'grid', placeItems: 'center' }}>
            <Button
              id={WALKME_TOGGLE_BUTTON_ID}
              option="quaternary"
              css={{
                borderRadius: '100%',
                width: 25,
                height: 25,
                textAlign: 'center',
                lineHeight: '25px',
                padding: 0,
                minHeight: 'auto',
                fontSize: 18,
              }}
              onClick={(): void => {
                if (!USE_WALKME) {
                  return alert(
                    'Walkme is currently disabled in this environment.'
                  );
                }
                (document.querySelector('#walkme-player') as fixMe)?.click();
                setTimeout(() => {
                  (
                    document.querySelector('.wm-menu-search-bar input') as fixMe
                  )?.focus();
                }, 400);
              }}
            >
              ?
            </Button>
          </div>
        </Grid>
      </BaseHeader>
    </Grid>
  );
};
