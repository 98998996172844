import * as TP from '../types';

import { gql } from '@apollo/client';
import { KeyValueInfoFragmentDoc } from './keyValueInfo';
export type ContactInfoFragment = (
  { readonly __typename?: 'Contact' }
  & Pick<TP.Contact, 'id' | 'name' | 'contactTypeId' | 'main' | 'phoneNumber' | 'extension' | 'faxNumber' | 'emailAddress' | 'chatUsername' | 'chatTypeId' | 'riskInfoSource' | 'thirdPartyId'>
  & { readonly chatType?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )> }
);

export const ContactInfoFragmentDoc = gql`
    fragment ContactInfo on Contact {
  id
  name
  contactTypeId
  main
  phoneNumber
  extension
  faxNumber
  emailAddress
  chatType {
    ...KeyValueInfo
  }
  chatUsername
  chatTypeId
  main
  riskInfoSource
  thirdPartyId
}
    ${KeyValueInfoFragmentDoc}`;