import {
  AddressDisplay,
  getAddressDisplayString,
} from '@components/AddressDisplay';
import {
  AutoComplete,
  Props as AutoCompleteProps,
  Shell,
} from '@components/AutoComplete';
import { useFlag } from '@components/Flag';
import { getInnerText } from '@components/Table/util/getInnerText';
import { useViewOnly } from '@components/ViewOnly';
import { ContactInfoFragment } from '@generated/fragments/contactInfo';
import { CustomerFacilityDefaultInfoFragment } from '@generated/fragments/customerFacilityDefault';
import { CustomerFacilityDefaultInfoV2Fragment } from '@generated/fragments/customerFacilityDefaultV2';
import { FacilityAddressBriefFragment } from '@generated/fragments/FacilityAddressBrief';
import { FacilityIdentifierInfoFragment } from '@generated/fragments/facilityIdentifierInfo';
import { FacilityInfoFragment } from '@generated/fragments/facilityInfo';
import { FacilityInfoV2Fragment } from '@generated/fragments/facilityInfoV2';
import { FacilityNoteInfoFragment } from '@generated/fragments/FacilityNoteInfo';
import { NoteInfoFragment } from '@generated/fragments/noteInfo';
import { ScheduleInfoFragment } from '@generated/fragments/scheduleInfo';
import { ScheduleInfoV2Fragment } from '@generated/fragments/scheduleInfoV2';
import {
  AllFacilitiesForFacilityPickerDocument,
  AllFacilitiesForFacilityPickerQuery,
  AllFacilitiesForFacilityPickerQueryVariables,
  AllFacilitiesV2ForFacilityPickerDocument,
  AllFacilitiesV2ForFacilityPickerQuery,
  AllFacilitiesV2ForFacilityPickerQueryVariables,
} from '@generated/queries/allFacilitiesForFacilityPicker';
import {
  FacilitiesForMasterfindDocument,
  FacilityMfItemAddressFragment,
  FacilityMfItemFragment,
} from '@generated/queries/facilitiesForMasterfind';
import {
  FacilitiesForMasterfindV2Document,
  FacilityMfItemV2Fragment,
} from '@generated/queries/facilitiesForMasterfindV2';
import {
  Contact,
  CustomerFacility,
  FacilityLoadDefaults,
} from '@generated/types';
import { useDebouncedFn } from '@hooks/useDebouncedFn';
import { useFacilityV2Flag } from '@hooks/useFacilityV2';
import { useLazyQueryWithDataPromise } from '@hooks/useLazyQueryWithDataPromise';
import { getNodesFromConnection } from '@utils/graphqlUtils';
import { pipeSeparator } from '@utils/htmlEntities';
import { filterByPromiseFulfilled } from '@utils/promise';
import { searchPhoneNumberMatch } from '@utils/searchPhoneNumberMatch';
import { ControllerStateAndHelpers } from 'downshift';
import fuzzy from 'fuzzy';
import { compact, isFunction, isString, isUndefined, uniqBy } from 'lodash-es';
import {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { match } from './util/match';

export type BaseFacilityAddress = FacilityMfItemAddressFragment;

type BaseFacilityContact = Pick<
  Contact,
  'active' | 'chatType' | 'id' | 'main' | 'name'
>;

export interface BaseFacility
  extends Pick<
    FacilityInfoFragment | FacilityInfoV2Fragment,
    'id' | 'name' | 'code' | 'phoneNumber'
  > {
  addresses: ReadonlyArray<BaseFacilityAddress>;
  contacts: ReadonlyArray<BaseFacilityContact>;
  phoneNumber: Maybe<Readonly<string>>;
  mainAddress: Maybe<BaseFacilityAddress>;
  customerFacilities: Maybe<ReadonlyArray<CustomerFacility>>;
  isCustomerFacility: Maybe<Readonly<boolean>>;
  customerFacilityInfo: Maybe<Readonly<Partial<CustomerFacility>>>;
  [index: string]: fixMe;
}

export type BaseFacilityShell = Shell<BaseFacility>;

export type ExtendedAddress = Omit<
  FacilityAddressBriefFragment,
  '__typename' | 'facililityId' | 'isVerified'
> & {
  facililityId?: string;
};

export interface ExtendedFacility
  extends Pick<
    FacilityInfoFragment | FacilityInfoV2Fragment,
    | 'id'
    | 'name'
    | 'code'
    | 'facilityNote'
    | 'facilityType'
    | 'loadFromType'
    | 'phoneNumber'
    | 'scaleNote'
    | 'schedulingSystemType'
    | 'schedulingContact'
    | 'sourceType'
    | 'status'
    | 'timezone'
    | 'taxExempt'
    | 'unloadFromType'
  > {
  addresses?: Maybe<ReadonlyArray<ExtendedAddress>>;
  mainAddress?: Maybe<BaseFacilityAddress | ExtendedAddress>;
  contacts: ReadonlyArray<Omit<ContactInfoFragment, '__typename'>>;
  externalNotes: Maybe<
    | ReadonlyArray<Omit<NoteInfoFragment, '__typename'>>
    | ReadonlyArray<Omit<FacilityNoteInfoFragment, '__typename'>>
  >;
  facilityIdentifiers: Maybe<
    ReadonlyArray<Omit<FacilityIdentifierInfoFragment, '__typename'>>
  >;
  notes: Maybe<
    | ReadonlyArray<Omit<NoteInfoFragment, '__typename'>>
    | ReadonlyArray<Omit<FacilityNoteInfoFragment, '__typename'>>
  >;
  schedules:
    | ReadonlyArray<Omit<ScheduleInfoFragment, '__typename'>>
    | ReadonlyArray<Omit<ScheduleInfoV2Fragment, '__typename'>>;
  customerFacilities: Maybe<
    ReadonlyArray<
      | CustomerFacilityDefaultInfoFragment
      | CustomerFacilityDefaultInfoV2Fragment
    >
  >;
  isCustomerFacility: Maybe<Readonly<boolean>>;
  facilityLoadDefaults: Maybe<FacilityLoadDefaults>;
  customerFacilityInfo: Maybe<Readonly<Partial<CustomerFacility>>>;
}

export interface FacilityPickerProps<ItemType>
  extends Omit<AutoCompleteProps<ItemType>, 'items' | 'onChange'> {
  onChange: (
    facility: Maybe<Shell<ItemType>>,
    helpers: ControllerStateAndHelpers<Shell<ItemType>> | undefined
  ) => void;
  /** Initial facility code, if you want the component to do a lookup */
  initialCode?: Maybe<string>;
  ['data-testid']?: string;
  /** Typical workflows should only search for facilities that are NOT duplicates, and have an "active" status. However, if you need to include all facilities no matter the status, use "all". */
  include: 'only-active' | 'all';
  filterItems?: (items: ReadonlyArray<ItemType>) => ReadonlyArray<ItemType>;
  /** Choose which fragment will be used to resolve the Facility. Small is only necessary information like name and id. Large includes many more keys, like contacts and should be used with caution to avoid API pressure. */
  itemFidelity?: 'small' | 'large';
  /** Initial customer id, if you want the component to do a lookup from customer facility relationship */
  initialCustomerId?: Maybe<string>;
}

export const toBaseFacilityShell = (
  value: BaseFacility
): BaseFacilityShell => ({
  value,
  label: value.name,
  id: value.id,
});

export const filterFacilityItemsOnLabel = (
  items: readonly BaseFacilityShell[],
  searchStr: string
): BaseFacilityShell[] => {
  return items.filter((obj) => {
    let labelStr;
    if (isString(obj.label)) {
      labelStr = obj.label;
    } else if (isFunction(obj.label)) {
      labelStr = obj.label(obj.value);
    }
    try {
      // getInnerText here is expensive, but probably necessary since the labels are ReactNode type and need to be converted to a string for the match util.
      const res = getInnerText(labelStr as fixMe);
      const phoneString = searchPhoneNumberMatch(searchStr) ?? '';
      if (
        phoneString?.length > 3 &&
        obj?.value?.phoneNumber &&
        !isNaN(parseInt(phoneString))
      ) {
        return (
          match(res, searchStr) || obj.value.phoneNumber.includes(phoneString)
        );
      }
      return match(res, searchStr);
    } catch {
      // noop;
    }
    return;
  });
};

const getFacilityFuzzyVector = (
  facility: Maybe<FacilityMfItemFragment | FacilityMfItemV2Fragment>,
  searchStr: string
): string => {
  if (!facility) {
    return '';
  }
  const { name, code, mainAddress, phoneNumber } = facility;
  const phoneString = searchPhoneNumberMatch(searchStr) ?? '';
  if (phoneString?.length > 3 && phoneNumber && !isNaN(parseInt(phoneString))) {
    return `${name} ${code} ${phoneNumber} ${getAddressDisplayString({
      value: mainAddress,
      street: true,
      city: true,
      state: true,
    })}`;
  }
  return `${name} ${code} ${getAddressDisplayString({
    value: mainAddress,
    street: true,
    city: true,
    state: true,
  })}`;
};

export const FacilityPickerItemDisplay: FC<{
  facility: Maybe<BaseFacility>;
  compact?: boolean;
}> = ({ facility, compact: compactProp }) => {
  if (!facility) {
    return <></>;
  }
  const { name, code, mainAddress } = facility;
  return (
    <div>
      <strong>{name}</strong>
      {pipeSeparator}
      <span>{code}</span>
      {compactProp ? <br /> : pipeSeparator}
      <AddressDisplay value={mainAddress} street city state />
    </div>
  );
};

const isV2Query = (
  arg:
    | AllFacilitiesV2ForFacilityPickerQuery
    | AllFacilitiesForFacilityPickerQuery
): arg is AllFacilitiesV2ForFacilityPickerQuery => {
  return !isUndefined((arg as anyOk).allFacilitiesV2);
};

// Ideally we could use one query to search by name OR code, but the API does not support that right now
// So we execute three queries and combine them ourselves two for name field with and without number and one for code
export function useFacilityCombinedSearch<T extends BaseFacility>(kwargs: {
  large?: boolean;
  filterInactive: boolean;
  customerIds?: string[];
}): (text: string, kwargs?: { codeOnly?: boolean }) => Promise<T[]> {
  const { filterInactive, large, customerIds } = kwargs;
  let document = large
    ? AllFacilitiesForFacilityPickerDocument
    : FacilitiesForMasterfindDocument;

  const shouldUseV2 = useFacilityV2Flag();

  if (shouldUseV2) {
    document = large
      ? AllFacilitiesV2ForFacilityPickerDocument
      : FacilitiesForMasterfindV2Document;
  }

  const callQueryV1 = useLazyQueryWithDataPromise<
    AllFacilitiesForFacilityPickerQuery,
    AllFacilitiesForFacilityPickerQueryVariables
  >(document);

  const callQueryV2 = useLazyQueryWithDataPromise<
    AllFacilitiesV2ForFacilityPickerQuery,
    AllFacilitiesV2ForFacilityPickerQueryVariables
  >(document);

  const callQuery = shouldUseV2 ? callQueryV2 : callQueryV1;

  const first =
    useFlag('ME-22735-fix-facility-picker-query-limit-number') || 10;

  const shouldFilterResponses = useFlag(
    'ME-32857-fix-facility-picker-filtering'
  );

  return useCallback(
    async (text: string): Promise<T[]> => {
      // If we are using our client-side filtering, we need to only send the facility name to the query. So we strip the address off.
      // ie "General Mills 200 main street" becomes "General Mills"
      // Of course this is not completely adequate, as the user can type anything into the input, but hopefully it is good enough until we come up with better API-specific solutions.
      const textWithoutNumbers = text.replace(/\d.*/, '').trim();
      const checkStrippedPhone =
        searchPhoneNumberMatch(text)?.replace(/\d.*/, '')?.trim() ?? '';
      const isPhoneNumber = checkStrippedPhone.length === 0;
      // This if/else block is just to merge into release branch safely without breaking anything. Once that is done it can be removed.
      const rawRes = shouldUseV2
        ? await Promise.allSettled(
            compact([
              callQuery({
                fetchPolicy: 'network-only',
                variables: {
                  first,
                  filter: { text: text, useFacilityPickerFuzzy: true },
                  ...(customerIds && customerIds.length > 0
                    ? { customerIds: customerIds }
                    : {}),
                },
              }),
            ])
          )
        : await Promise.allSettled(
            compact([
              callQuery({
                fetchPolicy: 'network-only',
                variables: {
                  first,
                  filter: { text: searchPhoneNumberMatch(text) },
                  ...(customerIds && customerIds.length > 0
                    ? { customerIds: customerIds }
                    : {}),
                },
              }),
              textWithoutNumbers &&
                !isPhoneNumber &&
                callQuery({
                  fetchPolicy: 'network-only',
                  variables: {
                    first,
                    filter: { text: textWithoutNumbers },
                    ...(customerIds && customerIds.length > 0
                      ? { customerIds: customerIds }
                      : {}),
                  },
                }),
            ])
          );
      let resultsCombined = uniqBy(
        filterByPromiseFulfilled(rawRes)
          .map((obj) => {
            if (isV2Query(obj.value.data)) {
              return getNodesFromConnection(obj.value.data.allFacilitiesV2);
            }
            return getNodesFromConnection(obj.value.data.allFacilities);
          })
          .flat(),
        (obj) => obj.id
      ).filter((obj) => {
        if (filterInactive) {
          return !obj.status?.toLowerCase().match(/inactive|duplicate/);
        }
        return true;
      });

      if (shouldFilterResponses && !shouldUseV2) {
        resultsCombined = fuzzy
          .filter(`${searchPhoneNumberMatch(text)}`, resultsCombined, {
            extract: (obj) =>
              getFacilityFuzzyVector(obj, `${searchPhoneNumberMatch(text)}`),
          })
          .map((obj) => obj.original);
      }

      return resultsCombined as fixMe;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callQuery]
  );
}
interface StampedResultSet {
  facilities: BaseFacility[];
  searchKey: string;
}
export const FacilityPicker = <PickerType extends BaseFacility>({
  initialCode,
  ['data-testid']: testId,
  disabled,
  selectedItem,
  inputProps,
  onChange,
  include: mode,
  filterItems,
  itemFidelity,
  ...restProps
}: FacilityPickerProps<PickerType>): ReactElement => {
  const shouldUseLarge = itemFidelity === 'large';
  const { isViewOnly } = useViewOnly();
  const searchFacilities = useFacilityCombinedSearch<PickerType>({
    large: shouldUseLarge,
    filterInactive: mode === 'only-active',
  });

  const initialState = {
    searchKey: '',
    facilities: [],
  };
  const [dataResults, setDataResults] =
    useState<StampedResultSet>(initialState);
  const [loading, setLoading] = useState(false);

  const [dropdownKey, setDropdownKey] = useState(Date.now());

  const [shouldUseInitialFacility, setShouldUseInitialFacility] = useState(
    Boolean(initialCode)
  );
  const shouldUseV2 = useFacilityV2Flag();

  useEffect((): void => {
    (async (): Promise<void> => {
      if (initialCode && shouldUseInitialFacility) {
        setLoading(true);
        const res = await searchFacilities(initialCode, { codeOnly: true });
        setDataResults({ facilities: res, searchKey: '' });
      } else {
        setDataResults(initialState);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUseInitialFacility]);
  const shouldFilter =
    useFlag('ME-22735-fix-facility-picker-query-limit-number') !== 0;

  const [searchStr, setSearchStr] = useState('');

  const get = useCallback(
    async (str: string): Promise<void> => {
      if (!str.length) {
        setDataResults(initialState);
        return;
      }
      const searchStr = str.trim();
      setSearchStr(str);
      if (searchStr.length > 2) {
        setLoading(true);
        const res = await searchFacilities(searchStr);
        setDataResults({ facilities: res, searchKey: str });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchFacilities, initialState, searchStr, dataResults]
  );
  const onInputValueChange = useDebouncedFn(get, 400, []);

  let gotFacilityFromInitialCode = false;
  let initialSelectedItem: Maybe<Shell<BaseFacility>> = undefined;
  if (initialCode) {
    initialSelectedItem = dataResults.facilities
      .filter((obj) => obj.code === initialCode)
      .map(toBaseFacilityShell)[0];
    if (initialSelectedItem) {
      gotFacilityFromInitialCode = true;
    }
  }

  useEffect(() => {
    if (shouldUseInitialFacility) {
      setDropdownKey(Date.now());
    }
  }, [
    gotFacilityFromInitialCode,
    shouldUseInitialFacility,
    initialSelectedItem?.value?.name,
  ]);
  const [items, setItems] = useState<ReadonlyArray<BaseFacilityShell>>([]);
  useEffect(() => {
    if (searchStr == dataResults.searchKey) {
      let items: ReadonlyArray<BaseFacilityShell> =
        dataResults.facilities.map((obj) => {
          return {
            label: (item: BaseFacility): ReactNode => (
              <FacilityPickerItemDisplay facility={item} />
            ),
            value: obj,
            id: obj?.id,
          };
        }) || [];

      if (shouldFilter && !shouldUseV2) {
        items = filterFacilityItemsOnLabel(items, searchStr);
      }
      setItems(items);
      setLoading(false);
    } else if (searchStr.length <= 2 && !shouldUseInitialFacility) {
      setItems([]);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataResults, searchStr, shouldFilter, shouldUseInitialFacility]);

  return isViewOnly ? (
    <div data-testid={testId || restProps.name}>{selectedItem?.label}</div>
  ) : (
    <AutoComplete<PickerType>
      key={dropdownKey}
      data-testid={testId}
      disabled={disabled}
      loading={loading}
      onChange={(item, helpers): void => {
        setShouldUseInitialFacility(false);
        onChange && onChange(item, helpers);
      }}
      onInputValueChange={(str): void => {
        setShouldUseInitialFacility(false);
        onInputValueChange(str);
      }}
      items={filterItems ? filterItems(items as fixMe) : (items as fixMe)}
      inputProps={{
        placeholder: 'Search Facilities',
        ...inputProps,
      }}
      selectedItem={selectedItem}
      initialSelectedItem={initialSelectedItem as fixMe}
      {...restProps}
    />
  );
};

export const FacilityPickerLarge = (
  props: FacilityPickerProps<ExtendedFacility>
): ReactElement => {
  return <FacilityPicker<anyOk> {...props} itemFidelity="large" />;
};
