import {
  AutoComplete,
  Props as AutoCompleteProps,
  Shell,
} from '@components/AutoComplete';
import { AddressBriefFragment } from '@generated/fragments/addressBrief';
import { CarrierInfoSidebarFragment } from '@generated/fragments/carrierInfoSidebar';
import { CarrierInfoSidebarV2Fragment } from '@generated/fragments/carrierInfoSidebarV2';
import { useSearchCarriersCarrierInfoSidebarLazyQuery } from '@generated/queries/searchCarriersCarrierInfoSidebar';
import { useSearchCarriersCarrierInfoSidebarV2LazyQuery } from '@generated/queries/searchCarriersCarrierInfoSidebarV2';
import { useCarrierV2Flag } from '@hooks/useCarrierV2Flag';
import { useDebouncedFn } from '@hooks/useDebouncedFn';
import { Actions } from 'downshift';
import { Component, FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SIDEBAR_SEARCH_STYLE } from '../style';

type ShortAddress = Pick<AddressBriefFragment, 'city' | 'state'>;

const formatAddress = (address: Maybe<ShortAddress>): string =>
  address ? `${address.city}, ${address.state}` : '???';

const renderCarrierResult: AutoCompleteProps<
  CarrierInfoSidebarFragment | CarrierInfoSidebarV2Fragment
>['renderItem'] = ({
  item,
  key,
  defaultItemStyles,
  getItemProps,
  isHighlighted,
  index,
}) => {
  const { value: carrier } = item;
  return (
    <li
      key={key}
      css={{
        ...defaultItemStyles,
        ...{
          display: 'block',
          width: '100%',
          padding: '0.5rem',
          textAlign: 'left',
        },
      }}
      {...getItemProps({
        item,
        index,
        key,
        style: {
          backgroundColor: isHighlighted ? '#0f70e7' : 'white',
          color: isHighlighted ? 'white' : 'inherit',
        },
      })}
      data-testid={carrier?.id ?? ''}
    >
      <span css={{ fontWeight: 600 }}>{carrier?.name}</span>
      <br />
      <span>
        {carrier?.code} | {formatAddress(carrier?.mainAddress)}
      </span>
    </li>
  );
};

const CarrierSearchBase: FC<RouteComponentProps> = ({ history: { push } }) => {
  const carrierSelectHandler = (
    item: Shell<
      CarrierInfoSidebarFragment | CarrierInfoSidebarV2Fragment
    > | null,
    helpers?: Actions<
      Shell<CarrierInfoSidebarFragment | CarrierInfoSidebarV2Fragment>
    >
  ): void => {
    if (item) {
      helpers?.clearSelection();
      helpers?.closeMenu();

      push(`/carriers/${item.id}/capacity-manager`);
    }
  };
  const useCarrierV2 = useCarrierV2Flag();
  const [searchCarriersV1, { loading: loadingV1, data: dataV1 }] =
    useSearchCarriersCarrierInfoSidebarLazyQuery();
  const [searchCarriersV2, { loading: loadingV2, data: dataV2 }] =
    useSearchCarriersCarrierInfoSidebarV2LazyQuery();
  const data = useCarrierV2
    ? dataV2?.carriersNullableV2
    : dataV1?.carriersNullable;
  const loading = useCarrierV2 ? loadingV2 : loadingV1;
  const searchCarriers = useCarrierV2 ? searchCarriersV2 : searchCarriersV1;

  const debouncedSearchCarriers = useDebouncedFn(searchCarriers, 500, []);

  const carriers = data?.edges ?? [];
  const items = carriers.map(({ node }) => ({
    value: node,
    label: node.name,
    id: node.id,
  }));

  return (
    <AutoComplete<CarrierInfoSidebarFragment | CarrierInfoSidebarV2Fragment>
      onInputValueChange={(inputValue): void => {
        debouncedSearchCarriers({
          variables: { filter: { text: inputValue }, first: 15 },
        });
      }}
      onChange={carrierSelectHandler}
      loading={loading}
      items={items}
      renderItem={renderCarrierResult}
      renderDropdownInPopper
      showSearchIcon
      css={{ height: '100%' }}
      inputProps={{
        placeholder: 'Search',
        'data-testid': 'sidebar-carrier-search-input',
        'aria-label': 'Search Carriers',
        css: SIDEBAR_SEARCH_STYLE,
      }}
    />
  );
};

export const CarrierSearch: typeof Component = withRouter(CarrierSearchBase);
