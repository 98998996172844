import { FC } from 'react';

interface LogoProps {
  color?: string;
  width?: string;
}

export const Logo: FC<LogoProps> = ({ color, width = '4rem', ...rest }) => (
  <div css={{ width, height: width }} data-testid="logo" {...rest}>
    <svg
      enableBackground="new 0 0 300 300"
      viewBox="0 0 300 300"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={color ? color : '#fff'}>
        <ellipse
          cx="150.1"
          cy="84.8"
          rx="19.6"
          ry="19.6"
          transform="matrix(.3827 -.9239 .9239 .3827 14.3134 190.9926)"
          shapeRendering="geometricPrecision"
        />
        <ellipse
          cx="150.1"
          cy="215.2"
          rx="19.6"
          ry="19.6"
          transform="matrix(.9871 -.1602 .1602 .9871 -32.5357 26.8186)"
          shapeRendering="geometricPrecision"
        />
        <path
          d="m216.2 165.6v-31.2c5.9-3.4 9.9-9.7 9.9-17 0-10.8-8.8-19.6-19.6-19.6s-19.6 8.8-19.6 19.6v.4l-36.9 22-36.9-22c0-.1 0-.3 0-.4 0-10.8-8.8-19.6-19.6-19.6s-19.6 8.8-19.6 19.6c0 7.3 4 13.6 9.9 17v31.2c-5.9 3.4-9.9 9.7-9.9 17 0 10.8 8.8 19.6 19.6 19.6s19.6-8.8 19.6-19.6c0-7.3-4-13.6-9.9-17v-31.2l41.9 25c3.1 1.8 6.9 1.8 9.9 0l41.9-25v31.2c-5.9 3.4-9.9 9.7-9.9 17 0 10.8 8.8 19.6 19.6 19.6s19.6-8.8 19.6-19.6c-.1-7.3-4.1-13.6-10-17z"
          shapeRendering="geometricPrecision"
        />
      </g>
    </svg>
  </div>
);
