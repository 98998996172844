import { TOOLTIP_ERROR_LIST_CLASS } from '@components/Button/SaveButton/util';
import { BODY_FONT_SIZE, useInputStyle } from '@components/Theme';
import { visuallyHiddenStyle } from '@components/VisuallyHidden';
import { Global } from '@emotion/react';
import { ENV_VARS } from '@env';
import { useOutlineRing } from '@hooks/useOutlineRing';
import { useTheme } from '@hooks/useTheme';
import '@reach/dialog/styles.css';
import '@reach/menu-button/styles.css';
import { win } from '@utils/win';
import { AUTOCOMPLETE, REACH_MENU } from '@utils/zIndex';
import { noop, round } from 'lodash-es';
import { FC, useEffect } from 'react';
import { iconBgImg } from './iconBgImg';

const handlers = {
  firstClick: noop,
  firstKey: noop,
};

// https://medium.com/hackernoon/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2
handlers.firstKey = (e: KeyboardEvent): void => {
  // the "I am a keyboard user" key
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
    win.removeEventListener('keydown', handlers.firstKey);
    win.addEventListener('mousedown', handlers.firstClick);
  }
};

handlers.firstClick = (): void => {
  document.body.classList.remove('user-is-tabbing');
  win.removeEventListener('mousedown', handlers.firstClick);
  win.addEventListener('keydown', handlers.firstKey);
};

const DEFAULT_LINE_HEIGHT = `${round(BODY_FONT_SIZE * 1.3)}px`;

export const AppStyles: FC = () => {
  useEffect(() => {
    win.addEventListener('keydown', handlers.firstKey);
  }, []);

  const { colors, gray, fonts, tooltip, contextMenu, formElement } = useTheme();
  const inputStyle = useInputStyle();
  const getOutLineRing = useOutlineRing();

  return (
    <Global
      styles={{
        '*': {
          boxSizing: 'border-box',
          '&:focus': {
            outline: 0,
          },
        },
        body: {
          fontFamily: fonts.main,
          webkitFontSmoothing: 'antialiased',
          mozOsxFontSmoothing: 'grayscale',
          fontSize: BODY_FONT_SIZE,
          lineHeight: DEFAULT_LINE_HEIGHT,
          color: colors.text,
          '&.user-is-tabbing *:focus': {
            outline: getOutLineRing(),
          },
          '&.user-is-tabbing .focusable:focus-within': {
            outline: getOutLineRing(),
          },
          '&.user-is-tabbing .validation-error:focus': {
            outline: getOutLineRing(colors.error),
          },
          '&.user-is-tabbing .validation-warningDark:focus': {
            outline: getOutLineRing(colors.warningDark),
          },
          '&.user-is-tabbing .validation-success:focus': {
            outline: getOutLineRing(colors.success),
          },
        },
        a: {
          color: colors.text,
          '&:visited': {
            color: colors.text,
          },
        },
        address: {
          display: 'inline-block',
          fontStyle: 'inherit',
        },
        code: { fontFamily: fonts.code },
        pre: {
          fontFamily: 'inherit',
          whiteSpace: 'pre',
          display: 'inline',
          margin: 0,
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        button: {
          background: 'transparent',
          border: 'none',
          cursor: 'pointer',
          font: 'unset',
          fontFamily: fonts.main,
          color: 'inherit',
          padding: 0,
          fontWeight: 'normal',
          margin: 0,
          lineHeight: DEFAULT_LINE_HEIGHT,
        },
        p: { margin: 0 },
        h1: {
          margin: 0,
          paddingRight: '.25rem',
          fontSize: '1.5rem',
        },
        input: inputStyle,
        '.input': inputStyle,
        'input[type="checkbox"]': {
          width: 'auto',
        },
        'input.timeInput': {
          background: iconBgImg('clock', 5),
        },
        textarea: {
          ...inputStyle,
          display: 'block',
          minHeight: '5rem',
        },
        select: {
          ...inputStyle,
          appearance: 'none',
          cursor: 'pointer',
          padding: '.5rem 1.75rem .5rem .5rem',
        },
        '[data-readonly="true"]': {
          ...inputStyle,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          height: 'calc(1em + 20px)',
          borderColor: gray[200],
          backgroundColor: formElement.readOnly.background,
        },
        '[data-readonly="true"][data-input="TextArea"]': {
          ...inputStyle,
          display: 'block',
          minHeight: '5rem',
          height: 'auto',
          overflow: 'auto',
          borderColor: gray[200],
          backgroundColor: formElement.readOnly.background,
        },
        label: {
          fontWeight: 500,
          whiteSpace: 'nowrap',
          display: 'inline-block',
          padding: '0 0 6px 0',
          cursor: 'pointer',
        },
        fieldset: {
          border: 0,
          padding: 0,
          margin: 0,
          minWidth: 0,
        },
        legend: {
          border: 0,
          padding: 0,
          margin: 0,
          minWidth: 0,
        },
        '.table-striped': {
          width: '100%',
          borderCollapse: 'collapse',
          borderColor: gray[200],

          th: {
            padding: '.5rem 1rem',
            textAlign: 'left',
          },

          tbody: {
            '& tr:nth-of-type(odd) td': {
              background: gray[75],
              borderBottom: `1px solid ${gray[300]}`,
            },
          },
          td: {
            padding: '.5rem 1rem',
            wordWrap: 'break-word',

            '&:last-of-type': { textAlign: 'right' },
          },
        },
        '.react-datepicker-wrapper': {
          display: 'block',
          width: 'unset',
          '[aria-label="Close"]': {
            display: 'none',
          },
        },
        '.react-datepicker__input-container': {
          display: 'block',
          width: 'unset',
          input: {
            background: iconBgImg('calendar', 6),
          },
        },
        '.react-datepicker-popper': {
          zIndex: `${AUTOCOMPLETE} !important` as anyOk,
        },
        '#walkme-player': visuallyHiddenStyle,
        '.Toastify__toast-body': {
          whiteSpace: 'pre-wrap',
        },
        '.Toastify__toast--warning': {
          color: colors.text,
          '.Toastify__close-button': {
            color: colors.text,
          },
        },
        '[data-reach-dialog-content]': {
          overflow: 'auto',
        },
        '[data-reach-tab-list]': {
          background: 'none',
        },
        'div[data-reach-tooltip]': {
          ...tooltip,
        },
        [`.${TOOLTIP_ERROR_LIST_CLASS}`]: {
          padding: '0 0 0 10px',
        },
        ['.fs-unmask *']: ENV_VARS.VITE_DEBUG_FS_MASK && {
          textShadow: '1px 1px goldenrod',
        },
        ['.fs-mask *']: ENV_VARS.VITE_DEBUG_FS_MASK && {
          textShadow: 'none',
        },
        '[data-reach-menu]': {
          zIndex: REACH_MENU,
          fontFamily: fonts.main,
        },
        '[data-reach-menu-list]': {
          border: 0,
          padding: 0,
          fontWeight: 500,
          overflow: 'hidden',
          fontSize: 'inherit',
          borderRadius: 2,
          boxShadow: '0 0 7px 5px rgba(0, 0, 0, 0.03)',
          ...contextMenu,
          '[data-reach-menu-item]': {
            fontWeight: 600,
            whiteSpace: 'nowrap',
            padding: '10px 15px',
            '&[data-selected]': {
              background: 'white',
              color: colors.primary,
              '&:hover': {
                color: colors.primary,
              },
              '&[data-contextmenuitem]': {
                color: '#333',
                '&:hover': {
                  color: colors.primary,
                },
              },
            },
            '&[disabled]': {
              color: gray[200],
            },
          },
        },
        '#content > *': {
          '-webkit-app-region': 'no-drag',
        },
      }}
    />
  );
};
