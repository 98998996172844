import * as TP from '../types';

import { gql } from '@apollo/client';
import { EmployeeInfoFragmentDoc } from '../fragments/employeeInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchEmployeesQueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.EmployeeFilter>;
  first?: TP.InputMaybe<TP.Scalars['Int']>;
  last?: TP.InputMaybe<TP.Scalars['Int']>;
  after?: TP.InputMaybe<TP.Scalars['String']>;
  before?: TP.InputMaybe<TP.Scalars['String']>;
}>;


export type SearchEmployeesQuery = (
  { readonly __typename?: 'Query' }
  & { readonly allEmployees?: TP.Maybe<(
    { readonly __typename?: 'EmployeeConnection' }
    & { readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & Pick<TP.PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), readonly edges: ReadonlyArray<(
      { readonly __typename?: 'EmployeeEdge' }
      & Pick<TP.EmployeeEdge, 'cursor'>
      & { readonly node: (
        { readonly __typename?: 'Employee' }
        & Pick<TP.Employee, 'companyEmail' | 'divisionId' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeCompanyLevelId' | 'employeeDepartmentId' | 'employeeDepartmentLevelId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeRoleId' | 'employeeShirtSizeId' | 'employeeSuffixId' | 'employeeTimeId' | 'employeeTypeId' | 'ext' | 'firstName' | 'firstNameDotLastName' | 'fullName' | 'hireDate' | 'id' | 'lastName' | 'managerId' | 'middleName' | 'mobilePhone' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'relationship' | 'slackName' | 'subRegion' | 'termDate' | 'title' | 'userId'>
        & { readonly division?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly employeeGroup?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly employeeOffice?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly employeeStatus: (
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'active'>
        ), readonly manager?: TP.Maybe<(
          { readonly __typename?: 'Employee' }
          & Pick<TP.Employee, 'companyEmail' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeDepartmentId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeSuffixId' | 'ext' | 'firstName' | 'fullName' | 'id' | 'lastName' | 'middleName' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'relationship' | 'title' | 'userId' | 'managerId'>
        )> }
      ) }
    )> }
  )> }
);


export const SearchEmployeesDocument = gql`
    query searchEmployees($filter: EmployeeFilter, $first: Int, $last: Int, $after: String, $before: String) {
  allEmployees(
    filter: $filter
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...EmployeeInfo
      }
    }
  }
}
    ${EmployeeInfoFragmentDoc}`;

/**
 * __useSearchEmployeesQuery__
 *
 * To run a query within a React component, call `useSearchEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchEmployeesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useSearchEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<SearchEmployeesQuery, SearchEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchEmployeesQuery, SearchEmployeesQueryVariables>(SearchEmployeesDocument, options);
      }
export function useSearchEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchEmployeesQuery, SearchEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchEmployeesQuery, SearchEmployeesQueryVariables>(SearchEmployeesDocument, options);
        }
export type SearchEmployeesQueryHookResult = ReturnType<typeof useSearchEmployeesQuery>;
export type SearchEmployeesLazyQueryHookResult = ReturnType<typeof useSearchEmployeesLazyQuery>;
export type SearchEmployeesQueryResult = Apollo.QueryResult<SearchEmployeesQuery, SearchEmployeesQueryVariables>;