import { CSSObject } from '@emotion/react';
import { jsonStringify } from '@utils/json';
import { memoize, omit, pick, pickBy } from 'lodash-es';
import { FC, ReactNode } from 'react';

export interface PaddingProps {
  /** All */
  a?: number;
  a1?: boolean;
  a2?: boolean;
  a3?: boolean;
  a4?: boolean;
  a5?: boolean;
  /** Top */
  t?: number;
  t1?: boolean;
  t2?: boolean;
  t3?: boolean;
  t4?: boolean;
  t5?: boolean;
  /** Right */
  r?: number;
  r1?: boolean;
  r2?: boolean;
  r3?: boolean;
  r4?: boolean;
  r5?: boolean;
  /** Bottom */
  b?: number;
  b1?: boolean;
  b2?: boolean;
  b3?: boolean;
  b4?: boolean;
  b5?: boolean;
  /** Left */
  l?: number;
  l1?: boolean;
  l2?: boolean;
  l3?: boolean;
  l4?: boolean;
  l5?: boolean;
  children?: ReactNode;
  className?: string;
}

const sizeArr = [10, 20, 30, 40, 50];

type Size = boolean | undefined;

const getFirstFound = (arr: Size[]): number | undefined => {
  return arr.map((b, idx) => b && sizeArr[idx]).find(Boolean) || undefined;
};

const getFirstDefinedSize = (arr1: Size[], arr2: Size[]): number => {
  return getFirstFound(arr1) || getFirstFound(arr2) || 0;
};

const getPaddingObjectFn = (props: PaddingProps): CSSObject => {
  const {
    a = 0,
    a1,
    a2,
    a3,
    a4,
    a5,
    t = 0,
    t1,
    t2,
    t3,
    t4,
    t5,
    r = 0,
    r1,
    r2,
    r3,
    r4,
    r5,
    b = 0,
    b1,
    b2,
    b3,
    b4,
    b5,
    l = 0,
    l1,
    l2,
    l3,
    l4,
    l5,
  } = props;
  const allArr = [a1, a2, a3, a4, a5];

  const paddingTop =
    getFirstDefinedSize([t1, t2, t3, t4, t5], allArr) || t * 10 || a * 10;
  const paddingRight =
    getFirstDefinedSize([r1, r2, r3, r4, r5], allArr) || r * 10 || a * 10;
  const paddingBottom =
    getFirstDefinedSize([b1, b2, b3, b4, b5], allArr) || b * 10 || a * 10;
  const paddingLeft =
    getFirstDefinedSize([l1, l2, l3, l4, l5], allArr) || l * 10 || a * 10;

  // Set a default if no props found
  const padding =
    !paddingTop && !paddingRight && !paddingBottom && !paddingLeft && '10px 0';

  return pickBy({
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    padding,
  });
};

const configPropsArr = [
  'a',
  'a1',
  'a2',
  'a3',
  'a4',
  'a5',
  't',
  't1',
  't2',
  't3',
  't4',
  't5',
  'r',
  'r1',
  'r2',
  'r3',
  'r4',
  'r5',
  'b',
  'b1',
  'b2',
  'b3',
  'b4',
  'b5',
  'l',
  'l1',
  'l2',
  'l3',
  'l4',
  'l5',
];

const getConfigProps = (props: PaddingProps): Partial<PaddingProps> =>
  pick(props, configPropsArr);

const getNonConfigProps = (props: PaddingProps): Partial<PaddingProps> =>
  omit(props, configPropsArr);

const getPaddingObject = memoize(getPaddingObjectFn, jsonStringify);

export const Padding: FC<PaddingProps> = (props) => {
  const { className, children, ...rest } = getNonConfigProps(props);
  return (
    <div
      className={className}
      css={getPaddingObject(getConfigProps(props))}
      {...rest}
    >
      {children}
    </div>
  );
};
