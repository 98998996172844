import { Grid } from '@components/Grid';
import { Icon } from '@components/Icon';
import { EmployeeDetailsEmployeeTreeFragment } from '@generated/fragments/EmployeeDetailsEmployeeTree';
import { EmployeeDetailsEmployeeTreeV2Fragment } from '@generated/fragments/employeeDetailsEmployeeTreeV2';
import { EmployeeInfoFragment } from '@generated/fragments/employeeInfo';
import { EmployeeInfoV2Fragment } from '@generated/fragments/employeeInfoV2';
import { ReactNode } from 'react';
import { Tooltip } from '../../../components/Tooltip';
import { TreeNode } from '../../../components/TreePicker';
import { EmployeeTreeNodeValue } from '../util';

export type EmployeeTreeInfoType =
  | EmployeeInfoFragment
  | EmployeeInfoV2Fragment
  | undefined
  | null;

export const isSlackUserExists = (
  employee: EmployeeDetailsEmployeeTreeV2Fragment,
  grayColor: string
): ReactNode => {
  return (
    <>
      {employee?.messagingUserIdentities?.length === 0 ? (
        <Icon
          i="commentLinesRegular"
          data-testid="slack-employee-tree-chat-disabled"
          css={{ opacity: '0.3', pointerEvents: 'none', color: grayColor }}
          size={13}
        />
      ) : (
        employee?.messagingUserIdentities
          ?.filter((obj) => obj.type === 'Slack')
          .slice(0, 1)
          .map((data, index) => {
            return (
              <>
                <a
                  key={index}
                  data-testid="slack-employee-tree-chat"
                  href={
                    'https://slack.com/app_redirect?channel=' + data.serverId
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon i="commentLinesRegular" size={13} />
                </a>
              </>
            );
          })
      )}
    </>
  );
};

const getSlackServiceIcons = (
  employee:
    | EmployeeDetailsEmployeeTreeFragment
    | EmployeeDetailsEmployeeTreeV2Fragment,
  grayColor: string
): ReactNode => {
  return (
    <div
      data-testid="slack-icon-block"
      css={{ float: 'right', 'margin-right': '10px' }}
    >
      {'messagingUserIdentities' in employee ? (
        isSlackUserExists(employee, grayColor)
      ) : (
        <Icon
          i="commentLinesRegular"
          data-testid="slack-employee-tree-chat-disabled"
          css={{ opacity: '0.3', pointerEvents: 'none', color: grayColor }}
          size={13}
        />
      )}
      &nbsp;
      <a
        data-testid="slack-employee-tree-email"
        href={'mailto:' + employee.email}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon i="envelopeRegular" size={13} />
      </a>
    </div>
  );
};

const TreeNodeTooltipLabel = (
  employee:
    | EmployeeDetailsEmployeeTreeFragment
    | EmployeeDetailsEmployeeTreeV2Fragment,
  viewSlackEmployeeTreeFlag: boolean | undefined,
  grayColor: string
): ReactNode => {
  return (
    <Tooltip
      label={
        <>
          <div>
            <b>{employee?.fullName}</b>
          </div>
          <div>{employee?.title}</div>
          <div>{employee?.phoneNumber}</div>
          <hr />
          <div>
            <b>Group: </b>
            {employee?.employeeGroup?.name}
          </div>
          <div>
            <b>Office: </b>
            {employee?.employeeOffice?.name}
          </div>
          <div>
            <b>Manager: </b>
            {employee?.manager?.fullName || 'N/A'}
          </div>
          <div>
            <b>Division: </b>
            {employee?.division?.name}
          </div>
        </>
      }
    >
      <div>
        <Grid sm="2fr 2fr">
          <div data-testid="employee-node-tooltip">{employee?.fullName}</div>
          <div data-testid="slack-employee-tree">
            {viewSlackEmployeeTreeFlag
              ? getSlackServiceIcons(employee, grayColor)
              : ''}
          </div>
        </Grid>
      </div>
    </Tooltip>
  );
};

export const getEmployeeTreeNodes = (
  employees:
    | Readonly<EmployeeDetailsEmployeeTreeFragment[]>
    | Readonly<EmployeeDetailsEmployeeTreeV2Fragment[]>
    | null
    | undefined,
  viewSlackEmployeeTreeFlag: boolean | undefined,
  grayColor: string
): TreeNode<EmployeeTreeNodeValue>[] => {
  if (!employees) {
    return [];
  }
  const employeeTreeNodes: TreeNode<EmployeeTreeNodeValue>[] = [];
  employees.forEach((employee) => {
    employeeTreeNodes.push({
      id: employee?.id || '',
      label: TreeNodeTooltipLabel(
        employee,
        viewSlackEmployeeTreeFlag,
        grayColor
      ),
      value: {
        id: employee?.id || '',
        fullName: employee?.fullName || '',
        employeeRoleId: employee?.employeeRole?.id,
      },
      isChecked: false,
      isExpanded: false,
      children:
        employee.children && employee.children?.length > 0
          ? getEmployeeTreeNodes(
              employee?.children,
              viewSlackEmployeeTreeFlag,
              grayColor
            )
          : undefined,
    });
  });
  return employeeTreeNodes;
};

export const prioritizeEmployeeTreeNodes = (
  employeeTreeNodes: TreeNode<EmployeeTreeNodeValue>[],
  employeeId: string | undefined,
  employeeManagerId: string | undefined
): TreeNode<EmployeeTreeNodeValue>[] => {
  if (employeeId && employeeManagerId) {
    const managerNode = employeeTreeNodes.splice(
      employeeTreeNodes.findIndex((node) => node.id === employeeManagerId),
      1
    )[0];
    if (managerNode) {
      const childrenNodes = managerNode?.children;
      const currentEmployeeNode = childrenNodes?.splice(
        childrenNodes.findIndex((node) => node.id === employeeId),
        1
      )[0];
      if (currentEmployeeNode) {
        childrenNodes?.unshift(currentEmployeeNode);
      }
      employeeTreeNodes?.unshift(managerNode);
    }
  }
  return employeeTreeNodes;
};
