import { CSSObject } from '@emotion/react';
import { breakpoint, breakpointMap, BreakpointObject } from '@utils/breakpoint';
import { jsonStringify } from '@utils/json';
import { keys } from '@utils/keys';
import { fromPairs, isEmpty, memoize, omit, pick, pickBy } from 'lodash-es';
import { FC, forwardRef, HTMLProps, ReactNode } from 'react';
import { BASE_GAP_MULTIPLIER } from './constants';

interface Props extends Partial<BreakpointObject<string>> {
  as?: 'div' | 'ul';
  hidden?: boolean;
  children: ReactNode;
  className?: string;
  /** Number of columns */
  cols?: number;
  /** The minimum pixels a column should have */
  fit?: number;
  /** grid-gap rem */
  gap?: number;
  /** grid-gap for breakpoint:lg */
  lgGap?: number;
  /** grid-gap for breakpoint:md */
  mdGap?: number;
  /** Can be a number for px or any valid padding string property */
  padding?: string | number;
  /** grid-gap for breakpoint:sm */
  smGap?: number;
  /** grid-gap for breakpoint:xl */
  xlGap?: number;
  /** grid-gap for breakpoint:xxl */
  xxlGap?: number;
  /** grid-gap for breakpoint:vl */
  vlGap?: number;
  /** grid-gap for breakpoint:vvl */
  vvlGap?: number;
}

export const colSpan = (span: number): CSSObject => ({
  gridColumn: `span ${span}`,
});

const unitMultiplier = (n: number): string => `${n * BASE_GAP_MULTIPLIER}px`;
const evenSplit = (value: number): string => `repeat(${value}, 1fr)`;

// generate styles for each breakpoint specified in props
const genBreakpointStylesRaw = (
  props: Omit<Props, 'children'>
): Record<string, unknown> =>
  fromPairs(
    keys(breakpointMap)
      .map((bp) => {
        const gridTemplateColumns = props[bp];
        const gap = props[`${bp}Gap` as keyof typeof props] as number;
        const val = pickBy({
          gridTemplateColumns,
          gap: gap ? `${unitMultiplier(gap)}` : undefined,
        });
        return [breakpoint[bp], isEmpty(val) ? undefined : val];
      })
      .filter(([, val]) => Boolean(val))
  );

const genBreakpointStyles = memoize(genBreakpointStylesRaw, jsonStringify);

const getCols = ({ fit, cols, xs }: Props): string => {
  let str = '100%';
  if (xs) {
    str = xs;
  } else if (fit) {
    str = `repeat(auto-fit, minmax(${fit}px, 1fr))`;
  } else if (cols) {
    str = evenSplit(cols);
  }
  return str;
};

// We don't want these to be applied to <Element/> so filter them out
const configPropsArr: Array<keyof Props> = [
  'cols',
  'fit',
  'lg',
  'lgGap',
  'md',
  'mdGap',
  'sm',
  'smGap',
  'xl',
  'xlGap',
  'xxl',
  'xxlGap',
  'xs',
  'vl',
  'vlGap',
  'vvl',
  'vvlGap',
];

export const Grid: FC<Props> = (rawProps) => {
  const { children, ...props } = rawProps;
  const {
    as = 'div',
    className,
    gap: gapProp = 1,
    padding,
    ...rest
  } = omit(props, configPropsArr);

  const gap = unitMultiplier(gapProp);
  const Element = as;

  return (
    <Element
      {...rest}
      css={pickBy({
        display: 'grid',
        gap,
        gridTemplateColumns: getCols(rawProps),
        gridAutoRows: 'min-content',
        padding:
          typeof padding === 'number' ? `${unitMultiplier(padding)}` : padding,
        maxWidth: '100%',
        width: '100%',
        ...genBreakpointStyles(pick(props, configPropsArr)),
      })}
      className={className}
    >
      {children}
    </Element>
  );
};

export const VertGrid = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement> & {
    children: ReactNode;
    onScroll?: (evt: fixMe) => void;
  }
>((props, ref) => (
  <div
    css={{ display: 'grid', gridAutoFlow: 'column', gridGap: 20 }}
    {...props}
    ref={ref}
  />
));
