import { getFormattedFormikError } from '@components/Button/SaveButton';
import { CSSObject } from '@emotion/react';
import { isString } from 'lodash-es';
import { FC, ReactNode } from 'react';
import { Grid } from '../../Grid';
import { Icon, IconProp } from '../../Icon';
import { PositionType, Tooltip } from '../../Tooltip';

export enum IconPosition {
  'normal' = 'normal',
  'inline' = 'inline',
}

interface Props {
  info: ReactNode;
  gap?: number;
  icon?: IconProp;
  iconColor?: string;
  isVisible?: boolean;
  fieldName?: string;
  iconPosition?: IconPosition;
  onIconClick?: (val: unknown | null) => anyOk;
  fieldValue?: anyOk;
  positionType?: PositionType;
}

const STATUS_ICON_SIZE = 14;

const iconPositionMap: Record<IconPosition, CSSObject> = {
  inline: {
    top: `calc(50% - ${Math.ceil(STATUS_ICON_SIZE / 2)}px)`,
    right: 5,
    height: '75%',
  },
  normal: {
    top: 0,
    right: 0,
  },
};

export const STATUS_ICON_WRAPPER_ICON_ZINDEX = 2;

export const StatusIconWrapper: FC<Props> = ({
  info,
  gap,
  icon = 'infoCircle',
  iconColor,
  isVisible,
  fieldName,
  iconPosition = IconPosition['normal'],
  onIconClick,
  fieldValue,
  positionType,
}) => {
  const Wrapper = onIconClick ? 'button' : 'span';
  let formattedInfo = info;
  if (isString(formattedInfo)) {
    formattedInfo = getFormattedFormikError(fieldName || '', info as string);
  }
  const formattedLabelEl =
    isString(formattedInfo) && formattedInfo ? (
      <div dangerouslySetInnerHTML={{ __html: formattedInfo }} />
    ) : (
      formattedInfo
    );
  return (
    <Grid sm="1fr min-content" gap={gap}>
      <div role="alert" hidden>
        {formattedLabelEl}
      </div>
      <Tooltip
        // For some reason @reach/tooltip seems to be detecting the presence of
        // the key instead of simply the value. Thus the conditional spread here
        // instead of isVisible={undefined}.
        {...(isVisible === true
          ? {
              isVisible,
            }
          : undefined)}
        label={formattedLabelEl}
        positionType={positionType || 'top'}
      >
        <div
          data-testid={fieldName ? `${fieldName}-tooltip-icon` : undefined}
          css={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'start',
            height: '100%',
            zIndex: STATUS_ICON_WRAPPER_ICON_ZINDEX,
            ...iconPositionMap[iconPosition],
          }}
        >
          <Wrapper
            type={onIconClick ? 'button' : undefined}
            onClick={
              onIconClick
                ? (): void => {
                    onIconClick(fieldValue);
                  }
                : undefined
            }
          >
            <Icon i={icon} size={STATUS_ICON_SIZE} color={iconColor} />
          </Wrapper>
        </div>
      </Tooltip>
    </Grid>
  );
};
