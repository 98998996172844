import { KeyValueInfoFragment } from '@generated/fragments/keyValueInfo';
import { KeyValue } from '@generated/types';
import { camelCase } from 'lodash-es';
import { TCName } from '.';

export enum ExtraTypesEnum {
  LoadMode = 'loadMode',
  LoadRelationship = 'loadRelationship',
  LoadPower = 'loadPower',
  LoadTrailer = 'loadTrailer',
  LoadCondition = 'loadCondition',
  CommodityFreightClass = 'commodityFreightClass',
  CommodityHazmatClass = 'commodityHazmatClass',
  TenderMode = 'tenderMode',
  MileageProvider = 'mileageProvider',
  MileMakerRouteType = 'mileMakerRouteType',
  DistanceSearchPreference = 'distanceSearchPreference',
  Distance = 'distance',
}

const opt = (name: string, kwargs?: Partial<KeyValue>): KeyValue => {
  return {
    active: true,
    id: camelCase(name),
    metadataJson: '{}',
    name,
    sort: 1,
    systemDefined: true,
    description: '',
    ...kwargs,
  };
};

export const extraTypes: Array<{
  options: KeyValueInfoFragment[];
  name: TCName;
}> = [
  {
    name: ExtraTypesEnum.LoadMode,
    options: ['TL', 'LTL', 'IMDL', 'Air', 'Ocean'].map((s) => opt(s)),
  },
  {
    name: ExtraTypesEnum.LoadRelationship,
    options: ['Transact', 'Managed', 'Market'].map((s) => opt(s)),
  },
  {
    name: ExtraTypesEnum.LoadPower,
    options: ['Brokered', 'Managed', 'Own'].map((s) => opt(s)),
  },
  {
    name: ExtraTypesEnum.LoadTrailer,
    options: ['Brokered', 'Managed', 'Own'].map((s) => opt(s)),
  },
  {
    name: ExtraTypesEnum.LoadCondition,
    options: ['Active', 'Hold', 'Pending', 'Cancelled'].map((s) => opt(s)),
  },
  {
    name: ExtraTypesEnum.MileageProvider,
    options: [
      opt('PC Miler', { id: 'pcmiler' }),
      opt('MileMaker', { id: 'milemaker' }),
      opt('Azure', { id: 'azure' }),
    ],
  },
  {
    name: ExtraTypesEnum.MileMakerRouteType,
    options: [
      opt('Practical', { id: 'p' }),
      opt('Household Goods', { id: 'h' }),
    ],
  },
  {
    name: ExtraTypesEnum.DistanceSearchPreference,
    options: [
      opt('Postal Code', { id: 'postal code' }),
      opt('Geopoint', { id: 'geopoint' }),
      opt('Locality', { id: 'locality' }),
    ],
  },
  {
    name: ExtraTypesEnum.CommodityFreightClass,
    options: [
      opt('50', { id: 'Class_50' }),
      opt('55', { id: 'Class_55' }),
      opt('60', { id: 'Class_60' }),
      opt('65', { id: 'Class_65' }),
      opt('70', { id: 'Class_70' }),
      opt('77.5', { id: 'Class_77_5' }),
      opt('85', { id: 'Class_85' }),
      opt('92.5', { id: 'Class_92_5' }),
      opt('100', { id: 'Class_100' }),
      opt('110', { id: 'Class_110' }),
      opt('125', { id: 'Class_125' }),
      opt('150', { id: 'Class_150' }),
      opt('175', { id: 'Class_175' }),
      opt('200', { id: 'Class_200' }),
      opt('250', { id: 'Class_250' }),
      opt('300', { id: 'Class_300' }),
      opt('400', { id: 'Class_400' }),
      opt('500', { id: 'Class_500' }),
      opt('No Freight Class', { id: 'No_Freight_Class' }),
    ],
  },
  {
    name: ExtraTypesEnum.CommodityHazmatClass,
    options: [
      opt('1.1', { id: 'Division_1_1' }),
      opt('1.2', { id: 'Division_1_2' }),
      opt('1.3', { id: 'Division_1_3' }),
      opt('1.4', { id: 'Division_1_4' }),
      opt('1.5', { id: 'Division_1_5' }),
      opt('1.6', { id: 'Division_1_6' }),
      opt('2.1', { id: 'Division_2_1' }),
      opt('2.2', { id: 'Division_2_2' }),
      opt('2.3', { id: 'Division_2_3' }),
      opt('3', { id: 'Division_3' }),
      opt('4.1', { id: 'Division_4_1' }),
      opt('4.2', { id: 'Division_4_2' }),
      opt('4.3', { id: 'Division_4_3' }),
      opt('5.1', { id: 'Division_5_1' }),
      opt('5.2', { id: 'Division_5_2' }),
      opt('6.1', { id: 'Division_6_1' }),
      opt('6.2', { id: 'Division_6_2' }),
      opt('7', { id: 'Division_7' }),
      opt('8', { id: 'Division_8' }),
      opt('9', { id: 'Division_9' }),
    ],
  },
  {
    name: ExtraTypesEnum.TenderMode,
    //'SMS' is not currently supported
    options: ['EDI', 'Email', 'EDI & Email'].map((s) => {
      return {
        active: true,
        id: s,
        metadataJson: '{}',
        name: s,
        sort: 1,
        systemDefined: true,
        description: '',
      };
    }),
  },
  {
    name: ExtraTypesEnum.Distance,
    options: [
      opt('Customer Defaults', { id: 'customerdefaults' }),
      opt('PCMiler Defaults', { id: 'pcmilerdefaults' }),
      opt('Milemaker Defaults', { id: 'milemakerdefaults' }),
    ],
  },
];
