import { lazy, ReactElement, Suspense } from 'react';

const Component = lazy(() =>
  import('./component').then((module) => ({ default: module.LoadSearchV2 }))
);

export const LoadSearchV2 = (): ReactElement => (
  <Suspense fallback="">
    <Component />
  </Suspense>
);
