import { Menu, MenuButton, MenuList } from '@reach/menu-button';
import { FC, ReactNode } from 'react';
import { Icon } from '../Icon';

interface Props {
  text: ReactNode;
}

// TODO: work this into the theme?
const toggleGray = '#C6C6C6';

/** Direct children of ToggleMenu MUST be MenuItem or MenuLink elements */
export const ToggleMenu: FC<Props> = (props) => {
  return (
    <div
      css={{
        position: 'relative',
        '[data-reach-menu-button]': {
          fontSize: 14,
          color: toggleGray,
          padding: 20,
          '&:hover': {
            color: 'white',
          },
          '&[aria-expanded="true"]': {
            color: 'white',
          },
        },
      }}
    >
      <Menu>
        {({
          isOpen,
        }: fixMe): // TS typings for @reach/menu-button are currently incorrect and do not reflect the render prop options
        ReactNode => (
          <>
            <MenuButton>
              {props.text}
              <Icon
                i={isOpen ? 'chevronUp' : 'chevronDown'}
                size={10}
                css={{ marginLeft: 6 }}
                aria-hidden
              />
            </MenuButton>
            {isOpen && (
              <MenuList
                css={{ marginTop: props.text !== 'Dashboard' ? -8 : 12 }}
              >
                {props.children}
              </MenuList>
            )}
          </>
        )}
      </Menu>
    </div>
  );
};
