import { MutationFunctionOptions } from '@apollo/client';
import { RESPONSE_META_KEY } from '@app/client/constants';
import { APP_VERBOSE_ERROR_DISPLAY_SYMBOL } from '@app/GlobalVariables/constants';
import { getGlobalVariable } from '@app/GlobalVariables/util';
import {
  toast,
  toastOptions as defaultToastOptions,
  ToastOptions,
} from '@components/Toast';
import { IS_CYPRESS } from '@utils/constants';
import { ExecutionResult, GraphQLError } from 'graphql';
import { flatten, get, isEqual } from 'lodash-es';
import { ReactText } from 'react';

export const REQ_ID_KEY = 'x-mastery-req-id' as const;
interface CustomOperationMeta {
  headers: {
    [REQ_ID_KEY]?: string;
  };
}

export const getErrorMessageReqIdPrefix = (
  meta: CustomOperationMeta | undefined
): string => {
  const reqId = get(meta, ['headers', REQ_ID_KEY]);
  if (reqId) {
    return `Request ID:\n${reqId}\n`;
  }
  return '';
};

type MutateFn<T> = (
  options?: MutationFunctionOptions
) => Promise<ExecutionResult<T>>;

interface Options {
  hideSuccessToast?: boolean;
  toastOptions?: Partial<ToastOptions>;
  successMessage?: ReactText;
  showVerboseError?: boolean;
}

const parseError = (
  error: GraphQLError,
  meta: CustomOperationMeta
): [string, boolean] => {
  let prefix = '';
  if (getGlobalVariable(APP_VERBOSE_ERROR_DISPLAY_SYMBOL)) {
    prefix = getErrorMessageReqIdPrefix(meta);
  }
  const path = error.path || [];

  // We explicitly know about this error, and we want to display it.
  // This one happens when a user tries to pick a carrier code that is already taken
  if (isEqual(path, ['attributes', 'code'])) {
    return [`${prefix}Code has already been taken`, true];
  }

  return [`${prefix}${error.message}`, false];
};

export const DEFAULT_TOAST_SUCCESS_MSG = 'Updated';

export const submitMutation = async <T>(
  mutate: MutateFn<T>,
  options?: Options
): ReturnType<MutateFn<T>> => {
  try {
    const response = await mutate();
    const { errors: topErrors, data } = response;
    const meta: CustomOperationMeta = get(data, RESPONSE_META_KEY);
    const mutationErrors = flatten(
      Object.values((data as anyOk) || {}).map(
        (obj: anyOk) => obj?.errors || []
      )
    );
    // topErrors are things like graphql formatting, or unknown variables + keys
    // mutationErrors are those incurred from the actual mutation sent, which are inside of the mutation name property on data
    const errors = (topErrors || []).concat(mutationErrors);
    const toastOptions: ToastOptions = {
      ...defaultToastOptions,
      ...(options?.toastOptions ?? {}),
    };
    if (errors.length && errors[0]) {
      const [msgParsed, allowDisplay] = parseError(errors[0], meta);
      const finalMsg =
        getGlobalVariable(APP_VERBOSE_ERROR_DISPLAY_SYMBOL) ||
        allowDisplay ||
        options?.showVerboseError
          ? msgParsed
          : 'An error occurred when attempting to submit data.';
      toast.error(finalMsg, toastOptions);
    } else {
      if (!options?.hideSuccessToast) {
        toast.success(
          options?.successMessage || DEFAULT_TOAST_SUCCESS_MSG,
          toastOptions
        );
      }
    }

    return response;
  } catch (err: anyOk) {
    // This catch usually happens when Apollo client deems there to be a NetworkError
    // eslint-disable-next-line no-console
    const str = 'Failed to update. Please try again or contact support.';
    toast.error(
      IS_CYPRESS ? err?.message : (!err?.networkError && err?.message) || str
    );
    return err;
  }
};
