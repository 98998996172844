import * as TP from '../types';

import { gql } from '@apollo/client';
export type CarrierInfoSidebarFragment = (
  { readonly __typename?: 'Carrier' }
  & Pick<TP.Carrier, 'id' | 'name' | 'code'>
  & { readonly mainAddress?: TP.Maybe<(
    { readonly __typename?: 'Address' }
    & Pick<TP.Address, 'id' | 'city' | 'state'>
  )> }
);

export const CarrierInfoSidebarFragmentDoc = gql`
    fragment CarrierInfoSidebar on Carrier {
  id
  name
  code
  mainAddress {
    id
    city
    state
  }
}
    `;