import { ThemeColor } from '@emotion/react';
import { useTheme } from '@hooks/useTheme';
import { win } from '@utils/win';
import { isNumber, random } from 'lodash-es';
import { FC, useEffect, useRef, useState } from 'react';
import { useInterval } from 'react-use';

export interface Props {
  loading: boolean;
  targetMillis: number;
  height?: number;
  background?: string | ThemeColor;
}

const DESIRED_STEPS_TO_SHOW = 20;
export const DEFAULT_LOADING_BAR_HEIGHT = 3;

const DEFAULT_GRADIENT_SECOND_COLOR = '#0FABFF';

export const LoadingBar: FC<Props> = ({
  loading,
  targetMillis,
  height = DEFAULT_LOADING_BAR_HEIGHT,
  background: backgroundProp,
  ...rest
}) => {
  const { colors } = useTheme();
  const delay = targetMillis / DESIRED_STEPS_TO_SHOW;
  const [percent, setPercent] = useState(0);
  const isRunning = loading && isNumber(percent) && percent < 99;
  const cancelTimeout = useRef(0);
  const background =
    colors[backgroundProp as ThemeColor] ||
    backgroundProp ||
    `linear-gradient(90deg, ${colors.primary} 0%, ${DEFAULT_GRADIENT_SECOND_COLOR} 100%)`;
  useInterval(
    () => {
      setPercent((p) => {
        if (p === 0) {
          return 1;
        }
        const toGo = 100 - p;
        const toAdd = random(toGo / 10, toGo / 6, true);
        return p + toAdd;
      });
    },
    isRunning ? delay : null
  );
  useEffect(() => {
    if (!loading && percent > 0) {
      setPercent(100);
      win.clearTimeout(cancelTimeout.current);
      cancelTimeout.current = win.setTimeout(() => {
        setPercent(0);
      }, 300);
    } else if (loading) {
      win.clearTimeout(cancelTimeout.current);
    } else if (percent !== 0) {
      setPercent(0);
    }
  }, [loading, percent]);
  return (
    <div
      css={{
        width: '100%',
        height,
        '&:after': {
          content: '""',
          display: 'block',
          opacity: percent === 100 ? '0' : '1',
          width: `${percent}%`,
          maxWidth: '100%',
          height: '100%',
          background,
          transition:
            percent === 0 || percent === 100
              ? 'opacity 300ms'
              : 'all 300ms ease-in-out',
        },
      }}
      {...rest}
    />
  );
};
