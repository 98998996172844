export const APP_KEYCLOAK_TRIGGER_REFRESH_SECONDS_SYMBOL = Symbol(
  'app-keycloak-trigger-refresh-seconds'
);

export const APP_KEYCLOAK_MIN_VALIDITY_SECONDS_SYMBOL = Symbol(
  'app-keycloak-min-validity-seconds'
);

export const APP_KEYCLOAK_USE_KEYCLOAK_USER_ID = Symbol(
  'ME-17006-feat-keycloak-use-keycloak-user-id'
);

export const APP_VERBOSE_ERROR_DISPLAY_SYMBOL = Symbol(
  'app-verbose-error-display'
);

export const APP_RELOAD_ON_AUTH_TIMEOUT_SYMBOL = Symbol(
  'app-reload-on-auth-timeout'
);

export const APP_QUIET_NETWORK_ERRORS = Symbol(
  'ME-34235-app-quiet-network-errors'
);

export const APP_FIX_COMBINE_DATE_TIME = Symbol(
  'ME-34100-fix-combine-date-time'
);
