import {
  LOCAL_STORAGE_LD_USER_IDENTIFIERS,
  STARTED_WITH_USER_DATA,
  UserData,
} from '@components/AuthContext';
import { RESOLVED_TENANT } from '@utils/getTenantFromDomain';
import { setLocalStorage } from '@utils/localStorage';
import { reportError, sentry } from '@utils/sentry';
import { win } from '@utils/win';
import { LDClient } from 'launchdarkly-js-client-sdk';

interface SetUserArg {
  ldClient?: LDClient;
  data: UserData;
}

export const setUserData = async (arg: SetUserArg): Promise<void> => {
  const { ldClient, data } = arg;
  const { email, name, key } = data;

  setLocalStorage(LOCAL_STORAGE_LD_USER_IDENTIFIERS, {
    email,
    name,
    key,
    custom: { hostname: win.location.hostname },
  });

  try {
    // LAUNCH DARKLY
    // If we started with user data, we already identified when the app booted, in the initLD method
    if (!STARTED_WITH_USER_DATA) {
      await ldClient?.identify({
        name,
        email,
        key: key || 'unknown',
        custom: {
          hostname: win.location.hostname,
        },
      });
    }
  } catch (ldErr) {
    reportError(ldErr);
  }

  // SENTRY
  sentry.configureScope((scope) => {
    scope.setUser({ email });
  });
};

// https://support.walkme.com/knowledge-base/variables/
export const setWalkmeVariables = (
  data: UserData & Record<string, Maybe<string>>
): void => {
  const { name, email, key } = data;
  win.masteryWalkmeVariables = {
    ...data,
    name: name || 'unknown',
    email: email || 'unknown',
    tenant: RESOLVED_TENANT,
    host: win.location.host,
    custom: undefined,
    key,
  };
};
