// eslint-disable-next-line no-restricted-imports
import { Theme, useTheme as useEmotionTheme } from '@emotion/react';
import { MODE } from '@env';
import { themeObj } from '../../components/Theme';

export function useTheme(): Theme {
  const theme = useEmotionTheme();
  // for jest
  if (MODE === 'test') {
    return themeObj;
  }
  return theme;
}
