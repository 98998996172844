import * as TP from '../types';

import { gql } from '@apollo/client';
export type SeerLoadMasterfindFragment = (
  { readonly __typename?: 'SeerLoadSearchV2' }
  & Pick<TP.SeerLoadSearchV2, 'id' | 'loadCode' | 'routeCode' | 'orderNumber' | 'puStartDate' | 'puEndDate' | 'puStartTimeZone' | 'puEndTimeZone' | 'originCity' | 'originState' | 'destinationCity' | 'destinationState' | 'customer' | 'carrier' | 'refNumber'>
);

export const SeerLoadMasterfindFragmentDoc = gql`
    fragment SeerLoadMasterfind on SeerLoadSearchV2 {
  id
  loadCode
  routeCode
  orderNumber
  puStartDate
  puEndDate
  puStartTimeZone
  puEndTimeZone
  originCity
  originState
  destinationCity
  destinationState
  customer
  carrier
  refNumber
}
    `;