import {
  ApolloClient,
  ApolloLink,
  defaultDataIdFromObject,
  InMemoryCache,
  NormalizedCacheObject,
  Operation,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client/link/http';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { WebSocketLink } from '@apollo/client/link/ws';
import { relayStylePagination } from '@apollo/client/utilities';
import { getUserData } from '@components/AuthContext';
import {
  getErrorMessageReqIdPrefix,
  REQ_ID_KEY,
} from '@components/shared/mutation';
import { toast } from '@components/Toast';
import { LoadInfoFragment } from '@generated/fragments/loadInfo';
import { useUserEmail } from '@hooks/useUserEmail';
import {
  BUILD_VERSION,
  environment,
  IS_LOCAL_DEV,
  IS_NOT_PREVIEW_OR_PROD,
} from '@utils/constants';
import { convertForGraphql } from '@utils/graphqlUtils';
import { jsonStringify } from '@utils/json';
import { reportError, sentry } from '@utils/sentry';
import { win } from '@utils/win';
import { sha256 } from 'crypto-hash';
import { GraphQLError } from 'graphql';
import { compact, debounce, get, isArray, pickBy, set, unset } from 'lodash-es';
import { useMemo } from 'react';
import { v4 } from 'uuid';
import { config } from '../../config';
import { getAuthHeader } from '../auth/token';
import {
  APP_QUIET_NETWORK_ERRORS,
  APP_RELOAD_ON_AUTH_TIMEOUT_SYMBOL,
  APP_VERBOSE_ERROR_DISPLAY_SYMBOL,
} from '../GlobalVariables/constants';
import { getGlobalVariable } from '../GlobalVariables/util';
import { RESPONSE_META_KEY } from './constants';

const JWT_EXPIRED_CODE = 'JWT_EXPIRED_SIGNATURE';

const wsLink = new WebSocketLink({
  uri: config?.subscriptionApiEndpoint || '',
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: (): anyOk => ({
      authorization: getAuthHeader(),
    }),
  },
});

const httpLink = createHttpLink({
  uri: ({ operationName, query }: Operation): string => {
    const type = get(query, 'definitions[0].operation', 'query');
    return `${config.apiEndpoint}?${type[0] || 'q'}=${operationName}`;
  },
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: getAuthHeader(),
  },
}));

const customHeadersLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      [REQ_ID_KEY]: v4(),
      'X-Client-Version': BUILD_VERSION || '',
      'x-mastery-audit-context-initiated-via': 'user',
      'x-mastery-audit-context-initiated-timestamp': Date.now(),
      'x-mastery-audit-context-initiated-id': getUserData()?.key,
    },
  };
});

const addMetaLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    set(data, ['data', RESPONSE_META_KEY], {
      headers: operation.getContext().headers,
    });
    return data;
  });
});

interface ExtendedLoad extends LoadInfoFragment {
  truckId: string;
}

const cleanVariablesForMutationLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = convertForGraphql(operation.variables);
  }
  return forward(operation);
});

const ERROR_AND_RELOAD_TIMEOUT_MS = 1000 * 10;

const errorAndReloadDebounced = debounce(
  () => {
    if (getGlobalVariable(APP_RELOAD_ON_AUTH_TIMEOUT_SYMBOL)) {
      const timeout = setTimeout(
        () => win.location.reload(),
        ERROR_AND_RELOAD_TIMEOUT_MS
      );
      toast.error(
        `Your browser window has been open too long, refreshing in ${Math.round(
          ERROR_AND_RELOAD_TIMEOUT_MS / 1000
        )} seconds...`,
        {
          onClose: () => clearTimeout(timeout),
          autoClose: false,
        }
      );
    }
  },
  200,
  // we want to fire off this event immediately, not wait until things settle
  // network requests might keep firing, so take the first invocation
  { leading: true, trailing: false }
);

interface MasteryGraphQLError extends Omit<GraphQLError, 'extensions'> {
  extensions: {
    code?: string;
    details?: string;
    serviceName?: string;
    exception?: {
      stacktrace?: string[];
    };
  };
}

const getErrorMessage = (message?: string): string => {
  const isInputError = (message || '').startsWith(
    'Variable "$input" got invalid value'
  );
  if (isInputError) {
    return 'Invalid input variable';
  }
  return message || 'Unknown';
};

const jwtExpiredTrace =
  'AuthenticationError: Context creation failed: Invalid JWT: TokenExpiredError: jwt expired.';

// The idea behind this list is that we _should_ already have these errors in the respective service Sentry project (like mastery-api). We don't want to double report so we strip these out from the frontend side.
const quietCodes = [
  'SERVER_ERROR',
  'NOT_FOUND',
  'INVALID_DATA',
  'DOWNSTREAM_SERVICE_ERROR',
  'INTERNAL_SERVICE_ERROR',
  'INTERNAL_SERVER_ERROR',
  // ⬇ Comes from Records API
  'ARGUMENT_ERROR',
  // ⬇ Comes from `mastery-accounting` API
  // https://github.com/masterysystems/mastery-accounting/blob/6808ad096e325892a053ea6954ecb4cd579c5424/src/Mastery.Accounting.GraphQL/Mutations/Mutation.cs#L627
  'BAD_USER_INPUT',
  // ⬇ Example: Error trying to resolve field 'getRoundsByBidId'.
  'FORMAT',
  // ⬇ Example: Cannot return null for non-nullable field.
  'EXEC_NON_NULL_VIOLATION',
  // ⬇ As of writing we see this come through for JWT expired errors
  // We should probably attempt to limit seeing those types of errors entirely
  // No end user bad behaviors have been reported - user should be returned to login page automatically
  'UNAUTHENTICATED',
];

// We know these errors are truly innocuous, so do nothing with them.
const omitCodes = ['PERSISTED_QUERY_NOT_FOUND'];

const outputAPIWarning = (
  errorObj: MasteryGraphQLError,
  opName: string
): void => {
  const modifiedErrorObj = { ...errorObj };
  // These keys can contain a lot of data, we don't need to report them to Sentry.
  // On larger requests these can actually make call to Sentry fail with a 413 Entity Too Large error.
  unset(modifiedErrorObj, 'extensions.query');
  unset(modifiedErrorObj, 'extensions.variables');
  set(modifiedErrorObj, 'operation', opName);
  // eslint-disable-next-line no-console
  return console.warn(`API Error:\n\n${jsonStringify(modifiedErrorObj)}`);
};

const errorLink = onError(({ graphQLErrors, operation, networkError }) => {
  const type = get(operation.query, 'definitions[0].operation', 'query');
  const opName = operation.operationName;
  // For some reason, graphQLErrors will pass the isArray function, but will not have a map method...
  // https://sentry.io/organizations/mastery-logistics-systems/issues/1901239299
  if (isArray(graphQLErrors)) {
    const errorsArr = graphQLErrors as MasteryGraphQLError[];
    errorsArr.map((errorObj) => {
      try {
        const { message, locations, path, extensions } = errorObj;
        const { code, details, serviceName } = extensions || {};
        const firstStackTrace =
          get(extensions, 'exception.stacktrace[0]') || '';
        if (code === JWT_EXPIRED_CODE || firstStackTrace === jwtExpiredTrace) {
          errorAndReloadDebounced();
          if (getGlobalVariable(APP_QUIET_NETWORK_ERRORS)) {
            return;
          }
        }

        if (omitCodes.includes(code || '')) {
          return;
        }

        if (opName === 'FindOneValidationConfiguration' && code === '404') {
          return;
        }

        if ((code || '').match(/^\d+$/)) {
          // Do nothing with pure numeric codes
          // https://github.com/masterysystems/customer/blob/c9fa3dd930c0e4a4ee35e10a3728c18438ef860d/apps/customer/src/common/errors/error-codes.enum.ts
          return;
        }

        if (quietCodes.includes(code || '')) {
          if (getGlobalVariable(APP_QUIET_NETWORK_ERRORS)) {
            return;
          }
          return outputAPIWarning(errorObj, opName);
        }

        let exceptionData: anyOk;
        try {
          exceptionData = {
            tags: pickBy({
              service: serviceName || '',
              code: code || '',
              graphql: 'true',
              path: path?.join('.') || '',
              operationType: type,
              operation: opName,
            }),
            extra: pickBy({
              locations: jsonStringify(locations),
              details,
              message,
            }),
          };
        } catch {
          // noop
        }

        // https://blog.sentry.io/2019/01/17/debug-tough-front-end-errors-sentry-clues#group-errors-your-way-with-fingerprints
        sentry.withScope((scope) => {
          scope.setFingerprint([
            serviceName,
            code,
            type,
            opName,
            firstStackTrace,
          ]);
          sentry.captureException(
            new Error(getErrorMessage(message)),
            exceptionData
          );
        });
      } catch (outerErr) {
        reportError(outerErr);
      }
    });
  } else if (graphQLErrors) {
    reportError(jsonStringify(graphQLErrors));
  }

  // This code propagates a better message to the user with enhanced context.
  // Apollo client treats network errors differently, and as of writing makes it difficult to pass along custom data with the error. So we encode our info in the error message directly.
  if (networkError?.message) {
    if (!getGlobalVariable(APP_VERBOSE_ERROR_DISPLAY_SYMBOL)) {
      networkError.message =
        'There was an error processing your request. An automatic report has been generated.';
    } else {
      const prefix = getErrorMessageReqIdPrefix(
        operation.getContext() as anyOk
      );
      const firstErr = get(graphQLErrors, '0.message');
      const finalMessage = firstErr || networkError.message;
      networkError.message = `${prefix}${finalMessage}`;
    }
  }
});

const persistedQueriesLink = createPersistedQueryLink({
  sha256,
});

// Used in cypress component test rendering
// ts-unused-exports:disable-next-line
export const getClient = (kwargs: {
  persistedQueries: boolean;
}): ApolloClient<NormalizedCacheObject> => {
  const link = ApolloLink.from(
    compact([
      kwargs.persistedQueries ? persistedQueriesLink : undefined,
      cleanVariablesForMutationLink,
      authLink,
      customHeadersLink,
      addMetaLink,
      errorLink,
      httpLink,
    ])
  );

  const splitLink = split(
    // split based on operation type
    (op) => {
      const isSubscription = !!op.query.definitions.find(
        (def) =>
          def.kind === 'OperationDefinition' && def.operation === 'subscription'
      );
      return isSubscription;
    },
    wsLink,
    link
  );

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          allAvailableRoutes: relayStylePagination(),
          allAvailableTrucks: relayStylePagination(),
          getContainersForCarrier: relayStylePagination(),
          getGateReservationsForCarrier: relayStylePagination(),
          getTruckEntriesForCarrier: relayStylePagination(),
          customerCommitments: relayStylePagination(),
          clientExceptionRecords: relayStylePagination(),
          incidentsV2: relayStylePagination(),
          tasksPaginatedV2: relayStylePagination(),
        },
      },
      SeerMainPageRouteBoard: {
        keyFields: ['routeNumber'],
      },
      SeerMainPageTrackingBoard: {
        keyFields: ['routeNumber'],
      },
      SeerFacilityTrackingBoard: {
        keyFields: ['routeNumber', 'destination'],
      },
      SeerTrackingPage: {
        keyFields: ['routeNumber'],
      },
      StopAddress: {
        keyFields: ['id', 'city'],
      },
      SeerLoadSearch: {
        keyFields: ['routeId'],
      },
      SeerCarrierRoute: {
        keyFields: ['routeId'],
      },
      SeerCustomerOrder: {
        keyFields: ['routeId'],
      },
      SeerFacilityRoute: {
        keyFields: ['routeId'],
      },
      BidConnection: {
        merge: true,
      },
      BidLaneConnection: {
        merge: true,
      },
      AvailableRoute: {
        merge: true,
      },
      AvailableTruck: {
        keyFields: ['truckPostingId'],
      },
    },
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    dataIdFromObject: (obj) => {
      const { __typename: n } = obj;
      if (n === 'calculateVatCheckedModel') {
        // this query/type is at a tenant level, there is no need for id
        // but to use optimistic options from apollo client we need to generate an identifier
        return 'calculateVatCheckedModel:tenant';
      } else if (n === 'TempLoad') {
        // we shouldn't have to do this if the ID for the TempLoad object is distinct per Truck + Load combo.
        // As of now, it is not - and loads with the same id, but different DDH or ODH values happen, and the cache gets confused
        // So we stabilize the cache by narrowing down the exact id ourselves
        const load = obj as unknown as ExtendedLoad;
        try {
          // this load.truck access is dependent on adding the truck object in the response above.
          return `TempLoad:${load.id}-TruckPosting:${load.truckId}`;
        } catch {
          // hopefully this never happens, but if it does then we are effectively bailing out of the cache as an escape hatch
          return `TempLoad:${Math.random().toString()}`;
        }
      }
      return defaultDataIdFromObject(obj);
    },
  });

  return new ApolloClient({
    link: splitLink,
    cache,
    name: `frontend-${environment}`,
    version: BUILD_VERSION,
    connectToDevTools: IS_LOCAL_DEV || IS_NOT_PREVIEW_OR_PROD,
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
    },
  });
};

const persistedQueriesOptOut = new Set(['keycloaksuperuser@mastery.net']);

export const useMasteryApolloClient =
  (): ApolloClient<NormalizedCacheObject> => {
    const email = useUserEmail();
    const emailInOptOut = persistedQueriesOptOut.has(
      email?.toLowerCase() ?? 'noop'
    );
    const optOutOfPersistedQueries = emailInOptOut || IS_LOCAL_DEV;
    return useMemo(() => {
      return getClient({
        persistedQueries: optOutOfPersistedQueries ? false : true,
      });
    }, [emailInOptOut]);
  };
