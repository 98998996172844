import { SetRequired } from 'type-fest';
import { FeatureFlagBody } from '../../../util/ci/flags/types';

export type DeprecatedFlagDefinition = SetRequired<
  Partial<FeatureFlagBody>,
  'description'
>;

/** @deprecated Create flag (old). Use the "make" export instead. */
export const deprecatedCreate = (
  obj: DeprecatedFlagDefinition
): DeprecatedFlagDefinition => {
  return {
    ...obj,
    /** Only a marker that the flag has been created with this initializer */
    __wrapped: true,
  } as DeprecatedFlagDefinition;
};

type TeamType =
  | 'aardvark'
  | 'aardwolf'
  | 'alexandria'
  | 'amber-route'
  | 'artemis'
  | 'argos'
  | 'atlas'
  | 'authority'
  | 'babylon'
  | 'bookie'
  | 'byblos'
  | 'core-load'
  | 'damascus'
  | 'diesel'
  | 'dras'
  | 'fifth-wheel'
  | 'hercules'
  | 'highway-61'
  | 'incense-route'
  | 'jericho'
  | 'kingpin'
  | 'lovelace'
  | 'off-the-rails'
  | 'memphis'
  | 'purple-rain'
  | 'route-66'
  | 'silk-route'
  | 'spice-route'
  | 'tea-route'
  | 'memphis'
  | 'salt-route'
  | 'rome'
  | 'susa'
  | 'flatbed'
  | 'dhrona'
  | 'long-haul'
  | 'seawise-gaint'
  | 'office-heroes'
  | 'sparta'
  | 'tin-route'
  | 'varna'
  | 'vibranium'
  | 'beskar';

type FlagDefinition = SetRequired<
  Omit<Partial<FeatureFlagBody>, 'temporary'>,
  'description'
> & {
  temporary?: false;
  /** Initial owning team. Flags can be transferred to another team on request. */
  initialTeam: TeamType;
};

// ts-unused-exports:disable-next-line
export const make = (
  obj: FlagDefinition
): Partial<FeatureFlagBody> & { __wrapped: true } => {
  return {
    ...obj,
    /** Only a marker that the flag has been created with this initializer */
    __wrapped: true,
    temporary: obj.temporary === false ? false : true,
    tags: [`team-${obj.initialTeam}`].concat(obj.tags ?? []),
  };
};
