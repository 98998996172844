import * as TP from '../types';

import { gql } from '@apollo/client';
import { AddressInfoFragmentDoc } from '../fragments/addressInfo';
import { KeyValueInfoFragmentDoc } from '../fragments/keyValueInfo';
import { EmployeeInfoFragmentDoc } from '../fragments/employeeInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchCustomersQueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.CustomersFilter>;
  first?: TP.InputMaybe<TP.Scalars['Int']>;
  last?: TP.InputMaybe<TP.Scalars['Int']>;
  before?: TP.InputMaybe<TP.Scalars['String']>;
  after?: TP.InputMaybe<TP.Scalars['String']>;
}>;


export type SearchCustomersQuery = (
  { readonly __typename?: 'Query' }
  & { readonly allCustomers: (
    { readonly __typename?: 'CustomerConnection' }
    & { readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & Pick<TP.PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), readonly edges: ReadonlyArray<(
      { readonly __typename?: 'CustomerEdge' }
      & Pick<TP.CustomerEdge, 'cursor'>
      & { readonly node: (
        { readonly __typename?: 'Customer' }
        & Pick<TP.Customer, 'id' | 'code' | 'name'>
        & { readonly mainAddress?: TP.Maybe<(
          { readonly __typename?: 'Address' }
          & Pick<TP.Address, 'addressTypeId' | 'addressableId' | 'addressableType' | 'city' | 'country' | 'id' | 'main' | 'postalCode' | 'state' | 'street1' | 'street2' | 'isVerified'>
          & { readonly addressType?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )>, readonly geopoint: (
            { readonly __typename?: 'Geopoint' }
            & Pick<TP.Geopoint, 'lat' | 'lon'>
          ) }
        )>, readonly customerStatusType?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly customerLevelType?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly mainRep?: TP.Maybe<(
          { readonly __typename?: 'CustomerRep' }
          & Pick<TP.CustomerRep, 'id'>
          & { readonly employee: (
            { readonly __typename?: 'Employee' }
            & Pick<TP.Employee, 'companyEmail' | 'divisionId' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeCompanyLevelId' | 'employeeDepartmentId' | 'employeeDepartmentLevelId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeRoleId' | 'employeeShirtSizeId' | 'employeeSuffixId' | 'employeeTimeId' | 'employeeTypeId' | 'ext' | 'firstName' | 'firstNameDotLastName' | 'fullName' | 'hireDate' | 'id' | 'lastName' | 'managerId' | 'middleName' | 'mobilePhone' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'relationship' | 'slackName' | 'subRegion' | 'termDate' | 'title' | 'userId'>
            & { readonly division?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
            )>, readonly employeeGroup?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
            )>, readonly employeeOffice?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
            )>, readonly employeeStatus: (
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'id' | 'active'>
            ), readonly manager?: TP.Maybe<(
              { readonly __typename?: 'Employee' }
              & Pick<TP.Employee, 'companyEmail' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeDepartmentId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeSuffixId' | 'ext' | 'firstName' | 'fullName' | 'id' | 'lastName' | 'middleName' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'relationship' | 'title' | 'userId' | 'managerId'>
            )> }
          ) }
        )> }
      ) }
    )> }
  ) }
);


export const SearchCustomersDocument = gql`
    query searchCustomers($filter: CustomersFilter, $first: Int, $last: Int, $before: String, $after: String) {
  allCustomers(
    filter: $filter
    first: $first
    last: $last
    before: $before
    after: $after
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        code
        name
        mainAddress {
          ...AddressInfo
        }
        customerStatusType {
          ...KeyValueInfo
        }
        customerLevelType {
          ...KeyValueInfo
        }
        mainRep {
          id
          employee {
            ...EmployeeInfo
          }
        }
      }
    }
  }
}
    ${AddressInfoFragmentDoc}
${KeyValueInfoFragmentDoc}
${EmployeeInfoFragmentDoc}`;

/**
 * __useSearchCustomersQuery__
 *
 * To run a query within a React component, call `useSearchCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCustomersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSearchCustomersQuery(baseOptions?: Apollo.QueryHookOptions<SearchCustomersQuery, SearchCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCustomersQuery, SearchCustomersQueryVariables>(SearchCustomersDocument, options);
      }
export function useSearchCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCustomersQuery, SearchCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCustomersQuery, SearchCustomersQueryVariables>(SearchCustomersDocument, options);
        }
export type SearchCustomersQueryHookResult = ReturnType<typeof useSearchCustomersQuery>;
export type SearchCustomersLazyQueryHookResult = ReturnType<typeof useSearchCustomersLazyQuery>;
export type SearchCustomersQueryResult = Apollo.QueryResult<SearchCustomersQuery, SearchCustomersQueryVariables>;