import { getUserData } from '@components/AuthContext';
import { useFlag } from '@components/Flag';
import { MODE } from '@env';
import { KnownDomains } from '@utils/constants';
import { isNumber, isString, mapKeys } from 'lodash-es';
import { FC, useEffect } from 'react';
import { v4 } from 'uuid';
import { BASE_TENANT_HOSTNAME } from '../../config';
import { Fullstory, win } from '../win';

export const FS_UNMASK = 'fs-unmask';
// ts-unused-exports:disable-next-line
export const FS_MASK = 'fs-mask';

const identify = (...args: Parameters<Fullstory['identify']>): void => {
  try {
    win.FS?.identify(...args);
  } catch (err: anyOk) {
    // eslint-disable-next-line no-console
    console.error('Error identifying with Fullstory\n' + err.message);
  }
};

export const setUserVars = (
  ...args: Parameters<Fullstory['setUserVars']>
): void => {
  win.FS?.setUserVars(...args);
};

export const fsEvent = (...args: Parameters<Fullstory['event']>): void => {
  const [type, obj] = args;
  const mapped = mapKeys(obj, (value, key) => {
    if (isString(value)) {
      return `${key}_str`;
    } else if (isNumber(value)) {
      return `${key}_int`;
    }
    return key;
  });
  if (MODE === 'development') {
    // eslint-disable-next-line no-console
    return console.info('Fullstory Event', { type, data: mapped });
  }
  win.FS?.event(type, mapped);
};

let HAS_INITIALIZED = false;

const tenantHostnameOrgIdMap: Partial<Record<KnownDomains, string>> &
  Record<string, string> = {
  'test.mm100.mastermindtms.com': '162Y2J',
  'ml100.mastermindtms.com': 'o-1ABYQ0-na1',
  'test.sn100.mastermindtms.com': 'o-1ABYRR-na1',
  'dev.mm100.mastermindtms.com': 'o-1C1ZZ2-na1',
  'tb100.mastermindtms.com': 'o-1CXFYY-na1',
  'ru100.mastermindtms.com': 'o-1EFFBG-na1',
  'sn100.mastermindtms.com': 'o-1EFEMT-na1',
  'tm100.mastermindtms.com': 'o-1EFF78-na1',
  'pr100.mastermindtms.com': 'o-1EFFHZ-na1',
  'we100.mastermindtms.com': 'o-1EFEX3-na1',
};

const ORG_ID: string | undefined = (
  tenantHostnameOrgIdMap as Record<string, string>
)[BASE_TENANT_HOSTNAME ?? ''];

const init = (): void => {
  if (!ORG_ID || HAS_INITIALIZED) {
    return;
  }
  const userData = getUserData();
  if (userData) {
    try {
      win.masteryFullstoryInit(ORG_ID);
      // We probably need a settimeout here because masteryFullstoryInit actually loads the js snippet which is async. We want to give it enough time to download from the network and boot before identify.
      setTimeout(() => {
        identify(userData.key ?? v4(), {
          displayName: userData.name,
          email: userData.email,
        });
      }, 4000);
      HAS_INITIALIZED = true;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
};

export const Unmask: FC = ({ children }) => {
  return <div className={FS_UNMASK}>{children}</div>;
};

export const Mask: FC = ({ children }) => {
  return <div className={FS_MASK}>{children}</div>;
};

export const useFullstory = (): void => {
  const flagData = useFlag('ME-56819-app-fullstory-enable');
  useEffect(() => {
    if (flagData) {
      init();
    }
  }, [flagData]);
};
