import { IconBg, IconProp } from '@components/Icon';
import ReactDOMServer from 'react-dom/server';

const iconToBase64String = (iconKey: IconProp): string => {
  const elementAsString = ReactDOMServer.renderToStaticMarkup(
    <IconBg i={iconKey} />
  );
  return btoa(elementAsString);
};

export const iconBgImg = (icon: IconProp, paddingRight = 4): string => {
  const width = 10.5;
  const gradientStop = width + 1 + paddingRight;
  return `linear-gradient(to left, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) ${gradientStop}px, rgba(255,255,255,1) ${gradientStop}px, rgba(255,255,255,1) 100%),url("data:image/svg+xml;base64,${iconToBase64String(
    icon
  )}") no-repeat right ${paddingRight}px center /${width}px 12px`;
};
