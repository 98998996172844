import { useRect } from '@reach/rect';
import { MutableRefObject } from 'react';
import { useWindowSize } from 'react-use';

export const useMaxItemContainerWidth = (
  refArg: MutableRefObject<HTMLElement | undefined>,
  optOut?: boolean
): number | '100%' | 'none' => {
  const { width: windowWidth } = useWindowSize();
  const boundingClientRect = useRect(refArg);
  const maxWidth = boundingClientRect?.left
    ? (windowWidth || 2000) - boundingClientRect?.left - 20
    : undefined;
  if (optOut) {
    return '100%';
  }
  return maxWidth ? Math.round(maxWidth) : 'none';
};
