import * as TP from '../types';

import { gql } from '@apollo/client';
import { KeyValueInfoFragmentDoc } from './keyValueInfo';
import { ScheduleEntryInfoV2FragmentDoc } from './scheduleEntryInfoV2';
export type ScheduleInfoV2Fragment = (
  { readonly __typename?: 'ScheduleV2' }
  & Pick<TP.ScheduleV2, 'appointmentType' | 'dropType' | 'serviceType' | 'trailerTypeId' | 'trailerTypeIdNullable' | 'id' | 'modeType' | 'scheduleType' | 'scheduleTypeNullable' | 'workType' | 'loadSizeId' | 'temperatureId' | 'routeTransportModeId'>
  & { readonly trailerType: (
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  ), readonly trailerTypeNullable?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly loadSize?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly temperatures?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>>, readonly routeTransportMode?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly sunday: (
    { readonly __typename?: 'ScheduleEntryV2' }
    & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
    & { readonly hours: ReadonlyArray<(
      { readonly __typename?: 'ScheduleRangeV2' }
      & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
    )> }
  ), readonly monday: (
    { readonly __typename?: 'ScheduleEntryV2' }
    & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
    & { readonly hours: ReadonlyArray<(
      { readonly __typename?: 'ScheduleRangeV2' }
      & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
    )> }
  ), readonly tuesday: (
    { readonly __typename?: 'ScheduleEntryV2' }
    & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
    & { readonly hours: ReadonlyArray<(
      { readonly __typename?: 'ScheduleRangeV2' }
      & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
    )> }
  ), readonly wednesday: (
    { readonly __typename?: 'ScheduleEntryV2' }
    & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
    & { readonly hours: ReadonlyArray<(
      { readonly __typename?: 'ScheduleRangeV2' }
      & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
    )> }
  ), readonly thursday: (
    { readonly __typename?: 'ScheduleEntryV2' }
    & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
    & { readonly hours: ReadonlyArray<(
      { readonly __typename?: 'ScheduleRangeV2' }
      & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
    )> }
  ), readonly friday: (
    { readonly __typename?: 'ScheduleEntryV2' }
    & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
    & { readonly hours: ReadonlyArray<(
      { readonly __typename?: 'ScheduleRangeV2' }
      & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
    )> }
  ), readonly saturday: (
    { readonly __typename?: 'ScheduleEntryV2' }
    & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
    & { readonly hours: ReadonlyArray<(
      { readonly __typename?: 'ScheduleRangeV2' }
      & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
    )> }
  ) }
);

export const ScheduleInfoV2FragmentDoc = gql`
    fragment ScheduleInfoV2 on ScheduleV2 {
  appointmentType
  dropType
  serviceType
  trailerType {
    ...KeyValueInfo
  }
  trailerTypeId
  trailerTypeNullable {
    ...KeyValueInfo
  }
  trailerTypeIdNullable
  id
  modeType
  scheduleType
  scheduleTypeNullable
  serviceType
  workType
  loadSize {
    ...KeyValueInfo
  }
  loadSizeId
  temperatures {
    ...KeyValueInfo
  }
  temperatureId
  routeTransportMode {
    ...KeyValueInfo
  }
  routeTransportModeId
  sunday {
    ...ScheduleEntryInfoV2
  }
  monday {
    ...ScheduleEntryInfoV2
  }
  tuesday {
    ...ScheduleEntryInfoV2
  }
  wednesday {
    ...ScheduleEntryInfoV2
  }
  thursday {
    ...ScheduleEntryInfoV2
  }
  friday {
    ...ScheduleEntryInfoV2
  }
  saturday {
    ...ScheduleEntryInfoV2
  }
}
    ${KeyValueInfoFragmentDoc}
${ScheduleEntryInfoV2FragmentDoc}`;