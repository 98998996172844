import { useWhoAmI } from '@app/WhoAmI';
import { EmployeeSimpleFragment } from '@generated/fragments/employeeSimple';
import { EmployeeSimpleV2Fragment } from '@generated/fragments/employeeSimpleV2';
import { useGetEmployeeGroupByIdLazyQuery } from '@generated/queries/getEmployeeGroupByID';
import { useGetEmployeeGroupByIdv2LazyQuery } from '@generated/queries/getEmployeeGroupByIDV2';
import { useEmployeeV2Flag } from '@hooks/useEmployeeV2Flag';
import { useUserPreferences } from '@hooks/UserPreferences';
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
  IS_HEADER_ALLOWED,
  IS_SIDEBAR_ALLOWED,
  IS_SIDEBAR_SHOWN_ON_PAGE_LOAD,
} from './constants';
import { SearchType } from './Search/types';

const noop = (): void => {
  //
};

export const SIDEBAR_OPEN_WIDTH = 250;
export const EMPLOYEE_ENTITY_TABS_HEIGHT = 60;
export const CLOSED_SIDEBAR_WIDTH = 60;
export const TOGGLE_AREA_HEIGHT = 60;
export const SIDEBAR_TRANSITION_MS = 200;
export const SIDEBAR_BORDER_WIDTH = 2;

export interface EmployeeTreeNodeValue {
  id: string;
  fullName: string;
  employeeRoleId: Maybe<string>;
}

export interface GroupTreeNodeValue {
  id: string;
  groupName: string;
}
type EmployeeType = Maybe<EmployeeSimpleFragment | EmployeeSimpleV2Fragment>;

interface SidebarContextType {
  employeeId: string;
  searchType: SearchType;
  employeeTreeNodeValue: Maybe<EmployeeTreeNodeValue>;
  isOpen: boolean;
  toggleOpen: () => void;
  setEmployee: (emp: EmployeeType) => void;
  setSearchType: Dispatch<SetStateAction<SearchType>>;
  updateEmployeeTreeNodeValue: (node: Maybe<EmployeeTreeNodeValue>) => void;
  employee: EmployeeType;
  isSidebarAllowed: boolean;
  isHeaderAllowed: boolean;
  group: GroupTreeNodeValue;
  setGroup: (group: GroupTreeNodeValue) => void;
  groupSelected: boolean;
  setGroupSelected: (_: boolean) => void;
}

const defaultKeyValueObj: GroupTreeNodeValue = {
  id: '',
  groupName: '',
};

export const SidebarContext = createContext<SidebarContextType>({
  employeeId: '',
  searchType: SearchType.carrier,
  employeeTreeNodeValue: undefined,
  isOpen: IS_SIDEBAR_SHOWN_ON_PAGE_LOAD,
  toggleOpen: noop,
  setEmployee: noop,
  setSearchType: () => SearchType.rep,
  updateEmployeeTreeNodeValue: noop,
  employee: undefined,
  isSidebarAllowed: true,
  isHeaderAllowed: true,
  group: defaultKeyValueObj,
  setGroup: noop,
  groupSelected: true,
  setGroupSelected: noop,
});

export const SidebarProvider: FC = ({ children }) => {
  const { sidebarOpen, setPrefs } = useUserPreferences();

  const isSidebarAllowed = IS_SIDEBAR_ALLOWED;

  const isOpen = sidebarOpen;
  const [searchType, setSearchType] = useState(SearchType.rep);

  const whoAmIData = useWhoAmI();
  const [employee, setEmployee] = useState<EmployeeType>(whoAmIData.employee);

  const [groupSelected, setGroupSelected] = useState(true);

  const [group, setGroup] = useState(defaultKeyValueObj);

  useEffect(() => {
    setGroupSelected(true);
  }, [group]);

  const employeeV2Flag = useEmployeeV2Flag();

  const [getEmployeeGroupV1] = useGetEmployeeGroupByIdLazyQuery({
    onCompleted: (employeeData) => {
      setGroup({
        id: employeeData.employee?.employeeGroup?.id || '',
        groupName: employeeData.employee?.employeeGroup?.name || '',
      });
    },
  });

  const [getEmployeeGroupV2] = useGetEmployeeGroupByIdv2LazyQuery({
    onCompleted: (employeeData) => {
      setGroup({
        id: employeeData.employee?.employeeGroup?.id || '',
        groupName: employeeData.employee?.employeeGroup?.name || '',
      });
    },
  });

  const fireQuery = useCallback(
    (empData) => {
      employeeV2Flag
        ? getEmployeeGroupV2({
            variables: {
              id: empData,
            },
          })
        : getEmployeeGroupV1({
            variables: {
              id: empData,
            },
          });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [whoAmIData]
  );

  useEffect(() => {
    if (whoAmIData) {
      fireQuery(whoAmIData.employee?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whoAmIData.employee?.id]);

  const [employeeTreeNodeValue, updateEmployeeTreeNodeValue] = useState<
    Maybe<EmployeeTreeNodeValue>
  >(
    whoAmIData.employee
      ? {
          id: whoAmIData.employee?.id || '',
          fullName: whoAmIData.employee?.fullName || '',
          employeeRoleId: whoAmIData.employee?.employeeRoleId,
        }
      : undefined
  );

  const toggleOpen = useCallback(() => {
    setPrefs({ sidebarOpen: !sidebarOpen });
  }, [setPrefs, sidebarOpen]);

  const value = useMemo((): SidebarContextType => {
    return {
      employee,
      employeeId: employee?.id ?? '',
      employeeTreeNodeValue,
      isOpen: Boolean(isOpen),
      searchType,
      setEmployee,
      setSearchType,
      toggleOpen,
      updateEmployeeTreeNodeValue,
      isSidebarAllowed,
      isHeaderAllowed: IS_HEADER_ALLOWED,
      group,
      setGroup,
      groupSelected,
      setGroupSelected,
    };
  }, [
    employee,
    employeeTreeNodeValue,
    isOpen,
    searchType,
    toggleOpen,
    isSidebarAllowed,
    group,
    groupSelected,
    setGroupSelected,
  ]);

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};
