import { RESPONSE_META_KEY } from '@app/client/constants';
import { Identifiable } from '@components/shared/interfaces';
import { ExecutionResult, GraphQLError } from 'graphql';
import {
  each,
  get,
  isDate,
  isEmpty,
  isError,
  isObject,
  isString,
  reject,
  unionWith,
  uniqueId,
  unset,
} from 'lodash-es';
import { Maybe, PageInfo } from '../../generated/types';
import { jsonParse, jsonStringify } from '../json';
import { reportError } from '../sentry';

// DEPRECATED
// ts-unused-exports:disable-next-line
export enum TempProperties {
  Unsaved = 'unsaved',
  TempId = 'tempId',
}

interface CanSaveStrippable {
  [TempProperties.Unsaved]?: boolean;
  [TempProperties.TempId]?: string;
}

export const TEMPID_PREFIX = 'client-generated-';

export const isTempId = (id?: string): boolean =>
  isString(id) && id.startsWith(TEMPID_PREFIX);
export const getTempId = (suffix?: string): string => {
  return suffix ? `${TEMPID_PREFIX}${suffix}` : uniqueId(TEMPID_PREFIX);
};
export const isNewItem = (item: anyOk): boolean => isTempId(get(item, 'id'));
export const isNotNewItem = (item: anyOk): boolean => {
  const val = get(item, 'id');
  return isString(val) && !val.startsWith(TEMPID_PREFIX);
};

export const getTopLevelErrors = (
  res?: ExecutionResult<unknown>
): Error | readonly GraphQLError[] | undefined => {
  if (isError(res)) {
    return res;
  } else if (res?.errors && !isEmpty(res.errors)) {
    return res.errors;
  }
  return;
};

export const hasTopLevelErrors = (res: ExecutionResult<unknown>): boolean => {
  return Boolean(getTopLevelErrors(res));
};

export const noTopLevelErrors = (res: ExecutionResult<unknown>): boolean => {
  return !getTopLevelErrors(res);
};

/** Use this as the key to a boolean value in a parent object that you want entirely stripped before making mutations, check the graphqlUtils test file for an example */
export const REMOVE_BEFORE_MUTATION_KEY = '__removeBeforeMutation';

type Saveable = CanSaveStrippable & { _destroy?: string | null };
type CanSave<T> = T & Saveable;

/**
 * Apply CanSave type to fields of a type.
 *
 * Works with nullable fields, list fields, and nullable list fields.
 *
 * See test file for example usage.
 */
export type CanSaveFields<TData, FieldHasCanSaveProps extends keyof TData> = {
  [Field in keyof TData]: Field extends FieldHasCanSaveProps
    ? TData[Field] extends (infer ArrayField)[]
      ? CanSave<ArrayField>[]
      : TData[Field] extends (infer NullableArrayField)[] | null
      ? CanSave<NullableArrayField>[] | null
      : CanSave<TData[Field]>
    : TData[Field];
};

export function stripProperty(
  value: anyOk,
  conditional: (obj: anyOk, key: string, parentValue: anyOk) => boolean
): anyOk {
  each(value, (v, k) => {
    if (conditional(v, k, value)) {
      unset(value, k);
    } else if (isObject(v)) {
      stripProperty(v, conditional);
    }
  });
  return value;
}

export function stripNull<T>(val: Record<string, anyOk>): T {
  return stripProperty(val, (o) => o === null);
}

export function stripNullOrBlank<T>(val: Record<string, anyOk>): T {
  return stripProperty(val, (o) => o === null || o === '');
}

/** Removes properties on objects used for temporary form state or display purposes only,
 * which will be rejected by Apollo if included in a mutation
 */
type Stripped<T> = Exclude<T, CanSaveStrippable> | Record<string, unknown>;

export function stripTempProperties<T>(obj: T): Stripped<T> {
  let newObj = { ...obj };
  for (const key in TempProperties) {
    newObj = stripProperty(
      newObj,
      (obj, prop) => prop === TempProperties[key as keyof typeof TempProperties]
    ) as fixMe;
  }

  return newObj as fixMe;
}

export const convertForGraphql = (
  rawValues: Record<string, unknown>
): Record<string, unknown> => {
  let values = rawValues;
  // strip metadataJson from KeyValue input types
  values = stripProperty(
    values,
    (obj, prop, parent) =>
      parent?.__typename === 'KeyValue' && prop === 'metadataJson'
  );
  values = stripProperty(values, isTempId);
  values = stripProperty(
    values,
    (obj) => obj && obj[REMOVE_BEFORE_MUTATION_KEY]
  );
  values = stripProperty(values, (o, p) => p === '__typename');
  values = stripProperty(values, (o, p) => {
    const isFullName = p === 'fullName';
    if (isFullName) {
      // eslint-disable-next-line no-console
      console.warn(
        'Found property "fullName" in variables for graphql mutation. This property has been stripped as most views do not intend to send it. You should stop sending this key if your mutation does not accept it.'
      );
      return true;
    }
    return false;
  });
  values = stripTempProperties(values);
  return values;
};

/** This helper derives mutation input for array-like fields that use the _destroy keyword for deletion.
 */
export const getMutationArrayInput = <
  FormFieldState extends Identifiable,
  MutationInput extends { _destroy?: string | null }
>(
  submitted: ReadonlyArray<FormFieldState>,
  initial: ReadonlyArray<FormFieldState>,
  mapToMutationInput: (state: FormFieldState) => MutationInput
): MutationInput[] => {
  const currentEntries = new Set(submitted.map((entry) => entry.id));
  // assumption: new entries are created with empty-string ids
  return unionWith(
    submitted,
    initial,
    (lhs, rhs) => lhs.id === rhs.id && lhs.id !== ''
  ).map((entry) => ({
    ...mapToMutationInput(entry),
    _destroy: (!currentEntries.has(entry.id)).toString(),
  }));
};

interface Connection<T = unknown> {
  readonly edges: ReadonlyArray<{ node: T }>;
  readonly pageInfo?: Partial<PageInfo>;
  readonly __typename?: string;
}

export const getNodesFromConnection = <NodeType>(
  con: Maybe<Partial<Connection<NodeType>>>
): NodeType[] => con?.edges?.map((obj) => obj.node) || [];

interface ConnectionResult<T = unknown> {
  [key: string]: Connection<T>;
}

/* Deprecated, use relayStylePagination in the InMemoryCache
   See https://github.com/apollographql/apollo-client/blob/main/docs/source/pagination/cursor-based.mdx#relay-style-cursor-pagination for more info
*/
export const updateQuery = (
  // TODO: remove these fixMes, we are so close on the typings but just can't quite get it
  // It seems like previousResultRaw should be ConnectionResult but consumers don't like that
  previousResultRaw: fixMe,
  options: { fetchMoreResult: fixMe; variables: fixMe }
): fixMe => {
  const previousResult = previousResultRaw as Maybe<ConnectionResult>;
  const { fetchMoreResult: fetchMoreObjRaw } = options;
  const fetchMoreObj = fetchMoreObjRaw as Maybe<{
    fetchMoreResult?: ConnectionResult;
  }>;
  const { fetchMoreResult } = fetchMoreObj || {};
  const dataKeys = reject(
    Object.keys(fetchMoreResult || {}),
    (k) => k === RESPONSE_META_KEY
  );
  if (dataKeys.length !== 1) {
    reportError(
      `Got more data keys for updateQuery util than expected. ${jsonStringify(
        dataKeys
      )}`
    );
  }
  // TODO: this is a bit risky and may not work for all cases?
  const dataKey = dataKeys[0] as string;
  const fetchMoreResultData = fetchMoreResult?.[dataKey];
  const newEdges = fetchMoreResultData?.edges || [];
  const pageInfo = fetchMoreResultData?.pageInfo;

  if (!previousResult) {
    return {};
  } else if (!newEdges.length) {
    return previousResult;
  }

  return {
    [dataKey]: {
      __typename: previousResult[dataKey]?.__typename,
      edges: [...(previousResult[dataKey]?.edges || []), ...newEdges],
      pageInfo,
    },
  };
};

/** Convert all date values (deeply) to string with .toISOString */
export const convertAllDateValues = <T>(obj: T): T => {
  return (
    jsonParse(
      jsonStringify(obj, ((key: anyOk, value: anyOk) => {
        return isDate(value) ? value.toISOString() : value;
      }) as anyOk)
    ) || obj
  );
};
