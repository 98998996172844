import { ThemeColor } from '@emotion/react';

export const gray = {
  50: '#fafafa',
  75: '#f8f8f8',
  100: '#efefef',
  200: '#E0E0E0',
  250: '#dcdcdc',
  300: '#c6c6c6',
  400: '#BDBDBD',
  500: '#9E9E9E',
  600: '#757575',
  700: '#616161',
  800: '#4a4a4a',
  900: '#212121',
};

export const colors: Record<ThemeColor, string> = {
  error: '#eb0000',
  primary: '#0f70e7',
  primaryLight: '#dbeafc',
  success: '#00b074',
  text: '#4a4a4a',
  textReverse: 'white',
  warning: '#ffdd57',
  lightPurple: '#a53ede',
  warningDark: '#946B12',
  ceruleanBlue: '#0f70e7',
  disabledText: '#c6c6c6',
  warningLight: '#fff7e7',
  successLight: '#e1f6ef',
  tentative: '#ffa500',
  darkYellow: '#b88f36',
};
