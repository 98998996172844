import { LinkTo } from '@components/Link';
import { MenuLink as ML, MenuLinkProps } from '@reach/menu-button';
import { MouseEvent, ReactElement } from 'react';

type Props = Omit<MenuLinkProps, 'to'> & {
  to?: LinkTo;
  as?: fixMe;
  target?: HTMLAnchorElement['target'];
  rel?: HTMLAnchorElement['rel'];
  href?: HTMLAnchorElement['href'];
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
};

export const MenuLink = (props: Props): ReactElement => {
  return <ML {...props} />;
};
