import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, memo } from 'react';
import { useTimeout } from 'react-use';

const frames = keyframes({
  '0%': {
    top: 6,
    height: 51,
  },
  '50%, 100%': {
    top: 19,
    height: 26,
  },
});

const Bar = styled.div({
  animation: `${frames} 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite`,
  background: 'black',
  display: 'inline-block',
  left: 6,
  position: 'absolute',
  width: 13,
});

// ts-unused-exports:disable-next-line
export const LOADING_DEFAULT_DELAY = 250;

interface Props {
  /** Amount to wait before showing the loader */
  delay?: number;
}

export const Loading: FC<Props> = memo(
  ({ delay = LOADING_DEFAULT_DELAY, ...rest }) => {
    const [isReady] = useTimeout(delay || 0);
    if (!isReady()) {
      return null;
    }
    return (
      <div
        data-testid="component-loading"
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '30%',
        }}
        {...rest}
      >
        <div
          css={{
            width: 64,
            height: 64,
            position: 'relative',
          }}
        >
          <Bar css={{ left: 6, animationDelay: '-0.24s' }} />
          <Bar css={{ left: 26, animationDelay: '-0.12s' }} />
          <Bar css={{ left: 45, animationDelay: '0s' }} />
        </div>
      </div>
    );
  }
);
