import { compact } from 'lodash-es';

const onlyAlphaNumeric = (str: string): string =>
  str.replace(/[^a-zA-Z0-9 -]/gi, '').toLowerCase();

const getWords = (str: string): string[] =>
  compact(str.split(' ').map((s) => s.trim()));

export const match = (rawLabel: string, rawSearch: string): boolean => {
  const label = onlyAlphaNumeric(rawLabel);
  const search = onlyAlphaNumeric(rawSearch);
  const labelArr = getWords(label);
  const searchArr = getWords(search);
  return searchArr
    .map((searchStr) =>
      labelArr.map((labelStr) => labelStr.includes(searchStr)).some(Boolean)
    )
    .every(Boolean);
};
