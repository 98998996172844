import { useFlag } from '@components/Flag';
import { debounce, throttle } from 'lodash-es';
import { useCallback, useState } from 'react';

/// Custom hook that either throttles or debounces updates to a text value
/// depending on the length of the text:
/// * throttle when text length is below `lengthThreshold`
/// * debounce when text length is at or over `lengthThreshold`

const DEFAULT_THROTTLE_TIMEOUT = 500;
const DEFAULT_DEBOUNCE_TIMEOUT = 500;
const DEFAULT_LENGTH_THRESHOLD = 6;

interface StringSetter {
  (_: string): void;
}

interface Props {
  initialValue?: string;
  throttleTimeout?: number;
  debounceTimeout?: number;
  lengthThreshold?: number;
}

export const useTamedInputValue = ({
  initialValue = '',
  throttleTimeout = DEFAULT_THROTTLE_TIMEOUT,
  debounceTimeout = DEFAULT_DEBOUNCE_TIMEOUT,
  lengthThreshold = DEFAULT_LENGTH_THRESHOLD,
}: Props): [string, StringSetter] => {
  const [value, updateValue] = useState<string>(initialValue);
  const useDebounce = useFlag('ME-76615-fix-debounce-tamed-inputs');

  // TODO: examine this eslint rule
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledUpdate = useCallback(
    throttle((newValue: string): void => {
      updateValue(newValue);
    }, throttleTimeout),
    []
  );

  // TODO: examine this eslint rule
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = useCallback(
    debounce((newValue: string): void => {
      updateValue(newValue);
    }, debounceTimeout),
    []
  );

  const valueLength = value ? value.length : 0;

  let tamedUpdate =
    valueLength < lengthThreshold ? throttledUpdate : debouncedUpdate;
  if (useDebounce) {
    tamedUpdate = debouncedUpdate;
  }

  return [value, tamedUpdate];
};
