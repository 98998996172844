import * as TP from '../types';

import { gql } from '@apollo/client';
import { KeyValueInfoFragmentDoc } from '../fragments/keyValueInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEmployeeGroupByIdQueryVariables = TP.Exact<{
  id: TP.Scalars['ID'];
}>;


export type GetEmployeeGroupByIdQuery = (
  { readonly __typename?: 'Query' }
  & { readonly employee?: TP.Maybe<(
    { readonly __typename?: 'Employee' }
    & Pick<TP.Employee, 'id'>
    & { readonly employeeGroup?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )> }
  )> }
);


export const GetEmployeeGroupByIdDocument = gql`
    query getEmployeeGroupByID($id: ID!) {
  employee: employee(id: $id) {
    id
    employeeGroup {
      ...KeyValueInfo
    }
  }
}
    ${KeyValueInfoFragmentDoc}`;

/**
 * __useGetEmployeeGroupByIdQuery__
 *
 * To run a query within a React component, call `useGetEmployeeGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeGroupByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployeeGroupByIdQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeGroupByIdQuery, GetEmployeeGroupByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeGroupByIdQuery, GetEmployeeGroupByIdQueryVariables>(GetEmployeeGroupByIdDocument, options);
      }
export function useGetEmployeeGroupByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeGroupByIdQuery, GetEmployeeGroupByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeGroupByIdQuery, GetEmployeeGroupByIdQueryVariables>(GetEmployeeGroupByIdDocument, options);
        }
export type GetEmployeeGroupByIdQueryHookResult = ReturnType<typeof useGetEmployeeGroupByIdQuery>;
export type GetEmployeeGroupByIdLazyQueryHookResult = ReturnType<typeof useGetEmployeeGroupByIdLazyQuery>;
export type GetEmployeeGroupByIdQueryResult = Apollo.QueryResult<GetEmployeeGroupByIdQuery, GetEmployeeGroupByIdQueryVariables>;