import * as TP from '../types';

import { gql } from '@apollo/client';
import { EmployeeDetailsEmployeeTreeFragmentDoc } from '../fragments/EmployeeDetailsEmployeeTree';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEmployeeDetailsQueryVariables = TP.Exact<{
  id: TP.Scalars['ID'];
}>;


export type GetEmployeeDetailsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly employee?: TP.Maybe<(
    { readonly __typename?: 'Employee' }
    & Pick<TP.Employee, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
    & { readonly parent?: TP.Maybe<(
      { readonly __typename?: 'Employee' }
      & Pick<TP.Employee, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
      & { readonly children?: TP.Maybe<ReadonlyArray<(
        { readonly __typename?: 'Employee' }
        & Pick<TP.Employee, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
        & { readonly children?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'Employee' }
          & Pick<TP.Employee, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
          & { readonly children?: TP.Maybe<ReadonlyArray<(
            { readonly __typename?: 'Employee' }
            & Pick<TP.Employee, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
            & { readonly employeeRole?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'id' | 'name'>
            )>, readonly employeeGroup?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'id' | 'name'>
            )>, readonly employeeOffice?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'id' | 'name'>
            )>, readonly division?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'id' | 'name'>
            )>, readonly manager?: TP.Maybe<(
              { readonly __typename?: 'Employee' }
              & Pick<TP.Employee, 'id' | 'fullName'>
            )> }
          )>>, readonly employeeRole?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly employeeGroup?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly employeeOffice?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly division?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly manager?: TP.Maybe<(
            { readonly __typename?: 'Employee' }
            & Pick<TP.Employee, 'id' | 'fullName'>
          )> }
        )>>, readonly employeeRole?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly employeeGroup?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly employeeOffice?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly division?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly manager?: TP.Maybe<(
          { readonly __typename?: 'Employee' }
          & Pick<TP.Employee, 'id' | 'fullName'>
        )> }
      )>>, readonly employeeRole?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly employeeGroup?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly employeeOffice?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly division?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly manager?: TP.Maybe<(
        { readonly __typename?: 'Employee' }
        & Pick<TP.Employee, 'id' | 'fullName'>
      )> }
    )>, readonly children?: TP.Maybe<ReadonlyArray<(
      { readonly __typename?: 'Employee' }
      & Pick<TP.Employee, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
      & { readonly children?: TP.Maybe<ReadonlyArray<(
        { readonly __typename?: 'Employee' }
        & Pick<TP.Employee, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
        & { readonly children?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'Employee' }
          & Pick<TP.Employee, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
          & { readonly employeeRole?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly employeeGroup?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly employeeOffice?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly division?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          )>, readonly manager?: TP.Maybe<(
            { readonly __typename?: 'Employee' }
            & Pick<TP.Employee, 'id' | 'fullName'>
          )> }
        )>>, readonly employeeRole?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly employeeGroup?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly employeeOffice?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly division?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly manager?: TP.Maybe<(
          { readonly __typename?: 'Employee' }
          & Pick<TP.Employee, 'id' | 'fullName'>
        )> }
      )>>, readonly employeeRole?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly employeeGroup?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly employeeOffice?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly division?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly manager?: TP.Maybe<(
        { readonly __typename?: 'Employee' }
        & Pick<TP.Employee, 'id' | 'fullName'>
      )> }
    )>>, readonly employeeRole?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly employeeGroup?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly employeeOffice?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly division?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly manager?: TP.Maybe<(
      { readonly __typename?: 'Employee' }
      & Pick<TP.Employee, 'id' | 'fullName'>
    )> }
  )> }
);


export const GetEmployeeDetailsDocument = gql`
    query getEmployeeDetails($id: ID!) {
  employee(id: $id) {
    ...EmployeeDetailsEmployeeTree
    parent {
      ...EmployeeDetailsEmployeeTree
    }
  }
}
    ${EmployeeDetailsEmployeeTreeFragmentDoc}`;

/**
 * __useGetEmployeeDetailsQuery__
 *
 * To run a query within a React component, call `useGetEmployeeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployeeDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeDetailsQuery, GetEmployeeDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeDetailsQuery, GetEmployeeDetailsQueryVariables>(GetEmployeeDetailsDocument, options);
      }
export function useGetEmployeeDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeDetailsQuery, GetEmployeeDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeDetailsQuery, GetEmployeeDetailsQueryVariables>(GetEmployeeDetailsDocument, options);
        }
export type GetEmployeeDetailsQueryHookResult = ReturnType<typeof useGetEmployeeDetailsQuery>;
export type GetEmployeeDetailsLazyQueryHookResult = ReturnType<typeof useGetEmployeeDetailsLazyQuery>;
export type GetEmployeeDetailsQueryResult = Apollo.QueryResult<GetEmployeeDetailsQuery, GetEmployeeDetailsQueryVariables>;