import * as TP from '../types';

import { gql } from '@apollo/client';
import { CarrierInfoSearchPageFragmentDoc } from '../fragments/carrierInfoSearchPage';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchCarriersQueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.CarriersFilter>;
  first?: TP.InputMaybe<TP.Scalars['Int']>;
  last?: TP.InputMaybe<TP.Scalars['Int']>;
  after?: TP.InputMaybe<TP.Scalars['String']>;
  before?: TP.InputMaybe<TP.Scalars['String']>;
}>;


export type SearchCarriersQuery = (
  { readonly __typename?: 'Query' }
  & { readonly carriersNullable?: TP.Maybe<(
    { readonly __typename?: 'CarrierConnection' }
    & { readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & Pick<TP.PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), readonly edges: ReadonlyArray<(
      { readonly __typename?: 'CarrierEdge' }
      & Pick<TP.CarrierEdge, 'cursor'>
      & { readonly node: (
        { readonly __typename?: 'Carrier' }
        & Pick<TP.Carrier, 'id' | 'name' | 'code' | 'scacNumber' | 'mcNumber' | 'dotNumber' | 'levelId' | 'status'>
        & { readonly mainAddress?: TP.Maybe<(
          { readonly __typename?: 'Address' }
          & Pick<TP.Address, 'id' | 'city' | 'state'>
        )>, readonly reps?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'Rep' }
          & Pick<TP.Rep, 'id' | 'main'>
          & { readonly employee: (
            { readonly __typename?: 'Employee' }
            & Pick<TP.Employee, 'id' | 'firstName' | 'lastName'>
          ) }
        )>>, readonly opportunity?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly carrierServices?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'CarrierService' }
          & Pick<TP.CarrierService, 'id'>
          & { readonly carrierServiceType: (
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          ) }
        )>>, readonly certifications?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'CarrierCertification' }
          & Pick<TP.CarrierCertification, 'id'>
          & { readonly carrierCertificationType: (
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          ) }
        )>> }
      ) }
    )> }
  )> }
);


export const SearchCarriersDocument = gql`
    query searchCarriers($filter: CarriersFilter, $first: Int, $last: Int, $after: String, $before: String) {
  carriersNullable(
    filter: $filter
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...CarrierInfoSearchPage
      }
    }
  }
}
    ${CarrierInfoSearchPageFragmentDoc}`;

/**
 * __useSearchCarriersQuery__
 *
 * To run a query within a React component, call `useSearchCarriersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCarriersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCarriersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useSearchCarriersQuery(baseOptions?: Apollo.QueryHookOptions<SearchCarriersQuery, SearchCarriersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCarriersQuery, SearchCarriersQueryVariables>(SearchCarriersDocument, options);
      }
export function useSearchCarriersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCarriersQuery, SearchCarriersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCarriersQuery, SearchCarriersQueryVariables>(SearchCarriersDocument, options);
        }
export type SearchCarriersQueryHookResult = ReturnType<typeof useSearchCarriersQuery>;
export type SearchCarriersLazyQueryHookResult = ReturnType<typeof useSearchCarriersLazyQuery>;
export type SearchCarriersQueryResult = Apollo.QueryResult<SearchCarriersQuery, SearchCarriersQueryVariables>;