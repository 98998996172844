import { startOfDay } from '@components/DateRangePicker/util/relative';
import { useHasPermissionFromContext } from '@components/PermissionsContext';
import { useEffectAfterMount } from '@hooks/useEffectAfterMount';
import { parseAbsolute, parseZonedDateTime } from '@internationalized/date';
import {
  currentTZ,
  dateFnsFormat,
  getDateMillis,
  parseStringDate,
  shiftDateByTimezone,
  strHasYear,
} from '@utils/time';
import { isDate, isValid, subDays } from 'date-fns';
import { omit } from 'lodash-es';
import { forwardRef, ReactElement, useEffect, useRef, useState } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { usePrevious } from 'react-use';
import { ReadOnlyField } from '../Field/ReadOnlyField';
import { AUTOCOMPLETE_OFF_VALUE } from '../Input';

const padTwoDigits = (n: number): string => n.toString().padStart(2, '0');

interface BaseProps
  extends Omit<
    ReactDatePickerProps,
    | 'onChange'
    | 'onCalendarClose'
    | 'onSelect'
    | 'onBlur'
    | 'isClearable'
    | 'onChangeRaw'
  > {
  limitDateRange?: boolean;
  dateFormatting?: boolean;
  showOnlyYear?: boolean;
  minDate?: Date;
  timezone?: string;
  /** Use the current user's time (like 11:30a) and apply that to the day selected */
  applyCurrentTime?: boolean;
}

interface PropsWithTruthyOnChange extends BaseProps {
  onChange?: (d: Date) => void;
  allowEmpty?: false | undefined;
}

interface PropsWithNullishOnChange extends BaseProps {
  allowEmpty: true;
  onChange?: (d: Date | null) => void;
}

export type Props = PropsWithTruthyOnChange | PropsWithNullishOnChange;

const allowNullish = (props: Props): props is PropsWithNullishOnChange => {
  return props.allowEmpty === true;
};

const sixtyDaysAsMillis = 1000 * 60 * 60 * 24 * 60;

const fitBounds = (d: Date): Date => {
  const diff = d.valueOf() - Date.now();
  if (diff < 0 && Math.abs(diff) > sixtyDaysAsMillis) {
    // if the date is more than 60 days prior to today
    // we typically do not want dates in the past
    // so if we find that the year is incorrect (typically this year)
    // set it to the next year.
    // this is special for our particular application, but works
    // but...if there is a mismatch in leap year vs not, and the date is 02/29, this can return a strange result
    d.setFullYear(Number(d.getFullYear()) + 1);
  }
  return d;
};

const customParse = (rawStr: string): Date | undefined => {
  const d = parseStringDate(rawStr);
  if (!d) {
    return undefined;
  }
  if (!isValid(d)) {
    return new Date();
  } else if (strHasYear(rawStr)) {
    return d;
  }
  return fitBounds(d);
};

const coerceToTimezone = (
  date: Date | string,
  timezoneFrom: string,
  timezoneTo: string
): Date => {
  if (!timezoneFrom || !timezoneTo || timezoneFrom === timezoneTo) {
    return new Date(date);
  }
  return shiftDateByTimezone(new Date(date), timezoneFrom, timezoneTo);
};

const normalizeSelection = (
  rawDate: Date,
  applyCurrentTime?: boolean,
  tz?: string
): Date => {
  // As of writing, there is a bug with react-datepicker where keyboard selection differs from mouse selection, so we normalize below to startOfDay for consistency.
  // https://github.com/Hacker0x01/react-datepicker/issues/1484
  const dLocal = parseAbsolute(rawDate.toISOString(), currentTZ);
  const dObj = parseZonedDateTime(
    `${padTwoDigits(dLocal.year)}-${padTwoDigits(dLocal.month)}-${padTwoDigits(
      dLocal.day
    )}T${padTwoDigits(dLocal.hour)}:${padTwoDigits(
      dLocal.minute
    )}:${padTwoDigits(dLocal.second)}[${tz || currentTZ}]`
  );
  let val = startOfDay(dObj).toDate();
  if (applyCurrentTime) {
    const millis = getDateMillis(new Date());
    val = new Date(val.valueOf() + millis);
  }
  return val;
};

const CustomInput = forwardRef<HTMLInputElement, fixMe>((props, ref) => (
  <input
    data-testid="datepicker-input"
    type="text"
    {...props}
    ref={ref}
    onChange={(e): void => {
      const str = (e.target.value || '').trim();
      const replaced = str.replace(/(\D)/gi, '/');
      props.onChange?.({
        isDefaultPrevented: () => false,
        target: {
          value: replaced,
        },
      });
    }}
  />
));

export function DatePicker(props: Props): ReactElement {
  const {
    onChange,
    limitDateRange,
    minDate,
    dateFormatting,
    showOnlyYear,
    timezone,
    applyCurrentTime,
  } = props;
  const [userHasPermission, permissionScope] = useHasPermissionFromContext();
  const readOnly = !userHasPermission || props.readOnly;
  // `input` is a prop available on the ReactDatePicker component, but it's not
  // explicitly defined in the type declarations:
  const datePickerRef = useRef<ReactDatePicker & { input: HTMLInputElement }>(
    null
  );

  const [internalVal, setInternalValRaw] = useState<Date | null | undefined>(
    props.selected
  );

  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());

  const setInternalVal = (
    val: Date | null,
    skipFit?: true,
    skipNormalize?: true
  ): void => {
    let d = val;
    if (isDate(val) && isValid(val) && d !== null && !skipFit) {
      d = fitBounds(d);
    }
    if (!skipNormalize) {
      d = d ? normalizeSelection(d, applyCurrentTime, timezone) : d;
    }
    setLastUpdated(() => Date.now());
    setInternalValRaw(() => d);
  };

  const resolvedTZ = timezone || currentTZ;

  const valOrSelection = props.value || props.selected;

  const coercedSelection = coerceToTimezone(
    valOrSelection || new Date(),
    resolvedTZ,
    currentTZ
  );
  const dateFormat = showOnlyYear
    ? 'yyyy'
    : dateFormatting
    ? 'MM/dd/yy'
    : 'MM/dd';

  const prevTZ = usePrevious(timezone);

  useEffect(() => {
    if (prevTZ && prevTZ !== timezone && valOrSelection) {
      const newVal = coerceToTimezone(
        valOrSelection || new Date(),
        prevTZ,
        resolvedTZ
      );
      onChange?.(newVal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevTZ, timezone]);

  useEffectAfterMount(() => {
    if (valOrSelection) {
      setInternalValRaw(new Date(valOrSelection));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valOrSelection?.valueOf()]);

  if (readOnly && coercedSelection) {
    return (
      <ReadOnlyField data-scope={permissionScope}>
        {valOrSelection ? dateFnsFormat(coercedSelection, dateFormat) : ''}
      </ReadOnlyField>
    );
  }

  const internalOnChange = (): void => {
    if (props.onChange && allowNullish(props)) {
      props.onChange(internalVal ?? null);
    } else if (onChange && internalVal) {
      onChange(internalVal);
    }
  };

  return (
    <ReactDatePicker
      ref={datePickerRef}
      autoComplete={AUTOCOMPLETE_OFF_VALUE}
      showPopperArrow={false}
      {...omit(props, ['dateFormatting', 'onChange'])}
      value={undefined}
      dateFormat={dateFormat}
      {...(valOrSelection &&
        timezone && {
          selected: coercedSelection,
        })}
      onChange={undefined as anyOk}
      onBlur={(): void => {
        internalOnChange();
      }}
      onCalendarClose={(): void => {
        internalOnChange();
      }}
      onChangeRaw={(event): void => {
        const { value } = event.target;
        if (!value) {
          setInternalVal(null);
          return;
        }
        const parsed = customParse(value);
        setInternalVal(parsed ?? null, true);
      }}
      onSelect={(d): void => {
        if (Date.now() - lastUpdated > 50) {
          setInternalVal(d, true);
        }
      }}
      customInput={
        <CustomInput
          data-testid="datepicker-input"
          data-scope={permissionScope}
          type="text"
        />
      }
      minDate={
        props.minDate ||
        (limitDateRange
          ? minDate || subDays(new Date(), 60)
          : subDays(new Date(), 32850))
      }
    />
  );
}
