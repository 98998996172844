const noop = (): void => {
  //
};

const MyProxy = (): anyOk =>
  new Proxy(noop, {
    get: (): anyOk => MyProxy(),
  });

const winProxy = MyProxy();

export interface Fullstory {
  identify(uid: string, opts: { displayName?: string; email?: string }): void;
  setUserVars(vars: Record<string, anyOk>): void;
  getCurrentSessionURL(): string;
  event(name: string, properties: Record<string, anyOk>): void;
  restart(): void;
}

interface MasteryWindow extends Window {
  Cypress?: unknown;
  masteryWalkmeVariables: Record<string, string | undefined>;
  React: {
    Fragment: fixMe;
  };
  electron?: {
    ipc: {
      send: (s: string, d: unknown) => void;
      sendSync: <D extends unknown>(s: string, ...args: unknown[]) => D;
      on: (s: string, cb: (evt: unknown, data: fixMe) => void) => void;
      invoke: (s: string, d: unknown) => void;
    };
    registerWindow: (id: string) => void;
    openWindows: Set<string>;
  };
  FS?: Fullstory;
  masteryFullstoryInit: (orgId: string) => void;
  masteryBoot: {
    BASE_HEADER_HEIGHT: number;
    IS_HEADER_ALLOWED: boolean;
    IS_SIDEBAR_ALLOWED: boolean;
    IS_SIDEBAR_SHOWN_ON_PAGE_LOAD: boolean;
    SIDEBAR_QUERY_PARAM: null | boolean;
  };
  MERCATOR_ENV?: string;
}

/** When this variable evaluates to true, it means we have a valid window object. We are probably running inside of a browser (vs Node). */
// eslint-disable-next-line no-restricted-globals
export const HAS_WINDOW = typeof window !== 'undefined';

// eslint-disable-next-line no-restricted-globals
export const win = (HAS_WINDOW ? window : winProxy) as MasteryWindow;

//This code will add a random name to the parent (opener) window and open empty url.
//This is used to focus on the parent tab as win.opener.focus() function does not work.
export const getParentWinObj = (): Window => {
  if (win.opener) {
    let parentWin = win.opener;
    while (parentWin.opener) {
      parentWin = parentWin.opener;
    }
    const randomName = `w-${Date.now().toString()}`;
    parentWin.name = randomName;
    win.open('', randomName);
    return parentWin;
  } else {
    return win;
  }
};
