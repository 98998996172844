import { BODY_FONT_SIZE } from '@components/Theme';
import { css, CSSObject } from '@emotion/react';
import { useTheme } from '@hooks/useTheme';
import { forwardRef, HTMLProps } from 'react';

interface ButtonTheme {
  primary: Array<string>;
  secondary?: Array<string>;
  tertiary?: Array<string>;
  quaternary?: Array<string>;
  quinary?: Array<string>;
  alert?: Array<string>;
  inverseAlert?: Array<string>;
}

export type ButtonOption = keyof ButtonTheme;

export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  option?: ButtonOption;
  as?: 'button' | 'span' | 'div';
  show?: boolean;
}

export const MIN_BUTTON_HEIGHT = 28;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (originalProps, ref) => {
    const { as = 'button', show = true, ...props } = originalProps;
    const { children, type = 'button', option = 'primary' } = props;

    const {
      button: { background },
      colors: { primary, error },
      gray,
    } = useTheme();

    const getStyleProps = (arr: string[], important?: string): CSSObject => ({
      background: `${arr[0]} ${important || ''}`,
      color: `${arr[1]} ${important || ''}`,
      border: `${arr[2]} ${important || ''}`,
    });

    const initial: ButtonTheme = {
      primary: [background, primary, `1px solid ${primary}`],
      secondary: [gray[800], background, `1px solid ${gray[800]}`],
      tertiary: [background, primary, 'none'],
      quaternary: [primary, background, `1px solid ${primary}`],
      quinary: [background, gray[800], `1px solid ${gray[800]}`],
      alert: [error, background, `1px solid ${error}`],
      inverseAlert: [background, error, '1px solid white'],
    };

    const active: ButtonTheme = {
      primary: [primary, background, `1px solid ${primary}`],
      secondary: [background, gray[800], `1px solid ${gray[800]}`],
      tertiary: [background, gray[800], 'none'],
      quaternary: [background, primary, `1px solid ${primary}`],
      quinary: [gray[800], background, `1px solid ${gray[800]}`],
      alert: [background, error, `1px solid ${error}`],
      inverseAlert: [error, background, '1px solid white'],
    };

    const initialStyles = initial[option] || initial.primary;
    const activeStyles = active[option] || active.primary;

    const style = css({
      borderRadius: '3px',
      cursor: 'pointer',
      justifyContent: 'center',
      padding: '0.375rem .75rem',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      transition: '.3s',
      fontSize: BODY_FONT_SIZE,
      minHeight: MIN_BUTTON_HEIGHT,
      ...getStyleProps(initialStyles),
      '&:disabled': {
        cursor: 'not-allowed !important',
        opacity: 0.5,
        ...getStyleProps(initialStyles, '!important'),
      },
      '&:hover': getStyleProps(activeStyles),
    });

    const El = as as anyOk;

    return (
      <>
        {show && (
          <El {...props} type={type} css={style} ref={ref}>
            {children}
          </El>
        )}
      </>
    );
  }
);
