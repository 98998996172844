import {
  ApolloQueryResult,
  OperationVariables,
  QueryOptions,
} from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useCallback } from 'react';
import { useMasteryApolloClient } from '../../app/client';

/** As of writing, the return value of a useLazyQuery call was `void`. If you need to `await` the lazy query call and use the data somehow, this hook helps.  */
export function useLazyQueryWithDataPromise<
  TData = unknown,
  TVariables = OperationVariables
>(
  query: DocumentNode
): (
  options: Omit<QueryOptions<TVariables, unknown>, 'query'>
) => Promise<ApolloQueryResult<TData>> {
  const client = useMasteryApolloClient();
  return useCallback(
    (options: Omit<QueryOptions<TVariables, unknown>, 'query'>) => {
      return client.query<TData, TVariables>({
        ...options,
        query: query,
      } as fixMe);
    },
    [client, query]
  );
}
