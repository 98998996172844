import { FC, ReactNode } from 'react';
import { Card } from '../Card';
import { Padding } from '../Padding';

interface FallbackProps {
  title: string;
  content?: string;
  children?: ReactNode;
}

export const FallbackCard: FC<FallbackProps> = ({
  title,
  content,
  children,
}) => {
  return (
    <>
      <Padding a={1}>
        <Card title={title}>
          <Padding t={1} b={1}>
            {content || children}
          </Padding>
        </Card>
      </Padding>
    </>
  );
};
