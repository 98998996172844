import { RESOLVED_TENANT_HOSTNAME } from '../../config';

const vanityHostnames = {
  'mm.shipmolo.com': 'ml',
  'molo.mastermindtms.com': 'ml',
  'demo.masterytms.com': 'mastery',
  'dev.mm100.mastermindtms.com': 'mastery',
  'test.mm100.mastermindtms.com': 'mastery',
  'test.td100.mastermindtms.com': 'mastery',
  'mm100.mastermindtms.com': 'mastery',
  'mastery-frontend.dev.mm100.mastermindtms.com': 'mastery',
} as const;

export const getTenantFromHostname = (
  rawDomain?: string
): string | undefined => {
  const originStr = rawDomain ?? RESOLVED_TENANT_HOSTNAME;
  const foundVanity =
    vanityHostnames[originStr as keyof typeof vanityHostnames];
  if (foundVanity) {
    return foundVanity;
  }
  const [, match1] =
    originStr.match(/(.*)\.(mastermindtms|masterytms)\.com/) || [];
  const [env, tenant] = (match1 || '').split('.');
  const [, id] = (tenant || env)?.match(/([a-z]+)\d/) || [];
  return id;
};

export const RESOLVED_TENANT = getTenantFromHostname();
