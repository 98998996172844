import { MODE } from '@env';
import { keys } from '@utils/keys';
import {
  deprecatedRawBooleanFlags,
  deprecatedRawNumberFlags,
} from './deprecated';
import { jsonFlagArr } from './jsonFlags';
import { deprecatedCreate, make } from './util';

export const moduleCheck = 1;

// If you want to add a new flag to the system, this is the file you edit! 🏁

export const rawBooleanFlags = {
  'ME-21297-feat-available-routes-edit-inline': deprecatedCreate({
    description:
      'This will display the "Save","Save & Post" buttons and add Post column with checkboxes/ editable fields in table.',
  }),
  'ME-10294-view-request-to-book-on-route': deprecatedCreate({
    description:
      "This will display 'Request to Book' instead of 'Add Carrier' on Route when OAS/RAS = Uncommitted.",
    temporary: false,
  }),
  'ME-6601-feat-unpost-all-routes-on-available-routes': deprecatedCreate({
    description:
      'This will display the "Unpost All" button on Available Routes',
  }),
  'ME-18087-view-route66-tours': deprecatedCreate({
    description: 'Turns on Tours kebab and modal in truck entry',
  }),
  'ME-33970-view-route66-tours-v2': deprecatedCreate({
    description:
      'Turns on Cost Allocation, Offers Submit, and Lock Routes for Tours',
  }),
  'ME-70336-view-book-routes-from-tours': make({
    description: 'Turns on Book Routes for Tours',
    temporary: false,
    initialTeam: 'route-66',
  }),
  'ME-45360-view-revenue-on-procurement-screens': deprecatedCreate({
    description: 'Turns on Revenue and Margin columns on procurement screens',
  }),
  'ME-22840-feat-available-routes-refresh': deprecatedCreate({
    description:
      'This will enable available routes screen to refresh based on subscription ',
  }),
  'ME-22841-feat-available-routes-lock-refresh': deprecatedCreate({
    description:
      'This will enable available routes screen to lock based on subscription ',
  }),
  'ME-10011-view-repeat-order-status-reason-code': deprecatedCreate({
    description: 'Used to display reason status code for repeat order',
  }),
  'ME-10144-fix-part-1-remove-load-data-refresh': deprecatedCreate({
    description:
      'Drop a call of refreshLoadData from the routes section to avoid form data clobbering',
  }),
  'ME-8632-view-book-from-offers-load': deprecatedCreate({
    description:
      'Display Book in kebab menu for Offer that passes validation for users with permissions',
  }),
  'ME-11963-view-booking-validations-offers-modals': deprecatedCreate({
    description: 'Display booking validations on the carrier on Offers modal',
  }),
  'ME-9205-feat-commissions-global-configuration': deprecatedCreate({
    description:
      'Display commissions Global Configuration grid in Admin > Accounting.',
  }),
  'ME-9300-feat-commissions-employee-configuration': deprecatedCreate({
    description:
      'Display commissions Employee Configuration grid in Admin > Accounting.',
  }),
  'ME-11200-fix-disable-dropdown-refocus': deprecatedCreate({
    //Reenables standard tabbing behavior across application but adding FF in case it impacts other workflows
    description: 'Disables refocus when tabbing away from dropdown component',
  }),
  'ME-8615-view-route-best-offer-card': deprecatedCreate({
    description: 'This will enable the best offers card on routes tab',
  }),
  'ME-10932-view-home-mytask-task-service': deprecatedCreate({
    description:
      'If Flag is off use exsisting feature of My Task Tab else new My Task Tab service.Once production ready this flag is no longer needed. ',
    temporary: false,
  }),
  'ME-11055-feat-trailer-id-validation': deprecatedCreate({
    description: 'If Flag is on trailer id validation is enabled.',
  }),
  'ME-9210-feat-commissions-customer-overhead-configuration': deprecatedCreate({
    description:
      'Display commissions Customer Overhead Configuration grid in Admin > Accounting.',
  }),
  'ME-11859-view-load-procure-tab': deprecatedCreate({
    description: 'Show procure tab enabled or disabled on the load > routes',
  }),
  'ME-12224-view-allocations-tab': deprecatedCreate({
    description: 'Show allocations grid for commission totals on load page',
  }),
  'ME-8914-feat-route-posting-status-card': deprecatedCreate({
    description:
      'Displays route Posting Status card on routes section of load page.',
  }),
  'ME-11727-feat-route-vendor-reps': deprecatedCreate({
    description:
      '[true = New Modal] Toggle between old and new voucher creation modal @ Load > Accounting > Vouchers > Add Voucher (button)',
  }),
  'ME-11819-view-vendor-validation-in-offers-tables': deprecatedCreate({
    description: 'Display vendor validation status in offers table',
  }),
  'ME-12090-view-offers-modal-best-offer-card': deprecatedCreate({
    description: 'Add Best Offer card to Add/Edit and Response Offer Modals',
  }),
  'ME-13466-view-createLoad-option-in-quotes-table-kebab': deprecatedCreate({
    description:
      'Provides visibility to "Create Load" option within Customer > Rate Quote > Quotes table kebab',
  }),
  'ME-12617-view-submit-offer-action-button': deprecatedCreate({
    description:
      'This will enable Submit Offer action button for Suggested tab on Procure page',
  }),
  'ME-21714-feat-use-route-v2-for-offers': deprecatedCreate({
    description:
      'This will be used to use routesV2 instead of routes as the system of record for routes',
  }),
  'ME-14060-view-load-commissions-tab': deprecatedCreate({
    description: 'Enables the commissions tab on load page',
  }),
  'ME-13254-view-recreate-option-in-invoices-table-kebab': deprecatedCreate({
    description:
      'Provides visibility to "recreate" option within Load > Accounting > Invoices and invoice queue table kebab',
  }),
  'ME-14657-view-book-with-bin-from-procure-screen': deprecatedCreate({
    description: 'Enable book with bin action button',
  }),
  'ME-14713-view-carrier-portfolio-option': deprecatedCreate({
    description: 'Enable carrier dropdown option on portfolio page',
  }),
  'ME-14753-fix-table-card-height': deprecatedCreate({
    description:
      'Changes the default css of the Card component within the Table component to be 100%',
  }),
  'ME-14209-view-exception-reason': deprecatedCreate({
    description:
      'Enables exception multiselect dropdown filtering option on Vendor Settlement page',
  }),
  'ME-9860-feat-driver-reload-intent': deprecatedCreate({
    description: 'Enables reload intent dropdown on Load > Drivers',
  }),
  'ME-14060-view-commissions-tab': deprecatedCreate({
    description: 'Enable the commissions tab on Load view.',
  }),
  'ME-15435-view-historical-tab-from-procure-screen': deprecatedCreate({
    description: 'Enables/Disables historical tab from procure screen',
  }),
  'ME-34786-view-procure-tab': deprecatedCreate({
    description: 'Enables/disables procure tab on main load view',
  }),
  'ME-13869-feat-driver-linked-routes': deprecatedCreate({
    description: 'Enable route linking on Load > Drivers',
  }),
  'ME-15441-feat-handle-historical-pagination': deprecatedCreate({
    description: 'Pagination for historical tab from procure screen',
  }),
  'ME-8932-feat-booking-events-subscription': deprecatedCreate({
    description: 'Enable Subscriptions to Route Vendor Booking Events',
  }),
  'ME-15460-view-matches-tab-from-procure-screen': deprecatedCreate({
    description: 'Enables/Disables matches tab from procure screen',
  }),
  'ME-16118-view-customer-crm-notes': deprecatedCreate({
    description: 'Enable notes tab for customer crm',
  }),
  'ME-57856-feat-vouchering-disable-financials-toggle': deprecatedCreate({
    description:
      'Checkbox to allow frontend users to set the disable financials field for a route vendor',
  }),
  'ME-16119-view-carrier-crm-notes': deprecatedCreate({
    description: 'Enable the carrier crm Notes table',
  }),
  'ME-15110-view-recreate-and-resend-batch-processing-on-invoice-queue':
    deprecatedCreate({
      description:
        'This will enable recreate and resend batch processing on invoice queue page ',
    }),
  'ME-17123-view-admin-assign-commission-plan': deprecatedCreate({
    description:
      'Allows assigning employees to a commission plan on the Admin > Accounting page.',
  }),
  'ME-17006-feat-keycloak-use-keycloak-user-id': deprecatedCreate({
    description:
      'Uses keycloak_user_id instead of auth0_id to create users in Launch Darkly',
  }),
  'ME-17060-view-truck-entry-view-route-section': deprecatedCreate({
    description: 'This will display view route section in truck entry page',
  }),
  'ME-16830-view-routing-guide': deprecatedCreate({
    description:
      'This will display the routing guide under the rate quotes tab on the customer page',
  }),
  'ME-15468-feat-handle-matches-pagination': deprecatedCreate({
    description: 'Pagination for matches tab from procure screen',
  }),
  'ME-18121-view-historical-total-runs': deprecatedCreate({
    description: 'This will show/hide total runs checkbox on procure page',
  }),
  'ME-18983-view-ofs-column-in-load-table': deprecatedCreate({
    description: 'This will toggle OFS column in load table',
  }),
  'ME-17076-feat-invoiced-currency-column-for-credit-memo': deprecatedCreate({
    description:
      'Toggle the display of the new invoiced currency column in the process credit memo modal',
  }),
  'ME-18019-feat-multiple-advance-providers': deprecatedCreate({
    description:
      'Adds support for configuring and selecting between multiple providers for advances',
  }),
  'ME-9172-view-load-suggested-carrier': deprecatedCreate({
    description: 'Show suggested tab on the load > routes',
  }),
  'ME-18357-feat-route-vendor-details-v2': deprecatedCreate({
    description: 'Show suggested tab on the load > routes',
  }),
  'ME-12653-feat-RMIS-enhancements-ph1': deprecatedCreate({
    description: 'Toggle new RMIS Ph1 enhancements feature',
  }),
  'ME-18382-view-alchemy-tab-carrier-record': deprecatedCreate({
    description: 'This will enable/disable Alchemy tab on the carrier record',
  }),
  'ME-19760-view-rate-con-section-v2': deprecatedCreate({
    description: 'This will enable/disable Alchemy tab on the carrier record',
  }),
  'ME-18859-feat-bookings-migration-mainpage': deprecatedCreate({
    description: 'use V2 schema part of bookings migration on main page',
  }),
  'ME-20496-fix-geoautocomplete-city-name-display': deprecatedCreate({
    description: 'Fix for edge case city name display',
  }),
  'ME-16832-view-tender-options': deprecatedCreate({
    description:
      'Enables tender options to be displayed and edited by expanding a routing guide sequence item',
  }),
  'ME-18426-view-procure-tender-tab': deprecatedCreate({
    description: 'Enables/Disables tender tab from procure screen',
  }),
  'ME-15447-view-historical-tab-accounting-info': deprecatedCreate({
    description:
      'This will show/hide accounting information on Hstorical Tab Table',
  }),
  'ME-20397-view-ability-to-manually-add-required-document': deprecatedCreate({
    description: 'This will provide ability to manually add required document',
  }),
  'ME-17405-view-carrier-compliance-tab': deprecatedCreate({
    description: 'This will enable Compliance tab on Carrier page',
  }),
  'ME-20198-feat-offers-modal-cca': deprecatedCreate({
    description: 'Reference CCA for PU/Del date times',
  }),
  'ME-12577-view-autocomplete-address': deprecatedCreate({
    description: 'This will enable the functionality of autocompleting address',
  }),
  'ME-17103-view-vrfs-column-under-route-list': deprecatedCreate({
    description: 'This will toggle vrfs column in load table ',
  }),
  'ME-20131-view-cost-quotes-table-under-alchemy-tab': deprecatedCreate({
    description: 'This will enable cost quotes tab in carrier alchemy tab',
  }),
  'ME-21898-view-admin-configuration-approved-users': deprecatedCreate({
    description:
      'This will enable approved user tab under admin >accounting>configuration',
  }),
  'ME-21647-feat-EDI-Rate-Con-Contact-Method': deprecatedCreate({
    description:
      'This will enable EDI as a contact method for triggering rate con',
  }),
  'ME-19388-feat-auto-carrier-create-code': deprecatedCreate({
    description: 'Carrier code is created on the BE and read-only on the FE',
  }),
  'ME-19163-feat-hot-route': deprecatedCreate({
    description: 'This will display hot route checkbox in route on load page',
  }),
  'ME-24173-feat-posting-enhancements': deprecatedCreate({
    description: 'This will display route posting card and modal enhancements.',
  }),
  'ME-23189-view-planning-nav-item': deprecatedCreate({
    description: 'Displays planning navigation dropdown',
    temporary: false,
  }),
  'ME-14873-feat-distance-details': deprecatedCreate({
    description:
      'This will enable the distance details table and interactivity on the carrier & customer tabs',
  }),
  'ME-24384-view-truck-entry-view-route-booking-tab': deprecatedCreate({
    description:
      'This will display the booking tab and Add Carrier/Request to Book button in view route section of truck entry',
  }),
  'ME-24478-view-truck-entry-view-route-drivers-tab': deprecatedCreate({
    description:
      'This will display the drivers tab in view route section of truck entry',
  }),
  'ME-24383-view-truck-entry-view-route-offers-tab': deprecatedCreate({
    description:
      'This will display the offers tab and add offer button in view route section of truck entry',
  }),
  'ME-24479-view-truck-entry-view-route-commissions-tab': deprecatedCreate({
    description:
      'This will display the commissions tab in view route section of truck entry',
  }),
  'ME-24766-view-route-references-table': deprecatedCreate({
    description:
      'This will display route references table under route section on load page',
  }),
  'ME-22672-feat-disable-finalized-fuel-status': deprecatedCreate({
    description:
      'This will temporarily disable the finalized fuel graphQL status check until rate detail outboxing speed is improved.',
  }),
  'ME-25865-view-customer-crm-document-tab': deprecatedCreate({
    description: 'This will enable documents tab under customer crm',
  }),
  'ME-25902-feat-soft-delete-contacts': deprecatedCreate({
    description:
      'This feature removes the option to hard-delete contacts and adds an *Active* checkbox in the modal instead',
  }),
  'ME-25738-view-carrier-crm-documents': deprecatedCreate({
    description: 'Enable Documents tab for carrier crm',
  }),
  'ME-18098-feat-admin-accounting-tax-tab': deprecatedCreate({
    description: 'show/hide the Tax tab under the Accounting -> Admin Nav',
  }),
  'ME-25329-feat-admin-accounting-tax-view-enable-vat': deprecatedCreate({
    description:
      'show/hide Enable VAT card under the Accounting -> Admin Nav -> Tax',
  }),
  'ME-43927-feat-auto-invoicing-processing': deprecatedCreate({
    description:
      'show/hide auto invoicing processing button on customer settlement screen.',
  }),
  'ME-23197-view-rate-quote-grid-lanes-page': deprecatedCreate({
    description: 'Enable Rate Quote tabsTable in the Lane view',
  }),
  'ME-15297-feat-multi-currency': deprecatedCreate({
    description:
      'Show/Hide Multi-Currency items, currency columns & flip codes and symbols',
  }),
  'ME-20201-feat-del-date-cca': deprecatedCreate({
    description:
      'Reference CCA for Del date times on vendor and customer settlement',
  }),
  'ME-28335-view-customer-load-defaults-tab': deprecatedCreate({
    description: 'This will enable Load Defaults tab on Customer page',
  }),
  'ME-40577-view-facility-load-defaults-tab': deprecatedCreate({
    description: 'This will enable Load Defaults tab on Facility page',
  }),
  'ME-32174-feat-assets': deprecatedCreate({
    description: 'This will enable Load Defaults tab on Driver page',
  }),
  'ME-47252-feat-mass-upload-export-button': deprecatedCreate({
    description:
      'Controls whether to display download/export button of routing guides from global and customer lane pages',
  }),
  'ME-48382-feat-mass-upload-import-button': deprecatedCreate({
    description:
      'Controls whether to display upload button of routing guides from global and customer lane pages',
  }),
  'ME-29926-view-lanes-tab-in-customer-record': deprecatedCreate({
    description: 'This will hide/show Lanes tab on Customer page',
  }),
  'ME-24369-feat-bin-checkbox': deprecatedCreate({
    description: 'Show/Hide the Book It Now Checkbox in the Carrier Info Card',
  }),
  'ME-30049-feat-available-credit-value': deprecatedCreate({
    description:
      'Show/Hide Available Credit value on Customer->Accounting page',
  }),
  'ME-30471-view-route-board-tabbed-ux': deprecatedCreate({
    description: 'This will enable tabbed view of Route Board on Main page',
  }),
  'ME-17877-feat-table-columnresize': deprecatedCreate({
    description:
      'Enable feature allowing users to resize the columns on tables',
  }),
  'ME-31766-view-load-patterns-tab-in-customer-lanes': deprecatedCreate({
    description:
      'This will enable/disable Load Patterns tab in Customer > Lanes page',
  }),
  'ME-31766-view-load-patterns-tab-in-planning-lanes': deprecatedCreate({
    description:
      'This will enable/disable Load Patterns tab in Planning > Lanes page',
  }),
  'ME-33595-view-matches-without-offers': deprecatedCreate({
    description: 'This will remove offers on the match query',
  }),
  'ME-46472-feat-dataexchange': deprecatedCreate({
    description:
      'Hide or show the "Data Exchange Configuration" tab on the Customer page.',
  }),
  'ME-30053-view-size-mode-division-search-fields': deprecatedCreate({
    description: 'This will show Size, Mode and Division search fields',
  }),
  'ME-30101-view-mode-size-division-for-invoice': deprecatedCreate({
    description:
      'This will enable mode size division fields under accounting > invoice ',
  }),
  'ME-31327-feat-regions-v2': deprecatedCreate({
    description: 'This will enable/disable Regions v2 data',
  }),
  'ME-30888-view-dashboard-tab-main-page': deprecatedCreate({
    description:
      'Enable Dashboard tab with embedded PowerBI report on the main page',
  }),
  'ME-34451-view-rating-source-customer-record-accounting': deprecatedCreate({
    description: 'Allows viewing of Rating Source field in Customer Accounting',
  }),
  'ME-30154-view-vendor-invoice-tab': deprecatedCreate({
    description: 'This will show/hide vendor invoice tab on accounting',
  }),
  'ME-23869-feat-Post-Details-Modal-BIN-price': deprecatedCreate({
    description: 'BiN price field added to modal',
  }),
  'ME-31865-feat-patterns-multi-stop': deprecatedCreate({
    description:
      'This enables Load Patterns multi-stop allowing more then two stops per route',
  }),
  'ME-31865-feat-patterns-multi-route': deprecatedCreate({
    description:
      'This enables Load Patterns multi-route allowing more then one route per pattern',
  }),
  'ME-31566-feat-patterns-apply': deprecatedCreate({
    description:
      'This enables the pattern apply and quick apply buttons in the load screen',
  }),
  'ME-42154-feat-patterns-route-carrier': deprecatedCreate({
    description:
      'This enables the route carrier field in pattern create screen',
  }),
  'ME-42407-feat-patterns-route-max-cost': deprecatedCreate({
    description:
      'This enables the pattern route max cost field in the view, edit, and create pattern screens',
  }),
  'ME-42430-feat-patterns-stop-use-order-stop-facility': deprecatedCreate({
    description:
      'This enables the pattern stop use order stop facility boolean field in the view, edit, and create pattern screens',
  }),
  'ME-46010-feat-patterns-minimum-pickup-lead-time': deprecatedCreate({
    description:
      'This enables the pattern minimum pickup lead time field in the view, edit, and create pattern screens',
  }),
  'ME-42086-feat-patterns-eligible-days': deprecatedCreate({
    description:
      'This enables the pattern eligible days mutliselect dropdown in the view, edit, and create pattern screens',
  }),
  'ME-42158-feat-patterns-route-vendor': deprecatedCreate({
    description: 'This enables the route vendor field in pattern create screen',
  }),
  'ME-42435-feat-patterns-days-from-previous-stop': deprecatedCreate({
    description:
      'This enables the days from previous stop field in view, edit, and create pattern screens',
  }),
  'ME-42438-feat-patterns-preferred-appointment-times': deprecatedCreate({
    description:
      'This enables the preferred appointment times fields in view, edit, and create pattern screens',
  }),
  'ME-57656-feat-patterns-effective-expiration-dates': deprecatedCreate({
    description:
      'This enables the effective and expiration fields in view, edit, and create pattern screens',
    temporary: true,
  }),
  'ME-66720-feat-patterns-auto-apply-checkbox': deprecatedCreate({
    description: 'This enables the auto-apply checkbox in the pattern table.',
    temporary: true,
  }),
  'ME-38348-view-carrier-ltl-page': deprecatedCreate({
    description:
      'This enables view of the Carrier LTL page under the Lanes tab.',
  }),
  'ME-34764-feat-tracking-updates-notify-users': deprecatedCreate({
    description:
      'Show/Hide field to select user(s) to notify when a manual tracking update is created',
  }),
  'ME-38173-view-group-option-in-sidebar': deprecatedCreate({
    description: 'This will display Group option in sidebar',
  }),
  'ME-35876-feat-booked-by-hyperlink': deprecatedCreate({
    description: 'Make "booked by" field read only if user not a super user',
  }),
  'ME-30112-view-add-quote-link-on-routing-guide': deprecatedCreate({
    description:
      'This will display the add quote link under Base Rate in the Routing Guide table when the carrier does not have an associated active cost quote',
  }),
  'ME-32857-fix-facility-picker-filtering': deprecatedCreate({
    description:
      'Use client-side filtering for better UX in facility pickers and masterfind',
  }),
  'ME-38848-view-hot-route-offers-page': deprecatedCreate({
    description: 'This will display if a particular route is "hot"',
  }),
  'ME-52291-view-hot-route-main-page-offers-tab': deprecatedCreate({
    description: 'This will display if a particular route is "hot"',
  }),
  'ME-34238-feat-main-page-capacity-tab': deprecatedCreate({
    description: 'Enables the Capacity Tab feature on the Main Page',
  }),
  'ME-14914-feat-charge-type-configuration': deprecatedCreate({
    description: 'Enable charge type configurations',
  }),
  'ME-40908-view-imdl-tab-under-profile': deprecatedCreate({
    description: 'Enable flag to view IMDL functioanality',
  }),
  'ME-47958-view-stops-dropdown-on-ratedetail': deprecatedCreate({
    description: 'Enable flag to view stops Dropdown on rate detail modal',
  }),
  'ME-47974-view-stops-dropdown-on-costdetail': deprecatedCreate({
    description: 'Enable flag to view stops Dropdown on cost detail modal',
  }),
  'ME-38569-feat-use-tasks-v2': deprecatedCreate({
    description: 'This will enable pointing at the new TasksV2 gql',
  }),
  'ME-43070-fix-masterfind-load-query': deprecatedCreate({
    description:
      'Q1:2022: Use a new query for masterfind load search enabling better performance.',
  }),
  'ME-42936-view-only-PDF-document-for-manual-association': deprecatedCreate({
    description:
      'This will allowed and accepted .PDF documents for manual association',
  }),
  'ME-41630-view-total-pallet-count': deprecatedCreate({
    description:
      'This will show/hide total pallet count field in load edit page',
  }),
  'ME-42234-feat-allow-negative-and-zero-line-items': deprecatedCreate({
    description:
      'This allows cost/rate detail line items to be zero or negative',
  }),
  'ME-35962-view-pending-tab-in-route-board': deprecatedCreate({
    description:
      'This will show/hide pending tab in bottom row of tabs on main page',
  }),
  'ME-43214-feat-mass-booking': deprecatedCreate({
    description: 'This will enable mass booking feature on available routes.',
  }),
  'ME-43600-view-prevent-double-horizontal-scrollbars-in-tables':
    deprecatedCreate({
      description:
        'This will add overflowX: hidden to styles to prevent double horizontal scrollbars ',
    }),
  'ME-30816-feat-new-offers-fields': deprecatedCreate({
    description:
      'This will show Trailer Owner quick search component to Offers Modal',
  }),
  'ME-34235-app-quiet-network-errors': deprecatedCreate({
    description: 'Disable specific network errors from sentry reporting',
  }),
  'ME-32458-feat-post-details-modal-checkboxes': deprecatedCreate({
    description: 'This will allow user to select load board',
  }),
  'ME-45522-view-completed-tab-route-board': deprecatedCreate({
    description: 'Enables Completed RLC tab on the main page route board',
  }),
  'ME-47881-view-accounting-exceptions': deprecatedCreate({
    description: 'Allows viewing the accounting exceptions tab',
  }),
  'ME-29756-view-remit-to-address-fields': deprecatedCreate({
    description: 'This will show Remit-To Address fields on vendor invoice',
  }),
  'ME-44493-view-outbound-tender-settings': deprecatedCreate({
    description: 'Allows viewing the outbound tender settings modal',
  }),
  'ME-47582-view-route-type-dropdown': deprecatedCreate({
    description: 'This will show/hide route type dropdown in route details',
  }),
  'ME-50686-view-customer-accounting-bill-to-codes': deprecatedCreate({
    description: 'Allows viewing of customer accounting bill to codes features',
  }),
  'ME-34100-fix-combine-date-time': deprecatedCreate({
    description: 'Fix the functionality of our combine date and time utils',
  }),
  'ME-31848-feat-remove-stop-event-validation-for-edi-testing':
    deprecatedCreate({
      description:
        'This will allow new customers to avoid typical stop event validation for EDI testing',
      temporary: false,
    }),
  'ME-36025-view-offers-mass-respond-main-page': deprecatedCreate({
    description: 'This will allow customer to mass respond offers on main page',
  }),
  'ME-49548-view-load-new-tabs': deprecatedCreate({
    description:
      'This will show/hide an extended to full-width bottom tab in the load',
    temporary: false,
  }),
  'ME-50176-fix-wrap-offers-event': deprecatedCreate({
    description:
      'Guards the refresh of data on offers page when offers event comes in through subscription',
  }),
  'ME-51181-view-tendering-contact-multi-select': deprecatedCreate({
    description: 'Allows users to select multiple contacts on a routing guide',
  }),
  'ME-51614-view-commitment-on-rate-quote': deprecatedCreate({
    description:
      'Allows users to see Add New Commitment button on new rate quote modal',
  }),
  'ME-36024-view-offers-mass-respond-option-offers-page': deprecatedCreate({
    description: 'This will enable mass respond offers option on offers page',
  }),
  'ME-49639-feat-tracking-trailer-temperature': deprecatedCreate({
    description: 'This enables the trailer temperature for tracking updates',
  }),
  'ME-51746-view-service-profiles-table-v2': deprecatedCreate({
    description:
      'This will show/hide Service profiles table V2 which is using V2 services',
  }),
  'ME-58753-view-new-rate-con-panel': deprecatedCreate({
    description: 'This will show new and organized rate-con panel',
    temporary: true,
  }),
  'ME-44640-view-spot-quote-customer-record': deprecatedCreate({
    description:
      'Allow user to view and navigate to the Spot Quote tab in the Customer page',
  }),
  'ME-55749-feat-add-geonames-admin-area-3': deprecatedCreate({
    description:
      'Allows GeoNames search requests to return AdminArea3 results within GeoAutoComplete',
  }),
  'ME-56282-feat-carrier-crm': deprecatedCreate({
    description:
      'Allows users to acces the new CRM version2 functionality in carrier',
  }),
  'ME-56282-feat-customer-crm': deprecatedCreate({
    description:
      'Allows users to acces the new CRM version2 functionality in customer',
  }),
  'ME-46488-feat-tracking-method-column-tracking-updates': deprecatedCreate({
    description:
      'This will enable/disable Tracking Method in the Tracking Updates Grid',
    temporary: true,
  }),
  'ME-52614-view-ta-stop-pending-checkbox': deprecatedCreate({
    description:
      'This will show/hide TA Stop Pending checkbox in Rate con panel',
  }),
  'ME-30471-feat-counts-tabbed-route-board': deprecatedCreate({
    description: 'This will enable counts of Route Board on Main page',
  }),
  'ME-49831-feat-auto-dispatch-driver-rail': deprecatedCreate({
    description:
      'Driver is auto dispatched when route transport mode type is: Rail',
  }),
  'ME-55586-view-carrier-parent-details': deprecatedCreate({
    description: 'This will show/hide multiple parents details table',
  }),
  'ME-56717-view-new-capacity-structure': deprecatedCreate({
    description: 'Enables new capacity structure for capacity manager',
  }),
  'ME-56363-feat-ROR-decommision': deprecatedCreate({
    description: 'This will call new loadV2 query if enabled',
  }),
  'ME-49695-feat-use-recordsV2': deprecatedCreate({
    description:
      'This will enable the usage of records V2 data in view route panel of capacity manager and documents tab of load editor screen',
  }),
  'ME-56228-view-invoices-tab-on-customer': deprecatedCreate({
    description: 'This will enable invoices tab on customer.',
    temporary: false,
  }),
  'ME-56229-view-settlements-tab-on-customer': deprecatedCreate({
    description: 'This will enable settlements tab on customer.',
    temporary: false,
  }),
  'ME-53253-view-enhance-customer-reps': deprecatedCreate({
    description:
      'Allows users to acces the new customer reps functionality in customer',
    temporary: true,
  }),
  'ME-50971-view-stop-type-dropdown': deprecatedCreate({
    description: 'This will show/hide stop type dropdown in add stop card',
  }),
  'ME-53254-view-enhance-carrier-reps': deprecatedCreate({
    description:
      'Allows users to acces the new carrier reps functionality in carrier',
  }),
  'ME-56231-view-vendor-vouchers-tab-on-carrier': deprecatedCreate({
    description: 'This will enable vendor vouchers tab on Carrier.',
    temporary: false,
  }),
  'ME-56232-view-vendor-settlements-tab-on-carrier': deprecatedCreate({
    description: 'This will enable vendor settlements tab on Carrier.',
    temporary: false,
  }),
  'ME-56819-app-fullstory-enable': make({
    description: 'Enable Fullstory recording',
    temporary: false,
    initialTeam: 'atlas',
  }),
  'ME-58227-view-employee-business-unit-and-project': deprecatedCreate({
    description:
      'It shall encompass the changes on the Employee Search grid as well as the Employee Profile tab.',
  }),
  'ME-59681-view-customer-record-regions-tab': deprecatedCreate({
    description: 'Enable customer regions tab',
    temporary: false,
  }),
  'ME-57583-view-facility-scheduling-size-dropdown-adjustments':
    deprecatedCreate({
      description:
        'The Facility Schedules table changes along with the Add/Edit Schedules modal',
      temporary: false,
    }),
  'ME-59310-view-facility-record-directions-card': deprecatedCreate({
    description: 'This will view direction card which is using V2 services.',
  }),
  'ME-69370-feat-holidays-card': make({
    description: 'This will enable holidays card which uses V2 services.',
    initialTeam: 'damascus',
  }),
  'ME-52963-feat-order-as-non-taxable': deprecatedCreate({
    description:
      'Checkbox to frontend to allow users to toggle order as non-taxable requirement',
    temporary: true,
  }),
  'ME-58228-feat-loadex-imdl': deprecatedCreate({
    description: 'This enables intermodal for the loadex domain',
  }),
  'ME-56924-feat-load-search-view-oas-ols-support': deprecatedCreate({
    description:
      'This will allow users to search loads by order activation status',
    temporary: true,
  }),
  'ME-58954-feat-open-deck-fields': deprecatedCreate({
    description: 'This will show the new commmodity modal re-design',
  }),
  'ME-59560-view-carrier-record-resources-tab': deprecatedCreate({
    description: 'This will show/hide Resources tab under Carrier Record in V2',
  }),
  'ME-60107-fix-SendToOpenEnabledOnRlcsTenderedAndTenderPlanFulfilled':
    deprecatedCreate({
      description:
        'Enables logic to enable the send to open button when booking fails',
      temporary: true,
    }),
  'ME-56472-view-customer-bid-tab': deprecatedCreate({
    description: 'This will enable Bids tab on Customer page',
    temporary: true,
  }),
  'ME-77169-view-statement-num-column': make({
    description:
      'This will show the statement number column for invoices displayed in Invoice Queue and on Load > Accounting',
    initialTeam: 'diesel',
  }),
  'ME-37921-view-new-columns-in-tracking-screen-and-tabs': deprecatedCreate({
    description:
      'This adds BookBy, RouteReference, RouteType, column in Tracking screen and tabs',

    temporary: true,
  }),
  'ME-59682-view-carrier-record-regions-tab': deprecatedCreate({
    description: 'Enables carrier regions tab',
  }),
  'ME-57389-view-facility-records-documents-tab': deprecatedCreate({
    description: 'This enables documents feature for facility',
  }),
  'ME-58030-view-customer-ltl-page': deprecatedCreate({
    description:
      'This enables view of the Customer LTL page under the Lanes tab.',
  }),
  'ME-68032-view-customer-profile-record': make({
    description: 'This will hide/show Profile tab on Customer page.',
    initialTeam: 'office-heroes',
  }),
  'ME-68031-view-carrier-profile-record': deprecatedCreate({
    description: 'This will hide/show Profile tab on carrier page.',
  }),
  'ME-56328-feat-open-deck-drawer-fields': deprecatedCreate({
    description:
      'This will show the new open deck fields on route and order drawer.',
    temporary: true,
  }),
  'ME-61696-view-enable-electronic-trackings-table': make({
    description:
      'This will show the electronic trackings table for carrier under general tab',
    initialTeam: 'argos',
  }),
  'ME-61696-view-enable-carrier-groups-table': make({
    description:
      'This will show the groups table for carrier under general tab',
    initialTeam: 'argos',
  }),
  'ME-61696-view-enable-multiselect-employee-groups': make({
    description:
      'This will enable to multiselect groups for employee under company information card',
    initialTeam: 'argos',
  }),
  'ME-53020-view-minion-carrier-rep-type': deprecatedCreate({
    description: 'Carrier rep type is a minion field for carrierV2 services',
    temporary: true,
  }),
  'ME-42733-view-3pi-red-banner': deprecatedCreate({
    description:
      'Enables displaying red banner on drivers tab when 3pi automated tracking request fails.',
    temporary: true,
  }),
  'ME-58578-feat-use-geographiesRD': make({
    description:
      'This will call geographiesRD query if flag is true, otherwise will call geographies query.',
    initialTeam: 'off-the-rails',
  }),
  'ME-45127-view-order-requirement-modal': make({
    description: 'This will show order requirement modal on load->money',
    initialTeam: 'salt-route',
  }),
  'ME-77502-view-route-requirement-modal': make({
    description: 'This will show route requirement modal on load->money',
    initialTeam: 'salt-route',
  }),
  'ME-48868-view-four-category-for-settlement-queue': make({
    description:
      'This will show baseCharge, fuel, accessorial and other fields for settlement queue.',
    initialTeam: 'salt-route',
  }),
  'ME-50847-app-use-driver-electronic-tracking-type-metadata': make({
    description:
      'Maps carrier electronic tracking to driver electronic tracking using DDT metadata instead of hardcoded values',
    initialTeam: 'artemis',
  }),
  'ME-62439-view-export-order-and-route-list': make({
    description: 'This will able to download order and route list.',
    initialTeam: 'rome',
  }),
  'ME-54603-feat-soft-delete-carrier-rep': make({
    description:
      'This feature flag for all Soft Delete tickets under Carrier Reps',
    initialTeam: 'memphis',
  }),
  'ME-62897-view-Facility-Records-Locations-Tab': make({
    description: 'Enable Locations tab for facility',
    initialTeam: 'damascus',
  }),
  'ME-88478-view-imdl-phase-2': make({
    description: 'This will show/hide IMDL phase-2 functionalities',
    initialTeam: 'alexandria',
  }),
  'ME-52933-feat-use-plan-loadV2': make({
    description: 'Migrate to planLoadV2',
    initialTeam: 'core-load',
  }),
  'ME-45197-feat-capacity-manager-gate-reservations': make({
    description: 'Enable Gate Reservations on the Capacity Manager screen',
    initialTeam: 'authority',
  }),
  'ME-55930-feat-capacity-manager-containers': make({
    description: 'Enable Containers on the Capacity Manager screen',
    initialTeam: 'authority',
  }),
  'ME-64579-view-RouteBoard-Hover-ToolTip-changes': make({
    description:
      'This shows changes in Hover Tool tips of different RouteBoard RCLS tab',
    initialTeam: 'susa',
  }),
  'ME-45202-feat-disable-fuel-validation-for-invoicing': make({
    description: 'This will enable fuel validation for processing invoices',
    initialTeam: 'salt-route',
  }),
  'ME-61741-view-slack-share': make({
    description:
      'This feature enables/disables the option to view slack share in the Route,Truck or Match Kebobs.',
    initialTeam: 'dhrona',
  }),
  'ME-61741-view-slack-employee-tree': make({
    description:
      'This feature enables/disables the option to view slack employee tree.',
    initialTeam: 'dhrona',
  }),
  'ME-56701-view-old-truck-entry': make({
    description: 'This will use the old truck entry UI when set to true',
    initialTeam: 'authority',
    defaults: {
      onVariation: 1,
      offVariation: 1,
    },
  }),
  'ME-57388-feat-automated-tendering-enhancements': make({
    description: 'A set of enhancements to Automated Tendering',
    initialTeam: 'aardwolf',
  }),
  'ME-61423-view-carrier-general-website-field': make({
    description:
      'Enables view of website field in carrier general information.',
    initialTeam: 'memphis',
  }),
  'ME-45916-feat-connect-to-new-mileage-service': make({
    description:
      'Enables calls to new mileage service in rate and cost line item modals',
    initialTeam: 'flatbed',
  }),
  'ME-37245-view-allow-multiple-types-on-facility-address': make({
    description:
      'Allows to select multiple types within address modals of facility',
    initialTeam: 'argos',
  }),
  'ME-52592-feat-cost-line-items-tooltip': make({
    description:
      'Enables tooltip which shows breakdown of tender cost line items in tooltip on carrier cost',
    initialTeam: 'aardwolf',
  }),
  'ME-62594-view-gate-reservations-resold-imdl': make({
    description:
      'Enables the user to view and select the gate reservation data and configuration dropdowns',
    initialTeam: 'spice-route',
  }),
  'ME-62594-view-containers-resold-imdl': make({
    description:
      'Enables the user to view and select the containers data and configuration dropdowns',
    initialTeam: 'spice-route',
  }),
  'ME-62597-view-resold-imdl': make({
    description: 'Enables the user to view new imdl related fields',
    initialTeam: 'spice-route',
    defaults: {
      onVariation: 1,
      offVariation: 1,
    },
  }),
  'ME-48993-view-ctc-allowed-by-customer-column': make({
    description:
      'Shows or hides the Allowed by Customer column on CTC config tab',
    initialTeam: 'flatbed',
  }),
  'ME-67013-feat-vouchering-enable-processfinancials-dropdown': make({
    description: 'Enables or disables a dropdown on the auto voucher modal',
    initialTeam: 'flatbed',
  }),
  'ME-64246-feat-load-city-state-stop': make({
    description: 'This will allow users to create a city/state stop on a load',
    initialTeam: 'core-load',
  }),
  'ME-60304-view-vendor-route-financial-status-reason-codes-vendor-settlement':
    make({
      description:
        'This will show vendor route financial status reason codes column in vendor settlement queue grid. ',
      initialTeam: 'salt-route',
    }),
  'ME-68766-feat-aardvark-records-v2-enabled': make({
    description: 'This enables RecordsV2 in the route drawer',
    initialTeam: 'aardvark',
    defaults: {
      onVariation: 1,
      offVariation: 1,
    },
  }),
  'ME-65672-view-accounting-status': make({
    description:
      'This will allow users to view Accounting Status on settlement queue',
    initialTeam: 'salt-route',
  }),
  'ME-66922-feat-lane-multi-stop': make({
    description: 'This will use the new multi stop UI when set to true',
    initialTeam: 'babylon',
  }),
  'ME-65797-view-project-page': make({
    description: 'Shows or hides project page',
    initialTeam: 'rome',
  }),
  'ME-60398-feat-bookie-expected-ready-validations': make({
    description: 'This enables CANVAS expected ready validations',
    initialTeam: 'bookie',
  }),
  'ME-24656-view-change-customer': make({
    description: 'This will enable change customer kebab option',
    initialTeam: 'amber-route',
  }),
  'ME-88164-view-allow-pu-del-as-execution-stop': make({
    description:
      'This will allow user to check or uncheck PU/DEL order stop from order stop model',
    initialTeam: 'amber-route',
  }),
  'ME-64689-view-carrier-project-businessunit-division-work': make({
    description: 'Shows or hides project businessunit division work in carrier',
    initialTeam: 'rome',
  }),
  'ME-64689-view-customer-project-businessunit-division-work': make({
    description:
      'Shows or hides project businessunit division work in customer',
    initialTeam: 'rome',
  }),
  'ME-67291-view-facility-project-businessunit-division-work': make({
    description:
      'Shows or hides project businessunit division work in facility',
    initialTeam: 'rome',
  }),
  'ME-69630-view-tender-tab-in-route-board': make({
    description: 'Enables tender tab on the route board',
    initialTeam: 'susa',
  }),
  'ME-69719-view-pre-tender-tab-in-routeboard': make({
    description: 'Enables pre-tender tab on the main page route board',
    initialTeam: 'susa',
  }),
  'ME-60594-view-covered-tab-show-railbill-dt-tm-column': make({
    description: 'Enables us to show the railbill date time column',
    initialTeam: 'incense-route',
  }),
  'ME-53788-feat-refresh-ddt-minionrefreshevent': make({
    description:
      'Enables refetching of all data dictionary terms (DDT) on a Event.MinionRefreshEvent',
    initialTeam: 'atlas',
  }),
  'ME-70314-feat-commodity-modal-enhancements': make({
    description: 'Enables piece type and stacking options for commodities',
    initialTeam: 'core-load',
  }),
  'ME-41503-feat-shipment-mvp': make({
    description: 'Enables basic shipment management',
    initialTeam: 'core-load',
  }),
  'ME-62401-feat-use-electronic-trackings': make({
    description:
      'Use carrierV2 electronic trackings (multiple) field instead of single electronic tracking type/method',
    initialTeam: 'artemis',
    defaults: {
      onVariation: 1,
      offVariation: 1,
    },
  }),
  'ME-68029-view-add-commodities-spot-quote': make({
    description:
      'Enables add commodity modal in the Customer Spot Quote screen',
    initialTeam: 'long-haul',
  }),
  'ME-76615-fix-debounce-tamed-inputs': make({
    description: 'Use debounce instead of throttle for tamed inputs',
    initialTeam: 'atlas',
    defaults: {
      onVariation: 0,
      offVariation: 0,
    },
  }),
  'ME-68430-feat-carrier-capacity': make({
    description:
      'Allow user to enter/edit carrier capacity on cost quote screen',
    initialTeam: 'aardwolf',
  }),
  'ME-75438-view-find-driver-find-trailer-from-load-procureTab': make({
    description:
      'This will hide/show Find Driver and Find Trailer tabs in Load->Procure tab',
    initialTeam: 'susa',
  }),
  'ME-75348-feat-chipfiltergroup': make({
    description: 'Enable chip filters, starting with Main Page Route Board',
    initialTeam: 'atlas',
  }),
  'ME-69768-view-carrier-record-release-method': make({
    description:
      'Enables view of release method in carrier general information.',
    initialTeam: 'memphis',
  }),
  'ME-62618-app-invoke-availableRoute-query': make({
    description:
      'When enabled, this will invoke routev2 query in main page capacity tab',
    initialTeam: 'spice-route',
  }),
  'ME-65696-view-tender-contact-email-require': make({
    description: 'Require Email Address for Tender Contact Type',
    initialTeam: 'tin-route',
  }),
  'ME-73860-view-customer-commitment-surge': deprecatedCreate({
    description:
      'This will hide/show surge fields on Customer Commitment Settings and New Rate Quote modal',
  }),
  'ME-73254-view-dashboard-tab-main-page-for-customer-rep': make({
    description: 'This will view dashboard tab on main page for customer rep',
    initialTeam: 'seawise-gaint',
  }),
  'ME-72308-feat-use-geographies-api-for-autocomplete': make({
    description:
      'When on, autocomplete feature using geonames will point to internal geographies-api',
    initialTeam: 'purple-rain',
  }),
  'ME-63935-view-vendor-invoice-missing-checkbox': make({
    description:
      'This will show vendor invoice missing checkbox on accounting->vendor settlement',
    initialTeam: 'sparta',
  }),
  'ME-83313-view-rate-quote-customer-record': make({
    description: 'This will show/hide Rate Quote tab in Customer',
    initialTeam: 'long-haul',
  }),
  'ME-68339-feat-Booking-validation-tooltip-component-for-Procure-Tab': make({
    description:
      'This will display and run the Booking Validation ToolTip component for the Procure Tab.',
    initialTeam: 'bookie',
  }),
  'ME-82913-view-new-simplified-booking-page': make({
    description: 'This will show the new simplified booking page',
    initialTeam: 'bookie',
  }),
  'ME-84078-view-simplified-bookings-route-drawer': make({
    description: 'Enables the user to new simplified booking route drawer',
    initialTeam: 'varna',
    defaults: {
      onVariation: 1,
      offVariation: 1,
    },
  }),
  'ME-88898-feat-simplified-bookings-kebabs-on-capacity-manager': make({
    description:
      'Enables kebabs on capacity-manager to be linked with new simplified booking page',
    initialTeam: 'varna',
    defaults: {
      onVariation: 1,
      offVariation: 1,
    },
  }),
  'ME-88901-feat-simplified-bookings-kebabs-on-offers-tab': make({
    description:
      'Make the kebabs in the Offers tab link to the new simplified booking page.',
    initialTeam: 'varna',
    defaults: {
      onVariation: 1,
      offVariation: 1,
    },
  }),
  'ME-86013-feat-simplified-bookings-kebabs-on-procure-tab': make({
    description:
      'Enables kebabs on procure-tab to be linked with new simplified bookings page',
    initialTeam: 'varna',
    defaults: {
      onVariation: 1,
      offVariation: 1,
    },
  }),
  'ME-85141-view-commitments-columns-on-route-board-pending-tab': make({
    description:
      'Show/hide the commitments columns on Main Page > Route Board > Pending tab',
    initialTeam: 'byblos',
  }),
  'ME-85144-view-commitments-columns-on-customer-operations-pending-tab': make({
    description:
      'Show/hide the commitments columns on Customer > Operations > Pending tab',
    initialTeam: 'byblos',
  }),
  'ME-85430-view-hide-front-page-offers': make({
    description: 'Hides the main page offers tab',
    initialTeam: 'core-load',
  }),
  'ME-85427-view-offers-tab-not-default': make({
    description: 'opens the capacity tab first on the main page',
    initialTeam: 'core-load',
  }),
  'ME-78147-view-split-receiving-facility-schedules': make({
    description: 'Enables split receiving for facility schedules',
    initialTeam: 'damascus',
  }),
  'ME-85177-view-facility-schedule-temperature': make({
    description: 'Enables temperature field for facility schedules',
    initialTeam: 'damascus',
  }),
  'ME-84102-view-enable-multiselect-employee-division-and-businessunit': make({
    description:
      'This will enable to multiselect Divisions and Businessunits for employee under company information card',
    initialTeam: 'rome',
  }),
  'ME-58519-view-disable-equip-region-filters-on-pending-incomplete': make({
    description:
      'Disables the equipment and regions filters on the pending and incomplete tab on main page',
    initialTeam: 'susa',
  }),
  'ME-67283-view-saved-filter-dropdown-field': make({
    description:
      'This will show saved filter dropdown field using which one can save applied filters & can use later on routeboard page',
    initialTeam: 'incense-route',
  }),
  'ME-86368-view-hide-change-customer-and-facility-for-edi': make({
    description:
      'Enables or disables change customer/facility feature for edi loads or customer',
    initialTeam: 'amber-route',
  }),
  'ME-79207-view-project-business-unit-for-accounting': make({
    description: 'Enables or Disables project and business unit dropdown',
    initialTeam: 'salt-route',
  }),
  'ME-86080-feat-using-single-query-for-mprb-counts-and-rlc': make({
    description:
      'Using one single query instead of multiple queries for counts of MPRB and RLC',
    initialTeam: 'incense-route',
  }),
  'ME-86095-feat-seer-mprb-details-query': make({
    description:
      'Toggles Main Page Route Board to use the seerSearchMainPageRouteBoardDetails query',
    initialTeam: 'incense-route',
  }),
  'ME-81024-feat-deactivate-lane': make({
    description:
      'This will show an active checkbox and deactivate/reactivate lane option to the user on customer/carrier lane table',
    initialTeam: 'vibranium',
  }),
  'ME-88001-view-employee-search-screen-enhancements': make({
    description: 'Enables or disables employee search screen enhancements ',
    initialTeam: 'rome',
  }),
  'ME-86499-view-spot-quotes-tab-customer-planning-lanes': make({
    description:
      'Enables Spot Quote tab in Rate Quote Tabs Tables in Customer>Lanes and Planning>Lanes',
    initialTeam: 'long-haul',
  }),
  'ME-79243-feat-rate-quotes-import-button': make({
    description:
      'Controls whether to display upload button of rate quote from global and customer lane pages',
    initialTeam: 'hercules',
  }),
  'ME-88589-feat-rate-quotes-export-button': make({
    description:
      'Controls whether to display download/export button of rate quote from global and customer lane pages',
    initialTeam: 'hercules',
  }),
  'ME-90034-view-carrier-relationships-table-enhancements': make({
    description:
      'Enables or disables Carrier Relationships table enhacements on Carrier General tab',
    initialTeam: 'argos',
  }),
  'ME-90035-view-customer-relationships-table-enhancements': make({
    description: 'Enables or disables Customer Relationships table enhacements',
    initialTeam: 'argos',
  }),
  'ME-89139-view-carrier-customer-accessorial-pages': make({
    description:
      'Enables the Accessorial page on both Customer and Carrier Accounting pages',
    initialTeam: 'kingpin',
  }),
  'ME-92845-view-facility-schedule-dropdown-multiselect': make({
    description: 'This will enable multiselect dropdown for facility schedules',
    initialTeam: 'damascus',
  }),
  'ME-91969-view-orders-associated-to-lane': make({
    description: 'This will show Orders tab in Customer and Global Planning',
    initialTeam: 'beskar',
  }),
  'ME-91969-view-routes-associated-to-lane': make({
    description: 'This will show Routes tab in Customer and Global Planning',
    initialTeam: 'beskar',
  }),
} as const;

type Flavor = 'view' | 'feat' | 'fix' | 'app';

type BoolFlagNamingSchema = `ME-${number}-${Flavor}-${string}`;

type Unit = 'hours' | 'minutes' | 'seconds' | 'ms' | 'number';
type NumberFlagNamingSchema = `ME-${number}-${Flavor}-${string}-${Unit}`;

export type BoolFlagKeys =
  | keyof typeof deprecatedRawBooleanFlags
  | keyof typeof rawBooleanFlags;

const throwErrOnMissingCreate = (obj: anyOk): void => {
  Object.entries(obj).forEach(([k, v]) => {
    if (!(v as fixMe).__wrapped) {
      throw new Error(`Flag of ${k} is not wrapped in "make" function.`);
    }
  });
};

/** For number flags, we default all environment targeting to the first variation, unless you set the defaults key. */
export const rawNumberFlags = {
  // Example of flag adhering to new name schema below:
  // 'ME-100-app-keycloak-min-validity-minutes': deprecatedCreate({
  //   temporary: false,
  //   description: 'Min validity for keycloak updateToken() command',
  //   variations: [
  //     { value: 5, name: 'default' },
  //     { value: 30, name: 'long' },
  //   ],
  // }),
  'ME-11793-app-portfolio-pagination-number': deprecatedCreate({
    temporary: false,
    description: 'Pagination limit for portfolio page ',
    variations: [
      { value: 100, name: 'default' },
      { value: 50, name: 'low' },
      { value: 150, name: 'high' },
    ],
  }),
  'ME-22735-fix-facility-picker-query-limit-number': deprecatedCreate({
    description:
      'The number of results to request from the API when using the facility picker. When the value of this flag is > 0, the picker will also use client-side filtering of the results for better UX. A value of 0 will revert to a default of 10 with no client-side filtering.',
    variations: [
      { value: 200, name: 'default' },
      { value: 50, name: 'low' },
      { value: 0, name: 'off' },
    ],
  }),
  'ME-31432-app-main-page-route-board-polling-ms': deprecatedCreate({
    temporary: false,
    description:
      'Timeout in ms for main page route board polling or auto-refresh',
    variations: [
      { value: 18000, name: 'default' },
      { value: 30000, name: 'long' },
    ],
  }),
  'ME-44282-view-record-management-tracking-polling-ms': deprecatedCreate({
    temporary: false,
    description: 'Timeout in ms for main page tracking polling or auto-refresh',
    variations: [
      { value: 18000, name: 'default' },
      { value: 30000, name: 'long' },
    ],
  }),
  'ME-31132-view-main-page-route-board-pagination-size-number':
    deprecatedCreate({
      temporary: false,
      description:
        'Size of tracking updates fetched for main page route board query',
      variations: [
        { value: 400, name: 'default' },
        { value: 200, name: 'old small' },
      ],
    }),
  'ME-30981-view-track-page-pagination-size-number': deprecatedCreate({
    temporary: false,
    description:
      'Size of tracking updates fetched for track page tracking updates query',
    variations: [
      { value: 400, name: 'default' },
      { value: 200, name: 'old small' },
    ],
  }),
  'ME-31924-view-load-search-pagination-size-number': deprecatedCreate({
    temporary: false,
    description:
      'Size of items fetched for Customer orders, Carrier routes, and Load search',
    variations: [
      { value: 400, name: 'default' },
      { value: 50, name: 'old small' },
    ],
  }),
  'ME-35600-feat-masterfind-debounce-ms': deprecatedCreate({
    temporary: false,
    description: 'Controls query fire wait time of masterfind after keystroke',
    variations: [
      { value: 400, name: 'default' },
      { value: 1000, name: 'long' },
    ],
  }),
  'ME-54280-app-table-auto-virtualize-above-row-number': deprecatedCreate({
    temporary: false,
    description:
      'Trigger row virtualization above N number of data rows. Set to 0 to completely turn off.',
    variations: [
      { value: 50, name: 'default' },
      { value: 200, name: 'large' },
      { value: 0, name: 'off' },
    ],
  }),
} as const;

export type NumberFlagKeys =
  | keyof typeof deprecatedRawNumberFlags
  | keyof typeof rawNumberFlags;

if (MODE !== 'production') {
  [
    deprecatedRawBooleanFlags,
    rawBooleanFlags,
    deprecatedRawNumberFlags,
    rawNumberFlags,
  ].forEach(throwErrOnMissingCreate);
}

const boolFlagKeys: BoolFlagNamingSchema[] = keys(rawBooleanFlags);

const numberFlagKeys: NumberFlagNamingSchema[] = keys(rawNumberFlags);

const stringFlags = {
  'ME-32857-app-masterfind-regex': 1,
};

const stringFlagKeys: BoolFlagNamingSchema[] = keys(stringFlags);

export type StringFlagKeys = keyof typeof stringFlags;

/* eslint-disable @typescript-eslint/no-unused-vars */
const noop = (...args: anyOk[]): void => {
  //
};

// Below is a type check trigger to enforce good naming practices for our flags. Do not remove.
noop(boolFlagKeys && numberFlagKeys && stringFlagKeys);

export const allFlags = [
  ...boolFlagKeys,
  ...stringFlagKeys,
  ...numberFlagKeys,
  ...keys(deprecatedRawBooleanFlags),
  ...keys(deprecatedRawNumberFlags),
  ...jsonFlagArr,
];
