export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type Query = {
  readonly __typename?: 'Query';
  readonly health: Scalars['Boolean'];
};

export type Subscription = {
  readonly __typename?: 'Subscription';
  readonly subscribe: Events;
  readonly subscribeForAslRoutes: AslRouteChangedEvent;
  readonly subscribeForTasks: TaskEvent;
  readonly subscribeForClientExceptionRecords: ClientExceptionRecordFilteredEvent;
};


export type SubscriptionSubscribeArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  types?: InputMaybe<ReadonlyArray<EventType>>;
};


export type SubscriptionSubscribeForAslRoutesArgs = {
  routeOrLoadIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  routeLifeCycleStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  inboundRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  outboundRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  trailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  transportModes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  repIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
};


export type SubscriptionSubscribeForTasksArgs = {
  idsToOverrideFilter?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  name?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  type?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  note?: InputMaybe<Scalars['String']>;
  neededBy?: InputMaybe<TaskEventsDateRangeInput>;
  closedAt?: InputMaybe<TaskEventsDateRangeInput>;
  requestedAt?: InputMaybe<TaskEventsDateRangeInput>;
  loadId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  routeId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  requestedBy?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  assignedTo?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  status?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  loadCode?: InputMaybe<Scalars['String']>;
  routeCode?: InputMaybe<Scalars['String']>;
  showClosed?: InputMaybe<Scalars['Boolean']>;
  assignedToGroupId?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  externalId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};


export type SubscriptionSubscribeForClientExceptionRecordsArgs = {
  idsToOverrideFilter?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  types?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  statuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  sourceTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  sourceCode?: InputMaybe<Scalars['String']>;
  metadataIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  assignedToIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  assignedToGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type Events = BaseEvent | RouteLockingEvent | LinkedRouteEvent | LoadStatusEvent | RouteStatusEvent | TaskEvent | TasksByLoadIdEvent | TrackingStatusEvent | DriverAssignmentEvent | SubStopExecutionEvent | TruckPostingMatchEvent | CarrierMatchEvent | UserMatchEvent | RouteMatchEvent | RouteVendorEvent | CommissionsRecalculatedEvent | TenderPlanEvent | TenderPlanRouteEvent | TrackingUpdateEvent | ToastEvent | OfferEvent | RouteMaxCostEvent | CostLineItemEvent | RateLineItemEvent | PricingRateEvent | MinionRefreshEvent | ClientExceptionRecordEvent | ClientExceptionRecordFilteredEvent | IncidentEvent | MessagingNotificationStatusEvent;

export type BaseEvent = {
  readonly __typename?: 'BaseEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
};

/** Events that are available from this service for realtime notifications */
export enum EventType {
  Unknown = 'Unknown',
  RouteLockingEvent = 'RouteLockingEvent',
  LinkedRouteEvent = 'LinkedRouteEvent',
  LoadStatusEvent = 'LoadStatusEvent',
  RouteStatusEvent = 'RouteStatusEvent',
  TrackingStatusEvent = 'TrackingStatusEvent',
  DriverAssignmentEvent = 'DriverAssignmentEvent',
  SubStopExecutionEvent = 'SubStopExecutionEvent',
  TaskEvent = 'TaskEvent',
  TasksByLoadIdEvent = 'TasksByLoadIdEvent',
  TruckPostingMatchEvent = 'TruckPostingMatchEvent',
  CarrierMatchEvent = 'CarrierMatchEvent',
  UserMatchEvent = 'UserMatchEvent',
  RouteMatchEvent = 'RouteMatchEvent',
  RouteVendorEvent = 'RouteVendorEvent',
  CommissionsRecalculatedEvent = 'CommissionsRecalculatedEvent',
  TenderPlanEvent = 'TenderPlanEvent',
  TenderPlanRouteEvent = 'TenderPlanRouteEvent',
  TrackingUpdateEvent = 'TrackingUpdateEvent',
  ToastEvent = 'ToastEvent',
  OfferEvent = 'OfferEvent',
  RouteMaxCostEvent = 'RouteMaxCostEvent',
  CostLineItemEvent = 'CostLineItemEvent',
  RateLineItemEvent = 'RateLineItemEvent',
  PricingRateEvent = 'PricingRateEvent',
  MinionRefreshEvent = 'MinionRefreshEvent',
  ClientExceptionRecordEvent = 'ClientExceptionRecordEvent',
  ClientExceptionRecordFilteredEvent = 'ClientExceptionRecordFilteredEvent',
  IncidentEvent = 'IncidentEvent',
  MessagingNotificationStatusEvent = 'MessagingNotificationStatusEvent'
}

export type RouteLockingEvent = {
  readonly __typename?: 'RouteLockingEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly expirationTimestamp?: Maybe<Scalars['DateTime']>;
  readonly userIdentity?: Maybe<Scalars['String']>;
  readonly adminIdentity?: Maybe<Scalars['String']>;
  readonly lockEventType: LockEventType;
};

export enum LockEventType {
  LockAcquired = 'lockAcquired',
  LockReleased = 'lockReleased',
  LockRefreshed = 'lockRefreshed',
  LockExpired = 'lockExpired',
  LockOverridden = 'lockOverridden'
}

export type LinkedRouteEvent = {
  readonly __typename?: 'LinkedRouteEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly linkedRouteID: Scalars['ID'];
  readonly linkType: LinkedRouteLinkType;
};

export enum LinkedRouteLinkType {
  Previous = 'Previous',
  Next = 'Next'
}

export type LoadStatusEvent = {
  readonly __typename?: 'LoadStatusEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
};

export type RouteStatusEvent = {
  readonly __typename?: 'RouteStatusEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
};

export type TaskEvent = {
  readonly __typename?: 'TaskEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly taskEventType: TaskEventType;
  readonly details?: Maybe<TaskEventDetails>;
};

export enum TaskEventType {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete'
}

export type TaskEventDetails = {
  readonly __typename?: 'TaskEventDetails';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly type: Scalars['String'];
  readonly status: Scalars['String'];
  readonly routeId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly requestedAt: Scalars['DateTime'];
  readonly requestedBy: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly neededBy?: Maybe<Scalars['DateTime']>;
  readonly assignedTo: Scalars['String'];
  readonly closedAt?: Maybe<Scalars['DateTime']>;
  readonly latestNote?: Maybe<Scalars['String']>;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly assignedToGroupId?: Maybe<Scalars['String']>;
  readonly groupId?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly comment?: Maybe<Scalars['String']>;
};

export type TasksByLoadIdEvent = {
  readonly __typename?: 'TasksByLoadIdEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly taskID: Scalars['ID'];
  readonly taskEventType: TaskEventType;
};

export type TrackingStatusEvent = {
  readonly __typename?: 'TrackingStatusEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly trackingStatus: TrackingStatus;
};

/** Current state of electronic tracking for a Load */
export enum TrackingStatus {
  AppInstallRequired = 'AppInstallRequired',
  CompletedSuccessfully = 'CompletedSuccessfully',
  DeniedByDriver = 'DeniedByDriver',
  ExpiredRequest = 'ExpiredRequest',
  HiddenByDriver = 'HiddenByDriver',
  InvalidMobile = 'InvalidMobile',
  InvalidTractorNumber = 'InvalidTractorNumber',
  ReadyToTrack = 'ReadyToTrack',
  StoppedByTms = 'StoppedByTMS',
  TrackingNow = 'TrackingNow',
  WaitingForUpdate = 'WaitingForUpdate'
}

export type DriverAssignmentEvent = {
  readonly __typename?: 'DriverAssignmentEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly driverAssignmentID: Scalars['ID'];
  readonly eventTimestamp: Scalars['DateTime'];
};

export type SubStopExecutionEvent = {
  readonly __typename?: 'SubStopExecutionEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly stopID: Scalars['ID'];
  readonly stopEventIDs: ReadonlyArray<Scalars['String']>;
  readonly stopEventTypeID: Scalars['String'];
  readonly actionType: StopEventActionType;
};

export enum StopEventActionType {
  Created = 'Created',
  Updated = 'Updated',
  Deleted = 'Deleted'
}

export type TruckPostingMatchEvent = {
  readonly __typename?: 'TruckPostingMatchEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
};

export type CarrierMatchEvent = {
  readonly __typename?: 'CarrierMatchEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
};

export type UserMatchEvent = {
  readonly __typename?: 'UserMatchEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
};

export type RouteMatchEvent = {
  readonly __typename?: 'RouteMatchEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
};

export type RouteVendorEvent = {
  readonly __typename?: 'RouteVendorEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly loadId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
};

export type CommissionsRecalculatedEvent = {
  readonly __typename?: 'CommissionsRecalculatedEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly loadId: Scalars['ID'];
};

export type TenderPlanEvent = {
  readonly __typename?: 'TenderPlanEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
};

export type TenderPlanRouteEvent = {
  readonly __typename?: 'TenderPlanRouteEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
};

export type TrackingUpdateEvent = {
  readonly __typename?: 'TrackingUpdateEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly trackingUpdateEventType: TrackingUpdateEventType;
  readonly trackingUpdateId: Scalars['String'];
};

export enum TrackingUpdateEventType {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete'
}

export type ToastEvent = {
  readonly __typename?: 'ToastEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly toastType: ToastType;
  readonly message: Scalars['String'];
};

export enum ToastType {
  Error = 'Error',
  Info = 'Info',
  Success = 'Success'
}

export type OfferEvent = {
  readonly __typename?: 'OfferEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly offerId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
};

export type RouteMaxCostEvent = {
  readonly __typename?: 'RouteMaxCostEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly routeMaxCost?: Maybe<Scalars['Float']>;
};

export type CostLineItemEvent = {
  readonly __typename?: 'CostLineItemEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly costLineItemId: Scalars['String'];
  readonly action: Scalars['String'];
};

export type RateLineItemEvent = {
  readonly __typename?: 'RateLineItemEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly rateLineItemId: Scalars['String'];
  readonly action: Scalars['String'];
};

export type PricingRateEvent = {
  readonly __typename?: 'PricingRateEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly rateType?: Maybe<Scalars['String']>;
};

export type MinionRefreshEvent = {
  readonly __typename?: 'MinionRefreshEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly message: Scalars['String'];
};

export type ClientExceptionRecordEvent = {
  readonly __typename?: 'ClientExceptionRecordEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly clientExceptionRecordId: Scalars['String'];
  readonly clientExceptionRecordEventType: ClientExceptionRecordEventType;
};

export enum ClientExceptionRecordEventType {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete'
}

export type ClientExceptionRecordFilteredEvent = {
  readonly __typename?: 'ClientExceptionRecordFilteredEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly clientExceptionRecordEventType: ClientExceptionRecordEventType;
  readonly details?: Maybe<ClientExceptionRecordFilteredEventDetails>;
};

export type ClientExceptionRecordFilteredEventDetails = {
  readonly __typename?: 'ClientExceptionRecordFilteredEventDetails';
  readonly id: Scalars['String'];
  readonly type: Scalars['String'];
  readonly status: Scalars['String'];
  readonly sourceID: Scalars['String'];
  readonly sourceType: Scalars['String'];
  readonly sourceCode?: Maybe<Scalars['String']>;
  readonly metadataIDs?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedToIDs?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedToGroups?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type IncidentEvent = {
  readonly __typename?: 'IncidentEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly incidentEventType: IncidentEventType;
  readonly incidentID: Scalars['ID'];
};

export enum IncidentEventType {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete'
}

export type MessagingNotificationStatusEvent = {
  readonly __typename?: 'MessagingNotificationStatusEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly messageId: Scalars['String'];
  readonly status: MessagingNotificationStatus;
  readonly serverType: MessagingNotificationServerType;
  readonly errorMessage: Scalars['String'];
};

export enum MessagingNotificationStatus {
  Success = 'Success',
  Error = 'Error'
}

export enum MessagingNotificationServerType {
  Slack = 'Slack',
  MsTeams = 'MSTeams'
}

export type AslRouteChangedEvent = {
  readonly __typename?: 'AslRouteChangedEvent';
  readonly scopes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly routeId: Scalars['ID'];
  readonly loadId?: Maybe<Scalars['String']>;
  readonly inboundSuperRegions?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly inboundRegions?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly inboundSubRegions?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundSuperRegions?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundRegions?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundSubRegions?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly trailerTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly transportMode?: Maybe<Scalars['String']>;
  readonly orderRepIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly routeRepIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
};

export type TaskEventsDateRangeInput = {
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
};
