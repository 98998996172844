import { Icon } from '@components/Icon';
import { CSSObject } from '@emotion/react';
import { EmployeeInfoFragment } from '@generated/fragments/employeeInfo';
import { EmployeeInfoV2Fragment } from '@generated/fragments/employeeInfoV2';
import { EmployeeSimpleFragment } from '@generated/fragments/employeeSimple';
import { EmployeeSimpleV2Fragment } from '@generated/fragments/employeeSimpleV2';
import { useTheme } from '@hooks/useTheme';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@reach/tabs';
import '@reach/tabs/styles.css';
import { FC } from 'react';
import { EmployeeTree } from '../EmployeeTree';
import { GroupTree } from '../GroupTree';
import { List } from '../List';
import { SearchType } from '../Search/types';
import {
  EmployeeTreeNodeValue,
  EMPLOYEE_ENTITY_TABS_HEIGHT,
  SIDEBAR_BORDER_WIDTH,
  SIDEBAR_OPEN_WIDTH,
} from '../util';

const tabPanelStyle: CSSObject = { height: '100%', overflowY: 'hidden' };
interface Props {
  employee: EmployeeSimpleFragment | EmployeeSimpleV2Fragment;
  isSidebarOpen: boolean;
  resetRepFilter: () => void;
  updateEmployeeTreeNodeValue: (emp: EmployeeTreeNodeValue) => void;
  searchType: SearchType;
}

export const EmployeeEntityTabs: FC<Props> = ({
  employee,
  isSidebarOpen,
  resetRepFilter,
  updateEmployeeTreeNodeValue,
  searchType,
}) => {
  const { card, colors, gray } = useTheme();

  return (
    <Tabs
      css={{
        bottom: 1,
        position: 'relative',
        display: 'grid',
        gridTemplateRows: '1fr min-content',
        height: '100%',
        overflow: 'hidden',
      }}
      data-testid="entity-tabs-wrapper"
    >
      <TabPanels
        css={{
          padding: '0 0 1rem 1rem',
          height: '100%',
          overflowY: 'hidden',
        }}
      >
        <TabPanel
          css={{
            height: '100%',
            overflowY: 'auto',
            overflowX: 'auto',
          }}
        >
          {searchType == SearchType.rep && employee ? (
            <EmployeeTree
              updateEmployeeTreeNodeValue={updateEmployeeTreeNodeValue}
              employee={
                employee as EmployeeInfoFragment | EmployeeInfoV2Fragment
              }
            />
          ) : (
            ''
          )}
          {searchType == SearchType.group ? (
            <GroupTree
              updateEmployeeTreeNodeValue={updateEmployeeTreeNodeValue}
            />
          ) : (
            ''
          )}
        </TabPanel>
        <TabPanel css={tabPanelStyle}>
          <List
            key="carrier"
            searchType={SearchType.carrier}
            employee={employee}
            resetRepFilter={resetRepFilter}
          />
        </TabPanel>
        <TabPanel css={tabPanelStyle}>
          <List
            key="customer"
            searchType={SearchType.customer}
            employee={employee}
            resetRepFilter={resetRepFilter}
          />
        </TabPanel>
      </TabPanels>
      <TabList
        data-testid="sidebar-bottom-icons"
        css={{
          width: isSidebarOpen ? SIDEBAR_OPEN_WIDTH - SIDEBAR_BORDER_WIDTH : 0,
          transform: 'width 250ms',
          display: 'grid',
          gridTemplate: `auto / repeat(3, auto)`,
          background: card.background,
          borderTop: `1px solid ${gray[400]}`,
          height: EMPLOYEE_ENTITY_TABS_HEIGHT,
          '> *': {
            whiteSpace: 'nowrap',
            padding: '1rem',
            display: isSidebarOpen ? 'flex' : 'none',
            textDecoration: 'none',
            alignItems: 'center',
            justifyContent: 'center',

            '&[data-selected], &.active': {
              color: colors.primary,
            },
          },
          '[disabled]': {
            color: gray[400],
            cursor: 'default',
          },
        }}
      >
        <Tab data-testid="sidebar-people-tab" data-walkme="sidebar-users">
          <Icon i="usersLight" size={21} />
        </Tab>
        <Tab data-testid="sidebar-carriers-tab" data-walkme="sidebar-carriers">
          <Icon i="truckMovingLight" size={21} />
        </Tab>
        <Tab
          data-testid="sidebar-customers-tab"
          data-walkme="sidebar-customers"
        >
          <Icon i="truckLoading" size={21} />
        </Tab>
      </TabList>
    </Tabs>
  );
};
