import { useKeycloakAuthContext } from '@components/AuthContext';
import {
  devDomains,
  IS_CYPRESS,
  IS_NOT_PREVIEW_OR_PROD,
  IS_PR_PREVIEW,
  KnownDomains,
  USE_LD,
} from '@utils/constants';
import { jsonParse } from '@utils/json';
import { HAS_WINDOW, win } from '@utils/win';
import { useFlags as ldUseFlags } from 'launchdarkly-react-client-sdk';
import {
  fromPairs,
  isBoolean,
  isNil,
  isObjectLike,
  isUndefined,
  noop,
  pick,
  set,
} from 'lodash-es';
import { parse } from 'query-string';
import { FC, ReactNode } from 'react';
import { JsonObject } from 'type-fest';
import { configAliases, RESOLVED_TENANT_HOSTNAME } from '../../config';
import { BoolFlagKeys, moduleCheck } from './data';
import {
  flagDefaultsForLocalDev,
  FlagsObject,
  NamesWithoutJson,
} from './flags';
import { JsonFlagKeys } from './jsonFlags';

// Allows checking that flags are set up correctly, do not remove.
noop(moduleCheck);

type UseFlag = <T extends NamesWithoutJson>(
  name: T,
  defaultOverrides?: Partial<FlagsObject>,
  waitUntilUserIdentify?: boolean
) => FlagsObject[T];

type UseFlagJSONData = <T extends JsonFlagKeys>(
  name: T,
  defaultOverrides?: Partial<FlagsObject>,
  waitUntilUserIdentify?: boolean
) => FlagsObject[T];

type UseFlagsType = <T extends NamesWithoutJson>(
  name?: T,
  defaultOverrides?: Partial<FlagsObject>
) => FlagsObject;

const getQueryParamFlags = (rawFlags: FlagsObject): FlagsObject => {
  const newFlags = { ...rawFlags };
  // Allow users to set flags via the query string, but ONLY for test environments
  if (HAS_WINDOW && IS_NOT_PREVIEW_OR_PROD) {
    Object.entries(parse(win.location.search)).forEach(([key, val]) => {
      if (key.startsWith('flag-') || key.match(/^ME-\d/)) {
        const flagName = key.replace(/^flag-/, '') as keyof FlagsObject;
        const parsed = jsonParse(val as string, undefined, false);
        if (parsed !== undefined) {
          set(newFlags, flagName, parsed);
        }
      }
    });
    return newFlags;
  }
  return rawFlags;
};

const getCookieFlags = (): Partial<FlagsObject> => {
  if (!IS_CYPRESS) {
    return {};
  }
  return fromPairs(
    document.cookie
      .split('; ')
      .filter((str) => str.startsWith('flag-'))
      .map((str) => {
        const [key, val] = str.split('=');
        return [key?.substring(5), jsonParse(val as fixMe)];
      })
  ) as Partial<FlagsObject>;
};

export const useFlags: UseFlagsType = (name, defaultOverrides = {}) => {
  const rawFlags = ldUseFlags() as FlagsObject;
  if (!USE_LD && name) {
    if (name in defaultOverrides) {
      rawFlags[name] = (defaultOverrides as FlagsObject)[name];
    } else {
      const fromLocalDevMap = flagDefaultsForLocalDev[name];
      rawFlags[name] = (
        isUndefined(fromLocalDevMap) ? true : fromLocalDevMap
      ) as fixMe;
    }
  }

  const flags = {
    ...defaultOverrides,
    ...rawFlags,
    ...getQueryParamFlags(rawFlags),
    ...getCookieFlags(),
  };
  return flags as FlagsObject;
};

export const useFlag: UseFlag = (name, defaultOverrides = {}) => {
  return useFlags(name, defaultOverrides)[name];
};

export const useJSONFlagRawData: UseFlagJSONData = (
  name,
  defaultOverrides = {}
) => {
  return useFlags(name as anyOk, defaultOverrides)[name];
};

const findKeyUsingAlias = <D extends unknown>(
  flagData: Record<string, D>,
  originalKey: string,
  rawAliasMap: Record<string, string[]>
): D | undefined => {
  if (!isNil(flagData[originalKey])) {
    return flagData[originalKey];
  } else if (!isNil(flagData['*'])) {
    return flagData['*'];
  }
  const rawAliases = Object.entries(rawAliasMap).map(([k, v]) => v.concat(k));
  const aliases = rawAliases.find((arr) => arr.includes(originalKey)) ?? [
    originalKey,
  ];
  const picked = pick(flagData, aliases);
  return Object.values(picked)[0];
};

const jsonDomainAliases: Partial<Record<KnownDomains, KnownDomains[]>> = {
  ...configAliases,
  'test.mm100.mastermindtms.com': ['test.td100.mastermindtms.com'],
};

/** A more granular flag type, enabling flag differences across tenant domains. Expects a flag that is set up like:
 * ```
 * {
 *   "dev.mm100.mastermindtms.com": true,
 *   "mm.shipmolo.com": false
 * }
 * ```
 */
export const useFlagBasedOnJSONDomain = (name: JsonFlagKeys): boolean => {
  const flagData = useFlag(name as anyOk) as unknown as JsonObject;
  if (isObjectLike(flagData)) {
    let value = findKeyUsingAlias(
      flagData,
      RESOLVED_TENANT_HOSTNAME || '',
      jsonDomainAliases
    );
    if (isNil(value) && IS_PR_PREVIEW) {
      value = flagData[devDomains[0]];
    }
    return Boolean(value);
  } else if (!USE_LD && isBoolean(flagData)) {
    return flagData;
  }
  return false;
};

/** Waits for the user to be identified in LD, thus never receiving the "anonymous" flag default */
// ts-unused-exports:disable-next-line
export const useFlagWaitForIdentify = <T extends NamesWithoutJson>(
  name: T,
  defaultOverrides?: Partial<FlagsObject>
): FlagsObject[T] | null => {
  const { ldIdentified } = useKeycloakAuthContext();
  const flagVal = useFlag(name, defaultOverrides);
  // We need to return here if we do not use LD, like the case of Cypress tests.
  if (!USE_LD) {
    return flagVal;
  } else if (!ldIdentified) {
    return null;
  }
  return flagVal;
};

interface Props {
  /** A LaunchDarkly feature flag ID. Add to the TS definition if you are introducing a new one */
  name: BoolFlagKeys;
  children?: ReactNode;
  fallback?: ReactNode;
  defaults?: Partial<FlagsObject>;
}

/** See Flag/index.mdx for usage documentation */
export const Flag: FC<Props> = ({ fallback, children, name, defaults }) => {
  const flagEnabled = useFlag(name, defaults);
  if (flagEnabled) {
    return <>{children}</>;
  }
  return <>{fallback}</>;
};
