import { IdSlash, NoAuthRoute, ParameterizedRoutePath } from '@app/routes';
import { LazyRoutes, LazyRoutesArr, LoadReturn } from '@components/LazyRoutes';
import { keys } from '@utils/keys';
import { win } from '@utils/win';
import { FC } from 'react';

const AUTH_BYPASS_ROUTES_MAP: Record<
  NoAuthRoute<IdSlash>,
  NoAuthRoute<IdSlash>
> = {
  '/__debug': '/__debug',
  '/report': '/report',
  '/__debug/:id/': '/__debug/:id/',
} as const;

const AUTH_BYPASS_ROUTES = keys(AUTH_BYPASS_ROUTES_MAP);

export const routeCanBypassAuth = (): boolean =>
  AUTH_BYPASS_ROUTES.includes(win.location.pathname || ('' as fixMe));

const routes: LazyRoutesArr = [
  {
    path: '/matches',
    load: (): LoadReturn => import('../../views/MyMatches'),
  },
  {
    path: '/trucks',
    load: (): LoadReturn => import('../../views/Carrier/Capacity'),
  },
  {
    path: '/capacity',
    load: (): LoadReturn => import('../../views/Carrier/Capacity'),
  },
  {
    path: '/availableroutes',
    load: (): LoadReturn => import('../../views/AvailableRoutes/redirects'),
  },
  {
    path: '/available-routes',
    load: (): LoadReturn => import('../../views/AvailableRoutes'),
  },
  {
    path: '/booking/:id/',
    load: (): LoadReturn => import('../../views/Booking'),
  },
  {
    path: '/offers',
    load: (): LoadReturn => import('../../views/Offers'),
  },
  {
    path: '/map',
    load: (): LoadReturn => import('../../views/StateMap'),
  },
  {
    path: '/customers',
    load: (): LoadReturn => import('../../views/Customer'),
  },
  {
    path: '/admin',
    load: (): LoadReturn => import('../../views/Admin'),
  },
  {
    path: '/accounting',
    load: (): LoadReturn => import('../../views/Accounting'),
  },
  {
    path: '/facilities',
    load: (): LoadReturn => import('../../views/Facility'),
  },
  {
    path: '/employees',
    load: (): LoadReturn => import('../../views/Employee'),
  },
  {
    path: '/trailers',
    load: (): LoadReturn => import('../../views/Trailer'),
  },
  {
    path: '/powers',
    load: (): LoadReturn => import('../../views/Power'),
  },
  {
    path: '/loads',
    load: (): LoadReturn => import('../../views/Load'),
  },
  {
    path: '/load-repeat',
    load: (): LoadReturn => import('../../views/Load/RepeatOrder'),
  },
  {
    path: '/track',
    load: (): LoadReturn => import('../../views/Load/Track'),
  },
  {
    path: '/capacity-manager/:id/',
    load: (): LoadReturn =>
      import(`../../views/Carrier/Capacity/CapacityManagerPage`),
  },
  {
    path: '/carriers',
    load: (): LoadReturn => import('../../views/Carrier'),
  },
  {
    path: '/truckstop/auth/success',
    load: (): LoadReturn => import('../../views/TruckStopAuthSuccess'),
  },
  {
    path: AUTH_BYPASS_ROUTES_MAP['/__debug'],
    load: (): LoadReturn => import('../../views/Debug'),
  },
  {
    // Allow ids to be appended to debug page for sentry grouping testing
    path: AUTH_BYPASS_ROUTES_MAP['/__debug/:id/'],
    load: (): LoadReturn => import('../../views/Debug'),
  },
  {
    path: AUTH_BYPASS_ROUTES_MAP['/report'],
    load: (): LoadReturn => import('../../views/Diagnostic'),
  },
  {
    path: '/CRMV2',
    load: (): LoadReturn => import('../../views/CRMV2'),
  },
  {
    path: '/CRM',
    load: (): LoadReturn => import('../../views/CRM'),
  },
  {
    path: '/',
    exact: true,
    load: (): LoadReturn => import('../../views/MainPage'),
  },
  // {
  //   path: '/windows',
  //   exact: true,
  //   load: (): LoadReturn => import('../../views/WindowManager'),
  // },
  {
    path: '/lanes',
    load: (): LoadReturn => import('../../views/Planning/Lane'),
  },
  {
    path: '/projects',
    load: (): LoadReturn => import('../../views/Planning/Project'),
  },
  {
    path: '/patterns',
    load: (): LoadReturn => import('../../views/Pattern'),
  },
  {
    path: '/drivers',
    load: (): LoadReturn => import('../../views/Driver'),
  },
  {
    path: '*' as ParameterizedRoutePath,
    load: (): LoadReturn => import('../../views/NotFound'),
  },
];

export const AppRoutes: FC = () => (
  <LazyRoutes routes={routes} fallbackRedirect={null as anyOk} />
);
