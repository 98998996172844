import { CurrencyDisplayType } from '@components/Digit';
import {
  MassUnitTypeEnum,
  Maybe,
  TemperatureUnitTypeEnum,
  UnitOfCurrencyEnum,
  UnitOfCurrencyEnumV2,
  UnitOfLengthEnum,
  VolumeUnitTypeEnum,
} from '@generated/types';
import { win } from '@utils/win';
import { isNil, isString } from 'lodash-es';
import { FC } from 'react';
import {
  MassUnitTypeEnumV2,
  TemperatureUnitTypeEnumV2,
  UnitOfDensityEnum,
  UnitOfLengthEnumV2,
  UnitOfTimeEnum,
  VolumeUnitTypeEnumV2,
} from './enum';

// ts-unused-exports:disable-next-line
export type UnitType =
  | UnitOfLengthEnum
  | TemperatureUnitTypeEnum
  | UnitOfCurrencyEnum
  | MassUnitTypeEnum
  | VolumeUnitTypeEnum
  | UnitOfDensityEnum
  | CurrencyEnum
  | string;

const defaultLang = win.navigator.language;

export enum CurrencyEnum {
  Usd = 'USD',
  Mxn = 'MXN',
  Cad = 'CAD',
}

// https://tc39.es/proposal-unified-intl-numberformat/section6/locales-currencies-tz_proposed_out.html#sec-issanctionedsimpleunitidentifier
type AllowedECMAScriptUnit =
  | 'acre'
  | 'bit'
  | 'byte'
  | 'celsius'
  | 'centimeter'
  | 'day'
  | 'degree'
  | 'fahrenheit'
  | 'fluid-ounce'
  | 'foot'
  | 'gallon'
  | 'gigabit'
  | 'gigabyte'
  | 'gram'
  | 'hectare'
  | 'hour'
  | 'inch'
  | 'kilobit'
  | 'kilobyte'
  | 'kilogram'
  | 'kilometer'
  | 'liter'
  | 'megabit'
  | 'megabyte'
  | 'meter'
  | 'mile'
  | 'mile-scandinavian'
  | 'milliliter'
  | 'millimeter'
  | 'millisecond'
  | 'minute'
  | 'month'
  | 'ounce'
  | 'percent'
  | 'petabyte'
  | 'pound'
  | 'second'
  | 'stone'
  | 'terabit'
  | 'terabyte'
  | 'week'
  | 'yard'
  | 'year';

const formatIntlUnits = new Map<UnitType, AllowedECMAScriptUnit>([
  // length
  [UnitOfLengthEnumV2.Ft, 'foot'],
  [UnitOfLengthEnumV2.In, 'inch'],
  [UnitOfLengthEnumV2.M, 'meter'],
  [UnitOfLengthEnumV2.Mi, 'mile'],
  [UnitOfLengthEnumV2.Km, 'kilometer'],
  // temp
  [TemperatureUnitTypeEnumV2.F, 'fahrenheit'],
  [TemperatureUnitTypeEnumV2.C, 'celsius'],
  // mass
  [MassUnitTypeEnumV2.G, 'gram'],
  [MassUnitTypeEnumV2.Kg, 'kilogram'],
  [MassUnitTypeEnumV2.Lbs, 'pound'],
  [MassUnitTypeEnumV2.St, 'stone'],
]);

interface Props {
  value?: Maybe<number>;
  unit?: Maybe<UnitType>;
  fractionDigits?: number;
  language?: string;
  /** Fallback when there is no data present */
  noData?: string;
  zeroFallback?: string;
  suffix?: string;
  currencyDisplay?: CurrencyDisplayType;
  showUnit?: boolean;
}

const suffixMap = new Map<
  | VolumeUnitTypeEnum
  | UnitOfDensityEnum
  | MassUnitTypeEnumV2
  | TemperatureUnitTypeEnumV2
  | UnitOfLengthEnumV2
  | VolumeUnitTypeEnumV2
  | UnitOfTimeEnum,
  string
>([
  [VolumeUnitTypeEnumV2.Ft3, 'ft³'],
  [VolumeUnitTypeEnumV2.In3, 'in³'],
  [VolumeUnitTypeEnumV2.Cm3, 'cm³'],
  [VolumeUnitTypeEnumV2.M3, 'm³'],
  [UnitOfDensityEnum.Lbft3, 'lb/ft³'],
  [UnitOfDensityEnum.Cwtft3, 'cwt/ft³'],
  [UnitOfDensityEnum.Lbin3, 'lb/in³'],
  [UnitOfDensityEnum.Kgcm3, 'kg/cm³'],
  [UnitOfDensityEnum.Kgm3, 'kg/m³'],
  [MassUnitTypeEnumV2.Lb, MassUnitTypeEnumV2.Lb],
  [MassUnitTypeEnumV2.Cwt, MassUnitTypeEnumV2.Cwt],
  [MassUnitTypeEnumV2.Oz, MassUnitTypeEnumV2.Oz],
  [MassUnitTypeEnumV2.T, 'T'],
  [MassUnitTypeEnumV2.Mt, MassUnitTypeEnumV2.Mt],
  [MassUnitTypeEnumV2.G, MassUnitTypeEnumV2.G],
  [MassUnitTypeEnumV2.Kg, MassUnitTypeEnumV2.Kg],
  [MassUnitTypeEnumV2.St, MassUnitTypeEnumV2.St],
  [UnitOfLengthEnumV2.Cm, UnitOfLengthEnumV2.Cm],
  [UnitOfLengthEnumV2.M, UnitOfLengthEnumV2.M],
  [TemperatureUnitTypeEnumV2.C, TemperatureUnitTypeEnumV2.C],
  [UnitOfTimeEnum.Hr, UnitOfTimeEnum.Hr],
  [UnitOfTimeEnum.Hours, UnitOfTimeEnum.Hours],
  [UnitOfTimeEnum.Mn, UnitOfTimeEnum.Mn],
  [UnitOfTimeEnum.Minutes, UnitOfTimeEnum.Minutes],
  [UnitOfTimeEnum.Days, UnitOfTimeEnum.Days],
]);

const genericFormatterMap = new Map<string, Intl.NumberFormat>();

const getFormatterId = (props: Props): string => {
  const lang = props.language ?? defaultLang;
  return `${lang}-${Object.values(props).join('-')}`;
};

const getFormatter = (
  props: Props,
  fallback: Parameters<typeof Intl.NumberFormat>['1']
): Intl.NumberFormat => {
  const id = getFormatterId(props);
  let formatter = genericFormatterMap.get(id);

  if (!formatter) {
    const lang = props.language || defaultLang;
    formatter = new Intl.NumberFormat(lang, fallback);
    genericFormatterMap.set(id, formatter);
  }
  return formatter;
};

const genericFormat = (props: Props): string => {
  if (isNil(props.value)) {
    return '';
  }
  const showUnit = props.showUnit === false ? false : true;

  const formatter = getFormatter(props, {
    maximumFractionDigits: props.fractionDigits || 0,
    minimumFractionDigits: props.fractionDigits || 0,
    style: 'unit',
    unit: 'foot',
  });

  return formatter
    .format(props.value)
    .replace('ft', showUnit ? suffixMap.get(props.unit as fixMe) || '' : '')
    .trim();
};

export const getNumberValue = (props: Maybe<Props>): string => {
  if (!props) {
    return '';
  }
  const { value, unit, suffix } = props;
  if (isNil(value)) {
    return props.noData || '';
  }

  if (value === 0 && isString(props.zeroFallback)) {
    return props.zeroFallback;
  }

  const isCurrency = Object.values(
    CurrencyEnum || UnitOfCurrencyEnum || UnitOfCurrencyEnumV2
  ).includes(
    (unit as CurrencyEnum) ||
      (unit as UnitOfCurrencyEnum) ||
      (unit as UnitOfCurrencyEnumV2)
  );

  const foundMatchingIntlUnit = formatIntlUnits.get(unit as fixMe);

  let intermediateVal = '';

  if (isCurrency && unit) {
    intermediateVal = getFormatter(props, {
      style: 'currency',
      currency: unit,
      maximumFractionDigits: props.fractionDigits ?? 2,
      minimumFractionDigits: props.fractionDigits ?? 2,
      currencyDisplay: props.currencyDisplay ?? 'symbol',
    }).format(value);
  } else if (foundMatchingIntlUnit && props.showUnit !== false) {
    intermediateVal = getFormatter(props, {
      maximumFractionDigits: props.fractionDigits || 0,
      minimumFractionDigits: props.fractionDigits || 0,
      style: 'unit',
      unit: foundMatchingIntlUnit,
    }).format(value);
  } else {
    intermediateVal = genericFormat(props);
  }
  return `${intermediateVal}${suffix ?? ''}`;
};

export const getUnitDisplay = (unit: Maybe<Props['unit']>): string => {
  if (!unit) {
    return '';
  }
  const str = getNumberValue({ value: 1, unit });
  return str.replace(/1/, '');
};

export const NumberValue: FC<Props> = (props) => {
  return <span {...props}>{getNumberValue(props)}</span>;
};
