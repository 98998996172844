import * as TP from '../types';

import { gql } from '@apollo/client';
import { CarrierItemFragmentDoc } from './carriersForCarrierPicker';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CarriersForMasterfindQueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.CarriersFilter>;
  first?: TP.InputMaybe<TP.Scalars['Int']>;
  last?: TP.InputMaybe<TP.Scalars['Int']>;
  after?: TP.InputMaybe<TP.Scalars['String']>;
  before?: TP.InputMaybe<TP.Scalars['String']>;
}>;


export type CarriersForMasterfindQuery = (
  { readonly __typename?: 'Query' }
  & { readonly carriersNullable?: TP.Maybe<(
    { readonly __typename?: 'CarrierConnection' }
    & { readonly edges: ReadonlyArray<(
      { readonly __typename?: 'CarrierEdge' }
      & { readonly node: (
        { readonly __typename?: 'Carrier' }
        & Pick<TP.Carrier, 'id' | 'name' | 'code' | 'scacNumber' | 'currencyId'>
        & { readonly mainAddress?: TP.Maybe<(
          { readonly __typename?: 'Address' }
          & Pick<TP.Address, 'city' | 'state'>
        )> }
      ) }
    )> }
  )> }
);


export const CarriersForMasterfindDocument = gql`
    query carriersForMasterfind($filter: CarriersFilter, $first: Int, $last: Int, $after: String, $before: String) {
  carriersNullable(
    filter: $filter
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    edges {
      node {
        ...CarrierItem
      }
    }
  }
}
    ${CarrierItemFragmentDoc}`;

/**
 * __useCarriersForMasterfindQuery__
 *
 * To run a query within a React component, call `useCarriersForMasterfindQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarriersForMasterfindQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarriersForMasterfindQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useCarriersForMasterfindQuery(baseOptions?: Apollo.QueryHookOptions<CarriersForMasterfindQuery, CarriersForMasterfindQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CarriersForMasterfindQuery, CarriersForMasterfindQueryVariables>(CarriersForMasterfindDocument, options);
      }
export function useCarriersForMasterfindLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CarriersForMasterfindQuery, CarriersForMasterfindQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CarriersForMasterfindQuery, CarriersForMasterfindQueryVariables>(CarriersForMasterfindDocument, options);
        }
export type CarriersForMasterfindQueryHookResult = ReturnType<typeof useCarriersForMasterfindQuery>;
export type CarriersForMasterfindLazyQueryHookResult = ReturnType<typeof useCarriersForMasterfindLazyQuery>;
export type CarriersForMasterfindQueryResult = Apollo.QueryResult<CarriersForMasterfindQuery, CarriersForMasterfindQueryVariables>;