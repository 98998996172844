import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
import { RouteLockingEventPayloadFragmentDoc } from '../subscriptionFragments/routeLockingEvent';
import { LinkedRouteEventPayloadFragmentDoc } from '../subscriptionFragments/linkedRouteEvent';
import { LoadStatusEventPayloadFragmentDoc } from '../subscriptionFragments/loadStatusEvent';
import { RouteStatusEventPayloadFragmentDoc } from '../subscriptionFragments/routeStatusEvent';
import { BaseEventPayloadFragmentDoc } from '../subscriptionFragments/baseEvent';
import { TrackingStatusEventPayloadFragmentDoc } from '../subscriptionFragments/trackingStatusEvent';
import { DriverAssignmentEventPayloadFragmentDoc } from '../subscriptionFragments/driverAssignmentEventPayload';
import { RouteVendorEventPayloadFragmentDoc } from '../subscriptionFragments/routeVendorEvent';
import { CarrierMatchEventPayloadFragmentDoc } from '../subscriptionFragments/carrierMatchEvent';
import { UserMatchEventPayloadFragmentDoc } from '../subscriptionFragments/userMatchEvent';
import { SubStopExecutionEventPayloadFragmentDoc } from '../subscriptionFragments/subStopExecutionEventPayload';
import { CommissionsRecalculatedEventPayloadFragmentDoc } from '../subscriptionFragments/commissionsRecalculatedEvent';
import { TenderPlanEventPayloadFragmentDoc } from '../subscriptionFragments/tenderPlanEvent';
import { TenderPlanRouteEventPayloadFragmentDoc } from '../subscriptionFragments/tenderPlanRouteEvent';
import { TrackingUpdateEventPayloadFragmentDoc } from '../subscriptionFragments/trackingUpdateEvent';
import { ToastEventPayloadFragmentDoc } from '../subscriptionFragments/toastEvent';
import { OfferEventPayloadFragmentDoc } from '../subscriptionFragments/offerEvent';
import { RouteMaxCostEventPayloadFragmentDoc } from '../subscriptionFragments/routeMaxCostEvent';
import { PricingRateEventPayloadFragmentDoc } from '../subscriptionFragments/pricingRateEvent';
import { CostLineItemEventPayloadFragmentDoc } from '../subscriptionFragments/costLineItemEvent';
import { RateLineItemEventPayloadFragmentDoc } from '../subscriptionFragments/rateLineItemEvent';
import { ClientExceptionRecordEventPayloadFragmentDoc } from '../subscriptionFragments/clientExceptionRecordEvent';
import { IncidentEventPayloadFragmentDoc } from '../subscriptionFragments/incidentsEvent';
import { MinionRefreshEventPayloadFragmentDoc } from '../subscriptionFragments/minionRefreshEvent';
import { MessagingNotificationStatusEventPayloadFragmentDoc } from '../subscriptionFragments/messagingNotificationStatusEvent';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SubscribeSubscriptionVariables = TP.Exact<{
  eventTypes?: TP.InputMaybe<ReadonlyArray<TP.EventType> | TP.EventType>;
  ids?: TP.InputMaybe<ReadonlyArray<TP.Scalars['String']> | TP.Scalars['String']>;
}>;


export type SubscribeSubscription = (
  { readonly __typename?: 'Subscription' }
  & { readonly subscribe: (
    { readonly __typename: 'BaseEvent' }
    & Pick<TP.BaseEvent, 'id' | 'type'>
  ) | (
    { readonly __typename: 'RouteLockingEvent' }
    & Pick<TP.RouteLockingEvent, 'id' | 'type' | 'expirationTimestamp' | 'userIdentity' | 'adminIdentity' | 'lockEventType'>
  ) | (
    { readonly __typename: 'LinkedRouteEvent' }
    & Pick<TP.LinkedRouteEvent, 'id' | 'type'>
  ) | (
    { readonly __typename: 'LoadStatusEvent' }
    & Pick<TP.LoadStatusEvent, 'id' | 'type'>
  ) | (
    { readonly __typename: 'RouteStatusEvent' }
    & Pick<TP.RouteStatusEvent, 'id' | 'type'>
  ) | { readonly __typename: 'TaskEvent' } | { readonly __typename: 'TasksByLoadIdEvent' } | (
    { readonly __typename: 'TrackingStatusEvent' }
    & Pick<TP.TrackingStatusEvent, 'id' | 'trackingStatus'>
  ) | (
    { readonly __typename: 'DriverAssignmentEvent' }
    & Pick<TP.DriverAssignmentEvent, 'id' | 'driverAssignmentID' | 'eventTimestamp'>
  ) | (
    { readonly __typename: 'SubStopExecutionEvent' }
    & Pick<TP.SubStopExecutionEvent, 'id' | 'stopEventIDs' | 'stopEventTypeID' | 'stopID' | 'actionType'>
  ) | { readonly __typename: 'TruckPostingMatchEvent' } | (
    { readonly __typename: 'CarrierMatchEvent' }
    & Pick<TP.CarrierMatchEvent, 'id' | 'type'>
  ) | (
    { readonly __typename: 'UserMatchEvent' }
    & Pick<TP.UserMatchEvent, 'id' | 'type'>
  ) | { readonly __typename: 'RouteMatchEvent' } | (
    { readonly __typename: 'RouteVendorEvent' }
    & Pick<TP.RouteVendorEvent, 'id' | 'loadId' | 'routeId' | 'type'>
  ) | (
    { readonly __typename: 'CommissionsRecalculatedEvent' }
    & Pick<TP.CommissionsRecalculatedEvent, 'id' | 'loadId' | 'type'>
  ) | (
    { readonly __typename: 'TenderPlanEvent' }
    & Pick<TP.TenderPlanEvent, 'id' | 'type'>
  ) | (
    { readonly __typename: 'TenderPlanRouteEvent' }
    & Pick<TP.TenderPlanRouteEvent, 'id' | 'type'>
  ) | (
    { readonly __typename: 'TrackingUpdateEvent' }
    & Pick<TP.TrackingUpdateEvent, 'id' | 'trackingUpdateId' | 'trackingUpdateEventType' | 'type'>
  ) | (
    { readonly __typename: 'ToastEvent' }
    & Pick<TP.ToastEvent, 'id' | 'toastType' | 'message'>
  ) | (
    { readonly __typename: 'OfferEvent' }
    & Pick<TP.OfferEvent, 'id' | 'type' | 'offerId' | 'carrierId'>
  ) | (
    { readonly __typename: 'RouteMaxCostEvent' }
    & Pick<TP.RouteMaxCostEvent, 'id' | 'routeMaxCost' | 'type'>
  ) | (
    { readonly __typename: 'CostLineItemEvent' }
    & Pick<TP.CostLineItemEvent, 'id' | 'action' | 'costLineItemId'>
  ) | (
    { readonly __typename: 'RateLineItemEvent' }
    & Pick<TP.RateLineItemEvent, 'id' | 'action' | 'rateLineItemId'>
  ) | (
    { readonly __typename: 'PricingRateEvent' }
    & Pick<TP.PricingRateEvent, 'id' | 'type'>
  ) | (
    { readonly __typename: 'MinionRefreshEvent' }
    & Pick<TP.MinionRefreshEvent, 'id' | 'message' | 'type'>
  ) | (
    { readonly __typename: 'ClientExceptionRecordEvent' }
    & Pick<TP.ClientExceptionRecordEvent, 'id' | 'clientExceptionRecordId' | 'clientExceptionRecordEventType' | 'type'>
  ) | { readonly __typename: 'ClientExceptionRecordFilteredEvent' } | (
    { readonly __typename: 'IncidentEvent' }
    & Pick<TP.IncidentEvent, 'id' | 'incidentID' | 'incidentEventType' | 'type'>
  ) | (
    { readonly __typename: 'MessagingNotificationStatusEvent' }
    & Pick<TP.MessagingNotificationStatusEvent, 'id' | 'type' | 'messageId' | 'status' | 'serverType' | 'errorMessage'>
  ) }
);


export const SubscribeDocument = gql`
    subscription Subscribe($eventTypes: [EventType!], $ids: [String!]) {
  subscribe(ids: $ids, types: $eventTypes) {
    __typename
    ... on RouteLockingEvent {
      ...RouteLockingEventPayload
    }
    ... on LinkedRouteEvent {
      ...LinkedRouteEventPayload
    }
    ... on LoadStatusEvent {
      ...LoadStatusEventPayload
    }
    ... on RouteStatusEvent {
      ...RouteStatusEventPayload
    }
    ... on BaseEvent {
      ...BaseEventPayload
    }
    ... on TrackingStatusEvent {
      ...TrackingStatusEventPayload
    }
    ... on DriverAssignmentEvent {
      ...DriverAssignmentEventPayload
    }
    ... on RouteVendorEvent {
      ...RouteVendorEventPayload
    }
    ... on CarrierMatchEvent {
      ...CarrierMatchEventPayload
    }
    ... on UserMatchEvent {
      ...UserMatchEventPayload
    }
    ... on SubStopExecutionEvent {
      ...SubStopExecutionEventPayload
    }
    ... on CommissionsRecalculatedEvent {
      ...CommissionsRecalculatedEventPayload
    }
    ... on TenderPlanEvent {
      ...TenderPlanEventPayload
    }
    ... on TenderPlanRouteEvent {
      ...TenderPlanRouteEventPayload
    }
    ... on TrackingUpdateEvent {
      ...TrackingUpdateEventPayload
    }
    ... on ToastEvent {
      ...ToastEventPayload
    }
    ... on OfferEvent {
      ...OfferEventPayload
    }
    ... on RouteMaxCostEvent {
      ...RouteMaxCostEventPayload
    }
    ... on PricingRateEvent {
      ...PricingRateEventPayload
    }
    ... on CostLineItemEvent {
      ...CostLineItemEventPayload
    }
    ... on RateLineItemEvent {
      ...RateLineItemEventPayload
    }
    ... on ClientExceptionRecordEvent {
      ...ClientExceptionRecordEventPayload
    }
    ... on IncidentEvent {
      ...IncidentEventPayload
    }
    ... on MinionRefreshEvent {
      ...MinionRefreshEventPayload
    }
    ... on MessagingNotificationStatusEvent {
      ...MessagingNotificationStatusEventPayload
    }
  }
}
    ${RouteLockingEventPayloadFragmentDoc}
${LinkedRouteEventPayloadFragmentDoc}
${LoadStatusEventPayloadFragmentDoc}
${RouteStatusEventPayloadFragmentDoc}
${BaseEventPayloadFragmentDoc}
${TrackingStatusEventPayloadFragmentDoc}
${DriverAssignmentEventPayloadFragmentDoc}
${RouteVendorEventPayloadFragmentDoc}
${CarrierMatchEventPayloadFragmentDoc}
${UserMatchEventPayloadFragmentDoc}
${SubStopExecutionEventPayloadFragmentDoc}
${CommissionsRecalculatedEventPayloadFragmentDoc}
${TenderPlanEventPayloadFragmentDoc}
${TenderPlanRouteEventPayloadFragmentDoc}
${TrackingUpdateEventPayloadFragmentDoc}
${ToastEventPayloadFragmentDoc}
${OfferEventPayloadFragmentDoc}
${RouteMaxCostEventPayloadFragmentDoc}
${PricingRateEventPayloadFragmentDoc}
${CostLineItemEventPayloadFragmentDoc}
${RateLineItemEventPayloadFragmentDoc}
${ClientExceptionRecordEventPayloadFragmentDoc}
${IncidentEventPayloadFragmentDoc}
${MinionRefreshEventPayloadFragmentDoc}
${MessagingNotificationStatusEventPayloadFragmentDoc}`;

/**
 * __useSubscribeSubscription__
 *
 * To run a query within a React component, call `useSubscribeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeSubscription({
 *   variables: {
 *      eventTypes: // value for 'eventTypes'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSubscribeSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SubscribeSubscription, SubscribeSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeSubscription, SubscribeSubscriptionVariables>(SubscribeDocument, options);
      }
export type SubscribeSubscriptionHookResult = ReturnType<typeof useSubscribeSubscription>;
export type SubscribeSubscriptionResult = Apollo.SubscriptionResult<SubscribeSubscription>;