import { EmployeeSimpleFragment } from '@generated/fragments/employeeSimple';
import { EmployeeSimpleV2Fragment } from '@generated/fragments/employeeSimpleV2';
import { useSearchEmployeesQuery } from '@generated/queries/searchEmployees';
import { useSearchEmployeesV2Query } from '@generated/queries/searchEmployeesV2';
import { useEmployeeV2Flag } from '@hooks/useEmployeeV2Flag';
import { useTamedInputValue } from '@hooks/useTamedInputValue';
import { FC } from 'react';
import {
  AutoComplete,
  Props as AutoCompleteProps,
} from '../../../components/AutoComplete';

type Item = EmployeeSimpleFragment | EmployeeSimpleV2Fragment;

type Props = Omit<AutoCompleteProps<Item>, 'items'>;

export const RepSearch: FC<Props & { includeAll?: boolean }> = ({
  includeAll,
  ...props
}) => {
  const [searchText, setSearchText] = useTamedInputValue({
    initialValue: '',
  });

  const useEmployeeV2 = useEmployeeV2Flag();

  const { loading, data } = useSearchEmployeesQuery({
    variables: {
      filter: {
        firstNameLastNameOrEmail: searchText,
        employeeStatus: includeAll ? null : 'Active',
      },
      first: 50,
    },
    skip: !searchText || useEmployeeV2,
  });

  const { loading: loading2, data: dataV2 } = useSearchEmployeesV2Query({
    variables: {
      filter: {
        firstNameLastNameOrEmail: searchText,
        employeeStatus: includeAll ? null : 'Active',
      },
      first: 50,
    },
    skip: !searchText || !useEmployeeV2,
  });

  const employees: ReadonlyArray<Item> = useEmployeeV2
    ? dataV2?.allEmployeesV2?.edges.map(({ node }) => node) ?? []
    : data?.allEmployees?.edges.map(({ node }) => node) ?? [];
  const items = employees.map((employee) => ({
    value: employee,
    label: employee.fullName,
    id: employee.id,
  }));

  return (
    <AutoComplete<Item>
      onInputValueChange={setSearchText}
      loading={loading || loading2}
      items={items}
      {...props}
    />
  );
};
