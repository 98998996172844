// eslint-disable-next-line no-restricted-imports
import { themeObj } from '@components/Theme';
import {
  faBalanceScale,
  faBars,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faCircle,
  faCloudUploadAlt,
  faColumns,
  faCommentDots,
  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faExternalLinkAlt,
  faEye,
  faFileExcel,
  faFolder,
  faGlobe,
  faHistory,
  faLink,
  faList,
  faLock,
  faLongArrowAltDown,
  faLongArrowAltRight,
  faMapMarkerAlt,
  faMinus,
  faPaperclip,
  faPenAlt,
  faPhone,
  faPlay,
  faQuestionCircle,
  faSave,
  faSearch,
  faShippingFast,
  faSlash,
  faSpinner,
  faStop,
  faStopwatch,
  faTimes,
  faTimesCircle,
  faTrash,
  faTruckMoving,
  faUpload,
  faUser,
  faUserFriends,
  faWineBottle,
} from '@fortawesome/free-solid-svg-icons';
import {
  faAngleDown as angleDownLight,
  faAngleLeft as angleLeftLight,
  faAngleRight as angleRightLight,
  faAngleUp as angleUpLight,
  faBalanceScaleRight,
  faCalendarAlt,
  faClock,
  faCopy,
  faEdit,
  faExclamationCircle as faExclamationCircleInverse,
  faGripVertical,
  faHome,
  faInfoCircle,
  faMoon,
  faPaw,
  faPen,
  faPig,
  faRoute,
  faRulerTriangle,
  faShareAlt,
  faSignalStream,
  faTrashAlt,
  faTruckLoading,
  faTruckMoving as truckMovingLight,
  faUsers as usersLight,
} from '@fortawesome/pro-light-svg-icons';
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowAltCircleRight,
  faArrowToBottom as faDownloadArrow,
  faBullseyeArrow,
  faCaretCircleDown as caretDownReg,
  faCommentLines as faCommentLinesRegular,
  faEnvelope as faEnvelopeRegular,
  faExpandAlt,
  faEyeSlash,
  faFileDownload,
  faFilePdf,
  faFileUpload,
  faFilter,
  faLevelDown,
  faLineColumns as faLineColumnsReg,
  faPlus,
  faSync,
  faWindowRestore,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowRight as arrowRightSolid,
  faArrowSquareRight,
  faBoxOpen as boxOpen,
  faBuilding as building,
  faFilter as filterSolid,
  faIndustryAlt as industryAlt,
  faPencil,
  faUsers as users,
} from '@fortawesome/pro-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { useTheme } from '@hooks/useTheme';
import { FC, memo } from 'react';

// export used by mdx file
// ts-unused-exports:disable-next-line
export const iconMap = {
  angleDown: faAngleDown,
  angleDownLight,
  angleLeft: faAngleLeft,
  angleLeftLight,
  angleRight: faAngleRight,
  angleRightLight,
  angleUp: faAngleUp,
  angleUpLight,
  arrowDown: faCaretDown,
  arrowLeft: faCaretLeft,
  arrowRight: faCaretRight,
  arrowRightSolid: arrowRightSolid,
  arrowSquareRight: faArrowSquareRight,
  backwardSlash: faSlash,
  balanceScaleRight: faBalanceScaleRight,
  balanceScale: faBalanceScale,
  bars: faBars,
  bullsEyeArrow: faBullseyeArrow,
  boxOpen,
  building,
  calendar: faCalendarAlt,
  caretCircleDownReg: caretDownReg,
  caretDown: faCaretDown,
  caretUp: faCaretUp,
  check: faCheck,
  checkCircle: faCheckCircle,
  chevronDown: faChevronDown,
  chevronUp: faChevronUp,
  circle: faCircle,
  clock: faClock,
  columns: faColumns,
  copy: faCopy,
  download: faDownloadArrow,
  drag: faGripVertical,
  edit: faPenAlt,
  editSquare: faEdit,
  envelope: faEnvelope,
  exclamationCircle: faExclamationCircle,
  exclamationCircleInverse: faExclamationCircleInverse,
  exclamationTriangle: faExclamationTriangle,
  expand: faExpand,
  expandAlt: faExpandAlt,
  eye: faEye,
  eyeSlash: faEyeSlash,
  faArrowCircleRight: faArrowAltCircleRight,
  filePdf: faFilePdf,
  filter: faFilter,
  folder: faFolder,
  globe: faGlobe,
  history: faHistory,
  industry: industryAlt,
  infoCircle: faInfoCircle,
  levelDown: faLevelDown,
  lineColumnsReg: faLineColumnsReg,
  link: faLink,
  linkExternal: faExternalLinkAlt,
  list: faList,
  lock: faLock,
  longArrowRight: faLongArrowAltRight,
  mapMarker: faMapMarkerAlt,
  moon: faMoon,
  partialCheck: faMinus,
  paw: faPaw,
  pencil: faPencil,
  phone: faPhone,
  pig: faPig,
  play: faPlay,
  plus: faPlus,
  question: faQuestionCircle,
  refresh: faSync,
  route: faRoute,
  rulerTraingle: faRulerTriangle,
  save: faSave,
  search: faSearch,
  signalStream: faSignalStream,
  shipping: faShippingFast,
  spinner: faSpinner,
  stop: faStop,
  stopwatch: faStopwatch,
  trash: faTrash,
  trashAlt: faTrashAlt,
  truckLoading: faTruckLoading,
  truckMoving: faTruckMoving,
  truckMovingLight: truckMovingLight,
  upload: faUpload,
  user: faUser,
  userFriends: faUserFriends,
  users,
  usersLight: usersLight,
  verticalEllipsis: faEllipsisV,
  wineBottle: faWineBottle,
  x: faTimes,
  xCircle: faTimesCircle,
  fileUpload: faFileUpload,
  fileDownload: faFileDownload,
  fileCloudUpload: faCloudUploadAlt,
  fileExcel: faFileExcel,
  paperClip: faPaperclip,
  commentDots: faCommentDots,
  arrowSquareDown: faLongArrowAltDown,
  commentLinesRegular: faCommentLinesRegular,
  envelopeRegular: faEnvelopeRegular,
  windows: faWindowRestore,
  filterSolid,
  pen: faPen,
  shareNodes: faShareAlt,
  home: faHome,
};

interface WeakIcon extends Omit<FontAwesomeIconProps, 'icon'> {
  size?: anyOk;
}

export type IconProp = keyof typeof iconMap;

interface Props extends WeakIcon {
  i: IconProp;
  /** Size correlates to the font size of the svg */
  size?: number;
  /** Color is a color key from the useTheme() hook, or any valid css color value */
  color?: keyof typeof themeObj.colors | string;
}

export const Icon: FC<Props> = memo(({ i, color, size, ...rest }) => {
  const { colors } = useTheme();
  return (
    <FontAwesomeIcon
      icon={iconMap[i]}
      css={{
        fontSize: size || 10,
        color: colors[color as keyof typeof colors] || color || 'inherit',
      }}
      {...rest}
    />
  );
});

export const IconBg: FC<Props> = memo(({ i }) => {
  return <FontAwesomeIcon icon={iconMap[i]} css={{ fontSize: 10 }} />;
});
