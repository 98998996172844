import { ApolloError } from '@apollo/client';
import { useKeycloakAuthContext } from '@components/AuthContext';
import { Grid } from '@components/Grid';
import { LinkTo } from '@components/Link';
import { NavLink } from '@components/NavLink';
import { SCROLLBAR_WIDTH } from '@components/Theme';
import { css } from '@emotion/react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EmployeeSimpleFragment } from '@generated/fragments/employeeSimple';
import { EmployeeSimpleV2Fragment } from '@generated/fragments/employeeSimpleV2';
import { useSearchCarriersQuery } from '@generated/queries/searchCarriers';
import {
  SearchCarriersV2Query,
  useSearchCarriersV2LazyQuery,
} from '@generated/queries/searchCarriersV2';
import { useSearchCustomersQuery } from '@generated/queries/searchCustomers';
import { useSearchCustomersV2Query } from '@generated/queries/searchCustomersV2';
import { useCarrierV2Flag } from '@hooks/useCarrierV2Flag';
import { useCustomerV2Flag } from '@hooks/useCustomerV2Flag';
import { useDebouncedFn } from '@hooks/useDebouncedFn';
import { useTheme } from '@hooks/useTheme';
import { updateQuery } from '@utils/graphqlUtils';
import { sortBy } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { SearchType } from '../Search/types';

type EmployeeType = EmployeeSimpleFragment | EmployeeSimpleV2Fragment;
interface ListProps {
  searchType: SearchType;
  employee: EmployeeType;
  resetRepFilter: () => void;
}

interface ListItem {
  id: string;
  name: string;
  url: LinkTo;
}

const getNameFromEmployee = (emp: Partial<EmployeeType>): string => {
  return `${emp.firstName || ''} ${emp.lastName || ''}`.trim();
};

export const List = ({
  searchType,
  employee,
  resetRepFilter,
}: //Had issues with return type ReactNode, so replaced with JSX Element
ListProps): JSX.Element => {
  const {
    sidebar: { link, active },
  } = useTheme();
  const useCustomerV2 = useCustomerV2Flag();
  const useCarrierV2 = useCarrierV2Flag();
  const listStyle = css({
    listStyle: 'none',
    overflowY: 'auto',
    overflowX: 'hidden',
    '::webkit-scrollbar': {
      height: SCROLLBAR_WIDTH,
      width: SCROLLBAR_WIDTH,
    },

    '& a': {
      ...link,

      '&.active': {
        ...active,
      },
    },
  });

  const searchCarriersQueryRes = useSearchCarriersQuery({
    variables: {
      filter: { employeeId: employee?.id },
    },
    skip: !employee?.id || useCarrierV2,
  });

  const searchCustomersQuery = useSearchCustomersQuery({
    variables: {
      filter: { employeeId: employee?.id },
    },
    skip: !employee?.id || useCustomerV2,
  });

  const searchCustomersV2Query = useSearchCustomersV2Query({
    variables: {
      filter: { employeeId: employee?.id },
    },
    skip: !employee?.id || !useCustomerV2,
  });

  const [paginationParams, setPaginationParams] = useState({
    hasNextPage: true,
    endCursor: '',
  });

  const [carriersV2ListItems, setCarriersV2ListItems] = useState<ListItem[]>();

  const updateCarriersV2List = (data: Maybe<SearchCarriersV2Query>): void => {
    if (data) {
      const listItems: ListItem[] = (
        carriersDataV2?.carriersNullableV2?.edges ?? []
      ).map(
        ({ node: { id, name } }): ListItem => ({
          id,
          name,
          url: `/carriers/${id}/capacity-manager`,
        })
      );
      setCarriersV2ListItems(listItems);
      const hasNextPage =
        data?.carriersNullableV2?.pageInfo?.hasNextPage ?? false;
      const endCursor = data?.carriersNullableV2?.pageInfo?.endCursor ?? '';
      setPaginationParams({ hasNextPage, endCursor });
    }
  };

  const [
    searchCarriersV2Query,
    { loading: loadingCarrierV2, data: carriersDataV2, fetchMore },
  ] = useSearchCarriersV2LazyQuery({
    fetchPolicy: 'cache-and-network',

    // workaround for pagination issues introduced with recent Apollo versions:
    // https://github.com/apollographql/apollo-client/issues/6327
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data): void => updateCarriersV2List(data),
  });
  const handleScrollToBottom = async (e: anyOk): Promise<void> => {
    const scrollHeight = e.target.scrollHeight - e.target.offsetHeight;
    const fromTop = e.target.scrollTop;
    const shouldFetchMore = scrollHeight - fromTop <= 50;

    if (!paginationParams.hasNextPage || loadingCarrierV2 || !shouldFetchMore) {
      return;
    }

    await debouncedFetchMore();

    setPaginationParams((previousParams) => ({
      ...previousParams,
      hasNextPage: false,
    }));
  };
  const getMoreCarriers = async (): Promise<void> => {
    await fetchMore?.({
      variables: {
        after: paginationParams.endCursor,
        first: 100,
      },
      updateQuery,
    });
  };
  const debouncedFetchMore = useDebouncedFn(getMoreCarriers, 250, [
    paginationParams.endCursor,
    fetchMore,
    updateQuery,
  ]);

  useEffect(() => {
    if (employee?.id) {
      setCarriersV2ListItems([]);
      searchCarriersV2Query({
        variables: {
          filter: {
            employeeId: employee?.id,
            sort: 'name asc',
          },
          first: 100,
        },
      });
    }
  }, [employee?.id, searchCarriersV2Query]);

  const shouldUsePagination = useCallback((): boolean => {
    if (searchType === SearchType.customer) {
      return false;
    }
    if (!useCarrierV2) {
      return false;
    }
    return true;
  }, [searchType, useCarrierV2]);

  interface ListQueryResult {
    error?: ApolloError;
    loading: boolean;
    listItems: ListItem[];
  }

  let listLabel = '';
  let listQuery: ListQueryResult;
  let listItems: ListItem[];
  switch (searchType) {
    case SearchType.customer:
      listItems = (
        (useCustomerV2
          ? searchCustomersV2Query.data?.allCustomersV2?.edges
          : searchCustomersQuery.data?.allCustomers.edges) ?? []
      ).map(
        ({ node: { id, name } }): ListItem => ({
          id,
          name,
          url: `/customers/${id}/`,
        })
      );
      listLabel = 'Customers';
      listQuery = useCustomerV2
        ? { ...searchCustomersV2Query, listItems }
        : { ...searchCustomersQuery, listItems };
      break;
    case SearchType.carrier:
    default:
      listLabel = 'Carriers';
      listItems = (
        searchCarriersQueryRes.data?.carriersNullable?.edges ?? []
      ).map(
        ({ node: { id, name } }): ListItem => ({
          id,
          name,
          url: `/carriers/${id}/capacity-manager`,
        })
      );
      listQuery = { ...searchCarriersQueryRes, listItems };
      break;
  }

  const { loading, error, listItems: list } = listQuery;

  // TODO ME-10510: Use WhoAmI endpoint instead of token.
  const { user: currentUser } = useKeycloakAuthContext();

  const isLoggedInUser = currentUser?.email === employee.email;

  if (loading) {
    return <p>Loading ...</p>;
  }
  if (error) {
    return <p>Error!</p>;
  }

  const sortedList = sortBy(list, (obj) => obj.name);

  return (
    <Grid css={{ gridTemplateRows: 'min-content 1fr', height: '100%' }}>
      {employee && (
        <div css={{ fontWeight: 'bold', textAlign: 'center' }}>
          {listLabel} for {getNameFromEmployee(employee)}
          <button
            css={{
              padding: '0.5em',
              visibility: isLoggedInUser ? 'hidden' : 'visible',
            }}
            onClick={resetRepFilter}
            data-testid="reset-rep-filter"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      )}
      {shouldUsePagination() ? (
        <ul
          onScroll={handleScrollToBottom}
          css={listStyle}
          data-testid="sidebar-search-list"
        >
          {carriersV2ListItems?.map(({ id, name, url }) => (
            <li key={id} data-testid={id}>
              <span>
                <NavLink
                  to={url}
                  replace
                  isActive={(match, location): boolean =>
                    location.pathname.includes(id)
                  }
                  data-testid="nav-link"
                >
                  {name}
                </NavLink>
              </span>
            </li>
          ))}
          {loadingCarrierV2 && <p>Loading ...</p>}
        </ul>
      ) : (
        <ul css={listStyle} data-testid="sidebar-search-list">
          {sortedList.map(({ id, name, url }) => (
            <li key={id} data-testid={id}>
              <span>
                <NavLink
                  to={url}
                  replace
                  isActive={(match, location): boolean =>
                    location.pathname.includes(id)
                  }
                  data-testid="nav-link"
                >
                  {name}
                </NavLink>
              </span>
            </li>
          ))}
        </ul>
      )}
    </Grid>
  );
};
