import { Theme, ThemeColor } from '@emotion/react';
import { useTheme } from '@hooks/useTheme';
import { keys } from '@utils/keys';
import { intersection, last, omit, pickBy } from 'lodash-es';
import { FC, HTMLProps } from 'react';
import { colors } from '../Theme/colors';

type ColorMap = { [k in keyof Theme['colors']]?: Maybe<boolean | number> };

interface Props extends ColorMap, HTMLProps<HTMLSpanElement> {
  as?: 'div' | 'span' | 'strong' | 'em' | 'label' | 'code';
  color?: ThemeColor;
}

export const Text: FC<Props> = ({
  as: asProp = 'span',
  children,
  color,
  ...others
}) => {
  const { colors: themeColors = {} } = useTheme();
  // We generate the possibleColorKeys from the import, and not the derived value of useTheme() because this component can sometimes be rendered without the themeprovider present - in the case of Table CSV rendering
  let resolvedColor: string | undefined = undefined;
  const possibleColorKeys = Object.keys(colors);
  const rest = omit(others, possibleColorKeys);
  if (color) {
    resolvedColor = colors[color];
  } else {
    const otherKeysWithNullishValuesRemoved = keys(pickBy(others));
    const matchingColorKeys = intersection(
      otherKeysWithNullishValuesRemoved,
      possibleColorKeys
    );
    const lastMatchingKey = last(matchingColorKeys) || '';
    resolvedColor = themeColors[lastMatchingKey as keyof typeof themeColors];
  }
  const El = asProp as anyOk;
  return (
    <El css={{ color: resolvedColor }} {...rest}>
      {children}
    </El>
  );
};
