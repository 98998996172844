// ts-unused-exports:disable-next-line
export const jsonFlagArr = [
  'test-json-flag',
  'ME-14922-view-route-details-pricing-card',
  'ME-16089-view-best-offer-card-truck-entry',
  'ME-16089-view-posting-status-card-truck-entry',
  'ME-16089-view-advertised-price-card-truck-entry',
  'ME-16089-view-pricing-card-truck-entry',
  'ME-16053-view-sni-advertised-card',
  'ME-18200-feat-table-context-menu-config',
  'ME-19313-view-sni-book-procure',
  'ME-15461-view-sni-suggested-procure',
  'ME-21675-view-werner-pricing-card-rate-quote',
  'ME-14911-feat-late-reason-code-edi-mapping',
  'ME-21253-view-repeat-order-reference-columns',
  'ME-16458-view-customer-fuel-flag',
  'ME-24258-feat-mastermind-admin-fuel-services',
  'ME-21570-view-posting-status-card-truckstop-config',
  'ME-21570-view-posting-status-card-truckertools-config',
  'ME-21570-view-posting-status-card-dat-config',
  'ME-22379-feat-appointment-change-reason',
  'ME-17877-feat-table-column-resize-exclude',
  'ME-26484-auto-schedule-checkbox',
  'ME-35786-feat-employee-migration-v2',
  'ME-35786-feat-customer-migration-v2',
  'ME-35786-feat-carrier-migration-v2',
  'ME-35786-feat-facility-migration-v2',
  'ME-31749-repeat-load-enhancements',
  'ME-37521-feat-uom-enhancements',
  'ME-38932-view-customer-ROE-rules-engine',
  'ME-39185-view-carrier-ROE-rules-engine',
  'ME-48033-feat-multi-route',
  'ME-52626-load-execution-rails-decommission',
  'ME-14627-view-offers-modal-posting-status-card',
  'ME-14627-view-offers-modal-pricing-details-card',
  'ME-14627-view-offers-modal-advertised-price-card',
  'ME-14627-view-add-offer-with-response-board-main',
  'ME-14627-view-add-offer-with-response',
  'ME-30288-feat-load-project-division-business-unit',
  'ME-62633-view-generic-pricing-route',
  'ME-62633-view-generic-pricing-quote',
  'ME-63063-change-facility-on-stop',
] as const;

// ts-unused-exports:disable-next-line
export type JsonFlagKeys = typeof jsonFlagArr[number];
