import { Grid } from '@components/Grid';
import { Icon } from '@components/Icon';
import { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import { EmployeeSimpleFragment } from '@generated/fragments/employeeSimple';
import { EmployeeSimpleV2Fragment } from '@generated/fragments/employeeSimpleV2';
import { useTheme } from '@hooks/useTheme';
import {
  HEADER_HEIGHT,
  IS_ELECTRON,
  IS_LOCAL_DEV,
  SIDEBAR_SEARCH_HEIGHT,
} from '@utils/constants';
import { FS_UNMASK } from '@utils/fullstory';
import { isNil } from 'lodash-es';
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMountedState } from 'react-use';
import { useWhoAmI } from '../WhoAmI';
import { BASE_HEADER_HEIGHT } from './constants';
import { EmployeeEntityTabs } from './EmployeeEntityTabs';
import { Search } from './Search';
import { SearchType } from './Search/types';
import {
  CLOSED_SIDEBAR_WIDTH,
  EmployeeTreeNodeValue,
  SidebarContext,
  SIDEBAR_BORDER_WIDTH,
  SIDEBAR_OPEN_WIDTH,
  SIDEBAR_TRANSITION_MS,
  TOGGLE_AREA_HEIGHT,
} from './util';

const isActive: CSSObject = {
  width: SIDEBAR_OPEN_WIDTH,
};

type EmployeeType = Maybe<EmployeeSimpleFragment | EmployeeSimpleV2Fragment>;

interface SidebarControlsProps {
  setEmployee: (b: EmployeeType) => void;
  employee: EmployeeType;
  setSearchType: Dispatch<SetStateAction<SearchType>>;
  searchType: SearchType;
  updateEmployeeTreeNodeValue: (emp: EmployeeTreeNodeValue) => void;
  isOpen: boolean;
}

const SidebarControls: FC<SidebarControlsProps> = ({
  setEmployee,
  setSearchType,
  searchType,
  employee,
  isOpen,
  updateEmployeeTreeNodeValue,
}) => {
  const { employee: currentEmployee } = useWhoAmI();
  const isMounted = useMountedState();

  const [isReadyToShow, setIsReadyToShow] = useState(false);
  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    setTimeout(
      () => {
        if (isMounted()) {
          setIsReadyToShow(isOpen);
        }
      },
      isOpen ? SIDEBAR_TRANSITION_MS + 50 : 0
    );
    setTimeout(
      () => {
        if (isMounted()) {
          setIsShowing(isOpen);
        }
      },
      isOpen ? SIDEBAR_TRANSITION_MS + 100 : 0
    );
  }, [isMounted, isOpen]);

  useEffect(() => {
    if (currentEmployee && isNil(employee)) {
      setEmployee(currentEmployee);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEmployee]);

  const resetRepFilter = (): void => {
    setEmployee(currentEmployee);
  };
  return (
    <div
      css={{
        opacity: isShowing ? 1 : 0,
        height: '100%',
        display: isReadyToShow ? 'grid' : 'none',
        gridTemplateRows: `${SIDEBAR_SEARCH_HEIGHT}px 1fr`,
        overflow: 'hidden',
        width: isReadyToShow ? SIDEBAR_OPEN_WIDTH : 0,
        transition: 'opacity 200ms',
      }}
    >
      <Search
        setEmployee={setEmployee}
        setSearchType={setSearchType}
        searchType={searchType}
      />
      {employee && (
        <EmployeeEntityTabs
          isSidebarOpen={isOpen}
          employee={employee}
          searchType={searchType}
          resetRepFilter={resetRepFilter}
          updateEmployeeTreeNodeValue={updateEmployeeTreeNodeValue}
        />
      )}
    </div>
  );
};

const TrafficLight = styled.div({
  borderRadius: '100%',
  width: 12,
  height: 12,
  border: '1px solid #777',
});

const TrafficLights: FC = () => {
  return (
    <Grid cols={3} gap={0}>
      <TrafficLight
        css={{
          background: '#FF5F56',
        }}
      />
      <TrafficLight
        css={{
          background: '#FEBC2D',
        }}
      />
      <TrafficLight
        css={{
          background: '#28CA41',
        }}
      />
    </Grid>
  );
};

export const Sidebar: FC = () => {
  const sidebarContextData = useContext(SidebarContext);
  const { isOpen, toggleOpen, isSidebarAllowed } = sidebarContextData;
  const { sidebar, gray } = useTheme();

  const sidebarWrapper: CSSObject = useMemo(
    () => ({
      width: CLOSED_SIDEBAR_WIDTH,
      overflow: 'hidden',
      height: `calc(100vh - ${HEADER_HEIGHT}px)`,
      position: 'sticky',
      top: HEADER_HEIGHT,
      transition: `width ${SIDEBAR_TRANSITION_MS}ms`,
      borderRight: `${SIDEBAR_BORDER_WIDTH}px solid ${gray[200]}`,
    }),
    [gray]
  );

  if (!isSidebarAllowed) {
    if (IS_LOCAL_DEV && !IS_ELECTRON) {
      const notifyWidth = 80;
      return (
        <div
          data-testid="electron-controls-placeholder"
          css={{
            top: 0,
            left: 0,
            height: BASE_HEADER_HEIGHT,
            width: notifyWidth,
            background: 'white',
            marginRight: notifyWidth * -1,
            zIndex: 1000,
            padding: '16px 4px 0 16px',
          }}
          title="This is a placeholder for traffic lights (mac) OS controls when electron app is in use. The intention is to provide a warning that no content should be placed in this area."
        >
          <TrafficLights />
        </div>
      );
    }
    return <div />;
  }

  return (
    <div
      css={[sidebarWrapper, isOpen && isActive, sidebar]}
      data-testid="sidebar"
      data-sidebar-open={isOpen.toString()}
      className={FS_UNMASK}
    >
      <Grid
        xs="1fr"
        css={{
          placeItems: 'center',
          gridTemplateRows: `${TOGGLE_AREA_HEIGHT}px 1fr`,
          height: '100%',
        }}
        gap={0}
      >
        <button
          title={`${isOpen ? 'Close' : 'Open'} Sidebar`}
          css={{ width: '100%', height: '100%' }}
          onClick={(): void => toggleOpen()}
          data-testid="sidebar-toggle"
        >
          <Icon i="bars" color="primary" size={20} />
        </button>
        <SidebarControls {...sidebarContextData} />
      </Grid>
    </div>
  );
};
