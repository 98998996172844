import {
  DateTimeDuration,
  endOfMonth,
  endOfWeek,
  now,
  startOfMonth,
  startOfWeek,
  ZonedDateTime,
} from '@internationalized/date';
import { currentTZ as rawCurrentTZ } from '@utils/time';
import { win } from '@utils/win';
import { parse, toString } from 'duration-fns';
import { noop } from 'lodash-es';
noop(parse, toString);

const currentLocale = win.navigator.language;

export const currentTZ = rawCurrentTZ;

export const startOfDay = (zoned: ZonedDateTime): ZonedDateTime => {
  return zoned.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};

const endOfDay = (zoned: ZonedDateTime): ZonedDateTime => {
  return zoned.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
};

export type RelativeUnit = 'T' | 'M' | 'Y' | 'TW' | 'LW' | 'TM' | 'LM' | 'L30D';

export const relativeNamedMap: Record<
  RelativeUnit,
  {
    label: string;
    startFn: () => ZonedDateTime;
    endFn: () => ZonedDateTime;
  }
> = {
  T: {
    label: 'Today',
    startFn: (): ZonedDateTime => startOfDay(now(currentTZ)),
    endFn: (): ZonedDateTime => endOfDay(now(currentTZ)),
  },
  M: {
    label: 'Tomorrow',
    startFn: (): ZonedDateTime => startOfDay(now(currentTZ).add({ days: 1 })),
    endFn: (): ZonedDateTime => endOfDay(now(currentTZ).add({ days: 1 })),
  },
  Y: {
    label: 'Yesterday',
    startFn: (): ZonedDateTime =>
      startOfDay(now(currentTZ).subtract({ days: 1 })),
    endFn: (): ZonedDateTime => endOfDay(now(currentTZ).subtract({ days: 1 })),
  },
  TW: {
    label: 'This Week',
    startFn: (): ZonedDateTime =>
      startOfDay(startOfWeek(now(currentTZ), currentLocale)),
    endFn: (): ZonedDateTime =>
      endOfDay(endOfWeek(now(currentTZ), currentLocale)),
  },
  LW: {
    label: 'Last Week',
    startFn: (): ZonedDateTime =>
      startOfDay(
        startOfWeek(now(currentTZ).subtract({ days: 7 }), currentLocale)
      ),
    endFn: (): ZonedDateTime =>
      endOfDay(endOfWeek(now(currentTZ).subtract({ days: 7 }), currentLocale)),
  },
  TM: {
    label: 'This Month',
    startFn: (): ZonedDateTime => startOfDay(startOfMonth(now(currentTZ))),
    endFn: (): ZonedDateTime => endOfDay(endOfMonth(now(currentTZ))),
  },
  LM: {
    label: 'Last Month',
    startFn: (): ZonedDateTime =>
      startOfDay(startOfMonth(now(currentTZ).subtract({ months: 1 }))),
    endFn: (): ZonedDateTime =>
      endOfDay(endOfMonth(now(currentTZ).subtract({ months: 1 }))),
  },
  L30D: {
    label: 'Last 30 Days',
    startFn: (): ZonedDateTime =>
      startOfDay(now(currentTZ).subtract({ days: 30 })),
    endFn: (): ZonedDateTime => startOfDay(now(currentTZ)),
  },
};

export interface RelativeObj {
  base: RelativeUnit;
  start: DateTimeDuration;
  end: DateTimeDuration;
}

const relativeStringInstance = Symbol('relative-string-type');
type RelativeStringEncoding = `R1:${RelativeUnit}:${
  | '-'
  | ''}P${number}D:P${number}D`;

export type RelativeString = typeof relativeStringInstance;

/** The supplied string should conform to the current supported relative encoded value. Example `R1:T:P0D:P0D` */
export const relativeString = (s: RelativeStringEncoding): RelativeString =>
  s as unknown as RelativeString;

export const relativeStringToObj = (
  string: Maybe<string>
): RelativeObj | undefined => {
  if (!string) {
    return undefined;
  }
  const [version, namedRelative, offsetStartStr, offsetEndStr] =
    string.split(':');
  noop(version);
  if (!namedRelative || !offsetStartStr || !offsetEndStr) {
    return undefined;
  }
  const offsetStartParsed = parse(offsetStartStr);
  const offsetEndParsed = parse(offsetEndStr);
  return {
    base: namedRelative as RelativeUnit,
    start: offsetStartParsed,
    end: offsetEndParsed,
  };
};

export const parseRelative = (
  string: Maybe<string>
): [Date, Date] | undefined => {
  //
  const obj = relativeStringToObj(string);
  const relativeObj = relativeNamedMap[(obj?.base ?? '') as RelativeUnit];
  if (!relativeObj || !obj) {
    return;
  }
  const startRaw = relativeObj.startFn().add(obj.start);
  const endRaw = relativeObj.endFn().add(obj.end);
  return [startRaw.toDate(), endRaw.toDate()];
};

export const relativeToString = (
  obj: Maybe<RelativeObj>
): RelativeString | undefined => {
  if (!obj) {
    return;
  }
  return `R1:${obj.base}:${toString(obj.start)}:${toString(
    obj.end
  )}` as unknown as RelativeString;
};
