import * as TP from '../types';

import { gql } from '@apollo/client';
import { SeerLoadMasterfindFragmentDoc } from '../fragments/seerLoadMasterfind';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LoadsForMasterfindV2QueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.SeerLoadSearchV2Filter>;
  orderBy?: TP.InputMaybe<TP.SeerLoadSearchV2OrderBy>;
  pagination?: TP.InputMaybe<TP.PaginationInput>;
}>;


export type LoadsForMasterfindV2Query = (
  { readonly __typename?: 'Query' }
  & { readonly seerSearchLoadSearchV2: (
    { readonly __typename?: 'SeerLoadSearchV2Result' }
    & { readonly edges: ReadonlyArray<(
      { readonly __typename?: 'SeerLoadSearchV2Edge' }
      & { readonly node: (
        { readonly __typename?: 'SeerLoadSearchV2' }
        & Pick<TP.SeerLoadSearchV2, 'id' | 'loadCode' | 'routeCode' | 'orderNumber' | 'puStartDate' | 'puEndDate' | 'puStartTimeZone' | 'puEndTimeZone' | 'originCity' | 'originState' | 'destinationCity' | 'destinationState' | 'customer' | 'carrier' | 'refNumber'>
      ) }
    )> }
  ) }
);


export const LoadsForMasterfindV2Document = gql`
    query loadsForMasterfindV2($filter: SeerLoadSearchV2Filter, $orderBy: SeerLoadSearchV2OrderBy, $pagination: PaginationInput) {
  seerSearchLoadSearchV2(
    filter: $filter
    orderBy: $orderBy
    pagination: $pagination
  ) {
    edges {
      node {
        ...SeerLoadMasterfind
      }
    }
  }
}
    ${SeerLoadMasterfindFragmentDoc}`;

/**
 * __useLoadsForMasterfindV2Query__
 *
 * To run a query within a React component, call `useLoadsForMasterfindV2Query` and pass it any options that fit your needs.
 * When your component renders, `useLoadsForMasterfindV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadsForMasterfindV2Query({
 *   variables: {
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useLoadsForMasterfindV2Query(baseOptions?: Apollo.QueryHookOptions<LoadsForMasterfindV2Query, LoadsForMasterfindV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadsForMasterfindV2Query, LoadsForMasterfindV2QueryVariables>(LoadsForMasterfindV2Document, options);
      }
export function useLoadsForMasterfindV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadsForMasterfindV2Query, LoadsForMasterfindV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadsForMasterfindV2Query, LoadsForMasterfindV2QueryVariables>(LoadsForMasterfindV2Document, options);
        }
export type LoadsForMasterfindV2QueryHookResult = ReturnType<typeof useLoadsForMasterfindV2Query>;
export type LoadsForMasterfindV2LazyQueryHookResult = ReturnType<typeof useLoadsForMasterfindV2LazyQuery>;
export type LoadsForMasterfindV2QueryResult = Apollo.QueryResult<LoadsForMasterfindV2Query, LoadsForMasterfindV2QueryVariables>;