import * as TP from '../types';

import { gql } from '@apollo/client';
import { EmployeeInfoFragmentDoc } from '../fragments/employeeInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WhoAmIDataFragment = (
  { readonly __typename?: 'WhoAmI' }
  & { readonly user?: TP.Maybe<(
    { readonly __typename?: 'User' }
    & Pick<TP.User, 'id' | 'email'>
  )>, readonly employee?: TP.Maybe<(
    { readonly __typename?: 'Employee' }
    & Pick<TP.Employee, 'companyEmail' | 'divisionId' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeCompanyLevelId' | 'employeeDepartmentId' | 'employeeDepartmentLevelId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeRoleId' | 'employeeShirtSizeId' | 'employeeSuffixId' | 'employeeTimeId' | 'employeeTypeId' | 'ext' | 'firstName' | 'firstNameDotLastName' | 'fullName' | 'hireDate' | 'id' | 'lastName' | 'managerId' | 'middleName' | 'mobilePhone' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'relationship' | 'slackName' | 'subRegion' | 'termDate' | 'title' | 'userId'>
    & { readonly division?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>, readonly employeeGroup?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>, readonly employeeOffice?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>, readonly employeeStatus: (
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'active'>
    ), readonly manager?: TP.Maybe<(
      { readonly __typename?: 'Employee' }
      & Pick<TP.Employee, 'companyEmail' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeDepartmentId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeSuffixId' | 'ext' | 'firstName' | 'fullName' | 'id' | 'lastName' | 'middleName' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'relationship' | 'title' | 'userId' | 'managerId'>
    )> }
  )>, readonly permissions: ReadonlyArray<(
    { readonly __typename?: 'UserPermission' }
    & Pick<TP.UserPermission, 'resource' | 'scopes'>
  )> }
);

export type WhoAmIQueryVariables = TP.Exact<{ [key: string]: never; }>;


export type WhoAmIQuery = (
  { readonly __typename?: 'Query' }
  & { readonly whoAmI?: TP.Maybe<(
    { readonly __typename?: 'WhoAmI' }
    & { readonly user?: TP.Maybe<(
      { readonly __typename?: 'User' }
      & Pick<TP.User, 'id' | 'email'>
    )>, readonly employee?: TP.Maybe<(
      { readonly __typename?: 'Employee' }
      & Pick<TP.Employee, 'companyEmail' | 'divisionId' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeCompanyLevelId' | 'employeeDepartmentId' | 'employeeDepartmentLevelId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeRoleId' | 'employeeShirtSizeId' | 'employeeSuffixId' | 'employeeTimeId' | 'employeeTypeId' | 'ext' | 'firstName' | 'firstNameDotLastName' | 'fullName' | 'hireDate' | 'id' | 'lastName' | 'managerId' | 'middleName' | 'mobilePhone' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'relationship' | 'slackName' | 'subRegion' | 'termDate' | 'title' | 'userId'>
      & { readonly division?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
      )>, readonly employeeGroup?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
      )>, readonly employeeOffice?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
      )>, readonly employeeStatus: (
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'active'>
      ), readonly manager?: TP.Maybe<(
        { readonly __typename?: 'Employee' }
        & Pick<TP.Employee, 'companyEmail' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeDepartmentId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeSuffixId' | 'ext' | 'firstName' | 'fullName' | 'id' | 'lastName' | 'middleName' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'relationship' | 'title' | 'userId' | 'managerId'>
      )> }
    )>, readonly permissions: ReadonlyArray<(
      { readonly __typename?: 'UserPermission' }
      & Pick<TP.UserPermission, 'resource' | 'scopes'>
    )> }
  )> }
);

export const WhoAmIDataFragmentDoc = gql`
    fragment WhoAmIData on WhoAmI {
  user {
    id
    email
  }
  employee {
    ...EmployeeInfo
  }
  permissions {
    resource
    scopes
  }
}
    ${EmployeeInfoFragmentDoc}`;
export const WhoAmIDocument = gql`
    query whoAmI {
  whoAmI {
    ...WhoAmIData
  }
}
    ${WhoAmIDataFragmentDoc}`;

/**
 * __useWhoAmIQuery__
 *
 * To run a query within a React component, call `useWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoAmIQuery(baseOptions?: Apollo.QueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
      }
export function useWhoAmILazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
        }
export type WhoAmIQueryHookResult = ReturnType<typeof useWhoAmIQuery>;
export type WhoAmILazyQueryHookResult = ReturnType<typeof useWhoAmILazyQuery>;
export type WhoAmIQueryResult = Apollo.QueryResult<WhoAmIQuery, WhoAmIQueryVariables>;