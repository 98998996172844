import * as TP from '../types';

import { gql } from '@apollo/client';
export type EmployeeDetailsFragment = (
  { readonly __typename?: 'Employee' }
  & Pick<TP.Employee, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
  & { readonly employeeRole?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name'>
  )>, readonly employeeGroup?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name'>
  )>, readonly employeeOffice?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name'>
  )>, readonly division?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name'>
  )>, readonly manager?: TP.Maybe<(
    { readonly __typename?: 'Employee' }
    & Pick<TP.Employee, 'id' | 'fullName'>
  )> }
);

export type EmployeeDetailsEmployeeTreeFragment = (
  { readonly __typename?: 'Employee' }
  & Pick<TP.Employee, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
  & { readonly children?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'Employee' }
    & Pick<TP.Employee, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
    & { readonly children?: TP.Maybe<ReadonlyArray<(
      { readonly __typename?: 'Employee' }
      & Pick<TP.Employee, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
      & { readonly children?: TP.Maybe<ReadonlyArray<(
        { readonly __typename?: 'Employee' }
        & Pick<TP.Employee, 'id' | 'fullName' | 'title' | 'email' | 'userId' | 'phoneNumber' | 'employeeDepartmentId'>
        & { readonly employeeRole?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly employeeGroup?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly employeeOffice?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly division?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly manager?: TP.Maybe<(
          { readonly __typename?: 'Employee' }
          & Pick<TP.Employee, 'id' | 'fullName'>
        )> }
      )>>, readonly employeeRole?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly employeeGroup?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly employeeOffice?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly division?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name'>
      )>, readonly manager?: TP.Maybe<(
        { readonly __typename?: 'Employee' }
        & Pick<TP.Employee, 'id' | 'fullName'>
      )> }
    )>>, readonly employeeRole?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly employeeGroup?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly employeeOffice?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly division?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    )>, readonly manager?: TP.Maybe<(
      { readonly __typename?: 'Employee' }
      & Pick<TP.Employee, 'id' | 'fullName'>
    )> }
  )>>, readonly employeeRole?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name'>
  )>, readonly employeeGroup?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name'>
  )>, readonly employeeOffice?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name'>
  )>, readonly division?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name'>
  )>, readonly manager?: TP.Maybe<(
    { readonly __typename?: 'Employee' }
    & Pick<TP.Employee, 'id' | 'fullName'>
  )> }
);

export const EmployeeDetailsFragmentDoc = gql`
    fragment EmployeeDetails on Employee {
  id
  fullName
  title
  email
  userId
  phoneNumber
  employeeDepartmentId
  employeeRole {
    id
    name
  }
  employeeGroup {
    id
    name
  }
  employeeOffice {
    id
    name
  }
  division {
    id
    name
  }
  manager {
    id
    fullName
  }
}
    `;
export const EmployeeDetailsEmployeeTreeFragmentDoc = gql`
    fragment EmployeeDetailsEmployeeTree on Employee {
  ...EmployeeDetails
  children {
    ...EmployeeDetails
    children {
      ...EmployeeDetails
      children {
        ...EmployeeDetails
      }
    }
  }
}
    ${EmployeeDetailsFragmentDoc}`;