import { MIN_BUTTON_HEIGHT } from '@components/Button';
import { CSSObject, Global } from '@emotion/react';
import { useTheme } from '@hooks/useTheme';
// eslint-disable-next-line no-restricted-imports
import {
  Position,
  TooltipPopup,
  TooltipPopupProps,
  useTooltip,
} from '@reach/tooltip';
import '@reach/tooltip/styles.css';
import { win } from '@utils/win';
import { TOOLTIP } from '@utils/zIndex';
import { isString } from 'lodash-es';
import { cloneElement, FC, ReactElement } from 'react';

const VISUAL_MARGIN = 8;

const centered: Position = (triggerRect, tooltipRect) => {
  const triggerCenter =
    (triggerRect?.left ?? 0) + (triggerRect?.width ?? 0) / 2;
  const left = triggerCenter - (tooltipRect?.width ?? 0) / 2;
  const maxLeft = win.innerWidth - (tooltipRect?.width ?? 0) - 2;
  return {
    left: Math.min(Math.max(2, left), maxLeft) + win.scrollX,
    top: (triggerRect?.bottom ?? 0) + VISUAL_MARGIN + win.scrollY,
  };
};

const top: Position = (triggerRect, tooltipRect) => {
  const triggerCenter =
    (triggerRect?.left ?? 0) + (triggerRect?.width ?? 0) / 2;
  const left = triggerCenter - (tooltipRect?.width ?? 0) / 2;
  const maxLeft = win.innerWidth - (tooltipRect?.width ?? 0) - 2;
  return {
    left: Math.min(Math.max(2, left), maxLeft) + win.scrollX,
    top:
      (triggerRect?.bottom ?? 0) -
      (tooltipRect?.height ?? 0) -
      MIN_BUTTON_HEIGHT -
      VISUAL_MARGIN +
      win.scrollY,
  };
};

export type PositionType = 'centered' | 'bottom' | 'top';

export interface TooltipProps extends Omit<TooltipPopupProps, 'triggerRect'> {
  children: ReactElement;
  hidden?: boolean;
  positionType?: PositionType;
}

const bottom: Position = (triggerRect, tooltipRect) => {
  const triggerCenter = (triggerRect?.left ?? 0) + (triggerRect?.width ?? 0);
  const left = triggerCenter - (tooltipRect?.width ?? 0);
  const maxLeft = win.innerWidth - (tooltipRect?.width ?? 0) - 2;
  return {
    left: Math.min(Math.max(2, left), maxLeft) + win.scrollX + 5,
    top: (triggerRect?.bottom ?? 0) + 5 + win.scrollY,
  };
};

const positions = {
  centered,
  bottom,
  top,
};

export const Tooltip: FC<TooltipProps> = ({
  children,
  label,
  ariaLabel,
  hidden,
  positionType,
  ...rest
}) => {
  const [trigger, tooltip] = useTooltip();

  const { tooltip: tooltipTheme } = useTheme();

  const tooltipStyles: CSSObject = {
    ...tooltipTheme,
    border: 'none',
    borderRadius: 2,
    padding: '0.5em 1em',
    display: hidden ? 'none' : 'block',
    justifyContent: 'center',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
    whiteSpace: 'pre-line',
  };

  return (
    <>
      <Global
        styles={{
          'html [data-reach-tooltip]': {
            zIndex: TOOLTIP,
          },
        }}
      />
      {cloneElement(children, trigger)}
      {label && (
        <TooltipPopup
          {...tooltip}
          css={tooltipStyles}
          position={positions[positionType || 'centered']}
          label={label}
          ariaLabel={ariaLabel || isString(label) ? (label as string) : ''}
          {...rest}
        />
      )}
    </>
  );
};

interface ButtonTooltip extends TooltipProps {
  hidden?: boolean;
}

/** Helper component when you need to render a tooltip with a button as trigger. Without this component it can be difficult to properly trigger the tooltip display if the button is disabled (due to browser default pointer-events behavior for disabled elements) */
export const ButtonTooltip: FC<ButtonTooltip> = ({
  hidden,
  children,
  ...rest
}) => (
  <div
    css={{
      display: 'inline-block',
      position: 'relative',
      cursor: !hidden ? 'not-allowed !important' : 'pointer',
    }}
  >
    {children}
    <Tooltip
      {...rest}
      hidden={hidden}
      label={
        isString(rest.label) ? (
          <div dangerouslySetInnerHTML={{ __html: rest.label }} />
        ) : (
          rest.label
        )
      }
    >
      {!hidden ? (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
      ) : (
        <></>
      )}
    </Tooltip>
  </div>
);
