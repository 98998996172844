import { noop } from 'lodash-es';
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

interface ViewOnlyValueType {
  isViewOnly: boolean;
  setViewOnly: Dispatch<SetStateAction<boolean>>;
}

const ViewOnlyContext = createContext<ViewOnlyValueType>({
  isViewOnly: false,
  setViewOnly: () => noop(),
});

interface Props {
  viewOnly: boolean;
  children?: ReactNode;
}

export const ViewOnlyProvider: FC<Props> = ({ viewOnly, children }) => {
  const [isViewOnly, setViewOnly] = useState(viewOnly);
  const value = useMemo(
    (): ViewOnlyValueType => ({
      isViewOnly,
      setViewOnly,
    }),
    [isViewOnly, setViewOnly]
  );
  return (
    <ViewOnlyContext.Provider value={value}>
      {children}
    </ViewOnlyContext.Provider>
  );
};

export const useViewOnly = (): ViewOnlyValueType => useContext(ViewOnlyContext);
