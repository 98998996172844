import { deprecatedCreate, DeprecatedFlagDefinition } from './util';

/** These flags are deprecated because they do not adhere to our naming schema */
type DeprecatedFrozenBoolFlagKeys =
  | 'admin-release-lock'
  | 'admin-vendor-required-docs'
  | 'app-allow-debug'
  | 'app-reload-on-auth-timeout'
  | 'app-serviceworker-reload-sibling-tabs-on-controllerchange'
  | 'app-show-offline-message'
  | 'app-verbose-error-display'
  | 'carrier-external-status-for-rmis-sync'
  | 'customer-CRM'
  | 'electronic-tracking'
  | 'home-received-actions'
  | 'load-documents-generate-bol'
  | 'nav-admin'
  | 'nav-admin-client-defined-values-menu'
  | 'nav-employee-menu'
  | 'nav-user-access'
  | 'route-carrier-documents'
  | 'route-carrier-load-history'
  | 'route-load-edi'
  | 'route-upload-tenant'
  | 'route-stop-disable-arrive-depart'
  | 'temp-restrict-molo-stop-times'
  | 'temp-route-load-document-preview'
  | 'test-bool-flag'
  | 'track-page-auto-load'
  | 'truck-entry-v2'
  | 'temp-schedule-stop-appointments'
  | 'rmis-request-update'
  | 'rmis-send-email'
  | 'vendor-associated-required-documents'
  | 'offers-page-auto-load';

export const deprecatedRawBooleanFlags: Record<
  DeprecatedFrozenBoolFlagKeys,
  DeprecatedFlagDefinition
> = {
  'admin-release-lock': deprecatedCreate({
    description:
      'Admin navigation menu option to Release Lock. User must have flag nav-admin = true for visibility.',
    temporary: false,
  }),
  'admin-vendor-required-docs': deprecatedCreate({
    description: 'Unknown',
    temporary: false,
  }),
  'app-allow-debug': deprecatedCreate({
    description: 'Unknown',
    temporary: false,
  }),
  'app-reload-on-auth-timeout': deprecatedCreate({
    description: 'Unknown',
    temporary: false,
  }),
  'app-serviceworker-reload-sibling-tabs-on-controllerchange': deprecatedCreate(
    {
      description: 'Unknown',
      temporary: false,
    }
  ),
  'app-show-offline-message': deprecatedCreate({
    description: 'Unknown',
    temporary: false,
  }),
  'app-verbose-error-display': deprecatedCreate({
    description: 'Unknown',
    temporary: false,
  }),
  'carrier-external-status-for-rmis-sync': deprecatedCreate({
    description:
      'Turn on RMIS Delta Sync to receive updates from RMIS that will update ext status/insurance in carrier profiles',
    temporary: false,
  }),
  'customer-CRM': deprecatedCreate({
    description: 'Unknown',
    temporary: false,
  }),
  'electronic-tracking': deprecatedCreate({
    description:
      'Disables three driver tracking fields and one display: Tracking method, checkbox, ELD vehicle ID, and Tracking status',
  }),
  'home-received-actions': deprecatedCreate({
    description: 'Unknown',
    temporary: false,
  }),
  'load-documents-generate-bol': deprecatedCreate({
    description:
      "Visibility to 'Generate BOL' button in Documents tab of Load, which allows user to generate bill of lading",
    temporary: false,
  }),
  'nav-admin': deprecatedCreate({
    description:
      "Visibility to 'Admin' in the top nav bar. Users must have this = true for visibility to any options in the Admin list",
    temporary: false,
  }),
  'nav-admin-client-defined-values-menu': deprecatedCreate({
    description: "Hides 'Client Defined Values' from Admin menu",
    temporary: false,
  }),
  'nav-employee-menu': deprecatedCreate({
    description: 'To display the employee links in main nav',
    temporary: false,
  }),
  'nav-user-access': deprecatedCreate({
    description: 'Unknown',
    temporary: false,
  }),
  'route-carrier-documents': deprecatedCreate({
    description: 'Visibility to data in Documents tab within carrier profile',
    temporary: false,
  }),
  'route-carrier-load-history': deprecatedCreate({
    description:
      "Visibility to data in the 'Route List' tab within Carrier profile",
    temporary: false,
  }),
  'route-load-edi': deprecatedCreate({
    description: 'Provides visibility to data in EDI log within the load',
    temporary: false,
  }),
  'route-upload-tenant': deprecatedCreate({
    description: 'Unknown',
    temporary: false,
  }),
  'route-stop-disable-arrive-depart': deprecatedCreate({
    description: 'Unknown',
    temporary: false,
  }),
  'temp-restrict-molo-stop-times': deprecatedCreate({
    description: 'Unknown',
    defaults: {
      offVariation: 0,
      onVariation: 0,
    },
  }),
  'temp-route-load-document-preview': deprecatedCreate({
    description: 'Unknown',
  }),
  'test-bool-flag': deprecatedCreate({
    description: 'Unknown',
    temporary: false,
  }),
  'track-page-auto-load': deprecatedCreate({
    description:
      'When served TRUE, the data on the Tracking Screen will automatically populate upon page load. If FALSE, the users will need to select their filters and explicitly search.',
    temporary: false,
  }),
  'truck-entry-v2': deprecatedCreate({
    description: 'Enables version 2 of truck entry',
  }),
  'temp-schedule-stop-appointments': deprecatedCreate({
    description: 'Unknown',
  }),
  'rmis-request-update': deprecatedCreate({
    description:
      "This will display the 'Request Update' button within Carrier > Maintenance > Insurance for an immediate sync via RMIS",
    temporary: false,
  }),
  'rmis-send-email': deprecatedCreate({
    description:
      'This button has been removed from the UI and this flag no longer does anything.',
    temporary: false,
  }),
  'vendor-associated-required-documents': deprecatedCreate({
    description: 'Unknown',
    temporary: false,
  }),
  'offers-page-auto-load': deprecatedCreate({
    description:
      'Automatically loads offers board using the default criteria when user accesses the screen',
    temporary: false,
  }),
};

type DeprecatedFrozenNumberFlagKeys =
  | 'app-keycloak-min-validity-seconds'
  | 'app-keycloak-trigger-refresh-seconds'
  | 'test-number-flag'
  | 'feat-list-loads-limit'
  | 'home-pending-received-page-limit'
  | 'route-lock-polling-interval-ms'
  | 'accounting-page-limit'
  | 'route-lock-expiration-warning-modal-seconds'
  | 'offers-board-routes-batch-size'
  | 'track-page-auto-polling-ms';

export const deprecatedRawNumberFlags: Record<
  DeprecatedFrozenNumberFlagKeys,
  DeprecatedFlagDefinition
> = {
  'app-keycloak-min-validity-seconds': deprecatedCreate({
    temporary: false,
    description: 'Min validity for keycloak updateToken() command',
    variations: [
      { value: 5, name: 'default' },
      { value: 30, name: 'long' },
    ],
  }),
  'app-keycloak-trigger-refresh-seconds': deprecatedCreate({
    temporary: false,
    description: 'Time in seconds to trigger refresh loop',
    variations: [
      { value: 300, name: 'default' },
      { value: 60, name: 'short' },
    ],
  }),
  'test-number-flag': deprecatedCreate({
    temporary: false,
    description: 'Unknown',
    variations: [
      { value: 1, name: 'one' },
      { value: 42, name: 'fourty-two' },
    ],
  }),
  'feat-list-loads-limit': deprecatedCreate({
    temporary: false,
    description: 'How many results to limit load search screen to',
    variations: [
      { value: 100, name: 'default' },
      { value: 500, name: 'high' },
      { value: 25, name: 'low' },
    ],
  }),
  'home-pending-received-page-limit': deprecatedCreate({
    temporary: false,
    description: 'Unknown',
    variations: [
      {
        value: 50,
        name: 'default',
      },
      {
        value: 15,
        name: 'low',
      },
    ],
  }),
  'route-lock-polling-interval-ms': deprecatedCreate({
    temporary: false,
    description:
      'How often the Load CreateOrEdit view polls for an update the Lock in milliseconds',
    variations: [
      { value: 1000, name: 'One Second' },
      { value: 3000, name: 'Three Seconds' },
      { value: 5000, name: 'Five Seconds' },
    ],
  }),
  'accounting-page-limit': deprecatedCreate({
    temporary: false,
    description: 'Unknown',
    variations: [
      {
        value: 50,
        name: 'default',
      },
      {
        value: 150,
        name: 'high',
      },
    ],
  }),
  'route-lock-expiration-warning-modal-seconds': deprecatedCreate({
    temporary: false,
    description: 'When to show the warning modal before lock expiration',
    variations: [
      { value: 30, name: 'Default (30 Seconds)' },
      { value: 15, name: '15 Seconds' },
      { value: 60, name: '60 Seconds' },
    ],
  }),

  'offers-board-routes-batch-size': deprecatedCreate({
    temporary: false,
    description: 'Number of routes pulled at once by offers board query',
    variations: [
      { value: 300, name: 'default' },
      { value: 30, name: 'low' },
      { value: 1000, name: 'high' },
    ],
  }),
  'track-page-auto-polling-ms': deprecatedCreate({
    temporary: false,
    description: 'How often tracking page polling happens',
    variations: [
      { value: 0, name: 'default' },
      { value: 180000, name: 'long' },
    ],
  }),
};
