import {
  defaultTheme,
  Provider as SpectrumProvider,
} from '@adobe/react-spectrum';
import { useFlag } from '@components/Flag';
import { Grid } from '@components/Grid';
import { Padding } from '@components/Padding';
import { Route } from '@components/Route';
import { initWindowManager } from '@hooks/useElectronWindowManagement';
import { UserPreferences } from '@hooks/UserPreferences';
import { useTheme } from '@hooks/useTheme';
import { HEADER_HEIGHT } from '@utils/constants';
import { useFullstory } from '@utils/fullstory';
import { history } from '@utils/history';
import { win } from '@utils/win';
import { PermissionsProvider } from '@views/Common/Permissions';
import { FC, ReactNode, StrictMode } from 'react';
import { Router, Switch } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNetwork } from 'react-use';
import { QueryParamProvider } from 'use-query-params';
import { SaveProvider } from '../components/SaveContext';
import { Theme } from '../components/Theme';
import { AppRoutes } from './AppRoutes';
import { AuthWrapper } from './auth/AuthWrapper';
import { MasteryKeycloakProvider } from './auth/keycloak';
import { GlobalVariables } from './GlobalVariables';
import { Header } from './layout/Header';
import { Masterfind, MasterfindProvider } from './Masterfind';
import { NavShortcuts } from './NavShortcuts';
import { Sidebar } from './Sidebar';
import { SidebarProvider } from './Sidebar/util';
import { AppStyles } from './Styles';
import { TenantConfig } from './TenantConfig';

initWindowManager();

const Content: FC = ({ children }) => {
  const {
    shell: { content },
  } = useTheme();
  const { online } = useNetwork({
    online: win.navigator.onLine,
  });
  const showOfflineMessage = useFlag('app-show-offline-message');
  if (!online && showOfflineMessage) {
    return (
      <Padding a={2}>
        <h2>No active network connection.</h2>
      </Padding>
    );
  }
  return (
    <div id="content" css={{ height: '100%', ...content }}>
      {children}
    </div>
  );
};

export const App: FC = () => {
  useFullstory();
  return (
    <StrictMode>
      <GlobalVariables />
      <SaveProvider>
        <UserPreferences>
          <SpectrumProvider theme={defaultTheme}>
            <Theme>
              <AppStyles />
              <ToastContainer limit={4} />
              <NavShortcuts />
              <MasteryKeycloakProvider>
                <Router history={history}>
                  <QueryParamProvider ReactRouterRoute={Route}>
                    <Route
                      render={(): ReactNode => {
                        return (
                          <Switch>
                            <AuthWrapper>
                              <PermissionsProvider>
                                <SidebarProvider>
                                  <TenantConfig>
                                    <Header />
                                    <Grid
                                      xs="min-content 1fr"
                                      gap={0}
                                      css={{
                                        minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
                                        gridTemplateRows: 'auto',
                                      }}
                                    >
                                      <Sidebar />
                                      <div
                                        css={{
                                          height: '100%',
                                        }}
                                      >
                                        <MasterfindProvider>
                                          <Content>
                                            <AppRoutes />
                                            <Masterfind />
                                          </Content>
                                        </MasterfindProvider>
                                      </div>
                                    </Grid>
                                  </TenantConfig>
                                </SidebarProvider>
                              </PermissionsProvider>
                            </AuthWrapper>
                          </Switch>
                        );
                      }}
                    />
                  </QueryParamProvider>
                </Router>
              </MasteryKeycloakProvider>
            </Theme>
          </SpectrumProvider>
        </UserPreferences>
      </SaveProvider>
    </StrictMode>
  );
};
