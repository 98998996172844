export type PermissionsScopeNames =
  | 'accounting:advance:activate'
  | 'accounting:advance:approve'
  | 'accounting:advance:block'
  | 'accounting:advance:cancel'
  | 'accounting:advance:issue'
  | 'accounting:costDetail:VendorFinancialStatusAwaitingPaperwork:create'
  | 'accounting:costDetail:VendorFinancialStatusAwaitingPaperwork:edit'
  | 'accounting:costDetail:VendorFinancialStatusComplete:create'
  | 'accounting:costDetail:VendorFinancialStatusComplete:edit'
  | 'accounting:costDetail:VendorFinancialStatusException:create'
  | 'accounting:costDetail:VendorFinancialStatusException:edit'
  | 'accounting:costDetail:VendorFinancialStatusIncomplete:create'
  | 'accounting:costDetail:VendorFinancialStatusIncomplete:edit'
  | 'accounting:costDetail:VendorFinancialStatusNone:create'
  | 'accounting:costDetail:VendorFinancialStatusNone:edit'
  | 'accounting:costDetail:VendorFinancialStatusSettled:create'
  | 'accounting:costDetail:VendorFinancialStatusSettled:edit'
  | 'accounting:costDetail:VendorFinancialStatusUnmapped:create'
  | 'accounting:costDetail:VendorFinancialStatusUnmapped:edit'
  | 'accounting:costDetail:VendorFinancialStatusVouchered:create'
  | 'accounting:costDetail:VendorFinancialStatusVouchered:edit'
  | 'accounting:costDetail:VendorFinancialStatusAwaitingRequirements:create'
  | 'accounting:costDetail:VendorFinancialStatusAwaitingRequirements:edit'
  | 'accounting:costDetail:view'
  | 'accounting:customerCreditMemo:create'
  | 'accounting:customerCreditMemo:process'
  | 'accounting:customerInvoice:create'
  | 'accounting:customerInvoice:view'
  | 'accounting:customerOrderRequiredDocuments:edit'
  | 'accounting:customerSettlement:edit'
  | 'accounting:customerSettlement:view'
  | 'accounting:invoiceBatch:override'
  | 'accounting:invoiceReqBypass:edit'
  | 'accounting:invoiceReqBypass:view'
  | 'accounting:invoices:view'
  | 'accounting:rateDetail:OrderFinancialStatusAwaitingPaperwork:create'
  | 'accounting:rateDetail:OrderFinancialStatusAwaitingPaperwork:edit'
  | 'accounting:rateDetail:OrderFinancialStatusComplete:create'
  | 'accounting:rateDetail:OrderFinancialStatusComplete:edit'
  | 'accounting:rateDetail:OrderFinancialStatusException:create'
  | 'accounting:rateDetail:OrderFinancialStatusException:edit'
  | 'accounting:rateDetail:OrderFinancialStatusIncomplete:create'
  | 'accounting:rateDetail:OrderFinancialStatusIncomplete:edit'
  | 'accounting:rateDetail:OrderFinancialStatusInvoiced:create'
  | 'accounting:rateDetail:OrderFinancialStatusInvoiced:edit'
  | 'accounting:rateDetail:OrderFinancialStatusNone:create'
  | 'accounting:rateDetail:OrderFinancialStatusNone:edit'
  | 'accounting:rateDetail:OrderFinancialStatusSettled:create'
  | 'accounting:rateDetail:OrderFinancialStatusSettled:edit'
  | 'accounting:rateDetail:OrderFinancialStatusUnmapped:create'
  | 'accounting:rateDetail:OrderFinancialStatusUnmapped:edit'
  | 'accounting:rateDetail:OrderFinancialStatusAwaitingRequirements:create'
  | 'accounting:rateDetail:OrderFinancialStatusAwaitingRequirements:edit'
  | 'accounting:rateDetail:view'
  | 'accounting:ratingSource:edit'
  | 'accounting:vendorCreditMemo:create'
  | 'accounting:vendorCreditMemo:process'
  | 'accounting:vendorInvoice:create'
  | 'accounting:vendorInvoice:edit'
  | 'accounting:vendorInvoice:view'
  | 'accounting:vendorRouteRequiredDocuments:edit'
  | 'accounting:vendorSettlement:edit'
  | 'accounting:vendorSettlement:view'
  | 'accounting:vendorVoucher:create'
  | 'accounting:vendorVoucher:view'
  | 'accounting:vendorVoucherAdj:edit'
  | 'accounting:vendorVoucherReqBypass:edit'
  | 'accounting:vendorVoucherReqBypass:view'
  | 'accounting:DisableRouteVendorFinancials:view'
  | 'accounting:DisableRouteVendorFinancials:edit'
  | 'accounting:view'
  | 'accounting:voucherBatch:override'
  | 'accounting:vouchers:view'
  | 'admin:accounting:edit'
  | 'admin:accounting:view'
  | 'admin:clientDefinedTypes:edit'
  | 'admin:clientDefinedTypes:view'
  | 'admin:regionUpload:edit'
  | 'admin:regionUpload:view'
  | 'admin:releaseLock:edit'
  | 'admin:releaseLock:view'
  | 'admin:userAccess:view'
  | 'admin:vendorRequiredDocs:edit'
  | 'admin:vendorRequiredDocs:view'
  | 'admin:view'
  | 'book:availableLoads:view'
  | 'book:myMatches:view'
  | 'book:stateMap:view'
  | 'book:view'
  | 'carrier:accounting:chargeTypes:edit'
  | 'carrier:accounting:edit'
  | 'carrier:accounting:view'
  | 'carrier:addresses:edit'
  | 'carrier:addresses:view'
  | 'carrier:BINCheckbox:edit'
  | 'carrier:BINCheckbox:view'
  | 'carrier:carrierAuditsExternalStatus:view'
  | 'carrier:certifications:create'
  | 'carrier:certifications:delete'
  | 'carrier:certifications:edit'
  | 'carrier:certifications:view'
  | 'carrier:code:edit'
  | 'carrier:compliance:edit'
  | 'carrier:contacts:edit'
  | 'carrier:contacts:view'
  | 'carrier:create'
  | 'carrier:edit'
  | 'carrier:externalStatus:edit'
  | 'carrier:geographies:edit'
  | 'carrier:geographies:view'
  | 'carrier:insurances:edit'
  | 'carrier:insurances:view'
  | 'carrier:identifiers:edit'
  | 'carrier:identifiers:view'
  | 'carrier:intrastateonly:edit'
  | 'carrier:intrastateonly:view'
  | 'carrier:mainRep:edit'
  | 'carrier:reps:edit'
  | 'carrier:reps:view'
  | 'carrier:services:create'
  | 'carrier:services:delete'
  | 'carrier:services:edit'
  | 'carrier:services:view'
  | 'carrier:serviceprofile:edit'
  | 'carrier:serviceprofile:view'
  | 'carrier:status:edit'
  | 'carrier:view'
  | 'carrier:routelist:download'
  | 'carrierCostQuotes:add'
  | 'carrierCostQuotes:edit'
  | 'carrierCostQuotes:view'
  | 'carrierCRM:edit'
  | 'carrierCRM:view'
  | 'carrierDocuments:create'
  | 'carrierDocuments:edit'
  | 'carrierDocuments:view'
  | 'carrierSetting:edit'
  | 'carrierSetting:view'
  | 'carrierTenderProcure:action'
  | 'carrierTenderProcure:edit'
  | 'carrierTenderProcure:view'
  | 'carrierTenderSettings:edit'
  | 'carrierTenderSettings:view'
  | 'carrier:regions:view'
  | 'carrier:regions:edit'
  | 'carrier:releaseMethods:edit'
  | 'carrier:releaseMethods:view'
  | 'commissions:customerCarrierSplitOverride:edit'
  | 'containerReservations:admin'
  | 'customer:accounting:chargeTypes:edit'
  | 'customer:accounting:edit'
  | 'customer:accounting:view'
  | 'customer:accounting:canvas:edit'
  | 'customer:accounting:canvas:view'
  | 'customer:addresses:edit'
  | 'customer:addresses:view'
  | 'customer:code:edit'
  | 'customer:contacts:edit'
  | 'customer:contacts:view'
  | 'customer:create'
  | 'customer:dataExchange:view'
  | 'customer:dataExchange:edit'
  | 'customer:edit'
  | 'customer:facilityRelationship:edit'
  | 'customer:facilityRelationship:view'
  | 'customer:fuel:edit'
  | 'customer:identifiers:edit'
  | 'customer:identifiers:view'
  | 'customer:insurance:edit'
  | 'customer:insurance:view'
  | 'customer:mainRep:edit'
  | 'customer:reps:edit'
  | 'customer:reps:view'
  | 'customer:trackingmethod:edit'
  | 'customer:standing:edit'
  | 'customer:standing:view'
  | 'customer:view'
  | 'customer:orderList:download'
  | 'customerCRM:edit'
  | 'customerCRM:view'
  | 'customerDocument:create'
  | 'customerDocument:edit'
  | 'customerDocument:view'
  | 'CustomerLoadDefaults:edit'
  | 'CustomerLoadDefaults:view'
  | 'customerQuote:edit'
  | 'customerQuote:view'
  | 'default:no:scope'
  | 'driver:accounting:view'
  | 'driver:addresses:edit'
  | 'driver:addresses:view'
  | 'driver:create'
  | 'driver:edit'
  | 'driver:general:view'
  | 'driver:history:view'
  | 'driver:operations:view'
  | 'driver:preferences:view'
  | 'driver:scorecard:view'
  | 'driver:targets:view'
  | 'driver:view'
  | 'employee:create'
  | 'employee:edit'
  | 'employee:group:edit'
  | 'employee:region:edit'
  | 'employee:view'
  | 'facility:addresses:edit'
  | 'facility:addresses:view'
  | 'facility:code:edit'
  | 'facility:contacts:edit'
  | 'facility:contacts:view'
  | 'facility:create'
  | 'facility:edit'
  | 'facilityDocuments:create'
  | 'facilityDocuments:edit'
  | 'facilityDocuments:view'
  | 'facility:schedules:edit'
  | 'facility:schedules:view'
  | 'facility:directions:edit'
  | 'facility:directions:view'
  | 'facility:general:holidays:edit'
  | 'facility:general:holidays:view'
  | 'facility:locations:edit'
  | 'facility:locations:view'
  | 'facility:view'
  | 'facility:routelist:download'
  | 'gateReservations:admin'
  | 'incident:accounting:edit'
  | 'incident:claims:edit'
  | 'incident:edit'
  | 'incident:view'
  | 'lane:edit'
  | 'lane:view'
  | 'profile:view'
  | 'load:accounting:edit'
  | 'load:accounting:view'
  | 'load:audit:edit'
  | 'load:audit:view'
  | 'load:autoSchedule:edit'
  | 'load:autoSchedule:view'
  | 'load:booking:edit'
  | 'load:booking:view'
  | 'load:commissions:edit'
  | 'load:commissions:view'
  | 'load:commodities:create'
  | 'load:commodities:delete'
  | 'load:commoditiesActual:edit'
  | 'load:commoditiesPlanned:edit'
  | 'load:create'
  | 'load:drivers:edit'
  | 'load:drivers:view'
  | 'load:edit'
  | 'load:map:view'
  | 'load:money:edit'
  | 'load:money:view'
  | 'load:offers:edit'
  | 'load:offers:view'
  | 'load:order:delete'
  | 'load:order:edit'
  | 'load:orderTenderStatus:edit'
  | 'load:ratecon:edit'
  | 'load:repeat'
  | 'load:route:addRoute'
  | 'load:route:create'
  | 'load:routeMaxCost:edit'
  | 'load:routePriority:edit'
  | 'load:stop:create'
  | 'load:stop:delete'
  | 'load:stop:reorder'
  | 'load:stopEvent:create'
  | 'load:stopEvent:edit'
  | 'load:stopPlanDetails:edit'
  | 'load:stopScheduleDetails:edit'
  | 'load:stopTimes:homeDepot:edit'
  | 'load:stopTimes:mizkan:edit'
  | 'load:stopTimes:target:edit'
  | 'load:track:edit'
  | 'load:track:view'
  | 'load:tracking:edit'
  | 'load:tracking:view'
  | 'load:view'
  | 'loadDocuments:edit'
  | 'loadDocuments:view'
  | 'loadPatterns:create'
  | 'loadPatterns:edit'
  | 'loadPatterns:view'
  | 'loadPatterns:delete'
  | 'loadPatterns:apply'
  | 'loadRoute:edit'
  | 'loadRoute:routeDetails:edit'
  | 'loadRoute:associatedRepsMain:edit'
  | 'loadRouteActivationStatus:edit'
  | 'offer:approve'
  | 'offer:book'
  | 'offer:counter'
  | 'offer:create'
  | 'offer:decline'
  | 'offer:delete'
  | 'offer:edit'
  | 'offer:view'
  | 'power:view'
  | 'routePosting:edit'
  | 'routePosting:view'
  | 'routingGuide:edit'
  | 'trailer:create'
  | 'trailer:edit'
  | 'truckEntry:create'
  | 'truckEntry:delete'
  | 'truckEntry:edit'
  | 'truckEntry:view'
  | 'truckEntryTemplate:create'
  | 'truckEntryTemplate:delete'
  | 'truckEntryTemplate:edit'
  | 'customerRoeStatus:action'
  | 'carrierRoeStatus:action'
  | 'customer:regions:view'
  | 'customer:regions:edit'
  | 'customer:commitments:view'
  | 'customer:commitments:edit'
  | 'order:customer:change'
  | 'lane:deactivate:edit';
export type PermissionsScopeObject = {
  [k in PermissionsScopeNames]: boolean;
};

/** All scopes default to `true` for local dev unless added here as false */
export const scopesFalseForLocalDev: Partial<PermissionsScopeObject> = {};
