export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  DateTimeOffset: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  Guid: any;
  /** An ISO 8601-encoded date */
  Iso8601Date: string;
  /** An ISO 8601-encoded date */
  ISO8601Date: string;
  ISO8601DateTime: string;
  Json: any;
  JSON: any;
  JSONObject: any;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  /** The multiplier path scalar represents a valid GraphQL multiplier path string. */
  MultiplierPath: any;
  PaginationAmount: any;
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any;
  Uuid: any;
};

export type AbacusCostLineItem = {
  readonly __typename?: 'AbacusCostLineItem';
  readonly laneId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly units?: Maybe<Scalars['Decimal']>;
  readonly costPer?: Maybe<Scalars['Decimal']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['ID'];
};

export type AcceptedOrders = {
  readonly __typename?: 'AcceptedOrders';
  readonly id?: Maybe<Scalars['ID']>;
  readonly commitmentVolume?: Maybe<Scalars['Int']>;
  readonly commitmentFrequency?: Maybe<Scalars['String']>;
  readonly acceptedOrdersCount?: Maybe<Scalars['Int']>;
};

export type AcceptOfferResponseInput = {
  readonly offerId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly counterPrice?: InputMaybe<CurrencyInput>;
  readonly counterPriceV2?: InputMaybe<CurrencyInputV2>;
  /** Will be overwritten with OfferResponseType.ACCEPT */
  readonly type?: InputMaybe<OfferResponseType>;
};

export type Accessorial = {
  readonly __typename?: 'Accessorial';
  readonly name?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly rate?: Maybe<Scalars['Float']>;
};

export enum AccountingAudienceEnum {
  /** Exceptions meant for developers. */
  Developer = 'Developer',
  /** Exceptions meant for users. */
  User = 'User'
}

export type AccountingException = {
  readonly __typename?: 'AccountingException';
  readonly audience?: Maybe<AccountingAudienceEnum>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly dateHandled?: Maybe<Scalars['Date']>;
  readonly handledById?: Maybe<Scalars['ID']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly integration?: Maybe<AccountingIntegrationEnum>;
  readonly isHandled?: Maybe<Scalars['Boolean']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly process?: Maybe<AccountingProcessEnum>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type AccountingExceptionConnection = {
  readonly __typename?: 'AccountingExceptionConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<AccountingExceptionEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<AccountingException>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AccountingExceptionEdge = {
  readonly __typename?: 'AccountingExceptionEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<AccountingException>;
};

export type AccountingExceptionFilterInput = {
  readonly audience?: InputMaybe<AccountingAudienceEnum>;
  readonly integration?: InputMaybe<AccountingIntegrationEnum>;
  readonly isHandled?: InputMaybe<Scalars['Boolean']>;
  readonly process?: InputMaybe<AccountingProcessEnum>;
};

export type AccountingExceptionUpdateInput = {
  readonly dateHandled?: InputMaybe<Scalars['DateTime']>;
  readonly handledById?: InputMaybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly isHandled: Scalars['Boolean'];
};

export enum AccountingIntegrationEnum {
  /** BusinessCentral */
  BusinessCentral = 'BusinessCentral',
  /** MasteryAPI */
  MasteryApi = 'MasteryAPI',
  /** Kafka */
  Kafka = 'Kafka',
  /** Comdata */
  Comdata = 'Comdata',
  /** TCheck */
  TCheck = 'TCheck',
  Trailerbridge = 'TRAILERBRIDGE',
  Masteryapiingress = 'MASTERYAPIINGRESS'
}

export enum AccountingProcessEnum {
  /** CustomerInvoiceBatchProcessing */
  CustomerInvoiceBatchProcessing = 'CustomerInvoiceBatchProcessing',
  /** VendorInvoiceBatchProcessing */
  VendorInvoiceBatchProcessing = 'VendorInvoiceBatchProcessing',
  /** CustomerSyncItemApplication */
  CustomerSyncItemApplication = 'CustomerSyncItemApplication',
  /** VendorSyncItemApplication */
  VendorSyncItemApplication = 'VendorSyncItemApplication',
  Customervendorsyncprocess = 'CUSTOMERVENDORSYNCPROCESS',
  Customercreditmemoprocessing = 'CUSTOMERCREDITMEMOPROCESSING',
  Vendorcreditmemoprocessing = 'VENDORCREDITMEMOPROCESSING',
  Undefined = 'UNDEFINED',
  Refreshadvanceprocess = 'REFRESHADVANCEPROCESS',
  Voucheringressprocess = 'VOUCHERINGRESSPROCESS',
  Invoiceingressprocess = 'INVOICEINGRESSPROCESS'
}

export enum Action {
  Created = 'Created',
  Updated = 'Updated',
  Deleted = 'Deleted'
}

export type ActiveCostQuote = {
  readonly __typename?: 'ActiveCostQuote';
  readonly id: Scalars['ID'];
  readonly laneId?: Maybe<Scalars['ID']>;
  readonly allTotalRate?: Maybe<CurrencyValue>;
  readonly allTotalRateV2?: Maybe<CurrencyOutputValue>;
  readonly code?: Maybe<Scalars['Int']>;
  readonly createdAtDateTime?: Maybe<Scalars['DateTime']>;
  readonly createdById?: Maybe<Scalars['ID']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly effectiveDateTime?: Maybe<Scalars['DateTimeOffset']>;
  readonly expirationDateTime?: Maybe<Scalars['DateTimeOffset']>;
  readonly extrasTotalRate?: Maybe<CurrencyValue>;
  readonly extrasTotalRateV2?: Maybe<CurrencyOutputValue>;
  readonly fuelTotalRate?: Maybe<CurrencyValue>;
  readonly fuelTotalRateV2?: Maybe<CurrencyOutputValue>;
  readonly linehaulTotalRate?: Maybe<CurrencyValue>;
  readonly linehaulTotalRateV2?: Maybe<CurrencyOutputValue>;
  readonly multipleStops?: Maybe<Scalars['Boolean']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly quoteType: TenantConfiguration;
  readonly quoteTypeFilter?: Maybe<Scalars['String']>;
  readonly scac?: Maybe<TenantConfiguration>;
  readonly scacNumber?: Maybe<Scalars['String']>;
  readonly quotedById?: Maybe<Scalars['ID']>;
  readonly team?: Maybe<Scalars['Boolean']>;
  readonly minimum?: Maybe<Scalars['Decimal']>;
  readonly lineItems?: Maybe<ReadonlyArray<Maybe<CostQuoteLineItem>>>;
  readonly createdBy?: Maybe<User>;
  readonly quotedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly quotedByV2?: Maybe<UserV2>;
  readonly lane?: Maybe<LaneV2>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly carrierCapacity?: Maybe<CarrierCapacity>;
};

export type ActiveLaneOrderExistsInput = {
  readonly customerId: Scalars['ID'];
  readonly laneId: Scalars['ID'];
};

export type ActiveLaneOrderExistsResult = {
  readonly __typename?: 'ActiveLaneOrderExistsResult';
  readonly activeOrder: Scalars['Boolean'];
};

export type Address = {
  readonly __typename?: 'Address';
  readonly addressType?: Maybe<KeyValue>;
  readonly addressTypeId?: Maybe<Scalars['ID']>;
  readonly addressableId: Scalars['ID'];
  readonly addressableType: Scalars['String'];
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly geopoint: Geopoint;
  readonly id: Scalars['ID'];
  readonly isVerified: Scalars['Boolean'];
  readonly main: Scalars['Boolean'];
  readonly postalCode: Scalars['String'];
  readonly province: Scalars['String'];
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly state: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: Maybe<Scalars['String']>;
  readonly thirdPartyId?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
};

export type AddressCleanseStatus = {
  readonly __typename?: 'AddressCleanseStatus';
  readonly addressId?: Maybe<Scalars['ID']>;
  readonly addressStatusId: Scalars['ID'];
  readonly addressableType?: Maybe<Scalars['String']>;
  readonly cleanseStatus?: Maybe<Scalars['String']>;
  readonly cleanseSource?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
};

export type AddressCleanseStatusConnection = {
  readonly __typename?: 'AddressCleanseStatusConnection';
  readonly totalCount?: Maybe<Scalars['Int']>;
  readonly pageInfo?: Maybe<CursorPageInfo>;
  readonly edges?: Maybe<ReadonlyArray<AddressCleanseStatusEdge>>;
};

export type AddressCleanseStatusEdge = {
  readonly __typename?: 'AddressCleanseStatusEdge';
  readonly cursor: Scalars['String'];
  readonly node: AddressCleanseStatus;
};

export type AddressCleanseStatusFilter = {
  readonly addressId?: InputMaybe<Scalars['String']>;
  readonly first?: InputMaybe<Scalars['Int']>;
  readonly last?: InputMaybe<Scalars['Int']>;
  readonly before?: InputMaybe<Scalars['String']>;
  readonly after?: InputMaybe<Scalars['String']>;
};

export type AddressesFilter = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly radius?: InputMaybe<UnitOfDistanceInput>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly stateCollection?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly stateIdCollection?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type AddressesQueryInput = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly radius?: InputMaybe<UnitOfDistanceInput>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly street1?: InputMaybe<Scalars['String']>;
  readonly street2?: InputMaybe<Scalars['String']>;
};

export type AddressFilter = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly radius?: InputMaybe<UnitOfLengthInput>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly stateCollection?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly stateIdCollection?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type AddressInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly addressTypeId?: InputMaybe<Scalars['ID']>;
  readonly addressableId?: InputMaybe<Scalars['ID']>;
  readonly addressableType?: InputMaybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isVerified?: InputMaybe<Scalars['Boolean']>;
  readonly main: Scalars['Boolean'];
  readonly postalCode: Scalars['String'];
  readonly province?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly state: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type AddressResultCodes = {
  readonly __typename?: 'AddressResultCodes';
  readonly code?: Maybe<Scalars['String']>;
  readonly reason?: Maybe<Scalars['String']>;
};

export type AddressResults = {
  readonly __typename?: 'AddressResults';
  readonly VerifiedAddresses?: Maybe<ReadonlyArray<FormattedAddress>>;
  readonly InvalidAddresses?: Maybe<ReadonlyArray<InvalidAddress>>;
};

export type AddressStatusChangeRequest = {
  readonly addressId?: InputMaybe<Scalars['ID']>;
  readonly addressableType?: InputMaybe<Scalars['String']>;
  readonly cleanseSource?: InputMaybe<Scalars['String']>;
  readonly cleanseStatus?: InputMaybe<Scalars['String']>;
};

export type AddVendorToRouteInput = {
  readonly bookedById: Scalars['ID'];
  readonly createdById: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
  readonly vendorType: RouteVendorTypeEnum;
};

/** Autogenerated return type of AddVendorToRoute */
export type AddVendorToRoutePayload = {
  readonly __typename?: 'AddVendorToRoutePayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly routeVendor: RouteVendor;
};

/** Represents the configuration for a charge type for a cost detail within the adjustment operations. */
export type AdjustmentInterface = {
  readonly __typename?: 'AdjustmentInterface';
  readonly adjustmentType: AdjustmentTypeEnum;
  readonly chargeTypeId?: Maybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
};

export type AdjustmentInterfaceCreateInput = {
  readonly adjustmentType: AdjustmentTypeEnum;
  readonly chargeTypeId: Scalars['ID'];
};

export type AdjustmentInterfaceUpdateInput = {
  readonly adjustmentType: AdjustmentTypeEnum;
  readonly chargeTypeId: Scalars['ID'];
  readonly id: Scalars['ID'];
};

export enum AdjustmentTypeEnum {
  /** Indicates that this is an adjustment for a short amount. */
  AdjustmentShort = 'AdjustmentShort',
  /** Indicates that this is an adjustment for an over amount. */
  AdjustmentOver = 'AdjustmentOver'
}

/** Represents an advance to a carrier. */
export type Advance = {
  readonly __typename?: 'Advance';
  readonly advanceDenialTypeId?: Maybe<Scalars['ID']>;
  readonly advanceNotificationUsers?: Maybe<ReadonlyArray<Maybe<AdvanceNotificationUser>>>;
  readonly amountRequested: Scalars['Decimal'];
  readonly approvalStatus: AdvanceApprovalStatusEnum;
  readonly approvedAmount: Scalars['Decimal'];
  readonly approvedByEmployee?: Maybe<Employee>;
  readonly approvedByEmployeeId?: Maybe<Scalars['ID']>;
  readonly approvedByEmployeeV2?: Maybe<EmployeeV2>;
  readonly approvedOn?: Maybe<Scalars['DateTime']>;
  readonly assignedToEmployee?: Maybe<Employee>;
  readonly assignedToEmployeeV2?: Maybe<EmployeeV2>;
  readonly assignedToId: Scalars['ID'];
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly cashedAmount: Scalars['Decimal'];
  readonly closedDateTime?: Maybe<Scalars['DateTime']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly descriptionId: Scalars['ID'];
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly feeAmount?: Maybe<Scalars['Decimal']>;
  readonly feeCalculation: FeeCalculationEnum;
  readonly feeMethod: FeeMethodEnum;
  readonly feePercent?: Maybe<Scalars['Decimal']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isClosed?: Maybe<Scalars['Boolean']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly load?: Maybe<Load>;
  readonly loadId: Scalars['ID'];
  readonly loadV2?: Maybe<LoadV2>;
  readonly neededBy?: Maybe<Scalars['DateTime']>;
  readonly netPayment: Scalars['Decimal'];
  readonly paymentType: PaymentTypeEnum;
  readonly ppwReceived: Scalars['Boolean'];
  readonly refNumber?: Maybe<Scalars['String']>;
  readonly requestedByEmployee?: Maybe<Employee>;
  readonly requestedByEmployeeId?: Maybe<Scalars['ID']>;
  readonly requestedByEmployeeV2?: Maybe<EmployeeV2>;
  readonly requestedOn?: Maybe<Scalars['DateTime']>;
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly status: AdvanceStatusEnum;
  readonly vendorId: Scalars['ID'];
};

export enum AdvanceApprovalStatusEnum {
  /** Indicates that this advance has been approved. */
  Approved = 'Approved',
  /** Indicates that this advance has been denied. */
  Denied = 'Denied',
  /** Indicates that this advance has been requested and is waiting to be approved or denied. */
  Pending = 'Pending'
}

/** A connection to a list of items. */
export type AdvanceConnection = {
  readonly __typename?: 'AdvanceConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<AdvanceEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Advance>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type AdvanceCreateInput = {
  readonly advanceNotificationUsers?: InputMaybe<ReadonlyArray<InputMaybe<AdvanceNotificationUserModelInput>>>;
  readonly amountRequested: Scalars['Decimal'];
  readonly comments?: InputMaybe<Scalars['String']>;
  readonly descriptionId: Scalars['ID'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly loadId: Scalars['ID'];
  readonly neededBy?: InputMaybe<Scalars['DateTime']>;
  readonly paymentType: PaymentTypeEnum;
  readonly routeId: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
};

/** An edge in a connection. */
export type AdvanceEdge = {
  readonly __typename?: 'AdvanceEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Advance>;
};

export type AdvanceFilterInput = {
  readonly approvalStatus?: InputMaybe<AdvanceApprovalStatusEnum>;
  readonly assignedToId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly descriptionIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly endDate?: InputMaybe<Scalars['Date']>;
  readonly isClosed?: InputMaybe<Scalars['Boolean']>;
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly requestedByEmployeeId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly startDate?: InputMaybe<Scalars['Date']>;
};

/** Represents the configuration for a charge type for a cost detail within the advance operations. */
export type AdvanceInterface = {
  readonly __typename?: 'AdvanceInterface';
  readonly advanceInterfaceType: AdvanceInterfaceTypeEnum;
  readonly advanceProvider: AdvanceProviderEnum;
  readonly chargeTypeId?: Maybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
};

export type AdvanceInterfaceCreateInput = {
  readonly advanceInterfaceType: AdvanceInterfaceTypeEnum;
  readonly advanceProvider: AdvanceProviderEnum;
  readonly chargeTypeId: Scalars['ID'];
};

export type AdvanceInterfaceFilter = {
  readonly advanceProvider: AdvanceProviderEnum;
};

export enum AdvanceInterfaceTypeEnum {
  /** Represents the charge type for use with the cost detail associated with the Advance Payment */
  AdvancePayment = 'AdvancePayment',
  /** Represents the charge type for use with the cost detail associated with the Payable Offset */
  PayableOffset = 'PayableOffset',
  /** Represents the charge type for use with the cost detail associated with the specified Advance Provider Expense (Comdata, TCheck, ...) */
  AdvanceProviderExpense = 'AdvanceProviderExpense',
  /** Represents the charge type for use with the cost detail associated with the Advance Payment Fee Income */
  AdvancePaymentFeeIncome = 'AdvancePaymentFeeIncome'
}

export type AdvanceInterfaceUpdateInput = {
  readonly advanceInterfaceType: AdvanceInterfaceTypeEnum;
  readonly advanceProvider: AdvanceProviderEnum;
  readonly chargeTypeId: Scalars['ID'];
  readonly id: Scalars['ID'];
};

/** The GL configuration for a Advance Notification User */
export type AdvanceNotificationUser = {
  readonly __typename?: 'AdvanceNotificationUser';
  readonly advanceEid?: Maybe<Scalars['ID']>;
  readonly notifyEmployee?: Maybe<Employee>;
  readonly notifyEmployeeV2?: Maybe<EmployeeV2>;
  readonly userId?: Maybe<Scalars['ID']>;
};

export type AdvanceNotificationUserModelInput = {
  readonly userId: Scalars['ID'];
};

/** Represents the vendor configuration for an Advance Provider */
export type AdvanceProviderConfig = {
  readonly __typename?: 'AdvanceProviderConfig';
  readonly advanceProvider?: Maybe<AdvanceProviderEnum>;
  readonly advanceProviderVendorId?: Maybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly isDefault?: Maybe<Scalars['Boolean']>;
  readonly paymentType?: Maybe<PaymentTypeEnum>;
  readonly providerExpense?: Maybe<Scalars['Decimal']>;
};

export type AdvanceProviderConfigCreateInputType = {
  readonly advanceProvider: AdvanceProviderEnum;
  readonly advanceProviderVendorId: Scalars['ID'];
  readonly isDefault: Scalars['Boolean'];
  readonly providerExpense?: InputMaybe<Scalars['Decimal']>;
};

export type AdvanceProviderConfigCreateUpdateType = {
  readonly advanceProvider: AdvanceProviderEnum;
  readonly advanceProviderVendorId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly isDefault: Scalars['Boolean'];
  readonly providerExpense?: InputMaybe<Scalars['Decimal']>;
};

export enum AdvanceProviderEnum {
  /** Represents the Comdata advance integration provider. */
  Comdata = 'Comdata',
  /** Represents the TCheck advance integration provider. */
  TCheck = 'TCheck',
  Noop = 'NOOP'
}

export type AdvanceRefreshOverrideInputType = {
  readonly cashedAmount: Scalars['Decimal'];
  readonly id: Scalars['ID'];
  readonly status?: InputMaybe<AdvanceStatusEnum>;
};

/** Type that represents taking a restricted action, (Issue Payment, Cancel Payment) */
export type AdvanceRestrictedActionInput = {
  readonly amountRequested: Scalars['Decimal'];
  readonly id: Scalars['ID'];
};

export enum AdvanceStatusEnum {
  /** Advance has been requested. */
  Requested = 'Requested',
  /** Advance has been blocked. */
  Blocked = 'Blocked',
  /** Advance has been canceled. */
  Canceled = 'Canceled',
  /** Advance has been cashed. */
  Cashed = 'Cashed',
  /** Advance has been denied. */
  Denied = 'Denied',
  /** Advance has been issued. */
  Issued = 'Issued',
  /** Advance has been partially cashed. */
  PartiallyCashed = 'PartiallyCashed',
  Approved = 'APPROVED'
}

/** Represents fields that a user may update on the advance */
export type AdvanceUpdateBasic = {
  readonly advanceNotificationUsers?: InputMaybe<ReadonlyArray<InputMaybe<AdvanceNotificationUserModelInput>>>;
  readonly amountRequested: Scalars['Decimal'];
  readonly comments: Scalars['String'];
  readonly descriptionId: Scalars['ID'];
  readonly firstName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly lastName: Scalars['String'];
  readonly neededBy: Scalars['DateTime'];
  readonly paymentType: PaymentTypeEnum;
  readonly vendorId: Scalars['ID'];
};

export type AdvanceUpdateRestrictedInput = {
  readonly advanceDenialTypeId?: InputMaybe<Scalars['ID']>;
  readonly approvalStatus: AdvanceApprovalStatusEnum;
  readonly approvedAmount: Scalars['Decimal'];
  readonly assignedToId: Scalars['ID'];
  readonly feeCalculation: FeeCalculationEnum;
  readonly feeMethod?: InputMaybe<FeeMethodEnum>;
  readonly feePercent?: InputMaybe<Scalars['Decimal']>;
  readonly flatFeeAmount?: InputMaybe<Scalars['Decimal']>;
  readonly id: Scalars['ID'];
  readonly netPayment: Scalars['Decimal'];
  readonly ppwReceived: Scalars['Boolean'];
};

/** Holds advertised Price Details. */
export type AdvertisedPriceConnection = {
  readonly __typename?: 'AdvertisedPriceConnection';
  readonly code?: Maybe<Scalars['String']>;
  readonly edges?: Maybe<ReadonlyArray<Maybe<PriceDetailEdge>>>;
  readonly message?: Maybe<Scalars['String']>;
  readonly pageInfo?: Maybe<PageInfo>;
};

export type AdvertisedPriceRequestFilter = {
  readonly loadBoardCode?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly orderCode?: InputMaybe<Scalars['String']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
};

export type AllBlumeChargeTypesPayload = {
  readonly __typename?: 'AllBlumeChargeTypesPayload';
  readonly blumeCharges: ReadonlyArray<BlumeChargeType>;
};

export type AllPricingProgram = {
  readonly __typename?: 'AllPricingProgram';
  readonly pricingPrograms: ReadonlyArray<PricingProgram>;
};

export type AllRegionsV2 = {
  readonly __typename?: 'AllRegionsV2';
  readonly superRegions?: Maybe<ReadonlyArray<Maybe<SuperRegionV2>>>;
  readonly regions?: Maybe<ReadonlyArray<Maybe<RegionV2>>>;
};

export type AllRegionsV2Input = {
  readonly regionSetType: Scalars['String'];
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['String']>;
};

export type AppCreationals = {
  readonly __typename?: 'AppCreationals';
  readonly client_id?: Maybe<Scalars['String']>;
  readonly client_secret?: Maybe<Scalars['String']>;
  readonly verification_token?: Maybe<Scalars['String']>;
  readonly signing_secret?: Maybe<Scalars['String']>;
};

export type AppCreationInputObj = {
  readonly config_token: Scalars['String'];
};

export type AppCreationResponseObj = {
  readonly __typename?: 'AppCreationResponseObj';
  readonly success: Scalars['Boolean'];
  readonly app_id?: Maybe<Scalars['String']>;
  readonly credentials?: Maybe<AppCreationals>;
  readonly oauth_authorize_url?: Maybe<Scalars['String']>;
  readonly error?: Maybe<Scalars['String']>;
};

/** The applicable tolerance for a route vendor. */
export type ApplicableRouteVendorTolerance = {
  readonly __typename?: 'ApplicableRouteVendorTolerance';
  readonly division?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly loadSize?: Maybe<Scalars['String']>;
  readonly lowerMax: Scalars['Decimal'];
  readonly lowerPercent?: Maybe<Scalars['Decimal']>;
  readonly transportMode?: Maybe<Scalars['String']>;
  readonly upperMax: Scalars['Decimal'];
  readonly upperPercent?: Maybe<Scalars['Decimal']>;
  readonly vendorId?: Maybe<Scalars['ID']>;
};

export type ApplyPatternInput = {
  readonly patternId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
};

export type ApplyPatternInputV2 = {
  readonly patternId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly userId: Scalars['ID'];
};

export type ApplyPatternPayload = {
  readonly __typename?: 'ApplyPatternPayload';
  readonly message?: Maybe<Scalars['String']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly status?: Maybe<ApplyPatternStatusEnum>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type ApplyPatternPayloadV2 = {
  readonly __typename?: 'ApplyPatternPayloadV2';
  readonly data?: Maybe<ApplyPatternPayload>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export enum ApplyPatternStatusEnum {
  Success = 'success',
  PartialFailure = 'partialFailure',
  Failure = 'failure'
}

export enum ApplyPolicy {
  BeforeResolver = 'BEFORE_RESOLVER',
  AfterResolver = 'AFTER_RESOLVER'
}

export type Appointment = {
  readonly __typename?: 'Appointment';
  readonly changedBy?: Maybe<Employee>;
  readonly confirmed: Scalars['Boolean'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly id: Scalars['ID'];
  readonly reasonForCancelation?: Maybe<Scalars['String']>;
  readonly requestedBy?: Maybe<Employee>;
  readonly requestedEnd?: Maybe<DatetimeWithTimezone>;
  readonly requestedStart?: Maybe<DatetimeWithTimezone>;
  readonly requestedTimeRange?: Maybe<ScheduleRange>;
  readonly requestedWith?: Maybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
};

export type AppointmentInput = {
  readonly confirmed?: InputMaybe<Scalars['Boolean']>;
  readonly reasonForCancelation?: InputMaybe<Scalars['String']>;
  readonly requestedEndDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly requestedStartDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly requestedTimeRange?: InputMaybe<ScheduleRangeInput>;
  readonly requestedWith?: InputMaybe<Scalars['String']>;
};

export type AppointmentInputV2 = {
  readonly confirmed?: InputMaybe<Scalars['Boolean']>;
  readonly requestedEndDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly requestedStartDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly requestedTimeRange?: InputMaybe<ScheduleRangeInput>;
  readonly requestedWith?: InputMaybe<Scalars['String']>;
  readonly reasonForCancelation?: InputMaybe<Scalars['String']>;
  readonly autoSchedule?: InputMaybe<Scalars['Boolean']>;
};

export type AppointmentV2 = {
  readonly __typename?: 'AppointmentV2';
  readonly id: Scalars['ID'];
  readonly changedBy?: Maybe<Employee>;
  readonly changedByV2?: Maybe<EmployeeV2>;
  readonly confirmed: Scalars['Boolean'];
  readonly reasonForCancelation?: Maybe<Scalars['String']>;
  readonly requestedBy?: Maybe<Employee>;
  readonly requestedByV2?: Maybe<EmployeeV2>;
  readonly requestedEnd?: Maybe<DatetimeWithTimezone>;
  readonly requestedStart?: Maybe<DatetimeWithTimezone>;
  readonly requestedTimeRange?: Maybe<ScheduleRange>;
  readonly requestedWith?: Maybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly autoSchedule?: Maybe<Scalars['Boolean']>;
  readonly scheduleReason?: Maybe<Scalars['String']>;
};

export type AreaCount = {
  readonly __typename?: 'AreaCount';
  readonly area: Scalars['String'];
  readonly id: Scalars['String'];
  readonly latitude: Scalars['Float'];
  readonly loadCount: Scalars['Int'];
  readonly longitude: Scalars['Float'];
  readonly truckCount: Scalars['Int'];
};

export enum AreaCountBy {
  Destination = 'DESTINATION',
  Origin = 'ORIGIN'
}

export type AslGenericDateFilter = {
  readonly operator: AslGenericFilterOperator;
  /** required for all filter operators but EXISTS and NOT_EXISTS */
  readonly value1?: InputMaybe<Scalars['DateTime']>;
  /** required only for filter operator BETWEEN as end value */
  readonly value2?: InputMaybe<Scalars['DateTime']>;
};

/** generic filter operator for asl */
export enum AslGenericFilterOperator {
  Greater = 'GREATER',
  Less = 'LESS',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  LessOrEqual = 'LESS_OR_EQUAL',
  Equal = 'EQUAL',
  NotEqual = 'NOT_EQUAL',
  Between = 'BETWEEN',
  Exists = 'EXISTS',
  NotExists = 'NOT_EXISTS'
}

export type AslGenericFloatFilter = {
  readonly operator: AslGenericFilterOperator;
  /** required for all filter operators but EXISTS and NOT_EXISTS */
  readonly value1?: InputMaybe<Scalars['Float']>;
  /** required only for filter operator BETWEEN as end value */
  readonly value2?: InputMaybe<Scalars['Float']>;
};

export type AslGenericIntFilter = {
  readonly operator: AslGenericFilterOperator;
  /** required for all filter operators but EXISTS and NOT_EXISTS */
  readonly value1?: InputMaybe<Scalars['Int']>;
  /** required only for filter operator BETWEEN as end value */
  readonly value2?: InputMaybe<Scalars['Int']>;
};

/** Sort order for asl */
export enum AslGenericOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type AslGenericRouteViewFilter = {
  readonly customers_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly customers_name_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly customers_groups_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly customers_groups_name_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly customers_groups_kvtId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly customers_groups_kvtName_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly activeCarrier_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly activeCarrier_name_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeVendors_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeVendors_vendorId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeVendors_name_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeVendors_active?: InputMaybe<Scalars['Boolean']>;
  readonly routeVendors_vendorType_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeVendors_utcExpectedEmptyDatetime?: InputMaybe<AslGenericDateFilter>;
  readonly routeVendors_expectedEmptyGeography_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeVendors_expectedEmptyGeography_name_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeVendors_expectedEmptyGeography_state_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeVendors_expectedEmptyGeography_timezone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly vendorReps_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly vendorReps_employeeId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly vendorReps_firstName_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly vendorReps_lastName_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly orderReps_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly orderReps_employeeId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly orderReps_firstName_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly orderReps_lastName_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly indexed?: InputMaybe<AslGenericDateFilter>;
  readonly created?: InputMaybe<AslGenericDateFilter>;
  readonly id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly previousRouteId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeCode_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly loadId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeLifeCycleStatus_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeActivationStatus_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeActivationStatus_name_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly orderActivationStatus_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly orderActivationStatus_name_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stopAmount?: InputMaybe<AslGenericIntFilter>;
  readonly stops_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_address_city_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_address_state_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_timeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_appointmentDate_start?: InputMaybe<AslGenericDateFilter>;
  readonly stops_appointmentDate_end?: InputMaybe<AslGenericDateFilter>;
  readonly stops_appointmentDate_timeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_appointmentTime_startOffset?: InputMaybe<AslGenericIntFilter>;
  readonly stops_appointmentTime_endOffset?: InputMaybe<AslGenericIntFilter>;
  readonly stops_status_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_superRegionUrn_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_regionUrn_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_subRegionUrn_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_stopType_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_scheduleAppointmentType_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_sequence?: InputMaybe<AslGenericFloatFilter>;
  readonly stops_carrierArrivalDate?: InputMaybe<AslGenericDateFilter>;
  readonly stops_carrierArrivalTimeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly stops_carrierDepartureDate?: InputMaybe<AslGenericDateFilter>;
  readonly stops_carrierDepartureTimeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_address_city_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_address_state_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_timeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_appointmentDate_start?: InputMaybe<AslGenericDateFilter>;
  readonly firstStop_appointmentDate_end?: InputMaybe<AslGenericDateFilter>;
  readonly firstStop_appointmentDate_timeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_appointmentTime_startOffset?: InputMaybe<AslGenericIntFilter>;
  readonly firstStop_appointmentTime_endOffset?: InputMaybe<AslGenericIntFilter>;
  readonly firstStop_status_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_superRegionUrn_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_regionUrn_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_subRegionUrn_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_stopType_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_scheduleAppointmentType_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_sequence?: InputMaybe<AslGenericFloatFilter>;
  readonly firstStop_carrierArrivalDate?: InputMaybe<AslGenericDateFilter>;
  readonly firstStop_carrierArrivalTimeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly firstStop_carrierDepartureDate?: InputMaybe<AslGenericDateFilter>;
  readonly firstStop_carrierDepartureTimeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_address_city_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_address_state_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_timeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_appointmentDate_start?: InputMaybe<AslGenericDateFilter>;
  readonly lastStop_appointmentDate_end?: InputMaybe<AslGenericDateFilter>;
  readonly lastStop_appointmentDate_timeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_appointmentTime_startOffset?: InputMaybe<AslGenericIntFilter>;
  readonly lastStop_appointmentTime_endOffset?: InputMaybe<AslGenericIntFilter>;
  readonly lastStop_status_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_superRegionUrn_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_regionUrn_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_subRegionUrn_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_stopType_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_scheduleAppointmentType_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_sequence?: InputMaybe<AslGenericFloatFilter>;
  readonly lastStop_carrierArrivalDate?: InputMaybe<AslGenericDateFilter>;
  readonly lastStop_carrierArrivalTimeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lastStop_carrierDepartureDate?: InputMaybe<AslGenericDateFilter>;
  readonly lastStop_carrierDepartureTimeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lock_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lock_objectID_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lock_flavor_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lock_expirationTimestamp?: InputMaybe<AslGenericDateFilter>;
  readonly lock_owner_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lock_owner_email_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lock_owner_employee_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly lock_owner_employee_fullName_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_lastUpdateId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_lastDriverAssignmentId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_driverName_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_driverNumber_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_trackingNote_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_actualUpdateTime?: InputMaybe<AslGenericDateFilter>;
  readonly trackingDetails_createDate?: InputMaybe<AslGenericDateFilter>;
  readonly trackingDetails_createdById_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_createdByName_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_createdBy_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_chkCallDate?: InputMaybe<AslGenericDateFilter>;
  readonly trackingDetails_nextRoute_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_previousRoute_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_trailerNumber_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_tractorNumber_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_etaDate?: InputMaybe<AslGenericDateFilter>;
  readonly trackingDetails_etaTimeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_carrierId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trackingDetails_carrierName_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trailerTypes_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly trailerTypes_name_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly transportMode_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly transportMode_name_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly dimensions_height?: InputMaybe<AslGenericFloatFilter>;
  readonly dimensions_heightUnit_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly dimensions_width?: InputMaybe<AslGenericFloatFilter>;
  readonly dimensions_widthUnit_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly dimensions_length?: InputMaybe<AslGenericFloatFilter>;
  readonly dimensions_lengthUnit_keyword?: InputMaybe<AslGenericStringFilter>;
};

export type AslGenericRouteViewOrderBy = {
  readonly customers_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly customers_name_keyword?: InputMaybe<AslGenericOrder>;
  readonly customers_groups_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly customers_groups_name_keyword?: InputMaybe<AslGenericOrder>;
  readonly customers_groups_kvtId_keyword?: InputMaybe<AslGenericOrder>;
  readonly customers_groups_kvtName_keyword?: InputMaybe<AslGenericOrder>;
  readonly activeCarrier_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly activeCarrier_name_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeVendors_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeVendors_vendorId_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeVendors_name_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeVendors_active?: InputMaybe<AslGenericOrder>;
  readonly routeVendors_vendorType_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeVendors_utcExpectedEmptyDatetime?: InputMaybe<AslGenericOrder>;
  readonly routeVendors_expectedEmptyGeography_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeVendors_expectedEmptyGeography_name_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeVendors_expectedEmptyGeography_state_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeVendors_expectedEmptyGeography_timezone_keyword?: InputMaybe<AslGenericOrder>;
  readonly vendorReps_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly vendorReps_employeeId_keyword?: InputMaybe<AslGenericOrder>;
  readonly vendorReps_firstName_keyword?: InputMaybe<AslGenericOrder>;
  readonly vendorReps_lastName_keyword?: InputMaybe<AslGenericOrder>;
  readonly orderReps_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly orderReps_employeeId_keyword?: InputMaybe<AslGenericOrder>;
  readonly orderReps_firstName_keyword?: InputMaybe<AslGenericOrder>;
  readonly orderReps_lastName_keyword?: InputMaybe<AslGenericOrder>;
  readonly indexed?: InputMaybe<AslGenericOrder>;
  readonly created?: InputMaybe<AslGenericOrder>;
  readonly id_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeId_keyword?: InputMaybe<AslGenericOrder>;
  readonly previousRouteId_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeCode_keyword?: InputMaybe<AslGenericOrder>;
  readonly loadId_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeLifeCycleStatus_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeActivationStatus_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeActivationStatus_name_keyword?: InputMaybe<AslGenericOrder>;
  readonly orderActivationStatus_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly orderActivationStatus_name_keyword?: InputMaybe<AslGenericOrder>;
  readonly stopAmount?: InputMaybe<AslGenericOrder>;
  readonly stops_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_address_city_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_address_state_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_timeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_appointmentDate_start?: InputMaybe<AslGenericOrder>;
  readonly stops_appointmentDate_end?: InputMaybe<AslGenericOrder>;
  readonly stops_appointmentDate_timeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_appointmentTime_startOffset?: InputMaybe<AslGenericOrder>;
  readonly stops_appointmentTime_endOffset?: InputMaybe<AslGenericOrder>;
  readonly stops_status_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_superRegionUrn_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_regionUrn_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_subRegionUrn_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_stopType_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_scheduleAppointmentType_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_sequence?: InputMaybe<AslGenericOrder>;
  readonly stops_carrierArrivalDate?: InputMaybe<AslGenericOrder>;
  readonly stops_carrierArrivalTimeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly stops_carrierDepartureDate?: InputMaybe<AslGenericOrder>;
  readonly stops_carrierDepartureTimeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_address_city_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_address_state_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_timeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_appointmentDate_start?: InputMaybe<AslGenericOrder>;
  readonly firstStop_appointmentDate_end?: InputMaybe<AslGenericOrder>;
  readonly firstStop_appointmentDate_timeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_appointmentTime_startOffset?: InputMaybe<AslGenericOrder>;
  readonly firstStop_appointmentTime_endOffset?: InputMaybe<AslGenericOrder>;
  readonly firstStop_status_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_superRegionUrn_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_regionUrn_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_subRegionUrn_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_stopType_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_scheduleAppointmentType_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_sequence?: InputMaybe<AslGenericOrder>;
  readonly firstStop_carrierArrivalDate?: InputMaybe<AslGenericOrder>;
  readonly firstStop_carrierArrivalTimeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly firstStop_carrierDepartureDate?: InputMaybe<AslGenericOrder>;
  readonly firstStop_carrierDepartureTimeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_address_city_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_address_state_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_timeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_appointmentDate_start?: InputMaybe<AslGenericOrder>;
  readonly lastStop_appointmentDate_end?: InputMaybe<AslGenericOrder>;
  readonly lastStop_appointmentDate_timeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_appointmentTime_startOffset?: InputMaybe<AslGenericOrder>;
  readonly lastStop_appointmentTime_endOffset?: InputMaybe<AslGenericOrder>;
  readonly lastStop_status_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_superRegionUrn_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_regionUrn_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_subRegionUrn_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_stopType_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_scheduleAppointmentType_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_sequence?: InputMaybe<AslGenericOrder>;
  readonly lastStop_carrierArrivalDate?: InputMaybe<AslGenericOrder>;
  readonly lastStop_carrierArrivalTimeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly lastStop_carrierDepartureDate?: InputMaybe<AslGenericOrder>;
  readonly lastStop_carrierDepartureTimeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly lock_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly lock_objectID_keyword?: InputMaybe<AslGenericOrder>;
  readonly lock_flavor_keyword?: InputMaybe<AslGenericOrder>;
  readonly lock_expirationTimestamp?: InputMaybe<AslGenericOrder>;
  readonly lock_owner_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly lock_owner_email_keyword?: InputMaybe<AslGenericOrder>;
  readonly lock_owner_employee_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly lock_owner_employee_fullName_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_lastUpdateId_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_lastDriverAssignmentId_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_driverName_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_driverNumber_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_trackingNote_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_actualUpdateTime?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_createDate?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_createdById_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_createdByName_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_createdBy_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_chkCallDate?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_nextRoute_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_previousRoute_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_trailerNumber_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_tractorNumber_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_etaDate?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_etaTimeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_carrierId_keyword?: InputMaybe<AslGenericOrder>;
  readonly trackingDetails_carrierName_keyword?: InputMaybe<AslGenericOrder>;
  readonly trailerTypes_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly trailerTypes_name_keyword?: InputMaybe<AslGenericOrder>;
  readonly transportMode_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly transportMode_name_keyword?: InputMaybe<AslGenericOrder>;
  readonly dimensions_height?: InputMaybe<AslGenericOrder>;
  readonly dimensions_heightUnit_keyword?: InputMaybe<AslGenericOrder>;
  readonly dimensions_width?: InputMaybe<AslGenericOrder>;
  readonly dimensions_widthUnit_keyword?: InputMaybe<AslGenericOrder>;
  readonly dimensions_length?: InputMaybe<AslGenericOrder>;
  readonly dimensions_lengthUnit_keyword?: InputMaybe<AslGenericOrder>;
};

export type AslGenericStringFilter = {
  readonly operator: AslGenericStringOperator;
  /** required for all filter operators but EXISTS and NOT_EXISTS */
  readonly value?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** Filter operator for strings */
export enum AslGenericStringOperator {
  CombineAnd = 'COMBINE_AND',
  CombineOr = 'COMBINE_OR',
  Exists = 'EXISTS',
  NotExists = 'NOT_EXISTS'
}

export type AslGenericTrackingUpdatesViewFilter = {
  readonly indexed?: InputMaybe<AslGenericDateFilter>;
  readonly id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly routeId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_processedDate?: InputMaybe<AslGenericDateFilter>;
  readonly updates_processed?: InputMaybe<Scalars['Boolean']>;
  readonly updates_routeId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_updateId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_carrierId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_action_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_customerId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_routeStopId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_currentLocation_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_currentLocationLatitude?: InputMaybe<AslGenericFloatFilter>;
  readonly updates_currentLocationLongitude?: InputMaybe<AslGenericFloatFilter>;
  readonly updates_currentLocationCity_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_currentLocationState_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_currentLocationCountry_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_currentLocationPostalCode_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_actualEventTime?: InputMaybe<AslGenericDateFilter>;
  readonly updates_actualEventTimeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_infoFrom_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_nextLocation_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_nextLocationLatitude?: InputMaybe<AslGenericFloatFilter>;
  readonly updates_nextLocationLongitude?: InputMaybe<AslGenericFloatFilter>;
  readonly updates_nextCity_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_nextState_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_nextStopId_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_notes_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_distanceToNext?: InputMaybe<AslGenericFloatFilter>;
  readonly updates_distanceToNextUnit_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_checkCallDatetime?: InputMaybe<AslGenericDateFilter>;
  readonly updates_checkCallDatetimeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_etaDatetime?: InputMaybe<AslGenericDateFilter>;
  readonly updates_etaDatetimeZone_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_trailerNumber_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_tenant_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_created_id_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_created_displayName_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_created_by_keyword?: InputMaybe<AslGenericStringFilter>;
  readonly updates_created_timestamp?: InputMaybe<AslGenericDateFilter>;
};

export type AslGenericTrackingUpdatesViewOrderBy = {
  readonly indexed?: InputMaybe<AslGenericOrder>;
  readonly id_keyword?: InputMaybe<AslGenericOrder>;
  readonly routeId_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_processedDate?: InputMaybe<AslGenericOrder>;
  readonly updates_processed?: InputMaybe<AslGenericOrder>;
  readonly updates_routeId_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_updateId_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_carrierId_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_action_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_customerId_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_routeStopId_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_currentLocation_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_currentLocationLatitude?: InputMaybe<AslGenericOrder>;
  readonly updates_currentLocationLongitude?: InputMaybe<AslGenericOrder>;
  readonly updates_currentLocationCity_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_currentLocationState_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_currentLocationCountry_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_currentLocationPostalCode_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_actualEventTime?: InputMaybe<AslGenericOrder>;
  readonly updates_actualEventTimeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_infoFrom_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_nextLocation_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_nextLocationLatitude?: InputMaybe<AslGenericOrder>;
  readonly updates_nextLocationLongitude?: InputMaybe<AslGenericOrder>;
  readonly updates_nextCity_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_nextState_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_nextStopId_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_notes_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_distanceToNext?: InputMaybe<AslGenericOrder>;
  readonly updates_distanceToNextUnit_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_checkCallDatetime?: InputMaybe<AslGenericOrder>;
  readonly updates_checkCallDatetimeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_etaDatetime?: InputMaybe<AslGenericOrder>;
  readonly updates_etaDatetimeZone_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_trailerNumber_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_tenant_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_created_id_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_created_displayName_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_created_by_keyword?: InputMaybe<AslGenericOrder>;
  readonly updates_created_timestamp?: InputMaybe<AslGenericOrder>;
};

export type AslRouteViewCarrier = {
  readonly __typename?: 'AslRouteViewCarrier';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
};

export type AslRouteViewCustomer = {
  readonly __typename?: 'AslRouteViewCustomer';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly groups: ReadonlyArray<AslRouteViewCustomerGroup>;
};

export type AslRouteViewCustomerGroup = {
  readonly __typename?: 'AslRouteViewCustomerGroup';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly kvtId?: Maybe<Scalars['String']>;
  readonly kvtName?: Maybe<Scalars['String']>;
};

export type AslRouteViewDateRange = {
  readonly __typename?: 'AslRouteViewDateRange';
  readonly start?: Maybe<Scalars['DateTime']>;
  readonly end?: Maybe<Scalars['DateTime']>;
  readonly timeZone?: Maybe<Scalars['String']>;
};

export type AslRouteViewDimensions = {
  readonly __typename?: 'AslRouteViewDimensions';
  readonly height?: Maybe<Scalars['Float']>;
  readonly heightUnit?: Maybe<Scalars['String']>;
  readonly width?: Maybe<Scalars['Float']>;
  readonly widthUnit?: Maybe<Scalars['String']>;
  readonly length?: Maybe<Scalars['Float']>;
  readonly lengthUnit?: Maybe<Scalars['String']>;
};

export type AslRouteViewEdge = {
  readonly __typename?: 'AslRouteViewEdge';
  readonly cursor: Scalars['String'];
  readonly node: AslRouteViewNode;
};

export type AslRouteViewGeography = {
  readonly __typename?: 'AslRouteViewGeography';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly timezone?: Maybe<Scalars['String']>;
};

export type AslRouteViewInput = {
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  readonly customerIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly orderRepIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeRepIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly mustMatchBothRouteAndOrderReps?: InputMaybe<Scalars['Boolean']>;
  readonly inboundRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly employeeGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeLifecycleStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly transportModes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hasTrackingDetails?: InputMaybe<Scalars['Boolean']>;
  /**
   * Will execute separate queries per given route.lifecycleStatus, this means you
   * will get count(lifecycleStatus)*pageSize results and the pageInfo cursors are
   * a map of status->cursor. hasNextPage is an or combination and might just be
   * respected for a single status
   */
  readonly separateQueryPerRouteLifecycleStatus?: InputMaybe<Scalars['Boolean']>;
  /**
   * Provides the possibility to give ordering per lifecycle status. Note that the
   * index of the order needs to match the index of the lifecyclestatus (in their
   * corresponding arrays). The contents of this array will be used instead of the
   * ordering given in the resolver.
   */
  readonly orderByPerLifeCycleStatus?: InputMaybe<ReadonlyArray<AslGenericRouteViewOrderBy>>;
  readonly genericFilter?: InputMaybe<AslGenericRouteViewFilter>;
};

export type AslRouteViewKvt = {
  readonly __typename?: 'AslRouteViewKVT';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type AslRouteViewLockInfo = {
  readonly __typename?: 'AslRouteViewLockInfo';
  readonly id: Scalars['ID'];
  readonly objectID: Scalars['String'];
  readonly flavor: Scalars['String'];
  readonly expirationTimestamp: Scalars['DateTime'];
  readonly owner: AslRouteViewLockOwner;
};

export type AslRouteViewLockInfoEmployee = {
  readonly __typename?: 'AslRouteViewLockInfoEmployee';
  readonly id: Scalars['ID'];
  readonly fullName: Scalars['String'];
};

export type AslRouteViewLockOwner = {
  readonly __typename?: 'AslRouteViewLockOwner';
  readonly id: Scalars['ID'];
  readonly email: Scalars['String'];
  readonly employee?: Maybe<AslRouteViewLockInfoEmployee>;
};

export type AslRouteViewNode = {
  readonly __typename?: 'AslRouteViewNode';
  readonly customers: ReadonlyArray<AslRouteViewCustomer>;
  readonly activeCarrier?: Maybe<AslRouteViewCarrier>;
  readonly routeVendors: ReadonlyArray<AslRouteViewRouteVendor>;
  readonly vendorReps: ReadonlyArray<AslRouteViewVendorRep>;
  readonly orderReps: ReadonlyArray<AslRouteViewOrderRep>;
  readonly indexed: Scalars['DateTime'];
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly previousRouteId?: Maybe<Scalars['ID']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<AslRouteViewKvt>;
  readonly orderActivationStatus?: Maybe<AslRouteViewKvt>;
  readonly stopAmount: Scalars['Int'];
  readonly stops: ReadonlyArray<AslRouteViewStop>;
  readonly firstStop?: Maybe<AslRouteViewStop>;
  readonly lastStop?: Maybe<AslRouteViewStop>;
  readonly lock?: Maybe<AslRouteViewLockInfo>;
  readonly trackingDetails?: Maybe<AslRouteViewTrackingDetails>;
  readonly trailerTypes?: Maybe<ReadonlyArray<AslRouteViewKvt>>;
  readonly transportMode?: Maybe<AslRouteViewKvt>;
  readonly dimensions?: Maybe<AslRouteViewDimensions>;
};

export type AslRouteViewOrderRep = {
  readonly __typename?: 'AslRouteViewOrderRep';
  readonly id: Scalars['ID'];
  readonly employeeId: Scalars['String'];
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
};

export type AslRouteViewResult = {
  readonly __typename?: 'AslRouteViewResult';
  readonly edges: ReadonlyArray<AslRouteViewEdge>;
  readonly pageInfo: PageInfo;
};

export type AslRouteViewRouteVendor = {
  readonly __typename?: 'AslRouteViewRouteVendor';
  readonly id: Scalars['ID'];
  readonly vendorId: Scalars['String'];
  readonly name?: Maybe<Scalars['String']>;
  readonly active: Scalars['Boolean'];
  readonly vendorType?: Maybe<Scalars['String']>;
  readonly utcExpectedEmptyDatetime?: Maybe<Scalars['DateTime']>;
  readonly expectedEmptyGeography?: Maybe<AslRouteViewGeography>;
};

export type AslRouteViewStop = {
  readonly __typename?: 'AslRouteViewStop';
  readonly id: Scalars['ID'];
  readonly address?: Maybe<AslRouteViewStopAddress>;
  readonly timeZone?: Maybe<Scalars['String']>;
  readonly appointmentDate?: Maybe<AslRouteViewDateRange>;
  readonly appointmentTime?: Maybe<AslRouteViewTimeRange>;
  readonly status?: Maybe<Scalars['String']>;
  readonly superRegionUrn?: Maybe<Scalars['String']>;
  readonly regionUrn?: Maybe<Scalars['String']>;
  readonly subRegionUrn?: Maybe<Scalars['String']>;
  readonly stopType?: Maybe<Scalars['String']>;
  readonly scheduleAppointmentType?: Maybe<Scalars['String']>;
  readonly sequence: Scalars['Float'];
  readonly carrierArrivalDate?: Maybe<Scalars['DateTime']>;
  readonly carrierArrivalTimeZone?: Maybe<Scalars['String']>;
  readonly carrierDepartureDate?: Maybe<Scalars['DateTime']>;
  readonly carrierDepartureTimeZone?: Maybe<Scalars['String']>;
};

export type AslRouteViewStopAddress = {
  readonly __typename?: 'AslRouteViewStopAddress';
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
};

export type AslRouteViewTimeRange = {
  readonly __typename?: 'AslRouteViewTimeRange';
  readonly startOffset?: Maybe<Scalars['Int']>;
  readonly endOffset?: Maybe<Scalars['Int']>;
};

export type AslRouteViewTrackingDetails = {
  readonly __typename?: 'AslRouteViewTrackingDetails';
  readonly lastUpdateId?: Maybe<Scalars['String']>;
  readonly lastDriverAssignmentId?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverNumber?: Maybe<Scalars['String']>;
  readonly trackingNote?: Maybe<Scalars['String']>;
  readonly actualUpdateTime?: Maybe<Scalars['DateTime']>;
  readonly createDate?: Maybe<Scalars['DateTime']>;
  readonly createdById?: Maybe<Scalars['String']>;
  readonly createdByName?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly chkCallDate?: Maybe<Scalars['DateTime']>;
  readonly nextRoute?: Maybe<Scalars['ID']>;
  readonly previousRoute?: Maybe<Scalars['ID']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly tractorNumber?: Maybe<Scalars['String']>;
  readonly etaDate?: Maybe<Scalars['DateTime']>;
  readonly etaTimeZone?: Maybe<Scalars['String']>;
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly carrierName?: Maybe<Scalars['String']>;
};

export type AslRouteViewVendorRep = {
  readonly __typename?: 'AslRouteViewVendorRep';
  readonly id: Scalars['ID'];
  readonly employeeId: Scalars['String'];
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
};

export type AslTrackingUpdate = {
  readonly __typename?: 'AslTrackingUpdate';
  readonly processedDate?: Maybe<Scalars['DateTime']>;
  readonly processed: Scalars['Boolean'];
  readonly routeId: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly updateId: Scalars['ID'];
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly action?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly routeStopId?: Maybe<Scalars['String']>;
  readonly currentLocation?: Maybe<Scalars['String']>;
  readonly currentLocationLatitude?: Maybe<Scalars['Float']>;
  readonly currentLocationLongitude?: Maybe<Scalars['Float']>;
  readonly currentLocationCity?: Maybe<Scalars['String']>;
  readonly currentLocationState?: Maybe<Scalars['String']>;
  readonly currentLocationCountry?: Maybe<Scalars['String']>;
  readonly currentLocationPostalCode?: Maybe<Scalars['String']>;
  readonly actualEventTime?: Maybe<Scalars['DateTime']>;
  readonly actualEventTimeZone?: Maybe<Scalars['String']>;
  readonly infoFrom?: Maybe<Scalars['String']>;
  readonly nextLocation?: Maybe<Scalars['String']>;
  readonly nextLocationLatitude?: Maybe<Scalars['Float']>;
  readonly nextLocationLongitude?: Maybe<Scalars['Float']>;
  readonly nextCity?: Maybe<Scalars['String']>;
  readonly nextState?: Maybe<Scalars['String']>;
  readonly nextStopId?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly distanceToNext?: Maybe<Scalars['Float']>;
  readonly distanceToNextUnit?: Maybe<Scalars['String']>;
  readonly checkCallDatetime?: Maybe<Scalars['DateTime']>;
  readonly checkCallDatetimeZone?: Maybe<Scalars['String']>;
  readonly etaDatetime?: Maybe<Scalars['DateTime']>;
  readonly etaDatetimeZone?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly tenant?: Maybe<Scalars['String']>;
  readonly created?: Maybe<AslTrackingUpdateAuditInfo>;
};

export type AslTrackingUpdateAuditInfo = {
  readonly __typename?: 'AslTrackingUpdateAuditInfo';
  readonly id?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly by: Scalars['String'];
  readonly timestamp: Scalars['DateTime'];
};

export type AslTrackingUpdatesViewEdge = {
  readonly __typename?: 'AslTrackingUpdatesViewEdge';
  readonly cursor: Scalars['String'];
  readonly node: AslTrackingUpdatesViewNode;
};

export type AslTrackingUpdatesViewNode = {
  readonly __typename?: 'AslTrackingUpdatesViewNode';
  readonly indexed: Scalars['DateTime'];
  readonly id: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly updates: ReadonlyArray<AslTrackingUpdate>;
};

export type AslTrackingUpdatesViewResult = {
  readonly __typename?: 'AslTrackingUpdatesViewResult';
  readonly edges: ReadonlyArray<AslTrackingUpdatesViewEdge>;
  readonly pageInfo: PageInfo;
};

export type AssetDriver = {
  readonly __typename?: 'AssetDriver';
  readonly id: Scalars['ID'];
  /** @deprecated driverIdentityId is deprecated. Use code instead. */
  readonly driverIdentityId?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly middleName?: Maybe<Scalars['String']>;
  readonly lastName: Scalars['String'];
  /** @deprecated nameSuffix is deprecated. Use nameSuffixTerm instead. */
  readonly nameSuffix?: Maybe<Scalars['String']>;
  readonly nameSuffixTerm?: Maybe<Scalars['String']>;
  readonly nickName?: Maybe<Scalars['String']>;
  readonly managerName?: Maybe<Scalars['String']>;
  /** @deprecated primaryPhoneCountryCd is deprecated. Use primaryPhoneCountry instead. */
  readonly primaryPhoneCountryCd?: Maybe<Scalars['String']>;
  readonly primaryPhoneCountry?: Maybe<Scalars['String']>;
  readonly primaryPhoneNumber?: Maybe<Scalars['String']>;
  readonly primaryEmail?: Maybe<Scalars['String']>;
  readonly isSmsEnabled?: Maybe<Scalars['Boolean']>;
  /** @deprecated preferredGenderPronoun is deprecated. Use preferredGenderPronounTerm instead. */
  readonly preferredGenderPronoun?: Maybe<Scalars['String']>;
  readonly preferredGenderPronounTerm?: Maybe<Scalars['String']>;
  /** @deprecated maritalStatus is deprecated. Use maritalStatusTerm instead. */
  readonly maritalStatus?: Maybe<Scalars['String']>;
  readonly maritalStatusTerm?: Maybe<Scalars['String']>;
  readonly spouseName?: Maybe<Scalars['String']>;
  /** @deprecated children is deprecated. Use childrenTerm instead. */
  readonly children?: Maybe<Scalars['String']>;
  readonly childrenTerm?: Maybe<Scalars['String']>;
  /** @deprecated militaryReserveObligations is deprecated. Use militaryReserveObligationsTerm instead. */
  readonly militaryReserveObligations?: Maybe<Scalars['String']>;
  readonly militaryReserveObligationsTerm?: Maybe<Scalars['String']>;
  /** @deprecated personalParkingSpace is deprecated. Use personalParkingSpaceTerm instead. */
  readonly personalParkingSpace?: Maybe<Scalars['String']>;
  readonly personalParkingSpaceTerm?: Maybe<Scalars['String']>;
  /** @deprecated professionType is deprecated. Use professionalTypeTerm instead. */
  readonly professionType?: Maybe<Scalars['String']>;
  readonly professionalTypeTerm?: Maybe<Scalars['String']>;
  readonly partnerName?: Maybe<Scalars['String']>;
  readonly partnerNumber?: Maybe<Scalars['String']>;
  readonly hireDate?: Maybe<Scalars['DateTime']>;
  readonly years_service?: Maybe<Scalars['String']>;
  readonly classTerm?: Maybe<Scalars['String']>;
  readonly fleet?: Maybe<Scalars['String']>;
  /** @deprecated division is deprecated. Use divisionTerm instead. */
  readonly division?: Maybe<Scalars['String']>;
  readonly divisionTerm?: Maybe<Scalars['String']>;
  /** @deprecated project is deprecated. Use projectTerm instead. */
  readonly project?: Maybe<Scalars['String']>;
  readonly projectTerm?: Maybe<Scalars['String']>;
  /** @deprecated program is deprecated. Use programTerm instead. */
  readonly program?: Maybe<Scalars['String']>;
  readonly programTerm?: Maybe<Scalars['String']>;
  readonly region?: Maybe<Scalars['String']>;
  readonly domicile?: Maybe<Scalars['String']>;
  /** @deprecated segment is deprecated. Use businessUnitTerm instead. */
  readonly segment?: Maybe<Scalars['String']>;
  readonly businessUnitTerm?: Maybe<Scalars['String']>;
  readonly milesRun?: Maybe<Scalars['Float']>;
  /** @deprecated status is deprecated. Use statusTerm instead. */
  readonly status?: Maybe<Scalars['String']>;
  readonly statusTerm?: Maybe<Scalars['String']>;
  /** @deprecated hosRule is deprecated. Use hosRuleTerm instead. */
  readonly hosRule?: Maybe<Scalars['String']>;
  readonly hosRuleTerm?: Maybe<Scalars['String']>;
  /** @deprecated permanentTractor is deprecated. */
  readonly permanentTractor?: Maybe<Scalars['String']>;
  /** @deprecated tractorType is deprecated. Use tractorTypeTerm instead. */
  readonly tractorType?: Maybe<Scalars['String']>;
  readonly tractorTypeTerm?: Maybe<Scalars['String']>;
  /** @deprecated trailerType is deprecated. Use trailerTypeTerm instead. */
  readonly trailerType?: Maybe<Scalars['String']>;
  readonly trailerTypeTerm?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly allottedVacationDays?: Maybe<Scalars['Float']>;
  readonly vacationDaysRemaining?: Maybe<Scalars['Float']>;
  readonly vacationResetDate?: Maybe<Scalars['DateTime']>;
  readonly allottedSickDays?: Maybe<Scalars['Float']>;
  readonly sickDaysRemaining?: Maybe<Scalars['Float']>;
  readonly sickResetDate?: Maybe<Scalars['DateTime']>;
  readonly assignedTractor?: Maybe<Scalars['String']>;
  readonly dutyStatus?: Maybe<Scalars['String']>;
  readonly remainingHours?: Maybe<Scalars['Float']>;
  readonly nextReset?: Maybe<Scalars['DateTime']>;
  readonly calculatedDateTime?: Maybe<Scalars['DateTime']>;
  readonly calculatedCity?: Maybe<Scalars['String']>;
  readonly calculatedState?: Maybe<Scalars['String']>;
  readonly preferredDateTime?: Maybe<Scalars['DateTime']>;
  readonly preferredCity?: Maybe<Scalars['String']>;
  readonly preferredState?: Maybe<Scalars['String']>;
  readonly assignedTrailers?: Maybe<ReadonlyArray<DriverAssignedTrailers>>;
  /** A collection of permanentTrailers associated with the driver. */
  readonly permanentTrailers?: Maybe<ReadonlyArray<DriverPermanentTrailers>>;
  /**
   * DEPRECATED: driverTimeOff is deprecated. Use driverTimeOffV2 instead
   * @deprecated driverTimeOff is deprecated. Use driverTimeOffV2 instead.
   */
  readonly driverTimeOff?: Maybe<ReadonlyArray<DriverTimeOff>>;
  /** A collection of timeOff associated with the driver. */
  readonly driverTimeOffV2?: Maybe<ReadonlyArray<DriverTimeOffV2>>;
  readonly driverIdentifiers?: Maybe<ReadonlyArray<DriverIdentifiers>>;
  readonly driverAddress?: Maybe<ReadonlyArray<DriverAddress>>;
  readonly driverContacts?: Maybe<ReadonlyArray<DriverContact>>;
  /**
   * DEPRECATED: driverCertificationsorPermits is deprecated. Use driverCertificationsorPermitsV2 instead
   * @deprecated driverCertificationsorPermits is deprecated. Use driverCertificationsorPermitsV2 instead.
   */
  readonly driverCertificationsorPermits?: Maybe<ReadonlyArray<DriverCertificationsOrPermits>>;
  /** A collection of certificationsorPermits associated with the driver. */
  readonly driverCertificationsorPermitsV2?: Maybe<ReadonlyArray<DriverCertificationsOrPermitsV2>>;
  /**
   * DEPRECATED: driverSchedule is deprecated. Use driverScheduleV2 instead
   * @deprecated driverSchedule is deprecated. Use driverScheduleV2 instead.
   */
  readonly driverSchedule?: Maybe<ReadonlyArray<DriverSchedule>>;
  /** A collection of driver schedule associated with the driver. */
  readonly driverScheduleV2?: Maybe<ReadonlyArray<DriverScheduleV2>>;
  readonly driverAwards?: Maybe<ReadonlyArray<DriverAward>>;
  /**
   * DEPRECATED: driverEquipment is deprecated. Use driverEquipmentV2 instead
   * @deprecated driverEquipment is deprecated. Use driverEquipmentV2 instead.
   */
  readonly driverEquipment?: Maybe<ReadonlyArray<DriverEquipment>>;
  /** A collection of equipment associated with the driver. */
  readonly driverEquipmentV2?: Maybe<ReadonlyArray<DriverEquipmentV2>>;
  readonly driverFacility?: Maybe<ReadonlyArray<DriverFacilityPreferences>>;
  readonly driverCommodity?: Maybe<ReadonlyArray<DriverCommodityPreferences>>;
  readonly driverCustomer?: Maybe<ReadonlyArray<DriverCustomerPreferences>>;
  readonly driverTeam?: Maybe<ReadonlyArray<DriverTeamPreferences>>;
  readonly driverOperational?: Maybe<ReadonlyArray<DriverOperationalPreferences>>;
  readonly driverGeography?: Maybe<ReadonlyArray<DriverGeographyPreferences>>;
  readonly driverCareerGoals?: Maybe<ReadonlyArray<DriverCareerGoalsPreferences>>;
  readonly driverPreferredLanes?: Maybe<ReadonlyArray<DriverPreferredLanes>>;
  /**
   * DEPRECATED: permanentPowers is deprecated. Use permanentPowerUnit instead
   * @deprecated permanentPowers is deprecated. Use permanentPowerUnit instead.
   */
  readonly permanentPowers?: Maybe<ReadonlyArray<DriverPermanentPowers>>;
  /** References to the power records that want to associate this driver too. */
  readonly permanentPowerUnit?: Maybe<ReadonlyArray<DriverPermanentPowers>>;
  readonly driverPreferredRoutes?: Maybe<ReadonlyArray<DriverPreferredRoutes>>;
  readonly driverWeeklyTargets?: Maybe<ReadonlyArray<DriverWeeklyTarget>>;
  readonly driverTrainings?: Maybe<ReadonlyArray<DriverTraining>>;
};

export type AssetDriverInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly driverIdentityId?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly middleName?: InputMaybe<Scalars['String']>;
  readonly lastName: Scalars['String'];
  readonly nameSuffix?: InputMaybe<Scalars['String']>;
  readonly nameSuffixTerm?: InputMaybe<Scalars['String']>;
  readonly nickName?: InputMaybe<Scalars['String']>;
  readonly managerName?: InputMaybe<Scalars['String']>;
  readonly primaryPhoneCountryCd?: InputMaybe<Scalars['String']>;
  readonly primaryPhoneCountry?: InputMaybe<Scalars['String']>;
  readonly primaryPhoneNumber?: InputMaybe<Scalars['String']>;
  readonly primaryEmail?: InputMaybe<Scalars['String']>;
  readonly isSmsEnabled?: InputMaybe<Scalars['Boolean']>;
  readonly preferredGenderPronoun?: InputMaybe<Scalars['String']>;
  readonly preferredGenderPronounTerm?: InputMaybe<Scalars['String']>;
  readonly maritalStatus?: InputMaybe<Scalars['String']>;
  readonly maritalStatusTerm?: InputMaybe<Scalars['String']>;
  readonly spouseName?: InputMaybe<Scalars['String']>;
  readonly children?: InputMaybe<Scalars['String']>;
  readonly childrenTerm?: InputMaybe<Scalars['String']>;
  readonly militaryReserveObligations?: InputMaybe<Scalars['String']>;
  readonly militaryReserveObligationsTerm?: InputMaybe<Scalars['String']>;
  readonly personalParkingSpace?: InputMaybe<Scalars['String']>;
  readonly personalParkingSpaceTerm?: InputMaybe<Scalars['String']>;
  readonly professionType?: InputMaybe<Scalars['String']>;
  readonly professionalTypeTerm?: InputMaybe<Scalars['String']>;
  readonly partnerName?: InputMaybe<Scalars['String']>;
  readonly partnerNumber?: InputMaybe<Scalars['String']>;
  readonly hireDate?: InputMaybe<Scalars['DateTime']>;
  readonly years_service?: InputMaybe<Scalars['String']>;
  readonly classTerm?: InputMaybe<Scalars['String']>;
  readonly fleet?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly projectTerm?: InputMaybe<Scalars['String']>;
  readonly program?: InputMaybe<Scalars['String']>;
  readonly programTerm?: InputMaybe<Scalars['String']>;
  readonly region?: InputMaybe<Scalars['String']>;
  readonly domicile?: InputMaybe<Scalars['String']>;
  readonly segment?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly milesRun?: InputMaybe<Scalars['Float']>;
  readonly status?: InputMaybe<Scalars['String']>;
  readonly statusTerm?: InputMaybe<Scalars['String']>;
  readonly hosRule?: InputMaybe<Scalars['String']>;
  readonly hosRuleTerm?: InputMaybe<Scalars['String']>;
  readonly permanentTractor?: InputMaybe<Scalars['String']>;
  readonly tractorType?: InputMaybe<Scalars['String']>;
  readonly tractorTypeTerm?: InputMaybe<Scalars['String']>;
  readonly trailerType?: InputMaybe<Scalars['String']>;
  readonly trailerTypeTerm?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly allottedVacationDays?: InputMaybe<Scalars['Float']>;
  readonly vacationDaysRemaining?: InputMaybe<Scalars['Float']>;
  readonly vacationResetDate?: InputMaybe<Scalars['DateTime']>;
  readonly allottedSickDays?: InputMaybe<Scalars['Float']>;
  readonly sickDaysRemaining?: InputMaybe<Scalars['Float']>;
  readonly sickResetDate?: InputMaybe<Scalars['DateTime']>;
  readonly assignedTractor?: InputMaybe<Scalars['String']>;
  readonly dutyStatus?: InputMaybe<Scalars['String']>;
  readonly remainingHours?: InputMaybe<Scalars['Float']>;
  readonly nextReset?: InputMaybe<Scalars['DateTime']>;
  readonly calculatedDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly calculatedCity?: InputMaybe<Scalars['String']>;
  readonly calculatedState?: InputMaybe<Scalars['String']>;
  readonly preferredDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly preferredCity?: InputMaybe<Scalars['String']>;
  readonly preferredState?: InputMaybe<Scalars['String']>;
  readonly assignedTrailers?: InputMaybe<ReadonlyArray<DriverAssignedTrailersInput>>;
  /** A collection of permanentTrailers associated with the driver. */
  readonly permanentTrailers?: InputMaybe<ReadonlyArray<DriverPermanentTrailersInput>>;
  /** DEPRECATED: driverTimeOff is deprecated. Use driverTimeOffV2 instead */
  readonly driverTimeOff?: InputMaybe<ReadonlyArray<DriverTimeOffInput>>;
  /** A collection of timeOff associated with the driver. */
  readonly driverTimeOffV2?: InputMaybe<ReadonlyArray<DriverTimeOffInputV2>>;
  readonly driverIdentifiers?: InputMaybe<ReadonlyArray<DriverIdentifiersInput>>;
  readonly driverAddress?: InputMaybe<ReadonlyArray<DriverAddressInput>>;
  readonly driverContacts?: InputMaybe<ReadonlyArray<DriverContactInput>>;
  /** DEPRECATED: driverCertificationsorPermits is deprecated. Use driverCertificationsorPermitsV2 instead */
  readonly driverCertificationsorPermits?: InputMaybe<ReadonlyArray<DriverCertificationsOrPermitsInput>>;
  /** A collection of certificationsorPermits associated with the driver. */
  readonly driverCertificationsorPermitsV2?: InputMaybe<ReadonlyArray<DriverCertificationsOrPermitsInputV2>>;
  /** DEPRECATED: driverSchedule is deprecated. Use driverScheduleV2 instead */
  readonly driverSchedule?: InputMaybe<ReadonlyArray<DriverScheduleInput>>;
  /** A collection of driver schedule associated with the driver. */
  readonly driverScheduleV2?: InputMaybe<ReadonlyArray<DriverScheduleInputV2>>;
  readonly driverAwards?: InputMaybe<ReadonlyArray<DriverAwardInput>>;
  /** DEPRECATED: driverEquipment is deprecated. Use driverEquipmentV2 instead */
  readonly driverEquipment?: InputMaybe<ReadonlyArray<DriverEquipmentInput>>;
  /** A collection of equipment associated with the driver. */
  readonly driverEquipmentV2?: InputMaybe<ReadonlyArray<DriverEquipmentInputV2>>;
  readonly driverFacility?: InputMaybe<ReadonlyArray<DriverFacilityPreferencesInput>>;
  readonly driverCommodity?: InputMaybe<ReadonlyArray<DriverCommodityPreferencesInput>>;
  readonly driverCustomer?: InputMaybe<ReadonlyArray<DriverCustomerPreferencesInput>>;
  readonly driverTeam?: InputMaybe<ReadonlyArray<DriverTeamPreferencesInput>>;
  readonly driverOperational?: InputMaybe<ReadonlyArray<DriverOperationalPreferencesInput>>;
  readonly driverGeography?: InputMaybe<ReadonlyArray<DriverGeographyPreferencesInput>>;
  readonly driverCareerGoals?: InputMaybe<ReadonlyArray<DriverCareerGoalsPreferencesInput>>;
  readonly driverPreferredLanes?: InputMaybe<ReadonlyArray<DriverPreferredLanesInput>>;
  /** DEPRECATED: permanentPowers is deprecated. Use permanentPowerUnit instead */
  readonly permanentPowers?: InputMaybe<ReadonlyArray<DriverPermanentPowerInput>>;
  /** References to the power records that want to associate this driver too. */
  readonly permanentPowerUnit?: InputMaybe<ReadonlyArray<DriverPermanentPowerInput>>;
  readonly driverPreferredRoutes?: InputMaybe<ReadonlyArray<DriverPreferredRoutesInput>>;
  readonly driverWeeklyTargets?: InputMaybe<ReadonlyArray<DriverWeeklyTargetInput>>;
  readonly driverTrainings?: InputMaybe<ReadonlyArray<DriverTrainingInput>>;
};

export type AssetDriverInputV2 = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly code: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly middleName?: InputMaybe<Scalars['String']>;
  readonly lastName: Scalars['String'];
  readonly nameSuffixTerm?: InputMaybe<Scalars['String']>;
  readonly nickname?: InputMaybe<Scalars['String']>;
  readonly managerName?: InputMaybe<Scalars['String']>;
  readonly phoneCountryCode?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly isSmsEnabled?: InputMaybe<Scalars['Boolean']>;
  readonly preferredGenderPronounTerm?: InputMaybe<Scalars['String']>;
  readonly maritalStatusTerm?: InputMaybe<Scalars['String']>;
  readonly spouseName?: InputMaybe<Scalars['String']>;
  readonly childrenTerm?: InputMaybe<Scalars['String']>;
  readonly militaryReserveObligationsTerm?: InputMaybe<Scalars['String']>;
  readonly personalParkingSpaceTerm?: InputMaybe<Scalars['String']>;
  readonly professionalTypeTerm?: InputMaybe<Scalars['String']>;
  readonly partnerCode?: InputMaybe<Scalars['String']>;
  readonly hireDate?: InputMaybe<Scalars['Date']>;
  readonly commercialDriverClassTerm?: InputMaybe<Scalars['String']>;
  readonly fleetCarrierCode?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly program?: InputMaybe<Scalars['String']>;
  readonly region?: InputMaybe<Scalars['String']>;
  readonly domicile?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly milesRun?: InputMaybe<Scalars['Int']>;
  readonly statusTerm?: InputMaybe<Scalars['String']>;
  readonly hosRuleTerm?: InputMaybe<Scalars['String']>;
  readonly vacationDays?: InputMaybe<LeaveInput>;
  readonly sickDays?: InputMaybe<LeaveInput>;
  readonly dutyStatus?: InputMaybe<Scalars['String']>;
  readonly remainingHours?: InputMaybe<Scalars['Int']>;
  readonly nextResetDate?: InputMaybe<Scalars['Date']>;
  readonly preferredNextAvailableLocation?: InputMaybe<PreferredNextAvailableLocationInput>;
  readonly permanentTrailerCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentPowerUnitCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A collection of Address associated with the driver. */
  readonly addresses?: InputMaybe<ReadonlyArray<DriverAddressInputV2>>;
  /** A collection of Contacts associated with the driver. */
  readonly contacts?: InputMaybe<ReadonlyArray<DriverContactInputV2>>;
  /** A collection of Awards associated with the driver. */
  readonly awards?: InputMaybe<ReadonlyArray<DriverAwardInputV2>>;
  /** A collection of Equipments associated with the driver. */
  readonly equipment?: InputMaybe<ReadonlyArray<DriverEquipmentInputV3>>;
  /** A collection of Certifications Or Permits associated with the driver. */
  readonly certificationsOrPermits?: InputMaybe<ReadonlyArray<DriverCertificationOrPermitInputV3>>;
  /** A collection of Schedules associated with the driver. */
  readonly schedules?: InputMaybe<ReadonlyArray<DriverScheduleInputV3>>;
  /** A collection of TimeOff associated with the driver. */
  readonly timeOff?: InputMaybe<ReadonlyArray<DriverTimeOffInputV3>>;
  /** A collection of Identifiers associated with the driver. */
  readonly identifiers?: InputMaybe<ReadonlyArray<DriverIdentifierInputV2>>;
};

export type AssetDriverV2 = {
  readonly __typename?: 'AssetDriverV2';
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly middleName?: Maybe<Scalars['String']>;
  readonly lastName: Scalars['String'];
  readonly nameSuffixTerm?: Maybe<Scalars['String']>;
  readonly nickname?: Maybe<Scalars['String']>;
  readonly managerName?: Maybe<Scalars['String']>;
  readonly phoneCountryCode?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly emailAddress?: Maybe<Scalars['String']>;
  readonly isSmsEnabled?: Maybe<Scalars['Boolean']>;
  readonly preferredGenderPronounTerm?: Maybe<Scalars['String']>;
  readonly maritalStatusTerm?: Maybe<Scalars['String']>;
  readonly spouseName?: Maybe<Scalars['String']>;
  readonly childrenTerm?: Maybe<Scalars['String']>;
  readonly militaryReserveObligationsTerm?: Maybe<Scalars['String']>;
  readonly personalParkingSpaceTerm?: Maybe<Scalars['String']>;
  readonly professionalTypeTerm?: Maybe<Scalars['String']>;
  readonly partnerCode?: Maybe<Scalars['String']>;
  readonly partnerName?: Maybe<Scalars['String']>;
  readonly hireDate?: Maybe<Scalars['Date']>;
  readonly yearsOfService?: Maybe<Scalars['Int']>;
  readonly commercialDriverClassTerm?: Maybe<Scalars['String']>;
  readonly fleetCarrierCode?: Maybe<Scalars['String']>;
  readonly divisionTerm?: Maybe<Scalars['String']>;
  readonly project?: Maybe<Scalars['String']>;
  readonly program?: Maybe<Scalars['String']>;
  readonly region?: Maybe<Scalars['String']>;
  readonly domicile?: Maybe<Scalars['String']>;
  readonly businessUnitTerm?: Maybe<Scalars['String']>;
  readonly milesRun?: Maybe<Scalars['Int']>;
  readonly statusTerm?: Maybe<Scalars['String']>;
  readonly hosRuleTerm?: Maybe<Scalars['String']>;
  readonly vacationDays?: Maybe<LeaveOutput>;
  readonly sickDays?: Maybe<LeaveOutput>;
  readonly dutyStatus?: Maybe<Scalars['String']>;
  readonly remainingHours?: Maybe<Scalars['Int']>;
  readonly nextResetDate?: Maybe<Scalars['Date']>;
  readonly preferredNextAvailableLocation?: Maybe<DriverLocationOutput>;
  readonly permanentTrailerCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentPowerUnitCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** A collection of Address associated with the driver. */
  readonly addresses?: Maybe<ReadonlyArray<DriverAddressV2>>;
  /** A collection of Contacts associated with the driver. */
  readonly contacts?: Maybe<ReadonlyArray<DriverContactV2>>;
  /** A collection of Awards associated with the driver. */
  readonly awards?: Maybe<ReadonlyArray<DriverAwardV2>>;
  /** A collection of Equipments associated with the driver. */
  readonly equipment?: Maybe<ReadonlyArray<DriverEquipmentV3>>;
  /** A collection of Certifications Or Permits associated with the driver. */
  readonly certificationsOrPermits?: Maybe<ReadonlyArray<DriverCertificationOrPermitV3>>;
  /** A collection of Schedules associated with the driver. */
  readonly schedules?: Maybe<ReadonlyArray<DriverScheduleV3>>;
  /** A collection of TimeOff associated with the driver. */
  readonly timeOff?: Maybe<ReadonlyArray<DriverTimeOffV3>>;
  /** A collection of Identifiers associated with the driver. */
  readonly identifiers?: Maybe<ReadonlyArray<DriverIdentifierV2>>;
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type AssetPowerEquipmentOutput = {
  readonly __typename?: 'AssetPowerEquipmentOutput';
  readonly id: Scalars['ID'];
  /** @deprecated equipmentCd is deprecated. Use typeTerm instead. */
  readonly equipmentCd: Scalars['String'];
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count?: Maybe<Scalars['Int']>;
  readonly assetId: Scalars['String'];
  /** @deprecated condition is deprecated. Use conditionTerm instead. */
  readonly condition: Scalars['String'];
  readonly conditionTerm: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
  readonly powerId?: Maybe<Scalars['String']>;
  readonly issueDate?: Maybe<Scalars['DateTime']>;
  readonly returnDate?: Maybe<Scalars['DateTime']>;
};

export type AssetPowerEquipmentOutputV2 = {
  readonly __typename?: 'AssetPowerEquipmentOutputV2';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId: Scalars['Int'];
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['Date'];
  readonly returnDate?: Maybe<Scalars['Date']>;
  readonly powerId?: Maybe<Scalars['String']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type AssetPowerIdentifierOutput = {
  readonly __typename?: 'AssetPowerIdentifierOutput';
  readonly id: Scalars['ID'];
  /** @deprecated identifierType is deprecated. Use typeTerm instead. */
  readonly identifierType: Scalars['String'];
  /** @deprecated identifierNumber is deprecated. Use value instead. */
  readonly identifierNumber: Scalars['String'];
  readonly state: Scalars['String'];
  /** @deprecated countryCd is deprecated. Use country instead. */
  readonly countryCd: Scalars['String'];
  readonly expirationDt: Scalars['DateTime'];
  readonly isActive: Scalars['Boolean'];
  readonly powerId?: Maybe<Scalars['String']>;
  /** @deprecated expirationDtV1 is deprecated. Use expirationDate instead. */
  readonly expirationDtV1: Scalars['String'];
};

export type AssetPowerIdentifierOutputV2 = {
  readonly __typename?: 'AssetPowerIdentifierOutputV2';
  readonly id: Scalars['ID'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly powerId?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
};

export type AssetPowerIdentifierOutputV3 = {
  readonly __typename?: 'AssetPowerIdentifierOutputV3';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly identifierId: Scalars['String'];
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly powerId?: Maybe<Scalars['String']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type AssetPowerLegalToRunOutPut = {
  readonly __typename?: 'AssetPowerLegalToRunOutPut';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly powerId?: Maybe<Scalars['String']>;
};

export type AssetPowerMaintenanceOutput = {
  readonly __typename?: 'AssetPowerMaintenanceOutput';
  readonly id: Scalars['ID'];
  /** @deprecated type is deprecated. Use typeTerm instead. */
  readonly type: Scalars['String'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly planned?: Maybe<Scalars['Boolean']>;
  /** @deprecated severity is deprecated. Use severityTerm instead. */
  readonly severity: Scalars['String'];
  readonly severityTerm?: Maybe<Scalars['String']>;
  readonly maintenanceFacility?: Maybe<Scalars['String']>;
  readonly location?: Maybe<Scalars['String']>;
  readonly expStartDate?: Maybe<Scalars['DateTime']>;
  readonly expStartTime?: Maybe<Scalars['String']>;
  readonly actStartDate?: Maybe<Scalars['DateTime']>;
  readonly actStartTime?: Maybe<Scalars['String']>;
  readonly estMaintTm?: Maybe<Scalars['String']>;
  readonly espEndDate?: Maybe<Scalars['DateTime']>;
  readonly espEndTime?: Maybe<Scalars['String']>;
  readonly actEndDate?: Maybe<Scalars['DateTime']>;
  readonly actEndTime?: Maybe<Scalars['String']>;
  readonly workPerformedDetail?: Maybe<Scalars['String']>;
  readonly powerId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AssetPowerMaintenanceOutputV2 = {
  readonly __typename?: 'AssetPowerMaintenanceOutputV2';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly severityTerm: Scalars['String'];
  readonly isPlanned?: Maybe<Scalars['Boolean']>;
  readonly facilityName?: Maybe<Scalars['String']>;
  readonly location?: Maybe<Scalars['String']>;
  readonly estimatedMaintenanceTime?: Maybe<Scalars['Int']>;
  readonly actual?: Maybe<DateTimeEntryOutput>;
  readonly expected?: Maybe<DateTimeEntryOutput>;
  readonly workPerformedDetail?: Maybe<Scalars['String']>;
  readonly powerId?: Maybe<Scalars['String']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type AssetPowerOutput = {
  readonly __typename?: 'AssetPowerOutput';
  readonly id: Scalars['ID'];
  /** @deprecated make is deprecated. Use makeTerm instead. */
  readonly make: Scalars['String'];
  readonly makeTerm?: Maybe<Scalars['String']>;
  /** @deprecated type is deprecated. Use typeTerm instead. */
  readonly type: Scalars['String'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly year?: Maybe<Scalars['Float']>;
  /** @deprecated model is deprecated. Use modelTerm instead. */
  readonly model: Scalars['String'];
  readonly modelTerm?: Maybe<Scalars['String']>;
  /**
   * DEPRECATED: legalToRun is deprecated. Use legalToRunTerm instead
   * @deprecated legalToRun is deprecated. Use legalToRunTerm instead.
   */
  readonly legalToRun?: Maybe<ReadonlyArray<AssetPowerLegalToRunOutPut>>;
  /** This field describes the list of countries details where the trailer is legal to run(Ex. Dry Van , CAN) */
  readonly legalToRunTerm?: Maybe<ReadonlyArray<AssetPowerLegalToRunOutPut>>;
  readonly equipmentDetails?: Maybe<ReadonlyArray<AssetPowerEquipmentOutput>>;
  /**
   * DEPRECATED: identifiers is deprecated. Use identifiersV2 instead
   * @deprecated identifiers is deprecated. Use identifiersV2 instead.
   */
  readonly identifiers?: Maybe<ReadonlyArray<AssetPowerIdentifierOutput>>;
  /** A collection of identifiers associated with the power. */
  readonly identifiersV2?: Maybe<ReadonlyArray<AssetPowerIdentifierOutputV2>>;
  /**
   * DEPRECATED: permanentDriver is deprecated. Use permanentDrivers instead
   * @deprecated permanentDriver is deprecated. Use permanentDrivers instead.
   */
  readonly permanentDriver?: Maybe<ReadonlyArray<AssetPowerPermanentDriverOutput>>;
  /** A collection of permanent driver associated with the power. */
  readonly permanentDrivers?: Maybe<ReadonlyArray<AssetPowerPermanentDriverOutput>>;
  /**
   * DEPRECATED: permanentTrailer is deprecated. Use permanentTrailers instead
   * @deprecated permanentTrailer is deprecated. Use permanentTrailers instead.
   */
  readonly permanentTrailer?: Maybe<ReadonlyArray<AssetPowerPermanentTrailerOutput>>;
  /** A collection of permanent trailer associated with the power. */
  readonly permanentTrailers?: Maybe<ReadonlyArray<AssetPowerPermanentTrailerOutput>>;
  readonly maintenance?: Maybe<ReadonlyArray<AssetPowerMaintenanceOutput>>;
  readonly isCARBCompliant?: Maybe<Scalars['Boolean']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated powerDevice is deprecated. Use trackingDeviceTerm instead. */
  readonly powerDevice: Scalars['String'];
  readonly trackingDeviceTerm?: Maybe<Scalars['String']>;
  /** @deprecated powerModel is deprecated. Use trackingModelTerm instead. */
  readonly powerModel: Scalars['String'];
  readonly trackingModelTerm?: Maybe<Scalars['String']>;
  readonly serialNumber?: Maybe<Scalars['String']>;
  /** @deprecated powerNumber is deprecated. Use code instead. */
  readonly powerNumber: Scalars['String'];
  readonly code?: Maybe<Scalars['String']>;
  /** @deprecated owner is deprecated. Use ownerTerm instead. */
  readonly owner: Scalars['String'];
  readonly ownerTerm?: Maybe<Scalars['String']>;
  /** @deprecated class is deprecated. Use classTerm instead. */
  readonly class: Scalars['String'];
  readonly classTerm?: Maybe<Scalars['String']>;
  readonly fleetName?: Maybe<Scalars['String']>;
  /** @deprecated segment is deprecated. Use businessUnitTerm instead. */
  readonly segment: Scalars['String'];
  readonly businessUnitTerm?: Maybe<Scalars['String']>;
  /** @deprecated division is deprecated. Use divisionTerm instead. */
  readonly division: Scalars['String'];
  readonly divisionTerm?: Maybe<Scalars['String']>;
  readonly odomoter?: Maybe<Scalars['Float']>;
  readonly exteriorColor?: Maybe<Scalars['String']>;
  readonly interiorColor?: Maybe<Scalars['String']>;
  /** @deprecated sleeperType is deprecated. Use sleeperTypeTerm instead. */
  readonly sleeperType: Scalars['String'];
  readonly sleeperTypeTerm?: Maybe<Scalars['String']>;
  /** @deprecated axleConfiguration is deprecated. Use axleConfigurationTerm instead. */
  readonly axleConfiguration: Scalars['String'];
  readonly axleConfigurationTerm?: Maybe<Scalars['String']>;
  /** @deprecated suspension is deprecated. Use suspensionTerm instead. */
  readonly suspension: Scalars['String'];
  readonly suspensionTerm?: Maybe<Scalars['String']>;
  /** @deprecated sleeperSize is deprecated. Use sleeperSizeDimensionsTerm instead. */
  readonly sleeperSize: Scalars['Float'];
  /** @deprecated sleeperSizeTerm is deprecated. Use sleeperSizeDimensionsTerm instead. */
  readonly sleeperSizeTerm: Scalars['Float'];
  readonly sleeperSizeDimensionsTerm?: Maybe<Scalars['String']>;
  readonly weight?: Maybe<Scalars['String']>;
  readonly engineMake?: Maybe<Scalars['String']>;
  readonly engineModel?: Maybe<Scalars['String']>;
  readonly engineHP?: Maybe<Scalars['Float']>;
  /** @deprecated engineBrake is deprecated. Use engineBrakeTerm instead. */
  readonly engineBrake: Scalars['String'];
  readonly engineBrakeTerm?: Maybe<Scalars['String']>;
  readonly transmissionMake?: Maybe<Scalars['String']>;
  readonly transmissionModel?: Maybe<Scalars['String']>;
  readonly transmissionSpeed?: Maybe<Scalars['Float']>;
  readonly readEndRatio?: Maybe<Scalars['Float']>;
  readonly wheelBase?: Maybe<Scalars['Float']>;
  readonly tankCapacity1?: Maybe<Scalars['Float']>;
  readonly tankCapacity2?: Maybe<Scalars['Float']>;
  /** @deprecated wheel5 is deprecated. Use fifthWheel instead. */
  readonly wheel5: Scalars['String'];
  readonly fifthWheel?: Maybe<Scalars['String']>;
  /** @deprecated note is deprecated. Use notes instead. */
  readonly note: Scalars['String'];
  readonly notes?: Maybe<Scalars['String']>;
  /** @deprecated operatingStatus is deprecated. Use operatingStatusTerm instead. */
  readonly operatingStatus: Scalars['String'];
  readonly operatingStatusTerm?: Maybe<Scalars['String']>;
  /** @deprecated serviceStatus is deprecated. Use serviceStatusTerm instead. */
  readonly serviceStatus: Scalars['String'];
  readonly serviceStatusTerm?: Maybe<Scalars['String']>;
  readonly pool?: Maybe<Scalars['String']>;
  readonly lastPingLocation?: Maybe<Scalars['String']>;
  /** @deprecated lastPingDateTm is deprecated. Use lastPingDateTime instead. */
  readonly lastPingDateTm: Scalars['String'];
  readonly lastPingDateTime?: Maybe<Scalars['String']>;
  readonly lastFacility?: Maybe<Scalars['String']>;
  readonly facilityLocation?: Maybe<Scalars['String']>;
  readonly programTerm?: Maybe<Scalars['String']>;
  readonly projectTerm?: Maybe<Scalars['String']>;
  readonly measurementTerm?: Maybe<Scalars['String']>;
  readonly odometerUOMTerm?: Maybe<Scalars['String']>;
  readonly weightUOMTerm?: Maybe<Scalars['String']>;
  readonly wheelBaseUOMTerm?: Maybe<Scalars['String']>;
  readonly tankCapacity1UOMTerm?: Maybe<Scalars['String']>;
  readonly tankCapacity2UOMTerm?: Maybe<Scalars['String']>;
};

export type AssetPowerOutputV2 = {
  readonly __typename?: 'AssetPowerOutputV2';
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly makeTerm?: Maybe<Scalars['String']>;
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly year?: Maybe<Scalars['Int']>;
  readonly modelTerm?: Maybe<Scalars['String']>;
  readonly isCARBCompliant?: Maybe<Scalars['Boolean']>;
  readonly trackingDevice?: Maybe<PowerDeviceOutput>;
  readonly serialNumber?: Maybe<Scalars['String']>;
  readonly ownerTerm?: Maybe<Scalars['String']>;
  readonly classTerm?: Maybe<Scalars['String']>;
  readonly fleetCarrierCode?: Maybe<Scalars['String']>;
  readonly businessUnitTerm?: Maybe<Scalars['String']>;
  readonly divisionTerm?: Maybe<Scalars['String']>;
  readonly odometerMiles?: Maybe<Scalars['Int']>;
  readonly exteriorColor?: Maybe<Scalars['String']>;
  readonly interiorColor?: Maybe<Scalars['String']>;
  readonly sleeperTypeTerm?: Maybe<Scalars['String']>;
  readonly axleConfigurationTerm?: Maybe<Scalars['String']>;
  readonly suspensionTerm?: Maybe<Scalars['String']>;
  readonly sleeperSizeDimensionsTerm?: Maybe<Scalars['String']>;
  readonly powerWeight?: Maybe<UnitOfWeightOutput>;
  readonly engineMakeTerm?: Maybe<Scalars['String']>;
  readonly engineModelTerm?: Maybe<Scalars['String']>;
  readonly engineHP?: Maybe<Scalars['String']>;
  readonly isEngineBrakeAvailable?: Maybe<Scalars['Boolean']>;
  readonly transmissionMakeTerm?: Maybe<Scalars['String']>;
  readonly transmissionModelTerm?: Maybe<Scalars['String']>;
  readonly transmissionSpeed?: Maybe<Scalars['Int']>;
  readonly rearEndRatio?: Maybe<Scalars['String']>;
  readonly wheelBase?: Maybe<UnitOfLengthOutputV2>;
  readonly tankCapacity1?: Maybe<UnitOfCapacityOutput>;
  readonly tankCapacity2?: Maybe<UnitOfCapacityOutput>;
  readonly fifthWheelTerm?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly operatingStatusTerm?: Maybe<Scalars['String']>;
  readonly serviceStatusTerm?: Maybe<Scalars['String']>;
  readonly pool?: Maybe<Scalars['String']>;
  readonly lastPingLocation?: Maybe<Scalars['String']>;
  readonly lastPingDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastFacility?: Maybe<Scalars['String']>;
  readonly facilityLocation?: Maybe<Scalars['String']>;
  readonly program?: Maybe<Scalars['String']>;
  readonly project?: Maybe<Scalars['String']>;
  readonly measurementTerm?: Maybe<Scalars['String']>;
  readonly equipmentDetails?: Maybe<ReadonlyArray<AssetPowerEquipmentOutputV2>>;
  /** A collection of identifiers associated with the power. */
  readonly identifiers?: Maybe<ReadonlyArray<AssetPowerIdentifierOutputV3>>;
  readonly maintenance?: Maybe<ReadonlyArray<AssetPowerMaintenanceOutputV2>>;
  readonly legalToRunTerm?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentTrailerCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentDriverCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type AssetPowerPermanentDriverOutput = {
  readonly __typename?: 'AssetPowerPermanentDriverOutput';
  readonly id: Scalars['ID'];
  readonly permanentDriverId: Scalars['String'];
  readonly powerId?: Maybe<Scalars['String']>;
  readonly permanentDriverIdV1?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
};

export type AssetPowerPermanentTrailerOutput = {
  readonly __typename?: 'AssetPowerPermanentTrailerOutput';
  readonly id: Scalars['ID'];
  readonly permanentTrailerId: Scalars['String'];
  readonly powerId?: Maybe<Scalars['String']>;
  readonly permanentTrailerIdV1?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
};

export type AssetTrailers = {
  readonly __typename?: 'AssetTrailers';
  readonly id: Scalars['ID'];
  /** @deprecated make is deprecated. Use makeTerm instead. */
  readonly make: Scalars['String'];
  readonly makeTerm?: Maybe<Scalars['String']>;
  /** @deprecated type is deprecated. Use typeTerm instead. */
  readonly type?: Maybe<Scalars['String']>;
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly year?: Maybe<Scalars['Float']>;
  /** @deprecated model is deprecated. Use modelTerm instead. */
  readonly model?: Maybe<Scalars['String']>;
  readonly modelTerm?: Maybe<Scalars['String']>;
  /**
   * DEPRECATED: legalToRun is deprecated. Use legalToRunTerm instead
   * @deprecated legalToRun is deprecated. Use legalToRunTerm instead.
   */
  readonly legalToRun?: Maybe<ReadonlyArray<AssetTrailersLegalToRun>>;
  /** This field describes the list of countries details where the trailer is legal to run(Ex. Dry Van , CAN) */
  readonly legalToRunTerm?: Maybe<ReadonlyArray<AssetTrailersLegalToRun>>;
  readonly equipmentDetails?: Maybe<ReadonlyArray<AssetTrailersEquipmentOutput>>;
  /**
   * DEPRECATED: identifiers is deprecated. Use identifiersV2 instead
   * @deprecated identifiers is deprecated. Use identifiersV2 instead.
   */
  readonly identifiers?: Maybe<ReadonlyArray<AssetTrailersIdentifierOutput>>;
  /** A collection of identifiers associated with the trailer. */
  readonly identifiersV2?: Maybe<ReadonlyArray<AssetTrailersIdentifierOutputV2>>;
  /**
   * DEPRECATED: permanentDriver is deprecated. Use permanentDrivers instead
   * @deprecated permanentDriver is deprecated. Use permanentDrivers instead.
   */
  readonly permanentDriver?: Maybe<ReadonlyArray<AssetTrailersPermanentDriverOutput>>;
  /** A collection of permanent driver (codes) associated with the trailer. */
  readonly permanentDrivers?: Maybe<ReadonlyArray<AssetTrailersPermanentDriverOutput>>;
  /**
   * DEPRECATED: permanentTractor is deprecated. Use permanentPowerUnit instead
   * @deprecated permanentTractor is deprecated. Use permanentPowerUnit instead.
   */
  readonly permanentTractor?: Maybe<ReadonlyArray<AssetTrailersPermanentTractorOutput>>;
  /** References to the power records that want to associate this trailer too. */
  readonly permanentPowerUnit?: Maybe<ReadonlyArray<AssetTrailersPermanentTractorOutput>>;
  readonly maintenance?: Maybe<ReadonlyArray<AssetTrailersMaintenanceOutput>>;
  readonly isCARBCompliant?: Maybe<Scalars['Boolean']>;
  /** @deprecated trailerDevice is deprecated. Use trailerDeviceTerm instead. */
  readonly trailerDevice?: Maybe<Scalars['String']>;
  readonly trailerDeviceTerm?: Maybe<Scalars['String']>;
  /** @deprecated trailerModel is deprecated. Use trailerModelTerm instead. */
  readonly trailerModel?: Maybe<Scalars['String']>;
  readonly trailerModelTerm?: Maybe<Scalars['String']>;
  readonly serialNumber?: Maybe<Scalars['String']>;
  /** @deprecated trailerNumber is deprecated. Use code instead. */
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  /** @deprecated owner is deprecated. Use ownerTerm instead. */
  readonly owner?: Maybe<Scalars['String']>;
  readonly ownerTerm?: Maybe<Scalars['String']>;
  readonly odomoter?: Maybe<Scalars['Float']>;
  /** @deprecated note is deprecated. Use notes instead. */
  readonly note?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly color?: Maybe<Scalars['String']>;
  /** @deprecated floor is deprecated. Use floorTerm instead. */
  readonly floor?: Maybe<Scalars['String']>;
  readonly floorTerm?: Maybe<Scalars['String']>;
  /** @deprecated roof is deprecated. Use roofTerm instead. */
  readonly roof?: Maybe<Scalars['String']>;
  readonly roofTerm?: Maybe<Scalars['String']>;
  /** @deprecated doors is deprecated. Use doorsTerm instead. */
  readonly doors?: Maybe<Scalars['String']>;
  readonly doorsTerm?: Maybe<Scalars['String']>;
  /** @deprecated skin is deprecated. Use skinTerm instead. */
  readonly skin?: Maybe<Scalars['String']>;
  readonly skinTerm?: Maybe<Scalars['String']>;
  /** @deprecated rearTireSize is deprecated. */
  readonly rearTireSize?: Maybe<Scalars['String']>;
  readonly length?: Maybe<Scalars['String']>;
  readonly exteriorHeight?: Maybe<Scalars['String']>;
  readonly exteriorWidth?: Maybe<Scalars['String']>;
  readonly gvwr?: Maybe<Scalars['String']>;
  readonly gawr?: Maybe<Scalars['String']>;
  readonly weight?: Maybe<Scalars['String']>;
  readonly frontInteriorHeight?: Maybe<Scalars['String']>;
  readonly rearInteriorHeight?: Maybe<Scalars['String']>;
  readonly interiorWidth?: Maybe<Scalars['String']>;
  readonly doorWidth?: Maybe<Scalars['String']>;
  readonly landingGear?: Maybe<Scalars['String']>;
  readonly suspension?: Maybe<Scalars['String']>;
  /** @deprecated liftgateType is deprecated. Use liftgateTypeTerm instead. */
  readonly liftgateType?: Maybe<Scalars['String']>;
  readonly liftgateTypeTerm?: Maybe<Scalars['String']>;
  readonly liftgateCapacity?: Maybe<Scalars['String']>;
  readonly liftgateSize?: Maybe<Scalars['String']>;
  /** @deprecated axleType is deprecated. Use axleTypeTerm instead. */
  readonly axleType?: Maybe<Scalars['String']>;
  readonly axleTypeTerm?: Maybe<Scalars['String']>;
  /** @deprecated etracks is deprecated. Use eTracks instead. */
  readonly etracks?: Maybe<Scalars['String']>;
  readonly eTracks?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated lClosed is deprecated. Use lengthClosed instead. */
  readonly lClosed?: Maybe<Scalars['String']>;
  readonly lengthClosed?: Maybe<Scalars['String']>;
  /** @deprecated lExtended is deprecated. Use lengthExtended instead. */
  readonly lExtended?: Maybe<Scalars['String']>;
  readonly lengthExtended?: Maybe<Scalars['String']>;
  /** @deprecated operatingStatus is deprecated. Use operatingStatusTerm instead. */
  readonly operatingStatus?: Maybe<Scalars['String']>;
  readonly operatingStatusTerm?: Maybe<Scalars['String']>;
  /** @deprecated serviceStatus is deprecated. Use serviceStatusTerm instead. */
  readonly serviceStatus?: Maybe<Scalars['String']>;
  readonly serviceStatusTerm?: Maybe<Scalars['String']>;
  readonly fleet?: Maybe<Scalars['String']>;
  readonly pool?: Maybe<Scalars['String']>;
  /** @deprecated tempMonitoring is deprecated. Use tempMonitoringTerm instead. */
  readonly tempMonitoring?: Maybe<Scalars['String']>;
  readonly tempMonitoringTerm?: Maybe<Scalars['String']>;
  readonly remoteAccess?: Maybe<Scalars['String']>;
  readonly divisionTerm?: Maybe<Scalars['String']>;
  readonly projectTerm?: Maybe<Scalars['String']>;
  readonly programTerm?: Maybe<Scalars['String']>;
  readonly businessUnitTerm?: Maybe<Scalars['String']>;
  readonly measurementTerm?: Maybe<Scalars['String']>;
  readonly odometerUOMTerm?: Maybe<Scalars['String']>;
  readonly lengthUOMTerm?: Maybe<Scalars['String']>;
  readonly exteriorHeightUOMTerm?: Maybe<Scalars['String']>;
  readonly exteriorWidthUOMTerm?: Maybe<Scalars['String']>;
  readonly gvwrUOMTerm?: Maybe<Scalars['String']>;
  readonly gawrUOMTerm?: Maybe<Scalars['String']>;
  readonly weightUOMTerm?: Maybe<Scalars['String']>;
  readonly frontInteriorHeightUOMTerm?: Maybe<Scalars['String']>;
  readonly rearInteriorHeightUOMTerm?: Maybe<Scalars['String']>;
  readonly interiorWidthUOMTerm?: Maybe<Scalars['String']>;
  readonly doorWidthUOMTerm?: Maybe<Scalars['String']>;
  readonly tireWidth?: Maybe<Scalars['Int']>;
  readonly tireAspectRatio?: Maybe<Scalars['Int']>;
  readonly tireRimSize?: Maybe<Scalars['Int']>;
  readonly liftgateCapacityUOMTerm?: Maybe<Scalars['String']>;
  readonly liftgateSizeUOMTerm?: Maybe<Scalars['String']>;
  readonly lengthClosedUOMTerm?: Maybe<Scalars['String']>;
  readonly lengthExtendedUOMTerm?: Maybe<Scalars['String']>;
};

export type AssetTrailersEquipmentOutput = {
  readonly __typename?: 'AssetTrailersEquipmentOutput';
  readonly id: Scalars['ID'];
  /** @deprecated equipmentCd is deprecated. Use typeTerm instead. */
  readonly equipmentCd: Scalars['String'];
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count?: Maybe<Scalars['Int']>;
  readonly assetId: Scalars['String'];
  /** @deprecated condition is deprecated. Use conditionTerm instead. */
  readonly condition: Scalars['String'];
  readonly conditionTerm: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly issueDate?: Maybe<Scalars['DateTime']>;
  readonly returnDate?: Maybe<Scalars['DateTime']>;
};

export type AssetTrailersEquipmentOutputV2 = {
  readonly __typename?: 'AssetTrailersEquipmentOutputV2';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId: Scalars['Int'];
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['DateTime'];
  readonly returnDate?: Maybe<Scalars['DateTime']>;
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type AssetTrailersIdentifierOutput = {
  readonly __typename?: 'AssetTrailersIdentifierOutput';
  readonly id: Scalars['ID'];
  /** @deprecated identifierType is deprecated. Use typeTerm instead. */
  readonly identifierType: Scalars['String'];
  /** @deprecated identifierNumber is deprecated. Use value instead. */
  readonly identifierNumber: Scalars['String'];
  readonly state: Scalars['String'];
  /** @deprecated countryCd is deprecated. Use country instead. */
  readonly countryCd: Scalars['String'];
  /** @deprecated expirationDt is deprecated. Use expirationDate instead. */
  readonly expirationDt: Scalars['DateTime'];
  /** @deprecated expirationDtV1 is deprecated. Use expirationDate instead. */
  readonly expirationDtV1?: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AssetTrailersIdentifierOutputV2 = {
  readonly __typename?: 'AssetTrailersIdentifierOutputV2';
  readonly id: Scalars['ID'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly isActive: Scalars['Boolean'];
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AssetTrailersIdentifierOutputV3 = {
  readonly __typename?: 'AssetTrailersIdentifierOutputV3';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly identifierId: Scalars['String'];
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type AssetTrailersLegalToRun = {
  readonly __typename?: 'AssetTrailersLegalToRun';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly trailerId?: Maybe<Scalars['String']>;
};

export type AssetTrailersMaintenanceOutput = {
  readonly __typename?: 'AssetTrailersMaintenanceOutput';
  readonly id: Scalars['ID'];
  /** @deprecated type is deprecated. Use typeTerm instead. */
  readonly type?: Maybe<Scalars['String']>;
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly planned?: Maybe<Scalars['Boolean']>;
  readonly deadline?: Maybe<Scalars['DateTime']>;
  readonly appointment?: Maybe<Scalars['String']>;
  readonly mainFacility?: Maybe<Scalars['String']>;
  readonly location?: Maybe<Scalars['String']>;
  /** @deprecated severity is deprecated. Use severityTerm instead. */
  readonly severity?: Maybe<Scalars['String']>;
  readonly severityTerm?: Maybe<Scalars['String']>;
  readonly dateIn?: Maybe<Scalars['DateTime']>;
  /** @deprecated out is deprecated. Use dateOut instead. */
  readonly out?: Maybe<Scalars['String']>;
  readonly dateOut?: Maybe<Scalars['DateTime']>;
  readonly duration?: Maybe<Scalars['String']>;
  readonly workPerformedDetail?: Maybe<Scalars['String']>;
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AssetTrailersMaintenanceOutputV2 = {
  readonly __typename?: 'AssetTrailersMaintenanceOutputV2';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly severityTerm: Scalars['String'];
  readonly isPlanned?: Maybe<Scalars['Boolean']>;
  readonly deadlineDate?: Maybe<Scalars['DateTime']>;
  readonly appointmentDate?: Maybe<Scalars['DateTime']>;
  readonly facilityName?: Maybe<Scalars['String']>;
  readonly location?: Maybe<Scalars['String']>;
  readonly dateIn?: Maybe<Scalars['DateTime']>;
  readonly dateOut?: Maybe<Scalars['DateTime']>;
  readonly duration?: Maybe<Scalars['String']>;
  readonly workPerformedDetail?: Maybe<Scalars['String']>;
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type AssetTrailersPermanentDriverOutput = {
  readonly __typename?: 'AssetTrailersPermanentDriverOutput';
  readonly id: Scalars['ID'];
  readonly permanentDriverId: Scalars['String'];
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly permanentDriverIdV1: Scalars['String'];
  readonly code?: Maybe<Scalars['String']>;
};

export type AssetTrailersPermanentTractorOutput = {
  readonly __typename?: 'AssetTrailersPermanentTractorOutput';
  readonly id: Scalars['ID'];
  readonly permanentTractorId: Scalars['String'];
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly permanentTractorIdV1: Scalars['String'];
  readonly code?: Maybe<Scalars['String']>;
};

export type AssetTrailersV2 = {
  readonly __typename?: 'AssetTrailersV2';
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly makeTerm?: Maybe<Scalars['String']>;
  readonly year?: Maybe<Scalars['Int']>;
  readonly isCARBCompliant?: Maybe<Scalars['Boolean']>;
  readonly serialNumber?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly color?: Maybe<Scalars['String']>;
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly modelTerm?: Maybe<Scalars['String']>;
  readonly trackingDevice?: Maybe<TrailerDeviceOutput>;
  readonly ownerTerm?: Maybe<Scalars['String']>;
  readonly odometerMiles?: Maybe<Scalars['Int']>;
  readonly floorTerm?: Maybe<Scalars['String']>;
  readonly roofTerm?: Maybe<Scalars['String']>;
  readonly doorsTerm?: Maybe<Scalars['String']>;
  readonly skinTerm?: Maybe<Scalars['String']>;
  readonly trailerLength?: Maybe<UnitOfLengthOutputV2>;
  readonly exteriorHeight?: Maybe<UnitOfLengthOutputV2>;
  readonly exteriorWidth?: Maybe<UnitOfLengthOutputV2>;
  readonly grossVehicleWeightRating?: Maybe<UnitOfWeightOutput>;
  readonly grossAxleWeightRating?: Maybe<UnitOfWeightOutput>;
  readonly trailerWeight?: Maybe<UnitOfWeightOutput>;
  readonly frontInteriorHeight?: Maybe<UnitOfLengthOutputV2>;
  readonly rearInteriorHeight?: Maybe<UnitOfLengthOutputV2>;
  readonly interiorWidth?: Maybe<UnitOfLengthOutputV2>;
  readonly doorWidth?: Maybe<UnitOfLengthOutputV2>;
  readonly landingGearTerm?: Maybe<Scalars['String']>;
  readonly suspensionTerm?: Maybe<Scalars['String']>;
  readonly axleTypeTerm?: Maybe<Scalars['String']>;
  readonly liftgateTypeTerm?: Maybe<Scalars['String']>;
  readonly liftgateCapacity?: Maybe<UnitOfWeightOutput>;
  readonly liftgateSize?: Maybe<UnitOfLengthOutputV2>;
  readonly isETracks?: Maybe<Scalars['Boolean']>;
  readonly lengthClosed?: Maybe<UnitOfLengthOutputV2>;
  readonly lengthExtended?: Maybe<UnitOfLengthOutputV2>;
  readonly operatingStatusTerm?: Maybe<Scalars['String']>;
  readonly serviceStatusTerm?: Maybe<Scalars['String']>;
  readonly fleetCarrierCode?: Maybe<Scalars['String']>;
  readonly pool?: Maybe<Scalars['String']>;
  readonly tempMonitoringTerm?: Maybe<Scalars['String']>;
  readonly isRemoteAccessAvailable?: Maybe<Scalars['Boolean']>;
  readonly divisionTerm?: Maybe<Scalars['String']>;
  readonly project?: Maybe<Scalars['String']>;
  readonly program?: Maybe<Scalars['String']>;
  readonly businessUnitTerm?: Maybe<Scalars['String']>;
  readonly measurementTerm?: Maybe<Scalars['String']>;
  readonly tireWidth?: Maybe<Scalars['Int']>;
  readonly tireAspectRatio?: Maybe<Scalars['String']>;
  readonly tireRimSize?: Maybe<Scalars['Int']>;
  /** A collection of Equipments associated with the trailer. */
  readonly equipmentDetails?: Maybe<ReadonlyArray<AssetTrailersEquipmentOutputV2>>;
  /** A collection of identifiers associated with the trailer. */
  readonly identifiers?: Maybe<ReadonlyArray<AssetTrailersIdentifierOutputV3>>;
  readonly legalToRunTerm?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentPowerUnitCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentDriverCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** A collection of identifiers associated with the trailer. */
  readonly maintenance?: Maybe<ReadonlyArray<AssetTrailersMaintenanceOutputV2>>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type AssignAccountingChargeTypeToBlumeChargeTypeInput = {
  readonly blumeChargeTypeId: Scalars['ID'];
  readonly accountingChargeTypeId?: InputMaybe<Scalars['ID']>;
};

export type AssignAccountingChargeTypeToBlumeChargeTypePayload = {
  readonly __typename?: 'AssignAccountingChargeTypeToBlumeChargeTypePayload';
  readonly success: Scalars['Boolean'];
};

export type AssignFacilityToCityStateStopInput = {
  readonly routeStopId: Scalars['ID'];
  readonly facilityId: Scalars['ID'];
};

export type AssignVendorToRouteInput = {
  readonly bookedById: Scalars['ID'];
  readonly bookedWithId?: InputMaybe<Scalars['ID']>;
  readonly bookingNotes?: InputMaybe<Scalars['String']>;
  readonly bracingType?: InputMaybe<BracingTypeEnum>;
  readonly createdById: Scalars['ID'];
  readonly distance?: InputMaybe<UnitOfDistanceInput>;
  readonly expectedEmptyGeographySource?: InputMaybe<Scalars['String']>;
  readonly expectedEmptyGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly numberOfBraces?: InputMaybe<Scalars['Int']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  readonly numberOfTarps?: InputMaybe<Scalars['Int']>;
  readonly palletType?: InputMaybe<PalletTypeEnum>;
  readonly routeId: Scalars['ID'];
  readonly routeVendorReps?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorRepInput>>;
  readonly tarpType?: InputMaybe<TarpTypeEnum>;
  readonly trailerHeight?: InputMaybe<UnitOfDistanceInput>;
  readonly trailerLength?: InputMaybe<UnitOfDistanceInput>;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly trailerWidth?: InputMaybe<UnitOfDistanceInput>;
  readonly utcExpectedEmptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly utcPickupEtaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly vendorId: Scalars['ID'];
  readonly vendorType: RouteVendorTypeEnum;
};

/** Autogenerated return type of AssignVendorToRoute */
export type AssignVendorToRoutePayload = {
  readonly __typename?: 'AssignVendorToRoutePayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly routeVendor?: Maybe<RouteVendor>;
};

export type AssignVendorToRouteV2Input = {
  readonly bookedById: Scalars['ID'];
  readonly bookingSource?: InputMaybe<Scalars['String']>;
  readonly bookingSourceId?: InputMaybe<Scalars['ID']>;
  readonly bookedWithId?: InputMaybe<Scalars['ID']>;
  readonly bookingNotes?: InputMaybe<Scalars['String']>;
  readonly bracingType?: InputMaybe<BracingTypeEnum>;
  readonly createdById: Scalars['ID'];
  readonly distance?: InputMaybe<UnitOfDistanceInput>;
  readonly expectedEmptyGeographySource?: InputMaybe<Scalars['String']>;
  readonly expectedEmptyGeographySourceId?: InputMaybe<Scalars['String']>;
  readonly isAutomated?: InputMaybe<Scalars['Boolean']>;
  readonly numberOfBraces?: InputMaybe<Scalars['Int']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  readonly numberOfTarps?: InputMaybe<Scalars['Int']>;
  readonly palletType?: InputMaybe<PalletTypeEnum>;
  readonly routeId: Scalars['ID'];
  readonly routeVendorReps?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorRepV2Input>>;
  readonly tarpType?: InputMaybe<TarpTypeEnum>;
  readonly trailerHeight?: InputMaybe<UnitOfDistanceInput>;
  readonly trailerLength?: InputMaybe<UnitOfDistanceInput>;
  readonly trailerWidth?: InputMaybe<UnitOfDistanceInput>;
  readonly trailerHeightV2?: InputMaybe<LengthInput>;
  readonly trailerLengthV2?: InputMaybe<LengthInput>;
  readonly trailerWidthV2?: InputMaybe<LengthInput>;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly utcExpectedEmptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly utcPickupEtaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly vendorId: Scalars['ID'];
  readonly vendorType: RouteVendorTypeEnum;
};

export type AssignVendorToRouteV2Payload = {
  readonly __typename?: 'AssignVendorToRouteV2Payload';
  readonly errors: ReadonlyArray<UserError>;
  readonly routeVendor?: Maybe<RouteVendorV2>;
};

export type AssociatedOrderStops = {
  readonly __typename?: 'AssociatedOrderStops';
  readonly routeStops: ReadonlyArray<RouteStop>;
  readonly routeStopsV2: ReadonlyArray<RouteStopV2>;
};

export type AssociatedOrderStopsError = {
  readonly __typename?: 'AssociatedOrderStopsError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

export type AssociatedShipmentIdWithLoadIdInput = {
  readonly customerCode: Scalars['String'];
  readonly loadId: Scalars['ID'];
  readonly shipmentId: Scalars['String'];
};

export type AssociateLoadOrderStopInput = {
  readonly loadStopId: Scalars['String'];
  readonly isOrderStop: Scalars['Boolean'];
};

export type AssociateOrderStopInput = {
  readonly routeStopId: Scalars['String'];
  readonly isOrderStop: Scalars['Boolean'];
};

export type AssociateOrderStopsPayload = {
  readonly __typename?: 'AssociateOrderStopsPayload';
  readonly errors: ReadonlyArray<AssociatedOrderStopsError>;
  readonly associatedOrderStops?: Maybe<AssociatedOrderStops>;
};

export type Attachment = {
  readonly __typename?: 'Attachment';
  readonly id: Scalars['String'];
  readonly documentId: Scalars['String'];
  readonly filename: Scalars['String'];
  readonly email: Email;
};

export type AttachmentInput = {
  readonly documentId?: InputMaybe<Scalars['String']>;
  readonly filename?: InputMaybe<Scalars['String']>;
};

/** Audit History. */
export type AuditHistory = {
  readonly __typename?: 'AuditHistory';
  readonly modelType?: Maybe<Scalars['String']>;
  readonly changeType?: Maybe<Scalars['String']>;
  readonly recordId?: Maybe<Scalars['String']>;
  readonly recordName?: Maybe<Scalars['String']>;
  readonly timestamp?: Maybe<Scalars['String']>;
  readonly oldValue?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly identity?: Maybe<Scalars['String']>;
  readonly changes?: Maybe<ReadonlyArray<Change>>;
};

export type AuditTypeInterface = {
  readonly actionType: Scalars['String'];
  readonly auditType: Scalars['String'];
  readonly auditableId: Scalars['ID'];
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByEmployee?: Maybe<Employee>;
  readonly id: Scalars['ID'];
};

export type AuditTypeInterfaceV2 = {
  readonly actionType: Scalars['String'];
  readonly auditType: Scalars['String'];
  readonly auditableId: Scalars['ID'];
  readonly createdByEmployee?: Maybe<EmployeeV2>;
  readonly id: Scalars['ID'];
  readonly userId?: Maybe<Scalars['ID']>;
};

/** Objects which may be audited */
export type AuditTypeUnion = CarrierAudit | CarrierAuditsExternalStatus | CarrierRepAudit;

/** Objects which may be audited */
export type AuditTypeUnionV2 = CarrierAuditV2 | CarrierAuditsExternalStatusV2 | CarrierRepAuditV2 | CarrierIdentifierAuditV2;

export type AutoAssociationUser = {
  readonly __typename?: 'AutoAssociationUser';
  readonly CreatedByUser?: Maybe<User>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly User?: Maybe<User>;
  readonly userId?: Maybe<Scalars['ID']>;
};

export type AutocompletionRequest = {
  readonly address: Scalars['String'];
  readonly country?: InputMaybe<Scalars['String']>;
};

export type AutoVoucherBatchInput = {
  /** Should adjustments be created? */
  readonly createAdjustments: Scalars['Boolean'];
  /** Process with financials Enabled, Disabled, or Both */
  readonly processFinancials?: InputMaybe<ProcessFinancialsEnum>;
};

/** Available Container */
export type AvailableContainer = {
  readonly __typename?: 'AvailableContainer';
  readonly id: Scalars['ID'];
  readonly carrierId: Scalars['String'];
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly carrierServices?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly reservationNumber?: Maybe<Scalars['String']>;
  readonly reservationType?: Maybe<Scalars['String']>;
  readonly containerType: Scalars['String'];
  readonly containerNumber?: Maybe<Scalars['String']>;
  readonly containerLength: Length;
  readonly containerProgram?: Maybe<Scalars['String']>;
  readonly originCity: Scalars['String'];
  readonly originState: Scalars['String'];
  readonly originRampFacilityId?: Maybe<Scalars['String']>;
  readonly originRampFacilityName?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly destinationRampFacilityId?: Maybe<Scalars['String']>;
  readonly destinationRampFacilityName?: Maybe<Scalars['String']>;
  readonly previousRouteId?: Maybe<Scalars['String']>;
  readonly previousRouteNumber?: Maybe<Scalars['String']>;
  readonly previousRailroadCarrierId?: Maybe<Scalars['String']>;
  readonly previousRailroadCarrierName?: Maybe<Scalars['String']>;
  readonly previousDraymanCarrierId?: Maybe<Scalars['String']>;
  readonly previousDraymanCarrierName?: Maybe<Scalars['String']>;
  readonly dateRange: AvailableDateRange;
  readonly startDay?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly startTimeZone?: Maybe<Scalars['String']>;
  readonly endDay?: Maybe<Scalars['String']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly endTimeZone?: Maybe<Scalars['String']>;
  readonly sourceTypeId: Scalars['String'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdByUserId: Scalars['String'];
  readonly createdByUserName?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly updatedByUserId: Scalars['String'];
  readonly updatedByUserName?: Maybe<Scalars['String']>;
  readonly previousRouteV2?: Maybe<LoadRouteV2>;
};

export type AvailableContainerConnection = {
  readonly __typename?: 'AvailableContainerConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<AvailableContainerEdge>;
};

export type AvailableContainerEdge = {
  readonly __typename?: 'AvailableContainerEdge';
  readonly node: AvailableContainer;
  /** Used in `before` and `after` args */
  readonly cursor: Scalars['String'];
};

export type AvailableContainerFilter = {
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly sortDirection?: InputMaybe<Scalars['String']>;
  readonly containerId?: InputMaybe<Scalars['String']>;
  readonly containerIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly carrierServices?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly containerNumber?: InputMaybe<Scalars['String']>;
  readonly reservationNumber?: InputMaybe<Scalars['String']>;
  readonly containerProgram?: InputMaybe<Scalars['String']>;
  readonly originRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRampFacilityName?: InputMaybe<Scalars['String']>;
  readonly destinationRampFacilityName?: InputMaybe<Scalars['String']>;
  readonly originCity?: InputMaybe<Scalars['String']>;
  readonly originStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationCity?: InputMaybe<Scalars['String']>;
  readonly destinationStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly previousRailroadCarrierName?: InputMaybe<Scalars['String']>;
  readonly previousDraymanCarrierName?: InputMaybe<Scalars['String']>;
  readonly previousRouteNumber?: InputMaybe<Scalars['String']>;
  readonly startDay?: InputMaybe<Scalars['String']>;
  readonly endDay?: InputMaybe<Scalars['String']>;
  readonly containerPrograms?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly sourceTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly containerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly reservationTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly enteredByRepEmployeeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainCarrierRepEmployeeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly previousDraymanCarrierIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly previousRailroadCarrierIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly utcInclusiveDateRange?: InputMaybe<DateRangeInput>;
  readonly originRadius?: InputMaybe<WithinRadiusInput>;
  readonly originRampFacilityRadius?: InputMaybe<WithinRadiusInput>;
  readonly destinationRampFacilityRadius?: InputMaybe<WithinRadiusInput>;
  readonly includeNullDestination?: InputMaybe<Scalars['Boolean']>;
  readonly createdByUserName?: InputMaybe<Scalars['String']>;
};

export type AvailableDateRange = {
  readonly __typename?: 'AvailableDateRange';
  readonly gte: Scalars['Timestamp'];
  readonly lte: Scalars['Timestamp'];
};

/** Available GateReservation */
export type AvailableGateReservation = {
  readonly __typename?: 'AvailableGateReservation';
  readonly id: Scalars['ID'];
  readonly carrierId: Scalars['String'];
  readonly carrierName: Scalars['String'];
  readonly carrierServices?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly containerType: Scalars['String'];
  readonly containerLength?: Maybe<Length>;
  readonly containerProgram?: Maybe<Scalars['String']>;
  readonly reservationNumber: Scalars['String'];
  readonly originRampFacilityId: Scalars['String'];
  readonly originRampFacilityName?: Maybe<Scalars['String']>;
  readonly originCity: Scalars['String'];
  readonly originState: Scalars['String'];
  readonly destinationRampFacilityId?: Maybe<Scalars['String']>;
  readonly destinationRampFacilityName?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly dateRange: AvailableDateRange;
  readonly startDay?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly startTimeZone?: Maybe<Scalars['String']>;
  readonly endDay?: Maybe<Scalars['String']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly endTimeZone?: Maybe<Scalars['String']>;
  readonly sourceTypeId?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly createdByUserId: Scalars['String'];
  readonly createdByUserName: Scalars['String'];
  readonly updatedByUserId: Scalars['String'];
  readonly updatedByUserName: Scalars['String'];
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
};

export type AvailableGateReservationConnection = {
  readonly __typename?: 'AvailableGateReservationConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<AvailableGateReservationEdge>;
};

export type AvailableGateReservationEdge = {
  readonly __typename?: 'AvailableGateReservationEdge';
  readonly node: AvailableGateReservation;
  /** Used in `before` and `after` args */
  readonly cursor: Scalars['String'];
};

export type AvailableGateReservationFilter = {
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly sortDirection?: InputMaybe<Scalars['String']>;
  readonly gateReservationIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly utcInclusiveDateRange?: InputMaybe<DateRangeInput>;
  readonly startDay?: InputMaybe<Scalars['String']>;
  readonly endDay?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly carrierServices?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly reservationNumber?: InputMaybe<Scalars['String']>;
  readonly containerProgram?: InputMaybe<Scalars['String']>;
  readonly containerPrograms?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly sourceTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly containerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originCity?: InputMaybe<Scalars['String']>;
  readonly originStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly enteredByRepEmployeeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainCarrierRepEmployeeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRampFacilityRadius?: InputMaybe<WithinRadiusInput>;
  readonly destinationRampFacilityRadius?: InputMaybe<WithinRadiusInput>;
  readonly includeNullDestination?: InputMaybe<Scalars['Boolean']>;
  readonly destinationCity?: InputMaybe<Scalars['String']>;
  readonly destinationStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly createdByUserName?: InputMaybe<Scalars['String']>;
};

/** Available Route */
export type AvailableRoute = {
  readonly __typename?: 'AvailableRoute';
  readonly id: Scalars['ID'];
  readonly routeTypeId?: Maybe<Scalars['String']>;
  readonly routeNumber: Scalars['String'];
  readonly type: RouteType;
  readonly loadId: Scalars['String'];
  readonly loadNumber: Scalars['String'];
  readonly capacityNeed?: Maybe<ReadonlyArray<CapacityNeed>>;
  readonly customerName: Scalars['String'];
  readonly customerId?: Maybe<Scalars['String']>;
  readonly customerGroups?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly customer?: Maybe<Customer>;
  readonly customerRepName?: Maybe<Scalars['String']>;
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly commodities: Scalars['String'];
  readonly pickup: RouteStopInfo;
  readonly delivery: RouteStopInfo;
  readonly routeLength?: Maybe<Length>;
  readonly trailerSize?: Maybe<Dimensions>;
  readonly requiredTruckTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly status: Scalars['String'];
  readonly stopCount?: Maybe<Scalars['Int']>;
  readonly expectedMaxWeight?: Maybe<Mass>;
  readonly commodityMinTemperature?: Maybe<TemperatureV2>;
  readonly outboundRegionUrn?: Maybe<Scalars['String']>;
  readonly inboundRegionUrn?: Maybe<Scalars['String']>;
  readonly createdAtDate?: Maybe<Scalars['DateTime']>;
  readonly updatedAtDate?: Maybe<Scalars['DateTime']>;
  readonly segmentCode?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly requirements?: Maybe<ReadonlyArray<OrderRequirementInfo>>;
  readonly hotRoute?: Maybe<Scalars['Boolean']>;
  readonly team: Scalars['Boolean'];
  readonly hazmat: Scalars['Boolean'];
  readonly loadRouteSequence?: Maybe<Fraction>;
  readonly externalSourceUrl?: Maybe<Scalars['String']>;
  readonly externalRouteMaxCost?: Maybe<Currency>;
  readonly allStopsHaveRequestedTime?: Maybe<Scalars['Boolean']>;
  readonly loadMoneySummary?: Maybe<LoadMoneySummary>;
  readonly loadRouteV2?: Maybe<LoadRouteV2>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly maxCost?: Maybe<RouteMaxCostType>;
  readonly lock?: Maybe<Lock>;
  /** @deprecated Use `externalRouteMaxCost` */
  readonly maxCostValue?: Maybe<Scalars['Float']>;
  /** @deprecated Use Federation for notes */
  readonly notes?: Maybe<Scalars['String']>;
  /** @deprecated Only used for External/Revenova loads, use maxCost instead */
  readonly maxPayCurrency?: Maybe<Currency>;
  /** @deprecated Use Federation for cargo information */
  readonly cargoValueCurrency?: Maybe<Currency>;
  /** @deprecated Use Federation for cargo information */
  readonly cargoWeight?: Maybe<Mass>;
  /** @deprecated Type RouteOffer is deprecated */
  readonly offers?: Maybe<ReadonlyArray<RouteOffer>>;
  /** @deprecated Use `loadRouteV2` */
  readonly loadRoute?: Maybe<LoadRoute>;
  readonly distanceToNextRoute?: Maybe<Scalars['Int']>;
  readonly postings?: Maybe<ReadonlyArray<Maybe<Posting>>>;
};

export type AvailableRouteConnection = {
  readonly __typename?: 'AvailableRouteConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<AvailableRouteEdge>;
};

export type AvailableRouteCustomerInput = {
  readonly customerName: Scalars['String'];
  readonly customerId: Scalars['String'];
  readonly customerEmployeeGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type AvailableRouteCustomerRepInput = {
  readonly main: Scalars['Boolean'];
  readonly typeId: Scalars['String'];
  readonly typeName: Scalars['String'];
  readonly employeeName: Scalars['String'];
  readonly employeeId: Scalars['String'];
};

export type AvailableRouteEdge = {
  readonly __typename?: 'AvailableRouteEdge';
  readonly node: AvailableRoute;
  /** Used in `before` and `after` args */
  readonly cursor: Scalars['String'];
};

export type AvailableRouteLengthInput = {
  readonly unit: UnitOfLengthEnum;
  readonly value: Scalars['Float'];
};

export type AvailableRoutesFilter = {
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly sortDirection?: InputMaybe<Scalars['String']>;
  readonly routeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadNumber?: InputMaybe<Scalars['String']>;
  readonly routeNumber?: InputMaybe<Scalars['String']>;
  readonly capacityNeed?: InputMaybe<ReadonlyArray<CapacityNeed>>;
  readonly customer?: InputMaybe<Scalars['String']>;
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerEmployeeGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerRep?: InputMaybe<Scalars['String']>;
  readonly customerRepIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly utcPickupStartDateTime?: InputMaybe<Scalars['Timestamp']>;
  readonly utcPickupEndDateTime?: InputMaybe<Scalars['Timestamp']>;
  readonly pickupCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly pickupStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly pickupState?: InputMaybe<Scalars['String']>;
  readonly pickupCity?: InputMaybe<Scalars['String']>;
  readonly inboundRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly inboundSubRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly pickupFreightAssignmentSuperRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly pickupFreightAssignmentRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly pickupFreightAssignmentSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundSubRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly deliveryFreightAssignmentSuperRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly deliveryFreightAssignmentRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly deliveryFreightAssignmentSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly utcDeliveryStartDateTime?: InputMaybe<Scalars['Timestamp']>;
  readonly utcDeliveryEndDateTime?: InputMaybe<Scalars['Timestamp']>;
  readonly deliveryCity?: InputMaybe<Scalars['String']>;
  readonly deliveryState?: InputMaybe<Scalars['String']>;
  readonly deliveryStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly deliveryCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly withinRadius?: InputMaybe<UnitOfLengthInput>;
  readonly radiusGeoPoint?: InputMaybe<GeoPointInput>;
  readonly deliveryRadius?: InputMaybe<UnitOfLengthInput>;
  readonly deliveryRadiusGeoPoint?: InputMaybe<GeoPointInput>;
  readonly requiredTruckTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trailerLength?: InputMaybe<AvailableRouteLengthInput>;
  readonly timeZone?: InputMaybe<Scalars['String']>;
  readonly stopCount?: InputMaybe<Scalars['Int']>;
  readonly routeActivationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly utcInclusivePickupDateRange?: InputMaybe<DateRangeInput>;
  readonly utcInclusiveDeliveryDateRange?: InputMaybe<DateRangeInput>;
  readonly firstStopRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly lastStopRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly firstStopLiveType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly lastStopLiveType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  readonly requirements?: InputMaybe<Scalars['String']>;
  readonly hotRoute?: InputMaybe<Scalars['Boolean']>;
  readonly routeType?: InputMaybe<RouteType>;
  readonly commodityMinTemperature?: InputMaybe<TemperatureV2Input>;
  readonly divisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly intrastateAdminAreas?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly division?: InputMaybe<Scalars['String']>;
};

export type AvailableRouteStopInput = {
  readonly id: Scalars['String'];
  readonly loadStopType?: InputMaybe<Scalars['String']>;
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  readonly timeZone?: InputMaybe<Scalars['String']>;
  readonly regionUrn?: InputMaybe<Scalars['String']>;
  readonly subRegionUrn?: InputMaybe<Scalars['String']>;
  readonly freightAssignmentSuperRegion?: InputMaybe<Scalars['String']>;
  readonly freightAssignmentRegion?: InputMaybe<Scalars['String']>;
  readonly freightAssignmentSubRegion?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  /** Two character country code. (e.g. US, MX, CA) */
  readonly country?: InputMaybe<Scalars['String']>;
  readonly scheduleType?: InputMaybe<Scalars['String']>;
  readonly facilityId?: InputMaybe<Scalars['String']>;
  readonly facilityName?: InputMaybe<Scalars['String']>;
  readonly appointmentStatus?: InputMaybe<Scalars['String']>;
  readonly geoPoint: GeoPointInput;
  readonly totalCommodityWeight?: InputMaybe<UnitOfMassInput>;
};

/** Available Truck */
export type AvailableTruck = {
  readonly __typename?: 'AvailableTruck';
  readonly truckPostingId: Scalars['ID'];
  readonly carrierId: Scalars['String'];
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly originCountry?: Maybe<Scalars['String']>;
  readonly originGeoPoint?: Maybe<Point>;
  readonly readyByDay?: Maybe<Scalars['String']>;
  readonly readyByDate: Scalars['DateTime'];
  readonly readyByTimeZone?: Maybe<Scalars['String']>;
  readonly equipmentType?: Maybe<Scalars['String']>;
  readonly trailerLength?: Maybe<Length>;
  readonly ddh?: Maybe<Length>;
  readonly odh?: Maybe<Length>;
  readonly destinationStates?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationAdminAreas?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationCountries?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRegions?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly destinationGeoPoint?: Maybe<Point>;
  readonly loadByDay?: Maybe<Scalars['String']>;
  readonly loadByDate?: Maybe<Scalars['DateTime']>;
  readonly loadByTimeZone?: Maybe<Scalars['String']>;
  readonly finalByDay?: Maybe<Scalars['String']>;
  readonly finalByDate?: Maybe<Scalars['DateTime']>;
  readonly finalByTimeZone?: Maybe<Scalars['String']>;
  readonly previousRouteNumber?: Maybe<Scalars['String']>;
  readonly previousRouteCode?: Maybe<Scalars['String']>;
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly ownerId?: Maybe<Scalars['String']>;
  readonly ownerName?: Maybe<Scalars['String']>;
  readonly carrierStatus?: Maybe<Scalars['String']>;
  readonly carrierServices?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly sourceType?: Maybe<Scalars['String']>;
  readonly isHazmat?: Maybe<Scalars['Boolean']>;
  readonly isTeam?: Maybe<Scalars['Boolean']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly mainCarrierRepName?: Maybe<Scalars['String']>;
  readonly mainCarrierRepUserId?: Maybe<Scalars['String']>;
  readonly enteredByUserName?: Maybe<Scalars['String']>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdByEmployeeId?: Maybe<Scalars['String']>;
  readonly updatedByEmployeeId?: Maybe<Scalars['String']>;
  readonly createdAtDate?: Maybe<Scalars['DateTime']>;
  readonly updatedAtDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `mainCarrierRepName` and `mainCarrierRepUserId` */
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly owner?: Maybe<Carrier>;
  readonly ownerV2?: Maybe<CarrierV2>;
  readonly createdByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUser?: Maybe<User>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly mainCarrierRepUser?: Maybe<User>;
  readonly mainCarrierRepUserV2?: Maybe<UserV2>;
  readonly previousRouteV2?: Maybe<LoadRouteV2>;
  readonly actualODH?: Maybe<Length>;
  readonly actualDDH?: Maybe<Length>;
  /** @deprecated Use `previousRouteV2` */
  readonly previousRoute?: Maybe<LoadRoute>;
};


/** Available Truck */
export type AvailableTruckActualOdhArgs = {
  fromPoint?: InputMaybe<GeoPointInput>;
};


/** Available Truck */
export type AvailableTruckActualDdhArgs = {
  toPoint?: InputMaybe<GeoPointInput>;
};

export type AvailableTruckConnection = {
  readonly __typename?: 'AvailableTruckConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<AvailableTruckEdge>;
};

export type AvailableTruckEdge = {
  readonly __typename?: 'AvailableTruckEdge';
  readonly node: AvailableTruck;
  /** Used in `before` and `after` args */
  readonly cursor: Scalars['String'];
};

export type AvailableTruckSelector = {
  readonly carrierId: Scalars['String'];
};

export type AvailableTrucksFilter = {
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly sortDirection?: InputMaybe<Scalars['String']>;
  readonly truckPostingIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly truckLifecycleStatus?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly carrierIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierName?: InputMaybe<Scalars['String']>;
  readonly originCity?: InputMaybe<Scalars['String']>;
  readonly originState?: InputMaybe<Scalars['String']>;
  readonly originCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly readyByDay?: InputMaybe<Scalars['String']>;
  readonly readyByDate?: InputMaybe<Scalars['Timestamp']>;
  readonly readyByDateFilterBehavior?: InputMaybe<DateBoundaryBehavior>;
  readonly equipmentType?: InputMaybe<Scalars['String']>;
  readonly equipmentTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trailerLength?: InputMaybe<UnitOfLengthInput>;
  readonly ddh?: InputMaybe<UnitOfLengthInput>;
  readonly odh?: InputMaybe<UnitOfLengthInput>;
  readonly destinationCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationAdminAreas?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationCity?: InputMaybe<Scalars['String']>;
  readonly destinationState?: InputMaybe<Scalars['String']>;
  readonly originRadius?: InputMaybe<WithinRadiusInput>;
  readonly destinationRadius?: InputMaybe<WithinRadiusInput>;
  readonly loadByDay?: InputMaybe<Scalars['String']>;
  readonly loadByDate?: InputMaybe<Scalars['DateTime']>;
  readonly loadByDateFilterBehavior?: InputMaybe<DateBoundaryBehavior>;
  readonly finalByDay?: InputMaybe<Scalars['String']>;
  readonly finalByDate?: InputMaybe<Scalars['DateTime']>;
  readonly finalByDateFilterBehavior?: InputMaybe<DateBoundaryBehavior>;
  readonly previousRouteNumber?: InputMaybe<Scalars['String']>;
  readonly previousRouteCode?: InputMaybe<Scalars['String']>;
  readonly enteredByRepEmployeeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainCarrierRepEmployeeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly truckPickupDateRange?: InputMaybe<DateRangeInput>;
  /** Results have readyBy-loadBy date range that INTERSECTS the input */
  readonly utcInclusiveDateRange?: InputMaybe<DateRangeInput>;
  readonly originStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly ownerId?: InputMaybe<Scalars['String']>;
  readonly ownerIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly ownerName?: InputMaybe<Scalars['String']>;
  readonly carrierStatuses?: InputMaybe<Scalars['String']>;
  readonly carrierStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly carrierServices?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly sourceTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly includeNullDestination?: InputMaybe<Scalars['Boolean']>;
  readonly sourceType?: InputMaybe<Scalars['String']>;
  readonly truckDestinationAdminAreas?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly withinRadius?: InputMaybe<UnitOfLengthInput>;
  readonly radiusGeoPoint?: InputMaybe<GeoPointInput>;
  readonly mainCarrierRep?: InputMaybe<Scalars['String']>;
  readonly mainCarrierReps?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly mainCarrierRepName?: InputMaybe<Scalars['String']>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly employeeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly enteredByUserName?: InputMaybe<Scalars['String']>;
};

export type AwardQuotesInput = {
  readonly bidId: Scalars['ID'];
};

export type AwardQuotesPayload = {
  readonly __typename?: 'AwardQuotesPayload';
  readonly lanes?: Maybe<ReadonlyArray<Maybe<BidLaneInput>>>;
  readonly rateQuotes?: Maybe<ReadonlyArray<Maybe<BidRateDetailAddInput>>>;
};

export enum AzureLoadType {
  /** Explosives */
  UsHazmatClass1 = 'USHazmatClass1',
  /** Compressed gas */
  UsHazmatClass2 = 'USHazmatClass2',
  /** Flammable liquids */
  UsHazmatClass3 = 'USHazmatClass3',
  /** Flammable solids */
  UsHazmatClass4 = 'USHazmatClass4',
  /** Oxidizers */
  UsHazmatClass5 = 'USHazmatClass5',
  /** Poisons */
  UsHazmatClass6 = 'USHazmatClass6',
  /** Radioactive */
  UsHazmatClass7 = 'USHazmatClass7',
  /** Corrosives */
  UsHazmatClass8 = 'USHazmatClass8',
  /** Miscellaneous */
  UsHazmatClass9 = 'USHazmatClass9',
  /** Explosive (outside US) */
  OtherHazmatExplosive = 'otherHazmatExplosive',
  /** Miscellaneous (outside US) */
  OtherHazmatGeneral = 'otherHazmatGeneral',
  /** Harmful to water (outside US) */
  OtherHazmatHarmfulToWater = 'otherHazmatHarmfulToWater'
}

/** Custom options to use when calculating mileage via Azure, see: https://docs.microsoft.com/en-us/rest/api/maps/route/post-route-matrix */
export type AzureOptions = {
  readonly __typename?: 'AzureOptions';
  readonly AvoidTollRoads?: Maybe<Scalars['Boolean']>;
  readonly AvoidMotorways?: Maybe<Scalars['Boolean']>;
  readonly AvoidFerries?: Maybe<Scalars['Boolean']>;
  readonly AvoidUnpavedRoads?: Maybe<Scalars['Boolean']>;
  readonly AvoidBorderCrossings?: Maybe<Scalars['Boolean']>;
  /** See Azure Docs: https://docs.microsoft.com/en-us/rest/api/maps/route/post-route-matrix-sync#routetype */
  readonly RouteType?: Maybe<AzureRouteType>;
  readonly VehicleAxleWeight?: Maybe<Scalars['Int']>;
  readonly VehicleHeight?: Maybe<Scalars['Float']>;
  readonly VehicleLength?: Maybe<Scalars['Float']>;
  /** Hazardous Material designations. See Azure Docs: https://docs.microsoft.com/en-us/rest/api/maps/route/post-route-matrix-sync#vehicleloadtype */
  readonly VehicleLoadType?: Maybe<ReadonlyArray<Maybe<AzureLoadType>>>;
  readonly VehicleMaxSpeed?: Maybe<Scalars['Int']>;
  readonly VehicleWeight?: Maybe<Scalars['Int']>;
  readonly VehicleWidth?: Maybe<Scalars['Int']>;
};

export type AzureOptionsInput = {
  readonly AvoidTollRoads?: InputMaybe<Scalars['Boolean']>;
  readonly AvoidMotorways?: InputMaybe<Scalars['Boolean']>;
  readonly AvoidFerries?: InputMaybe<Scalars['Boolean']>;
  readonly AvoidUnpavedRoads?: InputMaybe<Scalars['Boolean']>;
  readonly AvoidBorderCrossings?: InputMaybe<Scalars['Boolean']>;
  /** See Azure Docs: https://docs.microsoft.com/en-us/rest/api/maps/route/post-route-matrix-sync#routetype */
  readonly RouteType?: InputMaybe<AzureRouteType>;
  readonly VehicleAxleWeight?: InputMaybe<Scalars['Int']>;
  readonly VehicleHeight?: InputMaybe<Scalars['Float']>;
  readonly VehicleLength?: InputMaybe<Scalars['Float']>;
  /** Hazardous Material designations. See Azure Docs: https://docs.microsoft.com/en-us/rest/api/maps/route/post-route-matrix-sync#vehicleloadtype */
  readonly VehicleLoadType?: InputMaybe<ReadonlyArray<InputMaybe<AzureLoadType>>>;
  readonly VehicleMaxSpeed?: InputMaybe<Scalars['Int']>;
  readonly VehicleWeight?: InputMaybe<Scalars['Int']>;
  readonly VehicleWidth?: InputMaybe<Scalars['Int']>;
};

export enum AzureRouteType {
  /** A route balanced by economy and speed. */
  Economical = 'Economical',
  /** The fastest route. */
  Fastest = 'Fastest',
  /** The shortest route by distance. */
  Shortest = 'Shortest'
}

export type BaseSchedulesInput = {
  readonly serviceProfileId?: InputMaybe<Scalars['Float']>;
  readonly serviceId?: InputMaybe<Scalars['String']>;
  readonly gcMonTime: Scalars['DateTime'];
  readonly gcTuesTime: Scalars['DateTime'];
  readonly gcWedTime: Scalars['DateTime'];
  readonly gcThursTime: Scalars['DateTime'];
  readonly gcFriTime: Scalars['DateTime'];
  readonly gcSatTime: Scalars['DateTime'];
  readonly gcSunTime: Scalars['DateTime'];
  readonly eaDayOfMon: Scalars['String'];
  readonly eaMonTime: Scalars['DateTime'];
  readonly monIsClosed?: InputMaybe<Scalars['Boolean']>;
  readonly eaDayOfTues: Scalars['String'];
  readonly eaTuesTime: Scalars['DateTime'];
  readonly tuesIsClosed?: InputMaybe<Scalars['Boolean']>;
  readonly eaDayOfWed: Scalars['String'];
  readonly eaWedTime: Scalars['DateTime'];
  readonly wedIsClosed?: InputMaybe<Scalars['Boolean']>;
  readonly eaDayOfThurs: Scalars['String'];
  readonly eaThursTime: Scalars['DateTime'];
  readonly thursIsClosed?: InputMaybe<Scalars['Boolean']>;
  readonly eaDayOfFri: Scalars['String'];
  readonly eaFriTime: Scalars['DateTime'];
  readonly friIsClosed?: InputMaybe<Scalars['Boolean']>;
  readonly eaDayOfSat: Scalars['String'];
  readonly eaSatTime: Scalars['DateTime'];
  readonly satIsClosed?: InputMaybe<Scalars['Boolean']>;
  readonly eaDayOfSun: Scalars['String'];
  readonly eaSunTime: Scalars['DateTime'];
  readonly sunIsClosed?: InputMaybe<Scalars['Boolean']>;
};

export type BatchCoordinateInput = {
  readonly lat: Scalars['Float'];
  readonly lon: Scalars['Float'];
  readonly requestId: Scalars['ID'];
};

export type BatchFilter = {
  readonly status: StatusEnum;
};

export type BatchGeocodeError = {
  readonly __typename?: 'BatchGeocodeError';
  readonly code?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
};

export type BatchGeocodeErrorResponse = {
  readonly __typename?: 'BatchGeocodeErrorResponse';
  readonly error?: Maybe<BatchGeocodeError>;
  readonly request?: Maybe<BatchGeocodeRequest>;
};

export type BatchGeocodeRequest = {
  readonly __typename?: 'BatchGeocodeRequest';
  readonly addressLine1?: Maybe<Scalars['String']>;
  readonly locality?: Maybe<Scalars['String']>;
  readonly administrativeArea?: Maybe<Scalars['String']>;
  readonly postalCode?: Maybe<Scalars['String']>;
  readonly countryCode: Scalars['String'];
  readonly requestId: Scalars['ID'];
};

export type BatchGeocodeResponse = {
  readonly __typename?: 'BatchGeocodeResponse';
  readonly successfulItems?: Maybe<ReadonlyArray<Maybe<GeocodeResponse>>>;
  readonly unsuccessfulItems?: Maybe<ReadonlyArray<Maybe<BatchGeocodeErrorResponse>>>;
};

export type BatchParametersInput = {
  readonly batchId: Scalars['ID'];
};

export type Bid = {
  readonly __typename?: 'Bid';
  readonly bidId?: Maybe<Scalars['ID']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly externalBidId?: Maybe<Scalars['String']>;
  readonly bidUId?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly owner?: Maybe<Scalars['ID']>;
  readonly ownerDetails?: Maybe<OwnerDetails>;
  readonly bidStartDate?: Maybe<Scalars['DateTime']>;
  readonly bidEndDate?: Maybe<Scalars['DateTime']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly modeId?: Maybe<Scalars['String']>;
  readonly sizeId?: Maybe<Scalars['String']>;
  readonly bidStatusId?: Maybe<Scalars['ID']>;
  readonly bidStatus?: Maybe<BidStatus>;
  readonly distanceId?: Maybe<Scalars['String']>;
  readonly bidCalculated?: Maybe<BidCalculated>;
};

export type BidCalculated = {
  readonly __typename?: 'BidCalculated';
  readonly bidCalculatedId?: Maybe<Scalars['ID']>;
  readonly totalLanes?: Maybe<Scalars['Int']>;
  readonly lanesPriced?: Maybe<Scalars['Int']>;
  readonly percentCompleted?: Maybe<Scalars['Float']>;
  readonly totalProjectedVolume?: Maybe<Scalars['Float']>;
  readonly totalProjectedRevenue?: Maybe<Scalars['Float']>;
};

export type BidConnection = {
  readonly __typename?: 'BidConnection';
  readonly edges: ReadonlyArray<Maybe<BidEdge>>;
  readonly totalCount: Scalars['Int'];
  readonly pageInfo: BidPageInfo;
};

export type BidEdge = {
  readonly __typename?: 'BidEdge';
  readonly cursor: Scalars['String'];
  readonly node: Bid;
};

export type BidInput = {
  readonly bidId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly bidStatusId?: InputMaybe<Scalars['ID']>;
  readonly externalBidId?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly modeId: Scalars['String'];
  readonly sizeId: Scalars['String'];
  readonly distanceId: Scalars['String'];
  readonly distanceType?: InputMaybe<Scalars['String']>;
  readonly owner: Scalars['ID'];
  readonly bidStartDate: Scalars['String'];
  readonly bidEndDate: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type BidInputFilter = {
  readonly bidId?: InputMaybe<Scalars['ID']>;
  readonly externalBidId?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly owner?: InputMaybe<Scalars['ID']>;
  readonly modeId?: InputMaybe<Scalars['String']>;
  readonly sizeId?: InputMaybe<Scalars['String']>;
  readonly distanceId?: InputMaybe<Scalars['String']>;
  readonly bidStartDate?: InputMaybe<Scalars['String']>;
  readonly bidEndDate?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type BidLane = {
  readonly __typename?: 'BidLane';
  readonly bidLaneId?: Maybe<Scalars['ID']>;
  readonly externalBidLaneId?: Maybe<Scalars['String']>;
  readonly bid?: Maybe<Bid>;
  readonly equipmentId?: Maybe<Scalars['String']>;
  readonly priority?: Maybe<Scalars['Boolean']>;
  readonly minLength?: Maybe<Scalars['Float']>;
  readonly minWidth?: Maybe<Scalars['Float']>;
  readonly minHeight?: Maybe<Scalars['Float']>;
  readonly temperatureRequirement?: Maybe<Scalars['Float']>;
  readonly temperatureUnit?: Maybe<Scalars['String']>;
  readonly projectedVolume?: Maybe<Scalars['Float']>;
  readonly customerMiles?: Maybe<Scalars['Float']>;
  readonly isCurrentLane?: Maybe<Scalars['Boolean']>;
  readonly isAwarded?: Maybe<Scalars['Boolean']>;
  readonly assignedPricingRegion?: Maybe<Scalars['String']>;
  readonly assignedUser?: Maybe<Scalars['ID']>;
  readonly incumbent?: Maybe<Scalars['Boolean']>;
  readonly bidLaneStops?: Maybe<ReadonlyArray<Maybe<BidLaneStop>>>;
};

export type BidLaneConnection = {
  readonly __typename?: 'BidLaneConnection';
  readonly edges: ReadonlyArray<Maybe<BidLaneEdge>>;
  readonly totalCount: Scalars['Int'];
  readonly pageInfo: BidPageInfo;
};

export type BidLaneEdge = {
  readonly __typename?: 'BidLaneEdge';
  readonly cursor: Scalars['String'];
  readonly node: BidLaneRound;
};

export type BidLaneInput = {
  readonly __typename?: 'BidLaneInput';
  readonly businessUnit?: Maybe<Scalars['String']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly equipment?: Maybe<Scalars['ID']>;
  readonly laneStops?: Maybe<ReadonlyArray<Maybe<LaneStops>>>;
  readonly loadSize?: Maybe<Scalars['String']>;
  readonly minHeight?: Maybe<MinHeight>;
  readonly minLength?: Maybe<MinLength>;
  readonly minWidth?: Maybe<MinWidth>;
  readonly project?: Maybe<Scalars['String']>;
  readonly service?: Maybe<Scalars['String']>;
  readonly transportMode?: Maybe<Scalars['String']>;
};

export type BidLaneRound = {
  readonly __typename?: 'BidLaneRound';
  readonly bidLaneRoundStatus?: Maybe<BidStatus>;
  readonly bidLane?: Maybe<BidLane>;
};

export type BidLanesInputFilter = {
  readonly bidId?: InputMaybe<Scalars['ID']>;
  readonly roundId?: InputMaybe<Scalars['ID']>;
  readonly externalBidLaneId?: InputMaybe<Scalars['String']>;
  readonly laneStatus?: InputMaybe<Scalars['ID']>;
  readonly activeRound?: InputMaybe<Scalars['Int']>;
  readonly activeQuote?: InputMaybe<Scalars['String']>;
  readonly originType?: InputMaybe<Scalars['String']>;
  readonly originFacility?: InputMaybe<Scalars['String']>;
  readonly originCity?: InputMaybe<Scalars['String']>;
  readonly originState?: InputMaybe<Scalars['String']>;
  readonly originPostal?: InputMaybe<Scalars['String']>;
  readonly originCountry?: InputMaybe<Scalars['String']>;
  readonly destType?: InputMaybe<Scalars['String']>;
  readonly destFacility?: InputMaybe<Scalars['String']>;
  readonly destCity?: InputMaybe<Scalars['String']>;
  readonly destState?: InputMaybe<Scalars['String']>;
  readonly destPostal?: InputMaybe<Scalars['String']>;
  readonly destCountry?: InputMaybe<Scalars['String']>;
  readonly equip?: InputMaybe<Scalars['ID']>;
  readonly length?: InputMaybe<Scalars['Float']>;
  readonly temp?: InputMaybe<Scalars['Float']>;
  readonly distance?: InputMaybe<Scalars['Int']>;
  readonly estimateVolume?: InputMaybe<Scalars['Int']>;
  readonly incumbent?: InputMaybe<Scalars['Boolean']>;
  readonly awarded?: InputMaybe<Scalars['Boolean']>;
  readonly assignedRegion?: InputMaybe<Scalars['String']>;
  readonly assignedUser?: InputMaybe<Scalars['ID']>;
};

export type BidLaneStop = {
  readonly __typename?: 'BidLaneStop';
  readonly geography?: Maybe<Scalars['String']>;
  readonly facilityCode?: Maybe<Scalars['String']>;
  readonly postalCode?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly stateCode?: Maybe<Scalars['String']>;
  readonly geographyTypeId?: Maybe<Scalars['String']>;
};

export type BidPageInfo = {
  readonly __typename?: 'BidPageInfo';
  readonly startCursor?: Maybe<Scalars['String']>;
  readonly endCursor?: Maybe<Scalars['String']>;
  readonly hasNextPage: Scalars['Boolean'];
  readonly hasPreviousPage: Scalars['Boolean'];
};

export type BidPayload = {
  readonly __typename?: 'BidPayload';
  readonly bid?: Maybe<Bid>;
  readonly error?: Maybe<MasterBidError>;
};

export type BidQuote = {
  readonly __typename?: 'BidQuote';
  readonly bidQuoteId?: Maybe<Scalars['ID']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly commitmentValue?: Maybe<Scalars['Int']>;
  readonly divisionId?: Maybe<Scalars['String']>;
  readonly businessUnitId?: Maybe<Scalars['String']>;
  readonly scacId?: Maybe<Scalars['String']>;
  readonly modeId?: Maybe<Scalars['String']>;
  readonly priceId?: Maybe<Scalars['String']>;
  readonly stopEventId?: Maybe<Scalars['String']>;
  readonly commitmentId?: Maybe<Scalars['String']>;
  readonly commitmentIntervalId?: Maybe<Scalars['String']>;
  readonly bidQuoteDetails?: Maybe<ReadonlyArray<Maybe<BidQuoteDetailsPayload>>>;
};

export type BidQuoteDetails = {
  readonly ratePerUnit?: InputMaybe<Scalars['Float']>;
  readonly units?: InputMaybe<Scalars['Int']>;
  readonly chargeTypeId?: InputMaybe<Scalars['String']>;
  readonly currencyId?: InputMaybe<Scalars['String']>;
};

export type BidQuoteDetailsPayload = {
  readonly __typename?: 'BidQuoteDetailsPayload';
  readonly ratePerUnit?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Int']>;
  readonly chargeTypeId?: Maybe<Scalars['String']>;
  readonly currencyId?: Maybe<Scalars['String']>;
};

export type BidQuoteInput = {
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly commitmentValue?: InputMaybe<Scalars['Int']>;
  readonly divisionId?: InputMaybe<Scalars['String']>;
  readonly businessUnitId?: InputMaybe<Scalars['String']>;
  readonly externalBidLaneId?: InputMaybe<Scalars['String']>;
  readonly scacId?: InputMaybe<Scalars['String']>;
  readonly modeId?: InputMaybe<Scalars['String']>;
  readonly stopEventId?: InputMaybe<Scalars['String']>;
  readonly commitmentId?: InputMaybe<Scalars['String']>;
  readonly commitmentIntervalId?: InputMaybe<Scalars['String']>;
  readonly priceId?: InputMaybe<Scalars['String']>;
  readonly requirementId?: InputMaybe<Scalars['String']>;
  readonly bidQuoteDetails?: InputMaybe<ReadonlyArray<InputMaybe<BidQuoteDetails>>>;
  readonly capacityConstraints?: InputMaybe<CapacityConstraintsInput>;
};

export type BidQuotePayload = {
  readonly __typename?: 'BidQuotePayload';
  readonly bidQuoteWithCapacity?: Maybe<ReadonlyArray<Maybe<BidQuoteWithCapacity>>>;
  readonly error?: Maybe<MasterBidError>;
};

export type BidQuoteWithCapacity = {
  readonly __typename?: 'BidQuoteWithCapacity';
  readonly bidQuote?: Maybe<BidQuote>;
  readonly capacityConstraints?: Maybe<CapacityConstraints>;
};

export type BidRateDetailAddInput = {
  readonly __typename?: 'BidRateDetailAddInput';
  readonly billToCodeId?: Maybe<Scalars['ID']>;
  readonly chargeTypeId?: Maybe<Scalars['ID']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly enteredRatePer?: Maybe<Scalars['Float']>;
  readonly exchangeRate?: Maybe<Scalars['Float']>;
  readonly exchangeRateDate?: Maybe<Scalars['DateTime']>;
  readonly jurisdictionTaxesId?: Maybe<Scalars['ID']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly orderId?: Maybe<Scalars['ID']>;
  readonly ratePer?: Maybe<Scalars['Float']>;
  readonly stopId?: Maybe<Scalars['ID']>;
  readonly unitsDecimal?: Maybe<Scalars['Float']>;
};

export type BidRoundPayload = {
  readonly __typename?: 'BidRoundPayload';
  readonly rounds: ReadonlyArray<Maybe<Round>>;
};

export type BidStatus = {
  readonly __typename?: 'BidStatus';
  readonly name?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['ID']>;
};

/** The Bill To Code */
export type BillToCode = {
  readonly __typename?: 'BillToCode';
  readonly billingAddress?: Maybe<Scalars['ID']>;
  readonly billingAddressDisplayName?: Maybe<Scalars['String']>;
  readonly billingCode?: Maybe<Scalars['String']>;
  readonly billingEmail?: Maybe<Scalars['String']>;
  readonly billToCustomer?: Maybe<Scalars['ID']>;
  readonly billToCustomerDisplayName?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly distributionFrequency?: Maybe<Scalars['String']>;
  readonly frequency?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly invoiceGrouping?: Maybe<Scalars['String']>;
  readonly invoiceMethod?: Maybe<Scalars['String']>;
  readonly invoiceTemplate?: Maybe<Scalars['String']>;
  readonly isDefault?: Maybe<Scalars['Boolean']>;
  readonly paymentMethod?: Maybe<Scalars['String']>;
  readonly paymentTerms?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
};

/** The Bill To Code Audit History */
export type BillToCodeHistory = {
  readonly __typename?: 'BillToCodeHistory';
  readonly billingAddress?: Maybe<Scalars['ID']>;
  readonly billingAddressDisplayName?: Maybe<Scalars['String']>;
  readonly billingCode?: Maybe<Scalars['String']>;
  readonly billingEmail?: Maybe<Scalars['String']>;
  readonly billToCustomer?: Maybe<Scalars['ID']>;
  readonly billToCustomerDisplayName?: Maybe<Scalars['String']>;
  readonly changeType?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly distributionFrequency?: Maybe<Scalars['String']>;
  readonly frequency?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly invoiceGrouping?: Maybe<Scalars['String']>;
  readonly invoiceMethod?: Maybe<Scalars['String']>;
  readonly invoiceTemplate?: Maybe<Scalars['String']>;
  readonly isDefault?: Maybe<Scalars['Boolean']>;
  readonly paymentMethod?: Maybe<Scalars['String']>;
  readonly paymentTerms?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
};

/** The Bill To Code */
export type BillToCodeInput = {
  readonly billingAddress?: InputMaybe<Scalars['ID']>;
  readonly billingCode?: InputMaybe<Scalars['String']>;
  readonly billingEmail?: InputMaybe<Scalars['String']>;
  readonly billToCustomer?: InputMaybe<Scalars['ID']>;
  readonly currency?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly distributionFrequency?: InputMaybe<Scalars['String']>;
  readonly frequency?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly invoiceGrouping?: InputMaybe<Scalars['String']>;
  readonly invoiceMethod?: InputMaybe<Scalars['String']>;
  readonly invoiceTemplate?: InputMaybe<Scalars['String']>;
  readonly isDefault?: InputMaybe<Scalars['Boolean']>;
  readonly paymentMethod?: InputMaybe<Scalars['String']>;
  readonly paymentTerms?: InputMaybe<Scalars['String']>;
};

export type BlumeCharge = {
  readonly __typename?: 'BlumeCharge';
  readonly id: Scalars['ID'];
  readonly isMatched: Scalars['Boolean'];
  readonly assetUsageId?: Maybe<Scalars['String']>;
  readonly trackingNumberId?: Maybe<Scalars['String']>;
  readonly billableItemId?: Maybe<Scalars['String']>;
  readonly invoiceLineDetailId?: Maybe<Scalars['String']>;
  readonly programCode?: Maybe<Scalars['String']>;
  readonly assetPrefix?: Maybe<Scalars['String']>;
  readonly assetNumber?: Maybe<Scalars['String']>;
  readonly trackingNumber?: Maybe<Scalars['String']>;
  readonly parentShipperReferenceNumber?: Maybe<Scalars['String']>;
  readonly parentShipperName?: Maybe<Scalars['String']>;
  readonly shipperReferenceNumber?: Maybe<Scalars['String']>;
  readonly shipperName?: Maybe<Scalars['String']>;
  readonly customerReferenceNumber?: Maybe<Scalars['String']>;
  readonly onBehalfOfUser?: Maybe<Scalars['String']>;
  readonly updateUser?: Maybe<Scalars['String']>;
  readonly billOfLading?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly currentStatus?: Maybe<Scalars['String']>;
  readonly reservationRailroadName?: Maybe<Scalars['String']>;
  readonly reservationRampName?: Maybe<Scalars['String']>;
  readonly originRailroadName?: Maybe<Scalars['String']>;
  readonly originRampName?: Maybe<Scalars['String']>;
  readonly destinationRailroadName?: Maybe<Scalars['String']>;
  readonly destinationRampName?: Maybe<Scalars['String']>;
  readonly returnRailroadName?: Maybe<Scalars['String']>;
  readonly returnRampName?: Maybe<Scalars['String']>;
  readonly billableChargeType?: Maybe<Scalars['String']>;
  readonly billableItemCreateDate?: Maybe<Scalars['DateTime']>;
  readonly billableItemStartDate?: Maybe<Scalars['DateTime']>;
  readonly billableItemEndDate?: Maybe<Scalars['DateTime']>;
  readonly rateType?: Maybe<Scalars['String']>;
  readonly billableDays?: Maybe<Scalars['Int']>;
  readonly billableRate?: Maybe<Scalars['Float']>;
  readonly invoiceLineDetailCharges?: Maybe<Scalars['Float']>;
  readonly invoiceLineCharges?: Maybe<Scalars['Float']>;
  readonly asOfDate?: Maybe<Scalars['DateTime']>;
  readonly invoiceNumber?: Maybe<Scalars['String']>;
  readonly postingDate?: Maybe<Scalars['DateTime']>;
  readonly blumeChargeType?: Maybe<BlumeChargeType>;
  readonly matchInfo?: Maybe<BlumeMatchInfo>;
  readonly isIgnored: Scalars['Boolean'];
};

export type BlumeChargeType = {
  readonly __typename?: 'BlumeChargeType';
  readonly id: Scalars['ID'];
  readonly originChargeAllowed: Scalars['Boolean'];
  readonly destinationChargeAllowed: Scalars['Boolean'];
  readonly accountingChargeType?: Maybe<ChargeType>;
  readonly blumeEnumeratedValue?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['DateTime'];
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly updatedOn: Scalars['DateTime'];
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
};

export type BlumeDeletedCostDetail = {
  readonly __typename?: 'BlumeDeletedCostDetail';
  readonly id: Scalars['ID'];
};

export type BlumeInsertedCostDetail = {
  readonly __typename?: 'BlumeInsertedCostDetail';
  readonly id: Scalars['ID'];
};

export type BlumeMatchInfo = {
  readonly __typename?: 'BlumeMatchInfo';
  readonly matchingMethod: BlumeMatchMethod;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
};

export enum BlumeMatchMethod {
  BillOfLading = 'BillOfLading',
  TrackingNumber = 'TrackingNumber',
  BypassId = 'BypassId',
  NotMatched = 'NotMatched'
}

export type Book = {
  readonly __typename?: 'Book';
  readonly id: Scalars['ID'];
  readonly title: Scalars['String'];
  readonly isbn: Scalars['String'];
};

export type BookingValidationRule = {
  readonly __typename?: 'BookingValidationRule';
  readonly name: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
  readonly options: ReadonlyArray<BookingValidationRuleOption>;
};

export type BookingValidationRuleOption = {
  readonly __typename?: 'BookingValidationRuleOption';
  readonly key: Scalars['String'];
  readonly value: Scalars['String'];
};

export type BookingValidationRulesInput = {
  readonly scope?: InputMaybe<BookingValidationRulesScope>;
};

export type BookingValidationRulesPayload = {
  readonly __typename?: 'BookingValidationRulesPayload';
  readonly rules: ReadonlyArray<BookingValidationRule>;
};

export type BookingValidationRulesScope = {
  readonly division?: InputMaybe<Scalars['String']>;
  readonly equipments?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeTransportMode?: InputMaybe<Scalars['String']>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
};

export type BooleanError = {
  readonly __typename?: 'BooleanError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

export type BooleanPayload = {
  readonly __typename?: 'BooleanPayload';
  readonly errors: ReadonlyArray<BooleanError>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
};

export type BooleanType = {
  readonly __typename?: 'BooleanType';
  readonly value?: Maybe<Scalars['Boolean']>;
};

export type Bounce = {
  readonly __typename?: 'Bounce';
  readonly active: Scalars['Boolean'];
  readonly bounceReason: KeyValue;
  readonly bounceType: KeyValue;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser: User;
  readonly id: Scalars['ID'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly rebooked?: Maybe<Scalars['Boolean']>;
  readonly rebookedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly rebookedByUser?: Maybe<User>;
  readonly routeVendor?: Maybe<RouteVendor>;
  readonly updatedByUser: User;
};

/** The connection type for Bounce. */
export type BounceConnection = {
  readonly __typename?: 'BounceConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<BounceEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type BounceEdge = {
  readonly __typename?: 'BounceEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: Bounce;
};

export type BounceRouteVendorInput = {
  readonly bounceReasonId: Scalars['ID'];
  readonly bounceTypeId: Scalars['ID'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly routeVendorId: Scalars['ID'];
};

/** Autogenerated return type of BounceRouteVendor */
export type BounceRouteVendorPayload = {
  readonly __typename?: 'BounceRouteVendorPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly routeVendor: RouteVendor;
};

export type BounceRouteVendorV2Input = {
  readonly bounceReasonId: Scalars['ID'];
  readonly bounceTypeId: Scalars['ID'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly routeVendorId: Scalars['ID'];
  readonly bouncedByUserId: Scalars['ID'];
};

export type BounceRouteVendorV2Payload = {
  readonly __typename?: 'BounceRouteVendorV2Payload';
  readonly errors: ReadonlyArray<UserError>;
  readonly routeVendor?: Maybe<RouteVendorV2>;
};

export type BouncesFilter = {
  readonly routeId?: InputMaybe<Scalars['ID']>;
};

export type BouncesV2Connection = {
  readonly __typename?: 'BouncesV2Connection';
  readonly edges: ReadonlyArray<BouncesV2Edge>;
  readonly pageInfo: PageInfo;
};

export type BouncesV2Edge = {
  readonly __typename?: 'BouncesV2Edge';
  readonly cursor: Scalars['String'];
  readonly node: BounceV2;
};

export type BouncesV2Filter = {
  readonly routeId?: InputMaybe<Scalars['ID']>;
};

export type BounceV2 = {
  readonly __typename?: 'BounceV2';
  readonly active: Scalars['Boolean'];
  readonly bounceReason: KeyValue;
  readonly bounceType: KeyValue;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser: User;
  readonly createdByUserV2: UserV2;
  readonly id: Scalars['ID'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly rebooked?: Maybe<Scalars['Boolean']>;
  readonly rebookedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly rebookedByUser?: Maybe<User>;
  readonly rebookedByUserV2?: Maybe<UserV2>;
  readonly routeVendor?: Maybe<RouteVendorV2>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser: User;
  readonly updatedByUserV2: UserV2;
};

export type BraceTypes = {
  readonly __typename?: 'BraceTypes';
  readonly option: KeyValue;
};

export enum BracingTypeEnum {
  /** Load bars */
  LoadBars = 'loadBars',
  /** Load locks */
  LoadLocks = 'loadLocks',
  /** None */
  None = 'none',
  /** Straps */
  Straps = 'straps'
}

export enum BracingTypesEnum {
  LoadBars = 'loadBars',
  LoadLocks = 'loadLocks',
  Straps = 'straps'
}

export type BulkAssignVendorToRoutesOptionsInput = {
  readonly linkRoutes?: InputMaybe<Scalars['Boolean']>;
  readonly stopOnBookingFailure?: InputMaybe<Scalars['Boolean']>;
};

export type BulkAssignVendorToRoutesV2IndividualPayload = {
  readonly __typename?: 'BulkAssignVendorToRoutesV2IndividualPayload';
  readonly routeId: Scalars['String'];
  readonly errors: ReadonlyArray<UserError>;
  readonly routeVendor?: Maybe<RouteVendorV2>;
};

export type BulkAssignVendorToRoutesV2Input = {
  readonly options: BulkAssignVendorToRoutesOptionsInput;
  readonly routeVendorAssignments: ReadonlyArray<AssignVendorToRouteV2Input>;
};

export type BulkAssignVendorToRoutesV2Payload = {
  readonly __typename?: 'BulkAssignVendorToRoutesV2Payload';
  readonly errors: ReadonlyArray<UserError>;
  readonly routeVendors: ReadonlyArray<BulkAssignVendorToRoutesV2IndividualPayload>;
};

export type BulkCustomerFuelRangeCreateInput = {
  readonly customerFuelRateHeaderId: Scalars['ID'];
  readonly fuelRangeStart: Scalars['Decimal'];
  readonly fuelRangeEnd: Scalars['Decimal'];
  readonly fuelRangeIncrement: Scalars['Decimal'];
  readonly fuelSurchargeStart: Scalars['Decimal'];
  readonly fuelSurchargeIncrement: Scalars['Decimal'];
};

export type BulkDeleteExternalTruckEntriesInput = {
  readonly sourceTypeId: Scalars['String'];
  /** Delete Truck Entries with an externalUpdatedAt older than this input */
  readonly externalUpdatedAt: Scalars['ISO8601DateTime'];
};

export type BulkUpdateTenderStatusInput = {
  readonly acceptedById?: InputMaybe<Scalars['ID']>;
  readonly activationStatusId?: InputMaybe<Scalars['ID']>;
  readonly customerId: Scalars['ID'];
  readonly declinedById?: InputMaybe<Scalars['ID']>;
  readonly declinedReasonId?: InputMaybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly tenderStatusId: Scalars['ID'];
};

/** Autogenerated return type of BulkUpdateTenderStatus */
export type BulkUpdateTenderStatusPayload = {
  readonly __typename?: 'BulkUpdateTenderStatusPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly orders: ReadonlyArray<CustomerOrder>;
};

export type BusinessUnitR2 = {
  readonly __typename?: 'BusinessUnitR2';
  readonly id?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type CacheFlushResponse = {
  readonly __typename?: 'CacheFlushResponse';
  readonly response?: Maybe<Scalars['String']>;
};

export type CalculateDriverAssignmentEtaInput = {
  readonly routeID: Scalars['ID'];
  readonly emptyLocation: LatLongPair;
  readonly emptyLocationDatetime: Scalars['DateTime'];
  /** Indicates whether or not there is more than one driver listed on the given driver assignment. Defaults to `false`. */
  readonly isTeamOfDrivers?: InputMaybe<Scalars['Boolean']>;
};

export type CalculateVatCheckedModel = {
  readonly __typename?: 'calculateVatCheckedModel';
  readonly calculateVatOption?: Maybe<CalculateVatCheckedOptionEnumOutputType>;
};

export enum CalculateVatCheckedOptionEnumOutputType {
  Unchecked = 'UNCHECKED',
  Checked = 'CHECKED'
}

export type CancelCustomerOrderInput = {
  readonly id: Scalars['ID'];
  readonly cancelationReason: Scalars['String'];
};

export type CapacityConstraint = {
  readonly __typename?: 'CapacityConstraint';
  readonly id?: Maybe<Scalars['ID']>;
  readonly rateQuoteId?: Maybe<Scalars['ID']>;
  readonly sundayConstraint?: Maybe<Scalars['Int']>;
  readonly mondayConstraint?: Maybe<Scalars['Int']>;
  readonly tuesdayConstraint?: Maybe<Scalars['Int']>;
  readonly wednesdayConstraint?: Maybe<Scalars['Int']>;
  readonly thursdayConstraint?: Maybe<Scalars['Int']>;
  readonly fridayConstraint?: Maybe<Scalars['Int']>;
  readonly saturdayConstraint?: Maybe<Scalars['Int']>;
  readonly weeklyCapacityConstraint?: Maybe<Scalars['Int']>;
};

export type CapacityConstraints = {
  readonly __typename?: 'CapacityConstraints';
  readonly sundayConstraint?: Maybe<Scalars['Int']>;
  readonly mondayConstraint?: Maybe<Scalars['Int']>;
  readonly tuesdayConstraint?: Maybe<Scalars['Int']>;
  readonly wednesdayConstraint?: Maybe<Scalars['Int']>;
  readonly thursdayConstraint?: Maybe<Scalars['Int']>;
  readonly fridayConstraint?: Maybe<Scalars['Int']>;
  readonly saturdayConstraint?: Maybe<Scalars['Int']>;
};

export type CapacityConstraintsInput = {
  readonly sundayConstraint?: InputMaybe<Scalars['Int']>;
  readonly mondayConstraint?: InputMaybe<Scalars['Int']>;
  readonly tuesdayConstraint?: InputMaybe<Scalars['Int']>;
  readonly wednesdayConstraint?: InputMaybe<Scalars['Int']>;
  readonly thursdayConstraint?: InputMaybe<Scalars['Int']>;
  readonly fridayConstraint?: InputMaybe<Scalars['Int']>;
  readonly saturdayConstraint?: InputMaybe<Scalars['Int']>;
};

export type CapacityLocation = {
  readonly __typename?: 'CapacityLocation';
  /** Full city name, ex: Chicago */
  readonly city: Scalars['String'];
  /** The state abbreviated to 2 letters, ex: IL */
  readonly stateAlpha2Code: Scalars['String'];
  /** The country abbreviated to 2 letters, ex: US */
  readonly countryAlpha2Code: Scalars['String'];
  /** Returns Geopoint with shape { lat: _, lon: _ } */
  readonly point: Geopoint;
};

export type CapacityLocationInput = {
  /** Full city name, ex: Chicago */
  readonly city: Scalars['String'];
  /** The state, ex: IL, MEX, Ontario, Florida */
  readonly stateAlpha2Code: Scalars['String'];
  /** The country, ex: US, Mexico */
  readonly countryAlpha2Code: Scalars['String'];
  /** Optional postal code, ex: 60654 */
  readonly postalCode?: InputMaybe<Scalars['String']>;
};

/** The types of capacity the route needs */
export enum CapacityNeed {
  Truck = 'TRUCK',
  PowerOnly = 'POWER_ONLY',
  Container = 'CONTAINER',
  GateReservation = 'GATE_RESERVATION'
}

export type Carrier = {
  readonly __typename?: 'Carrier';
  readonly accountingNotes?: Maybe<ReadonlyArray<Note>>;
  readonly addresses?: Maybe<ReadonlyArray<Address>>;
  readonly allNotes?: Maybe<ReadonlyArray<Note>>;
  readonly ancestorIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly audits: ReadonlyArray<AuditTypeUnion>;
  readonly binQualified?: Maybe<Scalars['Boolean']>;
  readonly carrierAuditsExternalStatus?: Maybe<ReadonlyArray<CarrierAuditsExternalStatus>>;
  readonly carrierComplianceEmail?: Maybe<Scalars['String']>;
  readonly carrierIdentifiers?: Maybe<ReadonlyArray<CarrierIdentifier>>;
  readonly carrierPaymentTerm?: Maybe<KeyValue>;
  readonly carrierPaymentTermId?: Maybe<Scalars['ID']>;
  readonly carrierRepAudit?: Maybe<ReadonlyArray<CarrierRepAudit>>;
  readonly carrierSalesProgressType?: Maybe<KeyValue>;
  readonly carrierServices?: Maybe<ReadonlyArray<CarrierService>>;
  readonly certifications?: Maybe<ReadonlyArray<CarrierCertification>>;
  readonly children?: Maybe<ReadonlyArray<Carrier>>;
  readonly code: Scalars['String'];
  readonly confirmationNotes?: Maybe<ReadonlyArray<Note>>;
  readonly contacts?: Maybe<ReadonlyArray<Contact>>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly currency?: Maybe<KeyValue>;
  readonly currencyId?: Maybe<Scalars['ID']>;
  readonly customerRelationships?: Maybe<ReadonlyArray<CarrierCustomerRelationship>>;
  readonly dbaName?: Maybe<Scalars['String']>;
  readonly descendantIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly dotNumber?: Maybe<Scalars['String']>;
  readonly dunsNumber?: Maybe<Scalars['String']>;
  readonly electronicTrackingMethod?: Maybe<KeyValue>;
  readonly electronicTrackingType?: Maybe<KeyValue>;
  readonly entityType: EntityTypeEnum;
  /** @deprecated use `standing` field instead. */
  readonly externalStatus?: Maybe<StatusTypeEnum>;
  /** @deprecated Use `geographiesNullable` instead (`geographies` is not under access control) */
  readonly geographies: ReadonlyArray<CarrierGeography>;
  readonly geographiesNullable?: Maybe<ReadonlyArray<CarrierGeography>>;
  readonly hazmat?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  readonly inactiveContacts?: Maybe<ReadonlyArray<Contact>>;
  readonly insurances?: Maybe<ReadonlyArray<CarrierInsurance>>;
  readonly interactions?: Maybe<ReadonlyArray<Interaction>>;
  readonly intrastatePresent?: Maybe<Scalars['Boolean']>;
  readonly intrastateStates?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly invoiceDistributionMethod?: Maybe<KeyValue>;
  readonly invoiceDistributionMethodId?: Maybe<Scalars['ID']>;
  readonly isAutoTruckEnabled: Scalars['Boolean'];
  /** @deprecated Use carrierSettings instead */
  readonly level?: Maybe<KeyValue>;
  readonly levelId?: Maybe<Scalars['ID']>;
  /** @deprecated Use level */
  readonly levelName?: Maybe<LevelTypeEnum>;
  readonly mainAddress?: Maybe<Address>;
  readonly mainContact?: Maybe<Contact>;
  readonly manualTrackingInformation?: Maybe<Scalars['String']>;
  readonly manualTrackingMethod?: Maybe<ManualTrackingMethodEnum>;
  readonly mcNumber?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly nonCertifiedReasons?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly operatingStatus?: Maybe<Scalars['String']>;
  /** @deprecated Use carrierSettings instead */
  readonly opportunity?: Maybe<KeyValue>;
  readonly opportunityId?: Maybe<Scalars['ID']>;
  /** @deprecated Use opportunity */
  readonly opportunityName?: Maybe<OpportunityTypeEnum>;
  readonly parent?: Maybe<Carrier>;
  readonly paymentMethod?: Maybe<KeyValue>;
  readonly paymentMethodId?: Maybe<Scalars['ID']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly releaseMethods?: Maybe<ReadonlyArray<CarrierReleaseMethodV1>>;
  readonly remitToCarrier?: Maybe<Carrier>;
  readonly remittanceAddress?: Maybe<Address>;
  readonly remittanceEmail?: Maybe<Scalars['String']>;
  /** @deprecated Use temp_remittance_grouping for now */
  readonly remittanceGrouping?: Maybe<RemittanceGroupingEnum>;
  readonly reps?: Maybe<ReadonlyArray<Rep>>;
  /** @deprecated will be removed soon */
  readonly revenovaId?: Maybe<Scalars['String']>;
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly roeStatus?: Maybe<KeyValue>;
  readonly safetyRating?: Maybe<SafetyRatingTypeEnum>;
  readonly scacNumber?: Maybe<Scalars['String']>;
  readonly setting?: Maybe<CarrierSetting>;
  readonly siblings?: Maybe<ReadonlyArray<Carrier>>;
  readonly standing?: Maybe<ReadonlyArray<CarrierStanding>>;
  readonly status?: Maybe<StatusTypeEnum>;
  readonly tempRemittanceGrouping?: Maybe<TempRemittanceGroupingEnum>;
  readonly tenderOptions?: Maybe<TenderOptions>;
  /** @deprecated Service has reached EOL. Use TruckEntry subgraph. Data will soon be stale or null only */
  readonly truckPostings?: Maybe<ReadonlyArray<TruckPosting>>;
  readonly trucks?: Maybe<ReadonlyArray<CarrierTruck>>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
  /** @deprecated Use w9_certified_nullable instead. */
  readonly w9Certified: Scalars['Boolean'];
  readonly w9CertifiedNullable?: Maybe<Scalars['Boolean']>;
  readonly carrierFacilities?: Maybe<ReadonlyArray<CarrierFacility>>;
  readonly accountingStatus?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Date']>;
  readonly eid?: Maybe<Scalars['Long']>;
  readonly hasPropertyMetadata: Scalars['Boolean'];
  readonly propertyMetadata?: Maybe<ReadonlyArray<KeyValuePairOfStringAndPropertyMetadata>>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['Date']>;
};


export type CarrierInteractionsArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  interactionType?: InputMaybe<InteractionTypeEnum>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type CarrierAddress = {
  readonly __typename?: 'CarrierAddress';
  readonly addressType?: Maybe<KeyValue>;
  readonly addressTypeId?: Maybe<Scalars['ID']>;
  readonly carrierId: Scalars['ID'];
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly geopoint: Geopoint;
  readonly id: Scalars['ID'];
  readonly isVerified: Scalars['Boolean'];
  readonly main: Scalars['Boolean'];
  readonly postalCode: Scalars['String'];
  readonly province: Scalars['String'];
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly state: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: Maybe<Scalars['String']>;
  readonly thirdPartyId?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
};

export type CarrierAddressesFilter = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly stateCollection?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly stateIdCollection?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly radius?: InputMaybe<UnitOfDistanceInput>;
};

export type CarrierAddressInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly addressTypeId?: InputMaybe<Scalars['ID']>;
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly province?: InputMaybe<Scalars['String']>;
  readonly country: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly main: Scalars['Boolean'];
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly isVerified?: InputMaybe<Scalars['Boolean']>;
};

export type CarrierArgumentInput = {
  readonly code?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type CarrierArgumentInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly code?: InputMaybe<Scalars['String']>;
};

export type CarrierAudit = AuditTypeInterface & {
  readonly __typename?: 'CarrierAudit';
  readonly actionType: Scalars['String'];
  readonly auditType: Scalars['String'];
  readonly auditableId: Scalars['ID'];
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByEmployee?: Maybe<Employee>;
  readonly currentValue?: Maybe<CarrierAuditedChanges>;
  readonly id: Scalars['ID'];
  readonly previousValue?: Maybe<CarrierAuditedChanges>;
  readonly userId?: Maybe<Scalars['ID']>;
};

export type CarrierAuditedChanges = {
  readonly __typename?: 'CarrierAuditedChanges';
  readonly externalStatus?: Maybe<StatusTypeEnum>;
  readonly nonCertifiedReasons?: Maybe<Scalars['String']>;
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly status?: Maybe<StatusTypeEnum>;
};

export type CarrierAuditedChangesV2 = {
  readonly __typename?: 'CarrierAuditedChangesV2';
  readonly externalStatus?: Maybe<StatusTypeEnum>;
  readonly nonCertifiedReasons?: Maybe<Scalars['String']>;
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly status?: Maybe<StatusTypeEnum>;
};

export type CarrierAuditsExternalStatus = AuditTypeInterface & {
  readonly __typename?: 'CarrierAuditsExternalStatus';
  readonly actionType: Scalars['String'];
  readonly auditType: Scalars['String'];
  readonly auditableId: Scalars['ID'];
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByEmployee?: Maybe<Employee>;
  readonly currentValue?: Maybe<CarrierAuditedChanges>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly previousValue?: Maybe<CarrierAuditedChanges>;
};

export type CarrierAuditsExternalStatusV2 = AuditTypeInterfaceV2 & {
  readonly __typename?: 'CarrierAuditsExternalStatusV2';
  readonly actionType: Scalars['String'];
  readonly auditType: Scalars['String'];
  readonly auditableId: Scalars['ID'];
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByEmployee?: Maybe<EmployeeV2>;
  readonly currentValue?: Maybe<CarrierAuditedChangesV2>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly previousValue?: Maybe<CarrierAuditedChangesV2>;
  readonly userId?: Maybe<Scalars['ID']>;
};

export type CarrierAuditV2 = AuditTypeInterfaceV2 & {
  readonly __typename?: 'CarrierAuditV2';
  readonly actionType: Scalars['String'];
  readonly auditType: Scalars['String'];
  readonly auditableId: Scalars['ID'];
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByEmployee?: Maybe<EmployeeV2>;
  readonly currentValue?: Maybe<CarrierAuditedChangesV2>;
  readonly id: Scalars['ID'];
  readonly previousValue?: Maybe<CarrierAuditedChangesV2>;
  readonly userId?: Maybe<Scalars['ID']>;
};

export type CarrierBaseInfo = {
  readonly __typename?: 'CarrierBaseInfo';
  readonly code: Scalars['String'];
  readonly id: Scalars['String'];
};

export type CarrierCapacity = {
  readonly __typename?: 'CarrierCapacity';
  readonly id: Scalars['ID'];
  readonly costQuoteId: Scalars['ID'];
  readonly weeklyCapacity?: Maybe<Scalars['Int']>;
  readonly mondayCapacity?: Maybe<Scalars['Int']>;
  readonly tuesdayCapacity?: Maybe<Scalars['Int']>;
  readonly wednesdayCapacity?: Maybe<Scalars['Int']>;
  readonly thursdayCapacity?: Maybe<Scalars['Int']>;
  readonly fridayCapacity?: Maybe<Scalars['Int']>;
  readonly saturdayCapacity?: Maybe<Scalars['Int']>;
  readonly sundayCapacity?: Maybe<Scalars['Int']>;
  readonly createdBy: Scalars['ID'];
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedBy: Scalars['ID'];
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarrierCapacityConstraintInput = {
  readonly costQuoteId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly laneId: Scalars['ID'];
  readonly weeklyCapacity?: InputMaybe<Scalars['Int']>;
  readonly mondayCapacity?: InputMaybe<Scalars['Int']>;
  readonly tuesdayCapacity?: InputMaybe<Scalars['Int']>;
  readonly wednesdayCapacity?: InputMaybe<Scalars['Int']>;
  readonly thursdayCapacity?: InputMaybe<Scalars['Int']>;
  readonly fridayCapacity?: InputMaybe<Scalars['Int']>;
  readonly saturdayCapacity?: InputMaybe<Scalars['Int']>;
  readonly sundayCapacity?: InputMaybe<Scalars['Int']>;
  readonly requestedById: Scalars['ID'];
};

export type CarrierCapacityRemaining = {
  readonly __typename?: 'CarrierCapacityRemaining';
  readonly hasWeeklyCapacity?: Maybe<Scalars['Boolean']>;
  readonly hasDailyCapacity?: Maybe<Scalars['Boolean']>;
  readonly weeklyCapacityRemaining?: Maybe<Scalars['Int']>;
  readonly dailyCapacityRemaining?: Maybe<Scalars['Int']>;
};

export type CarrierCapacityRoute = {
  readonly __typename?: 'CarrierCapacityRoute';
  readonly id: Scalars['ID'];
  readonly countsAgainstCapacityDate?: Maybe<Scalars['DateTime']>;
  readonly countsAgainstCapacityDayOfWeek?: Maybe<Scalars['String']>;
  readonly countsAgainstCapacity?: Maybe<Scalars['Boolean']>;
};

export type CarrierCapacityWeek = {
  readonly __typename?: 'CarrierCapacityWeek';
  readonly id: Scalars['ID'];
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly weeklyCapacityConsumed?: Maybe<Scalars['Int']>;
  readonly mondayCapacityConsumed?: Maybe<Scalars['Int']>;
  readonly tuesdayCapacityConsumed?: Maybe<Scalars['Int']>;
  readonly wednesdayCapacityConsumed?: Maybe<Scalars['Int']>;
  readonly thursdayCapacityConsumed?: Maybe<Scalars['Int']>;
  readonly fridayCapacityConsumed?: Maybe<Scalars['Int']>;
  readonly saturdayCapacityConsumed?: Maybe<Scalars['Int']>;
  readonly sundayCapacityConsumed?: Maybe<Scalars['Int']>;
};

export type CarrierCertification = {
  readonly __typename?: 'CarrierCertification';
  readonly carrierCertificationType: KeyValue;
  readonly carrierCertificationTypeId: Scalars['ID'];
  readonly carrierInfo?: Maybe<CarrierBaseInfo>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly expirationDate?: Maybe<Scalars['Iso8601Date']>;
  readonly id: Scalars['ID'];
  readonly note?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
};

export type CarrierCertificationInput = {
  readonly carrier: CarrierArgumentInput;
  readonly carrierCertificationTypeId: Scalars['ID'];
  readonly expirationDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly note?: InputMaybe<Scalars['String']>;
};

export type CarrierCertificationInputV2 = {
  readonly carrier: CarrierArgumentInputV2;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly expirationDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly carrierCertificationTypeId: Scalars['ID'];
};

export type CarrierCertificationQueryInput = {
  readonly carrierCertificationTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type CarrierCertificationQueryInputV2 = {
  readonly carrierCertificationTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type CarrierCertificationV2 = {
  readonly __typename?: 'CarrierCertificationV2';
  readonly carrierCertificationType: KeyValue;
  readonly carrierCertificationTypeId: Scalars['ID'];
  readonly carrierInfo?: Maybe<CarrierBaseInfo>;
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['Iso8601Date']>;
  readonly id: Scalars['ID'];
  readonly note?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
};

export type CarrierChargeTypeConfigurationCreateInput = {
  readonly carrierChargeCode?: InputMaybe<Scalars['String']>;
  readonly carrierDescription?: InputMaybe<Scalars['String']>;
  readonly carrierId: Scalars['Guid'];
  readonly category?: InputMaybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly cost?: InputMaybe<Scalars['Decimal']>;
  readonly customerId?: InputMaybe<Scalars['Guid']>;
  readonly customerName?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly isAllowedByCarrier: Scalars['Boolean'];
  readonly isDefault: Scalars['Boolean'];
  readonly maximumTotalCost?: InputMaybe<Scalars['Decimal']>;
  readonly minimumTotalCost?: InputMaybe<Scalars['Decimal']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly perUnit?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly size?: InputMaybe<Scalars['String']>;
};

export type CarrierChargeTypeConfigurationResponse = {
  readonly __typename?: 'CarrierChargeTypeConfigurationResponse';
  readonly isActive: Scalars['Boolean'];
  readonly isCredit: Scalars['Boolean'];
  readonly carrierChargeCode?: Maybe<Scalars['String']>;
  readonly carrierDescription?: Maybe<Scalars['String']>;
  readonly carrierId: Scalars['Guid'];
  readonly category?: Maybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: Maybe<Scalars['String']>;
  readonly cost?: Maybe<Scalars['Decimal']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['DateTime'];
  readonly customerId?: Maybe<Scalars['Guid']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly isAllowedByCarrier: Scalars['Boolean'];
  readonly isDefault: Scalars['Boolean'];
  readonly maximumTotalCost?: Maybe<Scalars['Decimal']>;
  readonly minimumTotalCost?: Maybe<Scalars['Decimal']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly perUnit?: Maybe<CtcPerUnitTypeEnum>;
  readonly size?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn: Scalars['DateTime'];
};

export type CarrierChargeTypeConfigurationUpdateInput = {
  readonly carrierChargeCode?: InputMaybe<Scalars['String']>;
  readonly carrierDescription?: InputMaybe<Scalars['String']>;
  readonly carrierId: Scalars['Guid'];
  readonly category?: InputMaybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly cost?: InputMaybe<Scalars['Decimal']>;
  readonly customerId?: InputMaybe<Scalars['Guid']>;
  readonly customerName?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly isAllowedByCarrier: Scalars['Boolean'];
  readonly maximumTotalCost?: InputMaybe<Scalars['Decimal']>;
  readonly minimumTotalCost?: InputMaybe<Scalars['Decimal']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly perUnit?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly size?: InputMaybe<Scalars['String']>;
};

/** The connection type for Carrier. */
export type CarrierConnection = {
  readonly __typename?: 'CarrierConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<CarrierEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** The connection type for Carrier. */
export type CarrierConnectionV2 = {
  readonly __typename?: 'CarrierConnectionV2';
  /** A list of edges. */
  readonly edges: ReadonlyArray<CarrierEdgeV2>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

export type CarrierContact = {
  readonly __typename?: 'CarrierContact';
  readonly active: Scalars['Boolean'];
  readonly chatType?: Maybe<KeyValue>;
  readonly chatTypeId?: Maybe<Scalars['ID']>;
  readonly chatUsername?: Maybe<Scalars['String']>;
  readonly contactType: KeyValue;
  readonly contactTypeId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly emailAddress?: Maybe<Scalars['String']>;
  readonly extension?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly main: Scalars['Boolean'];
  readonly name?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly thirdPartyId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
};

export type CarrierContactInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly chatTypeId?: InputMaybe<Scalars['ID']>;
  readonly chatUsername?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly contactTypeId?: InputMaybe<Scalars['ID']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
};

/** Holds carrier costs. */
export type CarrierCostsByRouteConnection = {
  readonly __typename?: 'CarrierCostsByRouteConnection';
  readonly code?: Maybe<Scalars['String']>;
  readonly edges?: Maybe<ReadonlyArray<Maybe<RateDetailsEdgeV2>>>;
  readonly message?: Maybe<Scalars['String']>;
};

export type CarrierCostsByRouteFilter = {
  readonly applyRouteVendorReferences?: InputMaybe<Scalars['Boolean']>;
  readonly carrierId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
};

export type CarrierCrmDocument = {
  readonly __typename?: 'CarrierCrmDocument';
  readonly contacts?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly createdBy?: Maybe<User>;
  readonly createdDate?: Maybe<Scalars['DateTime']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly direction?: Maybe<Scalars['String']>;
  readonly documentId?: Maybe<Scalars['ID']>;
  readonly documentName?: Maybe<Scalars['String']>;
  readonly documentType?: Maybe<Scalars['String']>;
  readonly fileName?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  readonly opportunities?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly opportunityId?: Maybe<Scalars['ID']>;
  readonly opportunityName?: Maybe<Scalars['String']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<User>;
  readonly updatedDate?: Maybe<Scalars['DateTime']>;
  readonly vendorId?: Maybe<Scalars['ID']>;
};

/** A connection to a list of items. */
export type CarrierCrmDocumentConnection = {
  readonly __typename?: 'CarrierCrmDocumentConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CarrierCrmDocumentEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CarrierCrmDocument>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CarrierCrmDocumentEdge = {
  readonly __typename?: 'CarrierCrmDocumentEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CarrierCrmDocument>;
};

export type CarrierCrmNotes = {
  readonly __typename?: 'CarrierCRMNotes';
  readonly contacts?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly createdBy?: Maybe<User>;
  readonly createdDate?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly opportunities?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly opportunityId?: Maybe<Scalars['ID']>;
  readonly opportunityName?: Maybe<Scalars['String']>;
  readonly reps?: Maybe<ReadonlyArray<Maybe<CarrierCrmNotesRep>>>;
  readonly type?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<User>;
  readonly updatedDate?: Maybe<Scalars['DateTime']>;
  readonly vendorId?: Maybe<Scalars['ID']>;
};

/** A connection to a list of items. */
export type CarrierCrmNotesConnection = {
  readonly __typename?: 'CarrierCRMNotesConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CarrierCrmNotesEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CarrierCrmNotes>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CarrierCrmNotesEdge = {
  readonly __typename?: 'CarrierCRMNotesEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CarrierCrmNotes>;
};

export type CarrierCrmNotesFilter = {
  readonly vendorId: Scalars['ID'];
};

export type CarrierCrmNotesRep = {
  readonly __typename?: 'CarrierCRMNotesRep';
  readonly Employee?: Maybe<Employee>;
};

export type CarrierCrmOpportunity = {
  readonly __typename?: 'CarrierCRMOpportunity';
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly isDefault?: Maybe<Scalars['Boolean']>;
  readonly LastUpdatedByUser?: Maybe<User>;
  readonly lastUpdatedDate?: Maybe<Scalars['DateTime']>;
  readonly name: Scalars['String'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly opportunityStatusId?: Maybe<Scalars['ID']>;
  readonly opportunityTypeId?: Maybe<Scalars['ID']>;
  readonly owningContactId?: Maybe<Scalars['ID']>;
  readonly OwningRep?: Maybe<Employee>;
  readonly vendorId: Scalars['ID'];
};

/** A connection to a list of items. */
export type CarrierCrmOpportunityConnection = {
  readonly __typename?: 'CarrierCRMOpportunityConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CarrierCrmOpportunityEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CarrierCrmOpportunity>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CarrierCrmOpportunityEdge = {
  readonly __typename?: 'CarrierCRMOpportunityEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CarrierCrmOpportunity>;
};

export type CarrierCrmOpportunityFilter = {
  readonly checkForDefaultOpportunity?: InputMaybe<Scalars['Boolean']>;
  readonly showInactive?: InputMaybe<Scalars['Boolean']>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
};

export type CarrierCustomerRelationship = {
  readonly __typename?: 'CarrierCustomerRelationship';
  readonly active: Scalars['Boolean'];
  readonly bypassCompliance: Scalars['Boolean'];
  readonly canLoad: Scalars['Boolean'];
  readonly carrier: Carrier;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdBy?: Maybe<User>;
  readonly customer: Customer;
  readonly id: Scalars['ID'];
  readonly notes?: Maybe<Note>;
  readonly relationshipType?: Maybe<CarrierCustomerRelationshipTypeEnum>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedBy?: Maybe<User>;
};

export type CarrierCustomerRelationshipInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly active: Scalars['Boolean'];
  readonly bypassCompliance?: InputMaybe<Scalars['Boolean']>;
  readonly canLoad: Scalars['Boolean'];
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly notes?: InputMaybe<ReadonlyArray<NoteInput>>;
  readonly relationshipType?: InputMaybe<CarrierCustomerRelationshipTypeEnum>;
};

export type CarrierCustomerRelationshipInputV2 = {
  readonly active: Scalars['Boolean'];
  readonly canLoad: Scalars['Boolean'];
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly relationshipType?: InputMaybe<CarrierCustomerRelationshipTypeEnumV2>;
  readonly notes?: InputMaybe<ReadonlyArray<NoteInputV2>>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly bypassCompliance?: InputMaybe<Scalars['Boolean']>;
};

export type CarrierCustomerRelationshipNote = {
  readonly __typename?: 'CarrierCustomerRelationshipNote';
  readonly carrierCustomerRelationshipId: Scalars['ID'];
  readonly createdAt: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly noteType: CarrierCustomerRelationshipNoteTypeEnum;
  readonly text?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['Iso8601Date'];
};

export enum CarrierCustomerRelationshipNoteTypeEnum {
  /** Carrier Customer Notes */
  CarrierCustomer = 'carrierCustomer'
}

export enum CarrierCustomerRelationshipTypeEnum {
  /** Core Carrier */
  CoreCarrier = 'coreCarrier',
  /** Dedicated Fleet */
  DedicatedFleet = 'dedicatedFleet',
  /** Owned Fleet */
  OwnedFleet = 'ownedFleet',
  /** Preferred */
  Preferred = 'preferred'
}

export enum CarrierCustomerRelationshipTypeEnumV2 {
  /** Owned Fleet */
  OwnedFleet = 'ownedFleet',
  /** Dedicated Fleet */
  DedicatedFleet = 'dedicatedFleet',
  /** Preferred */
  Preferred = 'preferred',
  /** Core Carrier */
  CoreCarrier = 'coreCarrier'
}

export type CarrierCustomerRelationshipV2 = {
  readonly __typename?: 'CarrierCustomerRelationshipV2';
  readonly active: Scalars['Boolean'];
  readonly bypassCompliance: Scalars['Boolean'];
  readonly canLoad: Scalars['Boolean'];
  readonly carrier: CarrierV2;
  readonly carrierId: Scalars['ID'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdBy?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly customer: CustomerV2;
  readonly customerId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly notes?: Maybe<CarrierCustomerRelationshipNote>;
  readonly relationshipType?: Maybe<CarrierCustomerRelationshipTypeEnumV2>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedBy?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type CarrierDocuemntFilter = {
  readonly vendorId: Scalars['ID'];
};

export type CarrierDocument = {
  readonly __typename?: 'CarrierDocument';
  readonly id: Scalars['ID'];
  readonly carrierId: Scalars['String'];
  /** The name of the file to be displayed to the user, not necessarily the filename */
  readonly name: Scalars['String'];
  /** The name of the file provided during upload */
  readonly filename: Scalars['String'];
  readonly uploadedByUserId?: Maybe<Scalars['String']>;
  readonly uploadDate: Scalars['DateTime'];
  /** carrierDocumentSourceType KVT ID */
  readonly sourceId: Scalars['ID'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
  /** employeeDepartment KVT ID */
  readonly departmentTypeId: Scalars['ID'];
  /** carrierDocumentType KVT ID */
  readonly documentTypeId: Scalars['ID'];
  /** carrierThirdPartyDocument ID */
  readonly thirdPartyDocumentId?: Maybe<Scalars['ID']>;
  /** uri to access the document */
  readonly uri: Scalars['String'];
  /**
   * DEPRECATED: Use uploadedByUserV2 instead
   * @deprecated Use uploadedByUserV2 instead
   */
  readonly uploadedByUser?: Maybe<User>;
  readonly uploadedByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  /**
   * DEPRECATED: Use carrierV2 instead
   * @deprecated Use carrierV2 instead
   */
  readonly carrier: Carrier;
  readonly carrierV2: CarrierV2;
};

export type CarrierDocumentConnection = {
  readonly __typename?: 'CarrierDocumentConnection';
  readonly edges: ReadonlyArray<CarrierDocumentGqlTypeEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Float'];
};

export type CarrierDocumentFilter = {
  readonly carrierId: Scalars['ID'];
};

export type CarrierDocumentGqlTypeEdge = {
  readonly __typename?: 'CarrierDocumentGQLTypeEdge';
  readonly cursor: Scalars['String'];
  readonly node: CarrierDocument;
};

/** An edge in a connection. */
export type CarrierEdge = {
  readonly __typename?: 'CarrierEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: Carrier;
};

/** An edge in a connection. */
export type CarrierEdgeV2 = {
  readonly __typename?: 'CarrierEdgeV2';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: CarrierV2;
};

export type CarrierElectronicTracking = {
  readonly __typename?: 'CarrierElectronicTracking';
  readonly carrierId: Scalars['ID'];
  readonly electronicTrackingMethod?: Maybe<KeyValue>;
  readonly electronicTrackingMethodId: Scalars['ID'];
  readonly electronicTrackingType?: Maybe<KeyValue>;
  readonly electronicTrackingTypeId: Scalars['ID'];
  readonly priority: Scalars['Int'];
  readonly id: Scalars['ID'];
};

export type CarrierElectronicTrackingInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly electronicTrackingMethodId: Scalars['ID'];
  readonly electronicTrackingTypeId: Scalars['ID'];
  readonly priority: Scalars['Int'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type CarrierFacility = {
  readonly __typename?: 'CarrierFacility';
  readonly id: Scalars['ID'];
  readonly carrierId: Scalars['String'];
  readonly facilityId: Scalars['String'];
  readonly relationshipType?: Maybe<Scalars['String']>;
  readonly canLoad: Scalars['Boolean'];
  readonly isActive: Scalars['Boolean'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly facility?: Maybe<Facility>;
  readonly facilityV2?: Maybe<FacilityV2>;
  readonly updatedByUser?: Maybe<User>;
  readonly updatedByUserV2?: Maybe<UserV2>;
};

export type CarrierGeography = {
  readonly __typename?: 'CarrierGeography';
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<User>;
  readonly geography: Geography;
  readonly id: Scalars['ID'];
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<User>;
};

export type CarrierGeographyInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly geographyId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type CarrierGeographyInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly geographyId: Scalars['ID'];
  readonly _destroy?: InputMaybe<Scalars['String']>;
};

export type CarrierGeographyV2 = {
  readonly __typename?: 'CarrierGeographyV2';
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly geography: GeographyV2;
  readonly geographyId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type CarrierGroup = {
  readonly __typename?: 'CarrierGroup';
  readonly carrierId: Scalars['String'];
  readonly employeeDivision?: Maybe<KeyValue>;
  readonly employeeGroup?: Maybe<KeyValue>;
  readonly groupType?: Maybe<KeyValue>;
  readonly employeeDivisionId: Scalars['ID'];
  readonly employeeGroupId: Scalars['ID'];
  readonly groupTypeId: Scalars['ID'];
  readonly id: Scalars['ID'];
};

export type CarrierGroupInput = {
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly carrierId: Scalars['ID'];
  readonly employeeDivisionId: Scalars['ID'];
  readonly employeeGroupId: Scalars['ID'];
  readonly groupTypeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type CarrierIdentifier = {
  readonly __typename?: 'CarrierIdentifier';
  readonly carrierId: Scalars['ID'];
  readonly carrierIdentifierType: KeyValue;
  readonly carrierIdentifierTypeId: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly link?: Maybe<Scalars['String']>;
};

export type CarrierIdentifierAuditedChanges = {
  readonly __typename?: 'CarrierIdentifierAuditedChanges';
  readonly id?: Maybe<Scalars['ID']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly link?: Maybe<Scalars['String']>;
  readonly carrierIdentifierType?: Maybe<Scalars['String']>;
};

export type CarrierIdentifierAuditV2 = AuditTypeInterfaceV2 & {
  readonly __typename?: 'CarrierIdentifierAuditV2';
  readonly actionType: Scalars['String'];
  readonly auditType: Scalars['String'];
  readonly auditableId: Scalars['ID'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByEmployee?: Maybe<EmployeeV2>;
  readonly currentValue?: Maybe<CarrierIdentifierAuditedChanges>;
  readonly id: Scalars['ID'];
  readonly previousValue?: Maybe<CarrierIdentifierAuditedChanges>;
  readonly userId?: Maybe<Scalars['ID']>;
};

export type CarrierIdentifierInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly carrierIdentifierTypeId: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly link?: InputMaybe<Scalars['String']>;
};

export type CarrierIdentifierInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierIdentifierTypeId: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly link?: InputMaybe<Scalars['String']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
};

export type CarrierIdentifierV2 = {
  readonly __typename?: 'CarrierIdentifierV2';
  readonly carrierId: Scalars['ID'];
  readonly carrierIdentifierType: KeyValue;
  readonly carrierIdentifierTypeId: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly link?: Maybe<Scalars['String']>;
};

export type CarrierInput = {
  readonly accountingNotes?: InputMaybe<ReadonlyArray<NoteInput>>;
  readonly addresses?: InputMaybe<ReadonlyArray<AddressInput>>;
  readonly allNotes?: InputMaybe<ReadonlyArray<NoteInput>>;
  readonly binQualified?: InputMaybe<Scalars['Boolean']>;
  readonly carrierComplianceEmail?: InputMaybe<Scalars['String']>;
  readonly carrierIdentifiers?: InputMaybe<ReadonlyArray<CarrierIdentifierInput>>;
  readonly carrierPaymentTermId?: InputMaybe<Scalars['ID']>;
  readonly carrierSalesProgressTypeId?: InputMaybe<Scalars['ID']>;
  readonly carrierServices?: InputMaybe<ReadonlyArray<CarrierServiceInput>>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly contacts?: InputMaybe<ReadonlyArray<ContactInput>>;
  readonly currencyId?: InputMaybe<Scalars['ID']>;
  readonly dbaName?: InputMaybe<Scalars['String']>;
  readonly dotNumber?: InputMaybe<Scalars['String']>;
  readonly dunsNumber?: InputMaybe<Scalars['String']>;
  readonly electronicTrackingMethodId?: InputMaybe<Scalars['ID']>;
  readonly electronicTrackingTypeId?: InputMaybe<Scalars['ID']>;
  readonly entityType?: InputMaybe<EntityTypeEnum>;
  readonly externalStatus?: InputMaybe<StatusTypeEnum>;
  readonly geographies?: InputMaybe<ReadonlyArray<CarrierGeographyInput>>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly insurances?: InputMaybe<ReadonlyArray<CarrierInsuranceInput>>;
  readonly interactions?: InputMaybe<ReadonlyArray<InteractionInput>>;
  readonly invoiceDistributionMethodId?: InputMaybe<Scalars['ID']>;
  readonly isAutoTruckEnabled?: InputMaybe<Scalars['Boolean']>;
  readonly levelId?: InputMaybe<Scalars['ID']>;
  readonly levelName?: InputMaybe<LevelTypeEnum>;
  readonly mainContactId?: InputMaybe<Scalars['ID']>;
  readonly manualTrackingInformation?: InputMaybe<Scalars['String']>;
  readonly manualTrackingMethod?: InputMaybe<ManualTrackingMethodEnum>;
  readonly mcNumber?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly nonCertifiedReasons?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly operatingStatus?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly opportunityName?: InputMaybe<OpportunityTypeEnum>;
  readonly parentId?: InputMaybe<Scalars['ID']>;
  readonly paymentMethodId?: InputMaybe<Scalars['ID']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly remitToCarrierId?: InputMaybe<Scalars['ID']>;
  readonly remittanceAddressId?: InputMaybe<Scalars['ID']>;
  readonly remittanceEmail?: InputMaybe<Scalars['String']>;
  readonly remittanceGrouping?: InputMaybe<RemittanceGroupingEnum>;
  readonly reps?: InputMaybe<ReadonlyArray<RepInput>>;
  readonly revenovaId?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly roeStatus?: InputMaybe<RoeStatusEnum>;
  readonly safetyRating?: InputMaybe<SafetyRatingTypeEnum>;
  readonly scacNumber?: InputMaybe<Scalars['String']>;
  readonly setting?: InputMaybe<NestedCarrierSettingInput>;
  readonly standing?: InputMaybe<Scalars['Json']>;
  readonly status?: InputMaybe<StatusTypeEnum>;
  readonly tempRemittanceGrouping?: InputMaybe<TempRemittanceGroupingEnum>;
  readonly updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Use w9_certified_nullable instead. */
  readonly w9Certified?: InputMaybe<Scalars['Boolean']>;
  readonly w9CertifiedNullable?: InputMaybe<Scalars['Boolean']>;
};

export type CarrierInputV2 = {
  readonly addresses?: InputMaybe<ReadonlyArray<CarrierAddressInput>>;
  readonly accountingNotes?: InputMaybe<ReadonlyArray<CarrierNoteInput>>;
  readonly allNotes?: InputMaybe<ReadonlyArray<CarrierNoteInput>>;
  readonly carrierIdentifiers?: InputMaybe<ReadonlyArray<CarrierIdentifierInputV2>>;
  readonly carrierServices?: InputMaybe<ReadonlyArray<CarrierServiceInputV2>>;
  readonly carrierPaymentTermId?: InputMaybe<Scalars['ID']>;
  readonly carrierTypeId?: InputMaybe<Scalars['ID']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly contacts?: InputMaybe<ReadonlyArray<CarrierContactInput>>;
  readonly currencyId?: InputMaybe<Scalars['ID']>;
  readonly dbaName?: InputMaybe<Scalars['String']>;
  readonly complianceEmail?: InputMaybe<Scalars['String']>;
  readonly dotNumber?: InputMaybe<Scalars['String']>;
  readonly dunsNumber?: InputMaybe<Scalars['String']>;
  readonly entityType?: InputMaybe<EntityTypeEnum>;
  readonly electronicTrackings?: InputMaybe<ReadonlyArray<CarrierElectronicTrackingInput>>;
  readonly electronicTrackingMethodId?: InputMaybe<Scalars['ID']>;
  readonly electronicTrackingTypeId?: InputMaybe<Scalars['ID']>;
  readonly externalStatus?: InputMaybe<StatusTypeEnum>;
  readonly geographies?: InputMaybe<ReadonlyArray<CarrierGeographyInputV2>>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly insurances?: InputMaybe<ReadonlyArray<CarrierInsuranceInputV2>>;
  readonly interactions?: InputMaybe<ReadonlyArray<InteractionInputV2>>;
  readonly invoiceDistributionMethodId?: InputMaybe<Scalars['ID']>;
  readonly isAutoTruckEnabled?: InputMaybe<Scalars['Boolean']>;
  readonly levelId?: InputMaybe<Scalars['ID']>;
  readonly levelName?: InputMaybe<LevelTypeEnum>;
  readonly mainContactId?: InputMaybe<Scalars['ID']>;
  readonly manualTrackingInformation?: InputMaybe<Scalars['String']>;
  readonly manualTrackingMethod?: InputMaybe<ManualTrackingMethodEnum>;
  readonly mcNumber?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly nonCertifiedReasons?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly operatingStatus?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly opportunityName?: InputMaybe<OpportunityTypeEnum>;
  readonly parentId?: InputMaybe<Scalars['ID']>;
  readonly paymentMethodId?: InputMaybe<Scalars['ID']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly roeStatus?: InputMaybe<Scalars['String']>;
  readonly roeStatusId?: InputMaybe<Scalars['ID']>;
  readonly remitToCarrierId?: InputMaybe<Scalars['ID']>;
  readonly remittanceAddressId?: InputMaybe<Scalars['ID']>;
  readonly remittanceEmail?: InputMaybe<Scalars['String']>;
  readonly remittanceGrouping?: InputMaybe<RemittanceGroupingEnum>;
  readonly reps?: InputMaybe<ReadonlyArray<RepInputV2>>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly revenovaId?: InputMaybe<Scalars['String']>;
  readonly safetyRating?: InputMaybe<SafetyRatingTypeEnum>;
  readonly scacNumber?: InputMaybe<Scalars['String']>;
  readonly setting?: InputMaybe<NestedCarrierSettingInputV2>;
  readonly status?: InputMaybe<StatusTypeEnum>;
  readonly tempRemittanceGrouping?: InputMaybe<TempRemittanceGroupingEnum>;
  readonly updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly standing?: InputMaybe<Scalars['Json']>;
  readonly carrierSalesProgressTypeId?: InputMaybe<Scalars['ID']>;
  /** Use w9_certified_nullable instead. */
  readonly w9Certified?: InputMaybe<Scalars['Boolean']>;
  readonly w9CertifiedNullable?: InputMaybe<Scalars['Boolean']>;
  readonly binQualified?: InputMaybe<Scalars['Boolean']>;
  readonly website?: InputMaybe<Scalars['String']>;
};

export type CarrierInsurance = {
  readonly __typename?: 'CarrierInsurance';
  readonly cancelDate?: Maybe<Scalars['Iso8601Date']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly carrierInsuranceType?: Maybe<KeyValue>;
  readonly carrierInsuranceTypeId?: Maybe<Scalars['ID']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly effectiveDate?: Maybe<Scalars['Iso8601Date']>;
  readonly exemptions?: Maybe<Scalars['String']>;
  readonly expirationDate: Scalars['Iso8601Date'];
  readonly id: Scalars['ID'];
  readonly identification: Scalars['String'];
  /** @deprecated use carrierInsuranceTypeId or carrierInsuranceType */
  readonly insuranceType?: Maybe<CarrierInsuranceTypeEnum>;
  readonly insurer: Scalars['String'];
  readonly limit: Scalars['Int'];
  readonly producer: Scalars['String'];
  readonly rmisCovgId?: Maybe<Scalars['Int']>;
  readonly rmisDocumentId?: Maybe<Scalars['String']>;
  readonly underwriterRating?: Maybe<UnderwriterRatingTypeEnum>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
};

export type CarrierInsuranceInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly cancelDate?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly carrierInsuranceTypeId?: InputMaybe<Scalars['ID']>;
  readonly effectiveDate?: InputMaybe<Scalars['String']>;
  readonly exemptions?: InputMaybe<Scalars['String']>;
  readonly expirationDate: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly identification: Scalars['String'];
  readonly insuranceType?: InputMaybe<CarrierInsuranceTypeEnum>;
  readonly insurer: Scalars['String'];
  readonly limit: Scalars['Int'];
  readonly producer: Scalars['String'];
  readonly rmisCovgId?: InputMaybe<Scalars['String']>;
  readonly underwriterRating?: InputMaybe<UnderwriterRatingTypeEnum>;
};

export type CarrierInsuranceInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly carrierInsuranceTypeId?: InputMaybe<Scalars['ID']>;
  readonly insuranceType?: InputMaybe<CarrierInsuranceTypeEnum>;
  readonly producer: Scalars['String'];
  readonly insurer: Scalars['String'];
  readonly identification: Scalars['String'];
  readonly limit: Scalars['Int'];
  readonly expirationDate: Scalars['String'];
  readonly effectiveDate?: InputMaybe<Scalars['String']>;
  readonly cancelDate?: InputMaybe<Scalars['String']>;
  readonly rmisCovgId?: InputMaybe<Scalars['String']>;
  readonly exemptions?: InputMaybe<Scalars['String']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly underwriterRating?: InputMaybe<UnderwriterRatingTypeEnum>;
};

export type CarrierInsuranceRiskInformationInput = {
  readonly amount: Scalars['Int'];
  readonly cancelDate?: InputMaybe<Scalars['String']>;
  readonly effectiveDate?: InputMaybe<Scalars['String']>;
  readonly exemptions?: InputMaybe<Scalars['String']>;
  readonly expirationDate: Scalars['String'];
  readonly insuranceType?: InputMaybe<CarrierInsuranceTypeEnum>;
  readonly insurer: Scalars['String'];
  readonly policyNumber?: InputMaybe<Scalars['String']>;
  readonly producer: Scalars['String'];
  readonly rmisCovgId: Scalars['String'];
  readonly rmisDocumentId?: InputMaybe<Scalars['String']>;
  readonly underwriterRating?: InputMaybe<UnderwriterRatingTypeEnum>;
};

export enum CarrierInsuranceTypeEnum {
  /** Cargo */
  Cargo = 'Cargo',
  /** Liability */
  Liability = 'Liability',
  /** General */
  General = 'General',
  /** Other */
  Other = 'Other',
  /** BIPD */
  Bipd = 'BIPD',
  /** Auto */
  Auto = 'Auto'
}

export type CarrierInsuranceV2 = {
  readonly __typename?: 'CarrierInsuranceV2';
  readonly cancelDate?: Maybe<Scalars['Iso8601Date']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly carrierInsuranceType?: Maybe<KeyValue>;
  readonly carrierInsuranceTypeId?: Maybe<Scalars['ID']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly effectiveDate?: Maybe<Scalars['Iso8601Date']>;
  readonly exemptions?: Maybe<Scalars['String']>;
  readonly expirationDate: Scalars['Iso8601Date'];
  readonly id: Scalars['ID'];
  readonly identification: Scalars['String'];
  /** @deprecated use carrierInsuranceTypeId or carrierInsuranceType */
  readonly insuranceType?: Maybe<CarrierInsuranceTypeEnum>;
  readonly insurer: Scalars['String'];
  readonly limit: Scalars['Int'];
  readonly producer: Scalars['String'];
  readonly rmisCovgId?: Maybe<Scalars['Int']>;
  readonly rmisDocumentId?: Maybe<Scalars['String']>;
  readonly underwriterRating?: Maybe<UnderwriterRatingTypeEnum>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type CarrierInteraction = {
  readonly __typename?: 'CarrierInteraction';
  readonly CarrierV2?: Maybe<CarrierV2>;
  readonly contactId?: Maybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly interactionDate: Scalars['DateTime'];
  readonly interactionOutcomeTypeId?: Maybe<Scalars['ID']>;
  readonly interactionTypeId?: Maybe<Scalars['ID']>;
  readonly interactionViaTypeId?: Maybe<Scalars['ID']>;
  readonly nextInteractionDate?: Maybe<Scalars['DateTime']>;
  readonly NextInteractionRep?: Maybe<Employee>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly opportunityId?: Maybe<Scalars['ID']>;
  readonly opportunityName?: Maybe<Scalars['String']>;
  readonly Rep?: Maybe<Employee>;
  readonly vendor?: Maybe<Carrier>;
};

/** A connection to a list of items. */
export type CarrierInteractionConnection = {
  readonly __typename?: 'CarrierInteractionConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CarrierInteractionEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CarrierInteraction>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CarrierInteractionEdge = {
  readonly __typename?: 'CarrierInteractionEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CarrierInteraction>;
};

export type CarrierInteractionFilter = {
  readonly vendorId?: InputMaybe<Scalars['ID']>;
};

export enum CarrierInteractionFrequencyEnum {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Biweekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  Bimonthly = 'BIMONTHLY'
}

export type CarrierIntrastateInput = {
  readonly carrier: CarrierArgumentInput;
  readonly intrastateStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type CarrierIntrastateInputV2 = {
  readonly carrier: CarrierArgumentInputV2;
  readonly intrastateStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type CarrierNote = {
  readonly __typename?: 'CarrierNote';
  readonly carrierId: Scalars['ID'];
  readonly createdAt: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly noteType: CarrierNoteTypeEnum;
  readonly text?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['Iso8601Date'];
};

export type CarrierNoteInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly noteType?: InputMaybe<CarrierNoteTypeEnum>;
  readonly text?: InputMaybe<Scalars['String']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
};

export enum CarrierNoteTypeEnum {
  /** Carrier Accounting */
  CarrierAccounting = 'carrierAccounting',
  /** Carrier Confirmation */
  CarrierConfirmation = 'carrierConfirmation'
}

export type CarrierPortfolio = {
  readonly __typename?: 'CarrierPortfolio';
  readonly edges?: Maybe<ReadonlyArray<Maybe<CarrierPortfolioEdge>>>;
  readonly pageInfo?: Maybe<PortfolioPageInfo>;
};

export type CarrierPortfolioEdge = {
  readonly __typename?: 'CarrierPortfolioEdge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<CarrierPortfolioEntity>;
};

export type CarrierPortfolioEntity = {
  readonly __typename?: 'carrierPortfolioEntity';
  readonly carrier?: Maybe<Carrier>;
  readonly CarrierV2?: Maybe<CarrierV2>;
  readonly lastInteraction?: Maybe<CarrierPortfolioInteraction>;
};

export type CarrierPortfolioInputType = {
  readonly filter?: InputMaybe<CarriersWithInteractionFilterInputType>;
};

export type CarrierPortfolioInteraction = {
  readonly __typename?: 'CarrierPortfolioInteraction';
  readonly carrierInteractionFrequency?: Maybe<Scalars['ID']>;
  readonly contactId?: Maybe<Scalars['ID']>;
  readonly interactionDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Enum values are not used anymore usnig KVT values. New field of string type 'CarrierInteractionFrequency' is added  */
  readonly interactionFrequency?: Maybe<CarrierInteractionFrequencyEnum>;
  readonly nextInteractionDate?: Maybe<Scalars['DateTime']>;
  readonly notes?: Maybe<Scalars['String']>;
};

export type CarrierQuoteInputType = {
  readonly costQuoteId?: InputMaybe<Scalars['ID']>;
  readonly quoteTypeId: Scalars['String'];
  readonly createdById?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly effectiveDateTime?: InputMaybe<Scalars['DateTimeOffset']>;
  readonly minimum?: InputMaybe<Scalars['Decimal']>;
  readonly expirationDateTime?: InputMaybe<Scalars['DateTimeOffset']>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly scac?: InputMaybe<Scalars['String']>;
  readonly multipleStops?: InputMaybe<Scalars['Boolean']>;
  readonly team?: InputMaybe<Scalars['Boolean']>;
  readonly quotedById?: InputMaybe<Scalars['ID']>;
  readonly minHeight?: InputMaybe<CostQuoteUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<CostQuoteUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<CostQuoteUnitOfDistanceInput>;
  readonly lineItems?: InputMaybe<ReadonlyArray<InputMaybe<CostQuoteLineItemInput>>>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly services?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<CostQuoteLaneStopsInput>>>;
  readonly stopsV2?: InputMaybe<ReadonlyArray<InputMaybe<CostQuoteLaneStopsInputV2>>>;
  readonly transportMode?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
};

export type CarrierQuoteResponse = {
  readonly __typename?: 'CarrierQuoteResponse';
  readonly id: Scalars['ID'];
  readonly laneId?: Maybe<Scalars['ID']>;
  readonly allTotalRate?: Maybe<CurrencyValue>;
  readonly allTotalRateV2?: Maybe<CurrencyOutputValue>;
  readonly code?: Maybe<Scalars['Int']>;
  readonly createdAtDateTime?: Maybe<Scalars['DateTime']>;
  readonly createdById?: Maybe<Scalars['ID']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly effectiveDateTime?: Maybe<Scalars['DateTimeOffset']>;
  readonly expirationDateTime?: Maybe<Scalars['DateTimeOffset']>;
  readonly extrasTotalRate?: Maybe<CurrencyValue>;
  readonly extrasTotalRateV2?: Maybe<CurrencyOutputValue>;
  readonly fuelTotalRate?: Maybe<CurrencyValue>;
  readonly fuelTotalRateV2?: Maybe<CurrencyOutputValue>;
  readonly linehaulTotalRate?: Maybe<CurrencyValue>;
  readonly linehaulTotalRateV2?: Maybe<CurrencyOutputValue>;
  readonly multipleStops?: Maybe<Scalars['Boolean']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly quoteType: TenantConfiguration;
  readonly scac?: Maybe<TenantConfiguration>;
  readonly scacNumber?: Maybe<Scalars['String']>;
  readonly quotedById?: Maybe<Scalars['ID']>;
  readonly team?: Maybe<Scalars['Boolean']>;
  readonly minimum?: Maybe<Scalars['Decimal']>;
  readonly lineItems?: Maybe<ReadonlyArray<Maybe<CostQuoteLineItem>>>;
  readonly createdBy?: Maybe<User>;
  readonly quotedBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly quotedByV2?: Maybe<UserV2>;
  readonly lane?: Maybe<LaneV2>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly carrierCapacity?: Maybe<CarrierCapacity>;
};

export type CarrierReleaseMethod = {
  readonly __typename?: 'CarrierReleaseMethod';
  readonly id: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly releaseMethod?: Maybe<KeyValue>;
  readonly releaseMethodKey: Scalars['String'];
  readonly isReleaseNeeded?: Maybe<Scalars['Boolean']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type CarrierReleaseMethodInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly releaseMethodKey: Scalars['String'];
  readonly isReleaseNeeded?: InputMaybe<Scalars['Boolean']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
};

export type CarrierReleaseMethodInputV1 = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isReleaseNeeded?: InputMaybe<Scalars['Boolean']>;
  readonly releaseMethodKey: Scalars['String'];
};

export type CarrierReleaseMethodV1 = {
  readonly __typename?: 'CarrierReleaseMethodV1';
  readonly carrierId: Scalars['ID'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly isReleaseNeeded?: Maybe<Scalars['Boolean']>;
  readonly releaseMethod?: Maybe<KeyValue>;
  readonly releaseMethodKey: Scalars['String'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type CarrierRepAudit = AuditTypeInterface & {
  readonly __typename?: 'CarrierRepAudit';
  readonly actionType: Scalars['String'];
  readonly auditType: Scalars['String'];
  readonly auditableId: Scalars['ID'];
  readonly auditableRep?: Maybe<Rep>;
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByEmployee?: Maybe<Employee>;
  readonly currentValue?: Maybe<CarrierRepAuditedChanges>;
  readonly id: Scalars['ID'];
  readonly previousValue?: Maybe<CarrierRepAuditedChanges>;
};

export type CarrierRepAuditedChanges = {
  readonly __typename?: 'CarrierRepAuditedChanges';
  readonly employeeId?: Maybe<Scalars['ID']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly main?: Maybe<Scalars['Boolean']>;
  readonly repType?: Maybe<RepTypeEnum>;
  readonly active?: Maybe<Scalars['Boolean']>;
};

export type CarrierRepAuditV2 = AuditTypeInterfaceV2 & {
  readonly __typename?: 'CarrierRepAuditV2';
  readonly actionType: Scalars['String'];
  readonly auditType: Scalars['String'];
  readonly auditableId: Scalars['ID'];
  readonly auditableRep?: Maybe<RepV2>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByEmployee?: Maybe<EmployeeV2>;
  readonly currentValue?: Maybe<CarrierRepAuditedChanges>;
  readonly id: Scalars['ID'];
  readonly previousValue?: Maybe<CarrierRepAuditedChanges>;
  readonly userId?: Maybe<Scalars['ID']>;
};

export enum CarrierRepOrder {
  Default = 'DEFAULT'
}

export enum CarrierRepOrderV2 {
  Default = 'DEFAULT'
}

export type CarrierRiskInformationInput = {
  readonly dotNumber?: InputMaybe<Scalars['String']>;
  readonly externalStatus?: InputMaybe<StatusTypeEnum>;
  readonly insurances?: InputMaybe<ReadonlyArray<CarrierInsuranceRiskInformationInput>>;
  readonly isCertified?: InputMaybe<Scalars['Boolean']>;
  readonly mcNumber?: InputMaybe<Scalars['String']>;
  readonly nonCertifiedReasons?: InputMaybe<Scalars['String']>;
  readonly operatingStatus?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly safetyRating?: InputMaybe<SafetyRatingTypeEnum>;
  readonly standing?: InputMaybe<Scalars['Json']>;
};

export type CarrierService = {
  readonly __typename?: 'CarrierService';
  readonly carrierId: Scalars['ID'];
  readonly carrierServiceType: KeyValue;
  readonly carrierServiceTypeId: Scalars['ID'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly id: Scalars['ID'];
  readonly note?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
};

export type CarrierServiceInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly carrierServiceTypeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly note?: InputMaybe<Scalars['String']>;
};

export type CarrierServiceInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly carrierServiceTypeId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
};

export type CarrierServiceQueryInput = {
  readonly carrierServiceTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type CarrierServiceQueryInputV2 = {
  readonly carrierServiceTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type CarrierServiceStandaloneInput = {
  readonly carrier: CarrierArgumentInput;
  readonly carrierServiceTypeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly note?: InputMaybe<Scalars['String']>;
};

export type CarrierServiceStandaloneInputV2 = {
  readonly carrier: CarrierArgumentInputV2;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierServiceTypeId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
};

export type CarrierServiceV2 = {
  readonly __typename?: 'CarrierServiceV2';
  readonly carrierId: Scalars['ID'];
  readonly carrierServiceType: KeyValue;
  readonly carrierServiceTypeId: Scalars['ID'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly note?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type CarrierSetting = {
  readonly __typename?: 'CarrierSetting';
  readonly carrierId: Scalars['ID'];
  readonly defaultDestinationDeadhead: UnitOfDistance;
  readonly defaultDestinationGeographyId?: Maybe<Scalars['ID']>;
  readonly defaultDestinationSourceId?: Maybe<Scalars['Int']>;
  readonly defaultDestinationText?: Maybe<Scalars['String']>;
  readonly defaultOriginDeadhead: UnitOfDistance;
  readonly defaultTrailerHeight?: Maybe<Scalars['Float']>;
  readonly defaultTrailerHeightUnit: Scalars['String'];
  readonly defaultTrailerLength?: Maybe<Scalars['Float']>;
  readonly defaultTrailerLengthUnit: Scalars['String'];
  readonly defaultTrailerType?: Maybe<KeyValue>;
  readonly defaultTrailerTypeId?: Maybe<Scalars['ID']>;
  readonly defaultTrailerWidth?: Maybe<Scalars['Float']>;
  readonly defaultTrailerWidthUnit: Scalars['String'];
  /** @deprecated Transitioned to default_trailer_type */
  readonly defaultTruckType?: Maybe<CarrierTruckTypeEnum>;
  /** @deprecated Moved to certifications can also use carrier hazmat */
  readonly hazmat: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly intrastate: Scalars['Boolean'];
};

export type CarrierSettingInput = {
  readonly carrierId: Scalars['ID'];
  readonly defaultDestinationDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly defaultDestinationGeographyId?: InputMaybe<Scalars['ID']>;
  readonly defaultDestinationGeographySource?: InputMaybe<Scalars['String']>;
  readonly defaultDestinationGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly defaultDestinationText?: InputMaybe<Scalars['String']>;
  readonly defaultOriginDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly defaultTrailerHeight?: InputMaybe<Scalars['Float']>;
  readonly defaultTrailerHeightUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTrailerLength?: InputMaybe<Scalars['Float']>;
  readonly defaultTrailerLengthUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTrailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly defaultTrailerWidth?: InputMaybe<Scalars['Float']>;
  readonly defaultTrailerWidthUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTruckType?: InputMaybe<CarrierTruckTypeEnum>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly intrastate?: InputMaybe<Scalars['Boolean']>;
};

export type CarrierSettingInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId: Scalars['ID'];
  readonly defaultTrailerLength?: InputMaybe<Scalars['Float']>;
  readonly defaultTrailerWidth?: InputMaybe<Scalars['Float']>;
  readonly defaultTrailerHeight?: InputMaybe<Scalars['Float']>;
  readonly defaultDestinationText?: InputMaybe<Scalars['String']>;
  readonly defaultDestinationGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly defaultDestinationGeographySource?: InputMaybe<Scalars['String']>;
  readonly defaultDestinationGeographyId?: InputMaybe<Scalars['ID']>;
  readonly defaultTrailerLengthUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTrailerWidthUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTrailerHeightUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTrailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly defaultOriginDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly defaultDestinationDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly intrastate?: InputMaybe<Scalars['Boolean']>;
  readonly defaultTruckType?: InputMaybe<CarrierTruckTypeEnum>;
};

export type CarrierSettingV2 = {
  readonly __typename?: 'CarrierSettingV2';
  readonly carrierId: Scalars['ID'];
  readonly defaultDestinationDeadhead: UnitOfDistance;
  readonly defaultDestinationGeographyId?: Maybe<Scalars['ID']>;
  readonly defaultDestinationSourceId?: Maybe<Scalars['Int']>;
  readonly defaultDestinationText?: Maybe<Scalars['String']>;
  readonly defaultOriginDeadhead: UnitOfDistance;
  readonly defaultTrailerHeight?: Maybe<Scalars['Float']>;
  readonly defaultTrailerHeightUnit: Scalars['String'];
  readonly defaultTrailerLength?: Maybe<Scalars['Float']>;
  readonly defaultTrailerLengthUnit: Scalars['String'];
  readonly defaultTrailerType?: Maybe<KeyValue>;
  readonly defaultTrailerTypeId?: Maybe<Scalars['ID']>;
  readonly defaultTrailerWidth?: Maybe<Scalars['Float']>;
  readonly defaultTrailerWidthUnit: Scalars['String'];
  /** @deprecated Transitioned to default_trailer_type */
  readonly defaultTruckType?: Maybe<CarrierTruckTypeEnum>;
  /** @deprecated Moved to certifications can also use carrier hazmat */
  readonly hazmat: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly intrastate: Scalars['Boolean'];
};

export type CarriersFilter = {
  readonly address?: InputMaybe<AddressesFilter>;
  readonly carrierCertification?: InputMaybe<CarrierCertificationQueryInput>;
  readonly carrierIdentifier?: InputMaybe<IdentifierQueryInput>;
  readonly carrierService?: InputMaybe<CarrierServiceQueryInput>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly dotNumber?: InputMaybe<Scalars['String']>;
  readonly employeeId?: InputMaybe<Scalars['ID']>;
  readonly entityType?: InputMaybe<EntityTypeEnum>;
  readonly exactDotNumber?: InputMaybe<Scalars['String']>;
  readonly exactMcNumber?: InputMaybe<Scalars['String']>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly levelId?: InputMaybe<Scalars['ID']>;
  readonly mcDotNumber?: InputMaybe<Scalars['String']>;
  readonly mcNumber?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly opportunity?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly revenovaId?: InputMaybe<Scalars['String']>;
  readonly scacNumber?: InputMaybe<Scalars['String']>;
  readonly sort?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<StatusTypeEnum>;
  readonly text?: InputMaybe<Scalars['String']>;
};

export type CarriersFilterV2 = {
  readonly address?: InputMaybe<CarrierAddressesFilter>;
  readonly carrierIdentifier?: InputMaybe<IdentifierQueryInputV2>;
  readonly carrierService?: InputMaybe<CarrierServiceQueryInputV2>;
  readonly carrierTypeId?: InputMaybe<Scalars['ID']>;
  readonly carrierCertification?: InputMaybe<CarrierCertificationQueryInputV2>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly dotNumber?: InputMaybe<Scalars['String']>;
  readonly employeeId?: InputMaybe<Scalars['ID']>;
  readonly levelId?: InputMaybe<Scalars['ID']>;
  readonly mcDotNumber?: InputMaybe<Scalars['String']>;
  readonly mcNumber?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly opportunity?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly revenovaId?: InputMaybe<Scalars['String']>;
  readonly scacNumber?: InputMaybe<Scalars['String']>;
  readonly sort?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<StatusTypeEnum>;
  readonly entityType?: InputMaybe<EntityTypeEnum>;
  readonly text?: InputMaybe<Scalars['String']>;
  readonly exactMcNumber?: InputMaybe<Scalars['String']>;
  readonly exactDotNumber?: InputMaybe<Scalars['String']>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
};

export type CarrierStanding = {
  readonly __typename?: 'CarrierStanding';
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<User>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly reasonCodeId?: Maybe<Scalars['String']>;
  readonly statusTypeId?: Maybe<Scalars['String']>;
  readonly typeId?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<User>;
};

export type CarrierStandingInput = {
  readonly carrier: CarrierArgumentInput;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly reasonCodeId?: InputMaybe<Scalars['String']>;
  readonly statusTypeId?: InputMaybe<Scalars['String']>;
  readonly typeId?: InputMaybe<Scalars['String']>;
};

export type CarrierStandingInputV2 = {
  readonly carrier: CarrierArgumentInputV2;
  readonly typeId?: InputMaybe<Scalars['String']>;
  readonly statusTypeId?: InputMaybe<Scalars['String']>;
  readonly reasonCodeId?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type CarrierStandingV2 = {
  readonly __typename?: 'CarrierStandingV2';
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly reasonCodeId?: Maybe<Scalars['String']>;
  readonly statusTypeId?: Maybe<Scalars['String']>;
  readonly typeId?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

export type CarriersWithInteractionFilterInputType = {
  readonly repId: Scalars['ID'];
};

export type CarrierTruck = {
  readonly __typename?: 'CarrierTruck';
  readonly height?: Maybe<Scalars['Float']>;
  readonly heightUnit?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly length: Scalars['Float'];
  readonly lengthUnit: Scalars['String'];
  readonly quantity: Scalars['Int'];
  readonly truckType: CarrierTruckTypeEnum;
  readonly width?: Maybe<Scalars['Float']>;
  readonly widthUnit?: Maybe<Scalars['String']>;
};

export enum CarrierTruckTypeEnum {
  /** bulk_liquid */
  BulkLiquid = 'bulkLiquid',
  /** chassis */
  Chassis = 'chassis',
  /** flatbed */
  Flatbed = 'flatbed',
  /** none_defined */
  NoneDefined = 'noneDefined',
  /** other_specialized_equipment */
  OtherSpecializedEquipment = 'otherSpecializedEquipment',
  /** power_only */
  PowerOnly = 'powerOnly',
  /** reefer */
  Reefer = 'reefer',
  /** tcu */
  Tcu = 'tcu',
  /** van */
  Van = 'van',
  /** container */
  Container = 'container',
  /** high_cube_container */
  HighCubeContainer = 'highCubeContainer',
  /** flat_rack */
  FlatRack = 'flatRack',
  /** adjustable_chassis */
  AdjustableChassis = 'adjustableChassis',
  /** autorack */
  Autorack = 'autorack',
  /** boxcar */
  Boxcar = 'boxcar',
  /** centerbeam */
  Centerbeam = 'centerbeam',
  /** coil_car */
  CoilCar = 'coilCar',
  /** conestoga */
  Conestoga = 'conestoga',
  /** covered_hopper */
  CoveredHopper = 'coveredHopper',
  /** double_drop */
  DoubleDrop = 'doubleDrop',
  /** flat_car */
  FlatCar = 'flatCar',
  /** high_cube_reefer_container */
  HighCubeReeferContainer = 'highCubeReeferContainer',
  /** insulated_container */
  InsulatedContainer = 'insulatedContainer',
  /** open_top_container */
  OpenTopContainer = 'openTopContainer',
  /** open_top_hopper */
  OpenTopHopper = 'openTopHopper',
  /** reefer_container */
  ReeferContainer = 'reeferContainer',
  /** step_deck */
  StepDeck = 'stepDeck',
  /** tank_car */
  TankCar = 'tankCar',
  /** tank_container */
  TankContainer = 'tankContainer',
  /** well_car */
  WellCar = 'wellCar',
  /** refrigerated_boxcar */
  RefrigeratedBoxcar = 'refrigeratedBoxcar',
  /** rgn */
  Rgn = 'rgn',
  /** cargo_van */
  CargoVan = 'cargoVan',
  /** heated_van */
  HeatedVan = 'heatedVan',
  /** insulated_van */
  InsulatedVan = 'insulatedVan',
  /** pup_trailer */
  PupTrailer = 'pupTrailer',
  /** straight_truck */
  StraightTruck = 'straightTruck',
  /** tri_axle_vans */
  TriAxleVans = 'triAxleVans',
  /** van_open_top */
  VanOpenTop = 'vanOpenTop',
  /** walking_floor */
  WalkingFloor = 'walkingFloor',
  /** curtainside_dry_van */
  CurtainsideDryVan = 'curtainsideDryVan',
  /** e_track_dry_van */
  ETrackDryVan = 'eTrackDryVan',
  /** smoothside_dry_van */
  SmoothsideDryVan = 'smoothsideDryVan',
  /** plated_dry_van */
  PlatedDryVan = 'platedDryVan',
  /** van_with_curtains */
  VanWithCurtains = 'vanWithCurtains',
  /** van_with_liftgate */
  VanWithLiftgate = 'vanWithLiftgate',
  /** vented_van */
  VentedVan = 'ventedVan',
  /** boat_trailer */
  BoatTrailer = 'boatTrailer',
  /** end_dump */
  EndDump = 'endDump',
  /** flatbed_conestoga */
  FlatbedConestoga = 'flatbedConestoga',
  /** step_deck_conestoga */
  StepDeckConestoga = 'stepDeckConestoga',
  /** hopper_bottom */
  HopperBottom = 'hopperBottom',
  /** hot_shot_trailer */
  HotShotTrailer = 'hotShotTrailer',
  /** quad_axle */
  QuadAxle = 'quadAxle',
  /** dump_truck */
  DumpTruck = 'dumpTruck',
  /** b_train_van */
  BTrainVan = 'bTrainVan',
  /** b_train_end_dump */
  BTrainEndDump = 'bTrainEndDump',
  /** side_dump */
  SideDump = 'sideDump',
  /** belt_trailer */
  BeltTrailer = 'beltTrailer',
  /** sprinter_van */
  SprinterVan = 'sprinterVan',
  /** box_truck */
  BoxTruck = 'boxTruck',
  /** aluminum_tanker */
  AluminumTanker = 'aluminumTanker',
  /** belly_dump */
  BellyDump = 'bellyDump',
  /** gondola */
  Gondola = 'gondola',
  /** maxi */
  Maxi = 'maxi',
  /** pneumatic */
  Pneumatic = 'pneumatic',
  /** stainless_steel_tanker */
  StainlessSteelTanker = 'stainlessSteelTanker',
  /** tanker */
  Tanker = 'tanker',
  /** b_train_flatbed */
  BTrainFlatbed = 'bTrainFlatbed',
  /** double_drop_extendable */
  DoubleDropExtendable = 'doubleDropExtendable',
  /** flatbed_with_forklift */
  FlatbedWithForklift = 'flatbedWithForklift',
  /** flatbed_with_sides */
  FlatbedWithSides = 'flatbedWithSides',
  /** rgn_extendable */
  RgnExtendable = 'rgnExtendable',
  /** roll_top_conestoga */
  RollTopConestoga = 'rollTopConestoga',
  /** step_deck_extendable */
  StepDeckExtendable = 'stepDeckExtendable',
  /** step_deck_low */
  StepDeckLow = 'stepDeckLow',
  /** step_deck_with_loading_ramps */
  StepDeckWithLoadingRamps = 'stepDeckWithLoadingRamps',
  /** six_to_seven_axle_rgn_heavy_haul_trailer */
  SixToSevenAxleRgnHeavyHaulTrailer = 'sixToSevenAxleRgnHeavyHaulTrailer',
  /** seven_to_eight_axle_rgn_heavy_haul_trailer */
  SevenToEightAxleRgnHeavyHaulTrailer = 'sevenToEightAxleRgnHeavyHaulTrailer',
  /** nine_axle_plus_rgn_heavy_haul_trailer */
  NineAxlePlusRgnHeavyHaulTrailer = 'nineAxlePlusRgnHeavyHaulTrailer',
  /** beam */
  Beam = 'beam',
  /** lowboy */
  Lowboy = 'lowboy',
  /** perimeter */
  Perimeter = 'perimeter',
  /** stretch_open_deck */
  StretchOpenDeck = 'stretchOpenDeck',
  /** stretch_trailer */
  StretchTrailer = 'stretchTrailer',
  /** auto_hauler */
  AutoHauler = 'autoHauler',
  /** dump_trailer */
  DumpTrailer = 'dumpTrailer',
  /** garment_van */
  GarmentVan = 'garmentVan',
  /** livestock_trailer */
  LivestockTrailer = 'livestockTrailer',
  /** vacuum_or_pump */
  VacuumOrPump = 'vacuumOrPump',
  /** wheel_well */
  WheelWell = 'wheelWell'
}

export type CarrierTruckV2 = {
  readonly __typename?: 'CarrierTruckV2';
  readonly height?: Maybe<Scalars['Float']>;
  readonly heightUnit?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly length: Scalars['Float'];
  readonly lengthUnit: Scalars['String'];
  readonly quantity: Scalars['Int'];
  readonly truckType: CarrierTruckTypeEnum;
  readonly width?: Maybe<Scalars['Float']>;
  readonly widthUnit?: Maybe<Scalars['String']>;
};

export type CarrierV2 = {
  readonly __typename?: 'CarrierV2';
  readonly accountingNotes?: Maybe<ReadonlyArray<CarrierNote>>;
  readonly addresses?: Maybe<ReadonlyArray<CarrierAddress>>;
  readonly allNotes?: Maybe<ReadonlyArray<CarrierNote>>;
  readonly ancestorIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly audits: ReadonlyArray<AuditTypeUnionV2>;
  readonly binQualified?: Maybe<Scalars['Boolean']>;
  readonly carrierAuditsExternalStatus?: Maybe<ReadonlyArray<CarrierAuditsExternalStatusV2>>;
  readonly carrierIdentifiers?: Maybe<ReadonlyArray<CarrierIdentifierV2>>;
  readonly carrierPaymentTerm?: Maybe<KeyValue>;
  readonly carrierPaymentTermId?: Maybe<Scalars['ID']>;
  readonly carrierSalesProgressType?: Maybe<KeyValue>;
  readonly carrierSalesProgressTypeId?: Maybe<Scalars['ID']>;
  readonly carrierServices?: Maybe<ReadonlyArray<CarrierServiceV2>>;
  readonly carrierType?: Maybe<KeyValue>;
  readonly carrierTypeId?: Maybe<Scalars['ID']>;
  readonly certifications?: Maybe<ReadonlyArray<CarrierCertificationV2>>;
  readonly children?: Maybe<ReadonlyArray<CarrierV2>>;
  readonly code: Scalars['String'];
  readonly complianceEmail?: Maybe<Scalars['String']>;
  readonly confirmationNotes?: Maybe<ReadonlyArray<CarrierNote>>;
  readonly contacts?: Maybe<ReadonlyArray<CarrierContact>>;
  readonly allContacts?: Maybe<ReadonlyArray<CarrierContact>>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<KeyValue>;
  readonly currencyId?: Maybe<Scalars['ID']>;
  readonly customerRelationships?: Maybe<ReadonlyArray<CarrierCustomerRelationshipV2>>;
  readonly dbaName?: Maybe<Scalars['String']>;
  readonly descendantIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly dotNumber?: Maybe<Scalars['String']>;
  readonly dunsNumber?: Maybe<Scalars['String']>;
  readonly electronicTrackings?: Maybe<ReadonlyArray<CarrierElectronicTracking>>;
  /** @deprecated Use electronicTrackings instead */
  readonly electronicTrackingMethod?: Maybe<KeyValue>;
  /** @deprecated Use electronicTrackings instead */
  readonly electronicTrackingMethodId?: Maybe<Scalars['ID']>;
  /** @deprecated Use electronicTrackings instead */
  readonly electronicTrackingType?: Maybe<KeyValue>;
  /** @deprecated Use electronicTrackings instead */
  readonly electronicTrackingTypeId?: Maybe<Scalars['ID']>;
  readonly entityType: EntityTypeEnum;
  /** @deprecated use `standing` field instead. */
  readonly externalStatus?: Maybe<StatusTypeEnum>;
  /** @deprecated Use `geographiesNullable` instead (`geographies` is not under access control) */
  readonly geographies: ReadonlyArray<CarrierGeographyV2>;
  readonly geographiesNullable?: Maybe<ReadonlyArray<CarrierGeographyV2>>;
  readonly groups?: Maybe<ReadonlyArray<CarrierGroup>>;
  readonly hazmat?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  readonly insurances?: Maybe<ReadonlyArray<CarrierInsuranceV2>>;
  readonly interactions?: Maybe<ReadonlyArray<InteractionV2>>;
  readonly intrastatePresent?: Maybe<Scalars['Boolean']>;
  readonly intrastateStates?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly invoiceDistributionMethod?: Maybe<KeyValue>;
  readonly invoiceDistributionMethodId?: Maybe<Scalars['ID']>;
  readonly isAutoTruckEnabled: Scalars['Boolean'];
  /** @deprecated Use carrierSettings instead */
  readonly level?: Maybe<KeyValue>;
  readonly levelId?: Maybe<Scalars['ID']>;
  /** @deprecated Use level */
  readonly levelName?: Maybe<LevelTypeEnum>;
  readonly mainAddress?: Maybe<CarrierAddress>;
  readonly mainAddressId?: Maybe<Scalars['String']>;
  readonly mainContact?: Maybe<CarrierContact>;
  readonly mainContactId?: Maybe<Scalars['String']>;
  readonly manualTrackingInformation?: Maybe<Scalars['String']>;
  readonly manualTrackingMethod?: Maybe<ManualTrackingMethodEnum>;
  readonly mcNumber?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly nonCertifiedReasons?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly operatingStatus?: Maybe<Scalars['String']>;
  /** @deprecated Use carrierSettings instead */
  readonly opportunity?: Maybe<KeyValue>;
  readonly opportunityId?: Maybe<Scalars['ID']>;
  /** @deprecated Use opportunity */
  readonly opportunityName?: Maybe<OpportunityTypeEnum>;
  readonly parentId?: Maybe<Scalars['ID']>;
  readonly parent?: Maybe<CarrierV2>;
  readonly parentRelationship?: Maybe<ReadonlyArray<ParentRelationship>>;
  readonly paymentMethod?: Maybe<KeyValue>;
  readonly paymentMethodId?: Maybe<Scalars['ID']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly roeStatus?: Maybe<Scalars['String']>;
  readonly releaseMethods?: Maybe<ReadonlyArray<CarrierReleaseMethod>>;
  readonly remitToCarrier?: Maybe<CarrierV2>;
  readonly remitToCarrierId?: Maybe<Scalars['ID']>;
  readonly remittanceAddress?: Maybe<CarrierAddress>;
  readonly remittanceAddressId?: Maybe<Scalars['String']>;
  readonly remittanceEmail?: Maybe<Scalars['String']>;
  /** @deprecated Use temp_remittance_grouping for now */
  readonly remittanceGrouping?: Maybe<RemittanceGroupingEnum>;
  readonly reps?: Maybe<ReadonlyArray<RepV2>>;
  readonly allReps?: Maybe<ReadonlyArray<RepV2>>;
  /** @deprecated will be removed soon */
  readonly revenovaId?: Maybe<Scalars['String']>;
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly safetyRating?: Maybe<SafetyRatingTypeEnum>;
  readonly scacNumber?: Maybe<Scalars['String']>;
  readonly setting?: Maybe<CarrierSettingV2>;
  readonly siblings?: Maybe<ReadonlyArray<CarrierV2>>;
  readonly standing?: Maybe<ReadonlyArray<CarrierStandingV2>>;
  readonly status?: Maybe<StatusTypeEnum>;
  readonly tempRemittanceGrouping?: Maybe<TempRemittanceGroupingEnum>;
  readonly tenderOptions?: Maybe<TenderOptions>;
  readonly trucks?: Maybe<ReadonlyArray<CarrierTruckV2>>;
  readonly truckEntry?: Maybe<TruckEntry>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  /** @deprecated Use w9_certified_nullable instead. */
  readonly w9Certified: Scalars['Boolean'];
  readonly w9CertifiedNullable?: Maybe<Scalars['Boolean']>;
  readonly website?: Maybe<Scalars['String']>;
  readonly carrierFacilities?: Maybe<ReadonlyArray<CarrierFacility>>;
  readonly accountingStatus?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Date']>;
  readonly eid?: Maybe<Scalars['Long']>;
  readonly hasPropertyMetadata: Scalars['Boolean'];
  readonly propertyMetadata?: Maybe<ReadonlyArray<KeyValuePairOfStringAndPropertyMetadata>>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['Date']>;
};


export type CarrierV2InteractionsArgs = {
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  interactionType?: InputMaybe<InteractionTypeEnum>;
};

export type Change = {
  readonly __typename?: 'Change';
  readonly type?: Maybe<Scalars['String']>;
  readonly key?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly oldValue?: Maybe<Scalars['String']>;
};

export type ChangeCreditCustomerOnOrderInput = {
  readonly orderId: Scalars['ID'];
  readonly newCustomerId: Scalars['ID'];
};

export type ChangeCreditCustomerOnOrderPayload = {
  readonly __typename?: 'ChangeCreditCustomerOnOrderPayload';
  readonly approved: Scalars['Boolean'];
  readonly message: Scalars['String'];
  readonly details?: Maybe<Scalars['String']>;
};

export type ChangeCustomerOrderInput = {
  readonly orderId: Scalars['ID'];
  readonly customerId: Scalars['ID'];
};

export type ChangeFacilityOnStopInput = {
  readonly loadId: Scalars['ID'];
  readonly oldFacilityId: Scalars['ID'];
  readonly newFacilityId: Scalars['ID'];
  readonly doKeepAppointment: Scalars['Boolean'];
};

export type ChangeFacilityOnStopPayload = {
  readonly __typename?: 'ChangeFacilityOnStopPayload';
  readonly errors: ReadonlyArray<RouteStopsPayloadError>;
  readonly changeFacilityOnStop?: Maybe<RouteStopsPayload>;
};

export type ChangeInput = {
  readonly type?: InputMaybe<Scalars['String']>;
  readonly key?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
  readonly oldValue?: InputMaybe<Scalars['String']>;
};

/** The GL configuration for a Charge Type */
export type ChargeType = {
  readonly __typename?: 'ChargeType';
  readonly chargeTypeId: Scalars['ID'];
  readonly code?: Maybe<Scalars['String']>;
  readonly customerGlCode?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly isCredit?: Maybe<Scalars['Boolean']>;
  readonly isEligibleForFuel?: Maybe<Scalars['Boolean']>;
  readonly vendorGlCode?: Maybe<Scalars['String']>;
};

/** The input type used for GL configuration of Charge Types. */
export type ChargeTypeModelInput = {
  readonly chargeTypeId: Scalars['ID'];
  readonly code?: InputMaybe<Scalars['String']>;
  readonly customerGlCode: Scalars['String'];
  readonly description?: InputMaybe<Scalars['String']>;
  readonly isCredit: Scalars['Boolean'];
  readonly vendorGlCode: Scalars['String'];
};

export type ChargeTypeV2 = {
  readonly __typename?: 'ChargeTypeV2';
  readonly id: Scalars['ID'];
  readonly carrierGlCode?: Maybe<Scalars['String']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly customerGlCode?: Maybe<Scalars['String']>;
  readonly eligibleForFuel: Scalars['Boolean'];
  readonly excludeFromMargins: Scalars['Boolean'];
  readonly isActive: Scalars['Boolean'];
  readonly code?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
};

export type CityStateStopInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly routeId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly address: CityStateStopLocationInput;
  readonly scheduleType?: InputMaybe<ScheduleAppointmentTypeEnum>;
  readonly liveType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly workType?: InputMaybe<ScheduleWorkTypeEnum>;
  readonly isOrderStop?: InputMaybe<Scalars['Boolean']>;
  readonly type?: InputMaybe<StopTypeEnum>;
  /**
   * value is validated against following options:
   *   crossDock, originRamp, destinationRamp, customsBroker, yard, PU, Del, TA, TD, domicile, maintenanceShop, scale, fuel, driverComplianceTraining, waypoint, railJunction, relay, terminal, seaPort, airport, containerDepot, borderCrossing, transload, trailerWash
   */
  readonly loadStopType?: InputMaybe<Scalars['String']>;
  readonly loadFromId?: InputMaybe<Scalars['ID']>;
  readonly unloadFromId?: InputMaybe<Scalars['ID']>;
  readonly requirements?: InputMaybe<ReadonlyArray<StopRequirementInput>>;
  readonly commodities?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly refs: ReadonlyArray<RefInputV2>;
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly reqDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly availableEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly bolNumber?: InputMaybe<Scalars['String']>;
  readonly sealNumber?: InputMaybe<Scalars['String']>;
  readonly apptConfBy?: InputMaybe<Scalars['String']>;
  readonly apptReqWith?: InputMaybe<Scalars['String']>;
  /** stop unique identifier for external system */
  readonly externalRef?: InputMaybe<Scalars['String']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  /** flag for stops created from FE */
  readonly isCreatedManually?: InputMaybe<Scalars['Boolean']>;
  readonly splc?: InputMaybe<Scalars['String']>;
};

export type CityStateStopLocationInput = {
  readonly geopoint?: InputMaybe<GeoPointInput>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly postalCode: Scalars['String'];
  /** countryAlpha2Code */
  readonly country: Scalars['String'];
};

export type ClientExceptionRecord = {
  readonly __typename?: 'ClientExceptionRecord';
  readonly id: Scalars['ID'];
  readonly type: ClientExceptionRecordTypeDetails;
  readonly status: ClientExceptionRecordStatusDetails;
  readonly sourceID: Scalars['String'];
  readonly sourceCode?: Maybe<Scalars['String']>;
  readonly sourceType: ClientExceptionRecordSourceType;
  readonly reasons: ReadonlyArray<ClientExceptionRecordReason>;
  readonly metadata: ReadonlyArray<ClientExceptionRecordMetadata>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly closedAt?: Maybe<Scalars['DateTime']>;
  readonly sourceNullable?: Maybe<ExceptionSourceUnion>;
  readonly metadataNullable?: Maybe<ReadonlyArray<ExceptionMetadataUnion>>;
  readonly updatedBy?: Maybe<EmployeeV2>;
};

export type ClientExceptionRecordConnection = {
  readonly __typename?: 'ClientExceptionRecordConnection';
  readonly edges: ReadonlyArray<ClientExceptionRecordEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type ClientExceptionRecordDefaultMetadata = {
  readonly __typename?: 'ClientExceptionRecordDefaultMetadata';
  readonly id: Scalars['ID'];
  readonly entityType: ClientExceptionRecordMetadataType;
  readonly entityIdentifier: Scalars['String'];
};

export type ClientExceptionRecordDefaultSource = {
  readonly __typename?: 'ClientExceptionRecordDefaultSource';
  readonly id: Scalars['ID'];
  readonly sourceType: ClientExceptionRecordSourceType;
  readonly sourceID: Scalars['String'];
  readonly sourceCode?: Maybe<Scalars['String']>;
};

export type ClientExceptionRecordEdge = {
  readonly __typename?: 'ClientExceptionRecordEdge';
  readonly node: ClientExceptionRecord;
  readonly cursor: Scalars['String'];
};

export type ClientExceptionRecordFilterInput = {
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly types?: InputMaybe<ReadonlyArray<ClientExceptionRecordType>>;
  readonly statuses?: InputMaybe<ReadonlyArray<ClientExceptionRecordStatus>>;
  readonly sources?: InputMaybe<ReadonlyArray<ClientExceptionRecordSourceType>>;
  readonly reasons?: InputMaybe<ClientExceptionRecordReasonFilterInput>;
  readonly metadata?: InputMaybe<ClientExceptionRecordMetadataFilterInput>;
  readonly createdAt?: InputMaybe<GetClientExceptionByDateRangeInput>;
  readonly updatedAt?: InputMaybe<GetClientExceptionByDateRangeInput>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly repID?: InputMaybe<Scalars['ID']>;
  readonly sourceCode?: InputMaybe<Scalars['String']>;
  readonly repIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly repGroupIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly closedAt?: InputMaybe<GetClientExceptionByDateRangeInput>;
  readonly pickUpDate?: InputMaybe<GetClientExceptionByDateRangeInput>;
  readonly deliveryDate?: InputMaybe<GetClientExceptionByDateRangeInput>;
};

export type ClientExceptionRecordMetadata = {
  readonly __typename?: 'ClientExceptionRecordMetadata';
  readonly id: Scalars['ID'];
  readonly entityType: ClientExceptionRecordMetadataType;
  readonly entityIdentifier: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type ClientExceptionRecordMetadataFilterInput = {
  readonly entityIdentifiers?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly entityTypes?: InputMaybe<ReadonlyArray<ClientExceptionRecordMetadataType>>;
};

export enum ClientExceptionRecordMetadataType {
  Load = 'LOAD',
  Customer = 'CUSTOMER',
  Order = 'ORDER',
  Route = 'ROUTE',
  UnmatchedChargesBillOfLading = 'UNMATCHED_CHARGES_BILL_OF_LADING',
  Rep = 'REP',
  RepGroup = 'REP_GROUP',
  RouteVendor = 'ROUTE_VENDOR',
  Vendor = 'VENDOR',
  Carrier = 'CARRIER'
}

export type ClientExceptionRecordReason = {
  readonly __typename?: 'ClientExceptionRecordReason';
  readonly id: Scalars['ID'];
  /** @deprecated changed to use DDTs */
  readonly code: ClientExceptionRecordReasonCodeDetails;
  readonly reasonCode: Scalars['String'];
  readonly status: ClientExceptionRecordReasonStatusDetails;
  readonly bypassed: Scalars['Boolean'];
  readonly bypassedReason?: Maybe<Scalars['String']>;
  readonly assignedToGroup?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly assignedBy?: Maybe<EmployeeV2>;
  readonly assignedTo?: Maybe<EmployeeV2>;
};

export enum ClientExceptionRecordReasonCode {
  MissingActualWeight = 'MISSING_ACTUAL_WEIGHT',
  MissingCommodityPieces = 'MISSING_COMMODITY_PIECES',
  MissingRate = 'MISSING_RATE',
  MissingOrderReference = 'MISSING_ORDER_REFERENCE',
  UnmatchedCharges = 'UNMATCHED_CHARGES',
  FuelSurcharge = 'FUEL_SURCHARGE',
  TrailerValidation = 'TRAILER_VALIDATION',
  VendorInvoiceTolerance = 'VENDOR_INVOICE_TOLERANCE',
  PaperworkRequired = 'PAPERWORK_REQUIRED',
  Incident = 'INCIDENT'
}

export type ClientExceptionRecordReasonCodeDetails = {
  readonly __typename?: 'ClientExceptionRecordReasonCodeDetails';
  readonly name: ClientExceptionRecordReasonCode;
  readonly description?: Maybe<Scalars['String']>;
};

export type ClientExceptionRecordReasonFilterInput = {
  readonly statuses?: InputMaybe<ReadonlyArray<ClientExceptionRecordReasonStatus>>;
  readonly codes?: InputMaybe<ReadonlyArray<ClientExceptionRecordReasonCode>>;
  readonly reasonCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly bypassed?: InputMaybe<Scalars['Boolean']>;
  readonly bypassedReasons?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedToIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedToGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedByIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export enum ClientExceptionRecordReasonStatus {
  Unresolved = 'UNRESOLVED',
  Resolved = 'RESOLVED',
  Bypassed = 'BYPASSED'
}

export type ClientExceptionRecordReasonStatusDetails = {
  readonly __typename?: 'ClientExceptionRecordReasonStatusDetails';
  readonly name: ClientExceptionRecordReasonStatus;
  readonly description?: Maybe<Scalars['String']>;
};

export enum ClientExceptionRecordSourceType {
  Order = 'ORDER',
  Route = 'ROUTE',
  Blume = 'BLUME',
  RouteVendor = 'ROUTE_VENDOR',
  Customer = 'CUSTOMER',
  Vendor = 'VENDOR',
  Carrier = 'CARRIER'
}

export enum ClientExceptionRecordStatus {
  Open = 'OPEN',
  Closed = 'CLOSED'
}

export type ClientExceptionRecordStatusDetails = {
  readonly __typename?: 'ClientExceptionRecordStatusDetails';
  readonly name: ClientExceptionRecordStatus;
  readonly description?: Maybe<Scalars['String']>;
};

export enum ClientExceptionRecordType {
  CommodityReq = 'COMMODITY_REQ',
  UnmatchedCharges = 'UNMATCHED_CHARGES',
  CustomerPaperwork = 'CUSTOMER_PAPERWORK',
  FinalizedFuel = 'FINALIZED_FUEL',
  VendorPaperwork = 'VENDOR_PAPERWORK',
  VendorInvoice = 'VENDOR_INVOICE',
  AccountingIncident = 'ACCOUNTING_INCIDENT',
  TrailerValidation = 'TRAILER_VALIDATION',
  MissingInvoice = 'MISSING_INVOICE',
  VendorInvoiceTolerance = 'VENDOR_INVOICE_TOLERANCE',
  MissingVendorInvoice = 'MISSING_VENDOR_INVOICE'
}

export type ClientExceptionRecordTypeDetails = {
  readonly __typename?: 'ClientExceptionRecordTypeDetails';
  readonly name: ClientExceptionRecordType;
  readonly description?: Maybe<Scalars['String']>;
};

export type CloneLoadInput = {
  readonly loadId: Scalars['ID'];
  readonly orderDetails: CloneLoadOrderInput;
  readonly routeDetails: ReadonlyArray<CloneLoadRouteInput>;
  readonly rateDetails: ReadonlyArray<CloneLoadRateDetailsInput>;
  readonly rerateableRateDetails?: InputMaybe<ReadonlyArray<CloneLoadRateDetailsInput>>;
};

export type CloneLoadOrderInput = {
  readonly refs?: InputMaybe<ReadonlyArray<RefInputV2>>;
  readonly activationStatusId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly orderNote?: InputMaybe<Scalars['String']>;
  readonly activationStatusReason?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
};

export type CloneLoadRateDetailsInput = {
  readonly chargeTypeId: Scalars['ID'];
  readonly ratePer: Scalars['Float'];
  readonly unitsDecimal: Scalars['Float'];
  readonly currency: Scalars['String'];
  readonly enteredRatePer?: InputMaybe<Scalars['Float']>;
  readonly exchangeRate?: InputMaybe<Scalars['Float']>;
  readonly exchangeRateDate?: InputMaybe<Scalars['DateTime']>;
};

export type CloneLoadRouteInput = {
  readonly id: Scalars['ID'];
  readonly maxCost?: InputMaybe<Scalars['Float']>;
  readonly rebookCarrier?: InputMaybe<Scalars['Boolean']>;
  readonly copyCarrierCost?: InputMaybe<Scalars['Boolean']>;
  readonly emptyLocation?: InputMaybe<Scalars['String']>;
  readonly emptyDateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly emptyTrailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly emptyTrailerLength?: InputMaybe<UnitOfLengthInput>;
  readonly stopDetails: ReadonlyArray<CloneLoadStopInput>;
};

export type CloneLoadStopInput = {
  readonly note?: InputMaybe<Scalars['String']>;
  readonly scheduleType?: InputMaybe<ScheduleAppointmentTypeEnum>;
  readonly refs: ReadonlyArray<RefInputV2>;
  readonly reqDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly availableEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly appointment?: InputMaybe<AppointmentInputV2>;
  readonly id: Scalars['ID'];
  readonly availableStart: DatetimeWithTimezoneInput;
  readonly availableEnd: DatetimeWithTimezoneInput;
};

export type CommissionEvent = {
  readonly __typename?: 'CommissionEvent';
  readonly description?: Maybe<Scalars['String']>;
  readonly commissionEventType?: Maybe<CommissionEventTypeEnum>;
  readonly events?: Maybe<ReadonlyArray<Maybe<CommissionEvent>>>;
};

export enum CommissionEventTypeEnum {
  Step = 'STEP',
  Calculation = 'CALCULATION',
  Configuration = 'CONFIGURATION',
  Information = 'INFORMATION',
  Override = 'OVERRIDE'
}

export type CommissionsCustomerOverheadConfiguration = {
  readonly __typename?: 'CommissionsCustomerOverheadConfiguration';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly transportModeId?: Maybe<Scalars['String']>;
  readonly loadSizeId?: Maybe<Scalars['String']>;
  readonly divisionId?: Maybe<Scalars['String']>;
  readonly customerOverheadTypeId: Scalars['String'];
  readonly customerOverheadFlatAmount?: Maybe<Scalars['Decimal']>;
  readonly customerOverheadPercent?: Maybe<Scalars['Decimal']>;
  readonly customerOverheadPercentMinAmount?: Maybe<Scalars['Decimal']>;
  readonly customerOverheadPercentMaxAmount?: Maybe<Scalars['Decimal']>;
  /** @deprecated Field no longer supported */
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type CommissionsCustomerOverheadConfigurationFilterInput = {
  readonly customerId: Scalars['ID'];
  readonly sizeModeDivisionFilter?: InputMaybe<CommissionsSizeModeDivisionFilterInput>;
};

export type CommissionsCustomerOverheadConfigurationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly customerId: Scalars['ID'];
  readonly transportModeId?: InputMaybe<Scalars['String']>;
  readonly loadSizeId?: InputMaybe<Scalars['String']>;
  readonly divisionId?: InputMaybe<Scalars['String']>;
  readonly customerOverheadTypeId: Scalars['String'];
  readonly customerOverheadFlatAmount?: InputMaybe<Scalars['Decimal']>;
  readonly customerOverheadPercent?: InputMaybe<Scalars['Decimal']>;
  readonly customerOverheadPercentMinAmount?: InputMaybe<Scalars['Decimal']>;
  readonly customerOverheadPercentMaxAmount?: InputMaybe<Scalars['Decimal']>;
};

export type CommissionsGlobalConfiguration = {
  readonly __typename?: 'CommissionsGlobalConfiguration';
  readonly id: Scalars['ID'];
  readonly transportModeId?: Maybe<Scalars['String']>;
  readonly loadSizeId?: Maybe<Scalars['String']>;
  readonly divisionId?: Maybe<Scalars['String']>;
  readonly customerAllocationSplit: Scalars['Decimal'];
  readonly carrierAllocationSplit: Scalars['Decimal'];
  readonly companyOverheadTypeId: Scalars['String'];
  readonly companyOverheadFlatAmount?: Maybe<Scalars['Decimal']>;
  readonly companyOverheadPercent?: Maybe<Scalars['Decimal']>;
  readonly companyOverheadPercentMinAmount?: Maybe<Scalars['Decimal']>;
  readonly companyOverheadPercentMaxAmount?: Maybe<Scalars['Decimal']>;
  readonly routeRateAllocationMethodId?: Maybe<Scalars['String']>;
  readonly orderCostAllocationMethodId?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type CommissionsGlobalConfigurationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly transportModeId?: InputMaybe<Scalars['String']>;
  readonly loadSizeId?: InputMaybe<Scalars['String']>;
  readonly divisionId?: InputMaybe<Scalars['String']>;
  readonly customerAllocationSplit: Scalars['Decimal'];
  readonly carrierAllocationSplit: Scalars['Decimal'];
  readonly companyOverheadTypeId: Scalars['String'];
  readonly companyOverheadFlatAmount?: InputMaybe<Scalars['Decimal']>;
  readonly companyOverheadPercent?: InputMaybe<Scalars['Decimal']>;
  readonly companyOverheadPercentMinAmount?: InputMaybe<Scalars['Decimal']>;
  readonly companyOverheadPercentMaxAmount?: InputMaybe<Scalars['Decimal']>;
  readonly routeRateAllocationMethodId?: InputMaybe<Scalars['String']>;
  readonly orderCostAllocationMethodId?: InputMaybe<Scalars['String']>;
};

export type CommissionsOverhead = {
  readonly __typename?: 'CommissionsOverhead';
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly companyOverhead?: Maybe<Scalars['Decimal']>;
  readonly customerOverhead?: Maybe<Scalars['Decimal']>;
  readonly customerOverheadDetails?: Maybe<ReadonlyArray<Maybe<CustomerOverheadDetail>>>;
};

export type CommissionsSizeModeDivisionFilterInput = {
  readonly transportModeId?: InputMaybe<Scalars['String']>;
  readonly loadSizeId?: InputMaybe<Scalars['String']>;
  readonly divisionId?: InputMaybe<Scalars['String']>;
};

export enum CommitmentDayOfWeekEnum {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  None = 'NONE'
}

export type CommitmentVolume = {
  readonly __typename?: 'CommitmentVolume';
  readonly id: Scalars['ID'];
  readonly volume?: Maybe<Scalars['Int']>;
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
};

export type Commodities = {
  readonly code?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly expectedWeight?: InputMaybe<SniQuoteDimensions>;
  readonly actualWeight?: InputMaybe<SniQuoteDimensions>;
  readonly expectedPieces?: InputMaybe<Scalars['String']>;
  readonly actualPieces?: InputMaybe<Scalars['String']>;
  readonly number?: InputMaybe<Scalars['String']>;
  readonly packagingUnit?: InputMaybe<Scalars['String']>;
  readonly loadOnTerm?: InputMaybe<Scalars['String']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTempControlled?: InputMaybe<Scalars['Boolean']>;
  readonly stccCode?: InputMaybe<Scalars['String']>;
  readonly isTopLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly isBottomLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly preCoolTo?: InputMaybe<SniQuoteDimensions>;
  readonly minimumTemperature?: InputMaybe<SniQuoteDimensions>;
  readonly maximumTemperature?: InputMaybe<SniQuoteDimensions>;
  readonly temperatureSettingTerm?: InputMaybe<Scalars['String']>;
  readonly hazmatClassTerm?: InputMaybe<Scalars['String']>;
  readonly hazmatPackagingGroupTerm?: InputMaybe<Scalars['String']>;
  readonly expectedDimensions?: InputMaybe<TrailerDimensions>;
  readonly expectedLinear?: InputMaybe<SniQuoteDimensions>;
  readonly actualLinear?: InputMaybe<SniQuoteDimensions>;
  readonly expectedCube?: InputMaybe<SniQuoteDimensions>;
  readonly actualCube?: InputMaybe<SniQuoteDimensions>;
  readonly expectedDensity?: InputMaybe<SniQuoteDimensions>;
  readonly actualDensity?: InputMaybe<SniQuoteDimensions>;
  readonly expectedClassTerm?: InputMaybe<Scalars['String']>;
  readonly actualClassTerm?: InputMaybe<Scalars['String']>;
  readonly expectedNMFC?: InputMaybe<Scalars['String']>;
  readonly actualNMFC?: InputMaybe<Scalars['String']>;
};

export type CommoditiesFilter = {
  readonly orderId?: InputMaybe<Scalars['ID']>;
};

/** A commodity */
export type Commodity = {
  readonly __typename?: 'Commodity';
  readonly actualClass?: Maybe<KeyValue>;
  /** @deprecated Use actual_volume */
  readonly actualCube?: Maybe<Length>;
  readonly actualDensity?: Maybe<Scalars['String']>;
  readonly actualDimensions?: Maybe<Dimensions>;
  readonly actualLinear?: Maybe<Length>;
  readonly actualNMFCCode?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated due to typo, use actual NMFC Code */
  readonly actualNfmcCode?: Maybe<Scalars['String']>;
  readonly actualPieces?: Maybe<Scalars['Int']>;
  readonly actualVolume?: Maybe<Volume>;
  readonly actualWeight?: Maybe<Mass>;
  readonly bottomLoadable?: Maybe<Scalars['Boolean']>;
  readonly customerCode?: Maybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly expectedClass?: Maybe<KeyValue>;
  /** @deprecated Use expected_volume */
  readonly expectedCube?: Maybe<Length>;
  readonly expectedDensity?: Maybe<Scalars['String']>;
  readonly expectedDimensions?: Maybe<Dimensions>;
  readonly expectedLinear?: Maybe<Length>;
  readonly expectedNMFCCode?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated due to typo, use expected NMFC Code */
  readonly expectedNfmcCode?: Maybe<Scalars['String']>;
  readonly expectedPieces?: Maybe<Scalars['Int']>;
  readonly expectedVolume?: Maybe<Volume>;
  readonly expectedWeight: Mass;
  readonly hazmat?: Maybe<Scalars['Boolean']>;
  readonly hazmatClass?: Maybe<KeyValue>;
  readonly hazmatPackagingGroup?: Maybe<CommodityHazmatPackagingGroupTypeEnum>;
  readonly hazmatPhoneNumber?: Maybe<Scalars['String']>;
  readonly hazmatUnitedNationsNumber?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly loadOnType?: Maybe<KeyValue>;
  readonly maximumTemperature?: Maybe<Temperature>;
  readonly minimumTemperature?: Maybe<Temperature>;
  readonly orderId: Scalars['ID'];
  readonly packagingCount?: Maybe<Scalars['Int']>;
  readonly packagingType?: Maybe<KeyValue>;
  readonly preCoolTo?: Maybe<Temperature>;
  readonly stccCode?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<ReadonlyArray<Stop>>;
  readonly temperatureControlled?: Maybe<Scalars['Boolean']>;
  readonly temperatureSetting?: Maybe<CommodityTemperatureSettingTypeEnum>;
  readonly topLoadable?: Maybe<Scalars['Boolean']>;
};


/** A commodity */
export type CommodityActualCubeArgs = {
  unit: UnitOfLengthEnum;
};


/** A commodity */
export type CommodityActualDimensionsArgs = {
  unit: UnitOfLengthEnum;
};


/** A commodity */
export type CommodityActualLinearArgs = {
  unit: UnitOfLengthEnum;
};


/** A commodity */
export type CommodityActualVolumeArgs = {
  unit: VolumeUnitTypeEnum;
};


/** A commodity */
export type CommodityActualWeightArgs = {
  unit: MassUnitTypeEnum;
};


/** A commodity */
export type CommodityExpectedCubeArgs = {
  unit: UnitOfLengthEnum;
};


/** A commodity */
export type CommodityExpectedDimensionsArgs = {
  unit: UnitOfLengthEnum;
};


/** A commodity */
export type CommodityExpectedLinearArgs = {
  unit: UnitOfLengthEnum;
};


/** A commodity */
export type CommodityExpectedVolumeArgs = {
  unit: VolumeUnitTypeEnum;
};


/** A commodity */
export type CommodityExpectedWeightArgs = {
  unit: MassUnitTypeEnum;
};


/** A commodity */
export type CommodityMaximumTemperatureArgs = {
  unit: TemperatureUnitTypeEnum;
};


/** A commodity */
export type CommodityMinimumTemperatureArgs = {
  unit: TemperatureUnitTypeEnum;
};


/** A commodity */
export type CommodityPreCoolToArgs = {
  unit: TemperatureUnitTypeEnum;
};

/** The connection type for Commodity. */
export type CommodityConnection = {
  readonly __typename?: 'CommodityConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<CommodityEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

export type CommodityDimensionsV2 = {
  readonly __typename?: 'CommodityDimensionsV2';
  readonly height: LengthV2;
  readonly length: LengthV2;
  readonly width: LengthV2;
};


export type CommodityDimensionsV2HeightArgs = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityDimensionsV2LengthArgs = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityDimensionsV2WidthArgs = {
  unit?: InputMaybe<Scalars['String']>;
};

/** An edge in a connection. */
export type CommodityEdge = {
  readonly __typename?: 'CommodityEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: Commodity;
};

export enum CommodityFreightClassTypeEnum {
  Class_50 = 'Class_50',
  Class_55 = 'Class_55',
  Class_60 = 'Class_60',
  Class_65 = 'Class_65',
  Class_70 = 'Class_70',
  Class_77_5 = 'Class_77_5',
  Class_85 = 'Class_85',
  Class_92_5 = 'Class_92_5',
  Class_100 = 'Class_100',
  Class_110 = 'Class_110',
  Class_125 = 'Class_125',
  Class_150 = 'Class_150',
  Class_175 = 'Class_175',
  Class_200 = 'Class_200',
  Class_250 = 'Class_250',
  Class_300 = 'Class_300',
  Class_400 = 'Class_400',
  Class_500 = 'Class_500',
  NoFreightClass = 'No_Freight_Class'
}

export enum CommodityHazmatClassTypeEnum {
  Division_1_1 = 'Division_1_1',
  Division_1_2 = 'Division_1_2',
  Division_1_3 = 'Division_1_3',
  Division_1_4 = 'Division_1_4',
  Division_1_5 = 'Division_1_5',
  Division_1_6 = 'Division_1_6',
  Division_2_1 = 'Division_2_1',
  Division_2_2 = 'Division_2_2',
  Division_2_3 = 'Division_2_3',
  Division_3 = 'Division_3',
  Division_4_1 = 'Division_4_1',
  Division_4_2 = 'Division_4_2',
  Division_4_3 = 'Division_4_3',
  Division_5_1 = 'Division_5_1',
  Division_5_2 = 'Division_5_2',
  Division_6_1 = 'Division_6_1',
  Division_6_2 = 'Division_6_2',
  Division_7 = 'Division_7',
  Division_8 = 'Division_8',
  Division_9 = 'Division_9'
}

export enum CommodityHazmatPackagingGroupTypeEnum {
  I = 'I',
  Ii = 'II',
  Iii = 'III'
}

export type CommodityInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly orderId?: InputMaybe<Scalars['ID']>;
  readonly description: Scalars['String'];
  readonly stccCode?: InputMaybe<Scalars['String']>;
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly packagingCount?: InputMaybe<Scalars['Int']>;
  readonly packagingType?: InputMaybe<CommodityPackagingTypeEnum>;
  readonly packagingTypeId?: InputMaybe<Scalars['ID']>;
  readonly loadOnType?: InputMaybe<CommodityLoadOnTypeEnum>;
  readonly loadOnTypeId?: InputMaybe<Scalars['ID']>;
  readonly topLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly bottomLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly doNotStack?: InputMaybe<Scalars['Boolean']>;
  readonly expectedWeight?: InputMaybe<UnitOfMassInput>;
  readonly actualWeight?: InputMaybe<UnitOfMassInput>;
  /** either expectedWeight or expectedWeightV2 is required */
  readonly expectedWeightV2?: InputMaybe<UnitOfMassInputV2>;
  readonly actualWeightV2?: InputMaybe<UnitOfMassInputV2>;
  readonly pieceTypeId?: InputMaybe<Scalars['ID']>;
  readonly expectedPieces?: InputMaybe<Scalars['Int']>;
  readonly actualPieces?: InputMaybe<Scalars['Int']>;
  readonly temperatureControlled?: InputMaybe<Scalars['Boolean']>;
  readonly preCoolTo?: InputMaybe<UnitOfTemperatureInput>;
  readonly minimumTemperature?: InputMaybe<UnitOfTemperatureInput>;
  readonly maximumTemperature?: InputMaybe<UnitOfTemperatureInput>;
  readonly preCoolToV2?: InputMaybe<UnitOfTemperatureInputV2>;
  readonly minimumTemperatureV2?: InputMaybe<UnitOfTemperatureInputV2>;
  readonly maximumTemperatureV2?: InputMaybe<UnitOfTemperatureInputV2>;
  readonly temperatureSetting?: InputMaybe<CommodityTemperatureSettingTypeEnum>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly hazmatClass?: InputMaybe<CommodityHazmatClassTypeEnum>;
  readonly hazmatClassId?: InputMaybe<Scalars['ID']>;
  readonly hazmatPackagingGroup?: InputMaybe<CommodityHazmatPackagingGroupTypeEnum>;
  readonly hazmatUnitedNationsNumber?: InputMaybe<Scalars['String']>;
  readonly hazmatPhoneNumber?: InputMaybe<Scalars['String']>;
  readonly actualDimensions?: InputMaybe<DimensionsInput>;
  readonly expectedDimensions?: InputMaybe<DimensionsInput>;
  readonly actualDimensionsV2?: InputMaybe<DimensionsInputV2>;
  readonly expectedDimensionsV2?: InputMaybe<DimensionsInputV2>;
  readonly expectedLinear?: InputMaybe<UnitOfLengthInput>;
  readonly actualLinear?: InputMaybe<UnitOfLengthInput>;
  readonly expectedLinearV2?: InputMaybe<UnitOfLengthInputV2>;
  readonly actualLinearV2?: InputMaybe<UnitOfLengthInputV2>;
  readonly actualVolume?: InputMaybe<UnitOfVolumeInput>;
  readonly expectedVolume?: InputMaybe<UnitOfVolumeInput>;
  readonly actualVolumeV2?: InputMaybe<UnitOfVolumeInputV2>;
  readonly expectedVolumeV2?: InputMaybe<UnitOfVolumeInputV2>;
  readonly actualDensity?: InputMaybe<Scalars['String']>;
  readonly actualDensityInput?: InputMaybe<UnitOfDensityInput>;
  readonly actualDensityV2?: InputMaybe<UnitOfDensityInputV2>;
  readonly expectedDensity?: InputMaybe<Scalars['String']>;
  readonly expectedDensityInput?: InputMaybe<UnitOfDensityInput>;
  readonly expectedDensityV2?: InputMaybe<UnitOfDensityInputV2>;
  readonly actualClass?: InputMaybe<CommodityFreightClassTypeEnum>;
  readonly actualClassId?: InputMaybe<Scalars['ID']>;
  readonly expectedClass?: InputMaybe<CommodityFreightClassTypeEnum>;
  readonly expectedClassId?: InputMaybe<Scalars['ID']>;
  readonly actualNMFCCode?: InputMaybe<Scalars['String']>;
  readonly expectedNMFCCode?: InputMaybe<Scalars['String']>;
  /** 0 based integer and references order of stops passed */
  readonly associatedStopIndices?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly overDimensional?: InputMaybe<Scalars['Boolean']>;
  /** YYYY format */
  readonly year?: InputMaybe<Scalars['Float']>;
  readonly make?: InputMaybe<Scalars['String']>;
  readonly model?: InputMaybe<Scalars['String']>;
  readonly serialNumbers?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly shipments?: InputMaybe<ReadonlyArray<ShipmentInput>>;
};

export enum CommodityLoadOnTypeEnum {
  Floor = 'Floor',
  Floot = 'Floot',
  Pallet = 'Pallet',
  Skid = 'Skid',
  Ccc = 'ccc',
  Ddd = 'ddd'
}

export enum CommodityPackagingTypeEnum {
  Bag = 'Bag',
  Bale = 'Bale',
  Bundle = 'Bundle',
  Box = 'Box',
  Cases = 'Cases',
  Crate = 'Crate',
  Carton = 'Carton',
  Drum = 'Drum',
  Pieces = 'Pieces',
  Pallet = 'Pallet',
  Roll = 'Roll',
  Skid = 'Skid',
  Tube = 'Tube',
  ToteBin = 'ToteBin',
  AniDrumTest1 = 'Ani_Drum_Test1',
  Aaaaaaaaaddd = 'aaaaaaaaaddd'
}

export enum CommodityTemperatureSettingTypeEnum {
  Continuous = 'continuous',
  Cycle = 'cycle'
}

/** Agregate information of commodities */
export type CommodityTotals = {
  readonly __typename?: 'CommodityTotals';
  readonly actualWeight: Mass;
  readonly expectedWeight: Mass;
};


/** Agregate information of commodities */
export type CommodityTotalsActualWeightArgs = {
  unit: MassUnitTypeEnum;
};


/** Agregate information of commodities */
export type CommodityTotalsExpectedWeightArgs = {
  unit: MassUnitTypeEnum;
};

export type CommodityV2 = {
  readonly __typename?: 'CommodityV2';
  readonly id: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly description: Scalars['String'];
  readonly stccCode?: Maybe<Scalars['String']>;
  readonly customerCode?: Maybe<Scalars['String']>;
  readonly packagingCount?: Maybe<Scalars['Int']>;
  readonly packagingType?: Maybe<KeyValue>;
  readonly loadOnType?: Maybe<KeyValue>;
  readonly topLoadable?: Maybe<Scalars['Boolean']>;
  readonly bottomLoadable?: Maybe<Scalars['Boolean']>;
  readonly doNotStack?: Maybe<Scalars['Boolean']>;
  /** @deprecated Please use expectedWeightV2. It supports new uom values */
  readonly expectedWeight: Mass;
  /** @deprecated Please use actualWeightV2. It supports new uom values */
  readonly actualWeight?: Maybe<Mass>;
  readonly expectedWeightV2?: Maybe<MassV2>;
  readonly actualWeightV2?: Maybe<MassV2>;
  readonly pieceType?: Maybe<KeyValue>;
  readonly expectedPieces?: Maybe<Scalars['Int']>;
  readonly actualPieces?: Maybe<Scalars['Int']>;
  readonly temperatureControlled?: Maybe<Scalars['Boolean']>;
  /** @deprecated Please use preCoolToV2. It supports new uom values */
  readonly preCoolTo?: Maybe<Temperature>;
  /** @deprecated Please use minimumTemperatureV2. It supports new uom values */
  readonly minimumTemperature?: Maybe<Temperature>;
  /** @deprecated Please use maximumTemperatureV2. It supports new uom values */
  readonly maximumTemperature?: Maybe<Temperature>;
  readonly preCoolToV2?: Maybe<TemperatureV2>;
  readonly minimumTemperatureV2?: Maybe<TemperatureV2>;
  readonly maximumTemperatureV2?: Maybe<TemperatureV2>;
  readonly temperatureSetting?: Maybe<CommodityTemperatureSettingTypeEnum>;
  readonly hazmat?: Maybe<Scalars['Boolean']>;
  readonly hazmatClass?: Maybe<KeyValue>;
  readonly hazmatPackagingGroup?: Maybe<CommodityHazmatPackagingGroupTypeEnum>;
  readonly hazmatUnitedNationsNumber?: Maybe<Scalars['String']>;
  readonly hazmatPhoneNumber?: Maybe<Scalars['String']>;
  /** @deprecated Please use actualDimensionsV2. It supports new uom values */
  readonly actualDimensions?: Maybe<DimensionsV2>;
  /** @deprecated Please use expectedDimensionsV2. It supports new uom values */
  readonly expectedDimensions?: Maybe<DimensionsV2>;
  readonly actualDimensionsV2?: Maybe<CommodityDimensionsV2>;
  readonly expectedDimensionsV2?: Maybe<CommodityDimensionsV2>;
  /** @deprecated Please use expectedLinearV2. It supports new uom values */
  readonly expectedLinear?: Maybe<Length>;
  /** @deprecated Please use actualLinearV2. It supports new uom values */
  readonly actualLinear?: Maybe<Length>;
  readonly expectedLinearV2?: Maybe<LengthV2>;
  readonly actualLinearV2?: Maybe<LengthV2>;
  /** @deprecated Please use actualVolumeV2. It supports new uom values */
  readonly actualVolume?: Maybe<Volume>;
  /** @deprecated Please use expectedVolumeV2. It supports new uom values */
  readonly expectedVolume?: Maybe<Volume>;
  readonly actualVolumeV2?: Maybe<VolumeV2>;
  readonly expectedVolumeV2?: Maybe<VolumeV2>;
  /** @deprecated Please use actualDensityV2. It supports new uom values */
  readonly actualDensity?: Maybe<Density>;
  /** @deprecated Please use expectedDensityV2. It supports new uom values */
  readonly expectedDensity?: Maybe<Density>;
  readonly actualDensityV2?: Maybe<DensityV2>;
  readonly expectedDensityV2?: Maybe<DensityV2>;
  readonly actualClass?: Maybe<KeyValue>;
  readonly expectedClass?: Maybe<KeyValue>;
  readonly actualNMFCCode?: Maybe<Scalars['String']>;
  readonly expectedNMFCCode?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly updatedByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly overDimensional?: Maybe<Scalars['Boolean']>;
  readonly year?: Maybe<Scalars['Float']>;
  readonly make?: Maybe<Scalars['String']>;
  readonly model?: Maybe<Scalars['String']>;
  readonly serialNumbers?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** Shipments to which this commodity belongs */
  readonly shipments?: Maybe<ReadonlyArray<Shipment>>;
  /** Order Stops on commodities */
  readonly orderStops?: Maybe<ReadonlyArray<OrderStop>>;
  /** Shipment/Commodity tracking states as they relate to an order stop. */
  readonly stopTracking?: Maybe<ReadonlyArray<StopTracking>>;
};


export type CommodityV2ExpectedWeightArgs = {
  unit: MassUnitTypeEnum;
};


export type CommodityV2ActualWeightArgs = {
  unit: MassUnitTypeEnum;
};


export type CommodityV2ExpectedWeightV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2ActualWeightV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2PreCoolToV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2MinimumTemperatureV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2MaximumTemperatureV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2ExpectedLinearArgs = {
  unit: UnitOfLengthEnum;
};


export type CommodityV2ActualLinearArgs = {
  unit: UnitOfLengthEnum;
};


export type CommodityV2ExpectedLinearV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2ActualLinearV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2ActualVolumeArgs = {
  unit: VolumeUnitTypeEnum;
};


export type CommodityV2ExpectedVolumeArgs = {
  unit: VolumeUnitTypeEnum;
};


export type CommodityV2ActualVolumeV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2ExpectedVolumeV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2ActualDensityV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CommodityV2ExpectedDensityV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};

export type CompleteUploadInput = {
  readonly documentId: Scalars['String'];
  readonly documentType: Scalars['String'];
  readonly successCount: Scalars['Int'];
  readonly failedCount: Scalars['Int'];
  readonly processedCount: Scalars['Int'];
  readonly uploadedBy?: InputMaybe<Scalars['ID']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly docError: Scalars['String'];
};

export type CompleteUploadInputV2 = {
  readonly documentId: Scalars['String'];
  readonly documentType: Scalars['String'];
  readonly successCount: Scalars['Int'];
  readonly failedCount: Scalars['Int'];
  readonly processedCount: Scalars['Int'];
  readonly uploadedBy?: InputMaybe<Scalars['ID']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly errors?: InputMaybe<ReadonlyArray<RowErrorInput>>;
};

export type CompleteUploadPayload = {
  readonly __typename?: 'CompleteUploadPayload';
  readonly message?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Boolean']>;
};

export type ConditionProperties = {
  readonly __typename?: 'ConditionProperties';
  readonly fact?: Maybe<Scalars['String']>;
  readonly operator?: Maybe<OperatorType>;
  readonly value?: Maybe<DataValue>;
  readonly any?: Maybe<ReadonlyArray<Maybe<ConditionProperties>>>;
  readonly all?: Maybe<ReadonlyArray<Maybe<ConditionProperties>>>;
};

export type ConditionPropertiesInput = {
  readonly fact?: InputMaybe<Scalars['String']>;
  readonly operator?: InputMaybe<OperatorType>;
  readonly value?: InputMaybe<DataValueInput>;
  readonly any?: InputMaybe<ReadonlyArray<InputMaybe<ConditionPropertiesInput>>>;
  readonly all?: InputMaybe<ReadonlyArray<InputMaybe<ConditionPropertiesInput>>>;
};

export enum ConfigScope {
  Customer = 'Customer',
  Tenant = 'Tenant'
}

export enum ConfigTypeEnum {
  BusinessUnitRegionSet = 'businessUnitRegionSet',
  FacilityGatesGuardType = 'facilityGatesGuardType',
  FacilityGatesDocumentationNeeded = 'facilityGatesDocumentationNeeded',
  FacilityGatesIdChecked = 'facilityGatesIdChecked',
  FacilityDocumentSourceType = 'facilityDocumentSourceType',
  FacilityDocumentType = 'facilityDocumentType',
  FacilityAccommodationsRating = 'facilityAccommodationsRating',
  FacilityActivitiesVariability = 'facilityActivitiesVariability',
  FacilitySublocationType = 'facilitySublocationType',
  FacilitySublocationLocked = 'facilitySublocationLocked',
  FacilitySublocationGuarded = 'facilitySublocationGuarded',
  FacilityHolidays = 'facilityHolidays',
  FacilityHolidaysOpenClosed = 'facilityHolidaysOpenClosed',
  RegionType = 'regionType',
  TrackCommitmentPerformanceBasedOn = 'trackCommitmentPerformanceBasedOn',
  CommitmentWeekStarting = 'commitmentWeekStarting',
  CommitmentFrequencyType = 'commitmentFrequencyType',
  PortfolioRoeStatus = 'portfolioROEStatus',
  ViewCustomerPortfolioRoe = 'viewCustomerPortfolioROE',
  CarrierFacilityRelationshipTypes = 'carrierFacilityRelationshipTypes',
  DisableStopEventsBeforeStatus = 'disableStopEventsBeforeStatus',
  StopScheduleChangeReason = 'stopScheduleChangeReason',
  TaskStatuses = 'taskStatuses',
  StopEventsTrailerValidation = 'stopEventsTrailerValidation',
  StopEventsManualEtaDisabled = 'stopEventsManualEtaDisabled',
  RestrictEditingOfArrivalDepartureDateTimes = 'restrictEditingOfArrivalDepartureDateTimes',
  DriverElectronicTrackingType = 'driverElectronicTrackingType',
  IncidentTypes = 'incidentTypes',
  IncidentFaultParties = 'incidentFaultParties',
  IncidentGravity = 'incidentGravity',
  RoutingGuideType = 'routingGuideType',
  FuelProgramType = 'fuelProgramType',
  FuelReferenceType = 'fuelReferenceType',
  CreateSource = 'createSource',
  /** service */
  Service = 'service',
  ServiceProfileDay = 'serviceProfileDay',
  ServiceProfileStatus = 'serviceProfileStatus',
  ServiceProfileType = 'serviceProfileType',
  TenderMode = 'tenderMode',
  FinalizedFuelStatus = 'finalizedFuelStatus',
  FuelIndices = 'fuelIndices',
  LockPhoneFieldByDefault = 'lockPhoneFieldByDefault',
  TrackingEtaEnabled = 'trackingEtaEnabled',
  TrackingHierarchyEnabled = 'trackingHierarchyEnabled',
  ViewMarketPriceCardRateQuote = 'viewMarketPriceCardRateQuote',
  ViewMultirouteListScreenUpdates = 'viewMultirouteListScreenUpdates',
  ViewMarketPriceCardTruckEntry = 'viewMarketPriceCardTruckEntry',
  ViewOfferMarketPriceCard = 'viewOfferMarketPriceCard',
  ViewRouteDetailsMarketPriceCard = 'viewRouteDetailsMarketPriceCard',
  TrackingHierarchyExpectedBufferMinutes = 'trackingHierarchyExpectedBufferMinutes',
  TrackingHierarchyPreferredTrackingMethod = 'trackingHierarchyPreferredTrackingMethod',
  TrailerGeneralInformationMake = 'trailerGeneralInformationMake',
  TrailerGeneralInformationModel = 'trailerGeneralInformationModel',
  TrailerGeneralInformationType = 'trailerGeneralInformationType',
  TrailerGeneralInformationAuthorizedCountries = 'trailerGeneralInformationAuthorizedCountries',
  TrailerGeneralInformationTempMonitoringType = 'trailerGeneralInformationTempMonitoringType',
  TrailerGeneralOperationsOwner = 'trailerGeneralOperationsOwner',
  TrailerGeneralTrackingDevice = 'trailerGeneralTrackingDevice',
  TrailerGeneralTrackingModel = 'trailerGeneralTrackingModel',
  TrailerGeneralAppearanceFloor = 'trailerGeneralAppearanceFloor',
  TrailerGeneralAppearanceRoof = 'trailerGeneralAppearanceRoof',
  TrailerGeneralAppearanceDoors = 'trailerGeneralAppearanceDoors',
  TrailerGeneralAppearanceSkin = 'trailerGeneralAppearanceSkin',
  DriverAutoDispatchEnabled = 'driverAutoDispatchEnabled',
  DriverGeneralOrgSegment = 'driverGeneralOrgSegment',
  DriverGeneralOrgDivision = 'driverGeneralOrgDivision',
  DriverGeneralOrgFleet = 'driverGeneralOrgFleet',
  DriverGeneralOrgProject = 'driverGeneralOrgProject',
  DriverGeneralOrgProgram = 'driverGeneralOrgProgram',
  DriverGeneralPersonalGenderPronoun = 'driverGeneralPersonalGenderPronoun',
  DriverGeneralPersonalMaritalStatus = 'driverGeneralPersonalMaritalStatus',
  DriverGeneralCertificationType = 'driverGeneralCertificationType',
  DriverGeneralProfessionalType = 'driverGeneralProfessionalType',
  DriverGeneralProfessionalClass = 'driverGeneralProfessionalClass',
  DriverGeneralOpDetailsStatus = 'driverGeneralOpDetailsStatus',
  DriverGeneralOpDetailsHos = 'driverGeneralOpDetailsHOS',
  DriverGeneralOpDetailsPowerTractorType = 'driverGeneralOpDetailsPowerTractorType',
  DriverGeneralOpDetailsTrailerType = 'driverGeneralOpDetailsTrailerType',
  DriverGeneralAddressType = 'driverGeneralAddressType',
  DriverGeneralIdentifersType = 'driverGeneralIdentifersType',
  DriverGeneralTimeOff = 'driverGeneralTimeOff',
  DriverGeneralContactsType = 'driverGeneralContactsType',
  DriverGeneralAwardsType = 'driverGeneralAwardsType',
  DriverGeneralEquipmentCode = 'driverGeneralEquipmentCode',
  DriverGeneralEquipmentCondition = 'driverGeneralEquipmentCondition',
  DriverGeneralPersonalChildren = 'driverGeneralPersonalChildren',
  DriverGeneralPersonalMilitaryReserveObligation = 'driverGeneralPersonalMilitaryReserveObligation',
  DriverGeneralPersonalParkingSpace = 'driverGeneralPersonalParkingSpace',
  FuelApplicationMethod = 'fuelApplicationMethod',
  FuelScale = 'fuelScale',
  FuelSurchargeDate = 'fuelSurchargeDate',
  FuelWeekday = 'fuelWeekday',
  FuelSurchargeFrequency = 'fuelSurchargeFrequency',
  /** loadTrackingActionType */
  LoadTrackingActionType = 'loadTrackingActionType',
  /** loadTrackingInformationFrom */
  LoadTrackingInformationFrom = 'loadTrackingInformationFrom',
  LoadTrackingDelayReason = 'loadTrackingDelayReason',
  MileagePcMilerRoutingType = 'mileagePcMilerRoutingType',
  MileagePcMilerDataVersions = 'mileagePCMilerDataVersions',
  MileagePcMilerHazMatType = 'mileagePCMilerHazMatType',
  MileagePcMilerTollRoads = 'mileagePCMilerTollRoads',
  MileagePcMilerClassOverrides = 'mileagePCMilerClassOverrides',
  MileageMileMakerRouteType = 'mileageMileMakerRouteType',
  MileageAzureRouteType = 'mileageAzureRouteType',
  MileageAzureVehicleLoadType = 'mileageAzureVehicleLoadType',
  PowerGeneralInformationMake = 'powerGeneralInformationMake',
  PowerGeneralInformationModel = 'powerGeneralInformationModel',
  PowerGeneralInformationType = 'powerGeneralInformationType',
  PowerGeneralOpsOwner = 'powerGeneralOpsOwner',
  PowerGeneralOpsClass = 'powerGeneralOpsClass',
  PowerGeneralOpsLegalToRun = 'powerGeneralOpsLegalToRun',
  PowerGeneralOpsSegment = 'powerGeneralOpsSegment',
  PowerGeneralOpsFleet = 'powerGeneralOpsFleet',
  PowerGeneralTrackingDevice = 'powerGeneralTrackingDevice',
  PowerGeneralTrackingModel = 'powerGeneralTrackingModel',
  PowerGeneralAppearanceSleeperType = 'powerGeneralAppearanceSleeperType',
  PowerGeneralAppearanceAxleConfig = 'powerGeneralAppearanceAxleConfig',
  PowerGeneralAppearanceSuspension = 'powerGeneralAppearanceSuspension',
  PowerGeneralAppearanceSleeperSize = 'powerGeneralAppearanceSleeperSize',
  TrackingStatus = 'trackingStatus',
  RatingSource = 'ratingSource',
  RouteVendorReferenceType = 'routeVendorReferenceType',
  ImperialWeightUom = 'imperialWeightUOM',
  ImperialDensityUom = 'imperialDensityUOM',
  ImperialDimensionsUom = 'imperialDimensionsUOM',
  ImperialLinearUom = 'imperialLinearUOM',
  ImperialTemperatureUom = 'imperialTemperatureUOM',
  ImperialVolumeUom = 'imperialVolumeUOM',
  MetricDensityUom = 'metricDensityUOM',
  MetricDimensionsUom = 'metricDimensionsUOM',
  MetricLinearUom = 'metricLinearUOM',
  MetricTemperatureUom = 'metricTemperatureUOM',
  MetricVolumeUom = 'metricVolumeUOM',
  MetricWeightUom = 'metricWeightUOM',
  DriverPreferencePreference = 'driverPreferencePreference',
  DriverPreferencePreferenceReason = 'driverPreferencePreferenceReason',
  DriverPreferenceCustomerPreferenceReason = 'driverPreferenceCustomerPreferenceReason',
  DriverPreferenceCommodity = 'driverPreferenceCommodity',
  DriverPreferenceCommodityReason = 'driverPreferenceCommodityReason',
  DriverPreferenceTeamType = 'driverPreferenceTeamType',
  ValidationEntityType = 'validationEntityType',
  ValidationState = 'validationState',
  ViewValidationConfigTable = 'viewValidationConfigTable',
  ViewCarrierPortfolioRoe = 'viewCarrierPortfolioROE',
  ViewDisableAutoPostingCheckbox = 'viewDisableAutoPostingCheckbox',
  DriverPreferenceOperationalType = 'driverPreferenceOperationalType',
  DriverPreferencePosition = 'driverPreferencePosition',
  DriverPreferenceGeoPreferenceReason = 'driverPreferenceGeoPreferenceReason',
  DriverPreferenceGeoPreferenceDirection = 'driverPreferenceGeoPreferenceDirection',
  TenderAppointmentStatusRequired = 'tenderAppointmentStatusRequired',
  RouteType = 'routeType',
  DriverPreferenceCareerGoalReason = 'driverPreferenceCareerGoalReason',
  DriverPreferenceLanesReason = 'DriverPreferenceLanesReason',
  CustomerRelationshipType = 'customerRelationshipType',
  DriverPreferenceRoutesReason = 'DriverPreferenceRoutesReason',
  /** routeTransportMode */
  RouteTransportMode = 'routeTransportMode',
  AutoStartTenderPlan = 'autoStartTenderPlan',
  InvoiceGrouping = 'invoiceGrouping',
  InvoiceDistributionFrequency = 'invoiceDistributionFrequency',
  LoadStopType = 'loadStopType',
  ValidationRequirements = 'validationRequirements',
  ManualTrackingType = 'manualTrackingType',
  OrderPaymentTerm = 'orderPaymentTerm',
  CarrierCrmNotesType = 'carrierCrmNotesType',
  CustomerCrmNotesType = 'customerCrmNotesType',
  CrmScheduleInteractionVia = 'crmScheduleInteractionVia',
  CrmScheduleInteractionDirection = 'crmScheduleInteractionDirection',
  CrmScheduleInteractionObjective = 'crmScheduleInteractionObjective',
  CarrierCrmInteractionsOutcome = 'carrierCrmInteractionsOutcome',
  IsLoadDriversTabOwnerFieldShown = 'isLoadDriversTabOwnerFieldShown',
  CapacityType = 'capacityType',
  ContactEmailLabel = 'contactEmailLabel',
  ContactDepartment = 'contactDepartment',
  ContactPhoneLabel = 'contactPhoneLabel',
  ContactChatLabel = 'contactChatLabel',
  ContactFunction = 'contactFunction',
  ContactLevel = 'contactLevel',
  BusinessUnit = 'businessUnit',
  Project = 'project',
  ContainerProgram = 'containerProgram',
  NavigationType = 'navigationType',
  EnableIntelligentMatchingP44 = 'enableIntelligentMatchingP44',
  DriverEldNotRequired = 'driverEldNotRequired',
  CarrierRepType = 'carrierRepType',
  /** carrierReleaseMethod */
  CarrierReleaseMethod = 'carrierReleaseMethod',
  LoadTarps = 'loadTarps',
  LoadBracing = 'loadBracing',
  CarrierCrmDocumentsType = 'carrierCrmDocumentsType',
  CarrierCrmDocumentsSource = 'carrierCrmDocumentsSource',
  CarrierCrmDocumentsDirection = 'carrierCrmDocumentsDirection',
  CustomerCrmDocumentsType = 'customerCrmDocumentsType',
  CustomerCrmDocumentsSource = 'customerCrmDocumentsSource',
  CustomerCrmDocumentsDirection = 'customerCrmDocumentsDirection',
  CustomerAccountingStatus = 'customerAccountingStatus',
  DistanceSearchPreference = 'distanceSearchPreference',
  CarrierCrmInteractionsVia = 'carrierCrmInteractionsVia',
  CustomerCrmInteractionsVia = 'customerCrmInteractionsVia',
  CarrierCrmInteractionsObjective = 'carrierCrmInteractionsObjective',
  CarrierAccountingStatus = 'carrierAccountingStatus',
  CustomerCrmInteractionsObjective = 'customerCrmInteractionsObjective',
  ClientExceptionsReasonCodes = 'clientExceptionsReasonCodes',
  ContactNameSuffix = 'contactNameSuffix',
  ContactNamePronouns = 'contactNamePronouns',
  ContactBusinessInfoWorkEnvironment = 'contactBusinessInfoWorkEnvironment',
  ContactBusinessInfoCommunicationPreference = 'contactBusinessInfoCommunicationPreference',
  ContactBusinessInfoPricingBehavior = 'contactBusinessInfoPricingBehavior',
  ContactEntityType = 'contactEntityType',
  ContactEntityStatus = 'contactEntityStatus',
  ContactEntityStatusReason = 'contactEntityStatusReason',
  CustomerCrmInteractionsDirection = 'customerCrmInteractionsDirection',
  CarrierCrmInteractionsDirection = 'carrierCrmInteractionsDirection',
  ReservationType = 'reservationType',
  CustomerCrmInteractionsStatus = 'customerCrmInteractionsStatus',
  CustomerCrmInteractionsRescheduleReason = 'customerCrmInteractionsRescheduleReason',
  CarrierCrmInteractionsRescheduleReason = 'carrierCrmInteractionsRescheduleReason',
  EntityCrmSearchRepsOrGroups = 'entityCrmSearchRepsOrGroups',
  ContactPersonalInfoLanguage = 'contactPersonalInfoLanguage',
  TrailerOperatingStatus = 'trailerOperatingStatus',
  TrailerRemoteAccess = 'trailerRemoteAccess',
  TrailerCarbCompliant = 'trailerCARBCompliant',
  TrailerETracks = 'trailerETracks',
  TrailerServiceStatus = 'trailerServiceStatus',
  TrailerIdentifierType = 'trailerIdentifierType',
  TrailerMaintenanceType = 'trailerMaintenanceType',
  TrailerMaintenanceSeverity = 'trailerMaintenanceSeverity',
  TrailerWorkPerformed = 'trailerWorkPerformed',
  ContactRepRelationship = 'contactRepRelationship',
  EnableCanvasImdlRouteModeAndRouteType = 'enableCanvasImdlRouteModeAndRouteType',
  EnableCanvasBusinessUnitAsDdt = 'enableCanvasBusinessUnitAsDdt',
  InterliningType = 'InterliningType',
  GeneralinformationMeasurementType = 'generalinformationMeasurementType',
  MetricUomOdometer = 'metricUOMOdometer',
  ImperialUomOdometer = 'imperialUOMOdometer',
  CommodityPieceType = 'commodityPieceType',
  CarrierCrmInteractionsCompleteInteractionOutcome = 'carrierCrmInteractionsCompleteInteractionOutcome',
  CustomerCrmInteractionsCompleteInteractionOutcome = 'customerCrmInteractionsCompleteInteractionOutcome',
  ClientExceptionsBypassCodes = 'clientExceptionsBypassCodes',
  ImperialUomTankCapacity = 'imperialUOMTankCapacity',
  MetricUomTankCapacity = 'metricUOMTankCapacity',
  PowerServiceStatus = 'powerServiceStatus',
  PowerEngineBrake = 'powerEngineBrake',
  PowerOperatingStatus = 'powerOperatingStatus',
  PowerIdentifierType = 'powerIdentifierType',
  PowerMaintenanceType = 'powerMaintenanceType',
  PowerMaintenanceSeverity = 'powerMaintenanceSeverity',
  PowerCarbCompliant = 'powerCARBCompliant',
  TrailerLandingGear = 'trailerLandingGear',
  TrailerSuspension = 'trailerSuspension',
  TrailerAxleType = 'trailerAxleType',
  TrailerLiftgateType = 'trailerLiftgateType',
  CarrierCrmOpportunityStage = 'carrierCrmOpportunityStage',
  CustomerCrmOpportunityStage = 'customerCrmOpportunityStage',
  PowerEngineMake = 'powerEngineMake',
  PowerEngineModel = 'powerEngineModel',
  PowerTransMake = 'powerTransMake',
  PowerTransModel = 'powerTransModel',
  PowerFifthWheel = 'powerFifthWheel',
  CustomerCrmOpportunityPricingStrategy = 'customerCrmOpportunityPricingStrategy',
  CarrierCrmOpportunityPricingStrategy = 'carrierCrmOpportunityPricingStrategy',
  CustomerCrmOpportunitySolution = 'customerCrmOpportunitySolution',
  CarrierCrmOpportunitySolution = 'carrierCrmOpportunitySolution',
  CustomerCrmOpportunitySolutionType = 'customerCrmOpportunitySolutionType',
  CarrierCrmOpportunitySolutionType = 'carrierCrmOpportunitySolutionType',
  NoteType = 'noteType',
  CustomerCrmOpportunityStatusV2 = 'customerCrmOpportunityStatusV2',
  CarrierCrmOpportunityStatusV2 = 'carrierCrmOpportunityStatusV2',
  Temperature = 'Temperature',
  MaximumRoutesOnLoad = 'maximumRoutesOnLoad',
  CustomerCrmInteractionsOutcome = 'customerCrmInteractionsOutcome',
  CarrierCrmInteractionsStatus = 'carrierCrmInteractionsStatus',
  LtlCarrierTenderingPreference = 'ltlCarrierTenderingPreference',
  LtlInterliningType = 'ltlInterliningType',
  /** currency */
  Currency = 'currency',
  DriverHistoryTrainingType = 'driverHistoryTrainingType',
  DriverHistoryTrainingOutcome = 'driverHistoryTrainingOutcome',
  /** activationStatusCancelReason */
  ActivationStatusCancelReason = 'activationStatusCancelReason',
  /** activationStatusHoldReason */
  ActivationStatusHoldReason = 'activationStatusHoldReason',
  /** activationStatusType */
  ActivationStatusType = 'activationStatusType',
  /**
   * addressType
   * @deprecated AddressType is deprecated as it is now a base class for other entity-based address types.  Use the CustomerAddressType or  CarrierAddressType for Customer, Carrier respectively
   */
  AddressType = 'addressType',
  /** advanceDenialType */
  AdvanceDenialType = 'advanceDenialType',
  /** advanceType */
  AdvanceType = 'advanceType',
  /** bounceReason */
  BounceReason = 'bounceReason',
  /** bounceType */
  BounceType = 'bounceType',
  /** carrierAddressType */
  CarrierAddressType = 'carrierAddressType',
  /** carrierCertificationType */
  CarrierCertificationType = 'carrierCertificationType',
  /** carrierContactType */
  CarrierContactType = 'carrierContactType',
  /** carrierCrmOpportunityStatus */
  CarrierCrmOpportunityStatus = 'carrierCrmOpportunityStatus',
  /** carrierCrmOpportunityType */
  CarrierCrmOpportunityType = 'carrierCrmOpportunityType',
  /** carrierDocumentSourceType */
  CarrierDocumentSourceType = 'carrierDocumentSourceType',
  /** carrierDocumentType */
  CarrierDocumentType = 'carrierDocumentType',
  /** carrierIdentifierType */
  CarrierIdentifierType = 'carrierIdentifierType',
  /** carrierInsuranceType */
  CarrierInsuranceType = 'carrierInsuranceType',
  /** carrierInteractionFrequency */
  CarrierInteractionFrequency = 'carrierInteractionFrequency',
  /** carrierInteractionOutcome */
  CarrierInteractionOutcome = 'carrierInteractionOutcome',
  /** carrierInteractionType */
  CarrierInteractionType = 'carrierInteractionType',
  /** carrierInteractionVia */
  CarrierInteractionVia = 'carrierInteractionVia',
  /** carrierLeadSource */
  CarrierLeadSource = 'carrierLeadSource',
  /** carrierLevelType */
  CarrierLevelType = 'carrierLevelType',
  /** carrierOpportunityType */
  CarrierOpportunityType = 'carrierOpportunityType',
  /** carrierPaymentTerm */
  CarrierPaymentTerm = 'carrierPaymentTerm',
  /** carrierSalesProgressType */
  CarrierSalesProgressType = 'carrierSalesProgressType',
  /** carrierServiceType */
  CarrierServiceType = 'carrierServiceType',
  /** carrierStandingReasonCode */
  CarrierStandingReasonCode = 'carrierStandingReasonCode',
  /** carrierStandingStatusType */
  CarrierStandingStatusType = 'carrierStandingStatusType',
  /** carrierStandingType */
  CarrierStandingType = 'carrierStandingType',
  /** chargeType */
  ChargeType = 'chargeType',
  /** chatType */
  ChatType = 'chatType',
  /** commissionBasis */
  CommissionBasis = 'commissionBasis',
  /** commissionMarginBasis */
  CommissionMarginBasis = 'commissionMarginBasis',
  /** commissionPayTrigger */
  CommissionPayTrigger = 'commissionPayTrigger',
  /** commissionPlanAppliesTo */
  CommissionPlanAppliesTo = 'commissionPlanAppliesTo',
  /** commissionType */
  CommissionType = 'commissionType',
  /** commodityFreightClassType */
  CommodityFreightClassType = 'commodityFreightClassType',
  /** commodityHazmatClassType */
  CommodityHazmatClassType = 'commodityHazmatClassType',
  /** commodityLoadOnType */
  CommodityLoadOnType = 'commodityLoadOnType',
  /** commodityPackagingType */
  CommodityPackagingType = 'commodityPackagingType',
  /** companyOverheadType */
  CompanyOverheadType = 'companyOverheadType',
  /** compensationPeriod */
  CompensationPeriod = 'compensationPeriod',
  /** configureInvoiceLanguage */
  ConfigureInvoiceLanguage = 'configureInvoiceLanguage',
  /**
   * contactType
   * @deprecated ContactType is deprecated as it is now a base class for other entity-based address types.  Use the CustomerContactType or CarrierContactType for Customer, Carrier, and Facility respectively
   */
  ContactType = 'contactType',
  /** costQuoteType */
  CostQuoteType = 'costQuoteType',
  /** country */
  Country = 'country',
  /** creditProvider */
  CreditProvider = 'creditProvider',
  /** customerAddressType */
  CustomerAddressType = 'customerAddressType',
  /** customerContactType */
  CustomerContactType = 'customerContactType',
  /** customerCreditStatus */
  CustomerCreditStatus = 'customerCreditStatus',
  /** customerCrmOpportunityStatus */
  CustomerCrmOpportunityStatus = 'customerCrmOpportunityStatus',
  /** customerCrmOpportunityType */
  CustomerCrmOpportunityType = 'customerCrmOpportunityType',
  /** customerDocumentProvider */
  CustomerDocumentProvider = 'customerDocumentProvider',
  /** customerDocumentType */
  CustomerDocumentType = 'customerDocumentType',
  /** customerGroupType */
  CustomerGroupType = 'customerGroupType',
  /** customerLeadSource */
  CustomerLeadSource = 'customerLeadSource',
  /** customerLevelType */
  CustomerLevelType = 'customerLevelType',
  /** customerOpportunityType */
  CustomerOpportunityType = 'customerOpportunityType',
  /** customerOverheadType */
  CustomerOverheadType = 'customerOverheadType',
  /** customerPaymentTerm */
  CustomerPaymentTerm = 'customerPaymentTerm',
  /** customerRepType */
  CustomerRepType = 'customerRepType',
  /** customerSalesProgressType */
  CustomerSalesProgressType = 'customerSalesProgressType',
  /** customerStatusType */
  CustomerStatusType = 'customerStatusType',
  /** declinedReason */
  DeclinedReason = 'declinedReason',
  /** division */
  Division = 'division',
  /** driverReloadIntent */
  DriverReloadIntent = 'driverReloadIntent',
  /** electronicTrackingMethod */
  ElectronicTrackingMethod = 'electronicTrackingMethod',
  /** electronicTrackingType */
  ElectronicTrackingType = 'electronicTrackingType',
  /** employeeCompanyLevel */
  EmployeeCompanyLevel = 'employeeCompanyLevel',
  /** employeeDepartment */
  EmployeeDepartment = 'employeeDepartment',
  /** employeeDepartmentLevel */
  EmployeeDepartmentLevel = 'employeeDepartmentLevel',
  /**
   * employeeDivision
   * @deprecated EmployeeDivision is deprecated as it is has been renamed to Division
   */
  EmployeeDivision = 'employeeDivision',
  /** employeeGender */
  EmployeeGender = 'employeeGender',
  /** employeeGroup */
  EmployeeGroup = 'employeeGroup',
  /** employeeOffice */
  EmployeeOffice = 'employeeOffice',
  /** employeeRole */
  EmployeeRole = 'employeeRole',
  /** employeeShirtSize */
  EmployeeShirtSize = 'employeeShirtSize',
  /** employeeStatus */
  EmployeeStatus = 'employeeStatus',
  /** employeeSuffix */
  EmployeeSuffix = 'employeeSuffix',
  /** employeeTime */
  EmployeeTime = 'employeeTime',
  /** employeeType */
  EmployeeType = 'employeeType',
  /** facilityAddressType */
  FacilityAddressType = 'facilityAddressType',
  /** facilityContactType */
  FacilityContactType = 'facilityContactType',
  /** facilityIdentifierType */
  FacilityIdentifierType = 'facilityIdentifierType',
  /** facilityType */
  FacilityType = 'facilityType',
  /** geographyType */
  GeographyType = 'geographyType',
  /** identifierType */
  IdentifierType = 'identifierType',
  /** interactionFrequency */
  InteractionFrequency = 'interactionFrequency',
  /** interactionOutcome */
  InteractionOutcome = 'interactionOutcome',
  /** interactionType */
  InteractionType = 'interactionType',
  /** interactionVia */
  InteractionVia = 'interactionVia',
  /** invoiceDistributionMethod */
  InvoiceDistributionMethod = 'invoiceDistributionMethod',
  /** lateArrivalReasonCode */
  LateArrivalReasonCode = 'lateArrivalReasonCode',
  /** lateArrivalWindow */
  LateArrivalWindow = 'lateArrivalWindow',
  /** loadDirectionType */
  LoadDirectionType = 'loadDirectionType',
  /** loadDocumentSource */
  LoadDocumentSource = 'loadDocumentSource',
  /** loadDocumentType */
  LoadDocumentType = 'loadDocumentType',
  /** loadReferenceType */
  LoadReferenceType = 'loadReferenceType',
  /** loadSize */
  LoadSize = 'loadSize',
  /** marginBasis */
  MarginBasis = 'marginBasis',
  /** orderCostAllocationMethod */
  OrderCostAllocationMethod = 'orderCostAllocationMethod',
  /** orderDivisionType */
  OrderDivisionType = 'orderDivisionType',
  /** orderFinancialStatusType */
  OrderFinancialStatusType = 'orderFinancialStatusType',
  /** orderRelationType */
  OrderRelationType = 'orderRelationType',
  /** orderRequirement */
  OrderRequirement = 'orderRequirement',
  /** payTrigger */
  PayTrigger = 'payTrigger',
  /** paymentMethod */
  PaymentMethod = 'paymentMethod',
  /** quotePriceTier */
  QuotePriceTier = 'quotePriceTier',
  /** quoteTenderType */
  QuoteTenderType = 'quoteTenderType',
  /** quoteType */
  QuoteType = 'quoteType',
  /** routeActivationStatus */
  RouteActivationStatus = 'routeActivationStatus',
  /** routeFinancialStatusType */
  RouteFinancialStatusType = 'routeFinancialStatusType',
  /** routeRateAllocationMethod */
  RouteRateAllocationMethod = 'routeRateAllocationMethod',
  /** schedulingSystemType */
  SchedulingSystemType = 'schedulingSystemType',
  /** serviceLevel */
  ServiceLevel = 'serviceLevel',
  /** stopEventType */
  StopEventType = 'stopEventType',
  /** stopRequirement */
  StopRequirement = 'stopRequirement',
  /** tenantScac */
  TenantScac = 'tenantScac',
  /** tenderStatusType */
  TenderStatusType = 'tenderStatusType',
  /** timelineEventEntry */
  TimelineEventEntry = 'timelineEventEntry',
  /** timelineEventType */
  TimelineEventType = 'timelineEventType',
  /** timelineJournalEntryType */
  TimelineJournalEntryType = 'timelineJournalEntryType',
  /** trackingSystem */
  TrackingSystem = 'trackingSystem',
  /** trackingUpdateSources */
  TrackingUpdateSources = 'trackingUpdateSources',
  /** trailerType */
  TrailerType = 'trailerType',
  /** transportMode */
  TransportMode = 'transportMode',
  /** vendorInvoiceStatus */
  VendorInvoiceStatus = 'vendorInvoiceStatus'
}

export type Configuration = {
  readonly entityType: EntityType;
  readonly entityId?: InputMaybe<Scalars['ID']>;
  readonly useDefaults?: InputMaybe<Scalars['Boolean']>;
  readonly transactionDate?: InputMaybe<Scalars['String']>;
};

export type ConfigurationDistanceRequest = {
  readonly stops: ReadonlyArray<DistanceRequestStop>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly config?: InputMaybe<Configuration>;
};

export type ConfigurationMileageRequest = {
  readonly stops: ReadonlyArray<LatLongPair>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly config?: InputMaybe<Configuration>;
};

export type Contact = {
  readonly __typename?: 'Contact';
  readonly active: Scalars['Boolean'];
  readonly chatType?: Maybe<KeyValue>;
  readonly chatTypeId?: Maybe<Scalars['ID']>;
  readonly chatUsername?: Maybe<Scalars['String']>;
  readonly contactType: KeyValue;
  readonly contactTypeId: Scalars['ID'];
  readonly contactableId: Scalars['ID'];
  readonly contactableType: Scalars['String'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly emailAddress?: Maybe<Scalars['String']>;
  readonly extension?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly main: Scalars['Boolean'];
  readonly name?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly thirdPartyId?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
};

export type ContactDetail = {
  readonly email: Scalars['String'];
  readonly contactName: Scalars['String'];
  readonly contactPhone: Scalars['String'];
};

export type ContactInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly chatTypeId?: InputMaybe<Scalars['ID']>;
  readonly chatUsername?: InputMaybe<Scalars['String']>;
  readonly contactTypeId?: InputMaybe<Scalars['ID']>;
  readonly contactableId?: InputMaybe<Scalars['ID']>;
  readonly contactableType?: InputMaybe<Scalars['String']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export enum ContactTypeEnum {
  Cc = 'Cc',
  Bcc = 'Bcc'
}

export type ContainerConnection = {
  readonly __typename?: 'ContainerConnection';
  readonly edges?: Maybe<ReadonlyArray<Maybe<ContainerGraphQlEdge>>>;
  readonly pageInfo?: Maybe<PageInfo>;
  readonly totalCount?: Maybe<Scalars['Int']>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<TrucksUserError>>>;
};

export type ContainerFilter = {
  readonly carrierId: Scalars['ID'];
  readonly containerProgramId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly containerTypeId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly containerNumber?: InputMaybe<Scalars['String']>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly destinationRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly destinationRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly endDateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** A partial or full city name */
  readonly originCity?: InputMaybe<Scalars['String']>;
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly originRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly reservationNumber?: InputMaybe<Scalars['String']>;
  readonly reservationTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly sourceTypeId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly startDateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
};

export type ContainerGraphQl = {
  readonly __typename?: 'ContainerGraphQL';
  readonly carrierId: Scalars['ID'];
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly createdByUserId: Scalars['ID'];
  readonly containerNumber?: Maybe<Scalars['String']>;
  readonly containerTypeId: Scalars['ID'];
  readonly containerLength: UnitOfDistance;
  readonly containerProgramId?: Maybe<Scalars['String']>;
  readonly destinationRampFacilityId?: Maybe<Scalars['ID']>;
  readonly deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly endDatetimeUtc?: Maybe<Scalars['ISO8601DateTime']>;
  readonly endTimezone?: Maybe<Scalars['String']>;
  readonly externalSourceId?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly notes?: Maybe<Scalars['String']>;
  /** @deprecated DO NOT USE. Field is no longer supported. */
  readonly originDeadhead: UnitOfDistance;
  readonly originLocation: CapacityLocation;
  readonly originRampFacilityId?: Maybe<Scalars['ID']>;
  readonly previousDraymanId?: Maybe<Scalars['ID']>;
  readonly previousRailroadId?: Maybe<Scalars['ID']>;
  readonly previousRouteId?: Maybe<Scalars['ID']>;
  readonly reservationNumber?: Maybe<Scalars['String']>;
  readonly reservationTypeId?: Maybe<Scalars['ID']>;
  readonly startDatetimeUtc: Scalars['ISO8601DateTime'];
  readonly startTimezone: Scalars['String'];
  readonly sourceTypeId: Scalars['String'];
  readonly tenantInternalId?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedByUserId: Scalars['ID'];
  /** @deprecated Use originRampFacility instead */
  readonly legacyOriginRampFacility?: Maybe<Facility>;
  readonly originRampFacility?: Maybe<FacilityV2>;
  /** @deprecated Use destinationRampFacility instead */
  readonly legacyDestinationRampFacility?: Maybe<Facility>;
  readonly destinationRampFacility?: Maybe<FacilityV2>;
  /** @deprecated Use carrier instead */
  readonly legacyCarrier?: Maybe<Carrier>;
  readonly carrier?: Maybe<CarrierV2>;
  /** @deprecated Use previousDrayman instead */
  readonly legacyPreviousDrayman?: Maybe<Carrier>;
  readonly previousDrayman?: Maybe<CarrierV2>;
  /** @deprecated Use previousRailroad instead */
  readonly legacyPreviousRailroad?: Maybe<Carrier>;
  readonly previousRailroad?: Maybe<CarrierV2>;
  readonly previousRoute?: Maybe<LoadRouteV2>;
  /** @deprecated Use createdByUser instead */
  readonly legacyCreatedByUser?: Maybe<User>;
  readonly createdByUser?: Maybe<UserV2>;
  /** @deprecated Use updatedByUser instead */
  readonly legacyUpdatedByUser?: Maybe<User>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly containerProgram?: Maybe<KeyValueType>;
  readonly containerType?: Maybe<KeyValueType>;
  readonly reservationType?: Maybe<KeyValueType>;
};

export type ContainerGraphQlEdge = {
  readonly __typename?: 'ContainerGraphQLEdge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<ContainerGraphQl>;
};

/** Container Match Result from ElasticSearch (v2) */
export type ContainerMatch = {
  readonly __typename?: 'ContainerMatch';
  readonly id: Scalars['ID'];
  readonly routeId: Scalars['String'];
  readonly loadId: Scalars['String'];
  readonly capacityNeed?: Maybe<ReadonlyArray<CapacityNeed>>;
  readonly originDeadheadMileage?: Maybe<Scalars['Float']>;
  readonly destinationDeadheadMileage?: Maybe<Scalars['Float']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly routePickupCity?: Maybe<Scalars['String']>;
  readonly routePickupState?: Maybe<Scalars['String']>;
  readonly routePickupCountry?: Maybe<Scalars['String']>;
  readonly routePickupStartDate?: Maybe<Scalars['DateTime']>;
  readonly routePickupEndDate?: Maybe<Scalars['DateTime']>;
  readonly routePickupTimeZone?: Maybe<Scalars['String']>;
  readonly routePickupStartDay?: Maybe<Scalars['String']>;
  readonly routePickupEndDay?: Maybe<Scalars['String']>;
  readonly routeFirstStopDateRange?: Maybe<AvailableDateRange>;
  readonly routeFirstStopLiveType?: Maybe<ScheduleDropTypeEnum>;
  readonly routeDeliveryCity?: Maybe<Scalars['String']>;
  readonly routeDeliveryState?: Maybe<Scalars['String']>;
  readonly routeDeliveryCountry?: Maybe<Scalars['String']>;
  readonly routeDeliveryStartDate?: Maybe<Scalars['DateTime']>;
  readonly routeDeliveryEndDate?: Maybe<Scalars['DateTime']>;
  readonly routeDeliveryTimeZone?: Maybe<Scalars['String']>;
  readonly routeDeliveryStartDay?: Maybe<Scalars['String']>;
  readonly routeDeliveryEndDay?: Maybe<Scalars['String']>;
  readonly routeLastStopDateRange?: Maybe<AvailableDateRange>;
  readonly routeLastStopLiveType?: Maybe<ScheduleDropTypeEnum>;
  readonly routeLength?: Maybe<Scalars['Int']>;
  readonly routeTrailerLength?: Maybe<Length>;
  readonly routeCommodityMinTemperature?: Maybe<TemperatureV2>;
  readonly stopCount?: Maybe<Scalars['Int']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerRepName?: Maybe<Scalars['String']>;
  readonly routeTrailerTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly segmentCode?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly requirements?: Maybe<ReadonlyArray<OrderRequirementInfo>>;
  readonly hotRoute?: Maybe<Scalars['Boolean']>;
  readonly tmsType?: Maybe<Scalars['String']>;
  readonly mainCustomerRepEmployeeId?: Maybe<Scalars['String']>;
  readonly expectedMaxWeight?: Maybe<Scalars['Float']>;
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly equipment?: Maybe<Scalars['String']>;
  readonly routeLoadRouteSequence?: Maybe<Fraction>;
  readonly containerId: Scalars['String'];
  readonly containerOriginCity?: Maybe<Scalars['String']>;
  readonly containerOriginState?: Maybe<Scalars['String']>;
  readonly containerOriginRampFacilityId?: Maybe<Scalars['String']>;
  readonly containerOriginRampFacilityName?: Maybe<Scalars['String']>;
  readonly containerDestCity?: Maybe<Scalars['String']>;
  readonly containerDestState?: Maybe<Scalars['String']>;
  readonly containerDestRampFacilityId?: Maybe<Scalars['String']>;
  readonly containerDestRampFacilityName?: Maybe<Scalars['String']>;
  readonly maxCost?: Maybe<Scalars['Float']>;
  readonly route?: Maybe<AvailableRoute>;
  readonly container?: Maybe<AvailableContainer>;
};

export type ContainerMatchConnection = {
  readonly __typename?: 'ContainerMatchConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<ContainerMatchEdge>;
};

export type ContainerMatchEdge = {
  readonly __typename?: 'ContainerMatchEdge';
  readonly node: ContainerMatch;
  /** Used in `before` and `after` args */
  readonly cursor: Scalars['String'];
};

/** Gate Reservation Match Filter */
export type ContainerMatchFilter = {
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly sortDirection?: InputMaybe<Scalars['String']>;
  readonly matchId?: InputMaybe<Scalars['String']>;
  readonly routeId?: InputMaybe<Scalars['String']>;
  readonly routeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadId?: InputMaybe<Scalars['String']>;
  readonly capacityNeed?: InputMaybe<ReadonlyArray<CapacityNeed>>;
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly carrierName?: InputMaybe<Scalars['String']>;
  readonly routeActivationStatus?: InputMaybe<Scalars['String']>;
  readonly routeActivationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly originDeadheadInMiles?: InputMaybe<Scalars['Float']>;
  readonly destinationDeadheadInMiles?: InputMaybe<Scalars['Float']>;
  readonly includeDeleted?: InputMaybe<Scalars['Boolean']>;
  readonly routeNumber?: InputMaybe<Scalars['String']>;
  readonly routePickupCity?: InputMaybe<Scalars['String']>;
  readonly routePickupState?: InputMaybe<Scalars['String']>;
  readonly routePickupStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routePickupCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routePickupDate?: InputMaybe<Scalars['DateTime']>;
  readonly routePickupTimeZone?: InputMaybe<Scalars['String']>;
  readonly routeDeliveryCity?: InputMaybe<Scalars['String']>;
  readonly routeDeliveryState?: InputMaybe<Scalars['String']>;
  readonly routeDeliveryStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDeliveryCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDeliveryDate?: InputMaybe<Scalars['DateTime']>;
  readonly routeDeliveryTimeZone?: InputMaybe<Scalars['String']>;
  readonly routeCommodityMinTemperature?: InputMaybe<TemperatureV2Input>;
  readonly routeLength?: InputMaybe<Scalars['Int']>;
  readonly stopCount?: InputMaybe<Scalars['Int']>;
  readonly maxCost?: InputMaybe<Scalars['Float']>;
  readonly routeFirstStopLiveType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeLastStopLiveType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly customerName?: InputMaybe<Scalars['String']>;
  readonly customerRepName?: InputMaybe<Scalars['String']>;
  readonly equipment?: InputMaybe<Scalars['String']>;
  readonly routeTrailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  readonly requirements?: InputMaybe<Scalars['String']>;
  readonly hotRoute?: InputMaybe<Scalars['Boolean']>;
  readonly routeTrailerLength?: InputMaybe<UnitOfLengthInput>;
  readonly containerId?: InputMaybe<Scalars['String']>;
  readonly containerIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  readonly timeZone?: InputMaybe<Scalars['String']>;
  readonly containerOriginCity?: InputMaybe<Scalars['String']>;
  readonly containerOriginStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly containerOriginRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly containerOriginRampFacilityName?: InputMaybe<Scalars['String']>;
  readonly containerDestCity?: InputMaybe<Scalars['String']>;
  readonly containerDestStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly containerDestRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly containerDestRampFacilityName?: InputMaybe<Scalars['String']>;
};

export type ContainerPayloadWithErrorsAndContainer = {
  readonly __typename?: 'ContainerPayloadWithErrorsAndContainer';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly container?: Maybe<ContainerGraphQl>;
};

export type ContainerSort = {
  readonly key: ContainerSortKeysEnum;
  readonly direction: TruckEntrySortDirectionsEnum;
};

export enum ContainerSortKeysEnum {
  ContainerNumber = 'containerNumber',
  ReservationNumber = 'reservationNumber',
  StartDatetimeUtc = 'startDatetimeUtc',
  EndDatetimeUtc = 'endDatetimeUtc',
  SourceTypeId = 'sourceTypeId',
  UpdatedAt = 'updatedAt',
  ContainerProgramId = 'containerProgramId',
  ReservationTypeId = 'reservationTypeId',
  OriginCity = 'originCity',
  OriginState = 'originState',
  OriginDeadhead = 'originDeadhead'
}

export type ContentTemplate = {
  readonly __typename?: 'ContentTemplate';
  readonly templateId?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly renderType?: Maybe<ContentTemplateRenderType>;
  readonly contentType: ContentTemplateContentType;
  readonly components: ReadonlyArray<ContentTemplateComponentsItem>;
  readonly sampleData?: Maybe<Scalars['String']>;
  readonly renderedTemplate?: Maybe<RenderedTemplate>;
};

export type ContentTemplateComponentsItem = {
  readonly __typename?: 'ContentTemplateComponentsItem';
  readonly name: ContentTemplateComponentsItemName;
  readonly value: Scalars['String'];
  readonly schema: TemplateSchema;
};

export type ContentTemplateComponentsItemInput = {
  readonly name: ContentTemplateComponentsItemName;
  readonly value: Scalars['String'];
  readonly schema: TemplateSchemaInput;
};

export enum ContentTemplateComponentsItemName {
  Subject = 'Subject',
  Body = 'Body'
}

export enum ContentTemplateContentType {
  Email = 'Email'
}

export type ContentTemplateInput = {
  readonly templateId?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly renderType?: InputMaybe<ContentTemplateRenderType>;
  readonly contentType: ContentTemplateContentType;
  readonly components: ReadonlyArray<ContentTemplateComponentsItemInput>;
  readonly sampleData?: InputMaybe<Scalars['String']>;
};

export enum ContentTemplateRenderType {
  Json = 'JSON',
  Handlebars = 'Handlebars',
  Nunjucks = 'Nunjucks'
}

export type ConvertSpotQuoteToLoadDetail = {
  readonly __typename?: 'ConvertSpotQuoteToLoadDetail';
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly loadCode?: Maybe<Scalars['String']>;
};

export type ConvertSpotQuoteToLoadInput = {
  readonly spotQuoteId: Scalars['ID'];
};

export type ConvertSpotQuoteToLoadPayload = {
  readonly __typename?: 'ConvertSpotQuoteToLoadPayload';
  readonly data?: Maybe<ConvertSpotQuoteToLoadDetail>;
  readonly errors?: Maybe<ReadonlyArray<SpotQuoteError>>;
};

export type CoordinateInput = {
  readonly lat: Scalars['Float'];
  readonly lon: Scalars['Float'];
};

export type CoordinateStop = {
  readonly __typename?: 'CoordinateStop';
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

/** The Cost Detail */
export type CostDetail = {
  readonly __typename?: 'CostDetail';
  readonly advanceEid?: Maybe<Scalars['Long']>;
  readonly allowRerating?: Maybe<Scalars['Boolean']>;
  readonly blumeInvoiceNumber?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly chargeTypeId?: Maybe<Scalars['ID']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly costPer?: Maybe<Scalars['Decimal']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdById?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly currencyDiscountAmount?: Maybe<Scalars['Decimal']>;
  readonly description: Scalars['String'];
  readonly enteredCostPer?: Maybe<Scalars['Decimal']>;
  readonly enteredTotalCost?: Maybe<Scalars['Decimal']>;
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: Maybe<Scalars['Date']>;
  readonly glAccountCode?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly isAdjustment: Scalars['Boolean'];
  readonly isBlumeCharge?: Maybe<Scalars['Boolean']>;
  /** @deprecated No longer necessary with negatives in cost per. */
  readonly isCredit?: Maybe<Scalars['Boolean']>;
  readonly load?: Maybe<Load>;
  readonly loadId: Scalars['ID'];
  readonly loadV2?: Maybe<LoadV2>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeId: Scalars['ID'];
  readonly routeLifeCycleStatus?: Maybe<RouteLifeCycleStatusEnum>;
  /** @deprecated don't use, relationship does not exist */
  readonly routeVendor?: Maybe<RouteVendor>;
  readonly routeVendorType?: Maybe<RouteVendorTypeEnum>;
  readonly segment1?: Maybe<Scalars['String']>;
  readonly segment10?: Maybe<Scalars['String']>;
  readonly segment2?: Maybe<Scalars['String']>;
  readonly segment3?: Maybe<Scalars['String']>;
  readonly segment4?: Maybe<Scalars['String']>;
  readonly segment5?: Maybe<Scalars['String']>;
  readonly segment6?: Maybe<Scalars['String']>;
  readonly segment7?: Maybe<Scalars['String']>;
  readonly segment8?: Maybe<Scalars['String']>;
  readonly segment9?: Maybe<Scalars['String']>;
  /** @deprecated Use cost per. */
  readonly signedCostPer?: Maybe<Scalars['Decimal']>;
  /** @deprecated Use total cost. */
  readonly signedTotalCost?: Maybe<Scalars['Decimal']>;
  readonly statusId: StatusEnum;
  readonly stopId?: Maybe<Scalars['ID']>;
  readonly totalCost?: Maybe<Scalars['Decimal']>;
  readonly unitsDecimal?: Maybe<Scalars['Decimal']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedById?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly vendorGlCode?: Maybe<Scalars['String']>;
  readonly vendorId: Scalars['ID'];
  readonly vendorRouteFinancialStatus?: Maybe<VendorFinancialStatusEnum>;
  readonly voucherHeader?: Maybe<VoucherHeader>;
};

/** The input type for new Cost Detail. */
export type CostDetailAddInput = {
  readonly blumeInvoiceNumber?: InputMaybe<Scalars['String']>;
  readonly chargeTypeId?: InputMaybe<Scalars['ID']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly costPer?: InputMaybe<Scalars['Decimal']>;
  readonly currency?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly enteredCostPer?: InputMaybe<Scalars['Decimal']>;
  readonly exchangeRate?: InputMaybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: InputMaybe<Scalars['Date']>;
  readonly loadId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly routeVendorType?: InputMaybe<RouteVendorTypeEnum>;
  readonly stopId?: InputMaybe<Scalars['ID']>;
  readonly unitsDecimal?: InputMaybe<Scalars['Decimal']>;
  readonly vendorId: Scalars['ID'];
};

export type CostDetailChargeTypeConfigurationResponse = {
  readonly __typename?: 'CostDetailChargeTypeConfigurationResponse';
  readonly carrierId?: Maybe<Scalars['Guid']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: Maybe<Scalars['String']>;
  readonly cost?: Maybe<Scalars['Decimal']>;
  readonly customerId?: Maybe<Scalars['Guid']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly isCredit: Scalars['Boolean'];
  readonly isDefault: Scalars['Boolean'];
  readonly maximumTotalCost?: Maybe<Scalars['Decimal']>;
  readonly minimumTotalCost?: Maybe<Scalars['Decimal']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly perUnit?: Maybe<CtcPerUnitTypeEnum>;
  readonly size?: Maybe<Scalars['String']>;
};

/** Represents the cost detail object when processing a Voucher. */
export type CostDetailProcessingInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type CostDetailsFilter = {
  readonly applyCreditChargeTypeModifier?: InputMaybe<Scalars['Boolean']>;
  readonly includeAdvanceCostDetails?: InputMaybe<Scalars['Boolean']>;
  readonly loadId: Scalars['ID'];
  /** DEPRECATED: This is not used. */
  readonly vendorId?: InputMaybe<Scalars['ID']>;
};

/** The input type for updating Cost Detail. */
export type CostDetailUpdateInput = {
  readonly blumeInvoiceNumber?: InputMaybe<Scalars['String']>;
  readonly chargeTypeId?: InputMaybe<Scalars['ID']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly costPer?: InputMaybe<Scalars['Decimal']>;
  readonly currency?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly enteredCostPer?: InputMaybe<Scalars['Decimal']>;
  readonly exchangeRate?: InputMaybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: InputMaybe<Scalars['Date']>;
  readonly id: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly routeVendorType?: InputMaybe<RouteVendorTypeEnum>;
  readonly stopId?: InputMaybe<Scalars['ID']>;
  readonly unitsDecimal?: InputMaybe<Scalars['Decimal']>;
  readonly vendorId: Scalars['ID'];
};

export type CostQuoteConnection = {
  readonly __typename?: 'CostQuoteConnection';
  readonly edges: ReadonlyArray<Maybe<CostQuoteEdge>>;
  readonly totalCount: Scalars['Int'];
  readonly pageInfo: PageInfo;
};

export enum CostQuoteDistanceUnit {
  Feet = 'Feet',
  Inches = 'Inches',
  Kilometers = 'Kilometers',
  Meters = 'Meters',
  Miles = 'Miles'
}

export type CostQuoteEdge = {
  readonly __typename?: 'CostQuoteEdge';
  readonly cursor: Scalars['String'];
  readonly node: CarrierQuoteResponse;
};

export type CostQuoteGeographyRadiusSearchInput = {
  readonly distance?: InputMaybe<CostQuoteUnitOfDistanceInput>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
};

export enum CostQuoteGeographyTypeEnum {
  City = 'City',
  Country = 'Country',
  Facility = 'Facility',
  State = 'State'
}

export type CostQuoteLaneStopsInput = {
  readonly geographyId?: InputMaybe<Scalars['String']>;
  readonly geographyType?: InputMaybe<CostQuoteGeographyTypeEnum>;
  readonly stopType?: InputMaybe<StopTypeEnum>;
  readonly sequence?: InputMaybe<Scalars['Int']>;
};

export type CostQuoteLaneStopsInputV2 = {
  readonly geographyId?: InputMaybe<Scalars['String']>;
  readonly geographyType?: InputMaybe<Scalars['String']>;
  readonly stopType?: InputMaybe<Scalars['String']>;
  readonly sequence?: InputMaybe<Scalars['Int']>;
  readonly postalCodeCountry?: InputMaybe<Scalars['String']>;
};

export type CostQuoteLineItem = {
  readonly __typename?: 'CostQuoteLineItem';
  readonly id?: Maybe<Scalars['ID']>;
  readonly chargeType?: Maybe<Scalars['String']>;
  readonly chargeTypeId?: Maybe<Scalars['ID']>;
  readonly quoteId?: Maybe<Scalars['ID']>;
  readonly unit?: Maybe<Scalars['Decimal']>;
  readonly exchangeCurrency?: Maybe<CurrencyValue>;
  readonly exchangeCurrencyV2?: Maybe<CurrencyOutputValue>;
  readonly rateCurrency?: Maybe<CurrencyValue>;
  readonly rateCurrencyV2?: Maybe<CurrencyOutputValue>;
  readonly homeCurrency?: Maybe<CurrencyValue>;
  readonly homeCurrencyV2?: Maybe<CurrencyOutputValue>;
  readonly totalRateCurrency?: Maybe<CurrencyValue>;
  readonly totalRateCurrencyV2?: Maybe<CurrencyOutputValue>;
};

export type CostQuoteLineItemInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly chargeType?: InputMaybe<Scalars['String']>;
  readonly unit?: InputMaybe<Scalars['Decimal']>;
  readonly exchangeCurrency?: InputMaybe<CurrencyInputType>;
  readonly rateCurrency?: InputMaybe<CurrencyInputType>;
  readonly homeCurrency?: InputMaybe<CurrencyInputType>;
  readonly totalRateCurrency?: InputMaybe<CurrencyInputType>;
  readonly isDelete?: InputMaybe<Scalars['Boolean']>;
};

export type CostQuotePaginatedFilter = {
  readonly costQuoteType?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly effectiveDateTime?: InputMaybe<Scalars['DateTimeOffset']>;
  readonly minimum?: InputMaybe<Scalars['Decimal']>;
  readonly expirationDateTime?: InputMaybe<Scalars['DateTimeOffset']>;
  readonly loadSize?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly scac?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly multipleStops?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Boolean']>>>;
  readonly quotedById?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly minHeight?: InputMaybe<CostQuoteUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<CostQuoteUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<CostQuoteUnitOfDistanceInput>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly services?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly transportModeId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly team?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Boolean']>>>;
  readonly destinationGeographyType?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly destinationStopTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly destinationGeographySearch?: InputMaybe<ReadonlyArray<InputMaybe<CostQuoteGeographyRadiusSearchInput>>>;
  readonly originGeographyType?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly originStopTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly originGeographySearch?: InputMaybe<ReadonlyArray<InputMaybe<CostQuoteGeographyRadiusSearchInput>>>;
  readonly excludeExpired?: InputMaybe<Scalars['Boolean']>;
  readonly laneIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
};

export enum CostQuoteUnitOfCurrency {
  Usd = 'USD'
}

export type CostQuoteUnitOfDistanceInput = {
  readonly unit?: InputMaybe<CostQuoteDistanceUnit>;
  readonly value?: InputMaybe<Scalars['Decimal']>;
};

export type CounterOfferResponseInput = {
  readonly offerId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly counterPrice?: InputMaybe<CurrencyInput>;
  readonly counterPriceV2?: InputMaybe<CurrencyInputV2>;
  /** Will be overwritten with OfferResponseType.COUNTER */
  readonly type?: InputMaybe<OfferResponseType>;
};

export type CountriesV2 = {
  readonly __typename?: 'countriesV2';
  readonly countriesList?: Maybe<ReadonlyArray<Maybe<CountryType>>>;
};

export type CountryType = {
  readonly __typename?: 'CountryType';
  readonly countryCode?: Maybe<Scalars['String']>;
  readonly countryName?: Maybe<Scalars['String']>;
};

export type Create_Crm_Interaction_Entity_Lookup_Output = {
  readonly __typename?: 'Create_CRM_Interaction_Entity_Lookup_Output';
  readonly Interaction_Entity_Lookup_Id: Scalars['ID'];
  readonly Entity_Type?: Maybe<Scalars['String']>;
  readonly Entity_Name?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CreateAddressInput = {
  readonly addressTypeId: Scalars['ID'];
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly entity: EntityInput;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isVerified?: InputMaybe<Scalars['Boolean']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly postalCode: Scalars['String'];
  readonly province?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly state: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type CreateAdjustmentItemsChecked = {
  readonly __typename?: 'CreateAdjustmentItemsChecked';
  readonly createAdjustmentItemsCheckedOption?: Maybe<CreateAdjustmentItemsCheckedOptionEnum>;
};

export enum CreateAdjustmentItemsCheckedOptionEnum {
  Unchecked = 'UNCHECKED',
  Checked = 'CHECKED'
}

export type CreateAssetPermanentDriverInput = {
  readonly permanentDriverId?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly permanentDriverIdV1?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetPermanentTractorInput = {
  readonly permanentTractorId?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly permanentTractorIdV1?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetPermanentTrailerInput = {
  readonly permanentTrailerId?: InputMaybe<Scalars['String']>;
  readonly powerId?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly permanentTrailerIdV1?: InputMaybe<Scalars['String']>;
};

export type CreateAssetPowerEquipmentInput = {
  readonly equipmentCd?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly count?: InputMaybe<Scalars['Int']>;
  readonly assetId?: InputMaybe<Scalars['String']>;
  readonly condition?: InputMaybe<Scalars['String']>;
  readonly conditionTerm?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly powerId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly issueDate?: InputMaybe<Scalars['DateTime']>;
  readonly returnDate?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetPowerEquipmentInputV2 = {
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId: Scalars['Int'];
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['Date'];
  readonly returnDate?: InputMaybe<Scalars['Date']>;
  readonly powerId?: InputMaybe<Scalars['String']>;
};

export type CreateAssetPowerIdentifierInput = {
  readonly identifierType?: InputMaybe<Scalars['String']>;
  readonly identifierNumber?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly countryCd?: InputMaybe<Scalars['String']>;
  readonly expirationDt?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly expirationDtV1?: InputMaybe<Scalars['String']>;
  readonly powerId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetPowerIdentifierInputV2 = {
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly powerId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetPowerIdentifierInputV3 = {
  readonly typeTerm: Scalars['String'];
  readonly identifierId: Scalars['String'];
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly powerId?: InputMaybe<Scalars['String']>;
};

export type CreateAssetPowerInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly make?: InputMaybe<Scalars['String']>;
  readonly makeTerm?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly year?: InputMaybe<Scalars['Int']>;
  readonly model?: InputMaybe<Scalars['String']>;
  readonly modelTerm?: InputMaybe<Scalars['String']>;
  /** DEPRECATED: legalToRun is deprecated. Use legalToRunTerm instead */
  readonly legalToRun?: InputMaybe<ReadonlyArray<CreateAssetPowerLegalToRunInput>>;
  /** This field describes the list of countries details where the trailer is legal to run(Ex. Dry Van , CAN) */
  readonly legalToRunTerm?: InputMaybe<ReadonlyArray<CreateAssetPowerLegalToRunInput>>;
  readonly equipmentDetails?: InputMaybe<ReadonlyArray<CreateAssetPowerEquipmentInput>>;
  /** DEPRECATED: identifiers is deprecated. Use identifiersV2 instead */
  readonly identifiers?: InputMaybe<ReadonlyArray<CreateAssetPowerIdentifierInput>>;
  /** A collection of identifiers associated with the power. */
  readonly identifiersV2?: InputMaybe<ReadonlyArray<CreateAssetPowerIdentifierInputV2>>;
  /** DEPRECATED: permanentDriver is deprecated. Use permanentDrivers instead */
  readonly permanentDriver?: InputMaybe<ReadonlyArray<CreateAssetPowerPermanentDriverInput>>;
  /** A collection of permanent driver associated with the power. */
  readonly permanentDrivers?: InputMaybe<ReadonlyArray<CreateAssetPowerPermanentDriverInput>>;
  /** DEPRECATED: permanentTrailer is deprecated. Use permanentTrailers instead */
  readonly permanentTrailer?: InputMaybe<ReadonlyArray<CreateAssetPermanentTrailerInput>>;
  /** A collection of permanent trailer associated with the power. */
  readonly permanentTrailers?: InputMaybe<ReadonlyArray<CreateAssetPermanentTrailerInput>>;
  readonly maintenance?: InputMaybe<ReadonlyArray<CreateAssetPowerMaintenanceInput>>;
  readonly isCARBCompliant?: InputMaybe<Scalars['Boolean']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly powerDevice?: InputMaybe<Scalars['String']>;
  readonly trackingDeviceTerm?: InputMaybe<Scalars['String']>;
  readonly powerModel?: InputMaybe<Scalars['String']>;
  readonly trackingModelTerm?: InputMaybe<Scalars['String']>;
  readonly serialNumber?: InputMaybe<Scalars['String']>;
  readonly powerNumber?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly owner?: InputMaybe<Scalars['String']>;
  readonly ownerTerm?: InputMaybe<Scalars['String']>;
  readonly class?: InputMaybe<Scalars['String']>;
  readonly classTerm?: InputMaybe<Scalars['String']>;
  readonly fleetName?: InputMaybe<Scalars['String']>;
  readonly segment?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly odomoter?: InputMaybe<Scalars['Float']>;
  readonly exteriorColor?: InputMaybe<Scalars['String']>;
  readonly interiorColor?: InputMaybe<Scalars['String']>;
  readonly sleeperType?: InputMaybe<Scalars['String']>;
  readonly sleeperTypeTerm?: InputMaybe<Scalars['String']>;
  readonly axleConfiguration?: InputMaybe<Scalars['String']>;
  readonly axleConfigurationTerm?: InputMaybe<Scalars['String']>;
  readonly suspension?: InputMaybe<Scalars['String']>;
  readonly suspensionTerm?: InputMaybe<Scalars['String']>;
  readonly sleeperSize?: InputMaybe<Scalars['Float']>;
  readonly sleeperSizeTerm?: InputMaybe<Scalars['Float']>;
  readonly sleeperSizeDimensionsTerm?: InputMaybe<Scalars['String']>;
  readonly weight?: InputMaybe<Scalars['String']>;
  readonly engineMake?: InputMaybe<Scalars['String']>;
  readonly engineModel?: InputMaybe<Scalars['String']>;
  readonly engineHP?: InputMaybe<Scalars['Float']>;
  readonly engineBrake?: InputMaybe<Scalars['String']>;
  readonly engineBrakeTerm?: InputMaybe<Scalars['String']>;
  readonly transmissionMake?: InputMaybe<Scalars['String']>;
  readonly transmissionModel?: InputMaybe<Scalars['String']>;
  readonly transmissionSpeed?: InputMaybe<Scalars['Float']>;
  readonly readEndRatio?: InputMaybe<Scalars['Float']>;
  readonly wheelBase?: InputMaybe<Scalars['Float']>;
  readonly tankCapacity1?: InputMaybe<Scalars['Float']>;
  readonly tankCapacity2?: InputMaybe<Scalars['Float']>;
  readonly wheel5?: InputMaybe<Scalars['String']>;
  readonly fifthWheel?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly operatingStatus?: InputMaybe<Scalars['String']>;
  readonly operatingStatusTerm?: InputMaybe<Scalars['String']>;
  readonly serviceStatus?: InputMaybe<Scalars['String']>;
  readonly serviceStatusTerm?: InputMaybe<Scalars['String']>;
  readonly pool?: InputMaybe<Scalars['String']>;
  readonly lastPingLocation?: InputMaybe<Scalars['String']>;
  readonly lastPingDateTm?: InputMaybe<Scalars['String']>;
  readonly lastPingDateTime?: InputMaybe<Scalars['String']>;
  readonly lastFacility?: InputMaybe<Scalars['String']>;
  readonly facilityLocation?: InputMaybe<Scalars['String']>;
  readonly programTerm?: InputMaybe<Scalars['String']>;
  readonly projectTerm?: InputMaybe<Scalars['String']>;
  readonly measurementTerm?: InputMaybe<Scalars['String']>;
  readonly odometerUOMTerm?: InputMaybe<Scalars['String']>;
  readonly weightUOMTerm?: InputMaybe<Scalars['String']>;
  readonly wheelBaseUOMTerm?: InputMaybe<Scalars['String']>;
  readonly tankCapacity1UOMTerm?: InputMaybe<Scalars['String']>;
  readonly tankCapacity2UOMTerm?: InputMaybe<Scalars['String']>;
};

export type CreateAssetPowerInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly code: Scalars['String'];
  readonly makeTerm?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly year?: InputMaybe<Scalars['Int']>;
  readonly modelTerm?: InputMaybe<Scalars['String']>;
  readonly isCARBCompliant?: InputMaybe<Scalars['Boolean']>;
  readonly trackingDevice?: InputMaybe<TrackingDeviceInput>;
  readonly serialNumber?: InputMaybe<Scalars['String']>;
  readonly ownerTerm?: InputMaybe<Scalars['String']>;
  readonly classTerm?: InputMaybe<Scalars['String']>;
  readonly fleetCarrierCode?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly odometerMiles?: InputMaybe<Scalars['Int']>;
  readonly exteriorColor?: InputMaybe<Scalars['String']>;
  readonly interiorColor?: InputMaybe<Scalars['String']>;
  readonly sleeperTypeTerm?: InputMaybe<Scalars['String']>;
  readonly axleConfigurationTerm?: InputMaybe<Scalars['String']>;
  readonly suspensionTerm?: InputMaybe<Scalars['String']>;
  readonly sleeperSizeDimensionsTerm?: InputMaybe<Scalars['String']>;
  readonly powerWeight?: InputMaybe<UnitOfWeightInput>;
  readonly engineMakeTerm?: InputMaybe<Scalars['String']>;
  readonly engineModelTerm?: InputMaybe<Scalars['String']>;
  readonly engineHP?: InputMaybe<Scalars['String']>;
  readonly isEngineBrakeAvailable?: InputMaybe<Scalars['Boolean']>;
  readonly transmissionMakeTerm?: InputMaybe<Scalars['String']>;
  readonly transmissionModelTerm?: InputMaybe<Scalars['String']>;
  readonly transmissionSpeed?: InputMaybe<Scalars['Int']>;
  readonly rearEndRatio?: InputMaybe<Scalars['String']>;
  readonly wheelBase?: InputMaybe<UnitOfLengthInputV3>;
  readonly tankCapacity1?: InputMaybe<UnitOfCapacityInput>;
  readonly tankCapacity2?: InputMaybe<UnitOfCapacityInput>;
  readonly fifthWheelTerm?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly operatingStatusTerm?: InputMaybe<Scalars['String']>;
  readonly serviceStatusTerm?: InputMaybe<Scalars['String']>;
  readonly pool?: InputMaybe<Scalars['String']>;
  readonly lastPingLocation?: InputMaybe<Scalars['String']>;
  readonly lastPingDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly lastFacility?: InputMaybe<Scalars['String']>;
  readonly facilityLocation?: InputMaybe<Scalars['String']>;
  readonly program?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly measurementTerm?: InputMaybe<Scalars['String']>;
  readonly equipmentDetails?: InputMaybe<ReadonlyArray<CreateAssetPowerEquipmentInputV2>>;
  readonly identifiers?: InputMaybe<ReadonlyArray<CreateAssetPowerIdentifierInputV3>>;
  readonly maintenance?: InputMaybe<ReadonlyArray<CreateAssetPowerMaintenanceInputV2>>;
  readonly legalToRunTerm?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentTrailerCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentDriverCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type CreateAssetPowerLegalToRunInput = {
  readonly name?: InputMaybe<Scalars['String']>;
  readonly powerId?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetPowerMaintenanceInput = {
  readonly type?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly planned?: InputMaybe<Scalars['Boolean']>;
  readonly severity?: InputMaybe<Scalars['String']>;
  readonly severityTerm?: InputMaybe<Scalars['String']>;
  readonly maintenanceFacility?: InputMaybe<Scalars['String']>;
  readonly location?: InputMaybe<Scalars['String']>;
  readonly expStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly expStartTime?: InputMaybe<Scalars['String']>;
  readonly actStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly actStartTime?: InputMaybe<Scalars['String']>;
  readonly estMaintTm?: InputMaybe<Scalars['String']>;
  readonly espEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly espEndTime?: InputMaybe<Scalars['String']>;
  readonly actEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly actEndTime?: InputMaybe<Scalars['String']>;
  readonly workPerformedDetail?: InputMaybe<Scalars['String']>;
  readonly powerId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetPowerMaintenanceInputV2 = {
  readonly typeTerm: Scalars['String'];
  readonly severityTerm: Scalars['String'];
  readonly isPlanned?: InputMaybe<Scalars['Boolean']>;
  readonly facilityName?: InputMaybe<Scalars['String']>;
  readonly location?: InputMaybe<Scalars['String']>;
  readonly estimatedMaintenanceTime?: InputMaybe<Scalars['Int']>;
  readonly actual?: InputMaybe<DateTimeEntryInput>;
  readonly expected?: InputMaybe<DateTimeEntryInput>;
  readonly workPerformedDetail?: InputMaybe<Scalars['String']>;
  readonly powerId?: InputMaybe<Scalars['String']>;
};

export type CreateAssetPowerPermanentDriverInput = {
  readonly permanentDriverId?: InputMaybe<Scalars['String']>;
  readonly powerId?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly permanentDriverIdV1?: InputMaybe<Scalars['String']>;
};

export type CreateAssetTrailerMaintenanceInput = {
  readonly type?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly planned?: InputMaybe<Scalars['Boolean']>;
  readonly deadline?: InputMaybe<Scalars['DateTime']>;
  readonly appointment?: InputMaybe<Scalars['String']>;
  readonly mainFacility?: InputMaybe<Scalars['String']>;
  readonly location?: InputMaybe<Scalars['String']>;
  readonly severity?: InputMaybe<Scalars['String']>;
  readonly severityTerm?: InputMaybe<Scalars['String']>;
  readonly dateIn?: InputMaybe<Scalars['DateTime']>;
  readonly out?: InputMaybe<Scalars['String']>;
  readonly dateOut?: InputMaybe<Scalars['DateTime']>;
  readonly duration?: InputMaybe<Scalars['String']>;
  readonly workPerformedDetail?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetTrailerMaintenanceInputV2 = {
  readonly typeTerm: Scalars['String'];
  readonly severityTerm: Scalars['String'];
  readonly isPlanned?: InputMaybe<Scalars['Boolean']>;
  readonly deadlineDate?: InputMaybe<Scalars['DateTime']>;
  readonly appointmentDate?: InputMaybe<Scalars['DateTime']>;
  readonly facilityName?: InputMaybe<Scalars['String']>;
  readonly location?: InputMaybe<Scalars['String']>;
  readonly dateIn?: InputMaybe<Scalars['DateTime']>;
  readonly dateOut?: InputMaybe<Scalars['DateTime']>;
  readonly duration?: InputMaybe<Scalars['String']>;
  readonly workPerformedDetail?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
};

export type CreateAssetTrailersEquipmentInput = {
  readonly equipmentCd?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly count?: InputMaybe<Scalars['Int']>;
  readonly assetId: Scalars['String'];
  readonly condition?: InputMaybe<Scalars['String']>;
  readonly conditionTerm?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly issueDate?: InputMaybe<Scalars['DateTime']>;
  readonly returnDate?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetTrailersEquipmentInputV2 = {
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId: Scalars['Int'];
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['DateTime'];
  readonly returnDate?: InputMaybe<Scalars['DateTime']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
};

export type CreateAssetTrailersIdentifierInput = {
  readonly identifierType: Scalars['String'];
  readonly identifierNumber: Scalars['String'];
  readonly state: Scalars['String'];
  readonly countryCd: Scalars['String'];
  readonly expirationDt?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly expirationDtV1?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetTrailersIdentifierInputV2 = {
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAssetTrailersIdentifierInputV3 = {
  readonly typeTerm: Scalars['String'];
  readonly identifierId: Scalars['String'];
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
};

export type CreateAssetTrailersInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly make?: InputMaybe<Scalars['String']>;
  readonly makeTerm?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly year?: InputMaybe<Scalars['Float']>;
  readonly model?: InputMaybe<Scalars['String']>;
  readonly modelTerm?: InputMaybe<Scalars['String']>;
  /** DEPRECATED: legalToRun is deprecated. Use legalToRunTerm instead */
  readonly legalToRun?: InputMaybe<ReadonlyArray<CreateAssetTrailersLegalToRunInput>>;
  /** This field describes the list of countries details where the trailer is legal to run(Ex. Dry Van , CAN) */
  readonly legalToRunTerm?: InputMaybe<ReadonlyArray<CreateAssetTrailersLegalToRunInput>>;
  readonly equipmentDetails?: InputMaybe<ReadonlyArray<CreateAssetTrailersEquipmentInput>>;
  /** DEPRECATED: identifiers is deprecated. Use identifiersV2 instead */
  readonly identifiers?: InputMaybe<ReadonlyArray<CreateAssetTrailersIdentifierInput>>;
  /** A collection of identifiers associated with the trailer. */
  readonly identifiersV2?: InputMaybe<ReadonlyArray<CreateAssetTrailersIdentifierInputV2>>;
  /** DEPRECATED: permanentDriver is deprecated. Use permanentDrivers instead */
  readonly permanentDriver?: InputMaybe<ReadonlyArray<CreateAssetPermanentDriverInput>>;
  /** A collection of permanent driver (codes) associated with the trailer. */
  readonly permanentDrivers?: InputMaybe<ReadonlyArray<CreateAssetPermanentDriverInput>>;
  /** DEPRECATED: permanentTractor is deprecated. Use permanentPowerUnit instead */
  readonly permanentTractor?: InputMaybe<ReadonlyArray<CreateAssetPermanentTractorInput>>;
  /** References to the power records that want to associate this trailer too. */
  readonly permanentPowerUnit?: InputMaybe<ReadonlyArray<CreateAssetPermanentTractorInput>>;
  readonly maintenance?: InputMaybe<ReadonlyArray<CreateAssetTrailerMaintenanceInput>>;
  readonly isCARBCompliant?: InputMaybe<Scalars['Boolean']>;
  readonly trailerDevice?: InputMaybe<Scalars['String']>;
  readonly trailerDeviceTerm?: InputMaybe<Scalars['String']>;
  readonly trailerModel?: InputMaybe<Scalars['String']>;
  readonly trailerModelTerm?: InputMaybe<Scalars['String']>;
  readonly serialNumber?: InputMaybe<Scalars['String']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly owner?: InputMaybe<Scalars['String']>;
  readonly ownerTerm?: InputMaybe<Scalars['String']>;
  readonly odomoter?: InputMaybe<Scalars['Float']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly color?: InputMaybe<Scalars['String']>;
  readonly floor?: InputMaybe<Scalars['String']>;
  readonly floorTerm?: InputMaybe<Scalars['String']>;
  readonly roof?: InputMaybe<Scalars['String']>;
  readonly roofTerm?: InputMaybe<Scalars['String']>;
  readonly doors?: InputMaybe<Scalars['String']>;
  readonly doorsTerm?: InputMaybe<Scalars['String']>;
  readonly skin?: InputMaybe<Scalars['String']>;
  readonly skinTerm?: InputMaybe<Scalars['String']>;
  readonly rearTireSize?: InputMaybe<Scalars['String']>;
  readonly length?: InputMaybe<Scalars['String']>;
  readonly exteriorHeight?: InputMaybe<Scalars['String']>;
  readonly exteriorWidth?: InputMaybe<Scalars['String']>;
  readonly gvwr?: InputMaybe<Scalars['String']>;
  readonly gawr?: InputMaybe<Scalars['String']>;
  readonly weight?: InputMaybe<Scalars['String']>;
  readonly frontInteriorHeight?: InputMaybe<Scalars['String']>;
  readonly rearInteriorHeight?: InputMaybe<Scalars['String']>;
  readonly interiorWidth?: InputMaybe<Scalars['String']>;
  readonly doorWidth?: InputMaybe<Scalars['String']>;
  readonly landingGear?: InputMaybe<Scalars['String']>;
  readonly suspension?: InputMaybe<Scalars['String']>;
  readonly liftgateType?: InputMaybe<Scalars['String']>;
  readonly liftgateTypeTerm?: InputMaybe<Scalars['String']>;
  readonly liftgateCapacity?: InputMaybe<Scalars['String']>;
  readonly liftgateSize?: InputMaybe<Scalars['String']>;
  readonly axleType?: InputMaybe<Scalars['String']>;
  readonly axleTypeTerm?: InputMaybe<Scalars['String']>;
  readonly etracks?: InputMaybe<Scalars['String']>;
  readonly eTracks?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly lClosed?: InputMaybe<Scalars['String']>;
  readonly lengthClosed?: InputMaybe<Scalars['String']>;
  readonly lExtended?: InputMaybe<Scalars['String']>;
  readonly lengthExtended?: InputMaybe<Scalars['String']>;
  readonly operatingStatus?: InputMaybe<Scalars['String']>;
  readonly operatingStatusTerm?: InputMaybe<Scalars['String']>;
  readonly serviceStatus?: InputMaybe<Scalars['String']>;
  readonly serviceStatusTerm?: InputMaybe<Scalars['String']>;
  readonly fleet?: InputMaybe<Scalars['String']>;
  readonly pool?: InputMaybe<Scalars['String']>;
  readonly tempMonitoring?: InputMaybe<Scalars['String']>;
  readonly tempMonitoringTerm?: InputMaybe<Scalars['String']>;
  readonly remoteAccess?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly projectTerm?: InputMaybe<Scalars['String']>;
  readonly programTerm?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly measurementTerm?: InputMaybe<Scalars['String']>;
  readonly odometerUOMTerm?: InputMaybe<Scalars['String']>;
  readonly lengthUOMTerm?: InputMaybe<Scalars['String']>;
  readonly exteriorHeightUOMTerm?: InputMaybe<Scalars['String']>;
  readonly exteriorWidthUOMTerm?: InputMaybe<Scalars['String']>;
  readonly gvwrUOMTerm?: InputMaybe<Scalars['String']>;
  readonly gawrUOMTerm?: InputMaybe<Scalars['String']>;
  readonly weightUOMTerm?: InputMaybe<Scalars['String']>;
  readonly frontInteriorHeightUOMTerm?: InputMaybe<Scalars['String']>;
  readonly rearInteriorHeightUOMTerm?: InputMaybe<Scalars['String']>;
  readonly interiorWidthUOMTerm?: InputMaybe<Scalars['String']>;
  readonly doorWidthUOMTerm?: InputMaybe<Scalars['String']>;
  readonly tireWidth?: InputMaybe<Scalars['Int']>;
  readonly tireAspectRatio?: InputMaybe<Scalars['Int']>;
  readonly tireRimSize?: InputMaybe<Scalars['Int']>;
  readonly liftgateCapacityUOMTerm?: InputMaybe<Scalars['String']>;
  readonly liftgateSizeUOMTerm?: InputMaybe<Scalars['String']>;
  readonly lengthClosedUOMTerm?: InputMaybe<Scalars['String']>;
  readonly lengthExtendedUOMTerm?: InputMaybe<Scalars['String']>;
};

export type CreateAssetTrailersInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly code: Scalars['String'];
  readonly makeTerm?: InputMaybe<Scalars['String']>;
  readonly year?: InputMaybe<Scalars['Int']>;
  readonly isCARBCompliant?: InputMaybe<Scalars['Boolean']>;
  readonly serialNumber?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly color?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly modelTerm?: InputMaybe<Scalars['String']>;
  readonly trackingDevice?: InputMaybe<TrailerDeviceInput>;
  readonly ownerTerm?: InputMaybe<Scalars['String']>;
  readonly odometerMiles?: InputMaybe<Scalars['Int']>;
  readonly floorTerm?: InputMaybe<Scalars['String']>;
  readonly roofTerm?: InputMaybe<Scalars['String']>;
  readonly doorsTerm?: InputMaybe<Scalars['String']>;
  readonly skinTerm?: InputMaybe<Scalars['String']>;
  readonly trailerLength?: InputMaybe<UnitOfLengthInputV3>;
  readonly exteriorHeight?: InputMaybe<UnitOfLengthInputV3>;
  readonly exteriorWidth?: InputMaybe<UnitOfLengthInputV3>;
  readonly grossVehicleWeightRating?: InputMaybe<UnitOfWeightInput>;
  readonly grossAxleWeightRating?: InputMaybe<UnitOfWeightInput>;
  readonly trailerWeight?: InputMaybe<UnitOfWeightInput>;
  readonly frontInteriorHeight?: InputMaybe<UnitOfLengthInputV3>;
  readonly rearInteriorHeight?: InputMaybe<UnitOfLengthInputV3>;
  readonly interiorWidth?: InputMaybe<UnitOfLengthInputV3>;
  readonly doorWidth?: InputMaybe<UnitOfLengthInputV3>;
  readonly landingGearTerm?: InputMaybe<Scalars['String']>;
  readonly suspensionTerm?: InputMaybe<Scalars['String']>;
  readonly axleTypeTerm?: InputMaybe<Scalars['String']>;
  readonly liftgateTypeTerm?: InputMaybe<Scalars['String']>;
  readonly liftgateCapacity?: InputMaybe<UnitOfWeightInput>;
  readonly liftgateSize?: InputMaybe<UnitOfLengthInputV3>;
  readonly isETracks?: InputMaybe<Scalars['Boolean']>;
  readonly lengthClosed?: InputMaybe<UnitOfLengthInputV3>;
  readonly lengthExtended?: InputMaybe<UnitOfLengthInputV3>;
  readonly operatingStatusTerm?: InputMaybe<Scalars['String']>;
  readonly serviceStatusTerm?: InputMaybe<Scalars['String']>;
  readonly fleetCarrierCode?: InputMaybe<Scalars['String']>;
  readonly pool?: InputMaybe<Scalars['String']>;
  readonly tempMonitoringTerm?: InputMaybe<Scalars['String']>;
  readonly isRemoteAccessAvailable?: InputMaybe<Scalars['Boolean']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly program?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly measurementTerm?: InputMaybe<Scalars['String']>;
  readonly tireWidth?: InputMaybe<Scalars['Int']>;
  readonly tireAspectRatio?: InputMaybe<Scalars['String']>;
  readonly tireRimSize?: InputMaybe<Scalars['Int']>;
  /** A collection of Equipments associated with the trailer. */
  readonly equipmentDetails?: InputMaybe<ReadonlyArray<CreateAssetTrailersEquipmentInputV2>>;
  /** A collection of identifiers associated with the trailer. */
  readonly identifiers?: InputMaybe<ReadonlyArray<CreateAssetTrailersIdentifierInputV3>>;
  readonly legalToRunTerm?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentPowerUnitCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly permanentDriverCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A collection of Maintenance associated with the trailer. */
  readonly maintenance?: InputMaybe<ReadonlyArray<CreateAssetTrailerMaintenanceInputV2>>;
};

export type CreateAssetTrailersLegalToRunInput = {
  readonly name: Scalars['String'];
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateAuditHistoryInput = {
  readonly modelType?: InputMaybe<Scalars['String']>;
  readonly changeType?: InputMaybe<Scalars['String']>;
  readonly recordId?: InputMaybe<Scalars['String']>;
  readonly recordName?: InputMaybe<Scalars['String']>;
  readonly timestamp?: InputMaybe<Scalars['String']>;
  readonly oldValue?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
  readonly identity?: InputMaybe<Scalars['String']>;
  readonly changes?: InputMaybe<ReadonlyArray<ChangeInput>>;
};

export type CreateCarrierAddressInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly carrierCode?: InputMaybe<Scalars['String']>;
  readonly addressTypeId: Scalars['ID'];
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly province?: InputMaybe<Scalars['String']>;
  readonly country: Scalars['String'];
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly postalCode: Scalars['String'];
  readonly isVerified?: InputMaybe<Scalars['Boolean']>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type CreateCarrierContactInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly carrierCode?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly chatTypeId?: InputMaybe<Scalars['ID']>;
  readonly chatUsername?: InputMaybe<Scalars['String']>;
  readonly contactTypeId?: InputMaybe<Scalars['ID']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type CreateCarrierCrmNotesInput = {
  readonly contacts?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly notes: Scalars['String'];
  readonly opportunities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly reps: ReadonlyArray<Scalars['ID']>;
  readonly type?: InputMaybe<Scalars['String']>;
  readonly vendorId: Scalars['ID'];
};

export type CreateCarrierCrmOpportunityInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly name: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly opportunityStatusId?: InputMaybe<Scalars['ID']>;
  readonly opportunityTypeId?: InputMaybe<Scalars['ID']>;
  readonly owningContactId?: InputMaybe<Scalars['ID']>;
  readonly owningRepId: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
};

export type CreateCarrierFacilityInput = {
  readonly relationshipType?: InputMaybe<Scalars['String']>;
  readonly canLoad?: InputMaybe<Scalars['Boolean']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly carrierId: Scalars['String'];
  readonly facilityId: Scalars['String'];
};

export type CreateCarrierIdentifierInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId: Scalars['ID'];
  readonly carrierIdentifierTypeId: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly link?: InputMaybe<Scalars['String']>;
};

export type CreateCarrierInteractionInput = {
  readonly contactId?: InputMaybe<Scalars['ID']>;
  readonly interactionOutcomeTypeId?: InputMaybe<Scalars['ID']>;
  readonly interactionTypeId: Scalars['ID'];
  readonly interactionViaTypeId?: InputMaybe<Scalars['ID']>;
  readonly nextInteractionDate?: InputMaybe<Scalars['DateTime']>;
  readonly nextInteractionRepId?: InputMaybe<Scalars['ID']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly vendorId: Scalars['ID'];
};

export type CreateCarrierRepInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId: Scalars['ID'];
  readonly repType: RepTypeEnum;
  readonly repTypeKeyId?: InputMaybe<Scalars['ID']>;
  readonly employeeId: Scalars['ID'];
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly isLoadRep?: InputMaybe<Scalars['Boolean']>;
  readonly divisionId?: InputMaybe<Scalars['ID']>;
  readonly modeId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly businessUnitId?: InputMaybe<Scalars['ID']>;
  readonly directionId?: InputMaybe<Scalars['ID']>;
};

export type CreateClientExceptionRecordInput = {
  readonly type: ClientExceptionRecordType;
  readonly sourceType: ClientExceptionRecordSourceType;
  readonly secondarySourceType?: InputMaybe<ClientExceptionRecordSourceType>;
  readonly sourceID: Scalars['String'];
  readonly secondarySourceID?: InputMaybe<Scalars['String']>;
  readonly sourceCode?: InputMaybe<Scalars['String']>;
  readonly secondarySourceCode?: InputMaybe<Scalars['String']>;
  readonly reasons: ReadonlyArray<CreateClientExceptionRecordReasonInput>;
  readonly metadata: ReadonlyArray<UpsertClientExceptionRecordMetadataInput>;
};

export type CreateClientExceptionRecordReasonInput = {
  readonly status: ClientExceptionRecordReasonStatus;
  readonly code: ClientExceptionRecordReasonCode;
  readonly reasonCode: Scalars['String'];
  readonly bypassed?: InputMaybe<Scalars['Boolean']>;
  readonly assignedTo?: InputMaybe<Scalars['String']>;
  readonly assignedToGroup?: InputMaybe<Scalars['String']>;
  readonly assignedBy?: InputMaybe<Scalars['String']>;
};

export type CreateContactInput = {
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly chatTypeId?: InputMaybe<Scalars['ID']>;
  readonly chatUsername?: InputMaybe<Scalars['String']>;
  readonly contactTypeId?: InputMaybe<Scalars['ID']>;
  readonly contactableId?: InputMaybe<Scalars['ID']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly entity: EntityInput;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type CreateContainerInput = {
  readonly carrierId: Scalars['ID'];
  readonly containerLengthUnit: UnitOfLengthEnum;
  readonly containerLengthValue: Scalars['Float'];
  /** Must be alphanumeric and shorter than 50 characters; required if reservationNumber is not present */
  readonly containerNumber?: InputMaybe<Scalars['String']>;
  readonly containerProgramId?: InputMaybe<Scalars['String']>;
  readonly containerTypeId: Scalars['ID'];
  readonly createdByUserId: Scalars['ID'];
  readonly destinationRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly endDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly endTimezone?: InputMaybe<Scalars['String']>;
  readonly externalSourceId?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly originLocation: CapacityLocationInput;
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly previousRouteId?: InputMaybe<Scalars['ID']>;
  /** Must be alphanumeric and shorter than 50 characters; required if containerNumber is not present */
  readonly reservationNumber?: InputMaybe<Scalars['String']>;
  readonly reservationTypeId?: InputMaybe<Scalars['String']>;
  readonly startDatetimeUtc: Scalars['ISO8601DateTime'];
  readonly startTimezone: Scalars['String'];
  readonly sourceTypeId: Scalars['String'];
  readonly tenantInternalId?: InputMaybe<Scalars['String']>;
  readonly updatedByUserId: Scalars['ID'];
};

/** Autogenerated return type of CreateCreditHistory */
export type CreateCreditHistoryPayload = {
  readonly __typename?: 'CreateCreditHistoryPayload';
  readonly creditHistory?: Maybe<CreditHistory>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateCreditHistoryPayloadV2 = {
  readonly __typename?: 'CreateCreditHistoryPayloadV2';
  readonly creditHistory?: Maybe<CreditHistoryV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateCrmCustomerDetailInputType = {
  readonly customerId: Scalars['ID'];
  /** DEPRECATED. Do not use this. This will be removed. Use InteractionFrequencyTypeId. For values refer InteractionFrequency Type in minion  */
  readonly interactionFrequency?: InputMaybe<CustomerInteractionFrequencyEnum>;
  readonly interactionFrequencyTypeId?: InputMaybe<Scalars['ID']>;
  readonly leadNotes?: InputMaybe<Scalars['String']>;
  /** DEPRECATED. Do not use this. This will be removed. Use LeadSourceTypeId. For values refer CustomerLeadSource Type in minion  */
  readonly leadSource?: InputMaybe<LeadSourceEnum>;
  readonly leadSourceTypeId?: InputMaybe<Scalars['ID']>;
};

export type CreateCustomerAddressInput = {
  readonly addressTypeId: Scalars['ID'];
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isVerified?: InputMaybe<Scalars['Boolean']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly postalCode: Scalars['String'];
  readonly province?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly state: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type CreateCustomerCommodityInput = {
  readonly customerFacilityId?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly facilityId?: InputMaybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly stccCode?: InputMaybe<Scalars['String']>;
  readonly packagingUnit?: InputMaybe<Scalars['String']>;
  readonly loadOn?: InputMaybe<Scalars['String']>;
  readonly expWt: Scalars['Float'];
  readonly expPieces?: InputMaybe<Scalars['String']>;
  readonly uom_temperature?: InputMaybe<Scalars['String']>;
  readonly uom_dimension?: InputMaybe<Scalars['String']>;
  readonly uom_linear?: InputMaybe<Scalars['String']>;
  readonly uom_cube?: InputMaybe<Scalars['String']>;
  readonly uom_density?: InputMaybe<Scalars['String']>;
  readonly temp?: InputMaybe<Scalars['Boolean']>;
  readonly precoolTo?: InputMaybe<Scalars['Float']>;
  readonly minTemp?: InputMaybe<Scalars['Float']>;
  readonly maxTemp?: InputMaybe<Scalars['Float']>;
  readonly tempSetting?: InputMaybe<Scalars['String']>;
  readonly wtUnit?: InputMaybe<Scalars['String']>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly hazmatClass?: InputMaybe<Scalars['String']>;
  readonly packagingGroup?: InputMaybe<Scalars['String']>;
  readonly unNaNumber?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly expDimensions?: InputMaybe<Scalars['String']>;
  readonly expDimensionsW?: InputMaybe<Scalars['Float']>;
  readonly expDimensionsH?: InputMaybe<Scalars['Float']>;
  readonly expDimensionsL?: InputMaybe<Scalars['Float']>;
  readonly expLinear?: InputMaybe<Scalars['Float']>;
  readonly expCube?: InputMaybe<Scalars['Float']>;
  readonly expDensity?: InputMaybe<Scalars['Float']>;
  readonly expClass?: InputMaybe<Scalars['String']>;
  readonly expNmfc?: InputMaybe<Scalars['String']>;
  readonly nmfcSubcode?: InputMaybe<Scalars['String']>;
  readonly unitSystem?: InputMaybe<Scalars['String']>;
  readonly canTopLoad?: InputMaybe<Scalars['Boolean']>;
  readonly canBottomLoad?: InputMaybe<Scalars['Boolean']>;
};

export type CreateCustomerContactInput = {
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly chatTypeId?: InputMaybe<Scalars['ID']>;
  readonly chatUsername?: InputMaybe<Scalars['String']>;
  readonly contactTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateCustomerCreditProvider */
export type CreateCustomerCreditProviderPayload = {
  readonly __typename?: 'CreateCustomerCreditProviderPayload';
  readonly customerCreditProvider: CustomerCreditProvider;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateCustomerCreditProviderPayloadV2 = {
  readonly __typename?: 'CreateCustomerCreditProviderPayloadV2';
  readonly customerCreditProvider?: Maybe<CustomerCreditProviderV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateCustomerCrmNotesRequest = {
  readonly contacts?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly customerId: Scalars['ID'];
  readonly notes: Scalars['String'];
  readonly opportunities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly reps: ReadonlyArray<Scalars['ID']>;
  readonly type?: InputMaybe<Scalars['String']>;
};

export type CreateCustomerCrmOpportunityInput = {
  readonly customerId: Scalars['ID'];
  readonly description?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly name: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly opportunityStatusId?: InputMaybe<Scalars['ID']>;
  readonly opportunityTypeId?: InputMaybe<Scalars['ID']>;
  readonly owningContactId?: InputMaybe<Scalars['ID']>;
  readonly owningRepId: Scalars['ID'];
};

export type CreateCustomerCustomerRelationshipInput = {
  readonly relationshipType?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly customerId: Scalars['String'];
  readonly relatedCustomerId: Scalars['String'];
};

export type CreateCustomerFacilityInput = {
  readonly schedulingSystem?: InputMaybe<Scalars['String']>;
  readonly schedulingURL?: InputMaybe<Scalars['String']>;
  readonly username?: InputMaybe<Scalars['String']>;
  readonly password?: InputMaybe<Scalars['String']>;
  readonly isAutoScheduleEligible?: InputMaybe<Scalars['Boolean']>;
  readonly isAutoScheduleDefault?: InputMaybe<Scalars['Boolean']>;
  readonly isPickup?: InputMaybe<Scalars['Boolean']>;
  readonly pickupCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isDelivery?: InputMaybe<Scalars['Boolean']>;
  readonly deliveryCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trailPool?: InputMaybe<Scalars['String']>;
  readonly minTrailers?: InputMaybe<Scalars['Int']>;
  readonly maxTrailers?: InputMaybe<Scalars['Int']>;
  readonly nowTrailers?: InputMaybe<Scalars['Int']>;
  readonly specialRequirementsAndEquipment?: InputMaybe<Scalars['String']>;
  readonly pickupAllowableFreeTimeValue?: InputMaybe<Scalars['Int']>;
  readonly pickupAllowableFreeTimeUnit?: InputMaybe<Scalars['String']>;
  readonly deliveryAllowableFreeTimeValue?: InputMaybe<Scalars['Int']>;
  readonly deliveryAllowableFreeTimeUnit?: InputMaybe<Scalars['String']>;
  readonly drop?: InputMaybe<Scalars['String']>;
  readonly customerCustomerId?: InputMaybe<Scalars['String']>;
  readonly customerId: Scalars['String'];
  readonly facilityId: Scalars['String'];
};

export type CreateCustomerIdentifierInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly customerId: Scalars['ID'];
  readonly identifierTypeId: Scalars['ID'];
  readonly code: Scalars['String'];
};

export type CreateCustomerInteractionInput = {
  readonly contactId?: InputMaybe<Scalars['ID']>;
  readonly customerId: Scalars['ID'];
  readonly interactionOutcomeTypeId?: InputMaybe<Scalars['ID']>;
  readonly interactionTypeId?: InputMaybe<Scalars['ID']>;
  readonly interactionViaTypeId?: InputMaybe<Scalars['ID']>;
  readonly nextInteractionDate?: InputMaybe<Scalars['DateTime']>;
  readonly nextInteractionRepId?: InputMaybe<Scalars['ID']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  /** DEPRECATED. Do not use this. This will be removed. Use InteractionOutcomeTypeId. For values refer InteractionOutcome Type in minion  */
  readonly outcome?: InputMaybe<CustomerInteractionOutcomeEnum>;
  /** DEPRECATED. Do not use this. This will be removed. Use InteractionTypeId. For values refer InteractionType Type in minion  */
  readonly type?: InputMaybe<CustomerInteractionTypeEnum>;
  /** DEPRECATED. Do not use this. This will be removed. Use InteractionViaTypeId. For values refer InteractionVia Type in minion  */
  readonly via?: InputMaybe<CustomerInteractionViaEnum>;
};

/** Autogenerated return type of CreateCustomerQuote */
export type CreateCustomerQuotePayload = {
  readonly __typename?: 'CreateCustomerQuotePayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly quote: CustomerQuote;
};

export type CreateCustomerQuotePayloadV2 = {
  readonly __typename?: 'CreateCustomerQuotePayloadV2';
  readonly quote: CustomerQuotePaginatedV2;
};

export type CreateCustomerRegionInput = {
  readonly customerId: Scalars['String'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly region?: InputMaybe<Scalars['String']>;
};

export type CreateCustomerRepInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly customerId: Scalars['ID'];
  readonly repTypeId: Scalars['ID'];
  readonly divisionId?: InputMaybe<Scalars['ID']>;
  readonly modeId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly directionId?: InputMaybe<Scalars['ID']>;
  readonly businessUnitId?: InputMaybe<Scalars['ID']>;
  readonly employeeId: Scalars['ID'];
  readonly main: Scalars['Boolean'];
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly isLoadRep?: InputMaybe<Scalars['Boolean']>;
};

export type CreateCustomerStructureInput = {
  readonly customerId: Scalars['String'];
  readonly description?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
};

export type CreateDriverAssignmentInput = {
  readonly userID?: InputMaybe<Scalars['ID']>;
  readonly routeID: Scalars['ID'];
  readonly loadID?: InputMaybe<Scalars['ID']>;
  readonly facilityID?: InputMaybe<Scalars['ID']>;
  readonly equipmentID?: InputMaybe<Scalars['ID']>;
  /** DEPRECATED - DO NOT USE, VALUE IS THROWN AWAY */
  readonly previousRouteID?: InputMaybe<Scalars['ID']>;
  /** DEPRECATED - DO NOT USE, VALUE IS THROWN AWAY */
  readonly nextRouteID?: InputMaybe<Scalars['ID']>;
  readonly trackingSystemID?: InputMaybe<Scalars['ID']>;
  readonly trackingEnabled?: InputMaybe<Scalars['Boolean']>;
  /** temporary field until we can resolve user info from the gateway context */
  readonly trackingEnabledBy?: InputMaybe<TrackingUserMetaInput>;
  readonly driver1?: InputMaybe<DriverInput>;
  readonly driver2?: InputMaybe<DriverInput>;
  readonly eldVehicleID?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly trailerIdentifier?: InputMaybe<Scalars['String']>;
  readonly tractorIdentifier?: InputMaybe<Scalars['String']>;
  readonly trailerLocation?: InputMaybe<LocationInput>;
  readonly emptyLocation?: InputMaybe<LocationInput>;
  readonly emptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly emptyTimezone?: InputMaybe<Scalars['String']>;
  readonly etaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly etaTimezone?: InputMaybe<Scalars['String']>;
  readonly checkCallDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly trailerLength?: InputMaybe<Scalars['Float']>;
  readonly trailerWidth?: InputMaybe<Scalars['Float']>;
  readonly trailerHeight?: InputMaybe<Scalars['Float']>;
  readonly dispatched?: InputMaybe<Scalars['Boolean']>;
  /** temporary field until we can resolve user info from the gateway context */
  readonly dispatchedBy?: InputMaybe<TrackingUserMetaInput>;
  readonly externalInfo?: InputMaybe<DriverAssignmentExternalInfoInput>;
  readonly reloadIntentID?: InputMaybe<Scalars['String']>;
};

export type CreateFacilityAddressInput = {
  readonly addressTypeId: Scalars['ID'];
  readonly addressTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly facilityCode?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isVerified?: InputMaybe<Scalars['Boolean']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly postalCode: Scalars['String'];
  readonly province?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly state: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type CreateFacilityContactInput = {
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly chatTypeId?: InputMaybe<Scalars['ID']>;
  readonly chatUsername?: InputMaybe<Scalars['String']>;
  readonly contactTypeId?: InputMaybe<Scalars['ID']>;
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly facilityCode?: InputMaybe<Scalars['String']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type CreateFacilityDirectionInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly facilityId: Scalars['ID'];
  readonly source?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly averageTime?: InputMaybe<Scalars['Int']>;
  readonly directions: Scalars['String'];
};

export type CreateFacilityHolidayInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly facilityId: Scalars['ID'];
  readonly holiday: Scalars['String'];
  readonly facilityOpenState: Scalars['String'];
  readonly holidayDate: Scalars['ISO8601DateTime'];
  readonly weekday?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly startOffsetMs?: InputMaybe<Scalars['Int']>;
  readonly endOffsetMs?: InputMaybe<Scalars['Int']>;
};

export type CreateFacilityLoadDefaultsInput = {
  readonly defaultEquipments?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly length?: InputMaybe<Scalars['Float']>;
  readonly width?: InputMaybe<Scalars['Float']>;
  readonly height?: InputMaybe<Scalars['Float']>;
  readonly schedSystem?: InputMaybe<Scalars['String']>;
  readonly schedURL?: InputMaybe<Scalars['String']>;
  readonly specialRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly autoSchedEligible?: InputMaybe<Scalars['Boolean']>;
  readonly autoSchedDefault?: InputMaybe<Scalars['Boolean']>;
  readonly facilityId: Scalars['String'];
  readonly facilityLoadDefaultsDivision?: InputMaybe<ReadonlyArray<CreateOrUpdateFacilityLoadDefaultsDivisionInput>>;
  readonly facilityLoadDefaultsBusinessUnit?: InputMaybe<ReadonlyArray<CreateOrUpdateFacilityLoadDefaultsBusinessUnitInput>>;
};

export type CreateFacilityNoteDefaultsInput = {
  readonly facilityId: Scalars['String'];
  readonly rateConfirmNote?: InputMaybe<Scalars['String']>;
  readonly externalFacilityNote?: InputMaybe<Scalars['String']>;
  readonly facilityLoadNote?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly noteType?: InputMaybe<Scalars['String']>;
  readonly facilityNoteDefaultsDivision?: InputMaybe<ReadonlyArray<CreateOrUpdateFacilityNoteDefaultsDivisionInput>>;
  readonly facilityNoteDefaultsBusinessUnits?: InputMaybe<ReadonlyArray<CreateOrUpdateFacilityNoteDefaultsBusinessUnitInput>>;
};

export type CreateFacilitySublocationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly facilityId: Scalars['ID'];
  readonly sublocation: Scalars['String'];
  readonly description: Scalars['String'];
  readonly type: Scalars['String'];
  readonly locked?: InputMaybe<Scalars['String']>;
  readonly guarded?: InputMaybe<Scalars['String']>;
  readonly checkInMethod?: InputMaybe<Scalars['String']>;
  readonly directions?: InputMaybe<Scalars['String']>;
};

export type CreateFacilityToImportPayload = {
  readonly __typename?: 'CreateFacilityToImportPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly facility?: Maybe<FacilityV2>;
};

export type CreateFirstTruckEntriesInput = {
  readonly carrierId: Scalars['ID'];
  readonly createdByUserId: Scalars['ID'];
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly originLocation: TruckLocationInput;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  readonly updatedByUserId: Scalars['ID'];
  readonly fridayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly mondayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly saturdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly status?: InputMaybe<TruckEntryTemplateStatus>;
  readonly sourceType?: InputMaybe<TruckEntryTemplateSourceTypesEnum>;
  readonly sundayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly thursdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly trailerTypeId: Scalars['ID'];
  readonly tuesdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly wednesdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  /** Represents truckEntryTemplateId from which FirstTrucks will be created */
  readonly truckEntryTemplateId: Scalars['ID'];
};

export type CreateGateReservationInput = {
  readonly carrierId: Scalars['ID'];
  readonly originRampFacilityId: Scalars['ID'];
  readonly destinationRampFacilityId?: InputMaybe<Scalars['ID']>;
  /** DEPRECATED: Use containerProgramId instead */
  readonly containerProgramCarrierId?: InputMaybe<Scalars['ID']>;
  readonly containerProgramId?: InputMaybe<Scalars['String']>;
  readonly endDatetimeUtc: Scalars['ISO8601DateTime'];
  readonly startDatetimeUtc: Scalars['ISO8601DateTime'];
  readonly startTimezone: Scalars['String'];
  readonly endTimezone: Scalars['String'];
  readonly containerTypeId: Scalars['ID'];
  readonly createdByUserId: Scalars['ID'];
  readonly updatedByUserId: Scalars['ID'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly containerLengthUnit: UnitOfLengthEnum;
  readonly containerLengthValue: Scalars['Float'];
  readonly reservationNumber: Scalars['String'];
  readonly sourceTypeId?: InputMaybe<Scalars['String']>;
};

export type CreateHaykEventInput = {
  readonly eventName: Scalars['String'];
  readonly eventType: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
  readonly group: Scalars['String'];
  readonly displayName?: InputMaybe<Scalars['String']>;
  readonly displayGroup?: InputMaybe<Scalars['String']>;
  readonly isVisible?: InputMaybe<Scalars['Boolean']>;
};

export type CreateHaykEventResult = {
  readonly __typename?: 'CreateHaykEventResult';
  readonly eventId?: Maybe<Scalars['String']>;
  readonly eventName?: Maybe<Scalars['String']>;
  readonly eventType?: Maybe<Scalars['String']>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly group?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly displayGroup?: Maybe<Scalars['String']>;
  readonly isVisible?: Maybe<Scalars['Boolean']>;
};

export type CreateInteractionRepGroupLookupOutput = {
  readonly __typename?: 'CreateInteractionRepGroupLookupOutput';
  readonly Interaction_Rep_Group_Lookup_Id: Scalars['ID'];
  readonly Interaction_Rep_Group_Type?: Maybe<Scalars['String']>;
  readonly Interaction_Rep_Group_KeyTerm?: Maybe<Scalars['String']>;
  readonly crm_Interaction_Rep_Group?: Maybe<ReadonlyArray<CrmInteractionRepGroupOutput>>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CreateLinkedRouteInputV2 = {
  readonly userID?: InputMaybe<Scalars['ID']>;
  readonly routeID: Scalars['ID'];
  readonly nextRouteID: Scalars['ID'];
};

export type CreateLoadDefaultsInput = {
  readonly size?: InputMaybe<SizeTypesEnum>;
  readonly sizeV2?: InputMaybe<Scalars['String']>;
  readonly minLength?: InputMaybe<Scalars['Float']>;
  readonly width?: InputMaybe<Scalars['Float']>;
  readonly height?: InputMaybe<Scalars['Float']>;
  readonly cargoValue?: InputMaybe<Scalars['Float']>;
  readonly cargoUnit?: InputMaybe<Scalars['String']>;
  readonly doNotPostLoads?: InputMaybe<Scalars['Boolean']>;
  readonly disableAutoPost?: InputMaybe<Scalars['Boolean']>;
  readonly equipment?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly specialRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId: Scalars['String'];
  readonly mode: Scalars['String'];
  readonly divisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type CreateLoadDefaultsInputV2 = {
  readonly size?: InputMaybe<SizeTypesEnum>;
  readonly sizeV2?: InputMaybe<Scalars['String']>;
  readonly minLength?: InputMaybe<Scalars['Float']>;
  readonly width?: InputMaybe<Scalars['Float']>;
  readonly height?: InputMaybe<Scalars['Float']>;
  readonly cargoValue?: InputMaybe<Scalars['Float']>;
  readonly cargoUnit?: InputMaybe<Scalars['String']>;
  readonly doNotPostLoads?: InputMaybe<Scalars['Boolean']>;
  readonly disableAutoPost?: InputMaybe<Scalars['Boolean']>;
  readonly equipment?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly specialRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId: Scalars['String'];
  readonly mode: Scalars['String'];
  readonly divisions: ReadonlyArray<Scalars['String']>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** Autogenerated return type of CreateLoad */
export type CreateLoadPayload = {
  readonly __typename?: 'CreateLoadPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly load: Load;
};

export type CreateLoadRouteInput = {
  readonly orderId: Scalars['ID'];
  readonly activationStatusId: Scalars['String'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly hotRoute?: InputMaybe<Scalars['Boolean']>;
};

export type CreateLoadStopInput = {
  readonly facilityId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly scheduleType?: InputMaybe<ScheduleAppointmentTypeEnum>;
  readonly liveType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly workType?: InputMaybe<ScheduleWorkTypeEnum>;
  readonly isOrderStop?: InputMaybe<Scalars['Boolean']>;
  readonly type?: InputMaybe<StopTypeEnum>;
  /**
   * value is validated against following options:
   *   crossDock, originRamp, destinationRamp, customsBroker, yard, PU, Del, TA, TD, domicile, maintenanceShop, scale, fuel, driverComplianceTraining, waypoint, railJunction, relay, terminal, seaPort, airport, containerDepot, borderCrossing, transload, trailerWash
   */
  readonly loadStopType?: InputMaybe<Scalars['String']>;
  readonly loadFromId?: InputMaybe<Scalars['ID']>;
  readonly unloadFromId?: InputMaybe<Scalars['ID']>;
  readonly requirements?: InputMaybe<ReadonlyArray<StopRequirementInput>>;
  readonly refs: ReadonlyArray<RefInputV2>;
  readonly reqDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly availableEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly bolNumber?: InputMaybe<Scalars['String']>;
  readonly appointment?: InputMaybe<AppointmentInputV2>;
  readonly sealNumber?: InputMaybe<Scalars['String']>;
  readonly apptConfBy?: InputMaybe<Scalars['String']>;
  readonly apptReqWith?: InputMaybe<Scalars['String']>;
  /** stop unique identifier for external system */
  readonly externalRef?: InputMaybe<Scalars['String']>;
  readonly appointmentChangeReason?: InputMaybe<Scalars['String']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  /** flag for stops created from FE */
  readonly isCreatedManually?: InputMaybe<Scalars['Boolean']>;
  readonly splc?: InputMaybe<Scalars['String']>;
  /** PU/Del code(s) from customer-facility relationship */
  readonly externalFacilityId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateMultipleTruckPostings */
export type CreateMultipleTruckPostingsPayload = {
  readonly __typename?: 'CreateMultipleTruckPostingsPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly truckPostings?: Maybe<ReadonlyArray<TruckPosting>>;
};

export type CreateNoteDefaultInput = {
  readonly customerId: Scalars['String'];
  readonly customerLoadNote?: InputMaybe<Scalars['String']>;
  readonly rateConfirmNote?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly noteType?: InputMaybe<Scalars['String']>;
  readonly invoiceNote?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type CreateOfferInput = {
  readonly routeId: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
  readonly trailerOwnerId?: InputMaybe<Scalars['ID']>;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly trailerDimensions?: InputMaybe<DimensionsInput>;
  readonly trailerLength?: InputMaybe<UnitOfLengthInput>;
  readonly trailerWidth?: InputMaybe<UnitOfLengthInput>;
  readonly trailerHeight?: InputMaybe<UnitOfLengthInput>;
  readonly emptyLocationId?: InputMaybe<Scalars['ID']>;
  readonly emptyLocationGeographySourceId?: InputMaybe<Scalars['Float']>;
  readonly emptyLocationGeographySource?: InputMaybe<Scalars['String']>;
  readonly emptyDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly type: OfferType;
  readonly reason?: InputMaybe<OfferReason>;
  readonly price?: InputMaybe<CurrencyInput>;
  readonly offerPrice?: InputMaybe<CurrencyInputV2>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly askPrice?: InputMaybe<CurrencyInput>;
  readonly askPriceV2?: InputMaybe<CurrencyInputV2>;
  readonly vendorValidationErrors?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** Autogenerated return type of CreateOrUpdateAddress */
export type CreateOrUpdateAddressPayload = {
  readonly __typename?: 'CreateOrUpdateAddressPayload';
  readonly address?: Maybe<Address>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateAddress */
export type CreateOrUpdateCarrierAddressPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierAddressPayload';
  readonly address?: Maybe<CarrierAddress>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrierCertification */
export type CreateOrUpdateCarrierCertificationPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierCertificationPayload';
  readonly carrierCertification?: Maybe<CarrierCertification>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrierCertification */
export type CreateOrUpdateCarrierCertificationPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateCarrierCertificationPayloadV2';
  readonly carrierCertification?: Maybe<CarrierCertificationV2>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateContact */
export type CreateOrUpdateCarrierContactPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierContactPayload';
  readonly contact?: Maybe<CarrierContact>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrierCustomerRelationship */
export type CreateOrUpdateCarrierCustomerRelationshipPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierCustomerRelationshipPayload';
  readonly carrierCustomerRelationship?: Maybe<CarrierCustomerRelationship>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrierCustomerRelationship */
export type CreateOrUpdateCarrierCustomerRelationshipPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateCarrierCustomerRelationshipPayloadV2';
  readonly carrierCustomerRelationship?: Maybe<CarrierCustomerRelationshipV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCarrierGroupPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierGroupPayload';
  readonly carrierGroup?: Maybe<CarrierGroup>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCarrierIdentifierPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierIdentifierPayload';
  readonly carrierIdentifier?: Maybe<CarrierIdentifierV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCarrierParentInput = {
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId: Scalars['ID'];
  readonly carrierParentId: Scalars['ID'];
};

export type CreateOrUpdateCarrierParentPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierParentPayload';
  readonly carrierParent?: Maybe<ParentRelationship>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrier */
export type CreateOrUpdateCarrierPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierPayload';
  readonly carrier?: Maybe<Carrier>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrier */
export type CreateOrUpdateCarrierPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateCarrierPayloadV2';
  readonly carrier?: Maybe<CarrierV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCarrierReleaseMethodPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierReleaseMethodPayload';
  readonly releaseMethod?: Maybe<CarrierReleaseMethod>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrierReleaseMethodV1 */
export type CreateOrUpdateCarrierReleaseMethodV1Payload = {
  readonly __typename?: 'CreateOrUpdateCarrierReleaseMethodV1Payload';
  readonly errors: ReadonlyArray<UserError>;
  readonly releaseMethod?: Maybe<CarrierReleaseMethodV1>;
};

export type CreateOrUpdateCarrierRepPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierRepPayload';
  readonly carrierRep?: Maybe<RepV2>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrierRiskInformation */
export type CreateOrUpdateCarrierRiskInformationPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierRiskInformationPayload';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrierService */
export type CreateOrUpdateCarrierServicePayload = {
  readonly __typename?: 'CreateOrUpdateCarrierServicePayload';
  readonly carrierService?: Maybe<CarrierService>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrierService */
export type CreateOrUpdateCarrierServicePayloadV2 = {
  readonly __typename?: 'CreateOrUpdateCarrierServicePayloadV2';
  readonly carrierService?: Maybe<CarrierServiceV2>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrierSetting */
export type CreateOrUpdateCarrierSettingPayload = {
  readonly __typename?: 'CreateOrUpdateCarrierSettingPayload';
  readonly carrierSetting: CarrierSetting;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCarrierSetting */
export type CreateOrUpdateCarrierSettingPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateCarrierSettingPayloadV2';
  readonly carrierSetting: CarrierSettingV2;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCommodityInput = {
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly actualClass?: InputMaybe<CommodityFreightClassTypeEnum>;
  readonly actualClassId?: InputMaybe<Scalars['ID']>;
  /** Deprecated, use actualVolume */
  readonly actualCube?: InputMaybe<UnitOfLengthInput>;
  readonly actualDensity?: InputMaybe<Scalars['String']>;
  readonly actualDimensions?: InputMaybe<DimensionsInput>;
  readonly actualLinear?: InputMaybe<UnitOfLengthInput>;
  /** Deprecated due to typo, use actualNMFCCode */
  readonly actualNFMCCode?: InputMaybe<Scalars['String']>;
  readonly actualNMFCCode?: InputMaybe<Scalars['String']>;
  readonly actualPieces?: InputMaybe<Scalars['Int']>;
  readonly actualVolume?: InputMaybe<UnitOfVolumeInput>;
  readonly actualWeight?: InputMaybe<UnitOfMassInput>;
  readonly bottomLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly expectedClass?: InputMaybe<CommodityFreightClassTypeEnum>;
  readonly expectedClassId?: InputMaybe<Scalars['ID']>;
  /** Deprecated, use expectedVolume */
  readonly expectedCube?: InputMaybe<UnitOfLengthInput>;
  readonly expectedDensity?: InputMaybe<Scalars['String']>;
  readonly expectedDimensions?: InputMaybe<DimensionsInput>;
  readonly expectedLinear?: InputMaybe<UnitOfLengthInput>;
  /** Deprecated due to typo, use expectedNMFCCode */
  readonly expectedNFMCCode?: InputMaybe<Scalars['String']>;
  readonly expectedNMFCCode?: InputMaybe<Scalars['String']>;
  readonly expectedPieces?: InputMaybe<Scalars['Int']>;
  readonly expectedVolume?: InputMaybe<UnitOfVolumeInput>;
  readonly expectedWeight: UnitOfMassInput;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly hazmatClass?: InputMaybe<CommodityHazmatClassTypeEnum>;
  readonly hazmatClassId?: InputMaybe<Scalars['ID']>;
  readonly hazmatPackagingGroup?: InputMaybe<CommodityHazmatPackagingGroupTypeEnum>;
  readonly hazmatPhoneNumber?: InputMaybe<Scalars['String']>;
  readonly hazmatUnitedNationsNumber?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly loadOnType?: InputMaybe<CommodityLoadOnTypeEnum>;
  readonly loadOnTypeId?: InputMaybe<Scalars['ID']>;
  readonly maximumTemperature?: InputMaybe<UnitOfTemperatureInput>;
  readonly minimumTemperature?: InputMaybe<UnitOfTemperatureInput>;
  readonly orderId?: InputMaybe<Scalars['ID']>;
  readonly packagingCount?: InputMaybe<Scalars['Int']>;
  readonly packagingType?: InputMaybe<CommodityPackagingTypeEnum>;
  readonly packagingTypeId?: InputMaybe<Scalars['ID']>;
  readonly preCoolTo?: InputMaybe<UnitOfTemperatureInput>;
  readonly stccCode?: InputMaybe<Scalars['String']>;
  readonly temperatureControlled?: InputMaybe<Scalars['Boolean']>;
  readonly temperatureSetting?: InputMaybe<CommodityTemperatureSettingTypeEnum>;
  readonly topLoadable?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreateOrUpdateContact */
export type CreateOrUpdateContactPayload = {
  readonly __typename?: 'CreateOrUpdateContactPayload';
  readonly contact?: Maybe<Contact>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateContainerByRouteIdInput = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  /** Must be alphanumeric and shorter than 50 characters; required if reservationNumber is not present */
  readonly containerNumber?: InputMaybe<Scalars['String']>;
  readonly containerTypeId?: InputMaybe<Scalars['ID']>;
  readonly containerLengthUnit?: InputMaybe<UnitOfLengthEnum>;
  readonly containerLengthValue?: InputMaybe<Scalars['Float']>;
  readonly containerProgramId?: InputMaybe<Scalars['String']>;
  readonly destinationRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly endDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly endTimezone?: InputMaybe<Scalars['String']>;
  readonly externalSourceId?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly originLocation?: InputMaybe<CapacityLocationInput>;
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly previousRouteId?: InputMaybe<Scalars['ID']>;
  /** Must be alphanumeric and shorter than 50 characters; required if containerNumber is not present */
  readonly reservationNumber?: InputMaybe<Scalars['String']>;
  readonly reservationTypeId?: InputMaybe<Scalars['String']>;
  readonly startDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly startTimezone?: InputMaybe<Scalars['String']>;
  readonly tenantInternalId?: InputMaybe<Scalars['String']>;
  readonly updatedByUserId: Scalars['ID'];
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
};

export type CreateOrUpdateCustomerAddressPayload = {
  readonly __typename?: 'CreateOrUpdateCustomerAddressPayload';
  readonly customeraddress?: Maybe<CustomerAddress>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCustomerContactPayload = {
  readonly __typename?: 'CreateOrUpdateCustomerContactPayload';
  readonly contact?: Maybe<CustomerContact>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCustomerFacilityRelationship */
export type CreateOrUpdateCustomerFacilityRelationshipPayload = {
  readonly __typename?: 'CreateOrUpdateCustomerFacilityRelationshipPayload';
  readonly customerFacilityRelationship?: Maybe<CustomerFacilityRelationship>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCustomerFacilityRelationshipPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateCustomerFacilityRelationshipPayloadV2';
  readonly customerFacilityRelationship?: Maybe<CustomerFacilityRelationshipV2>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCustomerGroup */
export type CreateOrUpdateCustomerGroupPayload = {
  readonly __typename?: 'CreateOrUpdateCustomerGroupPayload';
  readonly customerGroup?: Maybe<CustomerGroup>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCustomerGroupPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateCustomerGroupPayloadV2';
  readonly customerGroup?: Maybe<CustomerGroupV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCustomerIdentifierPayload = {
  readonly __typename?: 'CreateOrUpdateCustomerIdentifierPayload';
  readonly customerIdentifier?: Maybe<IdentifierV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCustomerOrderInput = {
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly acceptedById?: InputMaybe<Scalars['ID']>;
  readonly activationStatusId?: InputMaybe<Scalars['ID']>;
  readonly activationStatusReason?: InputMaybe<Scalars['ID']>;
  readonly billToCustomerId?: InputMaybe<Scalars['ID']>;
  readonly cargoInsurance?: InputMaybe<CurrencyInput>;
  readonly commodities?: InputMaybe<ReadonlyArray<CreateOrUpdateCommodityInput>>;
  readonly customerId: Scalars['ID'];
  readonly customerOrderNumber?: InputMaybe<Scalars['String']>;
  readonly declinedById?: InputMaybe<Scalars['ID']>;
  readonly declinedReasonId?: InputMaybe<Scalars['ID']>;
  readonly dimensions?: InputMaybe<DimensionsInput>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly invoiceNote?: InputMaybe<Scalars['String']>;
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly orderNote?: InputMaybe<Scalars['String']>;
  readonly orderedById?: InputMaybe<Scalars['ID']>;
  readonly priceTierId?: InputMaybe<Scalars['ID']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly quoteTypeId?: InputMaybe<Scalars['ID']>;
  readonly refs?: InputMaybe<ReadonlyArray<RefInput>>;
  readonly relationId?: InputMaybe<Scalars['ID']>;
  readonly reps?: InputMaybe<ReadonlyArray<CustomerOrderRepInput>>;
  readonly requirements?: InputMaybe<ReadonlyArray<OrderRequirementInput>>;
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  readonly serviceLevelId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly tenderFromId?: InputMaybe<Scalars['ID']>;
  readonly tenderStatusId?: InputMaybe<Scalars['ID']>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
};

export type CreateOrUpdateCustomerOrderInputV2 = {
  readonly customerId: Scalars['ID'];
  readonly requirements?: InputMaybe<ReadonlyArray<OrderRequirementInput>>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly commodities?: InputMaybe<ReadonlyArray<CommodityInput>>;
  readonly shipments?: InputMaybe<ReadonlyArray<ShipmentInput>>;
  readonly refs?: InputMaybe<ReadonlyArray<RefInputV2>>;
  readonly dimensions?: InputMaybe<DimensionsInput>;
  readonly reps?: InputMaybe<ReadonlyArray<CustomerOrderRepInput>>;
  readonly activationStatusId?: InputMaybe<Scalars['ID']>;
  readonly tenderStatusId?: InputMaybe<Scalars['ID']>;
  /** Please use cargoInsuranceV2. It supports new currency values */
  readonly cargoInsurance?: InputMaybe<CurrencyInput>;
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly relationId?: InputMaybe<Scalars['ID']>;
  readonly quoteTypeId?: InputMaybe<Scalars['ID']>;
  readonly priceTierId?: InputMaybe<Scalars['ID']>;
  readonly invoiceNote?: InputMaybe<Scalars['String']>;
  readonly orderNote?: InputMaybe<Scalars['String']>;
  readonly acceptedById?: InputMaybe<Scalars['ID']>;
  readonly declinedById?: InputMaybe<Scalars['ID']>;
  readonly declineReason?: InputMaybe<Scalars['String']>;
  readonly declinedReasonId?: InputMaybe<Scalars['String']>;
  readonly orderedById?: InputMaybe<Scalars['ID']>;
  readonly divisionV2?: InputMaybe<Scalars['ID']>;
  readonly businessUnit?: InputMaybe<Scalars['ID']>;
  readonly projectV2?: InputMaybe<Scalars['ID']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  readonly serviceLevelId?: InputMaybe<Scalars['String']>;
  readonly billToCustomerId?: InputMaybe<Scalars['ID']>;
  readonly billToCodeId?: InputMaybe<Scalars['ID']>;
  readonly tenderFromId?: InputMaybe<Scalars['ID']>;
  readonly activationStatusReason?: InputMaybe<Scalars['String']>;
  readonly scac?: InputMaybe<Scalars['String']>;
  readonly tenderedAt?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly respondBy?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly customerOrderNumber?: InputMaybe<Scalars['String']>;
  readonly source?: InputMaybe<Scalars['String']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  readonly paymentTermsId?: InputMaybe<Scalars['ID']>;
  readonly tarpType?: InputMaybe<Scalars['ID']>;
  readonly tarpCount?: InputMaybe<Scalars['Int']>;
  readonly braceTypes?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly braceCount?: InputMaybe<Scalars['Int']>;
  readonly cargoInsuranceV2?: InputMaybe<LoadCurrencyInput>;
  readonly id?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateOrUpdateCustomerOrder */
export type CreateOrUpdateCustomerOrderPayload = {
  readonly __typename?: 'CreateOrUpdateCustomerOrderPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly order: CustomerOrder;
};

/** Autogenerated return type of CreateOrUpdateCustomer */
export type CreateOrUpdateCustomerPayload = {
  readonly __typename?: 'CreateOrUpdateCustomerPayload';
  readonly customer?: Maybe<Customer>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCustomerPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateCustomerPayloadV2';
  readonly customer?: Maybe<CustomerV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCustomerRepPayload = {
  readonly __typename?: 'CreateOrUpdateCustomerRepPayload';
  readonly customerRep?: Maybe<CustomerRepV2>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateCustomerSetting */
export type CreateOrUpdateCustomerSettingPayload = {
  readonly __typename?: 'CreateOrUpdateCustomerSettingPayload';
  readonly customerSetting?: Maybe<CustomerSetting>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateCustomerSettingPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateCustomerSettingPayloadV2';
  readonly customerSetting?: Maybe<CustomerSettingV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateDriverPayload = {
  readonly __typename?: 'CreateOrUpdateDriverPayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  readonly driver?: Maybe<AssetDriver>;
};

export type CreateOrUpdateDriverPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateDriverPayloadV2';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  readonly driver?: Maybe<AssetDriverV2>;
};

export type CreateOrUpdateDriverTraningPayload = {
  readonly __typename?: 'CreateOrUpdateDriverTraningPayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  readonly training?: Maybe<DriverTraining>;
};

export type CreateOrUpdateDriverWeeklyTargetPayload = {
  readonly __typename?: 'CreateOrUpdateDriverWeeklyTargetPayload';
  readonly errors?: Maybe<ReadonlyArray<DriverTypeError>>;
  readonly weeklyTarget?: Maybe<ReadonlyArray<DriverWeeklyTarget>>;
};

/** Autogenerated return type of CreateOrUpdateEmployeeExternal */
export type CreateOrUpdateEmployeeExternalPayload = {
  readonly __typename?: 'CreateOrUpdateEmployeeExternalPayload';
  readonly employee?: Maybe<Employee>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateEmployeeExternal */
export type CreateOrUpdateEmployeeExternalPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateEmployeeExternalPayloadV2';
  readonly employee?: Maybe<EmployeeV2>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateEmployee */
export type CreateOrUpdateEmployeePayload = {
  readonly __typename?: 'CreateOrUpdateEmployeePayload';
  readonly employee?: Maybe<Employee>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of CreateOrUpdateEmployee */
export type CreateOrUpdateEmployeePayloadV2 = {
  readonly __typename?: 'CreateOrUpdateEmployeePayloadV2';
  readonly employee?: Maybe<EmployeeV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateFacilityAddressPayload = {
  readonly __typename?: 'CreateOrUpdateFacilityAddressPayload';
  readonly address?: Maybe<FacilityAddress>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateFacilityContactPayload = {
  readonly __typename?: 'CreateOrUpdateFacilityContactPayload';
  readonly contact?: Maybe<FacilityContact>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateFacilityDirectionPayload = {
  readonly __typename?: 'CreateOrUpdateFacilityDirectionPayload';
  readonly direction?: Maybe<FacilityDirection>;
  readonly errors: ReadonlyArray<UserError>;
};

export type CreateOrUpdateFacilityHolidayPayload = {
  readonly __typename?: 'CreateOrUpdateFacilityHolidayPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly holiday?: Maybe<FacilityHoliday>;
};

/** Autogenerated return type of CreateOrUpdateFacilityIdentifier */
export type CreateOrUpdateFacilityIdentifierPayload = {
  readonly __typename?: 'CreateOrUpdateFacilityIdentifierPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly facilityIdentifier?: Maybe<FacilityIdentifier>;
};

export type CreateOrUpdateFacilityIdentifierPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateFacilityIdentifierPayloadV2';
  readonly errors: ReadonlyArray<UserError>;
  readonly facilityIdentifier?: Maybe<FacilityIdentifierV2>;
};

export type CreateOrUpdateFacilityLoadDefaultsBusinessUnitInput = {
  readonly businessUnit: Scalars['String'];
};

export type CreateOrUpdateFacilityLoadDefaultsDivisionInput = {
  readonly division: Scalars['String'];
};

export type CreateOrUpdateFacilityNoteDefaultsBusinessUnitInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly noteDefaultId?: InputMaybe<Scalars['String']>;
  readonly businessUnit: Scalars['String'];
};

export type CreateOrUpdateFacilityNoteDefaultsDivisionInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly noteDefaultId?: InputMaybe<Scalars['String']>;
  readonly division: Scalars['String'];
};

/** Autogenerated return type of CreateOrUpdateFacility */
export type CreateOrUpdateFacilityPayload = {
  readonly __typename?: 'CreateOrUpdateFacilityPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly facility?: Maybe<Facility>;
};

export type CreateOrUpdateFacilityPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateFacilityPayloadV2';
  readonly errors: ReadonlyArray<UserError>;
  readonly facility?: Maybe<FacilityV2>;
};

export type CreateOrUpdateFacilitySchedulePayload = {
  readonly __typename?: 'CreateOrUpdateFacilitySchedulePayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly schedule?: Maybe<ScheduleV2>;
};

export type CreateOrUpdateFacilitySublocationAccommodationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly sublocationId: Scalars['ID'];
  readonly accommodation: Scalars['String'];
  readonly rating?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type CreateOrUpdateFacilitySublocationAccommodationPayload = {
  readonly __typename?: 'CreateOrUpdateFacilitySublocationAccommodationPayload';
  readonly sublocationAccommodation?: Maybe<FacilitySublocationAccommodation>;
  readonly errors: ReadonlyArray<SublocationUserError>;
};

export type CreateOrUpdateFacilitySublocationActivityInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly sublocationId: Scalars['ID'];
  readonly activity: Scalars['String'];
  readonly averageTime?: InputMaybe<Scalars['Int']>;
  readonly averageTime100Loads?: InputMaybe<Scalars['Int']>;
  readonly variability?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type CreateOrUpdateFacilitySublocationActivityPayload = {
  readonly __typename?: 'CreateOrUpdateFacilitySublocationActivityPayload';
  readonly sublocationActivity?: Maybe<FacilitySublocationActivity>;
  readonly errors: ReadonlyArray<SublocationUserError>;
};

export type CreateOrUpdateFacilitySublocationGateInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly sublocationId: Scalars['ID'];
  readonly gate: Scalars['String'];
  readonly guardType?: InputMaybe<Scalars['String']>;
  readonly documentationNeeded?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly idChecked?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly guardContactInfo?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type CreateOrUpdateFacilitySublocationGatePayload = {
  readonly __typename?: 'CreateOrUpdateFacilitySublocationGatePayload';
  readonly sublocationGate?: Maybe<FacilitySublocationGate>;
  readonly errors: ReadonlyArray<SublocationUserError>;
};

export type CreateOrUpdateFacilitySublocationPayload = {
  readonly __typename?: 'CreateOrUpdateFacilitySublocationPayload';
  readonly sublocation?: Maybe<FacilitySublocation>;
  readonly errors: ReadonlyArray<SublocationUserError>;
};

export type CreateOrUpdateOrDeleteTruckByRouteIdInput = {
  readonly reloadIntentId: Scalars['String'];
  readonly previousRouteIdV2: Scalars['ID'];
  readonly createdByUserId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly trailerTypeId: Scalars['ID'];
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
};

export type CreateOrUpdatePowerPayload = {
  readonly __typename?: 'CreateOrUpdatePowerPayload';
  readonly errors?: Maybe<ReadonlyArray<PowerTypeError>>;
  readonly power?: Maybe<AssetPowerOutput>;
};

export type CreateOrUpdatePowerPayloadV2 = {
  readonly __typename?: 'CreateOrUpdatePowerPayloadV2';
  readonly errors?: Maybe<ReadonlyArray<PowerTypeError>>;
  readonly power?: Maybe<AssetPowerOutputV2>;
};

export type CreateOrUpdateProjectInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly name: Scalars['String'];
};

export type CreateOrUpdateProjectPayload = {
  readonly __typename?: 'CreateOrUpdateProjectPayload';
  readonly project?: Maybe<Project>;
  readonly errors: ReadonlyArray<ProjectUserError>;
};

export type CreateOrUpdateRouteVendorReferenceInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly value: Scalars['String'];
  readonly routeVendorReferenceType: RouteVendorReferenceTypeEnum;
  readonly ediQualifier?: InputMaybe<Scalars['String']>;
  readonly createdById?: InputMaybe<Scalars['String']>;
  readonly updatedById?: InputMaybe<Scalars['String']>;
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
};

export type CreateOrUpdateRouteVendorReferenceInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly value: Scalars['String'];
  readonly routeVendorReferenceTypeV2: Scalars['String'];
  readonly ediQualifier?: InputMaybe<Scalars['String']>;
  readonly createdById?: InputMaybe<Scalars['String']>;
  readonly updatedById?: InputMaybe<Scalars['String']>;
  readonly createdByUserId?: InputMaybe<Scalars['String']>;
  readonly updatedByUserId?: InputMaybe<Scalars['String']>;
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
};

export type CreateOrUpdateRouteVendorRepInput = {
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly employeeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly main: Scalars['Boolean'];
  readonly repType: RepTypeEnum;
};

export type CreateOrUpdateRouteVendorRepV2Input = {
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly employeeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly main: Scalars['Boolean'];
  readonly repType: RouteVendorRepTypeEnum;
};

/** Autogenerated return type of CreateOrUpdateSingleTruckPosting */
export type CreateOrUpdateSingleTruckPostingPayload = {
  readonly __typename?: 'CreateOrUpdateSingleTruckPostingPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly truckPosting: TruckPosting;
};

export type CreateOrUpdateStopInput = {
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly addressId?: InputMaybe<Scalars['ID']>;
  readonly appointment?: InputMaybe<AppointmentInput>;
  readonly apptConfBy?: InputMaybe<Scalars['String']>;
  readonly apptReqWith?: InputMaybe<Scalars['String']>;
  readonly availableEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly availableStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly bolNumber?: InputMaybe<Scalars['String']>;
  readonly carrierArrival?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly carrierDeparture?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly commodities?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly externalRef?: InputMaybe<Scalars['String']>;
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly lateArrivalReasonCodeId?: InputMaybe<Scalars['ID']>;
  readonly liveType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly loadFromId?: InputMaybe<Scalars['ID']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly refs?: InputMaybe<ReadonlyArray<RefInput>>;
  readonly reqDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly requirements?: InputMaybe<ReadonlyArray<StopRequirementInput>>;
  readonly routes?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly scheduleType?: InputMaybe<ScheduleAppointmentTypeEnum>;
  readonly sealNumber?: InputMaybe<Scalars['String']>;
  readonly signature?: InputMaybe<Scalars['String']>;
  readonly trailerNumberIn?: InputMaybe<Scalars['String']>;
  readonly trailerNumberOut?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<StopTypeEnum>;
  readonly unloadFromId?: InputMaybe<Scalars['ID']>;
  readonly workType?: InputMaybe<ScheduleWorkTypeEnum>;
};

/** Autogenerated return type of CreateOrUpdateStop */
export type CreateOrUpdateStopPayload = {
  readonly __typename?: 'CreateOrUpdateStopPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly stop: Stop;
};

export type CreateOrUpdateTrailerPayload = {
  readonly __typename?: 'CreateOrUpdateTrailerPayload';
  readonly errors?: Maybe<ReadonlyArray<TrailerTypeError>>;
  readonly trailer?: Maybe<AssetTrailers>;
};

export type CreateOrUpdateTrailerPayloadV2 = {
  readonly __typename?: 'CreateOrUpdateTrailerPayloadV2';
  readonly errors?: Maybe<ReadonlyArray<TrailerTypeError>>;
  readonly trailer?: Maybe<AssetTrailersV2>;
};

export type CreateOrUpdateTruckByExternalSourceIdInput = {
  readonly carrierId: Scalars['ID'];
  readonly createdByUserId: Scalars['ID'];
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly originLocation: TruckLocationInput;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  readonly updatedByUserId: Scalars['ID'];
  readonly bracingType?: InputMaybe<BracingTypesEnum>;
  readonly bracingCount?: InputMaybe<Scalars['Float']>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly externalSourceId: Scalars['String'];
  /** Truck's finalBy datetime. Value is nullified if the finalByDatetime value is less than the loadByDatetime value. */
  readonly finalByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly finalByTimezone?: InputMaybe<Scalars['String']>;
  /** Truck's loadBy datetime, defaults to the readyByDatetime (EOD) if a value is not provided or if the loadByDatetime value is less than the readyByDatetime value */
  readonly loadByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly loadByTimezone?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly palletsType?: InputMaybe<PalletTypesEnum>;
  readonly palletsCount?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED: Use previousRouteIdV2 instead */
  readonly previousRouteId?: InputMaybe<Scalars['ID']>;
  readonly previousRouteIdV2?: InputMaybe<Scalars['ID']>;
  readonly readyDatetimeUtc: Scalars['ISO8601DateTime'];
  /** DEPRECATED: Use sourceTypeId instead */
  readonly sourceType?: InputMaybe<SourceTypesEnum>;
  readonly sourceTypeId?: InputMaybe<Scalars['String']>;
  readonly tarpsType?: InputMaybe<TarpTypesEnum>;
  readonly tarpsCount?: InputMaybe<Scalars['Float']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly truckEntryTemplateId?: InputMaybe<Scalars['ID']>;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly expireAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly externalUpdatedAt: Scalars['ISO8601DateTime'];
  readonly externalUpdatedByUserId: Scalars['ID'];
};

export type CreatePatternInput = {
  readonly laneId: Scalars['ID'];
  readonly patternName: Scalars['String'];
  readonly eligibleDays?: InputMaybe<ReadonlyArray<DayOfWeekEnum>>;
  readonly eligibleDaysV2?: InputMaybe<ReadonlyArray<DayEnum>>;
  readonly patternRoutes: ReadonlyArray<CreatePatternRouteInput>;
  readonly minimumPickupLeadTime?: InputMaybe<Scalars['Int']>;
  readonly userId: Scalars['ID'];
  readonly effectiveDate?: InputMaybe<Scalars['Date']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
};

export type CreatePatternPayload = {
  readonly __typename?: 'CreatePatternPayload';
  readonly data?: Maybe<Pattern>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type CreatePatternRouteInput = {
  readonly routeSequence: Scalars['Int'];
  readonly routeTypeId: Scalars['String'];
  readonly transportModeId: Scalars['String'];
  readonly loadSizeId: Scalars['String'];
  readonly trailerTypeId: Scalars['String'];
  readonly divisionId?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly maxCost?: InputMaybe<Scalars['Float']>;
  readonly patternStops: ReadonlyArray<CreatePatternStopInput>;
};

export type CreatePatternStopInput = {
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly useOrderStopFacility?: InputMaybe<Scalars['Boolean']>;
  readonly stopSequence: Scalars['Int'];
  readonly stopType: Scalars['String'];
  readonly dropType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly workType?: InputMaybe<ScheduleWorkTypeEnum>;
  readonly requirements?: InputMaybe<ReadonlyArray<PatternStopRequirementInput>>;
  readonly daysFromPreviousStop?: InputMaybe<Scalars['Int']>;
  readonly laneStopSequence?: InputMaybe<Scalars['Int']>;
  readonly appointment?: InputMaybe<PatternStopAppointmentInput>;
};

export type CreatePlatformMessageInput = {
  readonly notificationChannels: ReadonlyArray<InputMaybe<PlatformNotificationChannels>>;
  readonly from: Scalars['String'];
  readonly to: ReadonlyArray<Scalars['String']>;
  readonly message: Scalars['String'];
  readonly messageId: Scalars['String'];
  readonly createdByUserId: Scalars['String'];
  readonly workspaceId?: InputMaybe<Scalars['String']>;
};

export type CreatePostingInput = {
  readonly createdByUserId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly postedRate: CurrencyInput;
  readonly bookItNowRate?: InputMaybe<CurrencyInput>;
  readonly size: UnitOfLengthInput;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly loadBoards?: InputMaybe<ReadonlyArray<PostingSource>>;
  readonly autoPosted?: InputMaybe<Scalars['Boolean']>;
};

export type CreatePostingPayload = {
  readonly __typename?: 'CreatePostingPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly posting?: Maybe<Posting>;
};

export type CreatePostingsInput = {
  readonly postings: ReadonlyArray<InputMaybe<CreatePostingInput>>;
};

export type CreatePostingsPayload = {
  readonly __typename?: 'CreatePostingsPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly postings?: Maybe<ReadonlyArray<Maybe<Posting>>>;
};

export type CreateRateConfirmationPayload = {
  readonly __typename?: 'CreateRateConfirmationPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly rateConfirmation: RateConfirmation;
};

export type CreateRoutingGuideInputV2 = {
  readonly routingGuideTypeTerm: Scalars['String'];
  readonly laneId: Scalars['ID'];
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly duration?: InputMaybe<Scalars['Int']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive: Scalars['Boolean'];
  readonly createdBy: Scalars['ID'];
};

export type CreateRoutingGuideSequenceItemInput = {
  readonly routingGuideId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly scac: Scalars['String'];
  readonly createdBy: Scalars['ID'];
  readonly tenderOptions?: InputMaybe<SequenceItemTenderOptionsInput>;
};

export type CreateScheduleInput = {
  readonly appointmentType: ScheduleAppointmentTypeEnum;
  readonly serviceType?: InputMaybe<Scalars['String']>;
  readonly dropType: ScheduleDropTypeEnum;
  readonly equipmentTypeId?: InputMaybe<Scalars['ID']>;
  readonly friday: ScheduleEntryInputV2;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly facilityId: Scalars['ID'];
  readonly modeType: ScheduleModeTypeEnum;
  readonly monday: ScheduleEntryInputV2;
  readonly saturday: ScheduleEntryInputV2;
  readonly scheduleType: ScheduleTypeEnum;
  readonly sunday: ScheduleEntryInputV2;
  readonly thursday: ScheduleEntryInputV2;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly tuesday: ScheduleEntryInputV2;
  readonly wednesday: ScheduleEntryInputV2;
  readonly workType: ScheduleWorkTypeEnum;
  readonly loadSizeId?: InputMaybe<Scalars['ID']>;
  readonly routeTransportModeId?: InputMaybe<Scalars['ID']>;
  /**  The field `temperature` is deprecated. Use `temperatures` instead.  */
  readonly temperature?: InputMaybe<Scalars['String']>;
  readonly temperatures?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly scheduleTypeNullable?: InputMaybe<ScheduleTypeEnum>;
  readonly trailerTypeIdNullable?: InputMaybe<Scalars['ID']>;
};

export type CreateServiceProfileInput = {
  readonly carrierId: Scalars['String'];
  readonly originRamp: Scalars['String'];
  readonly destinationRamp: Scalars['String'];
  readonly train?: InputMaybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly isCOFC?: InputMaybe<Scalars['Boolean']>;
  readonly isTOFC?: InputMaybe<Scalars['Boolean']>;
  readonly publishedTransitDays: Scalars['Float'];
  readonly reqOrigin?: InputMaybe<Scalars['Boolean']>;
  readonly reqLane?: InputMaybe<Scalars['Boolean']>;
  readonly boxType?: InputMaybe<Scalars['String']>;
  readonly laneStartDate: Scalars['DateTime'];
  readonly laneExpirationDate: Scalars['DateTime'];
  readonly laneExpirationDateV2?: InputMaybe<Scalars['DateTime']>;
  readonly schedules: BaseSchedulesInput;
};

export type CreateSpotQuoteInput = {
  readonly customerId: Scalars['ID'];
  /** The Minion transportMode's OptionId */
  readonly transportMode: Scalars['String'];
  /** The Minion loadSize's OptionId */
  readonly loadSize: Scalars['String'];
  readonly stops: ReadonlyArray<SpotQuoteStopInput>;
  readonly loadCount: Scalars['Int'];
  /** The Minion trailerType's OptionIds */
  readonly equipments: ReadonlyArray<Scalars['String']>;
  /** Length in feet */
  readonly length?: InputMaybe<Scalars['Int']>;
  /** Width in inches */
  readonly width?: InputMaybe<Scalars['Int']>;
  /** Height in inches */
  readonly height?: InputMaybe<Scalars['Int']>;
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate: Scalars['Date'];
  readonly shipStartDate: Scalars['Date'];
  readonly shipEndDate: Scalars['Date'];
  readonly desiredDeliveryDate?: InputMaybe<Scalars['Date']>;
  /** The Minion division's OptionId */
  readonly division?: InputMaybe<Scalars['String']>;
  /** The Minion project's OptionId */
  readonly project?: InputMaybe<Scalars['String']>;
  /** The Minion businessUnit's OptionId */
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  /** The Minion serviceLevel's OptionId */
  readonly serviceLevel: Scalars['String'];
  readonly quotedBy: Scalars['ID'];
  /** Optional Customer Contact */
  readonly quotedTo?: InputMaybe<Scalars['ID']>;
  /** The Minion tenantScac's OptionId */
  readonly scac?: InputMaybe<Scalars['String']>;
  readonly externalQuoteId?: InputMaybe<Scalars['String']>;
  readonly multiStop: Scalars['Boolean'];
  /** The Minion orderRequirement's OptionIds */
  readonly requirements: ReadonlyArray<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly rateLineItems: ReadonlyArray<SpotQuoteRateLineItemInput>;
  /** The user saving the spot quote */
  readonly savedBy: Scalars['ID'];
  /** The IANA timezone of the user. */
  readonly timezone: Scalars['String'];
};

export type CreateSpotQuotePayload = {
  readonly __typename?: 'CreateSpotQuotePayload';
  readonly data?: Maybe<SpotQuote>;
  readonly errors?: Maybe<ReadonlyArray<SpotQuoteError>>;
};

export type CreateTaskInput = {
  readonly name: Scalars['String'];
  readonly type: Scalars['String'];
  readonly status: Scalars['String'];
  readonly routeId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly requestedBy: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly neededBy?: InputMaybe<Scalars['DateTime']>;
  readonly assignedTo?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly loadCode?: InputMaybe<Scalars['String']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly groupId?: InputMaybe<Scalars['String']>;
  readonly externalId?: InputMaybe<Scalars['String']>;
  readonly comment?: InputMaybe<Scalars['String']>;
};

export type CreateTaskInputV2 = {
  readonly name: Scalars['String'];
  readonly type: Scalars['String'];
  readonly status: Scalars['String'];
  readonly routeId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly requestedBy: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly neededBy?: InputMaybe<Scalars['DateTime']>;
  readonly assignedTo?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly noteName?: InputMaybe<Scalars['String']>;
  readonly loadCode?: InputMaybe<Scalars['String']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly groupId?: InputMaybe<Scalars['String']>;
  readonly assignedToGroupId?: InputMaybe<Scalars['String']>;
  readonly externalId?: InputMaybe<Scalars['String']>;
  readonly comment?: InputMaybe<Scalars['String']>;
};

export type CreateTenantExceptionRecordInput = {
  readonly routeID: Scalars['String'];
  readonly orderID: Scalars['String'];
  readonly customerID: Scalars['String'];
  readonly carrierID?: InputMaybe<Scalars['String']>;
  readonly type: TenantExceptionRecordType;
  readonly status: TenantExceptionRecordStatus;
  readonly source: TenantExceptionRecordSource;
  readonly requirementName: Scalars['String'];
  readonly assignedTo?: InputMaybe<Scalars['String']>;
  readonly assignedToGroup?: InputMaybe<Scalars['String']>;
  readonly assignedBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy: Scalars['String'];
  readonly closedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateTruckEntryFromDriverAssignmentInput = {
  readonly reloadIntentId: Scalars['String'];
  readonly previousRouteIdV2: Scalars['ID'];
  readonly createdByUserId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly trailerTypeId: Scalars['ID'];
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
};

export type CreateTruckEntryInput = {
  readonly carrierId: Scalars['ID'];
  readonly createdByUserId: Scalars['ID'];
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly originLocation: TruckLocationInput;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  readonly updatedByUserId: Scalars['ID'];
  readonly bracingType?: InputMaybe<BracingTypesEnum>;
  readonly bracingCount?: InputMaybe<Scalars['Float']>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly externalSourceId?: InputMaybe<Scalars['String']>;
  /** Truck's finalBy datetime. Value is nullified if the finalByDatetime value is less than the loadByDatetime value. */
  readonly finalByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly finalByTimezone?: InputMaybe<Scalars['String']>;
  /** Truck's loadBy datetime, defaults to the readyByDatetime (EOD) if a value is not provided or if the loadByDatetime value is less than the readyByDatetime value */
  readonly loadByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly loadByTimezone?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly palletsType?: InputMaybe<PalletTypesEnum>;
  readonly palletsCount?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED: Use previousRouteIdV2 instead */
  readonly previousRouteId?: InputMaybe<Scalars['ID']>;
  readonly previousRouteIdV2?: InputMaybe<Scalars['ID']>;
  readonly readyDatetimeUtc: Scalars['ISO8601DateTime'];
  /** DEPRECATED: Use sourceTypeId instead */
  readonly sourceType?: InputMaybe<SourceTypesEnum>;
  readonly sourceTypeId?: InputMaybe<Scalars['String']>;
  readonly tarpsType?: InputMaybe<TarpTypesEnum>;
  readonly tarpsCount?: InputMaybe<Scalars['Float']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly truckEntryTemplateId?: InputMaybe<Scalars['ID']>;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
};

export type CreateTruckEntryTemplateInput = {
  readonly carrierId: Scalars['ID'];
  readonly createdByUserId: Scalars['ID'];
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly originLocation: TruckLocationInput;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  readonly updatedByUserId: Scalars['ID'];
  readonly fridayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly mondayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly saturdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly status?: InputMaybe<TruckEntryTemplateStatus>;
  readonly sourceType?: InputMaybe<TruckEntryTemplateSourceTypesEnum>;
  readonly sundayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly thursdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly tuesdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly wednesdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
};

export type CreateTruckEntryTemplateInputV2 = {
  readonly carrierId: Scalars['ID'];
  readonly createdByUserId: Scalars['ID'];
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly originLocation: TruckLocationInput;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  readonly updatedByUserId: Scalars['ID'];
  readonly fridayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly mondayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly saturdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly status?: InputMaybe<TruckEntryTemplateStatus>;
  readonly sourceType?: InputMaybe<TruckEntryTemplateSourceTypesEnum>;
  readonly sundayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly thursdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly trailerTypeId: Scalars['ID'];
  readonly tuesdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly wednesdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
};

export type CreditAvailability = {
  readonly __typename?: 'CreditAvailability';
  readonly approved: Scalars['Boolean'];
  readonly message: Scalars['String'];
  readonly details?: Maybe<Scalars['String']>;
};

export type CreditHistory = {
  readonly __typename?: 'CreditHistory';
  readonly createdAt: Scalars['Iso8601Date'];
  readonly createdBy: User;
  readonly creditLimit?: Maybe<Scalars['Float']>;
  readonly currency?: Maybe<KeyValue>;
  readonly customerId: Scalars['ID'];
  readonly expiration?: Maybe<Scalars['Iso8601Date']>;
  readonly expirationDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly id: Scalars['ID'];
  readonly note?: Maybe<Scalars['String']>;
  readonly status: CreditStatusEnum;
};

export type CreditHistoryInput = {
  readonly creditLimit?: InputMaybe<Scalars['Float']>;
  readonly currencyId?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly expiration?: InputMaybe<Scalars['ISO8601Date']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly limit?: InputMaybe<Scalars['Float']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly status: Scalars['String'];
};

export type CreditHistoryInputV2 = {
  readonly creditLimit?: InputMaybe<Scalars['Float']>;
  readonly currencyId?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly expiration?: InputMaybe<Scalars['ISO8601Date']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly limit?: InputMaybe<Scalars['Float']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly status: Scalars['String'];
};

export type CreditHistoryV2 = {
  readonly __typename?: 'CreditHistoryV2';
  readonly createdAt: Scalars['Iso8601Date'];
  readonly createdById?: Maybe<Scalars['String']>;
  readonly createdBy: UserV2;
  readonly creditLimit?: Maybe<Scalars['Float']>;
  readonly currency?: Maybe<KeyValue>;
  readonly currencyId?: Maybe<Scalars['ID']>;
  readonly customerId: Scalars['ID'];
  readonly expiration?: Maybe<Scalars['Iso8601Date']>;
  readonly expirationDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly id: Scalars['ID'];
  readonly note?: Maybe<Scalars['String']>;
  readonly status: CreditStatusEnum;
};

export type CreditInvoiceHeader = {
  readonly id: Scalars['ID'];
  readonly statusId: StatusEnum;
  readonly currentAmountDue: Scalars['Float'];
};

export type CreditRateDetails = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly totalRate: Scalars['Float'];
  readonly statusId: StatusEnum;
};

export type CreditResponse = {
  readonly __typename?: 'CreditResponse';
  readonly approved: Scalars['Boolean'];
  readonly message: Scalars['String'];
  readonly details?: Maybe<Scalars['String']>;
};

export enum CreditStatusEnum {
  Approved = 'Approved',
  Hold = 'Hold',
  Denied = 'Denied',
  Pending = 'Pending'
}

export type CrmCarrierDetail = {
  readonly __typename?: 'CRMCarrierDetail';
  readonly id?: Maybe<Scalars['ID']>;
  readonly interactionFrequencyTypeId?: Maybe<Scalars['ID']>;
  readonly leadNotes?: Maybe<Scalars['String']>;
  readonly leadSourceTypeId?: Maybe<Scalars['ID']>;
  readonly vendorId?: Maybe<Scalars['ID']>;
};

export type CrmCarrierDetailInputType = {
  readonly interactionFrequencyTypeId?: InputMaybe<Scalars['ID']>;
  readonly leadNotes?: InputMaybe<Scalars['String']>;
  readonly leadSourceTypeId?: InputMaybe<Scalars['ID']>;
  readonly vendorId: Scalars['ID'];
};

export type CrmCompanyDescriptionInput = {
  readonly companyDescriptionText?: InputMaybe<Scalars['String']>;
  readonly crmDetailsEntityLookupId?: InputMaybe<Scalars['String']>;
  readonly entityId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmCompanyDescriptionOutput = {
  readonly __typename?: 'CrmCompanyDescriptionOutput';
  readonly companyDescriptionId: Scalars['ID'];
  readonly companyDescriptionText?: Maybe<Scalars['String']>;
  readonly crmDetailsEntityLookupId?: Maybe<Scalars['String']>;
  readonly entityId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedEntityFunctionInput = {
  readonly Contact_Associated_Entity_Id?: InputMaybe<Scalars['String']>;
  readonly Function_ValueTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedEntityFunctionInputV2 = {
  readonly Contact_Associated_Entity_Id?: InputMaybe<Scalars['String']>;
  readonly Function_ValueTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedEntityFunctionOutput = {
  readonly __typename?: 'crmContactAssociatedEntityFunctionOutput';
  readonly Contact_Associated_Entity_Function_Id: Scalars['ID'];
  readonly Contact_Associated_Entity_Id?: Maybe<Scalars['String']>;
  readonly Function_ValueTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedEntityFunctionOutputV2 = {
  readonly __typename?: 'CrmContactAssociatedEntityFunctionOutputV2';
  readonly Contact_Associated_Entity_Function_Id: Scalars['ID'];
  readonly Contact_Associated_Entity_Id?: Maybe<Scalars['String']>;
  readonly Function_ValueTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedEntityInput = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Entity_Id?: InputMaybe<Scalars['String']>;
  readonly Entity_TypeTerm?: InputMaybe<Scalars['String']>;
  readonly Entity_Lookup?: InputMaybe<Scalars['String']>;
  readonly Entity_Name?: InputMaybe<Scalars['String']>;
  readonly Main_Phone?: InputMaybe<Scalars['String']>;
  readonly Main_Email?: InputMaybe<Scalars['String']>;
  readonly Details?: InputMaybe<Scalars['String']>;
  readonly StatusTerm?: InputMaybe<Scalars['String']>;
  readonly Status_ReasonTerm?: InputMaybe<Scalars['String']>;
  readonly Is_Main?: InputMaybe<Scalars['Boolean']>;
  readonly Is_Ext_User?: InputMaybe<Scalars['Boolean']>;
  readonly Location?: InputMaybe<Scalars['String']>;
  readonly Is_Active?: InputMaybe<Scalars['Boolean']>;
  readonly crm_Contact_Associated_Entity_Function?: InputMaybe<ReadonlyArray<CrmContactAssociatedEntityFunctionInput>>;
  readonly crm_Contact_Responsibility_Associated_Entity?: InputMaybe<ReadonlyArray<CrmContactResponsibilityAssociatedEntityInput>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedEntityInputV2 = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Entity_Id?: InputMaybe<Scalars['String']>;
  readonly Entity_TypeTerm?: InputMaybe<Scalars['String']>;
  readonly Entity_Lookup?: InputMaybe<Scalars['String']>;
  readonly Entity_Name?: InputMaybe<Scalars['String']>;
  readonly Main_Phone?: InputMaybe<Scalars['String']>;
  readonly Main_Email?: InputMaybe<Scalars['String']>;
  readonly Details?: InputMaybe<Scalars['String']>;
  readonly StatusTerm?: InputMaybe<Scalars['String']>;
  readonly Status_ReasonTerm?: InputMaybe<Scalars['String']>;
  readonly Is_Main?: InputMaybe<Scalars['Boolean']>;
  readonly Is_Ext_User?: InputMaybe<Scalars['Boolean']>;
  readonly Location?: InputMaybe<Scalars['String']>;
  readonly Is_Active?: InputMaybe<Scalars['Boolean']>;
  readonly crm_Contact_Associated_Entity_Function?: InputMaybe<ReadonlyArray<CrmContactAssociatedEntityFunctionInputV2>>;
  readonly crm_Contact_Responsibility_Associated_Entity?: InputMaybe<ReadonlyArray<CrmContactResponsibilityAssociatedEntityInputV2>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedEntityOutput = {
  readonly __typename?: 'crmContactAssociatedEntityOutput';
  readonly Contact_Associated_Entity_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Entity_Id?: Maybe<Scalars['String']>;
  readonly Entity_TypeTerm?: Maybe<Scalars['String']>;
  readonly Entity_Lookup?: Maybe<Scalars['String']>;
  readonly Entity_Name?: Maybe<Scalars['String']>;
  readonly Main_Phone?: Maybe<Scalars['String']>;
  readonly Main_Email?: Maybe<Scalars['String']>;
  readonly Details?: Maybe<Scalars['String']>;
  readonly StatusTerm?: Maybe<Scalars['String']>;
  readonly Status_ReasonTerm?: Maybe<Scalars['String']>;
  readonly Is_Main?: Maybe<Scalars['Boolean']>;
  readonly Is_Ext_User?: Maybe<Scalars['Boolean']>;
  readonly Location?: Maybe<Scalars['String']>;
  readonly Is_Active?: Maybe<Scalars['Boolean']>;
  readonly crm_Contact_Associated_Entity_Function?: Maybe<ReadonlyArray<CrmContactAssociatedEntityFunctionOutput>>;
  readonly crm_Contact_Responsibility_Associated_Entity?: Maybe<ReadonlyArray<CrmContactResponsibilityAssociatedEntityOutput>>;
  readonly crm_Contact_Associated_Entity_Phone?: Maybe<CrmContactInformationOutput>;
  readonly crm_Contact_Associated_Entity_Email?: Maybe<CrmContactInformationOutput>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedEntityOutputV2 = {
  readonly __typename?: 'CrmContactAssociatedEntityOutputV2';
  readonly Contact_Associated_Entity_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Entity_Id?: Maybe<Scalars['String']>;
  readonly Entity_TypeTerm?: Maybe<Scalars['String']>;
  readonly Entity_Lookup?: Maybe<Scalars['String']>;
  readonly Entity_Name?: Maybe<Scalars['String']>;
  readonly Main_Phone?: Maybe<Scalars['String']>;
  readonly Main_Email?: Maybe<Scalars['String']>;
  readonly Details?: Maybe<Scalars['String']>;
  readonly StatusTerm?: Maybe<Scalars['String']>;
  readonly Status_ReasonTerm?: Maybe<Scalars['String']>;
  readonly Is_Main?: Maybe<Scalars['Boolean']>;
  readonly Is_Ext_User?: Maybe<Scalars['Boolean']>;
  readonly Location?: Maybe<Scalars['String']>;
  readonly Is_Active?: Maybe<Scalars['Boolean']>;
  readonly crm_Contact_Associated_Entity_Function?: Maybe<ReadonlyArray<CrmContactAssociatedEntityFunctionOutputV2>>;
  readonly crm_Contact_Responsibility_Associated_Entity?: Maybe<ReadonlyArray<CrmContactResponsibilityAssociatedEntityOutputV2>>;
  readonly crm_Contact_Associated_Entity_Phone?: Maybe<CrmContactInformationOutputV2>;
  readonly crm_Contact_Associated_Entity_Email?: Maybe<CrmContactInformationOutputV2>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepInput = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Rep?: InputMaybe<Scalars['String']>;
  readonly Details?: InputMaybe<Scalars['String']>;
  readonly Is_Active?: InputMaybe<Scalars['Boolean']>;
  readonly Rep_Id?: InputMaybe<Scalars['String']>;
  readonly Rep_Role?: InputMaybe<Scalars['String']>;
  readonly crm_Contact_Associated_Rep_Relationship?: InputMaybe<ReadonlyArray<CrmContactAssociatedRepRelationshipInput>>;
  readonly crm_Contact_Associated_Rep_Responsibility?: InputMaybe<ReadonlyArray<CrmContactAssociatedRepResponsibilityInput>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepInputV2 = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Rep?: InputMaybe<Scalars['String']>;
  readonly Details?: InputMaybe<Scalars['String']>;
  readonly Is_Active?: InputMaybe<Scalars['Boolean']>;
  readonly Rep_Id?: InputMaybe<Scalars['String']>;
  readonly Rep_Role?: InputMaybe<Scalars['String']>;
  readonly crm_Contact_Associated_Rep_Relationship?: InputMaybe<ReadonlyArray<CrmContactAssociatedRepRelationshipInputV2>>;
  readonly crm_Contact_Associated_Rep_Responsibility?: InputMaybe<ReadonlyArray<CrmContactAssociatedRepResponsibilityInputV2>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepOutput = {
  readonly __typename?: 'crmContactAssociatedRepOutput';
  readonly Contact_Associated_Rep_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Rep?: Maybe<Scalars['String']>;
  readonly Details?: Maybe<Scalars['String']>;
  readonly Is_Active?: Maybe<Scalars['Boolean']>;
  readonly Rep_Id?: Maybe<Scalars['String']>;
  readonly Rep_Role?: Maybe<Scalars['String']>;
  readonly crm_Contact_Associated_Rep_Relationship?: Maybe<ReadonlyArray<CrmContactAssociatedRepRelationshipOutput>>;
  readonly crm_Contact_Associated_Rep_Responsibility?: Maybe<ReadonlyArray<CrmContactAssociatedRepResponsibilityOutput>>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepOutputV2 = {
  readonly __typename?: 'CrmContactAssociatedRepOutputV2';
  readonly Contact_Associated_Rep_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Rep?: Maybe<Scalars['String']>;
  readonly Details?: Maybe<Scalars['String']>;
  readonly Is_Active?: Maybe<Scalars['Boolean']>;
  readonly Rep_Id?: Maybe<Scalars['String']>;
  readonly Rep_Role?: Maybe<Scalars['String']>;
  readonly crm_Contact_Associated_Rep_Relationship?: Maybe<ReadonlyArray<CrmContactAssociatedRepRelationshipOutputV2>>;
  readonly crm_Contact_Associated_Rep_Responsibility?: Maybe<ReadonlyArray<CrmContactAssociatedRepResponsibilityOutputV2>>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepRelationshipInput = {
  readonly Contact_Associated_Rep_Id?: InputMaybe<Scalars['String']>;
  readonly Relationship_ValueTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepRelationshipInputV2 = {
  readonly Contact_Associated_Rep_Id?: InputMaybe<Scalars['String']>;
  readonly Relationship_ValueTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepRelationshipOutput = {
  readonly __typename?: 'crmContactAssociatedRepRelationshipOutput';
  readonly Contact_Associated_Rep_Relationship_Id: Scalars['ID'];
  readonly Contact_Associated_Rep_Id?: Maybe<Scalars['String']>;
  readonly Relationship_ValueTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepRelationshipOutputV2 = {
  readonly __typename?: 'CrmContactAssociatedRepRelationshipOutputV2';
  readonly Contact_Associated_Rep_Relationship_Id: Scalars['ID'];
  readonly Contact_Associated_Rep_Id?: Maybe<Scalars['String']>;
  readonly Relationship_ValueTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepResponsibilityInput = {
  readonly Contact_Associated_Rep_Id?: InputMaybe<Scalars['String']>;
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepResponsibilityInputV2 = {
  readonly Contact_Associated_Rep_Id?: InputMaybe<Scalars['String']>;
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepResponsibilityOutput = {
  readonly __typename?: 'crmContactAssociatedRepResponsibilityOutput';
  readonly Contact_Associated_Rep_Responsibility_Id: Scalars['ID'];
  readonly Contact_Associated_Rep_Id?: Maybe<Scalars['String']>;
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactAssociatedRepResponsibilityOutputV2 = {
  readonly __typename?: 'CrmContactAssociatedRepResponsibilityOutputV2';
  readonly Contact_Associated_Rep_Responsibility_Id: Scalars['ID'];
  readonly Contact_Associated_Rep_Id?: Maybe<Scalars['String']>;
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactEntityLookupOutput = {
  readonly __typename?: 'crmContactEntityLookupOutput';
  readonly Contact_Entity_Lookup_Id: Scalars['ID'];
  readonly Entity_Type?: Maybe<Scalars['Float']>;
  readonly Entity_Name?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactEntityLookupOutputV2 = {
  readonly __typename?: 'CrmContactEntityLookupOutputV2';
  readonly Contact_Entity_Lookup_Id: Scalars['ID'];
  readonly Entity_Type?: Maybe<Scalars['Float']>;
  readonly Entity_Name?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactInformationInput = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Contact_Information_Type_Id?: InputMaybe<Scalars['String']>;
  readonly LabelTerm?: InputMaybe<Scalars['String']>;
  readonly Country_Code?: InputMaybe<Scalars['String']>;
  readonly Contact_Value?: InputMaybe<Scalars['String']>;
  readonly Ext?: InputMaybe<Scalars['String']>;
  readonly Details?: InputMaybe<Scalars['String']>;
  readonly Is_Personal?: InputMaybe<Scalars['Boolean']>;
  readonly Is_Active?: InputMaybe<Scalars['Boolean']>;
  readonly crm_Contact_Information_Type?: InputMaybe<ReadonlyArray<CrmContactInformationTypeInput>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactInformationInputV2 = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Contact_Information_Type_Id?: InputMaybe<Scalars['String']>;
  readonly LabelTerm?: InputMaybe<Scalars['String']>;
  readonly Country_Code?: InputMaybe<Scalars['String']>;
  readonly Contact_Value?: InputMaybe<Scalars['String']>;
  readonly Ext?: InputMaybe<Scalars['String']>;
  readonly Details?: InputMaybe<Scalars['String']>;
  readonly Is_Personal?: InputMaybe<Scalars['Boolean']>;
  readonly Is_Active?: InputMaybe<Scalars['Boolean']>;
  readonly crm_Contact_Information_Type?: InputMaybe<ReadonlyArray<CrmContactInformationTypeInputV2>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactInformationOutput = {
  readonly __typename?: 'crmContactInformationOutput';
  readonly Contact_Information_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Contact_Information_Type_Id?: Maybe<Scalars['String']>;
  readonly LabelTerm?: Maybe<Scalars['String']>;
  readonly Country_Code?: Maybe<Scalars['String']>;
  readonly Contact_Value?: Maybe<Scalars['String']>;
  readonly Ext?: Maybe<Scalars['String']>;
  readonly Details?: Maybe<Scalars['String']>;
  readonly Is_Personal?: Maybe<Scalars['Boolean']>;
  readonly Is_Active?: Maybe<Scalars['Boolean']>;
  readonly crm_Contact_Information_Type?: Maybe<ReadonlyArray<CrmContactInformationTypeOutput>>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactInformationOutputV2 = {
  readonly __typename?: 'CrmContactInformationOutputV2';
  readonly Contact_Information_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Contact_Information_Type_Id?: Maybe<Scalars['String']>;
  readonly LabelTerm?: Maybe<Scalars['String']>;
  readonly Country_Code?: Maybe<Scalars['String']>;
  readonly Contact_Value?: Maybe<Scalars['String']>;
  readonly Ext?: Maybe<Scalars['String']>;
  readonly Details?: Maybe<Scalars['String']>;
  readonly Is_Personal?: Maybe<Scalars['Boolean']>;
  readonly Is_Active?: Maybe<Scalars['Boolean']>;
  readonly crm_Contact_Information_Type?: Maybe<ReadonlyArray<CrmContactInformationTypeOutputV2>>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactInformationTypeInput = {
  readonly Contact_Type_Id?: InputMaybe<Scalars['Float']>;
  readonly Contact_Type?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactInformationTypeInputV2 = {
  readonly Contact_Type_Id?: InputMaybe<Scalars['Float']>;
  readonly Contact_Type?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactInformationTypeOutput = {
  readonly __typename?: 'crmContactInformationTypeOutput';
  readonly Contact_Information_Type_Id: Scalars['ID'];
  readonly Contact_Type_Id?: Maybe<Scalars['Float']>;
  readonly Contact_Type?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactInformationTypeOutputV2 = {
  readonly __typename?: 'CrmContactInformationTypeOutputV2';
  readonly Contact_Information_Type_Id: Scalars['ID'];
  readonly Contact_Type_Id?: Maybe<Scalars['Float']>;
  readonly Contact_Type?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactInput = {
  readonly Name?: InputMaybe<Scalars['String']>;
  readonly SuffixTerm?: InputMaybe<Scalars['String']>;
  readonly PronounsTerm?: InputMaybe<Scalars['String']>;
  readonly Preferred_Name?: InputMaybe<Scalars['String']>;
  readonly Pronunciation?: InputMaybe<Scalars['String']>;
  readonly Zoom_Info_URL?: InputMaybe<Scalars['String']>;
  readonly Zoom_Info_Number?: InputMaybe<Scalars['String']>;
  readonly LinkedIn_URL?: InputMaybe<Scalars['String']>;
  readonly Twitter_URL?: InputMaybe<Scalars['String']>;
  readonly DepartmentTerm?: InputMaybe<Scalars['String']>;
  readonly Title?: InputMaybe<Scalars['String']>;
  readonly LevelTerm?: InputMaybe<Scalars['String']>;
  readonly Primary_Work_Location?: InputMaybe<Scalars['String']>;
  readonly Work_EnvironmentTerm?: InputMaybe<Scalars['String']>;
  readonly Communication_PreferenceTerm?: InputMaybe<Scalars['String']>;
  readonly Pricing_BehaviorTerm?: InputMaybe<Scalars['String']>;
  readonly Manager?: InputMaybe<Scalars['String']>;
  readonly Hometown?: InputMaybe<Scalars['String']>;
  readonly Current_Location?: InputMaybe<Scalars['String']>;
  readonly Primary_LanguageTerm?: InputMaybe<Scalars['String']>;
  readonly Secondary_LanguageTerm?: InputMaybe<Scalars['String']>;
  readonly Birthday?: InputMaybe<Scalars['DateTime']>;
  readonly Personal_Notes?: InputMaybe<Scalars['String']>;
  readonly External_User?: InputMaybe<Scalars['String']>;
  readonly Entity_Id?: InputMaybe<Scalars['String']>;
  readonly Phone_Value?: InputMaybe<Scalars['String']>;
  readonly Phone_Extension?: InputMaybe<Scalars['String']>;
  readonly Phone_Label?: InputMaybe<Scalars['String']>;
  readonly Phone_Notes?: InputMaybe<Scalars['String']>;
  readonly Phone_Is_Personal?: InputMaybe<Scalars['Boolean']>;
  readonly Email_Value?: InputMaybe<Scalars['String']>;
  readonly Email_Label?: InputMaybe<Scalars['String']>;
  readonly Email_Notes?: InputMaybe<Scalars['String']>;
  readonly Email_Is_Personal?: InputMaybe<Scalars['Boolean']>;
  readonly Entity_Name?: InputMaybe<Scalars['String']>;
  readonly Entity_Type?: InputMaybe<Scalars['String']>;
  readonly Entity_Status?: InputMaybe<Scalars['Boolean']>;
  readonly Price?: InputMaybe<Scalars['Float']>;
  readonly Service?: InputMaybe<Scalars['Float']>;
  readonly Rookie?: InputMaybe<Scalars['Float']>;
  readonly Expert?: InputMaybe<Scalars['Float']>;
  readonly Human?: InputMaybe<Scalars['Float']>;
  readonly Technology?: InputMaybe<Scalars['Float']>;
  readonly Handson?: InputMaybe<Scalars['Float']>;
  readonly Handsoff?: InputMaybe<Scalars['Float']>;
  readonly Supporter?: InputMaybe<Scalars['Float']>;
  readonly Detractor?: InputMaybe<Scalars['Float']>;
  readonly Contact_Entity_Lookup_Id?: InputMaybe<Scalars['String']>;
  readonly crm_Contact_Information?: InputMaybe<ReadonlyArray<CrmContactInformationInput>>;
  readonly crm_Contact_Associated_Entity?: InputMaybe<ReadonlyArray<CrmContactAssociatedEntityInput>>;
  readonly crm_Contact_Associated_Rep?: InputMaybe<ReadonlyArray<CrmContactAssociatedRepInput>>;
  readonly crm_Contact_Responsibility?: InputMaybe<ReadonlyArray<CrmContactResponsibilityInput>>;
  readonly crm_Contact_Slider?: InputMaybe<ReadonlyArray<CrmContactSliderInput>>;
  readonly crm_Contact_Pricing_Behaviour?: InputMaybe<ReadonlyArray<CrmContactPricingBehaviourInput>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly InterestsAndHobbies?: InputMaybe<Scalars['String']>;
};

export type CrmContactInputV2 = {
  readonly Name?: InputMaybe<Scalars['String']>;
  readonly SuffixTerm?: InputMaybe<Scalars['String']>;
  readonly PronounsTerm?: InputMaybe<Scalars['String']>;
  readonly Preferred_Name?: InputMaybe<Scalars['String']>;
  readonly Pronunciation?: InputMaybe<Scalars['String']>;
  readonly Zoom_Info_URL?: InputMaybe<Scalars['String']>;
  readonly Zoom_Info_Number?: InputMaybe<Scalars['String']>;
  readonly LinkedIn_URL?: InputMaybe<Scalars['String']>;
  readonly Twitter_URL?: InputMaybe<Scalars['String']>;
  readonly DepartmentTerm?: InputMaybe<Scalars['String']>;
  readonly Title?: InputMaybe<Scalars['String']>;
  readonly LevelTerm?: InputMaybe<Scalars['String']>;
  readonly Primary_Work_Location?: InputMaybe<Scalars['String']>;
  readonly Work_EnvironmentTerm?: InputMaybe<Scalars['String']>;
  readonly Communication_PreferenceTerm?: InputMaybe<Scalars['String']>;
  readonly Pricing_BehaviorTerm?: InputMaybe<Scalars['String']>;
  readonly Manager?: InputMaybe<Scalars['String']>;
  readonly Hometown?: InputMaybe<Scalars['String']>;
  readonly Current_Location?: InputMaybe<Scalars['String']>;
  readonly Primary_LanguageTerm?: InputMaybe<Scalars['String']>;
  readonly Secondary_LanguageTerm?: InputMaybe<Scalars['String']>;
  readonly Birthday?: InputMaybe<Scalars['DateTime']>;
  readonly Personal_Notes?: InputMaybe<Scalars['String']>;
  readonly External_User?: InputMaybe<Scalars['String']>;
  readonly Entity_Id?: InputMaybe<Scalars['String']>;
  readonly Phone_Value?: InputMaybe<Scalars['String']>;
  readonly Phone_Extension?: InputMaybe<Scalars['String']>;
  readonly Phone_Label?: InputMaybe<Scalars['String']>;
  readonly Phone_Notes?: InputMaybe<Scalars['String']>;
  readonly Phone_Is_Personal?: InputMaybe<Scalars['Boolean']>;
  readonly Email_Value?: InputMaybe<Scalars['String']>;
  readonly Email_Label?: InputMaybe<Scalars['String']>;
  readonly Email_Notes?: InputMaybe<Scalars['String']>;
  readonly Email_Is_Personal?: InputMaybe<Scalars['Boolean']>;
  readonly Entity_Name?: InputMaybe<Scalars['String']>;
  readonly Entity_Type?: InputMaybe<Scalars['String']>;
  readonly Entity_Status?: InputMaybe<Scalars['Boolean']>;
  readonly Price?: InputMaybe<Scalars['Float']>;
  readonly Service?: InputMaybe<Scalars['Float']>;
  readonly Rookie?: InputMaybe<Scalars['Float']>;
  readonly Expert?: InputMaybe<Scalars['Float']>;
  readonly Human?: InputMaybe<Scalars['Float']>;
  readonly Technology?: InputMaybe<Scalars['Float']>;
  readonly Handson?: InputMaybe<Scalars['Float']>;
  readonly Handsoff?: InputMaybe<Scalars['Float']>;
  readonly Supporter?: InputMaybe<Scalars['Float']>;
  readonly Detractor?: InputMaybe<Scalars['Float']>;
  readonly Contact_Entity_Lookup_Id?: InputMaybe<Scalars['String']>;
  readonly crm_Contact_Information?: InputMaybe<ReadonlyArray<CrmContactInformationInputV2>>;
  readonly crm_Contact_Associated_Entity?: InputMaybe<ReadonlyArray<CrmContactAssociatedEntityInputV2>>;
  readonly crm_Contact_Associated_Rep?: InputMaybe<ReadonlyArray<CrmContactAssociatedRepInputV2>>;
  readonly crm_Contact_Responsibility?: InputMaybe<ReadonlyArray<CrmContactResponsibilityInputV2>>;
  readonly crm_Contact_Slider?: InputMaybe<ReadonlyArray<CrmContactSliderInputV2>>;
  readonly crm_Contact_Pricing_Behaviour?: InputMaybe<ReadonlyArray<CrmContactPricingBehaviourInput>>;
  readonly crm_Contact_Interest_Hobby?: InputMaybe<ReadonlyArray<CrmContactInterestHobbyInputV2>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly InterestsAndHobbies?: InputMaybe<Scalars['String']>;
};

export type CrmContactInterestHobbyInputV2 = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Contact_Interest_Hobby_Value?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactInterestHobbyOutputV2 = {
  readonly __typename?: 'CrmContactInterestHobbyOutputV2';
  readonly Contact_Interest_Hobby_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Contact_Interest_Hobby_Value?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactOutput = {
  readonly __typename?: 'crmContactOutput';
  readonly Contact_Id: Scalars['ID'];
  readonly Name?: Maybe<Scalars['String']>;
  readonly SuffixTerm?: Maybe<Scalars['String']>;
  readonly PronounsTerm?: Maybe<Scalars['String']>;
  readonly Preferred_Name?: Maybe<Scalars['String']>;
  readonly Pronunciation?: Maybe<Scalars['String']>;
  readonly Zoom_Info_URL?: Maybe<Scalars['String']>;
  readonly Zoom_Info_Number?: Maybe<Scalars['String']>;
  readonly LinkedIn_URL?: Maybe<Scalars['String']>;
  readonly Twitter_URL?: Maybe<Scalars['String']>;
  readonly DepartmentTerm?: Maybe<Scalars['String']>;
  readonly Title?: Maybe<Scalars['String']>;
  readonly LevelTerm?: Maybe<Scalars['String']>;
  readonly Primary_Work_Location?: Maybe<Scalars['String']>;
  readonly Work_EnvironmentTerm?: Maybe<Scalars['String']>;
  readonly Communication_PreferenceTerm?: Maybe<Scalars['String']>;
  readonly Pricing_BehaviorTerm?: Maybe<Scalars['String']>;
  readonly Manager?: Maybe<Scalars['String']>;
  readonly Hometown?: Maybe<Scalars['String']>;
  readonly Current_Location?: Maybe<Scalars['String']>;
  readonly Primary_LanguageTerm?: Maybe<Scalars['String']>;
  readonly Secondary_LanguageTerm?: Maybe<Scalars['String']>;
  readonly Birthday?: Maybe<Scalars['DateTime']>;
  readonly Personal_Notes?: Maybe<Scalars['String']>;
  readonly External_User?: Maybe<Scalars['String']>;
  readonly Entity_Id?: Maybe<Scalars['String']>;
  readonly Phone_Value?: Maybe<Scalars['String']>;
  readonly Phone_Extension?: Maybe<Scalars['String']>;
  readonly Phone_Label?: Maybe<Scalars['String']>;
  readonly Phone_Notes?: Maybe<Scalars['String']>;
  readonly Phone_Is_Personal?: Maybe<Scalars['Boolean']>;
  readonly Email_Value?: Maybe<Scalars['String']>;
  readonly Email_Label?: Maybe<Scalars['String']>;
  readonly Email_Notes?: Maybe<Scalars['String']>;
  readonly Email_Is_Personal?: Maybe<Scalars['Boolean']>;
  readonly Entity_Name?: Maybe<Scalars['String']>;
  readonly Entity_Type?: Maybe<Scalars['String']>;
  readonly Entity_Status?: Maybe<Scalars['Boolean']>;
  readonly Price?: Maybe<Scalars['Float']>;
  readonly Service?: Maybe<Scalars['Float']>;
  readonly Rookie?: Maybe<Scalars['Float']>;
  readonly Expert?: Maybe<Scalars['Float']>;
  readonly Human?: Maybe<Scalars['Float']>;
  readonly Technology?: Maybe<Scalars['Float']>;
  readonly Handson?: Maybe<Scalars['Float']>;
  readonly Handsoff?: Maybe<Scalars['Float']>;
  readonly Supporter?: Maybe<Scalars['Float']>;
  readonly Detractor?: Maybe<Scalars['Float']>;
  readonly Contact_Entity_Lookup_Id?: Maybe<Scalars['String']>;
  readonly crm_Contact_Information?: Maybe<ReadonlyArray<CrmContactInformationOutput>>;
  readonly crm_Contact_Associated_Entity?: Maybe<ReadonlyArray<CrmContactAssociatedEntityOutput>>;
  readonly crm_Contact_Associated_Rep?: Maybe<ReadonlyArray<CrmContactAssociatedRepOutput>>;
  readonly crm_Contact_Responsibility?: Maybe<ReadonlyArray<CrmContactResponsibilityOutput>>;
  readonly crm_Contact_Slider?: Maybe<ReadonlyArray<CrmContactSliderOutput>>;
  readonly crm_Contact_Pricing_Behaviour?: Maybe<ReadonlyArray<CrmContactPricingBehaviourOutput>>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
  readonly InterestsAndHobbies?: Maybe<Scalars['String']>;
};

export type CrmContactOutputV2 = {
  readonly __typename?: 'CrmContactOutputV2';
  readonly Contact_Id: Scalars['ID'];
  readonly Name?: Maybe<Scalars['String']>;
  readonly SuffixTerm?: Maybe<Scalars['String']>;
  readonly PronounsTerm?: Maybe<Scalars['String']>;
  readonly Preferred_Name?: Maybe<Scalars['String']>;
  readonly Pronunciation?: Maybe<Scalars['String']>;
  readonly Zoom_Info_URL?: Maybe<Scalars['String']>;
  readonly Zoom_Info_Number?: Maybe<Scalars['String']>;
  readonly LinkedIn_URL?: Maybe<Scalars['String']>;
  readonly Twitter_URL?: Maybe<Scalars['String']>;
  readonly DepartmentTerm?: Maybe<Scalars['String']>;
  readonly Title?: Maybe<Scalars['String']>;
  readonly LevelTerm?: Maybe<Scalars['String']>;
  readonly Primary_Work_Location?: Maybe<Scalars['String']>;
  readonly Work_EnvironmentTerm?: Maybe<Scalars['String']>;
  readonly Communication_PreferenceTerm?: Maybe<Scalars['String']>;
  readonly Pricing_BehaviorTerm?: Maybe<Scalars['String']>;
  readonly Manager?: Maybe<Scalars['String']>;
  readonly ManagerEntity?: Maybe<CrmContactOutputV2>;
  readonly Hometown?: Maybe<Scalars['String']>;
  readonly Current_Location?: Maybe<Scalars['String']>;
  readonly Primary_LanguageTerm?: Maybe<Scalars['String']>;
  readonly Secondary_LanguageTerm?: Maybe<Scalars['String']>;
  readonly Birthday?: Maybe<Scalars['DateTime']>;
  readonly Personal_Notes?: Maybe<Scalars['String']>;
  readonly External_User?: Maybe<Scalars['String']>;
  readonly Entity_Id?: Maybe<Scalars['String']>;
  readonly Phone_Value?: Maybe<Scalars['String']>;
  readonly Phone_Extension?: Maybe<Scalars['String']>;
  readonly Phone_Label?: Maybe<Scalars['String']>;
  readonly Phone_Notes?: Maybe<Scalars['String']>;
  readonly Phone_Is_Personal?: Maybe<Scalars['Boolean']>;
  readonly Email_Value?: Maybe<Scalars['String']>;
  readonly Email_Label?: Maybe<Scalars['String']>;
  readonly Email_Notes?: Maybe<Scalars['String']>;
  readonly Email_Is_Personal?: Maybe<Scalars['Boolean']>;
  readonly Entity_Name?: Maybe<Scalars['String']>;
  readonly Entity_Type?: Maybe<Scalars['String']>;
  readonly Entity_Status?: Maybe<Scalars['Boolean']>;
  readonly Price?: Maybe<Scalars['Float']>;
  readonly Service?: Maybe<Scalars['Float']>;
  readonly Rookie?: Maybe<Scalars['Float']>;
  readonly Expert?: Maybe<Scalars['Float']>;
  readonly Human?: Maybe<Scalars['Float']>;
  readonly Technology?: Maybe<Scalars['Float']>;
  readonly Handson?: Maybe<Scalars['Float']>;
  readonly Handsoff?: Maybe<Scalars['Float']>;
  readonly Supporter?: Maybe<Scalars['Float']>;
  readonly Detractor?: Maybe<Scalars['Float']>;
  readonly Contact_Entity_Lookup_Id?: Maybe<Scalars['String']>;
  readonly crm_Contact_Information?: Maybe<ReadonlyArray<CrmContactInformationOutputV2>>;
  readonly crm_Contact_Associated_Entity?: Maybe<ReadonlyArray<CrmContactAssociatedEntityOutputV2>>;
  readonly crm_Contact_Associated_Rep?: Maybe<ReadonlyArray<CrmContactAssociatedRepOutputV2>>;
  readonly crm_Contact_Responsibility?: Maybe<ReadonlyArray<CrmContactResponsibilityOutputV2>>;
  readonly crm_Contact_Slider?: Maybe<ReadonlyArray<CrmContactSliderOutputV2>>;
  readonly crm_Contact_Pricing_Behaviour?: Maybe<ReadonlyArray<CrmContactPricingBehaviourOutputv2>>;
  readonly crm_Contact_Interest_Hobby?: Maybe<ReadonlyArray<CrmContactInterestHobbyOutputV2>>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
  readonly InterestsAndHobbies?: Maybe<Scalars['String']>;
};

export type CrmContactPricingBehaviourInput = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Pricing_Behaviour_Key?: InputMaybe<Scalars['String']>;
  readonly Pricing_Behaviour_ValueTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactPricingBehaviourOutput = {
  readonly __typename?: 'CrmContactPricingBehaviourOutput';
  readonly Contact_Pricing_Behaviour_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Pricing_Behaviour_Key?: Maybe<Scalars['String']>;
  readonly Pricing_Behaviour_ValueTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactPricingBehaviourOutputv2 = {
  readonly __typename?: 'CrmContactPricingBehaviourOutputv2';
  readonly Contact_Pricing_Behaviour_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Pricing_Behaviour_Key?: Maybe<Scalars['String']>;
  readonly Pricing_Behaviour_ValueTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityAssociatedEntityInput = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Contact_Associated_Entity_Id?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityAssociatedEntityInputV2 = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Contact_Associated_Entity_Id?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityAssociatedEntityOutput = {
  readonly __typename?: 'crmContactResponsibilityAssociatedEntityOutput';
  readonly Contact_Responsibility_Associated_Entity_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  readonly Contact_Associated_Entity_Id?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityAssociatedEntityOutputV2 = {
  readonly __typename?: 'CrmContactResponsibilityAssociatedEntityOutputV2';
  readonly Contact_Responsibility_Associated_Entity_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  readonly Contact_Associated_Entity_Id?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityBusinessUnitInput = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Responsibility_Business_Unit_Value?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityBusinessUnitInputV2 = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Responsibility_Business_Unit_Value?: InputMaybe<Scalars['String']>;
  readonly Responsibility_Business_Unit_ValueTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityBusinessUnitOutput = {
  readonly __typename?: 'crmContactResponsibilityBusinessUnitOutput';
  readonly Contact_Responsibility_Business_Unit_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  readonly Responsibility_Business_Unit_Value?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityBusinessUnitOutputV2 = {
  readonly __typename?: 'CrmContactResponsibilityBusinessUnitOutputV2';
  readonly Contact_Responsibility_Business_Unit_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  /** @deprecated Responsibility_Business_Unit_Value is deprecated, Use Responsibility_Business_Unit_ValueTerm instead */
  readonly Responsibility_Business_Unit_Value?: Maybe<Scalars['String']>;
  readonly Responsibility_Business_Unit_ValueTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityEquipmentInput = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Responsibility_Equipment_Value?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityEquipmentInputV2 = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Responsibility_Equipment_Value?: InputMaybe<Scalars['String']>;
  readonly Responsibility_Equipment_ValueTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityEquipmentOutput = {
  readonly __typename?: 'crmContactResponsibilityEquipmentOutput';
  readonly Contact_Responsibility_Equipment_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  readonly Responsibility_Equipment_Value?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityEquipmentOutputV2 = {
  readonly __typename?: 'CrmContactResponsibilityEquipmentOutputV2';
  readonly Contact_Responsibility_Equipment_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  /** @deprecated Responsibility_Equipment_Value is deprecated, Use Responsibility_Equipment_ValueTerm instead */
  readonly Responsibility_Equipment_Value?: Maybe<Scalars['String']>;
  readonly Responsibility_Equipment_ValueTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityIbRegionsInputV2 = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Ib_Regions_Data?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityIbRegionsOutputV2 = {
  readonly __typename?: 'CrmContactResponsibilityIBRegionsOutputV2';
  readonly Contact_Responsibility_Ib_Regions_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  readonly Ib_Regions_Data?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityInput = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Division?: InputMaybe<Scalars['String']>;
  readonly Details?: InputMaybe<Scalars['String']>;
  readonly crm_Contact_Responsibility_Business_Unit?: InputMaybe<ReadonlyArray<CrmContactResponsibilityBusinessUnitInput>>;
  readonly crm_Contact_Responsibility_Mode?: InputMaybe<ReadonlyArray<CrmContactResponsibilityModeInput>>;
  readonly crm_Contact_Responsibility_Associated_Entity?: InputMaybe<ReadonlyArray<CrmContactResponsibilityAssociatedEntityInput>>;
  readonly crm_Contact_Responsibility_Equipment?: InputMaybe<ReadonlyArray<CrmContactResponsibilityEquipmentInput>>;
  readonly crm_Contact_Responsibility_Region?: InputMaybe<ReadonlyArray<CrmContactResponsibilityRegionInput>>;
  readonly crm_Contact_Associated_Rep_Responsibility?: InputMaybe<ReadonlyArray<CrmContactAssociatedRepResponsibilityInput>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityInputV2 = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Division?: InputMaybe<Scalars['String']>;
  readonly DivisionTerm?: InputMaybe<Scalars['String']>;
  readonly Details?: InputMaybe<Scalars['String']>;
  readonly crm_Contact_Responsibility_Business_Unit?: InputMaybe<ReadonlyArray<CrmContactResponsibilityBusinessUnitInputV2>>;
  readonly crm_Contact_Responsibility_Mode?: InputMaybe<ReadonlyArray<CrmContactResponsibilityModeInputV2>>;
  readonly crm_Contact_Responsibility_Associated_Entity?: InputMaybe<ReadonlyArray<CrmContactResponsibilityAssociatedEntityInputV2>>;
  readonly crm_Contact_Responsibility_Equipment?: InputMaybe<ReadonlyArray<CrmContactResponsibilityEquipmentInputV2>>;
  readonly crm_Contact_Responsibility_Region?: InputMaybe<ReadonlyArray<CrmContactResponsibilityRegionInputV2>>;
  readonly crm_Contact_Associated_Rep_Responsibility?: InputMaybe<ReadonlyArray<CrmContactAssociatedRepResponsibilityInputV2>>;
  readonly crm_Contact_Responsibility_Size?: InputMaybe<ReadonlyArray<CrmContactResponsibilitySizeInputV2>>;
  readonly crm_Contact_Responsibility_Ib_Regions?: InputMaybe<ReadonlyArray<CrmContactResponsibilityIbRegionsInputV2>>;
  readonly crm_Contact_Responsibility_Ob_Regions?: InputMaybe<ReadonlyArray<CrmContactResponsibilityObRegionsInputV2>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly Is_Active?: InputMaybe<Scalars['Boolean']>;
};

export type CrmContactResponsibilityModeInput = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Responsibility_Mode_Value?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityModeInputV2 = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Responsibility_Mode_Value?: InputMaybe<Scalars['String']>;
  readonly Responsibility_Mode_ValueTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityModeOutput = {
  readonly __typename?: 'crmContactResponsibilityModeOutput';
  readonly Contact_Responsibility_Mode_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  readonly Responsibility_Mode_Value?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityModeOutputV2 = {
  readonly __typename?: 'CrmContactResponsibilityModeOutputV2';
  readonly Contact_Responsibility_Mode_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  /** @deprecated Responsibility_Mode_Value is deprecated, Use Responsibility_Mode_ValueTerm instead */
  readonly Responsibility_Mode_Value?: Maybe<Scalars['String']>;
  readonly Responsibility_Mode_ValueTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityObRegionsInputV2 = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Ob_Regions_Data?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityObRegionsOutputV2 = {
  readonly __typename?: 'CrmContactResponsibilityOBRegionsOutputV2';
  readonly Contact_Responsibility_Ob_Regions_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  readonly Ob_Regions_Data?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityOutput = {
  readonly __typename?: 'crmContactResponsibilityOutput';
  readonly Contact_Responsibility_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Division?: Maybe<Scalars['String']>;
  readonly Details?: Maybe<Scalars['String']>;
  readonly crm_Contact_Responsibility_Business_Unit?: Maybe<ReadonlyArray<CrmContactResponsibilityBusinessUnitOutput>>;
  readonly crm_Contact_Responsibility_Mode?: Maybe<ReadonlyArray<CrmContactResponsibilityModeOutput>>;
  readonly crm_Contact_Responsibility_Associated_Entity?: Maybe<ReadonlyArray<CrmContactResponsibilityAssociatedEntityOutput>>;
  readonly crm_Contact_Responsibility_Equipment?: Maybe<ReadonlyArray<CrmContactResponsibilityEquipmentOutput>>;
  readonly crm_Contact_Responsibility_Region?: Maybe<ReadonlyArray<CrmContactResponsibilityRegionOutput>>;
  readonly crm_Contact_Associated_Rep_Responsibility?: Maybe<ReadonlyArray<CrmContactAssociatedRepResponsibilityOutput>>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityOutputV2 = {
  readonly __typename?: 'CrmContactResponsibilityOutputV2';
  readonly Contact_Responsibility_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  /** @deprecated Division is deprecated, Use DivisionTerm instead */
  readonly Division?: Maybe<Scalars['String']>;
  readonly DivisionTerm?: Maybe<Scalars['String']>;
  readonly Details?: Maybe<Scalars['String']>;
  readonly crm_Contact_Responsibility_Business_Unit?: Maybe<ReadonlyArray<CrmContactResponsibilityBusinessUnitOutputV2>>;
  readonly crm_Contact_Responsibility_Mode?: Maybe<ReadonlyArray<CrmContactResponsibilityModeOutputV2>>;
  readonly crm_Contact_Responsibility_Associated_Entity?: Maybe<ReadonlyArray<CrmContactResponsibilityAssociatedEntityOutputV2>>;
  readonly crm_Contact_Responsibility_Equipment?: Maybe<ReadonlyArray<CrmContactResponsibilityEquipmentOutputV2>>;
  readonly crm_Contact_Responsibility_Region?: Maybe<ReadonlyArray<CrmContactResponsibilityRegionOutputV2>>;
  readonly crm_Contact_Associated_Rep_Responsibility?: Maybe<ReadonlyArray<CrmContactAssociatedRepResponsibilityOutputV2>>;
  readonly crm_Contact_Responsibility_Size?: Maybe<ReadonlyArray<CrmContactResponsibilitySizeOutputV2>>;
  readonly crm_Contact_Responsibility_Ib_Regions?: Maybe<ReadonlyArray<CrmContactResponsibilityIbRegionsOutputV2>>;
  readonly crm_Contact_Responsibility_Ob_Regions?: Maybe<ReadonlyArray<CrmContactResponsibilityObRegionsOutputV2>>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
  readonly Is_Active?: Maybe<Scalars['Boolean']>;
};

export type CrmContactResponsibilityRegionInput = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Region_Type?: InputMaybe<Scalars['String']>;
  readonly Region_Value?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityRegionInputV2 = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Region_Type?: InputMaybe<Scalars['String']>;
  readonly Region_Value?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityRegionOutput = {
  readonly __typename?: 'crmContactResponsibilityRegionOutput';
  readonly Contact_Responsibility_Region_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  readonly Region_Type?: Maybe<Scalars['String']>;
  readonly Region_Value?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilityRegionOutputV2 = {
  readonly __typename?: 'CrmContactResponsibilityRegionOutputV2';
  readonly Contact_Responsibility_Region_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  readonly Region_Type?: Maybe<Scalars['String']>;
  readonly Region_Value?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilitySizeInputV2 = {
  readonly Contact_Responsibility_Id?: InputMaybe<Scalars['String']>;
  readonly Responsibility_Size_ValueTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactResponsibilitySizeOutputV2 = {
  readonly __typename?: 'CrmContactResponsibilitySizeOutputV2';
  readonly Contact_Responsibility_Size_Id: Scalars['ID'];
  readonly Contact_Responsibility_Id?: Maybe<Scalars['String']>;
  readonly Responsibility_Size_ValueTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactSliderInput = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Price?: InputMaybe<Scalars['String']>;
  readonly Service?: InputMaybe<Scalars['String']>;
  readonly Rookie?: InputMaybe<Scalars['String']>;
  readonly Expert?: InputMaybe<Scalars['String']>;
  readonly Human?: InputMaybe<Scalars['String']>;
  readonly Technology?: InputMaybe<Scalars['String']>;
  readonly Handson?: InputMaybe<Scalars['String']>;
  readonly Handsoff?: InputMaybe<Scalars['String']>;
  readonly Supporter?: InputMaybe<Scalars['String']>;
  readonly Detractor?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactSliderInputV2 = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Price?: InputMaybe<Scalars['String']>;
  readonly Service?: InputMaybe<Scalars['String']>;
  readonly Rookie?: InputMaybe<Scalars['String']>;
  readonly Expert?: InputMaybe<Scalars['String']>;
  readonly Human?: InputMaybe<Scalars['String']>;
  readonly Technology?: InputMaybe<Scalars['String']>;
  readonly Handson?: InputMaybe<Scalars['String']>;
  readonly Handsoff?: InputMaybe<Scalars['String']>;
  readonly Supporter?: InputMaybe<Scalars['String']>;
  readonly Detractor?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactSliderOutput = {
  readonly __typename?: 'crmContactSliderOutput';
  readonly Contact_Slider_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Price?: Maybe<Scalars['String']>;
  readonly Service?: Maybe<Scalars['String']>;
  readonly Rookie?: Maybe<Scalars['String']>;
  readonly Expert?: Maybe<Scalars['String']>;
  readonly Human?: Maybe<Scalars['String']>;
  readonly Technology?: Maybe<Scalars['String']>;
  readonly Handson?: Maybe<Scalars['String']>;
  readonly Handsoff?: Maybe<Scalars['String']>;
  readonly Supporter?: Maybe<Scalars['String']>;
  readonly Detractor?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactSliderOutputV2 = {
  readonly __typename?: 'CrmContactSliderOutputV2';
  readonly Contact_Slider_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Price?: Maybe<Scalars['String']>;
  readonly Service?: Maybe<Scalars['String']>;
  readonly Rookie?: Maybe<Scalars['String']>;
  readonly Expert?: Maybe<Scalars['String']>;
  readonly Human?: Maybe<Scalars['String']>;
  readonly Technology?: Maybe<Scalars['String']>;
  readonly Handson?: Maybe<Scalars['String']>;
  readonly Handsoff?: Maybe<Scalars['String']>;
  readonly Supporter?: Maybe<Scalars['String']>;
  readonly Detractor?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactWorkHourAssociatedEntityInput = {
  readonly Contact_Work_Hour_Id?: InputMaybe<Scalars['String']>;
  readonly Contact_Associated_Entity_Id?: InputMaybe<Scalars['String']>;
  readonly Associated_Entity_Name?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactWorkHourAssociatedEntityOutput = {
  readonly __typename?: 'CrmContactWorkHourAssociatedEntityOutput';
  readonly Contact_Work_Hour_Associated_Entity_Id: Scalars['ID'];
  readonly Contact_Work_Hour_Id?: Maybe<Scalars['String']>;
  readonly Contact_Associated_Entity_Id?: Maybe<Scalars['String']>;
  readonly Associated_Entity_Name?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactWorkHourAssociatedEntityOutputv2 = {
  readonly __typename?: 'CrmContactWorkHourAssociatedEntityOutputv2';
  readonly Contact_Work_Hour_Associated_Entity_Id: Scalars['ID'];
  readonly Contact_Work_Hour_Id?: Maybe<Scalars['String']>;
  readonly Contact_Associated_Entity_Id?: Maybe<Scalars['String']>;
  readonly Associated_Entity_Name?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactWorkHoursInput = {
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Day?: InputMaybe<Scalars['String']>;
  readonly Hours_Start?: InputMaybe<Scalars['String']>;
  readonly Hours_End?: InputMaybe<Scalars['String']>;
  readonly Notes?: InputMaybe<Scalars['String']>;
  readonly Is_Day_Off?: InputMaybe<Scalars['Boolean']>;
  readonly crm_Contact_Work_Hour_Associated_Entity?: InputMaybe<ReadonlyArray<CrmContactWorkHourAssociatedEntityInput>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmContactWorkHoursOutput = {
  readonly __typename?: 'CrmContactWorkHoursOutput';
  readonly Contact_Work_Hour_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Day?: Maybe<Scalars['String']>;
  readonly Hours_Start?: Maybe<Scalars['String']>;
  readonly Hours_End?: Maybe<Scalars['String']>;
  readonly Notes?: Maybe<Scalars['String']>;
  readonly Is_Day_Off?: Maybe<Scalars['Boolean']>;
  readonly crm_Contact_Work_Hour_Associated_Entity?: Maybe<ReadonlyArray<CrmContactWorkHourAssociatedEntityOutput>>;
  readonly Created_By?: Maybe<Scalars['String']>;
  readonly Updated_By?: Maybe<Scalars['String']>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmContactWorkHoursOutputv2 = {
  readonly __typename?: 'CrmContactWorkHoursOutputv2';
  readonly Contact_Work_Hour_Id: Scalars['ID'];
  readonly Contact_Id?: Maybe<Scalars['String']>;
  readonly Day?: Maybe<Scalars['String']>;
  readonly Hours_Start?: Maybe<Scalars['String']>;
  readonly Hours_End?: Maybe<Scalars['String']>;
  readonly Notes?: Maybe<Scalars['String']>;
  readonly Is_Day_Off?: Maybe<Scalars['Boolean']>;
  readonly crm_Contact_Work_Hour_Associated_Entity?: Maybe<ReadonlyArray<CrmContactWorkHourAssociatedEntityOutputv2>>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmCustomerDetail = {
  readonly __typename?: 'CRMCustomerDetail';
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly id?: Maybe<Scalars['ID']>;
  /** @deprecated This property will be removed. Use InteractionFrequencyTypeId instead  */
  readonly interactionFrequency?: Maybe<CustomerInteractionFrequencyEnum>;
  readonly interactionFrequencyTypeId?: Maybe<Scalars['ID']>;
  readonly leadNotes?: Maybe<Scalars['String']>;
  /** @deprecated This property will be removed. Use LeadSourceTypeId instead  */
  readonly leadSource?: Maybe<LeadSourceEnum>;
  readonly leadSourceTypeId?: Maybe<Scalars['ID']>;
};

export type CrmDetailedDescriptionInput = {
  readonly detailedDescriptionText?: InputMaybe<Scalars['String']>;
  readonly crmDetailsEntityLookupId?: InputMaybe<Scalars['String']>;
  readonly entityId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmDetailedDescriptionOutput = {
  readonly __typename?: 'CrmDetailedDescriptionOutput';
  readonly detailedDescriptionId: Scalars['ID'];
  readonly detailedDescriptionText?: Maybe<Scalars['String']>;
  readonly crmDetailsEntityLookupId?: Maybe<Scalars['String']>;
  readonly entityId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmDetailsEntityLookupOutput = {
  readonly __typename?: 'CrmDetailsEntityLookupOutput';
  readonly crmDetailsEntityLookupId: Scalars['ID'];
  readonly entityType?: Maybe<Scalars['Float']>;
  readonly entityName?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmDocument = {
  readonly __typename?: 'CRMDocument';
  readonly id: Scalars['ID'];
  /** The name of the file to be displayed to the user, not necessarily the filename */
  readonly name: Scalars['String'];
  /** The name of the file provided during upload */
  readonly filename: Scalars['String'];
  /**
   * DEPRECATED: Use uploadedByUserV2 instead
   * @deprecated Use uploadedByUserV2 instead
   */
  readonly uploadedByUser?: Maybe<User>;
  readonly uploadDate: Scalars['DateTime'];
  /** entityType KVT ID */
  readonly entityType: Scalars['String'];
  /** entityId KVT ID */
  readonly entityId: Scalars['ID'];
  /** uri to access the document */
  readonly uri: Scalars['String'];
  readonly uploadedByUserV2?: Maybe<UserV2>;
};

export type CrmDocumentConnection = {
  readonly __typename?: 'CrmDocumentConnection';
  readonly edges: ReadonlyArray<CrmDocumentGqlTypeEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Float'];
};

export type CrmDocumentFilter = {
  readonly entityId?: InputMaybe<Scalars['ID']>;
  readonly entityType?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
};

export type CrmDocumentGqlTypeEdge = {
  readonly __typename?: 'CrmDocumentGQLTypeEdge';
  readonly cursor: Scalars['String'];
  readonly node: CrmDocument;
};

export type CrmDocumentSort = {
  readonly key: CrmDocumentSortEnum;
  readonly direction: CrmDocumentSortDirectionsEnum;
};

export enum CrmDocumentSortDirectionsEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum CrmDocumentSortEnum {
  Filename = 'filename'
}

export type CrmFirmographicsInput = {
  readonly annualRevenue?: InputMaybe<Scalars['Float']>;
  readonly netIncome?: InputMaybe<Scalars['Float']>;
  readonly grossProfit?: InputMaybe<Scalars['Float']>;
  readonly employeesAllSites?: InputMaybe<Scalars['Float']>;
  readonly employeesThisSite?: InputMaybe<Scalars['Float']>;
  readonly assets?: InputMaybe<Scalars['Float']>;
  readonly threeYearSalesGrowth?: InputMaybe<Scalars['Float']>;
  readonly threeYearNetIncomeGrowth?: InputMaybe<Scalars['Float']>;
  readonly yearFounded?: InputMaybe<Scalars['DateTime']>;
  readonly incorporated?: InputMaybe<Scalars['DateTime']>;
  readonly fiscalYearEnd?: InputMaybe<Scalars['DateTime']>;
  readonly keyPrincipal?: InputMaybe<Scalars['String']>;
  readonly fortune1000Rank?: InputMaybe<Scalars['Float']>;
  readonly femaleOwned?: InputMaybe<Scalars['String']>;
  readonly minorityOwned?: InputMaybe<Scalars['String']>;
  readonly veteranOwned?: InputMaybe<Scalars['String']>;
  readonly crmDetailsEntityLookupId?: InputMaybe<Scalars['String']>;
  readonly entityId?: InputMaybe<Scalars['String']>;
  readonly stockSymbolId?: InputMaybe<Scalars['String']>;
  readonly keyPrincipalIsContact?: InputMaybe<Scalars['Boolean']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmFirmographicsOutput = {
  readonly __typename?: 'CrmFirmographicsOutput';
  readonly firmographicsId: Scalars['ID'];
  readonly annualRevenue?: Maybe<Scalars['Float']>;
  readonly netIncome?: Maybe<Scalars['Float']>;
  readonly grossProfit?: Maybe<Scalars['Float']>;
  readonly employeesAllSites?: Maybe<Scalars['Float']>;
  readonly employeesThisSite?: Maybe<Scalars['Float']>;
  readonly assets?: Maybe<Scalars['Float']>;
  readonly threeYearSalesGrowth?: Maybe<Scalars['Float']>;
  readonly threeYearNetIncomeGrowth?: Maybe<Scalars['Float']>;
  readonly yearFounded?: Maybe<Scalars['DateTime']>;
  readonly incorporated?: Maybe<Scalars['DateTime']>;
  readonly fiscalYearEnd?: Maybe<Scalars['DateTime']>;
  readonly keyPrincipal?: Maybe<Scalars['String']>;
  readonly fortune1000Rank?: Maybe<Scalars['Float']>;
  readonly femaleOwned?: Maybe<Scalars['String']>;
  readonly minorityOwned?: Maybe<Scalars['String']>;
  readonly veteranOwned?: Maybe<Scalars['String']>;
  readonly crmDetailsEntityLookupId?: Maybe<Scalars['String']>;
  readonly entityId?: Maybe<Scalars['String']>;
  readonly stockSymbolId?: Maybe<Scalars['String']>;
  readonly keyPrincipalIsContact?: Maybe<Scalars['Boolean']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmInteractionContactInput = {
  readonly Contact_Id: Scalars['String'];
  readonly Interaction_Id?: InputMaybe<Scalars['String']>;
  readonly Contact_Name?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmInteractionContactOutput = {
  readonly __typename?: 'CrmInteractionContactOutput';
  readonly Interaction_Contact_Id: Scalars['ID'];
  readonly Contact_Id: Scalars['String'];
  readonly Interaction_Id?: Maybe<Scalars['String']>;
  readonly Contact_Name?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmInteractionDocumentInput = {
  readonly Document_Name?: InputMaybe<Scalars['String']>;
  readonly DocumentId?: InputMaybe<Scalars['String']>;
  readonly TypeTerm?: InputMaybe<Scalars['String']>;
  readonly SourceTerm?: InputMaybe<Scalars['String']>;
  readonly DirectionTerm?: InputMaybe<Scalars['String']>;
  readonly Details?: InputMaybe<Scalars['String']>;
  readonly Document_Path?: InputMaybe<Scalars['String']>;
  readonly Interaction_Id?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmInteractionDocumentOutput = {
  readonly __typename?: 'CrmInteractionDocumentOutput';
  readonly Interaction_Document_Id: Scalars['ID'];
  readonly Document_Name?: Maybe<Scalars['String']>;
  readonly DocumentId?: Maybe<Scalars['String']>;
  readonly TypeTerm?: Maybe<Scalars['String']>;
  readonly SourceTerm?: Maybe<Scalars['String']>;
  readonly DirectionTerm?: Maybe<Scalars['String']>;
  readonly Details?: Maybe<Scalars['String']>;
  readonly Document_Path?: Maybe<Scalars['String']>;
  readonly Interaction_Id?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmInteractionInput = {
  readonly Rescheduled?: InputMaybe<Scalars['Float']>;
  readonly Strategy?: InputMaybe<Scalars['String']>;
  readonly Status?: InputMaybe<Scalars['String']>;
  readonly ObjectiveTerm?: InputMaybe<Scalars['String']>;
  readonly DirectionTerm?: InputMaybe<Scalars['String']>;
  readonly ViaTerm?: InputMaybe<Scalars['String']>;
  readonly Details?: InputMaybe<Scalars['String']>;
  readonly OutcomeTerm?: InputMaybe<Scalars['String']>;
  readonly Interaction_Type?: InputMaybe<Scalars['String']>;
  readonly Original_Date_Time?: InputMaybe<Scalars['DateTime']>;
  readonly Scheduled_Date_Time?: InputMaybe<Scalars['DateTime']>;
  readonly Completed_Date_Time?: InputMaybe<Scalars['DateTime']>;
  readonly Completed_By?: InputMaybe<Scalars['String']>;
  readonly CompletedBy?: InputMaybe<Scalars['String']>;
  readonly Interaction_Schedule_Time?: InputMaybe<Scalars['String']>;
  readonly Interaction_Entity_Lookup_Id?: InputMaybe<Scalars['String']>;
  readonly Entity_Id?: InputMaybe<Scalars['String']>;
  readonly Interaction_Rep_Group_KeyTerm?: InputMaybe<Scalars['Float']>;
  readonly RepGroupField1?: InputMaybe<Scalars['String']>;
  readonly crm_Interaction_Opportunity?: InputMaybe<ReadonlyArray<CrmInteractionOpportunityInput>>;
  readonly crm_Interaction_Contact?: InputMaybe<ReadonlyArray<CrmInteractionContactInput>>;
  readonly crm_Interaction_Reschedule_Audit?: InputMaybe<ReadonlyArray<CrmInteractionRescheduleInput>>;
  readonly crm_Interaction_Document?: InputMaybe<ReadonlyArray<CrmInteractionDocumentInput>>;
  readonly crm_Interaction_Rep_Group?: InputMaybe<ReadonlyArray<CrmInteractionRepGroupInput>>;
  readonly crm_Interaction_Objective?: InputMaybe<ReadonlyArray<CrmInteractionObjectiveInput>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmInteractionObjectiveInput = {
  readonly Interaction_Objective_KeyTerm?: InputMaybe<Scalars['Float']>;
  readonly Interaction_Objective_KeyTermV2?: InputMaybe<Scalars['String']>;
  readonly Interaction_Objective_Value?: InputMaybe<Scalars['String']>;
  readonly Interaction_DDT_Lookup_Id?: InputMaybe<Scalars['String']>;
  readonly Interaction_Id?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmInteractionObjectiveOutput = {
  readonly __typename?: 'CrmInteractionObjectiveOutput';
  readonly Interaction_Objective_Id: Scalars['String'];
  /** @deprecated Interaction_Objective_KeyTerm is deprecated. Use Interaction_Objective_KeyTermV2 instead. */
  readonly Interaction_Objective_KeyTerm: Scalars['Float'];
  readonly Interaction_Objective_KeyTermV2?: Maybe<Scalars['String']>;
  readonly Interaction_Objective_Value?: Maybe<Scalars['String']>;
  readonly Interaction_DDT_Lookup_Id?: Maybe<Scalars['String']>;
  readonly Interaction_Id?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmInteractionOpportunityInput = {
  readonly Opportunity_Id: Scalars['String'];
  readonly Interaction_Id?: InputMaybe<Scalars['String']>;
  readonly Opportunity_Name?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmInteractionOpportunityOutput = {
  readonly __typename?: 'CrmInteractionOpportunityOutput';
  readonly Interaction_Opportunity_Id: Scalars['ID'];
  readonly Opportunity_Id: Scalars['String'];
  readonly Interaction_Id?: Maybe<Scalars['String']>;
  readonly Opportunity_Name?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmInteractionOutput = {
  readonly __typename?: 'CrmInteractionOutput';
  readonly Interaction_Id: Scalars['ID'];
  readonly Rescheduled?: Maybe<Scalars['Float']>;
  readonly Strategy?: Maybe<Scalars['String']>;
  readonly Status?: Maybe<Scalars['String']>;
  readonly ObjectiveTerm?: Maybe<Scalars['String']>;
  readonly DirectionTerm?: Maybe<Scalars['String']>;
  readonly ViaTerm?: Maybe<Scalars['String']>;
  readonly Details?: Maybe<Scalars['String']>;
  readonly OutcomeTerm?: Maybe<Scalars['String']>;
  readonly Interaction_Type?: Maybe<Scalars['String']>;
  readonly Original_Date_Time?: Maybe<Scalars['DateTime']>;
  readonly Scheduled_Date_Time?: Maybe<Scalars['DateTime']>;
  readonly Completed_Date_Time?: Maybe<Scalars['DateTime']>;
  /** @deprecated Completed_By is deprecated. Use CompletedBy instead. */
  readonly Completed_By?: Maybe<Scalars['String']>;
  readonly Interaction_Schedule_Time?: Maybe<Scalars['String']>;
  readonly Interaction_Entity_Lookup_Id?: Maybe<Scalars['String']>;
  readonly Entity_Id?: Maybe<Scalars['String']>;
  readonly crm_Interaction_Opportunity?: Maybe<ReadonlyArray<CrmInteractionOpportunityOutput>>;
  readonly crm_Interaction_Contact?: Maybe<ReadonlyArray<CrmInteractionContactOutput>>;
  readonly crm_Interaction_Reschedule_Audit?: Maybe<ReadonlyArray<CrmInteractionRescheduleOutput>>;
  readonly crm_Interaction_Document?: Maybe<ReadonlyArray<CrmInteractionDocumentOutput>>;
  readonly crm_Interaction_Rep_Group?: Maybe<ReadonlyArray<CrmInteractionRepGroupOutput>>;
  readonly crm_Interaction_Objective?: Maybe<ReadonlyArray<CrmInteractionObjectiveOutput>>;
  readonly CompletedBy?: Maybe<User>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
  readonly Interaction_Rep_Group_KeyTerm?: Maybe<Scalars['Float']>;
  readonly RepGroupField1?: Maybe<Scalars['String']>;
};

export type CrmInteractionRepGroupInput = {
  readonly Assigned_Rep_Group_Lookup_Id?: InputMaybe<Scalars['String']>;
  readonly Assigned_Rep_Group_Id?: InputMaybe<Scalars['String']>;
  readonly Rep_Id?: InputMaybe<Scalars['String']>;
  readonly RepId?: InputMaybe<Scalars['String']>;
  readonly Interaction_Id?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
  readonly Active?: InputMaybe<Scalars['Float']>;
  readonly Rep_Group_Name?: InputMaybe<Scalars['String']>;
};

export type CrmInteractionRepGroupOutput = {
  readonly __typename?: 'CrmInteractionRepGroupOutput';
  readonly Interaction_Rep_Group_Id: Scalars['ID'];
  readonly Assigned_Rep_Group_Lookup_Id?: Maybe<Scalars['String']>;
  readonly Assigned_Rep_Group_Id?: Maybe<Scalars['String']>;
  /** @deprecated Rep_Id is deprecated. Use RepId instead. */
  readonly Rep_Id?: Maybe<User>;
  readonly RepId?: Maybe<Employee>;
  readonly Interaction_Id?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
  readonly Active?: Maybe<Scalars['Float']>;
  readonly Rep_Group_Name?: Maybe<Scalars['String']>;
};

export type CrmInteractionRescheduleInput = {
  readonly Scheduled_Date_Time: Scalars['DateTime'];
  readonly ReasonTerm?: InputMaybe<Scalars['String']>;
  readonly Notes?: InputMaybe<Scalars['String']>;
  readonly Interaction_Id?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmInteractionRescheduleOutput = {
  readonly __typename?: 'CrmInteractionRescheduleOutput';
  readonly Interaction_Reschedule_Audit_Id: Scalars['ID'];
  readonly Scheduled_Date_Time: Scalars['DateTime'];
  readonly ReasonTerm?: Maybe<Scalars['String']>;
  readonly Notes?: Maybe<Scalars['String']>;
  readonly Interaction_Id?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityBusinessUnitInput = {
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly Business_UnitTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityBusinessUnitInputV2 = {
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityBusinessUnitOutput = {
  readonly __typename?: 'crmOpportunityBusinessUnitOutput';
  readonly Opportunity_Business_Unit_Id: Scalars['ID'];
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly Business_UnitTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityBusinessUnitOutputV2 = {
  readonly __typename?: 'CrmOpportunityBusinessUnitOutputV2';
  readonly opportunityBusinessUnitId: Scalars['ID'];
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly businessUnitTerm?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityContactInput = {
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly Contact_Id?: InputMaybe<Scalars['String']>;
  readonly Contact_Name?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityContactInputV2 = {
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly contactId?: InputMaybe<Scalars['String']>;
  readonly contactName?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityContactOutput = {
  readonly __typename?: 'crmOpportunityContactOutput';
  readonly Opportunity_Contact_Id: Scalars['ID'];
  readonly Contact_Id: Scalars['String'];
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly Contact_Name?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityContactOutputV2 = {
  readonly __typename?: 'CrmOpportunityContactOutputV2';
  readonly opportunityContactId: Scalars['ID'];
  readonly contactId: Scalars['String'];
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly contactName?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityEquipmentInput = {
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly EquipmentTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityEquipmentInputV2 = {
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly equipmentTerm?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityEquipmentOutput = {
  readonly __typename?: 'crmOpportunityEquipmentOutput';
  readonly Opportunity_Equipment_Id: Scalars['ID'];
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly EquipmentTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityEquipmentOutputV2 = {
  readonly __typename?: 'CrmOpportunityEquipmentOutputV2';
  readonly opportunityEquipmentId: Scalars['ID'];
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly equipmentTerm?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityIbRegionInput = {
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly Region?: InputMaybe<Scalars['String']>;
  readonly IB_Regions_Data?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityIbRegionInputV2 = {
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly ibRegionsData?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityIbRegionOutput = {
  readonly __typename?: 'crmOpportunityIBRegionOutput';
  readonly Opportunity_IBRegion_Id: Scalars['ID'];
  /** @deprecated Region is deprecated, Use IB_Regions_Data instead */
  readonly Region?: Maybe<Scalars['String']>;
  readonly IB_Regions_Data?: Maybe<Scalars['String']>;
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityIbRegionOutputV2 = {
  readonly __typename?: 'CrmOpportunityIBRegionOutputV2';
  readonly opportunityIBRegionId: Scalars['ID'];
  readonly ibRegionsData?: Maybe<Scalars['String']>;
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityInput = {
  readonly Name?: InputMaybe<Scalars['String']>;
  readonly StageTerm?: InputMaybe<Scalars['String']>;
  readonly TypeTerm?: InputMaybe<Scalars['String']>;
  readonly DivisionTerm?: InputMaybe<Scalars['String']>;
  readonly Solution_TypeTerm?: InputMaybe<Scalars['String']>;
  readonly Total_Opportunity_Volume?: InputMaybe<Scalars['Float']>;
  readonly Total_Opportunity_Revenue?: InputMaybe<Scalars['Float']>;
  readonly Projected_Volume?: InputMaybe<Scalars['Float']>;
  readonly Projected_Revenue?: InputMaybe<Scalars['Float']>;
  readonly Projected_Margin?: InputMaybe<Scalars['Float']>;
  readonly Pricing_StrategyTerm?: InputMaybe<Scalars['String']>;
  readonly Details?: InputMaybe<Scalars['String']>;
  readonly Due_Date?: InputMaybe<Scalars['DateTime']>;
  readonly Award_Date?: InputMaybe<Scalars['DateTime']>;
  readonly Golive_Date?: InputMaybe<Scalars['DateTime']>;
  readonly StatusTerm?: InputMaybe<Scalars['String']>;
  readonly Revisit_Date?: InputMaybe<Scalars['DateTime']>;
  readonly Last_Closed_Date?: InputMaybe<Scalars['DateTime']>;
  readonly Entity_Id?: InputMaybe<Scalars['String']>;
  readonly crm_Opportunity_Business_Unit?: InputMaybe<ReadonlyArray<CrmOpportunityBusinessUnitInput>>;
  readonly crm_Opportunity_Contact?: InputMaybe<ReadonlyArray<CrmOpportunityContactInput>>;
  readonly crm_Opportunity_Equipment?: InputMaybe<ReadonlyArray<CrmOpportunityEquipmentInput>>;
  readonly crm_Opportunity_IBRegion?: InputMaybe<ReadonlyArray<CrmOpportunityIbRegionInput>>;
  readonly crm_Opportunity_Mode?: InputMaybe<ReadonlyArray<CrmOpportunityModeInput>>;
  readonly crm_Opportunity_OBRegion?: InputMaybe<ReadonlyArray<CrmOpportunityObRegionInput>>;
  readonly crm_Opportunity_Reps?: InputMaybe<ReadonlyArray<CrmOpportunityRepsInput>>;
  readonly crm_Opportunity_Size?: InputMaybe<ReadonlyArray<CrmOpportunitySizeInput>>;
  readonly crm_Opportunity_Solution?: InputMaybe<ReadonlyArray<CrmOpportunitySolutionInput>>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityInputV2 = {
  readonly name?: InputMaybe<Scalars['String']>;
  readonly stageTerm?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly solutionTypeTerm?: InputMaybe<Scalars['String']>;
  readonly totalOpportunityVolume?: InputMaybe<Scalars['Float']>;
  readonly totalOpportunityRevenue?: InputMaybe<Scalars['Float']>;
  readonly projectedVolume?: InputMaybe<Scalars['Float']>;
  readonly projectedRevenue?: InputMaybe<Scalars['Float']>;
  readonly projectedMargin?: InputMaybe<Scalars['Float']>;
  readonly pricingStrategyTerm?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly dueDate?: InputMaybe<Scalars['DateTime']>;
  readonly awardDate?: InputMaybe<Scalars['DateTime']>;
  readonly goLiveDate?: InputMaybe<Scalars['DateTime']>;
  readonly statusTerm?: InputMaybe<Scalars['String']>;
  readonly revisitDate?: InputMaybe<Scalars['DateTime']>;
  readonly lastClosedDate?: InputMaybe<Scalars['DateTime']>;
  readonly entityId?: InputMaybe<Scalars['String']>;
  readonly crmOpportunityBusinessUnit?: InputMaybe<ReadonlyArray<CrmOpportunityBusinessUnitInputV2>>;
  readonly crmOpportunityContact?: InputMaybe<ReadonlyArray<CrmOpportunityContactInputV2>>;
  readonly crmOpportunityEquipment?: InputMaybe<ReadonlyArray<CrmOpportunityEquipmentInputV2>>;
  readonly crmOpportunityIbRegion?: InputMaybe<ReadonlyArray<CrmOpportunityIbRegionInputV2>>;
  readonly crmOpportunityMode?: InputMaybe<ReadonlyArray<CrmOpportunityModeInputV2>>;
  readonly crmOpportunityObRegion?: InputMaybe<ReadonlyArray<CrmOpportunityObRegionInputV2>>;
  readonly crmOpportunityReps?: InputMaybe<ReadonlyArray<CrmOpportunityRepsInputV2>>;
  readonly crmOpportunitySize?: InputMaybe<ReadonlyArray<CrmOpportunitySizeInputV2>>;
  readonly crmOpportunitySolution?: InputMaybe<ReadonlyArray<CrmOpportunitySolutionInputV2>>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityModeInput = {
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly ModeTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityModeInputV2 = {
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly modeTerm?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityModeOutput = {
  readonly __typename?: 'crmOpportunityModeOutput';
  readonly Opportunity_Mode_Id: Scalars['ID'];
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly ModeTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityModeOutputV2 = {
  readonly __typename?: 'CrmOpportunityModeOutputV2';
  readonly opportunityModeId: Scalars['ID'];
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly modeTerm?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityObRegionInput = {
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly Region?: InputMaybe<Scalars['String']>;
  readonly OB_Regions_Data?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityObRegionInputV2 = {
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly obRegionsData?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityObRegionOutput = {
  readonly __typename?: 'crmOpportunityOBRegionOutput';
  readonly Opportunity_OBRegion_Id: Scalars['ID'];
  /** @deprecated Region is deprecated, Use OB_Regions_Data instead */
  readonly Region?: Maybe<Scalars['String']>;
  readonly OB_Regions_Data?: Maybe<Scalars['String']>;
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityObRegionOutputV2 = {
  readonly __typename?: 'CrmOpportunityOBRegionOutputV2';
  readonly opportunityOBRegionId: Scalars['ID'];
  readonly obRegionsData?: Maybe<Scalars['String']>;
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityOutput = {
  readonly __typename?: 'CrmOpportunityOutput';
  readonly Opportunity_Id: Scalars['ID'];
  readonly Name?: Maybe<Scalars['String']>;
  readonly StageTerm?: Maybe<Scalars['String']>;
  readonly TypeTerm?: Maybe<Scalars['String']>;
  readonly DivisionTerm?: Maybe<Scalars['String']>;
  readonly Solution_TypeTerm?: Maybe<Scalars['String']>;
  readonly Total_Opportunity_Volume?: Maybe<Scalars['Float']>;
  readonly Total_Opportunity_Revenue?: Maybe<Scalars['Float']>;
  readonly Projected_Volume?: Maybe<Scalars['Float']>;
  readonly Projected_Revenue?: Maybe<Scalars['Float']>;
  readonly Projected_Margin?: Maybe<Scalars['Float']>;
  readonly Pricing_StrategyTerm?: Maybe<Scalars['String']>;
  readonly Details?: Maybe<Scalars['String']>;
  readonly Due_Date?: Maybe<Scalars['DateTime']>;
  readonly Award_Date?: Maybe<Scalars['DateTime']>;
  readonly Golive_Date?: Maybe<Scalars['DateTime']>;
  readonly StatusTerm?: Maybe<Scalars['String']>;
  readonly Revisit_Date?: Maybe<Scalars['DateTime']>;
  readonly Last_Closed_Date?: Maybe<Scalars['DateTime']>;
  readonly Entity_Id: Scalars['String'];
  readonly crm_Opportunity_Business_Unit?: Maybe<ReadonlyArray<CrmOpportunityBusinessUnitOutput>>;
  readonly crm_Opportunity_Contact?: Maybe<ReadonlyArray<CrmOpportunityContactOutput>>;
  readonly crm_Opportunity_Equipment?: Maybe<ReadonlyArray<CrmOpportunityEquipmentOutput>>;
  readonly crm_Opportunity_IBRegion?: Maybe<ReadonlyArray<CrmOpportunityIbRegionOutput>>;
  readonly crm_Opportunity_Mode?: Maybe<ReadonlyArray<CrmOpportunityModeOutput>>;
  readonly crm_Opportunity_OBRegion?: Maybe<ReadonlyArray<CrmOpportunityObRegionOutput>>;
  readonly crm_Opportunity_Reps?: Maybe<ReadonlyArray<CrmOpportunityRepsOutput>>;
  readonly crm_Opportunity_Size?: Maybe<ReadonlyArray<CrmOpportunitySizeOutput>>;
  readonly crm_Opportunity_Solution?: Maybe<ReadonlyArray<CrmOpportunitySolutionOutput>>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityOutputV2 = {
  readonly __typename?: 'CrmOpportunityOutputV2';
  readonly opportunityId: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly stageTerm?: Maybe<Scalars['String']>;
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly divisionTerm?: Maybe<Scalars['String']>;
  readonly solutionTypeTerm?: Maybe<Scalars['String']>;
  readonly totalOpportunityVolume?: Maybe<Scalars['Float']>;
  readonly totalOpportunityRevenue?: Maybe<Scalars['Float']>;
  readonly projectedVolume?: Maybe<Scalars['Float']>;
  readonly projectedRevenue?: Maybe<Scalars['Float']>;
  readonly projectedMargin?: Maybe<Scalars['Float']>;
  readonly pricingStrategyTerm?: Maybe<Scalars['String']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly dueDate?: Maybe<Scalars['DateTime']>;
  readonly awardDate?: Maybe<Scalars['DateTime']>;
  readonly goLiveDate?: Maybe<Scalars['DateTime']>;
  readonly statusTerm?: Maybe<Scalars['String']>;
  readonly revisitDate?: Maybe<Scalars['DateTime']>;
  readonly lastClosedDate?: Maybe<Scalars['DateTime']>;
  readonly entityId: Scalars['String'];
  readonly crmOpportunityBusinessUnit?: Maybe<ReadonlyArray<CrmOpportunityBusinessUnitOutputV2>>;
  readonly crmOpportunityContact?: Maybe<ReadonlyArray<CrmOpportunityContactOutputV2>>;
  readonly crmOpportunityEquipment?: Maybe<ReadonlyArray<CrmOpportunityEquipmentOutputV2>>;
  readonly crmOpportunityIbRegion?: Maybe<ReadonlyArray<CrmOpportunityIbRegionOutputV2>>;
  readonly crmOpportunityMode?: Maybe<ReadonlyArray<CrmOpportunityModeOutputV2>>;
  readonly crmOpportunityObRegion?: Maybe<ReadonlyArray<CrmOpportunityObRegionOutputV2>>;
  readonly crmOpportunityReps?: Maybe<ReadonlyArray<CrmOpportunityRepsOutputV2>>;
  readonly crmOpportunitySize?: Maybe<ReadonlyArray<CrmOpportunitySizeOutputV2>>;
  readonly crmOpportunitySolution?: Maybe<ReadonlyArray<CrmOpportunitySolutionOutputV2>>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityRepsInput = {
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly Rep_Id?: InputMaybe<Scalars['String']>;
  readonly Rep_Name?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityRepsInputV2 = {
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly repId?: InputMaybe<Scalars['String']>;
  readonly rep?: InputMaybe<Scalars['String']>;
  readonly repName?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunityRepsOutput = {
  readonly __typename?: 'crmOpportunityRepsOutput';
  readonly Opportunity_Rep_Id: Scalars['ID'];
  readonly Rep_Id: Scalars['String'];
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly Rep_Name?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunityRepsOutputV2 = {
  readonly __typename?: 'CrmOpportunityRepsOutputV2';
  readonly opportunityRepId: Scalars['ID'];
  /** @deprecated repId is deprecated, Use rep instead */
  readonly repId: Scalars['String'];
  readonly rep?: Maybe<Employee>;
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly repName?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunitySizeInput = {
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly SizeTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunitySizeInputV2 = {
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly sizeTerm?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunitySizeOutput = {
  readonly __typename?: 'crmOpportunitySizeOutput';
  readonly Opportunity_Size_Id: Scalars['ID'];
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly SizeTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunitySizeOutputV2 = {
  readonly __typename?: 'CrmOpportunitySizeOutputV2';
  readonly opportunitySizeId: Scalars['ID'];
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly sizeTerm?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunitySolutionInput = {
  readonly Opportunity_Id?: InputMaybe<Scalars['String']>;
  readonly SolutionTerm?: InputMaybe<Scalars['String']>;
  readonly Created_By?: InputMaybe<Scalars['String']>;
  readonly Updated_By?: InputMaybe<Scalars['String']>;
  readonly Created_At?: InputMaybe<Scalars['DateTime']>;
  readonly Updated_At?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunitySolutionInputV2 = {
  readonly opportunityId?: InputMaybe<Scalars['String']>;
  readonly solutionTerm?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CrmOpportunitySolutionOutput = {
  readonly __typename?: 'crmOpportunitySolutionOutput';
  readonly Opportunity_Solution_Id: Scalars['ID'];
  readonly Opportunity_Id?: Maybe<Scalars['String']>;
  readonly SolutionTerm?: Maybe<Scalars['String']>;
  readonly Created_By?: Maybe<User>;
  readonly Updated_By?: Maybe<User>;
  readonly Created_At?: Maybe<Scalars['DateTime']>;
  readonly Updated_At?: Maybe<Scalars['DateTime']>;
};

export type CrmOpportunitySolutionOutputV2 = {
  readonly __typename?: 'CrmOpportunitySolutionOutputV2';
  readonly opportunitySolutionId: Scalars['ID'];
  readonly opportunityId?: Maybe<Scalars['String']>;
  readonly solutionTerm?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<User>;
  readonly updatedBy?: Maybe<User>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CsvHeader = {
  readonly __typename?: 'CsvHeader';
  readonly id?: Maybe<Scalars['ID']>;
  readonly version: Scalars['Int'];
  readonly documentType: Scalars['String'];
  readonly columns?: Maybe<ReadonlyArray<Maybe<CsvHeaderColumns>>>;
};

export type CsvHeaderColumns = {
  readonly __typename?: 'CsvHeaderColumns';
  readonly fieldName: Scalars['String'];
  readonly required: Scalars['Boolean'];
};

export enum CtcPerUnitTypeEnum {
  Unit = 'Unit',
  Mile = 'Mile',
  Hour = 'Hour',
  Occurence = 'Occurence',
  Stop = 'Stop'
}

export enum CtcUsedOnEnum {
  Both = 'Both',
  Order = 'Order',
  Route = 'Route'
}

export type Currency = {
  readonly __typename?: 'Currency';
  readonly unit: UnitOfCurrencyEnum;
  readonly value: Scalars['Float'];
};

export type CurrencyInput = {
  readonly unit?: InputMaybe<UnitOfCurrencyEnum>;
  readonly value: Scalars['Float'];
};

export type CurrencyInputType = {
  readonly unit?: InputMaybe<CostQuoteUnitOfCurrency>;
  readonly currencyunit?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['Decimal']>;
};

export type CurrencyInputV2 = {
  readonly value: Scalars['Float'];
  readonly unit?: InputMaybe<UnitOfCurrencyEnumV2>;
};

export type CurrencyOutputValue = {
  readonly __typename?: 'CurrencyOutputValue';
  readonly currencyunit?: Maybe<TenantConfiguration>;
  readonly value?: Maybe<Scalars['Decimal']>;
};

export type CurrencyV2 = {
  readonly __typename?: 'CurrencyV2';
  readonly value: Scalars['Float'];
  readonly unit: UnitOfCurrencyEnumV2;
};

export type CurrencyValue = {
  readonly __typename?: 'CurrencyValue';
  readonly unit?: Maybe<CostQuoteUnitOfCurrency>;
  readonly value?: Maybe<Scalars['Decimal']>;
};

/** The latest intraday exchange rate used to convert from one currency to another */
export type CurrentRate = {
  readonly __typename?: 'CurrentRate';
  readonly date: Scalars['DateTime'];
  readonly fromCurrency: Scalars['String'];
  readonly toCurrency: Scalars['String'];
  readonly rate: Scalars['Float'];
};

export type CursorPageInfo = {
  readonly __typename?: 'CursorPageInfo';
  readonly lastCursor?: Maybe<Scalars['String']>;
  readonly hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type Customer = {
  readonly __typename?: 'Customer';
  readonly accountingNotes?: Maybe<ReadonlyArray<Note>>;
  readonly addresses?: Maybe<ReadonlyArray<Address>>;
  readonly allNotes?: Maybe<ReadonlyArray<Note>>;
  readonly ancestorIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly billToCustomer?: Maybe<Customer>;
  readonly billToCustomerId?: Maybe<Scalars['ID']>;
  readonly billingAddress?: Maybe<Address>;
  readonly billingEmail?: Maybe<Scalars['String']>;
  readonly carrierRelationships?: Maybe<ReadonlyArray<CarrierCustomerRelationship>>;
  readonly children?: Maybe<ReadonlyArray<Customer>>;
  readonly code: Scalars['String'];
  readonly contacts: ReadonlyArray<Contact>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly creditHistories?: Maybe<ReadonlyArray<CreditHistory>>;
  readonly creditLimit?: Maybe<Scalars['Float']>;
  readonly creditProviders?: Maybe<ReadonlyArray<CustomerCreditProvider>>;
  readonly creditStatus?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<KeyValue>;
  readonly currencyId?: Maybe<Scalars['ID']>;
  /** @deprecated Use credit status */
  readonly customerCreditStatus?: Maybe<KeyValue>;
  /** @deprecated Use credit status */
  readonly customerCreditStatusId?: Maybe<Scalars['ID']>;
  readonly customerLevelType?: Maybe<KeyValue>;
  readonly customerLevelTypeId?: Maybe<Scalars['ID']>;
  readonly customerOpportunityType?: Maybe<KeyValue>;
  readonly customerOpportunityTypeId?: Maybe<Scalars['ID']>;
  readonly customerPaymentTerm?: Maybe<KeyValue>;
  readonly customerPaymentTermId?: Maybe<Scalars['ID']>;
  readonly customerSalesProgressType?: Maybe<KeyValue>;
  readonly customerStatusType?: Maybe<KeyValue>;
  readonly customerStatusTypeId?: Maybe<Scalars['ID']>;
  readonly descendantIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly electronicTracking?: Maybe<Scalars['Boolean']>;
  readonly electronicTrackingNumber?: Maybe<Scalars['String']>;
  readonly electronicTrackingType?: Maybe<KeyValue>;
  readonly facilityRelationships?: Maybe<ReadonlyArray<CustomerFacilityRelationship>>;
  readonly groups?: Maybe<ReadonlyArray<CustomerGroup>>;
  readonly id: Scalars['ID'];
  readonly identifiers: ReadonlyArray<Identifier>;
  readonly insuranceCargo: Scalars['Int'];
  readonly insuranceGeneral: Scalars['Int'];
  readonly insuranceLiability: Scalars['Int'];
  readonly invoiceDistributionMethod?: Maybe<KeyValue>;
  readonly invoiceDistributionMethodId?: Maybe<Scalars['ID']>;
  readonly invoiceGrouping?: Maybe<InvoiceGroupingEnum>;
  readonly mainAddress?: Maybe<Address>;
  readonly mainContact?: Maybe<Contact>;
  readonly mainRep?: Maybe<CustomerRep>;
  readonly name: Scalars['String'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly parent?: Maybe<Customer>;
  readonly paymentMethod?: Maybe<KeyValue>;
  readonly paymentMethodId?: Maybe<Scalars['ID']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly reps?: Maybe<ReadonlyArray<CustomerRep>>;
  readonly roeStatus?: Maybe<KeyValue>;
  readonly settings?: Maybe<CustomerSetting>;
  readonly siblings?: Maybe<ReadonlyArray<Customer>>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
  readonly useParentCredit?: Maybe<Scalars['Boolean']>;
  readonly website?: Maybe<Scalars['String']>;
  readonly customerStructures?: Maybe<ReadonlyArray<CustomerStructure>>;
  readonly noteDefaults?: Maybe<ReadonlyArray<NoteDefault>>;
  readonly customerFacilities?: Maybe<ReadonlyArray<CustomerFacility>>;
  readonly customerRegions?: Maybe<ReadonlyArray<CustomerRegion>>;
  readonly customerCommodities?: Maybe<ReadonlyArray<CustomerCommodity>>;
  readonly loadDefaults?: Maybe<LoadDefaults>;
  readonly customerCustomerRelationship?: Maybe<ReadonlyArray<CustomerCustomerRelationship>>;
  readonly availableCredit?: Maybe<Scalars['Float']>;
  readonly ratingSourceId?: Maybe<Scalars['String']>;
  readonly accountingStatus?: Maybe<Scalars['String']>;
  readonly accountsReceivableOverdue?: Maybe<Scalars['Decimal']>;
  readonly autoInvoiceEnabled?: Maybe<Scalars['Boolean']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Date']>;
  readonly eid?: Maybe<Scalars['Long']>;
  readonly hasPropertyMetadata: Scalars['Boolean'];
  readonly isTaxExempt?: Maybe<Scalars['Boolean']>;
  readonly propertyMetadata?: Maybe<ReadonlyArray<KeyValuePairOfStringAndPropertyMetadata>>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['Date']>;
};


export type CustomerCustomerFacilitiesArgs = {
  facilityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type CustomerAccessorialSettingsPayload = {
  readonly __typename?: 'CustomerAccessorialSettingsPayload';
  readonly customerV2: CustomerV2;
};

export type CustomerAddress = {
  readonly __typename?: 'CustomerAddress';
  readonly addressType?: Maybe<KeyValue>;
  readonly addressTypeId?: Maybe<Scalars['ID']>;
  readonly addressableId: Scalars['ID'];
  readonly addressableType: Scalars['String'];
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly geopoint: Geopoint;
  readonly id: Scalars['ID'];
  readonly isVerified: Scalars['Boolean'];
  readonly main: Scalars['Boolean'];
  readonly postalCode: Scalars['String'];
  readonly province: Scalars['String'];
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly state: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: Maybe<Scalars['String']>;
  readonly thirdPartyId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
};

export type CustomerAddressesQueryInput = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly radius?: InputMaybe<UnitOfDistanceInput>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly street1?: InputMaybe<Scalars['String']>;
  readonly street2?: InputMaybe<Scalars['String']>;
};

export type CustomerAddressInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly addressTypeId?: InputMaybe<Scalars['ID']>;
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isVerified?: InputMaybe<Scalars['Boolean']>;
  readonly main: Scalars['Boolean'];
  readonly postalCode: Scalars['String'];
  readonly province?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly state: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type CustomerChargeTypeConfigurationCreateInput = {
  readonly billToId?: InputMaybe<Scalars['Guid']>;
  readonly carrierId?: InputMaybe<Scalars['Guid']>;
  readonly carrierName?: InputMaybe<Scalars['String']>;
  readonly category?: InputMaybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly customerChargeCode?: InputMaybe<Scalars['String']>;
  readonly customerDescription?: InputMaybe<Scalars['String']>;
  readonly customerId: Scalars['Guid'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly invoiceGroupingCode?: InputMaybe<Scalars['String']>;
  readonly invoiceMethodId?: InputMaybe<Scalars['String']>;
  readonly isAllowedByCustomer: Scalars['Boolean'];
  readonly isDefault: Scalars['Boolean'];
  readonly maximumTotalRate?: InputMaybe<Scalars['Decimal']>;
  readonly minimumTotalRate?: InputMaybe<Scalars['Decimal']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly perUnit?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly rate?: InputMaybe<Scalars['Decimal']>;
  readonly size?: InputMaybe<Scalars['String']>;
};

export type CustomerChargeTypeConfigurationResponse = {
  readonly __typename?: 'CustomerChargeTypeConfigurationResponse';
  readonly billToId?: Maybe<Scalars['Guid']>;
  readonly carrierId?: Maybe<Scalars['Guid']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['DateTime'];
  readonly customerChargeCode?: Maybe<Scalars['String']>;
  readonly customerDescription?: Maybe<Scalars['String']>;
  readonly customerId: Scalars['Guid'];
  readonly description?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly invoiceGroupingCode?: Maybe<Scalars['String']>;
  readonly invoiceMethodId?: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly isAllowedByCustomer: Scalars['Boolean'];
  readonly isCredit: Scalars['Boolean'];
  readonly isDefault: Scalars['Boolean'];
  readonly maximumTotalRate?: Maybe<Scalars['Decimal']>;
  readonly minimumTotalRate?: Maybe<Scalars['Decimal']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly perUnit?: Maybe<CtcPerUnitTypeEnum>;
  readonly rate?: Maybe<Scalars['Decimal']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn: Scalars['DateTime'];
};

export type CustomerChargeTypeConfigurationUpdateInput = {
  readonly billToId?: InputMaybe<Scalars['Guid']>;
  readonly carrierId?: InputMaybe<Scalars['Guid']>;
  readonly carrierName?: InputMaybe<Scalars['String']>;
  readonly category?: InputMaybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly customerChargeCode: Scalars['String'];
  readonly customerDescription: Scalars['String'];
  readonly customerId: Scalars['Guid'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly invoiceGroupingCode?: InputMaybe<Scalars['String']>;
  readonly invoiceMethodId?: InputMaybe<Scalars['String']>;
  readonly isAllowedByCustomer: Scalars['Boolean'];
  readonly maximumTotalRate?: InputMaybe<Scalars['Decimal']>;
  readonly minimumTotalRate?: InputMaybe<Scalars['Decimal']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly perUnit?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly rate?: InputMaybe<Scalars['Decimal']>;
  readonly size?: InputMaybe<Scalars['String']>;
};

export type CustomerCommitment = {
  readonly __typename?: 'CustomerCommitment';
  readonly customerCommitmentID: Scalars['ID'];
  readonly customerCommitmentCode?: Maybe<Scalars['Int']>;
  readonly externalCustomerCommitmentID?: Maybe<Scalars['String']>;
  readonly externalBidID?: Maybe<Scalars['String']>;
  readonly volume?: Maybe<Scalars['Int']>;
  readonly otherVolumes?: Maybe<ReadonlyArray<Maybe<CommitmentVolume>>>;
  readonly frequency?: Maybe<Scalars['String']>;
  readonly customerWeekStarts?: Maybe<CommitmentDayOfWeekEnum>;
  readonly surge?: Maybe<Scalars['Decimal']>;
  readonly surgeV2?: Maybe<Scalars['Int']>;
  readonly acceptanceRate?: Maybe<Scalars['Int']>;
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly volumeEffectiveDate?: Maybe<Scalars['DateTime']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly customerID?: Maybe<Scalars['ID']>;
  readonly customerRateQuoteID?: Maybe<Scalars['ID']>;
  readonly laneID?: Maybe<Scalars['ID']>;
  readonly createdbyUserID?: Maybe<Scalars['ID']>;
  readonly lastUpdatedbyUserID?: Maybe<Scalars['ID']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  readonly customerRateQuote?: Maybe<CustomerQuoteV4>;
  readonly capacityConstraintResponse?: Maybe<CapacityConstraint>;
};

export type CustomerCommitmentCapacityConstraintInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly sundayConstraint?: InputMaybe<Scalars['Int']>;
  readonly mondayConstraint?: InputMaybe<Scalars['Int']>;
  readonly tuesdayConstraint?: InputMaybe<Scalars['Int']>;
  readonly wednesdayConstraint?: InputMaybe<Scalars['Int']>;
  readonly thursdayConstraint?: InputMaybe<Scalars['Int']>;
  readonly fridayConstraint?: InputMaybe<Scalars['Int']>;
  readonly saturdayConstraint?: InputMaybe<Scalars['Int']>;
};

export type CustomerCommitmentConnection = {
  readonly __typename?: 'CustomerCommitmentConnection';
  readonly edges: ReadonlyArray<Maybe<CustomerCommitmentEdge>>;
  readonly totalCount: Scalars['Int'];
  readonly pageInfo: PageInfo;
};

export type CustomerCommitmentEdge = {
  readonly __typename?: 'CustomerCommitmentEdge';
  readonly cursor: Scalars['String'];
  readonly node: CustomerCommitment;
};

export type CustomerCommitmentFilter = {
  readonly customerID?: InputMaybe<Scalars['ID']>;
  readonly laneID?: InputMaybe<Scalars['ID']>;
  readonly laneIDs?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly rateQuoteID?: InputMaybe<Scalars['ID']>;
  readonly zeroVolume?: InputMaybe<Scalars['Int']>;
  readonly orderID?: InputMaybe<Scalars['ID']>;
  readonly includeActive?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerCommitmentInputType = {
  readonly customerCommitmentID?: InputMaybe<Scalars['ID']>;
  readonly externalCustomerCommitmentID?: InputMaybe<Scalars['String']>;
  readonly externalBidID?: InputMaybe<Scalars['String']>;
  readonly volume?: InputMaybe<Scalars['Int']>;
  readonly frequency?: InputMaybe<Scalars['String']>;
  readonly customerWeekStarts?: InputMaybe<CommitmentDayOfWeekEnum>;
  readonly surge?: InputMaybe<Scalars['Decimal']>;
  readonly surgeV2?: InputMaybe<Scalars['Int']>;
  readonly acceptanceRate?: InputMaybe<Scalars['Int']>;
  readonly effectiveDate?: InputMaybe<Scalars['DateTime']>;
  readonly volumeEffectiveDate?: InputMaybe<Scalars['DateTime']>;
  readonly volumeEffectiveDateTime?: InputMaybe<Scalars['DateTimeOffset']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly customerID?: InputMaybe<Scalars['ID']>;
  readonly customerRateQuoteID?: InputMaybe<Scalars['ID']>;
  readonly laneID?: InputMaybe<Scalars['ID']>;
  readonly capacityConstraintInput?: InputMaybe<CustomerCommitmentCapacityConstraintInput>;
};

export type CustomerCommitmentOrders = {
  readonly __typename?: 'CustomerCommitmentOrders';
  readonly id?: Maybe<Scalars['ID']>;
  readonly frequencyStartDate?: Maybe<Scalars['DateTime']>;
  readonly frequencyEndDate?: Maybe<Scalars['DateTime']>;
  readonly customerOrders?: Maybe<ReadonlyArray<Maybe<CustomerOrderV2>>>;
};

export type CustomerCommitmentsSettings = {
  readonly __typename?: 'CustomerCommitmentsSettings';
  readonly id?: Maybe<Scalars['ID']>;
  readonly customerId: Scalars['ID'];
  readonly commitmentPerformanceBasis?: Maybe<Scalars['String']>;
  readonly weekStarting?: Maybe<CommitmentDayOfWeekEnum>;
  readonly surgePercentage?: Maybe<Scalars['Decimal']>;
  readonly surgeV2?: Maybe<Scalars['Int']>;
  readonly acceptanceRate?: Maybe<Scalars['Int']>;
  readonly orderStatus?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type CustomerCommitmentsSettingsInputType = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly customerId: Scalars['ID'];
  readonly commitmentPerformanceBasis?: InputMaybe<Scalars['String']>;
  readonly weekStarting: CommitmentDayOfWeekEnum;
  readonly surgePercentage?: InputMaybe<Scalars['Decimal']>;
  readonly surgeV2?: InputMaybe<Scalars['Int']>;
  readonly acceptanceRate?: InputMaybe<Scalars['Int']>;
  readonly orderStatus: ReadonlyArray<InputMaybe<Scalars['String']>>;
};

export type CustomerCommodity = {
  readonly __typename?: 'CustomerCommodity';
  readonly id: Scalars['ID'];
  /** @deprecated Use customerIdV2, field became nullable */
  readonly customerId: Scalars['String'];
  readonly customerIdV2?: Maybe<Scalars['String']>;
  readonly facilityId?: Maybe<Scalars['String']>;
  readonly customerFacilityId?: Maybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly customerCode?: Maybe<Scalars['String']>;
  readonly stccCode?: Maybe<Scalars['String']>;
  readonly packagingUnit?: Maybe<Scalars['String']>;
  readonly loadOn?: Maybe<Scalars['String']>;
  readonly expWt: Scalars['Float'];
  readonly expPieces?: Maybe<Scalars['String']>;
  readonly uom_temperature?: Maybe<Scalars['String']>;
  readonly uom_dimension?: Maybe<Scalars['String']>;
  readonly uom_linear?: Maybe<Scalars['String']>;
  readonly uom_cube?: Maybe<Scalars['String']>;
  readonly uom_density?: Maybe<Scalars['String']>;
  readonly temp?: Maybe<Scalars['Boolean']>;
  readonly precoolTo?: Maybe<Scalars['Float']>;
  readonly minTemp?: Maybe<Scalars['Float']>;
  readonly maxTemp?: Maybe<Scalars['Float']>;
  readonly tempSetting?: Maybe<Scalars['String']>;
  readonly wtUnit?: Maybe<Scalars['String']>;
  readonly hazmat?: Maybe<Scalars['Boolean']>;
  readonly hazmatClass?: Maybe<Scalars['String']>;
  readonly packagingGroup?: Maybe<Scalars['String']>;
  readonly unNaNumber?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly expDimensions?: Maybe<Scalars['String']>;
  readonly expDimensionsW?: Maybe<Scalars['Float']>;
  readonly expDimensionsH?: Maybe<Scalars['Float']>;
  readonly expDimensionsL?: Maybe<Scalars['Float']>;
  readonly expLinear?: Maybe<Scalars['Float']>;
  readonly expCube?: Maybe<Scalars['Float']>;
  readonly expDensity?: Maybe<Scalars['Float']>;
  readonly expClass?: Maybe<Scalars['String']>;
  readonly expNmfc?: Maybe<Scalars['String']>;
  readonly nmfcSubcode?: Maybe<Scalars['String']>;
  readonly unitSystem?: Maybe<Scalars['String']>;
  readonly canTopLoad?: Maybe<Scalars['Boolean']>;
  readonly canBottomLoad?: Maybe<Scalars['Boolean']>;
  readonly customerFacility?: Maybe<CustomerFacility>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly facility?: Maybe<Facility>;
  readonly facilityV2?: Maybe<FacilityV2>;
};

export type CustomerCommodityConnection = {
  readonly __typename?: 'CustomerCommodityConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<CustomerCommodityEdge>;
};

export type CustomerCommodityEdge = {
  readonly __typename?: 'CustomerCommodityEdge';
  readonly node: CustomerCommodity;
  readonly cursor: Scalars['String'];
};

export type CustomerCommodityFilter = {
  /** customerId to filter by */
  readonly customerId?: InputMaybe<Scalars['ID']>;
  /** faciltyId to filter by */
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  /** description to filter by */
  readonly description?: InputMaybe<Scalars['String']>;
};

/** The connection type for Customer. */
export type CustomerConnection = {
  readonly __typename?: 'CustomerConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<CustomerEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

export type CustomerConnectionV2 = {
  readonly __typename?: 'CustomerConnectionV2';
  readonly edges: ReadonlyArray<CustomerEdgeV2>;
  readonly pageInfo: PageInfo;
};

export type CustomerContact = {
  readonly __typename?: 'CustomerContact';
  readonly chatType?: Maybe<KeyValue>;
  readonly chatTypeId?: Maybe<Scalars['ID']>;
  readonly chatUsername?: Maybe<Scalars['String']>;
  readonly contactType: KeyValue;
  readonly contactTypeId: Scalars['ID'];
  readonly active: Scalars['Boolean'];
  readonly contactableId: Scalars['ID'];
  readonly contactableType: Scalars['String'];
  readonly emailAddress?: Maybe<Scalars['String']>;
  readonly extension?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly id: Scalars['ID'];
  readonly main: Scalars['Boolean'];
  readonly name?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly thirdPartyId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
};

export type CustomerContactInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly chatTypeId?: InputMaybe<Scalars['ID']>;
  readonly chatUsername?: InputMaybe<Scalars['String']>;
  readonly contactTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type CustomerCreditMemo = {
  readonly __typename?: 'CustomerCreditMemo';
  readonly amount: Scalars['Decimal'];
  readonly creditMemoDate?: Maybe<Scalars['DateTime']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly invoiceCurrencyAmount?: Maybe<Scalars['Decimal']>;
  readonly invoiceHeader: InvoiceHeader;
};

export type CustomerCreditMemoInput = {
  readonly creditMemoDate: Scalars['DateTime'];
  readonly id: Scalars['ID'];
};

export type CustomerCreditProvider = {
  readonly __typename?: 'CustomerCreditProvider';
  readonly coverageAmount: Scalars['Float'];
  readonly createdAt?: Maybe<Scalars['ISO8601Date']>;
  readonly createdAtDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly createdByUser: User;
  readonly creditProvider: KeyValue;
  readonly creditScore: Scalars['String'];
  readonly currency: KeyValue;
  readonly effectiveDate: Scalars['ISO8601Date'];
  readonly effectiveDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly expirationDate: Scalars['ISO8601Date'];
  readonly expirationDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly id: Scalars['ID'];
  readonly notes?: Maybe<Scalars['String']>;
};

export type CustomerCreditProviderInput = {
  readonly coverageAmount: Scalars['Float'];
  readonly creditProviderId: Scalars['ID'];
  readonly creditScore: Scalars['String'];
  readonly currencyId: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly effectiveDate: Scalars['ISO8601Date'];
  readonly expirationDate: Scalars['ISO8601Date'];
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type CustomerCreditProviderInputV2 = {
  readonly coverageAmount: Scalars['Float'];
  readonly creditProviderId: Scalars['ID'];
  readonly creditScore: Scalars['String'];
  readonly currencyId: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly effectiveDate: Scalars['ISO8601Date'];
  readonly expirationDate: Scalars['ISO8601Date'];
  readonly notes?: InputMaybe<Scalars['String']>;
};

export type CustomerCreditProviderV2 = {
  readonly __typename?: 'CustomerCreditProviderV2';
  readonly coverageAmount: Scalars['Float'];
  readonly createdAt?: Maybe<Scalars['ISO8601Date']>;
  readonly createdAtDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly createdByUser: UserV2;
  readonly creditProvider: KeyValue;
  readonly creditProviderId?: Maybe<Scalars['ID']>;
  readonly creditScore: Scalars['String'];
  readonly currency: KeyValue;
  readonly currencyId?: Maybe<Scalars['ID']>;
  readonly effectiveDate: Scalars['ISO8601Date'];
  readonly effectiveDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly expirationDate: Scalars['ISO8601Date'];
  readonly expirationDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly id: Scalars['ID'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['String']>;
};

export type CustomerCrmDocuemntFilter = {
  readonly customerId: Scalars['ID'];
};

export type CustomerCrmDocument = {
  readonly __typename?: 'CustomerCrmDocument';
  readonly contacts?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly createdBy?: Maybe<User>;
  readonly createdDate?: Maybe<Scalars['DateTime']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly direction?: Maybe<Scalars['String']>;
  readonly documentId?: Maybe<Scalars['ID']>;
  readonly documentName?: Maybe<Scalars['String']>;
  readonly documentType?: Maybe<Scalars['String']>;
  readonly fileName?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  readonly opportunities?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly opportunityId?: Maybe<Scalars['ID']>;
  readonly opportunityName?: Maybe<Scalars['String']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<User>;
  readonly updatedDate?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type CustomerCrmDocumentConnection = {
  readonly __typename?: 'CustomerCrmDocumentConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CustomerCrmDocumentEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CustomerCrmDocument>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerCrmDocumentEdge = {
  readonly __typename?: 'CustomerCrmDocumentEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CustomerCrmDocument>;
};

export type CustomerCrmNote = {
  readonly __typename?: 'CustomerCrmNote';
  readonly contacts?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly createdBy?: Maybe<User>;
  readonly createdDate?: Maybe<Scalars['DateTime']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly opportunities?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly opportunityId?: Maybe<Scalars['ID']>;
  readonly opportunityName?: Maybe<Scalars['String']>;
  readonly reps?: Maybe<ReadonlyArray<Maybe<CustomerCrmNotesRep>>>;
  readonly type?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<User>;
  readonly updatedDate?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type CustomerCrmNoteConnection = {
  readonly __typename?: 'CustomerCrmNoteConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CustomerCrmNoteEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CustomerCrmNote>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerCrmNoteEdge = {
  readonly __typename?: 'CustomerCrmNoteEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CustomerCrmNote>;
};

export type CustomerCrmNotesFilter = {
  readonly customerId: Scalars['ID'];
};

export type CustomerCrmNotesRep = {
  readonly __typename?: 'CustomerCRMNotesRep';
  readonly Employee?: Maybe<Employee>;
};

export type CustomerCrmOpportunity = {
  readonly __typename?: 'CustomerCRMOpportunity';
  readonly customerId: Scalars['ID'];
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly isDefault?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated. Use LastUpdatedByUser instead */
  readonly LastUpdatedBy?: Maybe<Employee>;
  readonly LastUpdatedByUser?: Maybe<User>;
  readonly lastUpdatedDate?: Maybe<Scalars['DateTime']>;
  readonly name: Scalars['String'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly opportunityStatusId?: Maybe<Scalars['ID']>;
  readonly opportunityTypeId?: Maybe<Scalars['ID']>;
  readonly owningContactId?: Maybe<Scalars['ID']>;
  readonly OwningRep?: Maybe<Employee>;
};

/** A connection to a list of items. */
export type CustomerCrmOpportunityConnection = {
  readonly __typename?: 'CustomerCRMOpportunityConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CustomerCrmOpportunityEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CustomerCrmOpportunity>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerCrmOpportunityEdge = {
  readonly __typename?: 'CustomerCRMOpportunityEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CustomerCrmOpportunity>;
};

export type CustomerCustomerRelationship = {
  readonly __typename?: 'CustomerCustomerRelationship';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['String'];
  readonly relatedCustomerId: Scalars['String'];
  readonly relationshipType?: Maybe<Scalars['String']>;
  readonly note?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly relatedCustomer?: Maybe<Customer>;
  readonly relatedCustomerV2?: Maybe<CustomerV2>;
  readonly updatedByUser?: Maybe<User>;
  readonly updatedByUserV2?: Maybe<UserV2>;
};

/** An edge in a connection. */
export type CustomerEdge = {
  readonly __typename?: 'CustomerEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: Customer;
};

export type CustomerEdgeV2 = {
  readonly __typename?: 'CustomerEdgeV2';
  readonly cursor: Scalars['String'];
  readonly node: CustomerV2;
};

export type CustomerFacility = {
  readonly __typename?: 'CustomerFacility';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['String'];
  readonly facilityId: Scalars['String'];
  readonly customerCustomerId?: Maybe<Scalars['String']>;
  readonly schedulingSystem?: Maybe<Scalars['String']>;
  readonly schedulingURL?: Maybe<Scalars['String']>;
  readonly username?: Maybe<Scalars['String']>;
  readonly password?: Maybe<Scalars['String']>;
  readonly isAutoScheduleEligible?: Maybe<Scalars['Boolean']>;
  readonly isAutoScheduleDefault?: Maybe<Scalars['Boolean']>;
  readonly isPickup?: Maybe<Scalars['Boolean']>;
  readonly pickupCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly isDelivery?: Maybe<Scalars['Boolean']>;
  readonly deliveryCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly trailPool?: Maybe<Scalars['String']>;
  readonly minTrailers?: Maybe<Scalars['Int']>;
  readonly maxTrailers?: Maybe<Scalars['Int']>;
  readonly nowTrailers?: Maybe<Scalars['Int']>;
  readonly specialRequirementsAndEquipment?: Maybe<Scalars['String']>;
  readonly pickupAllowableFreeTimeValue?: Maybe<Scalars['Int']>;
  readonly pickupAllowableFreeTimeUnit?: Maybe<Scalars['String']>;
  readonly deliveryAllowableFreeTimeValue?: Maybe<Scalars['Int']>;
  readonly deliveryAllowableFreeTimeUnit?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly customerCustomer?: Maybe<Customer>;
  readonly customerCustomerV2?: Maybe<CustomerV2>;
  readonly facility?: Maybe<Facility>;
  readonly facilityV2?: Maybe<FacilityV2>;
  readonly drop?: Maybe<Scalars['String']>;
  readonly customerCommodities?: Maybe<ReadonlyArray<CustomerCommodity>>;
};

export type CustomerFacilityConnection = {
  readonly __typename?: 'CustomerFacilityConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<CustomerFacilityEdge>;
};

export type CustomerFacilityEdge = {
  readonly __typename?: 'CustomerFacilityEdge';
  readonly node: CustomerFacility;
  readonly cursor: Scalars['String'];
};

export type CustomerFacilityFilter = {
  /** customerId to filter by */
  readonly customerId?: InputMaybe<Scalars['ID']>;
  /** facilityId to filter by */
  readonly facilityId?: InputMaybe<Scalars['ID']>;
};

export type CustomerFacilityRelationship = {
  readonly __typename?: 'CustomerFacilityRelationship';
  readonly customer: Customer;
  readonly delivery: Scalars['Boolean'];
  readonly deliveryAvgDwellMinutes?: Maybe<Scalars['Int']>;
  readonly deliveryCode?: Maybe<Scalars['String']>;
  readonly facility: Facility;
  readonly id: Scalars['ID'];
  readonly pickup: Scalars['Boolean'];
  readonly pickupAvgDwellMinutes?: Maybe<Scalars['Int']>;
  readonly pickupCode?: Maybe<Scalars['String']>;
  readonly schedulingSystemType?: Maybe<KeyValue>;
};

export type CustomerFacilityRelationshipInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly delivery: Scalars['Boolean'];
  readonly deliveryAvgDwellMinutes?: InputMaybe<Scalars['Int']>;
  readonly deliveryCode?: InputMaybe<Scalars['String']>;
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly pickup: Scalars['Boolean'];
  readonly pickupAvgDwellMinutes?: InputMaybe<Scalars['Int']>;
  readonly pickupCode?: InputMaybe<Scalars['String']>;
  readonly schedulingSystemTypeId?: InputMaybe<Scalars['ID']>;
};

export type CustomerFacilityRelationshipInputV2 = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly delivery: Scalars['Boolean'];
  readonly deliveryAvgDwellMinutes?: InputMaybe<Scalars['Int']>;
  readonly deliveryCode?: InputMaybe<Scalars['String']>;
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly pickup: Scalars['Boolean'];
  readonly pickupAvgDwellMinutes?: InputMaybe<Scalars['Int']>;
  readonly pickupCode?: InputMaybe<Scalars['String']>;
  readonly schedulingSystemTypeId?: InputMaybe<Scalars['ID']>;
};

export type CustomerFacilityRelationshipV2 = {
  readonly __typename?: 'CustomerFacilityRelationshipV2';
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly customer: CustomerV2;
  readonly delivery: Scalars['Boolean'];
  readonly deliveryAvgDwellMinutes?: Maybe<Scalars['Int']>;
  readonly deliveryCode?: Maybe<Scalars['String']>;
  readonly facilityId?: Maybe<Scalars['ID']>;
  readonly facility: FacilityV2;
  readonly id: Scalars['ID'];
  readonly pickup: Scalars['Boolean'];
  readonly pickupAvgDwellMinutes?: Maybe<Scalars['Int']>;
  readonly pickupCode?: Maybe<Scalars['String']>;
  readonly schedulingSystemType?: Maybe<KeyValue>;
  readonly schedulingSystemTypeId?: Maybe<Scalars['ID']>;
};

export type CustomerFuelRangeCreateInput = {
  readonly customerFuelRateHeaderId?: InputMaybe<Scalars['Guid']>;
  readonly minPrice?: InputMaybe<Scalars['Decimal']>;
  readonly maxPrice?: InputMaybe<Scalars['Decimal']>;
  readonly priceDecimal?: InputMaybe<Scalars['Decimal']>;
  readonly pricePercentage?: InputMaybe<Scalars['Decimal']>;
};

export type CustomerFuelRangeModel = {
  readonly __typename?: 'CustomerFuelRangeModel';
  readonly customerFuelRangeId: Scalars['ID'];
  readonly customerFuelRateHeaderId?: Maybe<Scalars['Guid']>;
  readonly createdByUserId?: Maybe<Scalars['Guid']>;
  readonly createdByDesc?: Maybe<Scalars['String']>;
  readonly createdOnUtc?: Maybe<Scalars['DateTime']>;
  readonly updatedByUserId?: Maybe<Scalars['Guid']>;
  readonly updatedByDesc?: Maybe<Scalars['String']>;
  readonly updatedOnUtc?: Maybe<Scalars['DateTime']>;
  readonly priceDecimal?: Maybe<Scalars['Decimal']>;
  readonly pricePercentage?: Maybe<Scalars['Decimal']>;
  readonly minPrice?: Maybe<Scalars['Decimal']>;
  readonly maxPrice?: Maybe<Scalars['Decimal']>;
};

export type CustomerFuelRangeResponse = {
  readonly __typename?: 'CustomerFuelRangeResponse';
  readonly customerFuelRangeId: Scalars['ID'];
  readonly minPrice?: Maybe<Scalars['Decimal']>;
  readonly maxPrice?: Maybe<Scalars['Decimal']>;
  readonly priceDecimal?: Maybe<Scalars['Decimal']>;
  readonly pricePercentage?: Maybe<Scalars['Decimal']>;
};

export type CustomerFuelRangeUpdateInput = {
  readonly customerFuelRangeId: Scalars['ID'];
  readonly customerFuelRateHeaderId?: InputMaybe<Scalars['Guid']>;
  readonly minPrice?: InputMaybe<Scalars['Decimal']>;
  readonly maxPrice?: InputMaybe<Scalars['Decimal']>;
  readonly priceDecimal?: InputMaybe<Scalars['Decimal']>;
  readonly pricePercentage?: InputMaybe<Scalars['Decimal']>;
};

export type CustomerFuelRateHeaderCreateInput = {
  readonly fuelProgramCustomerConfigurationId: Scalars['ID'];
  readonly fuelApplicationMethod?: InputMaybe<Scalars['String']>;
  readonly fuelScale: Scalars['String'];
  readonly fuelWeekday?: InputMaybe<Scalars['String']>;
  readonly fuelSurchargeDate?: InputMaybe<Scalars['String']>;
  /** DEPRECATED - `fuelInclusivity` is no longer used - value will be ignored */
  readonly fuelInclusivity?: InputMaybe<Scalars['String']>;
  readonly fuelIndexType: Scalars['String'];
  readonly fuelSurchargeFrequency?: InputMaybe<Scalars['String']>;
};

export type CustomerFuelRateHeaderModel = {
  readonly __typename?: 'CustomerFuelRateHeaderModel';
  readonly customerFuelRateHeaderId: Scalars['ID'];
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly createdByDesc?: Maybe<Scalars['String']>;
  readonly createdOnUtc?: Maybe<Scalars['DateTime']>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly updatedByDesc?: Maybe<Scalars['String']>;
  readonly updatedOnUtc?: Maybe<Scalars['DateTime']>;
  readonly fuelProgramCustomerConfigurationId: Scalars['ID'];
  readonly fuelApplicationMethod?: Maybe<Scalars['String']>;
  readonly fuelScale?: Maybe<Scalars['String']>;
  readonly fuelWeekday?: Maybe<Scalars['String']>;
  readonly fuelSurchargeDate?: Maybe<Scalars['String']>;
  /** @deprecated `fuelInclusivity` is no longer used - value will always return `Yes` */
  readonly fuelInclusivity?: Maybe<Scalars['String']>;
  readonly fuelIndexType?: Maybe<Scalars['String']>;
  readonly fuelSurchargeFrequency?: Maybe<Scalars['String']>;
};

export type CustomerFuelRateHeaderResponse = {
  readonly __typename?: 'CustomerFuelRateHeaderResponse';
  readonly customerFuelRateHeaderId: Scalars['Guid'];
  readonly fuelProgramCustomerConfigurationId: Scalars['Guid'];
  readonly fuelApplicationMethod: Scalars['String'];
  readonly fuelScale: Scalars['String'];
  readonly fuelWeekday: Scalars['String'];
  readonly fuelSurchargeDate?: Maybe<Scalars['String']>;
  readonly fuelSurchargeFrequency: Scalars['String'];
  /** @deprecated `fuelInclusivity` is no longer used - value will always return `Yes` */
  readonly fuelInclusivity: Scalars['String'];
  readonly fuelIndexType: Scalars['String'];
  readonly fuelRanges: ReadonlyArray<CustomerFuelRangeResponse>;
  /** @deprecated Use `surcharges` */
  readonly weeklySurcharges: ReadonlyArray<CustomerFuelWeeklySurchargeResponse>;
  readonly surcharges: ReadonlyArray<CustomerFuelSurchargeResponse>;
};

export type CustomerFuelSurchargeResponse = {
  readonly __typename?: 'CustomerFuelSurchargeResponse';
  readonly beginDate?: Maybe<Scalars['DateTime']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly averageDieselPrice?: Maybe<Scalars['Decimal']>;
  readonly fuelSurcharge?: Maybe<Scalars['Decimal']>;
};

export type CustomerFuelWeeklySurchargeResponse = {
  readonly __typename?: 'CustomerFuelWeeklySurchargeResponse';
  readonly beginDate?: Maybe<Scalars['DateTime']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly averageDieselPrice?: Maybe<Scalars['Decimal']>;
  readonly fuelSurcharge?: Maybe<Scalars['Decimal']>;
};

export type CustomerGroup = {
  readonly __typename?: 'CustomerGroup';
  readonly customer?: Maybe<Customer>;
  readonly employeeDivision?: Maybe<KeyValue>;
  readonly employeeGroup?: Maybe<KeyValue>;
  readonly groupType?: Maybe<KeyValue>;
  readonly id: Scalars['ID'];
};

export type CustomerGroupInput = {
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly customerId: Scalars['ID'];
  readonly employeeDivisionId: Scalars['ID'];
  readonly employeeGroupId: Scalars['ID'];
  readonly groupTypeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type CustomerGroupInputV2 = {
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly customerId: Scalars['ID'];
  readonly employeeDivisionId: Scalars['ID'];
  readonly employeeGroupId: Scalars['ID'];
  readonly groupTypeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type CustomerGroupV2 = {
  readonly __typename?: 'CustomerGroupV2';
  readonly customerId?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<CustomerV2>;
  readonly employeeDivision?: Maybe<KeyValue>;
  readonly employeeDivisionId?: Maybe<Scalars['ID']>;
  readonly employeeGroup?: Maybe<KeyValue>;
  readonly employeeGroupId?: Maybe<Scalars['ID']>;
  readonly groupType?: Maybe<KeyValue>;
  readonly groupTypeId?: Maybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
};

export type CustomerInput = {
  readonly accountingNotes?: InputMaybe<ReadonlyArray<NoteInput>>;
  readonly addresses?: InputMaybe<ReadonlyArray<AddressInput>>;
  readonly allNotes?: InputMaybe<ReadonlyArray<NoteInput>>;
  readonly billToCustomerId?: InputMaybe<Scalars['ID']>;
  readonly billingAddressId?: InputMaybe<Scalars['ID']>;
  readonly billingEmail?: InputMaybe<Scalars['String']>;
  readonly code: Scalars['String'];
  readonly contacts?: InputMaybe<ReadonlyArray<ContactInput>>;
  readonly creditHistories?: InputMaybe<ReadonlyArray<CreditHistoryInput>>;
  readonly currencyId?: InputMaybe<Scalars['ID']>;
  readonly customerCreditStatusId?: InputMaybe<Scalars['ID']>;
  readonly customerLevelTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerOpportunityTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerPaymentTermId?: InputMaybe<Scalars['ID']>;
  readonly customerSalesProgressTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerStatusTypeId?: InputMaybe<Scalars['ID']>;
  readonly electronicTracking?: InputMaybe<Scalars['Boolean']>;
  readonly electronicTrackingNumber?: InputMaybe<Scalars['String']>;
  readonly electronicTrackingTypeId?: InputMaybe<Scalars['ID']>;
  readonly facilityRelationships?: InputMaybe<ReadonlyArray<CustomerFacilityRelationshipInput>>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly identifiers?: InputMaybe<ReadonlyArray<IdentifierInput>>;
  readonly insuranceCargo?: InputMaybe<Scalars['Int']>;
  readonly insuranceGeneral?: InputMaybe<Scalars['Int']>;
  readonly insuranceLiability?: InputMaybe<Scalars['Int']>;
  readonly invoiceDistributionMethodId?: InputMaybe<Scalars['ID']>;
  readonly invoiceGrouping?: InputMaybe<InvoiceGroupingEnum>;
  readonly mainContactId?: InputMaybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly parentId?: InputMaybe<Scalars['ID']>;
  readonly paymentMethodId?: InputMaybe<Scalars['ID']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly reps?: InputMaybe<ReadonlyArray<CustomerRepInput>>;
  readonly roeStatus?: InputMaybe<RoeStatusEnum>;
  readonly settings?: InputMaybe<CustomerSettingInput>;
  readonly useParentCredit?: InputMaybe<Scalars['Boolean']>;
  readonly website?: InputMaybe<Scalars['String']>;
};

export type CustomerInputV2 = {
  readonly accountingNotes?: InputMaybe<ReadonlyArray<CustomerNoteInput>>;
  readonly addresses?: InputMaybe<ReadonlyArray<CustomerAddressInput>>;
  readonly allNotes?: InputMaybe<ReadonlyArray<CustomerNoteInput>>;
  readonly billToCustomerId?: InputMaybe<Scalars['ID']>;
  readonly billingAddressId?: InputMaybe<Scalars['ID']>;
  readonly billingEmail?: InputMaybe<Scalars['String']>;
  readonly code: Scalars['String'];
  readonly contacts?: InputMaybe<ReadonlyArray<CustomerContactInput>>;
  readonly creditHistories?: InputMaybe<ReadonlyArray<CreditHistoryInputV2>>;
  readonly currencyId?: InputMaybe<Scalars['ID']>;
  readonly customerCreditStatusId?: InputMaybe<Scalars['ID']>;
  readonly customerLevelTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerOpportunityTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerPaymentTermId?: InputMaybe<Scalars['ID']>;
  readonly customerSalesProgressTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerStatusTypeId?: InputMaybe<Scalars['ID']>;
  readonly electronicTracking?: InputMaybe<Scalars['Boolean']>;
  readonly electronicTrackingNumber?: InputMaybe<Scalars['String']>;
  readonly electronicTrackingTypeId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly identifiers?: InputMaybe<ReadonlyArray<IdentifierInputV2>>;
  readonly insuranceCargo?: InputMaybe<Scalars['Int']>;
  readonly insuranceGeneral?: InputMaybe<Scalars['Int']>;
  readonly insuranceLiability?: InputMaybe<Scalars['Int']>;
  readonly invoiceDistributionMethodId?: InputMaybe<Scalars['ID']>;
  readonly invoiceGrouping?: InputMaybe<InvoiceGroupingEnum>;
  readonly mainContactId?: InputMaybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly parentId?: InputMaybe<Scalars['ID']>;
  readonly paymentMethodId?: InputMaybe<Scalars['ID']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly reps?: InputMaybe<ReadonlyArray<CustomerRepInputV2>>;
  readonly roeStatus?: InputMaybe<Scalars['String']>;
  readonly settings?: InputMaybe<CustomerSettingInputV2>;
  readonly useParentCredit?: InputMaybe<Scalars['Boolean']>;
  readonly website?: InputMaybe<Scalars['String']>;
};

export type CustomerInteraction = {
  readonly __typename?: 'CustomerInteraction';
  readonly contactId?: Maybe<Scalars['ID']>;
  readonly customer?: Maybe<Customer>;
  readonly CustomerV2?: Maybe<CustomerV2>;
  readonly id: Scalars['ID'];
  readonly interactionDate: Scalars['DateTime'];
  readonly interactionOutcomeTypeId?: Maybe<Scalars['ID']>;
  readonly interactionTypeId?: Maybe<Scalars['ID']>;
  readonly interactionViaTypeId?: Maybe<Scalars['ID']>;
  readonly nextInteractionDate?: Maybe<Scalars['DateTime']>;
  readonly NextInteractionRep?: Maybe<Employee>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly opportunityId?: Maybe<Scalars['ID']>;
  readonly opportunityName?: Maybe<Scalars['String']>;
  /** @deprecated This property will be removed. Use InteractionOutcomeTypeId instead  */
  readonly outcome?: Maybe<CustomerInteractionOutcomeEnum>;
  readonly Rep?: Maybe<Employee>;
  /** @deprecated This property will be removed. Use InteractionTypeId instead  */
  readonly type?: Maybe<CustomerInteractionTypeEnum>;
  /** @deprecated This property will be removed. Use InteractionViaTypeId instead  */
  readonly via?: Maybe<CustomerInteractionViaEnum>;
};

/** A connection to a list of items. */
export type CustomerInteractionConnection = {
  readonly __typename?: 'CustomerInteractionConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CustomerInteractionEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CustomerInteraction>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerInteractionEdge = {
  readonly __typename?: 'CustomerInteractionEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CustomerInteraction>;
};

export type CustomerInteractionFilter = {
  readonly customerId?: InputMaybe<Scalars['ID']>;
};

export enum CustomerInteractionFrequencyEnum {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Biweekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  Bimonthly = 'BIMONTHLY'
}

export enum CustomerInteractionOutcomeEnum {
  Noanswer = 'NOANSWER',
  Newopportunity = 'NEWOPPORTUNITY',
  Newcustomerlocation = 'NEWCUSTOMERLOCATION',
  Closedopportunity = 'CLOSEDOPPORTUNITY',
  Lostopportunity = 'LOSTOPPORTUNITY',
  Opportunityforotherbusinessunit = 'OPPORTUNITYFOROTHERBUSINESSUNIT'
}

export enum CustomerInteractionTypeEnum {
  Coldcall = 'COLDCALL',
  Warmlead = 'WARMLEAD',
  Followup = 'FOLLOWUP',
  Contractdiscussions = 'CONTRACTDISCUSSIONS',
  Pricingcall = 'PRICINGCALL',
  Accountchange = 'ACCOUNTCHANGE'
}

export enum CustomerInteractionViaEnum {
  Email = 'EMAIL',
  Phonecall = 'PHONECALL',
  Text = 'TEXT',
  Webconference = 'WEBCONFERENCE',
  Inperson = 'INPERSON',
  Socialmedia = 'SOCIALMEDIA'
}

export type CustomerInvoiceFilter = {
  readonly billToCustomerId?: InputMaybe<Scalars['Uuid']>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly carrierRepId?: InputMaybe<Scalars['Uuid']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly customerRepId?: InputMaybe<Scalars['Uuid']>;
  readonly deliveryDateRange?: InputMaybe<DateRange>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly dueDateRange?: InputMaybe<DateRange>;
  readonly invoiceDateRange?: InputMaybe<DateRange>;
  readonly invoiceNumber?: InputMaybe<Scalars['String']>;
  readonly loadCode?: InputMaybe<Scalars['String']>;
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly maximumAmount?: InputMaybe<Scalars['Decimal']>;
  readonly maximumBalance?: InputMaybe<Scalars['Decimal']>;
  readonly minimumAmount?: InputMaybe<Scalars['Decimal']>;
  readonly minimumBalance?: InputMaybe<Scalars['Decimal']>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly orderCode?: InputMaybe<Scalars['String']>;
  readonly orderFinancialStatuses?: InputMaybe<ReadonlyArray<OrderFinancialStatusEnum>>;
  readonly orderId?: InputMaybe<Scalars['Uuid']>;
  readonly orderLifeCycles?: InputMaybe<ReadonlyArray<OrderLifeCycleStatusEnum>>;
  readonly pageSize?: InputMaybe<Scalars['Long']>;
  readonly project?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly railsLoadIds?: InputMaybe<ReadonlyArray<Scalars['Uuid']>>;
  readonly refCode?: InputMaybe<Scalars['String']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly skipRecordCount?: InputMaybe<Scalars['Long']>;
  readonly statementNumber?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<StatusEnum>;
  readonly statuses?: InputMaybe<ReadonlyArray<StatusEnum>>;
};

export type CustomerMarkupInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly active: Scalars['Boolean'];
  readonly carrierName?: InputMaybe<Scalars['String']>;
  readonly types: Scalars['String'];
  readonly currencyValue: Scalars['String'];
  readonly currencyType?: InputMaybe<Scalars['String']>;
  readonly effDate: Scalars['DateTime'];
  readonly expDate: Scalars['DateTime'];
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerMarkupResponse = {
  readonly __typename?: 'CustomerMarkupResponse';
  readonly id?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly types?: Maybe<Scalars['String']>;
  readonly currencyValue?: Maybe<Scalars['String']>;
  readonly currencyType?: Maybe<Scalars['String']>;
  readonly effDate?: Maybe<Scalars['DateTime']>;
  readonly expDate?: Maybe<Scalars['DateTime']>;
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CustomerMarkupResponsePayloads = {
  readonly __typename?: 'CustomerMarkupResponsePayloads';
  readonly customerMarkupResponses?: Maybe<ReadonlyArray<CustomerMarkupResponse>>;
};

export type CustomerNote = {
  readonly __typename?: 'CustomerNote';
  readonly createdAt: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly noteType: Scalars['String'];
  readonly noteableId: Scalars['ID'];
  readonly noteableType: Scalars['String'];
  readonly text?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['Iso8601Date'];
};

export type CustomerNoteInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly noteType?: InputMaybe<Scalars['String']>;
  readonly noteableId?: InputMaybe<Scalars['ID']>;
  readonly text?: InputMaybe<Scalars['String']>;
};

export type CustomerOpportunityFilter = {
  readonly checkForDefaultOpportunity?: InputMaybe<Scalars['Boolean']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly showInactive?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerOrder = {
  readonly __typename?: 'CustomerOrder';
  readonly acceptedBy?: Maybe<Employee>;
  readonly accountingOrder?: Maybe<Order>;
  readonly activationStatus?: Maybe<KeyValue>;
  readonly activationStatusReason?: Maybe<Scalars['String']>;
  readonly billToCustomer?: Maybe<Customer>;
  readonly cargoInsurance?: Maybe<Currency>;
  readonly code: Scalars['String'];
  readonly commodities?: Maybe<ReadonlyArray<Commodity>>;
  readonly commodityTotals?: Maybe<CommodityTotals>;
  readonly customer: Customer;
  /** Customer-specific id for this order */
  readonly customerOrderNumber?: Maybe<Scalars['String']>;
  readonly declinedBy?: Maybe<Employee>;
  readonly declinedReason?: Maybe<KeyValue>;
  readonly dimensions?: Maybe<CustomerOrderDimensions>;
  readonly division?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly invoiceNote?: Maybe<Scalars['String']>;
  readonly lifeCycleStatus?: Maybe<Scalars['String']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly orderNote?: Maybe<Scalars['String']>;
  readonly orderedBy?: Maybe<Contact>;
  readonly priceTier?: Maybe<KeyValue>;
  readonly project?: Maybe<Scalars['String']>;
  readonly quoteType?: Maybe<KeyValue>;
  readonly refs?: Maybe<ReadonlyArray<Ref>>;
  readonly relation?: Maybe<KeyValue>;
  readonly reps?: Maybe<ReadonlyArray<CustomerOrderRep>>;
  readonly requirements?: Maybe<ReadonlyArray<OrderRequirement>>;
  /** Timestamp by when the tendering system expects a response */
  readonly respondBy?: Maybe<DatetimeWithTimezone>;
  /** The SCAC to which this order was tendered */
  readonly scac?: Maybe<Scalars['String']>;
  readonly segmentCode?: Maybe<Scalars['String']>;
  readonly serviceLevel?: Maybe<KeyValue>;
  readonly size?: Maybe<KeyValue>;
  readonly tenderFrom?: Maybe<Customer>;
  readonly tenderStatus?: Maybe<KeyValue>;
  /** Timestamp when this shipment was tendered */
  readonly tenderedAt?: Maybe<DatetimeWithTimezone>;
  readonly totalDistance?: Maybe<Length>;
  readonly trailerTypes?: Maybe<ReadonlyArray<KeyValue>>;
  readonly transportMode?: Maybe<KeyValue>;
};


export type CustomerOrderCargoInsuranceArgs = {
  unit?: InputMaybe<UnitOfCurrencyEnum>;
};


export type CustomerOrderTotalDistanceArgs = {
  unit: UnitOfLengthEnum;
};

export type CustomerOrderDimensions = {
  readonly __typename?: 'CustomerOrderDimensions';
  readonly length: Length;
  readonly height?: Maybe<Length>;
  readonly width?: Maybe<Length>;
};


export type CustomerOrderDimensionsLengthArgs = {
  unit: UnitOfLengthEnum;
};


export type CustomerOrderDimensionsHeightArgs = {
  unit: UnitOfLengthEnum;
};


export type CustomerOrderDimensionsWidthArgs = {
  unit: UnitOfLengthEnum;
};

export type CustomerOrderEdge = {
  readonly __typename?: 'CustomerOrderEdge';
  readonly node: CustomerOrderV2;
  readonly cursor: Scalars['String'];
};

export type CustomerOrderError = {
  readonly __typename?: 'CustomerOrderError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

export type CustomerOrderInput = {
  readonly customerId: Scalars['ID'];
  readonly requirements?: InputMaybe<ReadonlyArray<OrderRequirementInput>>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly commodities?: InputMaybe<ReadonlyArray<CommodityInput>>;
  readonly shipments?: InputMaybe<ReadonlyArray<ShipmentInput>>;
  readonly refs?: InputMaybe<ReadonlyArray<RefInputV2>>;
  readonly dimensions?: InputMaybe<DimensionsInput>;
  readonly reps?: InputMaybe<ReadonlyArray<CustomerOrderRepInput>>;
  readonly activationStatusId?: InputMaybe<Scalars['ID']>;
  readonly tenderStatusId?: InputMaybe<Scalars['ID']>;
  /** Please use cargoInsuranceV2. It supports new currency values */
  readonly cargoInsurance?: InputMaybe<CurrencyInput>;
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly relationId?: InputMaybe<Scalars['ID']>;
  readonly quoteTypeId?: InputMaybe<Scalars['ID']>;
  readonly priceTierId?: InputMaybe<Scalars['ID']>;
  readonly invoiceNote?: InputMaybe<Scalars['String']>;
  readonly orderNote?: InputMaybe<Scalars['String']>;
  readonly acceptedById?: InputMaybe<Scalars['ID']>;
  readonly declinedById?: InputMaybe<Scalars['ID']>;
  readonly declineReason?: InputMaybe<Scalars['String']>;
  readonly declinedReasonId?: InputMaybe<Scalars['String']>;
  readonly orderedById?: InputMaybe<Scalars['ID']>;
  readonly divisionV2?: InputMaybe<Scalars['ID']>;
  readonly businessUnit?: InputMaybe<Scalars['ID']>;
  readonly projectV2?: InputMaybe<Scalars['ID']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  readonly serviceLevelId?: InputMaybe<Scalars['String']>;
  readonly billToCustomerId?: InputMaybe<Scalars['ID']>;
  readonly billToCodeId?: InputMaybe<Scalars['ID']>;
  readonly tenderFromId?: InputMaybe<Scalars['ID']>;
  readonly activationStatusReason?: InputMaybe<Scalars['String']>;
  readonly scac?: InputMaybe<Scalars['String']>;
  readonly tenderedAt?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly respondBy?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly customerOrderNumber?: InputMaybe<Scalars['String']>;
  readonly source?: InputMaybe<Scalars['String']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  readonly paymentTermsId?: InputMaybe<Scalars['ID']>;
  readonly tarpType?: InputMaybe<Scalars['ID']>;
  readonly tarpCount?: InputMaybe<Scalars['Int']>;
  readonly braceTypes?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly braceCount?: InputMaybe<Scalars['Int']>;
  readonly cargoInsuranceV2?: InputMaybe<LoadCurrencyInput>;
};

export type CustomerOrderPayload = {
  readonly __typename?: 'CustomerOrderPayload';
  readonly errors: ReadonlyArray<CustomerOrderError>;
  readonly order?: Maybe<CustomerOrderV2>;
};

/** Holds customer order rates. */
export type CustomerOrderRatesByCarrierConnection = {
  readonly __typename?: 'CustomerOrderRatesByCarrierConnection';
  readonly code?: Maybe<Scalars['String']>;
  readonly edges?: Maybe<ReadonlyArray<Maybe<RateDetailsEdgeV2>>>;
  readonly message?: Maybe<Scalars['String']>;
};

export type CustomerOrderRatesByCarrierInputType = {
  readonly filter?: InputMaybe<CustomerOrderRatesByCarrierRequestFilter>;
};

export type CustomerOrderRatesByCarrierRequestFilter = {
  readonly applyRouteVendorReferences?: InputMaybe<Scalars['Boolean']>;
  readonly carrierId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
};

/** Holds customer order rates. */
export type CustomerOrderRatesConnectionV2 = {
  readonly __typename?: 'CustomerOrderRatesConnectionV2';
  readonly code?: Maybe<Scalars['String']>;
  readonly edges?: Maybe<ReadonlyArray<Maybe<RateDetailsEdgeV2>>>;
  readonly message?: Maybe<Scalars['String']>;
};

export type CustomerOrderRatesInputTypeV2 = {
  readonly filter?: InputMaybe<CustomerOrderRatesRequestFilterV2>;
};

export type CustomerOrderRatesRequestFilterV2 = {
  readonly orderId: Scalars['ID'];
};

/** Deprecated, use CustomerOrderRepV2 */
export type CustomerOrderRep = {
  readonly __typename?: 'CustomerOrderRep';
  readonly id: Scalars['ID'];
  readonly employeeId: Scalars['ID'];
  readonly employeeGroup?: Maybe<KeyValue>;
  readonly employeeOffice?: Maybe<KeyValue>;
  readonly main: Scalars['Boolean'];
  readonly type: KeyValue;
  readonly fullName: Scalars['String'];
  readonly employee?: Maybe<Employee>;
};

export type CustomerOrderRepInput = {
  readonly employeeGroupId?: InputMaybe<Scalars['ID']>;
  readonly employeeId: Scalars['ID'];
  readonly employeeOfficeId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly main: Scalars['Boolean'];
  readonly typeId: Scalars['ID'];
};

export type CustomerOrderRepV2 = {
  readonly __typename?: 'CustomerOrderRepV2';
  readonly id: Scalars['ID'];
  readonly employeeId: Scalars['ID'];
  readonly employeeGroup?: Maybe<KeyValue>;
  readonly employeeOffice?: Maybe<KeyValue>;
  readonly main: Scalars['Boolean'];
  readonly type: KeyValue;
  readonly fullName: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly updatedByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly employee?: Maybe<Employee>;
  readonly employeeV2?: Maybe<EmployeeV2>;
};

export type CustomerOrderRequiredDocument = {
  readonly __typename?: 'CustomerOrderRequiredDocument';
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly isManualRequirement?: Maybe<Scalars['Boolean']>;
  readonly isMergedPodDocument?: Maybe<Scalars['Boolean']>;
  readonly isWaived?: Maybe<Scalars['Boolean']>;
  readonly loadDocument?: Maybe<LoadDocument>;
  readonly loadDocumentId?: Maybe<Scalars['ID']>;
  readonly loadDocumentType?: Maybe<Scalars['String']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly mergedPodStopNumbers?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly orderId?: Maybe<Scalars['ID']>;
  readonly stopAddress?: Maybe<Scalars['String']>;
  readonly stopFacilityName?: Maybe<Scalars['String']>;
  readonly stopId?: Maybe<Scalars['ID']>;
  readonly stopNumber?: Maybe<Scalars['Int']>;
};

export type CustomerOrderRequiredDocumentCreateInputType = {
  readonly customerId: Scalars['ID'];
  readonly isIncludeWithInvoice?: InputMaybe<Scalars['Boolean']>;
  readonly loadDocumentId?: InputMaybe<Scalars['ID']>;
  readonly loadDocumentType: Scalars['String'];
  readonly loadId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
};

export type CustomerOrderRequiredDocumentUpdateInput = {
  readonly id: Scalars['ID'];
  readonly isWaived: Scalars['Boolean'];
  readonly loadDocumentId?: InputMaybe<Scalars['ID']>;
};

export type CustomerOrdersConnection = {
  readonly __typename?: 'CustomerOrdersConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<CustomerOrderEdge>;
};

export type CustomerOrdersFilter = {
  /** A list of orders to return */
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** A list of order activation statuses to filter by */
  readonly activationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of order tender statuses to filter by */
  readonly tenderStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of customer identifiers to filter by */
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** A list of customer groups to filter by */
  readonly customerGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of order rep ids to filter by */
  readonly repIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** A list of pickup states to filter by */
  readonly pickupStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of delivery states to filter by */
  readonly deliveryStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Customer Order Number(s) to filter by */
  readonly customerOrderNumbers?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** The source of the orders */
  readonly source?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** The name of the field to order the results with.  Note: this can only be a field directly on the order */
  readonly sortBy?: InputMaybe<Scalars['String']>;
  /** The direction to order the results in */
  readonly sortDirection?: InputMaybe<LoadSortDirectionEnum>;
};

export type CustomerOrderV2 = {
  readonly __typename?: 'CustomerOrderV2';
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly customerId: Scalars['String'];
  readonly customer: Customer;
  readonly customerV2: CustomerV2;
  readonly deleted: Scalars['Boolean'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly activationStatus?: Maybe<KeyValue>;
  readonly tenderStatus?: Maybe<KeyValue>;
  readonly transportMode?: Maybe<KeyValue>;
  readonly size?: Maybe<KeyValue>;
  readonly relation?: Maybe<KeyValue>;
  readonly quoteType?: Maybe<KeyValue>;
  readonly priceTier?: Maybe<KeyValue>;
  /**
   * Please use cargoInsuranceV2. It supports new currency values
   * @deprecated Please use cargoInsuranceV2. It supports new currency values
   */
  readonly cargoInsurance: Currency;
  readonly requirements?: Maybe<ReadonlyArray<OrderRequirement>>;
  readonly trailerTypes: ReadonlyArray<KeyValue>;
  readonly commodities?: Maybe<ReadonlyArray<CommodityV2>>;
  readonly shipments?: Maybe<ReadonlyArray<Shipment>>;
  readonly dimensions?: Maybe<CustomerOrderDimensions>;
  readonly orderNote?: Maybe<Scalars['String']>;
  readonly invoiceNote?: Maybe<Scalars['String']>;
  readonly refs?: Maybe<ReadonlyArray<RefV2>>;
  readonly reps: ReadonlyArray<CustomerOrderRep>;
  readonly acceptedBy?: Maybe<Employee>;
  readonly orderedBy?: Maybe<Contact>;
  readonly createdByUser?: Maybe<User>;
  readonly updatedByUser?: Maybe<User>;
  readonly declinedBy?: Maybe<Employee>;
  readonly acceptedByV2?: Maybe<EmployeeV2>;
  readonly orderedByV2?: Maybe<CustomerContact>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly declinedByV2?: Maybe<EmployeeV2>;
  readonly declinedReason?: Maybe<KeyValue>;
  readonly declinedReasonId?: Maybe<Scalars['String']>;
  readonly version: Scalars['Int'];
  readonly division?: Maybe<Scalars['String']>;
  readonly project?: Maybe<Scalars['String']>;
  readonly segmentCode?: Maybe<Scalars['String']>;
  readonly serviceLevel?: Maybe<KeyValue>;
  readonly billToCustomer?: Maybe<Customer>;
  readonly billToCodeId?: Maybe<Scalars['ID']>;
  readonly tenderFrom?: Maybe<Customer>;
  readonly billToCustomerV2?: Maybe<CustomerV2>;
  readonly tenderFromV2?: Maybe<CustomerV2>;
  readonly activationStatusReason?: Maybe<Scalars['String']>;
  readonly lifeCycleStatus?: Maybe<Scalars['String']>;
  readonly scac?: Maybe<Scalars['String']>;
  readonly tenderedAt?: Maybe<DatetimeWithTimezone>;
  readonly respondBy?: Maybe<DatetimeWithTimezone>;
  readonly customerOrderNumber?: Maybe<Scalars['String']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly repsV2?: Maybe<ReadonlyArray<CustomerOrderRepV2>>;
  readonly numberOfPallets?: Maybe<Scalars['Int']>;
  readonly paymentTerms?: Maybe<KeyValue>;
  readonly divisionV2?: Maybe<Scalars['String']>;
  readonly projectV2?: Maybe<Scalars['String']>;
  readonly businessUnit?: Maybe<Scalars['String']>;
  readonly tarpType?: Maybe<Scalars['ID']>;
  readonly tarpCount?: Maybe<Scalars['Int']>;
  readonly braceTypes?: Maybe<ReadonlyArray<OrderBraceTypes>>;
  readonly braceCount?: Maybe<Scalars['Int']>;
  readonly cargoInsuranceV2: LoadCurrency;
  readonly totalDistance?: Maybe<Length>;
  readonly stops: ReadonlyArray<OrderStop>;
  readonly stopsV2: ReadonlyArray<OrderStopV2>;
  readonly load?: Maybe<LoadV2>;
  readonly patterns?: Maybe<ReadonlyArray<Pattern>>;
  readonly accountingOrder?: Maybe<Order>;
};


export type CustomerOrderV2CargoInsuranceArgs = {
  unit?: InputMaybe<UnitOfCurrencyEnum>;
};


export type CustomerOrderV2CargoInsuranceV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type CustomerOrderV2TotalDistanceArgs = {
  unit: UnitOfLengthEnum;
};

export type CustomerOverheadDetail = {
  readonly __typename?: 'CustomerOverheadDetail';
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly customerOverhead?: Maybe<Scalars['Decimal']>;
};

export type CustomerPayment = {
  readonly __typename?: 'CustomerPayment';
  readonly amount?: Maybe<Scalars['Decimal']>;
  readonly checkNumber?: Maybe<Scalars['String']>;
  readonly issueDate?: Maybe<Scalars['Date']>;
  readonly paymentType?: Maybe<Scalars['String']>;
};

export type CustomerPaymentInput = {
  readonly customerOrderId?: InputMaybe<Scalars['ID']>;
  readonly invoiceId?: InputMaybe<Scalars['ID']>;
  readonly loadId?: InputMaybe<Scalars['ID']>;
};

export type CustomerPortfolio = {
  readonly __typename?: 'CustomerPortfolio';
  readonly edges?: Maybe<ReadonlyArray<Maybe<PortfolioEdge>>>;
  readonly pageInfo?: Maybe<PortfolioPageInfo>;
};

export type CustomerPortfolioEntity = {
  readonly __typename?: 'customerPortfolioEntity';
  readonly customer?: Maybe<Customer>;
  readonly CustomerV2?: Maybe<CustomerV2>;
  readonly lastInteraction?: Maybe<PortfolioInteraction>;
};

export type CustomerPortfolioInputType = {
  readonly filter?: InputMaybe<CustomersWithInteractionFilterInputType>;
};

export type CustomerQuote = {
  readonly __typename?: 'CustomerQuote';
  readonly allTotalRate?: Maybe<Scalars['Float']>;
  readonly code?: Maybe<Scalars['Int']>;
  /** @deprecated use createdAtDateTime for timestamp */
  readonly createdAt: Scalars['ISO8601Date'];
  readonly createdAtDateTime: Scalars['ISO8601DateTime'];
  readonly createdBy: Employee;
  readonly createdById: Scalars['ID'];
  readonly customer: Customer;
  readonly customerId: Scalars['ID'];
  readonly destinationGeographySource?: Maybe<Scalars['String']>;
  readonly destinationGeographySourceId?: Maybe<Scalars['Int']>;
  readonly destinationText: Scalars['String'];
  readonly distance: UnitOfDistance;
  /** @deprecated use effectiveDateTime for timestamp */
  readonly effectiveDate: Scalars['ISO8601Date'];
  readonly effectiveDateTime: Scalars['ISO8601DateTime'];
  /** @deprecated use expirationDateTime for timestamp */
  readonly expirationDate?: Maybe<Scalars['ISO8601Date']>;
  readonly expirationDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly extrasTotalRate?: Maybe<Scalars['Float']>;
  readonly fuelTotalRate?: Maybe<Scalars['Float']>;
  readonly id: Scalars['ID'];
  readonly lineItems?: Maybe<ReadonlyArray<QuoteLineItem>>;
  readonly linehaulTotalRate?: Maybe<Scalars['Float']>;
  readonly loadSize: KeyValue;
  readonly minHeight?: Maybe<UnitOfDistance>;
  readonly minLength?: Maybe<UnitOfDistance>;
  readonly minWidth?: Maybe<UnitOfDistance>;
  readonly multipleStops: Scalars['Boolean'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly originGeographySource?: Maybe<Scalars['String']>;
  readonly originGeographySourceId?: Maybe<Scalars['Int']>;
  readonly originText: Scalars['String'];
  readonly quoteGroup?: Maybe<QuoteGroup>;
  readonly quoteGroupId?: Maybe<Scalars['ID']>;
  readonly quotePriceTier?: Maybe<KeyValue>;
  readonly quoteType: KeyValue;
  readonly quoteTypeId: Scalars['ID'];
  readonly quotedBy: Employee;
  readonly quotedById: Scalars['ID'];
  readonly quotedTo?: Maybe<Contact>;
  readonly routingType: Scalars['String'];
  /** @deprecated Use scacV2 */
  readonly scac?: Maybe<Scalars['String']>;
  readonly scacV2: KeyValue;
  readonly team: Scalars['Boolean'];
  readonly trailerTypes?: Maybe<ReadonlyArray<KeyValue>>;
  readonly transportMode: KeyValue;
  readonly transportModeId: Scalars['ID'];
};

/** The connection type for CustomerQuote. */
export type CustomerQuoteConnection = {
  readonly __typename?: 'CustomerQuoteConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<CustomerQuoteEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CustomerQuoteEdge = {
  readonly __typename?: 'CustomerQuoteEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: CustomerQuote;
};

/** customerQuotePaginatedV2 is deprecated. Use CustomerQuoteV3. */
export type CustomerQuotePaginatedV2 = {
  readonly __typename?: 'customerQuotePaginatedV2';
  readonly allTotalRate?: Maybe<RateQuoteCurrencyOutput>;
  readonly code?: Maybe<Scalars['Int']>;
  readonly createdAtDateTime?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<Employee>;
  readonly createdById: Scalars['ID'];
  readonly customer?: Maybe<Customer>;
  readonly customerId: Scalars['ID'];
  readonly destinationGeographySource?: Maybe<Scalars['String']>;
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly destinationGeographySourceId?: Maybe<Scalars['Int']>;
  /**
   * DEPRECATED. Do not use this.
   * @deprecated Field no longer supported
   */
  readonly destinationText: Scalars['String'];
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly distance?: Maybe<RateQuoteUnitOfDistance>;
  readonly effectiveDateTime: Scalars['DateTime'];
  readonly expirationDateTime?: Maybe<Scalars['DateTime']>;
  readonly extrasTotalRate?: Maybe<RateQuoteCurrencyOutput>;
  readonly fuelTotalRate?: Maybe<RateQuoteCurrencyOutput>;
  readonly id: Scalars['ID'];
  readonly lane?: Maybe<Lane>;
  readonly laneId: Scalars['Uuid'];
  readonly linehaulTotalRate?: Maybe<RateQuoteCurrencyOutput>;
  readonly lineItems?: Maybe<ReadonlyArray<RateQuoteLineItemOutput>>;
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly loadSize: RateQuoteKeyValue;
  readonly minHeight?: Maybe<RateQuoteUnitOfDistance>;
  readonly minLength?: Maybe<RateQuoteUnitOfDistance>;
  readonly minWidth?: Maybe<RateQuoteUnitOfDistance>;
  readonly multipleStops: Scalars['Boolean'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly originGeographySource?: Maybe<Scalars['String']>;
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly originGeographySourceId?: Maybe<Scalars['Int']>;
  /**
   * DEPRECATED. Do not use this.
   * @deprecated Field no longer supported
   */
  readonly originText: Scalars['String'];
  readonly quotedBy?: Maybe<Employee>;
  readonly quotedById: Scalars['ID'];
  readonly quotedToId?: Maybe<Scalars['ID']>;
  readonly quoteGroup?: Maybe<RateQuoteQuoteGroup>;
  readonly quoteGroupId?: Maybe<Scalars['ID']>;
  readonly quotePriceTier?: Maybe<RateQuoteKeyValue>;
  readonly quoteType: RateQuoteKeyValue;
  readonly quoteTypeId?: Maybe<Scalars['ID']>;
  readonly routingType: Scalars['String'];
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly scac?: Maybe<Scalars['String']>;
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly scacV2: RateQuoteKeyValue;
  readonly team: Scalars['Boolean'];
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly trailerTypes?: Maybe<ReadonlyArray<RateQuoteKeyValue>>;
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly transportMode: RateQuoteKeyValue;
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly transportModeId: Scalars['String'];
};

/** A connection to a list of items. */
export type CustomerQuotePaginatedV2Connection = {
  readonly __typename?: 'customerQuotePaginatedV2Connection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CustomerQuotePaginatedV2Edge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CustomerQuotePaginatedV2>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerQuotePaginatedV2Edge = {
  readonly __typename?: 'customerQuotePaginatedV2Edge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CustomerQuotePaginatedV2>;
};

/** DEPRECATED. use CustomerQuoteV4 */
export type CustomerQuoteV3 = {
  readonly __typename?: 'CustomerQuoteV3';
  readonly allTotalRate?: Maybe<RateQuoteCurrencyOutput>;
  readonly autoAccept: Scalars['Boolean'];
  readonly code?: Maybe<Scalars['Int']>;
  readonly createdAtDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated DEPRECATED. Use userCreatedBy */
  readonly createdBy?: Maybe<Employee>;
  readonly createdById: Scalars['ID'];
  readonly createdByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly customer?: Maybe<Customer>;
  readonly customerId: Scalars['ID'];
  readonly customerV2?: Maybe<CustomerV2>;
  readonly effectiveDateTime: Scalars['DateTime'];
  readonly expirationDateTime?: Maybe<Scalars['DateTime']>;
  readonly extrasTotalRate?: Maybe<RateQuoteCurrencyOutput>;
  readonly fuelTotalRate?: Maybe<RateQuoteCurrencyOutput>;
  readonly id: Scalars['ID'];
  /** @deprecated DEPRECATED. Use LaneV2 */
  readonly lane?: Maybe<Lane>;
  readonly laneId?: Maybe<Scalars['ID']>;
  readonly laneV2?: Maybe<LaneV2>;
  readonly linehaulTotalRate?: Maybe<RateQuoteCurrencyOutput>;
  readonly lineItems?: Maybe<ReadonlyArray<RateQuoteLineItemOutput>>;
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly loadSize?: Maybe<RateQuoteKeyValue>;
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly minHeight?: Maybe<RateQuoteUnitOfDistance>;
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly minLength?: Maybe<RateQuoteUnitOfDistance>;
  /**
   * DEPRECATED. Do not use this.For values refer lane object.
   * @deprecated Field no longer supported
   */
  readonly minWidth?: Maybe<RateQuoteUnitOfDistance>;
  readonly multipleStops: Scalars['Boolean'];
  readonly notes?: Maybe<Scalars['String']>;
  /** @deprecated DEPRECATED. use userQuotedBy */
  readonly quotedBy?: Maybe<Employee>;
  readonly quotedById: Scalars['ID'];
  readonly quotedByUser?: Maybe<User>;
  readonly quotedByUserV2?: Maybe<UserV2>;
  readonly quotedToId?: Maybe<Scalars['ID']>;
  readonly quoteGroup?: Maybe<RateQuoteQuoteGroup>;
  readonly quoteGroupId?: Maybe<Scalars['ID']>;
  readonly quotePriceTier?: Maybe<RateQuoteKeyValue>;
  readonly quoteType: RateQuoteKeyValue;
  readonly quoteTypeId?: Maybe<Scalars['ID']>;
  readonly routingType: Scalars['String'];
  readonly scac?: Maybe<RateQuoteKeyValue>;
  readonly team: Scalars['Boolean'];
  /** @deprecated This property is Depericated use createByUser */
  readonly userCreatedBy?: Maybe<User>;
  /** @deprecated This property is Depericated use quotedByUser */
  readonly userQuotedBy?: Maybe<User>;
};

/** A connection to a list of items. */
export type CustomerQuoteV3Connection = {
  readonly __typename?: 'CustomerQuoteV3Connection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CustomerQuoteV3Edge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CustomerQuoteV3>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerQuoteV3Edge = {
  readonly __typename?: 'CustomerQuoteV3Edge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CustomerQuoteV3>;
};

export type CustomerQuoteV4 = {
  readonly __typename?: 'CustomerQuoteV4';
  readonly allTotalRate?: Maybe<RateQuoteCurrencyOutputV2>;
  readonly autoAccept: Scalars['Boolean'];
  readonly businessUnit?: Maybe<RateQuoteKeyValue>;
  readonly code?: Maybe<Scalars['Int']>;
  readonly createdAtDateTime?: Maybe<Scalars['DateTime']>;
  readonly createdById: Scalars['ID'];
  readonly createdByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly customer?: Maybe<Customer>;
  readonly customerId: Scalars['ID'];
  readonly customerV2?: Maybe<CustomerV2>;
  readonly effectiveDateTime: Scalars['DateTime'];
  readonly expirationDateTime?: Maybe<Scalars['DateTime']>;
  readonly extrasTotalRate?: Maybe<RateQuoteCurrencyOutputV2>;
  readonly fuelTotalRate?: Maybe<RateQuoteCurrencyOutputV2>;
  readonly id: Scalars['ID'];
  readonly laneId?: Maybe<Scalars['ID']>;
  readonly laneV2?: Maybe<LaneV2>;
  readonly linehaulTotalRate?: Maybe<RateQuoteCurrencyOutputV2>;
  readonly lineItems?: Maybe<ReadonlyArray<RateQuoteLineItemOutputV2>>;
  readonly multipleStops: Scalars['Boolean'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly project?: Maybe<RateQuoteKeyValue>;
  readonly quotedById: Scalars['ID'];
  readonly quotedByUser?: Maybe<User>;
  readonly quotedByUserV2?: Maybe<UserV2>;
  readonly quotedToId?: Maybe<Scalars['ID']>;
  readonly quoteGroup?: Maybe<RateQuoteQuoteGroup>;
  readonly quoteGroupId?: Maybe<Scalars['ID']>;
  readonly quotePriceTier?: Maybe<RateQuoteKeyValue>;
  readonly quoteType: RateQuoteKeyValue;
  readonly quoteTypeId?: Maybe<Scalars['ID']>;
  readonly routingType: Scalars['String'];
  readonly scac?: Maybe<RateQuoteKeyValue>;
  readonly team: Scalars['Boolean'];
};

/** A connection to a list of items. */
export type CustomerQuoteV4Connection = {
  readonly __typename?: 'CustomerQuoteV4Connection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CustomerQuoteV4Edge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CustomerQuoteV4>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerQuoteV4Edge = {
  readonly __typename?: 'CustomerQuoteV4Edge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CustomerQuoteV4>;
};

export type CustomerRegion = {
  readonly __typename?: 'CustomerRegion';
  readonly id: Scalars['ID'];
  readonly division?: Maybe<Scalars['String']>;
  readonly region?: Maybe<Scalars['String']>;
  readonly customerId: Scalars['ID'];
};

export type CustomerRep = {
  readonly __typename?: 'CustomerRep';
  readonly employee: Employee;
  readonly id: Scalars['ID'];
  readonly isLoadRep: Scalars['Boolean'];
  readonly main: Scalars['Boolean'];
  readonly repType: KeyValue;
};

export type CustomerRepInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly employeeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isLoadRep?: InputMaybe<Scalars['Boolean']>;
  readonly main: Scalars['Boolean'];
  readonly repTypeId: Scalars['ID'];
};

export type CustomerRepInputV2 = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly employeeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isLoadRep?: InputMaybe<Scalars['Boolean']>;
  readonly main: Scalars['Boolean'];
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly repTypeId: Scalars['ID'];
  readonly divisionId?: InputMaybe<Scalars['ID']>;
  readonly modeId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly directionId?: InputMaybe<Scalars['ID']>;
  readonly businessUnitId?: InputMaybe<Scalars['ID']>;
};

export type CustomerRepV2 = {
  readonly __typename?: 'CustomerRepV2';
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly employee: EmployeeV2;
  readonly id: Scalars['ID'];
  readonly isLoadRep: Scalars['Boolean'];
  readonly main: Scalars['Boolean'];
  readonly active: Scalars['Boolean'];
  readonly repType: KeyValue;
  readonly repTypeId?: Maybe<Scalars['String']>;
  readonly division?: Maybe<KeyValue>;
  readonly divisionId?: Maybe<Scalars['ID']>;
  readonly mode?: Maybe<KeyValue>;
  readonly modeId?: Maybe<Scalars['ID']>;
  readonly size?: Maybe<KeyValue>;
  readonly sizeId?: Maybe<Scalars['ID']>;
  readonly direction?: Maybe<KeyValue>;
  readonly directionId?: Maybe<Scalars['ID']>;
  readonly businessUnit?: Maybe<KeyValue>;
  readonly businessUnitId?: Maybe<Scalars['ID']>;
  readonly customerId?: Maybe<Scalars['String']>;
};

export type CustomerRequiredDocument = {
  readonly __typename?: 'CustomerRequiredDocument';
  readonly chargeType?: Maybe<Scalars['ID']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly customerId: Scalars['ID'];
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isIncludeWithInvoice: Scalars['Boolean'];
  readonly isMergePod?: Maybe<Scalars['Boolean']>;
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
};

/** The input type for a new customer required document. */
export type CustomerRequiredDocumentCreateInput = {
  readonly chargeType?: InputMaybe<Scalars['ID']>;
  readonly customerId: Scalars['ID'];
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly isIncludeWithInvoice: Scalars['Boolean'];
  readonly isMergePod?: InputMaybe<Scalars['Boolean']>;
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
};

/** The input type for updating customer required document. */
export type CustomerRequiredDocumentUpdateInput = {
  readonly chargeType?: InputMaybe<Scalars['ID']>;
  readonly customerId: Scalars['ID'];
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isIncludeWithInvoice: Scalars['Boolean'];
  readonly isMergePod?: InputMaybe<Scalars['Boolean']>;
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
};

export type CustomerSetting = {
  readonly __typename?: 'CustomerSetting';
  readonly customer: Customer;
  readonly defaultTrailerLength: UnitOfDistance;
  readonly doNotPostLoads?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  readonly trailerTypes?: Maybe<ReadonlyArray<KeyValue>>;
};

export type CustomerSettingInput = {
  readonly customerId: Scalars['ID'];
  readonly defaultTrailerLength?: InputMaybe<UnitOfDistanceInput>;
  readonly doNotPostLoads?: InputMaybe<Scalars['Boolean']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type CustomerSettingInputV2 = {
  readonly customerId: Scalars['ID'];
  readonly defaultTrailerLength?: InputMaybe<UnitOfDistanceInput>;
  readonly doNotPostLoads?: InputMaybe<Scalars['Boolean']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type CustomerSettingV2 = {
  readonly __typename?: 'CustomerSettingV2';
  readonly customerId?: Maybe<Scalars['String']>;
  readonly customer: CustomerV2;
  readonly defaultTrailerLength: UnitOfDistance;
  readonly doNotPostLoads?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  readonly trailerTypes?: Maybe<ReadonlyArray<KeyValue>>;
};

export type CustomersFilter = {
  readonly address?: InputMaybe<AddressesQueryInput>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly currencyId?: InputMaybe<Scalars['ID']>;
  readonly customerCreditStatusId?: InputMaybe<Scalars['ID']>;
  readonly customerLevelTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerOpportunityTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerPaymentTermId?: InputMaybe<Scalars['ID']>;
  readonly customerStatusTypeId?: InputMaybe<Scalars['ID']>;
  readonly employeeId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly identifier?: InputMaybe<IdentifierQueryInput>;
  readonly insuranceCargo?: InputMaybe<Scalars['Int']>;
  readonly insuranceGeneral?: InputMaybe<Scalars['Int']>;
  readonly insuranceLiability?: InputMaybe<Scalars['Int']>;
  readonly invoiceDistributionMethodId?: InputMaybe<Scalars['ID']>;
  readonly isMainRep?: InputMaybe<Scalars['Boolean']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly paymentMethodId?: InputMaybe<Scalars['ID']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly sort?: InputMaybe<Scalars['String']>;
  readonly text?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
};

export type CustomersFilterV2 = {
  readonly address?: InputMaybe<CustomerAddressesQueryInput>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly currencyId?: InputMaybe<Scalars['ID']>;
  readonly customerCreditStatusId?: InputMaybe<Scalars['ID']>;
  readonly customerLevelTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerOpportunityTypeId?: InputMaybe<Scalars['ID']>;
  readonly customerPaymentTermId?: InputMaybe<Scalars['ID']>;
  readonly customerStatusTypeId?: InputMaybe<Scalars['ID']>;
  readonly employeeId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly identifier?: InputMaybe<IdentifierQueryInputV2>;
  readonly insuranceCargo?: InputMaybe<Scalars['Int']>;
  readonly insuranceGeneral?: InputMaybe<Scalars['Int']>;
  readonly insuranceLiability?: InputMaybe<Scalars['Int']>;
  readonly invoiceDistributionMethodId?: InputMaybe<Scalars['ID']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly paymentMethodId?: InputMaybe<Scalars['ID']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly sort?: InputMaybe<Scalars['String']>;
  readonly text?: InputMaybe<Scalars['String']>;
  readonly updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly isMainRep?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerStructure = {
  readonly __typename?: 'CustomerStructure';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
};

export type CustomersWithInteractionFilterInputType = {
  readonly repId: Scalars['ID'];
};

export type CustomerV2 = {
  readonly __typename?: 'CustomerV2';
  readonly accountingNotes?: Maybe<ReadonlyArray<CustomerNote>>;
  readonly addresses?: Maybe<ReadonlyArray<CustomerAddress>>;
  readonly allNotes?: Maybe<ReadonlyArray<CustomerNote>>;
  readonly ancestorIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly billToCustomer?: Maybe<CustomerV2>;
  readonly billToCustomerId?: Maybe<Scalars['ID']>;
  readonly billingAddressId?: Maybe<Scalars['String']>;
  readonly billingAddress?: Maybe<CustomerAddress>;
  readonly billingEmail?: Maybe<Scalars['String']>;
  readonly children?: Maybe<ReadonlyArray<CustomerV2>>;
  readonly code: Scalars['String'];
  readonly contacts: ReadonlyArray<CustomerContact>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly creditHistories?: Maybe<ReadonlyArray<CreditHistoryV2>>;
  readonly creditLimit?: Maybe<Scalars['Float']>;
  readonly creditProviders?: Maybe<ReadonlyArray<CustomerCreditProviderV2>>;
  readonly creditStatus?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<KeyValue>;
  readonly currencyId?: Maybe<Scalars['ID']>;
  /** @deprecated Use credit status */
  readonly customerCreditStatus?: Maybe<KeyValue>;
  /** @deprecated Use credit status */
  readonly customerCreditStatusId?: Maybe<Scalars['ID']>;
  readonly customerLevelType?: Maybe<KeyValue>;
  readonly customerLevelTypeId?: Maybe<Scalars['ID']>;
  readonly customerOpportunityType?: Maybe<KeyValue>;
  readonly customerOpportunityTypeId?: Maybe<Scalars['ID']>;
  readonly customerPaymentTerm?: Maybe<KeyValue>;
  readonly customerPaymentTermId?: Maybe<Scalars['ID']>;
  readonly customerSalesProgressType?: Maybe<KeyValue>;
  readonly customerSalesProgressTypeId?: Maybe<Scalars['ID']>;
  readonly customerStatusType?: Maybe<KeyValue>;
  readonly customerStatusTypeId?: Maybe<Scalars['ID']>;
  readonly descendantIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly electronicTracking?: Maybe<Scalars['Boolean']>;
  readonly electronicTrackingNumber?: Maybe<Scalars['String']>;
  readonly electronicTrackingType?: Maybe<KeyValue>;
  readonly electronicTrackingTypeId?: Maybe<Scalars['ID']>;
  readonly groups?: Maybe<ReadonlyArray<CustomerGroupV2>>;
  readonly id: Scalars['ID'];
  readonly identifiers: ReadonlyArray<IdentifierV2>;
  readonly insuranceCargo: Scalars['Int'];
  readonly insuranceGeneral: Scalars['Int'];
  readonly insuranceLiability: Scalars['Int'];
  readonly invoiceDistributionMethod?: Maybe<KeyValue>;
  readonly invoiceDistributionMethodId?: Maybe<Scalars['ID']>;
  readonly invoiceGrouping?: Maybe<InvoiceGroupingEnum>;
  readonly mainAddressId?: Maybe<Scalars['String']>;
  readonly mainAddress?: Maybe<CustomerAddress>;
  readonly mainContactId?: Maybe<Scalars['String']>;
  readonly mainContact?: Maybe<CustomerContact>;
  readonly mainRep?: Maybe<CustomerRepV2>;
  readonly name: Scalars['String'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly parentCustomerId?: Maybe<Scalars['String']>;
  readonly parent?: Maybe<CustomerV2>;
  readonly paymentMethod?: Maybe<KeyValue>;
  readonly paymentMethodId?: Maybe<Scalars['ID']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly reps?: Maybe<ReadonlyArray<CustomerRepV2>>;
  readonly allReps?: Maybe<ReadonlyArray<CustomerRepV2>>;
  readonly roeStatus?: Maybe<Scalars['String']>;
  readonly settings?: Maybe<CustomerSettingV2>;
  readonly siblings?: Maybe<ReadonlyArray<CustomerV2>>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly useParentCredit?: Maybe<Scalars['Boolean']>;
  readonly website?: Maybe<Scalars['String']>;
  readonly facilityRelationships?: Maybe<ReadonlyArray<CustomerFacilityRelationshipV2>>;
  readonly customerStructures?: Maybe<ReadonlyArray<CustomerStructure>>;
  readonly noteDefaults?: Maybe<ReadonlyArray<NoteDefault>>;
  readonly customerFacilities?: Maybe<ReadonlyArray<CustomerFacility>>;
  readonly customerRegions?: Maybe<ReadonlyArray<CustomerRegion>>;
  readonly customerCommodities?: Maybe<ReadonlyArray<CustomerCommodity>>;
  readonly loadDefaults?: Maybe<LoadDefaults>;
  readonly customerCustomerRelationship?: Maybe<ReadonlyArray<CustomerCustomerRelationship>>;
  readonly availableCredit?: Maybe<Scalars['Float']>;
  readonly carrierRelationships?: Maybe<ReadonlyArray<CarrierCustomerRelationshipV2>>;
  readonly ratingSourceId?: Maybe<Scalars['String']>;
  readonly accountingStatus?: Maybe<Scalars['String']>;
  readonly accountsReceivableOverdue?: Maybe<Scalars['Decimal']>;
  readonly autoInvoiceEnabled?: Maybe<Scalars['Boolean']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Date']>;
  readonly isTaxExempt?: Maybe<Scalars['Boolean']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['Date']>;
};


export type CustomerV2FacilityRelationshipsArgs = {
  facilityRelationshipsLoaderWithLimit?: InputMaybe<Scalars['Int']>;
};


export type CustomerV2CustomerFacilitiesArgs = {
  facilityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type DailyContainersCountPayload = {
  readonly __typename?: 'DailyContainersCountPayload';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly counts: ReadonlyArray<Scalars['Float']>;
};

export type DailyGateReservationsCountPayload = {
  readonly __typename?: 'DailyGateReservationsCountPayload';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly counts: ReadonlyArray<Scalars['Float']>;
};

export type DailyTruckCountPayload = {
  readonly __typename?: 'DailyTruckCountPayload';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly counts: ReadonlyArray<Scalars['Float']>;
};

export enum DataType {
  Boolean = 'Boolean',
  Date = 'Date',
  Ddt = 'Ddt',
  MultiSelect = 'MultiSelect',
  Number = 'Number',
  Rule = 'Rule',
  String = 'String'
}

export type DataValue = {
  readonly __typename?: 'DataValue';
  readonly stringValue?: Maybe<Scalars['String']>;
  readonly floatValue?: Maybe<Scalars['Float']>;
  readonly booleanValue?: Maybe<Scalars['Boolean']>;
};

/** Only one of these values is expected to be set. The existence of a value will be checked in the following order: stringValue, floatValue, booleanValue */
export type DataValueInput = {
  readonly stringValue?: InputMaybe<Scalars['String']>;
  readonly floatValue?: InputMaybe<Scalars['Float']>;
  readonly booleanValue?: InputMaybe<Scalars['Boolean']>;
};

export type DatContractAddressInputType = {
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly stateOrProvince: Scalars['String'];
};

/** Holds DAT rates origin and destination info. */
export type DatContractAddressType = {
  readonly __typename?: 'DatContractAddressType';
  readonly areaName?: Maybe<Scalars['String']>;
  readonly areaType?: Maybe<Scalars['String']>;
};

/** Holds DAT rates escalation info. */
export type DatContractEscalationType = {
  readonly __typename?: 'DatContractEscalationType';
  readonly destination?: Maybe<DatContractAddressType>;
  readonly origin?: Maybe<DatContractAddressType>;
  readonly timeFrame?: Maybe<Scalars['String']>;
};

export type DatContractGeographyInput = {
  readonly destination: DatContractAddressInputType;
  readonly origin: DatContractAddressInputType;
};

/** Holds DAT contract rates info. */
export type DatContractRatesConnection = {
  readonly __typename?: 'DatContractRatesConnection';
  readonly code?: Maybe<Scalars['String']>;
  readonly edges?: Maybe<ReadonlyArray<Maybe<DatContractRatesEdge>>>;
  readonly message?: Maybe<Scalars['String']>;
};

/** Holds DAT rates edge info. */
export type DatContractRatesEdge = {
  readonly __typename?: 'DatContractRatesEdge';
  readonly node?: Maybe<DatContractResponseType>;
};

export type DatContractRatesFilterType = {
  readonly equipments?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly geography?: InputMaybe<DatContractGeographyInput>;
  readonly isForceRefresh: Scalars['Boolean'];
  readonly routes?: InputMaybe<ReadonlyArray<InputMaybe<DatContractRatesRouteInput>>>;
};

export type DatContractRatesRouteInput = {
  readonly id: Scalars['Uuid'];
};

/** Holds DAT contract rates info. */
export type DatContractRatesType = {
  readonly __typename?: 'DatContractRatesType';
  readonly avgFuelSurchargePerMileUSD?: Maybe<Scalars['Decimal']>;
  readonly avgFuelSurchargePerTripUSD?: Maybe<Scalars['Decimal']>;
  readonly companies?: Maybe<Scalars['Int']>;
  readonly high?: Maybe<DatContractRateType>;
  readonly low?: Maybe<DatContractRateType>;
  readonly mileage?: Maybe<Scalars['Decimal']>;
  readonly rate?: Maybe<DatContractRateType>;
  readonly reports?: Maybe<Scalars['Decimal']>;
  readonly standardDeviation?: Maybe<Scalars['Decimal']>;
};

/** Holds DAT rate data info. */
export type DatContractRateType = {
  readonly __typename?: 'DatContractRateType';
  readonly perMile?: Maybe<Scalars['Decimal']>;
  readonly perTrip?: Maybe<Scalars['Decimal']>;
};

/** Holds DAT rates response. */
export type DatContractResponseType = {
  readonly __typename?: 'DatContractResponseType';
  readonly escalation?: Maybe<DatContractEscalationType>;
  readonly rate?: Maybe<DatContractRatesType>;
  readonly routeId?: Maybe<Scalars['Uuid']>;
};

/** Semantic descriptor for procurement-api Date filter fields e.g. `OnOrAfterDate` */
export enum DateBoundaryBehavior {
  OnOrAfterDate = 'OnOrAfterDate',
  OnOrBeforeDate = 'OnOrBeforeDate',
  MatchDate = 'MatchDate'
}

export type DatedPathEntryInput = {
  readonly datetime: Scalars['String'];
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export type DateRange = {
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
};

export type DateRangeFilter = {
  readonly endDate?: InputMaybe<Scalars['Iso8601Date']>;
  readonly startDate?: InputMaybe<Scalars['Iso8601Date']>;
};

export type DateRangeInput = {
  readonly first?: InputMaybe<Scalars['Timestamp']>;
  readonly last?: InputMaybe<Scalars['Timestamp']>;
};

export type DateTimeEntryInput = {
  readonly startDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly endDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeEntryOutput = {
  readonly __typename?: 'DateTimeEntryOutput';
  readonly startDateTime?: Maybe<Scalars['DateTime']>;
  readonly endDateTime?: Maybe<Scalars['DateTime']>;
};

export type DateTimeRangeWithTimeZone = {
  readonly __typename?: 'DateTimeRangeWithTimeZone';
  readonly startDateTime?: Maybe<Scalars['DateTime']>;
  readonly endDateTime?: Maybe<Scalars['DateTime']>;
  readonly timeZone?: Maybe<Scalars['String']>;
};

export type DatetimeWithTimezone = {
  readonly __typename?: 'DatetimeWithTimezone';
  readonly timezone: Scalars['String'];
  readonly value: Scalars['ISO8601DateTime'];
};

export type DatetimeWithTimezoneInput = {
  readonly timezone: Scalars['String'];
  readonly value: Scalars['ISO8601DateTime'];
};

export type DatSpotAddressInputType = {
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly stateOrProvince: Scalars['String'];
};

/** Holds DAT rates origin and destination info. */
export type DatSpotAddressType = {
  readonly __typename?: 'DatSpotAddressType';
  readonly areaName?: Maybe<Scalars['String']>;
  readonly areaType?: Maybe<Scalars['String']>;
};

/** Holds DAT rates escalation info. */
export type DatSpotEscalationType = {
  readonly __typename?: 'DatSpotEscalationType';
  readonly destination?: Maybe<DatSpotAddressType>;
  readonly origin?: Maybe<DatSpotAddressType>;
  readonly timeFrame?: Maybe<Scalars['String']>;
};

export type DatSpotGeographyInput = {
  readonly destination: DatSpotAddressInputType;
  readonly origin: DatSpotAddressInputType;
};

/** Holds DAT spot rates. */
export type DatSpotRatesConnection = {
  readonly __typename?: 'DatSpotRatesConnection';
  readonly code?: Maybe<Scalars['String']>;
  readonly edges?: Maybe<ReadonlyArray<Maybe<DatSpotRatesEdge>>>;
  readonly message?: Maybe<Scalars['String']>;
};

/** Holds DAT spot rates edge Info. */
export type DatSpotRatesEdge = {
  readonly __typename?: 'DatSpotRatesEdge';
  readonly node?: Maybe<DatSpotResponseType>;
};

export type DatSpotRatesFilterType = {
  readonly equipments?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly geography?: InputMaybe<DatSpotGeographyInput>;
  readonly isForceRefresh?: InputMaybe<Scalars['Boolean']>;
  readonly routes?: InputMaybe<ReadonlyArray<InputMaybe<DatSpotRatesRouteInput>>>;
};

export type DatSpotRatesRouteInput = {
  readonly id: Scalars['Uuid'];
};

/** Holds DAT spot rates info. */
export type DatSpotRatesType = {
  readonly __typename?: 'DatSpotRatesType';
  readonly avgFuelSurchargePerMileUSD?: Maybe<Scalars['Decimal']>;
  readonly avgFuelSurchargePerTripUSD?: Maybe<Scalars['Decimal']>;
  readonly companies?: Maybe<Scalars['Int']>;
  readonly high?: Maybe<DatSpotRateType>;
  readonly low?: Maybe<DatSpotRateType>;
  readonly mileage?: Maybe<Scalars['Decimal']>;
  readonly rate?: Maybe<DatSpotRateType>;
  readonly reports?: Maybe<Scalars['Decimal']>;
  readonly standardDeviation?: Maybe<Scalars['Decimal']>;
};

/** Holds DAT sopt rate data info. */
export type DatSpotRateType = {
  readonly __typename?: 'DatSpotRateType';
  readonly perMile?: Maybe<Scalars['Decimal']>;
  readonly perTrip?: Maybe<Scalars['Decimal']>;
};

/** Holds DAT spot rates response. */
export type DatSpotResponseType = {
  readonly __typename?: 'DatSpotResponseType';
  readonly escalation?: Maybe<DatSpotEscalationType>;
  readonly rate?: Maybe<DatSpotRatesType>;
  readonly ratesReceivedAt?: Maybe<Scalars['DateTime']>;
  readonly routeId?: Maybe<Scalars['Uuid']>;
};

export enum DayEnum {
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday'
}

/** Deprecated - Use DayEnum */
export enum DayOfWeekEnum {
  /** @deprecated Use DayEnum */
  Sunday = 'SUNDAY',
  /** @deprecated Use DayEnum */
  Monday = 'MONDAY',
  /** @deprecated Use DayEnum */
  Tuesday = 'TUESDAY',
  /** @deprecated Use DayEnum */
  Wednesday = 'WEDNESDAY',
  /** @deprecated Use DayEnum */
  Thursday = 'THURSDAY',
  /** @deprecated Use DayEnum */
  Friday = 'FRIDAY',
  /** @deprecated Use DayEnum */
  Saturday = 'SATURDAY'
}

export type DeactivateCustomerLaneInput = {
  readonly customerId: Scalars['ID'];
  readonly laneId: Scalars['ID'];
  readonly updatedById: Scalars['ID'];
};

export type DeactivateCustomerLaneResponse = {
  readonly __typename?: 'DeactivateCustomerLaneResponse';
  readonly isSuccess: Scalars['Boolean'];
};

export type DecimalRange = {
  readonly from?: InputMaybe<Scalars['Decimal']>;
  readonly to?: InputMaybe<Scalars['Decimal']>;
};

export type DeclineOfferResponseInput = {
  readonly offerId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly counterPrice?: InputMaybe<CurrencyInput>;
  readonly counterPriceV2?: InputMaybe<CurrencyInputV2>;
  /** Will be overwritten with OfferResponseType.DECLINE */
  readonly type?: InputMaybe<OfferResponseType>;
};

export type DefaultConfigurationResult = {
  readonly __typename?: 'DefaultConfigurationResult';
  readonly domain: EntityConfigurationDomain;
  readonly settings: EntityConfigurationSettings;
  readonly entityType: EntityConfigurationEntityType;
  readonly lastUpdatedTimestamp?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
};

export enum DefaultVoucherDateOptionEnum {
  Todaysdate = 'TODAYSDATE',
  Datereceived = 'DATERECEIVED',
  Vendorinvoicedate = 'VENDORINVOICEDATE'
}

export type DeleteAvailableRoutesByFilterResult = {
  readonly __typename?: 'DeleteAvailableRoutesByFilterResult';
  readonly routeIds: ReadonlyArray<Scalars['String']>;
};

export type DeleteCarrierCrmDocumentInput = {
  readonly id: Scalars['ID'];
  readonly updatedBy: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
};

export type DeleteCarrierDocumentPayload = {
  readonly __typename?: 'DeleteCarrierDocumentPayload';
  readonly carrierDocument?: Maybe<CarrierDocument>;
};

export type DeleteContainerByRouteIdInput = {
  readonly previousRouteId: Scalars['ID'];
  readonly updatedByUserId: Scalars['ID'];
};

export type DeleteCustomerCrmDocumentInput = {
  readonly customerId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly updatedBy: Scalars['ID'];
};

export type DeleteCustomerDocumentPayload = {
  readonly __typename?: 'DeleteCustomerDocumentPayload';
  readonly customerDocument?: Maybe<Document>;
};

export type DeleteDriverAssignmentInput = {
  readonly userID?: InputMaybe<Scalars['ID']>;
};

export type DeleteFacilityDocumentPayload = {
  readonly __typename?: 'DeleteFacilityDocumentPayload';
  readonly facilityDocument?: Maybe<FacilityDocument>;
};

export type DeleteFilterByIdInputType = {
  readonly filterId: Scalars['String'];
  readonly objectId: Scalars['String'];
};

export type DeleteFilterResponseType = {
  readonly __typename?: 'DeleteFilterResponseType';
  readonly status: Scalars['String'];
  readonly errors: ReadonlyArray<Scalars['JSON']>;
};

export type DeleteHaykRedirectInput = {
  readonly redirectId: Scalars['String'];
};

export type DeleteOrderStopInput = {
  readonly orderId: Scalars['ID'];
  readonly orderStopId: Scalars['ID'];
};

export type DeletePatternInput = {
  readonly patternId: Scalars['ID'];
  readonly userId: Scalars['ID'];
};

export type DeletePatternPayload = {
  readonly __typename?: 'DeletePatternPayload';
  readonly message?: Maybe<Scalars['String']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly currentLanePatterns?: Maybe<ReadonlyArray<Maybe<Pattern>>>;
};

export type DeletePatternPayloadV2 = {
  readonly __typename?: 'DeletePatternPayloadV2';
  readonly data?: Maybe<DeletePatternPayload>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type DeleteRouteInput = {
  readonly routeId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
};

export type DeleteRouteStopInput = {
  readonly routeId: Scalars['String'];
  readonly routeStopId: Scalars['String'];
};

export type DeleteStopInput = {
  readonly routeStopId: Scalars['String'];
};

export type DeleteTruckEntryTemplateInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
};

export type Density = {
  readonly __typename?: 'Density';
  readonly unit: DensityUnitTypeEnum;
  readonly value: Scalars['Float'];
};

export enum DensityUnitTypeEnum {
  Lbft3 = 'lbft3',
  Kgm3 = 'kgm3'
}

export type DensityV2 = {
  readonly __typename?: 'DensityV2';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

/** Autogenerated return type of DestroyAddress */
export type DestroyAddressPayload = {
  readonly __typename?: 'DestroyAddressPayload';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of DestroyAddress */
export type DestroyCarrierAddressPayload = {
  readonly __typename?: 'DestroyCarrierAddressPayload';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of DestroyCarrierCertification */
export type DestroyCarrierCertificationPayload = {
  readonly __typename?: 'DestroyCarrierCertificationPayload';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of DestroyCarrierCertificationV2 */
export type DestroyCarrierCertificationPayloadV2 = {
  readonly __typename?: 'DestroyCarrierCertificationPayloadV2';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of DestroyContact */
export type DestroyCarrierContactPayload = {
  readonly __typename?: 'DestroyCarrierContactPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyCarrierIdentifierPayload = {
  readonly __typename?: 'DestroyCarrierIdentifierPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyCarrierReleaseMethodPayload = {
  readonly __typename?: 'DestroyCarrierReleaseMethodPayload';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of DestroyCarrierReleaseMethodV1 */
export type DestroyCarrierReleaseMethodV1Payload = {
  readonly __typename?: 'DestroyCarrierReleaseMethodV1Payload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyCarrierRepPayload = {
  readonly __typename?: 'DestroyCarrierRepPayload';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of DestroyCarrierService */
export type DestroyCarrierServicePayload = {
  readonly __typename?: 'DestroyCarrierServicePayload';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of DestroyCarrierService */
export type DestroyCarrierServicePayloadV2 = {
  readonly __typename?: 'DestroyCarrierServicePayloadV2';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of DestroyContact */
export type DestroyContactPayload = {
  readonly __typename?: 'DestroyContactPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyCustomerAddressPayload = {
  readonly __typename?: 'DestroyCustomerAddressPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyCustomerContactPayload = {
  readonly __typename?: 'DestroyCustomerContactPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyCustomerIdentifierPayload = {
  readonly __typename?: 'DestroyCustomerIdentifierPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyCustomerRepPayload = {
  readonly __typename?: 'DestroyCustomerRepPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyFacilityAddressPayload = {
  readonly __typename?: 'DestroyFacilityAddressPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyFacilityContactPayload = {
  readonly __typename?: 'DestroyFacilityContactPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyFacilityDirectionPayload = {
  readonly __typename?: 'DestroyFacilityDirectionPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyFacilityHolidayPayload = {
  readonly __typename?: 'DestroyFacilityHolidayPayload';
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of DestroyFacilityIdentifier */
export type DestroyFacilityIdentifierPayload = {
  readonly __typename?: 'DestroyFacilityIdentifierPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyFacilityIdentifierPayloadV2 = {
  readonly __typename?: 'DestroyFacilityIdentifierPayloadV2';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyFacilitySchedulePayload = {
  readonly __typename?: 'DestroyFacilitySchedulePayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DestroyFacilitySublocationAccommodationPayload = {
  readonly __typename?: 'DestroyFacilitySublocationAccommodationPayload';
  readonly errors: ReadonlyArray<SublocationUserError>;
};

export type DestroyFacilitySublocationActivityPayload = {
  readonly __typename?: 'DestroyFacilitySublocationActivityPayload';
  readonly errors: ReadonlyArray<SublocationUserError>;
};

export type DestroyFacilitySublocationGatePayload = {
  readonly __typename?: 'DestroyFacilitySublocationGatePayload';
  readonly errors: ReadonlyArray<SublocationUserError>;
};

export type DestroyFacilitySublocationPayload = {
  readonly __typename?: 'DestroyFacilitySublocationPayload';
  readonly errors: ReadonlyArray<SublocationUserError>;
};

/** Autogenerated return type of DestroyTruckPosting */
export type DestroyTruckPostingPayload = {
  readonly __typename?: 'DestroyTruckPostingPayload';
  readonly errors: ReadonlyArray<UserError>;
};

export type DetailChargeTypeConfigurationInput = {
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['Guid']>;
  readonly carrierId?: InputMaybe<Scalars['Guid']>;
};

export type Dimensions = {
  readonly __typename?: 'Dimensions';
  readonly length: Length;
  readonly width: Length;
  readonly height: Length;
};

export type DimensionsInput = {
  readonly height?: InputMaybe<UnitOfLengthInput>;
  readonly length?: InputMaybe<UnitOfLengthInput>;
  readonly width?: InputMaybe<UnitOfLengthInput>;
};

export type DimensionsInputV2 = {
  readonly height?: InputMaybe<UnitOfLengthInputV2>;
  readonly length?: InputMaybe<UnitOfLengthInputV2>;
  readonly width?: InputMaybe<UnitOfLengthInputV2>;
};

export type DimensionsV2 = {
  readonly __typename?: 'DimensionsV2';
  readonly height: Length;
  readonly length: Length;
  readonly width: Length;
};


export type DimensionsV2HeightArgs = {
  unit?: InputMaybe<UnitOfLengthEnum>;
};


export type DimensionsV2LengthArgs = {
  unit?: InputMaybe<UnitOfLengthEnum>;
};


export type DimensionsV2WidthArgs = {
  unit?: InputMaybe<UnitOfLengthEnum>;
};

export type DistanceRequest = {
  readonly stops: ReadonlyArray<DistanceRequestStop>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
};

export type DistanceRequestStop = {
  readonly latitude?: InputMaybe<Scalars['Float']>;
  readonly longitude?: InputMaybe<Scalars['Float']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly locality?: InputMaybe<Scalars['String']>;
  readonly administrativeArea?: InputMaybe<Scalars['String']>;
  readonly countryCode?: InputMaybe<Scalars['String']>;
};

/** The preferred unit to use when calculating distance, if supported */
export enum DistanceSearchPreferenceOption {
  Postal = 'postal',
  Geopoint = 'geopoint',
  Locality = 'locality'
}

export enum DistanceUnitEnum {
  Feet = 'feet',
  Inches = 'inches',
  Kilometers = 'kilometers',
  Meters = 'meters',
  Miles = 'miles'
}

export type DistinctCarrierIdsPayloadsWithErrors = {
  readonly __typename?: 'DistinctCarrierIdsPayloadsWithErrors';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly distinctCarrierIds?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type DivisionR2 = {
  readonly __typename?: 'DivisionR2';
  readonly id?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
};

export type Document = {
  readonly __typename?: 'Document';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  /** The name of the file to be displayed to the user, not necessarily the filename */
  readonly name: Scalars['String'];
  /** The name of the file provided during upload */
  readonly filename: Scalars['String'];
  readonly uploadedBy: DocumentUserMeta;
  readonly uploadDate: Scalars['DateTime'];
  readonly sourceId: Scalars['ID'];
  readonly departmentTypeId: Scalars['ID'];
  readonly documentTypeId: Scalars['ID'];
  /** uri to access the customer document */
  readonly uri: Scalars['String'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly uploadedByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly customerV2: CustomerV2;
};

/**
 * temporary type for including user id and name information - will be deprecated
 * once we can query across the graph in federation
 */
export type DocumentUserMeta = {
  readonly __typename?: 'DocumentUserMeta';
  /** note - this is the user_id, NOT the employee_id */
  readonly userId: Scalars['ID'];
  readonly displayName: Scalars['String'];
};

/** lachesis-api: Status Object */
export type DomainStatusRecord = {
  readonly __typename?: 'DomainStatusRecord';
  /** lachesis-api: status label associated with domain status. */
  readonly status_label: Scalars['String'];
  /** lachesis-api: status value associated with domain status value. */
  readonly status_value: Scalars['Int'];
};

/** type for driver information */
export type Driver = {
  readonly __typename?: 'Driver';
  readonly displayName?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
};

export type DriverAddress = {
  readonly __typename?: 'DriverAddress';
  readonly id: Scalars['ID'];
  readonly addressTypeId: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
  readonly main?: Maybe<Scalars['Boolean']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverAddressInput = {
  readonly addressTypeId: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverAddressInputV2 = {
  readonly typeTerm: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
  readonly isMain?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
};

export type DriverAddressV2 = {
  readonly __typename?: 'DriverAddressV2';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: Maybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
  readonly isMain?: Maybe<Scalars['Boolean']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverAssignedTrailers = {
  readonly __typename?: 'DriverAssignedTrailers';
  readonly id: Scalars['ID'];
  readonly assignedTrailerNumber: Scalars['String'];
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverAssignedTrailersInput = {
  readonly assignedTrailerNumber: Scalars['String'];
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverAssignment = {
  readonly __typename?: 'DriverAssignment';
  readonly id: Scalars['ID'];
  readonly routeID: Scalars['ID'];
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly equipmentID?: Maybe<Scalars['ID']>;
  readonly previousRouteID?: Maybe<Scalars['ID']>;
  readonly nextRouteID?: Maybe<Scalars['ID']>;
  readonly trackingSystem?: Maybe<TrackingSystem>;
  readonly trackingEnabled: Scalars['Boolean'];
  readonly trackingEnabledBy?: Maybe<TrackingUserMeta>;
  readonly driver1?: Maybe<Driver>;
  readonly driver2?: Maybe<Driver>;
  readonly eldVehicleID?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly trailerIdentifier?: Maybe<Scalars['String']>;
  readonly tractorIdentifier?: Maybe<Scalars['String']>;
  readonly trailerLocationFacility?: Maybe<Facility>;
  readonly trailerLocationFacilityV2?: Maybe<FacilityV2>;
  readonly trailerLocation?: Maybe<Location>;
  readonly emptyLocation?: Maybe<Location>;
  readonly emptyDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly emptyTimezone?: Maybe<Scalars['String']>;
  readonly etaDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly etaTimezone?: Maybe<Scalars['String']>;
  /** @deprecated Removed in Rails decommission -- use etaCreatedByV2 */
  readonly etaCreatedBy?: Maybe<User>;
  readonly etaCreatedByV2?: Maybe<UserV2>;
  readonly checkCallDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly trailerLength?: Maybe<Scalars['Float']>;
  readonly trailerWidth?: Maybe<Scalars['Float']>;
  readonly trailerHeight?: Maybe<Scalars['Float']>;
  readonly reloadIntent?: Maybe<KeyValue>;
  readonly dispatched: Scalars['Boolean'];
  readonly dispatchedBy?: Maybe<TrackingUserMeta>;
  readonly trackingStatusMessage?: Maybe<Scalars['String']>;
  /** @deprecated Removed in Rails decommission -- use createdByV2 */
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  /** @deprecated Removed in Rails decommission -- use updatedByV2 */
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly externalInfo?: Maybe<DriverAssignmentExternalInfo>;
  /** The carrier who owns the truck, if any */
  readonly owner?: Maybe<Carrier>;
  /** The carrier who owns the truck, if any */
  readonly ownerV2?: Maybe<CarrierV2>;
  readonly hasTrackingRequestFailed?: Maybe<Scalars['Boolean']>;
  /** Whether the ETA, if Auto, was calculated using the Data Science formula */
  readonly isETADataScienceFormula?: Maybe<Scalars['Boolean']>;
};

/** info received from external source on driver assignment create/update */
export type DriverAssignmentExternalInfo = {
  readonly __typename?: 'DriverAssignmentExternalInfo';
  readonly sourceSystem?: Maybe<Scalars['String']>;
  readonly dispatcherName?: Maybe<Scalars['String']>;
  readonly dispatcherPhone?: Maybe<Scalars['String']>;
  readonly dispatcherEmail?: Maybe<Scalars['String']>;
};

/** input for external info on driver assignment create/update */
export type DriverAssignmentExternalInfoInput = {
  readonly sourceSystem?: InputMaybe<Scalars['String']>;
  readonly dispatcherName?: InputMaybe<Scalars['String']>;
  readonly dispatcherPhone?: InputMaybe<Scalars['String']>;
  readonly dispatcherEmail?: InputMaybe<Scalars['String']>;
};

export type DriverAssignmentInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly userID?: InputMaybe<Scalars['ID']>;
  readonly routeID: Scalars['ID'];
  /** DEPRECATED - DO NOT USE, VALUE IS THROWN AWAY */
  readonly carrierID?: InputMaybe<Scalars['ID']>;
  readonly loadID?: InputMaybe<Scalars['ID']>;
  readonly facilityID?: InputMaybe<Scalars['ID']>;
  readonly equipmentID?: InputMaybe<Scalars['ID']>;
  /** DEPRECATED - DO NOT USE, VALUE IS THROWN AWAY */
  readonly previousRouteID?: InputMaybe<Scalars['ID']>;
  /** DEPRECATED - DO NOT USE, VALUE IS THROWN AWAY */
  readonly nextRouteID?: InputMaybe<Scalars['ID']>;
  readonly trackingSystemID?: InputMaybe<Scalars['ID']>;
  readonly trackingEnabled: Scalars['Boolean'];
  /** temporary field until we can resolve user info from the gateway context */
  readonly trackingEnabledBy?: InputMaybe<TrackingUserMetaInput>;
  readonly driver1?: InputMaybe<DriverInput>;
  readonly driver2?: InputMaybe<DriverInput>;
  readonly eldVehicleID?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly trailerIdentifier?: InputMaybe<Scalars['String']>;
  readonly tractorIdentifier?: InputMaybe<Scalars['String']>;
  readonly trailerLocation?: InputMaybe<LocationInput>;
  readonly emptyLocation?: InputMaybe<LocationInput>;
  readonly emptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly emptyTimezone?: InputMaybe<Scalars['String']>;
  readonly etaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly etaTimezone?: InputMaybe<Scalars['String']>;
  readonly checkCallDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly trailerLength?: InputMaybe<Scalars['Float']>;
  readonly trailerWidth?: InputMaybe<Scalars['Float']>;
  readonly trailerHeight?: InputMaybe<Scalars['Float']>;
  readonly reloadIntentID?: InputMaybe<Scalars['String']>;
  readonly dispatched: Scalars['Boolean'];
  /** temporary field until we can resolve user info from the gateway context */
  readonly dispatchedBy?: InputMaybe<TrackingUserMetaInput>;
  readonly externalInfo?: InputMaybe<DriverAssignmentExternalInfoInput>;
  /** ID of the Carrier who owns the truck, if any */
  readonly ownerID?: InputMaybe<Scalars['String']>;
};

export type DriverAward = {
  readonly __typename?: 'DriverAward';
  readonly id: Scalars['ID'];
  /** @deprecated awardType is deprecated. Use typeTerm instead. */
  readonly awardType: Scalars['String'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly awardDate?: Maybe<Scalars['DateTime']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverAwardInput = {
  readonly awardType?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly awardDate?: InputMaybe<Scalars['DateTime']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverAwardInputV2 = {
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly awardDate: Scalars['Date'];
  readonly driverId?: InputMaybe<Scalars['String']>;
};

export type DriverAwardV2 = {
  readonly __typename?: 'DriverAwardV2';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly awardDate: Scalars['Date'];
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverCareerGoalsPreferences = {
  readonly __typename?: 'DriverCareerGoalsPreferences';
  readonly id: Scalars['ID'];
  readonly position?: Maybe<Scalars['String']>;
  readonly fleet?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly businessUnit?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly reason?: Maybe<Scalars['String']>;
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverCareerGoalsPreferencesInput = {
  readonly position?: InputMaybe<Scalars['String']>;
  readonly fleet?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly customer?: InputMaybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly reason?: InputMaybe<Scalars['String']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverCertificationOrPermitInputV3 = {
  readonly typeTerm: Scalars['String'];
  readonly certificationOrPermitId: Scalars['String'];
  readonly expirationDate: Scalars['Date'];
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
};

export type DriverCertificationOrPermitV3 = {
  readonly __typename?: 'DriverCertificationOrPermitV3';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly certificationOrPermitId: Scalars['String'];
  readonly expirationDate: Scalars['Date'];
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverCertificationsOrPermits = {
  readonly __typename?: 'DriverCertificationsOrPermits';
  readonly id: Scalars['ID'];
  /** @deprecated certificationOrPermitType is deprecated. Use typeTerm instead. */
  readonly certificationOrPermitType: Scalars['String'];
  /** @deprecated certificationOrPermitId is deprecated. Use value instead. */
  readonly certificationOrPermitId?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  /** @deprecated countryCd is deprecated. Use country instead. */
  readonly countryCd?: Maybe<Scalars['String']>;
  /** @deprecated expirationDt is deprecated. Use expirationDate instead. */
  readonly expirationDt: Scalars['DateTime'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverCertificationsOrPermitsInput = {
  readonly certificationOrPermitType: Scalars['String'];
  readonly certificationOrPermitId: Scalars['String'];
  readonly state?: InputMaybe<Scalars['String']>;
  readonly countryCd?: InputMaybe<Scalars['String']>;
  readonly expirationDt: Scalars['DateTime'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverCertificationsOrPermitsInputV2 = {
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverCertificationsOrPermitsV2 = {
  readonly __typename?: 'DriverCertificationsOrPermitsV2';
  readonly id: Scalars['ID'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverCommodityPreferences = {
  readonly __typename?: 'DriverCommodityPreferences';
  readonly id: Scalars['ID'];
  readonly commodity?: Maybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly reason: Scalars['String'];
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverCommodityPreferencesInput = {
  readonly commodity?: InputMaybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly reason: Scalars['String'];
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverContact = {
  readonly __typename?: 'DriverContact';
  readonly id: Scalars['ID'];
  readonly contactTypeId: Scalars['String'];
  readonly name: Scalars['String'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly extension?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly emailAddress?: Maybe<Scalars['String']>;
  readonly chatTypeId?: Maybe<Scalars['String']>;
  readonly chatUsername?: Maybe<Scalars['String']>;
  readonly main?: Maybe<Scalars['Boolean']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverContactInput = {
  readonly contactTypeId: Scalars['String'];
  readonly name: Scalars['String'];
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly chatTypeId?: InputMaybe<Scalars['String']>;
  readonly chatUsername?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverContactInputV2 = {
  readonly typeTerm: Scalars['String'];
  readonly name: Scalars['String'];
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly imServiceTerm?: InputMaybe<Scalars['String']>;
  readonly imUserName?: InputMaybe<Scalars['String']>;
  readonly isMain?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
};

export type DriverContactV2 = {
  readonly __typename?: 'DriverContactV2';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly name: Scalars['String'];
  readonly emailAddress?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly extension?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly imServiceTerm?: Maybe<Scalars['String']>;
  readonly imUserName?: Maybe<Scalars['String']>;
  readonly isMain?: Maybe<Scalars['Boolean']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverCustomerPreferences = {
  readonly __typename?: 'DriverCustomerPreferences';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly reason: Scalars['String'];
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly preferenceTerm?: Maybe<Scalars['String']>;
};

export type DriverCustomerPreferencesInput = {
  readonly name: Scalars['String'];
  readonly reason: Scalars['String'];
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<Scalars['String']>;
  readonly preferenceTerm?: InputMaybe<Scalars['String']>;
};

export type DriverDailyActivity = {
  readonly __typename?: 'DriverDailyActivity';
  readonly id: Scalars['ID'];
  /** This field describes the day of the week on which the driver will work */
  readonly weekDay: Scalars['String'];
  /** This field describes the number of hours a driver will work. This can be a decimal value. */
  readonly hours: Scalars['Float'];
  /** This field describes the miles driver covered with the empty truck. This can be a decimal value. */
  readonly emptyMiles?: Maybe<Scalars['Float']>;
  /** This field describes the miles driver covered with the loaded truck. This can be a decimal value. */
  readonly loadedMiles?: Maybe<Scalars['Float']>;
  /** This field describes the total miles (MT + LD) driver covered. This can be a decimal value. */
  readonly totalMiles?: Maybe<Scalars['Float']>;
  /** This field describes the actual revenue earned by the driver. This can be a decimal value. */
  readonly revenue?: Maybe<Scalars['Float']>;
  readonly weeklyTargetId?: Maybe<Scalars['ID']>;
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverEquipment = {
  readonly __typename?: 'DriverEquipment';
  readonly id: Scalars['ID'];
  /** @deprecated equipmentCd is deprecated. Use typeTerm instead. */
  readonly equipmentCd: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count?: Maybe<Scalars['Float']>;
  readonly assetId?: Maybe<Scalars['String']>;
  /** @deprecated issueDt is deprecated. Use issueDate instead. */
  readonly issueDt?: Maybe<Scalars['DateTime']>;
  /** @deprecated returnDt is deprecated. Use returnDate instead. */
  readonly returnDt?: Maybe<Scalars['DateTime']>;
  /** @deprecated condition is deprecated. Use conditionTerm instead. */
  readonly condition?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverEquipmentInput = {
  readonly equipmentCd: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Float'];
  readonly assetId?: InputMaybe<Scalars['String']>;
  readonly issueDt: Scalars['DateTime'];
  readonly returnDt?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly condition?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverEquipmentInputV2 = {
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly count?: InputMaybe<Scalars['Float']>;
  readonly assetId?: InputMaybe<Scalars['String']>;
  readonly issueDate?: InputMaybe<Scalars['DateTime']>;
  readonly returnDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly conditionTerm?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverEquipmentInputV3 = {
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId: Scalars['Int'];
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['Date'];
  readonly recoveredDate?: InputMaybe<Scalars['Date']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
};

export type DriverEquipmentV2 = {
  readonly __typename?: 'DriverEquipmentV2';
  readonly id: Scalars['ID'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly count?: Maybe<Scalars['Float']>;
  readonly assetId?: Maybe<Scalars['String']>;
  readonly issueDate?: Maybe<Scalars['DateTime']>;
  readonly returnDate?: Maybe<Scalars['DateTime']>;
  readonly conditionTerm?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverEquipmentV3 = {
  readonly __typename?: 'DriverEquipmentV3';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly description: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly assetId: Scalars['Int'];
  readonly conditionTerm: Scalars['String'];
  readonly issueDate: Scalars['Date'];
  readonly recoveredDate?: Maybe<Scalars['Date']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverFacilityPreferences = {
  readonly __typename?: 'DriverFacilityPreferences';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly reason: Scalars['String'];
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverFacilityPreferencesInput = {
  readonly name: Scalars['String'];
  readonly type?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly reason: Scalars['String'];
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverGeographyPreferences = {
  readonly __typename?: 'DriverGeographyPreferences';
  readonly id: Scalars['ID'];
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly direction?: Maybe<Scalars['String']>;
  readonly reason?: Maybe<Scalars['String']>;
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverGeographyPreferencesInput = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly direction?: InputMaybe<Scalars['String']>;
  readonly reason?: InputMaybe<Scalars['String']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverIdentifierInputV2 = {
  readonly typeTerm: Scalars['String'];
  readonly identifierId: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
};

export type DriverIdentifiers = {
  readonly __typename?: 'DriverIdentifiers';
  readonly id: Scalars['ID'];
  /** @deprecated identifierType is deprecated. Use typeTerm instead. */
  readonly identifierType: Scalars['String'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  /** @deprecated identifierId is deprecated. Use value instead. */
  readonly identifierId: Scalars['String'];
  readonly value?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverIdentifiersInput = {
  readonly identifierType?: InputMaybe<Scalars['String']>;
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly identifierId?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverIdentifierV2 = {
  readonly __typename?: 'DriverIdentifierV2';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly identifierId: Scalars['String'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

/** type for driver information */
export type DriverInput = {
  readonly displayName?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
};

export type DriverKeyValuePairOfString = {
  readonly __typename?: 'DriverKeyValuePairOfString';
  readonly key?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
};

export type DriverLocationFrequency = {
  readonly __typename?: 'DriverLocationFrequency';
  readonly id: Scalars['ID'];
  readonly frequencyType: Scalars['String'];
  readonly frequencyValue: Scalars['String'];
};

export type DriverLocationFrequencyInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly customerCode: Scalars['String'];
  readonly frequencyValue: Scalars['String'];
  readonly frequencyType: Scalars['String'];
};

export type DriverLocationOutput = {
  readonly __typename?: 'DriverLocationOutput';
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly dateTime: Scalars['DateTime'];
};

export type DriverOperationalPreferences = {
  readonly __typename?: 'DriverOperationalPreferences';
  readonly id: Scalars['ID'];
  readonly type?: Maybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly qualifier?: Maybe<Scalars['String']>;
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverOperationalPreferencesInput = {
  readonly type?: InputMaybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly qualifier?: InputMaybe<Scalars['String']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverPermanentPowerInput = {
  readonly powerId?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverPermanentPowers = {
  readonly __typename?: 'DriverPermanentPowers';
  readonly id: Scalars['ID'];
  readonly powerId?: Maybe<Scalars['String']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverPermanentTrailers = {
  readonly __typename?: 'DriverPermanentTrailers';
  readonly id: Scalars['ID'];
  readonly permanentTrailerNumber: Scalars['String'];
  readonly trailerId?: Maybe<Scalars['String']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
};

export type DriverPermanentTrailersInput = {
  readonly permanentTrailerNumber: Scalars['String'];
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly trailerId?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverPreferredLanes = {
  readonly __typename?: 'DriverPreferredLanes';
  readonly id: Scalars['ID'];
  readonly originType?: Maybe<Scalars['String']>;
  readonly originCityState?: Maybe<Scalars['String']>;
  readonly originRegion?: Maybe<Scalars['String']>;
  readonly destinationType?: Maybe<Scalars['String']>;
  readonly destinationCityState?: Maybe<Scalars['String']>;
  readonly destinationRegion?: Maybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly reason?: Maybe<Scalars['String']>;
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
};

export type DriverPreferredLanesInput = {
  readonly originType?: InputMaybe<Scalars['String']>;
  readonly originCityState?: InputMaybe<Scalars['String']>;
  readonly originRegion?: InputMaybe<Scalars['String']>;
  readonly destinationType?: InputMaybe<Scalars['String']>;
  readonly destinationCityState?: InputMaybe<Scalars['String']>;
  readonly destinationRegion?: InputMaybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly reason?: InputMaybe<Scalars['String']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type DriverPreferredRoutes = {
  readonly __typename?: 'DriverPreferredRoutes';
  readonly id: Scalars['ID'];
  readonly shipper?: Maybe<Scalars['String']>;
  readonly originCityState?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly consignee?: Maybe<Scalars['String']>;
  readonly destinationCityState?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['String']>;
  readonly additionalStops?: Maybe<Scalars['Boolean']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly reason?: Maybe<Scalars['String']>;
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  readonly preferredRouteStops?: Maybe<ReadonlyArray<DriverPreferredRouteStops>>;
};

export type DriverPreferredRoutesInput = {
  readonly shipper?: InputMaybe<Scalars['String']>;
  readonly originCityState?: InputMaybe<Scalars['String']>;
  readonly originState?: InputMaybe<Scalars['String']>;
  readonly consignee?: InputMaybe<Scalars['String']>;
  readonly destinationCityState?: InputMaybe<Scalars['String']>;
  readonly destinationState?: InputMaybe<Scalars['String']>;
  readonly stops?: InputMaybe<Scalars['String']>;
  readonly additionalStops?: InputMaybe<Scalars['Boolean']>;
  readonly customer?: InputMaybe<Scalars['String']>;
  readonly preference: Scalars['String'];
  readonly reason?: InputMaybe<Scalars['String']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly isDeleted?: InputMaybe<Scalars['Boolean']>;
  readonly preferredRouteStops?: InputMaybe<ReadonlyArray<DriverPreferredRouteStopsInput>>;
};

export type DriverPreferredRouteStops = {
  readonly __typename?: 'DriverPreferredRouteStops';
  readonly id: Scalars['ID'];
  readonly type?: Maybe<Scalars['String']>;
  readonly cityState?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly facility?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverPreferredRouteStopsInput = {
  readonly type?: InputMaybe<Scalars['String']>;
  readonly cityState?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly facility?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly routeId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverSchedule = {
  readonly __typename?: 'DriverSchedule';
  readonly id: Scalars['ID'];
  /** @deprecated scheduleEffectiveDt is deprecated. Use effectiveDate instead. */
  readonly scheduleEffectiveDt: Scalars['DateTime'];
  /** @deprecated scheduleExpirationDt is deprecated. Use expirationDate instead. */
  readonly scheduleExpirationDt: Scalars['DateTime'];
  readonly dayOfWeek: Scalars['String'];
  /** @deprecated startTm is deprecated. Use startTime instead. */
  readonly startTm: Scalars['DateTime'];
  /** @deprecated endTm is deprecated. Use endTime instead. */
  readonly endTm: Scalars['DateTime'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverScheduleInput = {
  readonly scheduleEffectiveDt: Scalars['DateTime'];
  readonly scheduleExpirationDt: Scalars['DateTime'];
  readonly dayOfWeek: Scalars['String'];
  readonly startTm: Scalars['DateTime'];
  readonly endTm: Scalars['DateTime'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverScheduleInputV2 = {
  readonly effectiveDate?: InputMaybe<Scalars['DateTime']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly dayOfWeek?: InputMaybe<Scalars['String']>;
  readonly startTime?: InputMaybe<Scalars['DateTime']>;
  readonly endTime?: InputMaybe<Scalars['DateTime']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverScheduleInputV3 = {
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate: Scalars['Date'];
  readonly startTime: Scalars['Int'];
  readonly endTime: Scalars['Int'];
  readonly dayOfWeek: Scalars['String'];
  readonly driverId?: InputMaybe<Scalars['String']>;
};

export type DriverScheduleV2 = {
  readonly __typename?: 'DriverScheduleV2';
  readonly id: Scalars['ID'];
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly dayOfWeek?: Maybe<Scalars['String']>;
  readonly startTime?: Maybe<Scalars['DateTime']>;
  readonly endTime?: Maybe<Scalars['DateTime']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverScheduleV3 = {
  readonly __typename?: 'DriverScheduleV3';
  readonly id: Scalars['ID'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate: Scalars['Date'];
  readonly startTime: Scalars['Int'];
  readonly endTime: Scalars['Int'];
  readonly dayOfWeek: Scalars['String'];
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverSearchResult = {
  readonly __typename?: 'DriverSearchResult';
  readonly id?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly middleName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly nickName?: Maybe<Scalars['String']>;
  readonly primaryEmail?: Maybe<Scalars['String']>;
  readonly primaryPhoneNumber?: Maybe<Scalars['String']>;
  readonly managerName?: Maybe<Scalars['String']>;
  /** @deprecated professionType is deprecated. Use professionalTypeTerm instead. */
  readonly professionType?: Maybe<Scalars['String']>;
  readonly professionalTypeTerm?: Maybe<Scalars['String']>;
  /** @deprecated status is deprecated. Use statusTerm instead. */
  readonly status?: Maybe<Scalars['String']>;
  readonly statusTerm?: Maybe<Scalars['String']>;
  /** @deprecated division is deprecated. Use divisionTerm instead. */
  readonly division?: Maybe<Scalars['String']>;
  readonly divisionTerm?: Maybe<Scalars['String']>;
  readonly fleet?: Maybe<Scalars['String']>;
  readonly region?: Maybe<Scalars['String']>;
  readonly geography?: Maybe<Scalars['String']>;
  /** @deprecated segment is deprecated. Use businessUnitTerm instead. */
  readonly segment?: Maybe<Scalars['String']>;
  readonly businessUnitTerm?: Maybe<Scalars['String']>;
  readonly domicile?: Maybe<Scalars['String']>;
  readonly partnerName?: Maybe<Scalars['String']>;
  /** @deprecated driverIdentityId is deprecated. Use code instead. */
  readonly driverIdentityId?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly location?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverTeamPreferences = {
  readonly __typename?: 'DriverTeamPreferences';
  readonly id: Scalars['ID'];
  readonly type: Scalars['String'];
  readonly preference: Scalars['String'];
  readonly reason: Scalars['String'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly recordedBy?: Maybe<Scalars['String']>;
  readonly systemDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverTeamPreferencesInput = {
  readonly type: Scalars['String'];
  readonly preference: Scalars['String'];
  readonly reason: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly recordedBy?: InputMaybe<Scalars['String']>;
  readonly systemDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverTimeOff = {
  readonly __typename?: 'DriverTimeOff';
  readonly id: Scalars['ID'];
  /** @deprecated timeOffType is deprecated. Use typeTerm instead. */
  readonly timeOffType: Scalars['String'];
  /** @deprecated startDt is deprecated. Use startDate instead. */
  readonly startDt: Scalars['DateTime'];
  /** @deprecated endDt is deprecated. Use endDate instead. */
  readonly endDt: Scalars['DateTime'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverTimeOffInput = {
  readonly timeOffType: Scalars['String'];
  readonly startDt: Scalars['DateTime'];
  readonly endDt: Scalars['DateTime'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DriverTimeOffInputV2 = {
  readonly typeTerm?: InputMaybe<Scalars['String']>;
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly createdBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly startLocation?: InputMaybe<Scalars['String']>;
  readonly endLocation?: InputMaybe<Scalars['String']>;
};

export type DriverTimeOffInputV3 = {
  readonly typeTerm: Scalars['String'];
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
  readonly location?: InputMaybe<TimeOffLocationInput>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly driverId?: InputMaybe<Scalars['String']>;
};

export type DriverTimeOffV2 = {
  readonly __typename?: 'DriverTimeOffV2';
  readonly id: Scalars['ID'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly startLocation?: Maybe<Scalars['String']>;
  readonly endLocation?: Maybe<Scalars['String']>;
};

export type DriverTimeOffV3 = {
  readonly __typename?: 'DriverTimeOffV3';
  readonly id: Scalars['ID'];
  readonly typeTerm: Scalars['String'];
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
  readonly location?: Maybe<TimeOffLocationOutput>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverTrailerInput = {
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly permanentTrailers?: InputMaybe<ReadonlyArray<DriverPermanentTrailersInput>>;
};

export type DriverTraining = {
  readonly __typename?: 'DriverTraining';
  readonly id: Scalars['ID'];
  /** This field describes the type of training the driver will undergo or has already completed. */
  readonly typeTerm: Scalars['String'];
  /** This field describes the date on which the driver is expected to complete the training as assigned. */
  readonly dueDate?: Maybe<Scalars['Date']>;
  /** This field describes the date on which the driver completed the training. */
  readonly completedDate?: Maybe<Scalars['Date']>;
  /** This field describes the training result for the driver. */
  readonly outcomeTerm?: Maybe<Scalars['String']>;
  /** This field describes the facility where the training is conducted. */
  readonly facility?: Maybe<FacilityV2>;
  /** This field provides information about the trainer who will conduct or has conducted the session. */
  readonly trainerName?: Maybe<Scalars['String']>;
  /** This field describes the information about the training that the driver has been assigned or completed. */
  readonly description?: Maybe<Scalars['String']>;
  readonly driverId: Scalars['ID'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DriverTrainingInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  /** This field describes the type of training the driver will undergo or has already completed. */
  readonly typeTerm: Scalars['String'];
  /** This field describes the date on which the driver is expected to complete the training as assigned. */
  readonly dueDate?: InputMaybe<Scalars['Date']>;
  /** This field describes the date on which the driver completed the training. */
  readonly completedDate?: InputMaybe<Scalars['Date']>;
  /** This field describes the training result for the driver. */
  readonly outcomeTerm?: InputMaybe<Scalars['String']>;
  /** This field describes the unique facility ID where the training is conducted. */
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  /** This field provides information about the trainer who will conduct or has conducted the session. */
  readonly trainerName?: InputMaybe<Scalars['String']>;
  /** This field describes the information about the training that the driver has been assigned or completed. */
  readonly description?: InputMaybe<Scalars['String']>;
  readonly driverId: Scalars['ID'];
};

export type DriverTypeError = {
  readonly __typename?: 'DriverTypeError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly code?: Maybe<Scalars['String']>;
};

export type DriverValidation = {
  readonly __typename?: 'DriverValidation';
  readonly hasDriverIdentityId?: Maybe<Scalars['Boolean']>;
  readonly hasPrimaryEmail?: Maybe<Scalars['Boolean']>;
  readonly hasPrimaryPhoneNumber?: Maybe<Scalars['Boolean']>;
};

export type DriverWeeklyTarget = {
  readonly __typename?: 'DriverWeeklyTarget';
  readonly id: Scalars['ID'];
  readonly weekStartDate: Scalars['DateTime'];
  readonly target: Target;
  readonly driverId: Scalars['String'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly driverDailyActivities?: Maybe<ReadonlyArray<DriverDailyActivity>>;
};

export type DriverWeeklyTargetInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly weekStartDate: Scalars['DateTime'];
  readonly recurringWeeks: Scalars['Float'];
  readonly target: TargetInput;
  readonly driverId?: InputMaybe<Scalars['String']>;
};

export type DummyType = {
  readonly __typename?: 'DummyType';
  readonly id: Scalars['ID'];
};

export type EdgeClass = {
  readonly __typename?: 'EdgeClass';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<Offer>;
};

export type EdiDocument = {
  readonly __typename?: 'EDIDocument';
  readonly id: Scalars['ID'];
  readonly shipmentId: Scalars['String'];
  readonly providerId: Scalars['String'];
  /** ediDocumentType KVT ID */
  readonly documentTypeId: Scalars['ID'];
  /** uri to access the document */
  readonly uri: Scalars['String'];
  /** The name of the file provided during upload */
  readonly filename: Scalars['String'];
  /**
   * DEPRECATED: Use uploadedByUserV2 instead
   * @deprecated Use uploadedByUserV2 instead
   */
  readonly uploadedByUser?: Maybe<User>;
  readonly uploadDate: Scalars['DateTime'];
  readonly uploadedByUserV2?: Maybe<UserV2>;
};

export type EdiDocumentConnection = {
  readonly __typename?: 'EDIDocumentConnection';
  readonly edges: ReadonlyArray<EdiDocumentGqlTypeEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Float'];
};

export type EdiDocumentFilter = {
  readonly shipmentId: Scalars['ID'];
  readonly documentTypeId: Scalars['ID'];
};

export type EdiDocumentGqlTypeEdge = {
  readonly __typename?: 'EdiDocumentGQLTypeEdge';
  readonly cursor: Scalars['String'];
  readonly node: EdiDocument;
};

/** EDIOutbox. */
export type EdiOutbox = {
  readonly __typename?: 'EdiOutbox';
  /** Gets or sets createdAt. */
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  /** Gets or sets eventValue. */
  readonly eventKey?: Maybe<Scalars['String']>;
  /** Gets or sets eventValue. */
  readonly eventValue?: Maybe<Scalars['String']>;
  /** Gets or sets Id. */
  readonly id?: Maybe<Scalars['Uuid']>;
  /** Gets or sets a value indicating whether IsRetryable. */
  readonly isRetryable?: Maybe<Scalars['Boolean']>;
  /** Gets or sets publishedTimestamp. */
  readonly publishedTimestamp?: Maybe<Scalars['DateTime']>;
  /** Gets or sets retryCount. */
  readonly retryCount?: Maybe<Scalars['Int']>;
  /** Gets or sets topic. */
  readonly topic?: Maybe<Scalars['String']>;
};

/** The input type for new Edi Outbox Message. */
export type EdiOutboxInput = {
  /** Gets or sets createdAt. */
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Gets or sets eventValue. */
  readonly eventKey: Scalars['String'];
  /** Gets or sets eventValue. */
  readonly eventValue: Scalars['String'];
  /** Gets or sets a value indicating whether IsRetryable. */
  readonly isRetryable: Scalars['Boolean'];
  /** Gets or sets publishedTimestamp. */
  readonly publishedTimestamp?: InputMaybe<Scalars['DateTime']>;
  /** Gets or sets retryCount. */
  readonly retryCount: Scalars['Int'];
  /** Gets or sets topic. */
  readonly topic: Scalars['String'];
};

/** This entity holds EDI data for preview pane. */
export type EdiPreviewData = {
  readonly __typename?: 'EDIPreviewData';
  /** Gets or sets document id. */
  readonly id?: Maybe<Scalars['ID']>;
  /** Gets or sets summarized data for preview pane. */
  readonly previewData?: Maybe<Scalars['String']>;
  /** Gets or sets uri. */
  readonly uri?: Maybe<Scalars['String']>;
};

/** EDIPreviewFilter. */
export type EdiPreviewFilter = {
  /** Gets or sets EDIDocumentId. */
  readonly eDIDocumentId: Scalars['ID'];
};

/** EDITender. */
export type EdiTender = {
  readonly __typename?: 'EDITender';
  /** Gets or sets Created_at. */
  readonly created_at?: Maybe<Scalars['DateTime']>;
  /** Gets or sets Scac. */
  readonly created_by?: Maybe<Scalars['String']>;
  /** Gets or sets Declined_reason. */
  readonly declined_reason?: Maybe<Scalars['String']>;
  /** Gets or sets Edi_transaction_id. */
  readonly edi_transaction_id?: Maybe<Scalars['String']>;
  /** Gets or sets Id. */
  readonly id?: Maybe<Scalars['String']>;
  /** Gets or sets a value indicating whether Is_alchemy_tender_request. */
  readonly is_alchemy_tender_request?: Maybe<Scalars['Boolean']>;
  /** Gets or sets Scac. */
  readonly scac?: Maybe<Scalars['String']>;
  /** Gets or sets Shipment_identification_number. */
  readonly shipment_identification_number?: Maybe<Scalars['String']>;
  /** Gets or sets Tender_id. */
  readonly tender_id?: Maybe<Scalars['String']>;
  /** Gets or sets Tender_response. */
  readonly tender_response?: Maybe<Scalars['String']>;
  /** Gets or sets Updated_at. */
  readonly updated_at?: Maybe<Scalars['DateTime']>;
  /** Gets or sets Updated_by. */
  readonly updated_by?: Maybe<Scalars['String']>;
};

/** EDITenderByShipmentIdAndScacFilter. */
export type EdiTenderByShipmentIdAndScacFilter = {
  /** Gets or sets Scac. */
  readonly scac: Scalars['ID'];
  /** Gets or sets ShipmentId. */
  readonly shipmentId: Scalars['ID'];
};

/** EDITransaction. */
export type EdiTransaction = {
  readonly __typename?: 'EDITransaction';
  /** Gets or sets Created_at. */
  readonly created_at?: Maybe<Scalars['DateTime']>;
  /** Gets or sets Created_by. */
  readonly created_by?: Maybe<Scalars['String']>;
  /** Gets or sets description. */
  readonly description?: Maybe<Scalars['String']>;
  /** Gets or sets Direction. */
  readonly direction?: Maybe<Scalars['String']>;
  /** Gets or sets Document_id. */
  readonly document_id?: Maybe<Scalars['String']>;
  /** Gets or sets Edi_message_type. */
  readonly edi_message_type?: Maybe<Scalars['String']>;
  /** Gets or sets ext_customer_identifier. */
  readonly ext_customer_identifier?: Maybe<Scalars['String']>;
  /** Gets or sets Id. */
  readonly id?: Maybe<Scalars['Uuid']>;
  /** Gets or sets a value indicating whether Is_tender_request. */
  readonly is_tender_request: Scalars['Boolean'];
  /** Gets or sets Load_id. */
  readonly load_id?: Maybe<Scalars['String']>;
  /** Gets or sets purpose type. */
  readonly purpose_Type?: Maybe<Scalars['String']>;
  /** Gets or sets Raw_message. */
  readonly raw_message?: Maybe<Scalars['String']>;
  /** Gets or sets Request_id. */
  readonly request_id?: Maybe<Scalars['ID']>;
  /** Gets or sets Route Code. */
  readonly route_code?: Maybe<Scalars['String']>;
  /** Gets or sets Selective_data. */
  readonly selective_data?: Maybe<Scalars['String']>;
  /** Gets or sets Shipment_id. */
  readonly shipment_id?: Maybe<Scalars['String']>;
  /** Gets or sets status. */
  readonly status?: Maybe<Scalars['String']>;
  /** Gets or sets Tracking_update_status_datetime. */
  readonly tracking_update_status_datetime?: Maybe<Scalars['DateTime']>;
  /** Gets or sets Transaction Id. */
  readonly transaction_id?: Maybe<Scalars['String']>;
  /** Gets or sets Updated_at. */
  readonly updated_at?: Maybe<Scalars['DateTime']>;
  /** Gets or sets Updated_by. */
  readonly updated_by?: Maybe<Scalars['String']>;
  /** Gets or sets Vendor. */
  readonly vendor?: Maybe<Scalars['String']>;
};

/** The input type for new EDI Transaction. */
export type EdiTransactionAddInput = {
  /** Gets or sets Description. */
  readonly description?: InputMaybe<Scalars['String']>;
  /** Gets or sets Direction. */
  readonly direction?: InputMaybe<Scalars['String']>;
  /** Gets or sets Document_id. */
  readonly document_id?: InputMaybe<Scalars['ID']>;
  /** Gets or sets Edi_message_type. */
  readonly edi_message_type: Scalars['String'];
  /** Gets or sets a value indicating whether Is_tender_request. */
  readonly is_tender_request?: InputMaybe<Scalars['Boolean']>;
  /** Gets or sets Load_id. */
  readonly load_id?: InputMaybe<Scalars['String']>;
  /** Gets or sets Request_id. */
  readonly request_id?: InputMaybe<Scalars['ID']>;
  /** Gets or sets Route Code. */
  readonly route_code?: InputMaybe<Scalars['String']>;
  /** Gets or sets Selective_data. */
  readonly selective_data?: InputMaybe<Scalars['String']>;
  /** Gets or sets Shipment_id. */
  readonly shipment_id?: InputMaybe<Scalars['String']>;
  /** Gets or sets Tracking_update_status_datetime. */
  readonly tracking_update_status_datetime?: InputMaybe<Scalars['DateTime']>;
  /** Gets or sets Transaction Id. */
  readonly transaction_id?: InputMaybe<Scalars['String']>;
  /** Gets or sets Vendor. */
  readonly vendor?: InputMaybe<Scalars['String']>;
};

/** EDITransactionByShipmentIdFilter. */
export type EdiTransactionByShipmentIdFilter = {
  /** Gets or sets customerId. */
  readonly customerId?: InputMaybe<Scalars['String']>;
  /** Gets or sets shipmentId. */
  readonly shipmentId: Scalars['ID'];
};

/** EDITransactionFilter. */
export type EdiTransactionFilter = {
  /** Gets or sets loadId. */
  readonly loadId: Scalars['ID'];
};

export type EligibleFuelProgramResponse = {
  readonly __typename?: 'EligibleFuelProgramResponse';
  readonly fuelProgram?: Maybe<Scalars['String']>;
  readonly isEligible?: Maybe<Scalars['Boolean']>;
};

export type Email = {
  readonly __typename?: 'Email';
  readonly id: Scalars['String'];
  readonly notificationType: NotificationType;
  readonly providerId: Scalars['String'];
  readonly referenceId: Scalars['String'];
  readonly userId: Scalars['String'];
  readonly triggerEvent: Scalars['String'];
  readonly sentdate: Scalars['DateTime'];
  readonly sender: Scalars['String'];
  readonly statusCode: Scalars['Float'];
  readonly toEmailAddresses: ReadonlyArray<EmailAddress>;
  readonly ccEmailAddresses?: Maybe<ReadonlyArray<EmailAddress>>;
  readonly emailUpdates?: Maybe<ReadonlyArray<EmailUpdate>>;
  readonly user: User;
  readonly attachments?: Maybe<ReadonlyArray<Attachment>>;
};

export type EmailAddress = {
  readonly __typename?: 'EmailAddress';
  readonly id: Scalars['String'];
  readonly address: Scalars['String'];
  readonly type: Scalars['String'];
};

export type EmailFilter = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly notificationType?: InputMaybe<NotificationType>;
  readonly triggerEvent?: InputMaybe<Scalars['String']>;
  readonly userId?: InputMaybe<Scalars['String']>;
  readonly providerId?: InputMaybe<Scalars['String']>;
  readonly referenceId?: InputMaybe<Scalars['String']>;
  readonly sentdate?: InputMaybe<Scalars['DateTime']>;
  readonly senderEmail?: InputMaybe<Scalars['String']>;
  readonly toEmails?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly ccEmails?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly bccEmails?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly subject?: InputMaybe<Scalars['String']>;
  readonly html?: InputMaybe<Scalars['String']>;
  readonly attachments?: InputMaybe<ReadonlyArray<AttachmentInput>>;
  readonly success?: InputMaybe<Scalars['Boolean']>;
  readonly statusCode?: InputMaybe<Scalars['Float']>;
};

export type EmailUpdate = {
  readonly __typename?: 'EmailUpdate';
  readonly id: Scalars['String'];
  readonly address?: Maybe<Scalars['String']>;
  readonly event?: Maybe<Scalars['String']>;
  readonly providerId?: Maybe<Scalars['String']>;
  readonly eventId?: Maybe<Scalars['String']>;
  readonly sentdate: Scalars['DateTime'];
  readonly email?: Maybe<ReadonlyArray<Email>>;
};

export type EmailUpdateFilter = {
  readonly address?: InputMaybe<Scalars['String']>;
  readonly event?: InputMaybe<Scalars['String']>;
  readonly providerId?: InputMaybe<Scalars['String']>;
  readonly eventId?: InputMaybe<Scalars['String']>;
  readonly sentdate?: InputMaybe<Scalars['DateTime']>;
};

export type EmbedParams = {
  readonly __typename?: 'EmbedParams';
  readonly embedReport?: Maybe<ReadonlyArray<Maybe<EmbedReport>>>;
  readonly dataSet?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly embedToken?: Maybe<EmbedToken>;
  readonly type: Scalars['String'];
};

/** The EmbedParams model */
export type EmbedParamsV2 = {
  readonly __typename?: 'EmbedParamsV2';
  readonly embedReport?: Maybe<ReadonlyArray<Maybe<EmbedReportV2>>>;
  readonly dataSet?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly embedToken?: Maybe<EmbedTokenV2>;
  readonly type: Scalars['String'];
};

export type EmbedReport = {
  readonly __typename?: 'EmbedReport';
  readonly embedUrl: Scalars['String'];
  readonly reportId: Scalars['ID'];
  readonly reportName: Scalars['String'];
};

/** The EmbedReportFilterTarget model */
export type EmbedReportFilterTargetV2 = {
  readonly __typename?: 'EmbedReportFilterTargetV2';
  /** The targetted table for the filter */
  readonly table: Scalars['String'];
  /** The targetted column within the targetted table */
  readonly column: Scalars['String'];
};

/**
 * The EmbedReportFilter model.
 *
 * For more information, please see: https://learn.microsoft.com/en-us/javascript/api/overview/powerbi/control-report-filters
 */
export type EmbedReportFilterV2 = {
  readonly __typename?: 'EmbedReportFilterV2';
  /**
   * This is the $schema for the PowerBi Client. It should be adjusted on the frontend to $schema.
   *
   * This should match the value set for filterType
   */
  readonly schema: Scalars['String'];
  /** Filter operator, possible values change depending on the filter type. Examples include: In, NotIn, All */
  readonly operator: Scalars['String'];
  /** Used to target a table and column for the filter */
  readonly target: EmbedReportFilterTargetV2;
  /** List of values to be used with the filter. Should be appended to by the frontend. */
  readonly values: ReadonlyArray<Scalars['String']>;
  /** The value of the FilterType enum from powerbi-models on npm. Basic is 1 */
  readonly filterType: Scalars['Float'];
  /** If the filter should be a basic, single option selection filter. */
  readonly requireSingleSelection: Scalars['Boolean'];
};

/** The EmbedReport model */
export type EmbedReportV2 = {
  readonly __typename?: 'EmbedReportV2';
  readonly embedUrl: Scalars['String'];
  readonly reportId: Scalars['ID'];
  readonly reportName: Scalars['String'];
  /** The base filter for the report. Should be combined with data appropriately by the frontend/clients */
  readonly filter: EmbedReportFilterV2;
};

export type EmbedToken = {
  readonly __typename?: 'EmbedToken';
  readonly token: Scalars['String'];
  readonly tokenId: Scalars['ID'];
  readonly expiration: Scalars['DateTime'];
};

/** The EmbedToken model */
export type EmbedTokenV2 = {
  readonly __typename?: 'EmbedTokenV2';
  readonly token: Scalars['String'];
  readonly tokenId: Scalars['ID'];
  readonly expiration: Scalars['Timestamp'];
};

export type Employee = {
  readonly __typename?: 'Employee';
  /** @deprecated temporary field, to toggle display of "admin" tab */
  readonly adminVisible?: Maybe<Scalars['Boolean']>;
  readonly children?: Maybe<ReadonlyArray<Employee>>;
  readonly commissionBasis?: Maybe<KeyValue>;
  readonly commissionBasisId?: Maybe<Scalars['ID']>;
  readonly commissionType?: Maybe<KeyValue>;
  readonly commissionTypeId?: Maybe<Scalars['ID']>;
  readonly companyEmail?: Maybe<Scalars['String']>;
  readonly compensationPeriod?: Maybe<KeyValue>;
  readonly compensationPeriodId?: Maybe<Scalars['ID']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly division?: Maybe<KeyValue>;
  readonly divisionId?: Maybe<Scalars['ID']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly emergencyContact?: Maybe<Scalars['String']>;
  readonly emergencyPhone?: Maybe<Scalars['String']>;
  readonly employeeCompanyLevel?: Maybe<KeyValue>;
  readonly employeeCompanyLevelId?: Maybe<Scalars['ID']>;
  readonly employeeDepartment?: Maybe<KeyValue>;
  readonly employeeDepartmentId?: Maybe<Scalars['ID']>;
  readonly employeeDepartmentLevel?: Maybe<KeyValue>;
  readonly employeeDepartmentLevelId?: Maybe<Scalars['ID']>;
  readonly employeeDisplayName: Scalars['String'];
  readonly employeeGender?: Maybe<KeyValue>;
  readonly employeeGenderId?: Maybe<Scalars['ID']>;
  readonly employeeGroup?: Maybe<KeyValue>;
  readonly employeeGroupId?: Maybe<Scalars['ID']>;
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly employeeOffice?: Maybe<KeyValue>;
  readonly employeeOfficeId?: Maybe<Scalars['ID']>;
  readonly employeeRole?: Maybe<KeyValue>;
  readonly employeeRoleId?: Maybe<Scalars['ID']>;
  readonly employeeShirtSize?: Maybe<KeyValue>;
  readonly employeeShirtSizeId?: Maybe<Scalars['ID']>;
  readonly employeeStatus: KeyValue;
  readonly employeeStatusId: Scalars['ID'];
  readonly employeeSuffix?: Maybe<KeyValue>;
  readonly employeeSuffixId?: Maybe<Scalars['ID']>;
  readonly employeeTime?: Maybe<KeyValue>;
  readonly employeeTimeId?: Maybe<Scalars['ID']>;
  readonly employeeType?: Maybe<KeyValue>;
  readonly employeeTypeId?: Maybe<Scalars['ID']>;
  /** @deprecated temporary field, to toggle display of "employee" tab */
  readonly employeeVisible?: Maybe<Scalars['Boolean']>;
  readonly ext?: Maybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly firstNameDotLastName: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly hireDate?: Maybe<Scalars['ISO8601Date']>;
  readonly id: Scalars['ID'];
  readonly lastName: Scalars['String'];
  readonly manager?: Maybe<Employee>;
  readonly managerId?: Maybe<Scalars['ID']>;
  readonly middleName?: Maybe<Scalars['String']>;
  readonly mobilePhone?: Maybe<Scalars['String']>;
  readonly nickname?: Maybe<Scalars['String']>;
  readonly parent?: Maybe<Employee>;
  readonly payTrigger?: Maybe<KeyValue>;
  readonly payTriggerId?: Maybe<Scalars['ID']>;
  readonly personalEmail?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly relationship?: Maybe<Scalars['String']>;
  readonly siblings?: Maybe<ReadonlyArray<Employee>>;
  readonly slackName?: Maybe<Scalars['String']>;
  readonly subRegion?: Maybe<Scalars['String']>;
  readonly termDate?: Maybe<Scalars['ISO8601Date']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
  readonly userId?: Maybe<Scalars['ID']>;
};

export type EmployeeCommissionConfigurationAssignmentInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly employeeId?: InputMaybe<Scalars['ID']>;
  readonly employeeCommissionPlanConfigurationHeaderId?: InputMaybe<Scalars['ID']>;
  readonly effectiveStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly effectiveEndDate?: InputMaybe<Scalars['DateTime']>;
};

export type EmployeeCommissionPlan = {
  readonly __typename?: 'EmployeeCommissionPlan';
  /** @deprecated This field is not a valid field at this time.  There is no id associated with this. */
  readonly id: Scalars['ID'];
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly employeeCommissionPlanConfigurationAssignments?: Maybe<ReadonlyArray<Maybe<EmployeeCommissionPlanConfigurationAssignment>>>;
};

export type EmployeeCommissionPlanAssignmentsInput = {
  readonly planHeaderId: Scalars['ID'];
  readonly employeeIds: ReadonlyArray<InputMaybe<Scalars['ID']>>;
};

export type EmployeeCommissionPlanConfiguration = {
  readonly __typename?: 'EmployeeCommissionPlanConfiguration';
  readonly id: Scalars['ID'];
  readonly commissionPlanName?: Maybe<Scalars['String']>;
  readonly marginBasisId?: Maybe<Scalars['String']>;
  readonly effectiveStartDate?: Maybe<Scalars['DateTime']>;
  readonly effectiveEndDate?: Maybe<Scalars['DateTime']>;
  readonly employeeId?: Maybe<Scalars['ID']>;
  readonly isGlobal: Scalars['Boolean'];
  readonly planAppliesToId?: Maybe<Scalars['String']>;
  readonly customerRepTypeId?: Maybe<Scalars['String']>;
  readonly carrierRepTypeId?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly employeeCommissionPlanConfigurationDetails?: Maybe<ReadonlyArray<Maybe<EmployeeCommissionPlanConfigurationDetail>>>;
};

export type EmployeeCommissionPlanConfigurationAssignment = {
  readonly __typename?: 'EmployeeCommissionPlanConfigurationAssignment';
  readonly id: Scalars['ID'];
  readonly employeeId?: Maybe<Scalars['ID']>;
  readonly effectiveStartDate?: Maybe<Scalars['DateTime']>;
  readonly effectiveEndDate?: Maybe<Scalars['DateTime']>;
  readonly employeeCommissionPlanConfigurationHeader?: Maybe<EmployeeCommissionPlanConfiguration>;
};

export type EmployeeCommissionPlanConfigurationDetail = {
  readonly __typename?: 'EmployeeCommissionPlanConfigurationDetail';
  readonly id: Scalars['ID'];
  readonly loadSizeId?: Maybe<Scalars['String']>;
  readonly transportModeId?: Maybe<Scalars['String']>;
  readonly divisionId?: Maybe<Scalars['String']>;
  readonly commissionBasisId?: Maybe<Scalars['String']>;
  readonly marginLowerBoundPercent?: Maybe<Scalars['Decimal']>;
  readonly marginUpperBoundPercent?: Maybe<Scalars['Decimal']>;
  readonly commissionPercent?: Maybe<Scalars['Decimal']>;
  readonly payTriggerId?: Maybe<Scalars['String']>;
  readonly flatAmount?: Maybe<Scalars['Decimal']>;
  readonly commissionPercentMinAmount?: Maybe<Scalars['Decimal']>;
  readonly commissionPercentMaxAmount?: Maybe<Scalars['Decimal']>;
};

export type EmployeeCommissionPlanConfigurationDetailInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly loadSizeId?: InputMaybe<Scalars['String']>;
  readonly transportModeId?: InputMaybe<Scalars['String']>;
  readonly divisionId?: InputMaybe<Scalars['String']>;
  readonly commissionBasisId?: InputMaybe<Scalars['String']>;
  readonly marginLowerBoundPercent?: InputMaybe<Scalars['Decimal']>;
  readonly marginUpperBoundPercent?: InputMaybe<Scalars['Decimal']>;
  readonly commissionPercent?: InputMaybe<Scalars['Decimal']>;
  readonly payTriggerId?: InputMaybe<Scalars['String']>;
  readonly flatAmount?: InputMaybe<Scalars['Decimal']>;
  readonly commissionPercentMinAmount?: InputMaybe<Scalars['Decimal']>;
  readonly commissionPercentMaxAmount?: InputMaybe<Scalars['Decimal']>;
};

export type EmployeeCommissionPlanConfigurationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly commissionPlanName?: InputMaybe<Scalars['String']>;
  readonly carrierRepTypeId?: InputMaybe<Scalars['String']>;
  readonly customerRepTypeId?: InputMaybe<Scalars['String']>;
  readonly planAppliesToId?: InputMaybe<Scalars['String']>;
  readonly marginBasisId?: InputMaybe<Scalars['String']>;
  readonly effectiveStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly effectiveEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly employeeId?: InputMaybe<Scalars['ID']>;
  readonly isGlobal: Scalars['Boolean'];
  readonly employeeCommissionPlanConfigurationDetails?: InputMaybe<ReadonlyArray<InputMaybe<EmployeeCommissionPlanConfigurationDetailInput>>>;
};

export type EmployeeCommissionPlanFilterInput = {
  readonly employeeId: Scalars['ID'];
};

export type EmployeeCommissionPlanInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly employeeCommissionPlanConfigurationAssignments?: InputMaybe<ReadonlyArray<InputMaybe<EmployeeCommissionConfigurationAssignmentInput>>>;
};

/** The connection type for Employee. */
export type EmployeeConnection = {
  readonly __typename?: 'EmployeeConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<EmployeeEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** The connection type for Employee. */
export type EmployeeConnectionV2 = {
  readonly __typename?: 'EmployeeConnectionV2';
  /** A list of edges. */
  readonly edges: ReadonlyArray<EmployeeEdgeV2>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Float'];
};

/** An edge in a connection. */
export type EmployeeEdge = {
  readonly __typename?: 'EmployeeEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: Employee;
};

/** An edge in a connection. */
export type EmployeeEdgeV2 = {
  readonly __typename?: 'EmployeeEdgeV2';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: EmployeeV2;
};

export type EmployeeFilter = {
  readonly companyEmail?: InputMaybe<Scalars['String']>;
  readonly employeeDisplayName?: InputMaybe<Scalars['String']>;
  readonly employeeStatus?: InputMaybe<Scalars['String']>;
  readonly externalEmployeeId?: InputMaybe<Scalars['String']>;
  readonly firstNameLastNameOrEmail?: InputMaybe<Scalars['String']>;
  readonly user?: InputMaybe<UserFilter>;
};

export type EmployeeFilterV2 = {
  readonly companyEmail?: InputMaybe<Scalars['String']>;
  readonly employeeDisplayName?: InputMaybe<Scalars['String']>;
  readonly employeeStatus?: InputMaybe<Scalars['String']>;
  readonly externalEmployeeId?: InputMaybe<Scalars['String']>;
  readonly firstNameLastNameOrEmail?: InputMaybe<Scalars['String']>;
  readonly user?: InputMaybe<UserFilterV2>;
  readonly sort?: InputMaybe<Scalars['String']>;
  readonly employeeDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly employeeBusinessUnits?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly employeeGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly employeeDepartment?: InputMaybe<Scalars['String']>;
  readonly employeeOffice?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
};

export type EmployeeInput = {
  readonly commissionBasisId?: InputMaybe<Scalars['ID']>;
  readonly commissionTypeId?: InputMaybe<Scalars['ID']>;
  readonly companyEmail?: InputMaybe<Scalars['String']>;
  readonly compensationPeriodId?: InputMaybe<Scalars['ID']>;
  readonly divisionId?: InputMaybe<Scalars['ID']>;
  readonly email?: InputMaybe<Scalars['String']>;
  readonly emergencyContact?: InputMaybe<Scalars['String']>;
  readonly emergencyPhone?: InputMaybe<Scalars['String']>;
  readonly employeeCompanyLevelId?: InputMaybe<Scalars['ID']>;
  readonly employeeDepartmentId?: InputMaybe<Scalars['ID']>;
  readonly employeeDepartmentLevelId?: InputMaybe<Scalars['ID']>;
  readonly employeeDisplayName?: InputMaybe<Scalars['String']>;
  readonly employeeGenderId?: InputMaybe<Scalars['ID']>;
  readonly employeeGroupId?: InputMaybe<Scalars['ID']>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly employeeOfficeId?: InputMaybe<Scalars['ID']>;
  readonly employeeRoleId?: InputMaybe<Scalars['ID']>;
  readonly employeeShirtSizeId?: InputMaybe<Scalars['ID']>;
  readonly employeeStatusId?: InputMaybe<Scalars['ID']>;
  readonly employeeSuffixId?: InputMaybe<Scalars['ID']>;
  readonly employeeTimeId?: InputMaybe<Scalars['ID']>;
  readonly employeeTypeId?: InputMaybe<Scalars['ID']>;
  readonly ext?: InputMaybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly hireDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly lastName: Scalars['String'];
  readonly managerId?: InputMaybe<Scalars['ID']>;
  readonly middleName?: InputMaybe<Scalars['String']>;
  readonly mobilePhone?: InputMaybe<Scalars['String']>;
  readonly nickname?: InputMaybe<Scalars['String']>;
  readonly payTriggerId?: InputMaybe<Scalars['ID']>;
  readonly personalEmail?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly relationship?: InputMaybe<Scalars['String']>;
  readonly slackName?: InputMaybe<Scalars['String']>;
  readonly subRegion?: InputMaybe<Scalars['String']>;
  readonly termDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly title?: InputMaybe<Scalars['String']>;
};

export type EmployeeInputTypeExternal = {
  readonly companyEmail: Scalars['String'];
  readonly employeeDepartmentName?: InputMaybe<Scalars['String']>;
  readonly employeeDisplayName?: InputMaybe<Scalars['String']>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly employeeOfficeName?: InputMaybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly hireDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly isUserActive?: InputMaybe<Scalars['Boolean']>;
  readonly lastName: Scalars['String'];
  readonly managerUserEmail?: InputMaybe<Scalars['String']>;
  readonly mobilePhone?: InputMaybe<Scalars['String']>;
  readonly nickname?: InputMaybe<Scalars['String']>;
  readonly personalEmail?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly title?: InputMaybe<Scalars['String']>;
  readonly userEmail: Scalars['String'];
};

export type EmployeeInputTypeExternalV2 = {
  readonly companyEmail: Scalars['String'];
  readonly employeeDepartmentName?: InputMaybe<Scalars['String']>;
  readonly employeeDisplayName?: InputMaybe<Scalars['String']>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly employeeOfficeName?: InputMaybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly hireDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly isUserActive?: InputMaybe<Scalars['Boolean']>;
  readonly lastName: Scalars['String'];
  readonly managerUserEmail?: InputMaybe<Scalars['String']>;
  readonly mobilePhone?: InputMaybe<Scalars['String']>;
  readonly nickname?: InputMaybe<Scalars['String']>;
  readonly personalEmail?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly title?: InputMaybe<Scalars['String']>;
  readonly userEmail: Scalars['String'];
};

export type EmployeeInputV2 = {
  readonly businessUnitId?: InputMaybe<Scalars['ID']>;
  readonly commissionBasisId?: InputMaybe<Scalars['ID']>;
  readonly commissionTypeId?: InputMaybe<Scalars['ID']>;
  readonly companyEmail?: InputMaybe<Scalars['String']>;
  readonly compensationPeriodId?: InputMaybe<Scalars['ID']>;
  readonly divisionId?: InputMaybe<Scalars['ID']>;
  readonly email?: InputMaybe<Scalars['String']>;
  readonly emergencyContact?: InputMaybe<Scalars['String']>;
  readonly emergencyPhone?: InputMaybe<Scalars['String']>;
  readonly employeeCompanyLevelId?: InputMaybe<Scalars['ID']>;
  readonly employeeDepartmentId?: InputMaybe<Scalars['ID']>;
  readonly employeeDepartmentLevelId?: InputMaybe<Scalars['ID']>;
  readonly employeeDisplayName?: InputMaybe<Scalars['String']>;
  readonly employeeGenderId?: InputMaybe<Scalars['ID']>;
  readonly employeeGroupId?: InputMaybe<Scalars['ID']>;
  readonly employeeGroupIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly employeeDivisionIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly employeeBusinessUnitIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly employeeOfficeId?: InputMaybe<Scalars['ID']>;
  readonly employeeRoleId?: InputMaybe<Scalars['ID']>;
  readonly employeeShirtSizeId?: InputMaybe<Scalars['ID']>;
  readonly employeeStatusId?: InputMaybe<Scalars['ID']>;
  readonly employeeSuffixId?: InputMaybe<Scalars['ID']>;
  readonly employeeTimeId?: InputMaybe<Scalars['ID']>;
  readonly employeeTypeId?: InputMaybe<Scalars['ID']>;
  readonly ext?: InputMaybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly hireDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly lastName: Scalars['String'];
  readonly managerId?: InputMaybe<Scalars['ID']>;
  readonly middleName?: InputMaybe<Scalars['String']>;
  readonly mobilePhone?: InputMaybe<Scalars['String']>;
  readonly nickname?: InputMaybe<Scalars['String']>;
  readonly payTriggerId?: InputMaybe<Scalars['ID']>;
  readonly projectId?: InputMaybe<Scalars['ID']>;
  readonly personalEmail?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly relationship?: InputMaybe<Scalars['String']>;
  readonly slackName?: InputMaybe<Scalars['String']>;
  readonly subRegion?: InputMaybe<Scalars['String']>;
  readonly termDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly title?: InputMaybe<Scalars['String']>;
  readonly messagingUserIdentities?: InputMaybe<ReadonlyArray<MessagingV2Input>>;
};

export enum EmployeeMessagingTypeEnum {
  /** Slack */
  Slack = 'Slack',
  /** MSTeams */
  MsTeams = 'MSTeams'
}

export type EmployeeV2 = {
  readonly __typename?: 'EmployeeV2';
  /** @deprecated temporary field, to toggle display of "admin" tab */
  readonly adminVisible?: Maybe<Scalars['Boolean']>;
  readonly children?: Maybe<ReadonlyArray<EmployeeV2>>;
  readonly businessUnit?: Maybe<KeyValue>;
  readonly businessUnitId?: Maybe<Scalars['ID']>;
  readonly commissionBasis?: Maybe<KeyValue>;
  readonly commissionBasisId?: Maybe<Scalars['ID']>;
  readonly commissionType?: Maybe<KeyValue>;
  readonly commissionTypeId?: Maybe<Scalars['ID']>;
  readonly companyEmail?: Maybe<Scalars['String']>;
  readonly compensationPeriod?: Maybe<KeyValue>;
  readonly compensationPeriodId?: Maybe<Scalars['ID']>;
  readonly division?: Maybe<KeyValue>;
  readonly divisionId?: Maybe<Scalars['ID']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly emergencyContact?: Maybe<Scalars['String']>;
  readonly emergencyPhone?: Maybe<Scalars['String']>;
  readonly employeeCompanyLevel?: Maybe<KeyValue>;
  readonly employeeCompanyLevelId?: Maybe<Scalars['ID']>;
  readonly employeeDepartment?: Maybe<KeyValue>;
  readonly employeeDepartmentId?: Maybe<Scalars['ID']>;
  readonly employeeDepartmentLevel?: Maybe<KeyValue>;
  readonly employeeDepartmentLevelId?: Maybe<Scalars['ID']>;
  readonly employeeDisplayName: Scalars['String'];
  readonly employeeGender?: Maybe<KeyValue>;
  readonly employeeGenderId?: Maybe<Scalars['ID']>;
  readonly employeeGroup?: Maybe<KeyValue>;
  readonly employeeGroupId?: Maybe<Scalars['ID']>;
  readonly employeeGroups?: Maybe<ReadonlyArray<KeyValue>>;
  readonly employeeDivisions?: Maybe<ReadonlyArray<KeyValue>>;
  readonly employeeBusinessUnits?: Maybe<ReadonlyArray<KeyValue>>;
  readonly messagingUserIdentities?: Maybe<ReadonlyArray<MessagingV2>>;
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly employeeOffice?: Maybe<KeyValue>;
  readonly employeeOfficeId?: Maybe<Scalars['ID']>;
  readonly employeeRole?: Maybe<KeyValue>;
  readonly employeeRoleId?: Maybe<Scalars['ID']>;
  readonly employeeShirtSize?: Maybe<KeyValue>;
  readonly employeeShirtSizeId?: Maybe<Scalars['ID']>;
  readonly employeeStatus: KeyValue;
  readonly employeeStatusId: Scalars['ID'];
  readonly employeeSuffix?: Maybe<KeyValue>;
  readonly employeeSuffixId?: Maybe<Scalars['ID']>;
  readonly employeeTime?: Maybe<KeyValue>;
  readonly employeeTimeId?: Maybe<Scalars['ID']>;
  readonly employeeType?: Maybe<KeyValue>;
  readonly employeeTypeId?: Maybe<Scalars['ID']>;
  /** @deprecated temporary field, to toggle display of "employee" tab */
  readonly employeeVisible?: Maybe<Scalars['Boolean']>;
  readonly ext?: Maybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly firstNameDotLastName: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly hireDate?: Maybe<Scalars['ISO8601Date']>;
  readonly id: Scalars['ID'];
  readonly lastName: Scalars['String'];
  readonly manager?: Maybe<EmployeeV2>;
  readonly managerId?: Maybe<Scalars['ID']>;
  readonly middleName?: Maybe<Scalars['String']>;
  readonly mobilePhone?: Maybe<Scalars['String']>;
  readonly nickname?: Maybe<Scalars['String']>;
  readonly parent?: Maybe<EmployeeV2>;
  readonly payTrigger?: Maybe<KeyValue>;
  readonly payTriggerId?: Maybe<Scalars['ID']>;
  readonly project?: Maybe<KeyValue>;
  readonly projectId?: Maybe<Scalars['ID']>;
  readonly personalEmail?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly relationship?: Maybe<Scalars['String']>;
  readonly siblings?: Maybe<ReadonlyArray<EmployeeV2>>;
  readonly slackName?: Maybe<Scalars['String']>;
  readonly subRegion?: Maybe<Scalars['String']>;
  readonly termDate?: Maybe<Scalars['ISO8601Date']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly userId?: Maybe<Scalars['ID']>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdAt: Scalars['ISO8601Date'];
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedAt: Scalars['ISO8601Date'];
};

export type EmptyLocation = {
  readonly __typename?: 'EmptyLocation';
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly timezone?: Maybe<Scalars['String']>;
};

export type EntityArgumentInput = {
  readonly code?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type EntityConfigurationApplied = {
  readonly __typename?: 'EntityConfigurationApplied';
  readonly provider?: Maybe<MileageProvider>;
  readonly distanceSearchPreference?: Maybe<ReadonlyArray<Maybe<DistanceSearchPreferenceOption>>>;
  readonly options?: Maybe<MileageProviderOptions>;
};

/** The domain this configuration is relevant for (Mileage, Data Cleansing, etc) */
export enum EntityConfigurationDomain {
  Mileage = 'mileage'
}

/** The type of entity this configuration is for (Customer, Carrier) */
export enum EntityConfigurationEntityType {
  Customer = 'customer',
  Carrier = 'carrier'
}

export type EntityConfigurationResult = {
  readonly __typename?: 'EntityConfigurationResult';
  readonly uuid?: Maybe<Scalars['ID']>;
  readonly entityType: EntityConfigurationEntityType;
  /** The id of the entity this belongs to, validated against Records Api to ensure exists */
  readonly entityId: Scalars['ID'];
  readonly domain: EntityConfigurationDomain;
  /** The value of the configuration itself, shape is determined by domain */
  readonly settings: EntityConfigurationSettings;
  readonly effectiveDate: Scalars['String'];
  readonly expirationDate?: Maybe<Scalars['String']>;
  /** The last updated timestamp when the configuration was modified */
  readonly lastUpdatedTimestamp?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
};

export type EntityConfigurationSettings = MileageSettings;

export type EntityInput = {
  readonly carrier?: InputMaybe<EntityArgumentInput>;
  readonly customer?: InputMaybe<EntityArgumentInput>;
  readonly facility?: InputMaybe<EntityArgumentInput>;
};

export enum EntityType {
  Customer = 'customer',
  Carrier = 'carrier'
}

export enum EntityTypeEnum {
  Factor = 'factor',
  Fleet = 'fleet',
  Rail = 'rail',
  Carrier = 'carrier',
  Vendor = 'vendor'
}

export type Equipment = {
  readonly trailerTypeTerms?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly trailerDimensions?: InputMaybe<TrailerDimensions>;
  readonly commodities?: InputMaybe<ReadonlyArray<InputMaybe<Commodities>>>;
};

export type EquipmentR2 = {
  readonly __typename?: 'EquipmentR2';
  readonly id?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
};

export type Error = {
  readonly __typename?: 'Error';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['String']>;
};

export type EstimatedFuelSurcharge = {
  readonly __typename?: 'EstimatedFuelSurcharge';
  readonly customerId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly fuelSurcharge?: Maybe<Scalars['Decimal']>;
};

export type EtaCalculatorIntegrityCheck = {
  readonly __typename?: 'ETACalculatorIntegrityCheck';
  readonly errors: ReadonlyArray<Maybe<Scalars['String']>>;
};

export type EtaCalculatorResponse = EtaCalculatorResult | EtaCalculatorIntegrityCheck;

export type EtaCalculatorResult = {
  readonly __typename?: 'ETACalculatorResult';
  readonly paths: ReadonlyArray<Maybe<PathDat>>;
  readonly reportSchedules: ReadonlyArray<Maybe<ReportScheduleDat>>;
  readonly hosFeasible: Scalars['Boolean'];
};

export type EventDetail = {
  readonly __typename?: 'EventDetail';
  readonly id: Scalars['ID'];
  readonly lateArrivalReasonCodeID?: Maybe<Scalars['String']>;
  /** DEPRECATED - DO NOT USE, Use Trailers field instead, to support multi-trailer */
  readonly trailer?: Maybe<Trailer>;
  /** Returns a SORTED array of trailers. Includes a sort index in case you mess with order */
  readonly trailers: ReadonlyArray<Trailer>;
  readonly note?: Maybe<Scalars['String']>;
  readonly createdDatetime: Scalars['DateTime'];
  readonly updatedDatetime: Scalars['DateTime'];
  readonly trailerID?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly tractorNumber?: Maybe<Scalars['String']>;
};

export type EventDetailInput = {
  readonly trailers?: InputMaybe<ReadonlyArray<TrailerInput>>;
  readonly lateArrivalReasonCodeID?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly tractorNumber?: InputMaybe<Scalars['String']>;
  /** DEPRECATED - DO NOT USE, Use trailers Array Instead */
  readonly trailer?: InputMaybe<TrailerInput>;
  /** DEPRECATED - DO NOT USE, Use trailers Array Instead */
  readonly trailerID?: InputMaybe<Scalars['String']>;
};

export type ExceptionMetadataUnion = LoadV2 | CustomerOrderV2 | LoadRouteV2 | CustomerV2 | ClientExceptionRecordDefaultMetadata;

export enum ExceptionReason {
  AccountingIncident = 'AccountingIncident',
  TrailerValidation = 'TrailerValidation',
  VendorInvoiceTolerance = 'VendorInvoiceTolerance'
}

export type ExceptionSourceUnion = LoadV2 | CustomerOrderV2 | LoadRouteV2 | CustomerV2 | ClientExceptionRecordDefaultSource;

export type ExchangeRateConfiguration = {
  readonly __typename?: 'ExchangeRateConfiguration';
  readonly costDetailConversionDate: VoucherExchangeRateConversionDateEnumType;
  readonly id: Scalars['ID'];
  readonly rateDetailConversionDate: InvoiceExchangeRateConversionDateEnum;
  readonly useInvoiceVoucherDateAsFallbackDate?: Maybe<Scalars['Boolean']>;
};

/** The input type for ExchangeRateConfiguration */
export type ExchangeRateConfigurationCreateInput = {
  readonly costDetailConversionDate: VoucherExchangeRateConversionDateEnumType;
  readonly rateDetailConversionDate: InvoiceExchangeRateConversionDateEnum;
  readonly useInvoiceVoucherDateAsFallbackDate?: InputMaybe<Scalars['Boolean']>;
};

/** The input type for ExchangeRateConfiguration */
export type ExchangeRateConfigurationUpdateInputType = {
  readonly costDetailConversionDate: VoucherExchangeRateConversionDateEnumType;
  readonly id: Scalars['ID'];
  readonly rateDetailConversionDate: InvoiceExchangeRateConversionDateEnum;
  readonly useInvoiceVoucherDateAsFallbackDate?: InputMaybe<Scalars['Boolean']>;
};

export type ExistingQuotesDetail = {
  readonly __typename?: 'ExistingQuotesDetail';
  readonly existingRateQuoteCode?: Maybe<Scalars['String']>;
  readonly existingSpotQuoteCode?: Maybe<Scalars['String']>;
};

export type ExistingQuotesFilterInput = {
  readonly spotQuoteFilter: SpotQuoteFilterInput;
  readonly customerId: Scalars['ID'];
};

export type ExistingQuotesInput = {
  readonly filter: ExistingQuotesFilterInput;
};

export type ExistingQuotesPayload = {
  readonly __typename?: 'ExistingQuotesPayload';
  readonly data?: Maybe<ExistingQuotesDetail>;
  readonly errors?: Maybe<ReadonlyArray<SpotQuoteError>>;
};

/** Holds Expected Cost Detail. */
export type ExpectedCost = {
  readonly __typename?: 'ExpectedCost';
  readonly code?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly dynamicCost?: Maybe<Scalars['Float']>;
  readonly message?: Maybe<Scalars['String']>;
};

export type ExpectedCostRequestFilter = {
  readonly destinationCity?: InputMaybe<Scalars['String']>;
  readonly destinationLatitude?: InputMaybe<Scalars['Float']>;
  readonly destinationLongitude?: InputMaybe<Scalars['Float']>;
  readonly destinationPostalCode?: InputMaybe<Scalars['String']>;
  readonly destinationRegion?: InputMaybe<Scalars['String']>;
  readonly destinationState?: InputMaybe<Scalars['String']>;
  readonly isCustomerLiveLoad?: InputMaybe<Scalars['Boolean']>;
  readonly isCustomerLiveUnload?: InputMaybe<Scalars['Boolean']>;
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly orderCode?: InputMaybe<Scalars['String']>;
  readonly originCity?: InputMaybe<Scalars['String']>;
  readonly originLatitude?: InputMaybe<Scalars['Float']>;
  readonly originLongitude?: InputMaybe<Scalars['Float']>;
  readonly originPostalCode?: InputMaybe<Scalars['String']>;
  readonly originRegion?: InputMaybe<Scalars['String']>;
  readonly originState?: InputMaybe<Scalars['String']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
};

export type ExpectedReadyValidationsInput = {
  readonly routeId: Scalars['ID'];
};

export type ExpectedReadyValidationsResponse = {
  readonly __typename?: 'ExpectedReadyValidationsResponse';
  readonly locationRequired: Scalars['Boolean'];
  readonly dateRequired: Scalars['Boolean'];
  readonly timeRequired: Scalars['Boolean'];
  readonly equipmentRequired: Scalars['Boolean'];
  readonly lengthRequired: Scalars['Boolean'];
  readonly costLineItemsRequired: Scalars['Boolean'];
};

export enum ExpiredCacheBehavior {
  Fail = 'Fail',
  UseCached = 'UseCached'
}

export type ExportCustomerCommitmentFilter = {
  readonly customerID?: InputMaybe<Scalars['ID']>;
  readonly laneIDs?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly rateQuoteID?: InputMaybe<Scalars['ID']>;
  readonly zeroVolume?: InputMaybe<Scalars['Int']>;
  readonly orderID?: InputMaybe<Scalars['ID']>;
  readonly includeActive?: InputMaybe<Scalars['Boolean']>;
};

export type ExportRoutingGuidesByLanesFilter = {
  readonly laneIds: ReadonlyArray<Scalars['ID']>;
  readonly onlyActive?: InputMaybe<Scalars['Boolean']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
};

export type ExternalAvailableRouteCustomerInput = {
  readonly customerName: Scalars['String'];
  readonly customerEmployeeGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type ExternalAvailableRouteCustomerRepInput = {
  readonly main: Scalars['Boolean'];
  readonly typeId?: InputMaybe<Scalars['String']>;
  readonly typeName?: InputMaybe<Scalars['String']>;
  readonly employeeName: Scalars['String'];
};

export type ExternalAvailableRouteStopInput = {
  readonly startDate: Scalars['DateTime'];
  readonly endDate: Scalars['DateTime'];
  readonly timeZone?: InputMaybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  /** Two character country code. (e.g. US, MX, CA) */
  readonly country?: InputMaybe<Scalars['String']>;
  readonly geoPoint?: InputMaybe<GeoPointInput>;
};

export type ExtractAvailableField = {
  readonly __typename?: 'ExtractAvailableField';
  readonly exportColumnDescription?: Maybe<Scalars['String']>;
  readonly exportColumnName?: Maybe<Scalars['String']>;
  readonly fileType?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
};

export type ExtractConfigurationInput = {
  readonly daysOfMonth?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Decimal']>>>;
  readonly daysOfWeek?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly extractMappings: ReadonlyArray<ExtractMappingInput>;
  readonly extractTime: Scalars['String'];
  readonly fileName: Scalars['String'];
  readonly fileType: Scalars['String'];
  readonly frequency: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

/** Represents all Extract configuration values */
export type ExtractConfigurationType = {
  readonly __typename?: 'ExtractConfigurationType';
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly daysOfMonth?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly daysOfWeek?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly extractMappings?: Maybe<ReadonlyArray<Maybe<ExtractMapping>>>;
  readonly extractTime?: Maybe<Scalars['String']>;
  readonly fileName?: Maybe<Scalars['String']>;
  readonly fileType?: Maybe<Scalars['String']>;
  readonly frequency?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
};

export type ExtractMapping = {
  readonly __typename?: 'ExtractMapping';
  readonly alias?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly exportFieldName?: Maybe<Scalars['String']>;
  readonly extractConfigurationEid?: Maybe<Scalars['Long']>;
  readonly id: Scalars['ID'];
  readonly sequence?: Maybe<Scalars['Long']>;
  readonly truncate?: Maybe<Scalars['Long']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
};

export type ExtractMappingInput = {
  readonly alias?: InputMaybe<Scalars['String']>;
  readonly exportFieldName: Scalars['String'];
  readonly sequence: Scalars['Long'];
  readonly truncate?: InputMaybe<Scalars['Long']>;
};

export type FacilitiesFilter = {
  readonly address?: InputMaybe<AddressesQueryInput>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly customer?: InputMaybe<CustomersFilter>;
  readonly dba?: InputMaybe<Scalars['String']>;
  readonly facilityIdentifier?: InputMaybe<IdentifierQueryInput>;
  readonly facilityNote?: InputMaybe<Scalars['String']>;
  readonly facilityType?: InputMaybe<Scalars['String']>;
  readonly facilityTypeId?: InputMaybe<Scalars['ID']>;
  readonly loadFromTypeId?: InputMaybe<Scalars['ID']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly scaleNote?: InputMaybe<Scalars['String']>;
  readonly schedulingContact?: InputMaybe<Scalars['String']>;
  readonly schedulingSystemTypeId?: InputMaybe<Scalars['ID']>;
  readonly sort?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['ID']>;
  readonly sourceType?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<FacilityStatusTypeEnum>;
  readonly text?: InputMaybe<Scalars['String']>;
  readonly unloadFromTypeId?: InputMaybe<Scalars['ID']>;
  readonly updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
};

export type FacilitiesFilterV2 = {
  readonly address?: InputMaybe<FacilityAddressesQueryInput>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly dba?: InputMaybe<Scalars['String']>;
  readonly facilityIdentifier?: InputMaybe<FacilityIdentifierQueryInput>;
  readonly facilityNote?: InputMaybe<Scalars['String']>;
  readonly facilityType?: InputMaybe<Scalars['String']>;
  readonly facilityTypeId?: InputMaybe<Scalars['ID']>;
  readonly loadFromTypeId?: InputMaybe<Scalars['ID']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly scaleNote?: InputMaybe<Scalars['String']>;
  readonly schedulingContact?: InputMaybe<Scalars['String']>;
  readonly schedulingSystemTypeId?: InputMaybe<Scalars['ID']>;
  readonly sort?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['ID']>;
  readonly sourceType?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<FacilityStatusTypeEnum>;
  readonly unloadFromTypeId?: InputMaybe<Scalars['ID']>;
  readonly updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly text?: InputMaybe<Scalars['String']>;
  readonly useFacilityPickerFuzzy?: InputMaybe<Scalars['Boolean']>;
};

export type FacilitiesQueryInput = {
  readonly limit?: InputMaybe<Scalars['Int']>;
  readonly text?: InputMaybe<Scalars['String']>;
};

export type FacilitiesQueryInputTypeV2 = {
  readonly text?: InputMaybe<Scalars['String']>;
  readonly limit?: InputMaybe<Scalars['Int']>;
};

export type Facility = {
  readonly __typename?: 'Facility';
  readonly addresses: ReadonlyArray<Address>;
  readonly code: Scalars['String'];
  readonly contacts: ReadonlyArray<Contact>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly customerRelationships?: Maybe<ReadonlyArray<CustomerFacilityRelationship>>;
  readonly dba?: Maybe<Scalars['String']>;
  /** @deprecated Use external_notes */
  readonly externalFacilityNotes?: Maybe<ReadonlyArray<Note>>;
  readonly externalNotes?: Maybe<ReadonlyArray<Note>>;
  readonly facilityIdentifiers?: Maybe<ReadonlyArray<FacilityIdentifier>>;
  readonly facilityNote?: Maybe<Scalars['String']>;
  readonly facilityType?: Maybe<KeyValue>;
  readonly facilityTypeId?: Maybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly inactiveContacts?: Maybe<ReadonlyArray<Contact>>;
  readonly loadFromType?: Maybe<KeyValue>;
  readonly loadFromTypeId?: Maybe<Scalars['ID']>;
  readonly mainAddress?: Maybe<Address>;
  readonly mainContact?: Maybe<Contact>;
  readonly name: Scalars['String'];
  readonly notes?: Maybe<ReadonlyArray<Note>>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly scaleNote?: Maybe<Scalars['String']>;
  readonly schedules: ReadonlyArray<Schedule>;
  readonly schedulingContact?: Maybe<Scalars['String']>;
  readonly schedulingSystemType?: Maybe<KeyValue>;
  readonly schedulingSystemTypeId?: Maybe<Scalars['ID']>;
  /** @deprecated facility_identifiers table to be used */
  readonly sourceId?: Maybe<Scalars['String']>;
  readonly sourceType?: Maybe<Scalars['String']>;
  readonly status?: Maybe<FacilityStatusTypeEnum>;
  readonly taxExempt?: Maybe<Scalars['Boolean']>;
  readonly timezone?: Maybe<Scalars['String']>;
  readonly unloadFromType?: Maybe<KeyValue>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
  readonly facilityNoteDefaults?: Maybe<FacilityNoteDefaults>;
  readonly facilityLoadDefaults?: Maybe<FacilityLoadDefaults>;
  readonly customerCommodities?: Maybe<ReadonlyArray<CustomerCommodity>>;
  readonly customerFacilities?: Maybe<ReadonlyArray<CustomerFacility>>;
};


export type FacilityCustomerFacilitiesArgs = {
  customerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type FacilityAddress = {
  readonly __typename?: 'FacilityAddress';
  readonly addressTypes?: Maybe<ReadonlyArray<KeyValue>>;
  readonly addressType?: Maybe<KeyValue>;
  readonly addressTypeId?: Maybe<Scalars['ID']>;
  readonly facililityId: Scalars['ID'];
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly geopoint: Geopoint;
  readonly id: Scalars['ID'];
  readonly isVerified: Scalars['Boolean'];
  readonly main: Scalars['Boolean'];
  readonly postalCode: Scalars['String'];
  readonly province: Scalars['String'];
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly state: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: Maybe<Scalars['String']>;
  readonly thirdPartyId?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
};

export type FacilityAddressesQueryInput = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly radius?: InputMaybe<UnitOfDistanceInput>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly street1?: InputMaybe<Scalars['String']>;
  readonly street2?: InputMaybe<Scalars['String']>;
};

export type FacilityAddressInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly addressTypeId?: InputMaybe<Scalars['ID']>;
  readonly addressTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isVerified?: InputMaybe<Scalars['Boolean']>;
  readonly main: Scalars['Boolean'];
  readonly postalCode: Scalars['String'];
  readonly province?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly state: Scalars['String'];
  readonly street1: Scalars['String'];
  readonly street2?: InputMaybe<Scalars['String']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type FacilityArgumentInput = {
  readonly code?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type FacilityArgumentInputV2 = {
  readonly code?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
};

/** The connection type for Facility. */
export type FacilityConnection = {
  readonly __typename?: 'FacilityConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<FacilityEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

export type FacilityConnectionV2 = {
  readonly __typename?: 'FacilityConnectionV2';
  readonly edges: ReadonlyArray<FacilityEdgeV2>;
  readonly pageInfo: PageInfo;
};

export type FacilityContact = {
  readonly __typename?: 'FacilityContact';
  readonly chatType?: Maybe<KeyValue>;
  readonly chatTypeId?: Maybe<Scalars['ID']>;
  readonly chatUsername?: Maybe<Scalars['String']>;
  readonly contactType: KeyValue;
  readonly contactTypeId: Scalars['ID'];
  readonly active: Scalars['Boolean'];
  readonly facilityId: Scalars['ID'];
  readonly emailAddress?: Maybe<Scalars['String']>;
  readonly extension?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly id: Scalars['ID'];
  readonly main: Scalars['Boolean'];
  readonly name?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly riskInfoSource?: Maybe<RiskInformationSourceTypeEnum>;
  readonly thirdPartyId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
};

export type FacilityContactInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly chatTypeId?: InputMaybe<Scalars['ID']>;
  readonly chatUsername?: InputMaybe<Scalars['String']>;
  readonly contactTypeId?: InputMaybe<Scalars['ID']>;
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly emailAddress?: InputMaybe<Scalars['String']>;
  readonly extension?: InputMaybe<Scalars['String']>;
  readonly faxNumber?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly riskInfoSource?: InputMaybe<RiskInformationSourceTypeEnum>;
  readonly riskInfoUpdatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type FacilityDirection = {
  readonly __typename?: 'FacilityDirection';
  readonly id?: Maybe<Scalars['ID']>;
  readonly facilityId: Scalars['ID'];
  readonly source?: Maybe<KeyValue>;
  readonly sourceId?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly averageTime?: Maybe<Scalars['Int']>;
  readonly directions: Scalars['String'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
};

export type FacilityDocument = {
  readonly __typename?: 'FacilityDocument';
  readonly id: Scalars['ID'];
  readonly facilityId: Scalars['String'];
  /** The name of the file to be displayed to the user, not necessarily the filename */
  readonly name: Scalars['String'];
  /** The name of the file provided during upload */
  readonly filename: Scalars['String'];
  readonly uploadedByUserId: Scalars['String'];
  readonly updatedByUserId: Scalars['String'];
  readonly uploadDate: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  /** facilityDocumentSourceType KVT ID */
  readonly sourceId: Scalars['ID'];
  /** employeeDepartment KVT ID */
  readonly departmentTypeId: Scalars['ID'];
  /** facilityDocumentType KVT ID */
  readonly documentTypeId: Scalars['ID'];
  /** facilityThirdPartyDocument ID */
  readonly thirdPartyDocumentId?: Maybe<Scalars['ID']>;
  /** uri to access the document */
  readonly uri: Scalars['String'];
  readonly uploadedByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly facilityV2?: Maybe<FacilityV2>;
};

export type FacilityDocumentConnection = {
  readonly __typename?: 'FacilityDocumentConnection';
  readonly edges: ReadonlyArray<FacilityDocumentEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Float'];
};

export type FacilityDocumentEdge = {
  readonly __typename?: 'FacilityDocumentEdge';
  readonly cursor: Scalars['String'];
  readonly node: FacilityDocument;
};

export type FacilityDocumentFilter = {
  readonly facilityId: Scalars['ID'];
};

/** An edge in a connection. */
export type FacilityEdge = {
  readonly __typename?: 'FacilityEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: Facility;
};

export type FacilityEdgeV2 = {
  readonly __typename?: 'FacilityEdgeV2';
  readonly cursor: Scalars['String'];
  readonly node: FacilityV2;
};

export type FacilityHoliday = {
  readonly __typename?: 'FacilityHoliday';
  readonly id?: Maybe<Scalars['ID']>;
  readonly facilityId: Scalars['ID'];
  readonly holiday: KeyValue;
  readonly holidayId?: Maybe<Scalars['String']>;
  readonly facilityOpenState: KeyValue;
  readonly facilityOpenStateId?: Maybe<Scalars['String']>;
  readonly holidayDate: Scalars['ISO8601DateTime'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly weekday?: Maybe<Scalars['String']>;
  readonly startOffsetMs?: Maybe<Scalars['Int']>;
  readonly endOffsetMs?: Maybe<Scalars['Int']>;
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
};

export type FacilityIdentifier = {
  readonly __typename?: 'FacilityIdentifier';
  readonly code: Scalars['String'];
  readonly facilityId: Scalars['ID'];
  readonly facilityIdentifierType: KeyValue;
  readonly facilityIdentifierTypeId: Scalars['ID'];
  readonly id: Scalars['ID'];
};

export type FacilityIdentifierInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly code: Scalars['String'];
  readonly facilityIdentifierTypeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type FacilityIdentifierInputV2 = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly code: Scalars['String'];
  readonly facilityIdentifierTypeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type FacilityIdentifierQueryInput = {
  readonly code: Scalars['String'];
  readonly identifierType: Scalars['String'];
};

export type FacilityIdentifierStandaloneInput = {
  readonly code: Scalars['String'];
  readonly facility: FacilityArgumentInput;
  readonly facilityIdentifierTypeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type FacilityIdentifierStandaloneInputV2 = {
  readonly code: Scalars['String'];
  readonly facility: FacilityArgumentInputV2;
  readonly facilityIdentifierTypeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type FacilityIdentifierV2 = {
  readonly __typename?: 'FacilityIdentifierV2';
  readonly code: Scalars['String'];
  readonly facilityId: Scalars['ID'];
  readonly facilityIdentifierType: KeyValue;
  readonly facilityIdentifierTypeId: Scalars['ID'];
  readonly id: Scalars['ID'];
};

export type FacilityImporterInput = {
  readonly addresses?: InputMaybe<ReadonlyArray<FacilityAddressInput>>;
  readonly code: Scalars['String'];
  readonly dba?: InputMaybe<Scalars['String']>;
  readonly facilityNote?: InputMaybe<Scalars['String']>;
  readonly facilityTypeId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly loadFromTypeId?: InputMaybe<Scalars['ID']>;
  readonly mainContactId?: InputMaybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly scaleNote?: InputMaybe<Scalars['String']>;
  readonly schedules?: InputMaybe<ReadonlyArray<ScheduleInputV2>>;
  readonly schedulingContact?: InputMaybe<Scalars['String']>;
  readonly schedulingSystemTypeId?: InputMaybe<Scalars['ID']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  readonly sourceType?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<FacilityStatusTypeEnum>;
  readonly taxExempt?: InputMaybe<Scalars['Boolean']>;
  readonly unloadFromTypeId?: InputMaybe<Scalars['ID']>;
};

export type FacilityInput = {
  readonly addresses?: InputMaybe<ReadonlyArray<AddressInput>>;
  readonly code: Scalars['String'];
  readonly contacts?: InputMaybe<ReadonlyArray<ContactInput>>;
  readonly dba?: InputMaybe<Scalars['String']>;
  readonly facilityIdentifiers?: InputMaybe<ReadonlyArray<FacilityIdentifierInput>>;
  readonly facilityNote?: InputMaybe<Scalars['String']>;
  readonly facilityTypeId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly loadFromTypeId?: InputMaybe<Scalars['ID']>;
  readonly mainContactId?: InputMaybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly notes?: InputMaybe<ReadonlyArray<NoteInput>>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly scaleNote?: InputMaybe<Scalars['String']>;
  readonly schedules?: InputMaybe<ReadonlyArray<ScheduleInput>>;
  readonly schedulingContact?: InputMaybe<Scalars['String']>;
  readonly schedulingSystemTypeId?: InputMaybe<Scalars['ID']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  readonly sourceType?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<FacilityStatusTypeEnum>;
  readonly taxExempt?: InputMaybe<Scalars['Boolean']>;
  readonly unloadFromTypeId?: InputMaybe<Scalars['ID']>;
};

export type FacilityInputV2 = {
  readonly addresses?: InputMaybe<ReadonlyArray<FacilityAddressInput>>;
  readonly code: Scalars['String'];
  readonly contacts?: InputMaybe<ReadonlyArray<FacilityContactInput>>;
  readonly dba?: InputMaybe<Scalars['String']>;
  readonly facilityIdentifiers?: InputMaybe<ReadonlyArray<FacilityIdentifierInputV2>>;
  readonly facilityNote?: InputMaybe<Scalars['String']>;
  readonly facilityTypeId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly loadFromTypeId?: InputMaybe<Scalars['ID']>;
  readonly mainContactId?: InputMaybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly notes?: InputMaybe<ReadonlyArray<FacilityNoteInput>>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly scaleNote?: InputMaybe<Scalars['String']>;
  readonly schedules?: InputMaybe<ReadonlyArray<ScheduleInputV2>>;
  readonly schedulingContact?: InputMaybe<Scalars['String']>;
  readonly schedulingSystemTypeId?: InputMaybe<Scalars['ID']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  readonly sourceType?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<FacilityStatusTypeEnum>;
  readonly taxExempt?: InputMaybe<Scalars['Boolean']>;
  readonly unloadFromTypeId?: InputMaybe<Scalars['ID']>;
};

export type FacilityLoadDefaults = {
  readonly __typename?: 'FacilityLoadDefaults';
  readonly id: Scalars['ID'];
  readonly facilityId: Scalars['String'];
  readonly defaultEquipments?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly length?: Maybe<Scalars['Float']>;
  readonly width?: Maybe<Scalars['Float']>;
  readonly height?: Maybe<Scalars['Float']>;
  readonly schedSystem?: Maybe<Scalars['String']>;
  readonly schedURL?: Maybe<Scalars['String']>;
  readonly specialRequirements?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly autoSchedEligible?: Maybe<Scalars['Boolean']>;
  readonly autoSchedDefault?: Maybe<Scalars['Boolean']>;
  readonly facility?: Maybe<Facility>;
  readonly facilityV2?: Maybe<FacilityV2>;
  readonly facilityLoadDefaultsEquipment?: Maybe<ReadonlyArray<FacilityLoadDefaultsEquipment>>;
  readonly facilityLoadDefaultsSpecialRequirement?: Maybe<ReadonlyArray<FacilityLoadDefaultsSpecialRequirement>>;
  readonly facilityLoadDefaultsDivision?: Maybe<ReadonlyArray<FacilityLoadDefaultsDivision>>;
  readonly facilityLoadDefaultsBusinessUnit?: Maybe<ReadonlyArray<FacilityLoadDefaultsBusinessUnit>>;
};

export type FacilityLoadDefaultsBusinessUnit = {
  readonly __typename?: 'FacilityLoadDefaultsBusinessUnit';
  readonly id: Scalars['ID'];
  readonly facilityLoadDefaultsId?: Maybe<Scalars['String']>;
  readonly businessUnit: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly facilityLoadDefaults?: Maybe<FacilityLoadDefaults>;
};

export type FacilityLoadDefaultsConnection = {
  readonly __typename?: 'FacilityLoadDefaultsConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<FacilityLoadDefaultsEdge>;
};

export type FacilityLoadDefaultsDivision = {
  readonly __typename?: 'FacilityLoadDefaultsDivision';
  readonly id?: Maybe<Scalars['ID']>;
  readonly facilityLoadDefaultsId?: Maybe<Scalars['String']>;
  readonly division: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly facilityLoadDefaults?: Maybe<FacilityLoadDefaults>;
};

export type FacilityLoadDefaultsEdge = {
  readonly __typename?: 'FacilityLoadDefaultsEdge';
  readonly node: FacilityLoadDefaults;
  readonly cursor: Scalars['String'];
};

export type FacilityLoadDefaultsEquipment = {
  readonly __typename?: 'FacilityLoadDefaultsEquipment';
  readonly id: Scalars['ID'];
  readonly facilityLoadDefaultsId: Scalars['String'];
  readonly defaultEquipment: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly facilityLoadDefaults?: Maybe<FacilityLoadDefaults>;
};

export type FacilityLoadDefaultsFilter = {
  /** facilityId to filter by */
  readonly facilityId?: InputMaybe<Scalars['ID']>;
};

export type FacilityLoadDefaultsSpecialRequirement = {
  readonly __typename?: 'FacilityLoadDefaultsSpecialRequirement';
  readonly id: Scalars['ID'];
  readonly facilityLoadDefaultsId: Scalars['String'];
  readonly specialRequirement: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly facilityLoadDefaults?: Maybe<FacilityLoadDefaults>;
};

export type FacilityNote = {
  readonly __typename?: 'FacilityNote';
  readonly createdAt: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly noteType: Scalars['String'];
  readonly noteableId: Scalars['ID'];
  readonly noteableType: Scalars['String'];
  readonly text?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['Iso8601Date'];
};

export type FacilityNoteDefaults = {
  readonly __typename?: 'FacilityNoteDefaults';
  readonly id: Scalars['ID'];
  readonly facilityId: Scalars['String'];
  readonly facilityLoadNote?: Maybe<Scalars['String']>;
  readonly externalFacilityNote?: Maybe<Scalars['String']>;
  readonly rateConfirmNote?: Maybe<Scalars['String']>;
  readonly note?: Maybe<Scalars['String']>;
  readonly noteType?: Maybe<Scalars['String']>;
  readonly facility?: Maybe<Facility>;
  readonly facilityV2?: Maybe<FacilityV2>;
  readonly facilityNoteDefaultsDivision?: Maybe<ReadonlyArray<FacilityNoteDefaultsDivision>>;
  readonly facilityNoteDefaultsBusinessUnits?: Maybe<ReadonlyArray<FacilityNoteDefaultsBusinessUnit>>;
};

export type FacilityNoteDefaultsBusinessUnit = {
  readonly __typename?: 'FacilityNoteDefaultsBusinessUnit';
  readonly id: Scalars['ID'];
  readonly noteDefaultId?: Maybe<Scalars['String']>;
  readonly businessUnit: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly facilityNoteDefaults?: Maybe<FacilityNoteDefaults>;
};

export type FacilityNoteDefaultsDivision = {
  readonly __typename?: 'FacilityNoteDefaultsDivision';
  readonly id?: Maybe<Scalars['ID']>;
  readonly noteDefaultId?: Maybe<Scalars['String']>;
  readonly division: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly facilityNoteDefaults?: Maybe<FacilityNoteDefaults>;
};

export type FacilityNoteInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly noteType?: InputMaybe<NoteTypeEnum>;
  readonly noteableId?: InputMaybe<Scalars['ID']>;
  readonly text?: InputMaybe<Scalars['String']>;
};

export enum FacilityStatusTypeEnum {
  Active = 'active',
  Duplicate = 'duplicate',
  Inactive = 'inactive',
  New = 'New',
  SystemGenerated = 'systemGenerated'
}

export type FacilitySublocation = {
  readonly __typename?: 'FacilitySublocation';
  readonly id?: Maybe<Scalars['ID']>;
  readonly facilityId: Scalars['ID'];
  readonly sublocation: Scalars['String'];
  readonly description: Scalars['String'];
  readonly type: KeyValue;
  readonly typeId: Scalars['String'];
  readonly locked?: Maybe<KeyValue>;
  readonly lockedId?: Maybe<Scalars['String']>;
  readonly guarded?: Maybe<KeyValue>;
  readonly guardedId?: Maybe<Scalars['String']>;
  readonly checkInMethod?: Maybe<Scalars['String']>;
  readonly directions?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
};

export type FacilitySublocationAccommodation = {
  readonly __typename?: 'FacilitySublocationAccommodation';
  readonly id?: Maybe<Scalars['ID']>;
  readonly sublocationId: Scalars['ID'];
  readonly sublocation: Scalars['String'];
  readonly accommodation: Scalars['String'];
  readonly rating?: Maybe<KeyValue>;
  readonly ratingId?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
};

export type FacilitySublocationAccommodationConnection = {
  readonly __typename?: 'FacilitySublocationAccommodationConnection';
  readonly edges: ReadonlyArray<FacilitySublocationAccommodationEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Float'];
};

export type FacilitySublocationAccommodationEdge = {
  readonly __typename?: 'FacilitySublocationAccommodationEdge';
  readonly cursor: Scalars['String'];
  readonly node: FacilitySublocationAccommodation;
};

export type FacilitySublocationAccommodationFilter = {
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly sublocationId?: InputMaybe<Scalars['ID']>;
};

export type FacilitySublocationActivity = {
  readonly __typename?: 'FacilitySublocationActivity';
  readonly id?: Maybe<Scalars['ID']>;
  readonly sublocationId: Scalars['ID'];
  readonly activity: Scalars['String'];
  readonly averageTime?: Maybe<Scalars['Int']>;
  readonly averageTime100Loads?: Maybe<Scalars['Int']>;
  readonly variability?: Maybe<KeyValue>;
  readonly variabilityId?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly sublocation: Scalars['String'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
};

export type FacilitySublocationActivityConnection = {
  readonly __typename?: 'FacilitySublocationActivityConnection';
  readonly edges: ReadonlyArray<FacilitySublocationActivityEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Float'];
};

export type FacilitySublocationActivityEdge = {
  readonly __typename?: 'FacilitySublocationActivityEdge';
  readonly cursor: Scalars['String'];
  readonly node: FacilitySublocationActivity;
};

export type FacilitySublocationActivityFilter = {
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly sublocationId?: InputMaybe<Scalars['ID']>;
};

export type FacilitySublocationGate = {
  readonly __typename?: 'FacilitySublocationGate';
  readonly id?: Maybe<Scalars['ID']>;
  readonly sublocationId: Scalars['ID'];
  readonly sublocation: Scalars['String'];
  readonly gate: Scalars['String'];
  readonly guardType?: Maybe<KeyValue>;
  readonly guardTypeId?: Maybe<Scalars['String']>;
  readonly documentationNeeded?: Maybe<ReadonlyArray<KeyValue>>;
  readonly documentationNeededId?: Maybe<Scalars['String']>;
  readonly idChecked?: Maybe<ReadonlyArray<KeyValue>>;
  readonly idCheckedId?: Maybe<Scalars['String']>;
  readonly guardContactInfo?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
};

export type FacilitySublocationGateConnection = {
  readonly __typename?: 'FacilitySublocationGateConnection';
  readonly edges: ReadonlyArray<FacilitySublocationGateEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Float'];
};

export type FacilitySublocationGateEdge = {
  readonly __typename?: 'FacilitySublocationGateEdge';
  readonly cursor: Scalars['String'];
  readonly node: FacilitySublocationGate;
};

export type FacilitySublocationGateFilter = {
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly sublocationId?: InputMaybe<Scalars['ID']>;
};

export type FacilityV2 = {
  readonly __typename?: 'FacilityV2';
  readonly addresses: ReadonlyArray<FacilityAddress>;
  readonly addressTypes?: Maybe<ReadonlyArray<KeyValue>>;
  readonly code: Scalars['String'];
  readonly contacts: ReadonlyArray<FacilityContact>;
  readonly inactiveContacts?: Maybe<ReadonlyArray<FacilityContact>>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly customerRelationships?: Maybe<ReadonlyArray<CustomerFacilityRelationshipV2>>;
  readonly dba?: Maybe<Scalars['String']>;
  readonly externalNotes?: Maybe<ReadonlyArray<FacilityNote>>;
  readonly facilityIdentifiers?: Maybe<ReadonlyArray<FacilityIdentifierV2>>;
  readonly facilityNote?: Maybe<Scalars['String']>;
  readonly facilityType?: Maybe<KeyValue>;
  readonly facilityTypeId?: Maybe<Scalars['ID']>;
  readonly directions?: Maybe<ReadonlyArray<FacilityDirection>>;
  readonly holidays?: Maybe<ReadonlyArray<FacilityHoliday>>;
  readonly id: Scalars['ID'];
  readonly loadFromType?: Maybe<KeyValue>;
  readonly loadFromTypeId?: Maybe<Scalars['ID']>;
  readonly mainAddress?: Maybe<FacilityAddress>;
  readonly mainContact?: Maybe<FacilityContact>;
  readonly name: Scalars['String'];
  readonly notes?: Maybe<ReadonlyArray<FacilityNote>>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly scaleNote?: Maybe<Scalars['String']>;
  readonly schedules: ReadonlyArray<ScheduleV2>;
  readonly schedulingContact?: Maybe<Scalars['String']>;
  readonly schedulingSystemType?: Maybe<KeyValue>;
  readonly schedulingSystemTypeId?: Maybe<Scalars['ID']>;
  readonly sourceType?: Maybe<Scalars['String']>;
  readonly status?: Maybe<FacilityStatusTypeEnum>;
  readonly taxExempt?: Maybe<Scalars['Boolean']>;
  readonly timezone?: Maybe<Scalars['String']>;
  readonly unloadFromType?: Maybe<KeyValue>;
  readonly unloadFromTypeId?: Maybe<Scalars['ID']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly externalFacilityNotes?: Maybe<ReadonlyArray<FacilityNote>>;
  readonly sourceId?: Maybe<Scalars['String']>;
  readonly mainContactId?: Maybe<Scalars['String']>;
  readonly mainAddressId?: Maybe<Scalars['String']>;
  readonly facilityNoteDefaults?: Maybe<FacilityNoteDefaults>;
  readonly facilityLoadDefaults?: Maybe<FacilityLoadDefaults>;
  readonly customerCommodities?: Maybe<ReadonlyArray<CustomerCommodity>>;
  readonly customerFacilities?: Maybe<ReadonlyArray<CustomerFacility>>;
};


export type FacilityV2CustomerRelationshipsArgs = {
  customerFacilityRelationshipLoaderWithLimit?: InputMaybe<Scalars['Int']>;
};


export type FacilityV2CustomerFacilitiesArgs = {
  customerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type Fak = {
  readonly __typename?: 'Fak';
  readonly start?: Maybe<Scalars['Int']>;
  readonly end?: Maybe<Scalars['Int']>;
  readonly ratedAs?: Maybe<Scalars['Int']>;
};

/** Indicates how the fee should be calculated for an advance. */
export enum FeeCalculationEnum {
  /** Indicates that the flatFeeAmount will be used for the fee. */
  Flat = 'Flat',
  /** Indicates that the feePercent will be used along with the approved amount to calculate the fee. */
  Percent = 'Percent'
}

/** Indicates how the net payment should be calculated for an advance. */
export enum FeeMethodEnum {
  /** Indicates that the net payment will be calculated using the Net fee method. */
  Net = 'Net',
  /** Indicates that the net payment will be calculated using the Gross fee method. */
  Gross = 'Gross'
}

export type FieldCachedType = {
  readonly __typename?: 'FieldCachedType';
  readonly timestamp?: Maybe<Scalars['String']>;
  readonly nonce?: Maybe<Scalars['String']>;
};

export type FileExtractConfiguration = {
  readonly __typename?: 'FileExtractConfiguration';
  readonly extractAvailableFields?: Maybe<ReadonlyArray<Maybe<ExtractAvailableField>>>;
  readonly extractConfigurations?: Maybe<ReadonlyArray<Maybe<ExtractConfigurationType>>>;
};

export type FilterServiceProfile = {
  readonly boxType: Scalars['String'];
  readonly originRamp?: InputMaybe<Scalars['String']>;
  readonly destinationRamp?: InputMaybe<Scalars['String']>;
};

export type FinalizedFuelStatus = {
  readonly __typename?: 'FinalizedFuelStatus';
  readonly finalizedFuelStatus?: Maybe<Scalars['String']>;
  readonly orderId: Scalars['ID'];
};

export type FinalizedFuelSurcharge = {
  readonly __typename?: 'FinalizedFuelSurcharge';
  readonly customerId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly fuelProgram?: Maybe<Scalars['String']>;
  readonly fuelSurcharge?: Maybe<Scalars['Decimal']>;
};

export type FindMatchingLanesForRoutesInput = {
  readonly loadCode: Scalars['String'];
  readonly loadId: Scalars['ID'];
  readonly matchInputs: ReadonlyArray<RouteLaneMatchInput>;
};

export type FindMatchingLanesForRoutesResult = {
  readonly __typename?: 'FindMatchingLanesForRoutesResult';
  readonly loadCode: Scalars['String'];
  readonly loadId: Scalars['ID'];
  readonly results: ReadonlyArray<RouteLaneMatch>;
};

export type FindOrCreateFacilityInput = {
  readonly address: StopAddressInput;
  readonly customerId: Scalars['String'];
  readonly locationCode: Scalars['String'];
  readonly stopType: StopTypeEnum;
};

export type FindOrCreateFacilityInputV2 = {
  readonly address: StopAddressInputV2;
  readonly customerId: Scalars['String'];
  readonly locationCode: Scalars['String'];
  readonly stopType: StopTypeEnum;
};

/** Autogenerated return type of FindOrCreateFacility */
export type FindOrCreateFacilityPayload = {
  readonly __typename?: 'FindOrCreateFacilityPayload';
  readonly errors: ReadonlyArray<UserError>;
  /** @deprecated use facilityNullable */
  readonly facility: Facility;
  readonly facilityNullable?: Maybe<Facility>;
};

export type FindOrCreateFacilityPayloadV2 = {
  readonly __typename?: 'FindOrCreateFacilityPayloadV2';
  readonly errors: ReadonlyArray<UserError>;
  readonly facility?: Maybe<FacilityV2>;
};

export type FirstTruckEntriesPayloadWithErrorsAndTruckEntries = {
  readonly __typename?: 'FirstTruckEntriesPayloadWithErrorsAndTruckEntries';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly truckEntry?: Maybe<ReadonlyArray<TruckEntry>>;
};

export type FloatType = {
  readonly __typename?: 'FloatType';
  readonly value?: Maybe<Scalars['Float']>;
};

export type FormattedAddress = {
  readonly __typename?: 'FormattedAddress';
  readonly requestId?: Maybe<Scalars['ID']>;
  readonly addressLine1: Scalars['String'];
  readonly addressLine2?: Maybe<Scalars['String']>;
  readonly addressLine3?: Maybe<Scalars['String']>;
  readonly addressLine4?: Maybe<Scalars['String']>;
  readonly locality: Scalars['String'];
  readonly administrativeArea: Scalars['String'];
  readonly countryAlpha2: Scalars['String'];
  readonly countryAlpha3: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
  readonly UTC: Scalars['String'];
  readonly DST: Scalars['String'];
};

export type Fraction = {
  readonly __typename?: 'Fraction';
  readonly numerator: Scalars['Float'];
  readonly denominator: Scalars['Float'];
};

export type FractionInput = {
  readonly numerator: Scalars['Float'];
  readonly denominator: Scalars['Float'];
};

/** for RegionV2 fields */
export type FreightAssignmentRegion = {
  readonly __typename?: 'FreightAssignmentRegion';
  readonly superRegion?: Maybe<Scalars['String']>;
  readonly region?: Maybe<Scalars['String']>;
  readonly subRegion?: Maybe<Scalars['String']>;
};

export type FuelProgramCustomerConfigurationCreateInput = {
  readonly defaultConfiguration: Scalars['Boolean'];
  readonly transportMode?: InputMaybe<Scalars['String']>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly trailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly region?: InputMaybe<Scalars['String']>;
  readonly fuelProgram: Scalars['String'];
  readonly isEstimate: Scalars['Boolean'];
  readonly customerId: Scalars['Guid'];
  readonly shipper?: InputMaybe<Scalars['String']>;
  readonly references?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type FuelProgramCustomerConfigurationModel = {
  readonly __typename?: 'FuelProgramCustomerConfigurationModel';
  readonly fuelProgramCustomerConfigurationId: Scalars['ID'];
  readonly createdByUserId?: Maybe<Scalars['Guid']>;
  readonly createdByDesc?: Maybe<Scalars['String']>;
  readonly createdOnUtc?: Maybe<Scalars['DateTime']>;
  readonly updatedByUserId?: Maybe<Scalars['Guid']>;
  readonly updatedByDesc?: Maybe<Scalars['String']>;
  readonly updatedOnUtc?: Maybe<Scalars['DateTime']>;
  readonly defaultConfiguration?: Maybe<Scalars['Boolean']>;
  readonly transportMode?: Maybe<Scalars['String']>;
  readonly loadSize?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly trailerTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly region?: Maybe<Scalars['String']>;
  readonly fuelProgram?: Maybe<Scalars['String']>;
  readonly isEstimate?: Maybe<Scalars['Boolean']>;
  readonly customerId: Scalars['Guid'];
  readonly shipper?: Maybe<Scalars['String']>;
  readonly references?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type FuelProgramCustomerConfigurationUpdateInput = {
  readonly fuelProgramCustomerConfigurationId: Scalars['ID'];
  readonly defaultConfiguration: Scalars['Boolean'];
  readonly transportMode?: InputMaybe<Scalars['String']>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly trailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly region?: InputMaybe<Scalars['String']>;
  readonly fuelProgram: Scalars['String'];
  readonly isEstimate: Scalars['Boolean'];
  readonly customerId: Scalars['Guid'];
  readonly shipper?: InputMaybe<Scalars['String']>;
  readonly references?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type GateReservationConnection = {
  readonly __typename?: 'GateReservationConnection';
  readonly edges?: Maybe<ReadonlyArray<Maybe<GateReservationGraphQlEdge>>>;
  readonly pageInfo?: Maybe<PageInfo>;
  readonly totalCount?: Maybe<Scalars['Int']>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<TrucksUserError>>>;
};

export type GateReservationFilter = {
  readonly carrierId: Scalars['ID'];
  /** DEPRECATED: Use containerProgramId instead */
  readonly containerProgramCarrierId?: InputMaybe<Scalars['ID']>;
  readonly containerProgramId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly endDateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly startDateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly containerTypeId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly reservationNumber?: InputMaybe<Scalars['String']>;
  readonly sourceTypeId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type GateReservationGraphQl = {
  readonly __typename?: 'GateReservationGraphQL';
  readonly id: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly originRampFacilityId: Scalars['ID'];
  readonly destinationRampFacilityId?: Maybe<Scalars['ID']>;
  readonly reservationNumber: Scalars['String'];
  readonly containerTypeId: Scalars['ID'];
  readonly containerLength?: Maybe<UnitOfDistance>;
  readonly startDatetimeUtc: Scalars['ISO8601DateTime'];
  readonly startTimezone?: Maybe<Scalars['String']>;
  readonly endDatetimeUtc: Scalars['ISO8601DateTime'];
  readonly endTimezone: Scalars['String'];
  /**
   * DEPRECATED: Use containerProgramId instead
   * @deprecated Use containerProgramId instead
   */
  readonly containerProgramCarrierId?: Maybe<Scalars['ID']>;
  readonly containerProgramId?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly createdByUserId: Scalars['ID'];
  readonly updatedByUserId: Scalars['ID'];
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly sourceTypeId?: Maybe<Scalars['String']>;
  /** @deprecated Use originRampFacility instead */
  readonly legacyOriginRampFacility?: Maybe<Facility>;
  readonly originRampFacility: FacilityV2;
  /** @deprecated Use destinationRampFacility instead */
  readonly legacyDestinationRampFacility?: Maybe<Facility>;
  readonly destinationRampFacility?: Maybe<FacilityV2>;
  readonly containerProgram?: Maybe<KeyValueType>;
  readonly containerType?: Maybe<KeyValueType>;
  /** @deprecated Use carrier instead */
  readonly legacyCarrier?: Maybe<Carrier>;
  readonly carrier: CarrierV2;
  /** @deprecated Use createdByUser instead */
  readonly legacyCreatedByUser?: Maybe<User>;
  readonly createdByUser: UserV2;
  /** @deprecated Use updatedByUser instead */
  readonly legacyUpdatedByUser?: Maybe<User>;
  readonly updatedByUser: UserV2;
};

export type GateReservationGraphQlEdge = {
  readonly __typename?: 'GateReservationGraphQLEdge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<GateReservationGraphQl>;
};

/** Gate Reservation Match Result from ElasticSearch (v2) */
export type GateReservationMatch = {
  readonly __typename?: 'GateReservationMatch';
  readonly id: Scalars['ID'];
  readonly routeId: Scalars['String'];
  readonly loadId: Scalars['String'];
  readonly capacityNeed?: Maybe<ReadonlyArray<CapacityNeed>>;
  readonly originDeadheadMileage?: Maybe<Scalars['Float']>;
  readonly destinationDeadheadMileage?: Maybe<Scalars['Float']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly routePickupCity?: Maybe<Scalars['String']>;
  readonly routePickupState?: Maybe<Scalars['String']>;
  readonly routePickupCountry?: Maybe<Scalars['String']>;
  readonly routePickupStartDate?: Maybe<Scalars['DateTime']>;
  readonly routePickupEndDate?: Maybe<Scalars['DateTime']>;
  readonly routePickupTimeZone?: Maybe<Scalars['String']>;
  readonly routePickupStartDay?: Maybe<Scalars['String']>;
  readonly routePickupEndDay?: Maybe<Scalars['String']>;
  readonly routeFirstStopDateRange?: Maybe<AvailableDateRange>;
  readonly routeFirstStopLiveType?: Maybe<ScheduleDropTypeEnum>;
  readonly routeDeliveryCity?: Maybe<Scalars['String']>;
  readonly routeDeliveryState?: Maybe<Scalars['String']>;
  readonly routeDeliveryCountry?: Maybe<Scalars['String']>;
  readonly routeDeliveryStartDate?: Maybe<Scalars['DateTime']>;
  readonly routeDeliveryEndDate?: Maybe<Scalars['DateTime']>;
  readonly routeDeliveryTimeZone?: Maybe<Scalars['String']>;
  readonly routeDeliveryStartDay?: Maybe<Scalars['String']>;
  readonly routeDeliveryEndDay?: Maybe<Scalars['String']>;
  readonly routeLastStopDateRange?: Maybe<AvailableDateRange>;
  readonly routeLastStopLiveType?: Maybe<ScheduleDropTypeEnum>;
  readonly routeLength?: Maybe<Scalars['Int']>;
  readonly routeTrailerLength?: Maybe<Length>;
  readonly routeCommodityMinTemperature?: Maybe<TemperatureV2>;
  readonly stopCount?: Maybe<Scalars['Int']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerRepName?: Maybe<Scalars['String']>;
  readonly routeTrailerTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly segmentCode?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly requirements?: Maybe<ReadonlyArray<OrderRequirementInfo>>;
  readonly hotRoute?: Maybe<Scalars['Boolean']>;
  readonly tmsType?: Maybe<Scalars['String']>;
  readonly mainCustomerRepEmployeeId?: Maybe<Scalars['String']>;
  readonly expectedMaxWeight?: Maybe<Scalars['Float']>;
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly equipment?: Maybe<Scalars['String']>;
  readonly routeLoadRouteSequence?: Maybe<Fraction>;
  readonly gateReservationId: Scalars['String'];
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly originRampFacilityId?: Maybe<Scalars['String']>;
  readonly originRampFacilityName?: Maybe<Scalars['String']>;
  readonly originRampCity?: Maybe<Scalars['String']>;
  readonly originRampState?: Maybe<Scalars['String']>;
  readonly destinationRampFacilityId?: Maybe<Scalars['String']>;
  readonly destinationRampFacilityName?: Maybe<Scalars['String']>;
  readonly destinationRampCity?: Maybe<Scalars['String']>;
  readonly destinationRampState?: Maybe<Scalars['String']>;
  readonly routeOriginRamp?: Maybe<Scalars['String']>;
  readonly routeDestinationRamp?: Maybe<Scalars['String']>;
  readonly route?: Maybe<AvailableRoute>;
  readonly gateReservation?: Maybe<AvailableGateReservation>;
};

export type GateReservationMatchConnection = {
  readonly __typename?: 'GateReservationMatchConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<GateReservationMatchEdge>;
};

export type GateReservationMatchEdge = {
  readonly __typename?: 'GateReservationMatchEdge';
  readonly node: GateReservationMatch;
  /** Used in `before` and `after` args */
  readonly cursor: Scalars['String'];
};

/** Gate Reservation Match Filter */
export type GateReservationMatchFilter = {
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly sortDirection?: InputMaybe<Scalars['String']>;
  readonly matchId?: InputMaybe<Scalars['String']>;
  readonly routeId?: InputMaybe<Scalars['String']>;
  readonly routeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadId?: InputMaybe<Scalars['String']>;
  readonly capacityNeed?: InputMaybe<ReadonlyArray<CapacityNeed>>;
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly carrierName?: InputMaybe<Scalars['String']>;
  readonly routeActivationStatus?: InputMaybe<Scalars['String']>;
  readonly routeActivationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly originDeadheadInMiles?: InputMaybe<Scalars['Float']>;
  readonly destinationDeadheadInMiles?: InputMaybe<Scalars['Float']>;
  readonly includeDeleted?: InputMaybe<Scalars['Boolean']>;
  readonly routeNumber?: InputMaybe<Scalars['String']>;
  readonly routePickupCity?: InputMaybe<Scalars['String']>;
  readonly routePickupState?: InputMaybe<Scalars['String']>;
  readonly routePickupStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routePickupCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routePickupDate?: InputMaybe<Scalars['DateTime']>;
  readonly routePickupTimeZone?: InputMaybe<Scalars['String']>;
  readonly routeDeliveryCity?: InputMaybe<Scalars['String']>;
  readonly routeDeliveryState?: InputMaybe<Scalars['String']>;
  readonly routeDeliveryStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDeliveryCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDeliveryDate?: InputMaybe<Scalars['DateTime']>;
  readonly routeDeliveryTimeZone?: InputMaybe<Scalars['String']>;
  readonly routeCommodityMinTemperature?: InputMaybe<TemperatureV2Input>;
  readonly routeLength?: InputMaybe<Scalars['Int']>;
  readonly stopCount?: InputMaybe<Scalars['Int']>;
  readonly maxCost?: InputMaybe<Scalars['Float']>;
  readonly routeFirstStopLiveType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeLastStopLiveType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly customerName?: InputMaybe<Scalars['String']>;
  readonly customerRepName?: InputMaybe<Scalars['String']>;
  readonly equipment?: InputMaybe<Scalars['String']>;
  readonly routeTrailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  readonly requirements?: InputMaybe<Scalars['String']>;
  readonly hotRoute?: InputMaybe<Scalars['Boolean']>;
  readonly routeTrailerLength?: InputMaybe<UnitOfLengthInput>;
  readonly gateReservationId?: InputMaybe<Scalars['String']>;
  readonly gateReservationIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  readonly dateRangeFilterTarget?: InputMaybe<MatchesDateRangeFilterTarget>;
  readonly timeZone?: InputMaybe<Scalars['String']>;
  readonly originRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly originRampFacilityName?: InputMaybe<Scalars['String']>;
  readonly originRampCity?: InputMaybe<Scalars['String']>;
  readonly originRampStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRampFacilityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRampFacilityName?: InputMaybe<Scalars['String']>;
  readonly destinationRampCity?: InputMaybe<Scalars['String']>;
  readonly destinationRampStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeOriginRamp?: InputMaybe<Scalars['String']>;
  readonly routeDestinationRamp?: InputMaybe<Scalars['String']>;
};

export type GateReservationPayloadWithErrorsAndGateReservation = {
  readonly __typename?: 'GateReservationPayloadWithErrorsAndGateReservation';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly gateReservation?: Maybe<GateReservationGraphQl>;
};

export type GateReservationSort = {
  readonly key: GateReservationSortKeysEnum;
  readonly direction: TruckEntrySortDirectionsEnum;
};

export enum GateReservationSortKeysEnum {
  ReservationNumber = 'reservationNumber',
  StartDatetimeUtc = 'startDatetimeUtc',
  EndDatetimeUtc = 'endDatetimeUtc',
  SourceTypeId = 'sourceTypeId',
  UpdatedAt = 'updatedAt',
  ContainerProgramId = 'containerProgramId',
  ContainerTypeId = 'containerTypeId'
}

export type GeocodeInput = {
  readonly addressLine1?: InputMaybe<Scalars['String']>;
  readonly locality?: InputMaybe<Scalars['String']>;
  readonly administrativeArea?: InputMaybe<Scalars['String']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly countryCode?: InputMaybe<Scalars['String']>;
  readonly requestId?: InputMaybe<Scalars['ID']>;
  readonly freeText?: InputMaybe<Scalars['String']>;
};

export type GeocodeInputV2 = {
  readonly addressLine1?: InputMaybe<Scalars['String']>;
  readonly locality?: InputMaybe<Scalars['String']>;
  readonly administrativeArea?: InputMaybe<Scalars['String']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly countryCode?: InputMaybe<Scalars['String']>;
  readonly requestId?: InputMaybe<Scalars['ID']>;
  readonly freeText?: InputMaybe<Scalars['String']>;
};

export type GeocodeResponse = {
  readonly __typename?: 'GeocodeResponse';
  readonly lat?: Maybe<Scalars['Float']>;
  readonly lon?: Maybe<Scalars['Float']>;
  readonly addressLine1?: Maybe<Scalars['String']>;
  readonly locality?: Maybe<Scalars['String']>;
  readonly administrativeArea?: Maybe<Scalars['String']>;
  readonly postalCode?: Maybe<Scalars['String']>;
  readonly countryCode?: Maybe<Scalars['String']>;
  readonly countryCodeISO3?: Maybe<Scalars['String']>;
  readonly countryName?: Maybe<Scalars['String']>;
  readonly resultType: GeocodeResultType;
  readonly requestId?: Maybe<Scalars['ID']>;
  readonly matchType?: Maybe<Scalars['String']>;
};

export enum GeocodeResultType {
  Approximate = 'Approximate',
  Exact = 'Exact'
}

export type Geography = {
  readonly __typename?: 'Geography';
  readonly abbreviatedState?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly county?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly geoPoint?: Maybe<Geopoint>;
  readonly geographyType: GeographyTypeEnum;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly source?: Maybe<Scalars['String']>;
  readonly sourceId?: Maybe<Scalars['Int']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly timezone?: Maybe<Scalars['String']>;
  readonly township?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
};

export type GeographyAdminArea = {
  readonly __typename?: 'GeographyAdminArea';
  readonly twoCharacter?: Maybe<Scalars['String']>;
  readonly threeCharacter?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
};

export type GeographyAdminAreaInput = {
  readonly adminArea: Scalars['String'];
};

export type GeographyCursorPageInfo = {
  readonly __typename?: 'GeographyCursorPageInfo';
  readonly lastCursor?: Maybe<Scalars['Int']>;
  readonly hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type GeographyFilter = {
  readonly geographyIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly geographyType?: InputMaybe<GeographyServiceTypeEnum>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly geographyId?: InputMaybe<Scalars['Int']>;
  readonly modifiedAfter?: InputMaybe<Scalars['String']>;
  readonly includeDeleted?: InputMaybe<Scalars['Boolean']>;
  readonly legacyId?: InputMaybe<Scalars['String']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type GeographyPreferredNameInput = {
  readonly sourceId: Scalars['Int'];
  readonly preferredName: Scalars['String'];
};

export type GeographyRadiusSearchInput = {
  readonly distance: UnitOfDistanceInput;
  readonly source?: InputMaybe<Scalars['String']>;
  readonly sourceId: Scalars['Int'];
};

export type GeographyRecordConnection = {
  readonly __typename?: 'GeographyRecordConnection';
  readonly totalCount: Scalars['Int'];
  readonly pageInfo?: Maybe<GeographyCursorPageInfo>;
  readonly edges?: Maybe<ReadonlyArray<GeographyRecordEdge>>;
};

export type GeographyRecordEdge = {
  readonly __typename?: 'GeographyRecordEdge';
  readonly cursor: Scalars['Int'];
  readonly node: GeographyServiceRecord;
};

export type GeographyServiceInput = {
  readonly sourceId?: InputMaybe<Scalars['Int']>;
};

export type GeographyServicePoint = {
  readonly __typename?: 'GeographyServicePoint';
  readonly lat: Scalars['Float'];
  readonly lon: Scalars['Float'];
};

export type GeographyServiceRecord = {
  readonly __typename?: 'GeographyServiceRecord';
  readonly abbreviatedState?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly county?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly geoPoint?: Maybe<GeographyServicePoint>;
  readonly geographyType: GeographyServiceTypeEnum;
  readonly population?: Maybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly originalName?: Maybe<Scalars['String']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly sourceId?: Maybe<Scalars['Int']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly timezone?: Maybe<Scalars['String']>;
  readonly township?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  readonly legacyId?: Maybe<Scalars['String']>;
  readonly postalCodes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly featureCode?: Maybe<Scalars['String']>;
  readonly preferredName?: Maybe<Scalars['String']>;
  readonly alternativeNames?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export enum GeographyServiceTypeEnum {
  /** a primary administrative division of a country, such as a state in the United States */
  AdministrativeArea1 = 'AdministrativeArea1',
  /** a subdivision of a first-order administrative division, such as a county in the United States */
  AdministrativeArea2 = 'AdministrativeArea2',
  /** a subdivision of a second-order administrative division, such as a village in the United States */
  AdministrativeArea3 = 'AdministrativeArea3',
  /** Airport */
  Airport = 'Airport',
  /** Locality */
  Locality = 'Locality',
  /** Other */
  Other = 'Other'
}

export enum GeographyTypeEnum {
  /** Other */
  Other = 'other',
  /** Locality */
  Locality = 'locality',
  /** a primary administrative division of a country, such as a state in the United States */
  AdministrativeArea1 = 'administrativeArea1',
  /** a subdivision of a first-order administrative division, such as a county in the United States */
  AdministrativeArea2 = 'administrativeArea2',
  /** a subdivision of a second-order administrative division, such as a village in the United States */
  AdministrativeArea3 = 'administrativeArea3',
  /** Airport */
  Airport = 'airport'
}

export type GeographyUpdateInput = {
  readonly sourceId: Scalars['Int'];
  readonly alternativeNames?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly preferredName?: InputMaybe<Scalars['String']>;
};

export type GeographyV2 = {
  readonly __typename?: 'GeographyV2';
  readonly abbreviatedState?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly county?: Maybe<Scalars['String']>;
  readonly geoPoint?: Maybe<Geopoint>;
  readonly geographyType: GeographyTypeEnum;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly source?: Maybe<Scalars['String']>;
  readonly sourceId?: Maybe<Scalars['Int']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly timezone?: Maybe<Scalars['String']>;
  readonly township?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly updatedByUser?: Maybe<UserV2>;
};

export type Geopoint = {
  readonly __typename?: 'Geopoint';
  readonly lat: Scalars['Float'];
  readonly lon: Scalars['Float'];
};

export type GeoPointInput = {
  readonly lat: Scalars['Float'];
  readonly lon: Scalars['Float'];
};

export type GetAbacusPricingInput = {
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly laneId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
};

export type GetAbacusPricingPayload = {
  readonly __typename?: 'GetAbacusPricingPayload';
  readonly costLineItems?: Maybe<ReadonlyArray<Maybe<AbacusCostLineItem>>>;
};

export type GetClientExceptionByDateRangeInput = {
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
};

export type GetExceptionByDateRangeInput = {
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
};

export type GetLoadRouteOffersArgsInput = {
  readonly createdByUserIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly types?: InputMaybe<ReadonlyArray<OfferType>>;
  readonly reasons?: InputMaybe<ReadonlyArray<OfferReason>>;
  readonly sources?: InputMaybe<ReadonlyArray<OfferSource>>;
  readonly statuses?: InputMaybe<ReadonlyArray<OfferStatus>>;
  readonly vendorIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly first?: InputMaybe<Scalars['Float']>;
  readonly last?: InputMaybe<Scalars['Float']>;
  readonly before?: InputMaybe<Scalars['String']>;
  readonly after?: InputMaybe<Scalars['String']>;
};

export type GetOffersArgsInput = {
  readonly createdByUserIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly types?: InputMaybe<ReadonlyArray<OfferType>>;
  readonly reasons?: InputMaybe<ReadonlyArray<OfferReason>>;
  readonly sources?: InputMaybe<ReadonlyArray<OfferSource>>;
  readonly statuses?: InputMaybe<ReadonlyArray<OfferStatus>>;
  readonly vendorIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly first?: InputMaybe<Scalars['Float']>;
  readonly last?: InputMaybe<Scalars['Float']>;
  readonly before?: InputMaybe<Scalars['String']>;
  readonly after?: InputMaybe<Scalars['String']>;
};

export type GetPatternLaneInput = {
  readonly laneId: Scalars['ID'];
};

export type GetPatternLanePayload = {
  readonly __typename?: 'GetPatternLanePayload';
  readonly laneId?: Maybe<Scalars['ID']>;
  readonly autoApply?: Maybe<Scalars['Boolean']>;
};

export type GetPatternLanePayloadV2 = {
  readonly __typename?: 'GetPatternLanePayloadV2';
  readonly data?: Maybe<GetPatternLanePayload>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type GetPatternPayload = {
  readonly __typename?: 'GetPatternPayload';
  readonly data?: Maybe<Pattern>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type GetPatternsByLanePayload = {
  readonly __typename?: 'GetPatternsByLanePayload';
  readonly patterns: ReadonlyArray<Maybe<Pattern>>;
};

export type GetPatternsByLanePayloadV2 = {
  readonly __typename?: 'GetPatternsByLanePayloadV2';
  readonly data?: Maybe<GetPatternsByLanePayload>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type GetPatternsByOrderPayload = {
  readonly __typename?: 'GetPatternsByOrderPayload';
  readonly patterns: ReadonlyArray<Maybe<Pattern>>;
};

export type GetPatternsByOrderPayloadV1 = {
  readonly __typename?: 'GetPatternsByOrderPayloadV1';
  readonly data?: Maybe<GetPatternsByOrderPayload>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type GetTaskArgsInput = {
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly sortDirection?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly type?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly neededBy?: InputMaybe<TaskDateRangeInput>;
  readonly closedAt?: InputMaybe<TaskDateRangeInput>;
  readonly requestedAt?: InputMaybe<TaskDateRangeInput>;
  readonly loadId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly requestedBy?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly assignedTo?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly status?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadCode?: InputMaybe<Scalars['String']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly showClosed?: InputMaybe<Scalars['Boolean']>;
  readonly groupId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly externalId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type GetTaskByDateRangeInput = {
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
};

export type GetTaskInputV2 = {
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly sortDirection?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly type?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly neededBy?: InputMaybe<GetTaskByDateRangeInput>;
  readonly closedAt?: InputMaybe<GetTaskByDateRangeInput>;
  readonly requestedAt?: InputMaybe<GetTaskByDateRangeInput>;
  readonly loadId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly requestedBy?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly assignedTo?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly status?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadCode?: InputMaybe<Scalars['String']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly showClosed?: InputMaybe<Scalars['Boolean']>;
  readonly groupId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedToGroupId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly externalId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type GlobalConfigurationOverride = {
  readonly __typename?: 'GlobalConfigurationOverride';
  readonly carrierAllocationSplit?: Maybe<Scalars['Decimal']>;
  readonly customerAllocationSplit?: Maybe<Scalars['Decimal']>;
  readonly orderCostAllocationMethodId?: Maybe<Scalars['String']>;
  readonly routeRateAllocationMethodId?: Maybe<Scalars['String']>;
};

export type GlobalConfigurationOverrideInput = {
  readonly carrierAllocationSplit?: InputMaybe<Scalars['Decimal']>;
  readonly customerAllocationSplit?: InputMaybe<Scalars['Decimal']>;
  readonly orderCostAllocationMethodId?: InputMaybe<Scalars['String']>;
  readonly routeRateAllocationMethodId?: InputMaybe<Scalars['String']>;
};

export type HaykEvent = {
  readonly __typename?: 'HaykEvent';
  readonly name?: Maybe<Scalars['String']>;
  readonly eventId?: Maybe<Scalars['String']>;
  readonly eventType?: Maybe<Scalars['String']>;
  readonly eventMode?: Maybe<Scalars['String']>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly isVisible?: Maybe<Scalars['Boolean']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly group?: Maybe<Scalars['String']>;
  readonly displayGroup?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly ruleSets?: Maybe<ReadonlyArray<Maybe<RuleSet>>>;
  readonly kafkaTopic?: Maybe<Scalars['String']>;
  readonly serviceSource: Scalars['String'];
  readonly templates?: Maybe<ReadonlyArray<ContentTemplate>>;
};

export type HaykNotificationResponse = {
  readonly __typename?: 'HaykNotificationResponse';
  readonly correlationId?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly offset?: Maybe<Scalars['Int']>;
};

export type HaykNotificationResponses = {
  readonly __typename?: 'HaykNotificationResponses';
  readonly responses?: Maybe<ReadonlyArray<Maybe<HaykNotificationResponse>>>;
};

export type HaykNotificationsInput = {
  readonly eventName?: InputMaybe<Scalars['String']>;
  readonly notificationChannels?: InputMaybe<ReadonlyArray<InputMaybe<NotificationChannels>>>;
  readonly from?: InputMaybe<Scalars['String']>;
  readonly to?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly cc?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly bcc?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly templateData?: InputMaybe<Scalars['JSONObject']>;
  readonly attachments?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly referenceId?: InputMaybe<Scalars['String']>;
};

export type HaykRedirectInput = {
  readonly division?: InputMaybe<Scalars['String']>;
  readonly redirects: HaykRedirectRedirectsInput;
};

export type HaykRedirectRedirects = {
  readonly __typename?: 'HaykRedirectRedirects';
  readonly toAddresses: Scalars['String'];
  readonly ccAddresses: Scalars['String'];
};

export type HaykRedirectRedirectsInput = {
  readonly toAddresses: Scalars['String'];
  readonly ccAddresses: Scalars['String'];
};

/** Redirects */
export type HaykRedirectResult = {
  readonly __typename?: 'HaykRedirectResult';
  readonly redirectId: Scalars['String'];
  readonly defaultType?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly redirects?: Maybe<HaykRedirectRedirects>;
};

export type HistoricalFuelIndexPriceCreateInput = {
  readonly fuelIndexType: Scalars['String'];
  readonly fuelPriceDate: Scalars['DateTime'];
  readonly averagePrice?: InputMaybe<Scalars['Decimal']>;
  readonly averagePriceOverride?: InputMaybe<Scalars['Decimal']>;
};

export type HistoricalFuelIndexPriceModel = {
  readonly __typename?: 'HistoricalFuelIndexPriceModel';
  readonly historicalFuelIndexPriceId: Scalars['ID'];
  readonly createdByUserId?: Maybe<Scalars['Guid']>;
  readonly createdByDesc?: Maybe<Scalars['String']>;
  readonly createdOnUtc?: Maybe<Scalars['DateTime']>;
  readonly updatedByUserId?: Maybe<Scalars['Guid']>;
  readonly updatedByDesc?: Maybe<Scalars['String']>;
  readonly updatedOnUtc?: Maybe<Scalars['DateTime']>;
  readonly fuelIndexType: Scalars['String'];
  readonly fuelPriceDate: Scalars['DateTime'];
  readonly averagePrice?: Maybe<Scalars['Decimal']>;
  readonly averagePriceOverride?: Maybe<Scalars['Decimal']>;
};

export type HistoricalFuelIndexPriceResponse = {
  readonly __typename?: 'HistoricalFuelIndexPriceResponse';
  readonly historicalFuelIndexPriceId?: Maybe<Scalars['Guid']>;
  readonly fuelIndexType: Scalars['String'];
  readonly fuelPriceDate?: Maybe<Scalars['DateTime']>;
  readonly averageDieselPrice?: Maybe<Scalars['Decimal']>;
};

export type HistoricalFuelIndexPriceUpdateInput = {
  readonly historicalFuelIndexPriceId: Scalars['Guid'];
  readonly fuelIndexType: Scalars['String'];
  readonly fuelPriceDate: Scalars['DateTime'];
  readonly averagePrice?: InputMaybe<Scalars['Decimal']>;
  readonly averagePriceOverride?: InputMaybe<Scalars['Decimal']>;
};

/** The final exchange rate used to convert from one currency to another on a given date */
export type HistoricRate = {
  readonly __typename?: 'HistoricRate';
  readonly date: Scalars['DateTime'];
  readonly fromCurrency: Scalars['String'];
  readonly toCurrency: Scalars['String'];
  readonly rate: Scalars['Float'];
};

export type Identifier = {
  readonly __typename?: 'Identifier';
  readonly code: Scalars['String'];
  readonly customerId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly identifierType: KeyValue;
  readonly identifierTypeId: Scalars['ID'];
};

export type IdentifierInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly code: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly identifierTypeId: Scalars['ID'];
};

export type IdentifierInputV2 = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly code: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly identifierTypeId: Scalars['ID'];
};

export type IdentifierQueryInput = {
  readonly code: Scalars['String'];
  readonly identifierType: Scalars['String'];
};

export type IdentifierQueryInputV2 = {
  readonly code: Scalars['String'];
  readonly identifierType: Scalars['String'];
};

export type IdentifierV2 = {
  readonly __typename?: 'IdentifierV2';
  readonly code: Scalars['String'];
  readonly customerId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly identifierType: KeyValue;
  readonly identifierTypeId: Scalars['ID'];
};

export type ImdlGenericResponse = {
  readonly __typename?: 'ImdlGenericResponse';
  readonly provider: Scalars['String'];
  readonly statusCode: Scalars['Int'];
  readonly message: Scalars['String'];
  readonly prices?: Maybe<ReadonlyArray<Maybe<PricingInfo>>>;
};

export type ImdlInput = {
  readonly provider: ReadonlyArray<InputMaybe<Scalars['String']>>;
  readonly hazmatShipment: Scalars['Boolean'];
  readonly shipFromLocation: ShipLocation;
  readonly shipToLocation: ShipLocation;
  readonly pickUpDate?: InputMaybe<Scalars['DateTime']>;
};

export type ImdlOutput = {
  readonly __typename?: 'ImdlOutput';
  readonly request?: Maybe<RequestOutput>;
  readonly response?: Maybe<ReadonlyArray<Maybe<ImdlGenericResponse>>>;
};

export type ImportBlumeChargesInput = {
  readonly billOfLading: Scalars['String'];
  readonly trackingNumber: Scalars['String'];
  readonly bypassId?: InputMaybe<Scalars['ID']>;
  readonly bypassIdType?: InputMaybe<Scalars['String']>;
  readonly bypassCode?: InputMaybe<Scalars['String']>;
  readonly bypassCodeType?: InputMaybe<Scalars['String']>;
  readonly persistImport: Scalars['Boolean'];
};

export type ImportBlumeChargesPayload = {
  readonly __typename?: 'ImportBlumeChargesPayload';
  readonly blumeCharges: ReadonlyArray<BlumeCharge>;
  readonly accountingCostDetailsInserted: ReadonlyArray<BlumeInsertedCostDetail>;
  readonly accountingCostDetailsDeleted: ReadonlyArray<BlumeDeletedCostDetail>;
  readonly adjustmentMethodsUsed: ReadonlyArray<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
};

export type IncidentConnectionV2 = {
  readonly __typename?: 'IncidentConnectionV2';
  readonly edges: ReadonlyArray<IncidentV2Edge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type IncidentCreateInputV2 = {
  readonly employeeID: Scalars['ID'];
  readonly loadID: Scalars['ID'];
  readonly orderIDs: ReadonlyArray<Scalars['ID']>;
  readonly routeIDs: ReadonlyArray<Scalars['ID']>;
  readonly customerID?: InputMaybe<Scalars['ID']>;
  readonly vendorID?: InputMaybe<Scalars['ID']>;
  readonly carrierID?: InputMaybe<Scalars['ID']>;
  readonly facilityID?: InputMaybe<Scalars['ID']>;
  readonly typeID: Scalars['ID'];
  readonly gravityID?: InputMaybe<Scalars['ID']>;
  readonly faultPartyID?: InputMaybe<Scalars['ID']>;
  readonly location?: InputMaybe<LocationInput>;
  readonly followUpDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly shutdown: Scalars['Boolean'];
  readonly closed: Scalars['Boolean'];
  readonly accounting: Scalars['Boolean'];
  readonly claims: Scalars['Boolean'];
  readonly summary: Scalars['String'];
  readonly description?: InputMaybe<Scalars['String']>;
  /** Currently does nothing */
  readonly notifyUserIDs: ReadonlyArray<Scalars['ID']>;
};

export type IncidentFilterV2 = {
  readonly startDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly endDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly showClosed?: InputMaybe<Scalars['Boolean']>;
  readonly routeID?: InputMaybe<Scalars['ID']>;
  readonly loadID?: InputMaybe<Scalars['ID']>;
  readonly repIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly vendorIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly carrierIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly facilityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type IncidentUpdate = {
  readonly __typename?: 'IncidentUpdate';
  readonly id: Scalars['ID'];
  readonly type: IncidentUpdateType;
  readonly createdDatetime: Scalars['ISO8601DateTime'];
  readonly followUpDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly shutdown: Scalars['Boolean'];
  readonly closed: Scalars['Boolean'];
  readonly accounting: Scalars['Boolean'];
  readonly claims: Scalars['Boolean'];
  readonly description: Scalars['String'];
  readonly createdBy: Employee;
  readonly createdByV2: EmployeeV2;
  readonly claimOpenedBy?: Maybe<Employee>;
  readonly claimOpenedByV2?: Maybe<EmployeeV2>;
};

export type IncidentUpdateInputV2 = {
  readonly id: Scalars['ID'];
  readonly employeeID: Scalars['ID'];
  readonly followUpDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly shutdown: Scalars['Boolean'];
  readonly closed: Scalars['Boolean'];
  readonly accounting: Scalars['Boolean'];
  readonly claims: Scalars['Boolean'];
  readonly updateDescription: Scalars['String'];
  /** Employee IDs of the users to notify */
  readonly notifyUserIDs: ReadonlyArray<Scalars['ID']>;
};

export enum IncidentUpdateType {
  Close = 'Close',
  Create = 'Create',
  Reopen = 'Reopen',
  Update = 'Update'
}

export type IncidentV2 = {
  readonly __typename?: 'IncidentV2';
  readonly id: Scalars['ID'];
  readonly type: KeyValue;
  readonly gravity?: Maybe<KeyValue>;
  readonly faultParty?: Maybe<KeyValue>;
  readonly location?: Maybe<Location>;
  readonly createdDatetime: Scalars['ISO8601DateTime'];
  readonly lastUpdatedDatetime: Scalars['ISO8601DateTime'];
  readonly followUpDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly shutdown: Scalars['Boolean'];
  readonly closed: Scalars['Boolean'];
  readonly accounting: Scalars['Boolean'];
  readonly claims: Scalars['Boolean'];
  readonly claimOpenedDatetime?: Maybe<Scalars['DateTime']>;
  readonly summary: Scalars['String'];
  readonly description: Scalars['String'];
  readonly latestUpdateType: IncidentUpdateType;
  readonly latestUpdateDescription: Scalars['String'];
  readonly updates: ReadonlyArray<IncidentUpdate>;
  readonly load: LoadV2;
  readonly orders: ReadonlyArray<CustomerOrderV2>;
  readonly routes: ReadonlyArray<LoadRouteV2>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly vendor?: Maybe<RouteVendorV2>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly facility?: Maybe<Facility>;
  readonly facilityV2?: Maybe<FacilityV2>;
  readonly createdBy: Employee;
  readonly createdByV2: EmployeeV2;
  readonly lastUpdatedBy: Employee;
  readonly lastUpdatedByV2: EmployeeV2;
  readonly claimOpenedBy?: Maybe<Employee>;
  readonly claimOpenedByV2?: Maybe<EmployeeV2>;
  readonly notifyUsers: ReadonlyArray<Employee>;
  readonly notifyUsersV2: ReadonlyArray<EmployeeV2>;
};

export type IncidentV2Edge = {
  readonly __typename?: 'IncidentV2Edge';
  readonly node: IncidentV2;
  readonly cursor: Scalars['String'];
};

export type InferredHoursOfServiceInput = {
  readonly readyLocation: DatedPathEntryInput;
  readonly currentLocation: DatedPathEntryInput;
  readonly nextStop: PathEntryInput;
};

export type InputSchema = {
  readonly locationDats?: InputMaybe<ReadonlyArray<InputMaybe<LocationDat>>>;
  readonly routeDats?: InputMaybe<ReadonlyArray<InputMaybe<RouteDat>>>;
  readonly routePathDats?: InputMaybe<ReadonlyArray<InputMaybe<RoutePathDat>>>;
  readonly speedMapDats?: InputMaybe<ReadonlyArray<InputMaybe<SpeedMapDat>>>;
  readonly parametersDat?: InputMaybe<ParametersDat>;
  readonly useInferredHoS?: InputMaybe<Scalars['Boolean']>;
};

export type IntegrationMapping = {
  readonly __typename?: 'IntegrationMapping';
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly fromMapping?: Maybe<Scalars['String']>;
  readonly groupName?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly toMapping?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
};

export type IntegrationMappingInput = {
  readonly fromMapping: Scalars['String'];
  readonly groupName: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly toMapping: Scalars['String'];
};

export type Interaction = {
  readonly __typename?: 'Interaction';
  readonly employee?: Maybe<Employee>;
  readonly id: Scalars['ID'];
  readonly interactableId: Scalars['ID'];
  readonly interactionType: InteractionTypeEnum;
  readonly utcDatetime: Scalars['ISO8601DateTime'];
};

export type InteractionInput = {
  readonly employeeId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly interactableId?: InputMaybe<Scalars['ID']>;
  readonly interactableType?: InputMaybe<Scalars['String']>;
  readonly interactionType: InteractionTypeEnum;
  readonly utcDatetime: Scalars['String'];
};

export type InteractionInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly employeeId?: InputMaybe<Scalars['ID']>;
  readonly interactionType: InteractionTypeEnum;
  readonly utcDatetime: Scalars['String'];
};

export enum InteractionTypeEnum {
  /** Zero Trucks Confirmed This Date */
  MarkNoTrucks = 'markNoTrucks',
  /** markNoContainers */
  MarkNoContainers = 'markNoContainers',
  /** markNoGateReservations */
  MarkNoGateReservations = 'markNoGateReservations'
}

export type InteractionV2 = {
  readonly __typename?: 'InteractionV2';
  readonly employee?: Maybe<EmployeeV2>;
  readonly employeeId?: Maybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly interactableId: Scalars['ID'];
  readonly interactionType: InteractionTypeEnum;
  readonly utcDatetime: Scalars['ISO8601DateTime'];
};

export type InvalidAddress = {
  readonly __typename?: 'InvalidAddress';
  readonly requestId?: Maybe<Scalars['ID']>;
  readonly formattedAddress?: Maybe<FormattedAddress>;
  readonly resultCodes?: Maybe<ReadonlyArray<Maybe<AddressResultCodes>>>;
};

export enum InvoiceAuditTypeEnum {
  Initial = 'INITIAL',
  Resend = 'RESEND',
  Recreate = 'RECREATE'
}

/** The Invoice Batch.  The Invoice Batch will contain 1-n Invoice Headers. */
export type InvoiceBatch = {
  readonly __typename?: 'InvoiceBatch';
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly description: Scalars['String'];
  readonly id?: Maybe<Scalars['ID']>;
  readonly invoiceHeaders: ReadonlyArray<InvoiceHeader>;
  readonly status: StatusEnum;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
};

export type InvoiceBatchCreateInput = {
  /** Description of the batch */
  readonly description: Scalars['String'];
  /** The order ids in the batch */
  readonly orderIds: ReadonlyArray<Scalars['ID']>;
};

export type InvoiceBatchDistributionInput = {
  readonly externalEmail?: InputMaybe<Scalars['String']>;
  readonly invoiceDistributionMethod?: InputMaybe<Scalars['String']>;
  readonly invoiceId: Scalars['ID'];
};

/** The Invoice Batch Processing Input Type.  Used to Process Invoice Batches */
export type InvoiceBatchProcessingInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly invoiceHeadersForProcessing: ReadonlyArray<InvoiceHeaderProcessingInput>;
  readonly invoiceOverrides?: InputMaybe<InvoiceOverridesInput>;
};

export type InvoiceDistributionHistory = {
  readonly __typename?: 'InvoiceDistributionHistory';
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Date']>;
  readonly createdOnDateTime?: Maybe<Scalars['DateTime']>;
  readonly customer_Name?: Maybe<Scalars['String']>;
  readonly eid?: Maybe<Scalars['Long']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly invoiceDistributionMethod?: Maybe<Scalars['String']>;
  readonly invoice_Number?: Maybe<Scalars['String']>;
  readonly order_Code: Scalars['String'];
  readonly source?: Maybe<InvoiceAuditTypeEnum>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['Date']>;
};

export enum InvoiceExchangeRateConversionDateEnum {
  Pickupdate = 'PICKUPDATE',
  Deliverydate = 'DELIVERYDATE',
  Invoicedate = 'INVOICEDATE'
}

export enum InvoiceGroupingEnum {
  /**
   * Invoice totals are grouped by customer
   * @deprecated Not Implemented
   */
  Combined = 'Combined',
  /** Invoice totals are grouped by order */
  Order = 'Order'
}

/** The Invoice Header Type */
export type InvoiceHeader = {
  readonly __typename?: 'InvoiceHeader';
  readonly batchId?: Maybe<Scalars['ID']>;
  readonly billTo?: Maybe<Customer>;
  readonly billToAddress?: Maybe<Scalars['String']>;
  readonly billToCodeId?: Maybe<Scalars['ID']>;
  readonly billToEmail?: Maybe<Scalars['String']>;
  readonly billToId?: Maybe<Scalars['ID']>;
  readonly billToV2?: Maybe<CustomerV2>;
  readonly businessUnit?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly creditMemoProcessedDateTime?: Maybe<Scalars['DateTime']>;
  readonly currencyDiscountAmount?: Maybe<Scalars['Decimal']>;
  readonly currencyId?: Maybe<Scalars['String']>;
  readonly currentAmountDue?: Maybe<Scalars['Decimal']>;
  readonly customer?: Maybe<Customer>;
  readonly customerId: Scalars['ID'];
  readonly customerPaymentTermId?: Maybe<Scalars['String']>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly dateSettled?: Maybe<Scalars['DateTime']>;
  readonly discountAmount?: Maybe<Scalars['Decimal']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly dueDate?: Maybe<Scalars['DateTime']>;
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: Maybe<Scalars['DateTime']>;
  readonly hasSeerSearchLimitReached?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  readonly invoiceCurrencyCurrentAmountDue?: Maybe<Scalars['Decimal']>;
  readonly invoiceCurrencyOriginalAmountDue?: Maybe<Scalars['Decimal']>;
  readonly invoiceDate?: Maybe<Scalars['DateTime']>;
  readonly invoiceDistributionMethod?: Maybe<Scalars['String']>;
  readonly invoiceGrouping: InvoiceGroupingEnum;
  readonly invoiceNumber: Scalars['String'];
  readonly invoiceProcessedDateTime?: Maybe<Scalars['DateTime']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly orderFinancialStatus?: Maybe<OrderFinancialStatusEnum>;
  readonly orderId?: Maybe<Scalars['ID']>;
  readonly orderLifeCycleStatus?: Maybe<OrderLifeCycleStatusEnum>;
  readonly originalAmountDue?: Maybe<Scalars['Decimal']>;
  readonly paymentMethod?: Maybe<Scalars['String']>;
  readonly paymentMethodId?: Maybe<Scalars['String']>;
  readonly project?: Maybe<Scalars['String']>;
  readonly rateDetails: ReadonlyArray<RateDetail>;
  readonly size?: Maybe<Scalars['String']>;
  readonly statementNumber?: Maybe<Scalars['String']>;
  readonly status: StatusEnum;
};

/** A connection to a list of items. */
export type InvoiceHeaderConnection = {
  readonly __typename?: 'InvoiceHeaderConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<InvoiceHeaderEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<InvoiceHeader>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type InvoiceHeaderEdge = {
  readonly __typename?: 'InvoiceHeaderEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<InvoiceHeader>;
};

/** Invoice details (Rate Details) object when processing a invoice. */
export type InvoiceHeaderProcessingInput = {
  readonly id: Scalars['ID'];
  readonly rateDetailsForProcessing: ReadonlyArray<RateDetailProcessingInput>;
};

/** Overrides for invoices at the time of invoice processing */
export type InvoiceOverridesInput = {
  readonly invoiceDate?: InputMaybe<Scalars['DateTime']>;
  readonly invoiceDistributionMethod?: InputMaybe<Scalars['String']>;
  readonly paymentTerms?: InputMaybe<Scalars['String']>;
};

/** Response Object from IsSNIShipmentServiceAvailable */
export type IsSniShipmentServiceAvailable = {
  readonly __typename?: 'IsSNIShipmentServiceAvailable';
  readonly code?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
};

export type JournalEntryCreateInput = {
  readonly userID: Scalars['ID'];
  readonly loadID: Scalars['ID'];
  readonly entryTermID?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly notifyUserIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type JournalEntryCreateInputV2 = {
  readonly userID: Scalars['ID'];
  readonly loadID: Scalars['ID'];
  readonly entryTermID?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly notifyUserIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type JournalEntryUpdateInput = {
  readonly userID: Scalars['ID'];
  readonly loadID: Scalars['ID'];
  readonly entryTermID?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly notifyUserIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['ID'];
};

export type JournalEntryUpdateInputV2 = {
  readonly userID: Scalars['ID'];
  readonly loadID: Scalars['ID'];
  readonly entryTermID?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly notifyUserIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['ID'];
};

/** The Jurisdiction Taxes. */
export type JurisdictionTax = {
  readonly __typename?: 'JurisdictionTax';
  readonly country: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly glCode: Scalars['String'];
  readonly harmonized: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly state: Scalars['String'];
  readonly taxRate: Scalars['Decimal'];
};

/** The Jurisdiction Taxes. */
export type JurisdictionTaxInput = {
  readonly country: Scalars['String'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly glCode: Scalars['String'];
  readonly harmonized: Scalars['Boolean'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly state: Scalars['String'];
  readonly taxRate: Scalars['Decimal'];
};

export type KeyValue = {
  readonly __typename?: 'KeyValue';
  readonly active: Scalars['Boolean'];
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly metadataJson: Scalars['String'];
  readonly name: Scalars['String'];
  readonly sort: Scalars['Float'];
  readonly systemDefined: Scalars['Boolean'];
};

/**
 * This is a collection of options, where keyValueTypes are grouped by name.  These
 * are generally tenant-configurable options, such as ContactType or AddressType.
 */
export type KeyValueCollection = {
  readonly __typename?: 'KeyValueCollection';
  readonly name: ConfigTypeEnum;
  readonly options: ReadonlyArray<KeyValue>;
};

export type KeyValuePairOfStringAndPropertyMetadata = {
  readonly __typename?: 'KeyValuePairOfStringAndPropertyMetadata';
  readonly key: Scalars['String'];
  readonly value: PropertyMetadata;
};

export type KeyValueType = {
  readonly __typename?: 'KeyValueType';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly sort: Scalars['Float'];
  readonly active: Scalars['Boolean'];
  readonly systemDefined: Scalars['Boolean'];
  readonly description?: Maybe<Scalars['String']>;
  readonly metadataJson: Scalars['String'];
};

export type Lane = {
  readonly __typename?: 'Lane';
  readonly active: Scalars['Boolean'];
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdById?: Maybe<Scalars['Uuid']>;
  readonly customer?: Maybe<Customer>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly destinationCityGeography?: Maybe<Geography>;
  readonly destinationCountry?: Maybe<LaneKeyValue>;
  readonly destinationFacility?: Maybe<Facility>;
  readonly destinationFacilityId?: Maybe<Scalars['ID']>;
  readonly destinationGeographyType: LaneKeyValue;
  readonly destinationId?: Maybe<Scalars['String']>;
  readonly destinationStateGeography?: Maybe<Geography>;
  readonly distance: LaneUnitOfDistance;
  readonly employee?: Maybe<Employee>;
  readonly equipment?: Maybe<ReadonlyArray<LaneKeyValue>>;
  readonly laneId: Scalars['ID'];
  readonly loadSize?: Maybe<LaneKeyValue>;
  readonly minLength?: Maybe<LaneUnitOfDistance>;
  readonly originCityGeography?: Maybe<Geography>;
  readonly originCountry?: Maybe<LaneKeyValue>;
  readonly originFacility?: Maybe<Facility>;
  readonly originFacilityId?: Maybe<Scalars['ID']>;
  readonly originGeographyType: LaneKeyValue;
  readonly originId?: Maybe<Scalars['String']>;
  readonly originStateGeography?: Maybe<Geography>;
  readonly scac?: Maybe<LaneKeyValue>;
  readonly transportMode?: Maybe<LaneKeyValue>;
};

/** A connection to a list of items. */
export type LaneConnection = {
  readonly __typename?: 'LaneConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<LaneEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Lane>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export enum LaneDistanceUnitEnum {
  Feet = 'feet',
  Inches = 'inches',
  Kilometers = 'kilometers',
  Meters = 'meters',
  Miles = 'miles'
}

/** An edge in a connection. */
export type LaneEdge = {
  readonly __typename?: 'LaneEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Lane>;
};

export type LaneGeographyRadiusSearchInput = {
  readonly distance?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly postalCodeCountry?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
};

/** DO NOT USE. Please use DDT */
export enum LaneGeographyTypeEnum {
  City = 'city',
  Country = 'country',
  Facility = 'facility',
  State = 'state',
  Postalcode = 'postalcode',
  /** @deprecated DO NOT USE. Please use postalcode */
  Zip = 'zip'
}

export type LaneInput = {
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly destinationGeographyType: Scalars['String'];
  readonly destinationId: Scalars['String'];
  readonly distance?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly equipment: ReadonlyArray<InputMaybe<Scalars['ID']>>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly minLength?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly originGeographyType: Scalars['String'];
  readonly originId: Scalars['String'];
  readonly scac?: InputMaybe<Scalars['String']>;
  readonly transportMode?: InputMaybe<Scalars['String']>;
};

export type LaneInputV2 = {
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['Uuid']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly equipment: ReadonlyArray<InputMaybe<Scalars['ID']>>;
  readonly laneStops: ReadonlyArray<InputMaybe<LaneStopInputV2>>;
  readonly laneType?: InputMaybe<Scalars['String']>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly minHeight?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly service?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly transportMode?: InputMaybe<Scalars['String']>;
};

export type LaneInputV3 = {
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly equipment: ReadonlyArray<InputMaybe<Scalars['ID']>>;
  readonly laneStops: ReadonlyArray<InputMaybe<LaneStopInputV3>>;
  readonly laneType?: InputMaybe<Scalars['String']>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly minHeight?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly service?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly transportMode?: InputMaybe<Scalars['String']>;
};

export type LaneInputV4 = {
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly equipment: ReadonlyArray<InputMaybe<Scalars['ID']>>;
  readonly laneStops: ReadonlyArray<InputMaybe<LaneStopInputV4>>;
  readonly laneType?: InputMaybe<Scalars['String']>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly minHeight?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly service?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly transportMode?: InputMaybe<Scalars['String']>;
};

export type LaneKeyValue = {
  readonly __typename?: 'LaneKeyValue';
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly metaDataJson?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly sort?: Maybe<Scalars['Int']>;
  readonly systemDefined?: Maybe<Scalars['Boolean']>;
};

export type LaneOrderMatch = {
  readonly __typename?: 'LaneOrderMatch';
  readonly order: CustomerOrderV2;
};

/** A connection to a list of items. */
export type LaneOrderMatchConnection = {
  readonly __typename?: 'LaneOrderMatchConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<LaneOrderMatchEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<LaneOrderMatch>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LaneOrderMatchEdge = {
  readonly __typename?: 'LaneOrderMatchEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<LaneOrderMatch>;
};

export type LaneOrdersInput = {
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly laneId: Scalars['ID'];
};

export type LaneOutput = {
  readonly __typename?: 'LaneOutput';
  readonly active: Scalars['Boolean'];
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdById?: Maybe<Scalars['Uuid']>;
  readonly customer?: Maybe<Customer>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly destinationCityGeography?: Maybe<Geography>;
  readonly destinationCountry?: Maybe<LaneKeyValue>;
  readonly destinationFacility?: Maybe<Facility>;
  readonly destinationFacilityId?: Maybe<Scalars['ID']>;
  readonly destinationGeographyType: LaneKeyValue;
  readonly destinationId?: Maybe<Scalars['String']>;
  readonly destinationStateGeography?: Maybe<Geography>;
  readonly distance: LaneUnitOfDistance;
  readonly employee?: Maybe<Employee>;
  readonly equipment?: Maybe<ReadonlyArray<LaneKeyValue>>;
  readonly laneId: Scalars['ID'];
  readonly loadSize?: Maybe<LaneKeyValue>;
  readonly minLength?: Maybe<LaneUnitOfDistance>;
  readonly originCityGeography?: Maybe<Geography>;
  readonly originCountry?: Maybe<LaneKeyValue>;
  readonly originFacility?: Maybe<Facility>;
  readonly originFacilityId?: Maybe<Scalars['ID']>;
  readonly originGeographyType: LaneKeyValue;
  readonly originId?: Maybe<Scalars['String']>;
  readonly originStateGeography?: Maybe<Geography>;
  readonly scac?: Maybe<LaneKeyValue>;
  readonly transportMode?: Maybe<LaneKeyValue>;
};

export type LanePaginatedFilter = {
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  /** Deprecated. DO NOT USE. */
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly carrierIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly code?: InputMaybe<Scalars['Int']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly customerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly destinationGeographySearch?: InputMaybe<LaneGeographyRadiusSearchInput>;
  readonly destinationGeographySearches?: InputMaybe<ReadonlyArray<InputMaybe<LaneGeographyRadiusSearchInput>>>;
  readonly destinationGeographyType?: InputMaybe<Scalars['String']>;
  readonly destinationGeographyTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly destinationStopType?: InputMaybe<Scalars['String']>;
  readonly destinationStopTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly divisions?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly laneId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly laneType?: InputMaybe<Scalars['String']>;
  readonly loadSizeId?: InputMaybe<Scalars['String']>;
  readonly loadSizeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly minHeight?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly originGeographySearch?: InputMaybe<LaneGeographyRadiusSearchInput>;
  readonly originGeographySearches?: InputMaybe<ReadonlyArray<InputMaybe<LaneGeographyRadiusSearchInput>>>;
  readonly originGeographyType?: InputMaybe<Scalars['String']>;
  readonly originGeographyTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly originStopType?: InputMaybe<Scalars['String']>;
  readonly originStopTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly project?: InputMaybe<Scalars['String']>;
  /** Deprecated. DO NOT USE. */
  readonly scacId?: InputMaybe<Scalars['String']>;
  readonly serviceIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly sortByColumn?: InputMaybe<LaneSortByColumnEnum>;
  readonly sortDirection?: InputMaybe<LaneSortDirectionEnum>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly transportModeId?: InputMaybe<Scalars['String']>;
  readonly transportModeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type LaneRouteMatch = {
  readonly __typename?: 'LaneRouteMatch';
  readonly route: LoadRouteV2;
};

/** A connection to a list of items. */
export type LaneRouteMatchConnection = {
  readonly __typename?: 'LaneRouteMatchConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<LaneRouteMatchEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<LaneRouteMatch>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LaneRouteMatchEdge = {
  readonly __typename?: 'LaneRouteMatchEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<LaneRouteMatch>;
};

export type LaneRoutesInput = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly laneId: Scalars['ID'];
};

export enum LaneSortByColumnEnum {
  LaneCode = 'laneCode',
  OriginType = 'originType',
  OriginStopType = 'originStopType',
  OriginFacility = 'originFacility',
  OriginCity = 'originCity',
  OriginState = 'originState',
  OriginCountry = 'originCountry',
  OriginZip = 'originZip',
  DestinationType = 'destinationType',
  DestinationStopType = 'destinationStopType',
  DestinationFacility = 'destinationFacility',
  DestinationCity = 'destinationCity',
  DestinationState = 'destinationState',
  DestinationCountry = 'destinationCountry',
  DestinationZip = 'destinationZip',
  StopCount = 'stopCount',
  Equipment = 'equipment',
  Mode = 'mode',
  Division = 'division',
  Size = 'size',
  Length = 'length',
  Width = 'width',
  Height = 'height',
  Service = 'service',
  Project = 'project',
  BusinessUnit = 'businessUnit',
  LaneType = 'laneType'
}

export enum LaneSortDirectionEnum {
  Asc = 'asc',
  Desc = 'desc'
}

export type LaneStop = {
  readonly __typename?: 'LaneStop';
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<User>;
  readonly geographyType: LaneKeyValue;
  readonly id: Scalars['ID'];
  readonly sequence: Scalars['Int'];
  readonly stopCityGeography?: Maybe<Geography>;
  readonly stopCountry?: Maybe<LaneKeyValue>;
  readonly stopFacilityType?: Maybe<Facility>;
  readonly stopFacilityTypeV2?: Maybe<FacilityV2>;
  readonly stopPostalCode?: Maybe<PostalCode>;
  readonly stopStateGeography?: Maybe<Geography>;
  readonly stopType: Scalars['String'];
};

export type LaneStopInputV2 = {
  readonly geographyId: Scalars['String'];
  readonly geographyType: LaneGeographyTypeEnum;
  readonly postalCodeCountry?: InputMaybe<Scalars['String']>;
  readonly sequence: Scalars['Int'];
  readonly stopType: LaneStopTypeEnum;
};

export type LaneStopInputV3 = {
  readonly geographyId: Scalars['String'];
  readonly geographyType: LaneGeographyTypeEnum;
  readonly postalCodeCountry?: InputMaybe<Scalars['String']>;
  readonly sequence: Scalars['Int'];
  readonly stopType: Scalars['String'];
};

export type LaneStopInputV4 = {
  readonly geographyId: Scalars['String'];
  readonly geographyType: Scalars['String'];
  readonly postalCodeCountry?: InputMaybe<Scalars['String']>;
  readonly sequence: Scalars['Int'];
  readonly stopType: Scalars['String'];
};

export type LaneStops = {
  readonly __typename?: 'LaneStops';
  readonly geographyId?: Maybe<Scalars['String']>;
  readonly geographyType?: Maybe<Scalars['String']>;
  readonly postalCodeCountry?: Maybe<Scalars['String']>;
  readonly sequence?: Maybe<Scalars['Int']>;
  readonly stopType?: Maybe<Scalars['String']>;
};

export enum LaneStopTypeEnum {
  Pu = 'PU',
  Del = 'Del',
  Ta = 'TA',
  Td = 'TD'
}

export type LaneUnitOfDistance = {
  readonly __typename?: 'LaneUnitOfDistance';
  readonly asFeet?: Maybe<Scalars['Decimal']>;
  readonly asInches?: Maybe<Scalars['Decimal']>;
  readonly asKilometers?: Maybe<Scalars['Decimal']>;
  readonly asMeters?: Maybe<Scalars['Decimal']>;
  readonly asMiles?: Maybe<Scalars['Decimal']>;
  readonly asRoundedFeet?: Maybe<Scalars['Decimal']>;
  readonly asRoundedInches?: Maybe<Scalars['Decimal']>;
  readonly asRoundedKilometers?: Maybe<Scalars['Decimal']>;
  readonly asRoundedMeters?: Maybe<Scalars['Decimal']>;
  readonly asRoundedMiles?: Maybe<Scalars['Decimal']>;
};

export type LaneUnitOfDistanceInput = {
  readonly unit?: InputMaybe<LaneDistanceUnitEnum>;
  readonly value?: InputMaybe<Scalars['Decimal']>;
};

export type LaneV2 = {
  readonly __typename?: 'LaneV2';
  readonly businessUnit?: Maybe<LaneKeyValue>;
  readonly code: Scalars['Int'];
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use createdByV2 instead */
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly division?: Maybe<LaneKeyValue>;
  readonly equipments?: Maybe<ReadonlyArray<LaneKeyValue>>;
  readonly id: Scalars['ID'];
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly laneType?: Maybe<LaneKeyValue>;
  readonly loadSize?: Maybe<LaneKeyValue>;
  readonly minHeight?: Maybe<LaneUnitOfDistance>;
  readonly minLength?: Maybe<LaneUnitOfDistance>;
  readonly minWidth?: Maybe<LaneUnitOfDistance>;
  readonly project?: Maybe<LaneKeyValue>;
  readonly services?: Maybe<ReadonlyArray<Maybe<LaneKeyValue>>>;
  readonly stops?: Maybe<ReadonlyArray<LaneStop>>;
  readonly transportMode?: Maybe<LaneKeyValue>;
};

/** A connection to a list of items. */
export type LaneV2Connection = {
  readonly __typename?: 'LaneV2Connection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<LaneV2Edge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<LaneV2>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LaneV2Edge = {
  readonly __typename?: 'LaneV2Edge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<LaneV2>;
};

export enum LastUpdate {
  Created = 'Created',
  Deleted = 'Deleted',
  Updated = 'Updated'
}

export type LatLongPair = {
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export enum LeadSourceEnum {
  Phone = 'PHONE',
  Referral = 'REFERRAL',
  Web = 'WEB'
}

export type LeaveInput = {
  readonly allotted?: InputMaybe<Scalars['Int']>;
  readonly remaining?: InputMaybe<Scalars['Int']>;
  readonly resetDate?: InputMaybe<Scalars['Date']>;
};

export type LeaveOutput = {
  readonly __typename?: 'LeaveOutput';
  readonly allotted?: Maybe<Scalars['Int']>;
  readonly remaining?: Maybe<Scalars['Int']>;
  readonly resetDate?: Maybe<Scalars['Date']>;
};

export type Length = {
  readonly __typename?: 'Length';
  readonly unit: UnitOfLengthEnum;
  readonly value: Scalars['Float'];
};

export type LengthInput = {
  readonly unit: UnitOfLengthEnum;
  readonly value: Scalars['Float'];
};

export type LengthV2 = {
  readonly __typename?: 'LengthV2';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export enum LevelTypeEnum {
  /** noneDefined */
  NoneDefined = 'noneDefined',
  /** Bronze */
  Bronze = 'Bronze',
  /** Silver */
  Silver = 'Silver',
  /** Gold */
  Gold = 'Gold',
  /** Platinum */
  Platinum = 'Platinum'
}

export type LinkedRouteV2 = {
  readonly __typename?: 'LinkedRouteV2';
  readonly id: Scalars['ID'];
  readonly createdDatetime: Scalars['DateTime'];
  readonly updatedDatetime: Scalars['DateTime'];
  readonly route: LoadRouteV2;
  readonly previousRoute?: Maybe<LoadRouteV2>;
  readonly nextRoute?: Maybe<LoadRouteV2>;
  /** @deprecated Removed in Rails decommission -- use createdByV2 */
  readonly createdBy: User;
  /** @deprecated Removed in Rails decommission -- use updatedByV2 */
  readonly updatedBy: User;
  readonly createdByV2: UserV2;
  readonly updatedByV2: UserV2;
};

/** Base class that all Load responses should implement */
export type Load = {
  readonly __typename?: 'Load';
  readonly code?: Maybe<Scalars['Int']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdBy: User;
  readonly id: Scalars['ID'];
  readonly note?: Maybe<Scalars['String']>;
  readonly orders: ReadonlyArray<CustomerOrder>;
  readonly routes: ReadonlyArray<LoadRoute>;
  readonly status: Scalars['String'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
};

export type LoadAllocatedMargins = {
  readonly __typename?: 'LoadAllocatedMargins';
  readonly loadId: Scalars['ID'];
  readonly loadCost?: Maybe<Scalars['Decimal']>;
  readonly loadRate?: Maybe<Scalars['Decimal']>;
  readonly companyOverheadAmount?: Maybe<Scalars['Decimal']>;
  readonly loadRateNetCompanyOverhead?: Maybe<Scalars['Decimal']>;
  readonly totalAllocatedOrderRate?: Maybe<Scalars['Decimal']>;
  readonly totalAllocatedOrderCost?: Maybe<Scalars['Decimal']>;
  readonly totalAllocatedRouteRate?: Maybe<Scalars['Decimal']>;
  readonly totalAllocatedRouteCost?: Maybe<Scalars['Decimal']>;
  readonly orderAllocatedMargins?: Maybe<ReadonlyArray<Maybe<OrderAllocatedMargin>>>;
  readonly routeAllocatedMargins?: Maybe<ReadonlyArray<Maybe<RouteAllocatedMargin>>>;
  /** @deprecated This field is intended to help diagnose commissions calculation issues */
  readonly commissionEventReport?: Maybe<CommissionEvent>;
  /** @deprecated Please use loadV2. */
  readonly load?: Maybe<Load>;
  readonly loadV2?: Maybe<LoadV2>;
};

export type LoadAuditResponse = {
  readonly __typename?: 'LoadAuditResponse';
  readonly customer?: Maybe<Customer>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly customerOrder?: Maybe<CustomerOrder>;
  readonly customerOrderV2?: Maybe<CustomerOrderV2>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly eventData?: Maybe<Scalars['String']>;
  readonly eventType?: Maybe<Scalars['String']>;
  readonly eventTypeCategory?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly newValue?: Maybe<Scalars['String']>;
  readonly objectType?: Maybe<Scalars['String']>;
  readonly oldValue?: Maybe<Scalars['String']>;
  readonly reasonCode?: Maybe<Scalars['String']>;
  readonly route?: Maybe<LoadRoute>;
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly routeV2?: Maybe<LoadRouteV2>;
  readonly triggeredBySystem?: Maybe<Scalars['String']>;
  readonly triggeredByUser?: Maybe<User>;
  readonly triggeredByUserId?: Maybe<Scalars['ID']>;
  readonly triggeredByUserV2?: Maybe<UserV2>;
  readonly triggeredOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use newly added property triggeredByUser to get the user information. */
  readonly user?: Maybe<Employee>;
  readonly vendor?: Maybe<Carrier>;
  readonly vendorId?: Maybe<Scalars['ID']>;
  readonly vendorV2?: Maybe<CarrierV2>;
};

/** A connection to a list of items. */
export type LoadAuditResponseConnection = {
  readonly __typename?: 'LoadAuditResponseConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<LoadAuditResponseEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<LoadAuditResponse>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadAuditResponseEdge = {
  readonly __typename?: 'LoadAuditResponseEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<LoadAuditResponse>;
};

export type LoadCommissionsOverrideConfiguration = {
  readonly __typename?: 'LoadCommissionsOverrideConfiguration';
  readonly id: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly customerAllocationSplit?: Maybe<Scalars['Decimal']>;
  readonly carrierAllocationSplit?: Maybe<Scalars['Decimal']>;
  readonly globalCustomerAllocationSplit?: Maybe<Scalars['Decimal']>;
  readonly globalCarrierAllocationSplit?: Maybe<Scalars['Decimal']>;
};

export type LoadCommissionsOverrideConfigurationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly loadId: Scalars['ID'];
  readonly customerAllocationSplit: Scalars['Decimal'];
  readonly carrierAllocationSplit: Scalars['Decimal'];
};

/** The connection type for Load. */
export type LoadConnection = {
  readonly __typename?: 'LoadConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<LoadEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

export type LoadCurrency = {
  readonly __typename?: 'LoadCurrency';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type LoadCurrencyInput = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type LoadDefaults = {
  readonly __typename?: 'LoadDefaults';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['String'];
  readonly mode: Scalars['String'];
  /** @deprecated SizeTypesEnum is deprecated. Use sizeV2 instead. */
  readonly size?: Maybe<SizeTypesEnum>;
  readonly sizeV2?: Maybe<Scalars['String']>;
  readonly minLength?: Maybe<Scalars['Float']>;
  readonly width?: Maybe<Scalars['Float']>;
  readonly height?: Maybe<Scalars['Float']>;
  readonly equipment?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly specialRequirements?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly cargoValue?: Maybe<Scalars['Float']>;
  readonly cargoUnit?: Maybe<Scalars['String']>;
  readonly doNotPostLoads?: Maybe<Scalars['Boolean']>;
  readonly disableAutoPost: Scalars['Boolean'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly loadDefaultsDivisions?: Maybe<ReadonlyArray<LoadDefaultsDivision>>;
  readonly loadDefaultsBusinessUnits?: Maybe<ReadonlyArray<LoadDefaultsBusinessUnit>>;
  readonly loadDefaultsEquipment?: Maybe<ReadonlyArray<LoadDefaultsEquipment>>;
  readonly loadDefaultsSpecialRequirement?: Maybe<ReadonlyArray<LoadDefaultsSpecialRequirement>>;
};

export type LoadDefaultsBusinessUnit = {
  readonly __typename?: 'LoadDefaultsBusinessUnit';
  readonly id: Scalars['ID'];
  readonly loadDefaultsId: Scalars['String'];
  readonly businessUnit: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly loadDefaults?: Maybe<LoadDefaults>;
};

export type LoadDefaultsDivision = {
  readonly __typename?: 'LoadDefaultsDivision';
  readonly id: Scalars['ID'];
  readonly loadDefaultsId: Scalars['String'];
  readonly division: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly loadDefaults?: Maybe<LoadDefaults>;
};

export type LoadDefaultsEquipment = {
  readonly __typename?: 'LoadDefaultsEquipment';
  readonly id: Scalars['ID'];
  readonly loadDefaultsId: Scalars['String'];
  readonly equipment: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly loadDefaults?: Maybe<LoadDefaults>;
};

export type LoadDefaultsSpecialRequirement = {
  readonly __typename?: 'LoadDefaultsSpecialRequirement';
  readonly id: Scalars['ID'];
  readonly loadDefaultsId: Scalars['String'];
  readonly specialRequirement: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly loadDefaults?: Maybe<LoadDefaults>;
};

export type LoadDetail = {
  readonly __typename?: 'LoadDetail';
  readonly error?: Maybe<Scalars['String']>;
  readonly loadId?: Maybe<Scalars['ID']>;
};

export type LoadDocument = {
  readonly __typename?: 'LoadDocument';
  readonly id: Scalars['ID'];
  /**
   * DEPRECATED: Use loadV2 instead
   * @deprecated Use loadV2 instead
   */
  readonly load: Load;
  /** V2 version of load */
  readonly loadV2?: Maybe<LoadV2>;
  /**
   * DEPRECATED: Use customerOrderV2 instead
   * @deprecated Use customerOrderV2 instead
   */
  readonly customerOrder?: Maybe<CustomerOrder>;
  /** V2 version of customerOrder */
  readonly customerOrderV2?: Maybe<CustomerOrderV2>;
  /**
   * DEPRECATED: Use routes instead
   * @deprecated Use routes instead
   */
  readonly route?: Maybe<LoadRoute>;
  /**
   * DEPRECATED: Use routes instead
   * @deprecated Use routes instead
   */
  readonly routeV2?: Maybe<LoadRouteV2>;
  readonly routes?: Maybe<ReadonlyArray<LoadRouteV2>>;
  readonly vendorId?: Maybe<Scalars['ID']>;
  readonly stopIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  /** The name of the file to be displayed to the user, not necessarily the filename */
  readonly name: Scalars['String'];
  /** The name of the file provided during upload */
  readonly filename: Scalars['String'];
  /**
   * DEPRECATED: Use uploadedByUserV2 instead
   * @deprecated Use uploadedByUserV2 field instead
   */
  readonly uploadedBy: User;
  /**
   * DEPRECATED: Use uploadedByUserV2 instead
   * @deprecated Use uploadedByUserV2 instead
   */
  readonly uploadedByUser?: Maybe<User>;
  /**
   * DEPRECATED: Use updatedByUserV2 instead
   * @deprecated Use updatedByUserV2 instead
   */
  readonly updatedByUser?: Maybe<User>;
  /** Upload date timestamp of document */
  readonly uploadDate: Scalars['DateTime'];
  readonly sourceId: Scalars['ID'];
  readonly documentTypeId: Scalars['ID'];
  /** uri to access the document */
  readonly uri: Scalars['String'];
  readonly thirdPartyDocumentId?: Maybe<Scalars['ID']>;
  /**
   * DEPRECATED: Use uploadedByUserV2 instead
   * @deprecated Use uploadedByUserV2 field instead
   */
  readonly uploadedByV2: UserV2;
  readonly uploadedByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
};

export type LoadDocumentConnection = {
  readonly __typename?: 'LoadDocumentConnection';
  readonly edges: ReadonlyArray<LoadDocumentGqlTypeEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Float'];
};

export type LoadDocumentFilter = {
  readonly loadId: Scalars['ID'];
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly orderId?: InputMaybe<Scalars['ID']>;
};

export type LoadDocumentGqlTypeEdge = {
  readonly __typename?: 'LoadDocumentGQLTypeEdge';
  readonly cursor: Scalars['String'];
  readonly node: LoadDocument;
};

/** An edge in a connection. */
export type LoadEdge = {
  readonly __typename?: 'LoadEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: Load;
};

export type LoadInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly orders?: InputMaybe<ReadonlyArray<CreateOrUpdateCustomerOrderInput>>;
};

export type LoadInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly orders?: InputMaybe<ReadonlyArray<CustomerOrderInput>>;
  readonly stops?: InputMaybe<ReadonlyArray<CreateLoadStopInput>>;
};

export type LoadMoneySummary = {
  readonly __typename?: 'LoadMoneySummary';
  readonly companyOverhead?: Maybe<Scalars['Decimal']>;
  readonly costDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  readonly customerOverhead?: Maybe<Scalars['Decimal']>;
  readonly customerOverheadDetails?: Maybe<ReadonlyArray<Maybe<LoadMoneySummaryCustomerOverheadDetail>>>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly rateDetails?: Maybe<ReadonlyArray<Maybe<RateDetail>>>;
  readonly totalCost?: Maybe<Scalars['Decimal']>;
  readonly totalGrossRate?: Maybe<Scalars['Decimal']>;
  readonly totalNetMargin?: Maybe<Scalars['Decimal']>;
  readonly totalNetRate?: Maybe<Scalars['Decimal']>;
};

export type LoadMoneySummaryCustomerOverheadDetail = {
  readonly __typename?: 'LoadMoneySummaryCustomerOverheadDetail';
  readonly customer?: Maybe<Customer>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly customerOverhead?: Maybe<Scalars['Decimal']>;
  readonly customerV2?: Maybe<CustomerV2>;
};

export type LoadPayload = {
  readonly __typename?: 'LoadPayload';
  readonly errors: ReadonlyArray<LoadTypeError>;
  readonly load?: Maybe<LoadV2>;
};

export type LoadRepeatCreateCostDetails = {
  readonly costDetails: ReadonlyArray<CostDetailAddInput>;
  readonly rerateableCostDetails: ReadonlyArray<CostDetailAddInput>;
};

export type LoadRepeatCreatedCostDetailsPayload = {
  readonly __typename?: 'LoadRepeatCreatedCostDetailsPayload';
  readonly adjustmentRequired?: Maybe<Scalars['String']>;
  readonly createdCostDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
};

export type LoadRepeatCreatedRateDetailsPayload = {
  readonly __typename?: 'LoadRepeatCreatedRateDetailsPayload';
  readonly adjustmentRequired?: Maybe<Scalars['String']>;
  readonly createdRateDetails?: Maybe<ReadonlyArray<Maybe<RateDetail>>>;
};

export type LoadRepeatCreateRateDetails = {
  readonly rateDetails: ReadonlyArray<RateDetailAddInput>;
  readonly rerateableRateDetails: ReadonlyArray<RateDetailAddInput>;
};

export type LoadRequiredDocumentsSummary = {
  readonly __typename?: 'LoadRequiredDocumentsSummary';
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly entityName?: Maybe<Scalars['String']>;
  readonly isPaperworkComplete?: Maybe<Scalars['Boolean']>;
  readonly loadId: Scalars['ID'];
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['ID']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly vendorId?: Maybe<Scalars['ID']>;
};

export type LoadRoute = {
  readonly __typename?: 'LoadRoute';
  readonly activationStatus?: Maybe<KeyValue>;
  readonly activeRouteVendors?: Maybe<ReadonlyArray<RouteVendor>>;
  readonly actualMaxWeight?: Maybe<Mass>;
  readonly cargoInsurance?: Maybe<Currency>;
  readonly code?: Maybe<Scalars['String']>;
  readonly dimensions?: Maybe<CustomerOrderDimensions>;
  readonly division?: Maybe<Scalars['String']>;
  readonly expectedMaxWeight?: Maybe<Mass>;
  readonly firstStop?: Maybe<Stop>;
  readonly hotRoute?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  readonly inboundRegion?: Maybe<Region>;
  readonly lastStop?: Maybe<Stop>;
  readonly lifeCycleStatus?: Maybe<Scalars['String']>;
  readonly load?: Maybe<Load>;
  readonly maxCost?: Maybe<RouteMaxCostType>;
  readonly outboundRegion?: Maybe<Region>;
  readonly requirements?: Maybe<ReadonlyArray<OrderRequirement>>;
  readonly routeVendors?: Maybe<ReadonlyArray<RouteVendor>>;
  readonly segmentCode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<KeyValue>;
  readonly stops: ReadonlyArray<Stop>;
  readonly stopsCount?: Maybe<Scalars['Int']>;
  readonly totalDistance?: Maybe<Length>;
  readonly trailerTypes?: Maybe<ReadonlyArray<KeyValue>>;
  readonly transportMode?: Maybe<KeyValue>;
  readonly postings?: Maybe<ReadonlyArray<Maybe<Posting>>>;
  readonly offers?: Maybe<ReadonlyArray<Offer>>;
  readonly lock?: Maybe<Lock>;
  readonly routeVendorsV2?: Maybe<ReadonlyArray<RouteVendorV2>>;
};


export type LoadRouteActualMaxWeightArgs = {
  unit?: InputMaybe<MassUnitTypeEnum>;
};


export type LoadRouteCargoInsuranceArgs = {
  unit?: InputMaybe<UnitOfCurrencyEnum>;
};


export type LoadRouteExpectedMaxWeightArgs = {
  unit?: InputMaybe<MassUnitTypeEnum>;
};


export type LoadRouteTotalDistanceArgs = {
  unit: UnitOfLengthEnum;
};


export type LoadRouteOffersArgs = {
  filter?: InputMaybe<GetLoadRouteOffersArgsInput>;
};


export type LoadRouteLockArgs = {
  flavor: LockFlavor;
};

/** The connection type for LoadRoute. */
export type LoadRouteConnection = {
  readonly __typename?: 'LoadRouteConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<LoadRouteEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LoadRouteEdge = {
  readonly __typename?: 'LoadRouteEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: LoadRoute;
};

export type LoadRouteEdgeV2 = {
  readonly __typename?: 'LoadRouteEdgeV2';
  readonly node: LoadRouteV2;
  readonly cursor: Scalars['String'];
};

export type LoadRouteError = {
  readonly __typename?: 'LoadRouteError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

export type LoadRoutePayload = {
  readonly __typename?: 'LoadRoutePayload';
  readonly errors: ReadonlyArray<LoadRouteError>;
  readonly route?: Maybe<LoadRouteV2>;
};

export type LoadRoutesConnection = {
  readonly __typename?: 'LoadRoutesConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<LoadRouteEdgeV2>;
};

export type LoadRoutesFilter = {
  /** A list of life cycle statuses to filter by */
  readonly lifecycleStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of activation statuses to filter by */
  readonly activationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of order rep IDs to filter by */
  readonly orderRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Minimum available start date */
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  /** Maximum available start date */
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  /** A list of inbound sub-region URNs to filter by */
  readonly inboundSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of outbound sub-region URNs to filter by */
  readonly outboundSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of inbound region URNs to filter by */
  readonly inboundRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of inbound super-region URNs to filter by */
  readonly inboundSuperRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of outbound region URNs to filter by */
  readonly outboundRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of outbound super-region URNs to filter by */
  readonly outboundSuperRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of inbound FreightAssignmentRegions to filter by */
  readonly inboundFreightAssignmentRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of outbound FreightAssignmentRegions to filter by */
  readonly outboundFreightAssignmentRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of inbound FreightAssignmentSuperRegions to filter by */
  readonly inboundFreightAssignmentSuperRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of outbound FreightAssignmentSuperRegions to filter by */
  readonly outboundFreightAssignmentSuperRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of inbound FreightAssignmentSubRegions to filter by */
  readonly inboundFreightAssignmentSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of outbound FreightAssignmentSubRegions to filter by */
  readonly outboundFreightAssignmentSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A route code to filter by */
  readonly code?: InputMaybe<Scalars['String']>;
  /** A list of customer IDs to filter by */
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of customer groups to filter by */
  readonly customerGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of equipments to filter by */
  readonly trailerType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** A list of transport modes to filter by */
  readonly transportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Radius and geopoint to filter against first stop address */
  readonly firstStopRadius?: InputMaybe<WithinRadiusInput>;
  /** Radius and geopoint to filter against last stop address */
  readonly lastStopRadius?: InputMaybe<WithinRadiusInput>;
  /** A list of route IDs to filter by */
  readonly routeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** GraphQL access to LoadRoutesOrders database view */
export type LoadRoutesOrders = {
  readonly __typename?: 'LoadRoutesOrders';
  readonly accountingOrder?: Maybe<Order>;
  readonly firstRouteStop?: Maybe<Stop>;
  readonly firstRouteStopId?: Maybe<Scalars['ID']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly lastRouteStop?: Maybe<Stop>;
  readonly lastRouteStopId?: Maybe<Scalars['ID']>;
  readonly load?: Maybe<Load>;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly mainCustomerRepEmployee?: Maybe<Employee>;
  /** @deprecated Field disabled, use main_customer_rep_employee */
  readonly mainCustomerSalesRep?: Maybe<Rep>;
  readonly mainRouteVendorRep?: Maybe<RouteVendorRep>;
  readonly maxCost?: Maybe<RouteMaxCostType>;
  readonly order?: Maybe<CustomerOrder>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly orderCustomer?: Maybe<Customer>;
  readonly orderCustomerId?: Maybe<Scalars['ID']>;
  readonly orderFinancialStatus?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['ID']>;
  readonly orderLifecycleStatus?: Maybe<Scalars['String']>;
  readonly route?: Maybe<LoadRoute>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly routeVendorCost?: Maybe<RouteVendorCost>;
  readonly size?: Maybe<Scalars['String']>;
  /** @deprecated Field disabled, use main_customer_rep_employee */
  readonly tempMainCustomerSalesRep?: Maybe<OrderRep>;
  readonly trailerTypes?: Maybe<ReadonlyArray<KeyValue>>;
  readonly transportMode?: Maybe<Scalars['String']>;
};

/** The connection type for LoadRoutesOrders. */
export type LoadRoutesOrdersConnection = {
  readonly __typename?: 'LoadRoutesOrdersConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<LoadRoutesOrdersEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LoadRoutesOrdersEdge = {
  readonly __typename?: 'LoadRoutesOrdersEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: LoadRoutesOrders;
};

export type LoadRouteV2 = {
  readonly __typename?: 'LoadRouteV2';
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly activationStatus?: Maybe<KeyValue>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly size?: Maybe<KeyValue>;
  readonly dimensions?: Maybe<RouteDimensions>;
  /**
   * Please use cargoInsuranceV2. It supports new currency values
   * @deprecated Please use cargoInsuranceV2. It supports new currency values
   */
  readonly cargoInsurance: Currency;
  readonly lifeCycleStatus: Scalars['String'];
  readonly transportMode?: Maybe<KeyValue>;
  readonly trailerTypes: ReadonlyArray<KeyValue>;
  readonly requirements?: Maybe<ReadonlyArray<RouteRequirement>>;
  readonly version: Scalars['Int'];
  readonly stops: ReadonlyArray<RouteStop>;
  readonly stopsV2?: Maybe<ReadonlyArray<RouteStopV2>>;
  readonly stopsCount: Scalars['Int'];
  readonly firstStop?: Maybe<RouteStop>;
  readonly firstStopV2?: Maybe<RouteStopV2>;
  readonly lastStop?: Maybe<RouteStop>;
  readonly lastStopV2?: Maybe<RouteStopV2>;
  readonly outboundRegion?: Maybe<Region>;
  readonly inboundRegion?: Maybe<Region>;
  readonly lock?: Maybe<Lock>;
  readonly division?: Maybe<Scalars['String']>;
  readonly segmentCode?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<User>;
  readonly updatedByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly expectedMaxWeight?: Maybe<Mass>;
  readonly expectedMinTemperature?: Maybe<TemperatureV2>;
  readonly hotRoute?: Maybe<Scalars['Boolean']>;
  readonly sequence?: Maybe<Scalars['Float']>;
  readonly freightAssignmentInboundRegion?: Maybe<Scalars['String']>;
  readonly freightAssignmentOutboundRegion?: Maybe<Scalars['String']>;
  readonly activationStatusReason?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['ID']>;
  readonly divisionV2?: Maybe<Scalars['String']>;
  readonly businessUnit?: Maybe<Scalars['String']>;
  readonly project?: Maybe<Scalars['String']>;
  readonly projectV2?: Maybe<Scalars['String']>;
  readonly tarpType?: Maybe<Scalars['ID']>;
  readonly tarpCount?: Maybe<Scalars['Int']>;
  readonly braceTypes?: Maybe<ReadonlyArray<BraceTypes>>;
  readonly braceCount?: Maybe<Scalars['Int']>;
  readonly cargoInsuranceV2: LoadCurrency;
  readonly load?: Maybe<LoadV2>;
  readonly totalDistance?: Maybe<Length>;
  readonly trackingDetails: TrackingDetailsV2;
  /** Most recent Tracking Update with a current location */
  readonly currentLocationUpdate?: Maybe<TrackingUpdateV4>;
  readonly currentStop?: Maybe<RouteStop>;
  readonly nextStop?: Maybe<RouteStop>;
  readonly currentStopV2?: Maybe<RouteStopV2>;
  readonly nextStopV2?: Maybe<RouteStopV2>;
  readonly postings?: Maybe<ReadonlyArray<Maybe<Posting>>>;
  readonly offers?: Maybe<ReadonlyArray<Offer>>;
  readonly previousRoute?: Maybe<LoadRouteV2>;
  readonly nextRoute?: Maybe<LoadRouteV2>;
  readonly activeRouteVendors?: Maybe<ReadonlyArray<RouteVendorV2>>;
  readonly routeVendors?: Maybe<ReadonlyArray<RouteVendorV2>>;
  /** @deprecated Use matchedRoutingGuide instead */
  readonly routingGuide?: Maybe<RoutingGuide>;
  readonly matchedRoutingGuide?: Maybe<RoutingGuide>;
  readonly maxCost?: Maybe<RouteMaxCostType>;
};


export type LoadRouteV2CargoInsuranceArgs = {
  unit?: InputMaybe<UnitOfCurrencyEnum>;
};


export type LoadRouteV2ExpectedMaxWeightArgs = {
  unit: MassUnitTypeEnum;
};


export type LoadRouteV2ExpectedMinTemperatureArgs = {
  unit: TemperatureUnitTypeEnumV2;
};


export type LoadRouteV2CargoInsuranceV2Args = {
  unit?: InputMaybe<Scalars['String']>;
};


export type LoadRouteV2TotalDistanceArgs = {
  unit: UnitOfLengthEnum;
};


export type LoadRouteV2OffersArgs = {
  filter?: InputMaybe<GetLoadRouteOffersArgsInput>;
};

export type LoadsFilter = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly carrierRepId?: InputMaybe<Scalars['ID']>;
  readonly consigneeId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by customer group IDs */
  readonly customerEmployeeGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by customer IDs */
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly customerRepId?: InputMaybe<Scalars['ID']>;
  /** Search/Filter by delivery_address */
  readonly deliveryAddress?: InputMaybe<AddressesFilter>;
  readonly deliveryDateRange?: InputMaybe<DateRangeFilter>;
  /** Search/Filter by equipments */
  readonly equipmentIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly loadCode?: InputMaybe<Scalars['String']>;
  readonly loadRouteOrOrderCode?: InputMaybe<Scalars['String']>;
  readonly orderActivationStatus?: InputMaybe<Scalars['ID']>;
  /** Search/Filter by Order Activation status */
  readonly orderActivationStatusWithNone?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly orderCode?: InputMaybe<Scalars['String']>;
  readonly orderRepEmployeeId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by order rep IDs */
  readonly orderRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly orderTenderStatus?: InputMaybe<Scalars['ID']>;
  /** Search/Filter by pick_up_address */
  readonly pickUpAddress?: InputMaybe<AddressesFilter>;
  readonly pickUpDateRange?: InputMaybe<DateRangeFilter>;
  readonly ref?: InputMaybe<Scalars['String']>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly routeLifecycleStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by route rep OR order rep ids */
  readonly routeRepOrOrderRepIds?: InputMaybe<RepIdsFilter>;
  readonly routeVendorRepEmployeeId?: InputMaybe<Scalars['ID']>;
  readonly shipperPickUpFacilityId?: InputMaybe<Scalars['ID']>;
  readonly sort?: InputMaybe<Scalars['String']>;
  /** Use delivery_address or pick_up_address for state collection filtering */
  readonly stateCollection?: InputMaybe<StateFilter>;
  /** Search/filter by transport modes */
  readonly transportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export enum LoadSortDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Lachesis Load Status Record */
export type LoadStatusRecord = {
  readonly __typename?: 'LoadStatusRecord';
  readonly load_id: Scalars['ID'];
  readonly created_at_timestamp: Scalars['Timestamp'];
  readonly event_timestamp: Scalars['Timestamp'];
  readonly order_financial_status?: Maybe<Scalars['String']>;
  readonly vendor_financial_status?: Maybe<Scalars['String']>;
  readonly order_tender_status?: Maybe<Scalars['String']>;
  readonly route_tender_status?: Maybe<Scalars['String']>;
  readonly order_activation_status?: Maybe<Scalars['String']>;
  readonly route_activation_status?: Maybe<Scalars['String']>;
  readonly order_lifecycle_status?: Maybe<Scalars['String']>;
  readonly route_lifecycle_status?: Maybe<Scalars['String']>;
  readonly route_stop_number?: Maybe<Scalars['Int']>;
  readonly initiator?: Maybe<Scalars['String']>;
};

/** lachesis-api: Load Status Record V3 - Features Multi-Route Functionality */
export type LoadStatusRecordV3 = {
  readonly __typename?: 'LoadStatusRecordV3';
  /** lachesis-api: ID associated with a Load. */
  readonly load_id: Scalars['ID'];
  /** lachesis-api: Timestamp when event occured */
  readonly event_timestamp: Scalars['Timestamp'];
  /** lachesis-api: ID of the user or process that initiated the event for status calculation */
  readonly initiator?: Maybe<Scalars['String']>;
  /** lachesis-api: Array of Orders. */
  readonly orders: ReadonlyArray<OrderStatusRecordV3>;
  /** lachesis-api: Array of Routes. */
  readonly routes: ReadonlyArray<RouteStatusRecordV3>;
};

export type LoadStop = {
  readonly __typename?: 'LoadStop';
  readonly id: Scalars['ID'];
  readonly loadId: Scalars['String'];
  readonly routeStopIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly orderStopIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly sequence: Scalars['Int'];
};

export type LoadStopInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly routeId: Scalars['ID'];
  readonly facilityId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly scheduleType?: InputMaybe<ScheduleAppointmentTypeEnum>;
  readonly liveType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly workType?: InputMaybe<ScheduleWorkTypeEnum>;
  readonly isOrderStop?: InputMaybe<Scalars['Boolean']>;
  readonly type?: InputMaybe<StopTypeEnum>;
  /**
   * value is validated against following options:
   *   crossDock, originRamp, destinationRamp, customsBroker, yard, PU, Del, TA, TD, domicile, maintenanceShop, scale, fuel, driverComplianceTraining, waypoint, railJunction, relay, terminal, seaPort, airport, containerDepot, borderCrossing, transload, trailerWash
   */
  readonly loadStopType?: InputMaybe<Scalars['String']>;
  readonly loadFromId?: InputMaybe<Scalars['ID']>;
  readonly unloadFromId?: InputMaybe<Scalars['ID']>;
  readonly requirements?: InputMaybe<ReadonlyArray<StopRequirementInput>>;
  readonly commodities?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly refs: ReadonlyArray<RefInputV2>;
  readonly _destroy?: InputMaybe<Scalars['Boolean']>;
  readonly reqDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly availableEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly bolNumber?: InputMaybe<Scalars['String']>;
  readonly appointment?: InputMaybe<AppointmentInputV2>;
  readonly sealNumber?: InputMaybe<Scalars['String']>;
  readonly apptConfBy?: InputMaybe<Scalars['String']>;
  readonly apptReqWith?: InputMaybe<Scalars['String']>;
  /** stop unique identifier for external system */
  readonly externalRef?: InputMaybe<Scalars['String']>;
  readonly appointmentChangeReason?: InputMaybe<Scalars['String']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  /** flag for stops created from FE */
  readonly isCreatedManually?: InputMaybe<Scalars['Boolean']>;
  readonly splc?: InputMaybe<Scalars['String']>;
  /** PU/Del code(s) from customer-facility relationship */
  readonly externalFacilityId?: InputMaybe<Scalars['String']>;
};

export type LoadStopPayload = {
  readonly __typename?: 'LoadStopPayload';
  readonly errors: ReadonlyArray<LoadStopReturnError>;
  readonly stops?: Maybe<LoadStopReturn>;
};

export type LoadStopReturn = {
  readonly __typename?: 'LoadStopReturn';
  readonly loadStops?: Maybe<ReadonlyArray<LoadStop>>;
};

export type LoadStopReturnError = {
  readonly __typename?: 'LoadStopReturnError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

export type LoadTypeError = {
  readonly __typename?: 'LoadTypeError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

export type LoadV2 = {
  readonly __typename?: 'LoadV2';
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly status: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly version: Scalars['Int'];
  readonly note?: Maybe<Scalars['String']>;
  readonly createdBy: User;
  readonly createdByV2: UserV2;
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly orders: ReadonlyArray<CustomerOrderV2>;
  readonly routes: ReadonlyArray<LoadRouteV2>;
  readonly stops: ReadonlyArray<LoadStop>;
};

export type LocalityStop = {
  readonly __typename?: 'LocalityStop';
  readonly locality: Scalars['String'];
  readonly administrativeArea: Scalars['String'];
  readonly countryCode: Scalars['String'];
};

/** this is the type for a City, ST location */
export type Location = {
  readonly __typename?: 'Location';
  readonly displayString: Scalars['String'];
  readonly lat: Scalars['Float'];
  readonly lng: Scalars['Float'];
};

export type LocationDat = {
  readonly location: Scalars['String'];
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

/** this is the input type for a City, ST location */
export type LocationInput = {
  readonly displayString?: InputMaybe<Scalars['String']>;
  readonly lat?: InputMaybe<Scalars['Float']>;
  readonly lng?: InputMaybe<Scalars['Float']>;
};

/** Caen Lock */
export type Lock = {
  readonly __typename?: 'Lock';
  readonly id: Scalars['ID'];
  readonly flavor: LockFlavor;
  readonly objectID: Scalars['ID'];
  readonly owner: User;
  readonly ownerV2: UserV2;
  readonly expirationTimestamp: Scalars['Timestamp'];
};

/** The flavor of the Lock represents the particular service which the lock enables,         e.g. restricting adding a carrier on a route to only the user who owns the lock */
export enum LockFlavor {
  Route = 'ROUTE'
}

/** State information about a Lock */
export type LockState = {
  readonly __typename?: 'LockState';
  readonly isLocked: Scalars['Boolean'];
  readonly wasLocked: Scalars['Boolean'];
};

export type LogicalCheck = {
  readonly __typename?: 'LogicalCheck';
  readonly value?: Maybe<Scalars['String']>;
  readonly operator?: Maybe<Scalars['String']>;
  readonly dataName?: Maybe<Scalars['String']>;
  /** @deprecated This property has been replaced by value and will be removed in the future */
  readonly comparator?: Maybe<Scalars['String']>;
};

export type LogicalCheckInput = {
  readonly value?: InputMaybe<Scalars['String']>;
  readonly operator?: InputMaybe<Scalars['String']>;
  readonly dataName?: InputMaybe<Scalars['String']>;
  readonly comparator?: InputMaybe<Scalars['String']>;
};

export enum LogicGate {
  And = 'And',
  Or = 'Or'
}

export enum ManualTrackingMethodEnum {
  /** Phone */
  Phone = 'phone',
  /** Email */
  Email = 'email',
  /** Web */
  Web = 'web'
}

export type Mass = {
  readonly __typename?: 'Mass';
  readonly unit: MassUnitTypeEnum;
  readonly value: Scalars['Float'];
};

export enum MassUnitTypeEnum {
  G = 'g',
  Kg = 'kg',
  Lbs = 'lbs',
  St = 'st',
  Tonnes = 'tonnes',
  Tons = 'tons'
}

export type MassV2 = {
  readonly __typename?: 'MassV2';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type MasterBidError = {
  readonly __typename?: 'MasterBidError';
  readonly message?: Maybe<Scalars['String']>;
};

/** Truck Match Result from ElasticSearch (v2) */
export type Match = {
  readonly __typename?: 'Match';
  readonly id: Scalars['ID'];
  readonly routeId: Scalars['String'];
  readonly loadId: Scalars['String'];
  readonly capacityNeed?: Maybe<ReadonlyArray<CapacityNeed>>;
  readonly originDeadheadMileage?: Maybe<Scalars['Float']>;
  readonly destinationDeadheadMileage?: Maybe<Scalars['Float']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly routePickupCity?: Maybe<Scalars['String']>;
  readonly routePickupState?: Maybe<Scalars['String']>;
  readonly routePickupCountry?: Maybe<Scalars['String']>;
  readonly routePickupStartDate?: Maybe<Scalars['DateTime']>;
  readonly routePickupEndDate?: Maybe<Scalars['DateTime']>;
  readonly routePickupTimeZone?: Maybe<Scalars['String']>;
  readonly routePickupStartDay?: Maybe<Scalars['String']>;
  readonly routePickupEndDay?: Maybe<Scalars['String']>;
  readonly routeFirstStopDateRange?: Maybe<AvailableDateRange>;
  readonly routeFirstStopLiveType?: Maybe<ScheduleDropTypeEnum>;
  readonly routeDeliveryCity?: Maybe<Scalars['String']>;
  readonly routeDeliveryState?: Maybe<Scalars['String']>;
  readonly routeDeliveryCountry?: Maybe<Scalars['String']>;
  readonly routeDeliveryStartDate?: Maybe<Scalars['DateTime']>;
  readonly routeDeliveryEndDate?: Maybe<Scalars['DateTime']>;
  readonly routeDeliveryTimeZone?: Maybe<Scalars['String']>;
  readonly routeDeliveryStartDay?: Maybe<Scalars['String']>;
  readonly routeDeliveryEndDay?: Maybe<Scalars['String']>;
  readonly routeLastStopDateRange?: Maybe<AvailableDateRange>;
  readonly routeLastStopLiveType?: Maybe<ScheduleDropTypeEnum>;
  readonly routeLength?: Maybe<Scalars['Int']>;
  readonly routeTrailerLength?: Maybe<Length>;
  readonly routeCommodityMinTemperature?: Maybe<TemperatureV2>;
  readonly stopCount?: Maybe<Scalars['Int']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerRepName?: Maybe<Scalars['String']>;
  readonly routeTrailerTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly segmentCode?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly requirements?: Maybe<ReadonlyArray<OrderRequirementInfo>>;
  readonly hotRoute?: Maybe<Scalars['Boolean']>;
  readonly tmsType?: Maybe<Scalars['String']>;
  readonly mainCustomerRepEmployeeId?: Maybe<Scalars['String']>;
  readonly expectedMaxWeight?: Maybe<Scalars['Float']>;
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly equipment?: Maybe<Scalars['String']>;
  readonly routeLoadRouteSequence?: Maybe<Fraction>;
  readonly truckPostingId: Scalars['String'];
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly readyByDate: Scalars['DateTime'];
  readonly truckReadyByDay?: Maybe<Scalars['String']>;
  readonly truckLoadByDate: Scalars['DateTime'];
  readonly truckLoadByDay?: Maybe<Scalars['String']>;
  readonly truckReadyByLoadByDateRange?: Maybe<AvailableDateRange>;
  readonly truckOriginCity?: Maybe<Scalars['String']>;
  readonly truckOriginState?: Maybe<Scalars['String']>;
  readonly truckOriginCountry?: Maybe<Scalars['String']>;
  readonly truckDestinationCity?: Maybe<Scalars['String']>;
  readonly truckDestinationState?: Maybe<Scalars['String']>;
  readonly truckDestinationStates?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly truckDestinationCountryList?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly truckCreatedByUserId?: Maybe<Scalars['String']>;
  readonly truckUpdatedByUserId?: Maybe<Scalars['String']>;
  readonly truckCreatedByEmployeeId?: Maybe<Scalars['String']>;
  readonly truckUpdatedByEmployeeId?: Maybe<Scalars['String']>;
  readonly mainCarrierRepEmployeeId?: Maybe<Scalars['String']>;
  readonly route?: Maybe<AvailableRoute>;
  readonly truckPosting?: Maybe<AvailableTruck>;
  /** @deprecated Use the dynamic haversine calculation */
  readonly isExactMileage?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use `truckCreatedByEmployeeId` */
  readonly employeeId?: Maybe<Scalars['String']>;
  /** @deprecated Use `truckDestinationCountryList` */
  readonly truckDestinationCountries?: Maybe<Scalars['String']>;
  /** @deprecated Type RouteOffer is deprecated */
  readonly offers?: Maybe<ReadonlyArray<RouteOffer>>;
};

export type MatchConnection = {
  readonly __typename?: 'MatchConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<MatchEdge>;
};

export type MatchEdge = {
  readonly __typename?: 'MatchEdge';
  readonly node: Match;
  /** Used in `before` and `after` args */
  readonly cursor: Scalars['String'];
};

/** Indicates which matches date data to evaluate against filter e.g. `RoutePickupDateRange` */
export enum MatchesDateRangeFilterTarget {
  RoutePickupDateRange = 'RoutePickupDateRange',
  TruckPostingReadyDateRange = 'TruckPostingReadyDateRange'
}

/** Truck Match Filter */
export type MatchFilter = {
  readonly sortBy?: InputMaybe<Scalars['String']>;
  readonly sortDirection?: InputMaybe<Scalars['String']>;
  readonly matchId?: InputMaybe<Scalars['String']>;
  readonly routeId?: InputMaybe<Scalars['String']>;
  readonly routeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadId?: InputMaybe<Scalars['String']>;
  readonly capacityNeed?: InputMaybe<ReadonlyArray<CapacityNeed>>;
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly carrierName?: InputMaybe<Scalars['String']>;
  readonly routeActivationStatus?: InputMaybe<Scalars['String']>;
  readonly routeActivationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly originDeadheadInMiles?: InputMaybe<Scalars['Float']>;
  readonly destinationDeadheadInMiles?: InputMaybe<Scalars['Float']>;
  readonly includeDeleted?: InputMaybe<Scalars['Boolean']>;
  readonly routeNumber?: InputMaybe<Scalars['String']>;
  readonly routePickupCity?: InputMaybe<Scalars['String']>;
  readonly routePickupState?: InputMaybe<Scalars['String']>;
  readonly routePickupStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routePickupCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routePickupDate?: InputMaybe<Scalars['DateTime']>;
  readonly routePickupTimeZone?: InputMaybe<Scalars['String']>;
  readonly routeDeliveryCity?: InputMaybe<Scalars['String']>;
  readonly routeDeliveryState?: InputMaybe<Scalars['String']>;
  readonly routeDeliveryStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDeliveryCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDeliveryDate?: InputMaybe<Scalars['DateTime']>;
  readonly routeDeliveryTimeZone?: InputMaybe<Scalars['String']>;
  readonly routeCommodityMinTemperature?: InputMaybe<TemperatureV2Input>;
  readonly routeLength?: InputMaybe<Scalars['Int']>;
  readonly stopCount?: InputMaybe<Scalars['Int']>;
  readonly maxCost?: InputMaybe<Scalars['Float']>;
  readonly routeFirstStopLiveType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeLastStopLiveType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly customerName?: InputMaybe<Scalars['String']>;
  readonly customerRepName?: InputMaybe<Scalars['String']>;
  readonly equipment?: InputMaybe<Scalars['String']>;
  readonly routeTrailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeDivisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  readonly requirements?: InputMaybe<Scalars['String']>;
  readonly hotRoute?: InputMaybe<Scalars['Boolean']>;
  readonly routeTrailerLength?: InputMaybe<UnitOfLengthInput>;
  readonly truckPostingId?: InputMaybe<Scalars['String']>;
  readonly truckPostingIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly gateReservationId?: InputMaybe<Scalars['String']>;
  readonly containerId?: InputMaybe<Scalars['String']>;
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  readonly dateRangeFilterTarget?: InputMaybe<MatchesDateRangeFilterTarget>;
  readonly timeZone?: InputMaybe<Scalars['String']>;
  readonly truckOriginCity?: InputMaybe<Scalars['String']>;
  readonly truckOriginState?: InputMaybe<Scalars['String']>;
  readonly truckOriginStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly truckOriginCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly truckDestinationCity?: InputMaybe<Scalars['String']>;
  readonly truckDestinationStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly truckDestinationCountryList?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly truckCreatedByUserId?: InputMaybe<Scalars['String']>;
  readonly truckUpdatedByUserId?: InputMaybe<Scalars['String']>;
  readonly routePickupStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly routePickupEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly routeDeliveryStartDate?: InputMaybe<Scalars['DateTime']>;
  readonly routeDeliveryEndDate?: InputMaybe<Scalars['DateTime']>;
  readonly truckDestinationState?: InputMaybe<Scalars['String']>;
  readonly truckDestinationCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type MatchingSpotQuoteInput = {
  readonly orderId: Scalars['ID'];
  readonly laneId: Scalars['ID'];
};

export type MatchingSpotQuotePayload = {
  readonly __typename?: 'MatchingSpotQuotePayload';
  readonly data?: Maybe<SpotQuote>;
  readonly errors?: Maybe<ReadonlyArray<SpotQuoteError>>;
};

export type MatchQuery = {
  readonly __typename?: 'MatchQuery';
  readonly createdBy: User;
  readonly id: Scalars['ID'];
  readonly results: ReadonlyArray<MatchResult>;
  readonly resultsConnection: MatchResultConnection;
};


export type MatchQueryResultsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<MatchResultConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type MatchQueryCombined = {
  readonly __typename?: 'MatchQueryCombined';
  readonly createdBy: User;
  readonly id: Scalars['ID'];
  readonly results: ReadonlyArray<MatchResultCombined>;
  readonly resultsConnection: MatchResultCombinedConnection;
};


export type MatchQueryCombinedResultsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<MatchResultConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type MatchQueryInput = {
  readonly destinationDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly endDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly matchable: MatchQueryMatchableInput;
  readonly originDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly startDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
};

export enum MatchQueryMatchableEnum {
  Carrier = 'Carrier',
  Employee = 'Employee',
  TruckPosting = 'TruckPosting'
}

export type MatchQueryMatchableInput = {
  readonly id: Scalars['ID'];
  readonly type: MatchQueryMatchableEnum;
};

export type MatchResult = {
  readonly __typename?: 'MatchResult';
  readonly destinationDeadheadMileage?: Maybe<UnitOfDistance>;
  readonly destinationDeadheadStraightaway?: Maybe<UnitOfDistance>;
  readonly id: Scalars['ID'];
  readonly originDeadheadMileage?: Maybe<UnitOfDistance>;
  readonly originDeadheadStraightaway?: Maybe<UnitOfDistance>;
  readonly tempLoad: TempLoad;
  /** @deprecated Service has reached EOL. Use TruckEntry subgraph. Will return null only soon */
  readonly truckPosting: TruckPosting;
};

export type MatchResultCombined = {
  readonly __typename?: 'MatchResultCombined';
  readonly destinationDeadheadMileage?: Maybe<UnitOfDistance>;
  readonly destinationDeadheadStraightaway?: Maybe<UnitOfDistance>;
  readonly id: Scalars['ID'];
  readonly originDeadheadMileage?: Maybe<UnitOfDistance>;
  readonly originDeadheadStraightaway?: Maybe<UnitOfDistance>;
  readonly route?: Maybe<LoadRoute>;
  readonly tempLoad?: Maybe<TempLoad>;
  /** @deprecated Service has reached EOL. Use TruckEntry subgraph. Will return null only soon */
  readonly truckPosting: TruckPosting;
};

/** The connection type for MatchResultCombined. */
export type MatchResultCombinedConnection = {
  readonly __typename?: 'MatchResultCombinedConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<MatchResultCombinedEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MatchResultCombinedEdge = {
  readonly __typename?: 'MatchResultCombinedEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: MatchResultCombined;
};

/** The connection type for MatchResult. */
export type MatchResultConnection = {
  readonly __typename?: 'MatchResultConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<MatchResultEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

export type MatchResultConnectionFilter = {
  /** Search/Filter carrier name */
  readonly carrierName?: InputMaybe<Scalars['String']>;
  /** Search/filter customer name */
  readonly customer?: InputMaybe<Scalars['String']>;
  /** Search/filter customer name */
  readonly customerRepName?: InputMaybe<Scalars['String']>;
  /** Search/filter load destination location (matches city & state) */
  readonly loadDestinationText?: InputMaybe<Scalars['String']>;
  /** Search/filter load origin location (matches city & state) */
  readonly loadOriginText?: InputMaybe<Scalars['String']>;
  /** Search/filter load numbers */
  readonly revenovaLoadNumber?: InputMaybe<Scalars['String']>;
  readonly sort?: InputMaybe<Scalars['String']>;
  readonly timezone?: InputMaybe<Scalars['String']>;
  /** Search/filter truck destination location (matches city & state) */
  readonly truckDestinationText?: InputMaybe<Scalars['String']>;
  /** Search/filter truck origin location (matches city & state) */
  readonly truckOriginText?: InputMaybe<Scalars['String']>;
  readonly truckType?: InputMaybe<Scalars['String']>;
  /** Delivery start range beginning */
  readonly utcDeliveryStartDatetimeEnd?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Delivery start range beginning */
  readonly utcDeliveryStartDatetimeStart?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Pickup start range beginning */
  readonly utcPickupStartDatetimeEnd?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Pickup start range beginning */
  readonly utcPickupStartDatetimeStart?: InputMaybe<Scalars['ISO8601DateTime']>;
};

/** An edge in a connection. */
export type MatchResultEdge = {
  readonly __typename?: 'MatchResultEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: MatchResult;
};

export type MergeBlumeCostDetails = {
  readonly blumeFileId?: InputMaybe<Scalars['ID']>;
  readonly blumeImportKey?: InputMaybe<Scalars['String']>;
  readonly costDetails: ReadonlyArray<CostDetailAddInput>;
};

export type MergeBlumeCostDetailsPayload = {
  readonly __typename?: 'MergeBlumeCostDetailsPayload';
  readonly adjustmentRequired?: Maybe<Scalars['String']>;
  readonly createdCostDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  readonly deletedCostDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
};

/** The input type used for merging cost details from abacus */
export type MergeCostDetailsInput = {
  readonly costDetails: ReadonlyArray<CostDetailAddInput>;
  readonly loadId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
  readonly userIdsToDeleteRecordsFor?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly vendorId: Scalars['ID'];
};

export type MergedCostDetailsModel = {
  readonly __typename?: 'MergedCostDetailsModel';
  readonly adjustmentRequired?: Maybe<Scalars['String']>;
  readonly createdCostDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  readonly deletedCostDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  readonly notMergedDueToProtectedCostDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
};

export type MergedRateDetailsModel = {
  readonly __typename?: 'MergedRateDetailsModel';
  readonly adjustmentRequired?: Maybe<Scalars['String']>;
  readonly createdRateDetails?: Maybe<ReadonlyArray<Maybe<RateDetail>>>;
  readonly deletedRateDetails?: Maybe<ReadonlyArray<Maybe<RateDetail>>>;
  readonly notMergedDueToProtectedRateDetails?: Maybe<ReadonlyArray<Maybe<RateDetail>>>;
};

/** The input type used for merging rate details from abacus */
export type MergeRateDetailsInput = {
  readonly loadId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly rateDetails: ReadonlyArray<RateDetailAddInput>;
  readonly updateCreditWhenCheckFails?: InputMaybe<Scalars['Boolean']>;
  readonly userIdsToDeleteRecordsFor?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type Message = {
  readonly __typename?: 'Message';
  readonly code?: Maybe<Scalars['Float']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly text?: Maybe<Scalars['String']>;
};

export type MessagingV2 = {
  readonly __typename?: 'MessagingV2';
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly serverId?: Maybe<Scalars['ID']>;
  readonly type: EmployeeMessagingTypeEnum;
  readonly userId?: Maybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdAt: Scalars['ISO8601Date'];
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedAt: Scalars['ISO8601Date'];
};

export type MessagingV2Input = {
  readonly serverId: Scalars['ID'];
  readonly type: EmployeeMessagingTypeEnum;
  readonly userId?: InputMaybe<Scalars['ID']>;
};

export type Mileage = {
  readonly __typename?: 'Mileage';
  readonly distance: UnitOfDistance;
  readonly routeId: Scalars['String'];
};

export type MileageCalculation = {
  readonly __typename?: 'MileageCalculation';
  readonly routeId: Scalars['ID'];
  readonly distance: Scalars['Float'];
  readonly unit: Scalars['String'];
  readonly config?: Maybe<EntityConfigurationApplied>;
};

export type MileageCalculationLength = {
  readonly __typename?: 'MileageCalculationLength';
  readonly routeId: Scalars['ID'];
  readonly distance: Length;
  readonly config?: Maybe<EntityConfigurationApplied>;
};

export type MileageFilter = {
  readonly routeId?: InputMaybe<Scalars['String']>;
  readonly sourceIds: ReadonlyArray<Scalars['Int']>;
};

export type MileageFlushResponse = {
  readonly __typename?: 'MileageFlushResponse';
  readonly response?: Maybe<Scalars['String']>;
};

export type MileageForPointsFilter = {
  readonly points: ReadonlyArray<PointInput>;
  readonly routeId?: InputMaybe<Scalars['String']>;
};

export enum MileageProvider {
  Pcmiler = 'pcmiler',
  Milemaker = 'milemaker',
  Azure = 'azure'
}

export type MileageProviderOptions = PcMilerOptions | MileMakerOptions | AzureOptions;

export type MileageRequest = {
  readonly stops: ReadonlyArray<LatLongPair>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
};

export type MileageSettings = {
  readonly __typename?: 'MileageSettings';
  readonly provider: MileageProvider;
  readonly distanceSearchPreference: ReadonlyArray<DistanceSearchPreferenceOption>;
  readonly options?: Maybe<MileageProviderOptions>;
};

export type MileageSettingsInput = {
  readonly provider: MileageProvider;
  readonly distanceSearchPreference?: InputMaybe<DistanceSearchPreferenceOption>;
  readonly pcMilerOptions?: InputMaybe<PcMilerOptionsInput>;
  readonly mileMakerOptions?: InputMaybe<MileMakerOptionsInput>;
  readonly azureOptions?: InputMaybe<AzureOptionsInput>;
};

/** Custom options to use when calculating mileage via MileMaker (Rand McNally) */
export type MileMakerOptions = {
  readonly __typename?: 'MileMakerOptions';
  readonly routetype?: Maybe<MileMakerRouteType>;
  /** Specify 48 or 52 */
  readonly trucklength?: Maybe<Scalars['Int']>;
  readonly hazmat?: Maybe<Scalars['Boolean']>;
  readonly avoidtolls?: Maybe<Scalars['Boolean']>;
};

export type MileMakerOptionsInput = {
  readonly routetype?: InputMaybe<MileMakerRouteType>;
  /** Specify 48 or 52 */
  readonly trucklength?: InputMaybe<Scalars['Int']>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly avoidtolls?: InputMaybe<Scalars['Boolean']>;
};

/** h - Household Goods, p - Practical */
export enum MileMakerRouteType {
  H = 'h',
  P = 'p'
}

export type MinHeight = {
  readonly __typename?: 'MinHeight';
  readonly unit?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['Float']>;
};

export type MinLength = {
  readonly __typename?: 'MinLength';
  readonly unit?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['Float']>;
};

export type MinWidth = {
  readonly __typename?: 'MinWidth';
  readonly unit?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['Float']>;
};

export enum Mode {
  Default = 'Default',
  Truck = 'Truck'
}

export type ModeR2 = {
  readonly __typename?: 'ModeR2';
  readonly id?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
};

/** MultilineAddressResults @deprecated, please switch to cleanseAddress with AddressResults */
export type MultilineAddressResults = {
  readonly __typename?: 'MultilineAddressResults';
  readonly VerifiedAddresses?: Maybe<ReadonlyArray<FormattedAddress>>;
  readonly InvalidAddresses?: Maybe<ReadonlyArray<InvalidAddress>>;
};

export type MultiLineRequest = {
  readonly requestId: Scalars['ID'];
  readonly addressLine1: Scalars['String'];
  readonly addressLine2?: InputMaybe<Scalars['String']>;
  readonly addressLine3?: InputMaybe<Scalars['String']>;
  readonly addressLine4?: InputMaybe<Scalars['String']>;
  readonly locality: Scalars['String'];
  readonly administrativeArea: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
};

export type MultiUserInputObj = {
  readonly userList: Scalars['String'];
  readonly userMessage: Scalars['String'];
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  /**
   * Deletes multiple Truck Entries created by a 3rd Party Source
   *
   * Requires (one of) these scope(s): [truckEntry:delete].
   *
   * (Repo: truck-entry-service)
   */
  readonly bulkDeleteExternalTruckEntries?: Maybe<TruckEntryPayloadWithOnlyErrors>;
  /**
   * Creates or Updates a TruckEntry by external source ID.
   *
   * Requires (one of) these scope(s): [truckEntry:create, truckEntry:edit].
   *
   * (Repo: truck-entry-service)
   */
  readonly createOrUpdateTruckByExternalSourceId?: Maybe<TruckEntryPayloadWithErrorsAndTruckEntry>;
  /**
   * Creates a FirstTruckEntries.
   *
   * Requires (one of) these scope(s): [firstTruckEntries:create].
   *
   * (Repo: truck-entry-service)
   */
  readonly createFirstTruckEntries?: Maybe<FirstTruckEntriesPayloadWithErrorsAndTruckEntries>;
  /**
   * Updates a FirstTruckEntries.
   *
   * Requires (one of) these scope(s): [firstTruckEntries:edit].
   *
   * (Repo: truck-entry-service)
   */
  readonly updateFirstTruckEntries?: Maybe<FirstTruckEntriesPayloadWithErrorsAndTruckEntries>;
  /**
   * Creates a TruckEntry.
   *
   * Requires (one of) these scope(s): [truckEntry:create].
   *
   * (Repo: truck-entry-service)
   */
  readonly createTruckEntry?: Maybe<TruckEntryPayloadWithErrorsAndTruckEntry>;
  /**
   * Updates a TruckEntry.
   *
   * Requires (one of) these scope(s): [truckEntry:edit].
   *
   * (Repo: truck-entry-service)
   */
  readonly updateTruckEntry?: Maybe<TruckEntryPayloadWithErrorsAndTruckEntry>;
  /**
   * Deletes a TruckEntry.
   *
   * Requires (one of) these scope(s): [truckEntry:delete].
   *
   * (Repo: truck-entry-service)
   */
  readonly deleteTruckEntry?: Maybe<TruckEntryPayloadWithOnlyErrors>;
  /**
   * Creates the truck entry template and corresponding FirstTruckEntries.
   *
   * Requires (one of) these scope(s): [truckEntry:create, truckEntryTemplate:create].
   *
   * (Repo: truck-entry-service)
   */
  readonly createTruckEntryTemplateAndFirstTrucks?: Maybe<TruckEntryTemplateAndFirstTrucksPayloadWithErrorsAndTruckEntries>;
  /**
   * Updates the template and corresponding FirstTruckEntries.
   *
   * Requires (one of) these scope(s): [truckEntry:edit, truckEntryTemplate:edit].
   *
   * (Repo: truck-entry-service)
   */
  readonly updateTruckEntryTemplateAndFirstTrucks?: Maybe<TruckEntryTemplateAndFirstTrucksPayloadWithErrorsAndTruckEntries>;
  /**
   * Updates a TruckEntryTemplate.
   *
   * Requires (one of) these scope(s): [truckEntryTemplate:edit].
   *
   * (Repo: truck-entry-service)
   */
  readonly updateTruckEntryTemplate?: Maybe<TruckEntryTemplatePayloadWithErrorsAndTruckEntryTemplate>;
  /**
   * Deletes a TruckEntryTemplate.
   *
   * Requires (one of) these scope(s): [truckEntryTemplate:delete].
   *
   * (Repo: truck-entry-service)
   */
  readonly deleteTruckEntryTemplate?: Maybe<TruckEntryTemplatePayloadWithErrorsAndTruckEntryTemplate>;
  /**
   * DEPRECATED: Use createTruckEntryTemplatev2 instead
   *
   * (Repo: truck-entry-service)
   * @deprecated use createTruckEntryTemplatev2
   */
  readonly createTruckEntryTemplate?: Maybe<TruckEntryTemplatePayloadWithErrorsAndTruckEntryTemplate>;
  /**
   * Creates a truckEntryTemplate.
   *
   * Requires (one of) these scope(s): [truckEntryTemplate:create].
   *
   * (Repo: truck-entry-service)
   */
  readonly createTruckEntryTemplateV2?: Maybe<TruckEntryTemplatePayloadWithErrorsAndTruckEntryTemplate>;
  /**
   * Creates new container reservation.
   *
   * Requires (one of) these scope(s): [containerReservations:admin].
   *
   * (Repo: truck-entry-service)
   */
  readonly createContainer: ContainerPayloadWithErrorsAndContainer;
  /**
   * Update the container reservation.
   *
   * Requires (one of) these scope(s): [containerReservations:admin].
   *
   * (Repo: truck-entry-service)
   */
  readonly updateContainer: ContainerPayloadWithErrorsAndContainer;
  /**
   * Deletes the container reservation.
   *
   * Requires (one of) these scope(s): [containerReservations:admin].
   *
   * (Repo: truck-entry-service)
   */
  readonly deleteContainer: ContainerPayloadWithErrorsAndContainer;
  /**
   * Creates new gate reservation.
   *
   * Requires (one of) these scope(s): [gateReservations:admin].
   *
   * (Repo: truck-entry-service)
   */
  readonly createGateReservation: GateReservationPayloadWithErrorsAndGateReservation;
  /**
   * Updates gate reservation.
   *
   * Requires (one of) these scope(s): [gateReservations:admin].
   *
   * (Repo: truck-entry-service)
   */
  readonly updateGateReservation: GateReservationPayloadWithErrorsAndGateReservation;
  /**
   * Deletes gate reservation.
   *
   * Requires (one of) these scope(s): [gateReservations:admin].
   *
   * (Repo: truck-entry-service)
   */
  readonly deleteGateReservation: GateReservationPayloadWithErrorsAndGateReservation;
  /**
   * Creates, updates, or deletes a TruckEntry by route ID if it was created via reload intent.
   *
   * Requires (one of) these scope(s): [truckEntry:create, truckEntry:edit, truckEntry:delete].
   *
   * (Repo: truck-entry-service)
   */
  readonly createOrUpdateOrDeleteTruckByRouteId?: Maybe<TruckEntryPayloadWithErrorsAndTruckEntry>;
  /**
   * Creates a TruckEntry based on Driver Assignment information
   *
   * Requires (one of) these scope(s): [truckEntry:create].
   *
   * (Repo: truck-entry-service)
   */
  readonly createTruckEntryFromDriverAssignment?: Maybe<TruckEntryPayloadWithErrorsAndTruckEntry>;
  /**
   * Deletes a TruckEntry by route ID if it was created via reload intent. Will return null and log a warning if a TruckEntry is not found.
   *
   * Requires (one of) these scope(s): [truckEntry:delete].
   *
   * (Repo: truck-entry-service)
   */
  readonly deleteTruckByRouteId?: Maybe<TruckEntryPayloadWithOnlyErrors>;
  /**
   * Finds and Updates a TruckEntry by route ID if it was created via reload intent. Will return null and log a warning if a TruckEntry is not found.
   *
   * Requires (one of) these scope(s): [truckEntry:edit].
   *
   * (Repo: truck-entry-service)
   */
  readonly updateTruckEntryByRouteId?: Maybe<TruckEntryPayloadWithErrorsAndTruckEntry>;
  /**
   * Creates or updates a Container by route ID if it was created via Street Turns.
   *
   * Requires (one of) these scope(s): [containerReservations:admin].
   *
   * (Repo: truck-entry-service)
   */
  readonly createOrUpdateContainerByRouteId?: Maybe<ContainerPayloadWithErrorsAndContainer>;
  /**
   * Deletes a Container by route ID if it was created via Street Turns.
   *
   * Requires (one of) these scope(s): [containerReservations:admin].
   *
   * (Repo: truck-entry-service)
   */
  readonly deleteContainerByRouteId?: Maybe<ContainerPayloadWithErrorsAndContainer>;
  readonly save_asset_trailers: AssetTrailers;
  /** @deprecated deleteAsset_Trailers Mutation deprecated */
  readonly deleteAsset_Trailers: Scalars['Boolean'];
  readonly updateAsset_Trailers: Scalars['Boolean'];
  /** @deprecated CreateupdatePermanentDrivers Mutation deprecated */
  readonly CreateupdatePermanentDrivers: Scalars['Boolean'];
  /** @deprecated CreateupdatePermanentTractors Mutation deprecated */
  readonly CreateupdatePermanentTractors: Scalars['Boolean'];
  readonly updateAssetTrailersByOperations: Scalars['Boolean'];
  /** @deprecated createOrUpdateTrailer Mutation is deprecated, use createOrUpdateTrailerV3 instead */
  readonly createOrUpdateTrailer: AssetTrailers;
  /**
   * Create or Update Trailer V2
   * @deprecated createOrUpdateTrailerV2 Mutation is deprecated, use createOrUpdateTrailerV3 instead
   */
  readonly createOrUpdateTrailerV2: CreateOrUpdateTrailerPayload;
  /** Create or Update Trailer V3 */
  readonly createOrUpdateTrailerV3: CreateOrUpdateTrailerPayloadV2;
  readonly upsertAutoETA?: Maybe<TrackingEtaForStop>;
  readonly createDriverAssignment: DriverAssignment;
  readonly updateDriverAssignment: DriverAssignment;
  readonly upsertDriverAssignment: DriverAssignment;
  readonly deleteDriverAssignment: Scalars['Boolean'];
  readonly createTrackingUpdateV4: TrackingUpdateV4;
  readonly createTimelineJournalEntry: TimelineEvent;
  readonly updateTimelineJournalEntry: TimelineEvent;
  readonly createTimelineJournalEntryV2: TimelineEventV2;
  readonly updateTimelineJournalEntryV2: TimelineEventV2;
  /** Create a list of task details. */
  readonly createTaskV2: TaskV2;
  /** Delete a list of task details. */
  readonly deleteTaskV2: Scalars['Boolean'];
  /** Update a list of task details. */
  readonly updateTaskV2: TaskV2;
  /** Close a task based on id and status. */
  readonly closeTaskV2: TaskV2;
  /** mastery-tasks-api: createTaskDetails - Create a list of task details. */
  readonly createTask: Task;
  /** mastery-tasks-api: deleteTaskDetails - Delete a list of task details. */
  readonly deleteTask: Scalars['Boolean'];
  /** mastery-tasks-api: updateTaskDetails - Update a list of task details. */
  readonly updateTask: Task;
  /** mastery-tasks-api: closeTask - Close a task based on id and status. */
  readonly closeTask: Task;
  readonly ingressStopEventV2: StopEventV2;
  readonly upsertStopEventsV2: ReadonlyArray<StopEventV2>;
  readonly upsertStopEventV2: ReadonlyArray<StopEventV2>;
  readonly deleteStopEventV2: Scalars['Boolean'];
  readonly updateStopEventV2LateReason: ReadonlyArray<StopEventV2>;
  /** @deprecated Use createSpotQuote instead */
  readonly saveSpotQuote?: Maybe<SaveSpotQuotePayload>;
  readonly createSpotQuote?: Maybe<CreateSpotQuotePayload>;
  readonly convertSpotQuoteToLoad?: Maybe<ConvertSpotQuoteToLoadPayload>;
  readonly sendDirectMessage: SendMessageResponseObj;
  readonly sendMsgToMultipleUsers: SendMessageResponseObj;
  readonly sendMessageToSlack: SendMessageResponseObj;
  readonly syncSlackUsersToEmployeeRecord: SendMessageResponseObj;
  readonly appCreation: AppCreationResponseObj;
  readonly sendSlackMessage: SendMessageResponseObj;
  readonly createOrUpdateFilter: SavedFilter;
  readonly deleteFilterById: DeleteFilterResponseType;
  /** Request to book carrier by provided route number, order code, carrier code, and bypassBooking flag */
  readonly requestToBookV2: RequestToBookPayloadV2;
  /**
   * Add an existing vendor to an existing route
   * @deprecated Moved to AssignVendorToRoute
   */
  readonly addVendorToRoute: AddVendorToRoutePayload;
  /** Assign an existing vendor to an existing route */
  readonly assignVendorToRoute: AssignVendorToRoutePayload;
  /** Bounces a Route Vendor */
  readonly bounceRouteVendor: BounceRouteVendorPayload;
  /** Updates the order with tender_status or activation_status */
  readonly bulkUpdateTenderStatus: BulkUpdateTenderStatusPayload;
  /** Create Credit History entry */
  readonly createCreditHistory: CreateCreditHistoryPayload;
  /** Create customer credit provider */
  readonly createCustomerCreditProvider: CreateCustomerCreditProviderPayload;
  /** Create a customer quote */
  readonly createCustomerQuote: CreateCustomerQuotePayload;
  /** Create or update a load */
  readonly createLoad: CreateLoadPayload;
  /**
   * Accepts quantity and truck_posting. Clones truck_posting quantity times.
   * @deprecated Service has reached EOL. Use TruckEntry subgraph. Field will be removed soon
   */
  readonly createMultipleTruckPostings: CreateMultipleTruckPostingsPayload;
  /** Create or update address for carrier, facility or customer */
  readonly createOrUpdateAddress: CreateOrUpdateAddressPayload;
  /** Create/Update/Delete Carriers including insurances, reps etc...exceptions are truck postings and carrier settings */
  readonly createOrUpdateCarrier: CreateOrUpdateCarrierPayload;
  /** Create, update or delete a carrier certification */
  readonly createOrUpdateCarrierCertification: CreateOrUpdateCarrierCertificationPayload;
  /** Create/Update/Delete Carrier-Customer Relationship */
  readonly createOrUpdateCarrierCustomerRelationship: CreateOrUpdateCarrierCustomerRelationshipPayload;
  /** Create or update a single Carrier Release Method */
  readonly createOrUpdateCarrierReleaseMethodV1: CreateOrUpdateCarrierReleaseMethodV1Payload;
  /** Create or Update Carrier RMIS */
  readonly createOrUpdateCarrierRiskInformation: CreateOrUpdateCarrierRiskInformationPayload;
  /** Create/Update Carrier service */
  readonly createOrUpdateCarrierService: CreateOrUpdateCarrierServicePayload;
  /** Create/Update/Delete Carrier settings */
  readonly createOrUpdateCarrierSetting: CreateOrUpdateCarrierSettingPayload;
  /** Create or update contact for carrier, facility or customer */
  readonly createOrUpdateContact: CreateOrUpdateContactPayload;
  /** Create/Update/Delete Customers */
  readonly createOrUpdateCustomer: CreateOrUpdateCustomerPayload;
  /** Create/Update/Delete Customer-Facility Relationship */
  readonly createOrUpdateCustomerFacilityRelationship: CreateOrUpdateCustomerFacilityRelationshipPayload;
  /** Create or Update Customer groups */
  readonly createOrUpdateCustomerGroup: CreateOrUpdateCustomerGroupPayload;
  /** Create or update a order */
  readonly createOrUpdateCustomerOrder: CreateOrUpdateCustomerOrderPayload;
  /** Create or Update Customer settings */
  readonly createOrUpdateCustomerSetting: CreateOrUpdateCustomerSettingPayload;
  /** Create or update a single employee */
  readonly createOrUpdateEmployee: CreateOrUpdateEmployeePayload;
  /** Create or update a single employee. Input does not require IDs for various fields */
  readonly createOrUpdateEmployeeExternal: CreateOrUpdateEmployeeExternalPayload;
  /** Create/Update/Delete Facility */
  readonly createOrUpdateFacility: CreateOrUpdateFacilityPayload;
  /** Create/Update Facility Identifier */
  readonly createOrUpdateFacilityIdentifier: CreateOrUpdateFacilityIdentifierPayload;
  /**
   * Create or update a single truck posting
   * @deprecated Service has reached EOL. Use TruckEntry subgraph. Field will be removed soon
   */
  readonly createOrUpdateSingleTruckPosting: CreateOrUpdateSingleTruckPostingPayload;
  /** Create or update a stop */
  readonly createOrUpdateStop: CreateOrUpdateStopPayload;
  /** Destroys one address by id */
  readonly destroyAddress: DestroyAddressPayload;
  /** Destroys one carrier_certification by id */
  readonly destroyCarrierCertification: DestroyCarrierCertificationPayload;
  /** Delete a carrier release method by id */
  readonly destroyCarrierReleaseMethodV1: DestroyCarrierReleaseMethodV1Payload;
  /** Destroys one carrier service by id */
  readonly destroyCarrierService: DestroyCarrierServicePayload;
  /** Destroys one contact by id */
  readonly destroyContact: DestroyContactPayload;
  /** Destroys one Facility Identifier by id */
  readonly destroyFacilityIdentifier: DestroyFacilityIdentifierPayload;
  /**
   * Destroys one truck_posting by id
   * @deprecated Service has reached EOL. Use TruckEntry subgraph. Field will be removed soon
   */
  readonly destroyTruckPosting: DestroyTruckPostingPayload;
  /** Find/Create Facility */
  readonly findOrCreateFacility: FindOrCreateFacilityPayload;
  /** Rebook a previously bounced Route Vendor */
  readonly rebookRouteVendor: RebookRouteVendorPayload;
  /** Remove a customer quote from a quote group */
  readonly removeCustomerQuoteFromGroup: RemoveCustomerQuoteFromGroupPayload;
  /** Updates an existing bounce */
  readonly updateBounce: UpdateBouncePayload;
  /** Updates the carrier with intrastate states */
  readonly updateCarrierIntrastate: UpdateCarrierIntrastatePayload;
  /** Update Carrier standing */
  readonly updateCarrierStanding: UpdateCarrierStandingPayload;
  /** Updates a load */
  readonly updateLoad: UpdateLoadPayload;
  /** Updates a route */
  readonly updateRoute: UpdateRoutePayload;
  /** Updates the stops and their sequence associated to a route */
  readonly updateRouteStops: UpdateRouteStopsPayload;
  /** Updates a route vendor */
  readonly updateRouteVendor: UpdateRouteVendorPayload;
  /** mastery-rateQuote: ConvertQuoteToLoad - Create a load from customer quote. */
  readonly ConvertQuoteToLoad?: Maybe<LoadDetail>;
  /**
   * DO NOT USE. This service is depricated. Please use createCustomerQuoteV3.
   * @deprecated This service is depricated. Please use createCustomerQuoteV3.
   */
  readonly createCustomerQuoteV2?: Maybe<CustomerQuotePaginatedV2>;
  /**
   * mastery-rateQuote: createCustomerQuote - Create a customer quote.
   * @deprecated DO NOT USE. This service is depricated. Please use createCustomerQuoteV4.
   */
  readonly createCustomerQuoteV3?: Maybe<CustomerQuoteV3>;
  /**
   * mastery-rateQuote: createCustomerQuote - Create a customer quote.
   * @deprecated This service is depricated. Please use createCustomerQuoteV5.
   */
  readonly createCustomerQuoteV4?: Maybe<CustomerQuoteV3>;
  /**
   * mastery-rateQuote: createCustomerQuote - Create a customer quote.
   * @deprecated This service is depricated. Please use createCustomerQuoteV6.
   */
  readonly createCustomerQuoteV5?: Maybe<CustomerQuoteV4>;
  /** mastery-rateQuote: createCustomerQuoteV6 - Create a customer quote. */
  readonly createCustomerQuoteV6?: Maybe<CustomerQuoteV4>;
  /**
   * DO NOT USE. This service is depricated. Please use removeCustomerQuoteFromGroupV3.
   * @deprecated This service is depricated. Please use removeCustomerQuoteFromGroupV3.
   */
  readonly removeCustomerQuoteFromGroupV2?: Maybe<RemoveCustomerQuoteFromGroupPayloadV2>;
  /**
   * mastery-rateQuote: ConvertQuoteToLoad - Removes quote from quote group .
   * @deprecated DO NOT USE. This service is depricated. Please use removeCustomerQuoteFromGroupV4.
   */
  readonly removeCustomerQuoteFromGroupV3?: Maybe<RemoveCustomerQuoteFromGroupPayloadV3>;
  /**
   * mastery-rateQuote: Removes quote from quote group .
   * @deprecated DO NOT USE. This service is depricated. Please use removeCustomerQuoteFromGroupV5.
   */
  readonly removeCustomerQuoteFromGroupV4?: Maybe<RemoveCustomerQuoteFromGroupPayloadV4>;
  /** mastery-rateQuote: Removes quote from quote group . */
  readonly removeCustomerQuoteFromGroupV5?: Maybe<RemoveCustomerQuoteFromGroupPayloadV5>;
  /** mastery-rateQuote: update quote . */
  readonly updateCustomerQuote?: Maybe<CustomerQuoteV4>;
  readonly createRateConfirmation: CreateRateConfirmationPayload;
  readonly startAutoRateConfirmation: StartAutoRateConfirmationPayload;
  readonly resetProjectApiDataDictionaryCache?: Maybe<ProjectBooleanPayload>;
  /** Create or Update Project */
  readonly createOrUpdateProject: CreateOrUpdateProjectPayload;
  /** Queries the load API and creates/updates a route in ES */
  readonly importLoadV2?: Maybe<Scalars['Boolean']>;
  /** Queries the Truck Entry API and creates/updates a truck in ES */
  readonly importTruckEntryV2?: Maybe<Scalars['Boolean']>;
  /** Updates carrier data in all ES trucks owned by that carrier */
  readonly updateTrucksForCarrierV2?: Maybe<Scalars['Boolean']>;
  /** Delete an Available Truck and tombstone related matches */
  readonly deleteAvailableTruckV2?: Maybe<Scalars['Boolean']>;
  /** Queries the Capacity API and creates/updates a gate reservation in ES */
  readonly importGateReservation?: Maybe<Scalars['Boolean']>;
  /** Delete an Available Gate Reservation and tombstone related matches */
  readonly deleteAvailableGateReservation?: Maybe<Scalars['Boolean']>;
  /** Queries the Capacity API and creates/updates a container in ES */
  readonly importContainer?: Maybe<Scalars['Boolean']>;
  /** Delete an Available Container and tombstone related matches */
  readonly deleteAvailableContainer?: Maybe<Scalars['Boolean']>;
  /** Queries the load API and creates/updates a route in ES */
  readonly importLoad?: Maybe<Scalars['Boolean']>;
  /** Creates/Updates/Deletes an Available Route */
  readonly processAvailableRoute?: Maybe<AvailableRoute>;
  /** Creates/Updates an External Available Route */
  readonly processExternalAvailableRoute?: Maybe<AvailableRoute>;
  /** Batch deactivate Available Routes */
  readonly deactivateAvailableRoutes?: Maybe<Scalars['Boolean']>;
  /** Batch delete Available Routes matching filter criteria */
  readonly deleteAvailableRoutesByFilter?: Maybe<DeleteAvailableRoutesByFilterResult>;
  /** Create/Update an Available Truck */
  readonly saveAvailableTruck?: Maybe<Scalars['Boolean']>;
  /** Re-index documents in procurement domain  */
  readonly updateProcurementDetails?: Maybe<Scalars['Boolean']>;
  /**
   * Delete an Available Truck and tombstone related matches
   * @deprecated Use `deleteAvailableTruckV2` instead: capacity updates through procurement-api are deprecated
   */
  readonly deleteAvailableTruck?: Maybe<Scalars['Boolean']>;
  /**
   * Queries the Truck Entry API and creates/updates a truck in ES
   * @deprecated Use `importTruckEntryV2` instead: capacity updates through procurement-api are deprecated
   */
  readonly importTruckEntry?: Maybe<Scalars['Boolean']>;
  /**
   * Updates carrier data in all ES trucks owned by that carrier
   * @deprecated Use `updateTrucksForCarrierV2` instead: capacity updates through procurement-api are deprecated
   */
  readonly updateTrucksForCarrier?: Maybe<Scalars['Boolean']>;
  /** Generates or updates matches for the given route. Called from routes consumer. */
  readonly generateMatchesForRoute?: Maybe<Scalars['Boolean']>;
  /**
   * Generates or updates matches for the given truck.
   *         Called from trucks consumer.
   */
  readonly generateMatchesForTruck?: Maybe<Scalars['Boolean']>;
  /** Deletes matches based on filter criteria. */
  readonly deleteMatchesByFilter: Scalars['Float'];
  /**
   * Generates or updates matches for the given gate reservation.
   *         Called from gate reservations consumer.
   */
  readonly generateMatchesForGateReservation?: Maybe<Scalars['Boolean']>;
  /**
   * Generates or updates matches for the given container.
   *         Called from container consumer.
   */
  readonly generateMatchesForContainer?: Maybe<Scalars['Boolean']>;
  /** mastery-pricing: updateMaxCost - update max cost. */
  readonly updateMaxCost?: Maybe<UpdateMaxCostResponse>;
  readonly saveAssetPower: AssetPowerOutput;
  readonly deleteAssetPower: Scalars['Boolean'];
  readonly updateAssetPower: Scalars['Boolean'];
  readonly createUpdatePowerPermanentDrivers: Scalars['Boolean'];
  readonly createUpdatePowerPermanentTrailers: Scalars['Boolean'];
  readonly updateAssetPowersByOperations: Scalars['Boolean'];
  /**
   * Create or Update Power
   * @deprecated createOrUpdatePower Mutation is deprecated, use createOrUpdatePowerV2 instead
   */
  readonly createOrUpdatePower: CreateOrUpdatePowerPayload;
  /** Create or Update Power V2 */
  readonly createOrUpdatePowerV2: CreateOrUpdatePowerPayloadV2;
  readonly createPosting: CreatePostingPayload;
  readonly createPostings: CreatePostingsPayload;
  readonly updatePosting: UpdatePostingPayload;
  readonly removePosting: RemovePostingPayload;
  readonly removePostings: RemovePostingsPayload;
  readonly removePostingsByRouteIds: RemovePostingsPayload;
  readonly createOffer: Offer;
  readonly updateOffer: Offer;
  readonly deleteOffer: Scalars['Boolean'];
  readonly acceptOffer: OfferResponse;
  readonly counterOffer: OfferResponse;
  readonly declineOffer: OfferResponse;
  readonly updateMultipleOfferResponse: UpdateMultipleOfferResponsePayload;
  readonly flushMileageCache?: Maybe<MileageFlushResponse>;
  readonly addBid?: Maybe<BidPayload>;
  readonly cancelBid?: Maybe<BidPayload>;
  readonly updateBid?: Maybe<BidPayload>;
  readonly acceptBid?: Maybe<BidPayload>;
  readonly rejectBid?: Maybe<BidPayload>;
  readonly updateRound?: Maybe<RoundPayload>;
  readonly addBidQuote?: Maybe<BidQuotePayload>;
  readonly insertCustomerMarkup: Scalars['String'];
  readonly updateCustomerMarkup: Scalars['String'];
  readonly addressStatusUpdate?: Maybe<ReadonlyArray<Maybe<AddressCleanseStatus>>>;
  readonly flushLocationServicesCache?: Maybe<CacheFlushResponse>;
  readonly createCustomerOrder: CustomerOrderPayload;
  readonly updateCustomerOrder: CustomerOrderPayload;
  readonly cancelCustomerOrderV2: CustomerOrderPayload;
  readonly createOrUpdateCustomerOrderV2: CustomerOrderPayload;
  readonly createOrderStop: OrderStopPayload;
  readonly deleteOrderStop: BooleanPayload;
  readonly changeCustomerOrder: CustomerOrderPayload;
  readonly createLoadV2: LoadPayload;
  readonly updateLoadV2: LoadPayload;
  readonly planLoad: LoadPayload;
  readonly resequenceStops: LoadRoutePayload;
  readonly resequenceLoadStops: LoadStopPayload;
  readonly createOrUpdateStopV2: RouteStopPayload;
  readonly createOrUpdateCityStateStopV2: RouteStopPayloadV2;
  readonly deleteStop: BooleanPayload;
  /** @deprecated This mutation will be removed in a future release, please use associateLoadOrderStops instead. */
  readonly associateOrderStops: AssociateOrderStopsPayload;
  readonly associateLoadOrderStops: AssociateOrderStopsPayload;
  readonly assignFacilityToCityStateStop: RouteStopPayload;
  readonly changeFacilityOnStop: ChangeFacilityOnStopPayload;
  readonly updateCityStateOnStop: UpdateCityStateOnStopPayload;
  readonly planLoadV2: LoadPayload;
  readonly cloneLoad: LoadPayload;
  readonly createLoadRoute: LoadRoutePayload;
  readonly updateLoadRoute: LoadRoutePayload;
  readonly deleteRoute: BooleanPayload;
  readonly createRouteStop: RouteStopPayload;
  readonly deleteRouteStop: BooleanPayload;
  readonly resetLoadApiDataDictionaryCache?: Maybe<BooleanPayload>;
  /** Create linked route */
  readonly createLinkedRouteV2: LinkedRouteV2;
  /** Delete linked route */
  readonly deleteLinkedRouteV2?: Maybe<LinkedRouteV2>;
  /**
   * mastery-lane: createLane - Create a lane.
   * @deprecated This service is deprecated. Please use createLaneV4.
   */
  readonly createLane?: Maybe<LaneOutput>;
  /**
   * mastery-lane: createLaneV2 - Create a lane.
   * @deprecated This service is deprecated. Please use createLaneV4.
   */
  readonly createLaneV2?: Maybe<LaneV2>;
  /**
   * mastery-lane: createLaneV3 - Create a lane.
   * @deprecated This service is deprecated. Please use createLaneV4.
   */
  readonly createLaneV3?: Maybe<LaneV2>;
  /** mastery-lane: createLaneV4 - Create a lane. */
  readonly createLaneV4?: Maybe<LaneV2>;
  /** mastery-lane: deactivateCustomerLane - Deactivate a lane by customer id. */
  readonly deactivateCustomerLane?: Maybe<DeactivateCustomerLaneResponse>;
  readonly createIncidentV2: IncidentV2;
  readonly updateIncidentV2: IncidentV2;
  readonly updateServiceProfile: ServiceProfile;
  readonly createServiceProfile: ServiceProfile;
  readonly deleteServiceProfile: Scalars['Boolean'];
  readonly updateServiceProfileV2: ServiceProfileObject;
  readonly createServiceProfileV2: ServiceProfileObject;
  /** Rule Sets will be updated by matching the name with existing rule sets. */
  readonly updateRuleSets?: Maybe<UpdateRuleSetsResult>;
  readonly updateTemplates?: Maybe<UpdateTemplatesResult>;
  readonly updateHaykEvent?: Maybe<UpdateHaykEventResult>;
  readonly createHaykEvent?: Maybe<CreateHaykEventResult>;
  readonly createHaykRedirect?: Maybe<HaykRedirectResult>;
  readonly updateHaykRedirect?: Maybe<HaykRedirectResult>;
  readonly deleteHaykRedirect?: Maybe<HaykRedirectResult>;
  readonly createHaykNotifications?: Maybe<HaykNotificationResponses>;
  readonly createPlatformMessage?: Maybe<HaykNotificationResponses>;
  readonly createHaykAuditHistory?: Maybe<AuditHistory>;
  readonly addPreferredName?: Maybe<GeographyServiceRecord>;
  readonly flushGeographiesCache?: Maybe<CacheFlushResponse>;
  readonly updateGeographyRecord?: Maybe<GeographyServiceRecord>;
  readonly insertFuelProgramCustomerConfiguration?: Maybe<FuelProgramCustomerConfigurationModel>;
  readonly updateFuelProgramCustomerConfiguration?: Maybe<FuelProgramCustomerConfigurationModel>;
  readonly deleteFuelProgramCustomerConfiguration?: Maybe<FuelProgramCustomerConfigurationModel>;
  readonly insertHistoricalFuelIndexPrice?: Maybe<HistoricalFuelIndexPriceModel>;
  readonly updateHistoricalFuelIndexPrice?: Maybe<HistoricalFuelIndexPriceModel>;
  readonly updateHistoricalFuelIndexAveragePriceOverride?: Maybe<HistoricalFuelIndexPriceModel>;
  readonly deleteHistoricalFuelIndexPrice?: Maybe<HistoricalFuelIndexPriceModel>;
  readonly insertCustomerFuelRateHeader?: Maybe<CustomerFuelRateHeaderModel>;
  readonly insertCustomerFuelRange?: Maybe<CustomerFuelRangeModel>;
  readonly updateCustomerFuelRange?: Maybe<CustomerFuelRangeModel>;
  readonly deleteCustomerFuelRange?: Maybe<CustomerFuelRangeModel>;
  readonly bulkInsertCustomerFuelRange?: Maybe<Scalars['Boolean']>;
  readonly resetSublocationApiDataDictionaryCache?: Maybe<ResetSublocationApiDdtCachePayload>;
  /** Create or Update Facility Sublocation Activity */
  readonly createOrUpdateFacilitySublocationActivity: CreateOrUpdateFacilitySublocationActivityPayload;
  /** Destroy Facility Sublocation Activity */
  readonly destroyFacilitySublocationActivity: DestroyFacilitySublocationActivityPayload;
  /** Create or Update Facility Sublocation Accommodation */
  readonly createOrUpdateFacilitySublocationAccommodation: CreateOrUpdateFacilitySublocationAccommodationPayload;
  /** Destroy Facility Sublocation Accommodation */
  readonly destroyFacilitySublocationAccommodation: DestroyFacilitySublocationAccommodationPayload;
  /** Create or Update Facility Sublocation */
  readonly createOrUpdateFacilitySublocation: CreateOrUpdateFacilitySublocationPayload;
  /** Destroy Facility Sublocation */
  readonly destroyFacilitySublocation: DestroyFacilitySublocationPayload;
  /** Create or Update Facility Sublocation Gate */
  readonly createOrUpdateFacilitySublocationGate: CreateOrUpdateFacilitySublocationGatePayload;
  /** Destroy Facility Sublocation Gate */
  readonly destroyFacilitySublocationGate: DestroyFacilitySublocationGatePayload;
  readonly createOrUpdateFacilityV2: CreateOrUpdateFacilityPayloadV2;
  readonly createOrUpdateFacilitySchedule: CreateOrUpdateFacilitySchedulePayload;
  readonly findOrCreateFacilityV2: FindOrCreateFacilityPayloadV2;
  readonly createFacilityToImport: CreateFacilityToImportPayload;
  readonly createOrUpdateFacilityIdentifierV2: CreateOrUpdateFacilityIdentifierPayloadV2;
  readonly destroyFacilityIdentifierV2: DestroyFacilityIdentifierPayloadV2;
  readonly destroyFacilityContact: DestroyFacilityContactPayload;
  readonly createOrUpdateFacilityContact: CreateOrUpdateFacilityContactPayload;
  readonly createOrUpdateFacilityDirection: CreateOrUpdateFacilityDirectionPayload;
  readonly createOrUpdateFacilityHoliday: CreateOrUpdateFacilityHolidayPayload;
  readonly createOrUpdateFacilityAddress: CreateOrUpdateFacilityAddressPayload;
  readonly destroyFacilityAddress: DestroyFacilityAddressPayload;
  readonly destroyFacilitySchedule: DestroyFacilitySchedulePayload;
  readonly destroyFacilityDirection: DestroyFacilityDirectionPayload;
  readonly destroyFacilityHoliday: DestroyFacilityHolidayPayload;
  readonly createOrUpdateCustomerFacilityRelationshipV2: CreateOrUpdateCustomerFacilityRelationshipPayloadV2;
  readonly createTenantExceptionRecord: TenantExceptionRecord;
  readonly updateTenantExceptionRecord: TenantExceptionRecord;
  readonly updateMileageTenantConfiguration?: Maybe<TenantConfigurationResult>;
  readonly updateMileageDefaultConfiguration?: Maybe<DefaultConfigurationResult>;
  readonly createMileageEntityConfiguration?: Maybe<EntityConfigurationResult>;
  readonly updateMileageEntityConfiguration?: Maybe<EntityConfigurationResult>;
  readonly deleteMileageEntityConfiguration?: Maybe<Scalars['Boolean']>;
  readonly createOrUpdateEmployeeV2: CreateOrUpdateEmployeePayloadV2;
  readonly createOrUpdateEmployeeExternalV2: CreateOrUpdateEmployeeExternalPayloadV2;
  /** Do not use it, ONLY to be used by development team for deleting cache if required. */
  readonly deleteEmployeeCache?: Maybe<Scalars['Boolean']>;
  /** Associates the corresponding mastermind load record id with a shipmentId unique per customerCode */
  readonly associateShipmentIdWithLoadId?: Maybe<ReadonlyArray<Maybe<EdiTransaction>>>;
  /** Saves Edi Outbox Message to Edi Database */
  readonly saveEdiOutboxMessage?: Maybe<EdiOutbox>;
  /** Saves EDI transaction to EDI Database */
  readonly saveEDITransactionAsync?: Maybe<EdiTransaction>;
  /** Update Edi Outbox Message */
  readonly updateEdiOutboxMessage?: Maybe<EdiOutbox>;
  readonly createDriver: AssetDriver;
  readonly deleteDriver: Scalars['Boolean'];
  readonly updateDriver: Scalars['Boolean'];
  readonly updateDriverPower: Scalars['Boolean'];
  readonly updateDriverTrailer: Scalars['Boolean'];
  readonly updateDriverOperations: Scalars['Boolean'];
  readonly updateDriverPreferences: Scalars['Boolean'];
  readonly deleteDriverFacilityPreferences: Scalars['Boolean'];
  readonly deleteDriverCustomerPreferences: Scalars['Boolean'];
  readonly saveDriverFacilityPreferences: DriverFacilityPreferences;
  readonly saveDriverCustomerPreferences: DriverCustomerPreferences;
  readonly updateDriverFacilityPreferences: Scalars['Boolean'];
  readonly updateDriverCustomerPreferences: Scalars['Boolean'];
  readonly saveDriverPreferredLanes: DriverPreferredLanes;
  readonly updateDriverPreferredLanes: Scalars['Boolean'];
  readonly saveDriverPreferredRoutes: DriverPreferredRoutes;
  readonly updateDriverPreferredRoutesById: Scalars['Boolean'];
  readonly deleteDriverPreferredLanes: Scalars['Boolean'];
  readonly deleteDriverPreferredRoutes: Scalars['Boolean'];
  /** Create or Update Driver  */
  readonly createOrUpdateDriver: CreateOrUpdateDriverPayload;
  /** Create or Update Driver V2 */
  readonly createOrUpdateDriverV2: CreateOrUpdateDriverPayloadV2;
  readonly deleteDriverWeeklyTarget: Scalars['Boolean'];
  /** Create or Update Driver Weekly Target */
  readonly createOrUpdateDriverWeeklyTarget: CreateOrUpdateDriverWeeklyTargetPayload;
  /** Create or Update Driver Training */
  readonly createOrUpdateDriverTraining: CreateOrUpdateDriverTraningPayload;
  readonly deleteTraining: Scalars['Boolean'];
  readonly updateCRMDocumentName: UpdateCrmNamePayload;
  readonly deleteCustomerDocument: DeleteCustomerDocumentPayload;
  readonly updateLoadDocumentDetails: UpdateLoadDocDetailsPayload;
  readonly deleteCarrierDocument: DeleteCarrierDocumentPayload;
  readonly deleteFacilityDocument: DeleteFacilityDocumentPayload;
  readonly completeUpload?: Maybe<CompleteUploadPayload>;
  readonly completeUploadV2?: Maybe<CompleteUploadPayload>;
  /** updateCustomerDriverLocationFrequencyConfiguration: create or update driver location frequency configuration, per customer in Mastermind. */
  readonly updateCustomerDriverLocationFrequencyConfiguration?: Maybe<DriverLocationFrequency>;
  readonly createOrUpdateCustomerGroupV2: CreateOrUpdateCustomerGroupPayloadV2;
  readonly createOrUpdateCustomerAddress: CreateOrUpdateCustomerAddressPayload;
  readonly createOrUpdateCustomerV2: CreateOrUpdateCustomerPayloadV2;
  readonly createOrUpdateCustomerSettingV2?: Maybe<CreateOrUpdateCustomerSettingPayloadV2>;
  readonly createCreditHistoryV2: CreateCreditHistoryPayloadV2;
  readonly createOrUpdateCustomerContact: CreateOrUpdateCustomerContactPayload;
  readonly createCustomerCreditProviderV2?: Maybe<CreateCustomerCreditProviderPayloadV2>;
  readonly createOrUpdateCustomerRep: CreateOrUpdateCustomerRepPayload;
  readonly createOrUpdateCustomerIdentifier: CreateOrUpdateCustomerIdentifierPayload;
  readonly destroyCustomerAddress: DestroyCustomerAddressPayload;
  readonly destroyCustomerContact: DestroyCustomerContactPayload;
  readonly destroyCustomerRep: DestroyCustomerRepPayload;
  readonly destroyCustomerIdentifier: DestroyCustomerIdentifierPayload;
  readonly createCustomerStructure: CustomerStructure;
  readonly updateCustomerStructure: CustomerStructure;
  readonly deleteCustomerStructure: Scalars['Boolean'];
  readonly createNoteDefault: NoteDefault;
  readonly deleteNoteDefault: Scalars['Boolean'];
  readonly updateNoteDefault: NoteDefault;
  readonly createCustomerRegion: CustomerRegion;
  readonly updateCustomerRegion: CustomerRegion;
  readonly deleteCustomerRegion: Scalars['Boolean'];
  readonly createCustomerFacility: CustomerFacility;
  readonly updateCustomerFacility: CustomerFacility;
  readonly deleteCustomerFacility: Scalars['Boolean'];
  readonly createCustomerCommodity: CustomerCommodity;
  readonly updateCustomerCommodity: CustomerCommodity;
  readonly deleteCustomerCommodity: Scalars['Boolean'];
  readonly createLoadDefaults: LoadDefaults;
  readonly createLoadDefaultsV2: LoadDefaults;
  readonly updateLoadDefaults: LoadDefaults;
  readonly updateLoadDefaultsV2: LoadDefaults;
  readonly deleteLoadDefaults: Scalars['Boolean'];
  readonly createCustomerCustomerRelationship: CustomerCustomerRelationship;
  readonly updateCustomerCustomerRelationship: CustomerCustomerRelationship;
  readonly deleteCustomerCustomerRelationship: Scalars['Boolean'];
  readonly createCarrierFacility: CarrierFacility;
  readonly updateCarrierFacility: CarrierFacility;
  readonly deleteCarrierFacility: Scalars['Boolean'];
  readonly createFacilityNoteDefaults: FacilityNoteDefaults;
  readonly updateFacilityNoteDefaults: FacilityNoteDefaults;
  readonly deleteFacilityNoteDefaults: Scalars['Boolean'];
  readonly createFacilityLoadDefaults: FacilityLoadDefaults;
  readonly updateFacilityLoadDefaults: FacilityLoadDefaults;
  readonly deleteFacilityLoadDefaults: Scalars['Boolean'];
  readonly createOrUpdateCustomerCommitment?: Maybe<CustomerCommitment>;
  readonly createOrUpdateCustomerCommitmentsSettings?: Maybe<CustomerCommitmentsSettings>;
  readonly crmOpportunitySave: CrmOpportunityOutput;
  readonly crmOpportunitySaveV2: CrmOpportunityOutputV2;
  readonly crmOpportunityAppendV2: Scalars['Boolean'];
  readonly crmInteractionDelete: Scalars['Boolean'];
  readonly crmInteractionSave: CrmInteractionOutput;
  readonly crmInteractionObjectiveSave: CrmInteractionObjectiveOutput;
  readonly crmInteractionRescheduleSave: CrmInteractionRescheduleOutput;
  readonly crmInteractionAppend: Scalars['Boolean'];
  readonly crmInteractionAssignRepGroupReassign: Scalars['Boolean'];
  readonly crmInteractionDocumentSave: CrmInteractionDocumentOutput;
  readonly crmInteractionDocumentDelete: Scalars['Boolean'];
  readonly crmInteractionDocumentRemove: Scalars['Boolean'];
  readonly crmCompanyDescriptionSave: CrmCompanyDescriptionOutput;
  readonly crmCompanyDescriptionEdit: Scalars['Boolean'];
  readonly crmDetailedDescriptionSave: CrmDetailedDescriptionOutput;
  readonly crmDetailedDescriptionEdit: Scalars['Boolean'];
  readonly crmDetailedDescriptionDelete: Scalars['Boolean'];
  readonly crmFirmographicsSave: CrmFirmographicsOutput;
  readonly crmFirmographicsEdit: Scalars['Boolean'];
  readonly saveContact: CrmContactOutput;
  readonly updateContactAssociatedEntity: ReadonlyArray<CrmContactAssociatedEntityOutput>;
  readonly updateContactNameInformationData: ReadonlyArray<CrmContactOutput>;
  readonly updateContactGeneralData: Scalars['Boolean'];
  readonly updateContactBusinessInformationData: Scalars['Boolean'];
  readonly saveAssociatedReps: CrmContactAssociatedRepOutput;
  readonly inactivateContactAssociatedRepById: Scalars['Boolean'];
  readonly inactivateContactInformationById: Scalars['Boolean'];
  readonly modifyAssociatedRepById: Scalars['Boolean'];
  readonly saveContactInformation: CrmContactInformationOutput;
  readonly modifyContactInformation: ReadonlyArray<CrmContactInformationOutput>;
  readonly saveContactWorkHours: ReadonlyArray<CrmContactWorkHoursOutput>;
  readonly modifyContactWorkHour: Scalars['Boolean'];
  readonly modifyAssociatedEntityByIdV2: Scalars['Boolean'];
  readonly deleteAssociatedEntityByIdV2: Scalars['Boolean'];
  readonly saveAssociatedEntityV2: CrmContactAssociatedEntityOutput;
  readonly saveContactv2: CrmContactOutputV2;
  readonly modifyContactAssociatedEntityv2: ReadonlyArray<CrmContactAssociatedEntityOutputV2>;
  readonly modifyContactNameInformationDatav2: ReadonlyArray<CrmContactOutputV2>;
  readonly modifyContactGeneralDatav2: Scalars['Boolean'];
  readonly modifyContactBusinessInformationDatav2: Scalars['Boolean'];
  readonly saveAssociatedRepsv2: CrmContactAssociatedRepOutputV2;
  readonly inactivateContactAssociatedRepByIdv2: Scalars['Boolean'];
  readonly inactivateContactInformationByIdv2: Scalars['Boolean'];
  readonly modifyAssociatedRepByIdv2: Scalars['Boolean'];
  readonly saveContactInformationv2: CrmContactInformationOutputV2;
  readonly modifyContactInformationv2: ReadonlyArray<CrmContactInformationOutputV2>;
  readonly saveContactWorkHoursv2: ReadonlyArray<CrmContactWorkHoursOutputv2>;
  readonly modifyContactWorkHourv2: Scalars['Boolean'];
  readonly saveAssociatedEntityv2: CrmContactAssociatedEntityOutputV2;
  readonly deleteAssociatedEntityByIdv2: Scalars['Boolean'];
  readonly modifyAssociatedEntityByIdv2: Scalars['Boolean'];
  readonly saveResponsibilityv2: CrmContactResponsibilityOutputV2;
  readonly deleteResponsibilityByIdv2: CrmContactResponsibilityOutputV2;
  readonly modifyResponsibilityByIdv2: CrmContactResponsibilityOutputV2;
  readonly saveContactSliderv2: CrmContactSliderOutputV2;
  readonly modifyContactSliderv2: CrmContactSliderOutputV2;
  readonly saveContactInterestHobbyv2: ReadonlyArray<CrmContactInterestHobbyOutputV2>;
  readonly modifyContactInterestHobbyv2: ReadonlyArray<CrmContactInterestHobbyOutputV2>;
  /** mastery-crm: createCarrierCrmNotes - Creates a Carrier Crm Notes record. */
  readonly createCarrierCrmNotes?: Maybe<CarrierCrmNotes>;
  /** mastery-crm: createCarrierCrmOpportunity - Creates a Carrier Crm Opportunity record. */
  readonly createCarrierCrmOpportunity?: Maybe<CarrierCrmOpportunity>;
  /** mastery-crm: createCarrierInteraction - Creates a carrier interaction record. */
  readonly createCarrierInteraction?: Maybe<CarrierInteraction>;
  /** mastery-crm: createCustomerNotes - creates a customer crm notes record. */
  readonly createCustomerCrmNotes?: Maybe<CustomerCrmNote>;
  /** mastery-crm: createCustomerCrmOpportunity - Creates a Customer Crm Opportunity record. */
  readonly createCustomerCrmOpportunity?: Maybe<CustomerCrmOpportunity>;
  /** mastery-crm: createCustomerInteraction - Creates a customer interaction record. */
  readonly createCustomerInteraction?: Maybe<CustomerInteraction>;
  /** mastery-crm: createCarrierDetail - Creates a carrier Detail record. */
  readonly createOrUpdateCRMCarrierDetail?: Maybe<CrmCarrierDetail>;
  /** mastery-crm: createCustomerDetail - Creates a customer Detail record. */
  readonly createOrUpdateCRMCustomerDetail?: Maybe<CrmCustomerDetail>;
  /** mastery-crm: deleteCarrierCrmDocuments - delete a carrier crm document record. */
  readonly deleteCarrierCrmDocuments: Scalars['Boolean'];
  /** mastery-crm: deleteCustomerCrmDocuments - delete a customer crm document record. */
  readonly deleteCustomerCrmDocuments: Scalars['Boolean'];
  /** mastery-crm: createCarrierDocuments - updates a carrier crm document record. */
  readonly updateCarrierCrmDocuments?: Maybe<CarrierCrmDocument>;
  /** mastery-crm: updateCarrierCrmNotes - Update a Carrier Crm Notes record. */
  readonly updateCarrierCrmNotes?: Maybe<CarrierCrmNotes>;
  /** mastery-crm: updateCarrierCrmOpportunity - Update a Carrier Crm Opportunity record. */
  readonly updateCarrierCrmOpportunity?: Maybe<CarrierCrmOpportunity>;
  /** mastery-crm: updateCustomerNotes - updates a customer crm document record. */
  readonly updateCustomerCrmDocuments?: Maybe<CustomerCrmDocument>;
  /** mastery-crm: updateCustomerNotes - updates a customer crm notes record. */
  readonly updateCustomerCrmNotes?: Maybe<CustomerCrmNote>;
  /** mastery-crm: updateCustomerCrmOpportunity - Update a Customer Crm Opportunity record. */
  readonly updateCustomerCrmOpportunity?: Maybe<CustomerCrmOpportunity>;
  /** mastery-crm: updateKvtValuesToCrmTable - updates KVT values to crm table. */
  readonly updateKvtValuesToCrmTable?: Maybe<UpdateKvtValuesResponse>;
  readonly reserveCreditNoTotalRate: CreditResponse;
  readonly reserveCreditWithTotalRate: CreditResponse;
  /** Deprecated: please use the reserveCreditWithDetails mutation instead */
  readonly reserveCreditWithRateDetails: CreditResponse;
  readonly reserveCreditWithDetails: CreditResponse;
  readonly reserveCreditWithAutomation: CreditResponse;
  readonly releaseCredit: CreditResponse;
  readonly changeCreditCustomerOnOrder?: Maybe<ChangeCreditCustomerOnOrderPayload>;
  readonly createOrUpdateCostQuote: CarrierQuoteResponse;
  readonly updateCostQuote: CarrierQuoteResponse;
  /** Sends a kafka message to the core-eng test topic with the wait as the input */
  readonly messageWaitForSeconds?: Maybe<Scalars['Int']>;
  /** mastery-commissions: createCommissionsGlobalConfiguration - Creates a new global configuration type. */
  readonly createCommissionsGlobalConfiguration?: Maybe<CommissionsGlobalConfiguration>;
  /** mastery-commissions: updateCommissionsGlobalConfiguration - Updates an existing global configuration type. */
  readonly updateCommissionsGlobalConfiguration?: Maybe<CommissionsGlobalConfiguration>;
  /** mastery-commissions: deleteCommissionsGlobalConfiguration - Marks a Global Configuration as Inactive. */
  readonly deleteCommissionsGlobalConfiguration?: Maybe<CommissionsGlobalConfiguration>;
  /** mastery-commissions: createCustomerOverheadConfiguration - Creates a Customer Overhead Configuration. */
  readonly createCommissionsCustomerOverheadConfiguration?: Maybe<CommissionsCustomerOverheadConfiguration>;
  /** mastery-commissions: updateCustomerOverheadConfiguration - Updates a Customer Overhead Configuration. */
  readonly updateCommissionsCustomerOverheadConfiguration?: Maybe<CommissionsCustomerOverheadConfiguration>;
  /** mastery-commissions: deleteCustomerOverheadConfiguration - Marks a Customer Overhead Configuration as Inactive. */
  readonly deleteCommissionsCustomerOverheadConfiguration?: Maybe<CommissionsCustomerOverheadConfiguration>;
  /** mastery-commissions: calculateCommissionsLoadAllocatedMargins - Calculates the Load Allocated Margins for the load id passed in. */
  readonly calculateCommissionsLoadAllocatedMargins?: Maybe<LoadAllocatedMargins>;
  /**
   * mastery-commissions: createEmployeeCommissionConfigurationPlan - Creates a Employee Commission Configuration.
   * @deprecated Use createOrUpdateEmployeeCommissionConfigurationPlan
   */
  readonly createEmployeeCommissionPlan?: Maybe<EmployeeCommissionPlan>;
  /**
   * mastery-commissions: createOrUpdateEmployeeCommissionConfigurationPlan - Updates a Employee Commission Configuration.
   * @deprecated Use createOrUpdateEmployeeCommissionConfigurationPlan
   */
  readonly createOrUpdateEmployeeCommissionConfigurationPlan?: Maybe<EmployeeCommissionPlan>;
  /** mastery-commissions: createEmployeeCommissionPlan - Creates a Employee Commission Plan. */
  readonly createEmployeeCommissionPlanConfiguration?: Maybe<EmployeeCommissionPlanConfiguration>;
  /** mastery-commissions: updateEmployeeCommissionPlan - Updates a Employee Commission Plan. */
  readonly updateEmployeeCommissionPlanConfiguration?: Maybe<EmployeeCommissionPlanConfiguration>;
  /** mastery-commissions: deleteEmployeeCommissionPlan - Marks an Employee Commission Plan as Inactive. */
  readonly deleteEmployeeCommissionPlanConfiguration?: Maybe<EmployeeCommissionPlanConfiguration>;
  /** mastery-commissions: updateEmployeeCommissionPlanHeader - Updates a Employee Commission Plan Header. */
  readonly updateEmployeeCommissionPlanConfigurationHeader?: Maybe<EmployeeCommissionPlanConfiguration>;
  /** mastery-commissions: createLoadCommissionsOverrideConfiguration - Creates a Load Commissions Override Configuration. */
  readonly createLoadCommissionsOverrideConfiguration?: Maybe<LoadCommissionsOverrideConfiguration>;
  /** mastery-commissions: updateLoadCommissionsOverrideConfiguration - Updates a Load Commissions Override Configuration. */
  readonly updateLoadCommissionsOverrideConfiguration?: Maybe<LoadCommissionsOverrideConfiguration>;
  /**
   * mastery-commissions: recalculateSaveCommissions - Recalculates commissions for a load, and saves the results of the calculation.
   * @deprecated We want to remove this eventually - adding while we work on getting kafka triggers set up for automatic calculation.
   */
  readonly recalculateSaveCommissions?: Maybe<Scalars['Boolean']>;
  /** mastery-commissions: setEmployeeAssignmentsForCommissionPlan - Given a commission plan header id, assigns all employees provided to that plan (unless already existing). Removes any assignments to that plan for employees not passed in the list. */
  readonly setEmployeeAssignmentsForCommissionPlan?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly createClientExceptionRecord: ClientExceptionRecord;
  /** @deprecated Use updateClientExceptionRecordV2, input was changed because we're moving from enums to ddts and therefore the type of 'code' changed */
  readonly updateClientExceptionRecord: ClientExceptionRecord;
  readonly updateClientExceptionRecordV2: ClientExceptionRecord;
  readonly createTenantChargeTypeConfiguration?: Maybe<TenantChargeTypeConfigurationResponse>;
  readonly updateTenantChargeTypeConfiguration?: Maybe<TenantChargeTypeConfigurationResponse>;
  readonly deleteTenantChargeTypeConfiguration?: Maybe<TenantChargeTypeConfigurationResponse>;
  readonly createPriorityConfiguration?: Maybe<PriorityConfigurationResponse>;
  readonly updatePriorityConfiguration?: Maybe<PriorityConfigurationResponse>;
  readonly deletePriorityConfiguration?: Maybe<PriorityConfigurationResponse>;
  readonly createCarrierChargeTypeConfiguration?: Maybe<CarrierChargeTypeConfigurationResponse>;
  readonly updateCarrierChargeTypeConfiguration?: Maybe<CarrierChargeTypeConfigurationResponse>;
  readonly deleteCarrierChargeTypeConfiguration?: Maybe<CarrierChargeTypeConfigurationResponse>;
  readonly createCustomerChargeTypeConfiguration?: Maybe<CustomerChargeTypeConfigurationResponse>;
  readonly updateCustomerChargeTypeConfiguration?: Maybe<CustomerChargeTypeConfigurationResponse>;
  readonly deleteCustomerChargeTypeConfiguration?: Maybe<CustomerChargeTypeConfigurationResponse>;
  readonly createOrUpdateCarrierV2: CreateOrUpdateCarrierPayloadV2;
  readonly createOrUpdateCarrierCertificationV2: CreateOrUpdateCarrierCertificationPayloadV2;
  readonly createOrUpdateCarrierCustomerRelationshipV2: CreateOrUpdateCarrierCustomerRelationshipPayloadV2;
  readonly createOrUpdateCarrierServiceV2: CreateOrUpdateCarrierServicePayloadV2;
  readonly createOrUpdateCarrierSettingV2: CreateOrUpdateCarrierSettingPayloadV2;
  readonly createOrUpdateCarrierReleaseMethod: CreateOrUpdateCarrierReleaseMethodPayload;
  readonly destroyCarrierCertificationV2: DestroyCarrierCertificationPayloadV2;
  readonly destroyCarrierIdentifier: DestroyCarrierIdentifierPayload;
  readonly destroyCarrierServiceV2: DestroyCarrierServicePayloadV2;
  readonly destroyCarrierRep: DestroyCarrierRepPayload;
  readonly destroyCarrierReleaseMethod: DestroyCarrierReleaseMethodPayload;
  readonly updateCarrierIntrastateV2: UpdateCarrierIntrastatePayloadV2;
  readonly updateCarrierStandingV2: UpdateCarrierStandingPayloadV2;
  readonly createOrUpdateCarrierAddress: CreateOrUpdateCarrierAddressPayload;
  readonly createOrUpdateCarrierRep: CreateOrUpdateCarrierRepPayload;
  readonly createOrUpdateCarrierIdentifier: CreateOrUpdateCarrierIdentifierPayload;
  readonly createOrUpdateCarrierContact: CreateOrUpdateCarrierContactPayload;
  readonly createOrUpdateCarrierParent: CreateOrUpdateCarrierParentPayload;
  readonly destroyCarrierAddress: DestroyCarrierAddressPayload;
  readonly destroyCarrierContact: DestroyCarrierContactPayload;
  readonly createOrUpdateCarrierGroup: CreateOrUpdateCarrierGroupPayload;
  readonly createValidationConfiguration: ValidationConfiguration;
  readonly updateValidationConfiguration: ValidationConfiguration;
  readonly deleteValidationConfiguration: ValidationConfiguration;
  readonly acquireLock?: Maybe<Lock>;
  readonly releaseLock: Scalars['Boolean'];
  readonly adminReleaseLock: LockState;
  readonly refreshLock?: Maybe<Lock>;
  readonly rebookRouteVendorV2: RebookRouteVendorV2Payload;
  readonly assignVendorToRouteV2: AssignVendorToRouteV2Payload;
  readonly bulkAssignVendorToRoutesV2: BulkAssignVendorToRoutesV2Payload;
  readonly updateRouteVendorV2: UpdateRouteVendorV2Payload;
  readonly updateBounceV2: UpdateBounceV2Payload;
  readonly bounceRouteVendorV2: BounceRouteVendorV2Payload;
  readonly validateBooking: ValidateBookingPayload;
  readonly createBook?: Maybe<Book>;
  readonly assignAccountingChargeTypeToBlumeChargeType: AssignAccountingChargeTypeToBlumeChargeTypePayload;
  readonly importBlumeCharges: ImportBlumeChargesPayload;
  readonly upsertTenderOptions?: Maybe<TenderOptions>;
  readonly reorderTenderPlanTenderSequence?: Maybe<TenderPlan>;
  readonly toggleTenderSkipStatus?: Maybe<TenderPlan>;
  readonly createTenderPlan?: Maybe<TenderPlan>;
  readonly startTenderPlan?: Maybe<TenderPlan>;
  readonly stopTenderPlan?: Maybe<TenderPlan>;
  readonly recreateTenderPlan?: Maybe<TenderPlan>;
  readonly createOutboundTenderSettings?: Maybe<OutboundTenderSettings>;
  readonly updateOutboundTenderSettings?: Maybe<OutboundTenderSettings>;
  readonly updateMultipleTenderOptions?: Maybe<ReadonlyArray<Maybe<TenderOptions>>>;
  readonly sendToOpenBoard: Scalars['Boolean'];
  /** @deprecated Use saveRoutingGuide instead */
  readonly createRoutingGuideV2?: Maybe<RoutingGuide>;
  /** @deprecated Use saveRoutingGuide instead */
  readonly updateRoutingGuide?: Maybe<RoutingGuide>;
  /** @deprecated Use saveRoutingGuide instead */
  readonly createRoutingGuideSequenceItem?: Maybe<RoutingGuideSequenceItem>;
  /** @deprecated Use saveRoutingGuide instead */
  readonly updateRoutingGuideSequenceItem?: Maybe<RoutingGuideSequenceItem>;
  /** @deprecated Use saveRoutingGuide instead */
  readonly deleteRoutingGuideSequenceItem: Scalars['Boolean'];
  /** @deprecated Use saveRoutingGuide instead */
  readonly reorderRoutingGuideSequenceItems: Scalars['Boolean'];
  readonly saveRoutingGuide?: Maybe<RoutingGuide>;
  /** @deprecated Use uploadRoutingGuidesV2 instead */
  readonly uploadRoutingGuides?: Maybe<UploadRoutingGuidePayload>;
  readonly uploadRoutingGuidesV2?: Maybe<UploadRoutingGuidesV2Payload>;
  /**
   * Deprecated - Use applyPatternV3
   * @deprecated Use applyPatternV3
   */
  readonly applyPattern?: Maybe<ApplyPatternPayload>;
  /**
   * Deprecated - Use createPatternV2
   * @deprecated Use createPatternV2
   */
  readonly createPattern?: Maybe<Pattern>;
  /**
   * Deprecated - Use deletePatternV2
   * @deprecated Use deletePatternV2
   */
  readonly deletePattern: DeletePatternPayload;
  /**
   * Deprecated - Use reorderLanePatternsByPriorityV2
   * @deprecated Use reorderLanePatternsByPriorityV2
   */
  readonly reorderLanePatternsByPriority: ReorderLanePatternsByPriorityPayload;
  /**
   * Deprecated - Use setPatternAutoApplyV2
   * @deprecated Use setPatternAutoApplyV2
   */
  readonly setPatternAutoApply: SetAutoApplyPayload;
  /**
   * Deprecated - Use updatePatternV2
   * @deprecated Use updatePatternV2
   */
  readonly updatePattern?: Maybe<Pattern>;
  /**
   * Deprecated - Use applyPatternV3
   * @deprecated Use applyPatternV3
   */
  readonly applyPatternV2: ApplyPatternPayloadV2;
  readonly createPatternV2: CreatePatternPayload;
  readonly deletePatternV2: DeletePatternPayloadV2;
  readonly reorderLanePatternsByPriorityV2: ReorderLanePatternsByPriorityPayloadV2;
  readonly setPatternAutoApplyV2: SetAutoApplyPayloadV2;
  readonly updatePatternV2: UpdatePatternPayload;
  readonly applyPatternV3?: Maybe<ApplyPatternPayloadV2>;
  readonly createCarrierCapacityConstraint?: Maybe<CarrierCapacity>;
  readonly updateCarrierCapacityConstraint?: Maybe<CarrierCapacity>;
  readonly saveCustomerRatingSource?: Maybe<SaveCustomerRatingSourcePayload>;
  /** mastery-accounting: activateAdvancePayment - Reactivates payment on a blocked advance, this is a restricted action. */
  readonly activateAdvancePayment?: Maybe<Advance>;
  /** mastery-accounting: addAutoAssociationUsers - add list of users for auto association */
  readonly addAutoAssociationUsers?: Maybe<ReadonlyArray<Maybe<AutoAssociationUser>>>;
  /** mastery-accounting: addCustomerOrderRequiredDocuments - create custom customer order required documents */
  readonly addCustomerOrderRequiredDocuments?: Maybe<ReadonlyArray<Maybe<CustomerOrderRequiredDocument>>>;
  /** mastery-accounting: advanceRefreshOverride - Manually overrides the cashed amount and status for Advance refreshes (dev and test environments only) */
  readonly advanceRefreshOverride?: Maybe<ReadonlyArray<Maybe<Advance>>>;
  /** mastery-accounting: autoRateInvoiceBatchAsync - executes the automated customer invoice batch process. */
  readonly autoInvoiceBatchAsync: Scalars['Boolean'];
  /** mastery-accounting: recreateInvoiceToCustomerByInvoiceHeaderId - Recreate invoice to customer as per distribution method. */
  readonly BatchRecreateInvoicesByInvoiceHeaderIds?: Maybe<ReadonlyArray<Maybe<InvoiceHeader>>>;
  /** mastery-accounting: resendInvoiceToCustomerByInvoiceHeaderId - Resend invoice to customer as per distribution method. */
  readonly BatchResendInvoicesByInvoiceHeaderIds?: Maybe<ReadonlyArray<Maybe<InvoiceHeader>>>;
  /** mastery-accounting: blockAdvancePayment - Blocks payment on an advance, this is a restricted action. */
  readonly blockAdvancePayment?: Maybe<Advance>;
  /** mastery-accounting: cancelAdvance - Cancels an advance, this is a restricted action. */
  readonly cancelAdvance?: Maybe<Advance>;
  /** mastery-accounting: cancelInvoiceBatch - Cancels (deletes) a pending batch of invoices, based on the batch id passed in. Also cancels the related invoice header(s) and resets the related rate details to 'Saved' status. */
  readonly cancelInvoiceBatch: Scalars['Boolean'];
  /** mastery-accounting: cancelVoucherBatch - Cancels (deletes) a pending batch of vouchers, based on the batch id passed in.  Also cancels the related voucher header(s) and resets the related cost details to 'Saved' status. */
  readonly cancelVoucherBatch: Scalars['Boolean'];
  /** mastery-accounting: closeAdvanceFromQueue - Sets an advance to closed, this is a restricted action. */
  readonly closeAdvanceFromQueue?: Maybe<Advance>;
  /** mastery-accounting: copyGlobalRequiredDocumentForCarrier - Copies a list of Global Vendor Required document for Vendor Type carrier */
  readonly copyGlobalVendorRequiredDocumentForCarrier?: Maybe<ReadonlyArray<Maybe<VendorAssociatedRequiredDocument>>>;
  /** mastery-accounting: createAdjustmentInterfaces - Creates a collection of Adjustment Interfaces */
  readonly createAdjustmentInterfaces?: Maybe<ReadonlyArray<Maybe<AdjustmentInterface>>>;
  /** mastery-accounting: createAdvance - Creates a new Advance */
  readonly createAdvance?: Maybe<Advance>;
  /** mastery-accounting: createAdvanceInterfaces - Creates a collection of Advance Interfaces */
  readonly createAdvanceInterfaces?: Maybe<ReadonlyArray<Maybe<AdvanceInterface>>>;
  /** mastery-accounting: createAdvanceProviderConfig - Creates an advance provider config */
  readonly createAdvanceProviderConfig?: Maybe<AdvanceProviderConfig>;
  /** mastery-accounting: createAutoVoucherBatch - Creates and processes a batch of vouchers for routeVendors with RLC and VRFS complete statuses.  The batch will be set to the 'Invoicing' status */
  readonly createAutoVoucherBatch: Scalars['Boolean'];
  /** Creates a new Bill To Code */
  readonly createBillToCode?: Maybe<BillToCode>;
  /** Creates new Bill To Codes */
  readonly createBillToCodes?: Maybe<ReadonlyArray<Maybe<BillToCode>>>;
  /** mastery-accounting: createCostDetails - Creates a list of cost details. */
  readonly createCostDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  /** mastery-accounting: createCustomerCreditMemo - Create customer credit memo */
  readonly createCustomerCreditMemo?: Maybe<CustomerCreditMemo>;
  /** mastery-accounting: createCustomerOrderRequiredDocument - create custom customer order required document */
  readonly createCustomerOrderRequiredDocument?: Maybe<CustomerOrderRequiredDocument>;
  /** mastery-accounting: createCustomerRequiredDocument - Creates a new customer required document */
  readonly createCustomerRequiredDocument?: Maybe<CustomerRequiredDocument>;
  /** mastery-accounting: createCustomerRequiredDocuments - Creates a new customer required documents */
  readonly createCustomerRequiredDocuments?: Maybe<ReadonlyArray<Maybe<CustomerRequiredDocument>>>;
  /** mastery-accounting: CreateExchangeRateConfiguration- Creates a new Exchange Rate Configuration */
  readonly createExchangeRateConfiguration?: Maybe<ExchangeRateConfiguration>;
  /** mastery-accounting: createInvoiceBatch - Creates a batch of invoices from the order ids passed in (if orders are open and haven't been previously invoiced). */
  readonly createInvoiceBatch?: Maybe<InvoiceBatch>;
  /** Creates a new Jurisdiction Tax */
  readonly createJurisdictionTax?: Maybe<JurisdictionTax>;
  /** mastery-accounting: createOrUpdateExtractConfiguration - Create or update extract configuration */
  readonly createOrUpdateExtractConfiguration?: Maybe<ExtractConfigurationType>;
  /** mastery-accounting: createOrUpdateIntegrationMapping - Create or update Integration Mapping */
  readonly createOrUpdateIntegrationMapping?: Maybe<IntegrationMapping>;
  /** mastery-accounting: createOrUpdateSegmentationConfigurations - Create or update multiple segmentation configurations. */
  readonly createOrUpdateSegmentationConfigurations?: Maybe<ReadonlyArray<Maybe<SegmentationConfiguration>>>;
  /** mastery-accounting: createOrUpdateVendorInvoice - Create or update a vendor invoice */
  readonly createOrUpdateVendorInvoice?: Maybe<VendorInvoice>;
  /** mastery-accounting: createOrupdateVendorRequiredDocuments - Create or Updates a list of vendor required documents */
  readonly createOrupdateVendorRequiredDocuments?: Maybe<ReadonlyArray<Maybe<VendorRequiredDocument>>>;
  /** mastery-accounting: createRateDetails - Creates a list of rate details. */
  readonly createRateDetails?: Maybe<ReadonlyArray<Maybe<RateDetail>>>;
  /** mastery-accounting: createSegment - Create a segment */
  readonly createSegment?: Maybe<Segment>;
  /** mastery-accounting: createSegment - Create a segmentation configuration. */
  readonly createSegmentationConfiguration?: Maybe<SegmentationConfiguration>;
  /** Creates a new Taxability Charge Tyoe */
  readonly createTaxabilityChargeType?: Maybe<TaxabilityChargeTypeOutput>;
  /** mastery-accounting: createTaxInterfaces - Creates a collection of Tax Interfaces */
  readonly createTaxInterfaces?: Maybe<ReadonlyArray<Maybe<TaxInterfaceModel>>>;
  /** mastery-accounting: createVendorAssociatedRequiredDocument - Creates a new vendor associated required document */
  readonly createVendorAssociatedRequiredDocument?: Maybe<VendorAssociatedRequiredDocument>;
  /** mastery-accounting: createVendorAssociatedRequiredDocuments - Creates a new vendor associated required documents */
  readonly createVendorAssociatedRequiredDocuments?: Maybe<ReadonlyArray<Maybe<VendorAssociatedRequiredDocument>>>;
  /** mastery-accounting: createVendorCreditMemo - Create vendor credit memo */
  readonly createVendorCreditMemo?: Maybe<VendorCreditMemo>;
  /** mastery-accounting: createVendorInvoice - Create a vendor invoice */
  readonly createVendorInvoice?: Maybe<VendorInvoice>;
  /** mastery-accounting: createVendorInvoiceVouchers - Creates a record linking vendor invoices to vouchers */
  readonly createVendorInvoiceVouchers?: Maybe<ReadonlyArray<Maybe<VendorInvoiceVoucher>>>;
  /** mastery-accounting: createVendorRequiredDocument - Creates a new vendor required document */
  readonly createVendorRequiredDocument?: Maybe<VendorRequiredDocument>;
  /** mastery-accounting: createVendorRequiredDocuments - Creates a list of new vendor required document */
  readonly createVendorRequiredDocuments?: Maybe<ReadonlyArray<Maybe<VendorRequiredDocument>>>;
  /**
   * mastery-accounting: createVoucherBatch - Creates a batch of vouchers from the route/vendor id pairs passed in (if the route/vendor pairs contain open cost details).
   * @deprecated This is the old way of creating a voucher batch. Use ProcessPartialVoucherBatch instead
   */
  readonly createVoucherBatch?: Maybe<VoucherBatch>;
  /** mastery-accounting: CreateVoucherTolerance - Creates a new voucher tolerance */
  readonly createVoucherTolerance?: Maybe<VoucherTolerance>;
  /** mastery-accounting: createVoucherVendorTolerance - Creates a new voucher vendor tolerance */
  readonly createVoucherVendorTolerance?: Maybe<VoucherVendorTolerance>;
  /** Deletes an existing Bill To Code */
  readonly deleteBillToCode?: Maybe<BillToCode>;
  /** mastery-accounting: deleteCostDetail - Delete a rate detail. */
  readonly deleteCostDetail?: Maybe<CostDetail>;
  /** mastery-accounting: deleteCustomerRequiredDocument - Delete a customer required document */
  readonly deleteCustomerRequiredDocument?: Maybe<CustomerRequiredDocument>;
  /** mastery-accounting: deleteExchangeRateConfiguration - Delete a Exchange Rate Configuration */
  readonly deleteExchangeRateConfiguration?: Maybe<ExchangeRateConfiguration>;
  /** mastery-accounting: deleteExtractConfiguration detail - Delete extract Configuration Details. */
  readonly deleteExtractConfiguration?: Maybe<ExtractConfigurationType>;
  /** mastery-accounting: deleteIntegrationMapping - Delete Integration Mapping */
  readonly deleteIntegrationMapping?: Maybe<IntegrationMapping>;
  /** Deletes an existing Jurisdiction Tax */
  readonly deleteJurisdictionTax?: Maybe<JurisdictionTax>;
  /**
   * mastery-accounting: deleteOrphanedEntities - This query is only intended to be used during the interim until Kafka is fully implemented
   * @deprecated This query should not be used; it is only intended for removing orphaned records while Kafka is being built out.
   */
  readonly deleteOrphanedEntities?: Maybe<OrphanedEntitiesModel>;
  /** mastery-accounting: deleteRateDetail - Delete a rate detail. */
  readonly deleteRateDetail?: Maybe<RateDetail>;
  /** mastery-accounting: deleteSegment - Delete a segment. */
  readonly deleteSegment: Scalars['Boolean'];
  /** mastery-accounting: deleteSegmentationConfiguration - Delete a segmentation configuration. */
  readonly deleteSegmentationConfiguration: Scalars['Boolean'];
  /** Deletes an existing Taxability Charge Type */
  readonly deleteTaxabilityChargeType?: Maybe<TaxabilityChargeTypeOutput>;
  /** mastery-accounting: deleteVendorAssociatedRequiredDocument - Delete a vendor associated required document */
  readonly deleteVendorAssociatedRequiredDocument?: Maybe<VendorAssociatedRequiredDocument>;
  /** mastery-accounting: deleteVendorInvoice - Delete a vendor invoice. */
  readonly deleteVendorInvoice?: Maybe<VendorInvoice>;
  /** mastery-accounting: deleteVendorInvoiceVoucher - Delete a vendor invoice / voucher link */
  readonly deleteVendorInvoiceVoucher?: Maybe<VendorInvoiceVoucher>;
  /** mastery-accounting: deleteVendorRequiredDocument - Delete a vendor required document */
  readonly deleteVendorRequiredDocument?: Maybe<VendorRequiredDocument>;
  /** mastery-accounting: deleteVoucherTolerance - Delete a voucher tolerance */
  readonly deleteVoucherTolerance?: Maybe<VoucherTolerance>;
  /** mastery-accounting: deleteVoucherVendorTolerance - Delete a voucher vendor tolerance */
  readonly deleteVoucherVendorTolerance?: Maybe<VoucherVendorTolerance>;
  /** mastery-accounting: forceCreateRateDetails - Creates a list of rate details. Will set order to incomplete if credit fails */
  readonly forceCreateRateDetails?: Maybe<ReadonlyArray<Maybe<RateDetail>>>;
  /** mastery-accounting: issueAdvance - Issues an advance, this is a restricted action. */
  readonly issueAdvance?: Maybe<Advance>;
  /** mastery-accounting: loadRepeatCreateCostDetails - Load repeat create cost details. */
  readonly loadRepeatCreateCostDetails?: Maybe<LoadRepeatCreatedCostDetailsPayload>;
  /** mastery-accounting: loadRepeatCreateRateDetails - Load repeat create rate details. */
  readonly loadRepeatCreateRateDetails?: Maybe<LoadRepeatCreatedRateDetailsPayload>;
  /** mastery-accounting: mergeBlumeCostDetails - Merge Blume cost details. */
  readonly mergeBlumeCostDetails?: Maybe<MergeBlumeCostDetailsPayload>;
  /** mastery-accounting: mergeCostDetailsV2 - Merges a list of cost details with the existing cost details. */
  readonly mergeCostDetailsV2?: Maybe<MergedCostDetailsModel>;
  /** mastery-accounting: mergeRateDetailsV3 - Merges a list of rate details with the existing rate details. */
  readonly mergeRateDetailsV3?: Maybe<MergedRateDetailsModel>;
  /** mastery-accounting: performAutoVoucherBatch - Perform an auto voucher batch - FOR TESTING ONLY! */
  readonly performAutoVoucherBatch?: Maybe<VoucherBatch>;
  /** mastery-accounting: processCustomerCreditMemo - Processes a customer credit memo */
  readonly processCustomerCreditMemo?: Maybe<CustomerCreditMemo>;
  /** mastery-accounting: processInvoiceBatch - Prepares an Invoice batch for the GL system.  The batch will be set to the 'Invoicing' status */
  readonly processInvoiceBatch?: Maybe<InvoiceBatch>;
  /** mastery-accounting: processPartialVoucherBatch - Prepares a Partial Voucher batch for the GL system.  The batch will be set to the 'Invoicing' status */
  readonly processPartialVoucherBatch?: Maybe<VoucherBatch>;
  /** mastery-accounting: processVendorCreditMemo - Processes a vendor credit memo */
  readonly processVendorCreditMemo?: Maybe<VendorCreditMemo>;
  /**
   * mastery-accounting: processVoucherBatch - Prepares a Voucher batch for the GL system.  The batch will be set to the 'Invoicing' status
   * @deprecated This is the old way of processing a voucher batch. Use ProcessPartialVoucherBatch instead
   */
  readonly processVoucherBatch?: Maybe<VoucherBatch>;
  /** mastery-accounting: recreateInvoiceToCustomerByInvoiceHeaderId - Recreate invoice to customer as per distribution method. */
  readonly recreateInvoiceToCustomerByInvoiceHeaderId?: Maybe<InvoiceHeader>;
  /** mastery-accounting: refreshApprovedAndIssuedAdvance - Refresh all approved and issued Advance only */
  readonly refreshApprovedAndIssuedAdvance?: Maybe<ReadonlyArray<Maybe<Advance>>>;
  /** mastery-accounting: removeAutoAssociationUser - Delete user from auto association user list  */
  readonly removeAutoAssociationUser?: Maybe<AutoAssociationUser>;
  /** mastery-accounting: removeCustomerOrderRequiredDocument - remove custom customer order required document */
  readonly removeCustomerOrderRequiredDocument?: Maybe<CustomerOrderRequiredDocument>;
  /** mastery-accounting: resendInvoiceToCustomerByInvoiceHeaderId - Resend invoice to customer as per distribution method. */
  readonly resendInvoiceToCustomerByInvoiceHeaderId?: Maybe<InvoiceHeader>;
  /**
   * mastery-accounting: setAdvanceStatusCashed - Set all approved advances in a load as cashed status.
   * @deprecated This query should not be used; it is only intended for testing purposes.
   */
  readonly setAdvanceStatusCashed?: Maybe<ReadonlyArray<Maybe<Advance>>>;
  /**
   * mastery-accounting: sets default values for charge types related to a given country
   * @deprecated Created field no longer required, use setDefaultChargeTypeForGivenCountry
   */
  readonly setDefaultChargeTypeForCountry: Scalars['Boolean'];
  /**
   * mastery-accounting: sets default values for charge types related to a given country
   * @deprecated defaultTaxability field will be removed in a follow up story
   */
  readonly setDefaultChargeTypeForGivenCountry: Scalars['Boolean'];
  /** mastery-accounting: set IsFinalized value of Segmentation Configuration. */
  readonly setSegmentationConfigurationIsFinalized?: Maybe<SegmentationConfiguration>;
  /** mastery-accounting: setSegmentValues - Set values to be used for a DDT in segmentation */
  readonly setSegmentValues: Scalars['Boolean'];
  /** mastery-accounting: synchronizeCustomersWithMasteryAccounting */
  readonly synchronizeCustomersWithMasteryAccounting?: Maybe<ReadonlyArray<Maybe<SynchronizedCustomerType>>>;
  /** mastery-accounting: synchronizeVendorsWithMasteryAccounting */
  readonly synchronizeVendorsWithMasteryAccounting?: Maybe<ReadonlyArray<Maybe<SynchronizedVendorType>>>;
  /** mastery-accounting: updateAccountingExceptions - Updates a list of accounting exceptions. */
  readonly updateAccountingExceptions?: Maybe<ReadonlyArray<Maybe<AccountingException>>>;
  /** mastery-accounting: updateAdjustmentInterfaces - Update a collection of Adjustment Interfaces */
  readonly updateAdjustmentInterfaces?: Maybe<ReadonlyArray<Maybe<AdjustmentInterface>>>;
  /** mastery-accounting: updateAdvanceBasic - The update mutation for updates by a basic user. */
  readonly updateAdvanceBasic?: Maybe<Advance>;
  /** mastery-accounting: updateAdvanceInterfaces - Update a collection of Advance Interfaces */
  readonly updateAdvanceInterfaces?: Maybe<ReadonlyArray<Maybe<AdvanceInterface>>>;
  /** mastery-accounting: Updates an advance provider config */
  readonly updateAdvanceProviderConfig?: Maybe<AdvanceProviderConfig>;
  /** mastery-accounting: updateAdvanceRestricted - The update mutation for updates by a user with elevated permissions. */
  readonly updateAdvanceRestricted?: Maybe<Advance>;
  /** Updates an existing Bill To Code */
  readonly updateBillToCode?: Maybe<BillToCode>;
  /** Updates an existing Bill To Codes */
  readonly updateBillToCodes?: Maybe<ReadonlyArray<Maybe<BillToCode>>>;
  /** mastery-accounting: updateCalculateVatChecked - Updates calculate VAT settings */
  readonly updateCalculateVatChecked?: Maybe<CalculateVatCheckedModel>;
  /** mastery-accounting: Updates a Carrier's Accounting Status */
  readonly updateCarrierAccountingDetails?: Maybe<SynchronizedVendorType>;
  /**
   * mastery-accounting: updateChargeTypeGLConfiguration - Updates a charge type GL configuration.
   * @deprecated This should not be used as we are transition to Charge Type Configuration service: Use updateTenantChargeTypeConfiguration with isGlobal true
   */
  readonly updateChargeTypeGLConfiguration?: Maybe<ChargeType>;
  /** mastery-accounting: updateCostDetails - Updates a list of cost details. */
  readonly updateCostDetails?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  /** mastery-accounting: updateCreateAdjustmentItemsChecked - Updates a default for create adjustment items unchecked / checked. */
  readonly updateCreateAdjustmentItemsChecked?: Maybe<CreateAdjustmentItemsChecked>;
  /** mastery-accounting: Updates a Customers Tax Exemption Status and Accounting Status */
  readonly updateCustomerAccountingDetails: Scalars['Boolean'];
  /** mastery-accounting: update customer autoinvoicing - Enable or disable the auto invoicing of a customer */
  readonly updateCustomerAutoInvoice: Scalars['Boolean'];
  /** mastery-accounting: updateCustomerCreditMemo - Updates a customer credit memo */
  readonly updateCustomerCreditMemo?: Maybe<CustomerCreditMemo>;
  /** mastery-accounting: updateCustomerOrderRequiredDocuments - Updates a list of customer order required documents. */
  readonly updateCustomerOrderRequiredDocuments?: Maybe<ReadonlyArray<Maybe<CustomerOrderRequiredDocument>>>;
  /** mastery-accounting: updateCustomerRequiredDocument - Updates a customer required document */
  readonly updateCustomerRequiredDocument?: Maybe<CustomerRequiredDocument>;
  /**
   * mastery-accounting: Updates a Customers Tax Exemption Status
   * @deprecated Use mutation updateCustomerAccountingDetails instead.
   */
  readonly updateCustomerTaxExemption: Scalars['Boolean'];
  /** mastery-accounting: updateExchangeRateConfiguration - Updates a Exchange Rate Configuration */
  readonly updateExchangeRateConfiguration?: Maybe<ExchangeRateConfiguration>;
  /** Updates an existing Jurisdiction Tax */
  readonly updateJurisdictionTax?: Maybe<JurisdictionTax>;
  /** mastery-accounting: updateOrderInvoiceRequirement - Update bypass for order invoice financial status complete validation */
  readonly updateOrderInvoiceRequirement: Scalars['Boolean'];
  /** mastery-accounting: updateOrderTaxRequirements - Update tax exemptions */
  readonly updateOrderTaxRequirements: Scalars['Boolean'];
  /** mastery-accounting: updateRateDetails - Updates a list of rate details. */
  readonly updateRateDetails?: Maybe<ReadonlyArray<Maybe<RateDetail>>>;
  /** mastery-accounting: updateRouteMaxCost - Update the max cost on a route */
  readonly updateRouteMaxCost?: Maybe<RouteMaxCostType>;
  /** mastery-accounting: updateRouteVendorThresholds - Update route vendor with the latest calculated thresholds */
  readonly updateRouteVendorThresholds?: Maybe<RouteVendorCost>;
  /** mastery-accounting: updateRouteVendorVoucherDisableFinancials - Update disable financials for auto voucher route vendor exempt from AP validations */
  readonly UpdateRouteVendorVoucherDisableFinancials: Scalars['Boolean'];
  /** mastery-accounting: updateRouteVendorVoucherRequirement - Update bypass for route vendor financial status complete validation */
  readonly updateRouteVendorVoucherRequirement: Scalars['Boolean'];
  /** mastery-accounting: updateSegment - Update a segment */
  readonly updateSegment?: Maybe<Segment>;
  /** mastery-accounting: updateSegment - Update a segmentation configuration. */
  readonly updateSegmentationConfiguration?: Maybe<SegmentationConfiguration>;
  /** Updates an existing Taxability Charge Type */
  readonly updateTaxabilityChargeType?: Maybe<TaxabilityChargeTypeOutput>;
  /** mastery-accounting: updateTaxInterfaces - Update a collection of Tax Interfaces */
  readonly updateTaxInterfaces?: Maybe<ReadonlyArray<Maybe<TaxInterfaceModel>>>;
  /** mastery-accounting: updateVendorAssociatedRequiredDocument - Updates a vendor associated required document */
  readonly updateVendorAssociatedRequiredDocument?: Maybe<VendorAssociatedRequiredDocument>;
  /** mastery-accounting: update vendor autovouchering - Enable or disable the auto vouchering of a vendor/carrier */
  readonly updateVendorAutoVoucher: Scalars['Boolean'];
  /** mastery-accounting: updateVendorCreditMemo - Updates a vendor credit memo */
  readonly updateVendorCreditMemo?: Maybe<VendorCreditMemo>;
  /** mastery-accounting: updateVendorInvoice - Update a vendor invoice */
  readonly updateVendorInvoice?: Maybe<VendorInvoice>;
  /** mastery-accounting: updateVendorInvoiceVoucher - Updates a record linking vendor invoice to voucher */
  readonly updateVendorInvoiceVoucher?: Maybe<VendorInvoiceVoucher>;
  /** mastery-accounting: updateVendorRequiredDocument - Updates a vendor required document */
  readonly updateVendorRequiredDocument?: Maybe<VendorRequiredDocument>;
  /** mastery-accounting: updateVendorRequiredDocuments - Updates a list of vendor required documents */
  readonly updateVendorRequiredDocuments?: Maybe<ReadonlyArray<Maybe<VendorRequiredDocument>>>;
  /** mastery-accounting: updateVendorRouteRequiredDocuments - Updates a list of vendor route required documents. */
  readonly updateVendorRouteRequiredDocuments?: Maybe<ReadonlyArray<Maybe<VendorRouteRequiredDocument>>>;
  /** mastery-accounting: update vendor use_global - Checked or unchecked */
  readonly updateVendorUseGlobalToggle: Scalars['Boolean'];
  /** mastery-accounting: updateDefaultVoucherDate - Updates a default voucher date preference. */
  readonly updateVoucherDateDefault?: Maybe<VoucherDateDefault>;
  /** mastery-accounting: updateVoucherTolerance - Updates a voucher tolerance */
  readonly updateVoucherTolerance?: Maybe<VoucherTolerance>;
  /** mastery-accounting: updateVoucherVendorTolerance - Updates a voucher vendor tolerance */
  readonly updateVoucherVendorTolerance?: Maybe<VoucherVendorTolerance>;
};


export type MutationBulkDeleteExternalTruckEntriesArgs = {
  input?: InputMaybe<BulkDeleteExternalTruckEntriesInput>;
};


export type MutationCreateOrUpdateTruckByExternalSourceIdArgs = {
  input?: InputMaybe<CreateOrUpdateTruckByExternalSourceIdInput>;
};


export type MutationCreateFirstTruckEntriesArgs = {
  input?: InputMaybe<CreateFirstTruckEntriesInput>;
};


export type MutationUpdateFirstTruckEntriesArgs = {
  input?: InputMaybe<UpdateFirstTruckEntriesInput>;
};


export type MutationCreateTruckEntryArgs = {
  input?: InputMaybe<CreateTruckEntryInput>;
};


export type MutationUpdateTruckEntryArgs = {
  input?: InputMaybe<UpdateTruckEntryInput>;
};


export type MutationDeleteTruckEntryArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationCreateTruckEntryTemplateAndFirstTrucksArgs = {
  input?: InputMaybe<CreateTruckEntryTemplateInputV2>;
};


export type MutationUpdateTruckEntryTemplateAndFirstTrucksArgs = {
  input?: InputMaybe<UpdateTruckEntryTemplateInput>;
};


export type MutationUpdateTruckEntryTemplateArgs = {
  input?: InputMaybe<UpdateTruckEntryTemplateInput>;
};


export type MutationDeleteTruckEntryTemplateArgs = {
  input?: InputMaybe<DeleteTruckEntryTemplateInput>;
};


export type MutationCreateTruckEntryTemplateArgs = {
  input?: InputMaybe<CreateTruckEntryTemplateInput>;
};


export type MutationCreateTruckEntryTemplateV2Args = {
  input?: InputMaybe<CreateTruckEntryTemplateInputV2>;
};


export type MutationCreateContainerArgs = {
  input: CreateContainerInput;
};


export type MutationUpdateContainerArgs = {
  input: UpdateContainerInput;
};


export type MutationDeleteContainerArgs = {
  id: Scalars['ID'];
  updatedByUserId: Scalars['ID'];
};


export type MutationCreateGateReservationArgs = {
  input: CreateGateReservationInput;
};


export type MutationUpdateGateReservationArgs = {
  input: UpdateGateReservationInput;
};


export type MutationDeleteGateReservationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateOrUpdateOrDeleteTruckByRouteIdArgs = {
  input?: InputMaybe<CreateOrUpdateOrDeleteTruckByRouteIdInput>;
};


export type MutationCreateTruckEntryFromDriverAssignmentArgs = {
  input?: InputMaybe<CreateTruckEntryFromDriverAssignmentInput>;
};


export type MutationDeleteTruckByRouteIdArgs = {
  routeId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateTruckEntryByRouteIdArgs = {
  input?: InputMaybe<UpdateTruckEntryByRouteIdInput>;
};


export type MutationCreateOrUpdateContainerByRouteIdArgs = {
  input?: InputMaybe<CreateOrUpdateContainerByRouteIdInput>;
};


export type MutationDeleteContainerByRouteIdArgs = {
  input?: InputMaybe<DeleteContainerByRouteIdInput>;
};


export type MutationSave_Asset_TrailersArgs = {
  objAsset_Trailers: CreateAssetTrailersInput;
};


export type MutationDeleteAsset_TrailersArgs = {
  objAssetID: Scalars['String'];
};


export type MutationUpdateAsset_TrailersArgs = {
  equipmentInput: ReadonlyArray<CreateAssetTrailersEquipmentInput>;
  identifierInput: ReadonlyArray<CreateAssetTrailersIdentifierInput>;
  permanentTractorinput: ReadonlyArray<CreateAssetPermanentTractorInput>;
  permanentDriverinput: ReadonlyArray<CreateAssetPermanentDriverInput>;
  input: ReadonlyArray<CreateAssetTrailersLegalToRunInput>;
  objAsset_Trailers: CreateAssetTrailersInput;
  objAssetID: Scalars['String'];
};


export type MutationCreateupdatePermanentDriversArgs = {
  objAssetDriverID: Scalars['String'];
  objTrailerDriverinput: ReadonlyArray<CreateAssetPermanentDriverInput>;
};


export type MutationCreateupdatePermanentTractorsArgs = {
  objAssetTractorID: Scalars['String'];
  objTrailerTractorinput: ReadonlyArray<CreateAssetPermanentTractorInput>;
};


export type MutationUpdateAssetTrailersByOperationsArgs = {
  maintenanceInput: ReadonlyArray<CreateAssetTrailerMaintenanceInput>;
  objUpdatedBy?: InputMaybe<Scalars['String']>;
  objPool?: InputMaybe<Scalars['String']>;
  objFleet?: InputMaybe<Scalars['String']>;
  objServiceStatus?: InputMaybe<Scalars['String']>;
  objOperatingStatus?: InputMaybe<Scalars['String']>;
  objAssetTrailerID: Scalars['String'];
};


export type MutationCreateOrUpdateTrailerArgs = {
  ObjAssetTrailer: CreateAssetTrailersInput;
};


export type MutationCreateOrUpdateTrailerV2Args = {
  input: CreateAssetTrailersInput;
};


export type MutationCreateOrUpdateTrailerV3Args = {
  input: CreateAssetTrailersInputV2;
};


export type MutationUpsertAutoEtaArgs = {
  input: UpsertAutoEtaInput;
};


export type MutationCreateDriverAssignmentArgs = {
  createDriverAssignmentInput: CreateDriverAssignmentInput;
};


export type MutationUpdateDriverAssignmentArgs = {
  updateDriverAssignmentInput: UpdateDriverAssignmentInput;
};


export type MutationUpsertDriverAssignmentArgs = {
  driverAssignment: DriverAssignmentInput;
};


export type MutationDeleteDriverAssignmentArgs = {
  deleteDriverAssignmentInput?: InputMaybe<DeleteDriverAssignmentInput>;
  id: Scalars['ID'];
};


export type MutationCreateTrackingUpdateV4Args = {
  update: TrackingUpdateInputV4;
};


export type MutationCreateTimelineJournalEntryArgs = {
  input: JournalEntryCreateInput;
};


export type MutationUpdateTimelineJournalEntryArgs = {
  input: JournalEntryUpdateInput;
};


export type MutationCreateTimelineJournalEntryV2Args = {
  input: JournalEntryCreateInputV2;
};


export type MutationUpdateTimelineJournalEntryV2Args = {
  input: JournalEntryUpdateInputV2;
};


export type MutationCreateTaskV2Args = {
  create: CreateTaskInputV2;
};


export type MutationDeleteTaskV2Args = {
  id: Scalars['ID'];
};


export type MutationUpdateTaskV2Args = {
  update: UpdateTaskInputV2;
};


export type MutationCloseTaskV2Args = {
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Scalars['String']>;
};


export type MutationCreateTaskArgs = {
  create: CreateTaskInput;
};


export type MutationDeleteTaskArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateTaskArgs = {
  update: UpdateTaskInput;
};


export type MutationCloseTaskArgs = {
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Scalars['String']>;
};


export type MutationIngressStopEventV2Args = {
  input: StopEventV2IngressInput;
};


export type MutationUpsertStopEventsV2Args = {
  input: ReadonlyArray<StopEventV2UpsertInput>;
};


export type MutationUpsertStopEventV2Args = {
  input: StopEventV2UpsertInput;
};


export type MutationDeleteStopEventV2Args = {
  input: StopEventV2DeleteInput;
};


export type MutationUpdateStopEventV2LateReasonArgs = {
  input: StopEventV2LateReasonUpdateInput;
};


export type MutationSaveSpotQuoteArgs = {
  input: SaveSpotQuoteInput;
};


export type MutationCreateSpotQuoteArgs = {
  input: CreateSpotQuoteInput;
};


export type MutationConvertSpotQuoteToLoadArgs = {
  input: ConvertSpotQuoteToLoadInput;
};


export type MutationSendDirectMessageArgs = {
  singleUserInput: SingleUserInputObj;
};


export type MutationSendMsgToMultipleUsersArgs = {
  multiUserInput: MultiUserInputObj;
};


export type MutationSendMessageToSlackArgs = {
  sendMsgToSlackInput: SendMsgToSlackInputObj;
};


export type MutationAppCreationArgs = {
  appCreationInput: AppCreationInputObj;
};


export type MutationSendSlackMessageArgs = {
  userInput: SendMsgInputObj;
};


export type MutationCreateOrUpdateFilterArgs = {
  input: SavedFilterInputType;
};


export type MutationDeleteFilterByIdArgs = {
  input: DeleteFilterByIdInputType;
};


export type MutationRequestToBookV2Args = {
  bypassBooking?: InputMaybe<Scalars['Boolean']>;
  carrierId?: InputMaybe<Scalars['ID']>;
  orderId: Scalars['ID'];
  routeId: Scalars['ID'];
};


export type MutationAddVendorToRouteArgs = {
  input: AddVendorToRouteInput;
};


export type MutationAssignVendorToRouteArgs = {
  input: AssignVendorToRouteInput;
};


export type MutationBounceRouteVendorArgs = {
  input: BounceRouteVendorInput;
};


export type MutationBulkUpdateTenderStatusArgs = {
  input: ReadonlyArray<BulkUpdateTenderStatusInput>;
};


export type MutationCreateCreditHistoryArgs = {
  input: CreditHistoryInput;
};


export type MutationCreateCustomerCreditProviderArgs = {
  input: CustomerCreditProviderInput;
};


export type MutationCreateCustomerQuoteArgs = {
  input: QuoteInput;
};


export type MutationCreateLoadArgs = {
  input: LoadInput;
};


export type MutationCreateMultipleTruckPostingsArgs = {
  input: TruckPostingInput;
};


export type MutationCreateOrUpdateAddressArgs = {
  input: CreateAddressInput;
};


export type MutationCreateOrUpdateCarrierArgs = {
  input: CarrierInput;
};


export type MutationCreateOrUpdateCarrierCertificationArgs = {
  input: CarrierCertificationInput;
};


export type MutationCreateOrUpdateCarrierCustomerRelationshipArgs = {
  input: CarrierCustomerRelationshipInput;
};


export type MutationCreateOrUpdateCarrierReleaseMethodV1Args = {
  input: CarrierReleaseMethodInputV1;
};


export type MutationCreateOrUpdateCarrierRiskInformationArgs = {
  input: CarrierRiskInformationInput;
};


export type MutationCreateOrUpdateCarrierServiceArgs = {
  input: CarrierServiceStandaloneInput;
};


export type MutationCreateOrUpdateCarrierSettingArgs = {
  input: CarrierSettingInput;
};


export type MutationCreateOrUpdateContactArgs = {
  input: CreateContactInput;
};


export type MutationCreateOrUpdateCustomerArgs = {
  input: CustomerInput;
};


export type MutationCreateOrUpdateCustomerFacilityRelationshipArgs = {
  input: CustomerFacilityRelationshipInput;
};


export type MutationCreateOrUpdateCustomerGroupArgs = {
  input: CustomerGroupInput;
};


export type MutationCreateOrUpdateCustomerOrderArgs = {
  input: CreateOrUpdateCustomerOrderInput;
};


export type MutationCreateOrUpdateCustomerSettingArgs = {
  input: CustomerSettingInput;
};


export type MutationCreateOrUpdateEmployeeArgs = {
  input: EmployeeInput;
};


export type MutationCreateOrUpdateEmployeeExternalArgs = {
  input: EmployeeInputTypeExternal;
};


export type MutationCreateOrUpdateFacilityArgs = {
  input: FacilityInput;
};


export type MutationCreateOrUpdateFacilityIdentifierArgs = {
  input: FacilityIdentifierStandaloneInput;
};


export type MutationCreateOrUpdateSingleTruckPostingArgs = {
  input: TruckPostingInput;
};


export type MutationCreateOrUpdateStopArgs = {
  input: CreateOrUpdateStopInput;
};


export type MutationDestroyAddressArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDestroyCarrierCertificationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDestroyCarrierReleaseMethodV1Args = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDestroyCarrierServiceArgs = {
  id: Scalars['ID'];
};


export type MutationDestroyContactArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDestroyFacilityIdentifierArgs = {
  id: Scalars['ID'];
};


export type MutationDestroyTruckPostingArgs = {
  id: Scalars['ID'];
};


export type MutationFindOrCreateFacilityArgs = {
  input: FindOrCreateFacilityInput;
};


export type MutationRebookRouteVendorArgs = {
  input: RebookRouteVendorInput;
};


export type MutationRemoveCustomerQuoteFromGroupArgs = {
  input: RemoveQuoteFromGroupInput;
};


export type MutationUpdateBounceArgs = {
  input: UpdateBounceInput;
};


export type MutationUpdateCarrierIntrastateArgs = {
  input: CarrierIntrastateInput;
};


export type MutationUpdateCarrierStandingArgs = {
  input: CarrierStandingInput;
};


export type MutationUpdateLoadArgs = {
  input: UpdateLoadInput;
};


export type MutationUpdateRouteArgs = {
  input: UpdateRouteInput;
};


export type MutationUpdateRouteStopsArgs = {
  input: UpdateRouteStopsInput;
};


export type MutationUpdateRouteVendorArgs = {
  input: UpdateRouteVendorInput;
};


export type MutationConvertQuoteToLoadArgs = {
  input: QuoteToLoadInput;
};


export type MutationCreateCustomerQuoteV2Args = {
  input: RateQuoteInput;
};


export type MutationCreateCustomerQuoteV3Args = {
  input: RateQuoteInputV3;
};


export type MutationCreateCustomerQuoteV4Args = {
  input: RateQuoteInputV4;
};


export type MutationCreateCustomerQuoteV5Args = {
  input: RateQuoteInputV4;
};


export type MutationCreateCustomerQuoteV6Args = {
  input: RateQuoteInputV5;
};


export type MutationRemoveCustomerQuoteFromGroupV2Args = {
  input: RemoveRateQuoteFromQuoteGroupInput;
};


export type MutationRemoveCustomerQuoteFromGroupV3Args = {
  input: RemoveRateQuoteFromQuoteGroupInput;
};


export type MutationRemoveCustomerQuoteFromGroupV4Args = {
  input: RemoveRateQuoteFromQuoteGroupInput;
};


export type MutationRemoveCustomerQuoteFromGroupV5Args = {
  input: RemoveRateQuoteFromQuoteGroupInput;
};


export type MutationUpdateCustomerQuoteArgs = {
  input: UpdateRateQuoteInput;
};


export type MutationCreateRateConfirmationArgs = {
  input: RateConfirmationInput;
};


export type MutationStartAutoRateConfirmationArgs = {
  input: StartAutoRateConfirmationInput;
};


export type MutationCreateOrUpdateProjectArgs = {
  input: CreateOrUpdateProjectInput;
};


export type MutationImportLoadV2Args = {
  routeLifecycleStatus?: InputMaybe<Scalars['String']>;
  routeActivationStatus?: InputMaybe<Scalars['String']>;
  loadId: Scalars['String'];
};


export type MutationImportTruckEntryV2Args = {
  truckEntryId: Scalars['String'];
};


export type MutationUpdateTrucksForCarrierV2Args = {
  carrierId: Scalars['String'];
};


export type MutationDeleteAvailableTruckV2Args = {
  truckPostingId: Scalars['String'];
};


export type MutationImportGateReservationArgs = {
  gateReservationId: Scalars['String'];
};


export type MutationDeleteAvailableGateReservationArgs = {
  gateReservationId: Scalars['String'];
};


export type MutationImportContainerArgs = {
  containerId: Scalars['String'];
};


export type MutationDeleteAvailableContainerArgs = {
  containerId: Scalars['String'];
};


export type MutationImportLoadArgs = {
  routeLifecycleStatus?: InputMaybe<Scalars['String']>;
  routeActivationStatus?: InputMaybe<Scalars['String']>;
  loadId: Scalars['String'];
};


export type MutationProcessAvailableRouteArgs = {
  availableRoute: SaveAvailableRouteInput;
};


export type MutationProcessExternalAvailableRouteArgs = {
  externalAvailableRoute: SaveExternalAvailableRouteInput;
};


export type MutationDeactivateAvailableRoutesArgs = {
  availableRoutes: ReadonlyArray<Scalars['String']>;
};


export type MutationDeleteAvailableRoutesByFilterArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  filter: AvailableRoutesFilter;
};


export type MutationSaveAvailableTruckArgs = {
  availableTruck: SaveAvailableTruckInput;
};


export type MutationUpdateProcurementDetailsArgs = {
  input: UpdateProcurementDetailsInput;
};


export type MutationDeleteAvailableTruckArgs = {
  truckPostingId: Scalars['String'];
};


export type MutationImportTruckEntryArgs = {
  truckEntryId: Scalars['String'];
};


export type MutationUpdateTrucksForCarrierArgs = {
  carrierId: Scalars['String'];
};


export type MutationGenerateMatchesForRouteArgs = {
  changeEvent?: InputMaybe<ProcurementMatchEvent>;
  routeId: Scalars['String'];
};


export type MutationGenerateMatchesForTruckArgs = {
  truckPostingId: Scalars['String'];
};


export type MutationDeleteMatchesByFilterArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  changeEvent: ProcurementMatchEvent;
  filter: MatchFilter;
};


export type MutationGenerateMatchesForGateReservationArgs = {
  gateReservationId: Scalars['String'];
};


export type MutationGenerateMatchesForContainerArgs = {
  containerId: Scalars['String'];
};


export type MutationUpdateMaxCostArgs = {
  updateMaxCostInput: UpdateMaxCostInput;
};


export type MutationSaveAssetPowerArgs = {
  objAssetPower: CreateAssetPowerInput;
};


export type MutationDeleteAssetPowerArgs = {
  objAssetID: Scalars['String'];
};


export type MutationUpdateAssetPowerArgs = {
  equipmentInput: ReadonlyArray<CreateAssetPowerEquipmentInput>;
  identifierInput: ReadonlyArray<CreateAssetPowerIdentifierInput>;
  permanentTrailerInput: ReadonlyArray<CreateAssetPermanentTrailerInput>;
  permanentDriverInput: ReadonlyArray<CreateAssetPowerPermanentDriverInput>;
  input: ReadonlyArray<CreateAssetPowerLegalToRunInput>;
  objAssetPower: CreateAssetPowerInput;
  objAssetID: Scalars['String'];
};


export type MutationCreateUpdatePowerPermanentDriversArgs = {
  objAssetDriverID: Scalars['String'];
  objPowerDriverinput: ReadonlyArray<CreateAssetPowerPermanentDriverInput>;
};


export type MutationCreateUpdatePowerPermanentTrailersArgs = {
  objAssetTrailerID: Scalars['String'];
  objPowerTrailerinput: ReadonlyArray<CreateAssetPermanentTrailerInput>;
};


export type MutationUpdateAssetPowersByOperationsArgs = {
  objMaintenanceInput?: InputMaybe<ReadonlyArray<CreateAssetPowerMaintenanceInput>>;
  objFacilityLocation?: InputMaybe<Scalars['String']>;
  objLastFacility?: InputMaybe<Scalars['String']>;
  objLastPingDateTm?: InputMaybe<Scalars['String']>;
  objLastPingLocation?: InputMaybe<Scalars['String']>;
  objUpdatedBy?: InputMaybe<Scalars['String']>;
  objPool?: InputMaybe<Scalars['String']>;
  objFleet?: InputMaybe<Scalars['String']>;
  objServiceStatus?: InputMaybe<Scalars['String']>;
  objOperatingStatus?: InputMaybe<Scalars['String']>;
  objAssetPowerID: Scalars['String'];
};


export type MutationCreateOrUpdatePowerArgs = {
  input: CreateAssetPowerInput;
};


export type MutationCreateOrUpdatePowerV2Args = {
  input: CreateAssetPowerInputV2;
};


export type MutationCreatePostingArgs = {
  input: CreatePostingInput;
};


export type MutationCreatePostingsArgs = {
  input: CreatePostingsInput;
};


export type MutationUpdatePostingArgs = {
  input: UpdatePostingInput;
};


export type MutationRemovePostingArgs = {
  input?: InputMaybe<RemovePostingInput>;
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationRemovePostingsArgs = {
  input: RemovePostingsInput;
};


export type MutationRemovePostingsByRouteIdsArgs = {
  input: RemovePostingsByRouteIdsInput;
};


export type MutationCreateOfferArgs = {
  input: CreateOfferInput;
};


export type MutationUpdateOfferArgs = {
  input: UpdateOfferInput;
};


export type MutationDeleteOfferArgs = {
  id: Scalars['ID'];
};


export type MutationAcceptOfferArgs = {
  input: AcceptOfferResponseInput;
};


export type MutationCounterOfferArgs = {
  input: CounterOfferResponseInput;
};


export type MutationDeclineOfferArgs = {
  input: DeclineOfferResponseInput;
};


export type MutationUpdateMultipleOfferResponseArgs = {
  input: ReadonlyArray<UpdateMultipleOfferResponseInput>;
};


export type MutationAddBidArgs = {
  bidData: BidInput;
};


export type MutationCancelBidArgs = {
  bidId: Scalars['ID'];
};


export type MutationUpdateBidArgs = {
  bidData: BidInput;
};


export type MutationAcceptBidArgs = {
  bidId: Scalars['ID'];
};


export type MutationRejectBidArgs = {
  bidId: Scalars['ID'];
};


export type MutationUpdateRoundArgs = {
  roundData: RoundInput;
};


export type MutationAddBidQuoteArgs = {
  bidQuoteData: BidQuoteInput;
};


export type MutationInsertCustomerMarkupArgs = {
  saveCustomerMarkupInput?: InputMaybe<CustomerMarkupInput>;
};


export type MutationUpdateCustomerMarkupArgs = {
  customerMarkupInput?: InputMaybe<CustomerMarkupInput>;
};


export type MutationAddressStatusUpdateArgs = {
  request: ReadonlyArray<AddressStatusChangeRequest>;
};


export type MutationCreateCustomerOrderArgs = {
  orderInput: CustomerOrderInput;
};


export type MutationUpdateCustomerOrderArgs = {
  orderId: Scalars['ID'];
  orderInput: CustomerOrderInput;
  version?: InputMaybe<Scalars['Int']>;
};


export type MutationCancelCustomerOrderV2Args = {
  input: CancelCustomerOrderInput;
};


export type MutationCreateOrUpdateCustomerOrderV2Args = {
  orderInput: CreateOrUpdateCustomerOrderInputV2;
};


export type MutationCreateOrderStopArgs = {
  orderStopInput: OrderStopInput;
};


export type MutationDeleteOrderStopArgs = {
  deleteOrderStopInput: DeleteOrderStopInput;
};


export type MutationChangeCustomerOrderArgs = {
  changeCustomerOrderInput: ChangeCustomerOrderInput;
};


export type MutationCreateLoadV2Args = {
  loadInput: LoadInputV2;
};


export type MutationUpdateLoadV2Args = {
  loadInput: UpdateLoadInputV2;
};


export type MutationPlanLoadArgs = {
  planLoadInput: PlanLoadInput;
};


export type MutationResequenceStopsArgs = {
  resequenceStopsInput: ResequenceStopsInput;
};


export type MutationResequenceLoadStopsArgs = {
  resequenceLoadStopsInput: ResequenceLoadStopsInput;
};


export type MutationCreateOrUpdateStopV2Args = {
  loadStopInput: LoadStopInput;
};


export type MutationCreateOrUpdateCityStateStopV2Args = {
  cityStateStopInput: CityStateStopInput;
};


export type MutationDeleteStopArgs = {
  deleteStopInput: DeleteStopInput;
};


export type MutationAssociateOrderStopsArgs = {
  associateOrderStopsInput: ReadonlyArray<AssociateOrderStopInput>;
};


export type MutationAssociateLoadOrderStopsArgs = {
  associateLoadOrderStopsInput: ReadonlyArray<AssociateLoadOrderStopInput>;
};


export type MutationAssignFacilityToCityStateStopArgs = {
  assignFacilityToStopInput: AssignFacilityToCityStateStopInput;
};


export type MutationChangeFacilityOnStopArgs = {
  changeFacilityOnStopInput: ChangeFacilityOnStopInput;
};


export type MutationUpdateCityStateOnStopArgs = {
  updateCityStateOnStopInput: UpdateCityStateOnStopInput;
};


export type MutationPlanLoadV2Args = {
  planLoadV2Input: PlanLoadV2Input;
};


export type MutationCloneLoadArgs = {
  cloneLoadInput: CloneLoadInput;
};


export type MutationCreateLoadRouteArgs = {
  route: CreateLoadRouteInput;
  loadId: Scalars['ID'];
};


export type MutationUpdateLoadRouteArgs = {
  routeInput: UpdateLoadRouteInput;
};


export type MutationDeleteRouteArgs = {
  deleteRouteInput: DeleteRouteInput;
};


export type MutationCreateRouteStopArgs = {
  routeStopInput: RouteStopInput;
};


export type MutationDeleteRouteStopArgs = {
  deleteRouteStopInput: DeleteRouteStopInput;
};


export type MutationCreateLinkedRouteV2Args = {
  input: CreateLinkedRouteInputV2;
};


export type MutationDeleteLinkedRouteV2Args = {
  userID?: InputMaybe<Scalars['ID']>;
  routeID: Scalars['ID'];
};


export type MutationCreateLaneArgs = {
  input: LaneInput;
};


export type MutationCreateLaneV2Args = {
  input: LaneInputV2;
};


export type MutationCreateLaneV3Args = {
  input: LaneInputV3;
};


export type MutationCreateLaneV4Args = {
  input: LaneInputV4;
};


export type MutationDeactivateCustomerLaneArgs = {
  input: DeactivateCustomerLaneInput;
};


export type MutationCreateIncidentV2Args = {
  input: IncidentCreateInputV2;
};


export type MutationUpdateIncidentV2Args = {
  input: IncidentUpdateInputV2;
};


export type MutationUpdateServiceProfileArgs = {
  UpdateServiceProfileInput: UpdateServiceProfileInput;
  serviceProfileId: Scalars['Float'];
};


export type MutationCreateServiceProfileArgs = {
  CreateServiceProfileInput: CreateServiceProfileInput;
};


export type MutationDeleteServiceProfileArgs = {
  serviceProfileId: Scalars['Float'];
};


export type MutationUpdateServiceProfileV2Args = {
  UpdateServiceProfileInput: UpdateServiceProfileInput;
  serviceProfileId: Scalars['Float'];
};


export type MutationCreateServiceProfileV2Args = {
  CreateServiceProfileInput: CreateServiceProfileInput;
};


export type MutationUpdateRuleSetsArgs = {
  update: UpdateRuleSetsInput;
};


export type MutationUpdateTemplatesArgs = {
  update: UpdateTemplatesInput;
};


export type MutationUpdateHaykEventArgs = {
  update: UpdateHaykEventInput;
};


export type MutationCreateHaykEventArgs = {
  create: CreateHaykEventInput;
};


export type MutationCreateHaykRedirectArgs = {
  create: HaykRedirectInput;
};


export type MutationUpdateHaykRedirectArgs = {
  update: UpdateHaykRedirectInput;
};


export type MutationDeleteHaykRedirectArgs = {
  delete: DeleteHaykRedirectInput;
};


export type MutationCreateHaykNotificationsArgs = {
  create: HaykNotificationsInput;
};


export type MutationCreatePlatformMessageArgs = {
  create: CreatePlatformMessageInput;
};


export type MutationCreateHaykAuditHistoryArgs = {
  create: CreateAuditHistoryInput;
};


export type MutationAddPreferredNameArgs = {
  input: GeographyPreferredNameInput;
};


export type MutationUpdateGeographyRecordArgs = {
  input: GeographyUpdateInput;
};


export type MutationInsertFuelProgramCustomerConfigurationArgs = {
  input: FuelProgramCustomerConfigurationCreateInput;
};


export type MutationUpdateFuelProgramCustomerConfigurationArgs = {
  input: FuelProgramCustomerConfigurationUpdateInput;
};


export type MutationDeleteFuelProgramCustomerConfigurationArgs = {
  fuelProgramCustomerConfigurationId: Scalars['ID'];
};


export type MutationInsertHistoricalFuelIndexPriceArgs = {
  input: HistoricalFuelIndexPriceCreateInput;
};


export type MutationUpdateHistoricalFuelIndexPriceArgs = {
  input: HistoricalFuelIndexPriceUpdateInput;
};


export type MutationUpdateHistoricalFuelIndexAveragePriceOverrideArgs = {
  historicalFuelIndexPriceId: Scalars['ID'];
  averagePriceOverride: Scalars['Decimal'];
};


export type MutationDeleteHistoricalFuelIndexPriceArgs = {
  historicalFuelIndexPriceId: Scalars['ID'];
};


export type MutationInsertCustomerFuelRateHeaderArgs = {
  input: CustomerFuelRateHeaderCreateInput;
};


export type MutationInsertCustomerFuelRangeArgs = {
  input: CustomerFuelRangeCreateInput;
};


export type MutationUpdateCustomerFuelRangeArgs = {
  input: CustomerFuelRangeUpdateInput;
};


export type MutationDeleteCustomerFuelRangeArgs = {
  customerFuelRangeId: Scalars['ID'];
};


export type MutationBulkInsertCustomerFuelRangeArgs = {
  input: BulkCustomerFuelRangeCreateInput;
};


export type MutationCreateOrUpdateFacilitySublocationActivityArgs = {
  input: CreateOrUpdateFacilitySublocationActivityInput;
};


export type MutationDestroyFacilitySublocationActivityArgs = {
  activityId: Scalars['ID'];
};


export type MutationCreateOrUpdateFacilitySublocationAccommodationArgs = {
  input: CreateOrUpdateFacilitySublocationAccommodationInput;
};


export type MutationDestroyFacilitySublocationAccommodationArgs = {
  accommodationId: Scalars['String'];
};


export type MutationCreateOrUpdateFacilitySublocationArgs = {
  input: CreateFacilitySublocationInput;
};


export type MutationDestroyFacilitySublocationArgs = {
  sublocationId: Scalars['ID'];
};


export type MutationCreateOrUpdateFacilitySublocationGateArgs = {
  input: CreateOrUpdateFacilitySublocationGateInput;
};


export type MutationDestroyFacilitySublocationGateArgs = {
  gateId: Scalars['ID'];
};


export type MutationCreateOrUpdateFacilityV2Args = {
  input: FacilityInputV2;
};


export type MutationCreateOrUpdateFacilityScheduleArgs = {
  input: CreateScheduleInput;
};


export type MutationFindOrCreateFacilityV2Args = {
  input: FindOrCreateFacilityInputV2;
};


export type MutationCreateFacilityToImportArgs = {
  input: FacilityImporterInput;
};


export type MutationCreateOrUpdateFacilityIdentifierV2Args = {
  input: FacilityIdentifierStandaloneInputV2;
};


export type MutationDestroyFacilityIdentifierV2Args = {
  id: Scalars['ID'];
};


export type MutationDestroyFacilityContactArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateOrUpdateFacilityContactArgs = {
  input: CreateFacilityContactInput;
};


export type MutationCreateOrUpdateFacilityDirectionArgs = {
  input: CreateFacilityDirectionInput;
};


export type MutationCreateOrUpdateFacilityHolidayArgs = {
  input: CreateFacilityHolidayInput;
};


export type MutationCreateOrUpdateFacilityAddressArgs = {
  input: CreateFacilityAddressInput;
};


export type MutationDestroyFacilityAddressArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDestroyFacilityScheduleArgs = {
  id: Scalars['ID'];
};


export type MutationDestroyFacilityDirectionArgs = {
  id: Scalars['ID'];
};


export type MutationDestroyFacilityHolidayArgs = {
  id: Scalars['ID'];
};


export type MutationCreateOrUpdateCustomerFacilityRelationshipV2Args = {
  input: CustomerFacilityRelationshipInputV2;
};


export type MutationCreateTenantExceptionRecordArgs = {
  create: CreateTenantExceptionRecordInput;
};


export type MutationUpdateTenantExceptionRecordArgs = {
  update: UpdateTenantExceptionRecordInput;
};


export type MutationUpdateMileageTenantConfigurationArgs = {
  mileageSettings: MileageSettingsInput;
};


export type MutationUpdateMileageDefaultConfigurationArgs = {
  entityType: EntityConfigurationEntityType;
  mileageSettings: MileageSettingsInput;
};


export type MutationCreateMileageEntityConfigurationArgs = {
  entityType: EntityConfigurationEntityType;
  entityId: Scalars['ID'];
  effectiveDate: Scalars['String'];
  expirationDate?: InputMaybe<Scalars['String']>;
  mileageSettings: MileageSettingsInput;
  createdBy?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateMileageEntityConfigurationArgs = {
  entityType: EntityConfigurationEntityType;
  entityId: Scalars['ID'];
  effectiveDate: Scalars['String'];
  expirationDate?: InputMaybe<Scalars['String']>;
  uuid: Scalars['ID'];
  mileageSettings: MileageSettingsInput;
  updatedBy?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteMileageEntityConfigurationArgs = {
  uuid: Scalars['ID'];
  updatedBy: Scalars['String'];
};


export type MutationCreateOrUpdateEmployeeV2Args = {
  input: EmployeeInputV2;
};


export type MutationCreateOrUpdateEmployeeExternalV2Args = {
  input: EmployeeInputTypeExternalV2;
};


export type MutationAssociateShipmentIdWithLoadIdArgs = {
  input: AssociatedShipmentIdWithLoadIdInput;
};


export type MutationSaveEdiOutboxMessageArgs = {
  input: EdiOutboxInput;
};


export type MutationSaveEdiTransactionAsyncArgs = {
  ediTransaction: EdiTransactionAddInput;
};


export type MutationUpdateEdiOutboxMessageArgs = {
  input: UpdateEdiOutboxInput;
};


export type MutationCreateDriverArgs = {
  driver: AssetDriverInput;
};


export type MutationDeleteDriverArgs = {
  driverId: Scalars['String'];
};


export type MutationUpdateDriverArgs = {
  permanentPowerInput?: InputMaybe<ReadonlyArray<DriverPermanentPowerInput>>;
  equipmentInput?: InputMaybe<ReadonlyArray<DriverEquipmentInput>>;
  awardInput?: InputMaybe<ReadonlyArray<DriverAwardInput>>;
  scheduleInput?: InputMaybe<ReadonlyArray<DriverScheduleInput>>;
  certificationsInput?: InputMaybe<ReadonlyArray<DriverCertificationsOrPermitsInput>>;
  contactInput?: InputMaybe<ReadonlyArray<DriverContactInput>>;
  addressInput?: InputMaybe<ReadonlyArray<DriverAddressInput>>;
  identifierInput?: InputMaybe<ReadonlyArray<DriverIdentifiersInput>>;
  timeOffInput?: InputMaybe<ReadonlyArray<DriverTimeOffInput>>;
  permanentTrailerInput?: InputMaybe<ReadonlyArray<DriverPermanentTrailersInput>>;
  driver: AssetDriverInput;
  driverId: Scalars['String'];
};


export type MutationUpdateDriverPowerArgs = {
  power: PermanentTractorInput;
};


export type MutationUpdateDriverTrailerArgs = {
  trailer: DriverTrailerInput;
};


export type MutationUpdateDriverOperationsArgs = {
  driver: AssetDriverInput;
};


export type MutationUpdateDriverPreferencesArgs = {
  driver: AssetDriverInput;
};


export type MutationDeleteDriverFacilityPreferencesArgs = {
  id: Scalars['String'];
};


export type MutationDeleteDriverCustomerPreferencesArgs = {
  id: Scalars['String'];
};


export type MutationSaveDriverFacilityPreferencesArgs = {
  driverFacility: DriverFacilityPreferencesInput;
};


export type MutationSaveDriverCustomerPreferencesArgs = {
  driverCustomer: DriverCustomerPreferencesInput;
};


export type MutationUpdateDriverFacilityPreferencesArgs = {
  driverFacility?: InputMaybe<ReadonlyArray<DriverFacilityPreferencesInput>>;
  id: Scalars['String'];
};


export type MutationUpdateDriverCustomerPreferencesArgs = {
  driverCustomer?: InputMaybe<ReadonlyArray<DriverCustomerPreferencesInput>>;
  id: Scalars['String'];
};


export type MutationSaveDriverPreferredLanesArgs = {
  driverPreferredLanes: DriverPreferredLanesInput;
};


export type MutationUpdateDriverPreferredLanesArgs = {
  driverPreferredLanes?: InputMaybe<ReadonlyArray<DriverPreferredLanesInput>>;
  id: Scalars['String'];
};


export type MutationSaveDriverPreferredRoutesArgs = {
  driverPreferredRoutes: DriverPreferredRoutesInput;
};


export type MutationUpdateDriverPreferredRoutesByIdArgs = {
  driverPreferredRouteStops?: InputMaybe<ReadonlyArray<DriverPreferredRouteStopsInput>>;
  driverPreferredRoutes?: InputMaybe<DriverPreferredRoutesInput>;
  id: Scalars['String'];
};


export type MutationDeleteDriverPreferredLanesArgs = {
  id: Scalars['String'];
};


export type MutationDeleteDriverPreferredRoutesArgs = {
  id: Scalars['String'];
};


export type MutationCreateOrUpdateDriverArgs = {
  input: AssetDriverInput;
};


export type MutationCreateOrUpdateDriverV2Args = {
  input: AssetDriverInputV2;
};


export type MutationDeleteDriverWeeklyTargetArgs = {
  id: Scalars['String'];
};


export type MutationCreateOrUpdateDriverWeeklyTargetArgs = {
  weeklyTarget: DriverWeeklyTargetInput;
};


export type MutationCreateOrUpdateDriverTrainingArgs = {
  training: DriverTrainingInput;
};


export type MutationDeleteTrainingArgs = {
  id: Scalars['String'];
};


export type MutationUpdateCrmDocumentNameArgs = {
  input: UpdateCrmDocumentNameInput;
};


export type MutationDeleteCustomerDocumentArgs = {
  documentId: Scalars['ID'];
};


export type MutationUpdateLoadDocumentDetailsArgs = {
  input: UpdateLoadDocDetailsInput;
};


export type MutationDeleteCarrierDocumentArgs = {
  documentId: Scalars['ID'];
};


export type MutationDeleteFacilityDocumentArgs = {
  documentId: Scalars['ID'];
};


export type MutationCompleteUploadArgs = {
  completeUpload: CompleteUploadInput;
};


export type MutationCompleteUploadV2Args = {
  completeUpload: CompleteUploadInputV2;
};


export type MutationUpdateCustomerDriverLocationFrequencyConfigurationArgs = {
  input: DriverLocationFrequencyInput;
};


export type MutationCreateOrUpdateCustomerGroupV2Args = {
  input: CustomerGroupInputV2;
};


export type MutationCreateOrUpdateCustomerAddressArgs = {
  input: CreateCustomerAddressInput;
};


export type MutationCreateOrUpdateCustomerV2Args = {
  input: CustomerInputV2;
};


export type MutationCreateOrUpdateCustomerSettingV2Args = {
  input: CustomerSettingInputV2;
};


export type MutationCreateCreditHistoryV2Args = {
  input: CreditHistoryInputV2;
};


export type MutationCreateOrUpdateCustomerContactArgs = {
  input: CreateCustomerContactInput;
};


export type MutationCreateCustomerCreditProviderV2Args = {
  input: CustomerCreditProviderInputV2;
};


export type MutationCreateOrUpdateCustomerRepArgs = {
  input: CreateCustomerRepInput;
};


export type MutationCreateOrUpdateCustomerIdentifierArgs = {
  input: CreateCustomerIdentifierInput;
};


export type MutationDestroyCustomerAddressArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDestroyCustomerContactArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDestroyCustomerRepArgs = {
  id: Scalars['ID'];
};


export type MutationDestroyCustomerIdentifierArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateCustomerStructureArgs = {
  customerStructureInputDto: CreateCustomerStructureInput;
};


export type MutationUpdateCustomerStructureArgs = {
  customerStructureInputDto: UpdateCustomerStructureInput;
  id: Scalars['String'];
};


export type MutationDeleteCustomerStructureArgs = {
  id: Scalars['String'];
};


export type MutationCreateNoteDefaultArgs = {
  noteDefaultInputDto: CreateNoteDefaultInput;
};


export type MutationDeleteNoteDefaultArgs = {
  id: Scalars['String'];
};


export type MutationUpdateNoteDefaultArgs = {
  noteDefaultInputDto: UpdateNoteDefaultInput;
  id: Scalars['String'];
};


export type MutationCreateCustomerRegionArgs = {
  input: CreateCustomerRegionInput;
};


export type MutationUpdateCustomerRegionArgs = {
  input: UpdateCustomerRegionInput;
};


export type MutationDeleteCustomerRegionArgs = {
  id: Scalars['String'];
};


export type MutationCreateCustomerFacilityArgs = {
  input: CreateCustomerFacilityInput;
};


export type MutationUpdateCustomerFacilityArgs = {
  input: UpdateCustomerFacilityInput;
};


export type MutationDeleteCustomerFacilityArgs = {
  id: Scalars['String'];
};


export type MutationCreateCustomerCommodityArgs = {
  createCustomerCommodityInput: CreateCustomerCommodityInput;
};


export type MutationUpdateCustomerCommodityArgs = {
  updateCustomerCommodityInput: UpdateCustomerCommodityInput;
  id: Scalars['String'];
};


export type MutationDeleteCustomerCommodityArgs = {
  id: Scalars['String'];
};


export type MutationCreateLoadDefaultsArgs = {
  input: CreateLoadDefaultsInput;
};


export type MutationCreateLoadDefaultsV2Args = {
  input: CreateLoadDefaultsInputV2;
};


export type MutationUpdateLoadDefaultsArgs = {
  input: UpdateLoadDefaultsInput;
  id: Scalars['String'];
};


export type MutationUpdateLoadDefaultsV2Args = {
  input: UpdateLoadDefaultsInputV2;
  id: Scalars['String'];
};


export type MutationDeleteLoadDefaultsArgs = {
  id: Scalars['String'];
};


export type MutationCreateCustomerCustomerRelationshipArgs = {
  createCustomerCustomerRelationshipInput: CreateCustomerCustomerRelationshipInput;
};


export type MutationUpdateCustomerCustomerRelationshipArgs = {
  updateCustomerCustomerRelationshipInput: UpdateCustomerCustomerRelationshipInput;
  id: Scalars['String'];
};


export type MutationDeleteCustomerCustomerRelationshipArgs = {
  id: Scalars['String'];
};


export type MutationCreateCarrierFacilityArgs = {
  CreateCarrierFacilityInput: CreateCarrierFacilityInput;
};


export type MutationUpdateCarrierFacilityArgs = {
  UpdateCarrierFacilityInput: UpdateCarrierFacilityInput;
  id: Scalars['String'];
};


export type MutationDeleteCarrierFacilityArgs = {
  id: Scalars['String'];
};


export type MutationCreateFacilityNoteDefaultsArgs = {
  input: CreateFacilityNoteDefaultsInput;
};


export type MutationUpdateFacilityNoteDefaultsArgs = {
  input: UpdateFacilityNoteDefaultsInput;
  id: Scalars['String'];
};


export type MutationDeleteFacilityNoteDefaultsArgs = {
  id: Scalars['String'];
};


export type MutationCreateFacilityLoadDefaultsArgs = {
  input: CreateFacilityLoadDefaultsInput;
};


export type MutationUpdateFacilityLoadDefaultsArgs = {
  input: UpdateFacilityLoadDefaultsInput;
  id: Scalars['String'];
};


export type MutationDeleteFacilityLoadDefaultsArgs = {
  id: Scalars['String'];
};


export type MutationCreateOrUpdateCustomerCommitmentArgs = {
  input: CustomerCommitmentInputType;
};


export type MutationCreateOrUpdateCustomerCommitmentsSettingsArgs = {
  input: CustomerCommitmentsSettingsInputType;
};


export type MutationCrmOpportunitySaveArgs = {
  obj_CRM_Opportunity: CrmOpportunityInput;
};


export type MutationCrmOpportunitySaveV2Args = {
  objCrmOpportunity: CrmOpportunityInputV2;
};


export type MutationCrmOpportunityAppendV2Args = {
  objCrmOpportunity: CrmOpportunityInputV2;
  objOpportunityId: Scalars['String'];
};


export type MutationCrmInteractionDeleteArgs = {
  obj_CRM_ID: Scalars['String'];
};


export type MutationCrmInteractionSaveArgs = {
  obj_CRM_Interaction: CrmInteractionInput;
};


export type MutationCrmInteractionObjectiveSaveArgs = {
  obj_CRM_Interaction_Objective: CrmInteractionObjectiveInput;
};


export type MutationCrmInteractionRescheduleSaveArgs = {
  obj_CRM_Interaction_Reschedule: CrmInteractionRescheduleInput;
};


export type MutationCrmInteractionAppendArgs = {
  interaction_RepGroup_Input: ReadonlyArray<CrmInteractionRepGroupInput>;
  interaction_Objective_Input: ReadonlyArray<CrmInteractionObjectiveInput>;
  interaction_Contact_Input: ReadonlyArray<CrmInteractionContactInput>;
  interaction_Opportunity_Input: ReadonlyArray<CrmInteractionOpportunityInput>;
  obj_CRM_Interaction: CrmInteractionInput;
  obj_Interaction_Id: Scalars['String'];
};


export type MutationCrmInteractionAssignRepGroupReassignArgs = {
  interaction_RepGroup_Input: ReadonlyArray<CrmInteractionRepGroupInput>;
  obj_CRM_Interaction: CrmInteractionInput;
  obj_Interaction_Id: Scalars['String'];
};


export type MutationCrmInteractionDocumentSaveArgs = {
  obj_CRM_Interaction_Document: CrmInteractionDocumentInput;
};


export type MutationCrmInteractionDocumentDeleteArgs = {
  obj_CRM_Interaction_Document_Id: Scalars['String'];
};


export type MutationCrmInteractionDocumentRemoveArgs = {
  DocumentId: Scalars['String'];
};


export type MutationCrmCompanyDescriptionSaveArgs = {
  objCrmCompanyDescription: CrmCompanyDescriptionInput;
};


export type MutationCrmCompanyDescriptionEditArgs = {
  objCrmCompanyDescription: CrmCompanyDescriptionInput;
  objCompanyDescriptionId: Scalars['String'];
};


export type MutationCrmDetailedDescriptionSaveArgs = {
  objDetailedDescription: CrmDetailedDescriptionInput;
};


export type MutationCrmDetailedDescriptionEditArgs = {
  objCrmDetailedDescription: CrmDetailedDescriptionInput;
  objDetailedDescriptionId: Scalars['String'];
};


export type MutationCrmDetailedDescriptionDeleteArgs = {
  objDetailedDescriptionId: Scalars['String'];
};


export type MutationCrmFirmographicsSaveArgs = {
  objFirmographics: CrmFirmographicsInput;
};


export type MutationCrmFirmographicsEditArgs = {
  objFirmographics: CrmFirmographicsInput;
  objFirmographicsId: Scalars['String'];
};


export type MutationSaveContactArgs = {
  obj_CRM_Contact: CrmContactInput;
};


export type MutationUpdateContactAssociatedEntityArgs = {
  obj_Main_Email_Id: Scalars['String'];
  obj_Main_Phone_Id: Scalars['String'];
  obj_Contact_Associated_Entity_Id: Scalars['String'];
};


export type MutationUpdateContactNameInformationDataArgs = {
  obj_CRM_Contact: CrmContactInput;
  obj_Contact_Id: Scalars['String'];
};


export type MutationUpdateContactGeneralDataArgs = {
  obj_CRM_Contact_Pricing_Behaviour: ReadonlyArray<CrmContactPricingBehaviourInput>;
  obj_CRM_Contact: CrmContactInput;
  obj_Contact_Id: Scalars['String'];
};


export type MutationUpdateContactBusinessInformationDataArgs = {
  obj_CRM_Contact: CrmContactInput;
  obj_Contact_Id: Scalars['String'];
};


export type MutationSaveAssociatedRepsArgs = {
  obj_CRM_Contact_Associated_Rep: CrmContactAssociatedRepInput;
};


export type MutationInactivateContactAssociatedRepByIdArgs = {
  obj_Is_Active: Scalars['Boolean'];
  obj_Contact_Associated_Rep_Id: Scalars['String'];
};


export type MutationInactivateContactInformationByIdArgs = {
  obj_Is_Active: Scalars['Boolean'];
  obj_Contact_Information_Id: Scalars['String'];
};


export type MutationModifyAssociatedRepByIdArgs = {
  obj_CRM_Contact_Associated_Rep: CrmContactAssociatedRepInput;
  obj_Contact_Associated_Rep_Id: Scalars['String'];
};


export type MutationSaveContactInformationArgs = {
  obj_CRM_Contact_Information: CrmContactInformationInput;
};


export type MutationModifyContactInformationArgs = {
  obj_CRM_Contact_Information: CrmContactInformationInput;
  obj_Contact_Information_Id: Scalars['String'];
};


export type MutationSaveContactWorkHoursArgs = {
  obj_CRM_Contact_Work_Hours: ReadonlyArray<CrmContactWorkHoursInput>;
};


export type MutationModifyContactWorkHourArgs = {
  obj_Contact_Work_Hour: CrmContactWorkHoursInput;
  obj_Contact_Work_Hour_Id: Scalars['String'];
};


export type MutationModifyAssociatedEntityByIdV2Args = {
  obj_CRM_Contact_Associated_Entity: CrmContactAssociatedEntityInput;
  obj_Contact_Associated_Entity_Id: Scalars['String'];
};


export type MutationDeleteAssociatedEntityByIdV2Args = {
  obj_Contact_Associated_Entity_Id: Scalars['String'];
};


export type MutationSaveAssociatedEntityV2Args = {
  obj_Associated_Entity: CrmContactAssociatedEntityInput;
};


export type MutationSaveContactv2Args = {
  obj_CRM_Contact: CrmContactInputV2;
};


export type MutationModifyContactAssociatedEntityv2Args = {
  obj_Main_Email_Id: Scalars['String'];
  obj_Main_Phone_Id: Scalars['String'];
  obj_Contact_Associated_Entity_Id: Scalars['String'];
};


export type MutationModifyContactNameInformationDatav2Args = {
  obj_CRM_Contact: CrmContactInputV2;
  obj_Contact_Id: Scalars['String'];
};


export type MutationModifyContactGeneralDatav2Args = {
  obj_CRM_Contact_Pricing_Behaviour: ReadonlyArray<CrmContactPricingBehaviourInput>;
  obj_CRM_Contact: CrmContactInputV2;
  obj_Contact_Id: Scalars['String'];
};


export type MutationModifyContactBusinessInformationDatav2Args = {
  obj_CRM_Contact: CrmContactInputV2;
  obj_Contact_Id: Scalars['String'];
};


export type MutationSaveAssociatedRepsv2Args = {
  obj_CRM_Contact_Associated_Rep: CrmContactAssociatedRepInputV2;
};


export type MutationInactivateContactAssociatedRepByIdv2Args = {
  obj_Is_Active: Scalars['Boolean'];
  obj_Contact_Associated_Rep_Id: Scalars['String'];
};


export type MutationInactivateContactInformationByIdv2Args = {
  obj_Is_Active: Scalars['Boolean'];
  obj_Contact_Information_Id: Scalars['String'];
};


export type MutationModifyAssociatedRepByIdv2Args = {
  obj_CRM_Contact_Associated_Rep: CrmContactAssociatedRepInputV2;
  obj_Contact_Associated_Rep_Id: Scalars['String'];
};


export type MutationSaveContactInformationv2Args = {
  obj_CRM_Contact_Information: CrmContactInformationInputV2;
};


export type MutationModifyContactInformationv2Args = {
  obj_CRM_Contact_Information: CrmContactInformationInputV2;
  obj_Contact_Information_Id: Scalars['String'];
};


export type MutationSaveContactWorkHoursv2Args = {
  obj_CRM_Contact_Work_Hours: ReadonlyArray<CrmContactWorkHoursInput>;
};


export type MutationModifyContactWorkHourv2Args = {
  obj_Contact_Work_Hour: CrmContactWorkHoursInput;
  obj_Contact_Work_Hour_Id: Scalars['String'];
};


export type MutationSaveAssociatedEntityv2Args = {
  obj_Associated_Entity: CrmContactAssociatedEntityInputV2;
};


export type MutationDeleteAssociatedEntityByIdv2Args = {
  obj_Contact_Associated_Entity_Id: Scalars['String'];
};


export type MutationModifyAssociatedEntityByIdv2Args = {
  obj_CRM_Contact_Associated_Entity: CrmContactAssociatedEntityInputV2;
  obj_Contact_Associated_Entity_Id: Scalars['String'];
};


export type MutationSaveResponsibilityv2Args = {
  obj_CRM_Contact_Responsibility: CrmContactResponsibilityInputV2;
};


export type MutationDeleteResponsibilityByIdv2Args = {
  obj_Contact_Responsibility_Id: Scalars['String'];
};


export type MutationModifyResponsibilityByIdv2Args = {
  obj_CRM_Contact_Responsibility: CrmContactResponsibilityInputV2;
  obj_Contact_Responsibility_Id: Scalars['String'];
};


export type MutationSaveContactSliderv2Args = {
  obj_CRM_Contact_Slider: CrmContactSliderInputV2;
};


export type MutationModifyContactSliderv2Args = {
  obj_CRM_Contact_Slider: CrmContactSliderInputV2;
  obj_CRM_Contact_Slider_Id: Scalars['String'];
};


export type MutationSaveContactInterestHobbyv2Args = {
  obj_CRM_Contact_Interest_Hobby: ReadonlyArray<CrmContactInterestHobbyInputV2>;
};


export type MutationModifyContactInterestHobbyv2Args = {
  obj_CRM_Contact_Interest_Hobby: ReadonlyArray<CrmContactInterestHobbyInputV2>;
  obj_CRM_Contact_Id: Scalars['String'];
};


export type MutationCreateCarrierCrmNotesArgs = {
  createCarrierCrmNotesInput: CreateCarrierCrmNotesInput;
};


export type MutationCreateCarrierCrmOpportunityArgs = {
  createCarrierCrmOpportunityInput: CreateCarrierCrmOpportunityInput;
};


export type MutationCreateCarrierInteractionArgs = {
  carrierInteractionRequest: CreateCarrierInteractionInput;
};


export type MutationCreateCustomerCrmNotesArgs = {
  createCustomerNotesRequest: CreateCustomerCrmNotesRequest;
};


export type MutationCreateCustomerCrmOpportunityArgs = {
  createCustomerCrmOpportunityInput: CreateCustomerCrmOpportunityInput;
};


export type MutationCreateCustomerInteractionArgs = {
  customerInteractionRequest: CreateCustomerInteractionInput;
};


export type MutationCreateOrUpdateCrmCarrierDetailArgs = {
  carrierDetailRequest: CrmCarrierDetailInputType;
};


export type MutationCreateOrUpdateCrmCustomerDetailArgs = {
  customerDetailRequest: CreateCrmCustomerDetailInputType;
};


export type MutationDeleteCarrierCrmDocumentsArgs = {
  deleteCarrierCrmDocumentInput: DeleteCarrierCrmDocumentInput;
};


export type MutationDeleteCustomerCrmDocumentsArgs = {
  deleteCustomerCrmDocumentInput: DeleteCustomerCrmDocumentInput;
};


export type MutationUpdateCarrierCrmDocumentsArgs = {
  updateCarrierCrmDocumentInput: UpdateCarrierCrmDocumentInput;
};


export type MutationUpdateCarrierCrmNotesArgs = {
  updateCarrierCrmNotesInput: UpdateCarrierCrmNotesInput;
};


export type MutationUpdateCarrierCrmOpportunityArgs = {
  updateCarrierCrmOpportunityInput: UpdateCarrierCrmOpportunityInput;
};


export type MutationUpdateCustomerCrmDocumentsArgs = {
  updateCustomerCrmDocumentInput: UpdateCustomerCrmDocumentInput;
};


export type MutationUpdateCustomerCrmNotesArgs = {
  updateCustomerNotesRequest: UpdateCustomerCrmNotesRequest;
};


export type MutationUpdateCustomerCrmOpportunityArgs = {
  updateCustomerCrmOpportunityInput: UpdateCustomerCrmOpportunityInput;
};


export type MutationReserveCreditNoTotalRateArgs = {
  creditRequest: ReserveCreditNoTotalRate;
};


export type MutationReserveCreditWithTotalRateArgs = {
  creditRequest: ReserveCreditWithTotalRate;
};


export type MutationReserveCreditWithRateDetailsArgs = {
  creditRequest: ReserveCreditWithRateDetails;
};


export type MutationReserveCreditWithDetailsArgs = {
  creditRequest: ReserveCreditWithDetails;
};


export type MutationReserveCreditWithAutomationArgs = {
  creditRequest: ReserveCreditWithDetails;
};


export type MutationReleaseCreditArgs = {
  creditRequest: ReleaseCredit;
};


export type MutationChangeCreditCustomerOnOrderArgs = {
  creditRequest: ChangeCreditCustomerOnOrderInput;
};


export type MutationCreateOrUpdateCostQuoteArgs = {
  input: CarrierQuoteInputType;
};


export type MutationUpdateCostQuoteArgs = {
  input: UpdateCarrierQuoteInputType;
};


export type MutationMessageWaitForSecondsArgs = {
  seconds?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateCommissionsGlobalConfigurationArgs = {
  globalConfiguration: CommissionsGlobalConfigurationInput;
};


export type MutationUpdateCommissionsGlobalConfigurationArgs = {
  globalConfiguration: CommissionsGlobalConfigurationInput;
};


export type MutationDeleteCommissionsGlobalConfigurationArgs = {
  globalConfigurationId: Scalars['ID'];
};


export type MutationCreateCommissionsCustomerOverheadConfigurationArgs = {
  customerOverheadConfiguration: CommissionsCustomerOverheadConfigurationInput;
};


export type MutationUpdateCommissionsCustomerOverheadConfigurationArgs = {
  customerOverheadConfiguration: CommissionsCustomerOverheadConfigurationInput;
};


export type MutationDeleteCommissionsCustomerOverheadConfigurationArgs = {
  customerOverheadConfigurationId: Scalars['ID'];
};


export type MutationCalculateCommissionsLoadAllocatedMarginsArgs = {
  loadId: Scalars['ID'];
};


export type MutationCreateEmployeeCommissionPlanArgs = {
  employeeCommissionPlan: EmployeeCommissionPlanInput;
};


export type MutationCreateOrUpdateEmployeeCommissionConfigurationPlanArgs = {
  employeeCommissionPlan: EmployeeCommissionPlanInput;
};


export type MutationCreateEmployeeCommissionPlanConfigurationArgs = {
  employeeCommissionPlanConfiguration: EmployeeCommissionPlanConfigurationInput;
};


export type MutationUpdateEmployeeCommissionPlanConfigurationArgs = {
  employeeCommissionPlanConfiguration: EmployeeCommissionPlanConfigurationInput;
};


export type MutationDeleteEmployeeCommissionPlanConfigurationArgs = {
  employeeCommissionPlanConfigurationId: Scalars['ID'];
};


export type MutationUpdateEmployeeCommissionPlanConfigurationHeaderArgs = {
  employeeCommissionPlanConfiguration: EmployeeCommissionPlanConfigurationInput;
};


export type MutationCreateLoadCommissionsOverrideConfigurationArgs = {
  loadCommissionsOverrideConfiguration: LoadCommissionsOverrideConfigurationInput;
};


export type MutationUpdateLoadCommissionsOverrideConfigurationArgs = {
  loadCommissionsOverrideConfiguration: LoadCommissionsOverrideConfigurationInput;
};


export type MutationRecalculateSaveCommissionsArgs = {
  loadId: Scalars['ID'];
};


export type MutationSetEmployeeAssignmentsForCommissionPlanArgs = {
  input: EmployeeCommissionPlanAssignmentsInput;
};


export type MutationCreateClientExceptionRecordArgs = {
  create: CreateClientExceptionRecordInput;
};


export type MutationUpdateClientExceptionRecordArgs = {
  update: UpdateClientExceptionRecordInput;
};


export type MutationUpdateClientExceptionRecordV2Args = {
  update: UpdateClientExceptionRecordInputV2;
};


export type MutationCreateTenantChargeTypeConfigurationArgs = {
  input?: InputMaybe<TenantChargeTypeConfigurationCreateInput>;
};


export type MutationUpdateTenantChargeTypeConfigurationArgs = {
  input?: InputMaybe<TenantChargeTypeConfigurationUpdateInput>;
};


export type MutationDeleteTenantChargeTypeConfigurationArgs = {
  id: Scalars['ID'];
};


export type MutationCreatePriorityConfigurationArgs = {
  input?: InputMaybe<PriorityConfigurationCreateInput>;
};


export type MutationUpdatePriorityConfigurationArgs = {
  input?: InputMaybe<PriorityConfigurationUpdateInput>;
};


export type MutationDeletePriorityConfigurationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCarrierChargeTypeConfigurationArgs = {
  input?: InputMaybe<CarrierChargeTypeConfigurationCreateInput>;
};


export type MutationUpdateCarrierChargeTypeConfigurationArgs = {
  input?: InputMaybe<CarrierChargeTypeConfigurationUpdateInput>;
};


export type MutationDeleteCarrierChargeTypeConfigurationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCustomerChargeTypeConfigurationArgs = {
  input?: InputMaybe<CustomerChargeTypeConfigurationCreateInput>;
};


export type MutationUpdateCustomerChargeTypeConfigurationArgs = {
  input?: InputMaybe<CustomerChargeTypeConfigurationUpdateInput>;
};


export type MutationDeleteCustomerChargeTypeConfigurationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateOrUpdateCarrierV2Args = {
  input: CarrierInputV2;
};


export type MutationCreateOrUpdateCarrierCertificationV2Args = {
  input: CarrierCertificationInputV2;
};


export type MutationCreateOrUpdateCarrierCustomerRelationshipV2Args = {
  input: CarrierCustomerRelationshipInputV2;
};


export type MutationCreateOrUpdateCarrierServiceV2Args = {
  input: CarrierServiceStandaloneInputV2;
};


export type MutationCreateOrUpdateCarrierSettingV2Args = {
  input: CarrierSettingInputV2;
};


export type MutationCreateOrUpdateCarrierReleaseMethodArgs = {
  input: CarrierReleaseMethodInput;
};


export type MutationDestroyCarrierCertificationV2Args = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDestroyCarrierIdentifierArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDestroyCarrierServiceV2Args = {
  id: Scalars['ID'];
};


export type MutationDestroyCarrierRepArgs = {
  id: Scalars['ID'];
};


export type MutationDestroyCarrierReleaseMethodArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateCarrierIntrastateV2Args = {
  input: CarrierIntrastateInputV2;
};


export type MutationUpdateCarrierStandingV2Args = {
  input: CarrierStandingInputV2;
};


export type MutationCreateOrUpdateCarrierAddressArgs = {
  input: CreateCarrierAddressInput;
};


export type MutationCreateOrUpdateCarrierRepArgs = {
  input: CreateCarrierRepInput;
};


export type MutationCreateOrUpdateCarrierIdentifierArgs = {
  input: CreateCarrierIdentifierInput;
};


export type MutationCreateOrUpdateCarrierContactArgs = {
  input: CreateCarrierContactInput;
};


export type MutationCreateOrUpdateCarrierParentArgs = {
  input: CreateOrUpdateCarrierParentInput;
};


export type MutationDestroyCarrierAddressArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDestroyCarrierContactArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateOrUpdateCarrierGroupArgs = {
  input: CarrierGroupInput;
};


export type MutationCreateValidationConfigurationArgs = {
  validationConfigurationInput: ValidationConfigurationInput;
};


export type MutationUpdateValidationConfigurationArgs = {
  validationConfigurationInput: ValidationConfigurationInput;
};


export type MutationDeleteValidationConfigurationArgs = {
  validationConfigurationKeysInput: ValidationConfigurationKeysInput;
};


export type MutationAcquireLockArgs = {
  userID?: InputMaybe<Scalars['String']>;
  flavor: LockFlavor;
  objectID: Scalars['String'];
};


export type MutationReleaseLockArgs = {
  userID?: InputMaybe<Scalars['String']>;
  flavor: LockFlavor;
  objectID: Scalars['String'];
};


export type MutationAdminReleaseLockArgs = {
  flavor: LockFlavor;
  objectID: Scalars['String'];
};


export type MutationRefreshLockArgs = {
  userID?: InputMaybe<Scalars['String']>;
  flavor: LockFlavor;
  objectID: Scalars['String'];
};


export type MutationRebookRouteVendorV2Args = {
  input: RebookRouteVendorV2Input;
};


export type MutationAssignVendorToRouteV2Args = {
  input: AssignVendorToRouteV2Input;
};


export type MutationBulkAssignVendorToRoutesV2Args = {
  input: BulkAssignVendorToRoutesV2Input;
};


export type MutationUpdateRouteVendorV2Args = {
  input: UpdateRouteVendorV2Input;
};


export type MutationUpdateBounceV2Args = {
  input: UpdateBounceV2Input;
};


export type MutationBounceRouteVendorV2Args = {
  input: BounceRouteVendorV2Input;
};


export type MutationValidateBookingArgs = {
  input: ValidateBookingInput;
};


export type MutationCreateBookArgs = {
  title: Scalars['String'];
  isbn: Scalars['String'];
};


export type MutationAssignAccountingChargeTypeToBlumeChargeTypeArgs = {
  input: AssignAccountingChargeTypeToBlumeChargeTypeInput;
};


export type MutationImportBlumeChargesArgs = {
  input: ImportBlumeChargesInput;
};


export type MutationUpsertTenderOptionsArgs = {
  upsertTenderOptionsInput?: InputMaybe<UpsertTenderOptionsInput>;
};


export type MutationReorderTenderPlanTenderSequenceArgs = {
  tenderPlanId: Scalars['ID'];
  tenderSequences: ReadonlyArray<ReorderTenderPlanTenderSequenceInput>;
};


export type MutationToggleTenderSkipStatusArgs = {
  tenderPlanId: Scalars['ID'];
  tenderId: Scalars['ID'];
};


export type MutationCreateTenderPlanArgs = {
  routeId: Scalars['ID'];
  createdByUserId: Scalars['ID'];
};


export type MutationStartTenderPlanArgs = {
  tenderPlanId: Scalars['ID'];
  startedByUserId: Scalars['ID'];
};


export type MutationStopTenderPlanArgs = {
  tenderPlanId: Scalars['ID'];
  stoppedByUserId: Scalars['ID'];
};


export type MutationRecreateTenderPlanArgs = {
  routeId: Scalars['ID'];
  recreatedByUserId: Scalars['ID'];
};


export type MutationCreateOutboundTenderSettingsArgs = {
  outboundTenderSettingsInput?: InputMaybe<OutboundTenderSettingsInput>;
};


export type MutationUpdateOutboundTenderSettingsArgs = {
  updateOutboundTenderSettingsInput?: InputMaybe<UpdateOutboundTenderSettingsInput>;
};


export type MutationUpdateMultipleTenderOptionsArgs = {
  updateMultipleTenderOptionsInput?: InputMaybe<UpdateMultipleTenderOptionsInput>;
};


export type MutationSendToOpenBoardArgs = {
  routeId: Scalars['ID'];
  loadId: Scalars['ID'];
};


export type MutationCreateRoutingGuideV2Args = {
  routingGuide: CreateRoutingGuideInputV2;
};


export type MutationUpdateRoutingGuideArgs = {
  routingGuide: UpdateRoutingGuideInput;
};


export type MutationCreateRoutingGuideSequenceItemArgs = {
  routingGuideSequenceItem: CreateRoutingGuideSequenceItemInput;
};


export type MutationUpdateRoutingGuideSequenceItemArgs = {
  routingGuideSequenceItem: UpdateRoutingGuideSequenceItemInput;
};


export type MutationDeleteRoutingGuideSequenceItemArgs = {
  sequenceItemId: Scalars['ID'];
};


export type MutationReorderRoutingGuideSequenceItemsArgs = {
  routingGuideId: Scalars['ID'];
  routingGuideSequenceItems: ReadonlyArray<ReorderRoutingGuideSequenceItemsInput>;
};


export type MutationSaveRoutingGuideArgs = {
  routingGuide: SaveRoutingGuideInput;
};


export type MutationUploadRoutingGuidesArgs = {
  uploadRoutingGuidesInput: UploadRoutingGuidesInput;
};


export type MutationUploadRoutingGuidesV2Args = {
  input: UploadRoutingGuidesV2Input;
};


export type MutationApplyPatternArgs = {
  applyPatternInput: ApplyPatternInput;
};


export type MutationCreatePatternArgs = {
  patternInput: CreatePatternInput;
};


export type MutationDeletePatternArgs = {
  patternId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationReorderLanePatternsByPriorityArgs = {
  reorderLanePatternsByPriorityInput: ReorderLanePatternsByPriorityInput;
};


export type MutationSetPatternAutoApplyArgs = {
  setAutoApplyInput: SetAutoApplyInput;
};


export type MutationUpdatePatternArgs = {
  patternInput: UpdatePatternInput;
};


export type MutationApplyPatternV2Args = {
  applyPatternInput: ApplyPatternInput;
};


export type MutationCreatePatternV2Args = {
  patternInput: CreatePatternInput;
};


export type MutationDeletePatternV2Args = {
  deletePatternInput: DeletePatternInput;
};


export type MutationReorderLanePatternsByPriorityV2Args = {
  reorderLanePatternsByPriorityInput: ReorderLanePatternsByPriorityInput;
};


export type MutationSetPatternAutoApplyV2Args = {
  setAutoApplyInput: SetAutoApplyInput;
};


export type MutationUpdatePatternV2Args = {
  patternInput: UpdatePatternInput;
};


export type MutationApplyPatternV3Args = {
  applyPatternInputV2: ApplyPatternInputV2;
};


export type MutationCreateCarrierCapacityConstraintArgs = {
  carrierCapacityConstraintInput?: InputMaybe<CarrierCapacityConstraintInput>;
};


export type MutationUpdateCarrierCapacityConstraintArgs = {
  updateCarrierCapacityConstraintInput?: InputMaybe<UpdateCarrierCapacityConstraintInput>;
};


export type MutationSaveCustomerRatingSourceArgs = {
  customerRating: SaveCustomerRatingSourceInput;
};


export type MutationActivateAdvancePaymentArgs = {
  advance: AdvanceRestrictedActionInput;
};


export type MutationAddAutoAssociationUsersArgs = {
  userIds: ReadonlyArray<Scalars['ID']>;
};


export type MutationAddCustomerOrderRequiredDocumentsArgs = {
  customerOrderRequiredDocumentModels: ReadonlyArray<CustomerOrderRequiredDocumentCreateInputType>;
};


export type MutationAdvanceRefreshOverrideArgs = {
  advancesToOverride: ReadonlyArray<AdvanceRefreshOverrideInputType>;
};


export type MutationBatchRecreateInvoicesByInvoiceHeaderIdsArgs = {
  invoices: ReadonlyArray<InvoiceBatchDistributionInput>;
};


export type MutationBatchResendInvoicesByInvoiceHeaderIdsArgs = {
  invoices: ReadonlyArray<InvoiceBatchDistributionInput>;
};


export type MutationBlockAdvancePaymentArgs = {
  advance: AdvanceRestrictedActionInput;
};


export type MutationCancelAdvanceArgs = {
  advance: AdvanceRestrictedActionInput;
};


export type MutationCancelInvoiceBatchArgs = {
  batchParameters: BatchParametersInput;
};


export type MutationCancelVoucherBatchArgs = {
  batchParameters: BatchParametersInput;
};


export type MutationCloseAdvanceFromQueueArgs = {
  advance: AdvanceRestrictedActionInput;
};


export type MutationCopyGlobalVendorRequiredDocumentForCarrierArgs = {
  vendorId: Scalars['ID'];
};


export type MutationCreateAdjustmentInterfacesArgs = {
  adjustmentInterfaces: ReadonlyArray<AdjustmentInterfaceCreateInput>;
};


export type MutationCreateAdvanceArgs = {
  advance: AdvanceCreateInput;
};


export type MutationCreateAdvanceInterfacesArgs = {
  advanceInterfaces: ReadonlyArray<AdvanceInterfaceCreateInput>;
};


export type MutationCreateAdvanceProviderConfigArgs = {
  advanceProviderConfig: AdvanceProviderConfigCreateInputType;
};


export type MutationCreateAutoVoucherBatchArgs = {
  batch: AutoVoucherBatchInput;
};


export type MutationCreateBillToCodeArgs = {
  billToCodeModel: BillToCodeInput;
};


export type MutationCreateBillToCodesArgs = {
  billToCodeModels: ReadonlyArray<InputMaybe<BillToCodeInput>>;
};


export type MutationCreateCostDetailsArgs = {
  costDetails: ReadonlyArray<CostDetailAddInput>;
};


export type MutationCreateCustomerCreditMemoArgs = {
  invoiceId: Scalars['ID'];
};


export type MutationCreateCustomerOrderRequiredDocumentArgs = {
  customerOrderRequiredDocumentModel: CustomerOrderRequiredDocumentCreateInputType;
};


export type MutationCreateCustomerRequiredDocumentArgs = {
  customerRequiredDocument: CustomerRequiredDocumentCreateInput;
};


export type MutationCreateCustomerRequiredDocumentsArgs = {
  customerRequiredDocuments: ReadonlyArray<InputMaybe<CustomerRequiredDocumentCreateInput>>;
};


export type MutationCreateExchangeRateConfigurationArgs = {
  exchangeRateConfigurationModel: ExchangeRateConfigurationCreateInput;
};


export type MutationCreateInvoiceBatchArgs = {
  batch: InvoiceBatchCreateInput;
};


export type MutationCreateJurisdictionTaxArgs = {
  jurisdictionTaxModel: JurisdictionTaxInput;
};


export type MutationCreateOrUpdateExtractConfigurationArgs = {
  extractConfiguration: ExtractConfigurationInput;
};


export type MutationCreateOrUpdateIntegrationMappingArgs = {
  integrationMapping: IntegrationMappingInput;
};


export type MutationCreateOrUpdateSegmentationConfigurationsArgs = {
  segmentationConfigurations: ReadonlyArray<SegmentationConfigurationInput>;
};


export type MutationCreateOrUpdateVendorInvoiceArgs = {
  vendorInvoice: VendorInvoiceUpsertInput;
};


export type MutationCreateOrupdateVendorRequiredDocumentsArgs = {
  vendorRequiredDocuments: ReadonlyArray<InputMaybe<VendorRequiredDocumentUpdsetInput>>;
};


export type MutationCreateRateDetailsArgs = {
  rateDetails: ReadonlyArray<RateDetailAddInput>;
};


export type MutationCreateSegmentArgs = {
  segment: SegmentInput;
};


export type MutationCreateSegmentationConfigurationArgs = {
  segmentationConfiguration: SegmentationConfigurationInput;
};


export type MutationCreateTaxabilityChargeTypeArgs = {
  taxabilityChargeTypeModel: TaxabilityChargeType;
};


export type MutationCreateTaxInterfacesArgs = {
  taxInterfaces: ReadonlyArray<TaxInterfaceCreateInput>;
};


export type MutationCreateVendorAssociatedRequiredDocumentArgs = {
  vendorAssociatedRequiredDocument: VendorAssociatedRequiredDocumentCreateInput;
};


export type MutationCreateVendorAssociatedRequiredDocumentsArgs = {
  vendorAssociatedRequiredDocuments: ReadonlyArray<VendorAssociatedRequiredDocumentCreateInput>;
};


export type MutationCreateVendorCreditMemoArgs = {
  voucherId: Scalars['ID'];
};


export type MutationCreateVendorInvoiceArgs = {
  vendorInvoice: VendorInvoiceInput;
};


export type MutationCreateVendorInvoiceVouchersArgs = {
  vendorInvoiceVouchers: ReadonlyArray<VendorInvoiceVoucherInput>;
};


export type MutationCreateVendorRequiredDocumentArgs = {
  vendorRequiredDocument: VendorRequiredDocumentCreateInput;
};


export type MutationCreateVendorRequiredDocumentsArgs = {
  vendorRequiredDocuments: ReadonlyArray<InputMaybe<VendorRequiredDocumentCreateInput>>;
};


export type MutationCreateVoucherBatchArgs = {
  batch: VoucherInvoiceBatchCreateInput;
};


export type MutationCreateVoucherToleranceArgs = {
  voucherTolerance: VoucherToleranceCreateInput;
};


export type MutationCreateVoucherVendorToleranceArgs = {
  voucherVendorTolerance: VoucherVendorToleranceCreateInput;
};


export type MutationDeleteBillToCodeArgs = {
  billToCodeId: Scalars['ID'];
};


export type MutationDeleteCostDetailArgs = {
  costDetailId: Scalars['ID'];
};


export type MutationDeleteCustomerRequiredDocumentArgs = {
  customerRequiredDocumentId: Scalars['ID'];
};


export type MutationDeleteExchangeRateConfigurationArgs = {
  exchangeRateConfigurationId: Scalars['ID'];
};


export type MutationDeleteExtractConfigurationArgs = {
  extractConfigurationId: Scalars['Uuid'];
};


export type MutationDeleteIntegrationMappingArgs = {
  integrationMappingId: Scalars['Uuid'];
};


export type MutationDeleteJurisdictionTaxArgs = {
  jurisdictionTaxId: Scalars['ID'];
};


export type MutationDeleteRateDetailArgs = {
  rateDetailId: Scalars['ID'];
};


export type MutationDeleteSegmentArgs = {
  segmentId: Scalars['ID'];
};


export type MutationDeleteSegmentationConfigurationArgs = {
  segmentationConfigurationId: Scalars['ID'];
};


export type MutationDeleteTaxabilityChargeTypeArgs = {
  taxabilityChargeTypeId: Scalars['ID'];
};


export type MutationDeleteVendorAssociatedRequiredDocumentArgs = {
  vendorAssociatedRequiredDocumentId: Scalars['ID'];
};


export type MutationDeleteVendorInvoiceArgs = {
  vendorInvoiceId: Scalars['ID'];
};


export type MutationDeleteVendorInvoiceVoucherArgs = {
  vendorInvoiceVoucherId: Scalars['ID'];
};


export type MutationDeleteVendorRequiredDocumentArgs = {
  vendorRequiredDocumentId: Scalars['ID'];
};


export type MutationDeleteVoucherToleranceArgs = {
  voucherToleranceId: Scalars['ID'];
};


export type MutationDeleteVoucherVendorToleranceArgs = {
  voucherVendorToleranceId: Scalars['ID'];
};


export type MutationForceCreateRateDetailsArgs = {
  rateDetails: ReadonlyArray<RateDetailAddInput>;
};


export type MutationIssueAdvanceArgs = {
  advance: AdvanceRestrictedActionInput;
};


export type MutationLoadRepeatCreateCostDetailsArgs = {
  loadRepeatCreateCostDetailsInput: LoadRepeatCreateCostDetails;
};


export type MutationLoadRepeatCreateRateDetailsArgs = {
  loadRepeatCreateRateDetailsInput: LoadRepeatCreateRateDetails;
};


export type MutationMergeBlumeCostDetailsArgs = {
  mergeBlumeCostDetailsInput: MergeBlumeCostDetails;
};


export type MutationMergeCostDetailsV2Args = {
  mergeCostDetailsInput: MergeCostDetailsInput;
};


export type MutationMergeRateDetailsV3Args = {
  mergeRateDetailsInput: MergeRateDetailsInput;
};


export type MutationPerformAutoVoucherBatchArgs = {
  batch: AutoVoucherBatchInput;
};


export type MutationProcessCustomerCreditMemoArgs = {
  creditMemo: CustomerCreditMemoInput;
};


export type MutationProcessInvoiceBatchArgs = {
  batch: InvoiceBatchProcessingInput;
};


export type MutationProcessPartialVoucherBatchArgs = {
  batch: PartialVoucherBatchProcessingInput;
};


export type MutationProcessVendorCreditMemoArgs = {
  creditMemo: VendorCreditMemoInput;
};


export type MutationProcessVoucherBatchArgs = {
  batch: VoucherBatchProcessingInput;
};


export type MutationRecreateInvoiceToCustomerByInvoiceHeaderIdArgs = {
  externalEmail?: InputMaybe<Scalars['String']>;
  invoiceDistributionMethod: Scalars['String'];
  invoiceId: Scalars['ID'];
};


export type MutationRemoveAutoAssociationUserArgs = {
  userId: Scalars['ID'];
};


export type MutationRemoveCustomerOrderRequiredDocumentArgs = {
  requiredDocumentId: Scalars['ID'];
};


export type MutationResendInvoiceToCustomerByInvoiceHeaderIdArgs = {
  externalEmail: Scalars['String'];
  invoiceDistributionMethod: Scalars['String'];
  invoiceId: Scalars['ID'];
};


export type MutationSetAdvanceStatusCashedArgs = {
  loadId: Scalars['ID'];
};


export type MutationSetDefaultChargeTypeForCountryArgs = {
  country: Scalars['String'];
  createdBy?: InputMaybe<Scalars['String']>;
};


export type MutationSetDefaultChargeTypeForGivenCountryArgs = {
  country: Scalars['String'];
  defaultTaxability?: InputMaybe<Scalars['Boolean']>;
  taxability?: InputMaybe<Scalars['String']>;
};


export type MutationSetSegmentationConfigurationIsFinalizedArgs = {
  isFinalized: Scalars['Boolean'];
  segmentationConfigurationId: Scalars['ID'];
};


export type MutationSetSegmentValuesArgs = {
  segmentValues: SegmentValues;
};


export type MutationSynchronizeCustomersWithMasteryAccountingArgs = {
  customerIds: ReadonlyArray<Scalars['ID']>;
};


export type MutationSynchronizeVendorsWithMasteryAccountingArgs = {
  vendorIds: ReadonlyArray<Scalars['ID']>;
};


export type MutationUpdateAccountingExceptionsArgs = {
  accountingExceptions: ReadonlyArray<AccountingExceptionUpdateInput>;
};


export type MutationUpdateAdjustmentInterfacesArgs = {
  adjustmentInterfaces: ReadonlyArray<AdjustmentInterfaceUpdateInput>;
};


export type MutationUpdateAdvanceBasicArgs = {
  advance: AdvanceUpdateBasic;
};


export type MutationUpdateAdvanceInterfacesArgs = {
  advanceInterfaces: ReadonlyArray<AdvanceInterfaceUpdateInput>;
};


export type MutationUpdateAdvanceProviderConfigArgs = {
  advanceProviderConfig: AdvanceProviderConfigCreateUpdateType;
};


export type MutationUpdateAdvanceRestrictedArgs = {
  advance: AdvanceUpdateRestrictedInput;
};


export type MutationUpdateBillToCodeArgs = {
  billToCodeModel: BillToCodeInput;
};


export type MutationUpdateBillToCodesArgs = {
  billToCodeModels: ReadonlyArray<InputMaybe<BillToCodeInput>>;
};


export type MutationUpdateCalculateVatCheckedArgs = {
  checkedOption: CalculateVatCheckedOptionEnumOutputType;
};


export type MutationUpdateCarrierAccountingDetailsArgs = {
  accountingStatus: Scalars['String'];
  carrierId: Scalars['ID'];
};


export type MutationUpdateChargeTypeGlConfigurationArgs = {
  chargeType: ChargeTypeModelInput;
};


export type MutationUpdateCostDetailsArgs = {
  costDetails: ReadonlyArray<CostDetailUpdateInput>;
};


export type MutationUpdateCreateAdjustmentItemsCheckedArgs = {
  createAdjustmentItemsCheckedOption: CreateAdjustmentItemsCheckedOptionEnum;
};


export type MutationUpdateCustomerAccountingDetailsArgs = {
  accountingStatus: Scalars['String'];
  customerId: Scalars['ID'];
  isTaxExempt: Scalars['Boolean'];
};


export type MutationUpdateCustomerAutoInvoiceArgs = {
  customerId: Scalars['ID'];
  isAutoInvoiceEnabled: Scalars['Boolean'];
};


export type MutationUpdateCustomerCreditMemoArgs = {
  creditMemo: CustomerCreditMemoInput;
};


export type MutationUpdateCustomerOrderRequiredDocumentsArgs = {
  customerOrderRequiredDocuments: ReadonlyArray<CustomerOrderRequiredDocumentUpdateInput>;
};


export type MutationUpdateCustomerRequiredDocumentArgs = {
  customerRequiredDocument: CustomerRequiredDocumentUpdateInput;
};


export type MutationUpdateCustomerTaxExemptionArgs = {
  customerId: Scalars['ID'];
  isTaxExempt: Scalars['Boolean'];
};


export type MutationUpdateExchangeRateConfigurationArgs = {
  exchangeRateConfigurationModel: ExchangeRateConfigurationUpdateInputType;
};


export type MutationUpdateJurisdictionTaxArgs = {
  jurisdictionTaxModel: JurisdictionTaxInput;
};


export type MutationUpdateOrderInvoiceRequirementArgs = {
  bypass: Scalars['Boolean'];
  orderId: Scalars['ID'];
};


export type MutationUpdateOrderTaxRequirementsArgs = {
  orderId: Scalars['ID'];
  taxExempt: Scalars['Boolean'];
};


export type MutationUpdateRateDetailsArgs = {
  rateDetails: ReadonlyArray<RateDetailUpdateInput>;
};


export type MutationUpdateRouteMaxCostArgs = {
  routeMaxCost: RouteMaxCostInputType;
};


export type MutationUpdateRouteVendorThresholdsArgs = {
  routeId: Scalars['ID'];
  vendorId: Scalars['ID'];
};


export type MutationUpdateRouteVendorVoucherDisableFinancialsArgs = {
  disableFinancials: Scalars['Boolean'];
  routeId: Scalars['ID'];
  vendorId: Scalars['ID'];
};


export type MutationUpdateRouteVendorVoucherRequirementArgs = {
  bypass: Scalars['Boolean'];
  routeId: Scalars['ID'];
  vendorId: Scalars['ID'];
};


export type MutationUpdateSegmentArgs = {
  segment: SegmentInput;
};


export type MutationUpdateSegmentationConfigurationArgs = {
  segmentationConfiguration: SegmentationConfigurationInput;
};


export type MutationUpdateTaxabilityChargeTypeArgs = {
  taxabilityChargeTypeModel: TaxabilityChargeType;
};


export type MutationUpdateTaxInterfacesArgs = {
  taxInterfaces: ReadonlyArray<TaxInterfaceUpdateInput>;
};


export type MutationUpdateVendorAssociatedRequiredDocumentArgs = {
  vendorAssociatedRequiredDocument: VendorAssociatedRequiredDocumentUpdateInput;
};


export type MutationUpdateVendorAutoVoucherArgs = {
  isAutoVoucherDisabled: Scalars['Boolean'];
  vendorId: Scalars['ID'];
};


export type MutationUpdateVendorCreditMemoArgs = {
  creditMemo: VendorCreditMemoInput;
};


export type MutationUpdateVendorInvoiceArgs = {
  vendorInvoice: VendorInvoiceInput;
};


export type MutationUpdateVendorInvoiceVoucherArgs = {
  vendorInvoiceVoucher: VendorInvoiceVoucherInput;
};


export type MutationUpdateVendorRequiredDocumentArgs = {
  vendorRequiredDocument: VendorRequiredDocumentUpdateInput;
};


export type MutationUpdateVendorRequiredDocumentsArgs = {
  vendorRequiredDocuments: ReadonlyArray<InputMaybe<VendorRequiredDocumentUpdateInput>>;
};


export type MutationUpdateVendorRouteRequiredDocumentsArgs = {
  vendorRouteRequiredDocuments: ReadonlyArray<VendorRouteRequiredDocumentUpdateInput>;
};


export type MutationUpdateVendorUseGlobalToggleArgs = {
  isUseGlobal: Scalars['Boolean'];
  vendorId: Scalars['ID'];
};


export type MutationUpdateVoucherDateDefaultArgs = {
  defaultVoucherDateOption: DefaultVoucherDateOptionEnum;
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateVoucherToleranceArgs = {
  voucherTolerance: VoucherToleranceUpdateInput;
};


export type MutationUpdateVoucherVendorToleranceArgs = {
  voucherVendorTolerance: VoucherVendorToleranceUpdateInput;
};

export type NestedCarrierSettingInput = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly defaultDestinationDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly defaultDestinationGeographyId?: InputMaybe<Scalars['ID']>;
  readonly defaultDestinationGeographySource?: InputMaybe<Scalars['String']>;
  readonly defaultDestinationGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly defaultDestinationText?: InputMaybe<Scalars['String']>;
  readonly defaultOriginDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly defaultTrailerHeight?: InputMaybe<Scalars['Float']>;
  readonly defaultTrailerHeightUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTrailerLength?: InputMaybe<Scalars['Float']>;
  readonly defaultTrailerLengthUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTrailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly defaultTrailerWidth?: InputMaybe<Scalars['Float']>;
  readonly defaultTrailerWidthUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTruckType?: InputMaybe<CarrierTruckTypeEnum>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly intrastate?: InputMaybe<Scalars['Boolean']>;
};

export type NestedCarrierSettingInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly defaultTrailerLength?: InputMaybe<Scalars['Float']>;
  readonly defaultTrailerWidth?: InputMaybe<Scalars['Float']>;
  readonly defaultTrailerHeight?: InputMaybe<Scalars['Float']>;
  readonly defaultDestinationText?: InputMaybe<Scalars['String']>;
  readonly defaultDestinationGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly defaultDestinationGeographySource?: InputMaybe<Scalars['String']>;
  readonly defaultDestinationGeographyId?: InputMaybe<Scalars['ID']>;
  readonly defaultTrailerLengthUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTrailerWidthUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTrailerHeightUnit?: InputMaybe<Scalars['String']>;
  readonly defaultTrailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly defaultOriginDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly defaultDestinationDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly intrastate?: InputMaybe<Scalars['Boolean']>;
  readonly defaultTruckType?: InputMaybe<CarrierTruckTypeEnum>;
};

export type NextCarrierInteractionDate = {
  readonly __typename?: 'nextCarrierInteractionDate';
  /** @deprecated Deprecated. Use NextInteractionDateNullable instead */
  readonly nextInteractionDate: Scalars['DateTime'];
  readonly nextInteractionDateNullable?: Maybe<Scalars['DateTime']>;
};

export type NextCustomerInteractionDate = {
  readonly __typename?: 'nextCustomerInteractionDate';
  /** @deprecated Deprecated. Use NextInteractionDateNullable instead */
  readonly nextInteractionDate: Scalars['DateTime'];
  readonly nextInteractionDateNullable?: Maybe<Scalars['DateTime']>;
};

export type Note = {
  readonly __typename?: 'Note';
  readonly createdAt: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly noteType: NoteTypeEnum;
  readonly noteableId: Scalars['ID'];
  readonly noteableType: Scalars['String'];
  readonly text?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['Iso8601Date'];
};

export type NoteDefault = {
  readonly __typename?: 'NoteDefault';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['String'];
  readonly customerLoadNote?: Maybe<Scalars['String']>;
  readonly rateConfirmNote?: Maybe<Scalars['String']>;
  readonly invoiceNote?: Maybe<Scalars['String']>;
  readonly note?: Maybe<Scalars['String']>;
  readonly noteType?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly noteDefaultDivision?: Maybe<ReadonlyArray<NoteDefaultDivision>>;
  readonly noteDefaultBusinessUnit?: Maybe<ReadonlyArray<NoteDefaultBusinessUnit>>;
};

export type NoteDefaultBusinessUnit = {
  readonly __typename?: 'NoteDefaultBusinessUnit';
  readonly id: Scalars['ID'];
  readonly noteDefaultId: Scalars['String'];
  readonly businessUnit: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly noteDefault?: Maybe<NoteDefault>;
};

export type NoteDefaultDivision = {
  readonly __typename?: 'NoteDefaultDivision';
  readonly id: Scalars['ID'];
  readonly noteDefaultId: Scalars['String'];
  readonly division: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly noteDefault?: Maybe<NoteDefault>;
};

export type NoteInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly noteType?: InputMaybe<NoteTypeEnum>;
  readonly noteableId?: InputMaybe<Scalars['ID']>;
  readonly text?: InputMaybe<Scalars['String']>;
};

export type NoteInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly noteableId?: InputMaybe<Scalars['ID']>;
  readonly noteType?: InputMaybe<NoteTypeEnumV2>;
  readonly text?: InputMaybe<Scalars['String']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
};

export enum NoteTypeEnum {
  CarrierAccounting = 'carrierAccounting',
  CarrierConfirmation = 'carrierConfirmation',
  CarrierCustomer = 'carrierCustomer',
  CustomerAccounting = 'customerAccounting',
  ExternalFacility = 'externalFacility'
}

export enum NoteTypeEnumV2 {
  /** External Facility */
  ExternalFacility = 'externalFacility',
  /** Carrier Customer Notes */
  CarrierCustomer = 'carrierCustomer',
  /** Carrier Accounting */
  CarrierAccounting = 'carrierAccounting',
  /** Customer Accounting */
  CustomerAccounting = 'customerAccounting',
  /** Carrier Confirmation */
  CarrierConfirmation = 'carrierConfirmation'
}

/** Notifications */
export enum NotificationChannels {
  Email = 'EMAIL',
  Slack = 'SLACK',
  Msteams = 'MSTEAMS'
}

export type NotificationEntity = {
  readonly __typename?: 'NotificationEntity';
  readonly id: Scalars['ID'];
  readonly sendDate: Scalars['DateTime'];
  readonly messages?: Maybe<ReadonlyArray<NotificationUnion>>;
};

export type NotificationFilter = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly referenceId?: InputMaybe<Scalars['String']>;
  readonly fromDate?: InputMaybe<Scalars['DateTime']>;
  readonly toDate?: InputMaybe<Scalars['DateTime']>;
};

export enum NotificationType {
  Email = 'EMAIL',
  Text = 'TEXT'
}

export type NotificationUnion = Email | TextEntity;

export type Offer = {
  readonly __typename?: 'Offer';
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['DateTime'];
  /** @deprecated Use `createdBy`. */
  readonly createdByUser: User;
  readonly createdBy: User;
  readonly createdByV2: UserV2;
  readonly updatedAt: Scalars['DateTime'];
  /** @deprecated Use `updatedBy`. */
  readonly updatedByUser: User;
  readonly updatedBy: User;
  readonly updatedByV2: UserV2;
  readonly status: OfferStatus;
  readonly source: OfferSource;
  readonly type: OfferType;
  readonly reason?: Maybe<OfferReason>;
  readonly routeId: Scalars['ID'];
  readonly route: LoadRoute;
  readonly routeV2: LoadRouteV2;
  /** @deprecated Use `vendorV2`. */
  readonly vendor: Carrier;
  readonly vendorV2?: Maybe<CarrierV2>;
  readonly trailerTypeId?: Maybe<Scalars['ID']>;
  readonly trailerLength?: Maybe<Length>;
  readonly trailerWidth?: Maybe<Length>;
  readonly trailerHeight?: Maybe<Length>;
  /** @deprecated Use `trailerLength`, `trailerWidth`, and `trailerHeight`. */
  readonly trailerDimensions?: Maybe<Dimensions>;
  readonly trailerOwner?: Maybe<Carrier>;
  readonly trailerOwnerV2?: Maybe<CarrierV2>;
  /** @deprecated Use originDeadheadV2. */
  readonly originDeadhead: Length;
  readonly originDeadheadV2?: Maybe<Length>;
  /** @deprecated Use priceV2. */
  readonly price: Currency;
  readonly priceV2?: Maybe<Currency>;
  readonly offerPrice?: Maybe<CurrencyV2>;
  readonly askPrice?: Maybe<Currency>;
  readonly askPriceV2?: Maybe<CurrencyV2>;
  readonly note?: Maybe<Scalars['String']>;
  readonly emptyDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use emptyLocationV2 */
  readonly emptyLocation?: Maybe<Address>;
  readonly emptyLocationData?: Maybe<EmptyLocation>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly timezone?: Maybe<Scalars['String']>;
  readonly emptyLocationV2?: Maybe<Geography>;
  readonly response?: Maybe<OfferResponse>;
  readonly errors?: Maybe<VendorValidation>;
  readonly deletedAt?: Maybe<Scalars['DateTime']>;
  readonly vendorValidationErrors?: Maybe<ReadonlyArray<Scalars['String']>>;
};


export type OfferTrailerLengthArgs = {
  unit: UnitOfLengthEnum;
};


export type OfferTrailerWidthArgs = {
  unit: UnitOfLengthEnum;
};


export type OfferTrailerHeightArgs = {
  unit: UnitOfLengthEnum;
};


export type OfferTrailerDimensionsArgs = {
  unit: UnitOfLengthEnum;
};


export type OfferOriginDeadheadArgs = {
  unit: UnitOfLengthEnum;
};


export type OfferOriginDeadheadV2Args = {
  unit: UnitOfLengthEnum;
};


export type OfferPriceArgs = {
  unit?: InputMaybe<UnitOfCurrencyEnum>;
};


export type OfferPriceV2Args = {
  unit?: InputMaybe<UnitOfCurrencyEnum>;
};


export type OfferOfferPriceArgs = {
  unit?: InputMaybe<UnitOfCurrencyEnumV2>;
};


export type OfferAskPriceArgs = {
  unit?: InputMaybe<UnitOfCurrencyEnum>;
};


export type OfferAskPriceV2Args = {
  unit?: InputMaybe<UnitOfCurrencyEnumV2>;
};

export type OfferConnection = {
  readonly __typename?: 'OfferConnection';
  readonly edges: ReadonlyArray<EdgeClass>;
  readonly pageInfo: PageInfo;
};

export type OfferPrice = {
  readonly __typename?: 'OfferPrice';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export enum OfferReason {
  OverMaxCost = 'OVER_MAX_COST',
  SchedulingConflict = 'SCHEDULING_CONFLICT',
  EquipmentType = 'EQUIPMENT_TYPE',
  WeightIssue = 'WEIGHT_ISSUE',
  Other = 'OTHER'
}

export type OfferResponse = {
  readonly __typename?: 'OfferResponse';
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['DateTime'];
  /** @deprecated Use `createdBy`. */
  readonly createdByUser: User;
  readonly createdBy: User;
  readonly createdByV2: UserV2;
  readonly updatedAt: Scalars['DateTime'];
  /** @deprecated Use `updatedBy`. */
  readonly updatedByUser: User;
  readonly updatedBy: User;
  readonly updatedByV2: UserV2;
  readonly type: Scalars['String'];
  /** @deprecated Use `counterPriceV2`. */
  readonly counterPrice?: Maybe<Currency>;
  readonly counterPriceV2?: Maybe<CurrencyV2>;
  readonly note?: Maybe<Scalars['String']>;
  readonly offer: Offer;
};


export type OfferResponseCounterPriceArgs = {
  unit?: InputMaybe<UnitOfCurrencyEnum>;
};


export type OfferResponseCounterPriceV2Args = {
  unit?: InputMaybe<UnitOfCurrencyEnumV2>;
};

export enum OfferResponseType {
  Decline = 'DECLINE',
  Counter = 'COUNTER',
  Accept = 'ACCEPT'
}

export enum OfferSource {
  Rep = 'REP',
  Mobile = 'MOBILE',
  Web = 'WEB'
}

export enum OfferStatus {
  Pending = 'PENDING',
  Countered = 'COUNTERED',
  Declined = 'DECLINED',
  Accepted = 'ACCEPTED',
  Inactive = 'INACTIVE'
}

export enum OfferType {
  InactiveOffer = 'INACTIVE_OFFER',
  NoCapacity = 'NO_CAPACITY',
  NoAnswer = 'NO_ANSWER',
  ActiveOffer = 'ACTIVE_OFFER'
}

export enum OperatorType {
  /** == */
  Equals = 'EQUALS',
  /** != */
  DoesNotEqual = 'DOES_NOT_EQUAL',
  /** < */
  LessThan = 'LESS_THAN',
  /** <= */
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  /** > */
  GreaterThan = 'GREATER_THAN',
  /** >= */
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  /** gtDaysAgo */
  GreaterThanDaysAgo = 'GREATER_THAN_DAYS_AGO',
  /** gteDaysAgo */
  GreaterThanOrEqualToDaysAgo = 'GREATER_THAN_OR_EQUAL_TO_DAYS_AGO',
  /** ltDaysAgo */
  LessThanDaysAgo = 'LESS_THAN_DAYS_AGO',
  /** lteDaysAgo */
  LessThanOrEqualToDaysAgo = 'LESS_THAN_OR_EQUAL_TO_DAYS_AGO',
  /** in */
  In = 'IN',
  /** notIn */
  NotIn = 'NOT_IN',
  /** isNull */
  IsNull = 'IS_NULL',
  /** notNull */
  NotNull = 'NOT_NULL'
}

export enum OpportunityTypeEnum {
  /** none_defined */
  NoneDefined = 'none_defined',
  /** Bronze */
  Bronze = 'Bronze',
  /** Silver */
  Silver = 'Silver',
  /** Gold */
  Gold = 'Gold',
  /** Platinum */
  Platinum = 'Platinum'
}

/** Represents an order that contains un-invoiced and invoiced items. */
export type Order = {
  readonly __typename?: 'Order';
  readonly accessorialChargeAmount?: Maybe<Scalars['Decimal']>;
  /**
   * List of all RateDetails having statuses
   * 'Saved', 'Pending', 'Processing', 'Processed', 'Error', 'CreditMemoing', 'CreditMemoed'.
   */
  readonly allRateDetails: ReadonlyArray<RateDetail>;
  readonly baseChargeAmount?: Maybe<Scalars['Decimal']>;
  readonly businessUnit?: Maybe<Scalars['String']>;
  readonly currencyInvoicedAmount?: Maybe<Scalars['Decimal']>;
  readonly currencyTotalAmount?: Maybe<Scalars['Decimal']>;
  readonly currencyUninvoicedAmount?: Maybe<Scalars['Decimal']>;
  readonly customer?: Maybe<Customer>;
  readonly customerId: Scalars['ID'];
  readonly customerV2?: Maybe<CustomerV2>;
  readonly finalizedFuelStatus?: Maybe<Scalars['String']>;
  readonly fuelAmount?: Maybe<Scalars['Decimal']>;
  readonly hasSeerSearchLimitReached?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  readonly invoicedAmount?: Maybe<Scalars['Decimal']>;
  readonly isInvoiceRequirementBypassed?: Maybe<Scalars['Boolean']>;
  readonly load?: Maybe<Load>;
  readonly loadV2?: Maybe<LoadV2>;
  readonly orderFinancialStatus?: Maybe<OrderFinancialStatusEnum>;
  readonly orderId: Scalars['ID'];
  readonly orderLifeCycle?: Maybe<OrderLifeCycleStatusEnum>;
  readonly otherChargeAmount?: Maybe<Scalars['Decimal']>;
  readonly paperWorkStatus?: Maybe<Scalars['String']>;
  readonly project?: Maybe<Scalars['String']>;
  /** List of all RateDetails having only 'Saved' status. */
  readonly rateDetails: ReadonlyArray<RateDetail>;
  readonly routeVendorStatuses?: Maybe<ReadonlyArray<RouteVendorStatus>>;
  readonly taxExempt?: Maybe<Scalars['Boolean']>;
  readonly totalAmount?: Maybe<Scalars['Decimal']>;
  readonly totalCost?: Maybe<Scalars['Decimal']>;
  readonly uninvoicedAmount?: Maybe<Scalars['Decimal']>;
  /** @deprecated Use RouteVendorStatuses instead */
  readonly vendorRouteFinancialStatus?: Maybe<VendorFinancialStatusEnum>;
  readonly accountingIncidentsV2: ReadonlyArray<IncidentV2>;
};

export type OrderAllocatedMargin = {
  readonly __typename?: 'OrderAllocatedMargin';
  readonly orderId: Scalars['ID'];
  readonly allocatedMargin?: Maybe<Scalars['Decimal']>;
  readonly grossAllocatedMargin?: Maybe<Scalars['Decimal']>;
  readonly customerOverheadAmount?: Maybe<Scalars['Decimal']>;
  readonly allocatedCompanyOverheadAmount?: Maybe<Scalars['Decimal']>;
  readonly orderRateAllocationPercent?: Maybe<Scalars['Decimal']>;
  readonly orderCostAllocationPercent?: Maybe<Scalars['Decimal']>;
  readonly grossOrderAllocatedRate?: Maybe<Scalars['Decimal']>;
  readonly grossOrderAllocatedCost?: Maybe<Scalars['Decimal']>;
  readonly distance?: Maybe<Scalars['Decimal']>;
  readonly distancePercent?: Maybe<Scalars['Decimal']>;
  readonly length?: Maybe<Scalars['Decimal']>;
  readonly lengthPercent?: Maybe<Scalars['Decimal']>;
  readonly weight?: Maybe<Scalars['Decimal']>;
  readonly weightPercent?: Maybe<Scalars['Decimal']>;
  readonly positions?: Maybe<Scalars['Decimal']>;
  readonly positionsPercent?: Maybe<Scalars['Decimal']>;
  readonly orderRateTotal?: Maybe<Scalars['Decimal']>;
  readonly orderCommissions?: Maybe<ReadonlyArray<Maybe<OrderCommission>>>;
  /** @deprecated Use the Order object */
  readonly orderCode: Scalars['String'];
  readonly customerId?: Maybe<Scalars['ID']>;
  /** @deprecated Use the Customer object */
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly order?: Maybe<CustomerOrder>;
};

export type OrderBraceTypes = {
  readonly __typename?: 'OrderBraceTypes';
  readonly option: KeyValue;
};

export type OrderCommission = {
  readonly __typename?: 'OrderCommission';
  readonly commissionAmount?: Maybe<Scalars['Decimal']>;
  readonly employeeId?: Maybe<Scalars['ID']>;
  readonly customerId?: Maybe<Scalars['ID']>;
  readonly customerRepTypeId?: Maybe<Scalars['String']>;
  readonly commissionPercent?: Maybe<Scalars['Decimal']>;
  readonly commissionBasisId?: Maybe<Scalars['String']>;
  readonly commissionBasisAmount?: Maybe<Scalars['Decimal']>;
  readonly marginBasisId?: Maybe<Scalars['String']>;
  readonly marginBasisPercent?: Maybe<Scalars['Decimal']>;
  readonly displayCommissionType?: Maybe<Scalars['String']>;
  readonly commissionBasisFlatAmount?: Maybe<Scalars['Decimal']>;
  readonly commissionBasisPercentAmount?: Maybe<Scalars['Decimal']>;
  readonly employee?: Maybe<Employee>;
  readonly employeeV2?: Maybe<EmployeeV2>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
};

/** A connection to a list of items. */
export type OrderConnection = {
  readonly __typename?: 'OrderConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<OrderEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Order>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type OrderEdge = {
  readonly __typename?: 'OrderEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Order>;
};

export enum OrderFinancialStatusEnum {
  Complete = 'COMPLETE',
  Exception = 'EXCEPTION',
  Incomplete = 'INCOMPLETE',
  Invoiced = 'INVOICED',
  None = 'NONE',
  Settled = 'SETTLED',
  /** @deprecated Field no longer supported */
  Unmapped = 'UNMAPPED',
  Vouchered = 'VOUCHERED',
  Awaitingpaperwork = 'AWAITINGPAPERWORK',
  Awaitingrequirements = 'AWAITINGREQUIREMENTS'
}

/** The input type for Order */
export type OrderInput = {
  readonly customerId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
};

export enum OrderLifeCycleStatusEnum {
  Assigned = 'ASSIGNED',
  Delivered = 'DELIVERED',
  Dispatched = 'DISPATCHED',
  None = 'NONE',
  Open = 'OPEN',
  /** @deprecated Field no longer supported */
  Unmapped = 'UNMAPPED',
  Arrivedstop = 'ARRIVEDSTOP',
  Departedstop = 'DEPARTEDSTOP',
  Driverassigned = 'DRIVERASSIGNED',
  InTransit = 'IN_TRANSIT'
}

export type OrderMatchedLane = {
  readonly __typename?: 'OrderMatchedLane';
  readonly laneId: Scalars['ID'];
  readonly laneType?: Maybe<Scalars['String']>;
};

export type OrderMatchedLanesInput = {
  readonly orderId: Scalars['ID'];
};

export type OrderOverride = {
  readonly __typename?: 'OrderOverride';
  readonly orderId: Scalars['ID'];
  readonly totalRate: Scalars['Decimal'];
};

export type OrderOverrideInput = {
  readonly orderId: Scalars['ID'];
  readonly totalRate: Scalars['Decimal'];
};

export type OrderRep = {
  readonly __typename?: 'OrderRep';
  readonly employee: Employee;
  readonly id: Scalars['ID'];
  readonly isLoadRep: Scalars['Boolean'];
  readonly main: Scalars['Boolean'];
};

export type OrderRequirement = {
  readonly __typename?: 'OrderRequirement';
  readonly option: KeyValue;
};

export type OrderRequirementInfo = {
  readonly __typename?: 'OrderRequirementInfo';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly metadataJson: Scalars['String'];
};

export type OrderRequirementInput = {
  readonly optionId: Scalars['ID'];
};

export type OrdersFilter = {
  readonly accountingStatuses?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly billToCustomerId?: InputMaybe<Scalars['ID']>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly carrierRepId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly customerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  /** Don't use this field, it has been deprecated */
  readonly customerName?: InputMaybe<Scalars['String']>;
  readonly customerRepId?: InputMaybe<Scalars['ID']>;
  readonly deliveryDateRange?: InputMaybe<DateRange>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly excludeVendorInvoiceMissing?: InputMaybe<Scalars['Boolean']>;
  readonly finalizedFuelStatuses?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly invoiceDistributionMethods?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly loadCode?: InputMaybe<Scalars['String']>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly orderCode?: InputMaybe<Scalars['String']>;
  readonly orderFinancialStatuses?: InputMaybe<ReadonlyArray<OrderFinancialStatusEnum>>;
  readonly orderLifeCycles?: InputMaybe<ReadonlyArray<OrderLifeCycleStatusEnum>>;
  readonly project?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly refCode?: InputMaybe<Scalars['String']>;
  readonly remitToVendorId?: InputMaybe<Scalars['ID']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly routeFinancialStatuses?: InputMaybe<ReadonlyArray<VendorFinancialStatusEnum>>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
};

/** lachesis-api: Order Status Record V3 - Features Multi-Route Functionality */
export type OrderStatusRecordV3 = {
  readonly __typename?: 'OrderStatusRecordV3';
  /** lachesis-api: ID associated with a Load. */
  readonly load_id: Scalars['ID'];
  /** lachesis-api: Timestamp when event occured */
  readonly event_timestamp: Scalars['Timestamp'];
  /** lachesis-api: ID of the user or process that initiated the event for status calculation */
  readonly initiator?: Maybe<Scalars['String']>;
  /** lachesis-api: ID of the order associated with the load */
  readonly order_id: Scalars['ID'];
  /** lachesis-api: ID of the client or process that initiated the event for status calculation */
  readonly tenant: Scalars['String'];
  /** lachesis-api: Timestamp when the status record was created */
  readonly created_at_timestamp: Scalars['Timestamp'];
  /** lachesis-api: Array of strings that details any supported reasons for current OAS. */
  readonly order_activation_status_reasons: ReadonlyArray<Scalars['String']>;
  /** lachesis-api: Array of strings that describes any supported reasons for the current order financial status. */
  readonly order_financial_status_reasons: ReadonlyArray<Scalars['String']>;
  /** lachesis-api: The current financial status of order. */
  readonly order_financial_status?: Maybe<Scalars['String']>;
  /** lachesis-api: The current status of the order tender. */
  readonly order_tender_status?: Maybe<Scalars['String']>;
  /** lachesis-api: The current order activation status. */
  readonly order_activation_status?: Maybe<Scalars['String']>;
  /** lachesis-api: The current state of the order lifecycle. Can change depending on current load state and events. */
  readonly order_lifecycle_status?: Maybe<Scalars['String']>;
};

export type OrderStop = {
  readonly __typename?: 'OrderStop';
  readonly id: Scalars['ID'];
  readonly facilityId: Scalars['ID'];
  readonly facility: Facility;
  readonly facilityV2: FacilityV2;
  readonly note?: Maybe<Scalars['String']>;
  readonly scheduleType?: Maybe<ScheduleAppointmentTypeEnum>;
  readonly liveType?: Maybe<ScheduleDropTypeEnum>;
  readonly workType?: Maybe<ScheduleWorkTypeEnum>;
  /** @deprecated use loadStopType as it accounts for multi-route stop types, if loadStopType is present and its value is not a StopTypeEnum, this value defaults to 'Pu' */
  readonly type: StopTypeEnum;
  readonly loadStopType?: Maybe<Scalars['String']>;
  readonly loadFrom?: Maybe<KeyValue>;
  readonly unloadFrom?: Maybe<KeyValue>;
  readonly reqDate?: Maybe<DatetimeWithTimezone>;
  readonly availableStart?: Maybe<DatetimeWithTimezone>;
  readonly availableStartTimes?: Maybe<ScheduleRange>;
  readonly availableEnd?: Maybe<DatetimeWithTimezone>;
  readonly availableEndTimes?: Maybe<ScheduleRange>;
  readonly requirements?: Maybe<ReadonlyArray<StopRequirement>>;
  readonly address?: Maybe<StopAddress>;
  readonly commodities: ReadonlyArray<CommodityV2>;
  readonly refs?: Maybe<ReadonlyArray<RefV2>>;
  readonly bolNumber?: Maybe<Scalars['String']>;
  readonly sealNumber?: Maybe<Scalars['String']>;
  readonly schedulingContactId?: Maybe<Scalars['ID']>;
  readonly schedulingContact?: Maybe<Contact>;
  readonly schedulingContactV2?: Maybe<CustomerContact>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly updatedByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly externalRef?: Maybe<Scalars['ID']>;
  readonly numberOfPallets?: Maybe<Scalars['Int']>;
  readonly distanceToNext?: Maybe<Length>;
};


export type OrderStopDistanceToNextArgs = {
  unit: UnitOfLengthEnum;
};

export type OrderStopError = {
  readonly __typename?: 'OrderStopError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

export type OrderStopInput = {
  readonly orderId: Scalars['ID'];
  readonly facilityId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly scheduleType?: InputMaybe<ScheduleAppointmentTypeEnum>;
  readonly liveType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly workType?: InputMaybe<ScheduleWorkTypeEnum>;
  readonly type?: InputMaybe<StopTypeEnum>;
  /**
   * value is validated against following options:
   *     crossDock, originRamp, destinationRamp, customsBroker, yard, PU, Del, TA, TD, domicile, maintenanceShop, scale, fuel, driverComplianceTraining, waypoint, railJunction, relay, terminal, seaPort, airport, containerDepot, borderCrossing, transload, trailerWash
   */
  readonly loadStopType?: InputMaybe<Scalars['String']>;
  readonly loadFromId?: InputMaybe<Scalars['ID']>;
  readonly unloadFromId?: InputMaybe<Scalars['ID']>;
  readonly requirements?: InputMaybe<ReadonlyArray<StopRequirementInput>>;
  readonly commodities?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly refs: ReadonlyArray<RefInputV2>;
  readonly reqDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly availableEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly bolNumber?: InputMaybe<Scalars['String']>;
  readonly sealNumber?: InputMaybe<Scalars['String']>;
  readonly schedulingContactId?: InputMaybe<Scalars['ID']>;
  readonly externalRef?: InputMaybe<Scalars['String']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
};

export type OrderStopPayload = {
  readonly __typename?: 'OrderStopPayload';
  readonly errors: ReadonlyArray<OrderStopError>;
  readonly orderStop?: Maybe<OrderStop>;
};

export type OrderStopV2 = {
  readonly __typename?: 'OrderStopV2';
  readonly id: Scalars['ID'];
  readonly facilityId?: Maybe<Scalars['ID']>;
  readonly facility?: Maybe<Facility>;
  readonly facilityV2?: Maybe<FacilityV2>;
  readonly note?: Maybe<Scalars['String']>;
  readonly scheduleType?: Maybe<ScheduleAppointmentTypeEnum>;
  readonly liveType?: Maybe<ScheduleDropTypeEnum>;
  readonly workType?: Maybe<ScheduleWorkTypeEnum>;
  /** @deprecated use loadStopType as it accounts for multi-route stop types, if loadStopType is present and its value is not a StopTypeEnum, this value defaults to 'Pu' */
  readonly type: StopTypeEnum;
  readonly loadStopType?: Maybe<Scalars['String']>;
  readonly loadFrom?: Maybe<KeyValue>;
  readonly unloadFrom?: Maybe<KeyValue>;
  readonly reqDate?: Maybe<DatetimeWithTimezone>;
  readonly availableStart?: Maybe<DatetimeWithTimezone>;
  readonly availableStartTimes?: Maybe<ScheduleRange>;
  readonly availableEnd?: Maybe<DatetimeWithTimezone>;
  readonly availableEndTimes?: Maybe<ScheduleRange>;
  readonly requirements?: Maybe<ReadonlyArray<StopRequirement>>;
  readonly address?: Maybe<StopAddressV2>;
  readonly commodities: ReadonlyArray<CommodityV2>;
  readonly refs?: Maybe<ReadonlyArray<RefV2>>;
  readonly bolNumber?: Maybe<Scalars['String']>;
  readonly sealNumber?: Maybe<Scalars['String']>;
  readonly schedulingContactId?: Maybe<Scalars['ID']>;
  readonly schedulingContact?: Maybe<Contact>;
  readonly schedulingContactV2?: Maybe<CustomerContact>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly updatedByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly externalRef?: Maybe<Scalars['ID']>;
  readonly numberOfPallets?: Maybe<Scalars['Int']>;
  readonly distanceToNext?: Maybe<Length>;
};


export type OrderStopV2DistanceToNextArgs = {
  unit: UnitOfLengthEnum;
};

/** This type is only a stop gap until Kafka is fully implemented. */
export type OrphanedEntitiesModel = {
  readonly __typename?: 'OrphanedEntitiesModel';
  readonly advancesToBeRemoved?: Maybe<ReadonlyArray<Maybe<Advance>>>;
  readonly costDetailsToBeRemoved?: Maybe<ReadonlyArray<Maybe<CostDetail>>>;
  readonly invoiceBatchesToBeRemoved?: Maybe<ReadonlyArray<Maybe<InvoiceBatch>>>;
  readonly invoiceHeadersToBeRemoved?: Maybe<ReadonlyArray<Maybe<InvoiceHeader>>>;
  readonly orphanedCustomerIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly orphanedLoadIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly orphanedOrderIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly orphanedRouteIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly orphanedVendorIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly rateDetailsToBeRemoved?: Maybe<ReadonlyArray<Maybe<RateDetail>>>;
  readonly voucherBatchesToBeRemoved?: Maybe<ReadonlyArray<Maybe<VoucherBatch>>>;
  readonly voucherHeadersToBeRemoved?: Maybe<ReadonlyArray<Maybe<VoucherHeader>>>;
};

export type OutboundTenderSettings = {
  readonly __typename?: 'OutboundTenderSettings';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly tenderAppointmentStatusTerm?: Maybe<Scalars['String']>;
  readonly automatedTendering?: Maybe<Scalars['Boolean']>;
  readonly rolloverTenderTime?: Maybe<Scalars['Boolean']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly lastUpdatedByUser?: Maybe<UserV2>;
  readonly customer?: Maybe<CustomerV2>;
};

export type OutboundTenderSettingsInput = {
  readonly customerId: Scalars['ID'];
  readonly tenderAppointmentStatusTerm: Scalars['String'];
  readonly automatedTendering: Scalars['Boolean'];
  readonly rolloverTenderTime: Scalars['Boolean'];
  readonly requestedById: Scalars['ID'];
};

export enum OutdatedFallbackBehavior {
  BestEffort = 'BestEffort',
  Fail = 'Fail'
}

export type OwnerDetails = {
  readonly __typename?: 'OwnerDetails';
  readonly id?: Maybe<Scalars['ID']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  readonly __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  readonly endCursor?: Maybe<Scalars['String']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  readonly hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  readonly hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  readonly startCursor?: Maybe<Scalars['String']>;
};

export type PageInfoDetails = {
  readonly __typename?: 'PageInfoDetails';
  readonly startCursor?: Maybe<Scalars['String']>;
  readonly endCursor?: Maybe<Scalars['String']>;
  readonly hasNextPage: Scalars['Boolean'];
  readonly hasPreviousPage: Scalars['Boolean'];
};

export type PaginationFilter = {
  readonly first?: InputMaybe<Scalars['Int']>;
  readonly last?: InputMaybe<Scalars['Int']>;
  readonly before?: InputMaybe<GeographyServiceInput>;
  readonly after?: InputMaybe<GeographyServiceInput>;
};

export type PaginationInput = {
  readonly first?: InputMaybe<Scalars['Int']>;
  readonly last?: InputMaybe<Scalars['Int']>;
  readonly before?: InputMaybe<Scalars['String']>;
  readonly after?: InputMaybe<Scalars['String']>;
};

export type PaginationQuery = {
  readonly skip?: InputMaybe<Scalars['Int']>;
  readonly limit?: InputMaybe<Scalars['Int']>;
};

export enum PalletTypeEnum {
  /** Chep */
  Chep = 'chep',
  /** Disposable */
  Disposable = 'disposable',
  /** None */
  None = 'none',
  /** Peco */
  Peco = 'peco',
  /** Pico */
  Pico = 'pico',
  /** Standard */
  Standard = 'standard'
}

export enum PalletTypesEnum {
  Standard = 'standard',
  Chep = 'chep',
  Peco = 'peco',
  Disposable = 'disposable'
}

export type ParametersDat = {
  readonly haversineCoefficient: Scalars['Float'];
};

export type ParentRelationship = {
  readonly __typename?: 'ParentRelationship';
  readonly id: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly carrierParentId: Scalars['ID'];
  readonly carrierParent?: Maybe<CarrierV2>;
  readonly createdAt?: Maybe<Scalars['Iso8601Date']>;
  readonly updatedAt?: Maybe<Scalars['Iso8601Date']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
};

/** The Partial Voucher batch processing input type.  Used to process single partial Voucher */
export type PartialVoucherBatchProcessingInput = {
  readonly addAdjustments?: InputMaybe<Scalars['Boolean']>;
  readonly batchId?: InputMaybe<Scalars['ID']>;
  readonly costDetailsForProcessing: ReadonlyArray<CostDetailProcessingInput>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly vendorInvoiceForProcessing: ReadonlyArray<VendorInvoiceProcessingInput>;
  readonly voucherDateOption?: InputMaybe<DefaultVoucherDateOptionEnum>;
  readonly voucherOverrides?: InputMaybe<VoucherOverridesInput>;
};

export type PartialVoucherModel = {
  readonly __typename?: 'PartialVoucherModel';
  readonly routeVendorModel: RouteVendorCost;
  readonly vendorInvoices: ReadonlyArray<VendorInvoice>;
};

export type PathDat = {
  readonly __typename?: 'PathDat';
  readonly location?: Maybe<Scalars['String']>;
  readonly serviceMinutes?: Maybe<Scalars['Int']>;
  readonly lowerWindow?: Maybe<Scalars['String']>;
  readonly upperWindow?: Maybe<Scalars['String']>;
  readonly latitude?: Maybe<Scalars['Float']>;
  readonly longitude?: Maybe<Scalars['Float']>;
  readonly milesToNext?: Maybe<Scalars['Float']>;
  readonly minutesToNext?: Maybe<Scalars['Float']>;
  readonly mph?: Maybe<Scalars['Float']>;
  readonly leg?: Maybe<Scalars['Int']>;
};

export type PathEntryInput = {
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export type Pattern = {
  readonly __typename?: 'Pattern';
  readonly id: Scalars['ID'];
  readonly laneId?: Maybe<Scalars['ID']>;
  readonly patternPriority?: Maybe<Scalars['Int']>;
  readonly patternName?: Maybe<Scalars['String']>;
  readonly numberOfRoutes?: Maybe<Scalars['Int']>;
  readonly numberOfStops?: Maybe<Scalars['Int']>;
  readonly patternRoutes?: Maybe<ReadonlyArray<Maybe<PatternRoute>>>;
  readonly minimumPickupLeadTime?: Maybe<Scalars['Int']>;
  /** @deprecated Use eligibleDaysV2 */
  readonly eligibleDays?: Maybe<ReadonlyArray<DayOfWeekEnum>>;
  readonly eligibleDaysV2?: Maybe<ReadonlyArray<DayEnum>>;
  readonly effectiveDate?: Maybe<Scalars['Date']>;
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
};

export type PatternDataProblem = {
  readonly __typename?: 'PatternDataProblem';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type PatternRoute = {
  readonly __typename?: 'PatternRoute';
  readonly id: Scalars['ID'];
  readonly patternId?: Maybe<Scalars['ID']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly routeTypeId?: Maybe<Scalars['String']>;
  readonly transportModeId?: Maybe<Scalars['String']>;
  readonly loadSizeId?: Maybe<Scalars['String']>;
  readonly trailerTypeId?: Maybe<Scalars['String']>;
  readonly divisionId?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly vendor?: Maybe<Carrier>;
  readonly vendorV2?: Maybe<CarrierV2>;
  readonly maxCost?: Maybe<Scalars['Float']>;
  readonly patternStops?: Maybe<ReadonlyArray<Maybe<PatternStop>>>;
  readonly serviceProfile?: Maybe<ServiceProfile>;
};

export type PatternStop = {
  readonly __typename?: 'PatternStop';
  readonly id: Scalars['ID'];
  readonly patternRouteId?: Maybe<Scalars['ID']>;
  readonly facility?: Maybe<Facility>;
  readonly facilityV2?: Maybe<FacilityV2>;
  readonly stopSequence?: Maybe<Scalars['Int']>;
  readonly stopType?: Maybe<Scalars['String']>;
  readonly dropType?: Maybe<ScheduleDropTypeEnum>;
  readonly workType?: Maybe<ScheduleWorkTypeEnum>;
  readonly requirements?: Maybe<ReadonlyArray<Maybe<PatternStopRequirement>>>;
  readonly useOrderStopFacility?: Maybe<Scalars['Boolean']>;
  readonly daysFromPreviousStop?: Maybe<Scalars['Int']>;
  readonly laneStopSequence?: Maybe<Scalars['Int']>;
  readonly appointment?: Maybe<PatternStopAppointment>;
};

export type PatternStopAppointment = {
  readonly __typename?: 'PatternStopAppointment';
  readonly startTime?: Maybe<Scalars['Int']>;
  readonly stopTime?: Maybe<Scalars['Int']>;
  readonly appointmentType?: Maybe<ScheduleAppointmentTypeEnum>;
};

export type PatternStopAppointmentInput = {
  readonly startTime?: InputMaybe<Scalars['Int']>;
  readonly stopTime?: InputMaybe<Scalars['Int']>;
  readonly appointmentType: ScheduleAppointmentTypeEnum;
};

export type PatternStopRequirement = {
  readonly __typename?: 'PatternStopRequirement';
  readonly patternStopId?: Maybe<Scalars['ID']>;
  readonly requirement?: Maybe<Scalars['String']>;
};

export type PatternStopRequirementInput = {
  readonly requirement: Scalars['String'];
};

/** Represents the available Advance providers. */
export enum PaymentTypeEnum {
  /** Comcheck provided by Comdata. */
  Comchek = 'Comchek',
  Noop = 'NOOP',
  Tcheck = 'TCHECK'
}

/** Custom options to use when calculating mileage via PCMiler (Trimble), see: https://developer.trimblemaps.com/restful-apis/routing/route-reports/overview/ */
export type PcMilerOptions = {
  readonly __typename?: 'PCMilerOptions';
  readonly dataVersion?: Maybe<Scalars['String']>;
  /** 1 - Always Avoid, 2 - Avoid if Possible, 3 - Use */
  readonly TollRoads?: Maybe<Scalars['Int']>;
  readonly TollDiscourage?: Maybe<Scalars['Boolean']>;
  readonly BordersOpen?: Maybe<Scalars['Boolean']>;
  readonly FerryDiscourage?: Maybe<Scalars['Boolean']>;
  /** 0 - Truck (Default), 1 - LightTruck, 2 - Auto */
  readonly VehicleType?: Maybe<Scalars['Int']>;
  /** 0 - None (Default, which is equivalent to the 48-foot semitrailer profile), 1 - TwentyEightDoubleTrailer, 2 - FortyStraightTruck, 3 - FortyEightSemiTrailer, 4 - FiftyThreeSemiTrailer, 5 - FullSizeVan, 6 - TwentySixStraightTruck, 7 - ConventionalSchoolBus (North America only), 8 - SmallSchoolBus (North America only) */
  readonly VehProfile?: Maybe<Scalars['Int']>;
  readonly VehLength?: Maybe<Scalars['String']>;
  readonly VehHeight?: Maybe<Scalars['String']>;
  readonly VehWidth?: Maybe<Scalars['String']>;
  readonly VehWeight?: Maybe<Scalars['String']>;
  /** Specify 2 - 5 */
  readonly Axles?: Maybe<Scalars['Int']>;
  /** 0 - Practical (Default), 1 - Shortest, 2 - Fastest */
  readonly RoutingType?: Maybe<Scalars['Int']>;
  /** 0 - None(Default), 1 - FiftyThreeFoot, 2 - NationalNetwork, 3 - NationalNetwork and FiftyThreeFoot */
  readonly ClassOverrides?: Maybe<Scalars['Int']>;
  readonly HighwayOnly?: Maybe<Scalars['Boolean']>;
  readonly OverrideRestrict?: Maybe<Scalars['Boolean']>;
  readonly UseCustomRoadSpeeds?: Maybe<Scalars['Boolean']>;
  readonly HubRouting?: Maybe<Scalars['Boolean']>;
  readonly GovernorSpeedLimit?: Maybe<Scalars['Int']>;
  readonly ElevLimit?: Maybe<Scalars['Int']>;
  readonly UseSites?: Maybe<Scalars['Boolean']>;
  /** 0 - None (Default), 1 - General, 2 - Caustic, 3 - Explosives, 4 - Flammable, 5 - Inhalants, 6 - Radioactive, 7 - HarmfulToWater, 8 - Tunnel */
  readonly HazMatTypes?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
};

export type PcMilerOptionsInput = {
  readonly dataVersion?: InputMaybe<Scalars['String']>;
  /** 1 - Always Avoid, 2 - Avoid if Possible, 3 - Use */
  readonly TollRoads?: InputMaybe<Scalars['Int']>;
  readonly TollDiscourage?: InputMaybe<Scalars['Boolean']>;
  readonly BordersOpen?: InputMaybe<Scalars['Boolean']>;
  readonly FerryDiscourage?: InputMaybe<Scalars['Boolean']>;
  /** 0 - Truck (Default), 1 - LightTruck, 2 - Auto */
  readonly VehicleType?: InputMaybe<Scalars['Int']>;
  /** 0 - None (Default, which is equivalent to the 48-foot semitrailer profile), 1 - TwentyEightDoubleTrailer, 2 - FortyStraightTruck, 3 - FortyEightSemiTrailer, 4 - FiftyThreeSemiTrailer, 5 - FullSizeVan, 6 - TwentySixStraightTruck, 7 - ConventionalSchoolBus (North America only), 8 - SmallSchoolBus (North America only) */
  readonly VehProfile?: InputMaybe<Scalars['Int']>;
  readonly VehLength?: InputMaybe<Scalars['String']>;
  readonly VehHeight?: InputMaybe<Scalars['String']>;
  readonly VehWidth?: InputMaybe<Scalars['String']>;
  readonly VehWeight?: InputMaybe<Scalars['String']>;
  /** Specify 2 - 5 */
  readonly Axles?: InputMaybe<Scalars['Int']>;
  /** 0 - Practical (Default), 1 - Shortest, 2 - Fastest */
  readonly RoutingType?: InputMaybe<Scalars['Int']>;
  /** 0 - None(Default), 1 - FiftyThreeFoot, 2 - NationalNetwork, 3 - NationalNetwork and FiftyThreeFoot */
  readonly ClassOverrides?: InputMaybe<Scalars['Int']>;
  readonly HighwayOnly?: InputMaybe<Scalars['Boolean']>;
  readonly OverrideRestrict?: InputMaybe<Scalars['Boolean']>;
  readonly UseCustomRoadSpeeds?: InputMaybe<Scalars['Boolean']>;
  readonly HubRouting?: InputMaybe<Scalars['Boolean']>;
  readonly GovernorSpeedLimit?: InputMaybe<Scalars['Int']>;
  readonly ElevLimit?: InputMaybe<Scalars['Int']>;
  readonly UseSites?: InputMaybe<Scalars['Boolean']>;
  /** 0 - None (Default), 1 - General, 2 - Caustic, 3 - Explosives, 4 - Flammable, 5 - Inhalants, 6 - Radioactive, 7 - HarmfulToWater, 8 - Tunnel */
  readonly HazMatTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>;
};

export type PermanentTractorInput = {
  readonly driverId?: InputMaybe<Scalars['String']>;
  readonly permanentTractor?: InputMaybe<Scalars['String']>;
  readonly updatedBy?: InputMaybe<Scalars['String']>;
};

export type PhoneNumber = {
  readonly __typename?: 'PhoneNumber';
  readonly id: Scalars['String'];
  readonly number: Scalars['String'];
};

export type PickupEta = {
  readonly __typename?: 'PickupEta';
  readonly timezone?: Maybe<Scalars['String']>;
};

export type PlanLoadInput = {
  readonly id: Scalars['ID'];
  readonly totalRouteCount: Scalars['Int'];
  /** creates/deletes route based on routes passed, associates stop based on either routeId or routeSequence */
  readonly routeStopAssociation?: InputMaybe<ReadonlyArray<PlanLoadStopInput>>;
};

export type PlanLoadReferenceV2Input = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeId: Scalars['ID'];
  readonly value: Scalars['String'];
  readonly externalRef?: InputMaybe<Scalars['String']>;
  readonly source?: InputMaybe<Scalars['String']>;
};

export type PlanLoadRouteIdentifierV2Input = {
  /** the route's UUID */
  readonly routeId?: InputMaybe<Scalars['String']>;
  /** the route's unique identifier as defined by the caller within this PlanLoad request */
  readonly newRouteKey?: InputMaybe<Scalars['String']>;
};

export type PlanLoadRouteStopIdentifierV2Input = {
  /** when present, the routeStop is defined by the receiver */
  readonly routeStopId?: InputMaybe<Scalars['String']>;
  /** when present, the routeStop is defined by the caller within this routeStop's routeStopInfo */
  readonly newRouteStopKey?: InputMaybe<Scalars['String']>;
  /** when present, the routeStop is defined by another routeStop's routeStopInfo in which the two be matched by this sourceRouteStopKey and the other stop's newRouteStopKey */
  readonly sourceRouteStopKey?: InputMaybe<Scalars['String']>;
  /** when present, the routeStop is defined by another routeStop's routeStopInfo in which the two be matched by this sourceRouteStopId and the other stop's routeStopId */
  readonly sourceRouteStopId?: InputMaybe<Scalars['String']>;
};

export type PlanLoadRouteStopInfoV2Input = {
  readonly facilityId?: InputMaybe<Scalars['String']>;
  readonly type: Scalars['String'];
  readonly address?: InputMaybe<CityStateStopLocationInput>;
  readonly liveType?: InputMaybe<Scalars['String']>;
  readonly workType?: InputMaybe<Scalars['String']>;
  readonly requirements?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly refs?: InputMaybe<ReadonlyArray<PlanLoadReferenceV2Input>>;
  readonly commodities?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly availableStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly availableEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly scheduleType?: InputMaybe<ScheduleAppointmentTypeEnum>;
  readonly loadStopType?: InputMaybe<Scalars['ID']>;
  readonly loadFromId?: InputMaybe<Scalars['ID']>;
  readonly unloadFromId?: InputMaybe<Scalars['ID']>;
  readonly appointment?: InputMaybe<AppointmentInputV2>;
};

export type PlanLoadRouteStopV2Input = {
  /** identifier that determines how to match the routeStop between the caller and receiver */
  readonly routeStopIdentifier: PlanLoadRouteStopIdentifierV2Input;
  readonly routeStopInfo?: InputMaybe<PlanLoadRouteStopInfoV2Input>;
  readonly deleted?: InputMaybe<Scalars['Boolean']>;
};

export type PlanLoadRouteV2Input = {
  /** identifier for a route that will update an existing route if given a routeId or create a new route if given a newRouteKey */
  readonly routeIdentifier: PlanLoadRouteIdentifierV2Input;
  readonly divisionId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly stops: ReadonlyArray<PlanLoadRouteStopV2Input>;
  readonly deleted?: InputMaybe<Scalars['Boolean']>;
  readonly routeTypeId?: InputMaybe<Scalars['ID']>;
  /** Route Activation Status */
  readonly activationStatusId?: InputMaybe<Scalars['ID']>;
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  readonly dimensions?: InputMaybe<DimensionsInput>;
  /** Please use cargoInsuranceV2. It supports new currency values */
  readonly cargoInsurance?: InputMaybe<CurrencyInput>;
  readonly hotRoute?: InputMaybe<Scalars['Boolean']>;
  readonly tarpType?: InputMaybe<Scalars['ID']>;
  readonly tarpCount?: InputMaybe<Scalars['Int']>;
  readonly braceTypes?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly braceCount?: InputMaybe<Scalars['Int']>;
  readonly cargoInsuranceV2?: InputMaybe<LoadCurrencyInput>;
};

export type PlanLoadStopDescriptionInput = {
  /** the stopId of an existing stop on a planLoad. null if we have to duplicate the stop from another record */
  readonly stopId?: InputMaybe<Scalars['ID']>;
  /** the stopId of an existing stop on a planLoad that needs to be duplicated since it will go on multiple routes. null if we dont need it. */
  readonly duplicateStopId?: InputMaybe<Scalars['ID']>;
  /** the loadStop id that this stop is associated with */
  readonly loadStopId?: InputMaybe<Scalars['ID']>;
  /** indication that stop needs to be removed */
  readonly deleteStop?: InputMaybe<Scalars['Boolean']>;
};

export type PlanLoadStopInput = {
  readonly routeId?: InputMaybe<Scalars['ID']>;
  /** 0 based integer, references route that will be created from existing and totalRouteCount difference */
  readonly routeSequence?: InputMaybe<Scalars['Int']>;
  readonly stopIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** adds context to determine if it needs to be duplicated or associated to multiple routes */
  readonly stops?: InputMaybe<ReadonlyArray<PlanLoadStopDescriptionInput>>;
};

export type PlanLoadV2Input = {
  readonly routes: ReadonlyArray<PlanLoadRouteV2Input>;
  readonly loadId: Scalars['ID'];
};

export enum PlatformNotificationChannels {
  Slack = 'Slack',
  MsTeams = 'MSTeams'
}

export type Point = {
  readonly __typename?: 'Point';
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export type PointInput = {
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export type PortfolioEdge = {
  readonly __typename?: 'PortfolioEdge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<CustomerPortfolioEntity>;
};

export type PortfolioInteraction = {
  readonly __typename?: 'PortfolioInteraction';
  readonly contactId?: Maybe<Scalars['ID']>;
  readonly customerInteractionFrequency?: Maybe<Scalars['ID']>;
  readonly interactionDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated This is Deprecated. Use CustomerInteractionFrequency instead. */
  readonly interactionFrequency?: Maybe<CustomerInteractionFrequencyEnum>;
  readonly nextInteractionDate?: Maybe<Scalars['DateTime']>;
  readonly notes?: Maybe<Scalars['String']>;
};

export type PortfolioPageInfo = {
  readonly __typename?: 'PortfolioPageInfo';
  readonly endCursor?: Maybe<Scalars['String']>;
  readonly hasNextPage?: Maybe<Scalars['Boolean']>;
  readonly hasPreviousPage?: Maybe<Scalars['Boolean']>;
  readonly startCursor?: Maybe<Scalars['String']>;
};

export type PostalCode = {
  readonly __typename?: 'PostalCode';
  readonly countryCode?: Maybe<Scalars['String']>;
  readonly postalCode?: Maybe<Scalars['String']>;
};

export type PostalCodeCountry = {
  readonly __typename?: 'PostalCodeCountry';
  readonly postalCode: Scalars['String'];
  readonly country?: Maybe<Scalars['String']>;
  readonly geoPoint?: Maybe<GeographyServicePoint>;
  readonly sourceId?: Maybe<Scalars['Int']>;
};

export type PostalCodeStop = {
  readonly __typename?: 'PostalCodeStop';
  readonly postalCode: Scalars['String'];
  readonly countryCode: Scalars['String'];
};

export type Posting = {
  readonly __typename?: 'Posting';
  readonly id: Scalars['ID'];
  readonly createdByUser: User;
  readonly createdByUserV2: UserV2;
  readonly createdDate: Scalars['DateTime'];
  readonly updatedByUser?: Maybe<User>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly updatedDate?: Maybe<Scalars['DateTime']>;
  readonly route?: Maybe<LoadRoute>;
  readonly postedRate?: Maybe<Currency>;
  readonly bookItNowRate?: Maybe<Currency>;
  readonly size: UnitOfLengthOutput;
  readonly notes?: Maybe<Scalars['String']>;
  readonly references: ReadonlyArray<Reference>;
  readonly active: Scalars['Boolean'];
  readonly inboundRegionUrn?: Maybe<Scalars['String']>;
  readonly lastAction?: Maybe<Scalars['String']>;
  readonly lastActionBy?: Maybe<User>;
  readonly lastActionByV2?: Maybe<UserV2>;
  readonly lastActionByDate?: Maybe<Scalars['DateTime']>;
  readonly load?: Maybe<Scalars['ID']>;
  readonly hasErrors?: Maybe<Scalars['Boolean']>;
  readonly autoPosted?: Maybe<Scalars['Boolean']>;
};

export type PostingError = {
  readonly __typename?: 'PostingError';
  readonly error: Scalars['String'];
  readonly severity: PostingErrorSeverity;
};

export enum PostingErrorSeverity {
  Error = 'ERROR',
  Warning = 'WARNING'
}

export type PostingsFilter = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId?: InputMaybe<Scalars['ID']>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly status?: InputMaybe<PostingStatus>;
  readonly active?: InputMaybe<Scalars['Boolean']>;
};

export enum PostingSource {
  Dat = 'DAT',
  TruckerTools = 'TruckerTools',
  Truckstop = 'Truckstop'
}

export enum PostingStatus {
  Accepted = 'ACCEPTED',
  Covered = 'COVERED',
  RejectCovered = 'REJECT_COVERED',
  RejectRemoved = 'REJECT_REMOVED',
  Rejected = 'REJECTED',
  Removed = 'REMOVED'
}

export type PowerDeviceOutput = {
  readonly __typename?: 'PowerDeviceOutput';
  readonly deviceTerm?: Maybe<Scalars['String']>;
  readonly modelTerm?: Maybe<Scalars['String']>;
};

export type PowerDriverOutput = {
  readonly __typename?: 'PowerDriverOutput';
  readonly driverId: Scalars['ID'];
  /** @deprecated driverIdentityId is deprecated. Use code instead. */
  readonly driverIdentityId: Scalars['String'];
  readonly code?: Maybe<Scalars['String']>;
  readonly powerId?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly powerDriverMapping?: Maybe<ReadonlyArray<AssetPowerPermanentDriverOutput>>;
};

export type PowerTrailerOutput = {
  readonly __typename?: 'PowerTrailerOutput';
  readonly trailerId: Scalars['ID'];
  /** @deprecated trailerNumber is deprecated. Use code instead. */
  readonly trailerNumber: Scalars['String'];
  readonly code?: Maybe<Scalars['String']>;
  /** @deprecated type is deprecated. Use typeTerm instead. */
  readonly type: Scalars['String'];
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly powerId?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly powerTrailerMapping?: Maybe<ReadonlyArray<AssetPowerPermanentTrailerOutput>>;
};

export type PowerTypeError = {
  readonly __typename?: 'PowerTypeError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly code?: Maybe<Scalars['String']>;
};

export type PowerValidation = {
  readonly __typename?: 'PowerValidation';
  readonly hasPowerIdentityId?: Maybe<Scalars['Boolean']>;
};

export type PreferredNextAvailableLocationInput = {
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly dateTime: Scalars['DateTime'];
};

/** Holds Price Rate Detail Node Data Info. */
export type PriceDetail = {
  readonly __typename?: 'PriceDetail';
  readonly accessorialTotal?: Maybe<Scalars['Float']>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly fuelSurcharge?: Maybe<Scalars['Float']>;
  readonly fuelSurchargeQuantity?: Maybe<Scalars['Float']>;
  readonly fuelSurchargeRateQualifier?: Maybe<Scalars['String']>;
  readonly fuelSurchargeUnitCharge?: Maybe<Scalars['Float']>;
  readonly highTotalCharge?: Maybe<Scalars['Float']>;
  readonly linehaulCharge?: Maybe<Scalars['Float']>;
  readonly linehaulQuantity?: Maybe<Scalars['Float']>;
  readonly linehaulRateQualifier?: Maybe<Scalars['String']>;
  readonly linehaulUnitCharge?: Maybe<Scalars['Float']>;
  readonly lowTotalCharge?: Maybe<Scalars['Float']>;
  readonly provider?: Maybe<Scalars['String']>;
  readonly smartRate?: Maybe<Scalars['Float']>;
  readonly totalCharge?: Maybe<Scalars['Float']>;
};

/** Holds Price Details Edge Data Info. */
export type PriceDetailEdge = {
  readonly __typename?: 'PriceDetailEdge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<PriceRateDetail>;
};

/** Holds Werner price details or SNI bin price details. */
export type PriceDetailsConnection = {
  readonly __typename?: 'PriceDetailsConnection';
  readonly code?: Maybe<Scalars['String']>;
  readonly edges?: Maybe<ReadonlyArray<Maybe<PriceDetailsEdge>>>;
  readonly message?: Maybe<Scalars['String']>;
  readonly pageInfo?: Maybe<PageInfo>;
};

/** Holds Price Details Edge Data Info. */
export type PriceDetailsEdge = {
  readonly __typename?: 'PriceDetailsEdge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<PriceDetail>;
};

export type PricePerUnitData = {
  readonly __typename?: 'PricePerUnitData';
  readonly pricePerUnitFloorMinimum?: Maybe<Scalars['Int']>;
  readonly breakStart?: Maybe<Scalars['Int']>;
  readonly breakEnd?: Maybe<Scalars['Int']>;
  readonly rate?: Maybe<Scalars['Decimal']>;
  readonly freightClassStart?: Maybe<Scalars['Decimal']>;
  readonly freightClassEnd?: Maybe<Scalars['Decimal']>;
  readonly pricePerUnitCurrency?: Maybe<Scalars['Decimal']>;
  readonly rateUnitOfMeasure?: Maybe<Scalars['String']>;
};

/** Holds Price Rate Detail Node Data Info. */
export type PriceRateDetail = {
  readonly __typename?: 'PriceRateDetail';
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly fuelSurcharge?: Maybe<Scalars['Float']>;
  readonly fuelSurchargeQuantity?: Maybe<Scalars['Float']>;
  readonly fuelSurchargeRateQualifier?: Maybe<Scalars['String']>;
  readonly fuelSurchargeUnitCharge?: Maybe<Scalars['Float']>;
  readonly linehaulCharge?: Maybe<Scalars['Float']>;
  readonly linehaulQuantity?: Maybe<Scalars['Float']>;
  readonly linehaulRateQualifier?: Maybe<Scalars['String']>;
  readonly linehaulUnitCharge?: Maybe<Scalars['Float']>;
  readonly loadBoardCode?: Maybe<Scalars['String']>;
  readonly loadBoardDescription?: Maybe<Scalars['String']>;
  readonly rank?: Maybe<Scalars['Int']>;
  readonly rankingFactors?: Maybe<ReadonlyArray<Maybe<RankingFactor>>>;
  readonly totalCharge?: Maybe<Scalars['Float']>;
};

export type PriceRequestFilter = {
  readonly accessorials?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly callDatHistoric?: InputMaybe<Scalars['Boolean']>;
  readonly carrierCode?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly destinationCity?: InputMaybe<Scalars['String']>;
  readonly destinationCountry?: InputMaybe<Scalars['String']>;
  readonly destinationLatitude?: InputMaybe<Scalars['Float']>;
  readonly destinationLongitude?: InputMaybe<Scalars['Float']>;
  readonly destinationState?: InputMaybe<Scalars['String']>;
  readonly destinationZip?: InputMaybe<Scalars['String']>;
  readonly equipType?: InputMaybe<Scalars['String']>;
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly originCity?: InputMaybe<Scalars['String']>;
  readonly originCountry?: InputMaybe<Scalars['String']>;
  readonly originLatitude?: InputMaybe<Scalars['Float']>;
  readonly originLongitude?: InputMaybe<Scalars['Float']>;
  readonly originState?: InputMaybe<Scalars['String']>;
  readonly originZip?: InputMaybe<Scalars['String']>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
};

export type PricingInfo = {
  readonly __typename?: 'PricingInfo';
  readonly pickupDate?: Maybe<Scalars['DateTime']>;
  readonly deliveryDate?: Maybe<Scalars['DateTime']>;
  readonly totalPrice?: Maybe<Scalars['Decimal']>;
  readonly availability?: Maybe<Scalars['Boolean']>;
  readonly transitDays?: Maybe<Scalars['Int']>;
};

export type PricingProgram = {
  readonly __typename?: 'PricingProgram';
  readonly id: Scalars['ID'];
  readonly contractName?: Maybe<Scalars['String']>;
  readonly customerCode?: Maybe<Scalars['String']>;
  readonly carrierCode?: Maybe<Scalars['String']>;
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly originFacility?: Maybe<Scalars['String']>;
  readonly originPostal?: Maybe<Scalars['String']>;
  readonly originRegion?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly originCountry?: Maybe<Scalars['String']>;
  readonly rateType?: Maybe<Scalars['String']>;
  readonly serviceLevel?: Maybe<Scalars['String']>;
  readonly destinationFacility?: Maybe<Scalars['String']>;
  readonly destinationPostal?: Maybe<Scalars['String']>;
  readonly destinationRegion?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly destinationCountry?: Maybe<Scalars['String']>;
  readonly tariffData?: Maybe<TariffData>;
  readonly pricePerUnitData?: Maybe<PricePerUnitData>;
  readonly fak1?: Maybe<Fak>;
  readonly fak2?: Maybe<Fak>;
  readonly fak3?: Maybe<Fak>;
  readonly fak4?: Maybe<Fak>;
  readonly fak5?: Maybe<Fak>;
  readonly fak6?: Maybe<Fak>;
  readonly fak7?: Maybe<Fak>;
  readonly fak8?: Maybe<Fak>;
};

export type PricingQuoteCommodityType = {
  readonly code?: InputMaybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly expectedClass?: InputMaybe<Scalars['String']>;
  readonly expectedCube?: InputMaybe<PricingQuoteUnitValueType>;
  readonly expectedDensity?: InputMaybe<PricingQuoteUnitValueType>;
  readonly expectedDimension?: InputMaybe<PricingQuoteDimensionType>;
  readonly expectedLinear?: InputMaybe<PricingQuoteUnitValueType>;
  readonly expectedNMFC?: InputMaybe<Scalars['String']>;
  readonly expectedPieces?: InputMaybe<Scalars['Int']>;
  readonly expectedWeight: PricingQuoteUnitValueType;
  readonly hazmatClass?: InputMaybe<Scalars['String']>;
  readonly hazmatPackagingGroup?: InputMaybe<Scalars['String']>;
  readonly isBottomLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTemperatureControlled?: InputMaybe<Scalars['Boolean']>;
  readonly isTopLoadable?: InputMaybe<Scalars['Boolean']>;
  readonly loadOnType?: InputMaybe<Scalars['String']>;
  readonly maximumTemperature?: InputMaybe<PricingQuoteUnitValueType>;
  readonly minimumTemperature?: InputMaybe<PricingQuoteUnitValueType>;
  readonly packagingCount?: InputMaybe<Scalars['Int']>;
  readonly packagingType?: InputMaybe<Scalars['String']>;
  readonly preCoolTo?: InputMaybe<PricingQuoteUnitValueType>;
  readonly stccCode?: InputMaybe<Scalars['String']>;
  readonly temperatureSetting?: InputMaybe<Scalars['String']>;
};

/** Holds Generic Pricing info. */
export type PricingQuoteConnection = {
  readonly __typename?: 'PricingQuoteConnection';
  readonly code?: Maybe<Scalars['String']>;
  readonly edges?: Maybe<ReadonlyArray<Maybe<PricingQuoteEdgeType>>>;
  readonly message?: Maybe<Scalars['String']>;
};

export type PricingQuoteDimensionType = {
  readonly height?: InputMaybe<PricingQuoteUnitValueType>;
  readonly length?: InputMaybe<PricingQuoteUnitValueType>;
  readonly width?: InputMaybe<PricingQuoteUnitValueType>;
};

/** Holds Generic Pricing info. */
export type PricingQuoteEdgeType = {
  readonly __typename?: 'PricingQuoteEdgeType';
  readonly node?: Maybe<PricingQuoteNodeType>;
};

export type PricingQuoteFilterType = {
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly commodities?: InputMaybe<ReadonlyArray<PricingQuoteCommodityType>>;
  readonly destination: PricingQuoteStopType;
  readonly dimension?: InputMaybe<PricingQuoteDimensionType>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly equipments: ReadonlyArray<Scalars['String']>;
  readonly mode: Scalars['String'];
  readonly origin: PricingQuoteStopType;
  readonly requirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly SCAC?: InputMaybe<Scalars['String']>;
  readonly size: Scalars['String'];
};

export type PricingQuoteInputType = {
  readonly filter: PricingQuoteFilterType;
};

/** Holds Generic Pricing info. */
export type PricingQuoteNodeType = {
  readonly __typename?: 'PricingQuoteNodeType';
  readonly displayDescription?: Maybe<Scalars['String']>;
  readonly displayOrder?: Maybe<Scalars['String']>;
  readonly displayValue?: Maybe<Scalars['String']>;
};

export type PricingQuoteStopType = {
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly region?: InputMaybe<Scalars['String']>;
  readonly state: Scalars['String'];
};

export type PricingQuoteUnitValueType = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Decimal'];
};

/** Holds Generic Pricing info. */
export type PricingRouteConnection = {
  readonly __typename?: 'PricingRouteConnection';
  readonly code?: Maybe<Scalars['String']>;
  readonly edges?: Maybe<ReadonlyArray<Maybe<PricingRouteEdgeType>>>;
  readonly message?: Maybe<Scalars['String']>;
};

/** Holds Generic Pricing info. */
export type PricingRouteEdgeType = {
  readonly __typename?: 'PricingRouteEdgeType';
  readonly node?: Maybe<PricingRouteNodeType>;
};

export type PricingRouteFilterType = {
  readonly routeId: Scalars['Uuid'];
};

export type PricingRouteInputType = {
  readonly filter: PricingRouteFilterType;
};

/** Holds Generic Pricing info. */
export type PricingRouteNodeType = {
  readonly __typename?: 'PricingRouteNodeType';
  readonly displayDescription?: Maybe<Scalars['String']>;
  readonly displayOrder?: Maybe<Scalars['String']>;
  readonly displayValue?: Maybe<Scalars['String']>;
};

export type PriorityConfigurationCreateInput = {
  readonly chargeTypeId: Scalars['Guid'];
  readonly customerCarrier: Scalars['Int'];
  readonly division: Scalars['Int'];
  readonly mode: Scalars['Int'];
  readonly size: Scalars['Int'];
};

export type PriorityConfigurationResponse = {
  readonly __typename?: 'PriorityConfigurationResponse';
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['DateTime'];
  readonly isActive: Scalars['Boolean'];
  readonly isCredit: Scalars['Boolean'];
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn: Scalars['DateTime'];
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: Maybe<Scalars['String']>;
  readonly customerCarrier: Scalars['Int'];
  readonly description?: Maybe<Scalars['String']>;
  readonly division: Scalars['Int'];
  readonly id: Scalars['ID'];
  readonly mode: Scalars['Int'];
  readonly size: Scalars['Int'];
};

export type PriorityConfigurationUpdateInput = {
  readonly chargeTypeId: Scalars['Guid'];
  readonly customerCarrier: Scalars['Int'];
  readonly division: Scalars['Int'];
  readonly id: Scalars['ID'];
  readonly mode: Scalars['Int'];
  readonly size: Scalars['Int'];
};

export enum ProcessFinancialsEnum {
  /** Process Route Vendors with financials Enabled to GL Integration. */
  Enabled = 'Enabled',
  /** Process Route Vendors with financials Disabled without sending to GL Integration */
  Disabled = 'Disabled',
  /** Process Route Vendors with financials Enabled and Disabled. */
  Both = 'Both'
}

export enum ProcurementCountBy {
  Pickup = 'PICKUP',
  Delivery = 'DELIVERY'
}

export type ProcurementCounts = {
  readonly __typename?: 'ProcurementCounts';
  readonly area: Scalars['String'];
  readonly point: Point;
  readonly routeCount: Scalars['Int'];
  readonly truckCount: Scalars['Int'];
  readonly containerCount?: Maybe<Scalars['Int']>;
  readonly gateReservationCount?: Maybe<Scalars['Int']>;
};

/** Indicates what user (e.g. Create, Update, Delete) action triggered a match event */
export enum ProcurementMatchChangeEvent {
  PreCreate = 'PRE_CREATE',
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE'
}

/** Indicates what type of domain object (e.g. Truck, Route, Container) a match event relates to */
export enum ProcurementMatchEntityType {
  Route = 'ROUTE',
  Truck = 'TRUCK',
  GateReservation = 'GATE_RESERVATION',
  Container = 'CONTAINER'
}

export type ProcurementMatchEvent = {
  readonly entityId: Scalars['String'];
  readonly matchedEntityType: ProcurementMatchEntityType;
  readonly changeEventType: ProcurementMatchChangeEvent;
};

export type Project = {
  readonly __typename?: 'Project';
  readonly id?: Maybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
};

export type ProjectBooleanError = {
  readonly __typename?: 'ProjectBooleanError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

export type ProjectBooleanPayload = {
  readonly __typename?: 'ProjectBooleanPayload';
  readonly errors: ReadonlyArray<ProjectBooleanError>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
};

export type ProjectEdge = {
  readonly __typename?: 'ProjectEdge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<Project>;
};

export type ProjectsFilterArgs = {
  readonly name: Scalars['String'];
};

export type ProjectsList = {
  readonly __typename?: 'ProjectsList';
  readonly edges?: Maybe<ReadonlyArray<ProjectEdge>>;
  readonly pageInfo: PageInfoDetails;
  readonly totalCount: Scalars['Float'];
  readonly errors?: Maybe<ReadonlyArray<ProjectUserError>>;
};

export type ProjectUserError = {
  readonly __typename?: 'ProjectUserError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

export type PropertyMetadata = {
  readonly __typename?: 'PropertyMetadata';
  readonly errors?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly isEnabled: Scalars['Boolean'];
  readonly isReadOnly: Scalars['Boolean'];
  readonly isValid: Scalars['Boolean'];
  readonly isVisible: Scalars['Boolean'];
  readonly name?: Maybe<Scalars['String']>;
};

export type Query = {
  readonly __typename?: 'Query';
  /**
   * Returns truck entries or the given carrier ID. Paginated (max 50 per page).
   *
   * Requires (one of) these scope(s): [truckEntry:view].
   *
   * (Repo: truck-entry-service)
   */
  readonly getTruckEntriesForCarrier?: Maybe<TruckEntryConnection>;
  /**
   * Returns truck counts for the given carrier ID for every day for seven (7) days beginning with the given start date based on the truck's local date/time.
   *
   * Requires (one of) these scope(s): [truckEntry:view].
   *
   * (Repo: truck-entry-service)
   */
  readonly getDailyTruckCountForCarrier?: Maybe<DailyTruckCountPayload>;
  /**
   * Query to retrieve a single TruckEntry based on either the Id or the SourceId. Note: if both Id and SourceId are provided, only the Id will be considered.
   *
   * Requires (one of) these scope(s): [truckEntry:view].
   *
   * (Repo: truck-entry-service)
   */
  readonly getTruckEntry?: Maybe<TruckEntryPayloadWithErrorsAndTruckEntry>;
  /**
   * distinct carrier IDs are retrieved for only TruckEntryTemplateStatus.ACTIVE;
   *
   * Requires (one of) these scope(s): [truckEntryTemplate:view].
   *
   * (Repo: truck-entry-service)
   */
  readonly getTruckEntryTemplateDistinctCarrierIds?: Maybe<DistinctCarrierIdsPayloadsWithErrors>;
  /**
   * Returns TruckEntryTemplates for the given carrier ID. Paginated (max 50 per page).
   *
   * Requires (one of) these scope(s): [truckEntryTemplate:view].
   *
   * (Repo: truck-entry-service)
   */
  readonly getTruckEntryTemplatesForCarrier?: Maybe<TruckEntryTemplateConnection>;
  /**
   * Query to retrieve a single TruckEntryTemplate based on either the Id or the SourceId. Note: if both Id and SourceId are provided, only the Id will be considered.
   *
   * Requires (one of) these scope(s): [truckEntryTemplate:view].
   *
   * (Repo: truck-entry-service)
   */
  readonly getTruckEntryTemplate?: Maybe<TruckEntryTemplatePayloadWithErrorsAndTruckEntryTemplate>;
  /**
   * Gets a single container by it's ID
   *
   * (Repo: truck-entry-service)
   */
  readonly getContainerById: ContainerPayloadWithErrorsAndContainer;
  /**
   * Returns containers counts for the given carrier id for every day for seven (7) days beginning with the given start date based on the container's local date/time.
   *
   * (Repo: truck-entry-service)
   */
  readonly getDailyContainerCount: DailyContainersCountPayload;
  /**
   * Returns containers on the given carrier id. Paginated (max 50 per page).
   *
   * (Repo: truck-entry-service)
   */
  readonly getContainersForCarrier: ContainerConnection;
  /**
   * Gets a single gate reservation by it's ID
   *
   * (Repo: truck-entry-service)
   */
  readonly getGateReservationById: GateReservationPayloadWithErrorsAndGateReservation;
  /**
   * Returns gate reservations counts for the given carrier id for every day for seven (7) days beginning with the given start date based on the gate reservation's local date/time.
   *
   * (Repo: truck-entry-service)
   */
  readonly getDailyGateReservationCount: DailyGateReservationsCountPayload;
  /**
   * Returns gate reservations on the given carrier id. Paginated (max 50 per page).
   *
   * (Repo: truck-entry-service)
   */
  readonly getGateReservationsForCarrier: GateReservationConnection;
  readonly validateTrailer: TrailerValidationResponse;
  readonly helloTrailerValidation: Scalars['String'];
  readonly getasset_trailers: ReadonlyArray<AssetTrailers>;
  readonly getTrailerDrivers: ReadonlyArray<TrailerDriverOutput>;
  readonly getTrailerTractors: ReadonlyArray<TrailerTractorOutput>;
  readonly getasset_trailersbyid: ReadonlyArray<AssetTrailers>;
  readonly getassetTrailerSearch: ReadonlyArray<AssetTrailers>;
  /** @deprecated getassetTrailerStatusDetails query deprecated */
  readonly getassetTrailerStatusDetails: ReadonlyArray<AssetTrailers>;
  readonly checkTrailerNumber: TrailerValidation;
  readonly getTrailerByCode: AssetTrailers;
  readonly getTrailerByCodeV2: AssetTrailersV2;
  readonly fetchTrackingETAForStop?: Maybe<TrackingEtaForStop>;
  /** Calculate Estimated Time of Arrival to first stop using driver assignment data */
  readonly calculateDriverAssignmentETA: TrackingEtaCalculation;
  /** DEPRECATED, DO NOT USE - Get driver assignment for a route */
  readonly driverAssignment: DriverAssignment;
  /** Get driver assignment for a route */
  readonly driverAssignmentForRoute?: Maybe<DriverAssignment>;
  readonly trackingUpdatesV4: TrackingUpdateV4Connection;
  /** Get Tracking Update By ID */
  readonly trackingUpdateByIDV4?: Maybe<TrackingUpdateV4>;
  readonly tours?: Maybe<ReadonlyArray<Maybe<Tour>>>;
  readonly timelineEvent: TimelineEvent;
  readonly timelineEvents: TimelineEventConnection;
  readonly timelineEventV2: TimelineEventV2;
  readonly timelineEventsV2: TimelineEventConnectionV2;
  /** Retrieves a list of task details. */
  readonly tasksV2: ReadonlyArray<TaskV2>;
  /** Retrieves Number of task based on filters */
  readonly taskCountV2: Scalars['Float'];
  /** Retrieves tasks based on filters */
  readonly tasksPaginatedV2: TaskConnectionV2;
  /** Retrieves a task based on id. */
  readonly taskV2: TaskV2;
  /** mastery-tasks-api: tasks - Retrieves a list of task details. */
  readonly tasks: ReadonlyArray<Task>;
  /** mastery-tasks-api: tasks - Retrieves Number of task based on filters */
  readonly taskCount: Scalars['Float'];
  /** mastery-tasks-api: tasks - Retrieves tasks based on filters */
  readonly tasksPaginated: TaskConnection;
  /** mastery-tasks-api: tasks - Retrieves a task based on id. */
  readonly task: Task;
  readonly StopEventEngine: StopEventEngineOutput;
  readonly StopEventV2: StopEventV2;
  readonly StopEventsV2: StopEventV2Connection;
  /** Returns Paginated SpotQuotes by Lane Filter */
  readonly spotQuotes?: Maybe<SpotQuotesPaginatedPayload>;
  /** Returns Paginated SpotQuotes by Optional Lane/Customer Ids */
  readonly spotQuotesPaged?: Maybe<SpotQuotesPaginatedPayload>;
  readonly existingQuotes?: Maybe<ExistingQuotesPayload>;
  readonly matchingSpotQuote?: Maybe<MatchingSpotQuotePayload>;
  /** Returns all requested Spot Quotes by Id, if they exist */
  readonly spotQuotesByIds: ReadonlyArray<SpotQuote>;
  /** @deprecated no longer needed */
  readonly message?: Maybe<Scalars['String']>;
  readonly seerSearchCarrierShipment: SeerCarrierShipmentResult;
  readonly seerSearchCarrierRoute: SeerCarrierRouteResult;
  readonly seerSearchCommandLog: SeerCommandLogResult;
  readonly seerSearchCurrentAndNextStop: SeerCurrentAndNextStopResult;
  readonly seerSearchCurrentStop: SeerCurrentStopResult;
  readonly seerSearchCustomerGroup: SeerCustomerGroupResult;
  readonly seerSearchCustomerRep: SeerCustomerRepResult;
  readonly seerSearchCustomerOrder: SeerCustomerOrderResult;
  readonly seerSearchFacilityRoute: SeerFacilityRouteResult;
  readonly seerSearchFacilityTrackingBoard: SeerFacilityTrackingBoardResult;
  readonly seerSearchIvMainPageRouteBoard: SeerIvMainPageRouteBoardResult;
  readonly seerSearchIvMprb: SeerIvMprbResult;
  readonly seerSearchLinkedRoutes: SeerLinkedRoutesResult;
  readonly seerSearchLoad: SeerLoadResult;
  readonly seerSearchLoadSearch: SeerLoadSearchResult;
  readonly seerSearchLoadSearchMoloSniSujan: SeerLoadSearchMoloSniSujanResult;
  readonly seerSearchLoadSearchSm: SeerLoadSearchSmResult;
  readonly seerSearchLoadSearchDataAggregation: SeerLoadSearchDataAggregationResult;
  readonly seerSearchLoadSearchDataAggregationBkup: SeerLoadSearchDataAggregationBkupResult;
  readonly seerSearchLoadSearchSideBar: SeerLoadSearchSideBarResult;
  readonly seerSearchLoadSearchV2: SeerLoadSearchV2Result;
  readonly seerSearchLoadSearchV2Sm: SeerLoadSearchV2SmResult;
  readonly seerSearchLoadSummaryDataAggregation: SeerLoadSummaryDataAggregationResult;
  readonly seerSearchLocks: SeerLocksResult;
  readonly seerSearchMainPageRouteBoard: SeerMainPageRouteBoardResult;
  readonly seerSearchMainPageRouteBoardWaybillTest: SeerMainPageRouteBoardWaybillTestResult;
  readonly seerSearchMainPageRouteBoardWaybillCte: SeerMainPageRouteBoardWaybillCteResult;
  readonly seerSearchMainPageRouteBoardCompleted: SeerMainPageRouteBoardCompletedResult;
  readonly seerSearchMainPageRouteBoardCountUnified: SeerMainPageRouteBoardCountUnifiedResult;
  readonly seerSearchMainPageRouteBoardDetails: SeerMainPageRouteBoardDetailsResult;
  readonly seerSearchMainPageRouteBoardDetailsAh: SeerMainPageRouteBoardDetailsAhResult;
  readonly seerSearchMainPageRouteBoardPending: SeerMainPageRouteBoardPendingResult;
  readonly seerSearchMainPageRouteBoardPreTender: SeerMainPageRouteBoardPreTenderResult;
  readonly seerSearchMainPageRouteBoardTender: SeerMainPageRouteBoardTenderResult;
  readonly seerSearchMainPageTrackingBoard: SeerMainPageTrackingBoardResult;
  readonly seerSearchMasterFindLoadSearch: SeerMasterFindLoadSearchResult;
  readonly seerSearchOrderReference: SeerOrderReferenceResult;
  readonly seerSearchOrderRep: SeerOrderRepResult;
  readonly seerSearchOrderStatus: SeerOrderStatusResult;
  readonly seerSearchRoute: SeerRouteResult;
  readonly seerSearchRouteStatus: SeerRouteStatusResult;
  readonly seerSearchRouteVendor: SeerRouteVendorResult;
  readonly seerSearchRouteVendorReference: SeerRouteVendorReferenceResult;
  readonly seerSearchRouteVendorStatus: SeerRouteVendorStatusResult;
  readonly seerSearchStop: SeerStopResult;
  readonly seerSearchStopEventsAggregations: SeerStopEventsAggregationsResult;
  readonly seerSearchTender: SeerTenderResult;
  readonly seerSearchTenderPlan: SeerTenderPlanResult;
  readonly seerSearchTenderPlanStatus: SeerTenderPlanStatusResult;
  readonly seerSearchTenderStatus: SeerTenderStatusResult;
  readonly seerSearchTrackingAggregations: SeerTrackingAggregationsResult;
  readonly seerSearchTrackingEtas: SeerTrackingEtasResult;
  readonly seerSearchTrackingPage: SeerTrackingPageResult;
  readonly seerSearchTrailerType: SeerTrailerTypeResult;
  readonly searchFacilitiesByAddress?: Maybe<SearchFacilityResult>;
  readonly getCurrentSearchFacilityVersion?: Maybe<Scalars['String']>;
  readonly getSavedFilters: SavedFilterResult;
  /** Checks whether the SNI Shipment Service is available */
  readonly IsSNIShipmentServiceAvailable: IsSniShipmentServiceAvailable;
  readonly regionInfoV2?: Maybe<RegionInfoV2Response>;
  readonly allRegionsV2?: Maybe<AllRegionsV2>;
  readonly regionSet?: Maybe<ReadonlyArray<Maybe<RegionSetR2>>>;
  readonly countriesV2?: Maybe<ReadonlyArray<Maybe<CountryType>>>;
  readonly getRegionSetDetails?: Maybe<AllRegionsV2>;
  /** Retrieves active and expired superregions, regions and subregions. */
  readonly allActiveAndExpiredRegions?: Maybe<RegionGraphResponse>;
  /** Retrieves all superregions, regions and subregions. */
  readonly allRegionsInfo?: Maybe<RegionGraphResponse>;
  /** Retrieves region data for given zipcode and country. */
  readonly regionInfo: RegionGraphResponse;
  /** Retrieves region data for given id. */
  readonly regionInfoById: RegionByIdGraphResponse;
  /** Retrieves region data for given urn. */
  readonly regionInfoByUrn?: Maybe<Region>;
  /**
   * Retrieves all customers
   * @deprecated User AllCustomersNullable instead
   */
  readonly allCustomers: CustomerConnection;
  /** Retrieves all customers */
  readonly allCustomersNullable?: Maybe<CustomerConnection>;
  /** gets all employees */
  readonly allEmployees?: Maybe<EmployeeConnection>;
  /** Retrieves all facilities */
  readonly allFacilities: FacilityConnection;
  /** All Temp Loads */
  readonly allTempLoads: TempLoadConnection;
  /** Returns bounces by search criteria */
  readonly bounces: BounceConnection;
  /** Returns a single carrier based on the ID */
  readonly carrier?: Maybe<Carrier>;
  /** Returns a single carrier based on the ID or CODE */
  readonly carrierNullable?: Maybe<Carrier>;
  /** Returns all carrier reps */
  readonly carrierReps: ReadonlyArray<Rep>;
  /**
   * Gets a single Carrier Settings by Carrier's ID
   * @deprecated Moved to CarrierType
   */
  readonly carrierSetting?: Maybe<CarrierSetting>;
  /**
   * Retrieves all carriers or search carriers
   * @deprecated Use CarriersNullable instead
   */
  readonly carriers: CarrierConnection;
  /** Retrieves all carriers or search carriers */
  readonly carriersNullable?: Maybe<CarrierConnection>;
  /** Retrieves all commodities */
  readonly commodities: CommodityConnection;
  /** Returns a count of loads/trucks by administrative area */
  readonly countsByArea?: Maybe<ReadonlyArray<AreaCount>>;
  /** Returns a single customer based on the ID */
  readonly customer?: Maybe<Customer>;
  /** Returns a single customer by its code */
  readonly customerByCode?: Maybe<Customer>;
  /**
   * Returns quotes based on filters
   * @deprecated Moved to QuotesPaginated
   */
  readonly customerQuotes: ReadonlyArray<CustomerQuote>;
  /** Returns quotes based on filters */
  readonly customerQuotesPaginated?: Maybe<CustomerQuoteConnection>;
  /** Returns employee based upon id */
  readonly employee?: Maybe<Employee>;
  /**
   * gets all employees
   * @deprecated Moved to AllEmployees
   */
  readonly employees?: Maybe<ReadonlyArray<Employee>>;
  /** Returns employee by external_employee_id */
  readonly exactEmployee?: Maybe<Employee>;
  /** Returns a single User along with associated employees based on user email */
  readonly exactUser?: Maybe<User>;
  /**
   * Retrieves all facilities
   * @deprecated Moved to AllFacilities
   */
  readonly facilities: ReadonlyArray<Facility>;
  /** Returns a single facility based on the ID */
  readonly facility?: Maybe<Facility>;
  /** Returns a single facility based on code */
  readonly facilityByCode?: Maybe<Facility>;
  readonly geographies?: Maybe<ReadonlyArray<Geography>>;
  /** Returns a single Geography based on source_id */
  readonly geography: Geography;
  /**
   * Gets all truck posts for carrier by carrier id
   * @deprecated Service has reached EOL. Use TruckEntry subgraph. Field will be removed soon
   */
  readonly getTruckPostingsForCarrier?: Maybe<ReadonlyArray<TruckPosting>>;
  /** Returns a single load based on the ID */
  readonly load?: Maybe<Load>;
  /** Returns loads, based on the loads_routes_orders view */
  readonly loadRoutesOrders?: Maybe<LoadRoutesOrdersConnection>;
  /** Returns a list of loads */
  readonly loads?: Maybe<LoadConnection>;
  /** Returns match results based on carrier or truck_posting */
  readonly matchQuery: MatchQuery;
  /** Returns match results with both TempLoads and Routes based on carrier or truck_posting */
  readonly matchQueryCombined: MatchQueryCombined;
  /** Returns distance between provided locations */
  readonly mileage?: Maybe<Mileage>;
  /** Returns distance between provided PointInputs */
  readonly mileageForPoints?: Maybe<Mileage>;
  /** Returns a single order by ID */
  readonly order?: Maybe<CustomerOrder>;
  /** Returns a single order by ID */
  readonly orderByCode?: Maybe<CustomerOrder>;
  /** Returns a single order by Customer Order Number */
  readonly orderByCustomerOrderNumber?: Maybe<CustomerOrder>;
  /** Returns a single quote based on the ID */
  readonly quote?: Maybe<CustomerQuote>;
  /** Returns a single Route based on the ID */
  readonly route?: Maybe<LoadRoute>;
  /** Returns a Route Vendor by Id */
  readonly routeVendor?: Maybe<RouteVendor>;
  /** Returns an array of RouteVendors based on search criteria */
  readonly routeVendors: RouteVendorConnection;
  /** Searches Routes */
  readonly routes?: Maybe<LoadRouteConnection>;
  /** Returns Stops based on filters */
  readonly stops?: Maybe<StopConnection>;
  /** Retrieves all temp loads based on criteria */
  readonly tempLoads: ReadonlyArray<TempLoad>;
  /**
   * Returns tenant configured data, including values for dropdowns
   * @deprecated Deprecating endpoint, transition to call Minion API instead. Contact team-purple-rain if more information is needed.
   */
  readonly tenantConfiguration?: Maybe<TenantConfig>;
  /**
   * Returns truck postings based on filters
   * @deprecated Service has reached EOL. Use TruckEntry subgraph. Field will be removed soon
   */
  readonly truckPostings?: Maybe<ReadonlyArray<TruckPosting>>;
  /**
   * Returns truck postings based on filters
   * @deprecated Service has reached EOL. Use TruckEntry subgraph. Field will be removed soon
   */
  readonly truckPostingsExternal?: Maybe<TruckPosting>;
  /** Returns all Users along with associated employees */
  readonly users: UserConnection;
  /** Returns a user/employee association based on current user */
  readonly whoAmI?: Maybe<WhoAmI>;
  /**
   * DO NOT USE. This service is depricated. Please use customerQuotePaginatedV3.
   * @deprecated This service is depricated. Please use customerQuotePaginatedV3.
   */
  readonly customerQuotePaginatedV2?: Maybe<CustomerQuotePaginatedV2Connection>;
  /**
   * mastery-ratequote: customerQuotePaginatedV3 - Retrieves a list of customer quotes.
   * @deprecated This service is depricated. Please use customerQuotePaginatedV4.
   */
  readonly customerQuotePaginatedV3?: Maybe<CustomerQuoteV3Connection>;
  /**
   * mastery-ratequote: customerQuotePaginatedV4 - Retrieves a list of customer quotes.
   * @deprecated This service is depricated. Please use customerQuotePaginatedV5.
   */
  readonly customerQuotePaginatedV4?: Maybe<CustomerQuoteV3Connection>;
  /** mastery-ratequote: customerQuotePaginatedV5 - Retrieves a list of customer quotes. */
  readonly customerQuotePaginatedV5?: Maybe<CustomerQuoteV4Connection>;
  /**
   * CustomerQuote: Retrieves quote details.
   * @deprecated This service is depricated. Please use getCustomerQuoteByIdV2.
   */
  readonly getCustomerQuoteById?: Maybe<CustomerQuoteV3>;
  /**
   * CustomerQuote: Retrieves quote details.
   * @deprecated This service is depricated. Please use getCustomerQuoteByIdV3.
   */
  readonly getCustomerQuoteByIdV2?: Maybe<CustomerQuoteV3>;
  /** CustomerQuote: Retrieves quote details. */
  readonly getCustomerQuoteByIdV3?: Maybe<CustomerQuoteV4>;
  readonly rateConfirmations?: Maybe<ReadonlyArray<RateConfirmation>>;
  /** return project by its id */
  readonly projectById?: Maybe<Project>;
  /** return all the Project */
  readonly projects: ProjectsList;
  /** Returns a single Available Route */
  readonly availableRoute?: Maybe<AvailableRoute>;
  /**
   * Returns all available routes that are available at any given time and combines both Mastermind Loads/Routes with external Loads.
   *         This powers the routes that appear on the State Map and available Routes screens.
   */
  readonly allAvailableRoutes: AvailableRouteConnection;
  /**
   * Returns a single Available Route from ElasticSearch
   * @deprecated Use `availableRoute`: ES variants are deprecated
   */
  readonly availableRouteES?: Maybe<AvailableRoute>;
  /**
   * Returns all available routes from ElasticSearch that match the provided filter criteria and are available at any given time.
   * @deprecated Use `allAvailableRoutes`: ES variants are deprecated
   */
  readonly allAvailableRoutesES: AvailableRouteConnection;
  /** Retrieve a single Available Truck */
  readonly availableTruck?: Maybe<AvailableTruck>;
  /** Retrieve all available trucks from ElasticSearch */
  readonly allAvailableTrucks: AvailableTruckConnection;
  /** Returns the available routes and trucks by state (current) to power the state map within MasterMind */
  readonly procurementCountsByArea: ReadonlyArray<ProcurementCounts>;
  /**
   * Returns the available routes and trucks by state (current) to power the state map within MasterMind
   * @deprecated Use `procurementCountsByArea`: ES variants are deprecated
   */
  readonly procurementCountsByAreaES: ReadonlyArray<ProcurementCounts>;
  /** Retrieve a single available gate reservations */
  readonly availableGateReservation?: Maybe<AvailableGateReservation>;
  /** Retrieve all available gate reservations from ElasticSearch */
  readonly allAvailableGateReservations: AvailableGateReservationConnection;
  /** Retrieve a single available container */
  readonly availableContainer?: Maybe<AvailableContainer>;
  /** Retrieve all available container from ElasticSearch */
  readonly allAvailableContainers: AvailableContainerConnection;
  /** Returns route matches from ElasticSearch given a truck, carrier, or employee. */
  readonly matches: MatchConnection;
  /** On demand (ad hoc) search to find all available routes that match the provided truck posting and filter criteria. */
  readonly searchMatchingRoutesForTruck: MatchConnection;
  /** Returns route matches from ElasticSearch given a gate reservation, carrier, or employee. */
  readonly gateReservationMatches: GateReservationMatchConnection;
  /** Returns precomputed route + container matches from ElasticSearch */
  readonly containerMatches: ContainerMatchConnection;
  /** On demand (ad hoc) search to find available routes that match the provided container and filter criteria. */
  readonly searchMatchingRoutesForContainer: ContainerMatchConnection;
  /** mastery-pricing-service: Retrieves advertised price details for given loads and loadBoard codes. */
  readonly advertisedPriceDetails: AdvertisedPriceConnection;
  /** mastery-pricing-service: Retrieves carrier costs from external rate API. */
  readonly carrierCostsByRoute?: Maybe<CarrierCostsByRouteConnection>;
  /** mastery-pricing-service: Retrieves customer order rates from external rate API. */
  readonly customerOrderRatesByCarrier?: Maybe<CustomerOrderRatesByCarrierConnection>;
  /** mastery-pricing-service: Retrieves customer order rates from external rate API. */
  readonly customerOrderRatesV2?: Maybe<CustomerOrderRatesConnectionV2>;
  /** mastery-pricing-service: Retrieves DAT contract rates by routes or other inputs from external rate API. */
  readonly datContractRates?: Maybe<DatContractRatesConnection>;
  /** mastery-pricing-service: Retrieves DAT spot rates by routes or other inputs from external rate API. */
  readonly datSpotRates?: Maybe<DatSpotRatesConnection>;
  /** mastery-pricing-service: Retrieves Expected cost detail for given route code and order code. */
  readonly expectedCostDetail: ExpectedCost;
  /** mastery-pricing-service: Retrieves SNI bin price details or Werner price details. */
  readonly PriceDetails: PriceDetailsConnection;
  /** mastery-pricing-service: Retrieves Generic Pricing by quote details from external Generic pricing API. */
  readonly pricingQuote?: Maybe<PricingQuoteConnection>;
  /** mastery-pricing-service: Retrieves Generic Pricing by routes from external Generic pricing API. */
  readonly pricingRoute?: Maybe<PricingRouteConnection>;
  readonly getAssetPower: ReadonlyArray<AssetPowerOutput>;
  readonly getAssetPowerbyId: ReadonlyArray<AssetPowerOutput>;
  readonly getassetPowerStatusDetails: ReadonlyArray<AssetPowerOutput>;
  readonly getAssetPowerSearch: ReadonlyArray<AssetPowerOutput>;
  readonly checkPowerIdentity: PowerValidation;
  readonly getPowerDrivers: ReadonlyArray<PowerDriverOutput>;
  readonly getPowerTractors: ReadonlyArray<PowerTrailerOutput>;
  readonly searchPowerDriverByName: ReadonlyArray<PowerDriverOutput>;
  readonly searchPowerDriverById: ReadonlyArray<PowerDriverOutput>;
  readonly getPowerByCode: AssetPowerOutput;
  readonly getPowerByCodeV2: AssetPowerOutputV2;
  readonly getPowerBiReportDetails: EmbedParamsV2;
  readonly embedParams?: Maybe<EmbedParams>;
  readonly posting: Posting;
  readonly postings?: Maybe<ReadonlyArray<Posting>>;
  readonly offer: Offer;
  readonly offers: ReadonlyArray<Offer>;
  readonly offersPaginated: OfferConnection;
  readonly offersCount: Scalars['Float'];
  readonly emails?: Maybe<ReadonlyArray<Email>>;
  readonly notifications?: Maybe<ReadonlyArray<NotificationEntity>>;
  readonly emailUpdates?: Maybe<ReadonlyArray<EmailUpdate>>;
  /**
   * Calculate total distance for lat/long stops.  Returns results in kilometers
   * @deprecated Please update to calculateDistanceLength for consistent behavior across unit conversions and stops consisting of lat/long pairs or postal codes
   */
  readonly calculateMileage: ReadonlyArray<MileageCalculation>;
  /**
   * Calculate total distance for lat/long stops for a carrier.  Returns results in kilometers
   * @deprecated Please update to calculateCarrierDistanceLength for consistent behavior across unit conversions and stops consisting of lat/long pairs or postal codes
   */
  readonly calculateCarrierMileage: ReadonlyArray<MileageCalculation>;
  /**
   * Calculate total distance for lat/long stops for a customer.  Returns results in kilometers
   * @deprecated Please update to calculateCustomerDistanceLength for consistent behavior across unit conversions and stops consisting of lat/long pairs or postal codes
   */
  readonly calculateCustomerMileage: ReadonlyArray<MileageCalculation>;
  /**
   * Calculate total distance for lat/long stops.  Returns results in kilometers unless unit is specified
   * @deprecated Please update to calculateDistanceLength for stops consisting of lat/long pairs or postal codes
   */
  readonly calculateMileageLength: ReadonlyArray<MileageCalculationLength>;
  /** Calculate total distance for stops consisting of coordinates, postal codes, or localities with admin areas.  Returns results in kilometers unless unit is specified */
  readonly calculateDistanceLength: ReadonlyArray<MileageCalculationLength>;
  /**
   * Calculate total distance for lat/long stops for a carrier.  Returns results in kilometers unless unit is specified
   * @deprecated Please update to calculateCarrierDistanceLength for stops consisting of lat/long pairs or postal codes
   */
  readonly calculateCarrierMileageLength: ReadonlyArray<MileageCalculationLength>;
  /** Calculate total distance for stops consisting of coordinates, postal codes, or localities with admin areas for a carrier.  Returns results in kilometers unless unit is specified */
  readonly calculateCarrierDistanceLength: ReadonlyArray<MileageCalculationLength>;
  /**
   * Calculate total distance for lat/long stops for a customer.  Returns results in kilometers unless unit is specified
   * @deprecated Please update to calculateCustomerDistanceLength for stops consisting of lat/long pairs or postal codes
   */
  readonly calculateCustomerMileageLength: ReadonlyArray<MileageCalculationLength>;
  /** Calculate total distance for stops consisting of coordinates, postal codes, or localities with admin areas for a customer.  Returns results in kilometers unless unit is specified */
  readonly calculateCustomerDistanceLength: ReadonlyArray<MileageCalculationLength>;
  readonly getCurrentMileageProviderVersion?: Maybe<Scalars['String']>;
  readonly getBidsPaginated?: Maybe<BidConnection>;
  readonly getBidLanesPaginated?: Maybe<BidLaneConnection>;
  readonly getRoundsByBidId?: Maybe<BidRoundPayload>;
  readonly getAwardedQuotesByBidId?: Maybe<AwardQuotesPayload>;
  readonly getCustomerPricingPrograms?: Maybe<AllPricingProgram>;
  readonly getCarrierPricingPrograms?: Maybe<AllPricingProgram>;
  readonly getAllPricingProgramByCustomerCode?: Maybe<AllPricingProgram>;
  readonly getAllPricingProgramByCarrierCode?: Maybe<AllPricingProgram>;
  readonly getAllCustomerMarkupByCustomerCode?: Maybe<CustomerMarkupResponsePayloads>;
  /** lachesis-api: Returns the most recent route status calculated for <route_id> */
  readonly getLseRouteVendorStatusRecordV3: ReadonlyArray<RouteVendorFinancialStatusV3>;
  /** lachesis-api: Returns the most recent route status calculated for <route_id> */
  readonly getLseRouteStatusRecordV3: RouteStatusRecordV3;
  /** lachesis-api: Returns the most recent order status calculated for <order_id> */
  readonly getLseOrderStatusRecordV3: OrderStatusRecordV3;
  /** lachesis-api: Returns up to most recent <limit> load status records for load <load_id>. <limit> defaults to 1000 if omitted. */
  readonly getLoadStatusRecordsAuditV3: ReadonlyArray<LoadStatusRecordV3>;
  /** lachesis-api: Returns all possible status values related to Order Lifecycle Status */
  readonly getOrderLifecycleDomainStatusRecords: ReadonlyArray<DomainStatusRecord>;
  /** lachesis-api: Returns all possible status values related to Route Lifecycle Status */
  readonly getRouteLifecycleDomainStatusRecords: ReadonlyArray<DomainStatusRecord>;
  /** lachesis-api: Returns all possible status values related to Order Tender Status */
  readonly getOrderTenderDomainStatusRecords: ReadonlyArray<DomainStatusRecord>;
  /** lachesis-api: Returns all possible status values related to Route Tender Status */
  readonly getRouteTenderDomainStatusRecords: ReadonlyArray<DomainStatusRecord>;
  /** lachesis-api: Returns all possible status values related to Order Activation Status */
  readonly getOrderActivationDomainStatusRecords: ReadonlyArray<DomainStatusRecord>;
  /** lachesis-api: Returns all possible status values related to Route Activation Status */
  readonly getRouteActivationDomainStatusRecords: ReadonlyArray<DomainStatusRecord>;
  /** lachesis-api: Returns all possible status values related to Order Financial Status */
  readonly getOrderFinancialDomainStatusRecords: ReadonlyArray<DomainStatusRecord>;
  /** lachesis-api: Returns all possible status values related to Route Vendor Financial Status */
  readonly getRouteVendorFinancialDomainStatusRecords: ReadonlyArray<DomainStatusRecord>;
  /** Cleanse address input, multilineAddresses OR singlelineAddresses required */
  readonly cleanseAddresses?: Maybe<AddressResults>;
  /**
   * Cleanse singleline address input, eg: '123 1st Ave, New York, NY'
   * @deprecated use cleanseAddresses query with singlelineAddresses filter
   */
  readonly cleanseSingleLineAddress?: Maybe<SingleLineResult>;
  /**
   * Cleanse Multiline addresses, @deprecated in favor of cleanseAddress
   * @deprecated use cleanseAddresses query with multilineAddresses filter
   */
  readonly cleanseMultiLineAddress?: Maybe<MultilineAddressResults>;
  /** Address autocomplete for UI interactivity */
  readonly autocompleteAddress?: Maybe<ReadonlyArray<Maybe<FormattedAddress>>>;
  readonly zipCodeAddress?: Maybe<ZipCodeFormatResult>;
  readonly addressCleanseStatus?: Maybe<AddressCleanseStatusConnection>;
  readonly geocode?: Maybe<GeocodeResponse>;
  readonly batchGeocode: BatchGeocodeResponse;
  readonly reverseGeocode?: Maybe<GeocodeResponse>;
  readonly batchReverseGeocode?: Maybe<BatchGeocodeResponse>;
  readonly lookupTimezone?: Maybe<TimezoneResponse>;
  readonly getCurrentLocationServicesVersion?: Maybe<Scalars['String']>;
  readonly geocodeV2: ReadonlyArray<Maybe<GeocodeResponse>>;
  /** Get Audits Per loadId */
  readonly GetLoadAuditDetails?: Maybe<LoadAuditResponseConnection>;
  readonly customerOrder?: Maybe<CustomerOrderV2>;
  readonly customerOrderByCode?: Maybe<CustomerOrderV2>;
  readonly customerOrderByNumber?: Maybe<CustomerOrderV2>;
  readonly orders: CustomerOrdersConnection;
  readonly orderStop?: Maybe<OrderStop>;
  readonly loadV2?: Maybe<LoadV2>;
  /** Returns the route with the given id, if it exists */
  readonly routeV2?: Maybe<LoadRouteV2>;
  /** Returns the route with the given code, if it exists */
  readonly routeByCode?: Maybe<LoadRouteV2>;
  readonly routesV2?: Maybe<LoadRoutesConnection>;
  readonly routeStop?: Maybe<RouteStop>;
  readonly stopsV2?: Maybe<StopsConnection>;
  /** Get linked route */
  readonly linkedRouteV2?: Maybe<LinkedRouteV2>;
  /** Lane: Verify any active lane order exists and returns a boolean value accordingly. */
  readonly ActiveLaneOrderExists?: Maybe<ActiveLaneOrderExistsResult>;
  /** Lane: Verify lane exists and returns details. */
  readonly CheckIfLaneExist?: Maybe<LaneV2>;
  /**
   * Lane: Verify lane exists and returns laneId.
   * @deprecated This service is deprecated. Please use CheckIfLaneExist.
   */
  readonly CheckLaneExistsForCustomerQuote?: Maybe<Scalars['ID']>;
  /** Lane: Finds lanes matching the input routes. */
  readonly findMatchingLanesForRoutes?: Maybe<FindMatchingLanesForRoutesResult>;
  /**
   * Lane: Retrieves lane Details.
   * @deprecated This service is depricated. Please use laneV2.
   */
  readonly lane?: Maybe<Lane>;
  /** Lane: Returns orders matching the lane. */
  readonly laneOrders?: Maybe<LaneOrderMatchConnection>;
  /**
   * Lane: Filter and retrieves list of lane Details.
   * @deprecated This service is depricated. Please use lanePaginatedV2.
   */
  readonly lanePaginated?: Maybe<LaneConnection>;
  /** Lane: Filter and retrieves list of lane Details. */
  readonly lanePaginatedV2?: Maybe<LaneV2Connection>;
  /** Lane: Returns routes matching the lane. */
  readonly laneRoutes?: Maybe<LaneRouteMatchConnection>;
  /** Lane: Retrieves all allowed Stop Types for Lanes. */
  readonly laneStopTypes?: Maybe<ReadonlyArray<Maybe<LaneKeyValue>>>;
  /** Lane: Retrieves all allowed Stop Types for CQ. */
  readonly laneStopTypesForCQ?: Maybe<ReadonlyArray<Maybe<LaneKeyValue>>>;
  /** Lane: Retrieves all allowed Stop Types for RQ. */
  readonly laneStopTypesForRQ?: Maybe<ReadonlyArray<Maybe<LaneKeyValue>>>;
  /** LaneV2: Retrieves lane Details. */
  readonly laneV2?: Maybe<LaneV2>;
  /** Lane: Get lanes matched to the order. */
  readonly orderMatchedLanes: ReadonlyArray<OrderMatchedLane>;
  readonly incidentsV2: IncidentConnectionV2;
  readonly incidentByIDV2?: Maybe<IncidentV2>;
  readonly allServiceProfile: ReadonlyArray<ServiceProfile>;
  readonly serviceProfileById: ServiceProfile;
  readonly allServiceProfileV2: ReadonlyArray<ServiceProfileObject>;
  readonly serviceProfileByIdV2: ServiceProfileObject;
  readonly allSchedules: ReadonlyArray<Schedules>;
  readonly allSchedulesV2: ReadonlyArray<SchedulesObject>;
  readonly getPricingData?: Maybe<ImdlOutput>;
  readonly getSniPricingData?: Maybe<SniQuoteOutput>;
  readonly ruleSetEvaluation?: Maybe<RuleSetEvaluationResult>;
  readonly searchRuleSets?: Maybe<SearchRuleSetsResult>;
  readonly searchTemplates?: Maybe<SearchTemplatesResult>;
  readonly searchHaykEvents?: Maybe<SearchHaykEventsResult>;
  readonly searchAuditHistory?: Maybe<SearchAuditHistoryResult>;
  readonly searchHaykRedirects?: Maybe<SearchHaykRedirectsResult>;
  /** A list of all the Widgets. For Testing Purposes Only. */
  readonly widgets?: Maybe<ReadonlyArray<Widget>>;
  /** Searches for a specific geography record */
  readonly geographyV2: GeographyServiceRecord;
  /** Searches for a collection of geography records based on search criteria */
  readonly geographiesV2: GeographyRecordConnection;
  readonly getCurrentGeographiesVersion?: Maybe<Scalars['String']>;
  /** Searches for a collection of countries based on a postal code */
  readonly getPostalCodeCountries: ReadonlyArray<Maybe<PostalCodeCountry>>;
  /** Searches for an admin area based on a variety of input */
  readonly getAdminArea?: Maybe<ReadonlyArray<GeographyAdminArea>>;
  readonly getFuelProgramCustomerConfigurationSummary?: Maybe<ReadonlyArray<FuelProgramCustomerConfigurationModel>>;
  readonly getFuelProgramCustomerConfiguration?: Maybe<FuelProgramCustomerConfigurationModel>;
  readonly getEstimatedFuel?: Maybe<EstimatedFuelSurcharge>;
  readonly hasQualifiedFuelProgram?: Maybe<EligibleFuelProgramResponse>;
  readonly getFinalizedFuel?: Maybe<FinalizedFuelSurcharge>;
  readonly getHistoricalFuelIndexPricesByFuelIndexType?: Maybe<ReadonlyArray<HistoricalFuelIndexPriceResponse>>;
  readonly getCustomerFuelRateHeaderResponseByCustomerAndFuelProgram?: Maybe<CustomerFuelRateHeaderResponse>;
  readonly getCustomerFuelRangesByCustomerFuelRateHeaderId?: Maybe<ReadonlyArray<CustomerFuelRangeModel>>;
  /** Get the latest intraday exchange rate. */
  readonly getCurrentRate?: Maybe<CurrentRate>;
  /** Get Mastery's fixed historical rate or the world's actual historical rate */
  readonly getHistoricRate?: Maybe<HistoricRate>;
  /** return facility sublocation activity by its id */
  readonly facilitySublocationActivityById: FacilitySublocationActivity;
  /** return facility sublocation activities by sublocation id */
  readonly facilitySublocationActivities: FacilitySublocationActivityConnection;
  /** return facility sublocation accommodation by its id */
  readonly facilitySublocationAccommodationById: FacilitySublocationAccommodation;
  /** return facility sublocation accommodations by sublocation id */
  readonly facilitySublocationAccommodations: FacilitySublocationAccommodationConnection;
  /** return facility sublocation by its id */
  readonly facilitySublocationById: FacilitySublocation;
  /** return all the sublocations by facility Id */
  readonly sublocationsByFacilityId: ReadonlyArray<FacilitySublocation>;
  /** return facility sublocation gate by its id */
  readonly facilitySublocationGateById: FacilitySublocationGate;
  /** return facility sublocation gates by sublocation id */
  readonly facilitySublocationGates: FacilitySublocationGateConnection;
  readonly allFacilitiesV2: FacilityConnectionV2;
  readonly facilityV2?: Maybe<FacilityV2>;
  readonly facilityByCodeV2?: Maybe<FacilityV2>;
  readonly facilitiesV2: ReadonlyArray<FacilityV2>;
  readonly tenantExceptionRecord: TenantExceptionRecord;
  readonly tenantExceptionRecords: ReadonlyArray<TenantExceptionRecord>;
  readonly GenerateETA?: Maybe<EtaCalculatorResponse>;
  readonly generateHoSInferredETA?: Maybe<Scalars['String']>;
  /** Get tenant level default configuration for a given domain */
  readonly getTenantConfiguration?: Maybe<TenantConfigurationResult>;
  /** Get default configuration for a given entity type */
  readonly getDefaultConfiguration?: Maybe<DefaultConfigurationResult>;
  /** Get configuration by uuid, or active configuration if no uuid supplied */
  readonly getEntityConfiguration?: Maybe<EntityConfigurationResult>;
  /** Gets all configurations for a given entity */
  readonly getAllEntityConfigurations: ReadonlyArray<Maybe<EntityConfigurationResult>>;
  readonly getCurrentEntityConfigurationVersion?: Maybe<Scalars['String']>;
  readonly allEmployeesV2?: Maybe<EmployeeConnectionV2>;
  readonly employeeV2?: Maybe<EmployeeV2>;
  /** @deprecated Moved to AllEmployees */
  readonly employeesV2?: Maybe<ReadonlyArray<EmployeeV2>>;
  readonly exactEmployeeV2?: Maybe<EmployeeV2>;
  readonly usersV2: UserConnectionV2;
  readonly exactUserV2?: Maybe<UserV2>;
  readonly whoAmIV2?: Maybe<WhoAmIv2>;
  /** Retreives preview data of EDI message for given EDI DocumentId */
  readonly EDIMessagePreview?: Maybe<EdiPreviewData>;
  /** Retreives a list of all EDI Outbox messages eligible for retry */
  readonly EdiOutboxMessagesForRetry?: Maybe<ReadonlyArray<Maybe<EdiOutbox>>>;
  /** Retreives a list of all EDI Tenders based on given Shipment Id and Scac code */
  readonly EDITenderByShipmentIdAndScac?: Maybe<ReadonlyArray<Maybe<EdiTender>>>;
  /** Retreives a list of all EDI Transaction for given load ID */
  readonly EDITransactions?: Maybe<ReadonlyArray<Maybe<EdiTransaction>>>;
  /** Retreives a list of all EDI Transaction for given shipment ID */
  readonly EDITransactionsByShipmentId?: Maybe<ReadonlyArray<Maybe<EdiTransaction>>>;
  readonly getDriver: ReadonlyArray<AssetDriver>;
  readonly getDriverById: AssetDriver;
  readonly searchDriver: ReadonlyArray<DriverSearchResult>;
  readonly checkDriverIdentity: DriverValidation;
  readonly getDriverOperations: AssetDriver;
  readonly searchDriverByName: ReadonlyArray<DriverSearchResult>;
  readonly searchDriverById: ReadonlyArray<DriverSearchResult>;
  readonly getDriverFacilityPreferences: ReadonlyArray<DriverFacilityPreferences>;
  readonly getDriverCustomerPreferences: ReadonlyArray<DriverCustomerPreferences>;
  readonly getTrailers: ReadonlyArray<DriverKeyValuePairOfString>;
  readonly getPowers: ReadonlyArray<DriverKeyValuePairOfString>;
  readonly getDriverPreferredLanes: ReadonlyArray<DriverPreferredLanes>;
  readonly getDriverPreferredRoutes: ReadonlyArray<DriverPreferredRoutes>;
  readonly getActiveDriverPreferredLanes: ReadonlyArray<DriverPreferredLanes>;
  readonly getActiveDriverPreferredRoutes: ReadonlyArray<DriverPreferredRoutes>;
  readonly getDriverByCode: AssetDriver;
  readonly getDriverWeeklyTargets: ReadonlyArray<DriverWeeklyTarget>;
  readonly getDailyActivitiesByWeeklyTargetId: ReadonlyArray<DriverDailyActivity>;
  readonly getDriverTrainings: ReadonlyArray<DriverTraining>;
  readonly crmDocuments: CrmDocumentConnection;
  readonly getCRMDocumentById: CrmDocument;
  readonly documents: ReadonlyArray<Document>;
  readonly ediDocuments: EdiDocumentConnection;
  readonly getEDIDocumentById: EdiDocument;
  readonly loadDocuments: LoadDocumentConnection;
  readonly getLoadDocumentById?: Maybe<LoadDocument>;
  readonly carrierDocuments: CarrierDocumentConnection;
  readonly getCarrierDocumentById: CarrierDocument;
  readonly facilityDocuments: FacilityDocumentConnection;
  readonly getFacilityDocumentById: FacilityDocument;
  readonly csvHeaders?: Maybe<CsvHeader>;
  /** getCustomerDriverLocationFrequencyConfiguration: retrieve customer's driver location frequency configuration in Mastermind. */
  readonly getCustomerDriverLocationFrequencyConfiguration?: Maybe<DriverLocationFrequency>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly customerByCodeV2?: Maybe<CustomerV2>;
  readonly allCustomersV2?: Maybe<CustomerConnectionV2>;
  readonly customerStructures: ReadonlyArray<CustomerStructure>;
  readonly customerStructuresByCustomerId: ReadonlyArray<CustomerStructure>;
  readonly customerDefaultNotes: ReadonlyArray<NoteDefault>;
  readonly notesByCustomerId: ReadonlyArray<NoteDefault>;
  readonly customerRegionsByCustomerId: ReadonlyArray<CustomerRegion>;
  readonly customerRegions: ReadonlyArray<CustomerRegion>;
  readonly customerFacilitiesByCustomerId: ReadonlyArray<CustomerFacility>;
  readonly customerFacilities: CustomerFacilityConnection;
  readonly customerCommoditiesByCustomerId: ReadonlyArray<CustomerCommodity>;
  readonly customerCommoditiesByFacilityId: ReadonlyArray<CustomerCommodity>;
  readonly customerCommodities: CustomerCommodityConnection;
  readonly customerCommoditiesByCustomerFacilityId: ReadonlyArray<CustomerCommodity>;
  readonly loadDefaults: ReadonlyArray<LoadDefaults>;
  readonly loadDefaultsByCustomerId?: Maybe<LoadDefaults>;
  readonly loadDefaultsByCustomerIdV2?: Maybe<ReadonlyArray<LoadDefaults>>;
  readonly customerCustomerRelationshipByCustomerId: ReadonlyArray<CustomerCustomerRelationship>;
  readonly customerCustomerRelationships: ReadonlyArray<CustomerCustomerRelationship>;
  readonly carrierFacilitiesByCarrierId: ReadonlyArray<CarrierFacility>;
  readonly carrierFacilitiesByFacilityId: ReadonlyArray<CarrierFacility>;
  readonly carrierFacilities: ReadonlyArray<CarrierFacility>;
  readonly facilityDefaultNotes: ReadonlyArray<FacilityNoteDefaults>;
  readonly facilityNotesByFacilityId?: Maybe<FacilityNoteDefaults>;
  readonly facilityNotesByFacilityIdV2?: Maybe<ReadonlyArray<FacilityNoteDefaults>>;
  readonly facilityLoadDefaults: FacilityLoadDefaultsConnection;
  readonly facilityLoadDefaultsByFacilityId?: Maybe<FacilityLoadDefaults>;
  readonly facilityLoadDefaultsByFacilityIdV2?: Maybe<ReadonlyArray<FacilityLoadDefaults>>;
  readonly customerCommitments?: Maybe<CustomerCommitmentConnection>;
  readonly customerCommitmentsSettings?: Maybe<CustomerCommitmentsSettings>;
  readonly customerCommitmentsById?: Maybe<CustomerCommitment>;
  readonly customerCommitmentOrdersById?: Maybe<CustomerCommitmentOrders>;
  readonly customerCapacityConstraintById?: Maybe<CapacityConstraint>;
  readonly customerCommitmentAcceptedOrders?: Maybe<ReadonlyArray<Maybe<AcceptedOrders>>>;
  readonly exportCustomerCommitments?: Maybe<CustomerCommitmentConnection>;
  readonly crmOpportunity: ReadonlyArray<CrmOpportunityOutput>;
  readonly crmOpportunityByEntityId: ReadonlyArray<CrmOpportunityOutput>;
  readonly crmOpportunityByOpportunityId: ReadonlyArray<CrmOpportunityOutput>;
  readonly validateCrmOpportunityName: Scalars['Boolean'];
  readonly crmOpportunityV2: ReadonlyArray<CrmOpportunityOutputV2>;
  readonly crmOpportunityByEntityIdV2: ReadonlyArray<CrmOpportunityOutputV2>;
  readonly crmOpportunityByOpportunityIdV2: ReadonlyArray<CrmOpportunityOutputV2>;
  readonly crmRepsByOpportunityIdV2: ReadonlyArray<CrmOpportunityRepsOutputV2>;
  readonly validateCrmOpportunityNameV2: Scalars['Boolean'];
  readonly validateCrmOpportunityNameByEntityIdV2: Scalars['Boolean'];
  readonly crmInteraction: ReadonlyArray<CrmInteractionOutput>;
  readonly crmInteractionById: ReadonlyArray<CrmInteractionOutput>;
  readonly crmInteractionByEntityId: ReadonlyArray<CrmInteractionOutput>;
  readonly crmInteractionByStatus: ReadonlyArray<CrmInteractionOutput>;
  readonly crmInteractionContact: ReadonlyArray<CrmInteractionContactOutput>;
  readonly crmInteractionRepGroupLookup: ReadonlyArray<CreateInteractionRepGroupLookupOutput>;
  readonly crmInteractionObjective: ReadonlyArray<CrmInteractionObjectiveOutput>;
  readonly crmInteractionRepGroupAuditHistory: ReadonlyArray<CrmInteractionRepGroupOutput>;
  readonly crmInteractionEntityLookup: ReadonlyArray<Create_Crm_Interaction_Entity_Lookup_Output>;
  readonly crmInteractionRescheduleAuditHistory: ReadonlyArray<CrmInteractionRescheduleOutput>;
  readonly crmCompanyDescription: ReadonlyArray<CrmCompanyDescriptionOutput>;
  readonly crmCompanyDescriptionByEntityId: ReadonlyArray<CrmCompanyDescriptionOutput>;
  readonly crmDetailedDescription: ReadonlyArray<CrmDetailedDescriptionOutput>;
  readonly crmDetailedDescriptionByEntityId: ReadonlyArray<CrmDetailedDescriptionOutput>;
  readonly crmFirmographics: ReadonlyArray<CrmFirmographicsOutput>;
  readonly crmFirmographicsByEntityId: ReadonlyArray<CrmFirmographicsOutput>;
  readonly crmDetailsEntityLookup: ReadonlyArray<CrmDetailsEntityLookupOutput>;
  readonly allContacts: ReadonlyArray<CrmContactOutput>;
  readonly getContactById: ReadonlyArray<CrmContactOutput>;
  readonly getContactsByName: ReadonlyArray<CrmContactOutput>;
  readonly getContactsByEntityId: ReadonlyArray<CrmContactOutput>;
  readonly getContactInformationTypes: ReadonlyArray<CrmContactInformationTypeOutput>;
  readonly getResponsibilitiesByContactId: ReadonlyArray<CrmContactResponsibilityOutput>;
  readonly contactEntityLookup: ReadonlyArray<CrmContactEntityLookupOutput>;
  readonly associatedRepsByContactId: ReadonlyArray<CrmContactAssociatedRepOutput>;
  readonly associatedRepsByContactIdandStatus: ReadonlyArray<CrmContactAssociatedRepOutput>;
  readonly associatedEntitiesByContactId: ReadonlyArray<CrmContactAssociatedEntityOutput>;
  readonly workHoursByContactId: ReadonlyArray<CrmContactWorkHoursOutput>;
  readonly contactInformationByContactInformationTypeId: ReadonlyArray<CrmContactInformationOutput>;
  readonly contactInformationById: ReadonlyArray<CrmContactInformationOutput>;
  readonly contactInformationTypes: ReadonlyArray<CrmContactInformationTypeOutput>;
  readonly contactInformationByContactId: ReadonlyArray<CrmContactInformationOutput>;
  readonly duplicateContactsIfExists: ReadonlyArray<CrmContactOutputV2>;
  readonly allContactsv2: ReadonlyArray<CrmContactOutputV2>;
  readonly contactByIdv2: ReadonlyArray<CrmContactOutputV2>;
  readonly contactsByNamev2: ReadonlyArray<CrmContactOutputV2>;
  readonly contactsByEntityIdv2: ReadonlyArray<CrmContactOutputV2>;
  readonly contactEntityLookupv2: ReadonlyArray<CrmContactEntityLookupOutputV2>;
  readonly associatedRepsByContactIdv2: ReadonlyArray<CrmContactAssociatedRepOutputV2>;
  readonly associatedRepsByContactIdandStatusv2: ReadonlyArray<CrmContactAssociatedRepOutputV2>;
  readonly associatedEntitiesByContactIdv2: ReadonlyArray<CrmContactAssociatedEntityOutputV2>;
  readonly workHoursByContactIdv2: ReadonlyArray<CrmContactWorkHoursOutputv2>;
  readonly contactInformationByContactInformationTypeIdv2: ReadonlyArray<CrmContactInformationOutputV2>;
  readonly contactInformationByIdv2: ReadonlyArray<CrmContactInformationOutputV2>;
  readonly contactInformationTypesv2: ReadonlyArray<CrmContactInformationTypeOutputV2>;
  readonly contactInformationByContactIdv2: ReadonlyArray<CrmContactInformationOutputV2>;
  readonly duplicateContactsIfExistsv2: ReadonlyArray<CrmContactOutputV2>;
  readonly responsibilitiesByContactIdv2: ReadonlyArray<CrmContactResponsibilityOutputV2>;
  /** mastery-crm: calculateNextCarrierInteractionDate - Returns calculated next carrier interaction date. */
  readonly calculateNextCarrierInteractionDate?: Maybe<NextCarrierInteractionDate>;
  /** mastery-crm: calculateNextCustomerInteractionDate - Returns calculated next customer interaction date. */
  readonly calculateNextCustomerInteractionDate?: Maybe<NextCustomerInteractionDate>;
  /** mastery-crm: carrierNotes - Retrieves a list of carrier Notes. */
  readonly carrierCRMNotes?: Maybe<CarrierCrmNotesConnection>;
  /** mastery-crm: carrierOpportunities - Retrieves a list of carrier Opportunities. */
  readonly carrierCRMOpportunities?: Maybe<CarrierCrmOpportunityConnection>;
  /** mastery-crm: carrierInteractions - Retrieves a list of carrier interactions. */
  readonly carrierInteractions?: Maybe<CarrierInteractionConnection>;
  /** mastery-crm: CarrierDetails - Retrieves Carrier Details. */
  readonly crmCarrierDetails?: Maybe<CrmCarrierDetail>;
  /** mastery-crm: crmCustomerDetails - Retrieves customer Details. */
  readonly crmCustomerDetails?: Maybe<CrmCustomerDetail>;
  /** mastery-crm: customerOpportunities - Retrieves a list of customer Opportunities. */
  readonly customerCRMOpportunities?: Maybe<CustomerCrmOpportunityConnection>;
  /** mastery-crm: customerInteractions - Retrieves a list of customer interactions. */
  readonly customerInteractions?: Maybe<CustomerInteractionConnection>;
  /** mastery-crm: getCarrierCrmDocuments - Retrieves a list of carrier documents. */
  readonly getCarrierCrmDocuments?: Maybe<CarrierCrmDocumentConnection>;
  /** mastery-crm: getCarrierPortfolio - Retrieves a list of carriers and their last interaction if available. */
  readonly getCarrierPortfolio?: Maybe<CarrierPortfolio>;
  /** mastery-crm: getCustomerCrmDocuments - Retrieves a list of customer documents. */
  readonly getCustomerCrmDocuments?: Maybe<CustomerCrmDocumentConnection>;
  /** mastery-crm: getCustomerCrmNotes - Retrieves a list of customer notes. */
  readonly getCustomerCrmNotes?: Maybe<CustomerCrmNoteConnection>;
  /** mastery-crm: getCustomerPortfolio - Retrieves a list of customers and their last interaction if available. */
  readonly getCustomerPortfolio?: Maybe<CustomerPortfolio>;
  /** mastery-crm: validateCarrierOpportunityName - Checks whether the opportunity name for Carrier is unique or not for particular carrier. */
  readonly validateCarrierCRMOpportunityNameAvailablity?: Maybe<Scalars['Boolean']>;
  /** mastery-crm: validateCustomerOpportunityName - Checks whether the opportunity name for Customer is unique or not for particular customer. */
  readonly validateCustomerCRMOpportunityNameAvailablity?: Maybe<Scalars['Boolean']>;
  readonly getCreditAvailability: CreditAvailability;
  readonly costQuotePaginated: CostQuoteConnection;
  readonly getCostQuoteById?: Maybe<CarrierQuoteResponse>;
  readonly getActiveCostQuotes?: Maybe<ReadonlyArray<Maybe<ActiveCostQuote>>>;
  /** Resolves when the input seconds has elapsed */
  readonly waitForSeconds?: Maybe<Scalars['Boolean']>;
  readonly testFieldLevelCache?: Maybe<FieldCachedType>;
  readonly testTypeLevelCache?: Maybe<TypeCachedType>;
  readonly testJsonObject?: Maybe<TestTypeWithJson>;
  readonly testJson?: Maybe<TestTypeWithJson>;
  readonly testUserContextHeaders?: Maybe<TestValidatedHeaderType>;
  readonly testProxyUserContextHeaders?: Maybe<TestValidatedHeaderType>;
  /** mastery-commissions: Retrieves a Global Configuration by mode, size, division. */
  readonly getCommissionsGlobalConfiguration?: Maybe<CommissionsGlobalConfiguration>;
  /** mastery-commissions: Retrieves a collection of Customer Overhead Configurations by filter.CustomerId. */
  readonly getCommissionsCustomerOverheadConfigurations?: Maybe<ReadonlyArray<Maybe<CommissionsCustomerOverheadConfiguration>>>;
  /** mastery-commissions: Retrieves all active Global Configurations. */
  readonly getAllActiveCommissionsGlobalConfigurations?: Maybe<ReadonlyArray<Maybe<CommissionsGlobalConfiguration>>>;
  /** mastery-commissions: Retrieves the active load allocated margin (along with order/route margin info). */
  readonly getActiveCommissionsLoadAllocatedMargins?: Maybe<LoadAllocatedMargins>;
  /** mastery-commissions: Retrieves the active load allocated margin (along with employee commissions and order/route margin info). */
  readonly getActiveCommissionsByLoadId?: Maybe<LoadAllocatedMargins>;
  /** mastery-commissions: Retrieves the active assignments for a particular employee. */
  readonly getEmployeeCommissionPlanByEmployeeId?: Maybe<EmployeeCommissionPlan>;
  /** mastery-commissions: Retrieves the employee IDs actively assigned to a particular commission plan. */
  readonly getEmployeesAssignedToCommissionPlanByPlanId?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  /** mastery-commissions: Retrieves all assignable commission plans. */
  readonly getAllAssignableCommissionPlanConfigurations?: Maybe<ReadonlyArray<Maybe<EmployeeCommissionPlanConfiguration>>>;
  /** mastery-commissions: Retrieves the employee commission plan. */
  readonly getEmployeeCommissionPlanConfiguration?: Maybe<EmployeeCommissionPlanConfiguration>;
  /** mastery-commissions: Calculates the commissions for a specific load with the specified what if parameters sent in WhatIf argument. */
  readonly getWhatIfLoadCommissionsCalculation?: Maybe<WhatIfScenario>;
  /** mastery-commissions: Calculates the allocated margins for a specific load with the specified what if parameters sent in WhatIf argument. */
  readonly getWhatIfLoadAllocatedMargins?: Maybe<WhatIfScenario>;
  /** mastery-commissions: Retrieves the load commission override configuration for a specific load with the specified load id sent in as argument. */
  readonly getLoadCommissionsOverrideConfigurationByLoadId?: Maybe<LoadCommissionsOverrideConfiguration>;
  /** mastery-commissions: Retrieves the overhead on a commission for a load (used for Money Summary). */
  readonly getLoadCommissionsMoneySummaryOverhead?: Maybe<CommissionsOverhead>;
  /** mastery-commissions: Retrieves the overheads on a commissions for a list of loads (used for Money Summaries). */
  readonly getLoadCommissionsMoneySummaryOverheads?: Maybe<ReadonlyArray<Maybe<CommissionsOverhead>>>;
  readonly clientExceptionRecord: ClientExceptionRecord;
  readonly clientExceptionRecords: ClientExceptionRecordConnection;
  readonly getGlobalTenantChargeTypeConfiguration?: Maybe<TenantChargeTypeConfigurationResponse>;
  readonly getAllGlobalTenantChargeTypeConfigurations?: Maybe<ReadonlyArray<Maybe<TenantChargeTypeConfigurationResponse>>>;
  readonly getAllTenantChargeTypeConfigurations?: Maybe<ReadonlyArray<Maybe<TenantChargeTypeConfigurationResponse>>>;
  readonly getTenantChargeTypeConfiguration?: Maybe<TenantChargeTypeConfigurationResponse>;
  readonly getAllPriorityConfiguration?: Maybe<ReadonlyArray<Maybe<PriorityConfigurationResponse>>>;
  readonly getPriorityConfiguration?: Maybe<PriorityConfigurationResponse>;
  readonly getCarrierChargeTypeConfiguration?: Maybe<CarrierChargeTypeConfigurationResponse>;
  readonly getAllCarrierChargeTypeConfigurations?: Maybe<ReadonlyArray<Maybe<CarrierChargeTypeConfigurationResponse>>>;
  readonly getCustomerChargeTypeConfiguration?: Maybe<CustomerChargeTypeConfigurationResponse>;
  readonly getAllCustomerChargeTypeConfigurations?: Maybe<ReadonlyArray<Maybe<CustomerChargeTypeConfigurationResponse>>>;
  readonly getRateDetailChargeTypeConfigurations?: Maybe<ReadonlyArray<Maybe<RateDetailChargeTypeConfigurationResponse>>>;
  readonly getCostDetailChargeTypeConfigurations?: Maybe<ReadonlyArray<Maybe<CostDetailChargeTypeConfigurationResponse>>>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly carrierNullableV2?: Maybe<CarrierV2>;
  readonly carrierRepsV2: ReadonlyArray<RepV2>;
  /** @deprecated Moved to CarrierType */
  readonly carrierSettingV2?: Maybe<CarrierSettingV2>;
  /** Query implemented by Rails Decommissioning, similar to geographies query of mastery-api. */
  readonly geographiesRD?: Maybe<ReadonlyArray<GeographyV2>>;
  /** Query implemented by Rails Decommissioning, similar to geography query of mastery-api. */
  readonly geographyRD: GeographyV2;
  readonly carriersNullableV2?: Maybe<CarrierConnectionV2>;
  readonly carrierParentsByCarrierId: ReadonlyArray<ParentRelationship>;
  readonly validationConfigurations: ReadonlyArray<ValidationConfiguration>;
  readonly validationConfiguration?: Maybe<ValidationConfiguration>;
  readonly searchValidationConfigurations: ReadonlyArray<ValidationConfiguration>;
  readonly searchValidationAuditHistory: ReadonlyArray<ValidationAuditHistory>;
  readonly searchValidationAuditHistoryByHashId: ReadonlyArray<ValidationAuditHistory>;
  readonly lock?: Maybe<Lock>;
  readonly bookingValidationRules: BookingValidationRulesPayload;
  readonly expectedReadyValidations: ExpectedReadyValidationsResponse;
  readonly routeVendorV2?: Maybe<RouteVendorV2>;
  /** Returns an array of RouteVendors based on search criteria */
  readonly routeVendorsV2: RouteVendorV2Connection;
  readonly bounceV2?: Maybe<BounceV2>;
  readonly bouncesV2: BouncesV2Connection;
  readonly book?: Maybe<Book>;
  readonly allBlumeChargeTypes?: Maybe<AllBlumeChargeTypesPayload>;
  readonly getLoadStatusRecordsAudit: ReadonlyArray<LoadStatusRecord>;
  readonly aslSearchRoutes: AslRouteViewResult;
  readonly aslSearchTrackingUpdates: AslTrackingUpdatesViewResult;
  readonly tenderOptions?: Maybe<TenderOptions>;
  readonly tenderOptionsByParentObject?: Maybe<TenderOptions>;
  readonly defaultTenderOptions?: Maybe<TenderOptions>;
  readonly activeTenderPlan?: Maybe<TenderPlan>;
  readonly tenderPlan?: Maybe<TenderPlan>;
  readonly outboundTenderSettings?: Maybe<OutboundTenderSettings>;
  readonly routingGuideV2?: Maybe<RoutingGuide>;
  readonly routingGuideV3?: Maybe<RoutingGuide>;
  readonly laneRoutingGuides: RoutingGuidesConnection;
  readonly exportRoutingGuides: RoutingGuidesConnection;
  /**
   * Deprecated - Use getPatternV3
   * @deprecated Use getPatternV3
   */
  readonly getPatternV2: Pattern;
  /**
   * Deprecated - Use getPatternsByLaneV2
   * @deprecated Use getPatternsByLaneV2
   */
  readonly getPatternsByLane: ReadonlyArray<Maybe<Pattern>>;
  /**
   * Deprecated - Use getPatternLaneV2
   * @deprecated Use getPatternLaneV2
   */
  readonly getPatternLane?: Maybe<GetPatternLanePayload>;
  readonly getPatternV3: GetPatternPayload;
  readonly getPatternsByLaneV2: GetPatternsByLanePayloadV2;
  readonly getPatternsByOrderV1: GetPatternsByOrderPayloadV1;
  readonly getPatternLaneV2?: Maybe<GetPatternLanePayloadV2>;
  readonly carrierCapacityConstraintByCostQuote?: Maybe<CarrierCapacity>;
  readonly carrierCapacityConstraintByCarrierLaneDate?: Maybe<CarrierCapacityRemaining>;
  readonly carrierCapacityRemainingByCostQuoteAndDate?: Maybe<CarrierCapacityRemaining>;
  readonly getCustomerAccessorialSettings?: Maybe<CustomerAccessorialSettingsPayload>;
  readonly getRatingSourceIdByCustomer?: Maybe<RatingSourceIdByCustomerResponse>;
  readonly getAbacusPricing?: Maybe<GetAbacusPricingPayload>;
  readonly getAbacusPricingV2?: Maybe<GetAbacusPricingPayload>;
  /** mastery-accounting: accountingExceptions - Retrieves accounting exceptions based on provided filters */
  readonly accountingExceptions?: Maybe<AccountingExceptionConnection>;
  /** mastery-accounting: adjustmentInterfaces - Retrieves the configured Adjustment Interfaces */
  readonly adjustmentInterfaces: ReadonlyArray<AdjustmentInterface>;
  /** mastery-accounting: advance - Retrieves a specific Advance based on the id. */
  readonly advance?: Maybe<Advance>;
  /** mastery-accounting: advanceInterfaces - Retrieves the configured Advance Interfaces for an Advance Provider */
  readonly advanceInterfaces: ReadonlyArray<AdvanceInterface>;
  /** mastery-accounting: advanceProviderConfig - retrieves an advance provider config. */
  readonly advanceProviderConfig?: Maybe<AdvanceProviderConfig>;
  /** mastery-accounting: advances - Retrieves a list of advances based on the filter parameter. */
  readonly advances?: Maybe<AdvanceConnection>;
  /**
   * mastery-accounting: chargeTypeGLConfigurations - Retrieves a collection of charge type GL configurations (optionally can retrieve a single charge type by passing the chargeTypeId)
   * @deprecated This should not be used as we are transition to Charge Type Configuration service: Use getGlobalTenantChargeTypeConfiguration
   */
  readonly chargeTypeGLConfigurations: ReadonlyArray<Maybe<ChargeType>>;
  /** mastery-accounting: costDetails - Retrieves a list of cost details. */
  readonly costDetails: ReadonlyArray<Maybe<CostDetail>>;
  /** mastery-accounting: customerCreditMemo - Retrieves a customer credit memo by invoice id. */
  readonly customerCreditMemo: CustomerCreditMemo;
  /**
   * mastery-accounting: customerInvoices - Retrieves a filtered list of customer invoices.
   * @deprecated Use customerInvoicesQueue instead
   */
  readonly customerInvoices?: Maybe<ReadonlyArray<Maybe<InvoiceHeader>>>;
  /** mastery-accounting: customerInvoicesQueue - Retrieves a paginated filtered list of customer invoices. */
  readonly customerInvoicesQueue?: Maybe<InvoiceHeaderConnection>;
  /** mastery-accounting: customerOrderRequiredDocuments - Retrieves the required documents for a customer order */
  readonly customerOrderRequiredDocuments?: Maybe<ReadonlyArray<Maybe<CustomerOrderRequiredDocument>>>;
  /** mastery-accounting: customerPayments - Retrieves a filtered list of customer payments */
  readonly customerPayments?: Maybe<ReadonlyArray<Maybe<CustomerPayment>>>;
  /** mastery-accounting: customerRequiredDocuments - Retrieves a list of customer required document settings by a customer id. */
  readonly customerRequiredDocuments?: Maybe<ReadonlyArray<Maybe<CustomerRequiredDocument>>>;
  /** mastery-accounting: customerSettlementQueue - Retrieves a list of open orders. */
  readonly customerSettlementQueue?: Maybe<OrderConnection>;
  /** mastery-accounting: Accounting Internal Configurations  - Retrieves list of configurations used only in accounting. */
  readonly getAccountingTenantConfiguration?: Maybe<TenantConfigurationsModel>;
  /** mastery-accounting: getAllAdvanceProviderConfigs - retrieves all advance provider configs for the tenant. */
  readonly getAllAdvanceProviderConfigs?: Maybe<ReadonlyArray<Maybe<AdvanceProviderConfig>>>;
  /** mastery-accounting: Get All AutoAssociation approved users */
  readonly getAllAutoAssociationUsers: ReadonlyArray<AutoAssociationUser>;
  /** mastery-accounting: Get all Extract Configurations */
  readonly getAllExtractConfigurations?: Maybe<FileExtractConfiguration>;
  /** mastery-accounting: Get All Jurisdiction Taxes */
  readonly getAllJurisdictionTaxes?: Maybe<ReadonlyArray<Maybe<JurisdictionTax>>>;
  /** mastery-accounting: applicableTolerance - Retrieves a single combined tolerance which is the applicable tolerance for the given routeId and vendorId. */
  readonly getApplicableTolerance?: Maybe<ApplicableRouteVendorTolerance>;
  /** mastery-accounting: Get 'Bill To Code Audit History' for a specific customer */
  readonly getBillToCodeHistoryByCustomerId?: Maybe<ReadonlyArray<Maybe<BillToCodeHistory>>>;
  /** mastery-accounting: Get 'Bill To Codes' for a specific customer */
  readonly getBillToCodesByCustomerId?: Maybe<ReadonlyArray<Maybe<BillToCode>>>;
  /** mastery-accounting: routeVendorCost - Retrieves a single RouteVendorCost by routeId and vendorId with the latest threshold calculations. */
  readonly getCalculatedRouteVendorThresholds?: Maybe<RouteVendorCost>;
  /** mastery-accounting: Calculate VAT Configuration Checked - Retrieves the default for calculate VAT to be unchecked / checked */
  readonly getCalculateVatCheckedConfiguration?: Maybe<CalculateVatCheckedModel>;
  /**
   * mastery-accounting: getChargeTypeById - Retrieves chargeType by Id -- This is required for Apollo Federation (chargeType is an exposed type)
   * @deprecated This should not be used as we are transition to Charge Type Configuration service: Use getGlobalTenantChargeTypeConfiguration
   */
  readonly getChargeTypeById: ChargeType;
  /** mastery-accounting: Create Adjustment Items Checked  - Retrieves the default for create adjustment items to be unchecked / checked */
  readonly getCreateAdjustmentItemsCheckedOption?: Maybe<CreateAdjustmentItemsChecked>;
  /** mastery-accounting: getCustomerInvoiceForResend - Retrieves a customer invoice and rate details. */
  readonly getCustomerInvoiceForResend?: Maybe<InvoiceHeader>;
  /** mastery-accounting: getCustomerInvoicesForResend - Retrieves a customer invoice and rate details. */
  readonly getCustomerInvoicesForResend?: Maybe<ReadonlyArray<Maybe<InvoiceHeader>>>;
  /** mastery-accounting: default voucher date  - Retrieves list of date option for setting default voucher date */
  readonly getdefaultvoucherdate?: Maybe<VoucherDateDefault>;
  /** mastery-accounting: Get the Exchange Rate Configurations - Retrieves the configuration for multi-currency exchange rate dates */
  readonly getExchangeRateConfigurations: ReadonlyArray<ExchangeRateConfiguration>;
  /** mastery-accounting: Finalized Fuel Status - Retrieves the finalized fuel status for an order */
  readonly getFinalizedFuelStatus?: Maybe<FinalizedFuelStatus>;
  /** mastery-accounting: Get Integration Mappings by Group Name */
  readonly getIntegrationMappings?: Maybe<ReadonlyArray<Maybe<IntegrationMapping>>>;
  /** mastery-accounting: Invoice Distribution history - Retrieves the invoice distribution history created by audit model */
  readonly getInvoiceDistributionhistoryById: ReadonlyArray<InvoiceDistributionHistory>;
  /** mastery-accounting: order - Retrieves a single Order by orderId */
  readonly getOrder?: Maybe<Order>;
  /**
   * mastery-accounting: getOrphanedEntities - This query is only intended to be used during the interim until Kafka is fully implemented
   * @deprecated This query should not be used; it is only intended for diagnosing orphaned records while Kafka is being built out.
   */
  readonly getOrphanedEntities?: Maybe<OrphanedEntitiesModel>;
  /** mastery-accounting: partial voucher information - Retrieves the information needed to create a partial voucher */
  readonly getPartialVoucherInformation: PartialVoucherModel;
  /** mastery-accounting: partial voucher information - Retrieves the information needed to update a partial voucher */
  readonly getPartialVoucherInformationByBatchId: PartialVoucherModel;
  /** mastery-accounting: routeVendorCost - Retrieves a single RouteVendorCost by routeId and vendorId */
  readonly getRouteVendorCost?: Maybe<RouteVendorCost>;
  /** mastery-accounting: Get All Taxability Charge Types */
  readonly getTaxabilityChargeTypes?: Maybe<ReadonlyArray<Maybe<TaxabilityChargeTypeOutput>>>;
  /** mastery-accounting: Get 'Auto Voucher' for a specific vendor */
  readonly getVendorAutoVoucher?: Maybe<VendorAutoVoucherType>;
  /** mastery-accounting: vendor invoice voucher - Retrieves the reference between vendor invoice and vouchers */
  readonly getVendorInvoiceVouchers: ReadonlyArray<VendorInvoiceVoucher>;
  /** mastery-accounting: Get 'Use_Global' Toggle for a specific vendor */
  readonly getVendorUseGlobalToggle?: Maybe<VendorUseGlobalType>;
  /** mastery-accounting: invoiceBatches - Retrieves pending invoice batches. */
  readonly invoiceBatches: ReadonlyArray<Maybe<InvoiceBatch>>;
  /** mastery-accounting: loadMoneySummary - Retrieves a financial summary of the load */
  readonly loadMoneySummary?: Maybe<LoadMoneySummary>;
  /** mastery-accounting: loadRequiredDocumentsSummary - Retrieves the required documents summary for all customer orders and vendor routes associated with the load */
  readonly loadRequiredDocumentsSummary?: Maybe<ReadonlyArray<Maybe<LoadRequiredDocumentsSummary>>>;
  /** mastery-accounting: rateDetails - Retrieves a list of rate details. */
  readonly rateDetails: ReadonlyArray<Maybe<RateDetail>>;
  /**
   * mastery-accounting: routeMaxCost - Retrieves the max cost for the specified route/load
   * @deprecated This query can potentially return null; Use routeMaxCostNullable instead.
   */
  readonly routeMaxCost: RouteMaxCostType;
  /** mastery-accounting: routeMaxCost - Retrieves the max cost for the specified route/load */
  readonly routeMaxCostNullable?: Maybe<RouteMaxCostType>;
  /** mastery-accounting: routeVendorMaxCostValidation - Retrieves validation errors for re-adding debounced vendor. */
  readonly routeVendorMaxCostValidation?: Maybe<RouteVendorMaxCostValidation>;
  /** mastery-accounting: segment - Retrieves segmentation segment based on provided ID */
  readonly segment?: Maybe<Segment>;
  /** mastery-accounting: segmentationConfiguration - Retrieves segmentation configuration based on provided ID */
  readonly segmentationConfiguration?: Maybe<SegmentationConfiguration>;
  /** mastery-accounting: segmentationConfiguration - Retrieves segmentation configurations */
  readonly segmentationConfigurations?: Maybe<ReadonlyArray<Maybe<SegmentationConfiguration>>>;
  /** mastery-accounting: segmentValues - Retrieves all segment value mappings */
  readonly segmentValues?: Maybe<ReadonlyArray<Maybe<SegmentValueOutput>>>;
  /** mastery-accounting: segmentValuesForSegment - Retrieves all segment value mappings associated to provided segment ID */
  readonly segmentValuesForSegment?: Maybe<ReadonlyArray<Maybe<SegmentValueOutput>>>;
  /** mastery-accounting: vendorAssociatedRequiredDocuments - Retrieves a list of vendor associated required document settings. */
  readonly vendorAssociatedRequiredDocument?: Maybe<ReadonlyArray<Maybe<VendorAssociatedRequiredDocument>>>;
  /** mastery-accounting: vendorCreditMemo - Retrieves a vendor credit memo by voucher id. */
  readonly vendorCreditMemo: VendorCreditMemo;
  /** mastery-accounting: vendorInvoiceQueue - Retrieves Vendor Invoices. */
  readonly vendorInvoiceQueue?: Maybe<VendorInvoiceConnection>;
  /** mastery-accounting: vendorInvoices - Retrieves vendor invoices based on provided filters */
  readonly vendorInvoices?: Maybe<ReadonlyArray<Maybe<VendorInvoice>>>;
  /** mastery-accounting: vendorPayments - Retrieves a filtered list of vendor payments */
  readonly vendorPayments?: Maybe<ReadonlyArray<Maybe<VendorPayment>>>;
  /** mastery-accounting: vendorRequiredDocuments - Retrieves a list of vendor required document settings. */
  readonly vendorRequiredDocuments?: Maybe<ReadonlyArray<Maybe<VendorRequiredDocument>>>;
  /** mastery-accounting: vendorRouteRequiredDocuments - Retrieves the required documents for a vendor route */
  readonly vendorRouteRequiredDocuments?: Maybe<ReadonlyArray<Maybe<VendorRouteRequiredDocument>>>;
  /** mastery-accounting: vendorRouteRequiredDocumentsByLoadId - Retrieves the required documents for all vendor routes within a load */
  readonly vendorRouteRequiredDocumentsByLoadId?: Maybe<ReadonlyArray<Maybe<VendorRouteRequiredDocument>>>;
  /** mastery-accounting: vendorSettlementQueue - Retrieves a list of open costs. */
  readonly vendorSettlementQueue?: Maybe<RouteVendorCostConnection>;
  /**
   * mastery-accounting: vendorVouchers - Retrieves a filtered list of vendor vouchers.
   * @deprecated Use vendorVouchersQueue instead
   */
  readonly vendorVouchers?: Maybe<ReadonlyArray<Maybe<VoucherHeader>>>;
  /** mastery-accounting: vendorVouchersQueue - Retrieves a paginated filtered list of vendor vouchers. */
  readonly vendorVouchersQueue?: Maybe<VoucherHeaderConnection>;
  /** mastery-accounting: voucherBatches - Retrieves pending voucher batches. */
  readonly voucherBatches: ReadonlyArray<Maybe<VoucherBatch>>;
  /** mastery-accounting: voucherTolerances - Retrieves a list of voucher tolerance settings. */
  readonly voucherTolerances?: Maybe<ReadonlyArray<Maybe<VoucherTolerance>>>;
  /** mastery-accounting: voucherVendorTolerances - Retrieves a list of voucher vendor tolerances. */
  readonly voucherVendorTolerance?: Maybe<ReadonlyArray<Maybe<VoucherVendorTolerance>>>;
  /** mastery-accounting: Extending Carrier - Appends Accounting Status */
  readonly _federatedCarrier?: Maybe<ReadonlyArray<Maybe<Carrier>>>;
  /** mastery-accounting: Extending CarrierV2 - Appends Accounting Status */
  readonly _federatedCarrierV2?: Maybe<ReadonlyArray<Maybe<CarrierV2>>>;
  /** mastery-accounting: Extending Customer - Appends AccountsReceivableOverdue */
  readonly _federatedCustomer?: Maybe<ReadonlyArray<Maybe<Customer>>>;
  /** mastery-accounting: Extending CustomerOrderV2 - Appends accountingOrder */
  readonly _federatedCustomerOrders?: Maybe<ReadonlyArray<Maybe<CustomerOrderV2>>>;
  /** mastery-accounting: Extending Customer V2 - Appends AccountsReceivableOverdue */
  readonly _federatedCustomerV2?: Maybe<ReadonlyArray<Maybe<CustomerV2>>>;
  /** mastery-accounting: loadMoneySummary - Retrieves a financial summary of the load (used for apollo federation) */
  readonly _federatedLoadMoneySummaries?: Maybe<ReadonlyArray<Maybe<LoadMoneySummary>>>;
  /** mastery-accounting: Extending LoadRouteV2 - Appends maxCosts */
  readonly _federatedLoadRoutes?: Maybe<ReadonlyArray<Maybe<LoadRouteV2>>>;
  /** mastery-accounting: orders - Retrieves a list of Orders by orderId */
  readonly _federatedOrders?: Maybe<ReadonlyArray<Maybe<Order>>>;
  /** mastery-accounting: routeMaxCost - Retrieves the max cost for the specified route/load */
  readonly _federatedRouteMaxCosts?: Maybe<ReadonlyArray<Maybe<RouteMaxCostType>>>;
  /** mastery-accounting: routeVendorCosts - Retrieves a list of RouteVendorCost items by RouteVendor */
  readonly _federatedRouteVendorCosts?: Maybe<ReadonlyArray<Maybe<RouteVendorCost>>>;
};


export type QueryGetTruckEntriesForCarrierArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<TruckEntryFilter>;
  sort?: InputMaybe<TruckEntrySort>;
};


export type QueryGetDailyTruckCountForCarrierArgs = {
  carrierId: Scalars['ID'];
  startDatetime: Scalars['String'];
};


export type QueryGetTruckEntryArgs = {
  id?: InputMaybe<Scalars['ID']>;
  sourceId?: InputMaybe<Scalars['String']>;
};


export type QueryGetTruckEntryTemplatesForCarrierArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<TruckEntryTemplateFilter>;
};


export type QueryGetTruckEntryTemplateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  sourceId?: InputMaybe<Scalars['String']>;
};


export type QueryGetContainerByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetDailyContainerCountArgs = {
  carrierId: Scalars['ID'];
  startDatetime: Scalars['String'];
};


export type QueryGetContainersForCarrierArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  filters: ContainerFilter;
  sort?: InputMaybe<ContainerSort>;
};


export type QueryGetGateReservationByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetDailyGateReservationCountArgs = {
  carrierId: Scalars['ID'];
  startDatetime: Scalars['String'];
};


export type QueryGetGateReservationsForCarrierArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  filters: GateReservationFilter;
  sort?: InputMaybe<GateReservationSort>;
};


export type QueryValidateTrailerArgs = {
  request: TrailerValidationArgumentInput;
};


export type QueryHelloTrailerValidationArgs = {
  flag: Scalars['Boolean'];
};


export type QueryGetasset_TrailersbyidArgs = {
  objId: Scalars['String'];
};


export type QueryGetassetTrailerSearchArgs = {
  objPool?: InputMaybe<Scalars['String']>;
  objFleet?: InputMaybe<Scalars['String']>;
  objType?: InputMaybe<Scalars['String']>;
  objNumber?: InputMaybe<Scalars['String']>;
};


export type QueryGetassetTrailerStatusDetailsArgs = {
  objId?: InputMaybe<Scalars['String']>;
};


export type QueryCheckTrailerNumberArgs = {
  trailerId?: InputMaybe<Scalars['String']>;
  trailerNumber?: InputMaybe<Scalars['String']>;
};


export type QueryGetTrailerByCodeArgs = {
  code: Scalars['String'];
};


export type QueryGetTrailerByCodeV2Args = {
  code: Scalars['String'];
};


export type QueryFetchTrackingEtaForStopArgs = {
  nextStopID: Scalars['ID'];
};


export type QueryCalculateDriverAssignmentEtaArgs = {
  input: CalculateDriverAssignmentEtaInput;
};


export type QueryDriverAssignmentArgs = {
  routeID: Scalars['ID'];
};


export type QueryDriverAssignmentForRouteArgs = {
  routeID: Scalars['ID'];
};


export type QueryTrackingUpdatesV4Args = {
  filters?: InputMaybe<TrackingUpdateV4Filter>;
  sort?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryTrackingUpdateByIdv4Args = {
  updateID: Scalars['ID'];
};


export type QueryToursArgs = {
  tourSearchInput: TourSearchInput;
};


export type QueryTimelineEventArgs = {
  id: Scalars['ID'];
};


export type QueryTimelineEventsArgs = {
  filters?: InputMaybe<TimelineEventFilter>;
};


export type QueryTimelineEventV2Args = {
  id: Scalars['ID'];
};


export type QueryTimelineEventsV2Args = {
  filters?: InputMaybe<TimelineEventFilterV2>;
};


export type QueryTasksV2Args = {
  filter?: InputMaybe<GetTaskInputV2>;
};


export type QueryTaskCountV2Args = {
  filter?: InputMaybe<GetTaskInputV2>;
};


export type QueryTasksPaginatedV2Args = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GetTaskInputV2>;
};


export type QueryTaskV2Args = {
  id: Scalars['ID'];
};


export type QueryTasksArgs = {
  filter?: InputMaybe<GetTaskArgsInput>;
};


export type QueryTaskCountArgs = {
  filter?: InputMaybe<GetTaskArgsInput>;
};


export type QueryTasksPaginatedArgs = {
  filter?: InputMaybe<GetTaskArgsInput>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryTaskArgs = {
  id: Scalars['ID'];
};


export type QueryStopEventEngineArgs = {
  input: StopEventEngineInput;
};


export type QueryStopEventV2Args = {
  id: Scalars['ID'];
};


export type QueryStopEventsV2Args = {
  filters?: InputMaybe<StopEventsV2Filter>;
  sort?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QuerySpotQuotesArgs = {
  input: SpotQuotePaginatedInput;
};


export type QuerySpotQuotesPagedArgs = {
  input: SpotQuotesPagedInput;
};


export type QueryExistingQuotesArgs = {
  input: ExistingQuotesInput;
};


export type QueryMatchingSpotQuoteArgs = {
  input: MatchingSpotQuoteInput;
};


export type QuerySpotQuotesByIdsArgs = {
  input: SpotQuotesByIdsInput;
};


export type QuerySeerSearchCarrierShipmentArgs = {
  filter?: InputMaybe<SeerCarrierShipmentFilter>;
  orderBy?: InputMaybe<SeerCarrierShipmentOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchCarrierRouteArgs = {
  filter?: InputMaybe<SeerCarrierRouteFilter>;
  orderBy?: InputMaybe<SeerCarrierRouteOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchCommandLogArgs = {
  filter?: InputMaybe<SeerCommandLogFilter>;
  orderBy?: InputMaybe<SeerCommandLogOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchCurrentAndNextStopArgs = {
  filter?: InputMaybe<SeerCurrentAndNextStopFilter>;
  orderBy?: InputMaybe<SeerCurrentAndNextStopOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchCurrentStopArgs = {
  filter?: InputMaybe<SeerCurrentStopFilter>;
  orderBy?: InputMaybe<SeerCurrentStopOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchCustomerGroupArgs = {
  filter?: InputMaybe<SeerCustomerGroupFilter>;
  orderBy?: InputMaybe<SeerCustomerGroupOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchCustomerRepArgs = {
  filter?: InputMaybe<SeerCustomerRepFilter>;
  orderBy?: InputMaybe<SeerCustomerRepOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchCustomerOrderArgs = {
  filter?: InputMaybe<SeerCustomerOrderFilter>;
  orderBy?: InputMaybe<SeerCustomerOrderOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchFacilityRouteArgs = {
  filter?: InputMaybe<SeerFacilityRouteFilter>;
  orderBy?: InputMaybe<SeerFacilityRouteOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchFacilityTrackingBoardArgs = {
  filter?: InputMaybe<SeerFacilityTrackingBoardFilter>;
  orderBy?: InputMaybe<SeerFacilityTrackingBoardOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchIvMainPageRouteBoardArgs = {
  filter?: InputMaybe<SeerIvMainPageRouteBoardFilter>;
  orderBy?: InputMaybe<SeerIvMainPageRouteBoardOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchIvMprbArgs = {
  filter?: InputMaybe<SeerIvMprbFilter>;
  orderBy?: InputMaybe<SeerIvMprbOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchLinkedRoutesArgs = {
  filter?: InputMaybe<SeerLinkedRoutesFilter>;
  orderBy?: InputMaybe<SeerLinkedRoutesOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchLoadArgs = {
  filter?: InputMaybe<SeerLoadFilter>;
  orderBy?: InputMaybe<SeerLoadOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchLoadSearchArgs = {
  filter?: InputMaybe<SeerLoadSearchFilter>;
  orderBy?: InputMaybe<SeerLoadSearchOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchLoadSearchMoloSniSujanArgs = {
  filter?: InputMaybe<SeerLoadSearchMoloSniSujanFilter>;
  orderBy?: InputMaybe<SeerLoadSearchMoloSniSujanOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchLoadSearchSmArgs = {
  filter?: InputMaybe<SeerLoadSearchSmFilter>;
  orderBy?: InputMaybe<SeerLoadSearchSmOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchLoadSearchDataAggregationArgs = {
  filter?: InputMaybe<SeerLoadSearchDataAggregationFilter>;
  orderBy?: InputMaybe<SeerLoadSearchDataAggregationOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchLoadSearchDataAggregationBkupArgs = {
  filter?: InputMaybe<SeerLoadSearchDataAggregationBkupFilter>;
  orderBy?: InputMaybe<SeerLoadSearchDataAggregationBkupOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchLoadSearchSideBarArgs = {
  filter?: InputMaybe<SeerLoadSearchSideBarFilter>;
  orderBy?: InputMaybe<SeerLoadSearchSideBarOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchLoadSearchV2Args = {
  filter?: InputMaybe<SeerLoadSearchV2Filter>;
  orderBy?: InputMaybe<SeerLoadSearchV2OrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchLoadSearchV2SmArgs = {
  filter?: InputMaybe<SeerLoadSearchV2SmFilter>;
  orderBy?: InputMaybe<SeerLoadSearchV2SmOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchLoadSummaryDataAggregationArgs = {
  filter?: InputMaybe<SeerLoadSummaryDataAggregationFilter>;
  orderBy?: InputMaybe<SeerLoadSummaryDataAggregationOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchLocksArgs = {
  filter?: InputMaybe<SeerLocksFilter>;
  orderBy?: InputMaybe<SeerLocksOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchMainPageRouteBoardArgs = {
  filter?: InputMaybe<SeerMainPageRouteBoardFilter>;
  orderBy?: InputMaybe<SeerMainPageRouteBoardOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchMainPageRouteBoardWaybillTestArgs = {
  filter?: InputMaybe<SeerMainPageRouteBoardWaybillTestFilter>;
  orderBy?: InputMaybe<SeerMainPageRouteBoardWaybillTestOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchMainPageRouteBoardWaybillCteArgs = {
  filter?: InputMaybe<SeerMainPageRouteBoardWaybillCteFilter>;
  orderBy?: InputMaybe<SeerMainPageRouteBoardWaybillCteOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchMainPageRouteBoardCompletedArgs = {
  filter?: InputMaybe<SeerMainPageRouteBoardCompletedFilter>;
  orderBy?: InputMaybe<SeerMainPageRouteBoardCompletedOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchMainPageRouteBoardCountUnifiedArgs = {
  filter?: InputMaybe<SeerMainPageRouteBoardCountUnifiedFilter>;
  orderBy?: InputMaybe<SeerMainPageRouteBoardCountUnifiedOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchMainPageRouteBoardDetailsArgs = {
  filter?: InputMaybe<SeerMainPageRouteBoardDetailsFilter>;
  orderBy?: InputMaybe<SeerMainPageRouteBoardDetailsOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchMainPageRouteBoardDetailsAhArgs = {
  filter?: InputMaybe<SeerMainPageRouteBoardDetailsAhFilter>;
  orderBy?: InputMaybe<SeerMainPageRouteBoardDetailsAhOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchMainPageRouteBoardPendingArgs = {
  filter?: InputMaybe<SeerMainPageRouteBoardPendingFilter>;
  orderBy?: InputMaybe<SeerMainPageRouteBoardPendingOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchMainPageRouteBoardPreTenderArgs = {
  filter?: InputMaybe<SeerMainPageRouteBoardPreTenderFilter>;
  orderBy?: InputMaybe<SeerMainPageRouteBoardPreTenderOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchMainPageRouteBoardTenderArgs = {
  filter?: InputMaybe<SeerMainPageRouteBoardTenderFilter>;
  orderBy?: InputMaybe<SeerMainPageRouteBoardTenderOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchMainPageTrackingBoardArgs = {
  filter?: InputMaybe<SeerMainPageTrackingBoardFilter>;
  orderBy?: InputMaybe<SeerMainPageTrackingBoardOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchMasterFindLoadSearchArgs = {
  filter?: InputMaybe<SeerMasterFindLoadSearchFilter>;
  orderBy?: InputMaybe<SeerMasterFindLoadSearchOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchOrderReferenceArgs = {
  filter?: InputMaybe<SeerOrderReferenceFilter>;
  orderBy?: InputMaybe<SeerOrderReferenceOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchOrderRepArgs = {
  filter?: InputMaybe<SeerOrderRepFilter>;
  orderBy?: InputMaybe<SeerOrderRepOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchOrderStatusArgs = {
  filter?: InputMaybe<SeerOrderStatusFilter>;
  orderBy?: InputMaybe<SeerOrderStatusOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchRouteArgs = {
  filter?: InputMaybe<SeerRouteFilter>;
  orderBy?: InputMaybe<SeerRouteOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchRouteStatusArgs = {
  filter?: InputMaybe<SeerRouteStatusFilter>;
  orderBy?: InputMaybe<SeerRouteStatusOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchRouteVendorArgs = {
  filter?: InputMaybe<SeerRouteVendorFilter>;
  orderBy?: InputMaybe<SeerRouteVendorOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchRouteVendorReferenceArgs = {
  filter?: InputMaybe<SeerRouteVendorReferenceFilter>;
  orderBy?: InputMaybe<SeerRouteVendorReferenceOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchRouteVendorStatusArgs = {
  filter?: InputMaybe<SeerRouteVendorStatusFilter>;
  orderBy?: InputMaybe<SeerRouteVendorStatusOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchStopArgs = {
  filter?: InputMaybe<SeerStopFilter>;
  orderBy?: InputMaybe<SeerStopOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchStopEventsAggregationsArgs = {
  filter?: InputMaybe<SeerStopEventsAggregationsFilter>;
  orderBy?: InputMaybe<SeerStopEventsAggregationsOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchTenderArgs = {
  filter?: InputMaybe<SeerTenderFilter>;
  orderBy?: InputMaybe<SeerTenderOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchTenderPlanArgs = {
  filter?: InputMaybe<SeerTenderPlanFilter>;
  orderBy?: InputMaybe<SeerTenderPlanOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchTenderPlanStatusArgs = {
  filter?: InputMaybe<SeerTenderPlanStatusFilter>;
  orderBy?: InputMaybe<SeerTenderPlanStatusOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchTenderStatusArgs = {
  filter?: InputMaybe<SeerTenderStatusFilter>;
  orderBy?: InputMaybe<SeerTenderStatusOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchTrackingAggregationsArgs = {
  filter?: InputMaybe<SeerTrackingAggregationsFilter>;
  orderBy?: InputMaybe<SeerTrackingAggregationsOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchTrackingEtasArgs = {
  filter?: InputMaybe<SeerTrackingEtasFilter>;
  orderBy?: InputMaybe<SeerTrackingEtasOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchTrackingPageArgs = {
  filter?: InputMaybe<SeerTrackingPageFilter>;
  orderBy?: InputMaybe<SeerTrackingPageOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySeerSearchTrailerTypeArgs = {
  filter?: InputMaybe<SeerTrailerTypeFilter>;
  orderBy?: InputMaybe<SeerTrailerTypeOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySearchFacilitiesByAddressArgs = {
  requestId?: InputMaybe<Scalars['String']>;
  filter: SearchFacilityAddressInput;
};


export type QueryGetSavedFiltersArgs = {
  pagination: SavedFilterPaginationInput;
  orderBy: SavedFilterGroupSortBy;
  filter: SavedFilterGroupInputType;
};


export type QueryRegionInfoV2Args = {
  regionsV2Input?: InputMaybe<RegionsV2Input>;
};


export type QueryAllRegionsV2Args = {
  allRegionsV2Input?: InputMaybe<AllRegionsV2Input>;
};


export type QueryRegionSetArgs = {
  regionSetV2Input?: InputMaybe<RegionSetV2Input>;
};


export type QueryGetRegionSetDetailsArgs = {
  regionSetID: Scalars['ID'];
};


export type QueryRegionInfoArgs = {
  request: RegionInfoInput;
};


export type QueryRegionInfoByIdArgs = {
  uuid: Scalars['ID'];
};


export type QueryRegionInfoByUrnArgs = {
  urn?: InputMaybe<Scalars['String']>;
};


export type QueryAllCustomersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CustomersFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryAllCustomersNullableArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CustomersFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryAllEmployeesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EmployeeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryAllFacilitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FacilitiesFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryAllTempLoadsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TempLoadsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryBouncesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BouncesFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCarrierArgs = {
  id: Scalars['ID'];
};


export type QueryCarrierNullableArgs = {
  carrier: CarrierArgumentInput;
};


export type QueryCarrierRepsArgs = {
  order?: InputMaybe<CarrierRepOrder>;
};


export type QueryCarrierSettingArgs = {
  carrierId: Scalars['ID'];
};


export type QueryCarriersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CarriersFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCarriersNullableArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CarriersFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCommoditiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CommoditiesFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCountsByAreaArgs = {
  by?: InputMaybe<AreaCountBy>;
  endDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  startDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerByCodeArgs = {
  code: Scalars['String'];
};


export type QueryCustomerQuotesArgs = {
  where?: InputMaybe<QuotesQueryInput>;
};


export type QueryCustomerQuotesPaginatedArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<QuotesPaginatedFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryEmployeeArgs = {
  id: Scalars['ID'];
};


export type QueryEmployeesArgs = {
  text?: InputMaybe<Scalars['String']>;
};


export type QueryExactEmployeeArgs = {
  filter?: EmployeeFilter;
};


export type QueryExactUserArgs = {
  email?: InputMaybe<Scalars['String']>;
};


export type QueryFacilitiesArgs = {
  input?: InputMaybe<FacilitiesQueryInput>;
};


export type QueryFacilityArgs = {
  id: Scalars['ID'];
};


export type QueryFacilityByCodeArgs = {
  code: Scalars['String'];
};


export type QueryGeographiesArgs = {
  geographyType?: InputMaybe<GeographyTypeEnum>;
};


export type QueryGeographyArgs = {
  sourceId: Scalars['Int'];
};


export type QueryGetTruckPostingsForCarrierArgs = {
  carrierId: Scalars['ID'];
  endDate?: InputMaybe<Scalars['Iso8601Date']>;
  endDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  startDate?: InputMaybe<Scalars['Iso8601Date']>;
  startDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
};


export type QueryLoadArgs = {
  id: Scalars['ID'];
};


export type QueryLoadRoutesOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LoadsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryLoadsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LoadsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryMatchQueryArgs = {
  carrierId?: InputMaybe<Scalars['ID']>;
  destinationDeadheadInMiles?: InputMaybe<Scalars['Float']>;
  employeeId?: InputMaybe<Scalars['ID']>;
  endDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  input?: InputMaybe<MatchQueryInput>;
  originDeadheadInMiles?: InputMaybe<Scalars['Float']>;
  startDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  truckPostingId?: InputMaybe<Scalars['ID']>;
};


export type QueryMatchQueryCombinedArgs = {
  carrierId?: InputMaybe<Scalars['ID']>;
  destinationDeadheadInMiles?: InputMaybe<Scalars['Float']>;
  employeeId?: InputMaybe<Scalars['ID']>;
  endDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  input?: InputMaybe<MatchQueryInput>;
  originDeadheadInMiles?: InputMaybe<Scalars['Float']>;
  startDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  truckPostingId?: InputMaybe<Scalars['ID']>;
};


export type QueryMileageArgs = {
  filter: MileageFilter;
};


export type QueryMileageForPointsArgs = {
  filter: MileageForPointsFilter;
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrderByCodeArgs = {
  code: Scalars['ID'];
};


export type QueryOrderByCustomerOrderNumberArgs = {
  customerOrderNumber: Scalars['String'];
};


export type QueryQuoteArgs = {
  id: Scalars['ID'];
};


export type QueryRouteArgs = {
  id: Scalars['ID'];
};


export type QueryRouteVendorArgs = {
  id: Scalars['ID'];
};


export type QueryRouteVendorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RouteVendorsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryRoutesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RoutesQueryInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryStopsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<StopsQueryInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryTempLoadsArgs = {
  input: TempLoadsInput;
};


export type QueryTenantConfigurationArgs = {
  configType?: InputMaybe<ConfigTypeEnum>;
};


export type QueryTruckPostingsArgs = {
  input: TruckPostingsInput;
};


export type QueryTruckPostingsExternalArgs = {
  input: TruckPostingsExternalInput;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCustomerQuotePaginatedV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: RateQuotesPaginatedFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryCustomerQuotePaginatedV3Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: RateQuotesPaginatedFilterV3;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryCustomerQuotePaginatedV4Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: RateQuotesPaginatedFilterV3;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryCustomerQuotePaginatedV5Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: RateQuotesPaginatedFilterV3;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryGetCustomerQuoteByIdArgs = {
  quoteId: Scalars['ID'];
};


export type QueryGetCustomerQuoteByIdV2Args = {
  quoteId: Scalars['ID'];
};


export type QueryGetCustomerQuoteByIdV3Args = {
  quoteId: Scalars['ID'];
};


export type QueryRateConfirmationsArgs = {
  filter: RateConfirmationsFilter;
};


export type QueryProjectByIdArgs = {
  projectId: Scalars['ID'];
};


export type QueryProjectsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProjectsFilterArgs>;
};


export type QueryAvailableRouteArgs = {
  id: Scalars['String'];
};


export type QueryAllAvailableRoutesArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<AvailableRoutesFilter>;
};


export type QueryAvailableRouteEsArgs = {
  id: Scalars['String'];
};


export type QueryAllAvailableRoutesEsArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<AvailableRoutesFilter>;
};


export type QueryAvailableTruckArgs = {
  truckPostingId: Scalars['String'];
};


export type QueryAllAvailableTrucksArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<AvailableTrucksFilter>;
};


export type QueryProcurementCountsByAreaArgs = {
  by?: InputMaybe<ProcurementCountBy>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  timeZone?: InputMaybe<Scalars['String']>;
  routeActivationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  ownerId?: InputMaybe<Scalars['String']>;
  capacityNeed?: InputMaybe<ReadonlyArray<CapacityNeed>>;
};


export type QueryProcurementCountsByAreaEsArgs = {
  by?: InputMaybe<ProcurementCountBy>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  timeZone?: InputMaybe<Scalars['String']>;
  routeActivationStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  ownerId?: InputMaybe<Scalars['String']>;
  capacityNeed?: InputMaybe<ReadonlyArray<CapacityNeed>>;
};


export type QueryAvailableGateReservationArgs = {
  gateReservationId: Scalars['String'];
};


export type QueryAllAvailableGateReservationsArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<AvailableGateReservationFilter>;
};


export type QueryAvailableContainerArgs = {
  containerId: Scalars['String'];
};


export type QueryAllAvailableContainersArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<AvailableContainerFilter>;
};


export type QueryMatchesArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter: MatchFilter;
};


export type QuerySearchMatchingRoutesForTruckArgs = {
  truckPostingId: Scalars['String'];
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter: MatchFilter;
};


export type QueryGateReservationMatchesArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter: GateReservationMatchFilter;
};


export type QueryContainerMatchesArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter: ContainerMatchFilter;
};


export type QuerySearchMatchingRoutesForContainerArgs = {
  containerId: Scalars['String'];
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter: ContainerMatchFilter;
};


export type QueryAdvertisedPriceDetailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: AdvertisedPriceRequestFilter;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCarrierCostsByRouteArgs = {
  filter?: InputMaybe<CarrierCostsByRouteFilter>;
};


export type QueryCustomerOrderRatesByCarrierArgs = {
  filter?: InputMaybe<CustomerOrderRatesByCarrierRequestFilter>;
};


export type QueryCustomerOrderRatesV2Args = {
  filter?: InputMaybe<CustomerOrderRatesRequestFilterV2>;
};


export type QueryDatContractRatesArgs = {
  filter?: InputMaybe<DatContractRatesFilterType>;
};


export type QueryDatSpotRatesArgs = {
  filter?: InputMaybe<DatSpotRatesFilterType>;
};


export type QueryExpectedCostDetailArgs = {
  filter: ExpectedCostRequestFilter;
};


export type QueryPriceDetailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PriceRequestFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryPricingQuoteArgs = {
  filter: PricingQuoteFilterType;
};


export type QueryPricingRouteArgs = {
  filter: PricingRouteFilterType;
};


export type QueryGetAssetPowerbyIdArgs = {
  objId: Scalars['String'];
};


export type QueryGetassetPowerStatusDetailsArgs = {
  objId: Scalars['String'];
};


export type QueryGetAssetPowerSearchArgs = {
  objPool?: InputMaybe<Scalars['String']>;
  objDriverId?: InputMaybe<Scalars['String']>;
  objFleet?: InputMaybe<Scalars['String']>;
  objSegment?: InputMaybe<Scalars['String']>;
  objDivision?: InputMaybe<Scalars['String']>;
  objType?: InputMaybe<Scalars['String']>;
  objNumber?: InputMaybe<Scalars['String']>;
};


export type QueryCheckPowerIdentityArgs = {
  powerId?: InputMaybe<Scalars['String']>;
  powerNumber?: InputMaybe<Scalars['String']>;
};


export type QuerySearchPowerDriverByNameArgs = {
  objName: Scalars['String'];
};


export type QuerySearchPowerDriverByIdArgs = {
  objId: Scalars['String'];
};


export type QueryGetPowerByCodeArgs = {
  code: Scalars['String'];
};


export type QueryGetPowerByCodeV2Args = {
  code: Scalars['String'];
};


export type QueryGetPowerBiReportDetailsArgs = {
  workspaceId?: InputMaybe<Scalars['ID']>;
  reportId?: InputMaybe<Scalars['ID']>;
  empRole: Scalars['String'];
};


export type QueryEmbedParamsArgs = {
  workspaceId?: InputMaybe<Scalars['ID']>;
  reportId?: InputMaybe<Scalars['ID']>;
};


export type QueryPostingArgs = {
  id: Scalars['ID'];
};


export type QueryPostingsArgs = {
  filter: PostingsFilter;
};


export type QueryOfferArgs = {
  id: Scalars['ID'];
};


export type QueryOffersArgs = {
  filter?: InputMaybe<GetOffersArgsInput>;
};


export type QueryOffersPaginatedArgs = {
  filter?: InputMaybe<GetOffersArgsInput>;
};


export type QueryOffersCountArgs = {
  filter?: InputMaybe<GetOffersArgsInput>;
};


export type QueryEmailsArgs = {
  input: EmailFilter;
};


export type QueryNotificationsArgs = {
  input: NotificationFilter;
};


export type QueryEmailUpdatesArgs = {
  input: EmailUpdateFilter;
};


export type QueryCalculateMileageArgs = {
  requests: ReadonlyArray<ConfigurationMileageRequest>;
};


export type QueryCalculateCarrierMileageArgs = {
  entityId: Scalars['String'];
  useDefaults: Scalars['Boolean'];
  requests: ReadonlyArray<MileageRequest>;
};


export type QueryCalculateCustomerMileageArgs = {
  entityId: Scalars['String'];
  useDefaults: Scalars['Boolean'];
  requests: ReadonlyArray<MileageRequest>;
};


export type QueryCalculateMileageLengthArgs = {
  requests: ReadonlyArray<ConfigurationMileageRequest>;
  unit?: InputMaybe<UnitOfLengthEnum>;
};


export type QueryCalculateDistanceLengthArgs = {
  requests: ReadonlyArray<ConfigurationDistanceRequest>;
  unit?: InputMaybe<UnitOfLengthEnum>;
};


export type QueryCalculateCarrierMileageLengthArgs = {
  entityId: Scalars['String'];
  useDefaults: Scalars['Boolean'];
  requests: ReadonlyArray<MileageRequest>;
  unit?: InputMaybe<UnitOfLengthEnum>;
};


export type QueryCalculateCarrierDistanceLengthArgs = {
  entityId: Scalars['String'];
  useDefaults: Scalars['Boolean'];
  requests: ReadonlyArray<DistanceRequest>;
  unit?: InputMaybe<UnitOfLengthEnum>;
};


export type QueryCalculateCustomerMileageLengthArgs = {
  entityId: Scalars['String'];
  useDefaults: Scalars['Boolean'];
  requests: ReadonlyArray<MileageRequest>;
  unit?: InputMaybe<UnitOfLengthEnum>;
};


export type QueryCalculateCustomerDistanceLengthArgs = {
  entityId: Scalars['String'];
  useDefaults: Scalars['Boolean'];
  requests: ReadonlyArray<DistanceRequest>;
  unit?: InputMaybe<UnitOfLengthEnum>;
};


export type QueryGetBidsPaginatedArgs = {
  filter?: InputMaybe<BidInputFilter>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryGetBidLanesPaginatedArgs = {
  filter?: InputMaybe<BidLanesInputFilter>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryGetRoundsByBidIdArgs = {
  bidId: Scalars['ID'];
};


export type QueryGetAwardedQuotesByBidIdArgs = {
  awardQuotesInput: AwardQuotesInput;
};


export type QueryGetAllPricingProgramByCustomerCodeArgs = {
  customerCode?: InputMaybe<Scalars['ID']>;
};


export type QueryGetAllPricingProgramByCarrierCodeArgs = {
  carrierCode?: InputMaybe<Scalars['ID']>;
};


export type QueryGetAllCustomerMarkupByCustomerCodeArgs = {
  customerCode?: InputMaybe<Scalars['ID']>;
};


export type QueryGetLseRouteVendorStatusRecordV3Args = {
  route_id: Scalars['String'];
};


export type QueryGetLseRouteStatusRecordV3Args = {
  route_id: Scalars['String'];
};


export type QueryGetLseOrderStatusRecordV3Args = {
  order_id: Scalars['String'];
};


export type QueryGetLoadStatusRecordsAuditV3Args = {
  record_ct?: InputMaybe<Scalars['Int']>;
  load_id: Scalars['String'];
};


export type QueryCleanseAddressesArgs = {
  multilineAddresses?: InputMaybe<ReadonlyArray<MultiLineRequest>>;
  singlelineAddresses?: InputMaybe<ReadonlyArray<SingleLineBulkRequestInput>>;
};


export type QueryCleanseSingleLineAddressArgs = {
  request: SingleLineRequest;
};


export type QueryCleanseMultiLineAddressArgs = {
  requests: ReadonlyArray<MultiLineRequest>;
};


export type QueryAutocompleteAddressArgs = {
  request?: InputMaybe<AutocompletionRequest>;
};


export type QueryZipCodeAddressArgs = {
  request?: InputMaybe<ZipCodeRequest>;
};


export type QueryAddressCleanseStatusArgs = {
  filter?: InputMaybe<AddressCleanseStatusFilter>;
};


export type QueryGeocodeArgs = {
  request: GeocodeInput;
};


export type QueryBatchGeocodeArgs = {
  requests: ReadonlyArray<GeocodeInput>;
};


export type QueryReverseGeocodeArgs = {
  request: CoordinateInput;
};


export type QueryBatchReverseGeocodeArgs = {
  requests: ReadonlyArray<BatchCoordinateInput>;
};


export type QueryLookupTimezoneArgs = {
  request: CoordinateInput;
};


export type QueryGeocodeV2Args = {
  request: GeocodeInputV2;
};


export type QueryGetLoadAuditDetailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
  loadId?: InputMaybe<Scalars['ID']>;
};


export type QueryCustomerOrderArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerOrderByCodeArgs = {
  code: Scalars['ID'];
};


export type QueryCustomerOrderByNumberArgs = {
  customerOrderNumber: Scalars['String'];
  customerId: Scalars['ID'];
};


export type QueryOrdersArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  filter?: InputMaybe<CustomerOrdersFilter>;
};


export type QueryOrderStopArgs = {
  id: Scalars['ID'];
};


export type QueryLoadV2Args = {
  id: Scalars['ID'];
};


export type QueryRouteV2Args = {
  id: Scalars['ID'];
};


export type QueryRouteByCodeArgs = {
  code: Scalars['ID'];
};


export type QueryRoutesV2Args = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  filter?: InputMaybe<LoadRoutesFilter>;
};


export type QueryRouteStopArgs = {
  id: Scalars['ID'];
};


export type QueryStopsV2Args = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  filter?: InputMaybe<StopsFilter>;
};


export type QueryLinkedRouteV2Args = {
  routeID: Scalars['ID'];
};


export type QueryActiveLaneOrderExistsArgs = {
  input: ActiveLaneOrderExistsInput;
};


export type QueryCheckIfLaneExistArgs = {
  filter: LanePaginatedFilter;
};


export type QueryCheckLaneExistsForCustomerQuoteArgs = {
  filter: LanePaginatedFilter;
};


export type QueryFindMatchingLanesForRoutesArgs = {
  input: FindMatchingLanesForRoutesInput;
};


export type QueryLaneArgs = {
  laneId: Scalars['ID'];
};


export type QueryLaneOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  input: LaneOrdersInput;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryLanePaginatedArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: LanePaginatedFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryLanePaginatedV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: LanePaginatedFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryLaneRoutesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  input: LaneRoutesInput;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryLaneV2Args = {
  id: Scalars['ID'];
};


export type QueryOrderMatchedLanesArgs = {
  input: OrderMatchedLanesInput;
};


export type QueryIncidentsV2Args = {
  filters?: InputMaybe<IncidentFilterV2>;
  sort?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryIncidentByIdv2Args = {
  incidentID: Scalars['ID'];
};


export type QueryAllServiceProfileArgs = {
  filter: FilterServiceProfile;
  carrierId: Scalars['String'];
};


export type QueryServiceProfileByIdArgs = {
  id: Scalars['String'];
};


export type QueryAllServiceProfileV2Args = {
  filter: FilterServiceProfile;
  carrierId: Scalars['String'];
};


export type QueryServiceProfileByIdV2Args = {
  id: Scalars['String'];
};


export type QueryAllSchedulesArgs = {
  serviceProfileId: Scalars['Float'];
};


export type QueryAllSchedulesV2Args = {
  serviceProfileId: Scalars['Float'];
};


export type QueryGetPricingDataArgs = {
  input?: InputMaybe<ImdlInput>;
};


export type QueryGetSniPricingDataArgs = {
  input?: InputMaybe<SniGenericInput>;
};


export type QueryRuleSetEvaluationArgs = {
  filter: RuleSetEvaluationRequestInput;
};


export type QuerySearchRuleSetsArgs = {
  filter: SearchRuleSetsInput;
};


export type QuerySearchTemplatesArgs = {
  filter: SearchTemplatesInput;
};


export type QuerySearchHaykEventsArgs = {
  filter: SearchHaykEventsInput;
};


export type QuerySearchAuditHistoryArgs = {
  filter: SearchAuditHistoryInput;
};


export type QuerySearchHaykRedirectsArgs = {
  filter: SearchHaykRedirectsInput;
};


export type QueryGeographyV2Args = {
  sourceId: Scalars['Int'];
};


export type QueryGeographiesV2Args = {
  filter: GeographyFilter;
  paginationInput?: InputMaybe<PaginationFilter>;
};


export type QueryGetPostalCodeCountriesArgs = {
  postalCode: Scalars['String'];
};


export type QueryGetAdminAreaArgs = {
  input: GeographyAdminAreaInput;
};


export type QueryGetFuelProgramCustomerConfigurationSummaryArgs = {
  customerId: Scalars['ID'];
};


export type QueryGetFuelProgramCustomerConfigurationArgs = {
  id: Scalars['ID'];
};


export type QueryGetEstimatedFuelArgs = {
  loadId: Scalars['ID'];
  customerId: Scalars['ID'];
  orderId: Scalars['ID'];
};


export type QueryHasQualifiedFuelProgramArgs = {
  loadId: Scalars['ID'];
  customerId: Scalars['ID'];
};


export type QueryGetFinalizedFuelArgs = {
  loadId: Scalars['ID'];
  customerId: Scalars['ID'];
  orderId: Scalars['ID'];
};


export type QueryGetHistoricalFuelIndexPricesByFuelIndexTypeArgs = {
  fuelIndexType: Scalars['String'];
};


export type QueryGetCustomerFuelRateHeaderResponseByCustomerAndFuelProgramArgs = {
  customerId: Scalars['ID'];
  fuelProgramCustomerConfigurationId: Scalars['ID'];
};


export type QueryGetCustomerFuelRangesByCustomerFuelRateHeaderIdArgs = {
  customerFuelRateHeaderId: Scalars['ID'];
};


export type QueryGetCurrentRateArgs = {
  fromCurrency: Scalars['String'];
  toCurrency: Scalars['String'];
};


export type QueryGetHistoricRateArgs = {
  fromCurrency: Scalars['String'];
  toCurrency: Scalars['String'];
  date: Scalars['DateTime'];
  bypassFixedRate?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFacilitySublocationActivityByIdArgs = {
  activityId: Scalars['ID'];
};


export type QueryFacilitySublocationActivitiesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<FacilitySublocationActivityFilter>;
};


export type QueryFacilitySublocationAccommodationByIdArgs = {
  accommodationId: Scalars['ID'];
};


export type QueryFacilitySublocationAccommodationsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<FacilitySublocationAccommodationFilter>;
};


export type QueryFacilitySublocationByIdArgs = {
  sublocationId: Scalars['ID'];
};


export type QuerySublocationsByFacilityIdArgs = {
  facilityId: Scalars['ID'];
};


export type QueryFacilitySublocationGateByIdArgs = {
  gateId: Scalars['ID'];
};


export type QueryFacilitySublocationGatesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<FacilitySublocationGateFilter>;
};


export type QueryAllFacilitiesV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FacilitiesFilterV2>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryFacilityV2Args = {
  id: Scalars['ID'];
};


export type QueryFacilityByCodeV2Args = {
  code: Scalars['String'];
};


export type QueryFacilitiesV2Args = {
  input?: InputMaybe<FacilitiesQueryInputTypeV2>;
};


export type QueryTenantExceptionRecordArgs = {
  id: Scalars['String'];
};


export type QueryTenantExceptionRecordsArgs = {
  filter?: InputMaybe<TenantExceptionRecordFilterInput>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGenerateEtaArgs = {
  locationDats?: InputMaybe<ReadonlyArray<InputMaybe<LocationDat>>>;
  routeDats?: InputMaybe<ReadonlyArray<InputMaybe<RouteDat>>>;
  routePathDats?: InputMaybe<ReadonlyArray<InputMaybe<RoutePathDat>>>;
  speedMapDats?: InputMaybe<ReadonlyArray<InputMaybe<SpeedMapDat>>>;
  parametersDat?: InputMaybe<ParametersDat>;
  routeID: Scalars['String'];
  useInferredHoS?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGenerateHoSInferredEtaArgs = {
  hoursOfServiceInputModel: InferredHoursOfServiceInput;
};


export type QueryGetTenantConfigurationArgs = {
  domain: EntityConfigurationDomain;
};


export type QueryGetDefaultConfigurationArgs = {
  domain: EntityConfigurationDomain;
  entityType?: InputMaybe<EntityConfigurationEntityType>;
};


export type QueryGetEntityConfigurationArgs = {
  domain: EntityConfigurationDomain;
  entityType: EntityConfigurationEntityType;
  entityId: Scalars['ID'];
  uuid?: InputMaybe<Scalars['ID']>;
};


export type QueryGetAllEntityConfigurationsArgs = {
  domain: EntityConfigurationDomain;
  entityType: EntityConfigurationEntityType;
  entityId: Scalars['ID'];
};


export type QueryAllEmployeesV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EmployeeFilterV2>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryEmployeeV2Args = {
  id: Scalars['ID'];
};


export type QueryEmployeesV2Args = {
  text?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryExactEmployeeV2Args = {
  filter?: EmployeeFilterV2;
};


export type QueryUsersV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryExactUserV2Args = {
  email?: InputMaybe<Scalars['String']>;
};


export type QueryEdiMessagePreviewArgs = {
  filter: EdiPreviewFilter;
};


export type QueryEdiTenderByShipmentIdAndScacArgs = {
  filter: EdiTenderByShipmentIdAndScacFilter;
};


export type QueryEdiTransactionsArgs = {
  filter: EdiTransactionFilter;
};


export type QueryEdiTransactionsByShipmentIdArgs = {
  filter: EdiTransactionByShipmentIdFilter;
};


export type QueryGetDriverByIdArgs = {
  driverId: Scalars['String'];
};


export type QuerySearchDriverArgs = {
  driver: SearchDriverInput;
};


export type QueryCheckDriverIdentityArgs = {
  driverId?: InputMaybe<Scalars['String']>;
  primaryPhoneNumber?: InputMaybe<Scalars['String']>;
  primaryEmail?: InputMaybe<Scalars['String']>;
  driverIdentityId?: InputMaybe<Scalars['String']>;
};


export type QueryGetDriverOperationsArgs = {
  driverId: Scalars['String'];
};


export type QuerySearchDriverByNameArgs = {
  objName: Scalars['String'];
};


export type QuerySearchDriverByIdArgs = {
  objId: Scalars['String'];
};


export type QueryGetDriverFacilityPreferencesArgs = {
  objId?: InputMaybe<Scalars['String']>;
};


export type QueryGetDriverCustomerPreferencesArgs = {
  objId?: InputMaybe<Scalars['String']>;
};


export type QueryGetDriverPreferredLanesArgs = {
  objId?: InputMaybe<Scalars['String']>;
};


export type QueryGetDriverPreferredRoutesArgs = {
  objId?: InputMaybe<Scalars['String']>;
};


export type QueryGetActiveDriverPreferredLanesArgs = {
  objId?: InputMaybe<Scalars['String']>;
};


export type QueryGetActiveDriverPreferredRoutesArgs = {
  objId?: InputMaybe<Scalars['String']>;
};


export type QueryGetDriverByCodeArgs = {
  code: Scalars['String'];
};


export type QueryGetDriverWeeklyTargetsArgs = {
  driverId?: InputMaybe<Scalars['String']>;
};


export type QueryGetDailyActivitiesByWeeklyTargetIdArgs = {
  weeklyTargetId?: InputMaybe<Scalars['String']>;
};


export type QueryGetDriverTrainingsArgs = {
  driverId: Scalars['String'];
};


export type QueryCrmDocumentsArgs = {
  sort?: InputMaybe<CrmDocumentSort>;
  filters: CrmDocumentFilter;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCrmDocumentByIdArgs = {
  crmDocumentId: Scalars['ID'];
};


export type QueryDocumentsArgs = {
  customerId: Scalars['ID'];
};


export type QueryEdiDocumentsArgs = {
  filters: EdiDocumentFilter;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetEdiDocumentByIdArgs = {
  ediDocumentId: Scalars['ID'];
};


export type QueryLoadDocumentsArgs = {
  filters: LoadDocumentFilter;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetLoadDocumentByIdArgs = {
  loadDocumentId?: InputMaybe<Scalars['ID']>;
};


export type QueryCarrierDocumentsArgs = {
  filters: CarrierDocumentFilter;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCarrierDocumentByIdArgs = {
  carrierDocumentId: Scalars['ID'];
};


export type QueryFacilityDocumentsArgs = {
  filters: FacilityDocumentFilter;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetFacilityDocumentByIdArgs = {
  documentId: Scalars['ID'];
};


export type QueryCsvHeadersArgs = {
  documentType: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCustomerDriverLocationFrequencyConfigurationArgs = {
  customerCode: Scalars['String'];
};


export type QueryCustomerV2Args = {
  id: Scalars['ID'];
};


export type QueryCustomerByCodeV2Args = {
  code: Scalars['String'];
};


export type QueryAllCustomersV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CustomersFilterV2>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCustomerStructuresByCustomerIdArgs = {
  customerId: Scalars['String'];
};


export type QueryNotesByCustomerIdArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerRegionsByCustomerIdArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerFacilitiesByCustomerIdArgs = {
  ifIncludeFacility?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};


export type QueryCustomerFacilitiesArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<CustomerFacilityFilter>;
  ifIncludeFacility?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCustomerCommoditiesByCustomerIdArgs = {
  ifIncludeFacility?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};


export type QueryCustomerCommoditiesByFacilityIdArgs = {
  ifIncludeFacility?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};


export type QueryCustomerCommoditiesArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<CustomerCommodityFilter>;
  ifIncludeFacility?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCustomerCommoditiesByCustomerFacilityIdArgs = {
  customerFacilityId: Scalars['ID'];
};


export type QueryLoadDefaultsByCustomerIdArgs = {
  id: Scalars['ID'];
};


export type QueryLoadDefaultsByCustomerIdV2Args = {
  id: Scalars['ID'];
};


export type QueryCustomerCustomerRelationshipByCustomerIdArgs = {
  id: Scalars['ID'];
};


export type QueryCarrierFacilitiesByCarrierIdArgs = {
  id: Scalars['ID'];
};


export type QueryCarrierFacilitiesByFacilityIdArgs = {
  id: Scalars['ID'];
};


export type QueryFacilityNotesByFacilityIdArgs = {
  id: Scalars['ID'];
};


export type QueryFacilityNotesByFacilityIdV2Args = {
  id: Scalars['ID'];
};


export type QueryFacilityLoadDefaultsArgs = {
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<FacilityLoadDefaultsFilter>;
};


export type QueryFacilityLoadDefaultsByFacilityIdArgs = {
  id: Scalars['ID'];
};


export type QueryFacilityLoadDefaultsByFacilityIdV2Args = {
  id: Scalars['ID'];
};


export type QueryCustomerCommitmentsArgs = {
  filter?: InputMaybe<CustomerCommitmentFilter>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCustomerCommitmentsSettingsArgs = {
  customerID: Scalars['ID'];
};


export type QueryCustomerCommitmentsByIdArgs = {
  commitmentID: Scalars['ID'];
};


export type QueryCustomerCommitmentOrdersByIdArgs = {
  commitmentID: Scalars['ID'];
  orderID?: InputMaybe<Scalars['ID']>;
};


export type QueryCustomerCapacityConstraintByIdArgs = {
  rateQuoteId: Scalars['ID'];
  capacityConstraintId?: InputMaybe<Scalars['ID']>;
};


export type QueryCustomerCommitmentAcceptedOrdersArgs = {
  orderIDs?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};


export type QueryExportCustomerCommitmentsArgs = {
  filter?: InputMaybe<ExportCustomerCommitmentFilter>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCrmOpportunityByEntityIdArgs = {
  entity_Id?: InputMaybe<Scalars['String']>;
};


export type QueryCrmOpportunityByOpportunityIdArgs = {
  opportunity_Id?: InputMaybe<Scalars['String']>;
};


export type QueryValidateCrmOpportunityNameArgs = {
  Name?: InputMaybe<Scalars['String']>;
};


export type QueryCrmOpportunityByEntityIdV2Args = {
  entityId?: InputMaybe<Scalars['String']>;
};


export type QueryCrmOpportunityByOpportunityIdV2Args = {
  opportunityId?: InputMaybe<Scalars['String']>;
};


export type QueryCrmRepsByOpportunityIdV2Args = {
  opportunityId?: InputMaybe<Scalars['String']>;
};


export type QueryValidateCrmOpportunityNameV2Args = {
  name?: InputMaybe<Scalars['String']>;
};


export type QueryValidateCrmOpportunityNameByEntityIdV2Args = {
  entityId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};


export type QueryCrmInteractionByIdArgs = {
  interaction_Id?: InputMaybe<Scalars['String']>;
};


export type QueryCrmInteractionByEntityIdArgs = {
  entity_Id?: InputMaybe<Scalars['String']>;
};


export type QueryCrmInteractionByStatusArgs = {
  entity_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};


export type QueryCrmInteractionRepGroupAuditHistoryArgs = {
  interaction_Id: Scalars['String'];
};


export type QueryCrmInteractionRescheduleAuditHistoryArgs = {
  interaction_Id: Scalars['String'];
};


export type QueryCrmCompanyDescriptionByEntityIdArgs = {
  entityId?: InputMaybe<Scalars['String']>;
};


export type QueryCrmDetailedDescriptionByEntityIdArgs = {
  entityId?: InputMaybe<Scalars['String']>;
};


export type QueryCrmFirmographicsByEntityIdArgs = {
  entityId?: InputMaybe<Scalars['String']>;
};


export type QueryGetContactByIdArgs = {
  objId: Scalars['String'];
};


export type QueryGetContactsByNameArgs = {
  contactName: Scalars['String'];
};


export type QueryGetContactsByEntityIdArgs = {
  obj_Entity_Id: Scalars['String'];
};


export type QueryGetResponsibilitiesByContactIdArgs = {
  obj_Contact_Id: Scalars['String'];
};


export type QueryAssociatedRepsByContactIdArgs = {
  obj_Contact_Id: Scalars['String'];
};


export type QueryAssociatedRepsByContactIdandStatusArgs = {
  objStatus: Scalars['Boolean'];
  obj_Contact_Id: Scalars['String'];
};


export type QueryAssociatedEntitiesByContactIdArgs = {
  obj_Contact_Id: Scalars['String'];
};


export type QueryWorkHoursByContactIdArgs = {
  obj_Contact_Id: Scalars['String'];
};


export type QueryContactInformationByContactInformationTypeIdArgs = {
  obj_Contact_Information_Type_Id: Scalars['String'];
  obj_Contact_Id: Scalars['String'];
};


export type QueryContactInformationByIdArgs = {
  obj_Contact_Information_Id: Scalars['String'];
};


export type QueryContactInformationByContactIdArgs = {
  obj_Contact_Id: Scalars['String'];
};


export type QueryDuplicateContactsIfExistsArgs = {
  obj_Twitter_URL: Scalars['String'];
  obj_Email_Value: Scalars['String'];
  obj_Phone_Value: Scalars['String'];
  obj_Name: Scalars['String'];
};


export type QueryContactByIdv2Args = {
  objId: Scalars['String'];
};


export type QueryContactsByNamev2Args = {
  contactName: Scalars['String'];
};


export type QueryContactsByEntityIdv2Args = {
  obj_Entity_Id: Scalars['String'];
};


export type QueryAssociatedRepsByContactIdv2Args = {
  obj_Contact_Id: Scalars['String'];
};


export type QueryAssociatedRepsByContactIdandStatusv2Args = {
  objStatus: Scalars['Boolean'];
  obj_Contact_Id: Scalars['String'];
};


export type QueryAssociatedEntitiesByContactIdv2Args = {
  obj_Contact_Id: Scalars['String'];
};


export type QueryWorkHoursByContactIdv2Args = {
  obj_Contact_Id: Scalars['String'];
};


export type QueryContactInformationByContactInformationTypeIdv2Args = {
  obj_Contact_Information_Type_Id: Scalars['String'];
  obj_Contact_Id: Scalars['String'];
};


export type QueryContactInformationByIdv2Args = {
  obj_Contact_Information_Id: Scalars['String'];
};


export type QueryContactInformationByContactIdv2Args = {
  obj_Contact_Id: Scalars['String'];
};


export type QueryDuplicateContactsIfExistsv2Args = {
  obj_Twitter_URL: Scalars['String'];
  obj_Email_Value: Scalars['String'];
  obj_Phone_Value: Scalars['String'];
  obj_Name: Scalars['String'];
};


export type QueryResponsibilitiesByContactIdv2Args = {
  obj_Contact_Id: Scalars['String'];
};


export type QueryCalculateNextCarrierInteractionDateArgs = {
  vendorId: Scalars['ID'];
};


export type QueryCalculateNextCustomerInteractionDateArgs = {
  customerId: Scalars['ID'];
};


export type QueryCarrierCrmNotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CarrierCrmNotesFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryCarrierCrmOpportunitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CarrierCrmOpportunityFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryCarrierInteractionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CarrierInteractionFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryCrmCarrierDetailsArgs = {
  vendorId: Scalars['ID'];
};


export type QueryCrmCustomerDetailsArgs = {
  customerId: Scalars['ID'];
};


export type QueryCustomerCrmOpportunitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CustomerOpportunityFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryCustomerInteractionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CustomerInteractionFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryGetCarrierCrmDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CarrierDocuemntFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryGetCarrierPortfolioArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CarriersWithInteractionFilterInputType;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCustomerCrmDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CustomerCrmDocuemntFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryGetCustomerCrmNotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CustomerCrmNotesFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryGetCustomerPortfolioArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CustomersWithInteractionFilterInputType;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryValidateCarrierCrmOpportunityNameAvailablityArgs = {
  validateCarrierCRMOpportunityNameRequest: ValidateCarrierCrmOpportunityNameRequest;
};


export type QueryValidateCustomerCrmOpportunityNameAvailablityArgs = {
  validateCustomerCRMOpportunityNameRequest: ValidateCustomerCrmOpportunityNameRequest;
};


export type QueryGetCreditAvailabilityArgs = {
  customerId: Scalars['String'];
};


export type QueryCostQuotePaginatedArgs = {
  filter: CostQuotePaginatedFilter;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCostQuoteByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetActiveCostQuotesArgs = {
  laneId?: InputMaybe<Scalars['ID']>;
  carrierId?: InputMaybe<Scalars['ID']>;
  customerId?: InputMaybe<Scalars['ID']>;
  quoteType?: InputMaybe<Scalars['String']>;
};


export type QueryWaitForSecondsArgs = {
  seconds?: InputMaybe<Scalars['Int']>;
};


export type QueryTestJsonObjectArgs = {
  body?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryTestJsonArgs = {
  body?: InputMaybe<Scalars['JSON']>;
};


export type QueryGetCommissionsGlobalConfigurationArgs = {
  filter?: InputMaybe<CommissionsSizeModeDivisionFilterInput>;
};


export type QueryGetCommissionsCustomerOverheadConfigurationsArgs = {
  filter?: InputMaybe<CommissionsCustomerOverheadConfigurationFilterInput>;
};


export type QueryGetActiveCommissionsLoadAllocatedMarginsArgs = {
  loadId: Scalars['Uuid'];
};


export type QueryGetActiveCommissionsByLoadIdArgs = {
  loadId: Scalars['Uuid'];
};


export type QueryGetEmployeeCommissionPlanByEmployeeIdArgs = {
  filter?: InputMaybe<EmployeeCommissionPlanFilterInput>;
};


export type QueryGetEmployeesAssignedToCommissionPlanByPlanIdArgs = {
  planHeaderId: Scalars['ID'];
};


export type QueryGetEmployeeCommissionPlanConfigurationArgs = {
  employeeCommissionPlanConfigurationId: Scalars['ID'];
};


export type QueryGetWhatIfLoadCommissionsCalculationArgs = {
  whatIf: WhatIfInput;
};


export type QueryGetWhatIfLoadAllocatedMarginsArgs = {
  whatIf: WhatIfInput;
};


export type QueryGetLoadCommissionsOverrideConfigurationByLoadIdArgs = {
  loadId: Scalars['ID'];
};


export type QueryGetLoadCommissionsMoneySummaryOverheadArgs = {
  loadId: Scalars['ID'];
};


export type QueryGetLoadCommissionsMoneySummaryOverheadsArgs = {
  loadIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryClientExceptionRecordArgs = {
  id: Scalars['String'];
};


export type QueryClientExceptionRecordsArgs = {
  filter?: InputMaybe<ClientExceptionRecordFilterInput>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetGlobalTenantChargeTypeConfigurationArgs = {
  chargeTypeId: Scalars['ID'];
};


export type QueryGetAllGlobalTenantChargeTypeConfigurationsArgs = {
  chargeTypeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};


export type QueryGetAllTenantChargeTypeConfigurationsArgs = {
  showInActive: Scalars['Boolean'];
};


export type QueryGetTenantChargeTypeConfigurationArgs = {
  tenantChargeTypeConfigurationId: Scalars['ID'];
};


export type QueryGetPriorityConfigurationArgs = {
  priorityConfigurationId: Scalars['ID'];
};


export type QueryGetCarrierChargeTypeConfigurationArgs = {
  carrierChargeTypeConfigurationId: Scalars['ID'];
};


export type QueryGetAllCarrierChargeTypeConfigurationsArgs = {
  carrierId: Scalars['ID'];
};


export type QueryGetCustomerChargeTypeConfigurationArgs = {
  customerChargeTypeConfigurationId: Scalars['ID'];
};


export type QueryGetAllCustomerChargeTypeConfigurationsArgs = {
  customerId: Scalars['ID'];
};


export type QueryGetRateDetailChargeTypeConfigurationsArgs = {
  input?: InputMaybe<DetailChargeTypeConfigurationInput>;
};


export type QueryGetCostDetailChargeTypeConfigurationsArgs = {
  input?: InputMaybe<DetailChargeTypeConfigurationInput>;
};


export type QueryCarrierV2Args = {
  id: Scalars['ID'];
};


export type QueryCarrierNullableV2Args = {
  carrier: CarrierArgumentInputV2;
};


export type QueryCarrierRepsV2Args = {
  order?: InputMaybe<CarrierRepOrderV2>;
};


export type QueryCarrierSettingV2Args = {
  carrierId: Scalars['ID'];
};


export type QueryGeographiesRdArgs = {
  geographyType?: InputMaybe<GeographyTypeEnum>;
};


export type QueryGeographyRdArgs = {
  sourceId: Scalars['Int'];
};


export type QueryCarriersNullableV2Args = {
  filter?: InputMaybe<CarriersFilterV2>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCarrierParentsByCarrierIdArgs = {
  carrierId: Scalars['ID'];
};


export type QueryValidationConfigurationsArgs = {
  paginationQuery?: InputMaybe<PaginationQuery>;
};


export type QueryValidationConfigurationArgs = {
  paginationQuery?: InputMaybe<PaginationQuery>;
  fallBacktoTenantConfig?: InputMaybe<Scalars['Boolean']>;
  fallbackToGlobalDefault?: InputMaybe<Scalars['Boolean']>;
  validationConfigurationKeysInput: ValidationConfigurationKeysInput;
};


export type QuerySearchValidationConfigurationsArgs = {
  paginationQuery?: InputMaybe<PaginationQuery>;
  validationConfigurationKeysInput: ValidationConfigurationKeysInput;
};


export type QuerySearchValidationAuditHistoryArgs = {
  paginationQuery?: InputMaybe<PaginationQuery>;
  validationConfigurationKeysInput: ValidationConfigurationKeysInput;
};


export type QuerySearchValidationAuditHistoryByHashIdArgs = {
  paginationQuery?: InputMaybe<PaginationQuery>;
  hashId: Scalars['String'];
};


export type QueryLockArgs = {
  flavor: LockFlavor;
  objectID: Scalars['String'];
};


export type QueryBookingValidationRulesArgs = {
  input?: InputMaybe<BookingValidationRulesInput>;
};


export type QueryExpectedReadyValidationsArgs = {
  input: ExpectedReadyValidationsInput;
};


export type QueryRouteVendorV2Args = {
  id: Scalars['ID'];
};


export type QueryRouteVendorsV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RouteVendorsV2Filter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryBounceV2Args = {
  id: Scalars['ID'];
};


export type QueryBouncesV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BouncesV2Filter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryBookArgs = {
  id: Scalars['ID'];
};


export type QueryGetLoadStatusRecordsAuditArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  load_id: Scalars['String'];
};


export type QueryAslSearchRoutesArgs = {
  orderBy?: InputMaybe<AslGenericRouteViewOrderBy>;
  filters?: InputMaybe<AslRouteViewInput>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryAslSearchTrackingUpdatesArgs = {
  orderBy?: InputMaybe<ReadonlyArray<AslGenericTrackingUpdatesViewOrderBy>>;
  filters?: InputMaybe<ReadonlyArray<AslGenericTrackingUpdatesViewFilter>>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryTenderOptionsArgs = {
  id: Scalars['ID'];
};


export type QueryTenderOptionsByParentObjectArgs = {
  parentObjectType: Scalars['String'];
  parentObjectId: Scalars['ID'];
};


export type QueryDefaultTenderOptionsArgs = {
  carrierId: Scalars['ID'];
};


export type QueryActiveTenderPlanArgs = {
  routeId: Scalars['ID'];
};


export type QueryTenderPlanArgs = {
  id: Scalars['ID'];
};


export type QueryOutboundTenderSettingsArgs = {
  customerId: Scalars['ID'];
};


export type QueryRoutingGuideV2Args = {
  laneId: Scalars['ID'];
  customerId?: InputMaybe<Scalars['ID']>;
};


export type QueryRoutingGuideV3Args = {
  routingGuideInput: RoutingGuideInput;
};


export type QueryLaneRoutingGuidesArgs = {
  filter: RoutingGuidesByLaneFilter;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryExportRoutingGuidesArgs = {
  filter: ExportRoutingGuidesByLanesFilter;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryGetPatternV2Args = {
  id: Scalars['ID'];
};


export type QueryGetPatternsByLaneArgs = {
  laneId: Scalars['ID'];
};


export type QueryGetPatternLaneArgs = {
  getPatternLaneInput?: InputMaybe<GetPatternLaneInput>;
};


export type QueryGetPatternV3Args = {
  id: Scalars['ID'];
};


export type QueryGetPatternsByLaneV2Args = {
  laneId: Scalars['ID'];
};


export type QueryGetPatternsByOrderV1Args = {
  orderId: Scalars['ID'];
};


export type QueryGetPatternLaneV2Args = {
  getPatternLaneInput?: InputMaybe<GetPatternLaneInput>;
};


export type QueryCarrierCapacityConstraintByCostQuoteArgs = {
  costQuoteId: Scalars['ID'];
};


export type QueryCarrierCapacityConstraintByCarrierLaneDateArgs = {
  carrierId: Scalars['ID'];
  laneId: Scalars['ID'];
  date: Scalars['DateTime'];
};


export type QueryCarrierCapacityRemainingByCostQuoteAndDateArgs = {
  costQuoteId: Scalars['ID'];
  date: Scalars['DateTime'];
};


export type QueryGetCustomerAccessorialSettingsArgs = {
  customerId: Scalars['ID'];
};


export type QueryGetRatingSourceIdByCustomerArgs = {
  customerId: Scalars['ID'];
};


export type QueryGetAbacusPricingArgs = {
  laneId: Scalars['ID'];
  routeId: Scalars['ID'];
  carrierId: Scalars['ID'];
};


export type QueryGetAbacusPricingV2Args = {
  abacusPricingFilter: GetAbacusPricingInput;
};


export type QueryAccountingExceptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: AccountingExceptionFilterInput;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryAdvanceArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryAdvanceInterfacesArgs = {
  filter: AdvanceInterfaceFilter;
};


export type QueryAdvanceProviderConfigArgs = {
  provider: AdvanceProviderEnum;
};


export type QueryAdvancesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdvanceFilterInput>;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryChargeTypeGlConfigurationsArgs = {
  chargeTypeId?: InputMaybe<Scalars['ID']>;
};


export type QueryCostDetailsArgs = {
  filter: CostDetailsFilter;
};


export type QueryCustomerCreditMemoArgs = {
  invoiceId: Scalars['ID'];
};


export type QueryCustomerInvoicesArgs = {
  filter: CustomerInvoiceFilter;
};


export type QueryCustomerInvoicesQueueArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: CustomerInvoiceFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryCustomerOrderRequiredDocumentsArgs = {
  customerId: Scalars['ID'];
  orderId: Scalars['ID'];
};


export type QueryCustomerPaymentsArgs = {
  filter: CustomerPaymentInput;
};


export type QueryCustomerRequiredDocumentsArgs = {
  customerId: Scalars['ID'];
};


export type QueryCustomerSettlementQueueArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrdersFilter>;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryGetApplicableToleranceArgs = {
  routeId?: InputMaybe<Scalars['ID']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetBillToCodeHistoryByCustomerIdArgs = {
  customerId: Scalars['ID'];
};


export type QueryGetBillToCodesByCustomerIdArgs = {
  customerId: Scalars['ID'];
};


export type QueryGetCalculatedRouteVendorThresholdsArgs = {
  routeId?: InputMaybe<Scalars['ID']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetChargeTypeByIdArgs = {
  chargeTypeId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetCustomerInvoiceForResendArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetCustomerInvoicesForResendArgs = {
  invoiceIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};


export type QueryGetFinalizedFuelStatusArgs = {
  orderId: Scalars['ID'];
};


export type QueryGetIntegrationMappingsArgs = {
  groupName: Scalars['String'];
};


export type QueryGetInvoiceDistributionhistoryByIdArgs = {
  loadId: Scalars['Uuid'];
};


export type QueryGetOrderArgs = {
  orderId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetPartialVoucherInformationArgs = {
  routeId: Scalars['Uuid'];
  vendorId: Scalars['Uuid'];
};


export type QueryGetPartialVoucherInformationByBatchIdArgs = {
  batchId: Scalars['Uuid'];
};


export type QueryGetRouteVendorCostArgs = {
  routeId?: InputMaybe<Scalars['ID']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetVendorAutoVoucherArgs = {
  vendorId: Scalars['ID'];
};


export type QueryGetVendorInvoiceVouchersArgs = {
  voucherHeaderId: Scalars['Uuid'];
};


export type QueryGetVendorUseGlobalToggleArgs = {
  vendorId: Scalars['ID'];
};


export type QueryInvoiceBatchesArgs = {
  filter: BatchFilter;
};


export type QueryLoadMoneySummaryArgs = {
  loadId: Scalars['ID'];
};


export type QueryLoadRequiredDocumentsSummaryArgs = {
  loadId: Scalars['ID'];
};


export type QueryRateDetailsArgs = {
  filter: RateDetailsFilter;
};


export type QueryRouteMaxCostArgs = {
  routeId: Scalars['ID'];
};


export type QueryRouteMaxCostNullableArgs = {
  routeId?: InputMaybe<Scalars['ID']>;
};


export type QueryRouteVendorMaxCostValidationArgs = {
  routeId: Scalars['ID'];
  vendorId: Scalars['ID'];
};


export type QuerySegmentArgs = {
  segmentId: Scalars['ID'];
};


export type QuerySegmentationConfigurationArgs = {
  segmentationConfigurationId: Scalars['ID'];
};


export type QuerySegmentValuesForSegmentArgs = {
  segmentId: Scalars['ID'];
};


export type QueryVendorAssociatedRequiredDocumentArgs = {
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryVendorCreditMemoArgs = {
  voucherId: Scalars['ID'];
};


export type QueryVendorInvoiceQueueArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: VendorInvoiceFilterInput;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryVendorInvoicesArgs = {
  filter: VendorInvoiceFilterInput;
};


export type QueryVendorPaymentsArgs = {
  filter: VendorPaymentInput;
};


export type QueryVendorRouteRequiredDocumentsArgs = {
  routeId: Scalars['ID'];
  vendorId: Scalars['ID'];
};


export type QueryVendorRouteRequiredDocumentsByLoadIdArgs = {
  loadId: Scalars['ID'];
};


export type QueryVendorSettlementQueueArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<VendorAccountingQueueFilter>;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryVendorVouchersArgs = {
  filter: VendorVoucherFilter;
};


export type QueryVendorVouchersQueueArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: VendorVoucherFilter;
  first?: InputMaybe<Scalars['PaginationAmount']>;
  last?: InputMaybe<Scalars['PaginationAmount']>;
};


export type QueryVoucherBatchesArgs = {
  filter: BatchFilter;
};


export type QueryVoucherVendorToleranceArgs = {
  vendorId: Scalars['ID'];
};


export type Query_FederatedCarrierArgs = {
  vendorIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};


export type Query_FederatedCarrierV2Args = {
  vendorIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};


export type Query_FederatedCustomerArgs = {
  customerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};


export type Query_FederatedCustomerOrdersArgs = {
  orderIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};


export type Query_FederatedCustomerV2Args = {
  customerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};


export type Query_FederatedLoadMoneySummariesArgs = {
  loadIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};


export type Query_FederatedLoadRoutesArgs = {
  loadRouteIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};


export type Query_FederatedOrdersArgs = {
  orderIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};


export type Query_FederatedRouteMaxCostsArgs = {
  routeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};


export type Query_FederatedRouteVendorCostsArgs = {
  routeVendorIds?: InputMaybe<ReadonlyArray<InputMaybe<RouteVendorInput>>>;
};

export type QuoteGroup = {
  readonly __typename?: 'QuoteGroup';
  readonly id: Scalars['ID'];
};

export type QuoteInput = {
  readonly createdById: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly destinationGeographySource?: InputMaybe<Scalars['String']>;
  readonly destinationGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly destinationText: Scalars['String'];
  readonly distance: UnitOfDistanceInput;
  /** use effectiveDateTime for timestamp */
  readonly effectiveDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly effectiveDateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** use expirationDateTime for timestamp */
  readonly expirationDate?: InputMaybe<Scalars['ISO8601Date']>;
  readonly expirationDateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly lineItems: ReadonlyArray<QuoteLineItemInput>;
  readonly loadSizeId: Scalars['ID'];
  readonly minHeight?: InputMaybe<UnitOfDistanceInput>;
  readonly minLength?: InputMaybe<UnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<UnitOfDistanceInput>;
  readonly multipleStops?: InputMaybe<Scalars['Boolean']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly originGeographySource?: InputMaybe<Scalars['String']>;
  readonly originGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly originText: Scalars['String'];
  readonly quoteGroupId?: InputMaybe<Scalars['ID']>;
  readonly quotePriceTierId?: InputMaybe<Scalars['ID']>;
  readonly quoteTypeId: Scalars['ID'];
  readonly quotedById: Scalars['ID'];
  readonly quotedToId?: InputMaybe<Scalars['ID']>;
  readonly routingType: Scalars['String'];
  readonly scac?: InputMaybe<Scalars['String']>;
  readonly scacId?: InputMaybe<Scalars['ID']>;
  readonly team?: InputMaybe<Scalars['Boolean']>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly transportModeId: Scalars['ID'];
};

export type QuoteLineItem = {
  readonly __typename?: 'QuoteLineItem';
  readonly chargeTypeId: Scalars['ID'];
  readonly currency: Scalars['String'];
  readonly exchangeRate: Scalars['Float'];
  readonly homeCurrency: Scalars['String'];
  readonly homeRate: Scalars['Float'];
  readonly id: Scalars['ID'];
  readonly quote: CustomerQuote;
  readonly quoteId: Scalars['ID'];
  readonly rate: Scalars['Float'];
  readonly totalRate: Scalars['Float'];
  readonly units: Scalars['Float'];
};

export type QuoteLineItemInput = {
  readonly chargeTypeId: Scalars['ID'];
  readonly currency: Scalars['String'];
  readonly exchangeRate: Scalars['Float'];
  readonly homeCurrency: Scalars['String'];
  readonly homeRate: Scalars['Float'];
  readonly rate: Scalars['Float'];
  readonly totalRate: Scalars['Float'];
  readonly units: Scalars['Float'];
};

export type QuotesPaginatedFilter = {
  /** Search/filter by Customer ID */
  readonly customerId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by Destination Deadhead Radius */
  readonly destinationGeographySearch?: InputMaybe<GeographyRadiusSearchInput>;
  /** Search/filter by Destination Text */
  readonly destinationText?: InputMaybe<Scalars['String']>;
  /** Exclude Expired Quotes in Results */
  readonly excludeExpired?: InputMaybe<Scalars['Boolean']>;
  /** Search/filter by Load Size */
  readonly loadSizeId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by Minimum Height */
  readonly minHeight?: InputMaybe<UnitOfDistanceInput>;
  /** Search/filter by Minimum Length */
  readonly minLength?: InputMaybe<UnitOfDistanceInput>;
  /** Search/filter by Minimum Width */
  readonly minWidth?: InputMaybe<UnitOfDistanceInput>;
  /** Search/filter by Origin Deadhead Radius */
  readonly originGeographySearch?: InputMaybe<GeographyRadiusSearchInput>;
  /** Search/filter by Origin Text */
  readonly originText?: InputMaybe<Scalars['String']>;
  /** Search/filter By Quote Group ID */
  readonly quoteGroupId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by Quote Price Tier */
  readonly quotePriceTierId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by Quote Type */
  readonly quoteTypeId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by SCAC */
  readonly scacId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by Team */
  readonly team?: InputMaybe<Scalars['Boolean']>;
  /** Search/filter by Trailer Types */
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/filter by Transportation Mode */
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
};

export type QuotesQueryInput = {
  /** Search/filter by Customer ID */
  readonly customerId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by Destination Deadhead Radius */
  readonly destinationGeographySearch?: InputMaybe<GeographyRadiusSearchInput>;
  /** Search/filter by Destination Text */
  readonly destinationText?: InputMaybe<Scalars['String']>;
  /** Exclude Expired Quotes in Results */
  readonly excludeExpired?: InputMaybe<Scalars['Boolean']>;
  /** Search/filter by Load Size */
  readonly loadSizeId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by Minimum Height */
  readonly minHeight?: InputMaybe<UnitOfDistanceInput>;
  /** Search/filter by Minimum Length */
  readonly minLength?: InputMaybe<UnitOfDistanceInput>;
  /** Search/filter by Minimum Width */
  readonly minWidth?: InputMaybe<UnitOfDistanceInput>;
  /** Search/filter by Origin Deadhead Radius */
  readonly originGeographySearch?: InputMaybe<GeographyRadiusSearchInput>;
  /** Search/filter by Origin Text */
  readonly originText?: InputMaybe<Scalars['String']>;
  /** Search/filter By Quote Group ID */
  readonly quoteGroupId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by Quote Price Tier */
  readonly quotePriceTierId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by Quote Type */
  readonly quoteTypeId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by SCAC */
  readonly scacId?: InputMaybe<Scalars['ID']>;
  /** Search/filter by Team */
  readonly team?: InputMaybe<Scalars['Boolean']>;
  /** Search/filter by Trailer Types */
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/filter by Transportation Mode */
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
};

export type QuoteToLoadInput = {
  readonly quoteId: Scalars['ID'];
};

/** Holds Ranking Factor Info. */
export type RankingFactor = {
  readonly __typename?: 'RankingFactor';
  readonly rankingFactorCode?: Maybe<Scalars['String']>;
  readonly rankingFactorDescription?: Maybe<Scalars['String']>;
};

export type RateConContacts = {
  readonly __typename?: 'RateConContacts';
  readonly id: Scalars['ID'];
  readonly contactType?: Maybe<ContactTypeEnum>;
  readonly email?: Maybe<Scalars['String']>;
  readonly contactName?: Maybe<Scalars['String']>;
  readonly contactPhone?: Maybe<Scalars['String']>;
  readonly rateConfirmationId: Scalars['ID'];
  readonly rateConfirmation?: Maybe<RateConfirmation>;
};

export type RateConfirmation = {
  readonly __typename?: 'RateConfirmation';
  readonly id: Scalars['ID'];
  readonly sentByUserId: Scalars['ID'];
  readonly documentId: Scalars['ID'];
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly routeVendorId?: Maybe<Scalars['ID']>;
  readonly notificationId?: Maybe<Scalars['ID']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly sendToEmail: Scalars['String'];
  readonly hideCostDetails: Scalars['Boolean'];
  readonly hideReference?: Maybe<Scalars['Boolean']>;
  readonly completed?: Maybe<Scalars['Boolean']>;
  readonly sentDate: Scalars['DateTime'];
  readonly contactName?: Maybe<Scalars['String']>;
  readonly contactPhone?: Maybe<Scalars['String']>;
  readonly contactMethod?: Maybe<Scalars['String']>;
  readonly ccEmails?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly sentByUser: User;
  readonly sentByUserV2: UserV2;
  readonly taStopPending?: Maybe<Scalars['Boolean']>;
  readonly rateConContacts?: Maybe<ReadonlyArray<Maybe<RateConContacts>>>;
};

export type RateConfirmationInput = {
  readonly sentByUserId: Scalars['ID'];
  readonly sendToEmail: Scalars['String'];
  readonly ccEmails?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly routeVendorId: Scalars['ID'];
  readonly vendorContactId: Scalars['ID'];
  readonly hideCostDetails: Scalars['Boolean'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly contactName?: InputMaybe<Scalars['String']>;
  readonly contactPhone?: InputMaybe<Scalars['String']>;
  readonly contactMethod?: InputMaybe<Scalars['String']>;
  readonly hideReference?: InputMaybe<Scalars['Boolean']>;
  readonly completed?: InputMaybe<Scalars['Boolean']>;
  readonly taStopPending?: InputMaybe<Scalars['Boolean']>;
  readonly rateConCcEmails?: InputMaybe<ReadonlyArray<InputMaybe<ContactDetail>>>;
  readonly rateConBccEmails?: InputMaybe<ReadonlyArray<InputMaybe<ContactDetail>>>;
};

export type RateConfirmationsFilter = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly sentByUserId?: InputMaybe<Scalars['ID']>;
  readonly sendToEmail?: InputMaybe<Scalars['String']>;
  readonly ccEmails?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeId?: InputMaybe<Scalars['String']>;
  readonly routeVendorId?: InputMaybe<Scalars['ID']>;
  readonly vendorContactId?: InputMaybe<Scalars['ID']>;
  readonly documentId?: InputMaybe<Scalars['ID']>;
  readonly hideCostDetails?: InputMaybe<Scalars['Boolean']>;
  readonly sentDate?: InputMaybe<Scalars['DateTime']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly contactName?: InputMaybe<Scalars['String']>;
  readonly contactPhone?: InputMaybe<Scalars['String']>;
  readonly taStopPending?: InputMaybe<Scalars['Boolean']>;
};

/** The Rate Detail */
export type RateDetail = {
  readonly __typename?: 'RateDetail';
  readonly allowRerating?: Maybe<Scalars['Boolean']>;
  readonly billToCodeCode?: Maybe<Scalars['String']>;
  readonly billToCodeId?: Maybe<Scalars['ID']>;
  /** @deprecated This should not be used anymore as we are transitioning to Charge Type Service. We will be adding a new federated type soon. */
  readonly chargeType?: Maybe<ChargeType>;
  readonly chargeTypeId?: Maybe<Scalars['ID']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdById?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly currencyDiscountAmount?: Maybe<Scalars['Decimal']>;
  readonly customer?: Maybe<Customer>;
  readonly customerId: Scalars['ID'];
  readonly customerV2?: Maybe<CustomerV2>;
  readonly description: Scalars['String'];
  readonly enteredRatePer?: Maybe<Scalars['Decimal']>;
  readonly enteredTotalRate?: Maybe<Scalars['Decimal']>;
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: Maybe<Scalars['Date']>;
  readonly glAccountCode?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly invoiceHeader?: Maybe<InvoiceHeader>;
  readonly jurisdictionTax?: Maybe<JurisdictionTax>;
  readonly jurisdictionTaxesId?: Maybe<Scalars['ID']>;
  readonly load?: Maybe<Load>;
  readonly loadId: Scalars['ID'];
  readonly loadV2?: Maybe<LoadV2>;
  readonly orderId: Scalars['ID'];
  readonly ratePer?: Maybe<Scalars['Decimal']>;
  readonly segment1?: Maybe<Scalars['String']>;
  readonly segment10?: Maybe<Scalars['String']>;
  readonly segment2?: Maybe<Scalars['String']>;
  readonly segment3?: Maybe<Scalars['String']>;
  readonly segment4?: Maybe<Scalars['String']>;
  readonly segment5?: Maybe<Scalars['String']>;
  readonly segment6?: Maybe<Scalars['String']>;
  readonly segment7?: Maybe<Scalars['String']>;
  readonly segment8?: Maybe<Scalars['String']>;
  readonly segment9?: Maybe<Scalars['String']>;
  readonly statementNumber?: Maybe<Scalars['String']>;
  readonly statusId: StatusEnum;
  readonly stopId?: Maybe<Scalars['ID']>;
  readonly totalRate?: Maybe<Scalars['Decimal']>;
  readonly unitsDecimal?: Maybe<Scalars['Decimal']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedById?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
};

/** The input type for new Rate Detail. */
export type RateDetailAddInput = {
  readonly billToCodeId?: InputMaybe<Scalars['ID']>;
  readonly chargeTypeId?: InputMaybe<Scalars['ID']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly currency?: InputMaybe<Scalars['String']>;
  readonly customerId: Scalars['ID'];
  readonly description?: InputMaybe<Scalars['String']>;
  readonly enteredRatePer?: InputMaybe<Scalars['Decimal']>;
  readonly exchangeRate?: InputMaybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: InputMaybe<Scalars['Date']>;
  readonly jurisdictionTaxesId?: InputMaybe<Scalars['ID']>;
  readonly loadId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly ratePer?: InputMaybe<Scalars['Decimal']>;
  readonly stopId?: InputMaybe<Scalars['ID']>;
  readonly unitsDecimal?: InputMaybe<Scalars['Decimal']>;
};

export type RateDetailChargeTypeConfigurationResponse = {
  readonly __typename?: 'RateDetailChargeTypeConfigurationResponse';
  readonly billToId?: Maybe<Scalars['Guid']>;
  readonly carrierId?: Maybe<Scalars['Guid']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: Maybe<Scalars['String']>;
  readonly customerId?: Maybe<Scalars['Guid']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly invoiceGroupingCode?: Maybe<Scalars['String']>;
  readonly invoiceMethodId?: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly isCredit: Scalars['Boolean'];
  readonly isDefault: Scalars['Boolean'];
  readonly maximumTotalRate?: Maybe<Scalars['Decimal']>;
  readonly minimumTotalRate?: Maybe<Scalars['Decimal']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly perUnit?: Maybe<CtcPerUnitTypeEnum>;
  readonly rate?: Maybe<Scalars['Decimal']>;
  readonly size?: Maybe<Scalars['String']>;
};

/** Represents the rate detail object when processing a invoice. */
export type RateDetailProcessingInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
};

/** Holds Rate Details Edge Data Info. */
export type RateDetailsEdgeV2 = {
  readonly __typename?: 'RateDetailsEdgeV2';
  readonly node?: Maybe<RateDetailsV2>;
};

export type RateDetailsFilter = {
  readonly loadId: Scalars['ID'];
};

/** Holds Rate Detail Node Data Info. */
export type RateDetailsV2 = {
  readonly __typename?: 'RateDetailsV2';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly chargeType?: Maybe<Scalars['String']>;
  readonly chargeTypeDescription?: Maybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Uuid'];
  readonly commodityClass?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly rate?: Maybe<Scalars['Float']>;
  readonly rateQualifier?: Maybe<Scalars['String']>;
  readonly unit?: Maybe<Scalars['Float']>;
};

/** The input type for updating Rate Detail. */
export type RateDetailUpdateInput = {
  readonly billToCodeId?: InputMaybe<Scalars['ID']>;
  readonly chargeTypeId?: InputMaybe<Scalars['ID']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly currency?: InputMaybe<Scalars['String']>;
  readonly customerId: Scalars['ID'];
  readonly description?: InputMaybe<Scalars['String']>;
  readonly enteredRatePer?: InputMaybe<Scalars['Decimal']>;
  readonly exchangeRate?: InputMaybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: InputMaybe<Scalars['Date']>;
  readonly id: Scalars['ID'];
  readonly jurisdictionTaxesId?: InputMaybe<Scalars['ID']>;
  readonly loadId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly ratePer?: InputMaybe<Scalars['Decimal']>;
  readonly stopId?: InputMaybe<Scalars['ID']>;
  readonly unitsDecimal?: InputMaybe<Scalars['Decimal']>;
};

export type RateQuoteCurrency = {
  readonly currencyUnit?: InputMaybe<Scalars['String']>;
  readonly unit?: InputMaybe<RateQuoteUnitOfCurrencyEnum>;
  readonly value?: InputMaybe<Scalars['Decimal']>;
};

export type RateQuoteCurrencyOutput = {
  readonly __typename?: 'RateQuoteCurrencyOutput';
  readonly unit?: Maybe<RateQuoteUnitOfCurrencyEnum>;
  readonly value?: Maybe<Scalars['Decimal']>;
};

export type RateQuoteCurrencyOutputV2 = {
  readonly __typename?: 'RateQuoteCurrencyOutputV2';
  readonly unit?: Maybe<RateQuoteTenantConfig>;
  readonly value?: Maybe<Scalars['Decimal']>;
};

export enum RateQuoteDistanceUnitEnumType {
  Feet = 'feet',
  Inches = 'inches',
  Kilometers = 'kilometers',
  Meters = 'meters',
  Miles = 'miles'
}

/** RateQuoteGeographyRadiusSearchInput is deprecated. Use RateQuoteGeographyRadiusSearchInputV3. */
export type RateQuoteGeographyRadiusSearchInput = {
  readonly distance?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly sourceId?: InputMaybe<Scalars['Int']>;
};

export type RateQuoteGeographyRadiusSearchInputV3 = {
  readonly distance?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly postalCodeCountry?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
};

export enum RateQuoteGeographyTypeEnum {
  City = 'City',
  Country = 'Country',
  Facility = 'Facility',
  State = 'State'
}

/** DEPRECATED.Do not use this.Use RateQuoteInputV3 */
export type RateQuoteInput = {
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly createdById: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  /** DEPRECATED. Do not use this. This will be removed. Use DestinationId. */
  readonly destinationGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly destinationId?: InputMaybe<Scalars['String']>;
  /** DEPRECATED. Do not use this. */
  readonly destinationText: Scalars['String'];
  readonly distance: RateQuoteUnitOfDistanceInput;
  readonly effectiveDateTime: Scalars['DateTime'];
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly lineItems: ReadonlyArray<RateQuoteLineItemInput>;
  readonly loadSize: Scalars['String'];
  readonly minHeight?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly multipleStops?: InputMaybe<Scalars['Boolean']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  /** DEPRECATED. Do not use this. This will be removed. Use OriginId. */
  readonly originGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly originId?: InputMaybe<Scalars['String']>;
  /** DEPRECATED. Do not use this. */
  readonly originText: Scalars['String'];
  readonly project?: InputMaybe<Scalars['String']>;
  readonly quotedById: Scalars['ID'];
  readonly quotedToId?: InputMaybe<Scalars['ID']>;
  readonly quoteGroupId?: InputMaybe<Scalars['ID']>;
  readonly quotePriceTier?: InputMaybe<Scalars['String']>;
  readonly quoteType: Scalars['String'];
  readonly routingType: Scalars['String'];
  readonly scac: Scalars['String'];
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<RateQuoteLaneStopInputV2>>>;
  readonly team?: InputMaybe<Scalars['Boolean']>;
  readonly trailerTypeIds: ReadonlyArray<Scalars['ID']>;
  readonly transportMode: Scalars['String'];
};

/** DEPRECATED.Do not use this.Use RateQuoteInputV4 */
export type RateQuoteInputV3 = {
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly createdById: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly destinationId: Scalars['String'];
  readonly destinationType: RateQuoteGeographyTypeEnum;
  readonly distance: RateQuoteUnitOfDistanceInput;
  readonly effectiveDateTime: Scalars['DateTime'];
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly lineItems: ReadonlyArray<RateQuoteLineItemInput>;
  readonly loadSize: Scalars['String'];
  readonly minHeight?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly multipleStops?: InputMaybe<Scalars['Boolean']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly originId: Scalars['String'];
  readonly originType: RateQuoteGeographyTypeEnum;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly quotedById: Scalars['ID'];
  readonly quotedToId?: InputMaybe<Scalars['ID']>;
  readonly quoteGroupId?: InputMaybe<Scalars['ID']>;
  readonly quotePriceTier?: InputMaybe<Scalars['String']>;
  readonly quoteType: Scalars['String'];
  readonly routingType: Scalars['String'];
  readonly scac: Scalars['String'];
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<RateQuoteLaneStopInputV2>>>;
  readonly team?: InputMaybe<Scalars['Boolean']>;
  readonly trailerTypeIds: ReadonlyArray<Scalars['ID']>;
  readonly transportMode: Scalars['String'];
};

/** DEPRECATED.Do not use this.Use RateQuoteInputV5 */
export type RateQuoteInputV4 = {
  readonly autoAccept?: InputMaybe<Scalars['Boolean']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly createdById: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly effectiveDateTime: Scalars['DateTime'];
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly lineItems: ReadonlyArray<RateQuoteLineItemInput>;
  readonly loadSize: Scalars['String'];
  readonly minHeight?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly multipleStops?: InputMaybe<Scalars['Boolean']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly quotedById: Scalars['ID'];
  readonly quotedToId?: InputMaybe<Scalars['ID']>;
  readonly quoteGroupId?: InputMaybe<Scalars['ID']>;
  readonly quotePriceTier?: InputMaybe<Scalars['String']>;
  readonly quoteType: Scalars['String'];
  readonly routingType: Scalars['String'];
  readonly scac: Scalars['String'];
  readonly services?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly stops: ReadonlyArray<InputMaybe<RateQuoteLaneStopInputV2>>;
  readonly team?: InputMaybe<Scalars['Boolean']>;
  readonly trailerTypeIds: ReadonlyArray<Scalars['ID']>;
  readonly transportMode: Scalars['String'];
};

export type RateQuoteInputV5 = {
  readonly autoAccept?: InputMaybe<Scalars['Boolean']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly createdById: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly effectiveDateTime: Scalars['DateTime'];
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly lineItems: ReadonlyArray<RateQuoteLineItemInput>;
  readonly loadSize: Scalars['String'];
  readonly minHeight?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly multipleStops?: InputMaybe<Scalars['Boolean']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly quotedById: Scalars['ID'];
  readonly quotedToId?: InputMaybe<Scalars['ID']>;
  readonly quoteGroupId?: InputMaybe<Scalars['ID']>;
  readonly quotePriceTier?: InputMaybe<Scalars['String']>;
  readonly quoteType: Scalars['String'];
  readonly routingType: Scalars['String'];
  readonly scac: Scalars['String'];
  readonly services?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly stops: ReadonlyArray<InputMaybe<RateQuoteLaneStopInputV3>>;
  readonly team?: InputMaybe<Scalars['Boolean']>;
  readonly trailerTypeIds: ReadonlyArray<Scalars['ID']>;
  readonly transportMode: Scalars['String'];
};

export type RateQuoteKeyValue = {
  readonly __typename?: 'RateQuoteKeyValue';
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly metaDataJson?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly sort?: Maybe<Scalars['Int']>;
  readonly systemDefined?: Maybe<Scalars['Boolean']>;
};

export type RateQuoteLaneStopInputV2 = {
  readonly geographyId: Scalars['String'];
  readonly geographyType: RateQuoteGeographyTypeEnum;
  readonly postalCodeCountry?: InputMaybe<Scalars['String']>;
  readonly sequence: Scalars['Int'];
  readonly stopType: RateQuoteLaneStopTypeEnum;
};

export type RateQuoteLaneStopInputV3 = {
  readonly geographyId: Scalars['String'];
  readonly geographyType: Scalars['String'];
  readonly postalCodeCountry?: InputMaybe<Scalars['String']>;
  readonly sequence: Scalars['Int'];
  readonly stopType: Scalars['String'];
};

export enum RateQuoteLaneStopTypeEnum {
  Pu = 'PU',
  Del = 'Del',
  TrailerAquisition = 'TRAILER_AQUISITION',
  TrailerDeposition = 'TRAILER_DEPOSITION'
}

export type RateQuoteLineItemInput = {
  readonly chargeTypeId: Scalars['ID'];
  readonly exchangeCurrency: RateQuoteCurrency;
  readonly homeCurrency: RateQuoteCurrency;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isDelete?: InputMaybe<Scalars['Boolean']>;
  readonly rateCurrency: RateQuoteCurrency;
  readonly totalRateCurrency: RateQuoteCurrency;
  readonly units: Scalars['Decimal'];
};

export type RateQuoteLineItemOutput = {
  readonly __typename?: 'RateQuoteLineItemOutput';
  readonly chargeType: Scalars['String'];
  readonly chargeTypeId: Scalars['ID'];
  readonly exchangeCurrency: RateQuoteCurrencyOutput;
  readonly homeCurrency: RateQuoteCurrencyOutput;
  readonly id: Scalars['ID'];
  readonly quoteId: Scalars['ID'];
  readonly rateCurrency: RateQuoteCurrencyOutput;
  readonly totalRateCurrency: RateQuoteCurrencyOutput;
  readonly units: Scalars['Decimal'];
};

export type RateQuoteLineItemOutputV2 = {
  readonly __typename?: 'RateQuoteLineItemOutputV2';
  readonly chargeType: Scalars['String'];
  readonly chargeTypeId: Scalars['ID'];
  readonly exchangeCurrency: RateQuoteCurrencyOutputV2;
  readonly homeCurrency: RateQuoteCurrencyOutputV2;
  readonly id: Scalars['ID'];
  readonly quoteId: Scalars['ID'];
  readonly rateCurrency: RateQuoteCurrencyOutputV2;
  readonly totalRateCurrency: RateQuoteCurrencyOutputV2;
  readonly units: Scalars['Decimal'];
};

export type RateQuoteQuoteGroup = {
  readonly __typename?: 'RateQuoteQuoteGroup';
  readonly id?: Maybe<Scalars['ID']>;
};

/** RateQuotesPaginatedFilter is deprecated. Use RateQuotesPaginatedFilterV3. */
export type RateQuotesPaginatedFilter = {
  readonly autoAccept?: InputMaybe<Scalars['Boolean']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly destinationGeographySearch?: InputMaybe<RateQuoteGeographyRadiusSearchInput>;
  readonly destinationGeographyType?: InputMaybe<Scalars['String']>;
  /** DEPRECATED. Do not use this. This will be removed. Use DestinationGeographySearch. */
  readonly destinationText?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly excludeExpired?: InputMaybe<Scalars['Boolean']>;
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly laneIds?: InputMaybe<ReadonlyArray<Scalars['Uuid']>>;
  readonly loadSizeId?: InputMaybe<Scalars['ID']>;
  readonly minHeight?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly originGeographySearch?: InputMaybe<RateQuoteGeographyRadiusSearchInput>;
  readonly originGeographyType?: InputMaybe<Scalars['String']>;
  /** DEPRECATED. Do not use this. This will be removed. Use OriginGeographySearch. */
  readonly originText?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly quoteGroupId?: InputMaybe<Scalars['ID']>;
  readonly quotePriceTierId?: InputMaybe<Scalars['ID']>;
  readonly quoteTypeId?: InputMaybe<Scalars['ID']>;
  readonly scacId?: InputMaybe<Scalars['ID']>;
  readonly serviceId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly team?: InputMaybe<Scalars['Boolean']>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
};

export type RateQuotesPaginatedFilterV3 = {
  readonly autoAccept?: InputMaybe<Scalars['Boolean']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly destinationGeographySearch?: InputMaybe<RateQuoteGeographyRadiusSearchInputV3>;
  readonly destinationGeographyType?: InputMaybe<Scalars['String']>;
  /** Deprecated. DO NOT USE. */
  readonly destinationText?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly excludeExpired?: InputMaybe<Scalars['Boolean']>;
  readonly expirationDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly laneIds?: InputMaybe<ReadonlyArray<Scalars['Uuid']>>;
  readonly loadSizeId?: InputMaybe<Scalars['ID']>;
  readonly minHeight?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<RateQuoteUnitOfDistanceInput>;
  readonly originGeographySearch?: InputMaybe<RateQuoteGeographyRadiusSearchInputV3>;
  readonly originGeographyType?: InputMaybe<Scalars['String']>;
  /** Deprecated. DO NOT USE. */
  readonly originText?: InputMaybe<Scalars['String']>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly quoteGroupId?: InputMaybe<Scalars['ID']>;
  readonly quotePriceTierId?: InputMaybe<Scalars['ID']>;
  readonly quoteTypeId?: InputMaybe<Scalars['ID']>;
  readonly scacId?: InputMaybe<Scalars['ID']>;
  readonly serviceIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly team?: InputMaybe<Scalars['Boolean']>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
};

export type RateQuoteTenantConfig = {
  readonly __typename?: 'RateQuoteTenantConfig';
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly metaDataJson?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly sort?: Maybe<Scalars['Int']>;
  readonly systemDefined?: Maybe<Scalars['Boolean']>;
};

export enum RateQuoteUnitOfCurrencyEnum {
  Usd = 'USD'
}

export type RateQuoteUnitOfDistance = {
  readonly __typename?: 'RateQuoteUnitOfDistance';
  readonly asFeet?: Maybe<Scalars['Decimal']>;
  readonly asInches?: Maybe<Scalars['Decimal']>;
  readonly asKilometers?: Maybe<Scalars['Decimal']>;
  readonly asMeters?: Maybe<Scalars['Decimal']>;
  readonly asMiles?: Maybe<Scalars['Decimal']>;
  readonly asRoundedFeet?: Maybe<Scalars['Decimal']>;
  readonly asRoundedInches?: Maybe<Scalars['Decimal']>;
  readonly asRoundedKilometers?: Maybe<Scalars['Decimal']>;
  readonly asRoundedMeters?: Maybe<Scalars['Decimal']>;
  readonly asRoundedMiles?: Maybe<Scalars['Decimal']>;
};

export type RateQuoteUnitOfDistanceInput = {
  readonly unit?: InputMaybe<RateQuoteDistanceUnitEnumType>;
  readonly value?: InputMaybe<Scalars['Decimal']>;
};

export type RatingSourceIdByCustomerResponse = {
  readonly __typename?: 'RatingSourceIdByCustomerResponse';
  readonly ratingSourceId?: Maybe<Scalars['String']>;
};

export type RebookRouteVendorInput = {
  readonly bounceId: Scalars['ID'];
  readonly rebookAsVendorType?: InputMaybe<RouteVendorTypeEnum>;
  readonly routeVendorId: Scalars['ID'];
};

/** Autogenerated return type of RebookRouteVendor */
export type RebookRouteVendorPayload = {
  readonly __typename?: 'RebookRouteVendorPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly routeVendor: RouteVendor;
};

export type RebookRouteVendorV2Input = {
  readonly bounceId: Scalars['ID'];
  readonly rebookAsVendorType?: InputMaybe<RouteVendorTypeEnum>;
  readonly routeVendorId: Scalars['ID'];
};

export type RebookRouteVendorV2Payload = {
  readonly __typename?: 'RebookRouteVendorV2Payload';
  readonly errors: ReadonlyArray<Maybe<UserError>>;
  readonly routeVendor?: Maybe<RouteVendorV2>;
};

export type Ref = {
  readonly __typename?: 'Ref';
  readonly ediQualifier?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly type: KeyValue;
  readonly value: Scalars['String'];
};

export type Reference = {
  readonly __typename?: 'Reference';
  readonly id: Scalars['ID'];
  readonly postingId: Scalars['ID'];
  /** @deprecated Use `thirdPartyId`. */
  readonly referenceId: Scalars['String'];
  readonly thirdPartyId?: Maybe<Scalars['String']>;
  readonly createdDate: Scalars['DateTime'];
  readonly status?: Maybe<PostingStatus>;
  readonly source?: Maybe<PostingSource>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<PostingError>>>;
};

export type RefInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeId: Scalars['ID'];
  readonly value: Scalars['String'];
};

export type RefInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly typeId: Scalars['ID'];
  readonly value: Scalars['String'];
  readonly externalRef?: InputMaybe<Scalars['String']>;
  readonly source?: InputMaybe<Scalars['String']>;
};

export type RefV2 = {
  readonly __typename?: 'RefV2';
  readonly id: Scalars['ID'];
  readonly externalRef?: Maybe<Scalars['String']>;
  readonly type: KeyValue;
  readonly value: Scalars['String'];
  readonly source?: Maybe<Scalars['String']>;
};

/** Holds Region Data Info. */
export type Region = {
  readonly __typename?: 'Region';
  readonly name?: Maybe<Scalars['String']>;
  readonly parenturn?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly urn?: Maybe<Scalars['String']>;
};

/** Holds Region Data Info. */
export type RegionByIdGraphResponse = {
  readonly __typename?: 'RegionByIdGraphResponse';
  readonly code?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly region?: Maybe<Region>;
  readonly status?: Maybe<Scalars['String']>;
};

export type RegionFilter = {
  /** Search/filter By array of region IDs */
  readonly regions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/filter By array of sub region IDs */
  readonly subRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/filter By array of super region IDs */
  readonly superRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** Holds Region Data Info. */
export type RegionGraphResponse = {
  readonly __typename?: 'RegionGraphResponse';
  readonly code?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly regions?: Maybe<ReadonlyArray<Maybe<Region>>>;
  readonly status?: Maybe<Scalars['String']>;
};

/** The input type for Region Info. */
export type RegionInfoInput = {
  readonly country?: InputMaybe<Scalars['String']>;
  readonly zipcode?: InputMaybe<Scalars['String']>;
};

export type RegionInfoV2 = {
  readonly __typename?: 'RegionInfoV2';
  readonly postalCodePrefix?: Maybe<Scalars['String']>;
  readonly postalCodeLower?: Maybe<Scalars['String']>;
  readonly postalCodeUpper?: Maybe<Scalars['String']>;
  readonly countryCode?: Maybe<Scalars['String']>;
  readonly region?: Maybe<RegionV2>;
  readonly superRegion?: Maybe<SuperRegionV2>;
  readonly subRegion?: Maybe<SubRegionV2>;
  readonly stateProvinceName?: Maybe<Scalars['String']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly notYetActive?: Maybe<Scalars['Boolean']>;
  readonly expired?: Maybe<Scalars['Boolean']>;
  readonly regionsetname?: Maybe<Scalars['String']>;
  readonly regionsetid: Scalars['ID'];
};

export type RegionInfoV2Response = {
  readonly __typename?: 'RegionInfoV2Response';
  readonly message?: Maybe<Scalars['String']>;
  readonly regionInfo?: Maybe<RegionInfoV2>;
};

export type RegionSetR2 = {
  readonly __typename?: 'RegionSetR2';
  readonly id: Scalars['ID'];
  readonly regionSetName?: Maybe<Scalars['String']>;
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly fileName?: Maybe<Scalars['String']>;
  readonly modifiedBy?: Maybe<Scalars['String']>;
  readonly modifiedDateTime?: Maybe<Scalars['DateTime']>;
  readonly division?: Maybe<ReadonlyArray<Maybe<DivisionR2>>>;
  readonly equipment?: Maybe<ReadonlyArray<Maybe<EquipmentR2>>>;
  readonly size?: Maybe<ReadonlyArray<Maybe<SizeR2>>>;
  readonly mode?: Maybe<ReadonlyArray<Maybe<ModeR2>>>;
  readonly businessunit?: Maybe<ReadonlyArray<Maybe<BusinessUnitR2>>>;
  readonly regionSetType?: Maybe<ReadonlyArray<Maybe<RegionSetTypeR2>>>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
};

export type RegionSetTypeR2 = {
  readonly __typename?: 'RegionSetTypeR2';
  readonly id?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
};

export type RegionSetV2Input = {
  readonly searchText?: InputMaybe<Scalars['String']>;
  readonly showExpired?: InputMaybe<Scalars['Boolean']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
};

export type RegionsV2Input = {
  readonly postalCode: Scalars['String'];
  readonly countryCode: Scalars['String'];
  readonly date: Scalars['DateTime'];
  readonly regionSetType: Scalars['String'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly equipment?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
};

export type RegionsV2InputNew = {
  readonly postalCode: Scalars['String'];
  readonly countryCode: Scalars['String'];
  readonly date: Scalars['DateTime'];
  readonly regionSetType: Scalars['String'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly equipment?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly businessUnit?: InputMaybe<Scalars['String']>;
};

export type RegionV2 = {
  readonly __typename?: 'RegionV2';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly subRegions?: Maybe<ReadonlyArray<Maybe<SubRegionV2>>>;
  readonly regionsetname?: Maybe<Scalars['String']>;
  readonly isregionsetactive?: Maybe<Scalars['Boolean']>;
  readonly subRegionNames?: Maybe<Scalars['String']>;
};

export type ReleaseCredit = {
  readonly orderCode: Scalars['ID'];
};

export enum RemittanceGroupingEnum {
  /** Route */
  Route = 'Route',
  /** Combined */
  Combined = 'Combined',
  /**
   * Order
   * @deprecated field deprecated, will be removed 08/07/2020
   */
  Order = 'Order'
}

/** Autogenerated return type of RemoveCustomerQuoteFromGroup */
export type RemoveCustomerQuoteFromGroupPayload = {
  readonly __typename?: 'RemoveCustomerQuoteFromGroupPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly quote: CustomerQuote;
};

/** RemoveCustomerQuoteFromGroupPayloadV2 is deprecated. Use RemoveCustomerQuoteFromGroupPayloadV3. */
export type RemoveCustomerQuoteFromGroupPayloadV2 = {
  readonly __typename?: 'RemoveCustomerQuoteFromGroupPayloadV2';
  readonly error: Scalars['String'];
  readonly quote: CustomerQuotePaginatedV2;
};

export type RemoveCustomerQuoteFromGroupPayloadV3 = {
  readonly __typename?: 'RemoveCustomerQuoteFromGroupPayloadV3';
  readonly error?: Maybe<Scalars['String']>;
  readonly quote: CustomerQuoteV3;
};

export type RemoveCustomerQuoteFromGroupPayloadV4 = {
  readonly __typename?: 'RemoveCustomerQuoteFromGroupPayloadV4';
  readonly error?: Maybe<Scalars['String']>;
  readonly quote?: Maybe<CustomerQuoteV3>;
};

export type RemoveCustomerQuoteFromGroupPayloadV5 = {
  readonly __typename?: 'RemoveCustomerQuoteFromGroupPayloadV5';
  readonly error?: Maybe<Scalars['String']>;
  readonly quote?: Maybe<CustomerQuoteV4>;
};

export type RemovePostingInput = {
  readonly id: Scalars['ID'];
  readonly removedByUserId: Scalars['ID'];
  readonly loadBoards?: InputMaybe<ReadonlyArray<PostingSource>>;
};

export type RemovePostingPayload = {
  readonly __typename?: 'RemovePostingPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly success?: Maybe<Scalars['Boolean']>;
};

export type RemovePostingsByRouteIdsInput = {
  readonly routeIds: ReadonlyArray<Scalars['ID']>;
  readonly removedByUserId: Scalars['ID'];
  readonly loadBoards?: InputMaybe<ReadonlyArray<PostingSource>>;
};

export type RemovePostingsInput = {
  readonly postingIds: ReadonlyArray<Scalars['ID']>;
  readonly removedByUserId: Scalars['ID'];
  readonly loadBoards?: InputMaybe<ReadonlyArray<PostingSource>>;
};

export type RemovePostingsPayload = {
  readonly __typename?: 'RemovePostingsPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly success?: Maybe<Scalars['Boolean']>;
};

export type RemoveQuoteFromGroupInput = {
  readonly quoteId: Scalars['ID'];
};

export type RemoveRateQuoteFromQuoteGroupInput = {
  readonly rateQuoteId: Scalars['ID'];
  readonly user?: InputMaybe<UserDetailInput>;
};

export type RenderedTemplate = {
  readonly __typename?: 'RenderedTemplate';
  readonly subject?: Maybe<Scalars['String']>;
  readonly body?: Maybe<Scalars['String']>;
};

export type ReorderLanePatternsByPriorityInput = {
  readonly userId: Scalars['ID'];
  readonly laneId: Scalars['ID'];
  readonly patternPriorities: ReadonlyArray<ReorderPatternInput>;
};

export type ReorderLanePatternsByPriorityPayload = {
  readonly __typename?: 'ReorderLanePatternsByPriorityPayload';
  readonly message?: Maybe<Scalars['String']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly currentLanePatterns?: Maybe<ReadonlyArray<Maybe<Pattern>>>;
};

export type ReorderLanePatternsByPriorityPayloadV2 = {
  readonly __typename?: 'ReorderLanePatternsByPriorityPayloadV2';
  readonly data?: Maybe<ReorderLanePatternsByPriorityPayload>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type ReorderPatternInput = {
  readonly patternId: Scalars['ID'];
  readonly priority: Scalars['Int'];
};

export type ReorderRoutingGuideSequenceItemsInput = {
  readonly routingGuideSequenceItemId: Scalars['ID'];
  readonly ordinal: Scalars['Int'];
};

export type ReorderTenderPlanTenderSequenceInput = {
  readonly tenderId: Scalars['ID'];
  readonly ordinal: Scalars['Int'];
};

export type Rep = {
  readonly __typename?: 'Rep';
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly employee: Employee;
  readonly id: Scalars['ID'];
  readonly isLoadRep: Scalars['Boolean'];
  readonly main: Scalars['Boolean'];
  readonly repType: RepTypeEnum;
  readonly repTypeKeyId?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
};

export type RepIdFilter = {
  readonly orderRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type RepIdsFilter = {
  /** Search/filter by order rep IDs */
  readonly orderRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/filter By route rep IDs */
  readonly routeRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type RepInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly employeeId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isLoadRep?: InputMaybe<Scalars['Boolean']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly repType: RepTypeEnum;
};

export type RepInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly repType: RepTypeEnum;
  readonly employeeId: Scalars['ID'];
  readonly repTypeKeyId?: InputMaybe<Scalars['ID']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly isLoadRep?: InputMaybe<Scalars['Boolean']>;
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly divisionId?: InputMaybe<Scalars['ID']>;
  readonly modeId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly businessUnitId?: InputMaybe<Scalars['ID']>;
  readonly directionId?: InputMaybe<Scalars['ID']>;
};

export type ReportScheduleDat = {
  readonly __typename?: 'ReportScheduleDat';
  readonly route?: Maybe<Scalars['String']>;
  readonly actionString?: Maybe<Scalars['String']>;
  readonly leg?: Maybe<Scalars['Int']>;
  readonly location?: Maybe<Scalars['String']>;
  readonly time?: Maybe<Scalars['String']>;
  readonly action: Scalars['String'];
  readonly latitude?: Maybe<Scalars['Float']>;
  readonly longitude?: Maybe<Scalars['Float']>;
  readonly milesToNext?: Maybe<Scalars['Float']>;
  readonly hoursToNext?: Maybe<Scalars['Float']>;
};

export enum RepTypeEnum {
  /** Accounts Payable */
  AccountsPayable = 'accountsPayable',
  /** Carrier Manager */
  CarrierManager = 'carrierManager',
  /** Carrier Ops */
  CarrierOps = 'carrierOps',
  /** Carrier Sales */
  CarrierSales = 'carrierSales',
  /** Issue Resolution Rep */
  IssueResolutionRep = 'issueResolutionRep',
  /** Tracking Specialist */
  TrackingSpecialist = 'trackingSpecialist',
  /** Other */
  Other = 'other',
  /** Sponsored Prospect */
  SponsoredProspect = 'sponsoredProspect'
}

export type RepV2 = {
  readonly __typename?: 'RepV2';
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<UserV2>;
  readonly createdByUserId?: Maybe<Scalars['ID']>;
  readonly employee: EmployeeV2;
  readonly employeeId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly isLoadRep: Scalars['Boolean'];
  readonly main: Scalars['Boolean'];
  readonly active: Scalars['Boolean'];
  readonly repType: RepTypeEnum;
  readonly repTypeKey?: Maybe<KeyValue>;
  readonly repTypeKeyId?: Maybe<Scalars['ID']>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<UserV2>;
  readonly updatedByUserId?: Maybe<Scalars['ID']>;
  readonly division?: Maybe<KeyValue>;
  readonly divisionId?: Maybe<Scalars['ID']>;
  readonly mode?: Maybe<KeyValue>;
  readonly modeId?: Maybe<Scalars['ID']>;
  readonly size?: Maybe<KeyValue>;
  readonly sizeId?: Maybe<Scalars['ID']>;
  readonly businessUnit?: Maybe<KeyValue>;
  readonly businessUnitId?: Maybe<Scalars['ID']>;
  readonly direction?: Maybe<KeyValue>;
  readonly directionId?: Maybe<Scalars['ID']>;
};

export type RequestOutput = {
  readonly __typename?: 'RequestOutput';
  readonly provider: ReadonlyArray<Maybe<Scalars['String']>>;
  readonly hazmatShipment: Scalars['Boolean'];
  readonly shipFromLocation: RequestShipLocation;
  readonly shipToLocation: RequestShipLocation;
  readonly pickUpDate?: Maybe<Scalars['DateTime']>;
};

export type RequestShipLocation = {
  readonly __typename?: 'RequestShipLocation';
  readonly city?: Maybe<Scalars['String']>;
  readonly stateAbbreviation?: Maybe<Scalars['String']>;
  readonly zipCode: Scalars['String'];
  readonly country?: Maybe<Scalars['String']>;
};

/** Response Payload Object from requestToBookV2 mutation */
export type RequestToBookPayloadV2 = {
  readonly __typename?: 'RequestToBookPayloadV2';
  readonly code?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly validationCode?: Maybe<Scalars['String']>;
  readonly validationMessage?: Maybe<Scalars['String']>;
};

export type Requirement = {
  readonly __typename?: 'Requirement';
  readonly dataType: DataType;
  readonly dataValidations: ReadonlyArray<LogicalCheck>;
  readonly displayName: Scalars['String'];
  readonly requirementName?: Maybe<Scalars['String']>;
  readonly isRequired: Scalars['Boolean'];
  readonly logicGate?: Maybe<LogicGate>;
  readonly dataDictionaryTermID?: Maybe<Scalars['String']>;
  /** @deprecated This property has been replaced by requirementName and will be removed in the future */
  readonly fieldName?: Maybe<Scalars['String']>;
};

export type RequirementInput = {
  readonly dataType: DataType;
  readonly dataValidations: ReadonlyArray<LogicalCheckInput>;
  readonly displayName: Scalars['String'];
  readonly requirementName?: InputMaybe<Scalars['String']>;
  readonly isRequired: Scalars['Boolean'];
  readonly logicGate?: InputMaybe<LogicGate>;
  readonly dataDictionaryTermID?: InputMaybe<Scalars['String']>;
  readonly fieldName?: InputMaybe<Scalars['String']>;
};

export type ResequenceLoadStopsInput = {
  readonly loadStopIds: ReadonlyArray<Scalars['ID']>;
  readonly loadId: Scalars['ID'];
};

export type ResequenceStopsInput = {
  readonly routeStopIds: ReadonlyArray<Scalars['ID']>;
  readonly routeId: Scalars['ID'];
};

export type ReserveCreditNoTotalRate = {
  readonly orderCode: Scalars['ID'];
};

export type ReserveCreditWithDetails = {
  readonly customerId: Scalars['ID'];
  readonly orderId: Scalars['ID'];
  readonly rateDetails: ReadonlyArray<InputMaybe<CreditRateDetails>>;
  readonly invoiceHeaders: ReadonlyArray<InputMaybe<CreditInvoiceHeader>>;
};

export type ReserveCreditWithRateDetails = {
  readonly orderId: Scalars['ID'];
  readonly rateDetails?: InputMaybe<ReadonlyArray<InputMaybe<CreditRateDetails>>>;
};

export type ReserveCreditWithTotalRate = {
  readonly orderId: Scalars['ID'];
  readonly totalRate: Scalars['Float'];
};

export type ResetSublocationApiDdtCacheError = {
  readonly __typename?: 'ResetSublocationApiDdtCacheError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

export type ResetSublocationApiDdtCachePayload = {
  readonly __typename?: 'ResetSublocationApiDdtCachePayload';
  readonly errors: ReadonlyArray<ResetSublocationApiDdtCacheError>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
};

export type ResultAddress = {
  readonly __typename?: 'ResultAddress';
  readonly requestId?: Maybe<Scalars['ID']>;
  readonly addressLine1: Scalars['String'];
  readonly addressLine2?: Maybe<Scalars['String']>;
  readonly addressLine3?: Maybe<Scalars['String']>;
  readonly addressLine4?: Maybe<Scalars['String']>;
  readonly locality?: Maybe<Scalars['String']>;
  readonly administrativeArea?: Maybe<Scalars['String']>;
  readonly countryAlpha2?: Maybe<Scalars['String']>;
  readonly countryAlpha3?: Maybe<Scalars['String']>;
  readonly postalCode?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly isVerified?: Maybe<Scalars['Boolean']>;
};

export enum RiskInformationSourceTypeEnum {
  /** source not specified. */
  UnKnown = 'unKnown',
  /** updated by RMIS API */
  RmisApi = 'rmisApi',
  /** updated by RMIS FTP */
  RmisFtp = 'rmisFtp',
  /** updated by USER INPUT */
  UserInput = 'userInput'
}

export enum RoeStatusEnum {
  Green = 'Green',
  Red = 'Red',
  Yellow = 'Yellow'
}

export type Round = {
  readonly __typename?: 'Round';
  readonly roundId: Scalars['ID'];
  readonly externalRoundId?: Maybe<Scalars['String']>;
  readonly roundNumber?: Maybe<Scalars['Int']>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly roundStatusId?: Maybe<Scalars['ID']>;
  readonly bidEid?: Maybe<Scalars['Int']>;
  readonly roundStatus?: Maybe<BidStatus>;
  readonly createdBy?: Maybe<Scalars['ID']>;
};

export type RoundInput = {
  readonly roundId: Scalars['ID'];
  readonly startDate: Scalars['String'];
  readonly endDate: Scalars['String'];
};

export type RoundPayload = {
  readonly __typename?: 'RoundPayload';
  readonly round?: Maybe<Round>;
};

export type RouteAllocatedMargin = {
  readonly __typename?: 'RouteAllocatedMargin';
  readonly routeId: Scalars['ID'];
  /** @deprecated Use the Route object */
  readonly routeCode: Scalars['String'];
  readonly allocatedMargin?: Maybe<Scalars['Decimal']>;
  readonly grossAllocatedMargin?: Maybe<Scalars['Decimal']>;
  readonly allocatedCompanyOverheadAmount?: Maybe<Scalars['Decimal']>;
  readonly routeRateAllocationPercent?: Maybe<Scalars['Decimal']>;
  readonly routeCostAllocationPercent?: Maybe<Scalars['Decimal']>;
  readonly routeCostTotal?: Maybe<Scalars['Decimal']>;
  readonly grossRouteAllocatedRate?: Maybe<Scalars['Decimal']>;
  readonly grossRouteAllocatedCost?: Maybe<Scalars['Decimal']>;
  readonly distance?: Maybe<Scalars['Decimal']>;
  readonly distancePercent?: Maybe<Scalars['Decimal']>;
  readonly routeCommissions?: Maybe<ReadonlyArray<Maybe<RouteCommissionModel>>>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  /** @deprecated Use the Carrier object */
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly route?: Maybe<LoadRoute>;
  readonly routeV2?: Maybe<LoadRouteV2>;
};

/** RouteCommission */
export type RouteCommissionModel = {
  readonly __typename?: 'RouteCommissionModel';
  readonly commissionAmount?: Maybe<Scalars['Decimal']>;
  readonly employeeId?: Maybe<Scalars['ID']>;
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly carrierRepTypeId?: Maybe<Scalars['String']>;
  readonly commissionPercent?: Maybe<Scalars['Decimal']>;
  readonly commissionBasisId?: Maybe<Scalars['String']>;
  readonly commissionBasisAmount?: Maybe<Scalars['Decimal']>;
  readonly marginBasisId?: Maybe<Scalars['String']>;
  readonly marginBasisPercent?: Maybe<Scalars['Decimal']>;
  readonly displayCommissionType?: Maybe<Scalars['String']>;
  readonly commissionBasisFlatAmount?: Maybe<Scalars['Decimal']>;
  readonly commissionBasisPercentAmount?: Maybe<Scalars['Decimal']>;
  readonly employee?: Maybe<Employee>;
  readonly employeeV2?: Maybe<EmployeeV2>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly route?: Maybe<LoadRoute>;
  readonly routeV2?: Maybe<LoadRouteV2>;
};

export type RouteDat = {
  readonly route: Scalars['String'];
  readonly availableTime: Scalars['String'];
  readonly remainingOnDutyMinutes: Scalars['Int'];
  readonly remainingShiftDriveMinutes: Scalars['Int'];
  readonly breakTaken: Scalars['Boolean'];
  readonly calendarType: Scalars['String'];
  readonly onDutyDayStart: Scalars['Int'];
  readonly haversineOverride: Scalars['Float'];
  readonly defaultMph: Scalars['Float'];
  readonly defaultMphMileThreshold: Scalars['Float'];
  readonly ignoreHos: Scalars['Boolean'];
  readonly haversineMesh: Scalars['Float'];
  readonly driveMinuteMesh: Scalars['Int'];
};

export type RouteDimensions = {
  readonly __typename?: 'RouteDimensions';
  readonly length: Length;
  readonly height?: Maybe<Length>;
  readonly width?: Maybe<Length>;
};


export type RouteDimensionsLengthArgs = {
  unit: UnitOfLengthEnum;
};


export type RouteDimensionsHeightArgs = {
  unit: UnitOfLengthEnum;
};


export type RouteDimensionsWidthArgs = {
  unit: UnitOfLengthEnum;
};

export type RouteLaneMatch = {
  readonly __typename?: 'RouteLaneMatch';
  readonly laneCode?: Maybe<Scalars['String']>;
  readonly laneId?: Maybe<Scalars['ID']>;
  readonly routeCode: Scalars['String'];
  readonly routeId: Scalars['ID'];
};

export type RouteLaneMatchInput = {
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly minHeight?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minLength?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly minWidth?: InputMaybe<LaneUnitOfDistanceInput>;
  readonly project?: InputMaybe<Scalars['String']>;
  readonly routeCode: Scalars['String'];
  readonly routeId: Scalars['ID'];
  readonly serviceLevel?: InputMaybe<Scalars['String']>;
  readonly stops: ReadonlyArray<RouteLaneMatchStopInput>;
  readonly trailerTypeIds: ReadonlyArray<Scalars['ID']>;
  readonly transportMode?: InputMaybe<Scalars['String']>;
};

export type RouteLaneMatchStopInput = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly stopType: Scalars['String'];
};

export enum RouteLifeCycleStatusEnum {
  Complete = 'COMPLETE',
  Covered = 'COVERED',
  Dispatched = 'DISPATCHED',
  None = 'NONE',
  Open = 'OPEN',
  /** @deprecated Field no longer supported */
  Unmapped = 'UNMAPPED',
  Arrivedstop = 'ARRIVEDSTOP',
  Departedstop = 'DEPARTEDSTOP',
  Pending = 'PENDING',
  Pretender = 'PRETENDER',
  Driverassigned = 'DRIVERASSIGNED',
  Tendered = 'TENDERED'
}

export type RouteMatch = {
  readonly __typename?: 'RouteMatch';
  readonly id: Scalars['ID'];
  readonly routeId: Scalars['String'];
  readonly loadId: Scalars['String'];
  readonly capacityNeed?: Maybe<ReadonlyArray<CapacityNeed>>;
  readonly originDeadheadMileage?: Maybe<Scalars['Float']>;
  readonly destinationDeadheadMileage?: Maybe<Scalars['Float']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly isDeleted?: Maybe<Scalars['Boolean']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly routePickupCity?: Maybe<Scalars['String']>;
  readonly routePickupState?: Maybe<Scalars['String']>;
  readonly routePickupCountry?: Maybe<Scalars['String']>;
  readonly routePickupStartDate?: Maybe<Scalars['DateTime']>;
  readonly routePickupEndDate?: Maybe<Scalars['DateTime']>;
  readonly routePickupTimeZone?: Maybe<Scalars['String']>;
  readonly routePickupStartDay?: Maybe<Scalars['String']>;
  readonly routePickupEndDay?: Maybe<Scalars['String']>;
  readonly routeFirstStopDateRange?: Maybe<AvailableDateRange>;
  readonly routeFirstStopLiveType?: Maybe<ScheduleDropTypeEnum>;
  readonly routeDeliveryCity?: Maybe<Scalars['String']>;
  readonly routeDeliveryState?: Maybe<Scalars['String']>;
  readonly routeDeliveryCountry?: Maybe<Scalars['String']>;
  readonly routeDeliveryStartDate?: Maybe<Scalars['DateTime']>;
  readonly routeDeliveryEndDate?: Maybe<Scalars['DateTime']>;
  readonly routeDeliveryTimeZone?: Maybe<Scalars['String']>;
  readonly routeDeliveryStartDay?: Maybe<Scalars['String']>;
  readonly routeDeliveryEndDay?: Maybe<Scalars['String']>;
  readonly routeLastStopDateRange?: Maybe<AvailableDateRange>;
  readonly routeLastStopLiveType?: Maybe<ScheduleDropTypeEnum>;
  readonly routeLength?: Maybe<Scalars['Int']>;
  readonly routeTrailerLength?: Maybe<Length>;
  readonly routeCommodityMinTemperature?: Maybe<TemperatureV2>;
  readonly stopCount?: Maybe<Scalars['Int']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerRepName?: Maybe<Scalars['String']>;
  readonly routeTrailerTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly segmentCode?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly requirements?: Maybe<ReadonlyArray<OrderRequirementInfo>>;
  readonly hotRoute?: Maybe<Scalars['Boolean']>;
  readonly tmsType?: Maybe<Scalars['String']>;
  readonly mainCustomerRepEmployeeId?: Maybe<Scalars['String']>;
  readonly expectedMaxWeight?: Maybe<Scalars['Float']>;
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly equipment?: Maybe<Scalars['String']>;
  readonly routeLoadRouteSequence?: Maybe<Fraction>;
};

export type RouteMaxCostInputType = {
  readonly loadId: Scalars['ID'];
  readonly maxCost?: InputMaybe<Scalars['Decimal']>;
  readonly overMax?: InputMaybe<Scalars['Decimal']>;
  readonly routeId: Scalars['ID'];
};

export type RouteMaxCostType = {
  readonly __typename?: 'RouteMaxCostType';
  readonly code?: Maybe<Scalars['String']>;
  readonly loadId: Scalars['ID'];
  readonly maxCost?: Maybe<Scalars['Decimal']>;
  readonly overMax?: Maybe<Scalars['Decimal']>;
  readonly routeId: Scalars['ID'];
};

/** DO NOT USE - Type is depcrecated. */
export type RouteOffer = {
  readonly __typename?: 'RouteOffer';
  readonly id: Scalars['String'];
  readonly routeId: Scalars['String'];
  readonly type: Scalars['String'];
  readonly status: Scalars['String'];
  readonly vendor: Carrier;
  readonly priceV2?: Maybe<OfferPrice>;
  readonly updatedAt: Scalars['String'];
};

export type RouteOverride = {
  readonly __typename?: 'RouteOverride';
  readonly routeId: Scalars['ID'];
  readonly totalCost: Scalars['Decimal'];
};

export type RouteOverrideInput = {
  readonly routeId: Scalars['ID'];
  readonly totalCost: Scalars['Decimal'];
};

export type RoutePathDat = {
  readonly route: Scalars['String'];
  readonly location: Scalars['String'];
  readonly leg: Scalars['Int'];
  readonly lowerWindow?: InputMaybe<Scalars['String']>;
  readonly upperWindow?: InputMaybe<Scalars['String']>;
  readonly serviceMinutes?: InputMaybe<Scalars['Int']>;
  readonly offDutyMinutes?: InputMaybe<Scalars['Int']>;
};

export type RouteRequirement = {
  readonly __typename?: 'RouteRequirement';
  readonly option: KeyValue;
};

export type RouteRequirementInput = {
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly metadataJson: Scalars['String'];
};

export type RoutesQueryInput = {
  /** Search/Filter by ComputedCurrentAppointment status */
  readonly appointmentStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by appt_conf_by */
  readonly apptConfBy?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by appt_req_with */
  readonly apptReqWith?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter array of group ids */
  readonly byCustomerEmployeeGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Filter by order reps */
  readonly byOrderRepIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Filter by route reps */
  readonly byRouteRepIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter array of route_vendor ids */
  readonly carrierRouteVendorIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/Filter by code or partial code */
  readonly code?: InputMaybe<Scalars['String']>;
  /** Search/Filter by customer ids */
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search by delivery address */
  readonly deliveryAddress?: InputMaybe<AddressesFilter>;
  /** Start range ending */
  readonly endDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Search/Filter by available_start_date or req_date range ending */
  readonly endDateReqDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Search/Filter by facility id */
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  /** Search/Filter by first stop address */
  readonly firstStopAddress?: InputMaybe<AddressesFilter>;
  /** Search by inbound regions (DEPRECATED) */
  readonly inboundRegion?: InputMaybe<RegionFilter>;
  /** Search by Inbound (destination) region */
  readonly inboundRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search by Inbound (destination) sub region */
  readonly inboundSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search by Inbound (destination) super region */
  readonly inboundSuperRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by last stop address */
  readonly lastStopAddress?: InputMaybe<AddressesFilter>;
  /** Search/Filter by Main Customer Rep */
  readonly mainCustomerRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/Filter by Order Activation Status */
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by Order Tender Status */
  readonly orderTenderStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search by inbound regions (DEPRECATED) */
  readonly outboundRegion?: InputMaybe<RegionFilter>;
  /** Search by outbound(origin) regions */
  readonly outboundRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search by outbound(origin) sub regions */
  readonly outboundSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search by outbound(origin) super regions */
  readonly outboundSuperRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search by pick_up address */
  readonly pickUpAddress?: InputMaybe<AddressesFilter>;
  /** Search/Filter by req_ship_date */
  readonly reqDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Search/Filter by Route Activation Status */
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/filter route rep AND order rep ids */
  readonly routeRepAndOrderRepIds?: InputMaybe<RepIdsFilter>;
  /** Search/Filter route rep OR order rep ids */
  readonly routeRepOrOrderRepIds?: InputMaybe<RepIdsFilter>;
  /** Search/Filter by schedule_drop_type */
  readonly scheduleDropType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by facility scheduling system type */
  readonly schedulingSystemType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Start range beginning */
  readonly startDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Search/Filter by available_start_date or req_date range beginning */
  readonly startDateReqDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Route Life Cycle Status */
  readonly status?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search by stop address */
  readonly stopAddress?: InputMaybe<AddressesFilter>;
  /** Search/Filter by available_start_date or available_end_date */
  readonly stopDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Filter by stops.schedule_appointment_type with null time interval */
  readonly stopScheduleAppointment?: InputMaybe<Scalars['String']>;
  /** Filter by stops.schedule_appointment_type with null time interval ['appt', 'notice'] */
  readonly stopScheduleAppointments?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by stop_type */
  readonly stopType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by Trailer Type */
  readonly trailerType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by Transport Mode */
  readonly transportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** lachesis-api: Route Status Record V3 - Features Multi-Route Functionality */
export type RouteStatusRecordV3 = {
  readonly __typename?: 'RouteStatusRecordV3';
  /** lachesis-api: ID associated with a Load. */
  readonly load_id: Scalars['ID'];
  /** lachesis-api: Timestamp when event occured */
  readonly event_timestamp: Scalars['Timestamp'];
  /** lachesis-api: ID of the user or process that initiated the event for status calculation */
  readonly initiator?: Maybe<Scalars['String']>;
  /** lachesis-api: ID of the route associated with the load. */
  readonly route_id: Scalars['ID'];
  /** lachesis-api: Timestamp when the status record was created */
  readonly created_at_timestamp: Scalars['Timestamp'];
  /** lachesis-api: ID of carrier associated to the route */
  readonly carrier_id?: Maybe<Scalars['ID']>;
  /** lachesis-api: Current route tender status for the associated route */
  readonly route_tender_status?: Maybe<Scalars['String']>;
  /** lachesis-api: Current route activation status for the associated route */
  readonly route_activation_status?: Maybe<Scalars['String']>;
  /** lachesis-api: Current route lifecycle status for the associated route */
  readonly route_lifecycle_status?: Maybe<Scalars['String']>;
  /** lachesis-api: Current stop number that contributed to status calculation. */
  readonly route_stop_number?: Maybe<Scalars['Int']>;
  /** lachesis-api: Array of Vendor Financial Statuses. */
  readonly route_vendor_financial_statuses: ReadonlyArray<RouteVendorFinancialStatusV3>;
};

export type RouteStop = {
  readonly __typename?: 'RouteStop';
  readonly id: Scalars['ID'];
  readonly facilityId: Scalars['ID'];
  readonly facility: Facility;
  readonly facilityV2: FacilityV2;
  readonly note?: Maybe<Scalars['String']>;
  readonly scheduleType?: Maybe<ScheduleAppointmentTypeEnum>;
  readonly liveType?: Maybe<ScheduleDropTypeEnum>;
  readonly workType?: Maybe<ScheduleWorkTypeEnum>;
  readonly isOrderStop?: Maybe<Scalars['Boolean']>;
  /** @deprecated use loadStopType as it accounts for multi-route stop types, if loadStopType is present and its value is not a StopTypeEnum, this value defaults to 'Pu' */
  readonly type: StopTypeEnum;
  readonly loadStopType?: Maybe<Scalars['String']>;
  readonly loadFrom?: Maybe<KeyValue>;
  readonly unloadFrom?: Maybe<KeyValue>;
  readonly reqDate?: Maybe<DatetimeWithTimezone>;
  readonly availableStart?: Maybe<DatetimeWithTimezone>;
  readonly availableStartTimes?: Maybe<ScheduleRange>;
  readonly availableEnd?: Maybe<DatetimeWithTimezone>;
  readonly availableEndTimes?: Maybe<ScheduleRange>;
  readonly requirements?: Maybe<ReadonlyArray<StopRequirement>>;
  readonly commodities?: Maybe<ReadonlyArray<CommodityV2>>;
  readonly superRegion?: Maybe<Region>;
  readonly region?: Maybe<Region>;
  readonly subRegion?: Maybe<Region>;
  readonly address?: Maybe<StopAddress>;
  readonly orderStopId?: Maybe<Scalars['ID']>;
  readonly refs?: Maybe<ReadonlyArray<RefV2>>;
  readonly bolNumber?: Maybe<Scalars['String']>;
  readonly appointment?: Maybe<AppointmentV2>;
  /** @deprecated Use new field schedulingLog instead */
  readonly appointments?: Maybe<ReadonlyArray<AppointmentV2>>;
  readonly schedulingLog?: Maybe<ReadonlyArray<SchedulingLog>>;
  readonly sealNumber?: Maybe<Scalars['String']>;
  readonly computedCurrentAppointment?: Maybe<AppointmentV2>;
  readonly schedulingContactId?: Maybe<Scalars['ID']>;
  readonly schedulingContact?: Maybe<Contact>;
  readonly schedulingContactV2?: Maybe<FacilityContact>;
  readonly apptConfBy?: Maybe<Scalars['String']>;
  readonly apptReqWith?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly updatedByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  /** Stop Unique Identifier for external system */
  readonly externalRef?: Maybe<Scalars['ID']>;
  readonly appointmentChangeReason?: Maybe<Scalars['ID']>;
  /** Do not use. Dev in Progress */
  readonly freightAssignmentRegion?: Maybe<FreightAssignmentRegion>;
  readonly numberOfPallets?: Maybe<Scalars['Int']>;
  readonly isCreatedManually?: Maybe<Scalars['Boolean']>;
  readonly splc?: Maybe<Scalars['String']>;
  /** PU/Del Codes from Customer-Facility Relationship */
  readonly externalFacilityId?: Maybe<Scalars['String']>;
  readonly distanceToNext?: Maybe<Length>;
  readonly route: LoadRouteV2;
  /** DEPRECATED - DO NOT USE */
  readonly trackingETA?: Maybe<TrackingEta>;
  readonly trackingAutoETADatetime?: Maybe<Scalars['DateTime']>;
  readonly trackingManualETADatetime?: Maybe<Scalars['DateTime']>;
  readonly trackingAutoETAUsesDataScienceFormula?: Maybe<Scalars['Boolean']>;
  readonly stopEventsV2: ReadonlyArray<StopEventV2>;
  /** @deprecated INCORRECT TYPE, USE stopEventsV2 */
  readonly stopSubEvents: ReadonlyArray<StopEvent>;
  readonly usableStopEvents: StopEventEngineOutput;
};


export type RouteStopDistanceToNextArgs = {
  unit: UnitOfLengthEnum;
};


export type RouteStopUsableStopEventsArgs = {
  routeType: Scalars['String'];
};

export type RouteStopError = {
  readonly __typename?: 'RouteStopError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

export type RouteStopInfo = {
  readonly __typename?: 'RouteStopInfo';
  readonly id: Scalars['String'];
  readonly loadStopType?: Maybe<Scalars['String']>;
  readonly address: Scalars['String'];
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly point?: Maybe<Point>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly startDateTimeZone?: Maybe<Scalars['String']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly endDateTimeZone?: Maybe<Scalars['String']>;
  readonly regionUrn?: Maybe<Scalars['String']>;
  readonly subRegionUrn?: Maybe<Scalars['String']>;
  readonly freightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly freightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly freightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly liveType?: Maybe<ScheduleDropTypeEnum>;
  readonly scheduleType?: Maybe<Scalars['String']>;
  readonly facilityId?: Maybe<Scalars['String']>;
  readonly facilityName: Scalars['String'];
  readonly appointmentStatus?: Maybe<Scalars['String']>;
  readonly totalCommodityWeight?: Maybe<Mass>;
};

export type RouteStopInput = {
  readonly routeId: Scalars['ID'];
  readonly facilityId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly scheduleType?: InputMaybe<ScheduleAppointmentTypeEnum>;
  readonly liveType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly workType?: InputMaybe<ScheduleWorkTypeEnum>;
  readonly isOrderStop?: InputMaybe<Scalars['Boolean']>;
  readonly type?: InputMaybe<StopTypeEnum>;
  /**
   * value is validated against following options:
   *     crossDock, originRamp, destinationRamp, customsBroker, yard, PU, Del, TA, TD, domicile, maintenanceShop, scale, fuel, driverComplianceTraining, waypoint, railJunction, relay, terminal, seaPort, airport, containerDepot, borderCrossing, transload, trailerWash
   */
  readonly loadStopType?: InputMaybe<Scalars['String']>;
  readonly loadFromId?: InputMaybe<Scalars['ID']>;
  readonly unloadFromId?: InputMaybe<Scalars['ID']>;
  readonly requirements?: InputMaybe<ReadonlyArray<StopRequirementInput>>;
  readonly commodities?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly orderStopId?: InputMaybe<Scalars['ID']>;
  readonly refs: ReadonlyArray<RefInputV2>;
  readonly reqDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly availableEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly bolNumber?: InputMaybe<Scalars['String']>;
  readonly sealNumber?: InputMaybe<Scalars['String']>;
  readonly schedulingContactId?: InputMaybe<Scalars['ID']>;
  readonly apptConfBy?: InputMaybe<Scalars['String']>;
  readonly apptReqWith?: InputMaybe<Scalars['String']>;
  /** Stop Unique Identifier for external system */
  readonly externalRef?: InputMaybe<Scalars['String']>;
  readonly appointmentChangeReason?: InputMaybe<Scalars['String']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  readonly isCreatedManually?: InputMaybe<Scalars['Boolean']>;
  readonly splc?: InputMaybe<Scalars['String']>;
  /** PU/Del Codes from Customer-Facility Relationship */
  readonly externalFacilityId?: InputMaybe<Scalars['String']>;
};

export type RouteStopPayload = {
  readonly __typename?: 'RouteStopPayload';
  readonly errors: ReadonlyArray<RouteStopError>;
  readonly routeStop?: Maybe<RouteStop>;
};

export type RouteStopPayloadV2 = {
  readonly __typename?: 'RouteStopPayloadV2';
  readonly errors: ReadonlyArray<RouteStopV2Error>;
  readonly routeStopV2?: Maybe<RouteStopV2>;
};

export type RouteStopsPayload = {
  readonly __typename?: 'RouteStopsPayload';
  readonly routeStops: ReadonlyArray<RouteStopV2>;
};

export type RouteStopsPayloadError = {
  readonly __typename?: 'RouteStopsPayloadError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

export type RouteStopV2 = {
  readonly __typename?: 'RouteStopV2';
  readonly id: Scalars['ID'];
  readonly facilityId?: Maybe<Scalars['ID']>;
  readonly facility?: Maybe<Facility>;
  readonly facilityV2?: Maybe<FacilityV2>;
  readonly note?: Maybe<Scalars['String']>;
  readonly scheduleType?: Maybe<ScheduleAppointmentTypeEnum>;
  readonly liveType?: Maybe<ScheduleDropTypeEnum>;
  readonly workType?: Maybe<ScheduleWorkTypeEnum>;
  readonly isOrderStop?: Maybe<Scalars['Boolean']>;
  /** @deprecated use loadStopType as it accounts for multi-route stop types, if loadStopType is present and its value is not a StopTypeEnum, this value defaults to 'Pu' */
  readonly type: StopTypeEnum;
  readonly loadStopType?: Maybe<Scalars['String']>;
  readonly loadFrom?: Maybe<KeyValue>;
  readonly unloadFrom?: Maybe<KeyValue>;
  readonly reqDate?: Maybe<DatetimeWithTimezone>;
  readonly availableStart?: Maybe<DatetimeWithTimezone>;
  readonly availableStartTimes?: Maybe<ScheduleRange>;
  readonly availableEnd?: Maybe<DatetimeWithTimezone>;
  readonly availableEndTimes?: Maybe<ScheduleRange>;
  readonly requirements?: Maybe<ReadonlyArray<StopRequirement>>;
  readonly commodities?: Maybe<ReadonlyArray<CommodityV2>>;
  readonly superRegion?: Maybe<Region>;
  readonly region?: Maybe<Region>;
  readonly subRegion?: Maybe<Region>;
  readonly address?: Maybe<StopAddressV2>;
  readonly orderStopId?: Maybe<Scalars['ID']>;
  readonly refs?: Maybe<ReadonlyArray<RefV2>>;
  readonly bolNumber?: Maybe<Scalars['String']>;
  readonly appointment?: Maybe<AppointmentV2>;
  /** @deprecated Use new field schedulingLog instead */
  readonly appointments?: Maybe<ReadonlyArray<AppointmentV2>>;
  readonly schedulingLog?: Maybe<ReadonlyArray<SchedulingLog>>;
  readonly sealNumber?: Maybe<Scalars['String']>;
  readonly computedCurrentAppointment?: Maybe<AppointmentV2>;
  readonly schedulingContactId?: Maybe<Scalars['ID']>;
  readonly schedulingContact?: Maybe<Contact>;
  readonly schedulingContactV2?: Maybe<FacilityContact>;
  readonly apptConfBy?: Maybe<Scalars['String']>;
  readonly apptReqWith?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly updatedByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  /** Stop Unique Identifier for external system */
  readonly externalRef?: Maybe<Scalars['ID']>;
  readonly appointmentChangeReason?: Maybe<Scalars['ID']>;
  /** Do not use. Dev in Progress */
  readonly freightAssignmentRegion?: Maybe<FreightAssignmentRegion>;
  readonly numberOfPallets?: Maybe<Scalars['Int']>;
  readonly isCreatedManually?: Maybe<Scalars['Boolean']>;
  readonly splc?: Maybe<Scalars['String']>;
  /** PU/Del Codes from Customer-Facility Relationship */
  readonly externalFacilityId?: Maybe<Scalars['String']>;
  readonly distanceToNext?: Maybe<Length>;
  readonly route: LoadRouteV2;
  readonly trackingAutoETADatetime?: Maybe<Scalars['DateTime']>;
  readonly trackingManualETADatetime?: Maybe<Scalars['DateTime']>;
  readonly trackingAutoETAUsesDataScienceFormula?: Maybe<Scalars['Boolean']>;
  readonly stopEventsV2: ReadonlyArray<StopEventV2>;
  readonly usableStopEvents: StopEventEngineOutput;
};


export type RouteStopV2DistanceToNextArgs = {
  unit: UnitOfLengthEnum;
};


export type RouteStopV2UsableStopEventsArgs = {
  routeType: Scalars['String'];
};

export type RouteStopV2Error = {
  readonly __typename?: 'RouteStopV2Error';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

/** The type of route (Mastery vs External System) */
export enum RouteType {
  Mastery = 'MASTERY',
  Revenova = 'REVENOVA',
  External = 'EXTERNAL'
}

export type RouteVendor = {
  readonly __typename?: 'RouteVendor';
  readonly activeBounce?: Maybe<Bounce>;
  readonly booked: Scalars['Boolean'];
  readonly bookedBy: Employee;
  readonly bookedWith?: Maybe<Contact>;
  readonly bookingNotes?: Maybe<Scalars['String']>;
  readonly bounced: Scalars['Boolean'];
  readonly bounces?: Maybe<ReadonlyArray<Bounce>>;
  readonly bracingType?: Maybe<BracingTypeEnum>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdBy: Employee;
  readonly createdByUser?: Maybe<User>;
  readonly distance?: Maybe<UnitOfDistance>;
  readonly expectedEmptyGeography?: Maybe<Geography>;
  readonly id: Scalars['ID'];
  readonly mainVendorRep?: Maybe<RouteVendorRep>;
  readonly numberOfBraces?: Maybe<Scalars['Int']>;
  readonly numberOfPallets?: Maybe<Scalars['Int']>;
  readonly numberOfTarps?: Maybe<Scalars['Int']>;
  readonly palletType?: Maybe<PalletTypeEnum>;
  readonly pickupEta?: Maybe<PickupEta>;
  readonly route?: Maybe<LoadRoute>;
  readonly routeVendorCost?: Maybe<RouteVendorCost>;
  readonly routeVendorReps?: Maybe<ReadonlyArray<RouteVendorRep>>;
  readonly tarpType?: Maybe<TarpTypeEnum>;
  /** @deprecated Use trailer_height_v2 instead */
  readonly trailerHeight?: Maybe<UnitOfDistance>;
  readonly trailerHeightV2?: Maybe<Length>;
  /** @deprecated Use trailer_length_v2 instead */
  readonly trailerLength?: Maybe<UnitOfDistance>;
  readonly trailerLengthV2?: Maybe<Length>;
  readonly trailerType?: Maybe<KeyValue>;
  /** @deprecated Use trailer_width_v2 instead */
  readonly trailerWidth?: Maybe<UnitOfDistance>;
  readonly trailerWidthV2?: Maybe<Length>;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedBy?: Maybe<Employee>;
  readonly utcExpectedEmptyDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly utcPickupEtaDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly vendor: Carrier;
  readonly vendorType: RouteVendorTypeEnum;
};


export type RouteVendorTrailerHeightV2Args = {
  unit: UnitOfLengthEnum;
};


export type RouteVendorTrailerLengthV2Args = {
  unit: UnitOfLengthEnum;
};


export type RouteVendorTrailerWidthV2Args = {
  unit: UnitOfLengthEnum;
};

/** The connection type for RouteVendor. */
export type RouteVendorConnection = {
  readonly __typename?: 'RouteVendorConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<RouteVendorEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** Represents a route / vendor pairing with associated cost details */
export type RouteVendorCost = {
  readonly __typename?: 'RouteVendorCost';
  readonly accessorialChargeAmount?: Maybe<Scalars['Decimal']>;
  readonly advanceAmount?: Maybe<Scalars['Decimal']>;
  readonly baseChargeAmount?: Maybe<Scalars['Decimal']>;
  readonly Carrier?: Maybe<Carrier>;
  readonly CarrierV2?: Maybe<CarrierV2>;
  readonly costDetails: ReadonlyArray<CostDetail>;
  readonly currencyTotalCost?: Maybe<Scalars['Decimal']>;
  readonly currencyUnvoucheredAmount?: Maybe<Scalars['Decimal']>;
  readonly currencyVendorInvoiceTotal?: Maybe<Scalars['Decimal']>;
  readonly currencyVoucheredAmount?: Maybe<Scalars['Decimal']>;
  readonly disableFinancials: Scalars['Boolean'];
  readonly fuelAmount?: Maybe<Scalars['Decimal']>;
  readonly hasSeerSearchLimitReached?: Maybe<Scalars['Boolean']>;
  readonly invoicedAmount?: Maybe<Scalars['Decimal']>;
  readonly isVoucherRequirementBypassed: Scalars['Boolean'];
  readonly maxThreshold?: Maybe<Scalars['Decimal']>;
  readonly minThreshold?: Maybe<Scalars['Decimal']>;
  readonly otherChargeAmount?: Maybe<Scalars['Decimal']>;
  readonly paperWorkStatus?: Maybe<Scalars['String']>;
  readonly quickPay?: Maybe<Scalars['Boolean']>;
  readonly routeFinancialStatus?: Maybe<VendorFinancialStatusEnum>;
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly routeLifeCycle?: Maybe<RouteLifeCycleStatusEnum>;
  readonly totalAmount?: Maybe<Scalars['Decimal']>;
  readonly totalCost?: Maybe<Scalars['Decimal']>;
  readonly uninvoicedAmount?: Maybe<Scalars['Decimal']>;
  readonly unvoucheredAmount?: Maybe<Scalars['Decimal']>;
  readonly variance?: Maybe<Scalars['Decimal']>;
  readonly vendorId?: Maybe<Scalars['ID']>;
  readonly vendorInvoiceNumbers?: Maybe<Scalars['String']>;
  readonly vendorInvoiceTotal?: Maybe<Scalars['Decimal']>;
  readonly vendorIsAdvanceProvider: Scalars['Boolean'];
  readonly vendorRouteFinancialStatusReasons?: Maybe<ReadonlyArray<Maybe<ExceptionReason>>>;
  readonly voucheredAmount?: Maybe<Scalars['Decimal']>;
  readonly accountingIncidentsV2: ReadonlyArray<IncidentV2>;
};

/** A connection to a list of items. */
export type RouteVendorCostConnection = {
  readonly __typename?: 'RouteVendorCostConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<RouteVendorCostEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<RouteVendorCost>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RouteVendorCostEdge = {
  readonly __typename?: 'RouteVendorCostEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<RouteVendorCost>;
};

/** An edge in a connection. */
export type RouteVendorEdge = {
  readonly __typename?: 'RouteVendorEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: RouteVendor;
};

/** lachesis-api: Route Vendor Financial Status Object */
export type RouteVendorFinancialStatusV3 = {
  readonly __typename?: 'RouteVendorFinancialStatusV3';
  /** lachesis-api: Load Id associated with the route vendor record. */
  readonly load_id: Scalars['ID'];
  /** lachesis-api: Vendor Id associated with the route vendor record. */
  readonly vendor_id: Scalars['ID'];
  /** lachesis-api: Route Id associated with the route vendor record. */
  readonly route_id: Scalars['ID'];
  /** lachesis-api: Indicator to determine whether or not the vendor is in a bounced status or not. */
  readonly is_bounced?: Maybe<Scalars['Boolean']>;
  /** lachesis-api: Descriptor that contains the route/vendor type. */
  readonly route_vendor_type?: Maybe<Scalars['String']>;
  /** lachesis-api: The current financial status for vendor. */
  readonly vendor_financial_status: Scalars['String'];
  /** lachesis-api: Array of strings that describes any supported reasons for the current vendor financial status. */
  readonly vendor_financial_status_reasons: ReadonlyArray<Scalars['String']>;
};

export type RouteVendorInput = {
  readonly routeId: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
};

export type RouteVendorMaxCostValidation = {
  readonly __typename?: 'RouteVendorMaxCostValidation';
  readonly isValid?: Maybe<Scalars['Boolean']>;
  readonly message?: Maybe<Scalars['String']>;
};

export type RouteVendorReference = {
  readonly __typename?: 'RouteVendorReference';
  readonly id: Scalars['ID'];
  /** @deprecated Use `routeVendorReferenceTypeV2`. */
  readonly routeVendorReferenceType: RouteVendorReferenceTypeEnum;
  readonly routeVendorReferenceTypeV2: Scalars['String'];
  readonly value: Scalars['String'];
  readonly ediQualifier?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly updatedByUser?: Maybe<User>;
  readonly updatedByUserV2?: Maybe<UserV2>;
};

export enum RouteVendorReferenceTypeEnum {
  /** Carrier Pro # */
  CarrierProNumber = 'carrierProNumber',
  /** PU Ref # */
  PickupReferenceNumber = 'pickupReferenceNumber',
  /** Waybill # */
  WaybillNumber = 'waybillNumber',
  /** Rail Pricing Authority */
  RailPricingAuthority = 'railPricingAuthority',
  /** Rail Routing */
  RailRouting = 'railRouting',
  /** Other */
  Other = 'other'
}

export type RouteVendorRep = {
  readonly __typename?: 'RouteVendorRep';
  readonly createdByUser: User;
  readonly employee: Employee;
  readonly employeeGroup?: Maybe<KeyValue>;
  readonly employeeOffice?: Maybe<KeyValue>;
  readonly id: Scalars['ID'];
  readonly main: Scalars['Boolean'];
  readonly repType: RepTypeEnum;
  readonly routeVendor: RouteVendor;
  readonly updatedByUser: User;
};

export enum RouteVendorRepTypeEnum {
  /** Accounts Payable */
  AccountsPayable = 'accountsPayable',
  /** Carrier Manager */
  CarrierManager = 'carrierManager',
  /** Carrier Ops */
  CarrierOps = 'carrierOps',
  /** Carrier Sales */
  CarrierSales = 'carrierSales',
  /** Issue Resolution Rep */
  IssueResolutionRep = 'issueResolutionRep',
  /** Other */
  Other = 'other',
  /** Tracking Specialist */
  TrackingSpecialist = 'trackingSpecialist',
  /** Sponsored Prospect */
  SponsoredProspect = 'sponsoredProspect'
}

export type RouteVendorRepV2 = {
  readonly __typename?: 'RouteVendorRepV2';
  readonly id: Scalars['ID'];
  readonly repType: RouteVendorRepTypeEnum;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly routeVendor: RouteVendorV2;
  readonly createdByUser: User;
  readonly createdByUserV2: UserV2;
  readonly employee: Employee;
  readonly employeeV2: EmployeeV2;
  readonly main: Scalars['Boolean'];
  readonly updatedByUser: User;
  readonly updatedByUserV2: UserV2;
  readonly employeeGroup?: Maybe<KeyValue>;
  readonly employeeOffice?: Maybe<KeyValue>;
};

export type RouteVendorsFilter = {
  readonly excludeInactive?: InputMaybe<Scalars['Boolean']>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
};

/** Represents a route / vendor financial status */
export type RouteVendorStatus = {
  readonly __typename?: 'RouteVendorStatus';
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly vendorFinancialStatus?: Maybe<VendorFinancialStatusEnum>;
  readonly vendorId?: Maybe<Scalars['ID']>;
};

export type RouteVendorsV2Filter = {
  readonly excludeInactive?: InputMaybe<Scalars['Boolean']>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly routeVendorReferenceNumbers?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export enum RouteVendorTypeEnum {
  /** Primary Carrier */
  Carrier = 'carrier',
  /** Other */
  Other = 'other',
  /** Truck Ordered Not Used */
  Tonu = 'tonu'
}

export type RouteVendorV2 = {
  readonly __typename?: 'RouteVendorV2';
  readonly activeBounce?: Maybe<BounceV2>;
  readonly booked: Scalars['Boolean'];
  readonly bookedBy: Employee;
  readonly bookedByV2: EmployeeV2;
  readonly bookedByUser: User;
  readonly bookedByUserV2: UserV2;
  readonly bookedAt?: Maybe<Scalars['DateTime']>;
  readonly bookedWith?: Maybe<Contact>;
  readonly bookedWithV2?: Maybe<CarrierContact>;
  readonly bookingNotes?: Maybe<Scalars['String']>;
  readonly bookingSource?: Maybe<KeyValue>;
  readonly bounced: Scalars['Boolean'];
  readonly bounces?: Maybe<ReadonlyArray<BounceV2>>;
  readonly bracingType?: Maybe<BracingTypeEnum>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdBy: Employee;
  readonly createdByV2: EmployeeV2;
  readonly createdByUser: User;
  readonly createdByUserV2: UserV2;
  readonly distance?: Maybe<UnitOfDistance>;
  readonly expectedEmptyGeography?: Maybe<Geography>;
  readonly id: Scalars['ID'];
  readonly mainVendorRep?: Maybe<RouteVendorRepV2>;
  readonly numberOfBraces?: Maybe<Scalars['Int']>;
  readonly numberOfPallets?: Maybe<Scalars['Int']>;
  readonly numberOfTarps?: Maybe<Scalars['Int']>;
  readonly palletType?: Maybe<PalletTypeEnum>;
  readonly route?: Maybe<LoadRouteV2>;
  readonly routeVendorCost?: Maybe<RouteVendorCost>;
  readonly routeVendorReps?: Maybe<ReadonlyArray<RouteVendorRepV2>>;
  readonly routeVendorReferences?: Maybe<ReadonlyArray<RouteVendorReference>>;
  readonly tarpType?: Maybe<TarpTypeEnum>;
  readonly trailerHeight?: Maybe<UnitOfDistance>;
  readonly trailerHeightV2?: Maybe<Length>;
  readonly trailerLength?: Maybe<UnitOfDistance>;
  readonly trailerLengthV2?: Maybe<Length>;
  readonly trailerType?: Maybe<KeyValue>;
  readonly trailerWidth?: Maybe<UnitOfDistance>;
  readonly trailerWidthV2?: Maybe<Length>;
  readonly utcExpectedEmptyDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly utcPickupEtaDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedByUser?: Maybe<User>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly vendor: Carrier;
  readonly vendorV2: CarrierV2;
  readonly vendorType: RouteVendorTypeEnum;
};


export type RouteVendorV2TrailerHeightV2Args = {
  unit: UnitOfLengthEnum;
};


export type RouteVendorV2TrailerLengthV2Args = {
  unit: UnitOfLengthEnum;
};


export type RouteVendorV2TrailerWidthV2Args = {
  unit: UnitOfLengthEnum;
};

/** The connection type for RouteVendorV2. */
export type RouteVendorV2Connection = {
  readonly __typename?: 'RouteVendorV2Connection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<RouteVendorV2Edge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RouteVendorV2Edge = {
  readonly __typename?: 'RouteVendorV2Edge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: RouteVendorV2;
};

export type RoutingGuide = {
  readonly __typename?: 'RoutingGuide';
  readonly id: Scalars['ID'];
  readonly routingGuideTypeTerm?: Maybe<Scalars['String']>;
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use createdByV2 instead */
  readonly createdBy?: Maybe<User>;
  readonly createdByV2?: Maybe<UserV2>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use updatedByV2 instead */
  readonly updatedBy?: Maybe<User>;
  readonly updatedByV2?: Maybe<UserV2>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly sequence: ReadonlyArray<RoutingGuideSequenceItem>;
  /** @deprecated Use customerV2 instead */
  readonly customer?: Maybe<Customer>;
  readonly customerV2?: Maybe<CustomerV2>;
  readonly lane?: Maybe<LaneV2>;
  readonly duration?: Maybe<Scalars['Int']>;
  readonly code: Scalars['String'];
};

export type RoutingGuideEdge = {
  readonly __typename?: 'RoutingGuideEdge';
  readonly cursor: Scalars['String'];
  readonly node: RoutingGuide;
};

export type RoutingGuideInput = {
  readonly laneId: Scalars['ID'];
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly timezone?: InputMaybe<Scalars['String']>;
};

export type RoutingGuidesByLaneFilter = {
  readonly laneId: Scalars['ID'];
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly onlyActive?: InputMaybe<Scalars['Boolean']>;
};

export type RoutingGuidesConnection = {
  readonly __typename?: 'RoutingGuidesConnection';
  readonly edges: ReadonlyArray<Maybe<RoutingGuideEdge>>;
  readonly totalCount: Scalars['Int'];
  readonly pageInfo: PageInfo;
};

export type RoutingGuideSequenceItem = {
  readonly __typename?: 'RoutingGuideSequenceItem';
  readonly id: Scalars['ID'];
  /** @deprecated Use carrierV2 instead */
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly ordinal?: Maybe<Scalars['Int']>;
  readonly scac?: Maybe<Scalars['String']>;
  readonly tenderOptions?: Maybe<TenderOptions>;
  readonly activeCostQuote?: Maybe<ActiveCostQuote>;
};

export type RowErrorInput = {
  readonly recordNumber?: InputMaybe<Scalars['Int']>;
  readonly error?: InputMaybe<Scalars['String']>;
};

export type Rule = {
  readonly __typename?: 'Rule';
  readonly name?: Maybe<Scalars['String']>;
  readonly schemaId?: Maybe<Scalars['String']>;
  readonly conditions: ConditionProperties;
};

export type RuleAction = {
  readonly __typename?: 'RuleAction';
  readonly type: Scalars['String'];
  /** additionalData is interpreted as a JSON string and stored as the parsed object */
  readonly additionalData?: Maybe<Scalars['String']>;
};

export type RuleActionInput = {
  readonly type: Scalars['String'];
  /** additionalData is interpreted as a JSON string and stored as the parsed object */
  readonly additionalData?: InputMaybe<Scalars['String']>;
};

export type RuleInput = {
  readonly name?: InputMaybe<Scalars['String']>;
  readonly schemaId?: InputMaybe<Scalars['String']>;
  readonly conditions: ConditionPropertiesInput;
};

export type RuleSet = {
  readonly __typename?: 'RuleSet';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly type: RuleSetType;
  readonly isActive: Scalars['Boolean'];
  readonly references?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly schemas?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly rules?: Maybe<ReadonlyArray<Maybe<RuleSetItem>>>;
};

/** RuleSet Evaluation */
export type RuleSetEvaluationRequestInput = {
  readonly eventName: Scalars['String'];
  readonly facts: ReadonlyArray<InputMaybe<Scalars['JSONObject']>>;
  readonly references?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly returnEvaluations?: InputMaybe<Scalars['Boolean']>;
};

export type RuleSetEvaluationResponseBody = {
  readonly __typename?: 'RuleSetEvaluationResponseBody';
  readonly passed: Scalars['Boolean'];
  readonly matched?: Maybe<ReadonlyArray<Maybe<Scalars['JSONObject']>>>;
  readonly unmatched?: Maybe<ReadonlyArray<Maybe<Scalars['JSONObject']>>>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly evaluations?: Maybe<ReadonlyArray<Maybe<Scalars['JSONObject']>>>;
};

export type RuleSetEvaluationResult = {
  readonly __typename?: 'RuleSetEvaluationResult';
  readonly evaluation?: Maybe<ReadonlyArray<RuleSetEvaluationResponseBody>>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type RuleSetInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly description?: InputMaybe<Scalars['String']>;
  readonly type: RuleSetType;
  readonly isActive: Scalars['Boolean'];
  readonly references?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly schemas?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly rules?: InputMaybe<ReadonlyArray<InputMaybe<RuleSetItemInput>>>;
};

export type RuleSetItem = {
  readonly __typename?: 'RuleSetItem';
  readonly priority?: Maybe<Scalars['Int']>;
  readonly rule?: Maybe<Rule>;
  readonly action?: Maybe<RuleAction>;
};

export type RuleSetItemInput = {
  readonly priority?: InputMaybe<Scalars['Int']>;
  readonly rule?: InputMaybe<RuleInput>;
  readonly action?: InputMaybe<RuleActionInput>;
};

export enum RuleSetType {
  /** Conditions from each rule are run, then Actions are applied, then Action Results are sent in as Facts to the next Rule conditions and so on. Rules are run in order of "priority" from largest to smallest number value. */
  Pipeline = 'PIPELINE',
  /** Condition from each rule are run and the first rule that passes return the RuleAction. Rules are run in order of "priority" from largest to smallest number value. */
  FirstMatch = 'FIRST_MATCH'
}

export enum SafetyRatingTypeEnum {
  /** None */
  None = 'none',
  /** Satisfactory */
  Satisfactory = 'satisfactory',
  /** Conditional */
  Conditional = 'conditional',
  /** Unsatisfactory */
  Unsatisfactory = 'unsatisfactory',
  /** Insufficient Data */
  InsufficientData = 'insufficientData',
  /** NA */
  Na = 'NA'
}

export type SaveAvailableRouteInput = {
  readonly id: Scalars['ID'];
  readonly routeType?: InputMaybe<RouteType>;
  readonly routeTypeId?: InputMaybe<Scalars['String']>;
  readonly routeNumber: Scalars['String'];
  readonly loadId: Scalars['String'];
  readonly loadNumber: Scalars['String'];
  readonly capacityNeed?: InputMaybe<ReadonlyArray<CapacityNeed>>;
  readonly hasPreviousRoute?: InputMaybe<Scalars['Boolean']>;
  readonly customers?: InputMaybe<ReadonlyArray<AvailableRouteCustomerInput>>;
  readonly customerReps?: InputMaybe<ReadonlyArray<AvailableRouteCustomerRepInput>>;
  readonly pickup?: InputMaybe<AvailableRouteStopInput>;
  readonly delivery?: InputMaybe<AvailableRouteStopInput>;
  readonly routeLength?: InputMaybe<UnitOfLengthInput>;
  readonly routeActivationStatus: Scalars['String'];
  readonly routeLifecycleStatus: Scalars['String'];
  readonly transportModeId?: InputMaybe<Scalars['String']>;
  readonly trailerLength?: InputMaybe<AvailableRouteLengthInput>;
  readonly trailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trailerSize?: InputMaybe<DimensionsInput>;
  readonly stopCount: Scalars['Int'];
  readonly createdAtDate?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtDate?: InputMaybe<Scalars['DateTime']>;
  readonly outboundRegionUrn?: InputMaybe<Scalars['String']>;
  readonly inboundRegionUrn?: InputMaybe<Scalars['String']>;
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly requirements?: InputMaybe<ReadonlyArray<RouteRequirementInput>>;
  readonly expectedMaxWeight?: InputMaybe<UnitOfMassInput>;
  readonly commodityMinTemperature?: InputMaybe<TemperatureV2Input>;
  readonly hotRoute?: InputMaybe<Scalars['Boolean']>;
  /** Explicit max cost (not federated) for External routes */
  readonly maxCostValue?: InputMaybe<Scalars['Float']>;
  readonly maxCostCurrency?: InputMaybe<CurrencyInput>;
  readonly loadRouteSequence?: InputMaybe<FractionInput>;
  readonly externalSourceUrl?: InputMaybe<Scalars['String']>;
  readonly allStopsHaveRequestedTime?: InputMaybe<Scalars['Boolean']>;
};

export type SaveAvailableTruckInput = {
  readonly truckPostingId: Scalars['ID'];
  readonly truckLifecycleStatus: Scalars['String'];
  readonly carrierId: Scalars['String'];
  readonly carrierName?: InputMaybe<Scalars['String']>;
  readonly originCity: Scalars['String'];
  readonly originState: Scalars['String'];
  readonly originCountry?: InputMaybe<Scalars['String']>;
  readonly originGeoPoint: GeoPointInput;
  readonly readyByDay?: InputMaybe<Scalars['String']>;
  readonly readyByDate: Scalars['DateTime'];
  readonly readyByTimeZone?: InputMaybe<Scalars['String']>;
  readonly equipmentType: Scalars['String'];
  readonly trailerLength?: InputMaybe<UnitOfLengthInput>;
  readonly ddh?: InputMaybe<UnitOfLengthInput>;
  readonly odh?: InputMaybe<UnitOfLengthInput>;
  readonly destinationCountries?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationAdminAreas?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationCity?: InputMaybe<Scalars['String']>;
  readonly destinationState?: InputMaybe<Scalars['String']>;
  readonly destinationGeoPoint?: InputMaybe<GeoPointInput>;
  readonly loadByDay?: InputMaybe<Scalars['String']>;
  readonly loadByDate?: InputMaybe<Scalars['DateTime']>;
  readonly loadByTimeZone?: InputMaybe<Scalars['String']>;
  readonly finalByDay?: InputMaybe<Scalars['String']>;
  readonly finalByDate?: InputMaybe<Scalars['DateTime']>;
  readonly finalByTimeZone?: InputMaybe<Scalars['String']>;
  readonly previousRouteNumber?: InputMaybe<Scalars['String']>;
  readonly previousRouteCode?: InputMaybe<Scalars['String']>;
  readonly enteredByUserName?: InputMaybe<Scalars['String']>;
  readonly employeeId?: InputMaybe<Scalars['String']>;
  readonly ownerId?: InputMaybe<Scalars['String']>;
  readonly ownerName?: InputMaybe<Scalars['String']>;
  readonly carrierStatus?: InputMaybe<Scalars['String']>;
  readonly mainCarrierRepUserId?: InputMaybe<Scalars['String']>;
  readonly mainCarrierRepName?: InputMaybe<Scalars['String']>;
  readonly carrierServices?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierCargoInsuranceLimit?: InputMaybe<CurrencyInput>;
  readonly sourceType?: InputMaybe<Scalars['String']>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly createdByUserId?: InputMaybe<Scalars['String']>;
  readonly updatedByUserId?: InputMaybe<Scalars['String']>;
  readonly createdByEmployeeId?: InputMaybe<Scalars['String']>;
  readonly updatedByEmployeeId?: InputMaybe<Scalars['String']>;
  readonly updatedAtDate?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtDate?: InputMaybe<Scalars['DateTime']>;
  readonly mainCarrierRep?: InputMaybe<Scalars['String']>;
};

export type SaveCustomerRatingSourceInput = {
  readonly customerId: Scalars['ID'];
  readonly ratingSourceId: Scalars['String'];
  readonly savedByUserId: Scalars['ID'];
};

export type SaveCustomerRatingSourcePayload = {
  readonly __typename?: 'SaveCustomerRatingSourcePayload';
  readonly customerId: Scalars['ID'];
  readonly ratingSourceId?: Maybe<Scalars['String']>;
};

export type SavedFilter = {
  readonly __typename?: 'SavedFilter';
  /** Unique ID generated by database */
  readonly _id: Scalars['ID'];
  /** User defined filter name */
  readonly filterName: Scalars['String'];
  /** Short description for filter */
  readonly description?: Maybe<Scalars['String']>;
  /** JSON value of table filters */
  readonly filters: Scalars['JSON'];
  /** Represents default filter status */
  readonly isDefault: Scalars['Boolean'];
  readonly managedBy?: Maybe<SavedFilterGroupManagedBy>;
  /** Filter creation DateTime */
  readonly createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Filter creation DateTime */
  readonly lastModified?: Maybe<Scalars['ISO8601DateTime']>;
  /** Email address of filter creator */
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly status?: Maybe<SavedFilterGroupStatus>;
};

export type SavedFilterEdge = {
  readonly __typename?: 'SavedFilterEdge';
  readonly cursor: Scalars['String'];
  readonly node: SavedFilter;
};

export type SavedFilterGroupInputType = {
  /** Unique ID generated by database */
  readonly _id?: InputMaybe<Scalars['ID']>;
  /** Table ID from frontend */
  readonly objectId: Scalars['ID'];
  /** User defined filter name */
  readonly filterName?: InputMaybe<Scalars['String']>;
  /** Represents default filter status */
  readonly isDefault?: InputMaybe<Scalars['Boolean']>;
  readonly status?: InputMaybe<SavedFilterGroupStatus>;
};

/** Filter group managed by */
export enum SavedFilterGroupManagedBy {
  User = 'USER'
}

export type SavedFilterGroupSortBy = {
  readonly createdAt?: InputMaybe<SavedFilterSortDirection>;
  readonly lastModified?: InputMaybe<SavedFilterSortDirection>;
};

/** Filter status for saved filter */
export enum SavedFilterGroupStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type SavedFilterInputType = {
  /** Unique ID generated by database */
  readonly _id?: InputMaybe<Scalars['ID']>;
  /** Table ID from frontend */
  readonly objectId: Scalars['ID'];
  /** User defined filter name */
  readonly filterName: Scalars['String'];
  /** Represents default filter status */
  readonly isDefault: Scalars['Boolean'];
  /** JSON value of table filters */
  readonly filters: Scalars['JSON'];
  readonly managedBy?: InputMaybe<SavedFilterGroupManagedBy>;
  readonly status?: InputMaybe<SavedFilterGroupStatus>;
};

export type SavedFilterPaginationInput = {
  readonly before?: InputMaybe<Scalars['Int']>;
  readonly after?: InputMaybe<Scalars['Int']>;
};

export type SavedFilterResult = {
  readonly __typename?: 'SavedFilterResult';
  readonly edges?: Maybe<ReadonlyArray<SavedFilterEdge>>;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

/** Sort order for seer */
export enum SavedFilterSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SaveExternalAvailableRouteInput = {
  readonly routeNumber: Scalars['String'];
  readonly capacityNeed?: InputMaybe<ReadonlyArray<CapacityNeed>>;
  readonly customers?: InputMaybe<ReadonlyArray<ExternalAvailableRouteCustomerInput>>;
  readonly customerReps?: InputMaybe<ReadonlyArray<ExternalAvailableRouteCustomerRepInput>>;
  readonly pickup: ExternalAvailableRouteStopInput;
  readonly delivery: ExternalAvailableRouteStopInput;
  readonly routeLength?: InputMaybe<UnitOfLengthInput>;
  readonly minimumTrailerLength?: InputMaybe<AvailableRouteLengthInput>;
  readonly trailerTypes: ReadonlyArray<Scalars['String']>;
  /** default = 2 */
  readonly stopCount?: InputMaybe<Scalars['Int']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly hotRoute?: InputMaybe<Scalars['Boolean']>;
  /** Explicit max cost (not federated) for External routes */
  readonly maxCostValue?: InputMaybe<CurrencyInput>;
  readonly externalSourceUrl?: InputMaybe<Scalars['String']>;
  readonly requirementDdtOptionNames?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly requirements?: InputMaybe<ReadonlyArray<RouteRequirementInput>>;
};

export type SaveRoutingGuideInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly laneId: Scalars['ID'];
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly routingGuideTypeTerm: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly duration?: InputMaybe<Scalars['Int']>;
  readonly sequenceItems: ReadonlyArray<InputMaybe<SaveRoutingGuideSequenceItemInput>>;
  readonly userId: Scalars['ID'];
};

export type SaveRoutingGuideSequenceItemInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly carrierId: Scalars['ID'];
  readonly scac: Scalars['String'];
  readonly tenderOptions?: InputMaybe<TenderOptionsInput>;
};

export type SaveSpotQuoteInput = {
  readonly customerId: Scalars['ID'];
  /** The Minion transportMode's OptionId */
  readonly transportMode: Scalars['String'];
  /** The Minion loadSize's OptionId */
  readonly loadSize: Scalars['String'];
  readonly stops: ReadonlyArray<SpotQuoteStopInput>;
  readonly loadCount: Scalars['Int'];
  /** The Minion trailerType's OptionIds */
  readonly equipments: ReadonlyArray<Scalars['String']>;
  /** Length in feet */
  readonly length?: InputMaybe<Scalars['Int']>;
  /** Width in inches */
  readonly width?: InputMaybe<Scalars['Int']>;
  /** Height in inches */
  readonly height?: InputMaybe<Scalars['Int']>;
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate: Scalars['Date'];
  readonly shipStartDate: Scalars['Date'];
  readonly shipEndDate: Scalars['Date'];
  readonly desiredDeliveryDate?: InputMaybe<Scalars['Date']>;
  /** The Minion division's OptionId */
  readonly division?: InputMaybe<Scalars['String']>;
  /** The Minion project's OptionId */
  readonly project?: InputMaybe<Scalars['String']>;
  /** The Minion businessUnit's OptionId */
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  /** The Minion serviceLevel's OptionId */
  readonly serviceLevel: Scalars['String'];
  readonly quotedBy: Scalars['ID'];
  /** The Minion tenantScac's OptionId */
  readonly scac?: InputMaybe<Scalars['String']>;
  readonly externalQuoteId?: InputMaybe<Scalars['String']>;
  readonly multiStop: Scalars['Boolean'];
  /** The Minion orderRequirement's OptionIds */
  readonly requirements: ReadonlyArray<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly rateLineItems: ReadonlyArray<SpotQuoteRateLineItemInput>;
  /** The user saving the spot quote */
  readonly savedBy: Scalars['ID'];
  /** The IANA timezone of the user. */
  readonly timezone: Scalars['String'];
};

export type SaveSpotQuotePayload = {
  readonly __typename?: 'SaveSpotQuotePayload';
  readonly data?: Maybe<SpotQuote>;
  readonly errors?: Maybe<ReadonlyArray<SpotQuoteError>>;
};

export type Schedule = {
  readonly __typename?: 'Schedule';
  readonly appointmentType: ScheduleAppointmentTypeEnum;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly dropType: ScheduleDropTypeEnum;
  /** @deprecated use trailerType */
  readonly equipmentType: KeyValue;
  /** @deprecated use trailerTypeId */
  readonly equipmentTypeId: Scalars['ID'];
  readonly friday: ScheduleEntry;
  readonly id: Scalars['ID'];
  readonly loadSize?: Maybe<KeyValue>;
  readonly loadSizeId?: Maybe<Scalars['ID']>;
  /** @deprecated use route_transport_mode */
  readonly modeType: ScheduleModeTypeEnum;
  readonly monday: ScheduleEntry;
  readonly routeTransportMode?: Maybe<KeyValue>;
  readonly routeTransportModeId?: Maybe<Scalars['ID']>;
  readonly saturday: ScheduleEntry;
  /** @deprecated use schedule_type_nullable */
  readonly scheduleType: ScheduleTypeEnum;
  readonly scheduleTypeNullable?: Maybe<Scalars['String']>;
  /** @deprecated Use serviceType instead */
  readonly service?: Maybe<KeyValue>;
  readonly serviceType?: Maybe<ServiceEnum>;
  readonly sunday: ScheduleEntry;
  readonly thursday: ScheduleEntry;
  /** @deprecated use trailer_type_nullable */
  readonly trailerType: KeyValue;
  /** @deprecated use trailer_type_id_nullable */
  readonly trailerTypeId: Scalars['ID'];
  readonly trailerTypeIdNullable?: Maybe<Scalars['ID']>;
  readonly trailerTypeNullable?: Maybe<KeyValue>;
  readonly tuesday: ScheduleEntry;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
  readonly wednesday: ScheduleEntry;
  readonly workType: ScheduleWorkTypeEnum;
};

export enum ScheduleAppointmentTypeEnum {
  Open = 'open',
  Appt = 'appt',
  Notice = 'notice'
}

export enum ScheduleDropTypeEnum {
  BobtailHookEmpty = 'bobtailHookEmpty',
  BobtailHookLoaded = 'bobtailHookLoaded',
  DropAvail = 'dropAvail',
  DropEmptyBobtail = 'dropEmptyBobtail',
  DropEmptyHookLoaded = 'dropEmptyHookLoaded',
  DropEmptyWait = 'dropEmptyWait',
  DropLoadedBobtail = 'dropLoadedBobtail',
  DropLoadedHookEmpty = 'dropLoadedHookEmpty',
  DropLoadedWait = 'dropLoadedWait',
  DropOnly = 'dropOnly',
  Live = 'live'
}

export type ScheduleEntry = {
  readonly __typename?: 'ScheduleEntry';
  readonly closed: Scalars['Boolean'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUser?: Maybe<User>;
  readonly hours: ReadonlyArray<ScheduleRange>;
  readonly id: Scalars['ID'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUser?: Maybe<User>;
};

export type ScheduleEntryInput = {
  readonly closed: Scalars['Boolean'];
  readonly hours: ReadonlyArray<ScheduleRangeInput>;
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type ScheduleEntryInputV2 = {
  readonly closed: Scalars['Boolean'];
  readonly hours: ReadonlyArray<ScheduleRangeInputV2>;
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type ScheduleEntryV2 = {
  readonly __typename?: 'ScheduleEntryV2';
  readonly closed: Scalars['Boolean'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly hours: ReadonlyArray<ScheduleRangeV2>;
  readonly id: Scalars['ID'];
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
};

export type ScheduleInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly appointmentType: ScheduleAppointmentTypeEnum;
  readonly dropType: ScheduleDropTypeEnum;
  readonly equipmentTypeId?: InputMaybe<Scalars['ID']>;
  readonly friday: ScheduleEntryInput;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly loadSizeId?: InputMaybe<Scalars['ID']>;
  /** Deprecated, use route_transport_mode_id */
  readonly modeType: ScheduleModeTypeEnum;
  readonly monday: ScheduleEntryInput;
  readonly routeTransportModeId?: InputMaybe<Scalars['ID']>;
  readonly saturday: ScheduleEntryInput;
  /** Deprecated, use schedule_type_nullable */
  readonly scheduleType: ScheduleTypeEnum;
  readonly scheduleTypeNullable?: InputMaybe<Scalars['String']>;
  /** Deprecated, use serviceType */
  readonly service?: InputMaybe<ServiceEnum>;
  readonly serviceType?: InputMaybe<ServiceEnum>;
  readonly sunday: ScheduleEntryInput;
  readonly thursday: ScheduleEntryInput;
  /** Deprecated, use trailer_type_id_nullable */
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly trailerTypeIdNullable?: InputMaybe<Scalars['ID']>;
  readonly tuesday: ScheduleEntryInput;
  readonly wednesday: ScheduleEntryInput;
  readonly workType: ScheduleWorkTypeEnum;
};

export type ScheduleInputV2 = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly appointmentType: ScheduleAppointmentTypeEnum;
  readonly serviceType?: InputMaybe<Scalars['String']>;
  readonly dropType: ScheduleDropTypeEnum;
  readonly equipmentTypeId?: InputMaybe<Scalars['ID']>;
  readonly friday: ScheduleEntryInputV2;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly modeType: ScheduleModeTypeEnum;
  readonly monday: ScheduleEntryInputV2;
  readonly saturday: ScheduleEntryInputV2;
  readonly scheduleType: ScheduleTypeEnum;
  readonly sunday: ScheduleEntryInputV2;
  readonly thursday: ScheduleEntryInputV2;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly tuesday: ScheduleEntryInputV2;
  readonly wednesday: ScheduleEntryInputV2;
  readonly workType: ScheduleWorkTypeEnum;
  readonly loadSizeId?: InputMaybe<Scalars['ID']>;
  readonly routeTransportModeId?: InputMaybe<Scalars['ID']>;
  readonly scheduleTypeNullable?: InputMaybe<ScheduleTypeEnum>;
  readonly trailerTypeIdNullable?: InputMaybe<Scalars['ID']>;
  /**  The field `temperature` is deprecated. Use `temperatures` instead.  */
  readonly temperature?: InputMaybe<Scalars['String']>;
  readonly temperatures?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export enum ScheduleModeTypeEnum {
  AllModes = 'allModes',
  Imdl = 'imdl',
  Ltl = 'ltl',
  Tl = 'tl'
}

export type ScheduleRange = {
  readonly __typename?: 'ScheduleRange';
  readonly id: Scalars['ID'];
  readonly startOffsetMs: Scalars['Int'];
  readonly endOffsetMs: Scalars['Int'];
};

export type ScheduleRangeInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly startOffsetMs: Scalars['Int'];
  readonly endOffsetMs: Scalars['Int'];
};

export type ScheduleRangeInputV2 = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly startOffsetMs: Scalars['Int'];
  readonly endOffsetMs: Scalars['Int'];
};

export type ScheduleRangeV2 = {
  readonly __typename?: 'ScheduleRangeV2';
  readonly id: Scalars['ID'];
  readonly startOffsetMs: Scalars['Int'];
  readonly endOffsetMs: Scalars['Int'];
};

export type Schedules = {
  readonly __typename?: 'Schedules';
  readonly id: Scalars['ID'];
  readonly serviceProfileId: Scalars['Float'];
  readonly gcMonTime: Scalars['DateTime'];
  readonly gcTuesTime: Scalars['DateTime'];
  readonly gcWedTime: Scalars['DateTime'];
  readonly gcThursTime: Scalars['DateTime'];
  readonly gcFriTime: Scalars['DateTime'];
  readonly gcSatTime: Scalars['DateTime'];
  readonly gcSunTime: Scalars['DateTime'];
  readonly eaDayOfMon: Scalars['String'];
  readonly eaMonTime: Scalars['DateTime'];
  readonly monIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfTues: Scalars['String'];
  readonly eaTuesTime: Scalars['DateTime'];
  readonly tuesIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfWed: Scalars['String'];
  readonly eaWedTime: Scalars['DateTime'];
  readonly wedIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfThurs: Scalars['String'];
  readonly eaThursTime: Scalars['DateTime'];
  readonly thursIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfFri: Scalars['String'];
  readonly eaFriTime: Scalars['DateTime'];
  readonly friIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfSat: Scalars['String'];
  readonly eaSatTime: Scalars['DateTime'];
  readonly satIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfSun: Scalars['String'];
  readonly eaSunTime: Scalars['DateTime'];
  readonly sunIsClosed?: Maybe<Scalars['Boolean']>;
  readonly serviceId?: Maybe<Scalars['String']>;
};

export type SchedulesObject = {
  readonly __typename?: 'SchedulesObject';
  readonly id: Scalars['String'];
  readonly serviceProfileId: Scalars['Float'];
  readonly serviceId?: Maybe<Scalars['String']>;
  readonly gcMonTime: Scalars['DateTime'];
  readonly gcTuesTime: Scalars['DateTime'];
  readonly gcWedTime: Scalars['DateTime'];
  readonly gcThursTime: Scalars['DateTime'];
  readonly gcFriTime: Scalars['DateTime'];
  readonly gcSatTime: Scalars['DateTime'];
  readonly gcSunTime: Scalars['DateTime'];
  readonly eaDayOfMon: Scalars['String'];
  readonly eaMonTime: Scalars['DateTime'];
  readonly monIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfTues: Scalars['String'];
  readonly eaTuesTime: Scalars['DateTime'];
  readonly tuesIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfWed: Scalars['String'];
  readonly eaWedTime: Scalars['DateTime'];
  readonly wedIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfThurs: Scalars['String'];
  readonly eaThursTime: Scalars['DateTime'];
  readonly thursIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfFri: Scalars['String'];
  readonly eaFriTime: Scalars['DateTime'];
  readonly friIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfSat: Scalars['String'];
  readonly eaSatTime: Scalars['DateTime'];
  readonly satIsClosed?: Maybe<Scalars['Boolean']>;
  readonly eaDayOfSun: Scalars['String'];
  readonly eaSunTime: Scalars['DateTime'];
  readonly sunIsClosed?: Maybe<Scalars['Boolean']>;
};

export enum ScheduleTypeEnum {
  Both = 'both',
  In = 'in',
  Out = 'out'
}

export type ScheduleV2 = {
  readonly __typename?: 'ScheduleV2';
  readonly appointmentType: ScheduleAppointmentTypeEnum;
  readonly serviceType?: Maybe<Scalars['String']>;
  readonly facilityId: Scalars['ID'];
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly createdByUser?: Maybe<UserV2>;
  readonly dropType: ScheduleDropTypeEnum;
  readonly friday: ScheduleEntryV2;
  readonly id: Scalars['ID'];
  readonly modeType: ScheduleModeTypeEnum;
  readonly monday: ScheduleEntryV2;
  readonly saturday: ScheduleEntryV2;
  readonly scheduleType: ScheduleTypeEnum;
  readonly sunday: ScheduleEntryV2;
  readonly thursday: ScheduleEntryV2;
  readonly trailerType: KeyValue;
  readonly trailerTypeId: Scalars['ID'];
  readonly tuesday: ScheduleEntryV2;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly updatedByUser?: Maybe<UserV2>;
  readonly wednesday: ScheduleEntryV2;
  readonly workType: ScheduleWorkTypeEnum;
  readonly loadSize?: Maybe<KeyValue>;
  readonly loadSizeId?: Maybe<Scalars['ID']>;
  readonly equipmentType: KeyValue;
  readonly equipmentTypeId: Scalars['ID'];
  readonly routeTransportModeId?: Maybe<Scalars['ID']>;
  readonly routeTransportMode?: Maybe<KeyValue>;
  readonly temperatureId?: Maybe<Scalars['String']>;
  /** @deprecated Use `temperatures` instead. */
  readonly temperature?: Maybe<KeyValue>;
  readonly temperatures?: Maybe<ReadonlyArray<KeyValue>>;
  readonly scheduleTypeNullable?: Maybe<ScheduleTypeEnum>;
  readonly trailerTypeNullable?: Maybe<KeyValue>;
  readonly trailerTypeIdNullable?: Maybe<Scalars['ID']>;
};

export enum ScheduleWorkTypeEnum {
  DriverAssist = 'driverAssist',
  Lumper = 'lumper',
  NoTouch = 'noTouch'
}

export type SchedulingLog = {
  readonly __typename?: 'SchedulingLog';
  readonly changedBy?: Maybe<Employee>;
  readonly changedByV2?: Maybe<EmployeeV2>;
  readonly confirmed: Scalars['Boolean'];
  readonly requestedBy?: Maybe<Employee>;
  readonly requestedByV2?: Maybe<EmployeeV2>;
  readonly requestedWith?: Maybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly autoSchedule?: Maybe<Scalars['Boolean']>;
  readonly scheduleReason?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly type: Scalars['String'];
  readonly category: Scalars['String'];
  readonly routeStopId: Scalars['ID'];
  readonly appointmentId?: Maybe<Scalars['ID']>;
  readonly endDate?: Maybe<DatetimeWithTimezone>;
  readonly startDate?: Maybe<DatetimeWithTimezone>;
  readonly timeRange?: Maybe<ScheduleRange>;
  readonly reason?: Maybe<Scalars['String']>;
};

export type SearchAuditHistoryInput = {
  readonly recordId: Scalars['String'];
};

export type SearchAuditHistoryResult = {
  readonly __typename?: 'SearchAuditHistoryResult';
  readonly auditHistory?: Maybe<ReadonlyArray<Maybe<AuditHistory>>>;
};

export type SearchDriverInput = {
  readonly name?: InputMaybe<Scalars['String']>;
  readonly countryCd?: InputMaybe<Scalars['String']>;
  readonly primaryPhoneNumber?: InputMaybe<Scalars['String']>;
  readonly driverIdentityId?: InputMaybe<Scalars['String']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly professionType?: InputMaybe<Scalars['String']>;
  readonly professionalTypeTerm?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly region?: InputMaybe<Scalars['String']>;
  readonly radius?: InputMaybe<Scalars['String']>;
  readonly distanceType?: InputMaybe<Scalars['String']>;
  readonly loadOrRouteNumber?: InputMaybe<Scalars['String']>;
  readonly serviceStatus?: InputMaybe<Scalars['String']>;
  readonly statusTerm?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly segment?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly fleet?: InputMaybe<Scalars['String']>;
};

export type SearchFacilityAddressInput = {
  readonly addressLine1: Scalars['String'];
  readonly locality: Scalars['String'];
  readonly administrativeArea: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly country?: InputMaybe<Scalars['String']>;
  readonly countryAlpha2?: InputMaybe<Scalars['String']>;
  readonly countryAlpha3?: InputMaybe<Scalars['String']>;
  readonly main?: InputMaybe<Scalars['Boolean']>;
};

export type SearchFacilityResult = {
  readonly __typename?: 'SearchFacilityResult';
  readonly facilities?: Maybe<ReadonlyArray<Maybe<Facility>>>;
  readonly address?: Maybe<ResultAddress>;
  readonly resultCodes?: Maybe<ReadonlyArray<SearchFacilityResultCode>>;
};

export enum SearchFacilityResultCode {
  /** The recevied address was recognized and cleansed and the ResultAddress address field is the result of cleansing */
  CleansedAddress = 'CLEANSED_ADDRESS',
  /** The recevied address was not recognized but may have been formatted and ResultAddress address field is the formatted address */
  FormattedAddress = 'FORMATTED_ADDRESS',
  /** The recevied address was attempted to be cleansed but it was not recognized or cleansed, the ResultAddress field will be the request address */
  CleanseError = 'CLEANSE_ERROR',
  /** A facility with the address field was found, the facility address is cleansed */
  MatchedFacilityCleansed = 'MATCHED_FACILITY_CLEANSED',
  /** A facility with the address field was found, the facility address is not cleansed */
  MatchedFacilityUncleansed = 'MATCHED_FACILITY_UNCLEANSED',
  /** No facility was found with the address field */
  NoMatchedFacility = 'NO_MATCHED_FACILITY'
}

export type SearchHaykEventsInput = {
  readonly name?: InputMaybe<Scalars['String']>;
  readonly eventId?: InputMaybe<Scalars['String']>;
  readonly eventType?: InputMaybe<Scalars['String']>;
  readonly serviceSource?: InputMaybe<Scalars['String']>;
};

export type SearchHaykEventsResult = {
  readonly __typename?: 'SearchHaykEventsResult';
  readonly events?: Maybe<ReadonlyArray<Maybe<HaykEvent>>>;
};

export type SearchHaykRedirectsInput = {
  readonly division?: InputMaybe<Scalars['String']>;
  readonly redirectId?: InputMaybe<Scalars['String']>;
};

export type SearchHaykRedirectsResult = {
  readonly __typename?: 'SearchHaykRedirectsResult';
  readonly redirects?: Maybe<ReadonlyArray<Maybe<HaykRedirectResult>>>;
};

export type SearchRuleSetsInput = {
  readonly eventName: Scalars['String'];
  readonly references?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type SearchRuleSetsResult = {
  readonly __typename?: 'SearchRuleSetsResult';
  readonly eventName?: Maybe<Scalars['String']>;
  readonly ruleSets?: Maybe<ReadonlyArray<Maybe<RuleSet>>>;
};

export type SearchTemplatesInput = {
  readonly eventName: Scalars['String'];
};

export type SearchTemplatesResult = {
  readonly __typename?: 'SearchTemplatesResult';
  readonly eventName?: Maybe<Scalars['String']>;
  readonly templates?: Maybe<ReadonlyArray<Maybe<ContentTemplate>>>;
};

export type SeerCarrierRoute = {
  readonly __typename?: 'SeerCarrierRoute';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly orderNumber?: Maybe<Scalars['String']>;
  readonly oas?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly stops?: Maybe<Scalars['Int']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerId?: Maybe<Scalars['String']>;
  readonly distance?: Maybe<Scalars['Float']>;
  readonly distanceUnit?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly mainCustomerRep?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly shipper?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly shipperId?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly puApptStatus?: Maybe<Scalars['String']>;
  readonly consignee?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly consigneeId?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly delApptStatus?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly maxCost?: Maybe<Scalars['Float']>;
  readonly overMax?: Maybe<Scalars['Float']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverNumber?: Maybe<Scalars['String']>;
};

export type SeerCarrierRouteEdge = {
  readonly __typename?: 'SeerCarrierRouteEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCarrierRoute;
};

export type SeerCarrierRouteFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oas?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly distance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly distanceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCustomerRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly shipper?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly shipperId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly consignee?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly consigneeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly maxCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly overMax?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly trailerNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCarrierRouteOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly orderNumber?: InputMaybe<SeerOrderBy>;
  readonly oas?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly size?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly distance?: InputMaybe<SeerOrderBy>;
  readonly distanceUnit?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly mainCustomerRep?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly shipper?: InputMaybe<SeerOrderBy>;
  readonly shipperId?: InputMaybe<SeerOrderBy>;
  readonly originCity?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly originState?: InputMaybe<SeerOrderBy>;
  readonly puApptStatus?: InputMaybe<SeerOrderBy>;
  readonly consignee?: InputMaybe<SeerOrderBy>;
  readonly consigneeId?: InputMaybe<SeerOrderBy>;
  readonly destinationCity?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly destinationState?: InputMaybe<SeerOrderBy>;
  readonly delApptStatus?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly maxCost?: InputMaybe<SeerOrderBy>;
  readonly overMax?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly totalCost?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber?: InputMaybe<SeerOrderBy>;
  readonly driverName?: InputMaybe<SeerOrderBy>;
  readonly driverNumber?: InputMaybe<SeerOrderBy>;
};

export type SeerCarrierRouteResult = {
  readonly __typename?: 'SeerCarrierRouteResult';
  readonly edges: ReadonlyArray<SeerCarrierRouteEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCarrierShipment = {
  readonly __typename?: 'SeerCarrierShipment';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly ediMessageType?: Maybe<Scalars['String']>;
  readonly direction?: Maybe<Scalars['String']>;
  readonly shipmentId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly shipmentSentDatetime: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly waybillNumber?: Maybe<Scalars['String']>;
  readonly waybillStatus?: Maybe<Scalars['String']>;
};

export type SeerCarrierShipmentEdge = {
  readonly __typename?: 'SeerCarrierShipmentEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCarrierShipment;
};

export type SeerCarrierShipmentFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ediMessageType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly direction?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly shipmentId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly shipmentSentDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly waybillNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly waybillStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCarrierShipmentOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly ediMessageType?: InputMaybe<SeerOrderBy>;
  readonly direction?: InputMaybe<SeerOrderBy>;
  readonly shipmentId?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly shipmentSentDatetime?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly createdAt?: InputMaybe<SeerOrderBy>;
  readonly waybillNumber?: InputMaybe<SeerOrderBy>;
  readonly waybillStatus?: InputMaybe<SeerOrderBy>;
};

export type SeerCarrierShipmentResult = {
  readonly __typename?: 'SeerCarrierShipmentResult';
  readonly edges: ReadonlyArray<SeerCarrierShipmentEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCommandLog = {
  readonly __typename?: 'SeerCommandLog';
  readonly id: Scalars['Int'];
  readonly databaseName?: Maybe<Scalars['String']>;
  readonly schemaName?: Maybe<Scalars['String']>;
  readonly objectName?: Maybe<Scalars['String']>;
  readonly indexName?: Maybe<Scalars['String']>;
  readonly statisticsName?: Maybe<Scalars['String']>;
  readonly partitionNumber?: Maybe<Scalars['Int']>;
  readonly command: Scalars['String'];
  readonly commandType: Scalars['String'];
  readonly startTime: Scalars['DateTime'];
  readonly endTime?: Maybe<Scalars['DateTime']>;
  readonly errorNumber?: Maybe<Scalars['Int']>;
  readonly errorMessage?: Maybe<Scalars['String']>;
};

export type SeerCommandLogEdge = {
  readonly __typename?: 'SeerCommandLogEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCommandLog;
};

export type SeerCommandLogFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly databaseName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly schemaName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly objectName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly indexName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly statisticsName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly partitionNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly command?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly commandType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly startTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly endTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly errorNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly errorMessage?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCommandLogOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly databaseName?: InputMaybe<SeerOrderBy>;
  readonly schemaName?: InputMaybe<SeerOrderBy>;
  readonly objectName?: InputMaybe<SeerOrderBy>;
  readonly indexName?: InputMaybe<SeerOrderBy>;
  readonly statisticsName?: InputMaybe<SeerOrderBy>;
  readonly partitionNumber?: InputMaybe<SeerOrderBy>;
  readonly command?: InputMaybe<SeerOrderBy>;
  readonly commandType?: InputMaybe<SeerOrderBy>;
  readonly startTime?: InputMaybe<SeerOrderBy>;
  readonly endTime?: InputMaybe<SeerOrderBy>;
  readonly errorNumber?: InputMaybe<SeerOrderBy>;
  readonly errorMessage?: InputMaybe<SeerOrderBy>;
};

export type SeerCommandLogResult = {
  readonly __typename?: 'SeerCommandLogResult';
  readonly edges: ReadonlyArray<SeerCommandLogEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCurrentAndNextStop = {
  readonly __typename?: 'SeerCurrentAndNextStop';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly currentStopId?: Maybe<Scalars['String']>;
  readonly nextStopId?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByTxId: Scalars['String'];
  readonly updatedByTxId: Scalars['String'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly eid: Scalars['Int'];
};

export type SeerCurrentAndNextStopEdge = {
  readonly __typename?: 'SeerCurrentAndNextStopEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCurrentAndNextStop;
};

export type SeerCurrentAndNextStopFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly updatedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly createdByTxId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedByTxId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
};

export type SeerCurrentAndNextStopOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly currentStopId?: InputMaybe<SeerOrderBy>;
  readonly nextStopId?: InputMaybe<SeerOrderBy>;
  readonly createdAt?: InputMaybe<SeerOrderBy>;
  readonly updatedAt?: InputMaybe<SeerOrderBy>;
  readonly createdByTxId?: InputMaybe<SeerOrderBy>;
  readonly updatedByTxId?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly updatedBy?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
};

export type SeerCurrentAndNextStopResult = {
  readonly __typename?: 'SeerCurrentAndNextStopResult';
  readonly edges: ReadonlyArray<SeerCurrentAndNextStopEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCurrentStop = {
  readonly __typename?: 'SeerCurrentStop';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly stopId: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByTxId: Scalars['String'];
  readonly updatedByTxId: Scalars['String'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly eid: Scalars['Int'];
};

export type SeerCurrentStopEdge = {
  readonly __typename?: 'SeerCurrentStopEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCurrentStop;
};

export type SeerCurrentStopFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly stopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly updatedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly createdByTxId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedByTxId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
};

export type SeerCurrentStopOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly stopId?: InputMaybe<SeerOrderBy>;
  readonly createdAt?: InputMaybe<SeerOrderBy>;
  readonly updatedAt?: InputMaybe<SeerOrderBy>;
  readonly createdByTxId?: InputMaybe<SeerOrderBy>;
  readonly updatedByTxId?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly updatedBy?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
};

export type SeerCurrentStopResult = {
  readonly __typename?: 'SeerCurrentStopResult';
  readonly edges: ReadonlyArray<SeerCurrentStopEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCustomerGroup = {
  readonly __typename?: 'SeerCustomerGroup';
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId: Scalars['String'];
  /** Column is of type UUID */
  readonly customerId: Scalars['String'];
  readonly type?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly groupId?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
};

export type SeerCustomerGroupEdge = {
  readonly __typename?: 'SeerCustomerGroupEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCustomerGroup;
};

export type SeerCustomerGroupFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly type?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly groupId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCustomerGroupOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly type?: InputMaybe<SeerOrderBy>;
  readonly groupId?: InputMaybe<SeerOrderBy>;
  readonly name?: InputMaybe<SeerOrderBy>;
};

export type SeerCustomerGroupResult = {
  readonly __typename?: 'SeerCustomerGroupResult';
  readonly edges: ReadonlyArray<SeerCustomerGroupEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCustomerOrder = {
  readonly __typename?: 'SeerCustomerOrder';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly orderNumber?: Maybe<Scalars['String']>;
  readonly oas?: Maybe<Scalars['String']>;
  readonly ots?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerId?: Maybe<Scalars['String']>;
  readonly mainCustomerRep?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly shipper?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly shipperId?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly puApptStatus?: Maybe<Scalars['String']>;
  readonly distance?: Maybe<Scalars['Float']>;
  readonly distanceUnit?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly consignee?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly consigneeId?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly delApptStatus?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly maxCost?: Maybe<Scalars['Float']>;
  readonly overMax?: Maybe<Scalars['Float']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
  readonly loadMaxCost?: Maybe<Scalars['Float']>;
  readonly loadOverMax?: Maybe<Scalars['Float']>;
  readonly loadTotalRate?: Maybe<Scalars['Float']>;
  readonly loadTotalCost?: Maybe<Scalars['Float']>;
};

export type SeerCustomerOrderEdge = {
  readonly __typename?: 'SeerCustomerOrderEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCustomerOrder;
};

export type SeerCustomerOrderFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oas?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ots?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCustomerRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly shipper?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly shipperId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly distance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly distanceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly consignee?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly consigneeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly maxCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly overMax?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly loadMaxCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly loadOverMax?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly loadTotalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly loadTotalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
};

export type SeerCustomerOrderOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly orderNumber?: InputMaybe<SeerOrderBy>;
  readonly oas?: InputMaybe<SeerOrderBy>;
  readonly ots?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly size?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly mainCustomerRep?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly shipper?: InputMaybe<SeerOrderBy>;
  readonly shipperId?: InputMaybe<SeerOrderBy>;
  readonly originCity?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly originState?: InputMaybe<SeerOrderBy>;
  readonly puApptStatus?: InputMaybe<SeerOrderBy>;
  readonly distance?: InputMaybe<SeerOrderBy>;
  readonly distanceUnit?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly consignee?: InputMaybe<SeerOrderBy>;
  readonly consigneeId?: InputMaybe<SeerOrderBy>;
  readonly destinationCity?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly destinationState?: InputMaybe<SeerOrderBy>;
  readonly delApptStatus?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly maxCost?: InputMaybe<SeerOrderBy>;
  readonly overMax?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly totalCost?: InputMaybe<SeerOrderBy>;
  readonly loadMaxCost?: InputMaybe<SeerOrderBy>;
  readonly loadOverMax?: InputMaybe<SeerOrderBy>;
  readonly loadTotalRate?: InputMaybe<SeerOrderBy>;
  readonly loadTotalCost?: InputMaybe<SeerOrderBy>;
};

export type SeerCustomerOrderResult = {
  readonly __typename?: 'SeerCustomerOrderResult';
  readonly edges: ReadonlyArray<SeerCustomerOrderEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerCustomerRep = {
  readonly __typename?: 'SeerCustomerRep';
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId: Scalars['String'];
  /** Column is of type UUID */
  readonly customerId: Scalars['String'];
  readonly name?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly employeeId?: Maybe<Scalars['String']>;
};

export type SeerCustomerRepEdge = {
  readonly __typename?: 'SeerCustomerRepEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerCustomerRep;
};

export type SeerCustomerRepFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly type?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly employeeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerCustomerRepOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly name?: InputMaybe<SeerOrderBy>;
  readonly type?: InputMaybe<SeerOrderBy>;
  readonly employeeId?: InputMaybe<SeerOrderBy>;
};

export type SeerCustomerRepResult = {
  readonly __typename?: 'SeerCustomerRepResult';
  readonly edges: ReadonlyArray<SeerCustomerRepEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

/** Distance units for seer */
export enum SeerDistanceUnit {
  Miles = 'MILES',
  Feet = 'FEET',
  Meter = 'METER',
  Kilometer = 'KILOMETER'
}

export type SeerFacilityRoute = {
  readonly __typename?: 'SeerFacilityRoute';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly orderNumber?: Maybe<Scalars['String']>;
  readonly oas?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerId?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly distance?: Maybe<Scalars['Float']>;
  readonly distanceUnit?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly mainCustomerRep?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly shipper?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly shipperId?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly puApptStatus?: Maybe<Scalars['String']>;
  readonly consignee?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly consigneeId?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly delApptStatus?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
};

export type SeerFacilityRouteEdge = {
  readonly __typename?: 'SeerFacilityRouteEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerFacilityRoute;
};

export type SeerFacilityRouteFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oas?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly distance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly distanceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCustomerRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly shipper?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly shipperId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly consignee?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly consigneeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
};

export type SeerFacilityRouteOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly orderNumber?: InputMaybe<SeerOrderBy>;
  readonly oas?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly size?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly distance?: InputMaybe<SeerOrderBy>;
  readonly distanceUnit?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly mainCustomerRep?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly shipper?: InputMaybe<SeerOrderBy>;
  readonly shipperId?: InputMaybe<SeerOrderBy>;
  readonly originCity?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly originState?: InputMaybe<SeerOrderBy>;
  readonly puApptStatus?: InputMaybe<SeerOrderBy>;
  readonly consignee?: InputMaybe<SeerOrderBy>;
  readonly consigneeId?: InputMaybe<SeerOrderBy>;
  readonly destinationCity?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly destinationState?: InputMaybe<SeerOrderBy>;
  readonly delApptStatus?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly totalCost?: InputMaybe<SeerOrderBy>;
};

export type SeerFacilityRouteResult = {
  readonly __typename?: 'SeerFacilityRouteResult';
  readonly edges: ReadonlyArray<SeerFacilityRouteEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerFacilityTrackingBoard = {
  readonly __typename?: 'SeerFacilityTrackingBoard';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly rlc?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly oas?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly destination?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly nxtCallBkDtTm?: Maybe<Scalars['DateTime']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverNumber?: Maybe<Scalars['String']>;
  readonly connectivity?: Maybe<Scalars['String']>;
  readonly infoFrom?: Maybe<Scalars['String']>;
  readonly trackingStatus?: Maybe<Scalars['String']>;
  readonly lastTrackingNotes?: Maybe<Scalars['String']>;
  readonly createDate?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly latestElectronicLocationUpdateDatetime?: Maybe<Scalars['DateTime']>;
  readonly latestTrackingRequest?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly facilityId?: Maybe<Scalars['String']>;
  readonly autoEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly manualEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly emptyDatetime?: Maybe<Scalars['DateTime']>;
  readonly emptyDate?: Maybe<Scalars['DateTime']>;
  readonly driverElectronicTrackingTypeId?: Maybe<Scalars['String']>;
  readonly nextApptStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptStartTime?: Maybe<Scalars['Int']>;
  readonly nextApptEndTime?: Maybe<Scalars['Int']>;
  readonly nextApptStartTimeZone?: Maybe<Scalars['String']>;
  readonly nextApptEndTimeZone?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly originFacilityId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly destinationFacilityId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly bookedById?: Maybe<Scalars['String']>;
  readonly bookedByName?: Maybe<Scalars['String']>;
};

export type SeerFacilityTrackingBoardEdge = {
  readonly __typename?: 'SeerFacilityTrackingBoardEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerFacilityTrackingBoard;
};

export type SeerFacilityTrackingBoardFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oas?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destination?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nxtCallBkDtTm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly connectivity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly infoFrom?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trackingStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastTrackingNotes?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly latestTrackingRequest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly facilityId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly autoEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly manualEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly emptyDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly emptyDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly originFacilityId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly destinationFacilityId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly bookedById?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly bookedByName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerFacilityTrackingBoardOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly oas?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly destination?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly nxtCallBkDtTm?: InputMaybe<SeerOrderBy>;
  readonly driverName?: InputMaybe<SeerOrderBy>;
  readonly driverNumber?: InputMaybe<SeerOrderBy>;
  readonly connectivity?: InputMaybe<SeerOrderBy>;
  readonly infoFrom?: InputMaybe<SeerOrderBy>;
  readonly trackingStatus?: InputMaybe<SeerOrderBy>;
  readonly lastTrackingNotes?: InputMaybe<SeerOrderBy>;
  readonly createDate?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<SeerOrderBy>;
  readonly latestTrackingRequest?: InputMaybe<SeerOrderBy>;
  readonly facilityId?: InputMaybe<SeerOrderBy>;
  readonly autoEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly manualEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly emptyDatetime?: InputMaybe<SeerOrderBy>;
  readonly emptyDate?: InputMaybe<SeerOrderBy>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly routeType?: InputMaybe<SeerOrderBy>;
  readonly originFacilityId?: InputMaybe<SeerOrderBy>;
  readonly destinationFacilityId?: InputMaybe<SeerOrderBy>;
  readonly bookedById?: InputMaybe<SeerOrderBy>;
  readonly bookedByName?: InputMaybe<SeerOrderBy>;
};

export type SeerFacilityTrackingBoardResult = {
  readonly __typename?: 'SeerFacilityTrackingBoardResult';
  readonly edges: ReadonlyArray<SeerFacilityTrackingBoardEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerGenericBoolFilter = {
  readonly operator: SeerGenericStringOperator;
  /** required for all filter operators but NULL and NOT_NULL */
  readonly value?: InputMaybe<Scalars['Boolean']>;
  readonly orGroup?: InputMaybe<Scalars['String']>;
};

export type SeerGenericDateFilter = {
  readonly operator: SeerGenericFilterOperator;
  /** required for all filter operators but NULL and NOT_NULL */
  readonly value1?: InputMaybe<Scalars['DateTime']>;
  /** required only for filter operator BETWEEN as end value */
  readonly value2?: InputMaybe<Scalars['DateTime']>;
  readonly orGroup?: InputMaybe<Scalars['String']>;
};

/** Allows filtering by distance to geopoints. Sqlserver doesn't support using these filters in or clauses, so no orgroup can be defined. */
export type SeerGenericDistanceFilter = {
  readonly operator: SeerGenericFilterOperator;
  /** Latitude of reference point, required for all filter operators but NULL and NOT_NULL */
  readonly latitude?: InputMaybe<Scalars['Float']>;
  /** Longitude of reference point, required for all filter operators but NULL and NOT_NULL */
  readonly longitude?: InputMaybe<Scalars['Float']>;
  /** Unit for value1 and value2, miles is default. */
  readonly unit?: InputMaybe<SeerDistanceUnit>;
  /** required for all filter operators but NULL and NOT_NULL */
  readonly value1?: InputMaybe<Scalars['Float']>;
  /** required only for filter operator BETWEEN as end value */
  readonly value2?: InputMaybe<Scalars['Float']>;
};

/** generic filter operator for seer */
export enum SeerGenericFilterOperator {
  /** The target fields value must be greater than value1  */
  Greater = 'GREATER',
  /** The target fields value must be less than value1  */
  Less = 'LESS',
  /** The target fields value must be greater than or equal to value1  */
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  /** The target fields value must be less than or equal to value1  */
  LessOrEqual = 'LESS_OR_EQUAL',
  /** The target fields value must be equal to value1  */
  Equal = 'EQUAL',
  /** The target fields value must not be equal to value1  */
  NotEqual = 'NOT_EQUAL',
  /** The target fields value must be between value1 (inclusive) and value2 (exclusive) */
  Between = 'BETWEEN',
  /** The target field is null, no values need to be given */
  Null = 'NULL',
  /** The target field is not null, no values need to be given */
  NotNull = 'NOT_NULL'
}

export type SeerGenericFloatFilter = {
  readonly operator: SeerGenericFilterOperator;
  /** required for all filter operators but NULL and NOT_NULL */
  readonly value1?: InputMaybe<Scalars['Float']>;
  /** required only for filter operator BETWEEN as end value */
  readonly value2?: InputMaybe<Scalars['Float']>;
  readonly orGroup?: InputMaybe<Scalars['String']>;
};

export type SeerGenericIntFilter = {
  readonly operator: SeerGenericFilterOperator;
  /** required for all filter operators but NULL and NOT_NULL */
  readonly value1?: InputMaybe<Scalars['Int']>;
  /** required only for filter operator BETWEEN as end value */
  readonly value2?: InputMaybe<Scalars['Int']>;
  readonly orGroup?: InputMaybe<Scalars['String']>;
};

/** Sort order for seer */
export enum SeerGenericOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SeerGenericStringFilter = {
  readonly operator: SeerGenericStringOperator;
  /** required for all filter operators but NULL and NOT_NULL, supports sql wildcards % and _ */
  readonly value?: InputMaybe<Scalars['String']>;
  readonly orGroup?: InputMaybe<Scalars['String']>;
};

/** Filter operator for strings */
export enum SeerGenericStringOperator {
  /** The target fields value must be equal to value */
  Equal = 'EQUAL',
  /** The target fields value must be like the given value, wildcards are _ and % */
  Like = 'LIKE',
  /** The target fields value must not be equal to value */
  NotEqual = 'NOT_EQUAL',
  /** The target fields value must not be like the given value, wildcards are _ and % */
  NotLike = 'NOT_LIKE',
  /** The target field is null, no values need to be given */
  Null = 'NULL',
  /** The target field is not null, no values need to be given */
  NotNull = 'NOT_NULL'
}

export type SeerGeoPoint = {
  readonly __typename?: 'SeerGeoPoint';
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export type SeerIvMainPageRouteBoard = {
  readonly __typename?: 'SeerIvMainPageRouteBoard';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly customer?: Maybe<Scalars['String']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly oBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly stop: Scalars['String'];
  /** Column is of type UUID */
  readonly originStopId: Scalars['String'];
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly routeNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly rlc?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly height?: Maybe<Scalars['Float']>;
  readonly width?: Maybe<Scalars['Float']>;
  readonly length?: Maybe<Scalars['Float']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly originStopType: Scalars['String'];
};

export type SeerIvMainPageRouteBoardEdge = {
  readonly __typename?: 'SeerIvMainPageRouteBoardEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerIvMainPageRouteBoard;
};

export type SeerIvMainPageRouteBoardFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly originStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly height?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly width?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly length?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerIvMainPageRouteBoardOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly routeTransportMode?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly oBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly stop?: InputMaybe<SeerOrderBy>;
  readonly originStopId?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly height?: InputMaybe<SeerOrderBy>;
  readonly width?: InputMaybe<SeerOrderBy>;
  readonly length?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly originStopType?: InputMaybe<SeerOrderBy>;
};

export type SeerIvMainPageRouteBoardResult = {
  readonly __typename?: 'SeerIvMainPageRouteBoardResult';
  readonly edges: ReadonlyArray<SeerIvMainPageRouteBoardEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerIvMprb = {
  readonly __typename?: 'SeerIvMprb';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly customer?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly oBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly stop: Scalars['String'];
  /** Column is of type UUID */
  readonly originStopId: Scalars['String'];
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly routeNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly rlc?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
};

export type SeerIvMprbEdge = {
  readonly __typename?: 'SeerIvMprbEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerIvMprb;
};

export type SeerIvMprbFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly originStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerIvMprbOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly oBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly stop?: InputMaybe<SeerOrderBy>;
  readonly originStopId?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
};

export type SeerIvMprbResult = {
  readonly __typename?: 'SeerIvMprbResult';
  readonly edges: ReadonlyArray<SeerIvMprbEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLinkedRoutes = {
  readonly __typename?: 'SeerLinkedRoutes';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly eid: Scalars['Int'];
  /** Column is of type UUID */
  readonly routeid: Scalars['String'];
  /** Column is of type UUID */
  readonly previousrouteid?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly nextrouteid?: Maybe<Scalars['String']>;
};

export type SeerLinkedRoutesEdge = {
  readonly __typename?: 'SeerLinkedRoutesEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLinkedRoutes;
};

export type SeerLinkedRoutesFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly routeid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly previousrouteid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly nextrouteid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerLinkedRoutesOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
  readonly routeid?: InputMaybe<SeerOrderBy>;
  readonly previousrouteid?: InputMaybe<SeerOrderBy>;
  readonly nextrouteid?: InputMaybe<SeerOrderBy>;
};

export type SeerLinkedRoutesResult = {
  readonly __typename?: 'SeerLinkedRoutesResult';
  readonly edges: ReadonlyArray<SeerLinkedRoutesEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLoad = {
  readonly __typename?: 'SeerLoad';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId?: Maybe<Scalars['String']>;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly orderCode?: Maybe<Scalars['String']>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly orderTenderStatus?: Maybe<Scalars['String']>;
  readonly orderMode?: Maybe<Scalars['String']>;
  readonly orderHeight?: Maybe<Scalars['Float']>;
  readonly orderWidth?: Maybe<Scalars['Float']>;
  readonly orderLength?: Maybe<Scalars['Float']>;
  readonly orderHeightUnit?: Maybe<Scalars['String']>;
  readonly orderWidthUnit?: Maybe<Scalars['String']>;
  readonly orderLengthUnit?: Maybe<Scalars['String']>;
  readonly orderDivision?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly orderCustomerId?: Maybe<Scalars['String']>;
  readonly orderCustomerName?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly routeTenderStatus?: Maybe<Scalars['String']>;
  readonly routeLifecyleStatus?: Maybe<Scalars['String']>;
  readonly totalDistance?: Maybe<Scalars['Float']>;
  readonly totalDistanceUnit?: Maybe<Scalars['String']>;
  readonly stopCount?: Maybe<Scalars['Int']>;
  readonly orderSize?: Maybe<Scalars['String']>;
  readonly customerRepIds?: Maybe<Scalars['String']>;
  readonly customerReps?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly orderLifecycleStatus?: Maybe<Scalars['String']>;
  readonly paymentTerms?: Maybe<Scalars['String']>;
  readonly scac?: Maybe<Scalars['String']>;
  readonly quoteType?: Maybe<Scalars['String']>;
  readonly relation?: Maybe<Scalars['String']>;
  readonly priceTier?: Maybe<Scalars['String']>;
  readonly totalWeight?: Maybe<Scalars['Float']>;
  readonly totalWeightUnit?: Maybe<Scalars['String']>;
  readonly cargoInsurance?: Maybe<Scalars['Float']>;
  readonly cargoInsuranceUnit?: Maybe<Scalars['String']>;
  readonly requirements?: Maybe<Scalars['String']>;
  readonly tenderedAt?: Maybe<Scalars['DateTime']>;
  readonly expectedAt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedDateTime?: Maybe<Scalars['DateTime']>;
  readonly timezone?: Maybe<Scalars['String']>;
};

export type SeerLoadEdge = {
  readonly __typename?: 'SeerLoadEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLoad;
};

export type SeerLoadFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderTenderStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderHeight?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly orderWidth?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly orderLength?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly orderHeightUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderWidthUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderLengthUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderDivision?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderCustomerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderCustomerName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeTenderStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeLifecyleStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly totalDistance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalDistanceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stopCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly orderSize?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerRepIds?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerReps?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly orderLifecycleStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly paymentTerms?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly scac?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly quoteType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly relation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly priceTier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly totalWeight?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalWeightUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly cargoInsurance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly cargoInsuranceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly requirements?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tenderedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly expectedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly lastUpdatedDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly timezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerLoadOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly orderCode?: InputMaybe<SeerOrderBy>;
  readonly orderActivationStatus?: InputMaybe<SeerOrderBy>;
  readonly orderTenderStatus?: InputMaybe<SeerOrderBy>;
  readonly orderMode?: InputMaybe<SeerOrderBy>;
  readonly orderHeight?: InputMaybe<SeerOrderBy>;
  readonly orderWidth?: InputMaybe<SeerOrderBy>;
  readonly orderLength?: InputMaybe<SeerOrderBy>;
  readonly orderHeightUnit?: InputMaybe<SeerOrderBy>;
  readonly orderWidthUnit?: InputMaybe<SeerOrderBy>;
  readonly orderLengthUnit?: InputMaybe<SeerOrderBy>;
  readonly orderDivision?: InputMaybe<SeerOrderBy>;
  readonly orderCustomerId?: InputMaybe<SeerOrderBy>;
  readonly orderCustomerName?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly routeActivationStatus?: InputMaybe<SeerOrderBy>;
  readonly routeTenderStatus?: InputMaybe<SeerOrderBy>;
  readonly routeLifecyleStatus?: InputMaybe<SeerOrderBy>;
  readonly totalDistance?: InputMaybe<SeerOrderBy>;
  readonly totalDistanceUnit?: InputMaybe<SeerOrderBy>;
  readonly stopCount?: InputMaybe<SeerOrderBy>;
  readonly orderSize?: InputMaybe<SeerOrderBy>;
  readonly customerRepIds?: InputMaybe<SeerOrderBy>;
  readonly customerReps?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly orderLifecycleStatus?: InputMaybe<SeerOrderBy>;
  readonly paymentTerms?: InputMaybe<SeerOrderBy>;
  readonly scac?: InputMaybe<SeerOrderBy>;
  readonly quoteType?: InputMaybe<SeerOrderBy>;
  readonly relation?: InputMaybe<SeerOrderBy>;
  readonly priceTier?: InputMaybe<SeerOrderBy>;
  readonly totalWeight?: InputMaybe<SeerOrderBy>;
  readonly totalWeightUnit?: InputMaybe<SeerOrderBy>;
  readonly cargoInsurance?: InputMaybe<SeerOrderBy>;
  readonly cargoInsuranceUnit?: InputMaybe<SeerOrderBy>;
  readonly requirements?: InputMaybe<SeerOrderBy>;
  readonly tenderedAt?: InputMaybe<SeerOrderBy>;
  readonly expectedAt?: InputMaybe<SeerOrderBy>;
  readonly lastUpdatedDateTime?: InputMaybe<SeerOrderBy>;
  readonly timezone?: InputMaybe<SeerOrderBy>;
};

export type SeerLoadResult = {
  readonly __typename?: 'SeerLoadResult';
  readonly edges: ReadonlyArray<SeerLoadEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLoadSearch = {
  readonly __typename?: 'SeerLoadSearch';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly orderNumber?: Maybe<Scalars['String']>;
  readonly oas?: Maybe<Scalars['String']>;
  readonly ots?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly orderDivision?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly puApptStatus?: Maybe<Scalars['String']>;
  readonly shipper?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly shipperId?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly distance?: Maybe<Scalars['Float']>;
  readonly distanceUnit?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly consignee?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly consigneeId?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly delApptStatus?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly carrierRepNames?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly carrierRepId?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly maxCost?: Maybe<Scalars['Float']>;
  readonly overMax?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly refNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly carrierId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerId?: Maybe<Scalars['String']>;
  readonly originRadius?: Maybe<SeerGeoPoint>;
  readonly mainCustomerRep?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly destinationRadius?: Maybe<SeerGeoPoint>;
  readonly carrierStatus: Scalars['String'];
  readonly booked: Scalars['Int'];
  readonly vendorType?: Maybe<Scalars['String']>;
  readonly repType?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly originFirstLoadStop?: Maybe<Scalars['Boolean']>;
  readonly originLastLoadStop?: Maybe<Scalars['Boolean']>;
  readonly originloadStopSequence?: Maybe<Scalars['Int']>;
  readonly destinationFirstLoadStop?: Maybe<Scalars['Boolean']>;
  readonly destinationLastLoadStop?: Maybe<Scalars['Boolean']>;
  readonly destinationloadStopSequence?: Maybe<Scalars['Int']>;
  readonly olcs?: Maybe<Scalars['String']>;
};

export type SeerLoadSearchDataAggregation = {
  readonly __typename?: 'SeerLoadSearchDataAggregation';
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  readonly maxCost?: Maybe<Scalars['Float']>;
  readonly overMax?: Maybe<Scalars['Float']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
};

export type SeerLoadSearchDataAggregationBkup = {
  readonly __typename?: 'SeerLoadSearchDataAggregationBkup';
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  readonly maxCost?: Maybe<Scalars['Float']>;
  readonly overMax?: Maybe<Scalars['Float']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
  /** Column is of type UUID */
  readonly routeId?: Maybe<Scalars['String']>;
};

export type SeerLoadSearchDataAggregationBkupEdge = {
  readonly __typename?: 'SeerLoadSearchDataAggregationBkupEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLoadSearchDataAggregationBkup;
};

export type SeerLoadSearchDataAggregationBkupFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly maxCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly overMax?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerLoadSearchDataAggregationBkupOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly maxCost?: InputMaybe<SeerOrderBy>;
  readonly overMax?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly totalCost?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
};

export type SeerLoadSearchDataAggregationBkupResult = {
  readonly __typename?: 'SeerLoadSearchDataAggregationBkupResult';
  readonly edges: ReadonlyArray<SeerLoadSearchDataAggregationBkupEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLoadSearchDataAggregationEdge = {
  readonly __typename?: 'SeerLoadSearchDataAggregationEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLoadSearchDataAggregation;
};

export type SeerLoadSearchDataAggregationFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly maxCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly overMax?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerLoadSearchDataAggregationOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly maxCost?: InputMaybe<SeerOrderBy>;
  readonly overMax?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly totalCost?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
};

export type SeerLoadSearchDataAggregationResult = {
  readonly __typename?: 'SeerLoadSearchDataAggregationResult';
  readonly edges: ReadonlyArray<SeerLoadSearchDataAggregationEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLoadSearchEdge = {
  readonly __typename?: 'SeerLoadSearchEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLoadSearch;
};

export type SeerLoadSearchFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oas?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ots?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderDivision?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly shipper?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly shipperId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly distance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly distanceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly consignee?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly consigneeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierRepNames?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trailerNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly maxCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly overMax?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly originState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly refNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originRadius?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDistanceFilter>>>;
  readonly mainCustomerRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationRadius?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDistanceFilter>>>;
  readonly carrierStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly booked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly vendorType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly repType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly originFirstLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly originLastLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly originloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destinationFirstLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly destinationLastLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly destinationloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly olcs?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerLoadSearchMoloSniSujan = {
  readonly __typename?: 'SeerLoadSearchMoloSniSujan';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly orderNumber?: Maybe<Scalars['String']>;
  readonly oas?: Maybe<Scalars['String']>;
  readonly ots?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly orderDivision?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly puApptStatus?: Maybe<Scalars['String']>;
  readonly shipper?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly shipperId?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly distance?: Maybe<Scalars['Float']>;
  readonly distanceUnit?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly consignee?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly consigneeId?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly delApptStatus?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly carrierRepId?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly maxCost?: Maybe<Scalars['Float']>;
  readonly overMax?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly refNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly carrierId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerId?: Maybe<Scalars['String']>;
  readonly originRadius?: Maybe<SeerGeoPoint>;
  readonly mainCustomerRep?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly destinationRadius?: Maybe<SeerGeoPoint>;
  readonly carrierStatus: Scalars['String'];
  readonly booked: Scalars['Int'];
  readonly vendorType?: Maybe<Scalars['String']>;
  readonly repType?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly originFirstLoadStop?: Maybe<Scalars['Boolean']>;
  readonly originLastLoadStop?: Maybe<Scalars['Boolean']>;
  readonly originloadStopSequence?: Maybe<Scalars['Int']>;
  readonly destinationFirstLoadStop?: Maybe<Scalars['Boolean']>;
  readonly destinationLastLoadStop?: Maybe<Scalars['Boolean']>;
  readonly destinationloadStopSequence?: Maybe<Scalars['Int']>;
};

export type SeerLoadSearchMoloSniSujanEdge = {
  readonly __typename?: 'SeerLoadSearchMoloSniSujanEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLoadSearchMoloSniSujan;
};

export type SeerLoadSearchMoloSniSujanFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oas?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ots?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderDivision?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly shipper?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly shipperId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly distance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly distanceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly consignee?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly consigneeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trailerNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly maxCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly overMax?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly originState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly refNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originRadius?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDistanceFilter>>>;
  readonly mainCustomerRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationRadius?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDistanceFilter>>>;
  readonly carrierStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly booked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly vendorType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly repType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly originFirstLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly originLastLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly originloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destinationFirstLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly destinationLastLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly destinationloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
};

export type SeerLoadSearchMoloSniSujanOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly orderNumber?: InputMaybe<SeerOrderBy>;
  readonly oas?: InputMaybe<SeerOrderBy>;
  readonly ots?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly size?: InputMaybe<SeerOrderBy>;
  readonly orderDivision?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puApptStatus?: InputMaybe<SeerOrderBy>;
  readonly shipper?: InputMaybe<SeerOrderBy>;
  readonly shipperId?: InputMaybe<SeerOrderBy>;
  readonly originCity?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly distance?: InputMaybe<SeerOrderBy>;
  readonly distanceUnit?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly consignee?: InputMaybe<SeerOrderBy>;
  readonly consigneeId?: InputMaybe<SeerOrderBy>;
  readonly destinationCity?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delApptStatus?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly carrierRepId?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber?: InputMaybe<SeerOrderBy>;
  readonly maxCost?: InputMaybe<SeerOrderBy>;
  readonly overMax?: InputMaybe<SeerOrderBy>;
  readonly totalCost?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly originState?: InputMaybe<SeerOrderBy>;
  readonly destinationState?: InputMaybe<SeerOrderBy>;
  readonly refNumber?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly mainCustomerRep?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly carrierStatus?: InputMaybe<SeerOrderBy>;
  readonly booked?: InputMaybe<SeerOrderBy>;
  readonly vendorType?: InputMaybe<SeerOrderBy>;
  readonly repType?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly originFirstLoadStop?: InputMaybe<SeerOrderBy>;
  readonly originLastLoadStop?: InputMaybe<SeerOrderBy>;
  readonly originloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly destinationFirstLoadStop?: InputMaybe<SeerOrderBy>;
  readonly destinationLastLoadStop?: InputMaybe<SeerOrderBy>;
  readonly destinationloadStopSequence?: InputMaybe<SeerOrderBy>;
};

export type SeerLoadSearchMoloSniSujanResult = {
  readonly __typename?: 'SeerLoadSearchMoloSniSujanResult';
  readonly edges: ReadonlyArray<SeerLoadSearchMoloSniSujanEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLoadSearchOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly orderNumber?: InputMaybe<SeerOrderBy>;
  readonly oas?: InputMaybe<SeerOrderBy>;
  readonly ots?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly size?: InputMaybe<SeerOrderBy>;
  readonly orderDivision?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puApptStatus?: InputMaybe<SeerOrderBy>;
  readonly shipper?: InputMaybe<SeerOrderBy>;
  readonly shipperId?: InputMaybe<SeerOrderBy>;
  readonly originCity?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly distance?: InputMaybe<SeerOrderBy>;
  readonly distanceUnit?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly consignee?: InputMaybe<SeerOrderBy>;
  readonly consigneeId?: InputMaybe<SeerOrderBy>;
  readonly destinationCity?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delApptStatus?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly carrierRepNames?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly carrierRepId?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber?: InputMaybe<SeerOrderBy>;
  readonly maxCost?: InputMaybe<SeerOrderBy>;
  readonly overMax?: InputMaybe<SeerOrderBy>;
  readonly totalCost?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly originState?: InputMaybe<SeerOrderBy>;
  readonly destinationState?: InputMaybe<SeerOrderBy>;
  readonly refNumber?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly mainCustomerRep?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly carrierStatus?: InputMaybe<SeerOrderBy>;
  readonly booked?: InputMaybe<SeerOrderBy>;
  readonly vendorType?: InputMaybe<SeerOrderBy>;
  readonly repType?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly originFirstLoadStop?: InputMaybe<SeerOrderBy>;
  readonly originLastLoadStop?: InputMaybe<SeerOrderBy>;
  readonly originloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly destinationFirstLoadStop?: InputMaybe<SeerOrderBy>;
  readonly destinationLastLoadStop?: InputMaybe<SeerOrderBy>;
  readonly destinationloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly olcs?: InputMaybe<SeerOrderBy>;
};

export type SeerLoadSearchResult = {
  readonly __typename?: 'SeerLoadSearchResult';
  readonly edges: ReadonlyArray<SeerLoadSearchEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLoadSearchSideBar = {
  readonly __typename?: 'SeerLoadSearchSideBar';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly orderNumber?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
};

export type SeerLoadSearchSideBarEdge = {
  readonly __typename?: 'SeerLoadSearchSideBarEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLoadSearchSideBar;
};

export type SeerLoadSearchSideBarFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerLoadSearchSideBarOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly orderNumber?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly originCity?: InputMaybe<SeerOrderBy>;
  readonly originState?: InputMaybe<SeerOrderBy>;
  readonly destinationCity?: InputMaybe<SeerOrderBy>;
  readonly destinationState?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
};

export type SeerLoadSearchSideBarResult = {
  readonly __typename?: 'SeerLoadSearchSideBarResult';
  readonly edges: ReadonlyArray<SeerLoadSearchSideBarEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLoadSearchSm = {
  readonly __typename?: 'SeerLoadSearchSm';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly orderNumber?: Maybe<Scalars['String']>;
  readonly oas?: Maybe<Scalars['String']>;
  readonly ots?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly orderDivision?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly puApptStatus?: Maybe<Scalars['String']>;
  readonly shipper?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly shipperId?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly distance?: Maybe<Scalars['Float']>;
  readonly distanceUnit?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly consignee?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly consigneeId?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly delApptStatus?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly carrierRepNames?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly carrierRepId?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly maxCost?: Maybe<Scalars['Float']>;
  readonly overMax?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly refNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly carrierId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerId?: Maybe<Scalars['String']>;
  readonly originRadius?: Maybe<SeerGeoPoint>;
  readonly mainCustomerRep?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly destinationRadius?: Maybe<SeerGeoPoint>;
  readonly carrierStatus: Scalars['String'];
  readonly booked: Scalars['Int'];
  readonly vendorType?: Maybe<Scalars['String']>;
  readonly repType?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly originFirstLoadStop?: Maybe<Scalars['Boolean']>;
  readonly originLastLoadStop?: Maybe<Scalars['Boolean']>;
  readonly originloadStopSequence?: Maybe<Scalars['Int']>;
  readonly destinationFirstLoadStop?: Maybe<Scalars['Boolean']>;
  readonly destinationLastLoadStop?: Maybe<Scalars['Boolean']>;
  readonly destinationloadStopSequence?: Maybe<Scalars['Int']>;
  readonly olcs?: Maybe<Scalars['String']>;
};

export type SeerLoadSearchSmEdge = {
  readonly __typename?: 'SeerLoadSearchSmEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLoadSearchSm;
};

export type SeerLoadSearchSmFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oas?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ots?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderDivision?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly shipper?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly shipperId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly distance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly distanceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly consignee?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly consigneeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierRepNames?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trailerNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly maxCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly overMax?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly originState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly refNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originRadius?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDistanceFilter>>>;
  readonly mainCustomerRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationRadius?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDistanceFilter>>>;
  readonly carrierStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly booked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly vendorType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly repType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly originFirstLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly originLastLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly originloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destinationFirstLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly destinationLastLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly destinationloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly olcs?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerLoadSearchSmOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly orderNumber?: InputMaybe<SeerOrderBy>;
  readonly oas?: InputMaybe<SeerOrderBy>;
  readonly ots?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly size?: InputMaybe<SeerOrderBy>;
  readonly orderDivision?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puApptStatus?: InputMaybe<SeerOrderBy>;
  readonly shipper?: InputMaybe<SeerOrderBy>;
  readonly shipperId?: InputMaybe<SeerOrderBy>;
  readonly originCity?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly distance?: InputMaybe<SeerOrderBy>;
  readonly distanceUnit?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly consignee?: InputMaybe<SeerOrderBy>;
  readonly consigneeId?: InputMaybe<SeerOrderBy>;
  readonly destinationCity?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delApptStatus?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly carrierRepNames?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly carrierRepId?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber?: InputMaybe<SeerOrderBy>;
  readonly maxCost?: InputMaybe<SeerOrderBy>;
  readonly overMax?: InputMaybe<SeerOrderBy>;
  readonly totalCost?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly originState?: InputMaybe<SeerOrderBy>;
  readonly destinationState?: InputMaybe<SeerOrderBy>;
  readonly refNumber?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly mainCustomerRep?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly carrierStatus?: InputMaybe<SeerOrderBy>;
  readonly booked?: InputMaybe<SeerOrderBy>;
  readonly vendorType?: InputMaybe<SeerOrderBy>;
  readonly repType?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly originFirstLoadStop?: InputMaybe<SeerOrderBy>;
  readonly originLastLoadStop?: InputMaybe<SeerOrderBy>;
  readonly originloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly destinationFirstLoadStop?: InputMaybe<SeerOrderBy>;
  readonly destinationLastLoadStop?: InputMaybe<SeerOrderBy>;
  readonly destinationloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly olcs?: InputMaybe<SeerOrderBy>;
};

export type SeerLoadSearchSmResult = {
  readonly __typename?: 'SeerLoadSearchSmResult';
  readonly edges: ReadonlyArray<SeerLoadSearchSmEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLoadSearchV2 = {
  readonly __typename?: 'SeerLoadSearchV2';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly orderNumber?: Maybe<Scalars['String']>;
  readonly oas?: Maybe<Scalars['String']>;
  readonly ots?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly orderDivision?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly puApptStatus?: Maybe<Scalars['String']>;
  readonly shipper?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly shipperId?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly distance?: Maybe<Scalars['Float']>;
  readonly distanceUnit?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly consignee?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly consigneeId?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly delApptStatus?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly carrierRepNames?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly carrierRepId?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly maxCost?: Maybe<Scalars['Float']>;
  readonly overMax?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly refNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly carrierId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerId?: Maybe<Scalars['String']>;
  readonly originRadius?: Maybe<SeerGeoPoint>;
  readonly mainCustomerRep?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly destinationRadius?: Maybe<SeerGeoPoint>;
  readonly carrierStatus: Scalars['String'];
  readonly booked: Scalars['Int'];
  readonly vendorType?: Maybe<Scalars['String']>;
  readonly repType?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly originFirstLoadStop?: Maybe<Scalars['Boolean']>;
  readonly originLastLoadStop?: Maybe<Scalars['Boolean']>;
  readonly originloadStopSequence?: Maybe<Scalars['Int']>;
  readonly destinationFirstLoadStop?: Maybe<Scalars['Boolean']>;
  readonly destinationLastLoadStop?: Maybe<Scalars['Boolean']>;
  readonly destinationloadStopSequence?: Maybe<Scalars['Int']>;
  readonly olcs?: Maybe<Scalars['String']>;
};

export type SeerLoadSearchV2Edge = {
  readonly __typename?: 'SeerLoadSearchV2Edge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLoadSearchV2;
};

export type SeerLoadSearchV2Filter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oas?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ots?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderDivision?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly shipper?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly shipperId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly distance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly distanceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly consignee?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly consigneeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierRepNames?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trailerNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly maxCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly overMax?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly originState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly refNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originRadius?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDistanceFilter>>>;
  readonly mainCustomerRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationRadius?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDistanceFilter>>>;
  readonly carrierStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly booked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly vendorType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly repType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly originFirstLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly originLastLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly originloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destinationFirstLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly destinationLastLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly destinationloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly olcs?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerLoadSearchV2OrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly orderNumber?: InputMaybe<SeerOrderBy>;
  readonly oas?: InputMaybe<SeerOrderBy>;
  readonly ots?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly size?: InputMaybe<SeerOrderBy>;
  readonly orderDivision?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puApptStatus?: InputMaybe<SeerOrderBy>;
  readonly shipper?: InputMaybe<SeerOrderBy>;
  readonly shipperId?: InputMaybe<SeerOrderBy>;
  readonly originCity?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly distance?: InputMaybe<SeerOrderBy>;
  readonly distanceUnit?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly consignee?: InputMaybe<SeerOrderBy>;
  readonly consigneeId?: InputMaybe<SeerOrderBy>;
  readonly destinationCity?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delApptStatus?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly carrierRepNames?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly carrierRepId?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber?: InputMaybe<SeerOrderBy>;
  readonly maxCost?: InputMaybe<SeerOrderBy>;
  readonly overMax?: InputMaybe<SeerOrderBy>;
  readonly totalCost?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly originState?: InputMaybe<SeerOrderBy>;
  readonly destinationState?: InputMaybe<SeerOrderBy>;
  readonly refNumber?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly mainCustomerRep?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly carrierStatus?: InputMaybe<SeerOrderBy>;
  readonly booked?: InputMaybe<SeerOrderBy>;
  readonly vendorType?: InputMaybe<SeerOrderBy>;
  readonly repType?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly originFirstLoadStop?: InputMaybe<SeerOrderBy>;
  readonly originLastLoadStop?: InputMaybe<SeerOrderBy>;
  readonly originloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly destinationFirstLoadStop?: InputMaybe<SeerOrderBy>;
  readonly destinationLastLoadStop?: InputMaybe<SeerOrderBy>;
  readonly destinationloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly olcs?: InputMaybe<SeerOrderBy>;
};

export type SeerLoadSearchV2Result = {
  readonly __typename?: 'SeerLoadSearchV2Result';
  readonly edges: ReadonlyArray<SeerLoadSearchV2Edge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLoadSearchV2Sm = {
  readonly __typename?: 'SeerLoadSearchV2Sm';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly orderNumber?: Maybe<Scalars['String']>;
  readonly oas?: Maybe<Scalars['String']>;
  readonly ots?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly orderDivision?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly puApptStatus?: Maybe<Scalars['String']>;
  readonly shipper?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly shipperId?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly distance?: Maybe<Scalars['Float']>;
  readonly distanceUnit?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly consignee?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly consigneeId?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly delApptStatus?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly carrierRepNames?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly carrierRepId?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly maxCost?: Maybe<Scalars['Float']>;
  readonly overMax?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly refNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly carrierId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerId?: Maybe<Scalars['String']>;
  readonly originRadius?: Maybe<SeerGeoPoint>;
  readonly mainCustomerRep?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly destinationRadius?: Maybe<SeerGeoPoint>;
  readonly carrierStatus: Scalars['String'];
  readonly booked: Scalars['Int'];
  readonly vendorType?: Maybe<Scalars['String']>;
  readonly repType?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly originFirstLoadStop?: Maybe<Scalars['Boolean']>;
  readonly originLastLoadStop?: Maybe<Scalars['Boolean']>;
  readonly originloadStopSequence?: Maybe<Scalars['Int']>;
  readonly destinationFirstLoadStop?: Maybe<Scalars['Boolean']>;
  readonly destinationLastLoadStop?: Maybe<Scalars['Boolean']>;
  readonly destinationloadStopSequence?: Maybe<Scalars['Int']>;
  readonly olcs?: Maybe<Scalars['String']>;
};

export type SeerLoadSearchV2SmEdge = {
  readonly __typename?: 'SeerLoadSearchV2SmEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLoadSearchV2Sm;
};

export type SeerLoadSearchV2SmFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oas?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ots?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderDivision?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly shipper?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly shipperId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly distance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly distanceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly consignee?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly consigneeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delApptStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierRepNames?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trailerNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly maxCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly overMax?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly originState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly refNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originRadius?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDistanceFilter>>>;
  readonly mainCustomerRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationRadius?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDistanceFilter>>>;
  readonly carrierStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly booked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly vendorType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly repType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly originFirstLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly originLastLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly originloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destinationFirstLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly destinationLastLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly destinationloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly olcs?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerLoadSearchV2SmOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly orderNumber?: InputMaybe<SeerOrderBy>;
  readonly oas?: InputMaybe<SeerOrderBy>;
  readonly ots?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly size?: InputMaybe<SeerOrderBy>;
  readonly orderDivision?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puApptStatus?: InputMaybe<SeerOrderBy>;
  readonly shipper?: InputMaybe<SeerOrderBy>;
  readonly shipperId?: InputMaybe<SeerOrderBy>;
  readonly originCity?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly distance?: InputMaybe<SeerOrderBy>;
  readonly distanceUnit?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly consignee?: InputMaybe<SeerOrderBy>;
  readonly consigneeId?: InputMaybe<SeerOrderBy>;
  readonly destinationCity?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delApptStatus?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly carrierRepNames?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly carrierRepId?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber?: InputMaybe<SeerOrderBy>;
  readonly maxCost?: InputMaybe<SeerOrderBy>;
  readonly overMax?: InputMaybe<SeerOrderBy>;
  readonly totalCost?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly originState?: InputMaybe<SeerOrderBy>;
  readonly destinationState?: InputMaybe<SeerOrderBy>;
  readonly refNumber?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly mainCustomerRep?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly carrierStatus?: InputMaybe<SeerOrderBy>;
  readonly booked?: InputMaybe<SeerOrderBy>;
  readonly vendorType?: InputMaybe<SeerOrderBy>;
  readonly repType?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly originFirstLoadStop?: InputMaybe<SeerOrderBy>;
  readonly originLastLoadStop?: InputMaybe<SeerOrderBy>;
  readonly originloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly destinationFirstLoadStop?: InputMaybe<SeerOrderBy>;
  readonly destinationLastLoadStop?: InputMaybe<SeerOrderBy>;
  readonly destinationloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly olcs?: InputMaybe<SeerOrderBy>;
};

export type SeerLoadSearchV2SmResult = {
  readonly __typename?: 'SeerLoadSearchV2SmResult';
  readonly edges: ReadonlyArray<SeerLoadSearchV2SmEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLoadSummaryDataAggregation = {
  readonly __typename?: 'SeerLoadSummaryDataAggregation';
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  readonly totalMaxCost?: Maybe<Scalars['Float']>;
  readonly totalOverMax?: Maybe<Scalars['Float']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['Float']>;
};

export type SeerLoadSummaryDataAggregationEdge = {
  readonly __typename?: 'SeerLoadSummaryDataAggregationEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLoadSummaryDataAggregation;
};

export type SeerLoadSummaryDataAggregationFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly totalMaxCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalOverMax?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
};

export type SeerLoadSummaryDataAggregationOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly totalMaxCost?: InputMaybe<SeerOrderBy>;
  readonly totalOverMax?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly totalCost?: InputMaybe<SeerOrderBy>;
};

export type SeerLoadSummaryDataAggregationResult = {
  readonly __typename?: 'SeerLoadSummaryDataAggregationResult';
  readonly edges: ReadonlyArray<SeerLoadSummaryDataAggregationEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerLocks = {
  readonly __typename?: 'SeerLocks';
  /** Column is of type UUID */
  readonly objectId: Scalars['String'];
  readonly isLocked: Scalars['Boolean'];
  readonly eventType: Scalars['String'];
  readonly flavor: Scalars['String'];
  readonly userIdent: Scalars['String'];
  readonly adminIdent?: Maybe<Scalars['String']>;
  readonly expirationTimestamp: Scalars['Int'];
  readonly metadata?: Maybe<Scalars['String']>;
  readonly expirationDateTimestamp?: Maybe<Scalars['DateTime']>;
};

export type SeerLocksEdge = {
  readonly __typename?: 'SeerLocksEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerLocks;
};

export type SeerLocksFilter = {
  /** Column is of type UUID */
  readonly objectId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isLocked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly eventType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly flavor?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly userIdent?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly adminIdent?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expirationTimestamp?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly metadata?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expirationDateTimestamp?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerLocksOrderBy = {
  readonly objectId?: InputMaybe<SeerOrderBy>;
  readonly isLocked?: InputMaybe<SeerOrderBy>;
  readonly eventType?: InputMaybe<SeerOrderBy>;
  readonly flavor?: InputMaybe<SeerOrderBy>;
  readonly userIdent?: InputMaybe<SeerOrderBy>;
  readonly adminIdent?: InputMaybe<SeerOrderBy>;
  readonly expirationTimestamp?: InputMaybe<SeerOrderBy>;
  readonly metadata?: InputMaybe<SeerOrderBy>;
  readonly expirationDateTimestamp?: InputMaybe<SeerOrderBy>;
};

export type SeerLocksResult = {
  readonly __typename?: 'SeerLocksResult';
  readonly edges: ReadonlyArray<SeerLocksEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMainPageRouteBoard = {
  readonly __typename?: 'SeerMainPageRouteBoard';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly previousRouteId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly nextRouteId?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly originCountry?: Maybe<Scalars['String']>;
  readonly originStreet1?: Maybe<Scalars['String']>;
  readonly originStreet2?: Maybe<Scalars['String']>;
  readonly originPostalCode?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly dest?: Maybe<Scalars['String']>;
  readonly destCountry?: Maybe<Scalars['String']>;
  readonly destStreet1?: Maybe<Scalars['String']>;
  readonly destStreet2?: Maybe<Scalars['String']>;
  readonly destPostalCode?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly mTtime?: Maybe<Scalars['String']>;
  readonly mTzone?: Maybe<Scalars['String']>;
  readonly emptyLocation?: Maybe<Scalars['String']>;
  readonly emptyTimezone?: Maybe<Scalars['String']>;
  readonly emptyDate?: Maybe<Scalars['DateTime']>;
  readonly appt?: Maybe<Scalars['DateTime']>;
  readonly eta?: Maybe<Scalars['DateTime']>;
  readonly stop?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly nxtCallBkDtTm?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly apptId?: Maybe<Scalars['String']>;
  readonly rep?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly mainRepIds?: Maybe<Scalars['String']>;
  readonly expectedEmptyLatitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyLongitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly expectedEmptyCountry?: Maybe<Scalars['String']>;
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly customerReps?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly originStopType?: Maybe<Scalars['String']>;
  readonly destinationStopType?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly originStopId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly destinationStopId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly vendorId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly ibRegionUrn?: Maybe<Scalars['String']>;
  readonly obRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsubReionUrn?: Maybe<Scalars['String']>;
  readonly oBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsuperReionUrn?: Maybe<Scalars['String']>;
  readonly customerGroup?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly driver1Name?: Maybe<Scalars['String']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  readonly driver1PhoneNumber?: Maybe<Scalars['String']>;
  readonly driver2PhoneNumber?: Maybe<Scalars['String']>;
  readonly trackingStatus?: Maybe<Scalars['String']>;
  readonly tractorNumber?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly lastTrackingNotes?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly lockId?: Maybe<Scalars['String']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierArivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierArivalTimezone?: Maybe<Scalars['String']>;
  readonly firstStopCarrierDepartureDateTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly lastStopCarrierArivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastStopCarrierArivalTimezone?: Maybe<Scalars['String']>;
  readonly lastStopCarrierDepartureDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly nextLocationCity?: Maybe<Scalars['String']>;
  readonly nextLocationState?: Maybe<Scalars['String']>;
  readonly height?: Maybe<Scalars['Float']>;
  readonly width?: Maybe<Scalars['Float']>;
  readonly length?: Maybe<Scalars['Float']>;
  readonly currentStopId?: Maybe<Scalars['String']>;
  readonly currentStopNumber?: Maybe<Scalars['Int']>;
  readonly currentStopCarrierArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopCarrierArrivalTimezone?: Maybe<Scalars['String']>;
  readonly currentStopCarrierDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly nextApptStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptStartTime?: Maybe<Scalars['Int']>;
  readonly nextApptEndTime?: Maybe<Scalars['Int']>;
  readonly nextApptStartTimeZone?: Maybe<Scalars['String']>;
  readonly nextApptEndTimeZone?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly originloadStopSequence?: Maybe<Scalars['Int']>;
  readonly destinationloadStopSequence?: Maybe<Scalars['Int']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly waybillDateTime?: Maybe<Scalars['DateTime']>;
};

export type SeerMainPageRouteBoardCompleted = {
  readonly __typename?: 'SeerMainPageRouteBoardCompleted';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly rlc?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly originCountry?: Maybe<Scalars['String']>;
  readonly originStreet1?: Maybe<Scalars['String']>;
  readonly originStreet2?: Maybe<Scalars['String']>;
  readonly originPostalCode?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly dest?: Maybe<Scalars['String']>;
  readonly destCountry?: Maybe<Scalars['String']>;
  readonly destStreet1?: Maybe<Scalars['String']>;
  readonly destStreet2?: Maybe<Scalars['String']>;
  readonly destPostalCode?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly vendorRepName?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly mainRepIds?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerId?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly customerReps?: Maybe<Scalars['String']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly obRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly ibRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly originDate?: Maybe<Scalars['DateTime']>;
  readonly originTime?: Maybe<Scalars['Int']>;
  readonly destDate?: Maybe<Scalars['DateTime']>;
  readonly destTime?: Maybe<Scalars['Int']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly driver1Name?: Maybe<Scalars['String']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  readonly driver1PhoneNumber?: Maybe<Scalars['String']>;
  readonly driver2PhoneNumber?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
};

export type SeerMainPageRouteBoardCompletedEdge = {
  readonly __typename?: 'SeerMainPageRouteBoardCompletedEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMainPageRouteBoardCompleted;
};

export type SeerMainPageRouteBoardCompletedFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStreet1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStreet2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originPostalCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destStreet1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destStreet2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destPostalCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly vendorRepName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainRepIds?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerReps?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly originTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly destTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly carrierName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver1Name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver2Name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver1PhoneNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver2PhoneNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
};

export type SeerMainPageRouteBoardCompletedOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly originCountry?: InputMaybe<SeerOrderBy>;
  readonly originStreet1?: InputMaybe<SeerOrderBy>;
  readonly originStreet2?: InputMaybe<SeerOrderBy>;
  readonly originPostalCode?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly dest?: InputMaybe<SeerOrderBy>;
  readonly destCountry?: InputMaybe<SeerOrderBy>;
  readonly destStreet1?: InputMaybe<SeerOrderBy>;
  readonly destStreet2?: InputMaybe<SeerOrderBy>;
  readonly destPostalCode?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly vendorRepName?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly mainRepIds?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly customerName?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly customerReps?: InputMaybe<SeerOrderBy>;
  readonly routeTransportMode?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly obRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly ibRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly originDate?: InputMaybe<SeerOrderBy>;
  readonly originTime?: InputMaybe<SeerOrderBy>;
  readonly destDate?: InputMaybe<SeerOrderBy>;
  readonly destTime?: InputMaybe<SeerOrderBy>;
  readonly carrierName?: InputMaybe<SeerOrderBy>;
  readonly driver1Name?: InputMaybe<SeerOrderBy>;
  readonly driver2Name?: InputMaybe<SeerOrderBy>;
  readonly driver1PhoneNumber?: InputMaybe<SeerOrderBy>;
  readonly driver2PhoneNumber?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
};

export type SeerMainPageRouteBoardCompletedResult = {
  readonly __typename?: 'SeerMainPageRouteBoardCompletedResult';
  readonly edges: ReadonlyArray<SeerMainPageRouteBoardCompletedEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMainPageRouteBoardCountUnified = {
  readonly __typename?: 'SeerMainPageRouteBoardCountUnified';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly rlc?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeRepId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly ibRegionUrn?: Maybe<Scalars['String']>;
  readonly obRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierArrivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierDepartureDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastStopCarrierArrivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastStopCarrierDepartureDateTime?: Maybe<Scalars['DateTime']>;
  readonly openCount: Scalars['Int'];
  readonly coveredCount: Scalars['Int'];
  readonly dispatchedCount: Scalars['Int'];
  readonly completeCount: Scalars['Int'];
  readonly preTenderCount: Scalars['Int'];
  readonly tenderedCount: Scalars['Int'];
  readonly isAtOriginCount: Scalars['Int'];
  readonly isAtDestinationCount: Scalars['Int'];
  readonly isInTransitCount: Scalars['Int'];
};

export type SeerMainPageRouteBoardCountUnifiedEdge = {
  readonly __typename?: 'SeerMainPageRouteBoardCountUnifiedEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMainPageRouteBoardCountUnified;
};

export type SeerMainPageRouteBoardCountUnifiedFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierArrivalDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierDepartureDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly lastStopCarrierArrivalDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly lastStopCarrierDepartureDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly openCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly coveredCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly dispatchedCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly completeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly preTenderCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly tenderedCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly isAtOriginCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly isAtDestinationCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly isInTransitCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
};

export type SeerMainPageRouteBoardCountUnifiedOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly routeTransportMode?: InputMaybe<SeerOrderBy>;
  readonly routeRepId?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly division?: InputMaybe<SeerOrderBy>;
  readonly ibRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly obRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierArrivalDateTime?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierDepartureDateTime?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierArrivalDateTime?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierDepartureDateTime?: InputMaybe<SeerOrderBy>;
  readonly openCount?: InputMaybe<SeerOrderBy>;
  readonly coveredCount?: InputMaybe<SeerOrderBy>;
  readonly dispatchedCount?: InputMaybe<SeerOrderBy>;
  readonly completeCount?: InputMaybe<SeerOrderBy>;
  readonly preTenderCount?: InputMaybe<SeerOrderBy>;
  readonly tenderedCount?: InputMaybe<SeerOrderBy>;
  readonly isAtOriginCount?: InputMaybe<SeerOrderBy>;
  readonly isAtDestinationCount?: InputMaybe<SeerOrderBy>;
  readonly isInTransitCount?: InputMaybe<SeerOrderBy>;
};

export type SeerMainPageRouteBoardCountUnifiedResult = {
  readonly __typename?: 'SeerMainPageRouteBoardCountUnifiedResult';
  readonly edges: ReadonlyArray<SeerMainPageRouteBoardCountUnifiedEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMainPageRouteBoardDetails = {
  readonly __typename?: 'SeerMainPageRouteBoardDetails';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly oBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly originStopType: Scalars['String'];
  readonly stops?: Maybe<Scalars['Int']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly height?: Maybe<Scalars['Float']>;
  readonly width?: Maybe<Scalars['Float']>;
  readonly length?: Maybe<Scalars['Float']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly mt?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeRepId?: Maybe<Scalars['String']>;
  readonly emptyTimezone?: Maybe<Scalars['String']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly dest?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly iBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly firstStopCarrierArrivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierArrivalTimezone?: Maybe<Scalars['String']>;
  readonly firstStopCarrierDepartureDateTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly lastStopCarrierArrivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastStopCarrierArrivalTimezone?: Maybe<Scalars['String']>;
  readonly lastStopCarrierDepartureDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly driver1Name?: Maybe<Scalars['String']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  readonly nextLocationCity?: Maybe<Scalars['String']>;
  readonly nextLocationState?: Maybe<Scalars['String']>;
  readonly nxtCallBkDtTm?: Maybe<Scalars['DateTime']>;
  readonly eta?: Maybe<Scalars['DateTime']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly waybillDateTime?: Maybe<Scalars['DateTime']>;
  readonly currentStopNumber?: Maybe<Scalars['Int']>;
  readonly currentStopCarrierArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopCarrierArrivalTimezone?: Maybe<Scalars['String']>;
  readonly currentStopCarrierDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly nextApptStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptStartTime?: Maybe<Scalars['Int']>;
  readonly nextApptEndTime?: Maybe<Scalars['Int']>;
  readonly nextApptStartTimeZone?: Maybe<Scalars['String']>;
  readonly nextApptEndTimeZone?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly lockId?: Maybe<Scalars['String']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
};

export type SeerMainPageRouteBoardDetailsAh = {
  readonly __typename?: 'SeerMainPageRouteBoardDetailsAh';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly oBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly originStopType: Scalars['String'];
  readonly stops?: Maybe<Scalars['Int']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly height?: Maybe<Scalars['Float']>;
  readonly width?: Maybe<Scalars['Float']>;
  readonly length?: Maybe<Scalars['Float']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly mt?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeRepId?: Maybe<Scalars['String']>;
  readonly emptyTimezone?: Maybe<Scalars['String']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly dest?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly iBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly firstStopCarrierArrivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierArrivalTimezone?: Maybe<Scalars['String']>;
  readonly firstStopCarrierDepartureDateTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly lastStopCarrierArrivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastStopCarrierArrivalTimezone?: Maybe<Scalars['String']>;
  readonly lastStopCarrierDepartureDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly driver1Name?: Maybe<Scalars['String']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  readonly nextLocationCity?: Maybe<Scalars['String']>;
  readonly nextLocationState?: Maybe<Scalars['String']>;
  readonly nxtCallBkDtTm?: Maybe<Scalars['DateTime']>;
  readonly eta?: Maybe<Scalars['DateTime']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly waybillDateTime?: Maybe<Scalars['DateTime']>;
  readonly currentStopNumber?: Maybe<Scalars['Int']>;
  readonly currentStopCarrierArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopCarrierArrivalTimezone?: Maybe<Scalars['String']>;
  readonly currentStopCarrierDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly nextApptStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptStartTime?: Maybe<Scalars['Int']>;
  readonly nextApptEndTime?: Maybe<Scalars['Int']>;
  readonly nextApptStartTimeZone?: Maybe<Scalars['String']>;
  readonly nextApptEndTimeZone?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly lockId?: Maybe<Scalars['String']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
};

export type SeerMainPageRouteBoardDetailsAhEdge = {
  readonly __typename?: 'SeerMainPageRouteBoardDetailsAhEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMainPageRouteBoardDetailsAh;
};

export type SeerMainPageRouteBoardDetailsAhFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly height?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly width?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly length?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly firstStopCarrierArrivalDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierArrivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly firstStopCarrierDepartureDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastStopCarrierArrivalDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly lastStopCarrierArrivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastStopCarrierDepartureDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly lastStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver1Name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver2Name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nxtCallBkDtTm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly eta?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly waybillDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly currentStopNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly currentStopCarrierArrivalDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly currentStopCarrierArrivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentStopCarrierDepartureDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly currentStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly lockId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockEmail?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isLocked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly lockExpirationTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerMainPageRouteBoardDetailsAhOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly routeTransportMode?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly oBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly originStopType?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly height?: InputMaybe<SeerOrderBy>;
  readonly width?: InputMaybe<SeerOrderBy>;
  readonly length?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly mt?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly routeRepId?: InputMaybe<SeerOrderBy>;
  readonly emptyTimezone?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCity?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyState?: InputMaybe<SeerOrderBy>;
  readonly dest?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly iBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierArrivalDateTime?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierArrivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierDepartureDateTime?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierArrivalDateTime?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierArrivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierDepartureDateTime?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly driver1Name?: InputMaybe<SeerOrderBy>;
  readonly driver2Name?: InputMaybe<SeerOrderBy>;
  readonly nextLocationCity?: InputMaybe<SeerOrderBy>;
  readonly nextLocationState?: InputMaybe<SeerOrderBy>;
  readonly nxtCallBkDtTm?: InputMaybe<SeerOrderBy>;
  readonly eta?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly waybillDateTime?: InputMaybe<SeerOrderBy>;
  readonly currentStopNumber?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierArrivalDatetime?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierArrivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierDepartureDatetime?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly lockId?: InputMaybe<SeerOrderBy>;
  readonly lockEmail?: InputMaybe<SeerOrderBy>;
  readonly isLocked?: InputMaybe<SeerOrderBy>;
  readonly lockExpirationTime?: InputMaybe<SeerOrderBy>;
};

export type SeerMainPageRouteBoardDetailsAhResult = {
  readonly __typename?: 'SeerMainPageRouteBoardDetailsAhResult';
  readonly edges: ReadonlyArray<SeerMainPageRouteBoardDetailsAhEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMainPageRouteBoardDetailsEdge = {
  readonly __typename?: 'SeerMainPageRouteBoardDetailsEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMainPageRouteBoardDetails;
};

export type SeerMainPageRouteBoardDetailsFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly height?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly width?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly length?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly firstStopCarrierArrivalDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierArrivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly firstStopCarrierDepartureDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastStopCarrierArrivalDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly lastStopCarrierArrivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastStopCarrierDepartureDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly lastStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver1Name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver2Name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nxtCallBkDtTm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly eta?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly waybillDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly currentStopNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly currentStopCarrierArrivalDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly currentStopCarrierArrivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentStopCarrierDepartureDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly currentStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly lockId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockEmail?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isLocked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly lockExpirationTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerMainPageRouteBoardDetailsOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly routeTransportMode?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly oBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly originStopType?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly height?: InputMaybe<SeerOrderBy>;
  readonly width?: InputMaybe<SeerOrderBy>;
  readonly length?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly mt?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly routeRepId?: InputMaybe<SeerOrderBy>;
  readonly emptyTimezone?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCity?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyState?: InputMaybe<SeerOrderBy>;
  readonly dest?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly iBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierArrivalDateTime?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierArrivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierDepartureDateTime?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierArrivalDateTime?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierArrivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierDepartureDateTime?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly driver1Name?: InputMaybe<SeerOrderBy>;
  readonly driver2Name?: InputMaybe<SeerOrderBy>;
  readonly nextLocationCity?: InputMaybe<SeerOrderBy>;
  readonly nextLocationState?: InputMaybe<SeerOrderBy>;
  readonly nxtCallBkDtTm?: InputMaybe<SeerOrderBy>;
  readonly eta?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly waybillDateTime?: InputMaybe<SeerOrderBy>;
  readonly currentStopNumber?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierArrivalDatetime?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierArrivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierDepartureDatetime?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly lockId?: InputMaybe<SeerOrderBy>;
  readonly lockEmail?: InputMaybe<SeerOrderBy>;
  readonly isLocked?: InputMaybe<SeerOrderBy>;
  readonly lockExpirationTime?: InputMaybe<SeerOrderBy>;
};

export type SeerMainPageRouteBoardDetailsResult = {
  readonly __typename?: 'SeerMainPageRouteBoardDetailsResult';
  readonly edges: ReadonlyArray<SeerMainPageRouteBoardDetailsEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMainPageRouteBoardEdge = {
  readonly __typename?: 'SeerMainPageRouteBoardEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMainPageRouteBoard;
};

export type SeerMainPageRouteBoardFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly previousRouteId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly nextRouteId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStreet1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStreet2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originPostalCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destStreet1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destStreet2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destPostalCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mTtime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mTzone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyLocation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly appt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly eta?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly stop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nxtCallBkDtTm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly apptId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainRepIds?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyLatitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyLongitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerReps?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly originStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly destinationStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly employeeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubReionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsuperRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsuperRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsuperReionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerGroup?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver1Name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver2Name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver1PhoneNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver2PhoneNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trackingStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tractorNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trailerNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastTrackingNotes?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly lockId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockEmail?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isLocked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly lockExpirationTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierArivalDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierArivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly firstStopCarrierDepartureDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastStopCarrierArivalDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly lastStopCarrierArivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastStopCarrierDepartureDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly lastStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly height?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly width?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly length?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly currentStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentStopNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly currentStopCarrierArrivalDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly currentStopCarrierArrivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentStopCarrierDepartureDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly currentStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly originloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destinationloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly waybillDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerMainPageRouteBoardOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly previousRouteId?: InputMaybe<SeerOrderBy>;
  readonly nextRouteId?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly originCountry?: InputMaybe<SeerOrderBy>;
  readonly originStreet1?: InputMaybe<SeerOrderBy>;
  readonly originStreet2?: InputMaybe<SeerOrderBy>;
  readonly originPostalCode?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly dest?: InputMaybe<SeerOrderBy>;
  readonly destCountry?: InputMaybe<SeerOrderBy>;
  readonly destStreet1?: InputMaybe<SeerOrderBy>;
  readonly destStreet2?: InputMaybe<SeerOrderBy>;
  readonly destPostalCode?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly mTtime?: InputMaybe<SeerOrderBy>;
  readonly mTzone?: InputMaybe<SeerOrderBy>;
  readonly emptyLocation?: InputMaybe<SeerOrderBy>;
  readonly emptyTimezone?: InputMaybe<SeerOrderBy>;
  readonly emptyDate?: InputMaybe<SeerOrderBy>;
  readonly appt?: InputMaybe<SeerOrderBy>;
  readonly eta?: InputMaybe<SeerOrderBy>;
  readonly stop?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly nxtCallBkDtTm?: InputMaybe<SeerOrderBy>;
  readonly apptId?: InputMaybe<SeerOrderBy>;
  readonly rep?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly mainRepIds?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLatitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLongitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCity?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyState?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCountry?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly customerReps?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly division?: InputMaybe<SeerOrderBy>;
  readonly originStopType?: InputMaybe<SeerOrderBy>;
  readonly destinationStopType?: InputMaybe<SeerOrderBy>;
  readonly originStopId?: InputMaybe<SeerOrderBy>;
  readonly destinationStopId?: InputMaybe<SeerOrderBy>;
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly employeeId?: InputMaybe<SeerOrderBy>;
  readonly ibRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly obRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsubReionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsuperRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsuperRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsuperReionUrn?: InputMaybe<SeerOrderBy>;
  readonly customerGroup?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly customerName?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly carrierName?: InputMaybe<SeerOrderBy>;
  readonly driver1Name?: InputMaybe<SeerOrderBy>;
  readonly driver2Name?: InputMaybe<SeerOrderBy>;
  readonly driver1PhoneNumber?: InputMaybe<SeerOrderBy>;
  readonly driver2PhoneNumber?: InputMaybe<SeerOrderBy>;
  readonly trackingStatus?: InputMaybe<SeerOrderBy>;
  readonly tractorNumber?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber?: InputMaybe<SeerOrderBy>;
  readonly lastTrackingNotes?: InputMaybe<SeerOrderBy>;
  readonly lockId?: InputMaybe<SeerOrderBy>;
  readonly lockEmail?: InputMaybe<SeerOrderBy>;
  readonly isLocked?: InputMaybe<SeerOrderBy>;
  readonly lockExpirationTime?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierArivalDateTime?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierArivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierDepartureDateTime?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierArivalDateTime?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierArivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierDepartureDateTime?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly nextLocationCity?: InputMaybe<SeerOrderBy>;
  readonly nextLocationState?: InputMaybe<SeerOrderBy>;
  readonly height?: InputMaybe<SeerOrderBy>;
  readonly width?: InputMaybe<SeerOrderBy>;
  readonly length?: InputMaybe<SeerOrderBy>;
  readonly currentStopId?: InputMaybe<SeerOrderBy>;
  readonly currentStopNumber?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierArrivalDatetime?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierArrivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierDepartureDatetime?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly originloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly destinationloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly routeTransportMode?: InputMaybe<SeerOrderBy>;
  readonly waybillDateTime?: InputMaybe<SeerOrderBy>;
};

export type SeerMainPageRouteBoardPending = {
  readonly __typename?: 'SeerMainPageRouteBoardPending';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly orderNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  readonly oas?: Maybe<Scalars['String']>;
  readonly tenderStatus?: Maybe<Scalars['String']>;
  readonly tenderedAt?: Maybe<Scalars['DateTime']>;
  readonly tenderedAtTimezone?: Maybe<Scalars['String']>;
  readonly expectedAt?: Maybe<Scalars['DateTime']>;
  readonly expectedAtTimezone?: Maybe<Scalars['String']>;
  readonly shipper?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly shipperId?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly requestedShipDate?: Maybe<Scalars['DateTime']>;
  readonly requestedShipDateTimezone?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puStartTimezone?: Maybe<Scalars['String']>;
  readonly puEndTimezone?: Maybe<Scalars['String']>;
  readonly totalDistance?: Maybe<Scalars['Float']>;
  readonly totalDistanceUnit?: Maybe<Scalars['String']>;
  readonly consignee?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly consigneeId?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delStartTimezone?: Maybe<Scalars['String']>;
  readonly delEndTimezone?: Maybe<Scalars['String']>;
  readonly reqArriveDate?: Maybe<Scalars['DateTime']>;
  readonly reqArriveDateTimezone?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly scac?: Maybe<Scalars['String']>;
  readonly quoteType?: Maybe<Scalars['String']>;
  readonly relation?: Maybe<Scalars['String']>;
  readonly tier?: Maybe<Scalars['String']>;
  readonly orderWeight?: Maybe<Scalars['Float']>;
  readonly orderWeightUnit?: Maybe<Scalars['String']>;
  readonly cargoInsurance?: Maybe<Scalars['Float']>;
  readonly cargoInsuranceUnit?: Maybe<Scalars['String']>;
  readonly requirements?: Maybe<Scalars['String']>;
  readonly totalRate?: Maybe<Scalars['Float']>;
  readonly customerGroup?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly routeRepId?: Maybe<Scalars['String']>;
  readonly routeRep?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerId?: Maybe<Scalars['String']>;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly obRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly ibRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly orderHeight?: Maybe<Scalars['Float']>;
  readonly orderWidth?: Maybe<Scalars['Float']>;
  readonly orderLength?: Maybe<Scalars['Float']>;
  readonly orderHeightUnit?: Maybe<Scalars['String']>;
  readonly orderWidthUnit?: Maybe<Scalars['String']>;
  readonly orderLengthUnit?: Maybe<Scalars['String']>;
};

export type SeerMainPageRouteBoardPendingEdge = {
  readonly __typename?: 'SeerMainPageRouteBoardPendingEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMainPageRouteBoardPending;
};

export type SeerMainPageRouteBoardPendingFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oas?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tenderStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tenderedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly tenderedAtTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly expectedAtTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly shipper?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly shipperId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly requestedShipDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly requestedShipDateTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly totalDistance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalDistanceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly consignee?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly consigneeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly reqArriveDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly reqArriveDateTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly scac?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly quoteType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly relation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderWeight?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly orderWeightUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly cargoInsurance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly cargoInsuranceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly requirements?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly totalRate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly customerGroup?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsuperRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsuperRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderHeight?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly orderWidth?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly orderLength?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly orderHeightUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderWidthUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderLengthUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerMainPageRouteBoardPendingOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly orderNumber?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly oas?: InputMaybe<SeerOrderBy>;
  readonly tenderStatus?: InputMaybe<SeerOrderBy>;
  readonly tenderedAt?: InputMaybe<SeerOrderBy>;
  readonly tenderedAtTimezone?: InputMaybe<SeerOrderBy>;
  readonly expectedAt?: InputMaybe<SeerOrderBy>;
  readonly expectedAtTimezone?: InputMaybe<SeerOrderBy>;
  readonly shipper?: InputMaybe<SeerOrderBy>;
  readonly shipperId?: InputMaybe<SeerOrderBy>;
  readonly originCity?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly requestedShipDate?: InputMaybe<SeerOrderBy>;
  readonly requestedShipDateTimezone?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimezone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimezone?: InputMaybe<SeerOrderBy>;
  readonly totalDistance?: InputMaybe<SeerOrderBy>;
  readonly totalDistanceUnit?: InputMaybe<SeerOrderBy>;
  readonly consignee?: InputMaybe<SeerOrderBy>;
  readonly consigneeId?: InputMaybe<SeerOrderBy>;
  readonly destinationCity?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimezone?: InputMaybe<SeerOrderBy>;
  readonly delEndTimezone?: InputMaybe<SeerOrderBy>;
  readonly reqArriveDate?: InputMaybe<SeerOrderBy>;
  readonly reqArriveDateTimezone?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly size?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly scac?: InputMaybe<SeerOrderBy>;
  readonly quoteType?: InputMaybe<SeerOrderBy>;
  readonly relation?: InputMaybe<SeerOrderBy>;
  readonly tier?: InputMaybe<SeerOrderBy>;
  readonly orderWeight?: InputMaybe<SeerOrderBy>;
  readonly orderWeightUnit?: InputMaybe<SeerOrderBy>;
  readonly cargoInsurance?: InputMaybe<SeerOrderBy>;
  readonly cargoInsuranceUnit?: InputMaybe<SeerOrderBy>;
  readonly requirements?: InputMaybe<SeerOrderBy>;
  readonly totalRate?: InputMaybe<SeerOrderBy>;
  readonly customerGroup?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly routeRepId?: InputMaybe<SeerOrderBy>;
  readonly routeRep?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly obRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsuperRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly ibRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsuperRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly routeTransportMode?: InputMaybe<SeerOrderBy>;
  readonly orderHeight?: InputMaybe<SeerOrderBy>;
  readonly orderWidth?: InputMaybe<SeerOrderBy>;
  readonly orderLength?: InputMaybe<SeerOrderBy>;
  readonly orderHeightUnit?: InputMaybe<SeerOrderBy>;
  readonly orderWidthUnit?: InputMaybe<SeerOrderBy>;
  readonly orderLengthUnit?: InputMaybe<SeerOrderBy>;
};

export type SeerMainPageRouteBoardPendingResult = {
  readonly __typename?: 'SeerMainPageRouteBoardPendingResult';
  readonly edges: ReadonlyArray<SeerMainPageRouteBoardPendingEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMainPageRouteBoardPreTender = {
  readonly __typename?: 'SeerMainPageRouteBoardPreTender';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puStartTimezone?: Maybe<Scalars['String']>;
  readonly puEndTimezone?: Maybe<Scalars['String']>;
  readonly obRegion?: Maybe<Scalars['String']>;
  readonly obSubRegion?: Maybe<Scalars['String']>;
  readonly dest?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly destStartDate?: Maybe<Scalars['DateTime']>;
  readonly destEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStartTime?: Maybe<Scalars['Int']>;
  readonly destEndTime?: Maybe<Scalars['Int']>;
  readonly destStartTimezone?: Maybe<Scalars['String']>;
  readonly destEndTimezone?: Maybe<Scalars['String']>;
  readonly ibRegion?: Maybe<Scalars['String']>;
  readonly ibSubRegion?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routingGuideId: Scalars['String'];
  readonly tenderPlanType: Scalars['String'];
  readonly numberOfCarriers?: Maybe<Scalars['Int']>;
  readonly equip?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerRepId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeRepId?: Maybe<Scalars['String']>;
};

export type SeerMainPageRouteBoardPreTenderEdge = {
  readonly __typename?: 'SeerMainPageRouteBoardPreTenderEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMainPageRouteBoardPreTender;
};

export type SeerMainPageRouteBoardPreTenderFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly destEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly destStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destStartTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destEndTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routingGuideId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tenderPlanType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly numberOfCarriers?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerMainPageRouteBoardPreTenderOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly routeTransportMode?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimezone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimezone?: InputMaybe<SeerOrderBy>;
  readonly obRegion?: InputMaybe<SeerOrderBy>;
  readonly obSubRegion?: InputMaybe<SeerOrderBy>;
  readonly dest?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly destStartDate?: InputMaybe<SeerOrderBy>;
  readonly destEndDate?: InputMaybe<SeerOrderBy>;
  readonly destStartTime?: InputMaybe<SeerOrderBy>;
  readonly destEndTime?: InputMaybe<SeerOrderBy>;
  readonly destStartTimezone?: InputMaybe<SeerOrderBy>;
  readonly destEndTimezone?: InputMaybe<SeerOrderBy>;
  readonly ibRegion?: InputMaybe<SeerOrderBy>;
  readonly ibSubRegion?: InputMaybe<SeerOrderBy>;
  readonly routingGuideId?: InputMaybe<SeerOrderBy>;
  readonly tenderPlanType?: InputMaybe<SeerOrderBy>;
  readonly numberOfCarriers?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly routeRepId?: InputMaybe<SeerOrderBy>;
};

export type SeerMainPageRouteBoardPreTenderResult = {
  readonly __typename?: 'SeerMainPageRouteBoardPreTenderResult';
  readonly edges: ReadonlyArray<SeerMainPageRouteBoardPreTenderEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMainPageRouteBoardResult = {
  readonly __typename?: 'SeerMainPageRouteBoardResult';
  readonly edges: ReadonlyArray<SeerMainPageRouteBoardEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMainPageRouteBoardTender = {
  readonly __typename?: 'SeerMainPageRouteBoardTender';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puStartTimezone?: Maybe<Scalars['String']>;
  readonly puEndTimezone?: Maybe<Scalars['String']>;
  readonly obRegion?: Maybe<Scalars['String']>;
  readonly obSubRegion?: Maybe<Scalars['String']>;
  readonly dest?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly destStartDate?: Maybe<Scalars['DateTime']>;
  readonly destEndDate?: Maybe<Scalars['DateTime']>;
  readonly destStartTime?: Maybe<Scalars['Int']>;
  readonly destEndTime?: Maybe<Scalars['Int']>;
  readonly destStartTimezone?: Maybe<Scalars['String']>;
  readonly destEndTimezone?: Maybe<Scalars['String']>;
  readonly ibRegion?: Maybe<Scalars['String']>;
  readonly ibSubRegion?: Maybe<Scalars['String']>;
  readonly tenderPlanType: Scalars['String'];
  readonly carrierSequence?: Maybe<Scalars['String']>;
  readonly currentTender: Scalars['String'];
  readonly equip?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly customerRepId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeRepId: Scalars['String'];
};

export type SeerMainPageRouteBoardTenderEdge = {
  readonly __typename?: 'SeerMainPageRouteBoardTenderEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMainPageRouteBoardTender;
};

export type SeerMainPageRouteBoardTenderFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly destEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly destStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destStartTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destEndTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tenderPlanType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentTender?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerMainPageRouteBoardTenderOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly routeTransportMode?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimezone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimezone?: InputMaybe<SeerOrderBy>;
  readonly obRegion?: InputMaybe<SeerOrderBy>;
  readonly obSubRegion?: InputMaybe<SeerOrderBy>;
  readonly dest?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly destStartDate?: InputMaybe<SeerOrderBy>;
  readonly destEndDate?: InputMaybe<SeerOrderBy>;
  readonly destStartTime?: InputMaybe<SeerOrderBy>;
  readonly destEndTime?: InputMaybe<SeerOrderBy>;
  readonly destStartTimezone?: InputMaybe<SeerOrderBy>;
  readonly destEndTimezone?: InputMaybe<SeerOrderBy>;
  readonly ibRegion?: InputMaybe<SeerOrderBy>;
  readonly ibSubRegion?: InputMaybe<SeerOrderBy>;
  readonly tenderPlanType?: InputMaybe<SeerOrderBy>;
  readonly carrierSequence?: InputMaybe<SeerOrderBy>;
  readonly currentTender?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly routeRepId?: InputMaybe<SeerOrderBy>;
};

export type SeerMainPageRouteBoardTenderResult = {
  readonly __typename?: 'SeerMainPageRouteBoardTenderResult';
  readonly edges: ReadonlyArray<SeerMainPageRouteBoardTenderEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMainPageRouteBoardWaybillCte = {
  readonly __typename?: 'SeerMainPageRouteBoardWaybillCte';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly previousRouteId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly nextRouteId?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly originCountry?: Maybe<Scalars['String']>;
  readonly originStreet1?: Maybe<Scalars['String']>;
  readonly originStreet2?: Maybe<Scalars['String']>;
  readonly originPostalCode?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly dest?: Maybe<Scalars['String']>;
  readonly destCountry?: Maybe<Scalars['String']>;
  readonly destStreet1?: Maybe<Scalars['String']>;
  readonly destStreet2?: Maybe<Scalars['String']>;
  readonly destPostalCode?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly mTtime?: Maybe<Scalars['String']>;
  readonly mTzone?: Maybe<Scalars['String']>;
  readonly emptyLocation?: Maybe<Scalars['String']>;
  readonly emptyTimezone?: Maybe<Scalars['String']>;
  readonly emptyDate?: Maybe<Scalars['DateTime']>;
  readonly appt?: Maybe<Scalars['DateTime']>;
  readonly eta?: Maybe<Scalars['DateTime']>;
  readonly stop?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly nxtCallBkDtTm?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly apptId?: Maybe<Scalars['String']>;
  readonly rep?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly mainRepIds?: Maybe<Scalars['String']>;
  readonly expectedEmptyLatitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyLongitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly expectedEmptyCountry?: Maybe<Scalars['String']>;
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly customerReps?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly originStopType?: Maybe<Scalars['String']>;
  readonly destinationStopType?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly originStopId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly destinationStopId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly vendorId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly ibRegionUrn?: Maybe<Scalars['String']>;
  readonly obRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsubReionUrn?: Maybe<Scalars['String']>;
  readonly oBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsuperReionUrn?: Maybe<Scalars['String']>;
  readonly customerGroup?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly driver1Name?: Maybe<Scalars['String']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  readonly driver1PhoneNumber?: Maybe<Scalars['String']>;
  readonly driver2PhoneNumber?: Maybe<Scalars['String']>;
  readonly trackingStatus?: Maybe<Scalars['String']>;
  readonly tractorNumber?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly lastTrackingNotes?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly lockId?: Maybe<Scalars['String']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierArivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierArivalTimezone?: Maybe<Scalars['String']>;
  readonly firstStopCarrierDepartureDateTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly lastStopCarrierArivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastStopCarrierArivalTimezone?: Maybe<Scalars['String']>;
  readonly lastStopCarrierDepartureDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly nextLocationCity?: Maybe<Scalars['String']>;
  readonly nextLocationState?: Maybe<Scalars['String']>;
  readonly height?: Maybe<Scalars['Float']>;
  readonly width?: Maybe<Scalars['Float']>;
  readonly length?: Maybe<Scalars['Float']>;
  readonly currentStopId?: Maybe<Scalars['String']>;
  readonly currentStopNumber?: Maybe<Scalars['Int']>;
  readonly currentStopCarrierArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopCarrierArrivalTimezone?: Maybe<Scalars['String']>;
  readonly currentStopCarrierDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly nextApptStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptStartTime?: Maybe<Scalars['Int']>;
  readonly nextApptEndTime?: Maybe<Scalars['Int']>;
  readonly nextApptStartTimeZone?: Maybe<Scalars['String']>;
  readonly nextApptEndTimeZone?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly originloadStopSequence?: Maybe<Scalars['Int']>;
  readonly destinationloadStopSequence?: Maybe<Scalars['Int']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly waybillDateTime?: Maybe<Scalars['DateTime']>;
};

export type SeerMainPageRouteBoardWaybillCteEdge = {
  readonly __typename?: 'SeerMainPageRouteBoardWaybillCteEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMainPageRouteBoardWaybillCte;
};

export type SeerMainPageRouteBoardWaybillCteFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly previousRouteId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly nextRouteId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStreet1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStreet2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originPostalCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destStreet1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destStreet2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destPostalCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mTtime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mTzone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyLocation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly appt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly eta?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly stop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nxtCallBkDtTm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly apptId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainRepIds?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyLatitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyLongitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerReps?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly originStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly destinationStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly employeeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubReionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsuperRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsuperRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsuperReionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerGroup?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver1Name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver2Name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver1PhoneNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver2PhoneNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trackingStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tractorNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trailerNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastTrackingNotes?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly lockId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockEmail?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isLocked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly lockExpirationTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierArivalDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierArivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly firstStopCarrierDepartureDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastStopCarrierArivalDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly lastStopCarrierArivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastStopCarrierDepartureDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly lastStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly height?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly width?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly length?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly currentStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentStopNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly currentStopCarrierArrivalDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly currentStopCarrierArrivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentStopCarrierDepartureDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly currentStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly originloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destinationloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly waybillDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerMainPageRouteBoardWaybillCteOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly previousRouteId?: InputMaybe<SeerOrderBy>;
  readonly nextRouteId?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly originCountry?: InputMaybe<SeerOrderBy>;
  readonly originStreet1?: InputMaybe<SeerOrderBy>;
  readonly originStreet2?: InputMaybe<SeerOrderBy>;
  readonly originPostalCode?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly dest?: InputMaybe<SeerOrderBy>;
  readonly destCountry?: InputMaybe<SeerOrderBy>;
  readonly destStreet1?: InputMaybe<SeerOrderBy>;
  readonly destStreet2?: InputMaybe<SeerOrderBy>;
  readonly destPostalCode?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly mTtime?: InputMaybe<SeerOrderBy>;
  readonly mTzone?: InputMaybe<SeerOrderBy>;
  readonly emptyLocation?: InputMaybe<SeerOrderBy>;
  readonly emptyTimezone?: InputMaybe<SeerOrderBy>;
  readonly emptyDate?: InputMaybe<SeerOrderBy>;
  readonly appt?: InputMaybe<SeerOrderBy>;
  readonly eta?: InputMaybe<SeerOrderBy>;
  readonly stop?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly nxtCallBkDtTm?: InputMaybe<SeerOrderBy>;
  readonly apptId?: InputMaybe<SeerOrderBy>;
  readonly rep?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly mainRepIds?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLatitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLongitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCity?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyState?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCountry?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly customerReps?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly division?: InputMaybe<SeerOrderBy>;
  readonly originStopType?: InputMaybe<SeerOrderBy>;
  readonly destinationStopType?: InputMaybe<SeerOrderBy>;
  readonly originStopId?: InputMaybe<SeerOrderBy>;
  readonly destinationStopId?: InputMaybe<SeerOrderBy>;
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly employeeId?: InputMaybe<SeerOrderBy>;
  readonly ibRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly obRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsubReionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsuperRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsuperRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsuperReionUrn?: InputMaybe<SeerOrderBy>;
  readonly customerGroup?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly customerName?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly carrierName?: InputMaybe<SeerOrderBy>;
  readonly driver1Name?: InputMaybe<SeerOrderBy>;
  readonly driver2Name?: InputMaybe<SeerOrderBy>;
  readonly driver1PhoneNumber?: InputMaybe<SeerOrderBy>;
  readonly driver2PhoneNumber?: InputMaybe<SeerOrderBy>;
  readonly trackingStatus?: InputMaybe<SeerOrderBy>;
  readonly tractorNumber?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber?: InputMaybe<SeerOrderBy>;
  readonly lastTrackingNotes?: InputMaybe<SeerOrderBy>;
  readonly lockId?: InputMaybe<SeerOrderBy>;
  readonly lockEmail?: InputMaybe<SeerOrderBy>;
  readonly isLocked?: InputMaybe<SeerOrderBy>;
  readonly lockExpirationTime?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierArivalDateTime?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierArivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierDepartureDateTime?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierArivalDateTime?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierArivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierDepartureDateTime?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly nextLocationCity?: InputMaybe<SeerOrderBy>;
  readonly nextLocationState?: InputMaybe<SeerOrderBy>;
  readonly height?: InputMaybe<SeerOrderBy>;
  readonly width?: InputMaybe<SeerOrderBy>;
  readonly length?: InputMaybe<SeerOrderBy>;
  readonly currentStopId?: InputMaybe<SeerOrderBy>;
  readonly currentStopNumber?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierArrivalDatetime?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierArrivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierDepartureDatetime?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly originloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly destinationloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly routeTransportMode?: InputMaybe<SeerOrderBy>;
  readonly waybillDateTime?: InputMaybe<SeerOrderBy>;
};

export type SeerMainPageRouteBoardWaybillCteResult = {
  readonly __typename?: 'SeerMainPageRouteBoardWaybillCteResult';
  readonly edges: ReadonlyArray<SeerMainPageRouteBoardWaybillCteEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMainPageRouteBoardWaybillTest = {
  readonly __typename?: 'SeerMainPageRouteBoardWaybillTest';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly previousRouteId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly nextRouteId?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly originCountry?: Maybe<Scalars['String']>;
  readonly originStreet1?: Maybe<Scalars['String']>;
  readonly originStreet2?: Maybe<Scalars['String']>;
  readonly originPostalCode?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly dest?: Maybe<Scalars['String']>;
  readonly destCountry?: Maybe<Scalars['String']>;
  readonly destStreet1?: Maybe<Scalars['String']>;
  readonly destStreet2?: Maybe<Scalars['String']>;
  readonly destPostalCode?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly mTtime?: Maybe<Scalars['String']>;
  readonly mTzone?: Maybe<Scalars['String']>;
  readonly emptyLocation?: Maybe<Scalars['String']>;
  readonly emptyTimezone?: Maybe<Scalars['String']>;
  readonly emptyDate?: Maybe<Scalars['DateTime']>;
  readonly appt?: Maybe<Scalars['DateTime']>;
  readonly eta?: Maybe<Scalars['DateTime']>;
  readonly stop?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly nxtCallBkDtTm?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly apptId?: Maybe<Scalars['String']>;
  readonly rep?: Maybe<Scalars['String']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly mainRepIds?: Maybe<Scalars['String']>;
  readonly expectedEmptyLatitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyLongitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly expectedEmptyCountry?: Maybe<Scalars['String']>;
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly customerReps?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly originStopType?: Maybe<Scalars['String']>;
  readonly destinationStopType?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly originStopId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly destinationStopId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly vendorId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly ibRegionUrn?: Maybe<Scalars['String']>;
  readonly obRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsubReionUrn?: Maybe<Scalars['String']>;
  readonly oBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsuperReionUrn?: Maybe<Scalars['String']>;
  readonly customerGroup?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly customerName?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly driver1Name?: Maybe<Scalars['String']>;
  readonly driver2Name?: Maybe<Scalars['String']>;
  readonly driver1PhoneNumber?: Maybe<Scalars['String']>;
  readonly driver2PhoneNumber?: Maybe<Scalars['String']>;
  readonly trackingStatus?: Maybe<Scalars['String']>;
  readonly tractorNumber?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly lastTrackingNotes?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly lockId?: Maybe<Scalars['String']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierArivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierArivalTimezone?: Maybe<Scalars['String']>;
  readonly firstStopCarrierDepartureDateTime?: Maybe<Scalars['DateTime']>;
  readonly firstStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly lastStopCarrierArivalDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastStopCarrierArivalTimezone?: Maybe<Scalars['String']>;
  readonly lastStopCarrierDepartureDateTime?: Maybe<Scalars['DateTime']>;
  readonly lastStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly nextLocationCity?: Maybe<Scalars['String']>;
  readonly nextLocationState?: Maybe<Scalars['String']>;
  readonly height?: Maybe<Scalars['Float']>;
  readonly width?: Maybe<Scalars['Float']>;
  readonly length?: Maybe<Scalars['Float']>;
  readonly currentStopId?: Maybe<Scalars['String']>;
  readonly currentStopNumber?: Maybe<Scalars['Int']>;
  readonly currentStopCarrierArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopCarrierArrivalTimezone?: Maybe<Scalars['String']>;
  readonly currentStopCarrierDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly currentStopCarrierDepartureTimezone?: Maybe<Scalars['String']>;
  readonly nextApptStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptStartTime?: Maybe<Scalars['Int']>;
  readonly nextApptEndTime?: Maybe<Scalars['Int']>;
  readonly nextApptStartTimeZone?: Maybe<Scalars['String']>;
  readonly nextApptEndTimeZone?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly originloadStopSequence?: Maybe<Scalars['Int']>;
  readonly destinationloadStopSequence?: Maybe<Scalars['Int']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly waybillDateTime?: Maybe<Scalars['DateTime']>;
};

export type SeerMainPageRouteBoardWaybillTestEdge = {
  readonly __typename?: 'SeerMainPageRouteBoardWaybillTestEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMainPageRouteBoardWaybillTest;
};

export type SeerMainPageRouteBoardWaybillTestFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly previousRouteId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly nextRouteId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStreet1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStreet2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originPostalCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destStreet1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destStreet2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destPostalCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mTtime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mTzone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyLocation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly appt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly eta?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly stop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nxtCallBkDtTm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly apptId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mainRepIds?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyLatitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyLongitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerReps?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly originStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly destinationStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly employeeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubReionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsuperRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsuperRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsuperReionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerGroup?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver1Name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver2Name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver1PhoneNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver2PhoneNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trackingStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tractorNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trailerNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastTrackingNotes?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly lockId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockEmail?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly isLocked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly lockExpirationTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierArivalDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierArivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly firstStopCarrierDepartureDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly firstStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastStopCarrierArivalDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly lastStopCarrierArivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastStopCarrierDepartureDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly lastStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly height?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly width?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly length?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly currentStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentStopNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly currentStopCarrierArrivalDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly currentStopCarrierArrivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentStopCarrierDepartureDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly currentStopCarrierDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly originloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destinationloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeTransportMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly waybillDateTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerMainPageRouteBoardWaybillTestOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly previousRouteId?: InputMaybe<SeerOrderBy>;
  readonly nextRouteId?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly originCountry?: InputMaybe<SeerOrderBy>;
  readonly originStreet1?: InputMaybe<SeerOrderBy>;
  readonly originStreet2?: InputMaybe<SeerOrderBy>;
  readonly originPostalCode?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly dest?: InputMaybe<SeerOrderBy>;
  readonly destCountry?: InputMaybe<SeerOrderBy>;
  readonly destStreet1?: InputMaybe<SeerOrderBy>;
  readonly destStreet2?: InputMaybe<SeerOrderBy>;
  readonly destPostalCode?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly mTtime?: InputMaybe<SeerOrderBy>;
  readonly mTzone?: InputMaybe<SeerOrderBy>;
  readonly emptyLocation?: InputMaybe<SeerOrderBy>;
  readonly emptyTimezone?: InputMaybe<SeerOrderBy>;
  readonly emptyDate?: InputMaybe<SeerOrderBy>;
  readonly appt?: InputMaybe<SeerOrderBy>;
  readonly eta?: InputMaybe<SeerOrderBy>;
  readonly stop?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly nxtCallBkDtTm?: InputMaybe<SeerOrderBy>;
  readonly apptId?: InputMaybe<SeerOrderBy>;
  readonly rep?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly mainRepIds?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLatitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLongitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCity?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyState?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCountry?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly customerReps?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly division?: InputMaybe<SeerOrderBy>;
  readonly originStopType?: InputMaybe<SeerOrderBy>;
  readonly destinationStopType?: InputMaybe<SeerOrderBy>;
  readonly originStopId?: InputMaybe<SeerOrderBy>;
  readonly destinationStopId?: InputMaybe<SeerOrderBy>;
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly employeeId?: InputMaybe<SeerOrderBy>;
  readonly ibRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly obRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsubReionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsuperRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsuperRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsuperReionUrn?: InputMaybe<SeerOrderBy>;
  readonly customerGroup?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly customerName?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly carrierName?: InputMaybe<SeerOrderBy>;
  readonly driver1Name?: InputMaybe<SeerOrderBy>;
  readonly driver2Name?: InputMaybe<SeerOrderBy>;
  readonly driver1PhoneNumber?: InputMaybe<SeerOrderBy>;
  readonly driver2PhoneNumber?: InputMaybe<SeerOrderBy>;
  readonly trackingStatus?: InputMaybe<SeerOrderBy>;
  readonly tractorNumber?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber?: InputMaybe<SeerOrderBy>;
  readonly lastTrackingNotes?: InputMaybe<SeerOrderBy>;
  readonly lockId?: InputMaybe<SeerOrderBy>;
  readonly lockEmail?: InputMaybe<SeerOrderBy>;
  readonly isLocked?: InputMaybe<SeerOrderBy>;
  readonly lockExpirationTime?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierArivalDateTime?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierArivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierDepartureDateTime?: InputMaybe<SeerOrderBy>;
  readonly firstStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierArivalDateTime?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierArivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierDepartureDateTime?: InputMaybe<SeerOrderBy>;
  readonly lastStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly nextLocationCity?: InputMaybe<SeerOrderBy>;
  readonly nextLocationState?: InputMaybe<SeerOrderBy>;
  readonly height?: InputMaybe<SeerOrderBy>;
  readonly width?: InputMaybe<SeerOrderBy>;
  readonly length?: InputMaybe<SeerOrderBy>;
  readonly currentStopId?: InputMaybe<SeerOrderBy>;
  readonly currentStopNumber?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierArrivalDatetime?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierArrivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierDepartureDatetime?: InputMaybe<SeerOrderBy>;
  readonly currentStopCarrierDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly originloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly destinationloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly routeTransportMode?: InputMaybe<SeerOrderBy>;
  readonly waybillDateTime?: InputMaybe<SeerOrderBy>;
};

export type SeerMainPageRouteBoardWaybillTestResult = {
  readonly __typename?: 'SeerMainPageRouteBoardWaybillTestResult';
  readonly edges: ReadonlyArray<SeerMainPageRouteBoardWaybillTestEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMainPageTrackingBoard = {
  readonly __typename?: 'SeerMainPageTrackingBoard';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly status?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly equip?: Maybe<Scalars['String']>;
  readonly rlc?: Maybe<Scalars['String']>;
  readonly originDate?: Maybe<Scalars['DateTime']>;
  readonly originTime?: Maybe<Scalars['Int']>;
  readonly destDate?: Maybe<Scalars['DateTime']>;
  readonly destTime?: Maybe<Scalars['Int']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly destination?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly nxtCallBkDtTm?: Maybe<Scalars['DateTime']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverNumber?: Maybe<Scalars['String']>;
  readonly lastTrackingNote?: Maybe<Scalars['String']>;
  readonly createDate?: Maybe<Scalars['DateTime']>;
  readonly rep?: Maybe<Scalars['String']>;
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly customerReps?: Maybe<Scalars['String']>;
  readonly routeRepId?: Maybe<Scalars['String']>;
  readonly expectedEmptyLatitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyLongitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly expectedEmptyCountry?: Maybe<Scalars['String']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly ras?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly originStopType?: Maybe<Scalars['String']>;
  readonly destinationStopType?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly originStopId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly destinationStopId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly vendorId?: Maybe<Scalars['String']>;
  readonly appt?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly ibRegionUrn?: Maybe<Scalars['String']>;
  readonly obRegionUrn?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly orderRepId?: Maybe<Scalars['String']>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly createdByName?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly lockId?: Maybe<Scalars['String']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly carrierArrivalDate?: Maybe<Scalars['DateTime']>;
  readonly carrierArrivalTimeZone?: Maybe<Scalars['String']>;
  readonly emptyLocation?: Maybe<Scalars['String']>;
  readonly emptyDate?: Maybe<Scalars['DateTime']>;
  readonly actualDepartureDate?: Maybe<Scalars['DateTime']>;
  readonly actualDepartureTimezone?: Maybe<Scalars['String']>;
  readonly nextLocationCity?: Maybe<Scalars['String']>;
  readonly nextLocationState?: Maybe<Scalars['String']>;
  readonly infoFrom?: Maybe<Scalars['String']>;
  readonly trackingStatus?: Maybe<Scalars['String']>;
  readonly latestElectronicLocationUpdateDatetime?: Maybe<Scalars['DateTime']>;
  readonly latestTrackingRequest?: Maybe<Scalars['DateTime']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly originloadStopSequence?: Maybe<Scalars['Int']>;
  readonly destinationloadStopSequence?: Maybe<Scalars['Int']>;
  /** Column is of type UUID */
  readonly customerId?: Maybe<Scalars['String']>;
  readonly autoEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly manualEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly driverElectronicTrackingTypeId?: Maybe<Scalars['String']>;
  readonly nextApptStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptStartTime?: Maybe<Scalars['Int']>;
  readonly nextApptEndTime?: Maybe<Scalars['Int']>;
  readonly nextApptStartTimeZone?: Maybe<Scalars['String']>;
  readonly nextApptEndTimeZone?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly bookedById?: Maybe<Scalars['String']>;
  readonly bookedByName?: Maybe<Scalars['String']>;
};

export type SeerMainPageTrackingBoardEdge = {
  readonly __typename?: 'SeerMainPageTrackingBoardEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMainPageTrackingBoard;
};

export type SeerMainPageTrackingBoardFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly status?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly equip?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly originTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly destTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destination?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nxtCallBkDtTm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastTrackingNote?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly rep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerReps?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyLatitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyLongitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ras?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationStopType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly originStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly destinationStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly appt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly employeeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdByName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly lockId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockEmail?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockExpirationTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly isLocked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly carrierArrivalDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly carrierArrivalTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyLocation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly emptyDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly actualDepartureDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly actualDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly infoFrom?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trackingStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly latestTrackingRequest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly originloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destinationloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly customerId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly autoEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly manualEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly bookedById?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly bookedByName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerMainPageTrackingBoardOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly status?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly equip?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly originDate?: InputMaybe<SeerOrderBy>;
  readonly originTime?: InputMaybe<SeerOrderBy>;
  readonly destDate?: InputMaybe<SeerOrderBy>;
  readonly destTime?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly destination?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly nxtCallBkDtTm?: InputMaybe<SeerOrderBy>;
  readonly driverName?: InputMaybe<SeerOrderBy>;
  readonly driverNumber?: InputMaybe<SeerOrderBy>;
  readonly lastTrackingNote?: InputMaybe<SeerOrderBy>;
  readonly createDate?: InputMaybe<SeerOrderBy>;
  readonly rep?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly customerReps?: InputMaybe<SeerOrderBy>;
  readonly routeRepId?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLatitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLongitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCity?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyState?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCountry?: InputMaybe<SeerOrderBy>;
  readonly mode?: InputMaybe<SeerOrderBy>;
  readonly ras?: InputMaybe<SeerOrderBy>;
  readonly division?: InputMaybe<SeerOrderBy>;
  readonly originStopType?: InputMaybe<SeerOrderBy>;
  readonly destinationStopType?: InputMaybe<SeerOrderBy>;
  readonly originStopId?: InputMaybe<SeerOrderBy>;
  readonly destinationStopId?: InputMaybe<SeerOrderBy>;
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly appt?: InputMaybe<SeerOrderBy>;
  readonly employeeId?: InputMaybe<SeerOrderBy>;
  readonly ibRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly obRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly orderRepId?: InputMaybe<SeerOrderBy>;
  readonly orderActivationStatus?: InputMaybe<SeerOrderBy>;
  readonly createdByName?: InputMaybe<SeerOrderBy>;
  readonly lockId?: InputMaybe<SeerOrderBy>;
  readonly lockEmail?: InputMaybe<SeerOrderBy>;
  readonly lockExpirationTime?: InputMaybe<SeerOrderBy>;
  readonly isLocked?: InputMaybe<SeerOrderBy>;
  readonly carrierArrivalDate?: InputMaybe<SeerOrderBy>;
  readonly carrierArrivalTimeZone?: InputMaybe<SeerOrderBy>;
  readonly emptyLocation?: InputMaybe<SeerOrderBy>;
  readonly emptyDate?: InputMaybe<SeerOrderBy>;
  readonly actualDepartureDate?: InputMaybe<SeerOrderBy>;
  readonly actualDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly nextLocationCity?: InputMaybe<SeerOrderBy>;
  readonly nextLocationState?: InputMaybe<SeerOrderBy>;
  readonly infoFrom?: InputMaybe<SeerOrderBy>;
  readonly trackingStatus?: InputMaybe<SeerOrderBy>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<SeerOrderBy>;
  readonly latestTrackingRequest?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly originloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly destinationloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly customerId?: InputMaybe<SeerOrderBy>;
  readonly autoEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly manualEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly routeType?: InputMaybe<SeerOrderBy>;
  readonly bookedById?: InputMaybe<SeerOrderBy>;
  readonly bookedByName?: InputMaybe<SeerOrderBy>;
};

export type SeerMainPageTrackingBoardResult = {
  readonly __typename?: 'SeerMainPageTrackingBoardResult';
  readonly edges: ReadonlyArray<SeerMainPageTrackingBoardEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerMasterFindLoadSearch = {
  readonly __typename?: 'SeerMasterFindLoadSearch';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly orderNumber?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly refNumber?: Maybe<Scalars['String']>;
};

export type SeerMasterFindLoadSearchEdge = {
  readonly __typename?: 'SeerMasterFindLoadSearchEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerMasterFindLoadSearch;
};

export type SeerMasterFindLoadSearchFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destinationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly refNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerMasterFindLoadSearchOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadCode?: InputMaybe<SeerOrderBy>;
  readonly routeCode?: InputMaybe<SeerOrderBy>;
  readonly orderNumber?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly originCity?: InputMaybe<SeerOrderBy>;
  readonly originState?: InputMaybe<SeerOrderBy>;
  readonly destinationCity?: InputMaybe<SeerOrderBy>;
  readonly destinationState?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly refNumber?: InputMaybe<SeerOrderBy>;
};

export type SeerMasterFindLoadSearchResult = {
  readonly __typename?: 'SeerMasterFindLoadSearchResult';
  readonly edges: ReadonlyArray<SeerMasterFindLoadSearchEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerOrderBy = {
  readonly order: SeerGenericOrder;
  readonly position: Scalars['Int'];
};

export type SeerOrderReference = {
  readonly __typename?: 'SeerOrderReference';
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId: Scalars['String'];
  readonly type?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly routeId?: Maybe<Scalars['String']>;
  readonly routeStopId?: Maybe<Scalars['String']>;
  readonly orderStopId?: Maybe<Scalars['String']>;
  readonly ediQualifier?: Maybe<Scalars['String']>;
};

export type SeerOrderReferenceEdge = {
  readonly __typename?: 'SeerOrderReferenceEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerOrderReference;
};

export type SeerOrderReferenceFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly type?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly value?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ediQualifier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerOrderReferenceOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly type?: InputMaybe<SeerOrderBy>;
  readonly value?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly routeStopId?: InputMaybe<SeerOrderBy>;
  readonly orderStopId?: InputMaybe<SeerOrderBy>;
  readonly ediQualifier?: InputMaybe<SeerOrderBy>;
};

export type SeerOrderReferenceResult = {
  readonly __typename?: 'SeerOrderReferenceResult';
  readonly edges: ReadonlyArray<SeerOrderReferenceEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerOrderRep = {
  readonly __typename?: 'SeerOrderRep';
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly orderId: Scalars['String'];
  readonly name?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly main?: Maybe<Scalars['Boolean']>;
};

export type SeerOrderRepEdge = {
  readonly __typename?: 'SeerOrderRepEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerOrderRep;
};

export type SeerOrderRepFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly type?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly employeeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly main?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
};

export type SeerOrderRepOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly name?: InputMaybe<SeerOrderBy>;
  readonly type?: InputMaybe<SeerOrderBy>;
  readonly employeeId?: InputMaybe<SeerOrderBy>;
  readonly main?: InputMaybe<SeerOrderBy>;
};

export type SeerOrderRepResult = {
  readonly __typename?: 'SeerOrderRepResult';
  readonly edges: ReadonlyArray<SeerOrderRepEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerOrderStatus = {
  readonly __typename?: 'SeerOrderStatus';
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId: Scalars['String'];
  readonly createdAtTimestampDatetime?: Maybe<Scalars['DateTime']>;
  readonly createdAtTimestamp: Scalars['Int'];
  readonly eventTimestampDatetime?: Maybe<Scalars['DateTime']>;
  readonly eventTimestamp: Scalars['Int'];
  readonly orderLifecycleStatus?: Maybe<Scalars['String']>;
  readonly orderTenderStatus?: Maybe<Scalars['String']>;
  readonly orderFinancialStatus?: Maybe<Scalars['String']>;
  readonly orderActivationStatus?: Maybe<Scalars['String']>;
  readonly orderActivationStatusReasons?: Maybe<Scalars['String']>;
  readonly orderFinancialStatusReasons?: Maybe<Scalars['String']>;
  readonly eid: Scalars['Int'];
};

export type SeerOrderStatusEdge = {
  readonly __typename?: 'SeerOrderStatusEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerOrderStatus;
};

export type SeerOrderStatusFilter = {
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdAtTimestampDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly createdAtTimestamp?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly eventTimestampDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly eventTimestamp?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly orderLifecycleStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderTenderStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderFinancialStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderActivationStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderActivationStatusReasons?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly orderFinancialStatusReasons?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
};

export type SeerOrderStatusOrderBy = {
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly createdAtTimestampDatetime?: InputMaybe<SeerOrderBy>;
  readonly createdAtTimestamp?: InputMaybe<SeerOrderBy>;
  readonly eventTimestampDatetime?: InputMaybe<SeerOrderBy>;
  readonly eventTimestamp?: InputMaybe<SeerOrderBy>;
  readonly orderLifecycleStatus?: InputMaybe<SeerOrderBy>;
  readonly orderTenderStatus?: InputMaybe<SeerOrderBy>;
  readonly orderFinancialStatus?: InputMaybe<SeerOrderBy>;
  readonly orderActivationStatus?: InputMaybe<SeerOrderBy>;
  readonly orderActivationStatusReasons?: InputMaybe<SeerOrderBy>;
  readonly orderFinancialStatusReasons?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
};

export type SeerOrderStatusResult = {
  readonly __typename?: 'SeerOrderStatusResult';
  readonly edges: ReadonlyArray<SeerOrderStatusEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerRoute = {
  readonly __typename?: 'SeerRoute';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly loadId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly activationStatus?: Maybe<Scalars['String']>;
  readonly tenderStatus?: Maybe<Scalars['String']>;
  readonly lifecycleStatus?: Maybe<Scalars['String']>;
  readonly totalDistance?: Maybe<Scalars['Float']>;
  readonly totalDistanceUnit?: Maybe<Scalars['String']>;
  readonly stopCount?: Maybe<Scalars['Int']>;
  readonly sequence?: Maybe<Scalars['Int']>;
  readonly routeType?: Maybe<Scalars['String']>;
  readonly transportMode?: Maybe<Scalars['String']>;
  readonly reqArriveDate?: Maybe<Scalars['DateTime']>;
  readonly timezone?: Maybe<Scalars['String']>;
};

export type SeerRouteEdge = {
  readonly __typename?: 'SeerRouteEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerRoute;
};

export type SeerRouteFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly code?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly activationStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tenderStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lifecycleStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly totalDistance?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly totalDistanceUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stopCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly sequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly transportMode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly reqArriveDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly timezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerRouteOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly code?: InputMaybe<SeerOrderBy>;
  readonly activationStatus?: InputMaybe<SeerOrderBy>;
  readonly tenderStatus?: InputMaybe<SeerOrderBy>;
  readonly lifecycleStatus?: InputMaybe<SeerOrderBy>;
  readonly totalDistance?: InputMaybe<SeerOrderBy>;
  readonly totalDistanceUnit?: InputMaybe<SeerOrderBy>;
  readonly stopCount?: InputMaybe<SeerOrderBy>;
  readonly sequence?: InputMaybe<SeerOrderBy>;
  readonly routeType?: InputMaybe<SeerOrderBy>;
  readonly transportMode?: InputMaybe<SeerOrderBy>;
  readonly reqArriveDate?: InputMaybe<SeerOrderBy>;
  readonly timezone?: InputMaybe<SeerOrderBy>;
};

export type SeerRouteResult = {
  readonly __typename?: 'SeerRouteResult';
  readonly edges: ReadonlyArray<SeerRouteEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerRouteStatus = {
  readonly __typename?: 'SeerRouteStatus';
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly createdAtTimestampDatetime?: Maybe<Scalars['DateTime']>;
  readonly createdAtTimestamp: Scalars['Int'];
  readonly eventTimestampDatetime?: Maybe<Scalars['DateTime']>;
  readonly eventTimestamp: Scalars['Int'];
  readonly initiator?: Maybe<Scalars['String']>;
  readonly routeLifecycleStatus?: Maybe<Scalars['String']>;
  readonly routeTenderStatus?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly eid: Scalars['Int'];
};

export type SeerRouteStatusEdge = {
  readonly __typename?: 'SeerRouteStatusEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerRouteStatus;
};

export type SeerRouteStatusFilter = {
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdAtTimestampDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly createdAtTimestamp?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly eventTimestampDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly eventTimestamp?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly initiator?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeLifecycleStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeTenderStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
};

export type SeerRouteStatusOrderBy = {
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly createdAtTimestampDatetime?: InputMaybe<SeerOrderBy>;
  readonly createdAtTimestamp?: InputMaybe<SeerOrderBy>;
  readonly eventTimestampDatetime?: InputMaybe<SeerOrderBy>;
  readonly eventTimestamp?: InputMaybe<SeerOrderBy>;
  readonly initiator?: InputMaybe<SeerOrderBy>;
  readonly routeLifecycleStatus?: InputMaybe<SeerOrderBy>;
  readonly routeTenderStatus?: InputMaybe<SeerOrderBy>;
  readonly routeActivationStatus?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
};

export type SeerRouteStatusResult = {
  readonly __typename?: 'SeerRouteStatusResult';
  readonly edges: ReadonlyArray<SeerRouteStatusEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerRouteVendor = {
  readonly __typename?: 'SeerRouteVendor';
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly vendorId: Scalars['String'];
  readonly vendorName: Scalars['String'];
  readonly vendorType: Scalars['String'];
  readonly activeFlag: Scalars['Boolean'];
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly vendorRepId: Scalars['String'];
  readonly vendorRepName?: Maybe<Scalars['String']>;
  readonly vendorRepEmail?: Maybe<Scalars['String']>;
  readonly vendorRepIsMain?: Maybe<Scalars['Boolean']>;
  readonly expectedEmptySource?: Maybe<Scalars['String']>;
  readonly expectedEmptySourceId?: Maybe<Scalars['String']>;
  readonly expectedEmptyLatitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyLongitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly expectedEmptyCountry?: Maybe<Scalars['String']>;
  readonly expectedEmptyDatetime?: Maybe<Scalars['String']>;
  readonly vendorRepEmployeeGroup?: Maybe<Scalars['String']>;
  readonly expectedEmptyTimezone?: Maybe<Scalars['String']>;
  readonly expectedEmptyDatetimeDate?: Maybe<Scalars['DateTime']>;
  /** Column is of type UUID */
  readonly routeVendorId: Scalars['String'];
  /** Column is of type UUID */
  readonly bookedById?: Maybe<Scalars['String']>;
  readonly bookedByName?: Maybe<Scalars['String']>;
};

export type SeerRouteVendorEdge = {
  readonly __typename?: 'SeerRouteVendorEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerRouteVendor;
};

export type SeerRouteVendorFilter = {
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly vendorName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly vendorType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly activeFlag?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly vendorRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly vendorRepName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly vendorRepEmail?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly vendorRepIsMain?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly expectedEmptySource?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptySourceId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyLatitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyLongitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly vendorRepEmployeeGroup?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyDatetimeDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  /** Column is of type UUID */
  readonly routeVendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly bookedById?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly bookedByName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerRouteVendorOrderBy = {
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly vendorName?: InputMaybe<SeerOrderBy>;
  readonly vendorType?: InputMaybe<SeerOrderBy>;
  readonly activeFlag?: InputMaybe<SeerOrderBy>;
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly vendorRepId?: InputMaybe<SeerOrderBy>;
  readonly vendorRepName?: InputMaybe<SeerOrderBy>;
  readonly vendorRepEmail?: InputMaybe<SeerOrderBy>;
  readonly vendorRepIsMain?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptySource?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptySourceId?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLatitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLongitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCity?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyState?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCountry?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyDatetime?: InputMaybe<SeerOrderBy>;
  readonly vendorRepEmployeeGroup?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyTimezone?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyDatetimeDate?: InputMaybe<SeerOrderBy>;
  readonly routeVendorId?: InputMaybe<SeerOrderBy>;
  readonly bookedById?: InputMaybe<SeerOrderBy>;
  readonly bookedByName?: InputMaybe<SeerOrderBy>;
};

export type SeerRouteVendorReference = {
  readonly __typename?: 'SeerRouteVendorReference';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly value: Scalars['String'];
  readonly ediQualifier?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeVendorId: Scalars['String'];
  readonly routeVendorReferenceType: Scalars['String'];
  readonly eid: Scalars['Int'];
  /** Column is of type UUID */
  readonly routeVendorRouteId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeVendorVendorId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeVendorVendorRepEmployeeId?: Maybe<Scalars['String']>;
};

export type SeerRouteVendorReferenceEdge = {
  readonly __typename?: 'SeerRouteVendorReferenceEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerRouteVendorReference;
};

export type SeerRouteVendorReferenceFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly value?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ediQualifier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeVendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeVendorReferenceType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly routeVendorRouteId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeVendorVendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeVendorVendorRepEmployeeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerRouteVendorReferenceOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly value?: InputMaybe<SeerOrderBy>;
  readonly ediQualifier?: InputMaybe<SeerOrderBy>;
  readonly routeVendorId?: InputMaybe<SeerOrderBy>;
  readonly routeVendorReferenceType?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
  readonly routeVendorRouteId?: InputMaybe<SeerOrderBy>;
  readonly routeVendorVendorId?: InputMaybe<SeerOrderBy>;
  readonly routeVendorVendorRepEmployeeId?: InputMaybe<SeerOrderBy>;
};

export type SeerRouteVendorReferenceResult = {
  readonly __typename?: 'SeerRouteVendorReferenceResult';
  readonly edges: ReadonlyArray<SeerRouteVendorReferenceEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerRouteVendorResult = {
  readonly __typename?: 'SeerRouteVendorResult';
  readonly edges: ReadonlyArray<SeerRouteVendorEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerRouteVendorStatus = {
  readonly __typename?: 'SeerRouteVendorStatus';
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly vendorId: Scalars['String'];
  readonly vendorFinancialStatusReasons?: Maybe<Scalars['String']>;
  readonly vendorFinancialStatus?: Maybe<Scalars['String']>;
  readonly eid: Scalars['Int'];
};

export type SeerRouteVendorStatusEdge = {
  readonly __typename?: 'SeerRouteVendorStatusEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerRouteVendorStatus;
};

export type SeerRouteVendorStatusFilter = {
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly vendorId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly vendorFinancialStatusReasons?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly vendorFinancialStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
};

export type SeerRouteVendorStatusOrderBy = {
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly vendorId?: InputMaybe<SeerOrderBy>;
  readonly vendorFinancialStatusReasons?: InputMaybe<SeerOrderBy>;
  readonly vendorFinancialStatus?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
};

export type SeerRouteVendorStatusResult = {
  readonly __typename?: 'SeerRouteVendorStatusResult';
  readonly edges: ReadonlyArray<SeerRouteVendorStatusEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerStop = {
  readonly __typename?: 'SeerStop';
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly stopId: Scalars['String'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  /** Column is of type UUID */
  readonly orderId: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly type: Scalars['String'];
  /** Column is of type UUID */
  readonly facilityId?: Maybe<Scalars['String']>;
  readonly facilityName?: Maybe<Scalars['String']>;
  readonly street1?: Maybe<Scalars['String']>;
  readonly street2?: Maybe<Scalars['String']>;
  readonly locality?: Maybe<Scalars['String']>;
  readonly administrativeArea1Code?: Maybe<Scalars['String']>;
  readonly postalCode?: Maybe<Scalars['String']>;
  readonly countryAlpha2Code?: Maybe<Scalars['String']>;
  readonly ccaAvailableStartDate?: Maybe<Scalars['DateTime']>;
  readonly ccaAvailableStartTimezone?: Maybe<Scalars['String']>;
  readonly ccaAvailableStartTimeStartIn?: Maybe<Scalars['Int']>;
  readonly ccaAvailableStartTimeEndIn?: Maybe<Scalars['Int']>;
  readonly ccaAvailableEndDate?: Maybe<Scalars['DateTime']>;
  readonly ccaAvailableEndTimezone?: Maybe<Scalars['String']>;
  readonly ccaAvailableEndTimeStartIn?: Maybe<Scalars['Int']>;
  readonly ccaAvailableEndTimeEndIn?: Maybe<Scalars['Int']>;
  readonly ccaApptReqWith?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly ccaApptConfBy?: Maybe<Scalars['String']>;
  readonly ccaStatus?: Maybe<Scalars['String']>;
  readonly subRegionUrn?: Maybe<Scalars['String']>;
  readonly regionUrn?: Maybe<Scalars['String']>;
  readonly superRegionUrn?: Maybe<Scalars['String']>;
  readonly sequence?: Maybe<Scalars['Int']>;
  readonly isFirstStop?: Maybe<Scalars['Boolean']>;
  readonly isLastStop?: Maybe<Scalars['Boolean']>;
  readonly geocode?: Maybe<SeerGeoPoint>;
  readonly freightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly freightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly freightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly loadStopSequence?: Maybe<Scalars['Int']>;
  readonly isFirstLoadStop?: Maybe<Scalars['Boolean']>;
  readonly isLastLoadStop?: Maybe<Scalars['Boolean']>;
  readonly liveType?: Maybe<Scalars['String']>;
  readonly scheduleType?: Maybe<Scalars['String']>;
  readonly requestedShipDate?: Maybe<Scalars['DateTime']>;
  readonly timezone?: Maybe<Scalars['String']>;
};

export type SeerStopEdge = {
  readonly __typename?: 'SeerStopEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerStop;
};

export type SeerStopEventsAggregations = {
  readonly __typename?: 'SeerStopEventsAggregations';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly stopId: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly loadId: Scalars['String'];
  readonly driverArrivalDatetime?: Maybe<Scalars['DateTime']>;
  readonly driverArrivalTimezone?: Maybe<Scalars['String']>;
  readonly driverDepartureDatetime?: Maybe<Scalars['DateTime']>;
  readonly driverDepartureTimezone?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByTxId: Scalars['String'];
  readonly updatedByTxId: Scalars['String'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly eid: Scalars['Int'];
};

export type SeerStopEventsAggregationsEdge = {
  readonly __typename?: 'SeerStopEventsAggregationsEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerStopEventsAggregations;
};

export type SeerStopEventsAggregationsFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly stopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverArrivalDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverArrivalTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverDepartureDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverDepartureTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly updatedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly createdByTxId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedByTxId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
};

export type SeerStopEventsAggregationsOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly stopId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly driverArrivalDatetime?: InputMaybe<SeerOrderBy>;
  readonly driverArrivalTimezone?: InputMaybe<SeerOrderBy>;
  readonly driverDepartureDatetime?: InputMaybe<SeerOrderBy>;
  readonly driverDepartureTimezone?: InputMaybe<SeerOrderBy>;
  readonly createdAt?: InputMaybe<SeerOrderBy>;
  readonly updatedAt?: InputMaybe<SeerOrderBy>;
  readonly createdByTxId?: InputMaybe<SeerOrderBy>;
  readonly updatedByTxId?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly updatedBy?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
};

export type SeerStopEventsAggregationsResult = {
  readonly __typename?: 'SeerStopEventsAggregationsResult';
  readonly edges: ReadonlyArray<SeerStopEventsAggregationsEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerStopFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly stopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly type?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly facilityId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly facilityName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly street1?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly street2?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly locality?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly administrativeArea1Code?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly postalCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly countryAlpha2Code?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ccaAvailableStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly ccaAvailableStartTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ccaAvailableStartTimeStartIn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly ccaAvailableStartTimeEndIn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly ccaAvailableEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly ccaAvailableEndTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ccaAvailableEndTimeStartIn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly ccaAvailableEndTimeEndIn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly ccaApptReqWith?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly ccaApptConfBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ccaStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly subRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly regionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly superRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly sequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly isFirstStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly isLastStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly geocode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDistanceFilter>>>;
  readonly freightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly freightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly freightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly loadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly isFirstLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly isLastLoadStop?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly liveType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly scheduleType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly requestedShipDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly timezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerStopOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly stopId?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly type?: InputMaybe<SeerOrderBy>;
  readonly facilityId?: InputMaybe<SeerOrderBy>;
  readonly facilityName?: InputMaybe<SeerOrderBy>;
  readonly street1?: InputMaybe<SeerOrderBy>;
  readonly street2?: InputMaybe<SeerOrderBy>;
  readonly locality?: InputMaybe<SeerOrderBy>;
  readonly administrativeArea1Code?: InputMaybe<SeerOrderBy>;
  readonly postalCode?: InputMaybe<SeerOrderBy>;
  readonly countryAlpha2Code?: InputMaybe<SeerOrderBy>;
  readonly ccaAvailableStartDate?: InputMaybe<SeerOrderBy>;
  readonly ccaAvailableStartTimezone?: InputMaybe<SeerOrderBy>;
  readonly ccaAvailableStartTimeStartIn?: InputMaybe<SeerOrderBy>;
  readonly ccaAvailableStartTimeEndIn?: InputMaybe<SeerOrderBy>;
  readonly ccaAvailableEndDate?: InputMaybe<SeerOrderBy>;
  readonly ccaAvailableEndTimezone?: InputMaybe<SeerOrderBy>;
  readonly ccaAvailableEndTimeStartIn?: InputMaybe<SeerOrderBy>;
  readonly ccaAvailableEndTimeEndIn?: InputMaybe<SeerOrderBy>;
  readonly ccaApptReqWith?: InputMaybe<SeerOrderBy>;
  readonly ccaApptConfBy?: InputMaybe<SeerOrderBy>;
  readonly ccaStatus?: InputMaybe<SeerOrderBy>;
  readonly subRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly regionUrn?: InputMaybe<SeerOrderBy>;
  readonly superRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly sequence?: InputMaybe<SeerOrderBy>;
  readonly isFirstStop?: InputMaybe<SeerOrderBy>;
  readonly isLastStop?: InputMaybe<SeerOrderBy>;
  readonly freightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly freightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly freightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly loadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly isFirstLoadStop?: InputMaybe<SeerOrderBy>;
  readonly isLastLoadStop?: InputMaybe<SeerOrderBy>;
  readonly liveType?: InputMaybe<SeerOrderBy>;
  readonly scheduleType?: InputMaybe<SeerOrderBy>;
  readonly requestedShipDate?: InputMaybe<SeerOrderBy>;
  readonly timezone?: InputMaybe<SeerOrderBy>;
};

export type SeerStopResult = {
  readonly __typename?: 'SeerStopResult';
  readonly edges: ReadonlyArray<SeerStopEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTender = {
  readonly __typename?: 'SeerTender';
  /** Column is of type UUID */
  readonly tenderId: Scalars['String'];
  /** Column is of type UUID */
  readonly tenderPlanId: Scalars['String'];
  /** Column is of type UUID */
  readonly carrierId: Scalars['String'];
  readonly leadTimeValue: Scalars['Float'];
  readonly leadTimeUnit: Scalars['String'];
  readonly expirationTimeValue: Scalars['Float'];
  readonly expirationTimeUnit: Scalars['String'];
  readonly ordinal: Scalars['Int'];
  /** Column is of type UUID */
  readonly tenderStatusId: Scalars['String'];
  readonly carrierCost: Scalars['Float'];
  readonly tenderedAt?: Maybe<Scalars['DateTime']>;
};

export type SeerTenderEdge = {
  readonly __typename?: 'SeerTenderEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTender;
};

export type SeerTenderFilter = {
  /** Column is of type UUID */
  readonly tenderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly tenderPlanId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly leadTimeValue?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly leadTimeUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expirationTimeValue?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expirationTimeUnit?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ordinal?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly tenderStatusId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierCost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly tenderedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerTenderOrderBy = {
  readonly tenderId?: InputMaybe<SeerOrderBy>;
  readonly tenderPlanId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly leadTimeValue?: InputMaybe<SeerOrderBy>;
  readonly leadTimeUnit?: InputMaybe<SeerOrderBy>;
  readonly expirationTimeValue?: InputMaybe<SeerOrderBy>;
  readonly expirationTimeUnit?: InputMaybe<SeerOrderBy>;
  readonly ordinal?: InputMaybe<SeerOrderBy>;
  readonly tenderStatusId?: InputMaybe<SeerOrderBy>;
  readonly carrierCost?: InputMaybe<SeerOrderBy>;
  readonly tenderedAt?: InputMaybe<SeerOrderBy>;
};

export type SeerTenderPlan = {
  readonly __typename?: 'SeerTenderPlan';
  /** Column is of type UUID */
  readonly tenderPlanId: Scalars['String'];
  /** Column is of type UUID */
  readonly routingGuideId: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly tenderPlanStatusId: Scalars['String'];
  readonly tenderPlanTypeTerm: Scalars['String'];
};

export type SeerTenderPlanEdge = {
  readonly __typename?: 'SeerTenderPlanEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTenderPlan;
};

export type SeerTenderPlanFilter = {
  /** Column is of type UUID */
  readonly tenderPlanId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routingGuideId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly tenderPlanStatusId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tenderPlanTypeTerm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerTenderPlanOrderBy = {
  readonly tenderPlanId?: InputMaybe<SeerOrderBy>;
  readonly routingGuideId?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly tenderPlanStatusId?: InputMaybe<SeerOrderBy>;
  readonly tenderPlanTypeTerm?: InputMaybe<SeerOrderBy>;
};

export type SeerTenderPlanResult = {
  readonly __typename?: 'SeerTenderPlanResult';
  readonly edges: ReadonlyArray<SeerTenderPlanEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTenderPlanStatus = {
  readonly __typename?: 'SeerTenderPlanStatus';
  /** Column is of type UUID */
  readonly tenderPlanStatusId: Scalars['String'];
  readonly name: Scalars['String'];
};

export type SeerTenderPlanStatusEdge = {
  readonly __typename?: 'SeerTenderPlanStatusEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTenderPlanStatus;
};

export type SeerTenderPlanStatusFilter = {
  /** Column is of type UUID */
  readonly tenderPlanStatusId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerTenderPlanStatusOrderBy = {
  readonly tenderPlanStatusId?: InputMaybe<SeerOrderBy>;
  readonly name?: InputMaybe<SeerOrderBy>;
};

export type SeerTenderPlanStatusResult = {
  readonly __typename?: 'SeerTenderPlanStatusResult';
  readonly edges: ReadonlyArray<SeerTenderPlanStatusEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTenderResult = {
  readonly __typename?: 'SeerTenderResult';
  readonly edges: ReadonlyArray<SeerTenderEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTenderStatus = {
  readonly __typename?: 'SeerTenderStatus';
  /** Column is of type UUID */
  readonly tenderStatusId: Scalars['String'];
  readonly name: Scalars['String'];
};

export type SeerTenderStatusEdge = {
  readonly __typename?: 'SeerTenderStatusEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTenderStatus;
};

export type SeerTenderStatusFilter = {
  /** Column is of type UUID */
  readonly tenderStatusId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerTenderStatusOrderBy = {
  readonly tenderStatusId?: InputMaybe<SeerOrderBy>;
  readonly name?: InputMaybe<SeerOrderBy>;
};

export type SeerTenderStatusResult = {
  readonly __typename?: 'SeerTenderStatusResult';
  readonly edges: ReadonlyArray<SeerTenderStatusEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTrackingAggregations = {
  readonly __typename?: 'SeerTrackingAggregations';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly driverAssignmentId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly updateId?: Maybe<Scalars['String']>;
  readonly driver1DisplayName?: Maybe<Scalars['String']>;
  readonly driver1PhoneNumber?: Maybe<Scalars['String']>;
  readonly driver2DisplayName?: Maybe<Scalars['String']>;
  readonly driver2PhoneNumber?: Maybe<Scalars['String']>;
  readonly lastTrackingNote?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly previousRouteId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly nextRouteId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly carrierId?: Maybe<Scalars['String']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly dispatched?: Maybe<Scalars['Boolean']>;
  readonly etaDatetime?: Maybe<Scalars['DateTime']>;
  readonly etaTimezone?: Maybe<Scalars['String']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly tractorNumber?: Maybe<Scalars['String']>;
  readonly checkCallDatetime?: Maybe<Scalars['DateTime']>;
  readonly checkCallTimezone?: Maybe<Scalars['String']>;
  readonly trackingStatus?: Maybe<Scalars['String']>;
  readonly sourceCreatedDatetime?: Maybe<Scalars['DateTime']>;
  readonly sourceCreatedTimezone?: Maybe<Scalars['String']>;
  readonly actionTypeId?: Maybe<Scalars['String']>;
  readonly currentLocation?: Maybe<Scalars['String']>;
  readonly currentLocationLatitude?: Maybe<Scalars['Float']>;
  readonly currentLocationLongitude?: Maybe<Scalars['Float']>;
  readonly currentLocationCity?: Maybe<Scalars['String']>;
  readonly currentLocationState?: Maybe<Scalars['String']>;
  readonly currentLocationCountry?: Maybe<Scalars['String']>;
  readonly currentLocationPostalCode?: Maybe<Scalars['String']>;
  readonly nextLocation?: Maybe<Scalars['String']>;
  readonly nextLocationLatitude?: Maybe<Scalars['Float']>;
  readonly nextLocationLongitude?: Maybe<Scalars['Float']>;
  readonly nextLocationCity?: Maybe<Scalars['String']>;
  readonly nextLocationState?: Maybe<Scalars['String']>;
  readonly nextLocationCountry?: Maybe<Scalars['String']>;
  readonly nextLocationPostalCode?: Maybe<Scalars['String']>;
  readonly integrationName?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdByTxId: Scalars['String'];
  readonly updatedByTxId: Scalars['String'];
  readonly createdBy: Scalars['String'];
  readonly updatedBy: Scalars['String'];
  readonly eid: Scalars['Int'];
  readonly emptyDatetime?: Maybe<Scalars['DateTime']>;
  readonly emptyTimezone?: Maybe<Scalars['String']>;
  readonly latestElectronicLocationUpdateDatetime?: Maybe<Scalars['DateTime']>;
  readonly latestTrackingRequestDatetime?: Maybe<Scalars['DateTime']>;
  readonly driverElectronicTrackingTypeId?: Maybe<Scalars['String']>;
  readonly autoEtaDatetime?: Maybe<Scalars['DateTime']>;
};

export type SeerTrackingAggregationsEdge = {
  readonly __typename?: 'SeerTrackingAggregationsEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTrackingAggregations;
};

export type SeerTrackingAggregationsFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly driverAssignmentId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly updateId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver1DisplayName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver1PhoneNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver2DisplayName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driver2PhoneNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastTrackingNote?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly previousRouteId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly nextRouteId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly carrierId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrierName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dispatched?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  readonly etaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly etaTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trailerNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly tractorNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly checkCallDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly checkCallTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trackingStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly sourceCreatedDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly sourceCreatedTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly actionTypeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentLocation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentLocationLatitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly currentLocationLongitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly currentLocationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentLocationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentLocationCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly currentLocationPostalCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocation?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationLatitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly nextLocationLongitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly nextLocationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationPostalCode?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly integrationName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly updatedAt?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly createdByTxId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedByTxId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createdBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly updatedBy?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly emptyDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly emptyTimezone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly latestTrackingRequestDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly autoEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
};

export type SeerTrackingAggregationsOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly driverAssignmentId?: InputMaybe<SeerOrderBy>;
  readonly updateId?: InputMaybe<SeerOrderBy>;
  readonly driver1DisplayName?: InputMaybe<SeerOrderBy>;
  readonly driver1PhoneNumber?: InputMaybe<SeerOrderBy>;
  readonly driver2DisplayName?: InputMaybe<SeerOrderBy>;
  readonly driver2PhoneNumber?: InputMaybe<SeerOrderBy>;
  readonly lastTrackingNote?: InputMaybe<SeerOrderBy>;
  readonly previousRouteId?: InputMaybe<SeerOrderBy>;
  readonly nextRouteId?: InputMaybe<SeerOrderBy>;
  readonly carrierId?: InputMaybe<SeerOrderBy>;
  readonly carrierName?: InputMaybe<SeerOrderBy>;
  readonly dispatched?: InputMaybe<SeerOrderBy>;
  readonly etaDatetime?: InputMaybe<SeerOrderBy>;
  readonly etaTimezone?: InputMaybe<SeerOrderBy>;
  readonly trailerNumber?: InputMaybe<SeerOrderBy>;
  readonly tractorNumber?: InputMaybe<SeerOrderBy>;
  readonly checkCallDatetime?: InputMaybe<SeerOrderBy>;
  readonly checkCallTimezone?: InputMaybe<SeerOrderBy>;
  readonly trackingStatus?: InputMaybe<SeerOrderBy>;
  readonly sourceCreatedDatetime?: InputMaybe<SeerOrderBy>;
  readonly sourceCreatedTimezone?: InputMaybe<SeerOrderBy>;
  readonly actionTypeId?: InputMaybe<SeerOrderBy>;
  readonly currentLocation?: InputMaybe<SeerOrderBy>;
  readonly currentLocationLatitude?: InputMaybe<SeerOrderBy>;
  readonly currentLocationLongitude?: InputMaybe<SeerOrderBy>;
  readonly currentLocationCity?: InputMaybe<SeerOrderBy>;
  readonly currentLocationState?: InputMaybe<SeerOrderBy>;
  readonly currentLocationCountry?: InputMaybe<SeerOrderBy>;
  readonly currentLocationPostalCode?: InputMaybe<SeerOrderBy>;
  readonly nextLocation?: InputMaybe<SeerOrderBy>;
  readonly nextLocationLatitude?: InputMaybe<SeerOrderBy>;
  readonly nextLocationLongitude?: InputMaybe<SeerOrderBy>;
  readonly nextLocationCity?: InputMaybe<SeerOrderBy>;
  readonly nextLocationState?: InputMaybe<SeerOrderBy>;
  readonly nextLocationCountry?: InputMaybe<SeerOrderBy>;
  readonly nextLocationPostalCode?: InputMaybe<SeerOrderBy>;
  readonly integrationName?: InputMaybe<SeerOrderBy>;
  readonly createdAt?: InputMaybe<SeerOrderBy>;
  readonly updatedAt?: InputMaybe<SeerOrderBy>;
  readonly createdByTxId?: InputMaybe<SeerOrderBy>;
  readonly updatedByTxId?: InputMaybe<SeerOrderBy>;
  readonly createdBy?: InputMaybe<SeerOrderBy>;
  readonly updatedBy?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
  readonly emptyDatetime?: InputMaybe<SeerOrderBy>;
  readonly emptyTimezone?: InputMaybe<SeerOrderBy>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<SeerOrderBy>;
  readonly latestTrackingRequestDatetime?: InputMaybe<SeerOrderBy>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<SeerOrderBy>;
  readonly autoEtaDatetime?: InputMaybe<SeerOrderBy>;
};

export type SeerTrackingAggregationsResult = {
  readonly __typename?: 'SeerTrackingAggregationsResult';
  readonly edges: ReadonlyArray<SeerTrackingAggregationsEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTrackingEtas = {
  readonly __typename?: 'SeerTrackingEtas';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly eid: Scalars['Int'];
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  /** Column is of type UUID */
  readonly nextStopId: Scalars['String'];
  readonly autoEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly manualEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly autoEtaSource?: Maybe<Scalars['String']>;
};

export type SeerTrackingEtasEdge = {
  readonly __typename?: 'SeerTrackingEtasEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTrackingEtas;
};

export type SeerTrackingEtasFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly eid?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly nextStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly autoEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly manualEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly autoEtaSource?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerTrackingEtasOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly eid?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly nextStopId?: InputMaybe<SeerOrderBy>;
  readonly autoEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly manualEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly autoEtaSource?: InputMaybe<SeerOrderBy>;
};

export type SeerTrackingEtasResult = {
  readonly __typename?: 'SeerTrackingEtasResult';
  readonly edges: ReadonlyArray<SeerTrackingEtasEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTrackingPage = {
  readonly __typename?: 'SeerTrackingPage';
  /** Column is of type UUID */
  readonly id: Scalars['String'];
  readonly routeNumber?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly routeId: Scalars['String'];
  readonly rlc?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly nextRoute?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly previousRoute?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly stops?: Maybe<Scalars['Int']>;
  readonly customer?: Maybe<Scalars['String']>;
  readonly carrier?: Maybe<Scalars['String']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly ost?: Maybe<Scalars['String']>;
  readonly originDate?: Maybe<Scalars['DateTime']>;
  readonly originTime?: Maybe<Scalars['Int']>;
  readonly destDate?: Maybe<Scalars['DateTime']>;
  readonly destTime?: Maybe<Scalars['Int']>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly destTz?: Maybe<Scalars['String']>;
  readonly firstStopTz?: Maybe<Scalars['String']>;
  readonly puStartDate?: Maybe<Scalars['DateTime']>;
  readonly puStartTime?: Maybe<Scalars['Int']>;
  readonly puStartTimeZone?: Maybe<Scalars['String']>;
  readonly puEndDate?: Maybe<Scalars['DateTime']>;
  readonly puEndTime?: Maybe<Scalars['Int']>;
  readonly puEndTimeZone?: Maybe<Scalars['String']>;
  readonly delStartDate?: Maybe<Scalars['DateTime']>;
  readonly delStartTime?: Maybe<Scalars['Int']>;
  readonly delStartTimeZone?: Maybe<Scalars['String']>;
  readonly delEndDate?: Maybe<Scalars['DateTime']>;
  readonly delEndTime?: Maybe<Scalars['Int']>;
  readonly delEndTimeZone?: Maybe<Scalars['String']>;
  readonly destination?: Maybe<Scalars['String']>;
  readonly dst?: Maybe<Scalars['String']>;
  readonly nxtCallBkDtTm?: Maybe<Scalars['DateTime']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly driverNumber?: Maybe<Scalars['String']>;
  readonly lastTrackingNote?: Maybe<Scalars['String']>;
  readonly createDate?: Maybe<Scalars['DateTime']>;
  readonly nextLocationCity?: Maybe<Scalars['String']>;
  readonly nextLocationState?: Maybe<Scalars['String']>;
  readonly groupId?: Maybe<Scalars['String']>;
  readonly rep?: Maybe<Scalars['String']>;
  readonly routeRepId?: Maybe<Scalars['String']>;
  readonly expectedEmptyLatitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyLongitude?: Maybe<Scalars['Float']>;
  readonly expectedEmptyCity?: Maybe<Scalars['String']>;
  readonly expectedEmptyState?: Maybe<Scalars['String']>;
  readonly expectedEmptyCountry?: Maybe<Scalars['String']>;
  readonly customerRepId?: Maybe<Scalars['String']>;
  readonly customerReps?: Maybe<Scalars['String']>;
  readonly connectivity?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly originStopId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly destinationStopId?: Maybe<Scalars['String']>;
  readonly ibRegionUrn?: Maybe<Scalars['String']>;
  readonly obRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsubReionUrn?: Maybe<Scalars['String']>;
  readonly oBsubRegionUrn?: Maybe<Scalars['String']>;
  readonly iBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly oBsuperRegionUrn?: Maybe<Scalars['String']>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
  readonly ccaStartStatus?: Maybe<Scalars['String']>;
  readonly ccaEndStatus?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly lockId?: Maybe<Scalars['String']>;
  readonly lockEmail?: Maybe<Scalars['String']>;
  readonly lockExpirationTime?: Maybe<Scalars['DateTime']>;
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  /** Column is of type UUID */
  readonly orderRepId?: Maybe<Scalars['String']>;
  readonly infoFrom?: Maybe<Scalars['String']>;
  readonly trackingStatus?: Maybe<Scalars['String']>;
  readonly latestElectronicLocationUpdateDatetime?: Maybe<Scalars['DateTime']>;
  readonly emptyDate?: Maybe<Scalars['DateTime']>;
  readonly latestTrackingRequest?: Maybe<Scalars['DateTime']>;
  readonly outboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSubRegion?: Maybe<Scalars['String']>;
  readonly outboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly inboundFreightAssignmentSuperRegion?: Maybe<Scalars['String']>;
  readonly routeSequence?: Maybe<Scalars['Int']>;
  readonly routeCount?: Maybe<Scalars['Int']>;
  readonly originloadStopSequence?: Maybe<Scalars['Int']>;
  readonly destinationloadStopSequence?: Maybe<Scalars['Int']>;
  readonly mainCarrierRep?: Maybe<Scalars['String']>;
  readonly autoEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly manualEtaDatetime?: Maybe<Scalars['DateTime']>;
  readonly driverElectronicTrackingTypeId?: Maybe<Scalars['String']>;
  readonly nextApptStartDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptEndDate?: Maybe<Scalars['DateTime']>;
  readonly nextApptStartTime?: Maybe<Scalars['Int']>;
  readonly nextApptEndTime?: Maybe<Scalars['Int']>;
  readonly nextApptStartTimeZone?: Maybe<Scalars['String']>;
  readonly nextApptEndTimeZone?: Maybe<Scalars['String']>;
  readonly routeType?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly bookedById?: Maybe<Scalars['String']>;
  readonly bookedByName?: Maybe<Scalars['String']>;
};

export type SeerTrackingPageEdge = {
  readonly __typename?: 'SeerTrackingPageEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTrackingPage;
};

export type SeerTrackingPageFilter = {
  /** Column is of type UUID */
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly routeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rlc?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly nextRoute?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly previousRoute?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly status?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly stops?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly customer?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly carrier?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly origin?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ost?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly originDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly originTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly destTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly startDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly endDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly destTz?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly firstStopTz?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly puEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly puEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly puEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly delEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly delEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly delEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly destination?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly dst?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nxtCallBkDtTm?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly driverNumber?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lastTrackingNote?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly createDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextLocationCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextLocationState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly groupId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly rep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyLatitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyLongitude?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericFloatFilter>>>;
  readonly expectedEmptyCity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyState?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly expectedEmptyCountry?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly customerReps?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly connectivity?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly originStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly destinationStopId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ibRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly obRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubReionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsubRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly iBsuperRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly oBsuperRegionUrn?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ccaStartStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly ccaEndStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly lockId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockEmail?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly lockExpirationTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly isLocked?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericBoolFilter>>>;
  /** Column is of type UUID */
  readonly orderRepId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly infoFrom?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly trackingStatus?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly emptyDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly latestTrackingRequest?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly outboundFreightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly inboundFreightAssignmentSuperRegion?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly routeCount?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly originloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly destinationloadStopSequence?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly mainCarrierRep?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly autoEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly manualEtaDatetime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptStartDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptEndDate?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericDateFilter>>>;
  readonly nextApptStartTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptEndTime?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  readonly nextApptStartTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly nextApptEndTimeZone?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly routeType?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly bookedById?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly bookedByName?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerTrackingPageOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly routeNumber?: InputMaybe<SeerOrderBy>;
  readonly routeId?: InputMaybe<SeerOrderBy>;
  readonly rlc?: InputMaybe<SeerOrderBy>;
  readonly nextRoute?: InputMaybe<SeerOrderBy>;
  readonly previousRoute?: InputMaybe<SeerOrderBy>;
  readonly status?: InputMaybe<SeerOrderBy>;
  readonly stops?: InputMaybe<SeerOrderBy>;
  readonly customer?: InputMaybe<SeerOrderBy>;
  readonly carrier?: InputMaybe<SeerOrderBy>;
  readonly origin?: InputMaybe<SeerOrderBy>;
  readonly ost?: InputMaybe<SeerOrderBy>;
  readonly originDate?: InputMaybe<SeerOrderBy>;
  readonly originTime?: InputMaybe<SeerOrderBy>;
  readonly destDate?: InputMaybe<SeerOrderBy>;
  readonly destTime?: InputMaybe<SeerOrderBy>;
  readonly startDate?: InputMaybe<SeerOrderBy>;
  readonly endDate?: InputMaybe<SeerOrderBy>;
  readonly destTz?: InputMaybe<SeerOrderBy>;
  readonly firstStopTz?: InputMaybe<SeerOrderBy>;
  readonly puStartDate?: InputMaybe<SeerOrderBy>;
  readonly puStartTime?: InputMaybe<SeerOrderBy>;
  readonly puStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly puEndDate?: InputMaybe<SeerOrderBy>;
  readonly puEndTime?: InputMaybe<SeerOrderBy>;
  readonly puEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delStartDate?: InputMaybe<SeerOrderBy>;
  readonly delStartTime?: InputMaybe<SeerOrderBy>;
  readonly delStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly delEndDate?: InputMaybe<SeerOrderBy>;
  readonly delEndTime?: InputMaybe<SeerOrderBy>;
  readonly delEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly destination?: InputMaybe<SeerOrderBy>;
  readonly dst?: InputMaybe<SeerOrderBy>;
  readonly nxtCallBkDtTm?: InputMaybe<SeerOrderBy>;
  readonly driverName?: InputMaybe<SeerOrderBy>;
  readonly driverNumber?: InputMaybe<SeerOrderBy>;
  readonly lastTrackingNote?: InputMaybe<SeerOrderBy>;
  readonly createDate?: InputMaybe<SeerOrderBy>;
  readonly nextLocationCity?: InputMaybe<SeerOrderBy>;
  readonly nextLocationState?: InputMaybe<SeerOrderBy>;
  readonly groupId?: InputMaybe<SeerOrderBy>;
  readonly rep?: InputMaybe<SeerOrderBy>;
  readonly routeRepId?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLatitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyLongitude?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCity?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyState?: InputMaybe<SeerOrderBy>;
  readonly expectedEmptyCountry?: InputMaybe<SeerOrderBy>;
  readonly customerRepId?: InputMaybe<SeerOrderBy>;
  readonly customerReps?: InputMaybe<SeerOrderBy>;
  readonly connectivity?: InputMaybe<SeerOrderBy>;
  readonly originStopId?: InputMaybe<SeerOrderBy>;
  readonly destinationStopId?: InputMaybe<SeerOrderBy>;
  readonly ibRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly obRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsubReionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsubRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly iBsuperRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly oBsuperRegionUrn?: InputMaybe<SeerOrderBy>;
  readonly routeActivationStatus?: InputMaybe<SeerOrderBy>;
  readonly ccaStartStatus?: InputMaybe<SeerOrderBy>;
  readonly ccaEndStatus?: InputMaybe<SeerOrderBy>;
  readonly lockId?: InputMaybe<SeerOrderBy>;
  readonly lockEmail?: InputMaybe<SeerOrderBy>;
  readonly lockExpirationTime?: InputMaybe<SeerOrderBy>;
  readonly isLocked?: InputMaybe<SeerOrderBy>;
  readonly orderRepId?: InputMaybe<SeerOrderBy>;
  readonly infoFrom?: InputMaybe<SeerOrderBy>;
  readonly trackingStatus?: InputMaybe<SeerOrderBy>;
  readonly latestElectronicLocationUpdateDatetime?: InputMaybe<SeerOrderBy>;
  readonly emptyDate?: InputMaybe<SeerOrderBy>;
  readonly latestTrackingRequest?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSubRegion?: InputMaybe<SeerOrderBy>;
  readonly outboundFreightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly inboundFreightAssignmentSuperRegion?: InputMaybe<SeerOrderBy>;
  readonly routeSequence?: InputMaybe<SeerOrderBy>;
  readonly routeCount?: InputMaybe<SeerOrderBy>;
  readonly originloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly destinationloadStopSequence?: InputMaybe<SeerOrderBy>;
  readonly mainCarrierRep?: InputMaybe<SeerOrderBy>;
  readonly autoEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly manualEtaDatetime?: InputMaybe<SeerOrderBy>;
  readonly driverElectronicTrackingTypeId?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndDate?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTime?: InputMaybe<SeerOrderBy>;
  readonly nextApptStartTimeZone?: InputMaybe<SeerOrderBy>;
  readonly nextApptEndTimeZone?: InputMaybe<SeerOrderBy>;
  readonly routeType?: InputMaybe<SeerOrderBy>;
  readonly bookedById?: InputMaybe<SeerOrderBy>;
  readonly bookedByName?: InputMaybe<SeerOrderBy>;
};

export type SeerTrackingPageResult = {
  readonly __typename?: 'SeerTrackingPageResult';
  readonly edges: ReadonlyArray<SeerTrackingPageEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type SeerTrailerType = {
  readonly __typename?: 'SeerTrailerType';
  readonly id: Scalars['Int'];
  /** Column is of type UUID */
  readonly orderId?: Maybe<Scalars['String']>;
  /** Column is of type UUID */
  readonly loadId?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
};

export type SeerTrailerTypeEdge = {
  readonly __typename?: 'SeerTrailerTypeEdge';
  readonly cursor: Scalars['String'];
  readonly node: SeerTrailerType;
};

export type SeerTrailerTypeFilter = {
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericIntFilter>>>;
  /** Column is of type UUID */
  readonly orderId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  /** Column is of type UUID */
  readonly loadId?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
  readonly name?: InputMaybe<ReadonlyArray<InputMaybe<SeerGenericStringFilter>>>;
};

export type SeerTrailerTypeOrderBy = {
  readonly id?: InputMaybe<SeerOrderBy>;
  readonly orderId?: InputMaybe<SeerOrderBy>;
  readonly loadId?: InputMaybe<SeerOrderBy>;
  readonly name?: InputMaybe<SeerOrderBy>;
};

export type SeerTrailerTypeResult = {
  readonly __typename?: 'SeerTrailerTypeResult';
  readonly edges: ReadonlyArray<SeerTrailerTypeEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type Segment = {
  readonly __typename?: 'Segment';
  readonly defaultValue?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isRequired: Scalars['Boolean'];
  readonly length: Scalars['Int'];
  readonly repType?: Maybe<Scalars['String']>;
  readonly segmentNumber: Scalars['Int'];
  readonly segmentSeparator: Scalars['String'];
  readonly segmentType: Scalars['String'];
  readonly tenantDescription: Scalars['String'];
};

export type SegmentationConfiguration = {
  readonly __typename?: 'SegmentationConfiguration';
  readonly division?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isFinalized: Scalars['Boolean'];
  readonly segmentationConfigurationLevel: Scalars['String'];
  readonly segmentationConfigurationType: Scalars['String'];
  readonly segments?: Maybe<ReadonlyArray<Segment>>;
};

export type SegmentationConfigurationInput = {
  readonly division?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isFinalized: Scalars['Boolean'];
  readonly segmentationConfigurationLevel: Scalars['String'];
  readonly segmentationConfigurationType: Scalars['String'];
  readonly segments?: InputMaybe<ReadonlyArray<SegmentInput>>;
};

export type SegmentInput = {
  readonly defaultValue?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isRequired: Scalars['Boolean'];
  readonly length: Scalars['Int'];
  readonly repType?: InputMaybe<Scalars['String']>;
  readonly segmentNumber: Scalars['Int'];
  readonly segmentSeparator: Scalars['String'];
  readonly segmentType: Scalars['String'];
  readonly tenantDescription: Scalars['String'];
};

export type SegmentValue = {
  readonly id: Scalars['String'];
  readonly value: Scalars['String'];
};

export type SegmentValueOutput = {
  readonly __typename?: 'SegmentValueOutput';
  readonly dictionaryTermId: Scalars['String'];
  readonly dictionaryTermOptionId: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly segmentId: Scalars['ID'];
  readonly value: Scalars['String'];
};

export type SegmentValues = {
  readonly segmentId: Scalars['ID'];
  readonly segmentValues: ReadonlyArray<InputMaybe<SegmentValue>>;
};

export type SendMessageResponseObj = {
  readonly __typename?: 'sendMessageResponseObj';
  readonly success: Scalars['Boolean'];
  readonly error: Scalars['String'];
};

export type SendMsgInputObj = {
  readonly userList: ReadonlyArray<Scalars['String']>;
  readonly userMessage: Scalars['String'];
  readonly message_id: Scalars['String'];
  readonly server_type?: InputMaybe<Scalars['String']>;
  readonly workspace_id?: InputMaybe<Scalars['String']>;
  readonly sender_id: Scalars['String'];
  readonly created_by_user_id?: InputMaybe<Scalars['String']>;
  readonly created_at?: InputMaybe<Scalars['Float']>;
};

export type SendMsgToSlackInputObj = {
  readonly userId: Scalars['String'];
  readonly objTitle: Scalars['String'];
  readonly objUrl: Scalars['String'];
  readonly userMessage: Scalars['String'];
};

export type SequenceItemTenderOptionsInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly tenderModeTerm?: InputMaybe<Scalars['String']>;
  readonly tenderContacts?: InputMaybe<ReadonlyArray<InputMaybe<TenderOptionsContactInput>>>;
  readonly businessTimeZoneTerm?: InputMaybe<Scalars['String']>;
  readonly businessDays?: InputMaybe<ReadonlyArray<InputMaybe<TenderOptionsBusinessDaysInput>>>;
  readonly tenderExpirationTimeSpan?: InputMaybe<TenderOptionsTimeSpanInput>;
  readonly tenderLeadTimeSpan?: InputMaybe<TenderOptionsTimeSpanInput>;
  readonly autoTender?: InputMaybe<Scalars['Boolean']>;
};

export enum ServiceEnum {
  Gate = 'Gate',
  RampLift = 'RampLift'
}

export type ServiceProfile = {
  readonly __typename?: 'ServiceProfile';
  readonly id: Scalars['ID'];
  readonly serviceProfileId: Scalars['Float'];
  readonly carrierId: Scalars['String'];
  readonly originRamp: Scalars['String'];
  readonly destinationRamp: Scalars['String'];
  readonly train?: Maybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly isCOFC?: Maybe<Scalars['Boolean']>;
  readonly isTOFC?: Maybe<Scalars['Boolean']>;
  /** @deprecated removed this field */
  readonly publishedTransitTime?: Maybe<Scalars['String']>;
  readonly publishedTransitDays: Scalars['Float'];
  readonly reqOrigin?: Maybe<Scalars['Boolean']>;
  readonly reqLane?: Maybe<Scalars['Boolean']>;
  readonly boxType?: Maybe<Scalars['String']>;
  readonly laneStartDate: Scalars['DateTime'];
  /** @deprecated reason: Use laneExpirationDateV2 field became nullable */
  readonly laneExpirationDate: Scalars['DateTime'];
  readonly laneExpirationDateV2?: Maybe<Scalars['DateTime']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createDate?: Maybe<Scalars['DateTime']>;
  readonly updateDate: Scalars['DateTime'];
  readonly schedules: Schedules;
  readonly originRampFacility: Facility;
  readonly destinationRampFacility: Facility;
  readonly createdByUser: User;
  readonly updatedByUser: User;
  readonly originRampFacilityV2: FacilityV2;
  readonly destinationRampFacilityV2: FacilityV2;
  readonly createdByUserV2: UserV2;
  readonly updatedByUserV2: UserV2;
  readonly carrier: Carrier;
  readonly carrierV2: CarrierV2;
};

export type ServiceProfileObject = {
  readonly __typename?: 'ServiceProfileObject';
  readonly id: Scalars['String'];
  readonly serviceProfileId: Scalars['Float'];
  readonly carrierId: Scalars['String'];
  readonly originRamp: Scalars['String'];
  readonly destinationRamp: Scalars['String'];
  readonly train?: Maybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly isCOFC?: Maybe<Scalars['Boolean']>;
  readonly isTOFC?: Maybe<Scalars['Boolean']>;
  readonly publishedTransitDays: Scalars['Float'];
  readonly reqOrigin?: Maybe<Scalars['Boolean']>;
  readonly reqLane?: Maybe<Scalars['Boolean']>;
  readonly boxType?: Maybe<Scalars['String']>;
  readonly laneStartDate: Scalars['DateTime'];
  readonly laneExpirationDate: Scalars['DateTime'];
  readonly laneExpirationDateV2?: Maybe<Scalars['DateTime']>;
  readonly schedules: SchedulesObject;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createDate?: Maybe<Scalars['DateTime']>;
  readonly updateDate?: Maybe<Scalars['DateTime']>;
  readonly originRampFacility: Facility;
  readonly destinationRampFacility: Facility;
  readonly createdByUser: User;
  readonly updatedByUser: User;
  readonly originRampFacilityV2: FacilityV2;
  readonly destinationRampFacilityV2: FacilityV2;
  readonly createdByUserV2: UserV2;
  readonly updatedByUserV2: UserV2;
  readonly carrier: Carrier;
  readonly carrierV2: CarrierV2;
};

export enum ServiceSource {
  /** Events that are Owned or Serviced through Minion Portal */
  MinionPortal = 'MINION_PORTAL',
  /** Events that are Owned or Serviced through Hayk Rules Engine */
  HaykRulesEngine = 'HAYK_RULES_ENGINE'
}

export type SetAutoApplyInput = {
  readonly laneId?: InputMaybe<Scalars['ID']>;
  readonly autoApply?: InputMaybe<Scalars['Boolean']>;
  readonly userId?: InputMaybe<Scalars['ID']>;
};

export type SetAutoApplyPayload = {
  readonly __typename?: 'SetAutoApplyPayload';
  readonly laneId?: Maybe<Scalars['ID']>;
  readonly autoApply?: Maybe<Scalars['Boolean']>;
};

export type SetAutoApplyPayloadV2 = {
  readonly __typename?: 'SetAutoApplyPayloadV2';
  readonly data?: Maybe<SetAutoApplyPayload>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type ShipLocation = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly stateAbbreviation?: InputMaybe<Scalars['String']>;
  readonly zipCode: Scalars['String'];
  readonly country?: InputMaybe<Scalars['String']>;
};

export type Shipment = {
  readonly __typename?: 'Shipment';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly order?: Maybe<ReadonlyArray<CustomerOrderV2>>;
  readonly commodities?: Maybe<ReadonlyArray<CommodityV2>>;
};

export type ShipmentInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly name: Scalars['String'];
};

export type SingleLineBulkRequestInput = {
  readonly requestId: Scalars['String'];
  readonly addressLine1: Scalars['String'];
  readonly country: Scalars['String'];
};

export type SingleLineRequest = {
  readonly addressLine1: Scalars['String'];
  readonly country: Scalars['String'];
};

export type SingleLineResult = FormattedAddress | InvalidAddress;

export type SingleUserInputObj = {
  readonly userId: Scalars['String'];
  readonly userName?: InputMaybe<Scalars['String']>;
  readonly userMessage: Scalars['String'];
};

export enum Size {
  Default = 'Default',
  Ftl = 'Ftl'
}

export type SizeR2 = {
  readonly __typename?: 'SizeR2';
  readonly id?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
};

/** DEPRECATED: Use LoadSize DDT instead. */
export enum SizeTypesEnum {
  Tl = 'TL',
  Ltl = 'LTL'
}

export type SniGenericInput = {
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly divisionTerm?: InputMaybe<Scalars['String']>;
  readonly businessUnitTerm?: InputMaybe<Scalars['String']>;
  readonly projectTerm?: InputMaybe<Scalars['String']>;
  readonly modeTerm: Scalars['String'];
  readonly sizeTerm?: InputMaybe<Scalars['String']>;
  readonly carrierScac: Scalars['String'];
  readonly service?: InputMaybe<Scalars['String']>;
  readonly equipment?: InputMaybe<Equipment>;
  readonly requirementTerms?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly stops: ReadonlyArray<InputMaybe<SniQuoteStop>>;
};

export type SniQuoteDimensions = {
  readonly value?: InputMaybe<Scalars['Float']>;
  readonly unitTerm?: InputMaybe<Scalars['String']>;
};

export type SniQuoteOutput = {
  readonly __typename?: 'SniQuoteOutput';
  readonly timestamp?: Maybe<Scalars['DateTime']>;
  readonly status: Scalars['Int'];
  readonly error?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly path?: Maybe<Scalars['String']>;
  readonly sniResponse?: Maybe<SniResponse>;
};

export type SniQuoteStop = {
  readonly sequence: Scalars['Int'];
  readonly stopTypeTerm: StopTypeTerm;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly postalCode: Scalars['String'];
  readonly region?: InputMaybe<Scalars['String']>;
  readonly stopRequirementTerms?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type SniResponse = {
  readonly __typename?: 'SniResponse';
  readonly customerRequestId?: Maybe<Scalars['String']>;
  readonly solutions?: Maybe<ReadonlyArray<Maybe<Solutions>>>;
  readonly messages?: Maybe<ReadonlyArray<Maybe<Message>>>;
};

export type Solutions = {
  readonly __typename?: 'Solutions';
  readonly quoteId?: Maybe<Scalars['String']>;
  readonly quoteExpiration?: Maybe<Scalars['String']>;
  readonly scac?: Maybe<Scalars['String']>;
  readonly lineHaul?: Maybe<Scalars['Float']>;
  readonly fuel?: Maybe<Scalars['Float']>;
  readonly accessorials?: Maybe<Scalars['Float']>;
  readonly totalPrice?: Maybe<Scalars['Float']>;
  readonly startDateTime?: Maybe<Scalars['DateTime']>;
  readonly endDateTime?: Maybe<Scalars['DateTime']>;
  readonly transitDays?: Maybe<Scalars['Float']>;
  readonly accessorialList?: Maybe<ReadonlyArray<Maybe<Accessorial>>>;
};

export enum SourceTypeEnum {
  /** api */
  Api = 'api',
  /** dat */
  Dat = 'dat',
  /** email */
  Email = 'email',
  /** manual */
  Manual = 'manual'
}

/** DEPRECATED: Identifies the method by which a TruckEntry was created */
export enum SourceTypesEnum {
  Api = 'api',
  AutoTruck = 'autoTruck',
  Dat = 'dat',
  Email = 'email',
  Manual = 'manual'
}

export type SpeedMapDat = {
  readonly location: Scalars['String'];
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
  readonly mph: Scalars['Float'];
};

export type SpotQuote = {
  readonly __typename?: 'SpotQuote';
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  /** @deprecated Use customerV2 instead */
  readonly customer: Customer;
  readonly customerV2: CustomerV2;
  readonly lane: LaneV2;
  readonly stops: ReadonlyArray<SpotQuoteStop>;
  readonly loadCount: Scalars['Int'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate: Scalars['Date'];
  readonly shipStartDate: Scalars['Date'];
  readonly shipEndDate: Scalars['Date'];
  readonly desiredDeliveryDate?: Maybe<Scalars['Date']>;
  readonly quotedBy?: Maybe<UserV2>;
  readonly quotedTo?: Maybe<CustomerContact>;
  /** The Minion tenantScac's OptionId */
  readonly scac?: Maybe<Scalars['String']>;
  readonly externalQuoteId?: Maybe<Scalars['String']>;
  readonly multiStop: Scalars['Boolean'];
  /** The Minion orderRequirement's OptionIds */
  readonly requirements: ReadonlyArray<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly rateLineItems: ReadonlyArray<SpotQuoteRateLineItem>;
  readonly createdOn: Scalars['DateTime'];
  readonly createdBy: UserV2;
  readonly updatedOn: Scalars['DateTime'];
  readonly updatedBy: UserV2;
  readonly baseRate: Scalars['Decimal'];
  readonly fuel: Scalars['Decimal'];
  readonly extras: Scalars['Decimal'];
  readonly totalRate: Scalars['Decimal'];
};

export type SpotQuoteConnection = {
  readonly __typename?: 'SpotQuoteConnection';
  readonly edges: ReadonlyArray<Maybe<SpotQuoteEdge>>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type SpotQuoteCountryStopInput = {
  /** Two-character country code (e.g. US, MX, CA) */
  readonly countryCode: Scalars['String'];
};

export type SpotQuoteDistanceInput = {
  /** Only supports [miles, kilometers] */
  readonly unit: Scalars['String'];
  readonly value: Scalars['Decimal'];
};

export type SpotQuoteEdge = {
  readonly __typename?: 'SpotQuoteEdge';
  readonly cursor: Scalars['String'];
  readonly node: SpotQuote;
};

export type SpotQuoteError = {
  readonly __typename?: 'SpotQuoteError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type SpotQuoteFacilityStopInput = {
  readonly facilityId: Scalars['ID'];
};

export type SpotQuoteFilterInput = {
  /** The Minion transportMode's OptionId */
  readonly transportMode?: InputMaybe<Scalars['String']>;
  /** The Minion loadSize's OptionId */
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly stops?: InputMaybe<ReadonlyArray<SpotQuoteStopFilterInput>>;
  /** The Minion trailerType's OptionIds */
  readonly equipments?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly effectiveDate?: InputMaybe<Scalars['Date']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  /** The Minion serviceLevel's OptionId */
  readonly serviceLevel?: InputMaybe<Scalars['String']>;
  /** Length in feet */
  readonly length?: InputMaybe<Scalars['Int']>;
  /** Width in inches */
  readonly width?: InputMaybe<Scalars['Int']>;
  /** Height in inches */
  readonly height?: InputMaybe<Scalars['Int']>;
  /** The Minion division's OptionId */
  readonly division?: InputMaybe<Scalars['String']>;
  /** The Minion project's OptionId */
  readonly project?: InputMaybe<Scalars['String']>;
  /** The Minion businessUnit's OptionId */
  readonly businessUnit?: InputMaybe<Scalars['String']>;
  /** The Minion tenantScac's OptionId */
  readonly scac?: InputMaybe<Scalars['String']>;
  /** The Minion orderRequirement's OptionIds */
  readonly requirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type SpotQuoteGeographyStopInput = {
  readonly geographySourceId: Scalars['Int'];
};

export type SpotQuotePaginatedFilterInput = {
  readonly spotQuoteFilter?: InputMaybe<SpotQuoteFilterInput>;
  /** Include to filter by only the specified customerId or null to return all customers */
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly includeExpired?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadHeadDistance?: InputMaybe<SpotQuoteDistanceInput>;
  readonly destinationDeadHeadDistance?: InputMaybe<SpotQuoteDistanceInput>;
  /** Used to determine whether spot quotes are expired */
  readonly timezone: Scalars['String'];
};

export type SpotQuotePaginatedInput = {
  readonly filter: SpotQuotePaginatedFilterInput;
  readonly after?: InputMaybe<Scalars['String']>;
  readonly first?: InputMaybe<Scalars['Int']>;
};

export type SpotQuotePostalCodeStopInput = {
  /** Two-character country code (e.g. US, MX, CA) */
  readonly countryCode: Scalars['String'];
  readonly postalCode: Scalars['String'];
};

export type SpotQuoteRateLineItem = {
  readonly __typename?: 'SpotQuoteRateLineItem';
  /** The Minion chargeType's OptionId */
  readonly type: Scalars['String'];
  readonly ratePerUnit: Scalars['Decimal'];
  readonly units: Scalars['Decimal'];
  readonly totalRate: Scalars['Decimal'];
  /** The Minion currency's OptionId */
  readonly currency: Scalars['String'];
  readonly sequence: Scalars['Int'];
};

export type SpotQuoteRateLineItemInput = {
  /** The Minion chargeType's OptionId */
  readonly type: Scalars['String'];
  readonly ratePerUnit: Scalars['Decimal'];
  readonly units: Scalars['Decimal'];
  /** The Minion currency's OptionId */
  readonly currency: Scalars['String'];
};

export type SpotQuotesByIdsInput = {
  readonly spotQuoteIds: ReadonlyArray<Scalars['ID']>;
};

export type SpotQuotesPagedFilterInput = {
  /** Include to filter by only the specified laneId or null to return for all lanes */
  readonly laneId?: InputMaybe<Scalars['ID']>;
  /** Include to filter by only the specified customerId or null to return for all customers */
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  /** True to return only active (non-expired) or false to return only inactive (expired) */
  readonly timezone: Scalars['String'];
};

export type SpotQuotesPagedInput = {
  readonly filter: SpotQuotesPagedFilterInput;
  readonly after?: InputMaybe<Scalars['String']>;
  readonly first?: InputMaybe<Scalars['Int']>;
};

export type SpotQuotesPaginatedPayload = {
  readonly __typename?: 'SpotQuotesPaginatedPayload';
  readonly data?: Maybe<SpotQuoteConnection>;
  readonly errors?: Maybe<ReadonlyArray<SpotQuoteError>>;
};

export type SpotQuoteStop = {
  readonly __typename?: 'SpotQuoteStop';
  readonly id: Scalars['ID'];
  readonly laneStopId: Scalars['ID'];
  /** The Records ScheduleDropTypeEnum's value: https://studio.apollographql.com/graph/mastermind/schema/sdl?currentSubgraph=Records&variant=mm100dev#ScheduleDropTypeEnum */
  readonly liveType: Scalars['String'];
  readonly sequence: Scalars['Int'];
};

export type SpotQuoteStopFilterInput = {
  /** The Records ScheduleDropTypeEnum's value: https://studio.apollographql.com/graph/mastermind/schema/sdl?currentSubgraph=Records&variant=mm100dev#ScheduleDropTypeEnum */
  readonly liveType?: InputMaybe<Scalars['String']>;
  /** The Minion geographyType's OptionId. */
  readonly geographyType?: InputMaybe<Scalars['String']>;
  readonly stopFacility?: InputMaybe<SpotQuoteFacilityStopInput>;
  readonly stopPostalCode?: InputMaybe<SpotQuotePostalCodeStopInput>;
  readonly stopCity?: InputMaybe<SpotQuoteGeographyStopInput>;
  readonly stopState?: InputMaybe<SpotQuoteGeographyStopInput>;
  readonly stopCountry?: InputMaybe<SpotQuoteCountryStopInput>;
};

export type SpotQuoteStopInput = {
  /** The Records ScheduleDropTypeEnum's value: https://studio.apollographql.com/graph/mastermind/schema/sdl?currentSubgraph=Records&variant=mm100dev#ScheduleDropTypeEnum */
  readonly liveType: Scalars['String'];
  /**
   * The Minion geographyType's OptionId.
   * Only supports [Facility, PostalCode, City, State, Country]
   */
  readonly geographyType: Scalars['String'];
  readonly stopFacility?: InputMaybe<SpotQuoteFacilityStopInput>;
  readonly stopPostalCode?: InputMaybe<SpotQuotePostalCodeStopInput>;
  readonly stopCity?: InputMaybe<SpotQuoteGeographyStopInput>;
  readonly stopState?: InputMaybe<SpotQuoteGeographyStopInput>;
  readonly stopCountry?: InputMaybe<SpotQuoteCountryStopInput>;
};

export type StartAutoRateConfirmationInput = {
  readonly sentByUserId: Scalars['ID'];
  readonly sendToEmail?: InputMaybe<Scalars['String']>;
  readonly ccEmails?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly routeId: Scalars['ID'];
  readonly routeVendorId: Scalars['ID'];
  readonly vendorContactId: Scalars['ID'];
  readonly hideCostDetails?: InputMaybe<Scalars['Boolean']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly contactName?: InputMaybe<Scalars['String']>;
  readonly contactPhone?: InputMaybe<Scalars['String']>;
  readonly contactMethod?: InputMaybe<Scalars['String']>;
  readonly hideReference?: InputMaybe<Scalars['Boolean']>;
  readonly taStopPending?: InputMaybe<Scalars['Boolean']>;
};

export type StartAutoRateConfirmationPayload = {
  readonly __typename?: 'StartAutoRateConfirmationPayload';
  readonly errors?: Maybe<ReadonlyArray<UserError>>;
  readonly rateConfirmation?: Maybe<RateConfirmation>;
};

export type StateFilter = {
  readonly deliveryStateCollection?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly pickUpStateCollection?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export enum StatusEnum {
  /** Voucher / Invoice has not been generated. */
  Saved = 'Saved',
  /** Voucher / Invoice has been generated, but not processed by the GL System. */
  Pending = 'Pending',
  /** Voucher / Invoice has been generated, and processing GL System. */
  Processing = 'Processing',
  /** Voucher / Invoice has been generated, processed by the GL System, and confirmation has been received that it has posted. */
  Processed = 'Processed',
  /** During 'Processing' the Voucher / Invoice encountered an error. */
  Error = 'Error',
  /** The Voucher / Invoice has been processed, a credit memo has been created and processed by the GL System, and confirmation has been received that it has posted. */
  CreditMemoed = 'CreditMemoed',
  /** The Voucher / Invoice has been processed, a credit memo has been created, and processing GL System. */
  CreditMemoing = 'CreditMemoing'
}

export enum StatusTypeEnum {
  /** unknown */
  Unknown = 'unknown',
  /** new_account */
  NewAccount = 'newAccount',
  /** pending */
  Pending = 'pending',
  /** approved */
  Approved = 'approved',
  /** denied */
  Denied = 'denied',
  /** hold */
  Hold = 'hold',
  /** new */
  New = 'new',
  /** selective */
  Selective = 'selective',
  /** not_monitored */
  NotMonitored = 'not_monitored'
}

/** A stop for a load */
export type Stop = {
  readonly __typename?: 'Stop';
  readonly address?: Maybe<Address>;
  readonly appointment?: Maybe<Appointment>;
  readonly appointments?: Maybe<ReadonlyArray<Appointment>>;
  readonly apptConfBy?: Maybe<Scalars['String']>;
  readonly apptReqWith?: Maybe<Scalars['String']>;
  readonly availableEnd?: Maybe<DatetimeWithTimezone>;
  readonly availableEndTimes?: Maybe<ScheduleRange>;
  readonly availableStart?: Maybe<DatetimeWithTimezone>;
  readonly availableStartTimes?: Maybe<ScheduleRange>;
  readonly bolNumber?: Maybe<Scalars['String']>;
  readonly carrierArrival?: Maybe<DatetimeWithTimezone>;
  readonly carrierDeparture?: Maybe<DatetimeWithTimezone>;
  readonly commodities?: Maybe<ReadonlyArray<Commodity>>;
  readonly commodityTotals?: Maybe<CommodityTotals>;
  readonly computedCurrentAppointment?: Maybe<Appointment>;
  readonly currentAppointment?: Maybe<Appointment>;
  readonly destroyed?: Maybe<Scalars['Boolean']>;
  readonly distanceToNext?: Maybe<Length>;
  readonly employeeId?: Maybe<Scalars['String']>;
  readonly externalRef?: Maybe<Scalars['String']>;
  readonly facility?: Maybe<Facility>;
  readonly id: Scalars['ID'];
  readonly lateArrivalReasonCode?: Maybe<KeyValue>;
  readonly liveType?: Maybe<ScheduleDropTypeEnum>;
  readonly loadFrom?: Maybe<KeyValue>;
  readonly note?: Maybe<Scalars['String']>;
  readonly refs?: Maybe<ReadonlyArray<Ref>>;
  readonly region?: Maybe<Region>;
  readonly reqDate?: Maybe<DatetimeWithTimezone>;
  readonly requirements?: Maybe<ReadonlyArray<StopRequirement>>;
  readonly routes?: Maybe<ReadonlyArray<LoadRoute>>;
  readonly scheduleType?: Maybe<ScheduleAppointmentTypeEnum>;
  readonly sealNumber?: Maybe<Scalars['String']>;
  readonly signature?: Maybe<Scalars['String']>;
  readonly subRegion?: Maybe<Region>;
  readonly superRegion?: Maybe<Region>;
  readonly trailerNumberIn?: Maybe<Scalars['String']>;
  readonly trailerNumberOut?: Maybe<Scalars['String']>;
  readonly type: StopTypeEnum;
  readonly unloadFrom?: Maybe<KeyValue>;
  readonly workType?: Maybe<ScheduleWorkTypeEnum>;
};


/** A stop for a load */
export type StopDistanceToNextArgs = {
  unit?: InputMaybe<UnitOfLengthEnum>;
};

export type StopAddress = {
  readonly __typename?: 'StopAddress';
  readonly id: Scalars['String'];
  readonly geopoint?: Maybe<Geopoint>;
  readonly street1: Scalars['String'];
  readonly street2?: Maybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
};

export type StopAddressInput = {
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly countryArea: Scalars['String'];
  readonly main: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly province?: InputMaybe<Scalars['String']>;
  readonly state: Scalars['String'];
  readonly streetAddress1: Scalars['String'];
};

export type StopAddressInputV2 = {
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly countryArea: Scalars['String'];
  readonly main: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly province?: InputMaybe<Scalars['String']>;
  readonly state: Scalars['String'];
  readonly streetAddress1: Scalars['String'];
};

export type StopAddressV2 = {
  readonly __typename?: 'StopAddressV2';
  readonly id?: Maybe<Scalars['String']>;
  readonly geopoint?: Maybe<Geopoint>;
  readonly street1?: Maybe<Scalars['String']>;
  readonly street2?: Maybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
};

/** The connection type for Stop. */
export type StopConnection = {
  readonly __typename?: 'StopConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<StopEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type StopEdge = {
  readonly __typename?: 'StopEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: Stop;
};

export type StopEdgeV2 = {
  readonly __typename?: 'StopEdgeV2';
  readonly node: RouteStop;
  readonly cursor: Scalars['String'];
};

export type StopEvent = {
  readonly __typename?: 'StopEvent';
  readonly id: Scalars['ID'];
  readonly eventTypeID: Scalars['String'];
  readonly eventSubTypeID: Scalars['String'];
  readonly eventSubTypeDatetime?: Maybe<Scalars['DateTime']>;
  readonly eventSubTypeTimezone?: Maybe<Scalars['String']>;
  readonly eventDetail: EventDetail;
};

export type StopEventEngineInput = {
  readonly routeType: Scalars['String'];
  readonly stopType: Scalars['String'];
  readonly liveType: ScheduleDropTypeEnum;
};

export type StopEventEngineOutput = {
  readonly __typename?: 'StopEventEngineOutput';
  readonly coreStopEvents: ReadonlyArray<Scalars['String']>;
  readonly prefillStopEvents: ReadonlyArray<Scalars['String']>;
  readonly optionalStopEvents: ReadonlyArray<Scalars['String']>;
};

export type StopEventsV2Filter = {
  readonly stopID?: InputMaybe<Scalars['String']>;
  readonly eventTypeID?: InputMaybe<Scalars['String']>;
  readonly stopEventIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type StopEventV2 = {
  readonly __typename?: 'StopEventV2';
  readonly id: Scalars['ID'];
  readonly load: LoadV2;
  readonly route: LoadRouteV2;
  readonly stop: RouteStop;
  readonly eventTypeID: Scalars['String'];
  readonly eventSubTypeID: Scalars['String'];
  readonly eventSubTypeDatetime?: Maybe<Scalars['DateTime']>;
  readonly eventSubTypeTimezone?: Maybe<Scalars['String']>;
  readonly eventDetail: EventDetail;
  readonly isFromIngress: Scalars['Boolean'];
  readonly createdDatetime: Scalars['DateTime'];
  readonly updatedDatetime: Scalars['DateTime'];
  readonly lastUpdateSource?: Maybe<Scalars['String']>;
};

export type StopEventV2Connection = {
  readonly __typename?: 'StopEventV2Connection';
  readonly edges: ReadonlyArray<StopEventV2Edge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type StopEventV2DeleteInput = {
  readonly employeeID?: InputMaybe<Scalars['ID']>;
  readonly stopID: Scalars['ID'];
  readonly eventTypeID: Scalars['String'];
};

export type StopEventV2Edge = {
  readonly __typename?: 'StopEventV2Edge';
  readonly cursor: Scalars['String'];
  readonly node: StopEventV2;
};

export type StopEventV2IngressInput = {
  readonly userID?: InputMaybe<Scalars['ID']>;
  readonly loadID: Scalars['ID'];
  readonly routeID: Scalars['ID'];
  readonly stopID: Scalars['ID'];
  readonly eventTypeID: Scalars['String'];
  readonly eventSubTypeID: Scalars['String'];
  readonly eventDatetime?: InputMaybe<Scalars['DateTime']>;
  readonly eventTimezone?: InputMaybe<Scalars['String']>;
  readonly eventDetail?: InputMaybe<EventDetailInput>;
};

export type StopEventV2LateReasonUpdateInput = {
  readonly employeeID?: InputMaybe<Scalars['ID']>;
  readonly stopID: Scalars['ID'];
  readonly lateArrivalReasonCodeID: Scalars['String'];
};

export type StopEventV2UpsertInput = {
  readonly employeeID?: InputMaybe<Scalars['ID']>;
  readonly loadID: Scalars['ID'];
  readonly routeID: Scalars['ID'];
  readonly stopID: Scalars['ID'];
  readonly eventTypeID: Scalars['String'];
  readonly expectedDatetime?: InputMaybe<Scalars['DateTime']>;
  readonly expectedTimezone?: InputMaybe<Scalars['String']>;
  readonly actualDatetime?: InputMaybe<Scalars['DateTime']>;
  readonly actualTimezone?: InputMaybe<Scalars['String']>;
  readonly lateArrivalReasonCodeID?: InputMaybe<Scalars['String']>;
  readonly trailer?: InputMaybe<TrailerInput>;
  readonly trailers?: InputMaybe<ReadonlyArray<TrailerInput>>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly tractorNumber?: InputMaybe<Scalars['String']>;
};

export type StopRequirement = {
  readonly __typename?: 'StopRequirement';
  readonly option: KeyValue;
};

export type StopRequirementInput = {
  readonly optionId: Scalars['ID'];
};

export type StopsConnection = {
  readonly __typename?: 'StopsConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<StopEdgeV2>;
};

export type StopsFilter = {
  /** Search/Filter by ComputedCurrentAppointment status */
  readonly appointmentStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by appt_req_with */
  readonly apptReqWith?: InputMaybe<Scalars['String']>;
  /** Search/Filter by array of Customer Employee groups */
  readonly customerEmployeeGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by customer ids */
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/Filter by last route stop address */
  readonly destinationStopAddress?: InputMaybe<AddressFilter>;
  /** Search/Filter by start range ending */
  readonly endDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Search/Filter by facility id */
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  /** Search/Filter by Main Customer Rep */
  readonly mainCustomerRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/Filter by Order Tender Status */
  readonly orderTenderStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by first route stop address */
  readonly originStopAddress?: InputMaybe<AddressFilter>;
  /** Search/Filter by stop requested ship date */
  readonly requestedShipDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Search/Filter by Route Activation Status */
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by code or partial route code */
  readonly routeCode?: InputMaybe<Scalars['String']>;
  /** Search/Filter by Associated Order Rep */
  readonly associatedRepIds?: InputMaybe<RepIdFilter>;
  /** Search/Filter by facility Scheduling System Type */
  readonly schedulingSystemType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by start range beginning */
  readonly startDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Search/Filter by Route Life Cycle Status */
  readonly status?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by stop address */
  readonly stopAddress?: InputMaybe<AddressFilter>;
  /** Search/Filter by computed_current_appointments requested_start_date and requested_end_date */
  readonly stopDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Search/Filter by load_stop_types */
  readonly loadStopType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly stopType?: InputMaybe<ReadonlyArray<StopTypeEnum>>;
  /** Search/Filter by schedule_appointment_types */
  readonly scheduleAppointmentType?: InputMaybe<ReadonlyArray<ScheduleAppointmentTypeEnum>>;
  /** Search/Filter by schedule_drop_types */
  readonly scheduleDropType?: InputMaybe<ReadonlyArray<ScheduleDropTypeEnum>>;
};

export type StopsQueryInput = {
  /** Search/Filter by ComputedCurrentAppointment status */
  readonly appointmentStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by appt_req_with */
  readonly apptReqWith?: InputMaybe<Scalars['String']>;
  /** Search/Filter by Associated Order or Route Rep */
  readonly associatedRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/Filter by array of Customer Employee groups */
  readonly customerEmployeeGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by customer ids */
  readonly customerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/Filter by last route stop address */
  readonly destinationStopAddress?: InputMaybe<AddressesFilter>;
  /** Search/Filter by start range ending */
  readonly endDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Search/Filter by facility id */
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  /** Search/Filter by Main Customer Rep */
  readonly mainCustomerRepIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search/Filter by Order Tender Status */
  readonly orderTenderStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by first route stop address */
  readonly originStopAddress?: InputMaybe<AddressesFilter>;
  /** Search/Filter by stop requested ship date */
  readonly requestedShipDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Search/Filter by Route Activation Status */
  readonly routeActivationStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by code or partial route code */
  readonly routeCode?: InputMaybe<Scalars['String']>;
  /** Search/Filter route rep OR order rep ids */
  readonly routeRepOrOrderRepIds?: InputMaybe<RepIdsFilter>;
  /** Search/Filter by schedule_appointment_type */
  readonly scheduleAppointmentType?: InputMaybe<ReadonlyArray<ScheduleAppointmentTypeEnum>>;
  /** Search/Filter by schedule_drop_type */
  readonly scheduleDropType?: InputMaybe<ReadonlyArray<ScheduleDropTypeEnum>>;
  /** Search/Filter by facility Scheduling System Type */
  readonly schedulingSystemType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by start range beginning */
  readonly startDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Search/Filter by Route Life Cycle Status */
  readonly status?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Search/Filter by stop address */
  readonly stopAddress?: InputMaybe<AddressesFilter>;
  /** Search/Filter by computed_current_appointments requested_start_date and requested_end_date */
  readonly stopDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Search/Filter by stop_type */
  readonly stopType?: InputMaybe<ReadonlyArray<StopTypeEnum>>;
};

/** This object is not persisted. The purpose of this object is to provide information about a commodity as it pertains to a stop. */
export type StopTracking = {
  readonly __typename?: 'StopTracking';
  readonly stopId?: Maybe<Scalars['String']>;
  readonly stopSequence?: Maybe<Scalars['Float']>;
  readonly tracking?: Maybe<Scalars['String']>;
};

export enum StopTypeEnum {
  CrossDock = 'crossDock',
  OriginRamp = 'originRamp',
  DestinationRamp = 'destinationRamp',
  CustomsBroker = 'customsBroker',
  Yard = 'yard',
  Airport = 'airport',
  BorderCrossing = 'borderCrossing',
  ContainerDepot = 'containerDepot',
  Domicile = 'domicile',
  DriverComplianceTraining = 'driverComplianceTraining',
  Fuel = 'fuel',
  MaintenanceShop = 'maintenanceShop',
  Relay = 'relay',
  Scale = 'scale',
  SeaPort = 'seaPort',
  Terminal = 'terminal',
  TrailerWash = 'trailerWash',
  Transload = 'transload',
  Waypoint = 'waypoint',
  RailJunction = 'railJunction',
  Pu = 'PU',
  Del = 'Del',
  Ta = 'TA',
  Td = 'TD'
}

export enum StopTypeTerm {
  P = 'P',
  D = 'D'
}

export type StringType = {
  readonly __typename?: 'StringType';
  readonly value?: Maybe<Scalars['String']>;
};

export type SublocationUserError = {
  readonly __typename?: 'SublocationUserError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
};

export type SubRegionV2 = {
  readonly __typename?: 'SubRegionV2';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
};

export type SuperRegionV2 = {
  readonly __typename?: 'SuperRegionV2';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly regions?: Maybe<ReadonlyArray<Maybe<RegionV2>>>;
  readonly regionsetname?: Maybe<Scalars['String']>;
  readonly isregionsetactive?: Maybe<Scalars['Boolean']>;
  readonly regionNames?: Maybe<Scalars['String']>;
  readonly subRegionNames?: Maybe<Scalars['String']>;
};

export type SynchronizedCustomerType = {
  readonly __typename?: 'SynchronizedCustomerType';
  readonly code?: Maybe<Scalars['String']>;
  readonly customerId: Scalars['ID'];
};

export type SynchronizedVendorType = {
  readonly __typename?: 'SynchronizedVendorType';
  readonly carrierCode?: Maybe<Scalars['String']>;
  readonly vendorId: Scalars['ID'];
};

export type SyncUsersResponseObj = {
  readonly __typename?: 'syncUsersResponseObj';
  readonly success: Scalars['Boolean'];
  readonly error: Scalars['String'];
};

export type Target = {
  readonly __typename?: 'Target';
  readonly days?: Maybe<Scalars['Float']>;
  readonly loadedMiles?: Maybe<Scalars['Float']>;
  readonly emptyMiles?: Maybe<Scalars['Float']>;
  readonly revenue?: Maybe<Scalars['Float']>;
};

export type TargetInput = {
  readonly days?: InputMaybe<Scalars['Float']>;
  readonly loadedMiles?: InputMaybe<Scalars['Float']>;
  readonly emptyMiles?: InputMaybe<Scalars['Float']>;
  readonly revenue?: InputMaybe<Scalars['Float']>;
};

export type TariffData = {
  readonly __typename?: 'TariffData';
  readonly tariffName?: Maybe<Scalars['String']>;
  readonly tariffEffectiveDate?: Maybe<Scalars['DateTime']>;
  readonly discount?: Maybe<Scalars['Decimal']>;
  readonly tariffFloorMinimum?: Maybe<Scalars['Decimal']>;
  readonly minimumDiscount?: Maybe<Scalars['Decimal']>;
  readonly weightBreakStart?: Maybe<Scalars['Int']>;
  readonly weightBreakEnd?: Maybe<Scalars['Int']>;
  readonly tariffCurrency?: Maybe<Scalars['Decimal']>;
};

export enum TarpTypeEnum {
  /** Lumber */
  Lumber = 'lumber',
  /** None */
  None = 'none',
  /** Steel */
  Steel = 'steel'
}

export enum TarpTypesEnum {
  Lumber = 'lumber',
  Steel = 'steel'
}

/** The Task Detail */
export type Task = {
  readonly __typename?: 'Task';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly type: Scalars['String'];
  readonly status: Scalars['String'];
  readonly routeId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly requestedAt: Scalars['DateTime'];
  readonly requestedBy: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  /** @deprecated Use neededByV2, field became nullable */
  readonly neededBy: Scalars['DateTime'];
  readonly neededByV2?: Maybe<Scalars['DateTime']>;
  readonly assignedTo: Scalars['String'];
  readonly closedAt?: Maybe<Scalars['DateTime']>;
  readonly latestNote?: Maybe<Scalars['String']>;
  readonly notes: ReadonlyArray<TaskNote>;
  readonly groupId?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly comment?: Maybe<Scalars['String']>;
  readonly requestedByEmployee: Employee;
  readonly requestedByEmployeeNullable?: Maybe<Employee>;
  readonly assignedToEmployeeV2?: Maybe<Employee>;
  /** @deprecated Use assignedToEmployeeV2, field became nullable */
  readonly assignedToEmployee: Employee;
  readonly routeNullable?: Maybe<LoadRoute>;
  readonly route: LoadRoute;
  readonly routeV2?: Maybe<LoadRouteV2>;
  readonly vendorNullable?: Maybe<Carrier>;
  readonly vendor: Carrier;
};

export type TaskConnection = {
  readonly __typename?: 'TaskConnection';
  readonly edges: ReadonlyArray<TaskEdge>;
  readonly pageInfo: PageInfo;
};

export type TaskConnectionV2 = {
  readonly __typename?: 'TaskConnectionV2';
  readonly edges: ReadonlyArray<TaskEdgeV2>;
  readonly pageInfo: PageInfo;
};

export type TaskDateRangeInput = {
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
};

export type TaskEdge = {
  readonly __typename?: 'TaskEdge';
  readonly node: Task;
  readonly cursor: Scalars['String'];
};

export type TaskEdgeV2 = {
  readonly __typename?: 'TaskEdgeV2';
  readonly node: TaskV2;
  readonly cursor: Scalars['String'];
};

export type TaskNote = {
  readonly __typename?: 'TaskNote';
  readonly id: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly taskStatus: Scalars['String'];
  readonly note?: Maybe<Scalars['String']>;
};

export type TaskNoteV2 = {
  readonly __typename?: 'TaskNoteV2';
  readonly id: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly taskStatus: Scalars['String'];
  readonly note?: Maybe<Scalars['String']>;
};

export type TaskV2 = {
  readonly __typename?: 'TaskV2';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly type: Scalars['String'];
  readonly status: Scalars['String'];
  readonly routeId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly requestedAt: Scalars['DateTime'];
  readonly requestedBy: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly neededBy?: Maybe<Scalars['DateTime']>;
  readonly assignedTo?: Maybe<Scalars['String']>;
  readonly closedAt?: Maybe<Scalars['DateTime']>;
  readonly latestNote?: Maybe<Scalars['String']>;
  readonly assignedBy?: Maybe<Scalars['ID']>;
  readonly assignedAt?: Maybe<Scalars['DateTime']>;
  readonly actionBy?: Maybe<Scalars['ID']>;
  readonly actionAt?: Maybe<Scalars['DateTime']>;
  readonly notes: ReadonlyArray<TaskNoteV2>;
  readonly groupId?: Maybe<Scalars['String']>;
  readonly assignedToGroupId?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly comment?: Maybe<Scalars['String']>;
  /** @deprecated Moving to EmployeeV2, use requestedByEmployeeV2 */
  readonly requestedByEmployee: Employee;
  /** @deprecated Moving to EmployeeV2, use requestedByEmployeeNullableV2 */
  readonly requestedByEmployeeNullable?: Maybe<Employee>;
  /** @deprecated Moving to EmployeeV2, use assignedToEmployeeV3 */
  readonly assignedToEmployeeV2?: Maybe<Employee>;
  readonly requestedByEmployeeV2: EmployeeV2;
  readonly requestedByEmployeeNullableV2?: Maybe<EmployeeV2>;
  readonly assignedToEmployeeV3?: Maybe<EmployeeV2>;
  readonly routeV2?: Maybe<LoadRouteV2>;
  /** @deprecated Moving to CarrierV2, use vendorNullableV2 */
  readonly vendorNullable?: Maybe<Carrier>;
  /** @deprecated Moving to CarrierV2, use vendorV2 */
  readonly vendor: Carrier;
  readonly vendorNullableV2?: Maybe<CarrierV2>;
  readonly vendorV2: CarrierV2;
};

/** Taxability status and related Charge Type. */
export type TaxabilityChargeType = {
  readonly chargeType: Scalars['String'];
  readonly chargeTypeId: Scalars['ID'];
  readonly country: Scalars['String'];
  readonly defaultTaxability?: InputMaybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  readonly taxability: Scalars['String'];
};

/** Taxability status and related Charge Type. */
export type TaxabilityChargeTypeOutput = {
  readonly __typename?: 'TaxabilityChargeTypeOutput';
  readonly chargeType: Scalars['String'];
  readonly chargeTypeId: Scalars['ID'];
  readonly country: Scalars['String'];
  readonly defaultTaxability?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  readonly taxability: Scalars['String'];
};

export type TaxInterfaceCreateInput = {
  readonly chargeTypeId: Scalars['ID'];
  readonly taxType: TaxTypeEnum;
};

export type TaxInterfaceModel = {
  readonly __typename?: 'TaxInterfaceModel';
  readonly chargeType?: Maybe<ChargeType>;
  readonly chargeTypeExists?: Maybe<Scalars['Boolean']>;
  readonly chargeTypeId?: Maybe<Scalars['Uuid']>;
  readonly chargeTypeIsCredit?: Maybe<Scalars['Boolean']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Date']>;
  readonly eid?: Maybe<Scalars['Long']>;
  readonly hasPropertyMetadata: Scalars['Boolean'];
  readonly id?: Maybe<Scalars['Uuid']>;
  readonly propertyMetadata?: Maybe<ReadonlyArray<KeyValuePairOfStringAndPropertyMetadata>>;
  readonly taxType: TaxTypeEnum;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['Date']>;
};

export type TaxInterfaceUpdateInput = {
  readonly chargeTypeId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly taxType: TaxTypeEnum;
};

export enum TaxTypeEnum {
  /** Indicates that this is a country tax. */
  CountryTax = 'CountryTax',
  /** Indicates that this is a province tax. */
  ProvinceTax = 'ProvinceTax',
  /** Indicates that this is a harmonized tax. */
  HarmonizedTax = 'HarmonizedTax'
}

export type Temperature = {
  readonly __typename?: 'Temperature';
  readonly unit: TemperatureUnitTypeEnum;
  readonly value: Scalars['Float'];
};

export enum TemperatureUnitTypeEnum {
  F = 'f'
}

export enum TemperatureUnitTypeEnumV2 {
  F = 'f',
  C = 'c'
}

export type TemperatureV2 = {
  readonly __typename?: 'TemperatureV2';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type TemperatureV2Input = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type TemplateSchema = {
  readonly __typename?: 'TemplateSchema';
  readonly id?: Maybe<Scalars['String']>;
  readonly type: Scalars['String'];
  readonly required?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly properties: Scalars['String'];
};

export type TemplateSchemaInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly type: Scalars['String'];
  readonly required?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly properties: Scalars['String'];
};

export type TempLoad = {
  readonly __typename?: 'TempLoad';
  readonly cargoValue?: Maybe<Scalars['Float']>;
  readonly cargoValueCurrency: Scalars['String'];
  readonly commodities?: Maybe<Scalars['String']>;
  readonly consigneeName?: Maybe<Scalars['String']>;
  readonly customerName: Scalars['String'];
  readonly customerOperationsRep2Name?: Maybe<Scalars['String']>;
  readonly customerOperationsRepName?: Maybe<Scalars['String']>;
  readonly customerRepName?: Maybe<Scalars['String']>;
  readonly deliveryTimezone?: Maybe<Scalars['String']>;
  readonly destinationDeadheadInMiles?: Maybe<Scalars['Float']>;
  readonly destinationText?: Maybe<Scalars['String']>;
  readonly equipmentNeeded?: Maybe<Scalars['Json']>;
  readonly hazmat?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  readonly maxPay?: Maybe<Scalars['Float']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly originDeadheadInMiles?: Maybe<Scalars['Float']>;
  readonly originText?: Maybe<Scalars['String']>;
  readonly pickupTimezone: Scalars['String'];
  readonly requiredTruckTypes?: Maybe<Scalars['String']>;
  readonly revenovaDistanceInKilometers?: Maybe<Scalars['Float']>;
  readonly revenovaDistanceInMiles?: Maybe<Scalars['Float']>;
  readonly revenovaLoadId: Scalars['String'];
  readonly revenovaLoadNumber?: Maybe<Scalars['String']>;
  readonly shipperName?: Maybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly team?: Maybe<Scalars['Boolean']>;
  readonly trailerHeight?: Maybe<Scalars['Float']>;
  readonly trailerHeightUnit: Scalars['String'];
  readonly trailerLength?: Maybe<Scalars['Float']>;
  readonly trailerLengthUnit: Scalars['String'];
  readonly trailerWidth?: Maybe<Scalars['Float']>;
  readonly trailerWidthUnit: Scalars['String'];
  readonly utcDeliveryEndDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated Replaced by utc_delivery_end_date_time */
  readonly utcDeliveryEndTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly utcDeliveryStartDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated Replaced by utc_delivery_start_date_time */
  readonly utcDeliveryStartTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly utcPickupEndDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated Replaced by utc_pickup_end_date_time */
  readonly utcPickupEndTime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly utcPickupStartDateTime: Scalars['ISO8601DateTime'];
  /** @deprecated Replaced by utc_pickup_start_date_time */
  readonly utcPickupStartTime: Scalars['ISO8601DateTime'];
  readonly weight?: Maybe<Scalars['Float']>;
  readonly weightUnit?: Maybe<Scalars['String']>;
};

/** The connection type for TempLoad. */
export type TempLoadConnection = {
  readonly __typename?: 'TempLoadConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<TempLoadEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TempLoadEdge = {
  readonly __typename?: 'TempLoadEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: TempLoad;
};

export type TempLoadsFilter = {
  /** Search/filter customer name */
  readonly customer?: InputMaybe<Scalars['String']>;
  /** Search/filter customer name */
  readonly customerRepName?: InputMaybe<Scalars['String']>;
  /** Exact match on state */
  readonly destinationState?: InputMaybe<Scalars['String']>;
  /** Search/filter destination location (matches city & state) */
  readonly destinationText?: InputMaybe<Scalars['String']>;
  readonly onlyAvailable?: InputMaybe<Scalars['Boolean']>;
  /** Exact match on state */
  readonly originState?: InputMaybe<Scalars['String']>;
  /** Search/filter origin location (matches city & state) */
  readonly originText?: InputMaybe<Scalars['String']>;
  /** Search/filter load numbers */
  readonly revenovaLoadNumber?: InputMaybe<Scalars['String']>;
  readonly sort?: InputMaybe<Scalars['String']>;
  readonly timezone?: InputMaybe<Scalars['String']>;
  readonly truckType?: InputMaybe<Scalars['String']>;
  /** Delivery start range beginning */
  readonly utcDeliveryStartDatetimeEnd?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Delivery start range beginning */
  readonly utcDeliveryStartDatetimeStart?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Pickup start range beginning */
  readonly utcPickupStartDatetimeEnd?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Pickup start range beginning */
  readonly utcPickupStartDatetimeStart?: InputMaybe<Scalars['ISO8601DateTime']>;
};

export type TempLoadsInput = {
  readonly destination?: InputMaybe<Scalars['String']>;
  readonly origin?: InputMaybe<Scalars['String']>;
  readonly pickupStartDate?: InputMaybe<Scalars['String']>;
  readonly pickupStartDateEnd?: InputMaybe<Scalars['String']>;
};

export enum TempRemittanceGroupingEnum {
  /** Route */
  Route = 'Route',
  /** Combined */
  Combined = 'Combined'
}

export type TenantChargeTypeConfigurationCreateInput = {
  readonly carrierGlCode?: InputMaybe<Scalars['String']>;
  readonly category?: InputMaybe<Scalars['String']>;
  readonly cost?: InputMaybe<Scalars['Decimal']>;
  readonly costPerUnitType?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly customerGlCode?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly eligibleForFuel: Scalars['Boolean'];
  readonly excludeFromMargins: Scalars['Boolean'];
  readonly isActive: Scalars['Boolean'];
  readonly isCredit: Scalars['Boolean'];
  readonly isGlobal: Scalars['Boolean'];
  readonly maximumCost?: InputMaybe<Scalars['Decimal']>;
  readonly maximumRate?: InputMaybe<Scalars['Decimal']>;
  readonly minimumCost?: InputMaybe<Scalars['Decimal']>;
  readonly minimumRate?: InputMaybe<Scalars['Decimal']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly rate?: InputMaybe<Scalars['Decimal']>;
  readonly ratePerUnitType?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly segment?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly usedOn: CtcUsedOnEnum;
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
};

export type TenantChargeTypeConfigurationResponse = {
  readonly __typename?: 'TenantChargeTypeConfigurationResponse';
  readonly carrierGlCode?: Maybe<Scalars['String']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly cost?: Maybe<Scalars['Decimal']>;
  readonly costPerUnitType?: Maybe<CtcPerUnitTypeEnum>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['DateTime'];
  readonly customerGlCode?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly eligibleForFuel: Scalars['Boolean'];
  readonly excludeFromMargins: Scalars['Boolean'];
  readonly isActive: Scalars['Boolean'];
  readonly isCredit: Scalars['Boolean'];
  readonly isGlobal: Scalars['Boolean'];
  readonly maximumCost?: Maybe<Scalars['Decimal']>;
  readonly maximumRate?: Maybe<Scalars['Decimal']>;
  readonly minimumCost?: Maybe<Scalars['Decimal']>;
  readonly minimumRate?: Maybe<Scalars['Decimal']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly rate?: Maybe<Scalars['Decimal']>;
  readonly ratePerUnitType?: Maybe<CtcPerUnitTypeEnum>;
  readonly segment?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn: Scalars['DateTime'];
  readonly usedOn: CtcUsedOnEnum;
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
};

export type TenantChargeTypeConfigurationUpdateInput = {
  readonly carrierGlCode?: InputMaybe<Scalars['String']>;
  readonly category?: InputMaybe<Scalars['String']>;
  readonly cost?: InputMaybe<Scalars['Decimal']>;
  readonly costPerUnitType?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly customerGlCode?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly eligibleForFuel: Scalars['Boolean'];
  readonly excludeFromMargins: Scalars['Boolean'];
  readonly isActive: Scalars['Boolean'];
  readonly isGlobal: Scalars['Boolean'];
  readonly maximumCost?: InputMaybe<Scalars['Decimal']>;
  readonly maximumRate?: InputMaybe<Scalars['Decimal']>;
  readonly minimumCost?: InputMaybe<Scalars['Decimal']>;
  readonly minimumRate?: InputMaybe<Scalars['Decimal']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly rate?: InputMaybe<Scalars['Decimal']>;
  readonly ratePerUnitType?: InputMaybe<CtcPerUnitTypeEnum>;
  readonly segment?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Scalars['String']>;
  readonly chargeTypeId: Scalars['Guid'];
  readonly code?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
};

/** Tenant-specific configurations */
export type TenantConfig = {
  readonly __typename?: 'TenantConfig';
  readonly buildNumber?: Maybe<Scalars['String']>;
  /** @deprecated Refrain from using TenantConfigType to obtain the current user (unless absolutely necessary), this has been flagged as technical debt and will be moved into its own query. */
  readonly currentEmployee?: Maybe<Employee>;
  /** Data dictionary source */
  readonly dataDictionarySource?: Maybe<Scalars['String']>;
  /** A collection of tenant-configurable options, such as ContactType or AddressType. */
  readonly types: ReadonlyArray<KeyValueCollection>;
};

export type TenantConfiguration = {
  readonly __typename?: 'TenantConfiguration';
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['String']>;
  readonly metaDataJson?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly sort?: Maybe<Scalars['Int']>;
  readonly systemDefined?: Maybe<Scalars['Boolean']>;
};

export type TenantConfigurationResult = {
  readonly __typename?: 'TenantConfigurationResult';
  readonly domain: EntityConfigurationDomain;
  readonly settings: EntityConfigurationSettings;
  readonly lastUpdatedTimestamp?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
};

export type TenantConfigurationsModel = {
  readonly __typename?: 'TenantConfigurationsModel';
  readonly calculateVatCheckedOption?: Maybe<CalculateVatCheckedOptionEnumOutputType>;
  readonly createAdjustmentItemsCheckedOption?: Maybe<CreateAdjustmentItemsCheckedOptionEnum>;
  readonly defaultVoucherDateOption?: Maybe<DefaultVoucherDateOptionEnum>;
};

export type TenantExceptionRecord = {
  readonly __typename?: 'TenantExceptionRecord';
  readonly id: Scalars['ID'];
  readonly routeID: Scalars['String'];
  readonly orderID: Scalars['String'];
  readonly customerID: Scalars['String'];
  readonly carrierID?: Maybe<Scalars['String']>;
  readonly type: TenantExceptionRecordType;
  readonly status: TenantExceptionRecordStatus;
  readonly source: TenantExceptionRecordSource;
  readonly requirementName: Scalars['String'];
  readonly assignedTo?: Maybe<Scalars['String']>;
  readonly assignedToGroup?: Maybe<Scalars['String']>;
  readonly assignedBy?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedBy: Scalars['String'];
  readonly closedAt?: Maybe<Scalars['DateTime']>;
  readonly loadRouteV2: LoadRouteV2;
  readonly customerOrderV2: CustomerOrderV2;
  readonly customerV2: CustomerV2;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly assignedToEmployee?: Maybe<Employee>;
  readonly assignedByEmployee?: Maybe<Employee>;
  readonly updatedByEmployee: Employee;
};

export type TenantExceptionRecordFilterInput = {
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly routeIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly orderIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly types?: InputMaybe<ReadonlyArray<TenantExceptionRecordType>>;
  readonly statuses?: InputMaybe<ReadonlyArray<TenantExceptionRecordStatus>>;
  readonly sources?: InputMaybe<ReadonlyArray<TenantExceptionRecordSource>>;
  readonly requirementNames?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedToIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedToGroupIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedByIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly pickUpDateRange?: InputMaybe<GetExceptionByDateRangeInput>;
  readonly deliveryDateRange?: InputMaybe<GetExceptionByDateRangeInput>;
  readonly exceptionClosedDateRange?: InputMaybe<GetExceptionByDateRangeInput>;
};

export enum TenantExceptionRecordSource {
  Order = 'ORDER',
  Route = 'ROUTE'
}

export enum TenantExceptionRecordStatus {
  Open = 'OPEN',
  Closed = 'CLOSED'
}

export enum TenantExceptionRecordType {
  Fuel = 'FUEL',
  Incident = 'INCIDENT',
  Ppw = 'PPW',
  CommodityReq = 'COMMODITY_REQ'
}

export type Tender = {
  readonly __typename?: 'Tender';
  readonly id: Scalars['ID'];
  readonly tenderStatus?: Maybe<TenderStatusEnum>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly expiresAt?: Maybe<Scalars['DateTime']>;
  readonly tenderedAt?: Maybe<Scalars['DateTime']>;
  readonly respondedAt?: Maybe<Scalars['DateTime']>;
  readonly ordinal?: Maybe<Scalars['Int']>;
  readonly tenderOptions?: Maybe<TenderOptions>;
  readonly lineItems: ReadonlyArray<Maybe<TenderLineItem>>;
  readonly carrierCost?: Maybe<Scalars['Decimal']>;
  readonly tenderValidationErrors?: Maybe<ReadonlyArray<Maybe<TenderValidationError>>>;
  readonly respondedByContact?: Maybe<TenderContact>;
};

export type TenderBusinessDay = {
  readonly __typename?: 'TenderBusinessDay';
  readonly dayOfWeek?: Maybe<TenderDayOfWeekEnum>;
  readonly opensAtMilliseconds?: Maybe<Scalars['Long']>;
  readonly closesAtMilliseconds?: Maybe<Scalars['Long']>;
};

export type TenderBusinessDaysInput = {
  readonly dayOfWeek: TenderDayOfWeekEnum;
  readonly opensAtMilliseconds: Scalars['Long'];
  readonly closesAtMilliseconds: Scalars['Long'];
};

export type TenderContact = {
  readonly __typename?: 'TenderContact';
  readonly contact?: Maybe<Contact>;
  readonly contactEmailOverride?: Maybe<Scalars['String']>;
  readonly contactPhoneNumberOverride?: Maybe<Scalars['String']>;
  readonly contactFullNameOverride?: Maybe<Scalars['String']>;
};

export type TenderContactInput = {
  readonly contactId: Scalars['ID'];
  readonly contactEmailOverride?: InputMaybe<Scalars['String']>;
  readonly contactPhoneNumberOverride?: InputMaybe<Scalars['String']>;
  readonly contactFullNameOverride?: InputMaybe<Scalars['String']>;
};

export enum TenderDayOfWeekEnum {
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY'
}

export type TenderLineItem = {
  readonly __typename?: 'TenderLineItem';
  readonly chargeType?: Maybe<ChargeType>;
  readonly chargeTypeV2?: Maybe<ChargeTypeV2>;
  readonly units?: Maybe<Scalars['Decimal']>;
  readonly value?: Maybe<Scalars['Decimal']>;
  readonly currency?: Maybe<Scalars['String']>;
};

export type TenderOptions = {
  readonly __typename?: 'TenderOptions';
  readonly id: Scalars['ID'];
  readonly tenderModeTerm?: Maybe<Scalars['String']>;
  readonly tenderContacts?: Maybe<ReadonlyArray<Maybe<TenderContact>>>;
  readonly businessTimeZoneTerm?: Maybe<Scalars['String']>;
  readonly businessDays?: Maybe<ReadonlyArray<Maybe<TenderBusinessDay>>>;
  readonly tenderExpirationTimeSpan?: Maybe<TenderTimeSpan>;
  readonly tenderLeadTimeSpan?: Maybe<TenderTimeSpan>;
  readonly createdByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedByUser?: Maybe<User>;
  readonly lastUpdatedByUserV2?: Maybe<UserV2>;
  readonly lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  readonly parentObjectType?: Maybe<Scalars['String']>;
  readonly parentObjectId?: Maybe<Scalars['ID']>;
  readonly autoTender?: Maybe<Scalars['Boolean']>;
};

export type TenderOptionsBusinessDaysInput = {
  readonly dayOfWeek: TenderOptionsDayOfWeekEnum;
  readonly opensAtMilliseconds: Scalars['Long'];
  readonly closesAtMilliseconds: Scalars['Long'];
};

export type TenderOptionsContactInput = {
  readonly contactId?: InputMaybe<Scalars['ID']>;
  readonly contactEmailOverride?: InputMaybe<Scalars['String']>;
  readonly contactPhoneNumberOverride?: InputMaybe<Scalars['String']>;
};

export enum TenderOptionsDayOfWeekEnum {
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY'
}

export type TenderOptionsInput = {
  readonly tenderModeTerm?: InputMaybe<Scalars['String']>;
  readonly tenderContacts?: InputMaybe<ReadonlyArray<InputMaybe<TenderOptionsContactInput>>>;
  readonly businessTimeZoneTerm?: InputMaybe<Scalars['String']>;
  readonly businessDays?: InputMaybe<ReadonlyArray<InputMaybe<TenderOptionsBusinessDaysInput>>>;
  readonly tenderExpirationTimeSpan?: InputMaybe<TenderOptionsTimeSpanInput>;
  readonly tenderLeadTimeSpan?: InputMaybe<TenderOptionsTimeSpanInput>;
  readonly autoTender?: InputMaybe<Scalars['Boolean']>;
};

export type TenderOptionsTimeSpanInput = {
  readonly value: Scalars['Decimal'];
  readonly units: TenderOptionsTimeSpanUnitEnum;
};

export enum TenderOptionsTimeSpanUnitEnum {
  Minutes = 'MINUTES',
  Hours = 'HOURS'
}

export type TenderPlan = {
  readonly __typename?: 'TenderPlan';
  readonly id: Scalars['ID'];
  readonly routingGuide?: Maybe<RoutingGuide>;
  readonly createdByUser?: Maybe<User>;
  readonly startedByUser?: Maybe<User>;
  readonly stoppedByUser?: Maybe<User>;
  readonly lastUpdatedByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  readonly startedByUserV2?: Maybe<UserV2>;
  readonly stoppedByUserV2?: Maybe<UserV2>;
  readonly lastUpdatedByUserV2?: Maybe<UserV2>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly startedAt?: Maybe<Scalars['DateTime']>;
  readonly stoppedAt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  readonly tenderPlanStatus?: Maybe<TenderPlanStatusEnum>;
  readonly tenders: ReadonlyArray<Maybe<Tender>>;
  readonly tenderPlanTypeTerm?: Maybe<Scalars['String']>;
  readonly route?: Maybe<LoadRouteV2>;
  readonly statusReasons?: Maybe<ReadonlyArray<Maybe<TenderPlanStatusReason>>>;
};

export enum TenderPlanStatusEnum {
  Building = 'BUILDING',
  AwaitingExecution = 'AWAITING_EXECUTION',
  Executing = 'EXECUTING',
  Stopped = 'STOPPED',
  Exhausted = 'EXHAUSTED',
  Fulfilled = 'FULFILLED',
  Invalid = 'INVALID',
  Canceled = 'CANCELED',
  Ineligible = 'INELIGIBLE',
  BookingPending = 'BOOKING_PENDING',
  UnableToBook = 'UNABLE_TO_BOOK'
}

export type TenderPlanStatusReason = {
  readonly __typename?: 'TenderPlanStatusReason';
  readonly id: Scalars['ID'];
  readonly reason?: Maybe<Scalars['String']>;
  readonly tenderPlanId: Scalars['ID'];
};

export enum TenderStatusEnum {
  Created = 'CREATED',
  Ineligible = 'INELIGIBLE',
  Skipped = 'SKIPPED',
  Tendered = 'TENDERED',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  TimedOut = 'TIMED_OUT',
  UnableToTender = 'UNABLE_TO_TENDER',
  Rescinded = 'RESCINDED',
  GaveBack = 'GAVE_BACK',
  Eligible = 'ELIGIBLE',
  OverCapacity = 'OVER_CAPACITY'
}

export type TenderTimeSpan = {
  readonly __typename?: 'TenderTimeSpan';
  readonly value?: Maybe<Scalars['Decimal']>;
  readonly units?: Maybe<TenderTimeSpanUnitEnum>;
};

export type TenderTimeSpanInput = {
  readonly value: Scalars['Decimal'];
  readonly units: TenderTimeSpanUnitEnum;
};

export enum TenderTimeSpanUnitEnum {
  Minutes = 'MINUTES',
  Hours = 'HOURS'
}

export type TenderValidationError = {
  readonly __typename?: 'TenderValidationError';
  readonly message?: Maybe<Scalars['String']>;
};

export type TestTypeWithJson = {
  readonly __typename?: 'TestTypeWithJson';
  readonly status?: Maybe<Scalars['String']>;
  readonly bodyJSON?: Maybe<Scalars['JSON']>;
  readonly bodyJSONObject?: Maybe<Scalars['JSONObject']>;
};

export type TestValidatedHeaderType = {
  readonly __typename?: 'TestValidatedHeaderType';
  readonly initiatedViaValidationPassed?: Maybe<Scalars['Boolean']>;
  readonly initiatedIdValidationPassed?: Maybe<Scalars['Boolean']>;
  readonly initiatedTimestampValidationPassed?: Maybe<Scalars['Boolean']>;
};

export type TextEntity = {
  readonly __typename?: 'TextEntity';
  readonly id: Scalars['String'];
  readonly sentDate: Scalars['DateTime'];
  readonly notificationType: NotificationType;
  readonly sender: Scalars['String'];
  readonly receivedFlag: Scalars['Boolean'];
  readonly receiver: Scalars['String'];
  readonly phoneNumbers: ReadonlyArray<PhoneNumber>;
};

export type TimelineEvent = {
  readonly __typename?: 'TimelineEvent';
  readonly id: Scalars['ID'];
  readonly entryTerm?: Maybe<KeyValue>;
  readonly typeTerm?: Maybe<KeyValue>;
  readonly load: Load;
  readonly driverAssignment?: Maybe<DriverAssignment>;
  readonly route?: Maybe<LoadRoute>;
  readonly carrier?: Maybe<Carrier>;
  readonly stop?: Maybe<Stop>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly plannedDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly plannedTimezone?: Maybe<Scalars['String']>;
  readonly actualDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly actualTimezone?: Maybe<Scalars['String']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly note?: Maybe<Scalars['String']>;
  readonly driverNames: ReadonlyArray<Scalars['String']>;
  readonly trailerNumbers: ReadonlyArray<Scalars['String']>;
  readonly notifyUsers: ReadonlyArray<User>;
  readonly createdDatetime: Scalars['ISO8601DateTime'];
  readonly createdBy: User;
  readonly updatedDatetime: Scalars['ISO8601DateTime'];
  readonly updatedBy: User;
};

export type TimelineEventConnection = {
  readonly __typename?: 'TimelineEventConnection';
  readonly edges: ReadonlyArray<TimelineEventEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type TimelineEventConnectionV2 = {
  readonly __typename?: 'TimelineEventConnectionV2';
  readonly edges: ReadonlyArray<TimelineEventV2Edge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type TimelineEventEdge = {
  readonly __typename?: 'TimelineEventEdge';
  readonly cursor: Scalars['String'];
  readonly node: TimelineEvent;
};

export type TimelineEventFilter = {
  readonly loadID?: InputMaybe<Scalars['ID']>;
};

export type TimelineEventFilterV2 = {
  readonly loadID?: InputMaybe<Scalars['ID']>;
};

export type TimelineEventV2 = {
  readonly __typename?: 'TimelineEventV2';
  readonly id: Scalars['ID'];
  readonly entryTerm?: Maybe<KeyValue>;
  readonly typeTerm?: Maybe<KeyValue>;
  readonly load: LoadV2;
  readonly driverAssignment?: Maybe<DriverAssignment>;
  readonly route?: Maybe<LoadRouteV2>;
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  readonly stop?: Maybe<RouteStop>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly plannedDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly plannedTimezone?: Maybe<Scalars['String']>;
  readonly actualDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly actualTimezone?: Maybe<Scalars['String']>;
  readonly details?: Maybe<Scalars['String']>;
  readonly note?: Maybe<Scalars['String']>;
  readonly driverNames: ReadonlyArray<Scalars['String']>;
  readonly trailerNumbers: ReadonlyArray<Scalars['String']>;
  readonly notifyUsers: ReadonlyArray<User>;
  readonly notifyUsersV2: ReadonlyArray<UserV2>;
  readonly createdDatetime: Scalars['ISO8601DateTime'];
  readonly createdBy: User;
  readonly createdByV2: UserV2;
  readonly updatedDatetime: Scalars['ISO8601DateTime'];
  readonly updatedBy: User;
  readonly updatedByV2: UserV2;
};

export type TimelineEventV2Edge = {
  readonly __typename?: 'TimelineEventV2Edge';
  readonly cursor: Scalars['String'];
  readonly node: TimelineEventV2;
};

export type TimeOffLocationInput = {
  readonly start?: InputMaybe<TimeOffLocationPlaceInput>;
  readonly end?: InputMaybe<TimeOffLocationPlaceInput>;
};

export type TimeOffLocationOutput = {
  readonly __typename?: 'TimeOffLocationOutput';
  readonly start?: Maybe<TimeOffLocationPlaceOutput>;
  readonly end?: Maybe<TimeOffLocationPlaceOutput>;
};

export type TimeOffLocationPlaceInput = {
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
};

export type TimeOffLocationPlaceOutput = {
  readonly __typename?: 'TimeOffLocationPlaceOutput';
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
};

export type TimezoneComponent = {
  readonly __typename?: 'TimezoneComponent';
  readonly timezoneId?: Maybe<Scalars['String']>;
  readonly generic?: Maybe<Scalars['String']>;
  readonly standard?: Maybe<Scalars['String']>;
  readonly daylight?: Maybe<Scalars['String']>;
  readonly tag?: Maybe<Scalars['String']>;
  readonly standardOffset?: Maybe<Scalars['String']>;
  readonly daylightSavings?: Maybe<Scalars['String']>;
};

export type TimezoneResponse = {
  readonly __typename?: 'TimezoneResponse';
  readonly lat?: Maybe<Scalars['Float']>;
  readonly lon?: Maybe<Scalars['Float']>;
  readonly timezones?: Maybe<ReadonlyArray<TimezoneComponent>>;
};

export type Tour = {
  readonly __typename?: 'Tour';
  readonly solutionId?: Maybe<Scalars['Int']>;
  readonly legCount?: Maybe<Scalars['Int']>;
  readonly firstStopDateRange?: Maybe<DateTimeRangeWithTimeZone>;
  readonly originCity?: Maybe<Scalars['String']>;
  readonly originState?: Maybe<Scalars['String']>;
  readonly lastStopDateRange?: Maybe<DateTimeRangeWithTimeZone>;
  readonly destinationCity?: Maybe<Scalars['String']>;
  readonly destinationState?: Maybe<Scalars['String']>;
  readonly loadedDistance?: Maybe<Scalars['Int']>;
  readonly totalDeadheadDistance?: Maybe<Scalars['Int']>;
  readonly originDeadheadDistance?: Maybe<Scalars['Int']>;
  readonly destinationDeadheadDistance?: Maybe<Scalars['Int']>;
  readonly isRoundTrip?: Maybe<Scalars['Boolean']>;
  readonly routes?: Maybe<ReadonlyArray<Maybe<AvailableRoute>>>;
};

export type TourSearchInput = {
  readonly truckPostingId?: InputMaybe<Scalars['String']>;
  readonly originRadius?: InputMaybe<WithinRadiusInput>;
  readonly destinationRadius?: InputMaybe<WithinRadiusInput>;
  readonly readyByDate?: InputMaybe<Scalars['DateTime']>;
  readonly loadByDate?: InputMaybe<Scalars['DateTime']>;
  readonly finalByDate?: InputMaybe<Scalars['DateTime']>;
  readonly trailerTypeName?: InputMaybe<Scalars['String']>;
  readonly trailerLength?: InputMaybe<Scalars['Int']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly Division?: InputMaybe<Scalars['String']>;
  readonly destinationStates?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly intrastateAdminAreas?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type TrackingDetailsV2 = {
  readonly __typename?: 'TrackingDetailsV2';
  readonly latestTrackingUpdate?: Maybe<TrackingUpdateV4>;
  /** @deprecated causing major performance trouble use trackingUpdatesV3 query and load separately */
  readonly trackingUpdates: ReadonlyArray<TrackingUpdateV4>;
  readonly driverAssignment?: Maybe<DriverAssignment>;
};

export type TrackingDeviceInput = {
  readonly deviceTerm?: InputMaybe<Scalars['String']>;
  readonly modelTerm?: InputMaybe<Scalars['String']>;
};

export type TrackingEta = {
  readonly __typename?: 'TrackingETA';
  readonly id: Scalars['ID'];
  readonly route: LoadRouteV2;
  readonly trackingUpdate?: Maybe<TrackingUpdateV4>;
  readonly autoETADatetime?: Maybe<Scalars['DateTime']>;
  readonly manualETADatetime?: Maybe<Scalars['DateTime']>;
  readonly nextStop: Stop;
  readonly nextRouteStop: RouteStop;
  readonly nextRouteStopV2: RouteStopV2;
  readonly nextStopTimezone?: Maybe<Scalars['String']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly isDataScienceFormula?: Maybe<Scalars['Boolean']>;
};

/** Estimated Time of Arrival to next stop */
export type TrackingEtaCalculation = {
  readonly __typename?: 'TrackingETACalculation';
  readonly eta: Scalars['DateTime'];
  readonly nextStopTimezone?: Maybe<Scalars['String']>;
  readonly isDataScienceFormula?: Maybe<Scalars['Boolean']>;
};

export type TrackingEtaForStop = {
  readonly __typename?: 'TrackingETAForStop';
  readonly route: LoadRouteV2;
  readonly nextStop: RouteStop;
  readonly nextStopV2: RouteStopV2;
  readonly autoETADatetime?: Maybe<Scalars['DateTime']>;
  readonly manualETADatetime?: Maybe<Scalars['DateTime']>;
  readonly isDataScienceFormula?: Maybe<Scalars['Boolean']>;
};

export type TrackingSystem = {
  readonly __typename?: 'TrackingSystem';
  readonly id: Scalars['ID'];
  readonly displayName: Scalars['String'];
};

export type TrackingUpdateAddressV4 = {
  readonly __typename?: 'TrackingUpdateAddressV4';
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
};

export type TrackingUpdateInputV4 = {
  readonly employeeID: Scalars['ID'];
  readonly routeID: Scalars['ID'];
  readonly carrierID: Scalars['ID'];
  readonly actionTypeID: Scalars['ID'];
  readonly infoFromID: Scalars['ID'];
  readonly delayReasonID?: InputMaybe<Scalars['ID']>;
  readonly currentLocation?: InputMaybe<TrackingUpdateLocationInputV4>;
  readonly checkCallDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly checkCallTimezone?: InputMaybe<Scalars['String']>;
  readonly etaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly etaTimezone?: InputMaybe<Scalars['String']>;
  readonly nextLocation?: InputMaybe<TrackingUpdateLocationInputV4>;
  readonly trailerTemp?: InputMaybe<TemperatureV2Input>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly sourceCreatedDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly sourceCreatedTimezone?: InputMaybe<Scalars['String']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly nextStopID?: InputMaybe<Scalars['ID']>;
  readonly sourceFrom?: InputMaybe<Scalars['String']>;
  readonly notifyUserIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type TrackingUpdateLocationInputV4 = {
  readonly city?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Scalars['String']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly latitude?: InputMaybe<Scalars['Float']>;
  readonly longitude?: InputMaybe<Scalars['Float']>;
  readonly displayString?: InputMaybe<Scalars['String']>;
};

export type TrackingUpdateV4 = {
  readonly __typename?: 'TrackingUpdateV4';
  readonly id: Scalars['ID'];
  readonly route: LoadRouteV2;
  readonly carrier: Carrier;
  readonly carrierV2: CarrierV2;
  readonly createdBy: Employee;
  readonly createdByV2: EmployeeV2;
  readonly createdDatetime: Scalars['ISO8601DateTime'];
  readonly sourceCreatedDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly sourceCreatedTimezone?: Maybe<Scalars['String']>;
  readonly actionType?: Maybe<KeyValue>;
  readonly infoFrom?: Maybe<KeyValue>;
  readonly delayReason?: Maybe<KeyValue>;
  readonly currentLocation?: Maybe<Location>;
  readonly currentLocationAddress?: Maybe<TrackingUpdateAddressV4>;
  readonly checkCallDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly etaDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly etaTimezone?: Maybe<Scalars['String']>;
  readonly nextLocation?: Maybe<Location>;
  readonly nextStop?: Maybe<RouteStop>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly trailerNumber2?: Maybe<Scalars['String']>;
  readonly trailerNumber3?: Maybe<Scalars['String']>;
  readonly trailerTemp?: Maybe<TemperatureV2>;
  readonly routeStopID?: Maybe<Scalars['ID']>;
  readonly note?: Maybe<Scalars['String']>;
  readonly tenantID: Scalars['String'];
  /** @deprecated Removed in Rails decommission -- use notifyUsersV2 */
  readonly notifyUsers?: Maybe<ReadonlyArray<User>>;
  readonly notifyUsersV2?: Maybe<ReadonlyArray<UserV2>>;
  /** @deprecated No longer used. Please Use trackingAutoETADatetime */
  readonly autoETADatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly isETAManual: Scalars['Boolean'];
  readonly isETAVersion1: Scalars['Boolean'];
  readonly driverElectronicTrackingTypeID?: Maybe<Scalars['String']>;
  readonly driverNames?: Maybe<Scalars['String']>;
  readonly tractorNumber?: Maybe<Scalars['String']>;
  readonly trackingAutoETADatetime?: Maybe<Scalars['DateTime']>;
  readonly trackingManualETADatetime?: Maybe<Scalars['DateTime']>;
  readonly trackingManualETACreatedBy?: Maybe<Employee>;
  readonly trackingManualETACreatedByV2?: Maybe<EmployeeV2>;
  readonly trackingAutoETASource?: Maybe<Scalars['String']>;
  readonly trackingAutoETAUsesDataScienceFormula?: Maybe<Scalars['Boolean']>;
};

export type TrackingUpdateV4Connection = {
  readonly __typename?: 'TrackingUpdateV4Connection';
  readonly edges: ReadonlyArray<TrackingUpdateV4Edge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type TrackingUpdateV4Edge = {
  readonly __typename?: 'TrackingUpdateV4Edge';
  readonly cursor: Scalars['String'];
  readonly node: TrackingUpdateV4;
};

export type TrackingUpdateV4Filter = {
  readonly routeID?: InputMaybe<Scalars['ID']>;
  readonly routeIDs?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Currently does nothing */
  readonly hideDuplicateTech?: InputMaybe<Scalars['Boolean']>;
  readonly infoFromIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** this is a temporary type until we can reference users across the graph */
export type TrackingUserMeta = {
  readonly __typename?: 'TrackingUserMeta';
  readonly userID: Scalars['ID'];
  readonly displayName: Scalars['String'];
};

/** this is a temporary type until we can reference users across the graph */
export type TrackingUserMetaInput = {
  readonly userID: Scalars['ID'];
  readonly displayName?: InputMaybe<Scalars['String']>;
};

export type Trailer = {
  readonly __typename?: 'Trailer';
  readonly id: Scalars['ID'];
  readonly trailerID: Scalars['String'];
  readonly sortIndex: Scalars['Float'];
  readonly trailerValidationResponse?: Maybe<Scalars['String']>;
  readonly trailerValidationMessage?: Maybe<Scalars['String']>;
  readonly trailerValidationLastUpdate?: Maybe<Scalars['DateTime']>;
};

export type TrailerDeviceInput = {
  readonly deviceTerm?: InputMaybe<Scalars['String']>;
  readonly modelTerm?: InputMaybe<Scalars['String']>;
};

export type TrailerDeviceOutput = {
  readonly __typename?: 'TrailerDeviceOutput';
  readonly deviceTerm?: Maybe<Scalars['String']>;
  readonly modelTerm?: Maybe<Scalars['String']>;
};

export type TrailerDimensions = {
  readonly minimumLength?: InputMaybe<SniQuoteDimensions>;
  readonly width?: InputMaybe<SniQuoteDimensions>;
  readonly height?: InputMaybe<SniQuoteDimensions>;
  readonly length?: InputMaybe<SniQuoteDimensions>;
};

export type TrailerDriverOutput = {
  readonly __typename?: 'TrailerDriverOutput';
  readonly driverId: Scalars['ID'];
  /** @deprecated driverIdentityId is deprecated. Use code instead. */
  readonly driverIdentityId?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly trailerDriverMapping?: Maybe<ReadonlyArray<AssetTrailersPermanentDriverOutput>>;
};

export type TrailerInput = {
  readonly trailerID: Scalars['String'];
  readonly trailerIndex?: InputMaybe<Scalars['Float']>;
  readonly trailerValidationResponse?: InputMaybe<Scalars['String']>;
  readonly trailerValidationMessage?: InputMaybe<Scalars['String']>;
};

export type TrailerTractorOutput = {
  readonly __typename?: 'TrailerTractorOutput';
  readonly powerId: Scalars['ID'];
  /** @deprecated powerNumber is deprecated. Use code instead. */
  readonly powerNumber?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  /** @deprecated type is deprecated. Use typeTerm instead. */
  readonly type?: Maybe<Scalars['String']>;
  readonly typeTerm?: Maybe<Scalars['String']>;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  readonly trailerTractorMapping?: Maybe<ReadonlyArray<AssetTrailersPermanentTractorOutput>>;
};

export type TrailerTypeError = {
  readonly __typename?: 'TrailerTypeError';
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly code?: Maybe<Scalars['String']>;
};

export type TrailerValidation = {
  readonly __typename?: 'TrailerValidation';
  readonly hasTrailerNumber?: Maybe<Scalars['Boolean']>;
};

export type TrailerValidationArgumentInput = {
  readonly orderCode: Scalars['String'];
  readonly routeNumber: Scalars['String'];
  readonly activityDttm: Scalars['DateTime'];
  readonly facilityCode: Scalars['String'];
  readonly activityType: Scalars['String'];
  readonly stopSequence: Scalars['String'];
  readonly stopExternalId: Scalars['String'];
  readonly trailerNumber: Scalars['String'];
  readonly equipmentType?: InputMaybe<Scalars['String']>;
  readonly shipmentLegSequenceNumber?: InputMaybe<Scalars['String']>;
};

export type TrailerValidationResponse = {
  readonly __typename?: 'TrailerValidationResponse';
  readonly validationCode: Scalars['String'];
  readonly validationMessage: Scalars['String'];
  readonly validationResult: Scalars['String'];
};

export enum TruckAvailableDayOffSetEnum {
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY'
}

export type TruckEntry = {
  readonly __typename?: 'TruckEntry';
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUserId: Scalars['ID'];
  readonly destinationDeadhead?: Maybe<UnitOfDistance>;
  readonly destinationLocation?: Maybe<TruckLocation>;
  /**
   * destinationRegionUrns is no longer supported, null will be returned until this field is removed
   * @deprecated destinationRegionUrns is no longer supported, null will be returned until this field is removed
   */
  readonly destinationRegionUrns?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly id: Scalars['ID'];
  readonly isHazmat?: Maybe<Scalars['Boolean']>;
  readonly isTeam?: Maybe<Scalars['Boolean']>;
  readonly originDeadhead: UnitOfDistance;
  readonly originLocation: TruckLocation;
  readonly ownerId?: Maybe<Scalars['ID']>;
  readonly readyTimezone?: Maybe<Scalars['String']>;
  readonly sourceId?: Maybe<Scalars['String']>;
  readonly trailerSizeLength?: Maybe<UnitOfDistance>;
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedByUserId: Scalars['ID'];
  readonly bracingType?: Maybe<BracingTypesEnum>;
  readonly bracingCount?: Maybe<Scalars['Int']>;
  readonly driverName?: Maybe<Scalars['String']>;
  /** Expiration time for truck entries created through external source */
  readonly expireAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly externalSourceId?: Maybe<Scalars['String']>;
  /** Tracks when the entity was last updated by an external source, such as DAT or TruckerTools */
  readonly externalUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly externalUpdatedByUserId?: Maybe<Scalars['ID']>;
  readonly finalByDatetimeUtc?: Maybe<Scalars['ISO8601DateTime']>;
  readonly finalByTimezone?: Maybe<Scalars['String']>;
  /** Defaults to the end of the day (truck's local time) of the readyDatetimeUtc date. */
  readonly loadByDatetimeUtc?: Maybe<Scalars['ISO8601DateTime']>;
  readonly loadByTimezone?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly palletsType?: Maybe<PalletTypesEnum>;
  readonly palletsCount?: Maybe<Scalars['Int']>;
  /**
   * DEPRECATED: Use previousRouteIdV2 instead
   * @deprecated Use previousRouteIdV2 instead
   */
  readonly previousRouteId?: Maybe<Scalars['ID']>;
  readonly previousRouteIdV2?: Maybe<Scalars['ID']>;
  readonly readyDatetimeUtc?: Maybe<Scalars['ISO8601DateTime']>;
  /**
   * DEPRECATED: Use sourceTypeId instead
   * @deprecated Use sourceTypeId instead
   */
  readonly sourceType?: Maybe<SourceTypesEnum>;
  readonly sourceTypeId?: Maybe<Scalars['String']>;
  readonly sourceTypeDDT?: Maybe<KeyValueType>;
  readonly tarpsType?: Maybe<TarpTypesEnum>;
  readonly tarpsCount?: Maybe<Scalars['Int']>;
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly trailerTypeId?: Maybe<Scalars['ID']>;
  readonly trailerType?: Maybe<KeyValueType>;
  readonly truckEntryTemplateId?: Maybe<Scalars['ID']>;
  /**
   * DEPRECATED: Use createdByUserV2 instead
   * @deprecated Use createdByUserV2 instead
   */
  readonly createdByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  /**
   * DEPRECATED: Use updatedByUserV2 instead
   * @deprecated Use updatedByUserV2 instead
   */
  readonly updatedByUser?: Maybe<User>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  /**
   * DEPRECATED: Use carrierV2 instead
   * @deprecated Use carrierV2 instead
   */
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  /**
   * DEPRECATED: Use ownerV2 instead
   * @deprecated Use ownerV2 instead
   */
  readonly owner?: Maybe<Carrier>;
  readonly ownerV2?: Maybe<CarrierV2>;
  /**
   * DEPRECATED: Use previousRouteV2 instead
   * @deprecated Use previousRouteV2 instead
   */
  readonly previousRoute?: Maybe<LoadRoute>;
  readonly previousRouteV2?: Maybe<LoadRouteV2>;
  /** @deprecated destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegions?: Maybe<ReadonlyArray<Maybe<Region>>>;
  readonly destinationStates?: Maybe<ReadonlyArray<Maybe<Geography>>>;
};

export type TruckEntryConnection = {
  readonly __typename?: 'TruckEntryConnection';
  readonly edges?: Maybe<ReadonlyArray<Maybe<TruckEntryEdge>>>;
  readonly pageInfo?: Maybe<PageInfo>;
  readonly totalCount?: Maybe<Scalars['Int']>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<TrucksUserError>>>;
};

export type TruckEntryEdge = {
  readonly __typename?: 'TruckEntryEdge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<TruckEntry>;
};

export type TruckEntryFilter = {
  readonly carrierId: Scalars['ID'];
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  /** A partial or full city name */
  readonly destinationCity?: InputMaybe<Scalars['String']>;
  /** An array of abbreviated state names */
  readonly destinationStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly endDateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** An array of trailerType IDs */
  readonly equipment?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly filterOnReadyAndLoadByDates?: InputMaybe<Scalars['Boolean']>;
  readonly finalByDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** A partial or full city name */
  readonly originCity?: InputMaybe<Scalars['String']>;
  /** An array of abbreviated state names */
  readonly originStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  readonly startDateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** An array of sourceType IDs */
  readonly source?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly truckEntryTemplateId?: InputMaybe<Scalars['ID']>;
};

export type TruckEntryPayloadWithErrorsAndTruckEntry = {
  readonly __typename?: 'TruckEntryPayloadWithErrorsAndTruckEntry';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly truckEntry?: Maybe<TruckEntry>;
};

export type TruckEntryPayloadWithOnlyErrors = {
  readonly __typename?: 'TruckEntryPayloadWithOnlyErrors';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
};

export type TruckEntrySort = {
  readonly key: TruckEntrySortKeysEnum;
  readonly direction: TruckEntrySortDirectionsEnum;
};

export enum TruckEntrySortDirectionsEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum TruckEntrySortKeysEnum {
  OriginCity = 'originCity',
  OriginState = 'originState',
  OriginDeadhead = 'originDeadhead',
  DestinationCity = 'destinationCity',
  DestinationState = 'destinationState',
  DestinationDeadhead = 'destinationDeadhead',
  ReadyDatetimeUtc = 'readyDatetimeUtc',
  LoadByDatetimeUtc = 'loadByDatetimeUtc',
  FinalByDatetimeUtc = 'finalByDatetimeUtc',
  SourceType = 'sourceType'
}

export type TruckEntryTemplate = {
  readonly __typename?: 'TruckEntryTemplate';
  readonly carrierId?: Maybe<Scalars['ID']>;
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdByUserId: Scalars['ID'];
  readonly destinationDeadhead?: Maybe<UnitOfDistance>;
  readonly destinationLocation?: Maybe<TruckLocation>;
  /**
   * destinationRegionUrns is no longer supported, null will be returned until this field is removed
   * @deprecated destinationRegionUrns is no longer supported, null will be returned until this field is removed
   */
  readonly destinationRegionUrns?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly id: Scalars['ID'];
  readonly isHazmat?: Maybe<Scalars['Boolean']>;
  readonly isTeam?: Maybe<Scalars['Boolean']>;
  readonly originDeadhead: UnitOfDistance;
  readonly originLocation: TruckLocation;
  readonly ownerId?: Maybe<Scalars['ID']>;
  readonly readyTimezone?: Maybe<Scalars['String']>;
  readonly sourceId?: Maybe<Scalars['String']>;
  readonly trailerSizeLength?: Maybe<UnitOfDistance>;
  readonly updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  readonly updatedByUserId: Scalars['ID'];
  readonly fridayMsOffsetFromStartOfDay?: Maybe<Scalars['Int']>;
  readonly mondayMsOffsetFromStartOfDay?: Maybe<Scalars['Int']>;
  readonly saturdayMsOffsetFromStartOfDay?: Maybe<Scalars['Int']>;
  readonly sourceType?: Maybe<TruckEntryTemplateSourceTypesEnum>;
  readonly status?: Maybe<TruckEntryTemplateStatus>;
  readonly sundayMsOffsetFromStartOfDay?: Maybe<Scalars['Int']>;
  readonly thursdayMsOffsetFromStartOfDay?: Maybe<Scalars['Int']>;
  readonly tuesdayMsOffsetFromStartOfDay?: Maybe<Scalars['Int']>;
  readonly trailerTypeId?: Maybe<Scalars['ID']>;
  readonly trailerType?: Maybe<KeyValueType>;
  readonly wednesdayMsOffsetFromStartOfDay?: Maybe<Scalars['Int']>;
  /**
   * DEPRECATED: Use carrierV2 instead
   * @deprecated Use carrierV2 instead
   */
  readonly carrier?: Maybe<Carrier>;
  readonly carrierV2?: Maybe<CarrierV2>;
  /**
   * DEPRECATED: Use ownerV2 instead
   * @deprecated Use ownerV2 instead
   */
  readonly owner?: Maybe<Carrier>;
  readonly ownerV2?: Maybe<CarrierV2>;
  /**
   * DEPRECATED: Use createdByUserV2 instead
   * @deprecated Use createdByUserV2 instead
   */
  readonly createdByUser?: Maybe<User>;
  readonly createdByUserV2?: Maybe<UserV2>;
  /**
   * DEPRECATED: Use updatedByUserV2 instead
   * @deprecated Use updatedByUserV2 instead
   */
  readonly updatedByUser?: Maybe<User>;
  readonly updatedByUserV2?: Maybe<UserV2>;
  readonly destinationStates?: Maybe<ReadonlyArray<Maybe<Geography>>>;
};

export type TruckEntryTemplateAndFirstTrucksPayloadWithErrorsAndTruckEntries = {
  readonly __typename?: 'TruckEntryTemplateAndFirstTrucksPayloadWithErrorsAndTruckEntries';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly truckEntry?: Maybe<ReadonlyArray<TruckEntry>>;
  readonly truckEntryTemplate?: Maybe<TruckEntryTemplate>;
};

export type TruckEntryTemplateConnection = {
  readonly __typename?: 'TruckEntryTemplateConnection';
  readonly edges?: Maybe<ReadonlyArray<Maybe<TruckEntryTemplateEdge>>>;
  readonly pageInfo?: Maybe<PageInfo>;
  readonly totalCount?: Maybe<Scalars['Int']>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<TrucksUserError>>>;
};

export type TruckEntryTemplateEdge = {
  readonly __typename?: 'TruckEntryTemplateEdge';
  readonly cursor?: Maybe<Scalars['String']>;
  readonly node?: Maybe<TruckEntryTemplate>;
};

export type TruckEntryTemplateFilter = {
  readonly carrierId: Scalars['ID'];
  readonly status?: InputMaybe<ReadonlyArray<TruckEntryTemplateStatus>>;
  readonly truckAvailableDay?: InputMaybe<TruckAvailableDayOffSetEnum>;
};

export type TruckEntryTemplatePayloadWithErrorsAndTruckEntryTemplate = {
  readonly __typename?: 'TruckEntryTemplatePayloadWithErrorsAndTruckEntryTemplate';
  readonly errors?: Maybe<ReadonlyArray<TrucksUserError>>;
  readonly truckEntryTemplate?: Maybe<TruckEntryTemplate>;
};

/** Identifies the method by which a TruckEntryTemplate was created */
export enum TruckEntryTemplateSourceTypesEnum {
  Api = 'api',
  Manual = 'manual'
}

export enum TruckEntryTemplateStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Inactive = 'INACTIVE',
  Deleted = 'DELETED'
}

export type TruckLocation = {
  readonly __typename?: 'TruckLocation';
  /** Full city name, ex: Chicago */
  readonly city: Scalars['String'];
  /** The state abbreviated to 2 letters, ex: IL */
  readonly stateAlpha2Code: Scalars['String'];
  /** The country abbreviated to 2 letters, ex: US */
  readonly countryAlpha2Code: Scalars['String'];
  /** Returns Geopoint with shape { lat: _, lon: _ } */
  readonly point: Geopoint;
  /** Optional postal code, ex: 60654 */
  readonly postalCode?: Maybe<Scalars['String']>;
};

export type TruckLocationInput = {
  /** Full city name, ex: Chicago */
  readonly city: Scalars['String'];
  /** The state, ex: IL, MEX, Ontario, Florida */
  readonly stateAlpha2Code: Scalars['String'];
  /** The country, ex: US, Mexico */
  readonly countryAlpha2Code: Scalars['String'];
  /** Optional postal code, ex: 60654 */
  readonly postalCode?: InputMaybe<Scalars['String']>;
};

export type TruckPosting = {
  readonly __typename?: 'TruckPosting';
  readonly additionalEquipment?: Maybe<Scalars['Json']>;
  readonly auditData?: Maybe<Scalars['String']>;
  readonly carrier: Carrier;
  readonly carrierId: Scalars['ID'];
  readonly destinationDeadhead: UnitOfDistance;
  readonly destinationGeographies?: Maybe<ReadonlyArray<TruckPostingGeography>>;
  readonly destinationGeographySource?: Maybe<Scalars['String']>;
  readonly destinationGeographySourceId?: Maybe<Scalars['Int']>;
  readonly destinationPoint?: Maybe<Geopoint>;
  readonly destinationText?: Maybe<Scalars['String']>;
  readonly driverName?: Maybe<Scalars['String']>;
  readonly employee?: Maybe<Employee>;
  readonly emptyTimezone: Scalars['String'];
  readonly finalByTimezone?: Maybe<Scalars['String']>;
  readonly hazmat: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly loadByTimezone?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly originDeadhead: UnitOfDistance;
  readonly originGeographySource?: Maybe<Scalars['String']>;
  readonly originGeographySourceId?: Maybe<Scalars['Int']>;
  readonly originPoint: Geopoint;
  readonly originText: Scalars['String'];
  readonly previousRoute?: Maybe<LoadRoute>;
  readonly previousRouteId?: Maybe<Scalars['ID']>;
  /** @deprecated Replaced by source_id_as_string; this field attempts to cast any string to an integer and may be inaccurate */
  readonly sourceId?: Maybe<Scalars['Int']>;
  readonly sourceIdAsString?: Maybe<Scalars['String']>;
  readonly sourceType?: Maybe<SourceTypeEnum>;
  readonly team?: Maybe<Scalars['Boolean']>;
  readonly trailerHeight?: Maybe<Scalars['Float']>;
  readonly trailerHeightUnit: Scalars['String'];
  readonly trailerLength?: Maybe<Scalars['Float']>;
  readonly trailerLengthUnit: Scalars['String'];
  readonly trailerNumber?: Maybe<Scalars['String']>;
  readonly trailerType: KeyValue;
  readonly trailerTypeId?: Maybe<Scalars['ID']>;
  readonly trailerWidth?: Maybe<Scalars['Float']>;
  readonly trailerWidthUnit: Scalars['String'];
  /** @deprecated Transitioned to trailer_type */
  readonly truckType: TruckTypeEnum;
  readonly updatedAt: Scalars['ISO8601DateTime'];
  /** @deprecated Replaced by utc_empty_start_datetime */
  readonly utcDatetime: Scalars['ISO8601DateTime'];
  readonly utcEmptyStartDatetime: Scalars['ISO8601DateTime'];
  readonly utcFinalByDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly utcLoadByDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  readonly verified: Scalars['Boolean'];
};

export type TruckPostingGeography = {
  readonly __typename?: 'TruckPostingGeography';
  readonly geography: Geography;
  readonly id: Scalars['ID'];
};

export type TruckPostingGeographyInput = {
  readonly _destroy?: InputMaybe<Scalars['String']>;
  readonly geographyId: Scalars['ID'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type TruckPostingInput = {
  readonly additionalEquipment?: InputMaybe<Scalars['Json']>;
  readonly carrierId: Scalars['ID'];
  readonly count?: InputMaybe<Scalars['Int']>;
  readonly destinationDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly destinationGeographies?: InputMaybe<ReadonlyArray<TruckPostingGeographyInput>>;
  readonly destinationGeographySource?: InputMaybe<Scalars['String']>;
  readonly destinationGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly destinationText?: InputMaybe<Scalars['String']>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly emptyTimezone?: InputMaybe<Scalars['String']>;
  readonly finalByTimezone?: InputMaybe<Scalars['String']>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly loadByTimezone?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly originDeadhead?: InputMaybe<UnitOfDistanceInput>;
  readonly originGeographySource?: InputMaybe<Scalars['String']>;
  readonly originGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly originText: Scalars['String'];
  readonly previousRouteId?: InputMaybe<Scalars['ID']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  readonly sourceType: SourceTypeEnum;
  readonly team?: InputMaybe<Scalars['Boolean']>;
  readonly trailerHeight?: InputMaybe<Scalars['Float']>;
  readonly trailerLength?: InputMaybe<Scalars['Float']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly trailerWidth?: InputMaybe<Scalars['Float']>;
  readonly truckType?: InputMaybe<TruckTypeEnum>;
  readonly utcEmptyStartDatetime?: InputMaybe<Scalars['String']>;
  readonly utcFinalByDatetime?: InputMaybe<Scalars['String']>;
  readonly utcLoadByDatetime?: InputMaybe<Scalars['String']>;
};

export type TruckPostingsExternalInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
};

export type TruckPostingsInput = {
  readonly destination?: InputMaybe<Scalars['String']>;
  readonly emptyStartDate?: InputMaybe<Scalars['String']>;
  readonly emptyStartDateEnd?: InputMaybe<Scalars['String']>;
  readonly origin?: InputMaybe<Scalars['String']>;
};

export type TrucksUserError = {
  readonly __typename?: 'TrucksUserError';
  readonly code?: Maybe<Scalars['String']>;
  readonly extensions?: Maybe<Scalars['JSON']>;
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export enum TruckTypeEnum {
  /** Flatbed */
  Flatbed = 'flatbed',
  /** None Defined */
  NoneDefined = 'noneDefined',
  /** Power Only */
  PowerOnly = 'powerOnly',
  /** Reefer */
  Reefer = 'reefer',
  /** Van */
  Van = 'van'
}

export type TypeCachedType = {
  readonly __typename?: 'TypeCachedType';
  readonly timestamp?: Maybe<Scalars['String']>;
  readonly nonce?: Maybe<Scalars['String']>;
};

export enum UnderwriterRatingTypeEnum {
  /** A++ */
  APlusPlus = 'APlusPlus',
  /** A+ */
  APlus = 'APlus',
  /** A */
  A = 'A',
  /** A- */
  AMinus = 'AMinus',
  /** B++ */
  BPlusPlus = 'BPlusPlus',
  /** B+ */
  BPlus = 'BPlus',
  /** B */
  B = 'B',
  /** C+ */
  CPlus = 'CPlus',
  /** C */
  C = 'C',
  /** D */
  D = 'D',
  /** E */
  E = 'E',
  /** F */
  F = 'F',
  /** S */
  S = 'S',
  /** NR */
  Nr = 'NR',
  /** NA */
  Na = 'NA'
}

export type UnitOfCapacityInput = {
  readonly value: Scalars['Float'];
  readonly unit: Scalars['String'];
};

export type UnitOfCapacityOutput = {
  readonly __typename?: 'UnitOfCapacityOutput';
  readonly value: Scalars['Float'];
  readonly unit: Scalars['String'];
};

export enum UnitOfCurrencyEnum {
  Usd = 'USD'
}

export enum UnitOfCurrencyEnumV2 {
  Usd = 'USD',
  Cad = 'CAD',
  Mxn = 'MXN'
}

export type UnitOfDensityInput = {
  readonly unit: DensityUnitTypeEnum;
  readonly value: Scalars['Float'];
};

export type UnitOfDensityInputV2 = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type UnitOfDistance = {
  readonly __typename?: 'UnitOfDistance';
  readonly asFeet?: Maybe<Scalars['Float']>;
  readonly asInches?: Maybe<Scalars['Float']>;
  readonly asKilometers?: Maybe<Scalars['Float']>;
  readonly asMeters?: Maybe<Scalars['Float']>;
  readonly asMiles?: Maybe<Scalars['Float']>;
  readonly asRoundedFeet?: Maybe<Scalars['Int']>;
  readonly asRoundedInches?: Maybe<Scalars['Int']>;
  readonly asRoundedKilometers?: Maybe<Scalars['Int']>;
  readonly asRoundedMeters?: Maybe<Scalars['Int']>;
  readonly asRoundedMiles?: Maybe<Scalars['Int']>;
};

export type UnitOfDistanceInput = {
  readonly unit?: InputMaybe<DistanceUnitEnum>;
  readonly value?: InputMaybe<Scalars['Float']>;
};

export enum UnitOfLengthEnum {
  Ft = 'ft',
  In = 'in',
  Km = 'km',
  M = 'm',
  Mi = 'mi'
}

export type UnitOfLengthInput = {
  readonly unit: UnitOfLengthEnum;
  readonly value: Scalars['Float'];
};

export type UnitOfLengthInputV2 = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type UnitOfLengthInputV3 = {
  readonly value: Scalars['Float'];
  readonly unit: Scalars['String'];
};

export type UnitOfLengthOutput = {
  readonly __typename?: 'UnitOfLengthOutput';
  readonly unit: UnitOfLengthEnum;
  readonly value: Scalars['Float'];
};

export type UnitOfLengthOutputV2 = {
  readonly __typename?: 'UnitOfLengthOutputV2';
  readonly value: Scalars['Float'];
  readonly unit: Scalars['String'];
};

export type UnitOfMassInput = {
  readonly unit: MassUnitTypeEnum;
  readonly value: Scalars['Float'];
};

export type UnitOfMassInputV2 = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type UnitOfTemperatureInput = {
  readonly unit: TemperatureUnitTypeEnum;
  readonly value: Scalars['Float'];
};

export type UnitOfTemperatureInputV2 = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type UnitOfVolumeInput = {
  readonly unit: VolumeUnitTypeEnum;
  readonly value: Scalars['Float'];
};

export type UnitOfVolumeInputV2 = {
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type UnitOfWeightInput = {
  readonly value: Scalars['Float'];
  readonly unit: Scalars['String'];
};

export type UnitOfWeightOutput = {
  readonly __typename?: 'UnitOfWeightOutput';
  readonly value: Scalars['Float'];
  readonly unit: Scalars['String'];
};

export type UpdateBounceInput = {
  readonly bounceReasonId?: InputMaybe<Scalars['ID']>;
  readonly bounceTypeId?: InputMaybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
};

/** Autogenerated return type of UpdateBounce */
export type UpdateBouncePayload = {
  readonly __typename?: 'UpdateBouncePayload';
  readonly bounce: Bounce;
  readonly errors: ReadonlyArray<UserError>;
};

export type UpdateBounceV2Input = {
  readonly id: Scalars['ID'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly bounceReasonId?: InputMaybe<Scalars['ID']>;
  readonly bounceTypeId?: InputMaybe<Scalars['ID']>;
};

export type UpdateBounceV2Payload = {
  readonly __typename?: 'UpdateBounceV2Payload';
  readonly bounce?: Maybe<BounceV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type UpdateCarrierCapacityConstraintInput = {
  readonly costQuoteId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly laneId: Scalars['ID'];
  readonly weeklyCapacity?: InputMaybe<Scalars['Int']>;
  readonly mondayCapacity?: InputMaybe<Scalars['Int']>;
  readonly tuesdayCapacity?: InputMaybe<Scalars['Int']>;
  readonly wednesdayCapacity?: InputMaybe<Scalars['Int']>;
  readonly thursdayCapacity?: InputMaybe<Scalars['Int']>;
  readonly fridayCapacity?: InputMaybe<Scalars['Int']>;
  readonly saturdayCapacity?: InputMaybe<Scalars['Int']>;
  readonly sundayCapacity?: InputMaybe<Scalars['Int']>;
  readonly requestedById: Scalars['ID'];
};

export type UpdateCarrierCrmDocumentInput = {
  readonly contacts?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly details?: InputMaybe<Scalars['String']>;
  readonly direction?: InputMaybe<Scalars['String']>;
  readonly documentName: Scalars['String'];
  readonly documentType?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isDeleted: Scalars['Boolean'];
  readonly opportunities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly source?: InputMaybe<Scalars['String']>;
  readonly vendorId: Scalars['ID'];
};

export type UpdateCarrierCrmNotesInput = {
  readonly contacts?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly id: Scalars['ID'];
  readonly notes: Scalars['String'];
  readonly opportunities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly reps: ReadonlyArray<Scalars['ID']>;
  readonly type?: InputMaybe<Scalars['String']>;
  readonly vendorId: Scalars['ID'];
};

export type UpdateCarrierCrmOpportunityInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly name: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly opportunityStatusId?: InputMaybe<Scalars['ID']>;
  readonly opportunityTypeId?: InputMaybe<Scalars['ID']>;
  readonly owningContactId?: InputMaybe<Scalars['ID']>;
  readonly owningRepId: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
};

export type UpdateCarrierFacilityInput = {
  readonly relationshipType?: InputMaybe<Scalars['String']>;
  readonly canLoad?: InputMaybe<Scalars['Boolean']>;
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly carrierId?: InputMaybe<Scalars['String']>;
  readonly facilityId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateCarrierIntrastate */
export type UpdateCarrierIntrastatePayload = {
  readonly __typename?: 'UpdateCarrierIntrastatePayload';
  readonly carrier?: Maybe<Carrier>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of UpdateCarrierIntrastate */
export type UpdateCarrierIntrastatePayloadV2 = {
  readonly __typename?: 'UpdateCarrierIntrastatePayloadV2';
  readonly carrier?: Maybe<CarrierV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type UpdateCarrierQuoteInputType = {
  readonly costQuoteId: Scalars['ID'];
  readonly expirationDateTime?: InputMaybe<Scalars['DateTimeOffset']>;
  readonly lineItems?: InputMaybe<ReadonlyArray<InputMaybe<CostQuoteLineItemInput>>>;
};

/** Autogenerated return type of UpdateCarrierStanding */
export type UpdateCarrierStandingPayload = {
  readonly __typename?: 'UpdateCarrierStandingPayload';
  readonly carrier?: Maybe<Carrier>;
  readonly errors: ReadonlyArray<UserError>;
};

/** Autogenerated return type of UpdateCarrierStanding */
export type UpdateCarrierStandingPayloadV2 = {
  readonly __typename?: 'UpdateCarrierStandingPayloadV2';
  readonly carrier?: Maybe<CarrierV2>;
  readonly errors: ReadonlyArray<UserError>;
};

export type UpdateCityStateOnStopInput = {
  readonly loadId: Scalars['ID'];
  readonly oldStopAddress: CityStateStopLocationInput;
  readonly newStopAddress: CityStateStopLocationInput;
};

export type UpdateCityStateOnStopPayload = {
  readonly __typename?: 'UpdateCityStateOnStopPayload';
  readonly errors: ReadonlyArray<RouteStopsPayloadError>;
  readonly updateCityStateOnStop?: Maybe<RouteStopsPayload>;
};

export type UpdateClientExceptionRecordInput = {
  readonly id: Scalars['ID'];
  readonly status?: InputMaybe<ClientExceptionRecordStatus>;
  readonly reasons?: InputMaybe<ReadonlyArray<UpdateClientExceptionRecordReasonInput>>;
  readonly metadata?: InputMaybe<ReadonlyArray<UpsertClientExceptionRecordMetadataInput>>;
  readonly assignedTo?: InputMaybe<Scalars['String']>;
  readonly assignedToGroup?: InputMaybe<Scalars['String']>;
  readonly updatedBy: Scalars['String'];
};

export type UpdateClientExceptionRecordInputV2 = {
  readonly id: Scalars['ID'];
  readonly status?: InputMaybe<ClientExceptionRecordStatus>;
  readonly reasons?: InputMaybe<ReadonlyArray<UpdateClientExceptionRecordReasonInputV2>>;
  readonly metadata?: InputMaybe<ReadonlyArray<UpsertClientExceptionRecordMetadataInput>>;
  readonly assignedTo?: InputMaybe<Scalars['String']>;
  readonly assignedToGroup?: InputMaybe<Scalars['String']>;
  readonly updatedBy: Scalars['String'];
};

export type UpdateClientExceptionRecordReasonInput = {
  readonly status: ClientExceptionRecordReasonStatus;
  readonly code: ClientExceptionRecordReasonCode;
  readonly bypassed?: InputMaybe<Scalars['Boolean']>;
  readonly bypassedReason?: InputMaybe<Scalars['String']>;
  readonly assignedTo?: InputMaybe<Scalars['String']>;
  readonly assignedToGroup?: InputMaybe<Scalars['String']>;
  readonly assignedBy?: InputMaybe<Scalars['String']>;
};

export type UpdateClientExceptionRecordReasonInputV2 = {
  readonly status: ClientExceptionRecordReasonStatus;
  readonly code: Scalars['String'];
  readonly bypassed?: InputMaybe<Scalars['Boolean']>;
  readonly bypassedReason?: InputMaybe<Scalars['String']>;
  readonly assignedTo?: InputMaybe<Scalars['String']>;
  readonly assignedToGroup?: InputMaybe<Scalars['String']>;
  readonly assignedBy?: InputMaybe<Scalars['String']>;
};

export type UpdateContainerInput = {
  readonly id: Scalars['ID'];
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  /** Must be alphanumeric and shorter than 50 characters; required if reservationNumber is not present */
  readonly containerNumber?: InputMaybe<Scalars['String']>;
  readonly containerTypeId?: InputMaybe<Scalars['ID']>;
  readonly containerLengthUnit?: InputMaybe<UnitOfLengthEnum>;
  readonly containerLengthValue?: InputMaybe<Scalars['Float']>;
  readonly containerProgramId?: InputMaybe<Scalars['String']>;
  readonly destinationRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly endDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly endTimezone?: InputMaybe<Scalars['String']>;
  readonly externalSourceId?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly originLocation?: InputMaybe<CapacityLocationInput>;
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly previousRouteId?: InputMaybe<Scalars['ID']>;
  /** Must be alphanumeric and shorter than 50 characters; required if containerNumber is not present */
  readonly reservationNumber?: InputMaybe<Scalars['String']>;
  readonly reservationTypeId?: InputMaybe<Scalars['String']>;
  readonly startDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly startTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceTypeId?: InputMaybe<Scalars['String']>;
  readonly tenantInternalId?: InputMaybe<Scalars['String']>;
  readonly updatedByUserId: Scalars['ID'];
};

export type UpdateCrmDocumentNameInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type UpdateCrmNamePayload = {
  readonly __typename?: 'UpdateCRMNamePayload';
  readonly crm?: Maybe<CrmDocument>;
};

export type UpdateCustomerCommodityInput = {
  readonly customerFacilityId?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly facilityId?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly customerCode?: InputMaybe<Scalars['String']>;
  readonly stccCode?: InputMaybe<Scalars['String']>;
  readonly packagingUnit?: InputMaybe<Scalars['String']>;
  readonly loadOn?: InputMaybe<Scalars['String']>;
  readonly expWt?: InputMaybe<Scalars['Float']>;
  readonly expPieces?: InputMaybe<Scalars['String']>;
  readonly uom_temperature?: InputMaybe<Scalars['String']>;
  readonly uom_dimension?: InputMaybe<Scalars['String']>;
  readonly uom_linear?: InputMaybe<Scalars['String']>;
  readonly uom_cube?: InputMaybe<Scalars['String']>;
  readonly uom_density?: InputMaybe<Scalars['String']>;
  readonly temp?: InputMaybe<Scalars['Boolean']>;
  readonly precoolTo?: InputMaybe<Scalars['Float']>;
  readonly minTemp?: InputMaybe<Scalars['Float']>;
  readonly maxTemp?: InputMaybe<Scalars['Float']>;
  readonly tempSetting?: InputMaybe<Scalars['String']>;
  readonly wtUnit?: InputMaybe<Scalars['String']>;
  readonly hazmat?: InputMaybe<Scalars['Boolean']>;
  readonly hazmatClass?: InputMaybe<Scalars['String']>;
  readonly packagingGroup?: InputMaybe<Scalars['String']>;
  readonly unNaNumber?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly expDimensions?: InputMaybe<Scalars['String']>;
  readonly expDimensionsW?: InputMaybe<Scalars['Float']>;
  readonly expDimensionsH?: InputMaybe<Scalars['Float']>;
  readonly expDimensionsL?: InputMaybe<Scalars['Float']>;
  readonly expLinear?: InputMaybe<Scalars['Float']>;
  readonly expCube?: InputMaybe<Scalars['Float']>;
  readonly expDensity?: InputMaybe<Scalars['Float']>;
  readonly expClass?: InputMaybe<Scalars['String']>;
  readonly expNmfc?: InputMaybe<Scalars['String']>;
  readonly nmfcSubcode?: InputMaybe<Scalars['String']>;
  readonly unitSystem?: InputMaybe<Scalars['String']>;
  readonly canTopLoad?: InputMaybe<Scalars['Boolean']>;
  readonly canBottomLoad?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateCustomerCrmDocumentInput = {
  readonly contacts?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly customerId: Scalars['ID'];
  readonly details?: InputMaybe<Scalars['String']>;
  readonly direction?: InputMaybe<Scalars['String']>;
  readonly documentName: Scalars['String'];
  readonly documentType?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isDeleted: Scalars['Boolean'];
  readonly opportunities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly source?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerCrmNotesRequest = {
  readonly contacts?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly customerId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly notes: Scalars['String'];
  readonly opportunities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly reps: ReadonlyArray<Scalars['ID']>;
  readonly type?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerCrmOpportunityInput = {
  readonly customerId: Scalars['ID'];
  readonly description?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isActive?: InputMaybe<Scalars['Boolean']>;
  readonly name: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly opportunityStatusId?: InputMaybe<Scalars['ID']>;
  readonly opportunityTypeId?: InputMaybe<Scalars['ID']>;
  readonly owningContactId?: InputMaybe<Scalars['ID']>;
  readonly owningRepId: Scalars['ID'];
};

export type UpdateCustomerCustomerRelationshipInput = {
  readonly relationshipType?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly relatedCustomerId?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerFacilityInput = {
  readonly schedulingSystem?: InputMaybe<Scalars['String']>;
  readonly schedulingURL?: InputMaybe<Scalars['String']>;
  readonly username?: InputMaybe<Scalars['String']>;
  readonly password?: InputMaybe<Scalars['String']>;
  readonly isAutoScheduleEligible?: InputMaybe<Scalars['Boolean']>;
  readonly isAutoScheduleDefault?: InputMaybe<Scalars['Boolean']>;
  readonly isPickup?: InputMaybe<Scalars['Boolean']>;
  readonly pickupCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isDelivery?: InputMaybe<Scalars['Boolean']>;
  readonly deliveryCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly trailPool?: InputMaybe<Scalars['String']>;
  readonly minTrailers?: InputMaybe<Scalars['Int']>;
  readonly maxTrailers?: InputMaybe<Scalars['Int']>;
  readonly nowTrailers?: InputMaybe<Scalars['Int']>;
  readonly specialRequirementsAndEquipment?: InputMaybe<Scalars['String']>;
  readonly pickupAllowableFreeTimeValue?: InputMaybe<Scalars['Int']>;
  readonly pickupAllowableFreeTimeUnit?: InputMaybe<Scalars['String']>;
  readonly deliveryAllowableFreeTimeValue?: InputMaybe<Scalars['Int']>;
  readonly deliveryAllowableFreeTimeUnit?: InputMaybe<Scalars['String']>;
  readonly drop?: InputMaybe<Scalars['String']>;
  readonly customerCustomerId?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly facilityId?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerRegionInput = {
  readonly id: Scalars['String'];
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly region?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerStructureInput = {
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<Scalars['String']>;
};

export type UpdateDriverAssignmentInput = {
  readonly id: Scalars['ID'];
  readonly userID?: InputMaybe<Scalars['ID']>;
  readonly facilityID?: InputMaybe<Scalars['ID']>;
  readonly equipmentID?: InputMaybe<Scalars['ID']>;
  readonly trackingSystemID?: InputMaybe<Scalars['ID']>;
  readonly trackingEnabled?: InputMaybe<Scalars['Boolean']>;
  /** temporary field until we can resolve user info from the gateway context */
  readonly trackingEnabledBy?: InputMaybe<TrackingUserMetaInput>;
  readonly driver1?: InputMaybe<DriverInput>;
  readonly driver2?: InputMaybe<DriverInput>;
  readonly eldVehicleID?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly trailerIdentifier?: InputMaybe<Scalars['String']>;
  readonly tractorIdentifier?: InputMaybe<Scalars['String']>;
  readonly trailerLocation?: InputMaybe<LocationInput>;
  readonly emptyLocation?: InputMaybe<LocationInput>;
  readonly emptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly emptyTimezone?: InputMaybe<Scalars['String']>;
  readonly etaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly etaTimezone?: InputMaybe<Scalars['String']>;
  readonly checkCallDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly trailerLength?: InputMaybe<Scalars['Float']>;
  readonly trailerWidth?: InputMaybe<Scalars['Float']>;
  readonly trailerHeight?: InputMaybe<Scalars['Float']>;
  readonly dispatched?: InputMaybe<Scalars['Boolean']>;
  /** temporary field until we can resolve user info from the gateway context */
  readonly dispatchedBy?: InputMaybe<TrackingUserMetaInput>;
  readonly externalInfo?: InputMaybe<DriverAssignmentExternalInfoInput>;
  readonly reloadIntentID?: InputMaybe<Scalars['String']>;
};

/** The input type to update Edi Outbox Message. */
export type UpdateEdiOutboxInput = {
  /** Gets or sets id. */
  readonly id: Scalars['String'];
  /** Gets or sets a value indicating whether IsRetryable. */
  readonly isRetryable: Scalars['Boolean'];
  /** Gets or sets publishedTimestamp. */
  readonly publishedTimestamp?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateFacilityLoadDefaultsInput = {
  readonly defaultEquipments?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly length?: InputMaybe<Scalars['Float']>;
  readonly width?: InputMaybe<Scalars['Float']>;
  readonly height?: InputMaybe<Scalars['Float']>;
  readonly schedSystem?: InputMaybe<Scalars['String']>;
  readonly schedURL?: InputMaybe<Scalars['String']>;
  readonly specialRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly autoSchedEligible?: InputMaybe<Scalars['Boolean']>;
  readonly autoSchedDefault?: InputMaybe<Scalars['Boolean']>;
  readonly facilityId?: InputMaybe<Scalars['String']>;
  readonly facilityLoadDefaultsDivision?: InputMaybe<ReadonlyArray<CreateOrUpdateFacilityLoadDefaultsDivisionInput>>;
  readonly facilityLoadDefaultsBusinessUnit?: InputMaybe<ReadonlyArray<CreateOrUpdateFacilityLoadDefaultsBusinessUnitInput>>;
};

export type UpdateFacilityNoteDefaultsInput = {
  readonly facilityId?: InputMaybe<Scalars['String']>;
  readonly rateConfirmNote?: InputMaybe<Scalars['String']>;
  readonly externalFacilityNote?: InputMaybe<Scalars['String']>;
  readonly facilityLoadNote?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly noteType?: InputMaybe<Scalars['String']>;
  readonly facilityNoteDefaultsDivision?: InputMaybe<ReadonlyArray<CreateOrUpdateFacilityNoteDefaultsDivisionInput>>;
  readonly facilityNoteDefaultsBusinessUnits?: InputMaybe<ReadonlyArray<CreateOrUpdateFacilityNoteDefaultsBusinessUnitInput>>;
};

export type UpdateFirstTruckEntriesInput = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly originLocation?: InputMaybe<TruckLocationInput>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly fridayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly mondayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly saturdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly status?: InputMaybe<TruckEntryTemplateStatus>;
  readonly sourceType?: InputMaybe<TruckEntryTemplateSourceTypesEnum>;
  readonly sundayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly thursdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly tuesdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly wednesdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  /** Represents new truckEntryTemplateId from which FirstTrucks will be updated */
  readonly truckEntryTemplateId: Scalars['ID'];
};

export type UpdateGateReservationInput = {
  readonly id: Scalars['ID'];
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly originRampFacilityId?: InputMaybe<Scalars['ID']>;
  readonly destinationRampFacilityId?: InputMaybe<Scalars['ID']>;
  /** DEPRECATED: Use containerProgramId instead */
  readonly containerProgramCarrierId?: InputMaybe<Scalars['ID']>;
  readonly containerProgramId?: InputMaybe<Scalars['String']>;
  readonly endDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly startDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly startTimezone?: InputMaybe<Scalars['String']>;
  readonly endTimezone?: InputMaybe<Scalars['String']>;
  readonly containerTypeId?: InputMaybe<Scalars['ID']>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId: Scalars['ID'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly containerLengthUnit?: InputMaybe<UnitOfLengthEnum>;
  readonly containerLengthValue?: InputMaybe<Scalars['Float']>;
  readonly reservationNumber?: InputMaybe<Scalars['String']>;
  readonly sourceTypeId?: InputMaybe<Scalars['String']>;
};

export type UpdateHaykEventInput = {
  /** eventName is the registered Hayk event name. */
  readonly eventName: Scalars['String'];
  /** enabled represents whether an event has been turned on or off. */
  readonly enabled?: InputMaybe<Scalars['Boolean']>;
  /** eventMode is used in workflow processing and logging operations. Values can be "standard", "tracking", or "tracking-only". */
  readonly eventMode?: InputMaybe<Scalars['String']>;
  /** isVisible determines visibility */
  readonly isVisible?: InputMaybe<Scalars['Boolean']>;
  /** serviceSource is the source service that owns the event */
  readonly serviceSource?: InputMaybe<Scalars['String']>;
};

export type UpdateHaykEventResult = {
  readonly __typename?: 'UpdateHaykEventResult';
  readonly eventName: Scalars['String'];
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly eventMode?: Maybe<Scalars['String']>;
  readonly isVisible?: Maybe<Scalars['Boolean']>;
  readonly serviceSource?: Maybe<Scalars['String']>;
};

export type UpdateHaykRedirectInput = {
  readonly redirectId: Scalars['String'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly redirects: HaykRedirectRedirectsInput;
};

export type UpdateKvtValuesResponse = {
  readonly __typename?: 'updateKvtValuesResponse';
  readonly operationStatus?: Maybe<Scalars['String']>;
};

export type UpdateLoadDefaultsInput = {
  readonly size?: InputMaybe<SizeTypesEnum>;
  readonly sizeV2?: InputMaybe<Scalars['String']>;
  readonly minLength?: InputMaybe<Scalars['Float']>;
  readonly width?: InputMaybe<Scalars['Float']>;
  readonly height?: InputMaybe<Scalars['Float']>;
  readonly cargoValue?: InputMaybe<Scalars['Float']>;
  readonly cargoUnit?: InputMaybe<Scalars['String']>;
  readonly doNotPostLoads?: InputMaybe<Scalars['Boolean']>;
  readonly disableAutoPost?: InputMaybe<Scalars['Boolean']>;
  readonly equipment?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly specialRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly mode?: InputMaybe<Scalars['String']>;
  readonly divisions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type UpdateLoadDefaultsInputV2 = {
  readonly size?: InputMaybe<SizeTypesEnum>;
  readonly sizeV2?: InputMaybe<Scalars['String']>;
  readonly minLength?: InputMaybe<Scalars['Float']>;
  readonly width?: InputMaybe<Scalars['Float']>;
  readonly height?: InputMaybe<Scalars['Float']>;
  readonly cargoValue?: InputMaybe<Scalars['Float']>;
  readonly cargoUnit?: InputMaybe<Scalars['String']>;
  readonly doNotPostLoads?: InputMaybe<Scalars['Boolean']>;
  readonly disableAutoPost?: InputMaybe<Scalars['Boolean']>;
  readonly equipment?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly specialRequirements?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly mode: Scalars['String'];
  readonly divisions: ReadonlyArray<Scalars['String']>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type UpdateLoadDocDetailsInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly orderId?: InputMaybe<Scalars['ID']>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly routeIds?: InputMaybe<Scalars['String']>;
  readonly stopIds?: InputMaybe<Scalars['String']>;
};

export type UpdateLoadDocDetailsPayload = {
  readonly __typename?: 'UpdateLoadDocDetailsPayload';
  readonly load?: Maybe<LoadDocument>;
};

export type UpdateLoadInput = {
  readonly id: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
};

export type UpdateLoadInputV2 = {
  readonly id: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly orders?: InputMaybe<ReadonlyArray<CreateOrUpdateCustomerOrderInputV2>>;
  /** Stops created manually from UI will not be eligible for delete/update */
  readonly stops?: InputMaybe<ReadonlyArray<UpdateLoadStopInput>>;
};

/** Autogenerated return type of UpdateLoad */
export type UpdateLoadPayload = {
  readonly __typename?: 'UpdateLoadPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly load?: Maybe<Load>;
};

export type UpdateLoadRouteInput = {
  readonly id: Scalars['ID'];
  readonly activationStatusId: Scalars['String'];
  readonly divisionV2?: InputMaybe<Scalars['ID']>;
  readonly businessUnit?: InputMaybe<Scalars['ID']>;
  readonly projectV2?: InputMaybe<Scalars['ID']>;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  readonly hotRoute?: InputMaybe<Scalars['Boolean']>;
  readonly trailerTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly dimensions?: InputMaybe<DimensionsInput>;
  readonly cargoInsurance?: InputMaybe<CurrencyInput>;
  readonly transportModeId?: InputMaybe<Scalars['ID']>;
  readonly sizeId?: InputMaybe<Scalars['ID']>;
  readonly routeType?: InputMaybe<Scalars['ID']>;
  /** added in error, use projectV2 instead */
  readonly project?: InputMaybe<Scalars['String']>;
  readonly tarpType?: InputMaybe<Scalars['ID']>;
  readonly tarpCount?: InputMaybe<Scalars['Int']>;
  readonly braceTypes?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly braceCount?: InputMaybe<Scalars['Int']>;
  readonly cargoInsuranceV2?: InputMaybe<LoadCurrencyInput>;
};

export type UpdateLoadStopInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly facilityId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly scheduleType?: InputMaybe<ScheduleAppointmentTypeEnum>;
  readonly liveType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly workType?: InputMaybe<ScheduleWorkTypeEnum>;
  readonly isOrderStop?: InputMaybe<Scalars['Boolean']>;
  readonly type?: InputMaybe<StopTypeEnum>;
  /**
   * value is validated against following options:
   *   crossDock, originRamp, destinationRamp, customsBroker, yard, PU, Del, TA, TD, domicile, maintenanceShop, scale, fuel, driverComplianceTraining, waypoint, railJunction, relay, terminal, seaPort, airport, containerDepot, borderCrossing, transload, trailerWash
   */
  readonly loadStopType?: InputMaybe<Scalars['String']>;
  readonly loadFromId?: InputMaybe<Scalars['ID']>;
  readonly unloadFromId?: InputMaybe<Scalars['ID']>;
  readonly requirements?: InputMaybe<ReadonlyArray<StopRequirementInput>>;
  readonly refs: ReadonlyArray<RefInputV2>;
  readonly reqDate?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStart?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableEnd?: InputMaybe<DatetimeWithTimezoneInput>;
  readonly availableStartTimes?: InputMaybe<ScheduleRangeInput>;
  readonly availableEndTimes?: InputMaybe<ScheduleRangeInput>;
  readonly bolNumber?: InputMaybe<Scalars['String']>;
  readonly appointment?: InputMaybe<AppointmentInputV2>;
  readonly sealNumber?: InputMaybe<Scalars['String']>;
  readonly apptConfBy?: InputMaybe<Scalars['String']>;
  readonly apptReqWith?: InputMaybe<Scalars['String']>;
  /** stop unique identifier for external system */
  readonly externalRef?: InputMaybe<Scalars['String']>;
  readonly appointmentChangeReason?: InputMaybe<Scalars['String']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  /** flag for stops created from FE */
  readonly isCreatedManually?: InputMaybe<Scalars['Boolean']>;
  readonly splc?: InputMaybe<Scalars['String']>;
  /** PU/Del code(s) from customer-facility relationship */
  readonly externalFacilityId?: InputMaybe<Scalars['String']>;
};

export type UpdateMaxCostInput = {
  readonly loadId: Scalars['Uuid'];
  readonly routeId: Scalars['Uuid'];
};

export type UpdateMaxCostResponse = {
  readonly __typename?: 'UpdateMaxCostResponse';
  readonly code?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
};

export type UpdateMultipleOfferResponseInput = {
  readonly offerId: Scalars['ID'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly counterPrice?: InputMaybe<CurrencyInput>;
  readonly counterPriceV2?: InputMaybe<CurrencyInputV2>;
  readonly type: OfferResponseType;
};

export type UpdateMultipleOfferResponsePayload = {
  readonly __typename?: 'UpdateMultipleOfferResponsePayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly offerResponse: ReadonlyArray<OfferResponse>;
};

export type UpdateMultipleTenderOptionsInput = {
  readonly tenderOptions?: InputMaybe<ReadonlyArray<InputMaybe<UpdateTenderOptionsInput>>>;
  readonly updatedByUserId: Scalars['ID'];
};

export type UpdateNoteDefaultInput = {
  readonly customerId?: InputMaybe<Scalars['String']>;
  readonly customerLoadNote?: InputMaybe<Scalars['String']>;
  readonly rateConfirmNote?: InputMaybe<Scalars['String']>;
  readonly invoiceNote?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly noteType?: InputMaybe<Scalars['String']>;
  readonly division?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type UpdateOfferInput = {
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly trailerOwnerId?: InputMaybe<Scalars['ID']>;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly trailerDimensions?: InputMaybe<DimensionsInput>;
  readonly trailerLength?: InputMaybe<UnitOfLengthInput>;
  readonly trailerWidth?: InputMaybe<UnitOfLengthInput>;
  readonly trailerHeight?: InputMaybe<UnitOfLengthInput>;
  readonly emptyLocationId?: InputMaybe<Scalars['ID']>;
  readonly emptyLocationGeographySourceId?: InputMaybe<Scalars['Float']>;
  readonly emptyLocationGeographySource?: InputMaybe<Scalars['String']>;
  readonly emptyDateTime?: InputMaybe<Scalars['DateTime']>;
  readonly type?: InputMaybe<OfferType>;
  readonly reason?: InputMaybe<OfferReason>;
  readonly price?: InputMaybe<CurrencyInput>;
  readonly offerPrice?: InputMaybe<CurrencyInputV2>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly askPrice?: InputMaybe<CurrencyInput>;
  readonly askPriceV2?: InputMaybe<CurrencyInputV2>;
  readonly vendorValidationErrors?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['ID'];
  readonly status?: InputMaybe<OfferStatus>;
};

export type UpdateOutboundTenderSettingsInput = {
  readonly customerId: Scalars['ID'];
  readonly requestedById?: InputMaybe<Scalars['ID']>;
  readonly tenderAppointmentStatusTerm?: InputMaybe<Scalars['String']>;
  readonly automatedTendering?: InputMaybe<Scalars['Boolean']>;
  readonly rolloverTenderTime?: InputMaybe<Scalars['Boolean']>;
};

export type UpdatePatternInput = {
  readonly id: Scalars['ID'];
  readonly laneId: Scalars['ID'];
  readonly patternName: Scalars['String'];
  readonly eligibleDays?: InputMaybe<ReadonlyArray<DayOfWeekEnum>>;
  readonly eligibleDaysV2?: InputMaybe<ReadonlyArray<DayEnum>>;
  readonly patternRoutes: ReadonlyArray<UpdatePatternRouteInput>;
  readonly minimumPickupLeadTime?: InputMaybe<Scalars['Int']>;
  readonly userId: Scalars['ID'];
  readonly effectiveDate?: InputMaybe<Scalars['Date']>;
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
};

export type UpdatePatternPayload = {
  readonly __typename?: 'UpdatePatternPayload';
  readonly data?: Maybe<Pattern>;
  readonly errors?: Maybe<ReadonlyArray<PatternDataProblem>>;
};

export type UpdatePatternRouteInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly patternId: Scalars['ID'];
  readonly routeSequence: Scalars['Int'];
  readonly routeTypeId: Scalars['String'];
  readonly transportModeId: Scalars['String'];
  readonly loadSizeId: Scalars['String'];
  readonly trailerTypeId: Scalars['String'];
  readonly divisionId?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly maxCost?: InputMaybe<Scalars['Float']>;
  readonly patternStops: ReadonlyArray<UpdatePatternStopInput>;
};

export type UpdatePatternStopInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly patternRouteId?: InputMaybe<Scalars['ID']>;
  readonly facilityId?: InputMaybe<Scalars['ID']>;
  readonly useOrderStopFacility?: InputMaybe<Scalars['Boolean']>;
  readonly stopSequence: Scalars['Int'];
  readonly stopType: Scalars['String'];
  readonly dropType?: InputMaybe<ScheduleDropTypeEnum>;
  readonly workType?: InputMaybe<ScheduleWorkTypeEnum>;
  readonly requirements?: InputMaybe<ReadonlyArray<PatternStopRequirementInput>>;
  readonly daysFromPreviousStop?: InputMaybe<Scalars['Int']>;
  readonly laneStopSequence?: InputMaybe<Scalars['Int']>;
  readonly appointment?: InputMaybe<PatternStopAppointmentInput>;
};

export type UpdatePostingInput = {
  readonly id: Scalars['ID'];
  readonly updatedByUserId: Scalars['ID'];
  readonly referenceId?: InputMaybe<Scalars['String']>;
  readonly postedRate: CurrencyInput;
  readonly bookItNowRate?: InputMaybe<CurrencyInput>;
  readonly status?: InputMaybe<PostingStatus>;
  readonly source?: InputMaybe<PostingSource>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly loadBoards?: InputMaybe<ReadonlyArray<PostingSource>>;
};

export type UpdatePostingPayload = {
  readonly __typename?: 'UpdatePostingPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly posting?: Maybe<Posting>;
};

export type UpdateProcurementDetailsInput = {
  readonly availableTruckSelector: AvailableTruckSelector;
  readonly carrierName?: InputMaybe<Scalars['String']>;
  readonly carrierStatus?: InputMaybe<Scalars['String']>;
  readonly carrierServices?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly carrierCargoInsuranceLimit?: InputMaybe<CurrencyInput>;
  readonly mainCarrierRep?: InputMaybe<Scalars['String']>;
  readonly mainCarrierRepUserId?: InputMaybe<Scalars['String']>;
};

export type UpdateRateQuoteInput = {
  readonly autoAccept?: InputMaybe<Scalars['Boolean']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly lineItems: ReadonlyArray<RateQuoteLineItemInput>;
  readonly rateQuoteId: Scalars['ID'];
};

export type UpdateRouteInput = {
  readonly activationStatusId: Scalars['ID'];
  readonly division?: InputMaybe<Scalars['String']>;
  readonly hotRoute?: InputMaybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  readonly segmentCode?: InputMaybe<Scalars['String']>;
  readonly trailerTypeId: Scalars['ID'];
};

/** Autogenerated return type of UpdateRoute */
export type UpdateRoutePayload = {
  readonly __typename?: 'UpdateRoutePayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly route?: Maybe<LoadRoute>;
};

export type UpdateRouteStopsInput = {
  readonly routeId: Scalars['ID'];
  readonly stopIds: ReadonlyArray<Scalars['ID']>;
};

/** Autogenerated return type of UpdateRouteStops */
export type UpdateRouteStopsPayload = {
  readonly __typename?: 'UpdateRouteStopsPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly route: LoadRoute;
};

export type UpdateRouteVendorInput = {
  readonly bookedById?: InputMaybe<Scalars['ID']>;
  readonly bookedWithId?: InputMaybe<Scalars['ID']>;
  readonly bookingNotes?: InputMaybe<Scalars['String']>;
  readonly bracingType?: InputMaybe<BracingTypeEnum>;
  readonly distance?: InputMaybe<UnitOfDistanceInput>;
  readonly expectedEmptyGeographySource?: InputMaybe<Scalars['String']>;
  readonly expectedEmptyGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly numberOfBraces?: InputMaybe<Scalars['Int']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  readonly numberOfTarps?: InputMaybe<Scalars['Int']>;
  readonly palletType?: InputMaybe<PalletTypeEnum>;
  readonly routeVendorReps?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorRepInput>>;
  readonly tarpType?: InputMaybe<TarpTypeEnum>;
  readonly trailerHeight?: InputMaybe<UnitOfDistanceInput>;
  readonly trailerHeightV2?: InputMaybe<UnitOfLengthInput>;
  readonly trailerLength?: InputMaybe<UnitOfDistanceInput>;
  readonly trailerLengthV2?: InputMaybe<UnitOfLengthInput>;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly trailerWidth?: InputMaybe<UnitOfDistanceInput>;
  readonly trailerWidthV2?: InputMaybe<UnitOfLengthInput>;
  readonly utcExpectedEmptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly utcPickupEtaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly vendorType?: InputMaybe<RouteVendorTypeEnum>;
};

/** Autogenerated return type of UpdateRouteVendor */
export type UpdateRouteVendorPayload = {
  readonly __typename?: 'UpdateRouteVendorPayload';
  readonly errors: ReadonlyArray<UserError>;
  readonly routeVendor?: Maybe<RouteVendor>;
};

export type UpdateRouteVendorV2Input = {
  readonly activeBounceId?: InputMaybe<Scalars['ID']>;
  readonly bookedById?: InputMaybe<Scalars['ID']>;
  readonly bookedWithId?: InputMaybe<Scalars['ID']>;
  readonly bookingSourceId?: InputMaybe<Scalars['ID']>;
  readonly bookingNotes?: InputMaybe<Scalars['String']>;
  readonly bracingType?: InputMaybe<BracingTypeEnum>;
  readonly distance?: InputMaybe<UnitOfDistanceInput>;
  readonly expectedEmptyGeographySource?: InputMaybe<Scalars['String']>;
  readonly expectedEmptyGeographySourceId?: InputMaybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly numberOfBraces?: InputMaybe<Scalars['Int']>;
  readonly numberOfPallets?: InputMaybe<Scalars['Int']>;
  readonly numberOfTarps?: InputMaybe<Scalars['Int']>;
  readonly palletType?: InputMaybe<PalletTypeEnum>;
  readonly routeVendorReps?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorRepV2Input>>;
  readonly routeVendorReferences?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorReferenceInput>>;
  readonly routeVendorReferencesV2?: InputMaybe<ReadonlyArray<CreateOrUpdateRouteVendorReferenceInputV2>>;
  readonly tarpType?: InputMaybe<TarpTypeEnum>;
  readonly trailerHeight?: InputMaybe<UnitOfDistanceInput>;
  readonly trailerLength?: InputMaybe<UnitOfDistanceInput>;
  readonly trailerWidth?: InputMaybe<UnitOfDistanceInput>;
  readonly trailerHeightV2?: InputMaybe<LengthInput>;
  readonly trailerLengthV2?: InputMaybe<LengthInput>;
  readonly trailerWidthV2?: InputMaybe<LengthInput>;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId?: InputMaybe<Scalars['ID']>;
  readonly utcExpectedEmptyDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly utcPickupEtaDatetime?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly vendorType?: InputMaybe<RouteVendorTypeEnum>;
};

export type UpdateRouteVendorV2Payload = {
  readonly __typename?: 'UpdateRouteVendorV2Payload';
  readonly errors: ReadonlyArray<UserError>;
  readonly routeVendor?: Maybe<RouteVendorV2>;
};

export type UpdateRoutingGuideInput = {
  readonly id: Scalars['ID'];
  readonly routingGuideTypeTerm: Scalars['String'];
  readonly duration?: InputMaybe<Scalars['Int']>;
  readonly effectiveDate: Scalars['DateTime'];
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly isActive: Scalars['Boolean'];
  readonly updatedBy: Scalars['ID'];
};

export type UpdateRoutingGuideSequenceItemInput = {
  readonly id: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly scac: Scalars['String'];
  readonly updatedBy: Scalars['ID'];
  readonly tenderOptions?: InputMaybe<SequenceItemTenderOptionsInput>;
};

export type UpdateRuleSetsInput = {
  readonly eventName: Scalars['String'];
  readonly ruleSets: ReadonlyArray<InputMaybe<RuleSetInput>>;
};

/** As of now, this is simply a placeholder response object in case more response fields are needed in the future. */
export type UpdateRuleSetsResult = {
  readonly __typename?: 'UpdateRuleSetsResult';
  readonly eventName: Scalars['String'];
  readonly ruleSets: ReadonlyArray<Maybe<RuleSet>>;
};

export type UpdateServiceProfileInput = {
  readonly carrierId: Scalars['String'];
  readonly originRamp: Scalars['String'];
  readonly destinationRamp: Scalars['String'];
  readonly train?: InputMaybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly isCOFC?: InputMaybe<Scalars['Boolean']>;
  readonly isTOFC?: InputMaybe<Scalars['Boolean']>;
  readonly publishedTransitDays: Scalars['Float'];
  readonly reqOrigin?: InputMaybe<Scalars['Boolean']>;
  readonly reqLane?: InputMaybe<Scalars['Boolean']>;
  readonly boxType?: InputMaybe<Scalars['String']>;
  readonly laneStartDate: Scalars['DateTime'];
  readonly laneExpirationDate: Scalars['DateTime'];
  readonly laneExpirationDateV2?: InputMaybe<Scalars['DateTime']>;
  readonly schedules: BaseSchedulesInput;
};

export type UpdateTaskInput = {
  readonly id: Scalars['ID'];
  readonly type?: InputMaybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly assignedTo: Scalars['String'];
  readonly neededBy?: InputMaybe<Scalars['DateTime']>;
  readonly routeId?: InputMaybe<Scalars['String']>;
  readonly loadId?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly requestedBy?: InputMaybe<Scalars['ID']>;
  readonly groupId?: InputMaybe<Scalars['String']>;
  readonly comment?: InputMaybe<Scalars['String']>;
  readonly closedDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateTaskInputV2 = {
  readonly id: Scalars['ID'];
  readonly type?: InputMaybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly noteName?: InputMaybe<Scalars['String']>;
  readonly assignedTo?: InputMaybe<Scalars['String']>;
  readonly neededBy?: InputMaybe<Scalars['DateTime']>;
  readonly routeId?: InputMaybe<Scalars['String']>;
  readonly loadId?: InputMaybe<Scalars['String']>;
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly requestedBy?: InputMaybe<Scalars['ID']>;
  readonly groupId?: InputMaybe<Scalars['String']>;
  readonly assignedToGroupId?: InputMaybe<Scalars['String']>;
  readonly comment?: InputMaybe<Scalars['String']>;
  readonly closedDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateTemplatesInput = {
  readonly eventName: Scalars['String'];
  readonly templates: ReadonlyArray<InputMaybe<ContentTemplateInput>>;
};

export type UpdateTemplatesResult = {
  readonly __typename?: 'UpdateTemplatesResult';
  readonly eventName: Scalars['String'];
  readonly templates: ReadonlyArray<Maybe<ContentTemplate>>;
};

export type UpdateTenantExceptionRecordInput = {
  readonly id: Scalars['ID'];
  readonly routeID?: InputMaybe<Scalars['String']>;
  readonly orderID?: InputMaybe<Scalars['String']>;
  readonly customerID?: InputMaybe<Scalars['String']>;
  readonly carrierID?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<TenantExceptionRecordType>;
  readonly status?: InputMaybe<TenantExceptionRecordStatus>;
  readonly source?: InputMaybe<TenantExceptionRecordSource>;
  readonly assignedTo?: InputMaybe<Scalars['String']>;
  readonly assignedToGroup?: InputMaybe<Scalars['String']>;
  readonly assignedBy?: InputMaybe<Scalars['String']>;
  readonly updatedBy: Scalars['String'];
  readonly closedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateTenderOptionsInput = {
  readonly parentObjectType: Scalars['String'];
  readonly parentObjectId: Scalars['ID'];
  readonly tenderExpirationTimeSpan?: InputMaybe<TenderTimeSpanInput>;
  readonly autoTender?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateTruckEntryByRouteIdInput = {
  readonly previousRouteIdV2: Scalars['ID'];
  readonly loadByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly loadByTimezone?: InputMaybe<Scalars['String']>;
  readonly originLocation?: InputMaybe<TruckLocationInput>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly readyDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly updatedByUserId: Scalars['ID'];
};

export type UpdateTruckEntryInput = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly originLocation?: InputMaybe<TruckLocationInput>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId: Scalars['ID'];
  readonly bracingType?: InputMaybe<BracingTypesEnum>;
  readonly bracingCount?: InputMaybe<Scalars['Float']>;
  readonly driverName?: InputMaybe<Scalars['String']>;
  readonly externalSourceId?: InputMaybe<Scalars['String']>;
  /** Truck's finalBy datetime. Value is nullified if the finalByDatetime value is less than the loadByDatetime value. */
  readonly finalByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly finalByTimezone?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  /** Truck's loadBy datetime, defaults to the readyByDatetime (EOD) if a value is not provided or if the loadByDatetime value is less than the readyByDatetime value */
  readonly loadByDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly loadByTimezone?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly palletsType?: InputMaybe<PalletTypesEnum>;
  readonly palletsCount?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED: Use previousRouteIdV2 instead */
  readonly previousRouteId?: InputMaybe<Scalars['ID']>;
  readonly previousRouteIdV2?: InputMaybe<Scalars['ID']>;
  readonly readyDatetimeUtc?: InputMaybe<Scalars['ISO8601DateTime']>;
  readonly sourceType?: InputMaybe<SourceTypesEnum>;
  readonly sourceTypeId?: InputMaybe<Scalars['String']>;
  readonly tarpsType?: InputMaybe<TarpTypesEnum>;
  readonly tarpsCount?: InputMaybe<Scalars['Float']>;
  readonly trailerNumber?: InputMaybe<Scalars['String']>;
  readonly truckEntryTemplateId?: InputMaybe<Scalars['ID']>;
};

export type UpdateTruckEntryTemplateInput = {
  readonly carrierId?: InputMaybe<Scalars['ID']>;
  readonly createdByUserId?: InputMaybe<Scalars['ID']>;
  readonly destinationDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly destinationLocation?: InputMaybe<TruckLocationInput>;
  /** destinationRegionUrns is no longer supported, null will be returned until this field is removed */
  readonly destinationRegionUrns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly destinationStateIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly isHazmat?: InputMaybe<Scalars['Boolean']>;
  readonly isTeam?: InputMaybe<Scalars['Boolean']>;
  readonly originDeadhead?: InputMaybe<UnitOfLengthInput>;
  readonly originLocation?: InputMaybe<TruckLocationInput>;
  readonly ownerId?: InputMaybe<Scalars['ID']>;
  readonly readyTimezone?: InputMaybe<Scalars['String']>;
  readonly sourceId?: InputMaybe<Scalars['String']>;
  readonly trailerSizeLength?: InputMaybe<UnitOfLengthInput>;
  readonly trailerTypeId?: InputMaybe<Scalars['ID']>;
  readonly updatedByUserId?: InputMaybe<Scalars['ID']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly fridayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly mondayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly saturdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly status?: InputMaybe<TruckEntryTemplateStatus>;
  readonly sourceType?: InputMaybe<TruckEntryTemplateSourceTypesEnum>;
  readonly sundayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly thursdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly tuesdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
  readonly wednesdayMsOffsetFromStartOfDay?: InputMaybe<Scalars['Int']>;
};

export type UploadRoutingGuideError = {
  readonly __typename?: 'UploadRoutingGuideError';
  readonly message: Scalars['String'];
  readonly index: Scalars['Int'];
};

export type UploadRoutingGuideErrorV2 = {
  readonly __typename?: 'UploadRoutingGuideErrorV2';
  readonly message: Scalars['String'];
  readonly recordId: Scalars['String'];
};

export type UploadRoutingGuideInput = {
  readonly recordId: Scalars['String'];
  readonly laneId: Scalars['ID'];
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly routingGuideTypeTerm: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
  readonly effectiveDate: Scalars['Date'];
  readonly expirationDate?: InputMaybe<Scalars['Date']>;
  readonly duration?: InputMaybe<Scalars['Int']>;
  readonly sequenceItems: ReadonlyArray<InputMaybe<UploadRoutingGuideSequenceItemInput>>;
};

export type UploadRoutingGuidePayload = {
  readonly __typename?: 'UploadRoutingGuidePayload';
  /** @deprecated The upload process is now asynchronous */
  readonly routingGuides?: Maybe<ReadonlyArray<Maybe<RoutingGuide>>>;
  readonly queuedRoutingGuidesCount: Scalars['Int'];
  readonly errors?: Maybe<ReadonlyArray<Maybe<UploadRoutingGuideError>>>;
};

export type UploadRoutingGuideSequenceItemInput = {
  readonly carrierId: Scalars['ID'];
  readonly scac: Scalars['String'];
  readonly tenderOptions?: InputMaybe<TenderOptionsInput>;
};

export type UploadRoutingGuidesInput = {
  readonly routingGuideInputs: ReadonlyArray<SaveRoutingGuideInput>;
};

export type UploadRoutingGuidesV2Input = {
  readonly documentId: Scalars['String'];
  readonly routingGuideInputs: ReadonlyArray<UploadRoutingGuideInput>;
  readonly userId: Scalars['ID'];
};

export type UploadRoutingGuidesV2Payload = {
  readonly __typename?: 'UploadRoutingGuidesV2Payload';
  readonly queuedRoutingGuidesCount: Scalars['Int'];
  readonly documentId: Scalars['String'];
  readonly batchId?: Maybe<Scalars['ID']>;
  readonly errors: ReadonlyArray<UploadRoutingGuideErrorV2>;
};

export type UpsertAutoEtaInput = {
  readonly routeID: Scalars['ID'];
  readonly nextStopID: Scalars['ID'];
  readonly source: UpsertAutoEtaSource;
  readonly autoETADatetime?: InputMaybe<Scalars['DateTime']>;
};

/** Source for the new auto ETA value */
export enum UpsertAutoEtaSource {
  IngressApi = 'IngressAPI',
  /** @deprecated Inconsistent with trackingAutoEtaSource "Ingress API" option ID */
  Ingress = 'Ingress'
}

export type UpsertClientExceptionRecordMetadataInput = {
  readonly entityIdentifier: Scalars['String'];
  readonly entityType: ClientExceptionRecordMetadataType;
};

export type UpsertTenderOptionsInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly tenderModeTerm?: InputMaybe<Scalars['String']>;
  readonly tenderContacts?: InputMaybe<ReadonlyArray<InputMaybe<TenderContactInput>>>;
  readonly businessTimeZoneTerm?: InputMaybe<Scalars['String']>;
  readonly businessDays?: InputMaybe<ReadonlyArray<InputMaybe<TenderBusinessDaysInput>>>;
  readonly tenderExpirationTimeSpan?: InputMaybe<TenderTimeSpanInput>;
  readonly tenderLeadTimeSpan?: InputMaybe<TenderTimeSpanInput>;
  readonly parentObjectType: Scalars['String'];
  readonly parentObjectId: Scalars['ID'];
  readonly autoTender?: InputMaybe<Scalars['Boolean']>;
  readonly updatedByUserId: Scalars['ID'];
};

export type User = {
  readonly __typename?: 'User';
  readonly email: Scalars['String'];
  readonly employee?: Maybe<Employee>;
  readonly id: Scalars['ID'];
};

/** The connection type for User. */
export type UserConnection = {
  readonly __typename?: 'UserConnection';
  /** A list of edges. */
  readonly edges: ReadonlyArray<UserEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** The connection type for User. */
export type UserConnectionV2 = {
  readonly __typename?: 'UserConnectionV2';
  /** A list of edges. */
  readonly edges: ReadonlyArray<UserEdgeV2>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

export type UserDetailInput = {
  readonly id: Scalars['Uuid'];
};

/** An edge in a connection. */
export type UserEdge = {
  readonly __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: User;
};

/** An edge in a connection. */
export type UserEdgeV2 = {
  readonly __typename?: 'UserEdgeV2';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node: UserV2;
};

export type UserError = {
  readonly __typename?: 'UserError';
  readonly extensions?: Maybe<Scalars['Json']>;
  readonly message: Scalars['String'];
  readonly path?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type UserFilter = {
  readonly email?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['String']>;
};

export type UserFilterV2 = {
  readonly email?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['String']>;
};

export type UserIdObj = {
  readonly userId: Scalars['String'];
};

export type UserInputObj = {
  readonly userId: Scalars['String'];
  readonly userMessage: Scalars['String'];
};

export type UserPermission = {
  readonly __typename?: 'UserPermission';
  readonly resource: Scalars['String'];
  readonly scopes: ReadonlyArray<Scalars['String']>;
};

export type UserV2 = {
  readonly __typename?: 'UserV2';
  readonly email: Scalars['String'];
  readonly employee?: Maybe<EmployeeV2>;
  readonly id: Scalars['ID'];
};

export type ValidateBookingInput = {
  readonly routeId: Scalars['ID'];
  readonly vendorId: Scalars['ID'];
  readonly routeVendorType?: InputMaybe<RouteVendorTypeEnum>;
  readonly isRebook?: InputMaybe<Scalars['Boolean']>;
};

export type ValidateBookingPayload = {
  readonly __typename?: 'ValidateBookingPayload';
  readonly valid: Scalars['Boolean'];
  readonly validationErrors: ReadonlyArray<Scalars['String']>;
};

/** mastery-crm: input for validateCarrierCRMOpportunityNameAvailable query */
export type ValidateCarrierCrmOpportunityNameRequest = {
  readonly name: Scalars['String'];
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
  readonly vendorId: Scalars['ID'];
};

/** mastery-crm: input for validateCustomerCRMOpportunityNameAvailable query */
export type ValidateCustomerCrmOpportunityNameRequest = {
  readonly customerId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly opportunityId?: InputMaybe<Scalars['ID']>;
};

export type ValidationAuditHistory = {
  readonly __typename?: 'ValidationAuditHistory';
  readonly hashId: Scalars['String'];
  readonly version: Scalars['Int'];
  readonly field?: Maybe<Scalars['String']>;
  readonly action: Action;
  readonly previousValidation?: Maybe<Scalars['String']>;
  readonly newValidation?: Maybe<Scalars['String']>;
  readonly updatedBy: Scalars['String'];
  readonly updatedAt: Scalars['String'];
};

export type ValidationConfiguration = {
  readonly __typename?: 'ValidationConfiguration';
  readonly hashId: Scalars['String'];
  readonly entityVersion?: Maybe<Scalars['Int']>;
  readonly expiredCacheBehavior: ExpiredCacheBehavior;
  readonly failedValidationBehavior?: Maybe<Scalars['String']>;
  readonly globalDefault: Scalars['Boolean'];
  readonly outdatedFallbackBehavior: OutdatedFallbackBehavior;
  readonly requirements: ReadonlyArray<Requirement>;
  readonly validatorMetadata?: Maybe<ValidatorMetadata>;
  readonly configScope: ConfigScope;
  readonly division?: Maybe<Scalars['String']>;
  /** @deprecated use transportMode instead  */
  readonly mode?: Maybe<Mode>;
  readonly transportMode?: Maybe<Scalars['String']>;
  readonly scopedEntityUuid?: Maybe<Scalars['String']>;
  /** @deprecated use loadSize instead  */
  readonly size?: Maybe<Size>;
  readonly loadSize?: Maybe<Scalars['String']>;
  readonly equipment?: Maybe<Scalars['String']>;
  readonly unit?: Maybe<Scalars['String']>;
  /** @deprecated use validationEntityTypeDDT instead */
  readonly validationEntityType: ValidationEntityType;
  readonly validationEntityTypeDDT: Scalars['String'];
  /** @deprecated use validationStateDDT instead */
  readonly validationState: ValidationState;
  readonly validationStateDDT: Scalars['String'];
  readonly routeType?: Maybe<Scalars['String']>;
  readonly routeTransportMode?: Maybe<Scalars['String']>;
  readonly billToCode?: Maybe<Scalars['String']>;
};

export type ValidationConfigurationInput = {
  readonly hashId?: InputMaybe<Scalars['String']>;
  readonly entityVersion?: InputMaybe<Scalars['Int']>;
  readonly expiredCacheBehavior: ExpiredCacheBehavior;
  readonly failedValidationBehavior?: InputMaybe<Scalars['String']>;
  readonly globalDefault: Scalars['Boolean'];
  readonly outdatedFallbackBehavior: OutdatedFallbackBehavior;
  readonly requirements: ReadonlyArray<RequirementInput>;
  readonly validatorMetadata?: InputMaybe<ValidatorMetadataInput>;
  readonly configScope: ConfigScope;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly mode?: InputMaybe<Mode>;
  readonly transportMode?: InputMaybe<Scalars['String']>;
  readonly scopedEntityUuid?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Size>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly equipment?: InputMaybe<Scalars['String']>;
  readonly unit?: InputMaybe<Scalars['String']>;
  readonly validationEntityType?: InputMaybe<ValidationEntityType>;
  readonly validationEntityTypeDDT?: InputMaybe<Scalars['String']>;
  readonly validationState?: InputMaybe<ValidationState>;
  readonly validationStateDDT?: InputMaybe<Scalars['String']>;
  readonly routeType?: InputMaybe<Scalars['String']>;
  readonly routeTransportMode?: InputMaybe<Scalars['String']>;
  readonly billToCode?: InputMaybe<Scalars['String']>;
};

export type ValidationConfigurationKeysInput = {
  readonly configScope: ConfigScope;
  readonly division?: InputMaybe<Scalars['String']>;
  readonly mode?: InputMaybe<Mode>;
  readonly transportMode?: InputMaybe<Scalars['String']>;
  readonly scopedEntityUuid?: InputMaybe<Scalars['String']>;
  readonly size?: InputMaybe<Size>;
  readonly loadSize?: InputMaybe<Scalars['String']>;
  readonly equipment?: InputMaybe<Scalars['String']>;
  readonly unit?: InputMaybe<Scalars['String']>;
  readonly validationEntityType?: InputMaybe<ValidationEntityType>;
  readonly validationEntityTypeDDT?: InputMaybe<Scalars['String']>;
  readonly validationState?: InputMaybe<ValidationState>;
  readonly validationStateDDT?: InputMaybe<Scalars['String']>;
  readonly routeType?: InputMaybe<Scalars['String']>;
  readonly routeTransportMode?: InputMaybe<Scalars['String']>;
  readonly billToCode?: InputMaybe<Scalars['String']>;
};

export enum ValidationEntityType {
  Customer = 'Customer',
  DriverTab = 'DriverTab',
  ExpectedEmptyInformation = 'ExpectedEmptyInformation',
  Order = 'Order',
  VendorAssignment = 'VendorAssignment'
}

export enum ValidationState {
  InvoiceGeneration = 'InvoiceGeneration',
  Save = 'Save',
  SaveAndDispatch = 'SaveAndDispatch',
  SaveCarrierDetails = 'SaveCarrierDetails'
}

export type ValidatorMetadata = {
  readonly __typename?: 'ValidatorMetadata';
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdBy: Scalars['String'];
  readonly lastUpdate: LastUpdate;
  readonly lastUpdatedAt: Scalars['ISO8601DateTime'];
  readonly lastUpdatedBy: Scalars['String'];
  readonly validatorVersion: Scalars['Int'];
};

export type ValidatorMetadataInput = {
  readonly createdAt: Scalars['ISO8601DateTime'];
  readonly createdBy: Scalars['String'];
  readonly lastUpdate: LastUpdate;
  readonly lastUpdatedAt: Scalars['ISO8601DateTime'];
  readonly lastUpdatedBy: Scalars['String'];
  readonly validatorVersion: Scalars['Int'];
};

export type VendorAccountingQueueFilter = {
  readonly accountingStatuses?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly billToCustomerId?: InputMaybe<Scalars['ID']>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly carrierRepId?: InputMaybe<Scalars['ID']>;
  readonly customerId?: InputMaybe<Scalars['ID']>;
  readonly customerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly customerRepId?: InputMaybe<Scalars['ID']>;
  readonly deliveryDateRange?: InputMaybe<DateRange>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly exceptionReasonCodes?: InputMaybe<ReadonlyArray<ExceptionReason>>;
  readonly excludeVendorInvoiceMissing?: InputMaybe<Scalars['Boolean']>;
  readonly loadCode?: InputMaybe<Scalars['String']>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly orderCode?: InputMaybe<Scalars['String']>;
  readonly paymentTerm?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly project?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly refCode?: InputMaybe<Scalars['String']>;
  readonly remitToVendorId?: InputMaybe<Scalars['ID']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly routeFinancialStatuses?: InputMaybe<ReadonlyArray<VendorFinancialStatusEnum>>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly routeLifeCycleStatuses?: InputMaybe<ReadonlyArray<RouteLifeCycleStatusEnum>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
};

export type VendorAssociatedRequiredDocument = {
  readonly __typename?: 'vendorAssociatedRequiredDocument';
  readonly chargeType?: Maybe<Scalars['ID']>;
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isMergePod?: Maybe<Scalars['Boolean']>;
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly routeVendorType?: Maybe<RouteVendorTypeEnum>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
  readonly vendorId: Scalars['ID'];
};

/** The input type for a new vendor associated required document. */
export type VendorAssociatedRequiredDocumentCreateInput = {
  readonly chargeType?: InputMaybe<Scalars['ID']>;
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly isMergePod?: InputMaybe<Scalars['Boolean']>;
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly routeVendorType?: InputMaybe<RouteVendorTypeEnum>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
  readonly vendorId: Scalars['ID'];
};

/** The input type for updating vendor associated required document. */
export type VendorAssociatedRequiredDocumentUpdateInput = {
  readonly chargeType?: InputMaybe<Scalars['ID']>;
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isMergePod?: InputMaybe<Scalars['Boolean']>;
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly routeVendorType?: InputMaybe<RouteVendorTypeEnum>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
  readonly vendorId: Scalars['ID'];
};

/** Represents a auto_voucher_disable value of a particular vendor */
export type VendorAutoVoucherType = {
  readonly __typename?: 'VendorAutoVoucherType';
  readonly autoVoucherDisabled: Scalars['Boolean'];
  readonly vendorId: Scalars['ID'];
};

export type VendorCreditMemo = {
  readonly __typename?: 'VendorCreditMemo';
  readonly amount: Scalars['Decimal'];
  readonly creditMemoDate?: Maybe<Scalars['DateTime']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly voucherCurrencyAmount?: Maybe<Scalars['Decimal']>;
  readonly voucherHeader: VoucherHeader;
};

export type VendorCreditMemoInput = {
  readonly creditMemoDate: Scalars['DateTime'];
  readonly id: Scalars['ID'];
};

export enum VendorFinancialStatusEnum {
  Complete = 'COMPLETE',
  Exception = 'EXCEPTION',
  Incomplete = 'INCOMPLETE',
  None = 'NONE',
  Settled = 'SETTLED',
  /** @deprecated Field no longer supported */
  Unmapped = 'UNMAPPED',
  Vouchered = 'VOUCHERED',
  Awaitingpaperwork = 'AWAITINGPAPERWORK',
  Awaitingrequirements = 'AWAITINGREQUIREMENTS'
}

export type VendorInvoice = {
  readonly __typename?: 'VendorInvoice';
  readonly advanceAmount?: Maybe<Scalars['Decimal']>;
  readonly amount?: Maybe<Scalars['Decimal']>;
  readonly approvalDate?: Maybe<Scalars['DateTime']>;
  readonly approver?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly dateReceived?: Maybe<Scalars['DateTime']>;
  readonly dateToPay?: Maybe<Scalars['DateTime']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly enteredInvoiceAmount?: Maybe<Scalars['Decimal']>;
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: Maybe<Scalars['DateTime']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly invoiceCount?: Maybe<Scalars['Long']>;
  readonly invoiceDate?: Maybe<Scalars['DateTime']>;
  readonly isQuickpay?: Maybe<Scalars['Boolean']>;
  readonly isVouchered?: Maybe<Scalars['Boolean']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly loadV2?: Maybe<LoadV2>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly remitToAddressLine1?: Maybe<Scalars['String']>;
  readonly remitToAddressLine2?: Maybe<Scalars['String']>;
  readonly remitToCity?: Maybe<Scalars['String']>;
  readonly remitToCountry?: Maybe<Scalars['String']>;
  readonly remitToName?: Maybe<Scalars['String']>;
  readonly remitToState?: Maybe<Scalars['String']>;
  readonly remitToZipCode?: Maybe<Scalars['String']>;
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly routeLifeCycleStatus?: Maybe<RouteLifeCycleStatusEnum>;
  readonly size?: Maybe<Scalars['String']>;
  readonly status: VendorInvoiceStatus;
  readonly totalCost?: Maybe<Scalars['Decimal']>;
  readonly unvoucheredAmount?: Maybe<Scalars['Decimal']>;
  readonly variance?: Maybe<Scalars['Decimal']>;
  readonly vendorId?: Maybe<Scalars['ID']>;
  readonly vendorInvoiceNumber?: Maybe<Scalars['String']>;
  readonly vendorRouteFinancialStatus?: Maybe<VendorFinancialStatusEnum>;
  readonly voucheredAmount?: Maybe<Scalars['Decimal']>;
};

/** A connection to a list of items. */
export type VendorInvoiceConnection = {
  readonly __typename?: 'VendorInvoiceConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<VendorInvoiceEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<VendorInvoice>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type VendorInvoiceEdge = {
  readonly __typename?: 'VendorInvoiceEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<VendorInvoice>;
};

export type VendorInvoiceFilterInput = {
  readonly businessUnit?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly carrierRepId?: InputMaybe<Scalars['ID']>;
  readonly customerRepId?: InputMaybe<Scalars['Uuid']>;
  readonly deliveryDateRange?: InputMaybe<DateRange>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly hasMultipleInvoices?: InputMaybe<Scalars['Boolean']>;
  readonly loadCode?: InputMaybe<Scalars['String']>;
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly orderCode?: InputMaybe<Scalars['String']>;
  readonly orderId?: InputMaybe<Scalars['ID']>;
  readonly pageSize?: InputMaybe<Scalars['Long']>;
  readonly project?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly railsLoadIds?: InputMaybe<ReadonlyArray<Scalars['Uuid']>>;
  readonly refCode?: InputMaybe<Scalars['String']>;
  readonly remitToVendorId?: InputMaybe<Scalars['ID']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly routeFinancialStatuses?: InputMaybe<ReadonlyArray<VendorFinancialStatusEnum>>;
  readonly routeId?: InputMaybe<Scalars['ID']>;
  readonly routeLifeCycleStatuses?: InputMaybe<ReadonlyArray<RouteLifeCycleStatusEnum>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly skipRecordCount?: InputMaybe<Scalars['Long']>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly vendorInvoiceStatuses?: InputMaybe<ReadonlyArray<VendorInvoiceStatus>>;
};

export type VendorInvoiceInput = {
  readonly amount: Scalars['Decimal'];
  readonly approvalDate?: InputMaybe<Scalars['Date']>;
  readonly approver?: InputMaybe<Scalars['String']>;
  readonly comments?: InputMaybe<Scalars['String']>;
  readonly currency?: InputMaybe<Scalars['String']>;
  readonly dateReceived?: InputMaybe<Scalars['DateTime']>;
  readonly enteredInvoiceAmount?: InputMaybe<Scalars['Decimal']>;
  readonly exchangeRate?: InputMaybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: InputMaybe<Scalars['DateTime']>;
  readonly externalId?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly invoiceDate?: InputMaybe<Scalars['DateTime']>;
  readonly isQuickpay?: InputMaybe<Scalars['Boolean']>;
  readonly loadId: Scalars['ID'];
  readonly remitToAddressLine1?: InputMaybe<Scalars['String']>;
  readonly remitToAddressLine2?: InputMaybe<Scalars['String']>;
  readonly remitToCity?: InputMaybe<Scalars['String']>;
  readonly remitToCountry?: InputMaybe<Scalars['String']>;
  readonly remitToName?: InputMaybe<Scalars['String']>;
  readonly remitToState?: InputMaybe<Scalars['String']>;
  readonly remitToZipCode?: InputMaybe<Scalars['String']>;
  readonly routeId: Scalars['ID'];
  readonly status: VendorInvoiceStatus;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly vendorInvoiceNumber: Scalars['String'];
};

/** Represents the vendor invoice object when processing a Voucher. */
export type VendorInvoiceProcessingInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
};

export enum VendorInvoiceStatus {
  /** Vendor Invoice has been approved. */
  Approved = 'Approved',
  /** Vendor Invoice has been denied. */
  Denied = 'Denied',
  /** Vendor Invoice experienced an exception during processing. */
  Exception = 'Exception',
  /** Vendor Invoice is pending. */
  Pending = 'Pending'
}

export type VendorInvoiceUpsertInput = {
  readonly amount: Scalars['Decimal'];
  readonly approvalDate?: InputMaybe<Scalars['Date']>;
  readonly approver?: InputMaybe<Scalars['String']>;
  readonly comments?: InputMaybe<Scalars['String']>;
  readonly currency: Scalars['String'];
  readonly dateReceived: Scalars['Date'];
  readonly enteredInvoiceAmount?: InputMaybe<Scalars['Decimal']>;
  readonly exchangeRate?: InputMaybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: InputMaybe<Scalars['DateTime']>;
  readonly externalId: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly invoiceDate: Scalars['Date'];
  readonly isQuickpay?: InputMaybe<Scalars['Boolean']>;
  readonly loadId: Scalars['ID'];
  readonly remitToAddressLine1?: InputMaybe<Scalars['String']>;
  readonly remitToAddressLine2?: InputMaybe<Scalars['String']>;
  readonly remitToCity?: InputMaybe<Scalars['String']>;
  readonly remitToCountry?: InputMaybe<Scalars['String']>;
  readonly remitToName?: InputMaybe<Scalars['String']>;
  readonly remitToState?: InputMaybe<Scalars['String']>;
  readonly remitToZipCode?: InputMaybe<Scalars['String']>;
  readonly routeId: Scalars['ID'];
  readonly status: VendorInvoiceStatus;
  readonly vendorId: Scalars['ID'];
  readonly vendorInvoiceNumber: Scalars['String'];
};

export type VendorInvoiceVoucher = {
  readonly __typename?: 'VendorInvoiceVoucher';
  readonly id?: Maybe<Scalars['ID']>;
  readonly vendorInvoiceId?: Maybe<Scalars['ID']>;
  readonly voucherHeaderId?: Maybe<Scalars['ID']>;
};

export type VendorInvoiceVoucherInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly vendorInvoiceId?: InputMaybe<Scalars['ID']>;
  readonly voucherHeaderId?: InputMaybe<Scalars['ID']>;
};

export type VendorPayment = {
  readonly __typename?: 'VendorPayment';
  readonly amount?: Maybe<Scalars['Decimal']>;
  readonly checkNumber?: Maybe<Scalars['String']>;
  readonly issueDate?: Maybe<Scalars['Date']>;
  readonly paymentType?: Maybe<Scalars['String']>;
};

export type VendorPaymentInput = {
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly vendorRouteId?: InputMaybe<Scalars['ID']>;
  readonly voucherId?: InputMaybe<Scalars['ID']>;
};

export type VendorRequiredDocument = {
  readonly __typename?: 'VendorRequiredDocument';
  readonly chargeType?: Maybe<Scalars['ID']>;
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isMergePod?: Maybe<Scalars['Boolean']>;
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly routeVendorType?: Maybe<RouteVendorTypeEnum>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
};

/** The input type for a new vendor required document. */
export type VendorRequiredDocumentCreateInput = {
  readonly chargeType?: InputMaybe<Scalars['ID']>;
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly isMergePod?: InputMaybe<Scalars['Boolean']>;
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly routeVendorType?: InputMaybe<RouteVendorTypeEnum>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
};

/** The input type for updating vendor required document. */
export type VendorRequiredDocumentUpdateInput = {
  readonly chargeType?: InputMaybe<Scalars['ID']>;
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isMergePod?: InputMaybe<Scalars['Boolean']>;
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly routeVendorType?: InputMaybe<RouteVendorTypeEnum>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
};

/** The input type for creating and updating vendor required document. */
export type VendorRequiredDocumentUpdsetInput = {
  readonly chargeType?: InputMaybe<Scalars['ID']>;
  readonly division: ReadonlyArray<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly isMergePod?: InputMaybe<Scalars['Boolean']>;
  readonly loadDocumentType: Scalars['String'];
  readonly loadSize: ReadonlyArray<Scalars['String']>;
  readonly routeVendorType?: InputMaybe<RouteVendorTypeEnum>;
  readonly transportMode: ReadonlyArray<Scalars['String']>;
};

export type VendorRouteRequiredDocument = {
  readonly __typename?: 'VendorRouteRequiredDocument';
  readonly id?: Maybe<Scalars['ID']>;
  readonly isMergedPodDocument?: Maybe<Scalars['Boolean']>;
  readonly isWaived?: Maybe<Scalars['Boolean']>;
  readonly loadDocument?: Maybe<LoadDocument>;
  readonly loadDocumentId?: Maybe<Scalars['ID']>;
  readonly loadDocumentType?: Maybe<Scalars['String']>;
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly mergedPodStopNumbers?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly routeId?: Maybe<Scalars['ID']>;
  readonly stopAddress?: Maybe<Scalars['String']>;
  readonly stopFacilityName?: Maybe<Scalars['String']>;
  readonly stopNumber?: Maybe<Scalars['Int']>;
  readonly vendorId?: Maybe<Scalars['ID']>;
};

export type VendorRouteRequiredDocumentUpdateInput = {
  readonly id: Scalars['ID'];
  readonly isWaived: Scalars['Boolean'];
  readonly loadDocumentId?: InputMaybe<Scalars['ID']>;
};

/** Represents the Use_Global value of particular Vendor */
export type VendorUseGlobalType = {
  readonly __typename?: 'VendorUseGlobalType';
  readonly useGlobal: Scalars['Boolean'];
  readonly vendorId: Scalars['ID'];
};

export type VendorValidation = {
  readonly __typename?: 'VendorValidation';
  readonly id: Scalars['ID'];
  readonly vendorValidationErrors?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly offer: Offer;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type VendorVoucherFilter = {
  readonly amountRange?: InputMaybe<DecimalRange>;
  readonly balanceRange?: InputMaybe<DecimalRange>;
  readonly businessUnit?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly carrierRepId?: InputMaybe<Scalars['String']>;
  readonly customerRepId?: InputMaybe<Scalars['Uuid']>;
  readonly deliveryDateRange?: InputMaybe<DateRange>;
  readonly division?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly dueDateRange?: InputMaybe<DateRange>;
  readonly loadCode?: InputMaybe<Scalars['String']>;
  readonly loadId?: InputMaybe<Scalars['ID']>;
  readonly mode?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly orderCode?: InputMaybe<Scalars['String']>;
  readonly pageSize?: InputMaybe<Scalars['Long']>;
  readonly project?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly railsLoadIds?: InputMaybe<ReadonlyArray<Scalars['Uuid']>>;
  readonly refCode?: InputMaybe<Scalars['String']>;
  readonly remitToVendorId?: InputMaybe<Scalars['String']>;
  readonly routeCode?: InputMaybe<Scalars['String']>;
  readonly routeLifeCycleStatuses?: InputMaybe<ReadonlyArray<InputMaybe<RouteLifeCycleStatusEnum>>>;
  readonly size?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly skipRecordCount?: InputMaybe<Scalars['Long']>;
  readonly status?: InputMaybe<StatusEnum>;
  readonly statuses?: InputMaybe<ReadonlyArray<InputMaybe<StatusEnum>>>;
  readonly vendorFinancialStatuses?: InputMaybe<ReadonlyArray<InputMaybe<VendorFinancialStatusEnum>>>;
  readonly vendorId?: InputMaybe<Scalars['ID']>;
  readonly voucherDateRange?: InputMaybe<DateRange>;
  readonly voucherNumber?: InputMaybe<Scalars['String']>;
};

export type Volume = {
  readonly __typename?: 'Volume';
  readonly unit: VolumeUnitTypeEnum;
  readonly value: Scalars['Float'];
};

export enum VolumeUnitTypeEnum {
  Ft3 = 'ft3',
  M3 = 'm3'
}

export type VolumeV2 = {
  readonly __typename?: 'VolumeV2';
  readonly unit: Scalars['String'];
  readonly value: Scalars['Float'];
};

/** The Voucher Batch.  The batch will contain 1-n Voucher Headers. */
export type VoucherBatch = {
  readonly __typename?: 'VoucherBatch';
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly description: Scalars['String'];
  readonly id?: Maybe<Scalars['ID']>;
  readonly statusId: StatusEnum;
  readonly updatedBy?: Maybe<Scalars['String']>;
  readonly updatedOn?: Maybe<Scalars['DateTime']>;
  readonly voucherHeaders: ReadonlyArray<VoucherHeader>;
};

/** The Voucher batch processing input type.  Used to process Voucher batches */
export type VoucherBatchProcessingInput = {
  /** Should adjustments be created? */
  readonly createAdjustments?: InputMaybe<Scalars['Boolean']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly voucherDateOption?: InputMaybe<DefaultVoucherDateOptionEnum>;
  readonly voucherHeadersForProcessing: ReadonlyArray<VoucherHeaderProcessingInput>;
  readonly voucherOverrides?: InputMaybe<VoucherOverridesInput>;
};

export type VoucherDateDefault = {
  readonly __typename?: 'VoucherDateDefault';
  readonly defaultVoucherDateOption?: Maybe<DefaultVoucherDateOptionEnum>;
  readonly id: Scalars['ID'];
};

/** The input type for Default Date for Voucher */
export type VoucherDateDefaultInput = {
  readonly id: Scalars['ID'];
};

export enum VoucherExchangeRateConversionDateEnumType {
  Pickupdate = 'PICKUPDATE',
  Deliverydate = 'DELIVERYDATE',
  Voucherdate = 'VOUCHERDATE'
}

/** The Voucher Header Type */
export type VoucherHeader = {
  readonly __typename?: 'VoucherHeader';
  readonly adjustmentAmount?: Maybe<Scalars['Decimal']>;
  readonly batchId?: Maybe<Scalars['ID']>;
  readonly businessUnit?: Maybe<Scalars['String']>;
  readonly carrierPaymentMethod?: Maybe<Scalars['String']>;
  readonly carrierPaymentTerm?: Maybe<Scalars['String']>;
  readonly costDetails: ReadonlyArray<CostDetail>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['DateTime']>;
  readonly creditMemoProcessedDateTime?: Maybe<Scalars['DateTime']>;
  readonly currencyDiscountAmount?: Maybe<Scalars['Decimal']>;
  readonly currencyId?: Maybe<Scalars['String']>;
  readonly currentAmountDue?: Maybe<Scalars['Decimal']>;
  readonly dateSettled?: Maybe<Scalars['DateTime']>;
  readonly discountAmount?: Maybe<Scalars['Decimal']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly divisionCurrency?: Maybe<Scalars['String']>;
  readonly divisionCurrentAmountDue?: Maybe<Scalars['Decimal']>;
  readonly divisionExchangeRate?: Maybe<Scalars['Decimal']>;
  readonly divisionOriginalAmountDue?: Maybe<Scalars['Decimal']>;
  readonly dueDate?: Maybe<Scalars['DateTime']>;
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
  readonly exchangeRateDate?: Maybe<Scalars['DateTime']>;
  readonly hasSeerSearchLimitReached?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  readonly invoiceProcessedDateTime?: Maybe<Scalars['DateTime']>;
  readonly mode?: Maybe<Scalars['String']>;
  readonly originalAmountDue?: Maybe<Scalars['Decimal']>;
  readonly project?: Maybe<Scalars['String']>;
  readonly remittanceAddressCity?: Maybe<Scalars['String']>;
  readonly remittanceAddressPostalCode?: Maybe<Scalars['String']>;
  readonly remittanceAddressState?: Maybe<Scalars['String']>;
  readonly remittanceAddressStreet1?: Maybe<Scalars['String']>;
  readonly remittanceEmail?: Maybe<Scalars['String']>;
  readonly remittanceGrouping?: Maybe<VoucherMethodEnum>;
  readonly remitTo?: Maybe<Carrier>;
  readonly remitToV2?: Maybe<CarrierV2>;
  readonly size?: Maybe<Scalars['String']>;
  readonly status: StatusEnum;
  readonly vendorId: Scalars['ID'];
  readonly voucherCurrencyCurrentAmountDue?: Maybe<Scalars['Decimal']>;
  readonly voucherCurrencyOriginalAmountDue?: Maybe<Scalars['Decimal']>;
  readonly voucherDate?: Maybe<Scalars['DateTime']>;
  readonly voucherMethod: VoucherMethodEnum;
  readonly voucherNumber: Scalars['String'];
};

/** A connection to a list of items. */
export type VoucherHeaderConnection = {
  readonly __typename?: 'VoucherHeaderConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<VoucherHeaderEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<VoucherHeader>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type VoucherHeaderEdge = {
  readonly __typename?: 'VoucherHeaderEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<VoucherHeader>;
};

/** Voucher details (cost details) object when processing a voucher. */
export type VoucherHeaderProcessingInput = {
  readonly costDetailsForProcessing: ReadonlyArray<CostDetailProcessingInput>;
  readonly id: Scalars['ID'];
};

export type VoucherInvoiceBatchCreateInput = {
  /** Description of the batch */
  readonly description: Scalars['String'];
  /** The route/vendor id pairs in the batch */
  readonly routeVendors: ReadonlyArray<RouteVendorInput>;
  readonly voucherDateOption?: InputMaybe<DefaultVoucherDateOptionEnum>;
};

export enum VoucherMethodEnum {
  /**
   * Invoice totals are grouped by order
   * @deprecated This is an invalid value do not use
   */
  Order = 'Order',
  /**
   * Invoice totals are grouped by customer
   * @deprecated This is an invalid value do not use.
   */
  Combined = 'Combined',
  /** The Voucher will be printed and mailed. */
  Print = 'Print',
  /** The Voucher will be electronically delivered via E-mail. */
  Email = 'Email'
}

/** Overrides for vouchers at the time of voucher processing */
export type VoucherOverridesInput = {
  readonly paymentTerms?: InputMaybe<Scalars['String']>;
  readonly voucherDate?: InputMaybe<Scalars['DateTime']>;
};

export type VoucherTolerance = {
  readonly __typename?: 'VoucherTolerance';
  readonly division?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['ID'];
  readonly loadSize?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly lowerMax: Scalars['Decimal'];
  readonly lowerPercent?: Maybe<Scalars['Decimal']>;
  readonly transportMode?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly upperMax: Scalars['Decimal'];
  readonly upperPercent?: Maybe<Scalars['Decimal']>;
};

/** The input type for a new voucher tolerance. */
export type VoucherToleranceCreateInput = {
  readonly division?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly lowerMax: Scalars['Decimal'];
  readonly lowerPercent?: InputMaybe<Scalars['Decimal']>;
  readonly transportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly upperMax: Scalars['Decimal'];
  readonly upperPercent?: InputMaybe<Scalars['Decimal']>;
};

/** The input type for updating voucher tolerance. */
export type VoucherToleranceUpdateInput = {
  readonly division?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['ID'];
  readonly loadSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly lowerMax: Scalars['Decimal'];
  readonly lowerPercent?: InputMaybe<Scalars['Decimal']>;
  readonly transportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly upperMax: Scalars['Decimal'];
  readonly upperPercent?: InputMaybe<Scalars['Decimal']>;
};

/** The Voucher Vendor Tolerance. */
export type VoucherVendorTolerance = {
  readonly __typename?: 'VoucherVendorTolerance';
  readonly division?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['ID'];
  readonly loadSize?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly lowerMax: Scalars['Decimal'];
  readonly lowerPercent?: Maybe<Scalars['Decimal']>;
  readonly transportMode?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly upperMax: Scalars['Decimal'];
  readonly upperPercent?: Maybe<Scalars['Decimal']>;
  readonly vendorId: Scalars['ID'];
};

/** The input type for a new voucher vendor tolerance. */
export type VoucherVendorToleranceCreateInput = {
  readonly division?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly loadSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly lowerMax: Scalars['Decimal'];
  readonly lowerPercent?: InputMaybe<Scalars['Decimal']>;
  readonly transportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly upperMax: Scalars['Decimal'];
  readonly upperPercent?: InputMaybe<Scalars['Decimal']>;
  readonly vendorId: Scalars['ID'];
};

/** The input type for updating voucher vendor tolerance. */
export type VoucherVendorToleranceUpdateInput = {
  readonly division?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['ID'];
  readonly loadSize?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly lowerMax: Scalars['Decimal'];
  readonly lowerPercent?: InputMaybe<Scalars['Decimal']>;
  readonly transportMode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly upperMax: Scalars['Decimal'];
  readonly upperPercent?: InputMaybe<Scalars['Decimal']>;
};

export type WhatIf = {
  readonly __typename?: 'WhatIf';
  readonly loadId: Scalars['ID'];
  readonly globalConfigurationOverride?: Maybe<GlobalConfigurationOverride>;
  readonly orderOverrides?: Maybe<ReadonlyArray<OrderOverride>>;
  readonly routeOverrides?: Maybe<ReadonlyArray<RouteOverride>>;
};

export type WhatIfInput = {
  readonly loadId: Scalars['ID'];
  readonly globalConfigurationOverride?: InputMaybe<GlobalConfigurationOverrideInput>;
  readonly orderOverrides?: InputMaybe<ReadonlyArray<OrderOverrideInput>>;
  readonly routeOverrides?: InputMaybe<ReadonlyArray<RouteOverrideInput>>;
};

export type WhatIfScenario = {
  readonly __typename?: 'WhatIfScenario';
  readonly whatif?: Maybe<WhatIf>;
  readonly loadAllocatedMargins?: Maybe<LoadAllocatedMargins>;
};

export type WhoAmI = {
  readonly __typename?: 'WhoAmI';
  readonly employee?: Maybe<Employee>;
  readonly permissions: ReadonlyArray<UserPermission>;
  readonly user?: Maybe<User>;
};

export type WhoAmIv2 = {
  readonly __typename?: 'WhoAmIV2';
  readonly employee?: Maybe<EmployeeV2>;
  readonly permissions: ReadonlyArray<UserPermission>;
  readonly user?: Maybe<UserV2>;
};

/** What is a Widget? Who knows, but this is the SDL type for one! For Testing Purposes Only. */
export type Widget = {
  readonly __typename?: 'Widget';
  readonly id: Scalars['ID'];
  /** The name of the widget. */
  readonly name: Scalars['String'];
  /** The UTC date time string of when the widget was created. */
  readonly createdAtUtc: Scalars['DateTime'];
  /** The user that created the widget. */
  readonly createdBy?: Maybe<UserV2>;
};

export type WithinRadiusInput = {
  readonly withinRadius?: InputMaybe<UnitOfLengthInput>;
  readonly radiusGeoPoint?: InputMaybe<GeoPointInput>;
};

export type ZipCodeFormatResult = {
  readonly __typename?: 'ZipCodeFormatResult';
  readonly locality: Scalars['String'];
  readonly administrativeArea?: Maybe<Scalars['String']>;
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
};

export type ZipCodeRequest = {
  readonly postalCode: Scalars['String'];
  readonly country: Scalars['String'];
};

export type ZipCodeResult = ZipCodeFormatResult | InvalidAddress;
